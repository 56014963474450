import React, { useState } from 'react';
import { GridComponent, ColumnsDirective, ColumnDirective, Resize, Sort, ContextMenu, Filter, Page, ExcelExport, PdfExport, Inject, Toolbar } from '@syncfusion/ej2-react-grids';
import { replenishData2, replenishGrid2, replenishcompGrid, replenishcompData } from '../data/dummy';
import { Header } from '../components';

const Replenishment2 = () => {
  const [currentGrid, setCurrentGrid] = useState('replenish'); // Set the initial grid as 'replenish'
  const selectionsettings = { persistSelection: true };
  const toolbarOptions = ['ExcelExport']; // Remove 'Delete' and add 'ExcelExport' to export to Excel
  const editing = { allowDeleting: true, allowEditing: true };
  const customAttributes = { class: 'customcss' };

  // Define headerStyle object here
  const headerStyle = {
    backgroundColor: '#c0c0c0',
    color: 'rgb(0, 0, 0)',
    paddingLeft: '10px',
  };

  const handleGridChange = (gridName) => {
    setCurrentGrid(gridName);
  };

  return (
    <div className="m-2 md:m-3 mt-24 p-2 md:p-10 bg-white rounded-3xl">
      <Header category="MONTHLY" title="REPLENISHMENT (1 WH)" />
      {/*<div className="grid-tab-buttons">
        
       <button className={currentGrid === 'replenish' ? 'active' : ''} onClick={() => handleGridChange('replenish')}>Replenish Grid</button>
        <button className={currentGrid === 'complete' ? 'active' : ''} onClick={() => handleGridChange('complete')}>Complete Grid</button>
        
      </div> */}
      <GridComponent
        id="replenishmentGrid" // Add an ID to the GridComponent to reference it for exporting
        dataSource={currentGrid === 'replenish' ? replenishData2 : replenishcompData}
        enableHover={false}
        selectionSettings={selectionsettings}
        toolbar={toolbarOptions}
        editSettings={editing}
        allowTextWrap={false}
        allowResizing={true}
        allowScrolling={true} // Enable horizontal scrolling
        width="120%"
      >
        <ColumnsDirective>
          {currentGrid === 'replenish' ? (
            replenishGrid2.map((item, index) => (
              <ColumnDirective
                key={index}
                {...item}
                header={index === 0 ? headerStyle : undefined} // Apply headerStyle to the first column
                customAttributes={customAttributes}
                
              />
            ))
          ) : (
            replenishcompGrid.map((item, index) => (
              <ColumnDirective key={index} {...item} customAttributes={customAttributes}  />
            ))
          )}
        </ColumnsDirective>
        <Inject services={[Toolbar, ExcelExport, Filter, Resize]} />
      </GridComponent>
    </div>
  );
};

export default Replenishment2;
