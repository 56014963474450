import React from 'react';
import { AiOutlineCalendar, AiOutlineShoppingCart, AiOutlineAreaChart, AiOutlineBarChart, AiOutlineStock } from 'react-icons/ai';
import { FiShoppingBag, FiEdit, FiPieChart, FiBarChart, FiCreditCard, FiStar, FiShoppingCart } from 'react-icons/fi';
import { BsCircleFill, BsKanban, BsBarChart, BsBoxSeam, BsCurrencyDollar, BsShield, BsChatLeft } from 'react-icons/bs';
import { BiColorFill } from 'react-icons/bi';
import { IoMdContacts } from 'react-icons/io';
import { RiContactsLine, RiStockLine } from 'react-icons/ri';
import { MdOutlineSupervisorAccount, MdOilBarrel } from 'react-icons/md';
import { HiOutlineRefresh } from 'react-icons/hi';
import { TiTick } from 'react-icons/ti';
import { GiLouvrePyramid, GiHomeGarage } from 'react-icons/gi';
import { GrLocation } from 'react-icons/gr';
import avatar from './avatar.jpg';
import avatar2 from './avatar2.jpg';
import avatar3 from './avatar3.png';
import avatar4 from './avatar4.jpg';
import product1 from './product1.jpg';
import product2 from './product2.jpg';
import product3 from './product3.jpg';
import product4 from './product4.jpg';
import product5 from './product5.jpg';
import product6 from './product6.jpg';
import product7 from './product7.jpg';
import product8 from './product8.jpg';


export const gridOrderImage = (props) => (
  <div>
    <img
      className="rounded-xl h-20 md:ml-3"
      src={props.ProductImage}
      alt="order-item"
    />
  </div>
);

export const gridOrderStatus = (props) => (
  <button
    type="button"
    style={{ background: props.StatusBg }}
    className="text-white py-1 px-2 capitalize rounded-2xl text-md"
  >
    {props.Status}
  </button>
);

export const kanbanGrid = [
  { headerText: 'To Do',
    keyField: 'Open',
    allowToggle: true },

  { headerText: 'In Progress',
    keyField: 'InProgress',
    allowToggle: true },

  { headerText: 'Testing',
    keyField: 'Testing',
    allowToggle: true,
    isExpanded: false },

  { headerText: 'Done',
    keyField: 'Close',
    allowToggle: true },
];
const gridEmployeeProfile = (props) => (
  <div className="flex items-center gap-2">
    <img
      className="rounded-full w-10 h-10"
      src={props.EmployeeImage}
      alt="employee"
    />
    <p>{props.Name}</p>
  </div>
);

const gridEmployeeCountry = (props) => (
  <div className="flex items-center justify-center gap-2">
    <GrLocation />
    <span>{props.Country}</span>
  </div>
);
export const EditorData = () => (
  <div>
    <h3>
      Try React
      React has been designed from the start for gradual adoption, and you can use as little or as much React as you need. Whether you want to get a taste of React, add some interactivity to a simple HTML page, or start a complex React-powered app, the links in this section will help you get started.

      Online Playgrounds
      If you’re interested in playing around with React, you can use an online code playground. Try a Hello World template on CodePen, CodeSandbox, or Stackblitz.

      If you prefer to use your own text editor, you can also download this HTML file, edit it, and open it from the local filesystem in your browser. It does a slow runtime code transformation, so we’d only recommend using this for simple demos.

      Add React to a Website
      You can add React to an HTML page in one minute. You can then either gradually expand its presence, or keep it contained to a few dynamic widgets.

      Create a New React App
      When starting a React project, a simple HTML page with script tags might still be the best option. It only takes a minute to set up!

      As your application grows, you might want to consider a more integrated setup. There are several JavaScript toolchains we recommend for larger applications. Each of them can work with little to no configuration and lets you take full advantage of the rich React ecosystem. Learn how.

      Learn React
      People come to React from different backgrounds and with different learning styles. Whether you prefer a more theoretical or a practical approach, we hope you’ll find this section helpful.

      If you prefer to learn by doing, start with our practical tutorial.
      If you prefer to learn concepts step by step, start with our guide to main concepts.
      Like any unfamiliar technology, React does have a learning curve. With practice and some patience, you will get the hang of it.

      First Examples
      The React homepage contains a few small React examples with a live editor. Even if you don’t know anything about React yet, try changing their code and see how it affects the result.

      React for Beginners
      If you feel that the React documentation goes at a faster pace than you’re comfortable with, check out this overview of React by Tania Rascia. It introduces the most important React concepts in a detailed, beginner-friendly way. Once you’re done, give the documentation another try!

      React for Designers
      If you’re coming from a design background, these resources are a great place to get started.

      JavaScript Resources
      The React documentation assumes some familiarity with programming in the JavaScript language. You don’t have to be an expert, but it’s harder to learn both React and JavaScript at the same time.

      We recommend going through this JavaScript overview to check your knowledge level. It will take you between 30 minutes and an hour but you will feel more confident learning React.
    </h3>
  </div>
);
const customerGridImage = (props) => (
  <div className="image flex gap-4">
    <img
      className="rounded-full w-10 h-10"
      src={props.CustomerImage}
      alt="employee"
    />
    <div>
      <p>{props.CustomerName}</p>
      <p>{props.CustomerEmail}</p>
    </div>
  </div>
);

const customerGridStatus = (props) => (
  <div className="flex gap-2 justify-center items-center text-gray-700 capitalize">
    <p style={{ background: props.StatusBg }} className="rounded-full h-3 w-3" />
    <p>{props.Status}</p>
  </div>
);
export const areaPrimaryXAxis = {
  valueType: 'DateTime',
  labelFormat: 'y',
  majorGridLines: { width: 0 },
  intervalType: 'Years',
  edgeLabelPlacement: 'Shift',
  labelStyle: { color: 'gray' },
};

export const areaPrimaryYAxis = {
  labelFormat: '{value}%',
  lineStyle: { width: 0 },
  maximum: 4,
  interval: 1,
  majorTickLines: { width: 0 },
  minorTickLines: { width: 0 },
  labelStyle: { color: 'gray' },

};
export const barPrimaryXAxis = {
  valueType: 'Category',
  interval: 1,
  majorGridLines: { width: 0 },
};
export const barPrimaryYAxis = {
  majorGridLines: { width: 0 },
  majorTickLines: { width: 0 },
  lineStyle: { width: 0 },
  labelStyle: { color: 'transparent' },
};
const areaChartData = [
  [
    { x: new Date(2002, 0, 1), y: 2.2 },
    { x: new Date(2003, 0, 1), y: 3.4 },
    { x: new Date(2004, 0, 1), y: 2.8 },
    { x: new Date(2005, 0, 1), y: 1.6 },
    { x: new Date(2006, 0, 1), y: 2.3 },
    { x: new Date(2007, 0, 1), y: 2.5 },
    { x: new Date(2008, 0, 1), y: 2.9 },
    { x: new Date(2009, 0, 1), y: 3.8 },
    { x: new Date(2010, 0, 1), y: 1.4 },
    { x: new Date(2011, 0, 1), y: 3.1 },
  ],
  [
    { x: new Date(2002, 0, 1), y: 2 },
    { x: new Date(2003, 0, 1), y: 1.7 },
    { x: new Date(2004, 0, 1), y: 1.8 },
    { x: new Date(2005, 0, 1), y: 2.1 },
    { x: new Date(2006, 0, 1), y: 2.3 },
    { x: new Date(2007, 0, 1), y: 1.7 },
    { x: new Date(2008, 0, 1), y: 1.5 },
    { x: new Date(2009, 0, 1), y: 2.8 },
    { x: new Date(2010, 0, 1), y: 1.5 },
    { x: new Date(2011, 0, 1), y: 2.3 },
  ],
  [
    { x: new Date(2002, 0, 1), y: 0.8 },
    { x: new Date(2003, 0, 1), y: 1.3 },
    { x: new Date(2004, 0, 1), y: 1.1 },
    { x: new Date(2005, 0, 1), y: 1.6 },
    { x: new Date(2006, 0, 1), y: 2 },
    { x: new Date(2007, 0, 1), y: 1.7 },
    { x: new Date(2008, 0, 1), y: 2.3 },
    { x: new Date(2009, 0, 1), y: 2.7 },
    { x: new Date(2010, 0, 1), y: 1.1 },
    { x: new Date(2011, 0, 1), y: 2.3 },
  ],
];

export const areaCustomSeries = [
  {
    dataSource: areaChartData[0],
    xName: 'x',
    yName: 'y',
    name: 'USA',
    opacity: '0.8',
    type: 'SplineArea',
    width: '2',

  },
  {
    dataSource: areaChartData[1],
    xName: 'x',
    yName: 'y',
    name: 'France',
    opacity: '0.8',
    type: 'SplineArea',
    width: '2',
  },
  {
    dataSource: areaChartData[2],
    xName: 'x',
    yName: 'y',
    name: 'Germany',
    opacity: '0.8',
    type: 'SplineArea',
    width: '2',
  },
];

export const barChartData = [
  [
    { x: 'USA', y: 46 },
    { x: 'GBR', y: 27 },
    { x: 'CHN', y: 26 },
  ],
  [
    { x: 'USA', y: 37 },
    { x: 'GBR', y: 23 },
    { x: 'CHN', y: 18 },
  ],
  [
    { x: 'USA', y: 38 },
    { x: 'GBR', y: 17 },
    { x: 'CHN', y: 26 },
  ],
];

export const barCustomSeries = [
  {
    dataSource: barChartData[0],
    xName: 'x',
    yName: 'y',
    name: 'Gold',
    type: 'Column',
    marker: {
      dataLabel: {
        visible: true,
        position: 'Top',
        font: { fontWeight: '600', color: '#ffffff' },
      },
    },
  },
  {
    dataSource: barChartData[1],
    xName: 'x',
    yName: 'y',
    name: 'Silver',
    type: 'Column',
    marker: {
      dataLabel: {
        visible: true,
        position: 'Top',
        font: { fontWeight: '600', color: '#ffffff' },
      },
    },
  },
  {
    dataSource: barChartData[2],
    xName: 'x',
    yName: 'y',
    name: 'Bronze',
    type: 'Column',
    marker: {
      dataLabel: {
        visible: true,
        position: 'Top',
        font: { fontWeight: '600', color: '#ffffff' },
      },
    },
  },
];
export const colorMappingData = [
  [
    { x: 'Jan', y: 6.96 },
    { x: 'Feb', y: 8.9 },
    { x: 'Mar', y: 12 },
    { x: 'Apr', y: 17.5 },
    { x: 'May', y: 22.1 },
    { x: 'June', y: 25 },
    { x: 'July', y: 29.4 },
    { x: 'Aug', y: 29.6 },
    { x: 'Sep', y: 25.8 },
    { x: 'Oct', y: 21.1 },
    { x: 'Nov', y: 15.5 },
    { x: 'Dec', y: 9.9 },
  ],
  ['#FFFF99'],
  ['#FFA500'],
  ['#FF4040'],
];

export const rangeColorMapping = [
  { label: '1°C to 10°C',
    start: '1',
    end: '10',
    colors: colorMappingData[1] },

  { label: '11°C to 20°C',
    start: '11',
    end: '20',
    colors: colorMappingData[2] },

  { label: '21°C to 30°C',
    start: '21',
    end: '30',
    colors: colorMappingData[3] },

];

export const ColorMappingPrimaryXAxis = {
  valueType: 'Category',
  majorGridLines: { width: 0 },
  title: 'Months',
};

export const ColorMappingPrimaryYAxis = {
  lineStyle: { width: 0 },
  majorTickLines: { width: 0 },
  minorTickLines: { width: 0 },
  labelFormat: '{value}°C',
  title: 'Temperature',
};

export const FinancialPrimaryXAxis = {
  valueType: 'DateTime',
  minimum: new Date('2016, 12, 31'),
  maximum: new Date('2017, 9, 30'),
  crosshairTooltip: { enable: true },
  majorGridLines: { width: 0 },
};

export const FinancialPrimaryYAxis = {
  title: 'Price',
  minimum: 100,
  maximum: 180,
  interval: 20,
  lineStyle: { width: 0 },
  majorTickLines: { width: 0 },
};

export const LinePrimaryXAxis = {
  valueType: 'DateTime',
  labelFormat: 'y',
  intervalType: 'Years',
  edgeLabelPlacement: 'Shift',
  majorGridLines: { width: 0 },
  background: 'white',
};

export const LinePrimaryYAxis = {
  labelFormat: '{value}%',
  rangePadding: 'None',
  minimum: 0,
  maximum: 100,
  interval: 20,
  lineStyle: { width: 0 },
  majorTickLines: { width: 0 },
  minorTickLines: { width: 0 },
};

export const customersGrid = [
  { type: 'checkbox', width: '50' },
  { headerText: 'Name',
    width: '150',
    template: customerGridImage,
    textAlign: 'Center' },
  { field: 'ProjectName',
    headerText: 'Project Name',
    width: '150',
    textAlign: 'Center' },
  { field: 'Status',
    headerText: 'Status',
    width: '130',
    format: 'yMd',
    textAlign: 'Center',
    template: customerGridStatus },
  {
    field: 'Weeks',
    headerText: 'Weeks',
    width: '100',
    format: 'C2',
    textAlign: 'Center' },
  { field: 'Budget',
    headerText: 'Budget',
    width: '100',
    format: 'yMd',
    textAlign: 'Center' },

  { field: 'Location',
    headerText: 'Location',
    width: '150',
    textAlign: 'Center' },

  { field: 'CustomerID',
    headerText: 'Customer ID',
    width: '120',
    textAlign: 'Center',
    isPrimaryKey: true,
  },

];

export const employeesGrid = [
  { headerText: 'Employee',
    width: '150',
    template: gridEmployeeProfile,
    textAlign: 'Center' },
  { field: 'Name',
    headerText: '',
    width: '0',
    textAlign: 'Center',
  },
  { field: 'Title',
    headerText: 'Designation',
    width: '170',
    textAlign: 'Center',
  },
  { headerText: 'Country',
    width: '120',
    textAlign: 'Center',
    template: gridEmployeeCountry },

  { field: 'HireDate',
    headerText: 'Hire Date',
    width: '135',
    format: 'yMd',
    textAlign: 'Center' },

  { field: 'ReportsTo',
    headerText: 'Reports To',
    width: '120',
    textAlign: 'Center' },
  { field: 'EmployeeID',
    headerText: 'Employee ID',
    width: '125',
    textAlign: 'Center' },
];

export const links = [
  {
    
    title: 'IC HEALTH',
    links: [
      {
        name: 'INVENTORY',
        sublinks: [
          { name: 'AGING' },
          { name: 'TOPSPOT' },
          { name: 'PRIMEQUEST' },
          { name: 'CMI' },
          { name: 'REPLENISHMENT' },
          { name: 'REPLENISHMENT2' },
          { name: 'PTRF' },
          { name: 'PTRF2' },
        ],
      },
     
    ],
  },
  {
    
    title: 'MONITORING',
    links: [
      {
        name: 'LUBES',
        /*icon: <MdOilBarrel />,*/
        sublinks: [
          { name: 'VOLVO-CE' },
          { name: 'VOLVO-TRUCKS' },
          { name: 'UD' },
          { name: 'SDLG' },
          { name: 'LOCAL' },
        ],
      },
      { name: 'CROSS DOCK', /*icon: <GiHomeGarage />*/ },
      { name: 'DIRECT SHIPMENT' },
      { name: 'FILTERS' },
    ],
  },
  {
    title: 'MONTHLY REPORTS',
    links: [
      {
        name: 'LUBES',
        sublinks: [
          { name: 'VOLVO-CE' },
          { name: 'VOLVO-TRUCKS' },
          { name: 'UD' },
          { name: 'SDLG' },
          { name: 'LOCAL' },
        ],
      },
      { name: 'CROSS DOCK' },
      { name: 'DIRECT SHIPMENT' },
      { name: 'FILTERS' },
    ],
  },

  // Pages section (commented)
  // {
  //   title: 'Pages',
  //   links: [
  //     {
  //       name: 'orders',
  //       icon: <AiOutlineShoppingCart />,
  //     },
  //     {
  //       name: 'employees',
  //       icon: <IoMdContacts />,
  //     },
  //     {
  //       name: 'customers',
  //       icon: <RiContactsLine />,
  //     },
  //   ],
  // },

  // Apps section (commented)
  // {
  //   title: 'Apps',
  //   links: [
  //     {
  //       name: 'calendar',
  //       icon: <AiOutlineCalendar />,
  //     },
  //     {
  //       name: 'kanban',
  //       icon: <BsKanban />,
  //     },
  //     {
  //       name: 'editor',
  //       icon: <FiEdit />,
  //     },
  //     {
  //       name: 'color-picker',
  //       icon: <BiColorFill />,
  //     },
  //   ],
  // },

  // Charts section (commented)
  // {
  //   title: 'Charts',
  //   links: [
  //     {
  //       name: 'line',
  //       icon: <AiOutlineStock />,
  //     },
  //     {
  //       name: 'area',
  //       icon: <AiOutlineAreaChart />,
  //     },
  //     {
  //       name: 'bar',
  //       icon: <AiOutlineBarChart />,
  //     },
  //     {
  //       name: 'pie',
  //       icon: <FiPieChart />,
  //     },
  //     {
  //       name: 'financial',
  //       icon: <RiStockLine />,
  //     },
  //     {
  //       name: 'color-mapping',
  //       icon: <BsBarChart />,
  //     },
  //     {
  //       name: 'pyramid',
  //       icon: <GiLouvrePyramid />,
  //     },
  //     {
  //       name: 'stacked',
  //       icon: <AiOutlineBarChart />,
  //     },
  //   ],
  // },
];




export const chatData = [
  {
    image:
      avatar2,
    message: 'Roman Joined the Team!',
    desc: 'Congratulate him',
    time: '9:08 AM',
  },
  {
    image:
      avatar3,
    message: 'New message received',
    desc: 'Salma sent you new message',
    time: '11:56 AM',
  },
  {
    image:
      avatar4,
    message: 'New Payment received',
    desc: 'Check your earnings',
    time: '4:39 AM',
  },
  {
    image:
      avatar,
    message: 'Jolly completed tasks',
    desc: 'Assign her new tasks',
    time: '1:12 AM',
  },
];

export const earningData = [
  {
    icon: <BsCircleFill />,
    amount: '285,761,797.77',
    percentage: '-4%',
    title: 'TOTAL INVENTORY',
    iconColor: 'gray',
    iconBg: '',
    pcColor: 'red-600',
  },
  {
    icon: <BsCircleFill />,
    amount: '123,456,789',
    percentage: '+23%',
    title: 'COGS',
    iconColor: 'gray',
    iconBg: '',
    pcColor: 'green-600',
  },
  {
    icon: <BsCircleFill />,
    amount: '1.3',
    percentage: '+38%',
    title: 'TOR',
    iconColor: 'gray',
    iconBg: '',

    pcColor: 'green-600',
  },

];
export const earningData1 = [
  {
    icon: <BsCircleFill />,
    amount: '285,761,797.77',
    percentage: '15%',
    title: 'PAST',
    iconColor: 'gray',
    iconBg: '',
    //pcColor: 'red-600',
  },
  {
    icon: <BsCircleFill />,
    amount: '123,456,789',
    percentage: '25%',
    title: 'AVERAGE',
    iconColor: 'gray',
    iconBg: '',
    //pcColor: 'green-600',
  },
  {
    icon: <BsCircleFill />,
    amount: '1.3',
    percentage: '30%',
    title: 'SLOW',
    iconColor: 'gray',
    iconBg: '',

    //pcColor: 'green-600',
  },
  {
    icon: <BsCircleFill />,
    amount: '1.3',
    percentage: '30%',
    title: 'NONMOVING',
    iconColor: 'gray',
    iconBg: '',

    //pcColor: 'green-600',
  },

];

export const recentTransactions = [
  {
    icon: <BsCurrencyDollar />,
    amount: '+$350',
    title: 'Paypal Transfer',
    desc: 'Money Added',
    iconColor: '#03C9D7',
    iconBg: '#E5FAFB',
    pcColor: 'green-600',
  },
  {
    icon: <BsShield />,
    amount: '-$560',
    desc: 'Bill Payment',
    title: 'Wallet',
    iconColor: 'rgb(0, 194, 146)',
    iconBg: 'rgb(235, 250, 242)',
    pcColor: 'red-600',
  },
  {
    icon: <FiCreditCard />,
    amount: '+$350',
    title: 'Credit Card',
    desc: 'Money reversed',
    iconColor: 'rgb(255, 244, 229)',
    iconBg: 'rgb(254, 201, 15)',

    pcColor: 'green-600',
  },
  {
    icon: <TiTick />,
    amount: '+$350',
    title: 'Bank Transfer',
    desc: 'Money Added',

    iconColor: 'rgb(228, 106, 118)',
    iconBg: 'rgb(255, 244, 229)',
    pcColor: 'green-600',
  },
  {
    icon: <BsCurrencyDollar />,
    amount: '-$50',
    percentage: '+38%',
    title: 'Refund',
    desc: 'Payment Sent',
    iconColor: '#03C9D7',
    iconBg: '#E5FAFB',
    pcColor: 'red-600',
  },
];

export const weeklyStats = [
  {
    icon: <FiShoppingCart />,
    amount: '-$560',
    title: 'Top Sales',
    desc: 'Johnathan Doe',
    iconBg: '#FB9678',
    pcColor: 'red-600',
  },
  {
    icon: <FiStar />,
    amount: '-$560',
    title: 'Best Seller',
    desc: 'MaterialPro Admin',
    iconBg: 'rgb(254, 201, 15)',
    pcColor: 'red-600',
  },
  {
    icon: <BsChatLeft />,
    amount: '+$560',
    title: 'Most Commented',
    desc: 'Ample Admin',
    iconBg: '#00C292',
    pcColor: 'green-600',
  },
];

export const productsPerformance = [
  {
    
      
    title: 'Is it good butterscotch ice-cream?',
    desc: 'Ice-Cream, Milk, Powder',
    rating: 'Good',
    itemSold: '65%',
    earningAmount: '$546,000',
  },
  {
   
    title: 'Supreme fresh tomato available',
    desc: 'Market, Mall',
    rating: 'Excellent',
    itemSold: '98%',
    earningAmount: '$780,000',
  },
  {
    
    title: 'Red color candy from Gucci',
    desc: 'Chocolate, Yummy',
    rating: 'Average',
    itemSold: '46%',
    earningAmount: '$457,000',
  },
  {
    
    title: 'Stylish night lamp for night',
    desc: 'Electric, Wire, Current',
    rating: 'Poor',
    itemSold: '23%',
    earningAmount: '$123,000',
  },
];

export const medicalproBranding = {
  data: [
    {
      title: 'Due Date',
      desc: 'Oct 23, 2021',
    },
    {
      title: 'Budget',
      desc: '$98,500',
    },
    {
      title: 'Expense',
      desc: '$63,000',
    },
  ],
  teams: [
    {
      name: 'Bootstrap',
      color: 'orange',
    },
    {
      name: 'Angular',
      color: '#FB9678',
    },
  ],
  leaders: [
    {
      image:
        avatar2,
    },
    {
      image:
        avatar3,
    },
    {
      image:
        avatar2,
    },
    {
      image:
        avatar4,
    },
    {
      image:
        avatar,
    },
  ],
};

export const themeColors = [
  {
    name: 'blue-theme',
    color: '#1A97F5',
  },
  {
    name: 'green-theme',
    color: '#03C9D7',
  },
  {
    name: 'purple-theme',
    color: '#7352FF',
  },
  {
    name: 'red-theme',
    color: '#FF5C8E',
  },
  {
    name: 'indigo-theme',
    color: '#1E4DB7',
  },
  {
    color: '#FB9678',
    name: 'orange-theme',
  },
];

export const userProfileData = [
  {
    icon: <BsCurrencyDollar />,
    title: 'My Profile',
    desc: 'Account Settings',
    iconColor: '#03C9D7',
    iconBg: '#E5FAFB',
  },
  {
    icon: <BsShield />,
    title: 'My Inbox',
    desc: 'Messages & Emails',
    iconColor: 'rgb(0, 194, 146)',
    iconBg: 'rgb(235, 250, 242)',
  },
  {
    icon: <FiCreditCard />,
    title: 'My Tasks',
    desc: 'To-do and Daily Tasks',
    iconColor: 'rgb(255, 244, 229)',
    iconBg: 'rgb(254, 201, 15)',
  },
];

export const cluster1Grid = [
  { field: "WH", headerName: "WH", width: 100 },
  { field: "WH Desc", headerName: "WH Desc", width: 150 },
  { field: "AVE_30_DAYS", headerName: "AVE. 30 DAYS", width: 150 }, 
  { field: "TREND", headerName: "TREND", width: 120 },
  { field: "CONS_FC", headerName: "CONS FC", width: 120 }, 
  { field: "STOCK_STATUS", headerName: "STOCK STATUS", width: 150 }, 
];


export const cluster1Data = [
  { "WH": 12, "WH Desc": "MNL", "AVE30DAYS": 71.6666666666666, "TREND": 0.976744186046511, "CONS FC": 10.325581395348847, "STOCK STATUS": "OVERSTOCK" },
  { "WH": 18, "WH Desc": "ISA", "AVE30DAYS": 333.583333333333, "TREND": 0.793904571571321, "CONS FC": 8.72945291031727, "STOCK STATUS": "OVERSTOCK" },
  { "WH": 19, "WH Desc": "PAL", "AVE30DAYS": 557.666666666666, "TREND": 1.0448296473401, "CONS FC": 2.53197848176928, "STOCK STATUS": "INSUFFICIENT" },
  { "WH": 15, "WH Desc": "LEG", "AVE30DAYS": 71.6666666666666, "TREND": 0.976744186046511, "CONS FC": 2.606872037914695, "STOCK STATUS": "INSUFFICIENT" },
  { "WH": 17, "WH Desc": "BAT", "AVE30DAYS": 333.583333333333, "TREND": 0.793904571571321, "CONS FC": 4.319071167490866, "STOCK STATUS": "SUFFICIENT" },
  { "WH": 16, "WH Desc": "LAU", "AVE30DAYS": 557.666666666666, "TREND": 1.0448296473401, "CONS FC": 31.893292682926926, "STOCK STATUS": "OVERSTOCK" },
  { "WH": 40, "WH Desc": "PLX", "AVE30DAYS": 71.6666666666666, "TREND": 0.976744186046511, "CONS FC": 7.849353049907593, "STOCK STATUS": "OVERSTOCK" },
  { "WH": 47, "WH Desc": "RTN", "AVE30DAYS": 333.583333333333, "TREND": 0.793904571571321, "CONS FC": 13.905759162303665, "STOCK STATUS": "OVERSTOCK" },
  { "WH": 48, "WH Desc": "BAT", "AVE30DAYS": 557.666666666666, "TREND": 1.0448296473401, "CONS FC": 22.082840236686405, "STOCK STATUS": "OVERSTOCK" },
  { "WH": 50, "WH Desc": "SUN", "AVE30DAYS": 71.6666666666666, "TREND": 0.976744186046511, "CONS FC": 47.37096774193548, "STOCK STATUS": "OVERSTOCK" },
  { "WH": 44, "WH Desc": "SUA", "AVE30DAYS": 557.666666666666, "TREND": 1.0448296473401, "CONS FC": 3.878386699507413, "STOCK STATUS": "SUFFICIENT" },
  
];

export const cluster2Data = [
  { "WH": 90, "WH Desc": "CDO", "AVE30DAYS": 333.583333333333, "TREND": 0.793904571571321, "CONS FC": 10.325581395348847, "STOCK STATUS": "OVERSTOCK" },
  { "WH": 91, "WH Desc": "VAL", "AVE30DAYS": 557.666666666666, "TREND": 1.0448296473401, "CONS FC": 8.72945291031727, "STOCK STATUS": "OVERSTOCK" },
  { "WH": 30, "WH Desc": "DVO", "AVE30DAYS": 71.6666666666666, "TREND": 0.976744186046511, "CONS FC": 2.53197848176928, "STOCK STATUS": "INSUFFICIENT" },
  { "WH": 20, "WH Desc": "CBU", "AVE30DAYS": 333.583333333333, "TREND": 0.793904571571321, "CONS FC": 2.606872037914695, "STOCK STATUS": "INSUFFICIENT" },
  { "WH": 28, "WH Desc": "ILO", "AVE30DAYS": 557.666666666666, "TREND": 1.0448296473401, "CONS FC": 4.319071167490866, "STOCK STATUS": "SUFFICIENT" },
  { "WH": 21, "WH Desc": "SCAR", "AVE30DAYS": 71.6666666666666, "TREND": 0.976744186046511, "CONS FC": 31.893292682926926, "STOCK STATUS": "OVERSTOCK" },
  { "WH": 29, "WH Desc": "CCC", "AVE30DAYS": 333.583333333333, "TREND": 0.793904571571321, "CONS FC": 7.849353049907593, "STOCK STATUS": "OVERSTOCK" },
  { "WH": 31, "WH Desc": "APX", "AVE30DAYS": 557.666666666666, "TREND": 1.0448296473401, "CONS FC": 13.905759162303665, "STOCK STATUS": "OVERSTOCK" },
];


export const cluster3Data = [
  { "WH": 22, "WH Desc": "SUR", "AVE30DAYS": 71.6666666666666, "TREND": 0.976744186046511, "CONS FC": 1.802907077349028, "STOCK STATUS": "INSUFFICIENT" },
  { "WH": 26, "WH Desc": "CLA", "AVE30DAYS": 333.583333333333, "TREND": 0.793904571571321, "CONS FC": 3.878386699507413, "STOCK STATUS": "SUFFICIENT" },
  { "WH": 23, "WH Desc": "CARA", "AVE30DAYS": 557.666666666666, "TREND": 1.0448296473401, "CONS FC": 2.2041442824251725, "STOCK STATUS": "INSUFFICIENT" },
  { "WH": 52, "WH Desc": "TMC", "AVE30DAYS": 71.6666666666666, "TREND": 0.976744186046511, "CONS FC": 10.325581395348847, "STOCK STATUS": "OVERSTOCK" },
  { "WH": 54, "WH Desc": "HMC/CMC", "AVE30DAYS": 333.583333333333, "TREND": 0.793904571571321, "CONS FC": 8.72945291031727, "STOCK STATUS": "OVERSTOCK" },
  { "WH": 24, "WH Desc": "SRM", "AVE30DAYS": 557.666666666666, "TREND": 1.0448296473401, "CONS FC": 2.53197848176928, "STOCK STATUS": "INSUFFICIENT" },
  { "WH": 27, "WH Desc": "NB", "AVE30DAYS": 71.6666666666666, "TREND": 0.976744186046511, "CONS FC": 2.606872037914695, "STOCK STATUS": "INSUFFICIENT" },
  { "WH DESC": "TOTAL", "ON HAND": 740, "AVE30DAYS": 1997.4999999999977, "TREND": 0.976744186046511, "CONS FC": 2.2041442824251725, "STOCK STATUS": "INSUFFICIENT" },
  { "WH DESC": "GRAND TOTAL", "ON HAND": 740, "AVE30DAYS": 740, "TREND": 0.976744186046511, "CONS FC": 2.606872037914695, "STOCK STATUS": "INSUFFICIENT" },
];

export const VolvoCEData = [
  {
    "ITEM CODE": 101777,
    "PART NUMBER": 15002772,
    "DESCRIPTION": "TRANSMISSION OIL",
    "CAT": "LUV",
    "ON HAND": 500,
    "SHELF LIFE MTHS": 6.976744,
    "CURRENT STOCKING STATUS": "OVERSTOCK",
    "AVE. 30 DAYS": 71.666667,
    "TREND": 0.55814,
    "ON ORDER": 0,
    "ON BACKORDER": 0,
    "ROQ": 0,
    "APPROVED QTY": 0,
    "TOTAL": 500
  },
  {
    "ITEM CODE": 16217,
    "PART NUMBER": 15067098,
    "DESCRIPTION": "AUTOMATIC TRANSMISSION FLUID AT101",
    "CAT": "LUV",
    "ON HAND": 2748,
    "SHELF LIFE MTHS": 9.182957,
    "CURRENT STOCKING STATUS": "OVERSTOCK",
    "AVE. 30 DAYS": 299.25,
    "TREND": 0.90504,
    "ON ORDER": 0,
    "ON BACKORDER": 0,
    "ROQ": 0,
    "APPROVED QTY": 0,
    "TOTAL": 3268
  },
  {
    "ITEM CODE": 63181,
    "PART NUMBER": 15067513,
    "DESCRIPTION": "SUPER HYD OIL VG46",
    "CAT": "LUV",
    "ON HAND": 1412,
    "SHELF LIFE MTHS": 3.025714,
    "CURRENT STOCKING STATUS": "INSUFFICIENT",
    "AVE. 30 DAYS": 466.666667,
    "TREND": 1.222857,
    "ON ORDER": 2420,
    "ON BACKORDER": 0,
    "ROQ": 0,
    "APPROVED QTY": 0,
    "TOTAL": 4432
  },
  {
    "ITEM CODE": 16220,
    "PART NUMBER": 15067515,
    "DESCRIPTION": "SUPER TRANSMISSION OIL  80W/90  API GL-5",
    "CAT": "LUV",
    "ON HAND": 6350,
    "SHELF LIFE MTHS": 2.338212,
    "CURRENT STOCKING STATUS": "INSUFFICIENT",
    "AVE. 30 DAYS": 2715.75,
    "TREND": 0.962104,
    "ON ORDER": 7460,
    "ON BACKORDER": 0,
    "ROQ": 0,
    "APPROVED QTY": 0,
    "TOTAL": 15310
  },
  {
    "ITEM CODE": 16221,
    "PART NUMBER": 15067522,
    "DESCRIPTION": "SUPER TRANSMISSION OIL  85W/140  OIL API GL-5 99990-1Z05J",
    "CAT": "LUV",
    "ON HAND": 15710,
    "SHELF LIFE MTHS": 4.123901,
    "CURRENT STOCKING STATUS": "SUFFICIENT",
    "AVE. 30 DAYS": 3809.5,
    "TREND": 1.035219,
    "ON ORDER": 2400,
    "ON BACKORDER": 3080,
    "ROQ": 0,
    "APPROVED QTY": 0,
    "TOTAL": 21190
  },
  {
    "ITEM CODE": 27921,
    "PART NUMBER": 15067640,
    "DESCRIPTION": "GREASE, LITHIUM  EP2 ",
    "CAT": "LUV",
    "ON HAND": 3391,
    "SHELF LIFE MTHS": 28.921109,
    "CURRENT STOCKING STATUS": "OVERSTOCK",
    "AVE. 30 DAYS": 117.25,
    "TREND": 1.215352,
    "ON ORDER": 0,
    "ON BACKORDER": 19,
    "ROQ": 0,
    "APPROVED QTY": 0,
    "TOTAL": 3410
  },
  {
    "ITEM CODE": 16230,
    "PART NUMBER": 15067644,
    "DESCRIPTION": "WET BRAKE TRANSAXLE OIL WB101",
    "CAT": "LUV",
    "ON HAND": 2703,
    "SHELF LIFE MTHS": 7.957802,
    "CURRENT STOCKING STATUS": "OVERSTOCK",
    "AVE. 30 DAYS": 339.666667,
    "TREND": 0.949951,
    "ON ORDER": 0,
    "ON BACKORDER": 300,
    "ROQ": 0,
    "APPROVED QTY": 0,
    "TOTAL": 3003
  },
  {
    "ITEM CODE": 16232,
    "PART NUMBER": 15198387,
    "DESCRIPTION": "SUPER GEAR OIL 75W80 GO102 L150G/A40F 15057965 20L/PAIL",
    "CAT": "LUV",
    "ON HAND": 4512,
    "SHELF LIFE MTHS": 12.940727,
    "CURRENT STOCKING STATUS": "OVERSTOCK",
    "AVE. 30 DAYS": 348.666667,
    "TREND": 1.567878,
    "ON ORDER": 0,
    "ON BACKORDER": 800,
    "ROQ": 0,
    "APPROVED QTY": 0,
    "TOTAL": 5312
  },
  {
    "ITEM CODE": 93516,
    "PART NUMBER": 17232682,
    "DESCRIPTION": "VOLVO ENGINE OIL VDS-4 15W-40",
    "CAT": "LUV",
    "ON HAND": 1244,
    "SHELF LIFE MTHS": 22.08284,
    "CURRENT STOCKING STATUS": "OVERSTOCK",
    "AVE. 30 DAYS": 56.333333,
    "TREND": 0.733728,
    "ON ORDER": 0,
    "ON BACKORDER": 0,
    "ROQ": 0,
    "APPROVED QTY": 0,
    "TOTAL": 1244
  },
  {
    "ITEM CODE": 83065,
    "PART NUMBER": 17511983,
    "DESCRIPTION": "VOLVO SYNTHETIC DRUM ECCENTRIC OIL",
    "CAT": "LUV",
    "ON HAND": 5870,
    "SHELF LIFE MTHS": 53.525836,
    "CURRENT STOCKING STATUS": "OVERSTOCK",
    "AVE. 30 DAYS": 109.666667,
    "TREND": 1.12462,
    "ON ORDER": 0,
    "ON BACKORDER": 20,
    "ROQ": 0,
    "APPROVED QTY": 0,
    "TOTAL": 5890
  },
  {
    "ITEM CODE": 16214,
    "PART NUMBER": 15058186,
    "DESCRIPTION": "SUPER HYDRAULIC OIL  ISO VG68",
    "CAT": "LUV",
    "ON HAND": 21950,
    "SHELF LIFE MTHS": 1.025258,
    "CURRENT STOCKING STATUS": "INSUFFICIENT",
    "AVE. 30 DAYS": 21409.25,
    "TREND": 1.106461,
    "ON ORDER": 80000,
    "ON BACKORDER": 15512,
    "ROQ": 0,
    "APPROVED QTY": 0,
    "TOTAL": 114354
  },
  {
    "ITEM CODE": 16216,
    "PART NUMBER": 15058290,
    "DESCRIPTION": "SUPER WET BRAKE TRANSAXLE OIL WB102 ",
    "CAT": "LUV",
    "ON HAND": 3679,
    "SHELF LIFE MTHS": 3.576474,
    "CURRENT STOCKING STATUS": "SUFFICIENT",
    "AVE. 30 DAYS": 1028.666667,
    "TREND": 1.081011,
    "ON ORDER": 400,
    "ON BACKORDER": 2400,
    "ROQ": 0,
    "APPROVED QTY": 0,
    "TOTAL": 6479
  },
  {
    "ITEM CODE": 16218,
    "PART NUMBER": 15067197,
    "DESCRIPTION": "ULTRA DIESEL ENGINE OIL 15W/40 VDS-3 85102464",
    "CAT": "LUV",
    "ON HAND": 24252,
    "SHELF LIFE MTHS": 1.429433,
    "CURRENT STOCKING STATUS": "INSUFFICIENT",
    "AVE. 30 DAYS": 16966.166667,
    "TREND": 1.043832,
    "ON ORDER": 54000,
    "ON BACKORDER": 12932,
    "ROQ": 0,
    "APPROVED QTY": 0,
    "TOTAL": 91172
  },
  {
    "ITEM CODE": 16231,
    "PART NUMBER": 15143928,
    "DESCRIPTION": "AUTOMATIC TRANSMISSION FLUID AT102  L150G/A40F",
    "CAT": "LUV",
    "ON HAND": 3367,
    "SHELF LIFE MTHS": 4.780407,
    "CURRENT STOCKING STATUS": "SUFFICIENT",
    "AVE. 30 DAYS": 704.333333,
    "TREND": 1.45859,
    "ON ORDER": 0,
    "ON BACKORDER": 1920,
    "ROQ": 0,
    "APPROVED QTY": 0,
    "TOTAL": 5287
  },
  {
    "ITEM CODE": 61646,
    "PART NUMBER": 17470368,
    "DESCRIPTION": "VCS COOLANT (40:60 READY MIXED) 18L/PAIL YELLOW ",
    "CAT": "LUV",
    "ON HAND": 2952,
    "SHELF LIFE MTHS": 1.842792,
    "CURRENT STOCKING STATUS": "INSUFFICIENT",
    "AVE. 30 DAYS": 1601.916667,
    "TREND": 0.887583,
    "ON ORDER": 6462,
    "ON BACKORDER": 990,
    "ROQ": 0,
    "APPROVED QTY": 0,
    "TOTAL": 10404
  }
];

  export const replenishData2 = [
    {
      "PART NUMBER": 20460006,
      "DESCRIPTION": "SCREW",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 29250001061,
      "DESCRIPTION": "SLOTTED NUT",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 4016000113,
      "DESCRIPTION": "SPLIT PIN ",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 5,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 29250007591,
      "DESCRIPTION": "SLEEVE",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 4021000042,
      "DESCRIPTION": "BEARING GB9163-GE60ES-2RS",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.25,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 2,
      "WH REFERENCE": "WH12",
      "TREND": 2
    },
    {
      "PART NUMBER": 29250004031,
      "DESCRIPTION": "DOWN ARTICULATION PIN",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 4040000001,
      "DESCRIPTION": "WASHER ",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.416666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 19,
      "WH REFERENCE": "WH12",
      "TREND": 1.2
    },
    {
      "PART NUMBER": 29250004011,
      "DESCRIPTION": "FLANGE",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.25,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 1,
      "SHELF LIFE": 4,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 29250004021,
      "DESCRIPTION": "FLANGE",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 14636301,
      "DESCRIPTION": "I-ECU EC210B SN35001- 35001- 70001- 80001-  14390065",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 7,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 993275,
      "DESCRIPTION": "SIX POINT SCREW",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 20405724,
      "DESCRIPTION": "SCREW",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 20405654,
      "DESCRIPTION": "BOLT",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 20412733,
      "DESCRIPTION": "CORE PLUG",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 18,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": "B222100000494",
      "DESCRIPTION": "B222100000488 OIL FILTER ",
      "PRODUCT CATEGORY": "YPG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 5,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 21426987,
      "DESCRIPTION": "SENSOR 20513343",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 8,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 15048183,
      "DESCRIPTION": "OIL LEVEL SENSOR 11144494",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 21486081,
      "DESCRIPTION": "SEALING RING 20412568",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 15,
      "WH REFERENCE": "",
      "TREND": 1
    },
    {
      "PART NUMBER": 994849,
      "DESCRIPTION": "WASHER 10.5X22X2 976945",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 349,
      "WH REFERENCE": "WH12",
      "TREND": 2
    },
    {
      "PART NUMBER": 20460945,
      "DESCRIPTION": "TURBO CHARGER EC140B BL71PLUS",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 20964264,
      "DESCRIPTION": "SENSOR",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 20933362,
      "DESCRIPTION": "SEALING",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 4110000011082,
      "DESCRIPTION": "SEAL RING ",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 15,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 4110000011083,
      "DESCRIPTION": "SEAL RING",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 11,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 3030900232,
      "DESCRIPTION": "SHIM",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "AVERAGE (F2)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 3.58333333333333,
      "MAINTANING STOCK QTY": 7,
      "AVAILABLE": 13,
      "SHELF LIFE": 3.6279069767441894,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 57,
      "WH REFERENCE": "",
      "TREND": 1.26
    },
    {
      "PART NUMBER": 4015000013,
      "DESCRIPTION": "RIONG SNAP GB893.1-70-65MN",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 29050011341,
      "DESCRIPTION": "GASKET",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 12,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 3030900110,
      "DESCRIPTION": "SEAL",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 44,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 3030900111,
      "DESCRIPTION": "SEAL",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 28,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 4015000026,
      "DESCRIPTION": "WASHER",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 1.58333333333333,
      "MAINTANING STOCK QTY": 3,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 3,
      "APRD QTY": 3,
      "WH12": 91,
      "WH REFERENCE": "WH12",
      "TREND": 2
    },
    {
      "PART NUMBER": 4110001015025,
      "DESCRIPTION": "BELT, V RIBBED ALTERNATOR",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 70,
      "WH REFERENCE": "",
      "TREND": 1
    },
    {
      "PART NUMBER": 20550001,
      "DESCRIPTION": "INJECTION PUMP SD110",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 85102464,
      "DESCRIPTION": "ENGINE OIL VDS-3 SAE 15W-40 VTC 15067197",
      "PRODUCT CATEGORY": "LUV",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 6.83333333333333,
      "MAINTANING STOCK QTY": 14,
      "AVAILABLE": 2,
      "SHELF LIFE": 0.29268292682926844,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 12,
      "APRD QTY": 20,
      "WH12": 6216,
      "WH REFERENCE": "WH12",
      "TREND": 1.02
    },
    {
      "PART NUMBER": "99990-1Z03J",
      "DESCRIPTION": "GEAR OIL HD GL-4 SAE 80W-90 VTC/UD",
      "PRODUCT CATEGORY": "LUV",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 9.66666666666666,
      "MAINTANING STOCK QTY": 19,
      "AVAILABLE": 95,
      "SHELF LIFE": 9.827586206896557,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2220,
      "WH REFERENCE": "",
      "TREND": 0.28
    },
    {
      "PART NUMBER": "99990-1Z05J",
      "DESCRIPTION": "GEAR OIL HD GL-5 SAE 85W-140 VTC/UD",
      "PRODUCT CATEGORY": "LUV",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 19,
      "MAINTANING STOCK QTY": 38,
      "AVAILABLE": 32,
      "SHELF LIFE": 1.6842105263157894,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 6,
      "APRD QTY": 0,
      "WH12": 9852,
      "WH REFERENCE": "WH12",
      "TREND": 0.21
    },
    {
      "PART NUMBER": "8220-13340",
      "DESCRIPTION": "PIN_PARALLEL",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 4130000912,
      "DESCRIPTION": "BATTERY RELAY ",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 13,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 4120002264401,
      "DESCRIPTION": "SEALING KIT 3713CH ",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 4,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 4120002263401,
      "DESCRIPTION": "SEALING KIT 3713CH",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 9,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 4043000043,
      "DESCRIPTION": "SPACER ",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.666666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 16,
      "WH REFERENCE": "WH12",
      "TREND": 0
    },
    {
      "PART NUMBER": 15057758,
      "DESCRIPTION": "VALVE COVER GASKET 14521552",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 4130000060,
      "DESCRIPTION": "HAND BRAKE SWITCH ",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 4130000875,
      "DESCRIPTION": "STARTING SWITCH,   LG936L / LG956L",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 4,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 20828295,
      "DESCRIPTION": "SEALING RING",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 10,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 11712176,
      "DESCRIPTION": "SEALING RING",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 11,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 4013000203,
      "DESCRIPTION": "NUT, WHEEL HUB ",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 3.33333333333333,
      "MAINTANING STOCK QTY": 7,
      "AVAILABLE": 1,
      "SHELF LIFE": 0.3000000000000003,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 6,
      "APRD QTY": 6,
      "WH12": 0,
      "WH REFERENCE": "RFC",
      "TREND": 2
    },
    {
      "PART NUMBER": 14632954,
      "DESCRIPTION": "WIRE HARNESS",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 4030000789,
      "DESCRIPTION": "OIL SEAL",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 84035947,
      "DESCRIPTION": "WIPER BLADE 20826591",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 98,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 1547979,
      "DESCRIPTION": "SHIM",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 12,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": "8230-09800",
      "DESCRIPTION": "PLATE_ASSY SWAS",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 14530605,
      "DESCRIPTION": "HEADLIGHTS",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 29010013441,
      "DESCRIPTION": "CABLE SHAFT",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 14620906,
      "DESCRIPTION": "ROTATING BEACON",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 60013292,
      "DESCRIPTION": "FUEL FILTER",
      "PRODUCT CATEGORY": "YCG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.416666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 1,
      "SHELF LIFE": 2.400000000000004,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 16,
      "WH REFERENCE": "",
      "TREND": 0.4
    },
    {
      "PART NUMBER": "B222100000235",
      "DESCRIPTION": "HYDRAULIC SUCTION FILTER ",
      "PRODUCT CATEGORY": "YCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 1,
      "SHELF LIFE": 12.000000000000005,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": "A222100000119",
      "DESCRIPTION": "PILOT FILTER ELEMENT",
      "PRODUCT CATEGORY": "YCG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 22,
      "WH REFERENCE": "",
      "TREND": 1
    },
    {
      "PART NUMBER": 4030000101,
      "DESCRIPTION": "GREASE NIPPLE",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 29250000051,
      "DESCRIPTION": "BUSH, FRONT FRAME LG956L/LG958",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WH REFERENCE": "",
      "TREND": 1
    },
    {
      "PART NUMBER": 4015000168,
      "DESCRIPTION": "WASHER GB97.1-12EPZN-300HV LG958",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.833333333333333,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 2,
      "APRD QTY": 2,
      "WH12": 39,
      "WH REFERENCE": "WH12",
      "TREND": 2
    },
    {
      "PART NUMBER": 4130001685,
      "DESCRIPTION": "FORWARD LIGHTNING QZD-1 4130000797 LG936",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.5,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 26,
      "WH REFERENCE": "WH12",
      "TREND": 0.67
    },
    {
      "PART NUMBER": 7200002385,
      "DESCRIPTION": "FUEL FILTER LG936",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "AVERAGE (F2)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 5.08333333333333,
      "MAINTANING STOCK QTY": 10,
      "AVAILABLE": 5,
      "SHELF LIFE": 0.9836065573770497,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 5,
      "APRD QTY": 5,
      "WH12": 0,
      "WH REFERENCE": "RFC",
      "TREND": 1.11
    },
    {
      "PART NUMBER": 14619718,
      "DESCRIPTION": "ROLLER 14532433 ",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 14,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 4110000991027,
      "DESCRIPTION": "AIR FILTER SET",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.25,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 3,
      "SHELF LIFE": 12,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 28,
      "WH REFERENCE": "",
      "TREND": 1.33
    },
    {
      "PART NUMBER": 4110000054305,
      "DESCRIPTION": "OIL FILTER",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "AVERAGE (F2)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 1.75,
      "MAINTANING STOCK QTY": 4,
      "AVAILABLE": 1,
      "SHELF LIFE": 0.5714285714285714,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 3,
      "APRD QTY": 3,
      "WH12": 83,
      "WH REFERENCE": "WH12",
      "TREND": 1.24
    },
    {
      "PART NUMBER": 20405543,
      "DESCRIPTION": "SHIM",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 21928687,
      "DESCRIPTION": "DIPSTICK 20482782",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 24425970,
      "DESCRIPTION": "SHIM",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 30,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 4110000189006,
      "DESCRIPTION": "FUEL/WATER  FILTER ASSY  LG936L DEUTZ ENG",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "AVERAGE (F2)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 1.25,
      "MAINTANING STOCK QTY": 3,
      "AVAILABLE": 2,
      "SHELF LIFE": 1.6,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 126,
      "WH REFERENCE": "WH12",
      "TREND": 1.2
    },
    {
      "PART NUMBER": 4110000189005,
      "DESCRIPTION": "V-BELT, FAN / ALT.  ",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.5,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 39,
      "WH REFERENCE": "WH12",
      "TREND": 2
    },
    {
      "PART NUMBER": 4110000501,
      "DESCRIPTION": "BELT, A/C",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 14540778,
      "DESCRIPTION": "PISTON, HYDRAULIC PUMP  EC210B",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 14577801,
      "DESCRIPTION": "SEALING KIT, BOOM CYL.   EW145B/PRIME",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 8,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 14577802,
      "DESCRIPTION": "SEALING KIT, ARM CYL. EW145B/PRIME",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 9,
      "WH REFERENCE": "",
      "TREND": 1
    },
    {
      "PART NUMBER": 14589125,
      "DESCRIPTION": "SEALING KIT, BUCKET CYL.  EW145B",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 18,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 14630975,
      "DESCRIPTION": "WIPER ARM",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.416666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 7,
      "WH REFERENCE": "WH12",
      "TREND": 0.8
    },
    {
      "PART NUMBER": 14550189,
      "DESCRIPTION": "CYLINDER",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 20850506,
      "DESCRIPTION": "GASKET",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 5,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 14632032,
      "DESCRIPTION": "WIPER BLADE",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.666666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 1,
      "SHELF LIFE": 1.5000000000000016,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WH REFERENCE": "",
      "TREND": 1
    },
    {
      "PART NUMBER": 11103129,
      "DESCRIPTION": "DUST COVER",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 1,
      "SHELF LIFE": 12.000000000000005,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 4021000012,
      "DESCRIPTION": "BEARING ",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 40,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 4021000019,
      "DESCRIPTION": "BEARING ",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 52,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 4021000040,
      "DESCRIPTION": "THRUST BALL BEARING GB301-51111",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 20,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 4021000013,
      "DESCRIPTION": "BALL BEARING GB276-6016",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 996519,
      "DESCRIPTION": "BALL BEARING 993853",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 14616326,
      "DESCRIPTION": "PIN, BUCKET EC210B 1172-06910, 14512676 14543130 ",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 5,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 996518,
      "DESCRIPTION": "BALL BEARING EC210B 993852",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 21615194,
      "DESCRIPTION": "DISC",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 11,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 29250004041,
      "DESCRIPTION": "UP ARTICULATION PIN",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 14378640,
      "DESCRIPTION": "DIRECTION INDICATOR",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 11,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": "487169Z00A",
      "DESCRIPTION": "STEERING FILTER",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.25,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 1,
      "SHELF LIFE": 4,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 38,
      "WH REFERENCE": "",
      "TREND": 0.67
    },
    {
      "PART NUMBER": "4130000457A",
      "DESCRIPTION": "CONDENSER A/C ",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 9,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 29250000291,
      "DESCRIPTION": "OIL BOLT 29270008001",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 6,
      "WH REFERENCE": "WH12",
      "TREND": 2
    },
    {
      "PART NUMBER": 21743197,
      "DESCRIPTION": "AIR CLEANER 21064484  147719Z00A",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.25,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 1,
      "SHELF LIFE": 4,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 1.33
    },
    {
      "PART NUMBER": 3901000049,
      "DESCRIPTION": "STEP SELECTOR ",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 4014000024,
      "DESCRIPTION": "SCREW 29270008001",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 26,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 29250000311,
      "DESCRIPTION": "CLAMP 29270008001",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 10,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 4015000035,
      "DESCRIPTION": "WASHER 29270008001",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 35,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 4030000065,
      "DESCRIPTION": "LUBRICATING NIPPLE 29270008001 ",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.666666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 1,
      "SHELF LIFE": 1.5000000000000016,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 5,
      "WH REFERENCE": "",
      "TREND": 0.5
    },
    {
      "PART NUMBER": 4120000506,
      "DESCRIPTION": "SPRING BRAKE CYLINDER  LG936  LG956L",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 1,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 9,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 20799587,
      "DESCRIPTION": "BOLT",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 17,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 21611489,
      "DESCRIPTION": "GASKET L60C/L70C/L90E/BL71/EC210B 20459202",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 54,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 21349444,
      "DESCRIPTION": "SWIVEL NIPPLE",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 1,
      "SHELF LIFE": 3.000000000000003,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 25,
      "WH REFERENCE": "",
      "TREND": 0.5
    },
    {
      "PART NUMBER": 16631876,
      "DESCRIPTION": "AIR FILTER, PRIMARY 14519261",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 1,
      "SHELF LIFE": 12.000000000000005,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 26,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 20859647,
      "DESCRIPTION": "SEAL",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 4,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 20798699,
      "DESCRIPTION": "INLET VALVE 21896409 ",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 12,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 4110000556128,
      "DESCRIPTION": "SEALING WASHER ",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 11,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 990853,
      "DESCRIPTION": "O-RING",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 17,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 14616327,
      "DESCRIPTION": "PIN, LINK TO BUCKET  EC210B 14544079",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 11110702,
      "DESCRIPTION": "FILTER HOUSING, FWS  W/ HEATER 11110708",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 1,
      "SHELF LIFE": 3.000000000000003,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 4120000553401,
      "DESCRIPTION": "SEALING KIT, ST CYL.  ",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 12,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": "301009Z00B",
      "DESCRIPTION": "CLUTCH DISC",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 8,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": "146769Z01C",
      "DESCRIPTION": "SEALING RING, OIL PAN",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.25,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 143,
      "WH REFERENCE": "WH12",
      "TREND": 0.67
    },
    {
      "PART NUMBER": "A240700000508",
      "DESCRIPTION": "FLASHER RELAY",
      "PRODUCT CATEGORY": "YPG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 14601888,
      "DESCRIPTION": "HOSE",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 22023120,
      "DESCRIPTION": "OIL FILTER KIT",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 10503759,
      "DESCRIPTION": "AUXILIARY HOIST STEEL WIRE QY50C.2-2A",
      "PRODUCT CATEGORY": "YCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 60045284,
      "DESCRIPTION": "CLUTCH BOOSTER SY5250 60198054",
      "PRODUCT CATEGORY": "YPG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 14529014,
      "DESCRIPTION": "FLASHER",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 5,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 22089205,
      "DESCRIPTION": "BELT TENSIONER 20554253 22088967 ",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 8,
      "WH REFERENCE": "",
      "TREND": 1
    },
    {
      "PART NUMBER": 21971559,
      "DESCRIPTION": "COIL 21239915 21680291",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 1,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": "7117-30150",
      "DESCRIPTION": "SHIM",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 20798533,
      "DESCRIPTION": "EXHAUST VALVE",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 12,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": "99990-1Z01J",
      "DESCRIPTION": "UD DIESEL ENGINE OIL MEGA MULTI 3 SAE 15W-40",
      "PRODUCT CATEGORY": "LUV",
      "ITEM MOVEMENT BRANCH": "AVERAGE (F2)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 31.8333333333333,
      "MAINTANING STOCK QTY": 64,
      "AVAILABLE": 60,
      "SHELF LIFE": 1.8848167539267036,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 4,
      "APRD QTY": 0,
      "WH12": 2760,
      "WH REFERENCE": "WH12",
      "TREND": 1.39
    },
    {
      "PART NUMBER": 4011000186,
      "DESCRIPTION": "BOLT ",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 22243151,
      "DESCRIPTION": "CABLE HARNESS 22068267",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 1,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": "A222100000166",
      "DESCRIPTION": "AIR FILTER KIT  ",
      "PRODUCT CATEGORY": "YPG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 17,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 4110000509232,
      "DESCRIPTION": "FUEL FILTER",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 120,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 4110001169001,
      "DESCRIPTION": "AIR FILTER ( INNER & OUTER)",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 38,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 4120004036003,
      "DESCRIPTION": "FILTER INSERT",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.666666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 1,
      "SHELF LIFE": 1.5000000000000016,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 45,
      "WH REFERENCE": "",
      "TREND": 1
    },
    {
      "PART NUMBER": 4015000024,
      "DESCRIPTION": "RING SNAP ",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 26,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 60101256,
      "DESCRIPTION": "HYDRAULIC RETURN FILTER",
      "PRODUCT CATEGORY": "YCG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.25,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 4,
      "WH REFERENCE": "WH12",
      "TREND": 1.33
    },
    {
      "PART NUMBER": 60200364,
      "DESCRIPTION": "HYDRAULIC SUCTION FILTER * SCC750C/SCC550E",
      "PRODUCT CATEGORY": "YCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 9,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 29170036972,
      "DESCRIPTION": "BUCKET TEETH  LG936  29170036971",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 21302562,
      "DESCRIPTION": "HUB CASING",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 14611902,
      "DESCRIPTION": "SCREW 11988958",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 14528724,
      "DESCRIPTION": "PIN",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 6,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": "7117-30360",
      "DESCRIPTION": "PIN_NO1",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 5,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 21103569,
      "DESCRIPTION": "RUBBER MOULDING",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 21055401,
      "DESCRIPTION": "TAPPET",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 14589153,
      "DESCRIPTION": "SEALING KIT",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 14385098,
      "DESCRIPTION": "VALVE",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 15173282,
      "DESCRIPTION": "SEALING KIT",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 4130000622,
      "DESCRIPTION": "SAFETY",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 5,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 4015000030,
      "DESCRIPTION": "WASHER, LOCK ",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 3.33333333333333,
      "MAINTANING STOCK QTY": 7,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 7,
      "APRD QTY": 7,
      "WH12": 0,
      "WH REFERENCE": "RFC",
      "TREND": 2
    },
    {
      "PART NUMBER": 14016673,
      "DESCRIPTION": "STRIP CLAMP 4881438",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 30,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 14511215,
      "DESCRIPTION": "O-RING",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 69,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 14513653,
      "DESCRIPTION": "DISPLAY MODULE",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 14514378,
      "DESCRIPTION": "WIRE HARNESS",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 22,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 14523552,
      "DESCRIPTION": "TOOTH (VTS) 30GPE EC210B/EC240B 14523946",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 4.58333333333333,
      "MAINTANING STOCK QTY": 9,
      "AVAILABLE": 5,
      "SHELF LIFE": 1.0909090909090917,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 4,
      "APRD QTY": 0,
      "WH12": 716,
      "WH REFERENCE": "WH12",
      "TREND": 0.91
    },
    {
      "PART NUMBER": 14526220,
      "DESCRIPTION": "REPAIR KIT, TRAVEL GEARBOX EC210B",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 14528387,
      "DESCRIPTION": "OIL FILTER EC55B",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 1,
      "SHELF LIFE": 12.000000000000005,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 6,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 14529995,
      "DESCRIPTION": "14739511 HANDLE",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 14545883,
      "DESCRIPTION": "COUPLING 1-1/4\"",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 4,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 14547395,
      "DESCRIPTION": "PISTON BUSHING",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 61,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 14576774,
      "DESCRIPTION": "BLOWER MOTOR, A/C UNIT  14514331 ",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 14589155,
      "DESCRIPTION": "SEALING KIT, BOOM CYL.   EC140B/PRIME",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.25,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 2,
      "WH REFERENCE": "WH12",
      "TREND": 1.33
    },
    {
      "PART NUMBER": 15082742,
      "DESCRIPTION": "COMPRESSOR EC290B 11412632",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 32,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 20411420,
      "DESCRIPTION": "V-BELT_FAN FM9/FM10/EC210B",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 13,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 20412308,
      "DESCRIPTION": "HEX NUT L120F",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.666666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 58,
      "WH REFERENCE": "WH12",
      "TREND": 0
    },
    {
      "PART NUMBER": 20412314,
      "DESCRIPTION": "STUD",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.666666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 21,
      "WH REFERENCE": "WH12",
      "TREND": 0
    },
    {
      "PART NUMBER": 20412589,
      "DESCRIPTION": "FILLER CAP ( ENG. D7D EAE2) G710B EC290B",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 9,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 20450707,
      "DESCRIPTION": "TACHOMETER EC210B/G700B/G930/L60E",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.25,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 7,
      "WH REFERENCE": "WH12",
      "TREND": 0
    },
    {
      "PART NUMBER": 20450736,
      "DESCRIPTION": "THERMOSTAT BL71/G710B/G930/EC210B",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.25,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 1,
      "SHELF LIFE": 4,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 18,
      "WH REFERENCE": "",
      "TREND": 1.33
    },
    {
      "PART NUMBER": 20459016,
      "DESCRIPTION": "SENSOR EC210B/240B/290B/G700B",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 20459203,
      "DESCRIPTION": "GASKET L60C/L70C/L90E/BL71/EC210B",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 14,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 20459213,
      "DESCRIPTION": "SEALING RING EC210B",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 4,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 20459961,
      "DESCRIPTION": "SEALING RING, WATER PUMP EC210B/G700B/G930/SD110",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 20502536,
      "DESCRIPTION": "SEALING RING  EC210B",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 20775463,
      "DESCRIPTION": "PULLEY, ALTERNATOR EC210B",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 19,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 20798827,
      "DESCRIPTION": "O-RING",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 1.66666666666666,
      "MAINTANING STOCK QTY": 3,
      "AVAILABLE": 4,
      "SHELF LIFE": 2.4000000000000097,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 20805349,
      "DESCRIPTION": "54315408 FUEL FILTER (X10)",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "FAST (F1)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 8.33333333333333,
      "MAINTANING STOCK QTY": 17,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 17,
      "APRD QTY": 17,
      "WH12": 0,
      "WH REFERENCE": "RFC",
      "TREND": 0.94
    },
    {
      "PART NUMBER": 20840801,
      "DESCRIPTION": "GASKET",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 23,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 20850096,
      "DESCRIPTION": "BIG END BEARING KIT  D6EEAE2/3 ",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 7,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 24425916,
      "DESCRIPTION": "21178542 REPAIR KIT",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 4881440,
      "DESCRIPTION": "CABLE TIE A30 4881439 46674149",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 48,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": "8230-09710",
      "DESCRIPTION": "SPRING_CYLINDER",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 36,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 914167,
      "DESCRIPTION": "NIPPLE EC210B/EC460B/G930",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 1,
      "SHELF LIFE": 3.000000000000003,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 708,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 925071,
      "DESCRIPTION": "O-RING",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 1,
      "SHELF LIFE": 12.000000000000005,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 12,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 943475,
      "DESCRIPTION": "HOSE CLAMP",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 19,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 949874,
      "DESCRIPTION": "FLANGE LOCK NUT",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 11,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 955921,
      "DESCRIPTION": "WASHER SPRING 9213-08000",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.5,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 29,
      "WH REFERENCE": "WH12",
      "TREND": 0
    },
    {
      "PART NUMBER": 955926,
      "DESCRIPTION": "SPRING WASHER (16MM) 01602-01648",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 1571,
      "WH REFERENCE": "WH12",
      "TREND": 2
    },
    {
      "PART NUMBER": 960254,
      "DESCRIPTION": "O-RING EC210B/240B/290B 9511-22190 ",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 110,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 960265,
      "DESCRIPTION": "O-RING 14573761",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 968007,
      "DESCRIPTION": "O-RING",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 19,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 968564,
      "DESCRIPTION": "O-RING 9511-12018",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 42,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 969474,
      "DESCRIPTION": "V-BELT, FUEL PUMP EC240B/EC290B SN1000-~17000",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 55,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 977525,
      "DESCRIPTION": "V-BELT, A/C EC210B/L120E/G930",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 2,
      "SHELF LIFE": 12.000000000000048,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 1
    },
    {
      "PART NUMBER": 978710,
      "DESCRIPTION": "V-BELT, ALT L60,70,L120E/F, G930",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 46,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 978722,
      "DESCRIPTION": "V-BELT, FAN EC210B/L120E/G930 15078709 ",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 3,
      "SHELF LIFE": 18.000000000000075,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 5,
      "WH REFERENCE": "",
      "TREND": 1
    },
    {
      "PART NUMBER": 978999,
      "DESCRIPTION": "BLADE FUSE 20A 11039328 A30C/EC210B-P/EC460B/C/EC700B/G930/L150F/FM400",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.416666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 9,
      "WH REFERENCE": "WH12",
      "TREND": 0
    },
    {
      "PART NUMBER": 990601,
      "DESCRIPTION": "O-RING",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 15,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 990737,
      "DESCRIPTION": "O-RING",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 5,
      "SHELF LIFE": 15.000000000000016,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 16,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 990851,
      "DESCRIPTION": "O-RING",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 34,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": "1030-61460",
      "DESCRIPTION": "ELEMENT, PILOT HYDRAULIC_",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "AVERAGE (F2)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 1.25,
      "MAINTANING STOCK QTY": 3,
      "AVAILABLE": 6,
      "SHELF LIFE": 4.8,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 863,
      "WH REFERENCE": "",
      "TREND": 1.33
    },
    {
      "PART NUMBER": "1030-61680",
      "DESCRIPTION": "ELEMENT, HYDRAULIC EW145B",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "AVERAGE (F2)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 1,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 2,
      "SHELF LIFE": 2,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 4,
      "WH REFERENCE": "",
      "TREND": 1
    },
    {
      "PART NUMBER": 11110283,
      "DESCRIPTION": "AIR FILTER_PRI EC140B / EW145B",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "AVERAGE (F2)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 1.41666666666666,
      "MAINTANING STOCK QTY": 3,
      "AVAILABLE": 2,
      "SHELF LIFE": 1.4117647058823595,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 675,
      "WH REFERENCE": "WH12",
      "TREND": 1.06
    },
    {
      "PART NUMBER": 11110284,
      "DESCRIPTION": "AIR FILTER  SEC",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.5,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 1,
      "SHELF LIFE": 2,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 220,
      "WH REFERENCE": "",
      "TREND": 1
    },
    {
      "PART NUMBER": 11110670,
      "DESCRIPTION": "FUEL WATER SEPARATOR EC210B/290B/L90E/BL71/G710B",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 11110737,
      "DESCRIPTION": "54864253 BOWL, FWS W/O HEATER  EC210B/290B/G930/L120F 11110674",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 11110738,
      "DESCRIPTION": "BOWL, FWS W/ HEATER EC210B/EC480D",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 11172896,
      "DESCRIPTION": "WATER SEPARATOR (BASIC MACHINE, LOW SERIES)",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": "1122-00940",
      "DESCRIPTION": "FUSE HOLDER",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": "1141-00010",
      "DESCRIPTION": "STRAINER, HYD TANK",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 11708550,
      "DESCRIPTION": "OIL FILTER EC140B",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 1,
      "SHELF LIFE": 3.000000000000003,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 34,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 11708551,
      "DESCRIPTION": "OIL FILTER",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 51,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 11709635,
      "DESCRIPTION": "V-BELT, FAN DRIVE",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "RFC",
      "TREND": 0.5
    },
    {
      "PART NUMBER": "1171-00171",
      "DESCRIPTION": "SIDE BLADE RH EC210B",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 1
    },
    {
      "PART NUMBER": "1171-00181",
      "DESCRIPTION": "SIDE BLADE LH EC210B",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 1
    },
    {
      "PART NUMBER": "1172-01071",
      "DESCRIPTION": "COVER",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 11988962,
      "DESCRIPTION": "FUEL FILTER",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 23,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 13947622,
      "DESCRIPTION": "PLANE GASKET",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 77,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 13947628,
      "DESCRIPTION": "PLANE GASKET EC210B/EC290B",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 9,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 14382341,
      "DESCRIPTION": "CONTROL UNIT EC210B",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 14500233,
      "DESCRIPTION": "ELEMENT, BREATHER",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 49,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 14503269,
      "DESCRIPTION": "FILTER, CAB",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "AVERAGE (F2)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 2.08333333333333,
      "MAINTANING STOCK QTY": 4,
      "AVAILABLE": 5,
      "SHELF LIFE": 2.400000000000004,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 101,
      "WH REFERENCE": "",
      "TREND": 1.04
    },
    {
      "PART NUMBER": 14505515,
      "DESCRIPTION": "SENSOR EC210B/290B/360B/460B/700B",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 6,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 14506407,
      "DESCRIPTION": "SEAL KIT",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 8,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 14506844,
      "DESCRIPTION": "GLASS_FRONT LOWER EC210B/EC460B",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 4,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 14506889,
      "DESCRIPTION": "SEAL KIT_CONTROL VALVE EC210B",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 14507705,
      "DESCRIPTION": "SEAL, JOINT",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 6,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 33,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 14508662,
      "DESCRIPTION": "CABLE HARNESS",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 14509331,
      "DESCRIPTION": "VALVE, EXPANSION A/C",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 22,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 14509379,
      "DESCRIPTION": "14776946  ELEMENT, HYD RETURN_",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "AVERAGE (F2)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 1.08333333333333,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 9,
      "SHELF LIFE": 8.307692307692333,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 332,
      "WH REFERENCE": "",
      "TREND": 1.38
    },
    {
      "PART NUMBER": 14511594,
      "DESCRIPTION": "BRG_KIT-100-GUMZ8",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.416666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "RFC",
      "TREND": 0.4
    },
    {
      "PART NUMBER": 14514937,
      "DESCRIPTION": "SEAL KIT_T/JOINT EC210B 14500512",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 10,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 14515828,
      "DESCRIPTION": "ELEMENT COUPLING EC210B",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 13,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 14518349,
      "DESCRIPTION": "V-ECU EC210B/EC290B/EC700B",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 14520284,
      "DESCRIPTION": "WEATHERSTRIP L120F",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 4,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 14522962,
      "DESCRIPTION": "ADAPTER (VTS) EA30BL40 EC210B/240B",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 5,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 14523264,
      "DESCRIPTION": "FILTER_PILOT HOUSING EC210B 1030-61400 ",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 14523946,
      "DESCRIPTION": "TOOTH 30AMRE EC210B/EC240B 14523552",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.416666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 5,
      "SHELF LIFE": 12.000000000000018,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 478,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 14524170,
      "DESCRIPTION": "CARTRIDGE, HYDRAULIC_",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "AVERAGE (F2)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 1.41666666666666,
      "MAINTANING STOCK QTY": 3,
      "AVAILABLE": 9,
      "SHELF LIFE": 6.352941176470618,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 462,
      "WH REFERENCE": "",
      "TREND": 1.41
    },
    {
      "PART NUMBER": 14524449,
      "DESCRIPTION": "GASKET KIT, HYD TANK EC210B",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 12,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 14526158,
      "DESCRIPTION": "STARTER SWITCH EC210B/EC460B 1123-02820 ",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 14527267,
      "DESCRIPTION": "REPAIR SET, SOLENOID EC210B/EC460B/EC700B/BL71 14512987  ",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.25,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 11,
      "SHELF LIFE": 44,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 14527864,
      "DESCRIPTION": "PIN, HOR (KTS) EW145B",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "AVERAGE (F2)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 3.83333333333333,
      "MAINTANING STOCK QTY": 8,
      "AVAILABLE": 8,
      "SHELF LIFE": 2.086956521739132,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 202,
      "WH REFERENCE": "",
      "TREND": 0.65
    },
    {
      "PART NUMBER": 14529050,
      "DESCRIPTION": "O-RING",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 68,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 14529051,
      "DESCRIPTION": "O-RING, A/C",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 60,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 14529052,
      "DESCRIPTION": "O-RING",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 174,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 14529132,
      "DESCRIPTION": "DIODE W/ HARNESS EC460B",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 5,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 14529178,
      "DESCRIPTION": "KEY_PLASTIC EC210B-P/EC460B",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 7,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 14530365,
      "DESCRIPTION": "BUSHING, REG EC210BLC",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 1
    },
    {
      "PART NUMBER": 14530367,
      "DESCRIPTION": "14723460 SEAL, TRACK EC210B",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 14531109,
      "DESCRIPTION": "SEAL",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 7,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 14531426,
      "DESCRIPTION": "BALL BEARING 8230-21590",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 14531866,
      "DESCRIPTION": "STRAINER ASSY EC210B/EC240B/EW170",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 47,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 14532260,
      "DESCRIPTION": "STRAINER",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.916666666666666,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 2,
      "SHELF LIFE": 2.1818181818181834,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 7,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 14533862,
      "DESCRIPTION": "SPACER, MASTER PIN EC210BLC",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 5,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 14535425,
      "DESCRIPTION": "BEARING",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 14540004,
      "DESCRIPTION": "SHIM, LINKS TO BUCKET  EC210B 1070-61272",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 24,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 14540725,
      "DESCRIPTION": "PIN A20-30, HOR. (KTS) 1070-61320  EC210B/EW145B/EW170 1070-61320",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 14542152,
      "DESCRIPTION": "SWITCH EC210B",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 14544828,
      "DESCRIPTION": "PUMP EC210BLC",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 14544984,
      "DESCRIPTION": "BUSHING, BUCKET  EC210B / EC290B",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 14547826,
      "DESCRIPTION": "SHIM",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 1.5,
      "MAINTANING STOCK QTY": 3,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 3,
      "APRD QTY": 3,
      "WH12": 16,
      "WH REFERENCE": "WH12",
      "TREND": 1.33
    },
    {
      "PART NUMBER": 14551131,
      "DESCRIPTION": "GLASS, WINDSHIELD  EC210B 14506807",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 7,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 14551534,
      "DESCRIPTION": "BALL BRG EC210B",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 14552332,
      "DESCRIPTION": "SEAL KIT_SWING MOTOR EC210B/EC460B",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 26,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 14560201,
      "DESCRIPTION": "SEAL",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.5,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 108,
      "WH REFERENCE": "WH12",
      "TREND": 2
    },
    {
      "PART NUMBER": 14562193,
      "DESCRIPTION": "SWITCH, PRESSURE EC460B 14529294",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 1,
      "SHELF LIFE": 12.000000000000005,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 15,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 14577815,
      "DESCRIPTION": "SEAL KIT, REGULATOR EC210B",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 9,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 14589129,
      "DESCRIPTION": "SEAL KIT, BOOM CYL.  EC210B 14515051 ",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 1.08333333333333,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 1,
      "SHELF LIFE": 0.923076923076926,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 35,
      "WH REFERENCE": "WH12",
      "TREND": 1.54
    },
    {
      "PART NUMBER": 14589131,
      "DESCRIPTION": "SEALING KIT, DIPPER ARM CYL. EC210B",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.5,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 1,
      "WH REFERENCE": "WH12",
      "TREND": 1.67
    },
    {
      "PART NUMBER": 14589132,
      "DESCRIPTION": "SEALING KIT, BUCKET CYL.  EC210B / EC140BP DIPPER ARM CYL.",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 9,
      "WH REFERENCE": "WH12",
      "TREND": 2
    },
    {
      "PART NUMBER": 14589138,
      "DESCRIPTION": "SEALING KIT, DIPPER ARM CYL EC290B",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 14596399,
      "DESCRIPTION": "FILTER, BREATHER HYD EC210B_",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "AVERAGE (F2)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 2.58333333333333,
      "MAINTANING STOCK QTY": 5,
      "AVAILABLE": 3,
      "SHELF LIFE": 1.1612903225806468,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 2,
      "APRD QTY": 2,
      "WH12": 286,
      "WH REFERENCE": "WH12",
      "TREND": 1.23
    },
    {
      "PART NUMBER": 14600771,
      "DESCRIPTION": "PISTON, HYD PUMP EC210B",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 14880552,
      "DESCRIPTION": "BOLT, T/SHOE EC210B  14880553",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 31,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 318,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 14880553,
      "DESCRIPTION": "BOLT, T/SHOE EC210BLC 14880552",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 30.6666666666666,
      "MAINTANING STOCK QTY": 61,
      "AVAILABLE": 61,
      "SHELF LIFE": 1.989130434782613,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1014,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 14880692,
      "DESCRIPTION": "RING_CORD EC210B",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.833333333333333,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 2,
      "APRD QTY": 2,
      "WH12": 23,
      "WH REFERENCE": "WH12",
      "TREND": 2
    },
    {
      "PART NUMBER": 14881185,
      "DESCRIPTION": "RING CORD",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.75,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 2,
      "APRD QTY": 2,
      "WH12": 127,
      "WH REFERENCE": "WH12",
      "TREND": 1.11
    },
    {
      "PART NUMBER": 14881234,
      "DESCRIPTION": "CLAMP",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 14881276,
      "DESCRIPTION": "V-BELT, A/C EC210B/EC290B SN17001~19730/L120F / EW145B",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "AVERAGE (F2)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 1.25,
      "MAINTANING STOCK QTY": 3,
      "AVAILABLE": 1,
      "SHELF LIFE": 0.8,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 2,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "RFC",
      "TREND": 0.93
    },
    {
      "PART NUMBER": 15078671,
      "DESCRIPTION": "V-BELT, FAN DRV EC210B SN35001/50001/70001",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.25,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 2,
      "SHELF LIFE": 8,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 74,
      "WH REFERENCE": "",
      "TREND": 1.33
    },
    {
      "PART NUMBER": 20405561,
      "DESCRIPTION": "PISTON",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 8,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 20405599,
      "DESCRIPTION": "SEALING RING EC210B",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 12,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 20405907,
      "DESCRIPTION": "SEALING RING",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 20,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 20450749,
      "DESCRIPTION": "BALL BEARING",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 20450750,
      "DESCRIPTION": "BALL BEARING",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 20450754,
      "DESCRIPTION": "SCREW EC210B",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 22,
      "WH REFERENCE": "",
      "TREND": 1
    },
    {
      "PART NUMBER": 20459025,
      "DESCRIPTION": "RETAINING RING EC210B",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WH REFERENCE": "",
      "TREND": 1
    },
    {
      "PART NUMBER": 20459191,
      "DESCRIPTION": "PLUG EC210B",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 8,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 20459192,
      "DESCRIPTION": "GASKET EC210B",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 6,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 20459193,
      "DESCRIPTION": "SEALING RING EC210B",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 96,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 20459212,
      "DESCRIPTION": "GASKET EC210B/L60/70/90E",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 34,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 20459214,
      "DESCRIPTION": "WASHER EC210B",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 1,
      "SHELF LIFE": 6.000000000000024,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 9,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 20459215,
      "DESCRIPTION": "WASHER EC210B/EC460B",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 2,
      "SHELF LIFE": 6.000000000000006,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 8,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 20459218,
      "DESCRIPTION": "GASKET EC210B",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 72,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 20459263,
      "DESCRIPTION": "SCREW EC210B",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 12,
      "WH REFERENCE": "WH12",
      "TREND": 0
    },
    {
      "PART NUMBER": 20459863,
      "DESCRIPTION": "PULLEY",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 1
    },
    {
      "PART NUMBER": 20798186,
      "DESCRIPTION": "CYL HEAD GASKET, 1.37MM 2 BORES  EC210B",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 11,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 20798683,
      "DESCRIPTION": "FUEL INJECTOR ENG: D6D_EGE3 EC210B  REMAN 9020798683",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 3,
      "SHELF LIFE": 36.000000000000014,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 45,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 20841035,
      "DESCRIPTION": "GASKET EC210B",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 8,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 20973777,
      "DESCRIPTION": "SERVICE KIT, FUEL LINES EC210B/EC290B/G930",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 1,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 4,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 21009699,
      "DESCRIPTION": "SHAFT",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 21009704,
      "DESCRIPTION": "BRG BRACKET",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 21141943,
      "DESCRIPTION": "SENSOR EC210B/EC290B/G700B/L60E",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 1,
      "SHELF LIFE": 6.000000000000024,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 5,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 21142728,
      "DESCRIPTION": "ADAPTER",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 1,
      "SHELF LIFE": 6.000000000000024,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 21247955,
      "DESCRIPTION": "23657265 COOLANT PUMP (MUST BE SUPPLIED W/ D6E PULLEY 21795915 / D4E PULLEY 20798526 21247955)",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 21291011,
      "DESCRIPTION": "SWITCH_PRESSURE EC210B/EC290B/G930 20585158 20450687",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 2,
      "SHELF LIFE": 12.000000000000048,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 21584586,
      "DESCRIPTION": "FUEL PUMP EC210B/EC290B 21211875 20450894 21139287",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": "7123-52560",
      "DESCRIPTION": "WHEEL NUT",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 5,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 80,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": "7273-10751",
      "DESCRIPTION": "CAP, MCV  EC210B",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 47,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": "8230-21520",
      "DESCRIPTION": "PROTECTING PLATE",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": "8230-21670",
      "DESCRIPTION": "PLATE FRICTION",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": "8230-24860",
      "DESCRIPTION": "RING (8048-00010 SEAL KIT)",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 101,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": "8230-36840",
      "DESCRIPTION": "SEAL KIT, RCV  EC210B/EC460B/EC700B",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 7,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": "9111-31600",
      "DESCRIPTION": "NUT_HEX HD",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 16,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 955927,
      "DESCRIPTION": "SPRING WASHER 01602-01854",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 3,
      "MAINTANING STOCK QTY": 6,
      "AVAILABLE": 1,
      "SHELF LIFE": 0.3333333333333333,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 5,
      "APRD QTY": 0,
      "WH12": 387,
      "WH REFERENCE": "WH12",
      "TREND": 0.83
    },
    {
      "PART NUMBER": "9564-20750",
      "DESCRIPTION": "SEAL (8048-00010 SEAL KIT)",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 81,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 960259,
      "DESCRIPTION": "O-RING EC460B 9511-22230",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.5,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 73,
      "WH REFERENCE": "WH12",
      "TREND": 0
    },
    {
      "PART NUMBER": 983303,
      "DESCRIPTION": "HEXAGON NUT EC210B",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 55,
      "WH REFERENCE": "WH12",
      "TREND": 0
    },
    {
      "PART NUMBER": 983502,
      "DESCRIPTION": "O-RING EC210B/EC460C 9511-12011",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 211,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 990557,
      "DESCRIPTION": "O-RING EC210B",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.5,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 1,
      "SHELF LIFE": 2,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 297,
      "WH REFERENCE": "",
      "TREND": 1.33
    },
    {
      "PART NUMBER": 990645,
      "DESCRIPTION": "O-RING",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 21,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 990660,
      "DESCRIPTION": "O-RING",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 990740,
      "DESCRIPTION": "O-RING EC210B",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 315,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 992047,
      "DESCRIPTION": "60110287 PLAIN WASHER M20.5 X 34 X 3",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 0,
      "WH REFERENCE": "RFC",
      "TREND": 2
    },
    {
      "PART NUMBER": 1623726,
      "DESCRIPTION": "LENS WHITE FM9/FM10",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 11,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 1652986,
      "DESCRIPTION": "BALL BEARING FM10",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 1677352,
      "DESCRIPTION": "ADJUSTING WASHER FM10/FM340",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.5,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 18,
      "WH REFERENCE": "WH12",
      "TREND": 2
    },
    {
      "PART NUMBER": 1677355,
      "DESCRIPTION": "ADJUSTING WASHER FM10/FM340",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 11,
      "WH REFERENCE": "WH12",
      "TREND": 1.5
    },
    {
      "PART NUMBER": 1677356,
      "DESCRIPTION": "ADJUSTING WASHER FM340/FM10",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.5,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 13,
      "WH REFERENCE": "WH12",
      "TREND": 1
    },
    {
      "PART NUMBER": 1677362,
      "DESCRIPTION": "ADJUSTING WASHER FM10/FM340",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 1,
      "SHELF LIFE": 3.000000000000003,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 35,
      "WH REFERENCE": "",
      "TREND": 1.5
    },
    {
      "PART NUMBER": 1677364,
      "DESCRIPTION": "ADJUSTING WASHER FM10/FM340",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.5,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 1,
      "SHELF LIFE": 2,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 28,
      "WH REFERENCE": "",
      "TREND": 1
    },
    {
      "PART NUMBER": 1677365,
      "DESCRIPTION": "ADJUSTING WASHER FM10/FM340",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 1,
      "SHELF LIFE": 3.000000000000003,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 39,
      "WH REFERENCE": "",
      "TREND": 1.5
    },
    {
      "PART NUMBER": 1677366,
      "DESCRIPTION": "ADJUSTING WASHER FM340",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 1,
      "SHELF LIFE": 3.000000000000003,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 33,
      "WH REFERENCE": "",
      "TREND": 1.5
    },
    {
      "PART NUMBER": 1677367,
      "DESCRIPTION": "ADJUSTING WASHER",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 1696446,
      "DESCRIPTION": "THRUST PIN",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 115,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 20399043,
      "DESCRIPTION": "DOWEL",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 5,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 20428438,
      "DESCRIPTION": "PIN",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 22127385,
      "DESCRIPTION": "SWITCH, 4 POLE START XP375WCU",
      "PRODUCT CATEGORY": "DOP",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 10,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 22436331,
      "DESCRIPTION": "SEPARATOR ELEMENT AS-PLUS",
      "PRODUCT CATEGORY": "DOP",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 22496905,
      "DESCRIPTION": "OIL FILTER AS-PLUS",
      "PRODUCT CATEGORY": "DOP",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 6,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 22568091,
      "DESCRIPTION": "SOLENOID ASSY",
      "PRODUCT CATEGORY": "DOP",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 23279078,
      "DESCRIPTION": "ELEMENT, OIL FILTER W/ BYPASS M27X2 THREAD",
      "PRODUCT CATEGORY": "DOP",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 35393685,
      "DESCRIPTION": "AIR PRIMARY P135/185WIR",
      "PRODUCT CATEGORY": "DOP",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 16,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 36845493,
      "DESCRIPTION": "FUEL TANK FILTER L5K 36845501",
      "PRODUCT CATEGORY": "DOP",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 2,
      "SHELF LIFE": 24.00000000000001,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 15058186,
      "DESCRIPTION": "SUPER HYDRAULIC OIL  ISO VG68",
      "PRODUCT CATEGORY": "LUV",
      "ITEM MOVEMENT BRANCH": "FAST (F1)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 740.25,
      "MAINTANING STOCK QTY": 1481,
      "AVAILABLE": 100,
      "SHELF LIFE": 0.13508949679162446,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1381,
      "APRD QTY": 1380,
      "WH12": 4828,
      "WH REFERENCE": "WH12",
      "TREND": 0.96
    },
    {
      "PART NUMBER": 15058290,
      "DESCRIPTION": "SUPER WET BRAKE TRANSAXLE OIL WB102 ",
      "PRODUCT CATEGORY": "LUV",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 2,
      "MAINTANING STOCK QTY": 4,
      "AVAILABLE": 20,
      "SHELF LIFE": 10,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1136,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 15067098,
      "DESCRIPTION": "AUTOMATIC TRANSMISSION FLUID AT101",
      "PRODUCT CATEGORY": "LUV",
      "ITEM MOVEMENT BRANCH": "AVERAGE (F2)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 7.08333333333333,
      "MAINTANING STOCK QTY": 14,
      "AVAILABLE": 57,
      "SHELF LIFE": 8.047058823529415,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1661,
      "WH REFERENCE": "",
      "TREND": 0.87
    },
    {
      "PART NUMBER": 15067197,
      "DESCRIPTION": "ULTRA DIESEL ENGINE OIL 15W/40 VDS-3 85102464",
      "PRODUCT CATEGORY": "LUV",
      "ITEM MOVEMENT BRANCH": "FAST (F1)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 611.666666666666,
      "MAINTANING STOCK QTY": 1223,
      "AVAILABLE": 460,
      "SHELF LIFE": 0.7520435967302461,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 763,
      "APRD QTY": 760,
      "WH12": 10918,
      "WH REFERENCE": "WH12",
      "TREND": 0.99
    },
    {
      "PART NUMBER": 15067515,
      "DESCRIPTION": "SUPER TRANSMISSION OIL  80W/90  API GL-5",
      "PRODUCT CATEGORY": "LUV",
      "ITEM MOVEMENT BRANCH": "FAST (F1)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 174.666666666666,
      "MAINTANING STOCK QTY": 349,
      "AVAILABLE": 180,
      "SHELF LIFE": 1.030534351145042,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 169,
      "APRD QTY": 160,
      "WH12": 1762,
      "WH REFERENCE": "WH12",
      "TREND": 1.02
    },
    {
      "PART NUMBER": 15067522,
      "DESCRIPTION": "SUPER TRANSMISSION OIL  85W/140  OIL API GL-5 99990-1Z05J",
      "PRODUCT CATEGORY": "LUV",
      "ITEM MOVEMENT BRANCH": "FAST (F1)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 103.5,
      "MAINTANING STOCK QTY": 207,
      "AVAILABLE": 240,
      "SHELF LIFE": 2.318840579710145,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 6688,
      "WH REFERENCE": "",
      "TREND": 0.85
    },
    {
      "PART NUMBER": 15067644,
      "DESCRIPTION": "WET BRAKE TRANSAXLE OIL WB101",
      "PRODUCT CATEGORY": "LUV",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 1.66666666666666,
      "MAINTANING STOCK QTY": 3,
      "AVAILABLE": 100,
      "SHELF LIFE": 60.000000000000234,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1252,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 15143928,
      "DESCRIPTION": "AUTOMATIC TRANSMISSION FLUID AT102  L150G/A40F",
      "PRODUCT CATEGORY": "LUV",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 29,
      "SHELF LIFE": 87.00000000000009,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2532,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 15155620,
      "DESCRIPTION": "OIL FILTER 43922913",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 30,
      "WH REFERENCE": "",
      "TREND": 1
    },
    {
      "PART NUMBER": 43911668,
      "DESCRIPTION": "IN-LINE FILTER FF5079 P550974",
      "PRODUCT CATEGORY": "VCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 24,
      "WH REFERENCE": "",
      "TREND": 1
    },
    {
      "PART NUMBER": 43921394,
      "DESCRIPTION": "OIL ELEMENT SD100D HF6700",
      "PRODUCT CATEGORY": "VCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 43922400,
      "DESCRIPTION": "HYD OIL FILTER SD160 HF6556 SD110B",
      "PRODUCT CATEGORY": "VCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 43923093,
      "DESCRIPTION": "FUEL FILTER 36846",
      "PRODUCT CATEGORY": "VCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 121,
      "WH REFERENCE": "",
      "TREND": 1
    },
    {
      "PART NUMBER": 43924422,
      "DESCRIPTION": "FUEL FILTER, PRIM SD100D FF42000",
      "PRODUCT CATEGORY": "VCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 55,
      "WH REFERENCE": "",
      "TREND": 1
    },
    {
      "PART NUMBER": 43931922,
      "DESCRIPTION": "ELEMENT A/F SD100D 35318252 AF1733K P182059",
      "PRODUCT CATEGORY": "VCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 1
    },
    {
      "PART NUMBER": 11419486,
      "DESCRIPTION": " TEMPERATURE SENSOR G930/A40F 14508670",
      "PRODUCT CATEGORY": "VGG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 12725151,
      "DESCRIPTION": "REPAIR KIT G930",
      "PRODUCT CATEGORY": "VGG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 11,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 12735741,
      "DESCRIPTION": "SEALING RING G930",
      "PRODUCT CATEGORY": "VGG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 12743047,
      "DESCRIPTION": "REPAIR KIT, LEANING CYL G930",
      "PRODUCT CATEGORY": "VGG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 12745093,
      "DESCRIPTION": "END PANEL (5HOLES) 22\" MLBD 5/8\" BOLTS G930",
      "PRODUCT CATEGORY": "VGG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 12746142,
      "DESCRIPTION": "BEARING G930 ",
      "PRODUCT CATEGORY": "VGG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 0,
      "WH REFERENCE": "RFC",
      "TREND": 2
    },
    {
      "PART NUMBER": 2030900065,
      "DESCRIPTION": "OIL STRAINER, TRANS. LG956L",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "AVERAGE (F2)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 1.91666666666666,
      "MAINTANING STOCK QTY": 4,
      "AVAILABLE": 14,
      "SHELF LIFE": 7.304347826086982,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 38,
      "WH REFERENCE": "",
      "TREND": 1.3
    },
    {
      "PART NUMBER": 20792408,
      "DESCRIPTION": "GASKET, TURBOCHARGER",
      "PRODUCT CATEGORY": "VGG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 5,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 20798114,
      "DESCRIPTION": "UNIT INJECTOR ENG: D7E EAE2 EC290B",
      "PRODUCT CATEGORY": "VGG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.5,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 1,
      "SHELF LIFE": 2,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 20799064,
      "DESCRIPTION": "SEALING RING G930",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 2,
      "MAINTANING STOCK QTY": 4,
      "AVAILABLE": 5,
      "SHELF LIFE": 2.5,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 70,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 20799719,
      "DESCRIPTION": "BOLT G930",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 1.33333333333333,
      "MAINTANING STOCK QTY": 3,
      "AVAILABLE": 3,
      "SHELF LIFE": 2.2500000000000058,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 6,
      "WH REFERENCE": "",
      "TREND": 1.5
    },
    {
      "PART NUMBER": 29070000081,
      "DESCRIPTION": "RIM NUT   ",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.5,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 1,
      "SHELF LIFE": 2,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 29070000621,
      "DESCRIPTION": "RIM BOLT  ",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 4,
      "MAINTANING STOCK QTY": 8,
      "AVAILABLE": 11,
      "SHELF LIFE": 2.75,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 1.67
    },
    {
      "PART NUMBER": 29100000061,
      "DESCRIPTION": "OIL STRAINER, HYDRAULIC",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 4,
      "SHELF LIFE": 12.000000000000012,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 113,
      "WH REFERENCE": "",
      "TREND": 0.5
    },
    {
      "PART NUMBER": 29100004061,
      "DESCRIPTION": "FILTER INSERT  LG938",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "AVERAGE (F2)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 1.25,
      "MAINTANING STOCK QTY": 3,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 3,
      "APRD QTY": 3,
      "WH12": 0,
      "WH REFERENCE": "RFC",
      "TREND": 1.47
    },
    {
      "PART NUMBER": 29170019701,
      "DESCRIPTION": "BOLT, CORNER LG958",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 2,
      "MAINTANING STOCK QTY": 4,
      "AVAILABLE": 3,
      "SHELF LIFE": 1.5,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 55,
      "WH REFERENCE": "WH12",
      "TREND": 1.33
    },
    {
      "PART NUMBER": 29170019711,
      "DESCRIPTION": "BOLT, BUCKET EDGES  LG958",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 16,
      "MAINTANING STOCK QTY": 32,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 32,
      "APRD QTY": 32,
      "WH12": 0,
      "WH REFERENCE": "RFC",
      "TREND": 1.33
    },
    {
      "PART NUMBER": 29170020531,
      "DESCRIPTION": "SPACER, BUCKET TOOTH  LG956 / LG958",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "AVERAGE (F2)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 8,
      "MAINTANING STOCK QTY": 16,
      "AVAILABLE": 13,
      "SHELF LIFE": 1.625,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 3,
      "APRD QTY": 3,
      "WH12": 30,
      "WH REFERENCE": "WH12",
      "TREND": 1.33
    },
    {
      "PART NUMBER": 29170036961,
      "DESCRIPTION": "CORNER TEETH RH, BUCKET  ",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.416666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 1,
      "SHELF LIFE": 2.400000000000004,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WH REFERENCE": "",
      "TREND": 1.2
    },
    {
      "PART NUMBER": 29170036971,
      "DESCRIPTION": "TEETH, BUCKET  ",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 2.08333333333333,
      "MAINTANING STOCK QTY": 4,
      "AVAILABLE": 4,
      "SHELF LIFE": 1.920000000000003,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 36,
      "WH REFERENCE": "",
      "TREND": 1.2
    },
    {
      "PART NUMBER": 29170036981,
      "DESCRIPTION": "CORNER TEETH LH, BUCKET  ",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.416666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 0,
      "WH REFERENCE": "RFC",
      "TREND": 1.2
    },
    {
      "PART NUMBER": 29170039941,
      "DESCRIPTION": "TOOTH,  BUCKET  LG956 / LG958",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 5,
      "MAINTANING STOCK QTY": 10,
      "AVAILABLE": 6,
      "SHELF LIFE": 1.2,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 4,
      "APRD QTY": 4,
      "WH12": 0,
      "WH REFERENCE": "RFC",
      "TREND": 1.4
    },
    {
      "PART NUMBER": 29170039951,
      "DESCRIPTION": "SIDE CUTTER  LH, BUCKET  LG956 / LG958 29170019681",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.916666666666666,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 2,
      "APRD QTY": 2,
      "WH12": 0,
      "WH REFERENCE": "RFC",
      "TREND": 1.45
    },
    {
      "PART NUMBER": 29170039961,
      "DESCRIPTION": "SIDE CUTTER  RH, BUCKET  LG956 / LG958 29170019691",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.833333333333333,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 1,
      "SHELF LIFE": 1.2000000000000004,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 0,
      "WH REFERENCE": "RFC",
      "TREND": 1.4
    },
    {
      "PART NUMBER": 29250000061,
      "DESCRIPTION": "SPACER, FRNT FRAME  ",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.25,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 1,
      "SHELF LIFE": 4,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 16,
      "WH REFERENCE": "",
      "TREND": 1.33
    },
    {
      "PART NUMBER": 29250000071,
      "DESCRIPTION": "SPACER, FRNT FRAME  ",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 29250000081,
      "DESCRIPTION": "SPACER, FRNT FRAME  ",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 46,
      "WH REFERENCE": "",
      "TREND": 1
    },
    {
      "PART NUMBER": 29250000301,
      "DESCRIPTION": "SHIM  29270008001",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.416666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 22,
      "WH REFERENCE": "WH12",
      "TREND": 2
    },
    {
      "PART NUMBER": 4013000016,
      "DESCRIPTION": "NUT",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 7.25,
      "MAINTANING STOCK QTY": 15,
      "AVAILABLE": 17,
      "SHELF LIFE": 2.3448275862068964,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 40,
      "WH REFERENCE": "",
      "TREND": 1.59
    },
    {
      "PART NUMBER": 4013000020,
      "DESCRIPTION": "NUT ",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 18,
      "MAINTANING STOCK QTY": 36,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 36,
      "APRD QTY": 36,
      "WH12": 0,
      "WH REFERENCE": "RFC",
      "TREND": 1.33
    },
    {
      "PART NUMBER": 4015000001,
      "DESCRIPTION": "WASHER ",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 7.08333333333333,
      "MAINTANING STOCK QTY": 14,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 14,
      "APRD QTY": 14,
      "WH12": 0,
      "WH REFERENCE": "RFC",
      "TREND": 1.51
    },
    {
      "PART NUMBER": 4015000176,
      "DESCRIPTION": "WASHER ",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 1.91666666666666,
      "MAINTANING STOCK QTY": 4,
      "AVAILABLE": 1,
      "SHELF LIFE": 0.5217391304347844,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 3,
      "APRD QTY": 3,
      "WH12": 62,
      "WH REFERENCE": "WH12",
      "TREND": 1.48
    },
    {
      "PART NUMBER": 4021000058,
      "DESCRIPTION": "BEARING, FRONT FRAME  LG956L/LG958",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 11,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 4043000033,
      "DESCRIPTION": "SPACE LG958",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 17,
      "WH REFERENCE": "WH12",
      "TREND": 0
    },
    {
      "PART NUMBER": 4043000034,
      "DESCRIPTION": "SPACE LG958",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 14,
      "WH REFERENCE": "WH12",
      "TREND": 0
    },
    {
      "PART NUMBER": 4043000055,
      "DESCRIPTION": "PUSTRING LG958",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 16,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 4043000056,
      "DESCRIPTION": "PUSTRING LG958",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 2.16666666666666,
      "MAINTANING STOCK QTY": 4,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 4,
      "APRD QTY": 0,
      "WH12": 34,
      "WH REFERENCE": "WH12",
      "TREND": 0.15
    },
    {
      "PART NUMBER": 4043000057,
      "DESCRIPTION": "PUSTRING, FRONT FRAME  ",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.416666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 1,
      "SHELF LIFE": 2.400000000000004,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 5,
      "WH REFERENCE": "",
      "TREND": 1.2
    },
    {
      "PART NUMBER": 4043000059,
      "DESCRIPTION": "O-RING ",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 2,
      "MAINTANING STOCK QTY": 4,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 4,
      "APRD QTY": 0,
      "WH12": 42,
      "WH REFERENCE": "WH12",
      "TREND": 0
    },
    {
      "PART NUMBER": 4043000173,
      "DESCRIPTION": "PIN ",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 9,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 4043000337,
      "DESCRIPTION": "BOLT",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.916666666666666,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 2,
      "APRD QTY": 2,
      "WH12": 0,
      "WH REFERENCE": "RFC",
      "TREND": 1.64
    },
    {
      "PART NUMBER": 4110000076001,
      "DESCRIPTION": "SOLENOID VALVE LG958",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 10,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 4110000076368,
      "DESCRIPTION": "OIL FILTER, SPIN-ON LG938 7200001778",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "AVERAGE (F2)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 1.66666666666666,
      "MAINTANING STOCK QTY": 3,
      "AVAILABLE": 1,
      "SHELF LIFE": 0.6000000000000024,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 2,
      "APRD QTY": 2,
      "WH12": 32,
      "WH REFERENCE": "WH12",
      "TREND": 0.9
    },
    {
      "PART NUMBER": 4110000081265,
      "DESCRIPTION": "FUEL FILTER LG936 ",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 191,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 4110000081312,
      "DESCRIPTION": "FILTER FWS LG936/LG956 7200002428",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 10,
      "WH REFERENCE": "WH12",
      "TREND": 2
    },
    {
      "PART NUMBER": 4110000179020,
      "DESCRIPTION": "OIL FILTER LG936L",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.25,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 1,
      "SHELF LIFE": 4,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 54,
      "WH REFERENCE": "",
      "TREND": 1.33
    },
    {
      "PART NUMBER": 4110000367002,
      "DESCRIPTION": "GEAR SELECTOR LG958",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 4110000556209,
      "DESCRIPTION": "OIL FILTER  LG956L",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "AVERAGE (F2)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 4.66666666666666,
      "MAINTANING STOCK QTY": 9,
      "AVAILABLE": 32,
      "SHELF LIFE": 6.857142857142867,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 1.21
    },
    {
      "PART NUMBER": 4110000589001,
      "DESCRIPTION": "FUEL FILTER",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "AVERAGE (F2)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 3.75,
      "MAINTANING STOCK QTY": 8,
      "AVAILABLE": 35,
      "SHELF LIFE": 9.333333333333334,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 48,
      "WH REFERENCE": "",
      "TREND": 1.29
    },
    {
      "PART NUMBER": 4110000589016,
      "DESCRIPTION": "AIR FILTER KIT LG956L",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.25,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 6,
      "SHELF LIFE": 24,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 79,
      "WH REFERENCE": "",
      "TREND": 0.67
    },
    {
      "PART NUMBER": 4110000613,
      "DESCRIPTION": "FILTER FILTER, FUEL TANK CAP LG956L/LG936L",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 49,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 4110000679001,
      "DESCRIPTION": "14405944 AIR FILTER, OUTER LG936L ",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.666666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 5,
      "SHELF LIFE": 7.500000000000008,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 137,
      "WH REFERENCE": "",
      "TREND": 1
    },
    {
      "PART NUMBER": 4110000679002,
      "DESCRIPTION": "AIR FILTER, INNER LG936L 14405945",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.25,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 1,
      "SHELF LIFE": 4,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 56,
      "WH REFERENCE": "",
      "TREND": 1.33
    },
    {
      "PART NUMBER": 4110001016004,
      "DESCRIPTION": "FILTER, FWS  LG956L 4110000112006",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "AVERAGE (F2)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 2.33333333333333,
      "MAINTANING STOCK QTY": 5,
      "AVAILABLE": 15,
      "SHELF LIFE": 6.428571428571438,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 9,
      "WH REFERENCE": "",
      "TREND": 1.21
    },
    {
      "PART NUMBER": 4120000066,
      "DESCRIPTION": "WATER DRAIN VALVE ",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 7,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 4120000084,
      "DESCRIPTION": "AIR DRYER (ST-50G) LG956/LG958",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 45,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 4120000452,
      "DESCRIPTION": "FILTER INSERT W/ CAP,  HYD.  LG956L/LG958",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 1,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 27,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 4120001739,
      "DESCRIPTION": "DISK BRAKE CALIPER ",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 1.16666666666666,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 2,
      "APRD QTY": 2,
      "WH12": 0,
      "WH REFERENCE": "RFC",
      "TREND": 1.71
    },
    {
      "PART NUMBER": 4120001739016,
      "DESCRIPTION": "BRAKE PLATE LG938 LG956",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "AVERAGE (F2)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 14.9166666666666,
      "MAINTANING STOCK QTY": 30,
      "AVAILABLE": 1,
      "SHELF LIFE": 0.0670391061452517,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 29,
      "APRD QTY": 29,
      "WH12": 0,
      "WH REFERENCE": "RFC",
      "TREND": 1.44
    },
    {
      "PART NUMBER": 4120001954001,
      "DESCRIPTION": "FILTER INSERT, HYDRAULIC",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "AVERAGE (F2)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 2,
      "MAINTANING STOCK QTY": 4,
      "AVAILABLE": 12,
      "SHELF LIFE": 6,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 113,
      "WH REFERENCE": "",
      "TREND": 1.25
    },
    {
      "PART NUMBER": 4130000542,
      "DESCRIPTION": "LEFT FRONT HEAD LAMP LFLDH4-24V  LG956L/LG936",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 4190000153,
      "DESCRIPTION": "WATER SEPARATOR LG958",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 1,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 9,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 60100000,
      "DESCRIPTION": "ELECTRONIC UNIT E-ECU G930 EC210B EC290B",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.5,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 6,
      "WH REFERENCE": "WH12",
      "TREND": 0.33
    },
    {
      "PART NUMBER": 75439,
      "DESCRIPTION": "CUTTING EDGE 5/8\" X 6\" X 7FT 15 (5/8\") HOLES",
      "PRODUCT CATEGORY": "VGG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 5,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 11039563,
      "DESCRIPTION": "B-UP WRN UNIT L70/120C/G930",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 16,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 11039846,
      "DESCRIPTION": "WORK LAMP_70W/24V A30/EC210B/EC460B/L60/70E",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.25,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 1,
      "SHELF LIFE": 4,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 60,
      "WH REFERENCE": "",
      "TREND": 1.33
    },
    {
      "PART NUMBER": 11110022,
      "DESCRIPTION": "AIR FILTER, PRIMARY 15193225",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 57,
      "WH REFERENCE": "",
      "TREND": 1
    },
    {
      "PART NUMBER": 11110023,
      "DESCRIPTION": "SAFETY FILTER, INNER 15193225",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 153,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 11110175,
      "DESCRIPTION": "AIR FILTER, PRIM 15193224",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "AVERAGE (F2)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 2.16666666666666,
      "MAINTANING STOCK QTY": 4,
      "AVAILABLE": 6,
      "SHELF LIFE": 2.769230769230778,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 365,
      "WH REFERENCE": "",
      "TREND": 1.31
    },
    {
      "PART NUMBER": 11110176,
      "DESCRIPTION": "AIR FILTER, SAFETY",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "AVERAGE (F2)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 1.25,
      "MAINTANING STOCK QTY": 3,
      "AVAILABLE": 2,
      "SHELF LIFE": 1.6,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 0,
      "WH REFERENCE": "RFC",
      "TREND": 1.07
    },
    {
      "PART NUMBER": 11110668,
      "DESCRIPTION": "PRIMARY FILTER,  FWS",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.5,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 5,
      "SHELF LIFE": 10,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3,
      "WH REFERENCE": "",
      "TREND": 1.33
    },
    {
      "PART NUMBER": 11110683,
      "DESCRIPTION": "PRIMARY FILTER, FWS (X6)",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "FAST (F1)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 10.1666666666666,
      "MAINTANING STOCK QTY": 20,
      "AVAILABLE": 7,
      "SHELF LIFE": 0.688524590163939,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 13,
      "APRD QTY": 13,
      "WH12": 165,
      "WH REFERENCE": "WH12",
      "TREND": 1.21
    },
    {
      "PART NUMBER": 11110709,
      "DESCRIPTION": "WATER SEPARATOR W/O HEATER  EC210B  EW145B 11110703_",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 4,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 11147756,
      "DESCRIPTION": "SIGN LAMP L60/120E/BL71",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 11172907,
      "DESCRIPTION": "AIR FILTER, FUEL TANK BREATHER 8152009  (X12)",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "FAST (F1)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 3.33333333333333,
      "MAINTANING STOCK QTY": 7,
      "AVAILABLE": 4,
      "SHELF LIFE": 1.2000000000000013,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 3,
      "APRD QTY": 3,
      "WH12": 1,
      "WH REFERENCE": "PARTIAL WH12",
      "TREND": 1.3
    },
    {
      "PART NUMBER": 11417147,
      "DESCRIPTION": "LOCKING DEVICE 30P (VTS) EC210B/EC240B",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "AVERAGE (F2)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 5.58333333333333,
      "MAINTANING STOCK QTY": 11,
      "AVAILABLE": 9,
      "SHELF LIFE": 1.6119402985074636,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 2,
      "APRD QTY": 2,
      "WH12": 0,
      "WH REFERENCE": "RFC",
      "TREND": 1.07
    },
    {
      "PART NUMBER": 11700426,
      "DESCRIPTION": "SEALING RING",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.5,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 26,
      "WH REFERENCE": "WH12",
      "TREND": 0
    },
    {
      "PART NUMBER": 11703980,
      "DESCRIPTION": "AIR FILTER, CAB",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 228,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 11707077,
      "DESCRIPTION": "BREATHER ELEMENT L60/70E/120E/L150E/F/A30E/A35D/E",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 31,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 11708825,
      "DESCRIPTION": "SEAL KIT_LIFT L120E/L120F",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 1,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 11711074,
      "DESCRIPTION": "FUEL FILTER",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.666666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 3,
      "SHELF LIFE": 4.500000000000004,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 17,
      "WH REFERENCE": "",
      "TREND": 1.5
    },
    {
      "PART NUMBER": 11713240,
      "DESCRIPTION": "FUEL FILTER 14520578 ",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 1,
      "SHELF LIFE": 12.000000000000005,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 11991047,
      "DESCRIPTION": "SEALING KIT A30/L330/L150F",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 0,
      "WH REFERENCE": "RFC",
      "TREND": 2
    },
    {
      "PART NUMBER": 12743605,
      "DESCRIPTION": "COIL",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 13947281,
      "DESCRIPTION": "GASKET EC210B",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 10,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 13947621,
      "DESCRIPTION": "PLANE GASKET EC210B",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.25,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 66,
      "WH REFERENCE": "WH12",
      "TREND": 0
    },
    {
      "PART NUMBER": 13969011,
      "DESCRIPTION": "GASKET EC460B",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 6,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 13969162,
      "DESCRIPTION": "BLADE FUSE 15A A35D/G930/L150F/EC210B-P/EC460B/C 969162",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.833333333333333,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 2,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "RFC",
      "TREND": 0
    },
    {
      "PART NUMBER": 13970950,
      "DESCRIPTION": "HEXAGON SCREW",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 20443551,
      "DESCRIPTION": "LEVER LOCKING",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 20489245,
      "DESCRIPTION": "CAB FILTER",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.5,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 3,
      "SHELF LIFE": 6,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WH REFERENCE": "",
      "TREND": 1
    },
    {
      "PART NUMBER": 20524936,
      "DESCRIPTION": "SENSOR FM340/EC210B/EC290B/G930 20556500",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 1,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 20526428,
      "DESCRIPTION": "SEALING RING",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 17,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 20579690,
      "DESCRIPTION": "SEALING RING, OIL PAN",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "AVERAGE (F2)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 2.91666666666666,
      "MAINTANING STOCK QTY": 6,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 6,
      "APRD QTY": 0,
      "WH12": 37,
      "WH REFERENCE": "WH12",
      "TREND": 0.86
    },
    {
      "PART NUMBER": 20707685,
      "DESCRIPTION": "GASKET FM340",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 11,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 20734499,
      "DESCRIPTION": "CABLE SEAL 970772 ADAPTED QTY 200 PCS",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 266,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 20748450,
      "DESCRIPTION": "VALVE KIT FM13-FM440",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 8,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 20750350,
      "DESCRIPTION": "BRUSH, ALTERNATOR",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 20779040,
      "DESCRIPTION": "OIL FILTER KIT ASSEMBLY (TRANSMISSION)",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 16,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 20781146,
      "DESCRIPTION": "GASKET FM10/L150F/EC460B 8194365",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 23,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 20804638,
      "DESCRIPTION": "SEALING STRIP",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 1,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 20817742,
      "DESCRIPTION": "GASKET FM9/FM400",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 16,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 20829689,
      "DESCRIPTION": "PRESSURE SENSOR A35D",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 20835246,
      "DESCRIPTION": "MASTER CYLINDER FM340",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 1,
      "SHELF LIFE": 12.000000000000005,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 20997774,
      "DESCRIPTION": "V-RIBBED BELT, ALTERNATOR FM370",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 21210530,
      "DESCRIPTION": "21714563 FULE LEVEL SENSOR FM370",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 1,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 21255974,
      "DESCRIPTION": "RELAY FM9/G930/L150F/EC460B",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 12,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 21347087,
      "DESCRIPTION": "CRANKSHAFT SEAL 85108423",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 10,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 21380475,
      "DESCRIPTION": "FUEL FILTER WATER SEPARATOR",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 13,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 21380488,
      "DESCRIPTION": "FUEL FILTER WATER SEPARATOR",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "AVERAGE (F2)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 3,
      "MAINTANING STOCK QTY": 6,
      "AVAILABLE": 6,
      "SHELF LIFE": 2,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 65,
      "WH REFERENCE": "",
      "TREND": 0.83
    },
    {
      "PART NUMBER": 21482601,
      "DESCRIPTION": "GASKET FM10 8148172",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 19,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 21620181,
      "DESCRIPTION": "AIR DRIER FILTER",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 5,
      "SHELF LIFE": 60.00000000000002,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 8,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 276098,
      "DESCRIPTION": "REVERSE LOCK RH",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 53,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 276948,
      "DESCRIPTION": "O-RING KIT, INJECTOR   FM330",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 2,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 14,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 3090954,
      "DESCRIPTION": "DUST COVER_WHL BRK FM10",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 30,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 3090964,
      "DESCRIPTION": "REP KIT_WHEEL BRK FM10/FM400",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 8,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 3092340,
      "DESCRIPTION": "SEALANT 310ML (VOLVO GREY)",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 4,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 3093230,
      "DESCRIPTION": "REPAIR KIT_RANGE CYL NL10/FM10/FM400",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 19,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 3176550,
      "DESCRIPTION": "MOTOR",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 349619,
      "DESCRIPTION": "20580233 STEERING FILTER",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.25,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 0,
      "WH REFERENCE": "RFC",
      "TREND": 0
    },
    {
      "PART NUMBER": 8086970,
      "DESCRIPTION": "22923707 IDLER ROLLER FM10",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 8150543,
      "DESCRIPTION": "20480605 FIFTH WHEEL",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 85103703,
      "DESCRIPTION": "O-RING",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 6,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 8550977,
      "DESCRIPTION": "REPAIR KIT, WHEEL BRK FM9/FM10",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 8550978,
      "DESCRIPTION": "REP KIT_WHEEL BRK NL10/FM9/FM10",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 13,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 949329,
      "DESCRIPTION": "GASKET FM10/EC460C",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 98,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 977030,
      "DESCRIPTION": "O-RING",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 75,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 980881,
      "DESCRIPTION": "CABLE TIE",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 5594,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 982108,
      "DESCRIPTION": "O-RING",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 21,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 982724,
      "DESCRIPTION": "GASKET FM9",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 1,
      "SHELF LIFE": 3.000000000000003,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 25,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 983472,
      "DESCRIPTION": "CABLE TIE ADAPTED QTY 100",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.833333333333333,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 2,
      "SHELF LIFE": 2.400000000000001,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 189,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 990037,
      "DESCRIPTION": "BULB 70W 24V A35D/G930/L150F/FM10/FM400",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.75,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 2,
      "APRD QTY": 2,
      "WH12": 49,
      "WH REFERENCE": "WH12",
      "TREND": 1.11
    },
    {
      "PART NUMBER": 992519,
      "DESCRIPTION": "BULB 5W/24V FM10/FM400",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 3.25,
      "MAINTANING STOCK QTY": 7,
      "AVAILABLE": 11,
      "SHELF LIFE": 3.3846153846153846,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 26,
      "WH REFERENCE": "",
      "TREND": 0.77
    },
    {
      "PART NUMBER": 992521,
      "DESCRIPTION": "BULB 24V/21W G930/L150F 967708",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 1.33333333333333,
      "MAINTANING STOCK QTY": 3,
      "AVAILABLE": 6,
      "SHELF LIFE": 4.5000000000000115,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 312,
      "WH REFERENCE": "",
      "TREND": 1.25
    },
    {
      "PART NUMBER": "A820606010023",
      "DESCRIPTION": "SEAL RING FOR 125A",
      "PRODUCT CATEGORY": "YPG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 4.16666666666666,
      "MAINTANING STOCK QTY": 8,
      "AVAILABLE": 4,
      "SHELF LIFE": 0.9600000000000015,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 4,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "RFC",
      "TREND": 0
    },
    {
      "PART NUMBER": "A820606030049",
      "DESCRIPTION": "SEAL RING, PLACING BOOM",
      "PRODUCT CATEGORY": "YPG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 2.08333333333333,
      "MAINTANING STOCK QTY": 4,
      "AVAILABLE": 4,
      "SHELF LIFE": 1.920000000000003,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 136,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": "A820699000015",
      "DESCRIPTION": "CLEANING BALL",
      "PRODUCT CATEGORY": "YPG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.416666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 18,
      "WH REFERENCE": "WH12",
      "TREND": 0
    },
    {
      "PART NUMBER": "B222100000116",
      "DESCRIPTION": "FILTER ELEMENT, HYD.",
      "PRODUCT CATEGORY": "YPG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 3152527,
      "DESCRIPTION": "RADIAL SHAFT SEAL",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 7,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 8171450,
      "DESCRIPTION": "RADIAL SHAFT SEAL",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 6,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 14626370,
      "DESCRIPTION": "HOUR COUNTER EC210B/EC290B 14530130",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": "C11195",
      "DESCRIPTION": "SSC11195 ROD, (D100) MOIL POINT SB50/SU+55 TR-F",
      "PRODUCT CATEGORY": "SOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 29170036941,
      "DESCRIPTION": "CUTTING EDGE  LG936L",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 6,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 14507748,
      "DESCRIPTION": "CLAMP",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 14505765,
      "DESCRIPTION": "CLAMP",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 14547554,
      "DESCRIPTION": "BUSHING_BUCKET EC210B",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 21479106,
      "DESCRIPTION": "OIL FILTER KIT (TRANSMISSION)",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 3,
      "SHELF LIFE": 36.000000000000014,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 21261987,
      "DESCRIPTION": "WASHER",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 2,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 32,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 20555065,
      "DESCRIPTION": "SCREW",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 44,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 992328,
      "DESCRIPTION": "FLANGE NUT 979231",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 40,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 29220001251,
      "DESCRIPTION": "CONNECTOR-JOINT",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 21620172,
      "DESCRIPTION": "21620181 AIR PRODUCTION MODULATOR ",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 5,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 12745114,
      "DESCRIPTION": "OVERLAY END SHOE 22\" MLDB - 6\" EDGE",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 2,
      "SHELF LIFE": 12.000000000000048,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": "8230-03560",
      "DESCRIPTION": "PLATE, TRAVEL MOTOR  EC210B",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 16,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 14535450,
      "DESCRIPTION": "O-RING",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WH REFERENCE": "",
      "TREND": 1
    },
    {
      "PART NUMBER": "7117-30370",
      "DESCRIPTION": "HEXAGON SCREW",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 293,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 14541865,
      "DESCRIPTION": "PLATE, HYD PUMP EC210B",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": "8230-08940",
      "DESCRIPTION": "SUPPORT_SWASH",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 4041000771,
      "DESCRIPTION": "O-RING ",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 907816,
      "DESCRIPTION": "SPLIT PIN A40F 907813",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 14622357,
      "DESCRIPTION": "DRAIN",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 4120000087010,
      "DESCRIPTION": "BRAKE SHOE",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 22,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 11709817,
      "DESCRIPTION": "SEALING KIT",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 5,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": "9412-11090",
      "DESCRIPTION": "14884806 CONNECTOR",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 4120001739007,
      "DESCRIPTION": "RETAINER RING",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 224,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 4120001739008,
      "DESCRIPTION": "SQUARE SEAL",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 1.33333333333333,
      "MAINTANING STOCK QTY": 3,
      "AVAILABLE": 3,
      "SHELF LIFE": 2.2500000000000058,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 282,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 4021000023,
      "DESCRIPTION": "BEARING ",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 15,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 4110000076281,
      "DESCRIPTION": "GASKET 4110000076043  4110000076040",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 39,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 11708371,
      "DESCRIPTION": "LIMITED SLIP GEAR OIL SAE 85W-90LS API-GL5 15195533 ",
      "PRODUCT CATEGORY": "LUB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 20459226,
      "DESCRIPTION": "GASKET",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 20459855,
      "DESCRIPTION": "VALVE SEAL",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 2,
      "MAINTANING STOCK QTY": 4,
      "AVAILABLE": 4,
      "SHELF LIFE": 2,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 150,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 21496591,
      "DESCRIPTION": "LIP SEAL 20459168",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 14,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 21638691,
      "DESCRIPTION": "FUEL REGULATOR 21103266",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 17,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 20998903,
      "DESCRIPTION": "SEALING RING",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 20405643,
      "DESCRIPTION": "PLUG",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 20515173,
      "DESCRIPTION": "GASKET",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 4,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 21061009,
      "DESCRIPTION": "SEALING RING",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 10,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 993605,
      "DESCRIPTION": "GASKET",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 6,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 14559054,
      "DESCRIPTION": "SEALING",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 89,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": "7117-30170",
      "DESCRIPTION": "SHIM",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 12745102,
      "DESCRIPTION": "END PANEL 26\" MLBD 5/8\" BOLTS G970",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 20405721,
      "DESCRIPTION": "CORE PLUG",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.75,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 2,
      "APRD QTY": 2,
      "WH12": 46,
      "WH REFERENCE": "WH12",
      "TREND": 2
    },
    {
      "PART NUMBER": 14624748,
      "DESCRIPTION": "CABLE HARNESS",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 17214676,
      "DESCRIPTION": "EXPANSION TANK ASSY EC210B, EC290B  15047209",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 1,
      "SHELF LIFE": 3.000000000000003,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 8,
      "WH REFERENCE": "",
      "TREND": 1
    },
    {
      "PART NUMBER": 21354759,
      "DESCRIPTION": "EXPANSION VALVE",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 14527865,
      "DESCRIPTION": "ADAPTER A15-30, HOR. (KTS) EC140B/EW145B",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 5,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 20966752,
      "DESCRIPTION": "O-RING",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 29,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 20459204,
      "DESCRIPTION": "VALVE, OIL COOLER",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 14543216,
      "DESCRIPTION": "PIN, LINK ROD EC210B",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": "7117-30180",
      "DESCRIPTION": "SNAP-ON COUPLING",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": "7117-30330",
      "DESCRIPTION": "SUN GEAR",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 4120001739005,
      "DESCRIPTION": "OUTER PLIERS",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 7,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 18,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 4120001739006,
      "DESCRIPTION": "DUST CAP ",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 20724977,
      "DESCRIPTION": "VOLTAGE CONVERTER 20752237",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 21811707,
      "DESCRIPTION": "4WAY VALVE FM10/FM340/FM400 20452152",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 983242,
      "DESCRIPTION": "997421 HEXAGON SCREW",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 29,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 14529761,
      "DESCRIPTION": "PLATE",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 30,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 14563050,
      "DESCRIPTION": "BOLT",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 61,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 249,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 21038626,
      "DESCRIPTION": "GASKET",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 20,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 21108673,
      "DESCRIPTION": "SEALING RING",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 15,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 14621703,
      "DESCRIPTION": "SEALING RING EC210B 8230-27160",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": "B222100000751",
      "DESCRIPTION": "FUEL FILTER CORE",
      "PRODUCT CATEGORY": "YCG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.25,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 3,
      "SHELF LIFE": 12,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 17,
      "WH REFERENCE": "",
      "TREND": 1.33
    },
    {
      "PART NUMBER": "A222100000600",
      "DESCRIPTION": "AIR FILTER, OUTER",
      "PRODUCT CATEGORY": "YCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 1,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": "A222100000599",
      "DESCRIPTION": "AIR FILTER, SAFETY  QY25C/5OC",
      "PRODUCT CATEGORY": "YCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 5,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 15173332,
      "DESCRIPTION": "SEALING KIT",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 17204355,
      "DESCRIPTION": "ALTERNATOR_80A EC210B/G710B/G930/L150F 22098738 20849350 ",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 33,
      "WH REFERENCE": "",
      "TREND": 1
    },
    {
      "PART NUMBER": 14526214,
      "DESCRIPTION": "OVERHAUL KIT",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 4041000020,
      "DESCRIPTION": "O-RING LGB308-440*3.5",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 1.16666666666666,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 4,
      "SHELF LIFE": 3.428571428571448,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 16,
      "WH REFERENCE": "",
      "TREND": 1.71
    },
    {
      "PART NUMBER": 4030000146,
      "DESCRIPTION": "OIL SEAL, AXLE HG4-692-SG170*200*15 LG958",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 1.16666666666666,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 4,
      "SHELF LIFE": 3.428571428571448,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 14,
      "WH REFERENCE": "",
      "TREND": 1.71
    },
    {
      "PART NUMBER": 4110000025006,
      "DESCRIPTION": "STARTER ",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 5,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 21707132,
      "DESCRIPTION": "OIL FILTER, BYPASS (X8)",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "AVERAGE (F2)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 1.75,
      "MAINTANING STOCK QTY": 4,
      "AVAILABLE": 4,
      "SHELF LIFE": 2.2857142857142856,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 16,
      "WH REFERENCE": "",
      "TREND": 0.86
    },
    {
      "PART NUMBER": 4030000536,
      "DESCRIPTION": "O-RING ",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.5,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 1,
      "WH REFERENCE": "WH12",
      "TREND": 2
    },
    {
      "PART NUMBER": 4110000507007,
      "DESCRIPTION": "FILTER INSERT, T/C",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "AVERAGE (F2)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 1.16666666666666,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 17,
      "SHELF LIFE": 14.571428571428653,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 1.14
    },
    {
      "PART NUMBER": 14622354,
      "DESCRIPTION": "FUEL FILTER ASSY, EXTRA FWS",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 4110001015026,
      "DESCRIPTION": "V-RIBBED BELT, FAN",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 44,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 21628356,
      "DESCRIPTION": "24065172 CRANKSHAFT SEAL 20459115",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 4021000030,
      "DESCRIPTION": "BEARING ",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 4030000035,
      "DESCRIPTION": "O-RING GB3452.1-53.0*5.30G",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 14600711,
      "DESCRIPTION": "SEALING 8230-26900 14535452",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 1
    },
    {
      "PART NUMBER": 21534097,
      "DESCRIPTION": "BRAKE LINING KIT, FRONT 175MM STD",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.5,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 8,
      "SHELF LIFE": 16,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 21534385,
      "DESCRIPTION": "BRAKE LINING KIT, REAR 200MM STD",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 1.5,
      "MAINTANING STOCK QTY": 3,
      "AVAILABLE": 14,
      "SHELF LIFE": 9.333333333333334,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 17,
      "WH REFERENCE": "",
      "TREND": 1
    },
    {
      "PART NUMBER": 14597767,
      "DESCRIPTION": "CABLE HARNESS",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 12,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 21707134,
      "DESCRIPTION": "OIL FILTER 466634",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 1,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 374,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 14559479,
      "DESCRIPTION": "FUEL FILTER",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 3,
      "SHELF LIFE": 36.000000000000014,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 31,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 15066534,
      "DESCRIPTION": "FUEL FILTER",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 1,
      "SHELF LIFE": 12.000000000000005,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 19,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": "1040-20410",
      "DESCRIPTION": "OIL FILTER",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 2,
      "SHELF LIFE": 6.000000000000006,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 31,
      "WH REFERENCE": "",
      "TREND": 0.5
    },
    {
      "PART NUMBER": 12746143,
      "DESCRIPTION": "THRUST WASHER",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 7,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 21707133,
      "DESCRIPTION": "17533661 OIL FILTER",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 12743123,
      "DESCRIPTION": "O-RING",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 6,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 4015000034,
      "DESCRIPTION": "WASHER",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 14547821,
      "DESCRIPTION": "PIN, LINK-BUCKET  EC210B 14510438",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 14547822,
      "DESCRIPTION": "PIN",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 11,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 14211856,
      "DESCRIPTION": "SEALING RING",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 4,
      "SHELF LIFE": 12.000000000000012,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 31,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": "16Y-75-23200",
      "DESCRIPTION": "FILTER ELEMENT, TRANS.  SD16  175-49-11580  HF6072",
      "PRODUCT CATEGORY": "SDG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 9,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 4120001739053,
      "DESCRIPTION": "DUST CAP ",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 3,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 15,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 21483521,
      "DESCRIPTION": "PULLEY, V-BELT",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 14622355,
      "DESCRIPTION": "FUEL FILTER, EXTRA FWS_",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "FAST (F1)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 6.41666666666666,
      "MAINTANING STOCK QTY": 13,
      "AVAILABLE": 16,
      "SHELF LIFE": 2.493506493506496,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1379,
      "WH REFERENCE": "",
      "TREND": 1.17
    },
    {
      "PART NUMBER": 15067640,
      "DESCRIPTION": "GREASE, LITHIUM  EP2 ",
      "PRODUCT CATEGORY": "LUV",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3181,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": "R134A",
      "DESCRIPTION": "REFRIGERANT (PER KILO) 13.5 KL PER CYLINDER",
      "PRODUCT CATEGORY": "LUB",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 2.08333333333333,
      "MAINTANING STOCK QTY": 4,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 4,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "RFC",
      "TREND": 0.32
    },
    {
      "PART NUMBER": 4030000047,
      "DESCRIPTION": "OIL SEAL HG4-692-PD150*180*16",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 20412745,
      "DESCRIPTION": "CORE PLUG",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.5,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 1,
      "SHELF LIFE": 2,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 31,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 14591830,
      "DESCRIPTION": "FUEL FILL PUMP 14539462 ",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 1,
      "SHELF LIFE": 12.000000000000005,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 7,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 14532688,
      "DESCRIPTION": "FILTER CARTRIDGE, SERVO SYSTEM",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.25,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 1,
      "SHELF LIFE": 4,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 46,
      "WH REFERENCE": "",
      "TREND": 1.33
    },
    {
      "PART NUMBER": 14562924,
      "DESCRIPTION": "SPRING PACKAGE  EC210B",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 10,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 7200001514,
      "DESCRIPTION": "O-RING ",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 4110000556189,
      "DESCRIPTION": "PUMP GASKET",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 43,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 21517690,
      "DESCRIPTION": "GASKET FM330 20539127",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 1,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 9,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 12743391,
      "DESCRIPTION": "COIL, BRAKE SYSTEM  A35F",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 20460215,
      "DESCRIPTION": "CLIP",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 4110000556085,
      "DESCRIPTION": "THERMOSTAT ASSEMBLY ",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 21647837,
      "DESCRIPTION": "TURBOCHARGER, ENG. D6E EAE2 EC210B 20873313 ",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 14550884,
      "DESCRIPTION": "SOLENOID COIL",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 9,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 20459311,
      "DESCRIPTION": "BELT, FAN (10PK) EC480D",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.5,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 1,
      "SHELF LIFE": 2,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 1.33
    },
    {
      "PART NUMBER": 14550188,
      "DESCRIPTION": "CYLINDER",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": "7114-12371",
      "DESCRIPTION": "SEAL_RADIAL",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 48,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 4110000460,
      "DESCRIPTION": "SHOCK ABSORBER CBB95-2 LG956/LG958",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 2,
      "WH REFERENCE": "WH12",
      "TREND": 2
    },
    {
      "PART NUMBER": 14542155,
      "DESCRIPTION": "AIR FILTER, PRIMARY",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 4,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 18,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 14524470,
      "DESCRIPTION": "OIL FILTER  EC55B/EW60C 14523962",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 19,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 14612048,
      "DESCRIPTION": "FILTER, A/C CONDENSER  EC210B  SN 38328~/ 74214~",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 35,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 990603,
      "DESCRIPTION": "O-RING",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.25,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 1,
      "SHELF LIFE": 4,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WH REFERENCE": "",
      "TREND": 0.67
    },
    {
      "PART NUMBER": 14589150,
      "DESCRIPTION": "SEALING KIT",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 14622356,
      "DESCRIPTION": "BOWL, EXTRA WATER SEPARATOR",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 4,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 20840772,
      "DESCRIPTION": "SNAP RING",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 35,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 14550879,
      "DESCRIPTION": "NEEDLE BEARING",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 14594697,
      "DESCRIPTION": "V-ECU EC210BLR",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3,
      "WH REFERENCE": "",
      "TREND": 1
    },
    {
      "PART NUMBER": 4804563,
      "DESCRIPTION": "TENSIONING BAND",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 4,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 1547967,
      "DESCRIPTION": "SHIM",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 54,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 20405559,
      "DESCRIPTION": "SCREW",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.5,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 22,
      "WH REFERENCE": "WH12",
      "TREND": 0
    },
    {
      "PART NUMBER": 990719,
      "DESCRIPTION": "O-RING",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 6,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 468947,
      "DESCRIPTION": "SEALING RING",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 13,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 60075090,
      "DESCRIPTION": "MAIN SHAFT REDUCTON GEAR",
      "PRODUCT CATEGORY": "YPG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 14511752,
      "DESCRIPTION": "SEAL",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 18,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": "7114-11950",
      "DESCRIPTION": "SEAL_DUST",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 16,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": "DOT3",
      "DESCRIPTION": "BRAKE FLUID (1 LITER)",
      "PRODUCT CATEGORY": "LUB",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 0,
      "WH REFERENCE": "RFC",
      "TREND": 1
    },
    {
      "PART NUMBER": 20796351,
      "DESCRIPTION": "TANK, OIL COOLER  EC210B  20459945",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 14630764,
      "DESCRIPTION": "RELAY_24V G930/EC210B/EC460B/EC700B/A35D/L120E/G930 14594545 14376598",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 16,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 21598183,
      "DESCRIPTION": "TURBOCHARGER, ENG. D6E EAE3 EC210C 20933297",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 20459837,
      "DESCRIPTION": "BOLT",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 12,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 14519262,
      "DESCRIPTION": "AIR FILTER, SAFETY EC55B 15193234 ",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 110,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 14589156,
      "DESCRIPTION": "SEALING KIT, BUCKET CYL. (LONG REACH) EC210B / EC290B",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 20551332,
      "DESCRIPTION": "CABLE HARNESS",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 1,
      "SHELF LIFE": 12.000000000000005,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 15603435,
      "DESCRIPTION": "TOOTH, BUCKET BL71",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 51,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 15603422,
      "DESCRIPTION": "PIN, TOOTH BL71",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 85,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 15633940,
      "DESCRIPTION": "ADAPTER  (VTS) EA8BL20  BL71",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 8,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 14542156,
      "DESCRIPTION": "FILTER, SAFETY 15193236 ",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 1,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 8,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 20799673,
      "DESCRIPTION": "HOSE CLAMP",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 28,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 14536511,
      "DESCRIPTION": "WATER SEPARATOR, EC55B",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 1,
      "SHELF LIFE": 12.000000000000005,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 20,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 15052786,
      "DESCRIPTION": "CAB FILTER BL71/L60/70E/L120E/L150E/F/A30E/A35D/E 11703979",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "AVERAGE (F2)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 1.41666666666666,
      "MAINTANING STOCK QTY": 3,
      "AVAILABLE": 1,
      "SHELF LIFE": 0.7058823529411797,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 2,
      "APRD QTY": 2,
      "WH12": 0,
      "WH REFERENCE": "RFC",
      "TREND": 1.18
    },
    {
      "PART NUMBER": 4120001739052,
      "DESCRIPTION": "SQUARE SEAL",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 3,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 14621335,
      "DESCRIPTION": "EXTRA FWS FILTER ASSY  EC210BLC",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 14530607,
      "DESCRIPTION": "HEADLAMP",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 4110000727085,
      "DESCRIPTION": "14400779  OIL FILTER",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 29070000591,
      "DESCRIPTION": "SPACER(GASKET)",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 1.25,
      "MAINTANING STOCK QTY": 3,
      "AVAILABLE": 2,
      "SHELF LIFE": 1.6,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 0,
      "WH REFERENCE": "RFC",
      "TREND": 1.6
    },
    {
      "PART NUMBER": 14643849,
      "DESCRIPTION": "TRANSFER GEARBOX",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 17253817,
      "DESCRIPTION": "SEALING KIT 11708833",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 4,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": "A820606030048",
      "DESCRIPTION": "SEAL RING C12037.3.1-3",
      "PRODUCT CATEGORY": "YPG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 180,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 12579712,
      "DESCRIPTION": "SEAL RING BCV62.9.1-1 (175A)",
      "PRODUCT CATEGORY": "YPG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 17,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 14641479,
      "DESCRIPTION": "FILLER CAP 14626785 14528922 14529069",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 24425954,
      "DESCRIPTION": "INJECTION PUMP",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 1677348,
      "DESCRIPTION": "SHIM",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 27,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 4110000991005,
      "DESCRIPTION": "ALTERNATOR LG936  ",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 20551429,
      "DESCRIPTION": "FITTING",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": "4130000418A",
      "DESCRIPTION": "THERMOSTAT 4130000418",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 4110000189022,
      "DESCRIPTION": "STARTER MOTOR  4110000909089",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 22085052,
      "DESCRIPTION": "23105541 CLUTCH SERVO",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 48,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 4110001593002,
      "DESCRIPTION": "FILTER, FWS  4110001016004  ",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 2,
      "SHELF LIFE": 6.000000000000006,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 25,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 4110000189031,
      "DESCRIPTION": " FUEL FILTER INSERT",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 5,
      "SHELF LIFE": 15.000000000000016,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 21486084,
      "DESCRIPTION": "LIP SEAL 20412286 ",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 7,
      "WH REFERENCE": "",
      "TREND": 1
    },
    {
      "PART NUMBER": "252619Z00C ",
      "DESCRIPTION": "BULB 260199Z00C",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.416666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 32,
      "WH REFERENCE": "WH12",
      "TREND": 0
    },
    {
      "PART NUMBER": 993323,
      "DESCRIPTION": "O-RING  A40 932042",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 90,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 21519716,
      "DESCRIPTION": "FILTER",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.25,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 26,
      "WH REFERENCE": "WH12",
      "TREND": 2
    },
    {
      "PART NUMBER": 20554258,
      "DESCRIPTION": "CABLE HARNESS",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 22203109,
      "DESCRIPTION": "REPAIR KIT 21707608 ",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 9,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 4013000205,
      "DESCRIPTION": "NUT, PLOW BOLT  ",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 7.83333333333333,
      "MAINTANING STOCK QTY": 16,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 16,
      "APRD QTY": 0,
      "WH12": 601,
      "WH REFERENCE": "WH12",
      "TREND": 0.72
    },
    {
      "PART NUMBER": 3030900094,
      "DESCRIPTION": "DRIVE SHAFT GEAR",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 4110000557022,
      "DESCRIPTION": "SEALING RING",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 19,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 4110000556155,
      "DESCRIPTION": "CYLINDER HEAD GASKET ",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 95,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 28140006791,
      "DESCRIPTION": "BOLT, CUTTING EDGE  LG9190",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 2.83333333333333,
      "MAINTANING STOCK QTY": 6,
      "AVAILABLE": 6,
      "SHELF LIFE": 2.117647058823532,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 317,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 4043000218,
      "DESCRIPTION": "BUSH ",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.5,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "RFC",
      "TREND": 0
    },
    {
      "PART NUMBER": 4043000112,
      "DESCRIPTION": "WELD PIN LGB301-60*95*170G-40CR",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 2,
      "SHELF LIFE": 12.000000000000048,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 45,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 4043000126,
      "DESCRIPTION": "SHIM ",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.833333333333333,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 2,
      "APRD QTY": 2,
      "WH12": 6,
      "WH REFERENCE": "WH12",
      "TREND": 1.2
    },
    {
      "PART NUMBER": 4043000129,
      "DESCRIPTION": "O-RING ",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.833333333333333,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 2,
      "APRD QTY": 2,
      "WH12": 20,
      "WH REFERENCE": "WH12",
      "TREND": 1.2
    },
    {
      "PART NUMBER": 4043000125,
      "DESCRIPTION": "SHIM ",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 1.16666666666666,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 2,
      "APRD QTY": 2,
      "WH12": 47,
      "WH REFERENCE": "WH12",
      "TREND": 1.43
    },
    {
      "PART NUMBER": 4190002758,
      "DESCRIPTION": "COMPRESSOR",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 14623356,
      "DESCRIPTION": "CABLE HARNESS",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": "307099Z00A",
      "DESCRIPTION": "GASKET",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 13,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 14631808,
      "DESCRIPTION": "WIRE HARNESS",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 4110000054003,
      "DESCRIPTION": "O-SEAL ",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 1,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 2,
      "APRD QTY": 2,
      "WH12": 14,
      "WH REFERENCE": "WH12",
      "TREND": 2
    },
    {
      "PART NUMBER": 4110000054002,
      "DESCRIPTION": "O-SEAL ",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 1,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 2,
      "APRD QTY": 2,
      "WH12": 8,
      "WH REFERENCE": "WH12",
      "TREND": 2
    },
    {
      "PART NUMBER": 4110000054100,
      "DESCRIPTION": "SEALING RING, CRANKCASE ",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 4110000846035,
      "DESCRIPTION": "SEALING RING, FLYWHEEL ",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 4110000054038,
      "DESCRIPTION": "PLAIN WASHER ",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 4110000054037,
      "DESCRIPTION": "PLAIN WASHER 12161797",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 14663702,
      "DESCRIPTION": "NUT, T/S BOLT EC210B 14880574",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 30.6666666666666,
      "MAINTANING STOCK QTY": 61,
      "AVAILABLE": 61,
      "SHELF LIFE": 1.989130434782613,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1639,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 28140007461,
      "DESCRIPTION": "CUTTING EDGE",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 28140007451,
      "DESCRIPTION": "CUTTING EDGE",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 32,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 4110000057003,
      "DESCRIPTION": "GASKET 4110000054233",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 1,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 9,
      "SHELF LIFE": 9,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 11,
      "WH REFERENCE": "",
      "TREND": 1
    },
    {
      "PART NUMBER": 4110001525019,
      "DESCRIPTION": "GASKET",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.5,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 12,
      "WH REFERENCE": "WH12",
      "TREND": 0
    },
    {
      "PART NUMBER": 4110001525005,
      "DESCRIPTION": "CYLINDER LINER",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 1,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 2,
      "APRD QTY": 0,
      "WH12": 1,
      "WH REFERENCE": "PARTIAL WH12",
      "TREND": 1
    },
    {
      "PART NUMBER": 4110001525011,
      "DESCRIPTION": "PISTON",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.5,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 5,
      "SHELF LIFE": 10,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 20,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 4110000846229,
      "DESCRIPTION": " RING 4110000909123",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.5,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 18,
      "WH REFERENCE": "WH12",
      "TREND": 0
    },
    {
      "PART NUMBER": "7114-12121",
      "DESCRIPTION": "SEALING",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 36,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": "7114-12240",
      "DESCRIPTION": "SEALING",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 91,
      "WH REFERENCE": "WH12",
      "TREND": 2
    },
    {
      "PART NUMBER": "8220-13470",
      "DESCRIPTION": "O-RING",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 21,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": "7114-17460",
      "DESCRIPTION": "O-RING",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 27,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 14528776,
      "DESCRIPTION": "SEALING",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 66,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 15185168,
      "DESCRIPTION": "SEALING KIT",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 5,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 17416725,
      "DESCRIPTION": "CONTACT KIT 15095373",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 21242584,
      "DESCRIPTION": "GASKET EC210B 20405790",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 14649606,
      "DESCRIPTION": "COMPRESSOR",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 14558072,
      "DESCRIPTION": "SHEET",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 21337557,
      "DESCRIPTION": "AIR FILTER-PRIMARY",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.25,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 21,
      "WH REFERENCE": "WH12",
      "TREND": 2
    },
    {
      "PART NUMBER": 21693755,
      "DESCRIPTION": "AIR FILTER ASSY",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.416666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 1,
      "SHELF LIFE": 2.400000000000004,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 1.6
    },
    {
      "PART NUMBER": 29050016931,
      "DESCRIPTION": "CIRCLIP",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 10503761,
      "DESCRIPTION": "WIRE ROPE QY50C.2-1A",
      "PRODUCT CATEGORY": "YCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 17429862,
      "DESCRIPTION": "MOIL POINT, TOOL VOLVO HB21",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 24,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 4043000035,
      "DESCRIPTION": "SPACER ",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.666666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 0,
      "WH REFERENCE": "RFC",
      "TREND": 2
    },
    {
      "PART NUMBER": 4041000767,
      "DESCRIPTION": "O-RING ",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 25,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 29070001271,
      "DESCRIPTION": "SPACER",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 1,
      "SHELF LIFE": 6.000000000000024,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 14683137,
      "DESCRIPTION": "FUSE BOX 14641189",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 4110000054262,
      "DESCRIPTION": "HOSE CLIP ",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.916666666666666,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 2,
      "APRD QTY": 2,
      "WH12": 0,
      "WH REFERENCE": "RFC",
      "TREND": 2
    },
    {
      "PART NUMBER": 4040000032,
      "DESCRIPTION": "BOLT",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 5.83333333333333,
      "MAINTANING STOCK QTY": 12,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 12,
      "APRD QTY": 12,
      "WH12": 0,
      "WH REFERENCE": "RFC",
      "TREND": 1.2
    },
    {
      "PART NUMBER": 29170049661,
      "DESCRIPTION": "SHIM",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 2.33333333333333,
      "MAINTANING STOCK QTY": 5,
      "AVAILABLE": 6,
      "SHELF LIFE": 2.571428571428575,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 15,
      "WH REFERENCE": "",
      "TREND": 1.5
    },
    {
      "PART NUMBER": 29170049671,
      "DESCRIPTION": "SHIM",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 2.33333333333333,
      "MAINTANING STOCK QTY": 5,
      "AVAILABLE": 6,
      "SHELF LIFE": 2.571428571428575,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 37,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 29050021561,
      "DESCRIPTION": "FLANGE",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 14589154,
      "DESCRIPTION": "SEALING KIT",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 4110002109,
      "DESCRIPTION": "4110015996 FAN",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 4110001903103,
      "DESCRIPTION": "BRAKE CYLINDER",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 33,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 4190002822,
      "DESCRIPTION": "EXPANSION VALVE",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 4190002825,
      "DESCRIPTION": "SENSOR",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 1,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 27,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 4190002765,
      "DESCRIPTION": "SWITCH",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 6,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": "430519Z00C",
      "DESCRIPTION": "SEALING RING",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": "443419Z00D",
      "DESCRIPTION": "SLACK ADJUSTER",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 21649619,
      "DESCRIPTION": "RADIATOR",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 22285860,
      "DESCRIPTION": "TIMING GEAR COVER",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 5,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 20970435,
      "DESCRIPTION": "TURBOCHARGER",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 15173598,
      "DESCRIPTION": "SEALING KIT",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 4,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 14557971,
      "DESCRIPTION": "SPROCKET EC140B",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 20459235,
      "DESCRIPTION": "STUD",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 35,
      "WH REFERENCE": "WH12",
      "TREND": 0
    },
    {
      "PART NUMBER": 21027485,
      "DESCRIPTION": "GASKET",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 34,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 21940615,
      "DESCRIPTION": "SEALING RING 1547255",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 9,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 4021000043,
      "DESCRIPTION": "SPHERICAL PLAIN BEARING",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 9017204355,
      "DESCRIPTION": "ALTERNATOR REMAN",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 17,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 14597943,
      "DESCRIPTION": "DOOR 14578507",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 14345423,
      "DESCRIPTION": "NIPPLE",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 5,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 4110001948041,
      "DESCRIPTION": "FILTER INSERT",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "AVERAGE (F2)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 1.25,
      "MAINTANING STOCK QTY": 3,
      "AVAILABLE": 3,
      "SHELF LIFE": 2.4,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 60,
      "WH REFERENCE": "",
      "TREND": 0.67
    },
    {
      "PART NUMBER": 29350010491,
      "DESCRIPTION": "FILTER NETTING",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.75,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 4,
      "SHELF LIFE": 5.333333333333333,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0.89
    },
    {
      "PART NUMBER": 29350010501,
      "DESCRIPTION": "FILTER NETTING",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.75,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 1,
      "SHELF LIFE": 1.3333333333333333,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 0,
      "WH REFERENCE": "RFC",
      "TREND": 0.89
    },
    {
      "PART NUMBER": 4190002863,
      "DESCRIPTION": "RESERVOIR",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 4110000924103,
      "DESCRIPTION": "WATER PUMP LG956L  ",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 7,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 24426013,
      "DESCRIPTION": "SHIM",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 4190000608,
      "DESCRIPTION": "ELECTRIC FAN",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 8,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 11444203,
      "DESCRIPTION": "PANEL",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 29250004091,
      "DESCRIPTION": "OIL TUBE",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 4011000197,
      "DESCRIPTION": "BOLT GB16674-M12*25EPZN-8.8",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.5,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 26,
      "WH REFERENCE": "WH12",
      "TREND": 1.33
    },
    {
      "PART NUMBER": 345,
      "DESCRIPTION": "STEERING POWER CYLINDER ASSEMBLY",
      "PRODUCT CATEGORY": "YCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 914462,
      "DESCRIPTION": "SNAP RING 9541-01030",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "RFC",
      "TREND": 0
    },
    {
      "PART NUMBER": 21990221,
      "DESCRIPTION": "VALVE STEM SEAL 20864662",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 8,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 10,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": "166959Z01D",
      "DESCRIPTION": "FUEL FILTER WATER SEPARATOR",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.666666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 35,
      "WH REFERENCE": "WH12",
      "TREND": 0.5
    },
    {
      "PART NUMBER": "119249Z01D",
      "DESCRIPTION": "BALL BEARING",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 7,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": "324879Z00D",
      "DESCRIPTION": "BEARING",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 8158197,
      "DESCRIPTION": "COMPRESSION SPRING",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 29050018231,
      "DESCRIPTION": "SHIM",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 29050018221,
      "DESCRIPTION": "SHIM",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 29050018241,
      "DESCRIPTION": "SHIM",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 14528726,
      "DESCRIPTION": "PIN",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 14521612,
      "DESCRIPTION": "O-RING",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 5,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 15066561,
      "DESCRIPTION": "O-RING",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 14515742,
      "DESCRIPTION": "FILTER ELEMENT",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 10,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 17411510,
      "DESCRIPTION": "EXPANSION TANK  EC210B/EC210D",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 2,
      "SHELF LIFE": 12.000000000000048,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 5,
      "WH REFERENCE": "",
      "TREND": 1
    },
    {
      "PART NUMBER": 14689735,
      "DESCRIPTION": "FILTER MAIN, CAB_14506997",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "FAST (F1)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 3.41666666666666,
      "MAINTANING STOCK QTY": 7,
      "AVAILABLE": 7,
      "SHELF LIFE": 2.048780487804882,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 1.12
    },
    {
      "PART NUMBER": 4030000768,
      "DESCRIPTION": "SEALING RING",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 0,
      "WH REFERENCE": "RFC",
      "TREND": 2
    },
    {
      "PART NUMBER": 29050012751,
      "DESCRIPTION": "SHIM",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 11,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 29050012741,
      "DESCRIPTION": "SHIM",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 5,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 29050012731,
      "DESCRIPTION": "SHIM",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 9,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 16680661,
      "DESCRIPTION": "KIT",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 20,
      "WH REFERENCE": "",
      "TREND": 1
    },
    {
      "PART NUMBER": "440669Z01D",
      "DESCRIPTION": "BRAKE LINING KIT 410399Z00A ",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 22610786,
      "DESCRIPTION": "SPACER WASHER ",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 4,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 11110708,
      "DESCRIPTION": "54864268 FILTER RETAINER, FWS 11110702",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 0,
      "WH REFERENCE": "RFC",
      "TREND": 0
    },
    {
      "PART NUMBER": 22567321,
      "DESCRIPTION": "24078863 COOLANT (READY MIXED) 5L/GAL YELLOW  85108913",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "AVERAGE (F2)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 6,
      "MAINTANING STOCK QTY": 12,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 12,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "RFC",
      "TREND": 0.72
    },
    {
      "PART NUMBER": 14671764,
      "DESCRIPTION": "RECOIL SPRING",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 6,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 60209845,
      "DESCRIPTION": "CENTRAL CONDUCTIVE RING",
      "PRODUCT CATEGORY": "YPG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 14594707,
      "DESCRIPTION": "14794925 ECU",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 14622759,
      "DESCRIPTION": "FLANGE 14500083",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 992340,
      "DESCRIPTION": "FLANGE SCREW",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 13,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 82358216,
      "DESCRIPTION": "MODULE",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 1,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 5,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": "440669Z01B",
      "DESCRIPTION": "BRAKE LINING  KIT 440669Z00B ",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 8,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 41,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 4120005488006,
      "DESCRIPTION": "SEALING RING 4120004039003",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 22768937,
      "DESCRIPTION": "TAPPET",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 22075800,
      "DESCRIPTION": "ANCHORAGE",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 21825534,
      "DESCRIPTION": "CROSS MEMBER",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 4120001058,
      "DESCRIPTION": "GEAR PUMP CBGJ3100/1010-XF",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 17418256,
      "DESCRIPTION": "V RIBBED BELT ",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.25,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 7,
      "WH REFERENCE": "WH12",
      "TREND": 0.67
    },
    {
      "PART NUMBER": 4110001948021,
      "DESCRIPTION": "BEARING SHELL",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 25,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 4110000991013,
      "DESCRIPTION": "RUBBER HOSE",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 13,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": "4120000090Z",
      "DESCRIPTION": "PNEUMATIC CYLINDER 4120000090",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 10,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 21414908,
      "DESCRIPTION": "HOSE 20711411",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 4,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 14550595,
      "DESCRIPTION": "IDLER",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 14592566,
      "DESCRIPTION": "LAMP",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 5,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 4120000868101,
      "DESCRIPTION": "SEALING MEMBER AGGREGATE",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 4120001004101,
      "DESCRIPTION": "SEALING MEMBER AGGREGATE",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 14602601,
      "DESCRIPTION": "HOSE",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 13933900,
      "DESCRIPTION": "ELBOW NIPPLE",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 4120005062,
      "DESCRIPTION": "BREATHER CAP",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 11217633,
      "DESCRIPTION": "STARTER SWITCH 11215151 11211219",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 1,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 9,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 4110000054094,
      "DESCRIPTION": "THERMOSTAT 13020684",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 1,
      "SHELF LIFE": 12.000000000000005,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 20924088,
      "DESCRIPTION": "SENSOR",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 14591829,
      "DESCRIPTION": "FUEL FILLING PUMP",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 20412318,
      "DESCRIPTION": "STUD",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 38,
      "WH REFERENCE": "WH12",
      "TREND": 0
    },
    {
      "PART NUMBER": 4110001031037,
      "DESCRIPTION": "BELT 13033963",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 31,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 20460055,
      "DESCRIPTION": "SCREW",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 10,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 4110002247100,
      "DESCRIPTION": "FILTER INSERT",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 4,
      "SHELF LIFE": 48.00000000000002,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 34,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 15035179,
      "DESCRIPTION": "FILTER, PILOT",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "FAST (F1)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 3.25,
      "MAINTANING STOCK QTY": 7,
      "AVAILABLE": 6,
      "SHELF LIFE": 1.8461538461538463,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 52,
      "WH REFERENCE": "WH12",
      "TREND": 1.33
    },
    {
      "PART NUMBER": 22327063,
      "DESCRIPTION": "SOLENOID VALVE",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 6,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": "302009Z01E",
      "DESCRIPTION": "CLUTCH",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 22335631,
      "DESCRIPTION": "FILLER CAP ",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 9060100000,
      "DESCRIPTION": "ECU REMAN",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 14,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 4110000054266,
      "DESCRIPTION": "LUBRICAT.OIL LINE 12187856",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 14658433,
      "DESCRIPTION": "PIN",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.25,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 6,
      "WH REFERENCE": "WH12",
      "TREND": 1.33
    },
    {
      "PART NUMBER": 14701884,
      "DESCRIPTION": "DOOR 14634563",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 4014000033,
      "DESCRIPTION": "SCREW",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 13,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 29070012671,
      "DESCRIPTION": "SUN GEAR",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 24,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 29070012711,
      "DESCRIPTION": "PLANET GEAR",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 11,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 183680,
      "DESCRIPTION": "SPHERICAL PLAIN BEARING 14013647 19631",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 28140009601,
      "DESCRIPTION": "CUTTING EDGE",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 2,
      "SHELF LIFE": 6.000000000000006,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 28140009611,
      "DESCRIPTION": "SCREW",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 5,
      "MAINTANING STOCK QTY": 10,
      "AVAILABLE": 15,
      "SHELF LIFE": 3,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 58,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 28140010441,
      "DESCRIPTION": "CUTTING EDGE",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 16,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 4110002321001,
      "DESCRIPTION": "AIR FILTER INSERT",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.666666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 1,
      "SHELF LIFE": 1.5000000000000016,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 9,
      "WH REFERENCE": "",
      "TREND": 0.5
    },
    {
      "PART NUMBER": 21092586,
      "DESCRIPTION": "TURBOCHARGER",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 8,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 14576499,
      "DESCRIPTION": "BRACKET",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 8,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 4130000202,
      "DESCRIPTION": "ENGINE WATER TEMPERATURE SENSOR WDG1371",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 4110000846152,
      "DESCRIPTION": "BEARING SHELL",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 16,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 4110000846153,
      "DESCRIPTION": "BEARING SHELL",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 11,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 4110000054295,
      "DESCRIPTION": "BUSHING",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 4110000846135,
      "DESCRIPTION": "BUSHING",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 4110000054235,
      "DESCRIPTION": "GASKET",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.5,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 0,
      "WH REFERENCE": "RFC",
      "TREND": 2
    },
    {
      "PART NUMBER": 4110000054083,
      "DESCRIPTION": "OIL PUMP",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 17412747,
      "DESCRIPTION": "SEALING RING",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 6,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 85140107,
      "DESCRIPTION": "O-RING KIT",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 7,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 20799065,
      "DESCRIPTION": "HOSE",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 22144327,
      "DESCRIPTION": "23446987 BATTERY (MAINTENANCE FREE)",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 4110000555274,
      "DESCRIPTION": "SOLENOID",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 9,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 21774593,
      "DESCRIPTION": "BEARING BUSHING",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 29250000101,
      "DESCRIPTION": "SHIM",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 6,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 29250000111,
      "DESCRIPTION": "SHIM",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 6,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 29250000121,
      "DESCRIPTION": "SHIM",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 6,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 82356758,
      "DESCRIPTION": "O-RING",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 17,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 82356760,
      "DESCRIPTION": "O-RING",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 11,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 21396128,
      "DESCRIPTION": "SENSOR 20520111",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 2,
      "SHELF LIFE": 6.000000000000006,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 17,
      "WH REFERENCE": "",
      "TREND": 1.5
    },
    {
      "PART NUMBER": "LF16352",
      "DESCRIPTION": "LUBEFILTER",
      "PRODUCT CATEGORY": "CUF",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 4120004037001,
      "DESCRIPTION": "FILTER INSERT",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.416666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 1,
      "SHELF LIFE": 2.400000000000004,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 23,
      "WH REFERENCE": "",
      "TREND": 0.8
    },
    {
      "PART NUMBER": 29070015101,
      "DESCRIPTION": "SEALING COVER",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.25,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "RFC",
      "TREND": 0.67
    },
    {
      "PART NUMBER": 29070015111,
      "DESCRIPTION": "DUST COVER",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 13,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 20409787,
      "DESCRIPTION": "POSITION LAMP",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 4,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 84139925,
      "DESCRIPTION": "DIRECTION INDICATOR",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": "272139Z00A",
      "DESCRIPTION": "DESICCANT ADAPTED QTY 5",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 48,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 21583806,
      "DESCRIPTION": "KIT",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 1
    },
    {
      "PART NUMBER": 20409788,
      "DESCRIPTION": "POSITION LAMP",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 1,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": "7114-17250",
      "DESCRIPTION": "ROLLER BEARING_",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 5,
      "WH REFERENCE": "WH12",
      "TREND": 2
    },
    {
      "PART NUMBER": 14646872,
      "DESCRIPTION": "FILTER ELEMENT EW145B S/N: 19764-",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.5,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 1,
      "SHELF LIFE": 2,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WH REFERENCE": "",
      "TREND": 1
    },
    {
      "PART NUMBER": 14680534,
      "DESCRIPTION": "TOOTH  (ET)  EC140B/EW145B GP 14527863",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 3.83333333333333,
      "MAINTANING STOCK QTY": 8,
      "AVAILABLE": 8,
      "SHELF LIFE": 2.086956521739132,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 148,
      "WH REFERENCE": "",
      "TREND": 0.65
    },
    {
      "PART NUMBER": 14680532,
      "DESCRIPTION": "TOOTH (ET)  EC210B/D EC220D  GP ",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 29070013111,
      "DESCRIPTION": "GEAR RING",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 14552686,
      "DESCRIPTION": "SWING MOTOR",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": "7114-14370",
      "DESCRIPTION": "SPACER",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 4,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 5222677134,
      "DESCRIPTION": "FUEL FILTER 166959Z01C",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.583333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 4,
      "SHELF LIFE": 6.85714285714286,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 23,
      "WH REFERENCE": "",
      "TREND": 0.57
    },
    {
      "PART NUMBER": 4110000909011,
      "DESCRIPTION": "SOLENOID",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 5,
      "WH REFERENCE": "",
      "TREND": 1
    },
    {
      "PART NUMBER": 4030001002,
      "DESCRIPTION": "SEALING RING",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 6,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 5282682780,
      "DESCRIPTION": "CAB CELULOSE FILTER",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.25,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 9,
      "SHELF LIFE": 36,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WH REFERENCE": "",
      "TREND": 0.67
    },
    {
      "PART NUMBER": 14679709,
      "DESCRIPTION": "GLASS",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.416666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 1,
      "SHELF LIFE": 2.400000000000004,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WH REFERENCE": "",
      "TREND": 1.2
    },
    {
      "PART NUMBER": 22574941,
      "DESCRIPTION": "STUB",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 14,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 60017576,
      "DESCRIPTION": "KING PIN",
      "PRODUCT CATEGORY": "YPG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 11,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 14680749,
      "DESCRIPTION": "SHAFT 14609430 ",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 6668819,
      "DESCRIPTION": "FILTER OIL HYD",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 1,
      "SHELF LIFE": 12.000000000000005,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 22,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 7146391,
      "DESCRIPTION": "BELT, DRIVE  S510/S570",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 106,
      "WH REFERENCE": "",
      "TREND": 1
    },
    {
      "PART NUMBER": 7143498,
      "DESCRIPTION": "BELT ALTERNATOR ",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 45,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 6667352,
      "DESCRIPTION": "FILTER, FUEL W/SEPARATOR 12",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "AVERAGE (F2)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.916666666666666,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 2,
      "SHELF LIFE": 2.1818181818181834,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 218,
      "WH REFERENCE": "",
      "TREND": 0.73
    },
    {
      "PART NUMBER": 6666333,
      "DESCRIPTION": "FILTER, AIR OUTER",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 6666334,
      "DESCRIPTION": "FILTER AIR INNER",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 9,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 6675517,
      "DESCRIPTION": "FILTER OIL ENG 12",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.416666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 1,
      "SHELF LIFE": 2.400000000000004,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 155,
      "WH REFERENCE": "",
      "TREND": 1.2
    },
    {
      "PART NUMBER": 6666375,
      "DESCRIPTION": "FILTER AIR OUTER",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.25,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 1,
      "SHELF LIFE": 4,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 69,
      "WH REFERENCE": "",
      "TREND": 1.33
    },
    {
      "PART NUMBER": 6666376,
      "DESCRIPTION": "FILTER AIR INNER",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.25,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 1,
      "SHELF LIFE": 4,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 26,
      "WH REFERENCE": "",
      "TREND": 1.33
    },
    {
      "PART NUMBER": 4110001383201,
      "DESCRIPTION": "HYDRAULIC OIL COOLER",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 1,
      "SHELF LIFE": 12.000000000000005,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 82437188,
      "DESCRIPTION": "FRONT PANEL",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 21392404,
      "DESCRIPTION": "20580233 STEERING FILTER",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": "EP00",
      "DESCRIPTION": "GREASE PER PAIL (PAIL/35LBS/16KG)",
      "PRODUCT CATEGORY": "LUB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 14633688,
      "DESCRIPTION": "CONNECTING LEVER",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 14564695,
      "DESCRIPTION": "PIN",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 14564696,
      "DESCRIPTION": "PIN",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 6,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": "8220-09560",
      "DESCRIPTION": "WASHER_STEERING",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": "7114-14410",
      "DESCRIPTION": "14719107 BOLT_STOP",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": "7114-14200",
      "DESCRIPTION": "ROD END_ASSY LH",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": "7114-14220",
      "DESCRIPTION": "BALL BEARING",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 29250004081,
      "DESCRIPTION": "PIN",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 992453,
      "DESCRIPTION": "FUSE",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.583333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 21,
      "WH REFERENCE": "WH12",
      "TREND": 0
    },
    {
      "PART NUMBER": 4110000054316,
      "DESCRIPTION": "TURBOCHARGER",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 1,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 17457469,
      "DESCRIPTION": "17535679 OIL FILTER 3831236 ",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.666666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 0,
      "WH REFERENCE": "RFC",
      "TREND": 2
    },
    {
      "PART NUMBER": 22739649,
      "DESCRIPTION": "BEARING BRACKET 20999729 21901728  EC210BLC",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 6,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 15057777,
      "DESCRIPTION": "BIG-END BEARING KIT",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 14695920,
      "DESCRIPTION": "HOSE_DISCHARGE EC210B 14601887 ",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 14686427,
      "DESCRIPTION": "SPROCKET ENDURANCE EC140B/EC210B",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.25,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 1,
      "SHELF LIFE": 4,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 9,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 29250008711,
      "DESCRIPTION": "NUT",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 4021000134,
      "DESCRIPTION": "ROLLER BEARING",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 4,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 29250011271,
      "DESCRIPTION": "SLEEVE",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 29250011761,
      "DESCRIPTION": "SLEEVE",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 1,
      "SHELF LIFE": 12.000000000000005,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 29250011281,
      "DESCRIPTION": "SLEEVE",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 29250011291,
      "DESCRIPTION": "SLEEVE",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 1,
      "SHELF LIFE": 12.000000000000005,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 14715404,
      "DESCRIPTION": "IDLER ENDURANCE  EC210 14695200 14686450",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 39,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 22384866,
      "DESCRIPTION": "STARTER SWITCH",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 5,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 22472464,
      "DESCRIPTION": "BEARING BUSHING 20459998",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 22662214,
      "DESCRIPTION": "CONNECTING CABLE 20450687",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 1,
      "SHELF LIFE": 6.000000000000024,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 5,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 5222748702,
      "DESCRIPTION": "FUEL FILTER WATER SEPARATOR 164009Z00C ",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 11,
      "SHELF LIFE": 132.00000000000006,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 77,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 4120006350,
      "DESCRIPTION": "AIR CYLINDER LG956L 4120006350Z",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 4,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 4110000991053,
      "DESCRIPTION": "HAND PUMP",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 29250015711,
      "DESCRIPTION": "PIN",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 22,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 29250011341,
      "DESCRIPTION": "SHIM",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.25,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 0,
      "WH REFERENCE": "RFC",
      "TREND": 2
    },
    {
      "PART NUMBER": 29250011321,
      "DESCRIPTION": "SHIM",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 14882685,
      "DESCRIPTION": "ORING",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 50,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": "1109-27/23",
      "DESCRIPTION": "AIR FILTER KIT (PRIMARY+SECONDARY)",
      "PRODUCT CATEGORY": "JJG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 14640021,
      "DESCRIPTION": " BALL BEARING",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 5,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 14687316,
      "DESCRIPTION": "ENDURANCE, ROLLER, TOP EC210B (ET) 14545571 ",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 1,
      "SHELF LIFE": 3.000000000000003,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 111,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 14686458,
      "DESCRIPTION": "ENDURANCE. TRACK LINK ASSY 49L EC210BLC  14530361",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": "7114-12260",
      "DESCRIPTION": "SHIM",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 0,
      "WH REFERENCE": "RFC",
      "TREND": 2
    },
    {
      "PART NUMBER": "7114-12270",
      "DESCRIPTION": "SHIM",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 0,
      "WH REFERENCE": "RFC",
      "TREND": 2
    },
    {
      "PART NUMBER": "7114-12280",
      "DESCRIPTION": "SHIM",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 0,
      "WH REFERENCE": "RFC",
      "TREND": 2
    },
    {
      "PART NUMBER": "7114-12290",
      "DESCRIPTION": "SHIM",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 0,
      "WH REFERENCE": "RFC",
      "TREND": 2
    },
    {
      "PART NUMBER": "7114-12230",
      "DESCRIPTION": "PIN",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 0,
      "WH REFERENCE": "RFC",
      "TREND": 2
    },
    {
      "PART NUMBER": "9612-05030",
      "DESCRIPTION": "BEARING 14880949",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 8,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 14882341,
      "DESCRIPTION": "HOSE",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": "FF5706",
      "DESCRIPTION": "FUEL FILTER",
      "PRODUCT CATEGORY": "CUF",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 4011000106,
      "DESCRIPTION": "BOLT",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 4021000368,
      "DESCRIPTION": "ROLLER BEARING 2810000340006",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 87,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 4021000322,
      "DESCRIPTION": "BEARING  2810000340011",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 98,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 14688861,
      "DESCRIPTION": "FILTER ELEMENT (EW140/EW145) 14539482_",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "FAST (F1)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 3.16666666666666,
      "MAINTANING STOCK QTY": 6,
      "AVAILABLE": 13,
      "SHELF LIFE": 4.105263157894746,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 53,
      "WH REFERENCE": "",
      "TREND": 1.16
    },
    {
      "PART NUMBER": 14711981,
      "DESCRIPTION": "PILOT ELEMENT (EW140/EW145) 14532686",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "AVERAGE (F2)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 1.83333333333333,
      "MAINTANING STOCK QTY": 4,
      "AVAILABLE": 16,
      "SHELF LIFE": 8.727272727272743,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 51,
      "WH REFERENCE": "",
      "TREND": 1
    },
    {
      "PART NUMBER": 14658434,
      "DESCRIPTION": "PIN",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 14650586,
      "DESCRIPTION": "BUSHING",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 4130001731,
      "DESCRIPTION": "LAMP LG956L",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 15,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 21792306,
      "DESCRIPTION": "GASKET 20559834",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 14,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 7009365,
      "DESCRIPTION": "HYDRAULIC OIL FILTER",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 1,
      "SHELF LIFE": 12.000000000000005,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 5,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 6516722,
      "DESCRIPTION": "HYDRAULIC OIL FILTER",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 3,
      "SHELF LIFE": 18.000000000000075,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 24,
      "WH REFERENCE": "",
      "TREND": 1
    },
    {
      "PART NUMBER": 6692836,
      "DESCRIPTION": "HYDRAULIC TANK CAP",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 1,
      "SHELF LIFE": 3.000000000000003,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 6,
      "WH REFERENCE": "",
      "TREND": 1.5
    },
    {
      "PART NUMBER": 7010894,
      "DESCRIPTION": "FAN BELT",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 7010252,
      "DESCRIPTION": "A/C COMPRESSOR BELT",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 4,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 7025590,
      "DESCRIPTION": "ENGINE OIL FILTER",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 2,
      "SHELF LIFE": 6.000000000000006,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 15,
      "WH REFERENCE": "",
      "TREND": 1
    },
    {
      "PART NUMBER": 7010351,
      "DESCRIPTION": "FUEL FILTER",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 2,
      "SHELF LIFE": 6.000000000000006,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 10,
      "WH REFERENCE": "",
      "TREND": 1
    },
    {
      "PART NUMBER": 7276395,
      "DESCRIPTION": "FILTER ELEMENT",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 9,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 7004883,
      "DESCRIPTION": "RETURN FILTER",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 0,
      "WH REFERENCE": "RFC",
      "TREND": 1
    },
    {
      "PART NUMBER": 7013148,
      "DESCRIPTION": "FUEL FILTER ELEMENT",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 8,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 7006085,
      "DESCRIPTION": "A/C FILTER",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.25,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 1,
      "SHELF LIFE": 4,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WH REFERENCE": "",
      "TREND": 0.67
    },
    {
      "PART NUMBER": 7005577,
      "DESCRIPTION": "COMPRESSOR BELT",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 12007782,
      "DESCRIPTION": "FRONT WINDSCREEN (CAB) STC250",
      "PRODUCT CATEGORY": "YCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 4110000054039,
      "DESCRIPTION": "GASKET",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.25,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "RFC",
      "TREND": 0
    },
    {
      "PART NUMBER": 5222748694,
      "DESCRIPTION": "FUEL FILTER 152009Z00D",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 64,
      "WH REFERENCE": "",
      "TREND": 1
    },
    {
      "PART NUMBER": 16688307,
      "DESCRIPTION": "SENSOR 14509344 ",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 13,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 982320,
      "DESCRIPTION": "FUSE",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 9,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 6735884,
      "DESCRIPTION": "IDLER ASSY, BELT DRIVE",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 29,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 4110003158,
      "DESCRIPTION": "BRAKE SHOE KIT",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 14,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 992336,
      "DESCRIPTION": "FLANGE SCREW",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 5,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 29050011362,
      "DESCRIPTION": "29050011363  GASKET",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 17,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 14679620,
      "DESCRIPTION": "GLASS EC210D",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 6,
      "WH REFERENCE": "",
      "TREND": 1
    },
    {
      "PART NUMBER": 22803123,
      "DESCRIPTION": "24046348 FUEL PUMP 20980697 ",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 6714212,
      "DESCRIPTION": "HYDRAULIC PUMP IDLER PULLEY  S510/S570",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 17470368,
      "DESCRIPTION": "VCS COOLANT (40:60 READY MIXED) 18L/PAIL YELLOW ",
      "PRODUCT CATEGORY": "LUV",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 7.5,
      "MAINTANING STOCK QTY": 15,
      "AVAILABLE": 90,
      "SHELF LIFE": 12,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 4867,
      "WH REFERENCE": "",
      "TREND": 0.8
    },
    {
      "PART NUMBER": 82356810,
      "DESCRIPTION": "GLASS FH400",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 22902431,
      "DESCRIPTION": "24070789 PUMP UNIT FH400 22197705 FM10/FM340/FM370/FM400/EC700B ",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 22603162,
      "DESCRIPTION": "ANGLE BALL JOINT FH400",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 4110000054118,
      "DESCRIPTION": "PULLEY",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": "7114-14390",
      "DESCRIPTION": "CUP_LH",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 9021092586,
      "DESCRIPTION": "TURBOCHARGER REMAN EC210D",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 4,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 14686465,
      "DESCRIPTION": "TRACK SHOE, ENDURANCE",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 7,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 20,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 14715403,
      "DESCRIPTION": "BOTTOM ROLLER, ENDURANCE EC210B",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.666666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 78,
      "WH REFERENCE": "WH12",
      "TREND": 2
    },
    {
      "PART NUMBER": 4110000127008,
      "DESCRIPTION": "FUEL PUMP LG936L",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 8,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 4130001732,
      "DESCRIPTION": "LAMP",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 85020562,
      "DESCRIPTION": "CHARGE AIR COOLER, EXCH FM(4)",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 22383382,
      "DESCRIPTION": "LEVEL SENSOR",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 4,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 22905123,
      "DESCRIPTION": "24046345 FUEL PUMP 21620116 21019945",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 4110001031031,
      "DESCRIPTION": "CONNECTOR 4110001525018",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.5,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 8,
      "WH REFERENCE": "WH12",
      "TREND": 2
    },
    {
      "PART NUMBER": 1161937,
      "DESCRIPTION": "TRANSMISSION OIL 85W-140  20L ",
      "PRODUCT CATEGORY": "LUV",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 14.3333333333333,
      "MAINTANING STOCK QTY": 29,
      "AVAILABLE": 96,
      "SHELF LIFE": 6.697674418604667,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2972,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 22089218,
      "DESCRIPTION": "BELT TENSIONER EC210D",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 8,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 7161331,
      "DESCRIPTION": "ARM CYLINDER SEAL KIT-E35",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 4110000054299,
      "DESCRIPTION": "4110001948017 HOSE",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 4110000054330,
      "DESCRIPTION": "BEARING SHELL 4110000909167 4110000909166 ",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.583333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 5,
      "SHELF LIFE": 8.571428571428577,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 29,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": "4120006350Z",
      "DESCRIPTION": "4120006350  AIR CYLINDER",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 14560268,
      "DESCRIPTION": "O-RING",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 18,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 14560264,
      "DESCRIPTION": "RING",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 8,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 4043000026,
      "DESCRIPTION": "BUSHING",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 14686569,
      "DESCRIPTION": "WIRE",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 19,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 14690316,
      "DESCRIPTION": "FILTER ELEMENT 14569658",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 32,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 5222889270,
      "DESCRIPTION": "GEAR SHIFT LEVER C 328359Z00A ",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 60110356,
      "DESCRIPTION": "SCREW 990514 1629544 ",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 268,
      "WH REFERENCE": "WH12",
      "TREND": 2
    },
    {
      "PART NUMBER": 14693033,
      "DESCRIPTION": "BEARING 7117-30110",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 4110000054192,
      "DESCRIPTION": "SEALING RING",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 4110000054194,
      "DESCRIPTION": "SEALING RING",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 4110000054170,
      "DESCRIPTION": "SCREW",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 4110000054203,
      "DESCRIPTION": "SCREW",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 60208877,
      "DESCRIPTION": "FILTRATION CORE",
      "PRODUCT CATEGORY": "YCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 5,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 22435058,
      "DESCRIPTION": "SENSOR 20820494",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 4,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 4110000054092,
      "DESCRIPTION": "BRACKET",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 4110000557016,
      "DESCRIPTION": "STUD",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 19,
      "WH REFERENCE": "WH12",
      "TREND": 0
    },
    {
      "PART NUMBER": 4110000846100,
      "DESCRIPTION": "WASHER",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 4110000054117,
      "DESCRIPTION": "HOSE ASSEMBLY",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 14640020,
      "DESCRIPTION": "SUPPORT 14534462 ",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 14639732,
      "DESCRIPTION": "CONNECTOR 14537347",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 14882680,
      "DESCRIPTION": "O-RING",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 24,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 15067513,
      "DESCRIPTION": "SUPER HYD OIL VG46",
      "PRODUCT CATEGORY": "LUV",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3444,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 4110003167001,
      "DESCRIPTION": " TRANSMISSION OIL FILTER L956F",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "AVERAGE (F2)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 1.41666666666666,
      "MAINTANING STOCK QTY": 3,
      "AVAILABLE": 12,
      "SHELF LIFE": 8.470588235294157,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 1.41
    },
    {
      "PART NUMBER": 4110001755023,
      "DESCRIPTION": "AIR FILTER LG956F",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.833333333333333,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 6,
      "SHELF LIFE": 7.200000000000003,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 1
    },
    {
      "PART NUMBER": 4110001544001,
      "DESCRIPTION": "AIR FILTER INSERT LG956F",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.75,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 6,
      "SHELF LIFE": 8,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 132,
      "WH REFERENCE": "",
      "TREND": 0.89
    },
    {
      "PART NUMBER": 14560269,
      "DESCRIPTION": "O-RING",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 8,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 14560273,
      "DESCRIPTION": "RING",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 9,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 14560272,
      "DESCRIPTION": "O-RING",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 11704997,
      "DESCRIPTION": "SEALING RING",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 11704999,
      "DESCRIPTION": "BACK-UP RING",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 9,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 22821828,
      "DESCRIPTION": "EXPANSION TANK 22430366 ",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 5,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": "B222100000641",
      "DESCRIPTION": "PRIMARY AIR FILTER",
      "PRODUCT CATEGORY": "YCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": "B222100000640",
      "DESCRIPTION": "SECONDARY AIR FILTER",
      "PRODUCT CATEGORY": "YCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 60274433,
      "DESCRIPTION": "OIL FILTER ",
      "PRODUCT CATEGORY": "YPG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 14592564,
      "DESCRIPTION": "BODY",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 14685140,
      "DESCRIPTION": "ROTATING BEACON",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 14685843,
      "DESCRIPTION": "HAND RAIL 14528890",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 14687315,
      "DESCRIPTION": "TOP ROLLER, ENDURANCE EC140B (EU)",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 14,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 14708935,
      "DESCRIPTION": "MIRROR 14639120",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 4,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 14715425,
      "DESCRIPTION": "IDLER, ENDURANCE EC140B (EU)",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 6,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 14695898,
      "DESCRIPTION": "CHARGE AIR HOSE",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 14640023,
      "DESCRIPTION": "ROLLER BEARING  7114-12490",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 997459,
      "DESCRIPTION": "HEXAGON SCREW 978936",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 183,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 60222777,
      "DESCRIPTION": "OIL SUCTION FILTER CORE",
      "PRODUCT CATEGORY": "YCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 11,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 12802324,
      "DESCRIPTION": "SHOCK ABSORBER",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 4120002513,
      "DESCRIPTION": "GEAR PUMP",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 29110000043,
      "DESCRIPTION": "GASKET 29110000042",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 19,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 60110025,
      "DESCRIPTION": "HEXAGON SCREW 991649 ",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.5,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 1,
      "SHELF LIFE": 2,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 4016000043,
      "DESCRIPTION": "SPLIT PIN",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 13,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 21793270,
      "DESCRIPTION": "GASKET EC210B/G930 20559835",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 10,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 4110000556263,
      "DESCRIPTION": "ALTERNATOR",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 17418113,
      "DESCRIPTION": "FILTER CARTRIDGE, HYDRAULIC  11037868",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 6,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 14614042,
      "DESCRIPTION": "WASHER PUMP",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 14614044,
      "DESCRIPTION": "WASHER PUMP",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 14691235,
      "DESCRIPTION": "SEAL",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 6,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 6655216,
      "DESCRIPTION": "PUMP, FUEL",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 4130001290,
      "DESCRIPTION": "BUZZER",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 12,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 29010009212,
      "DESCRIPTION": "CONTROL CABLE",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 14679691,
      "DESCRIPTION": "WINDSHIELD EC210D",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 14674063,
      "DESCRIPTION": "SEALING KIT",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 9,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 22851149,
      "DESCRIPTION": "24040481 STARTER MOTOR 20880693 21306350 ",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 22967564,
      "DESCRIPTION": "MAIN BEARING 20850106",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 19,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 4110002483001,
      "DESCRIPTION": "STARTER MOTOR",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 1
    },
    {
      "PART NUMBER": 4110003450018,
      "DESCRIPTION": "TURBOCHARGER",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 1,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 20794115,
      "DESCRIPTION": "RETAINING RING",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 26,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 60252396,
      "DESCRIPTION": "FUEL/WATER SEPARATOR ELEMENT ",
      "PRODUCT CATEGORY": "YCG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 10,
      "WH REFERENCE": "",
      "TREND": 1
    },
    {
      "PART NUMBER": "A222100000380",
      "DESCRIPTION": "OIL FILTER",
      "PRODUCT CATEGORY": "YCG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 45,
      "WH REFERENCE": "",
      "TREND": 1
    },
    {
      "PART NUMBER": 14702253,
      "DESCRIPTION": "HORN 14545502 14654752 ",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.416666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 1,
      "SHELF LIFE": 2.400000000000004,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 19,
      "WH REFERENCE": "",
      "TREND": 0.8
    },
    {
      "PART NUMBER": 4030000950,
      "DESCRIPTION": "V-RING",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": "165289Z01A",
      "DESCRIPTION": "AIR FILTER-PRIMARY",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": "165289Z00E",
      "DESCRIPTION": "AIR FILTER INSERT - CRONER 14T",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 5222802305,
      "DESCRIPTION": "V-RIBBED BELT - CRONER",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": "262619Z01C",
      "DESCRIPTION": "BULB CRONER",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": "47500NA04D",
      "DESCRIPTION": "AIR DRIER FILTER KIT",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": "276949Z00B",
      "DESCRIPTION": "CAB FILTER",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 14521527,
      "DESCRIPTION": "VALVE SEAL",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 29070020651,
      "DESCRIPTION": "BRAKE DISC",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 2,
      "SHELF LIFE": 24.00000000000001,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 5,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 4013000064,
      "DESCRIPTION": "LOCK NUT",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 2.66666666666666,
      "MAINTANING STOCK QTY": 5,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 5,
      "APRD QTY": 5,
      "WH12": 0,
      "WH REFERENCE": "RFC",
      "TREND": 2
    },
    {
      "PART NUMBER": 29090001031,
      "DESCRIPTION": "SCREW",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.666666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 16,
      "WH REFERENCE": "WH12",
      "TREND": 2
    },
    {
      "PART NUMBER": 17438619,
      "DESCRIPTION": "FILTER ELEMENT 11445025 ",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 146,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 14558017,
      "DESCRIPTION": "COVER",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 4,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 14640024,
      "DESCRIPTION": "ROLLER BEARING",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 4043000127,
      "DESCRIPTION": "SEALING RING",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 2,
      "MAINTANING STOCK QTY": 4,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 4,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "RFC",
      "TREND": 1
    },
    {
      "PART NUMBER": 29250011261,
      "DESCRIPTION": "FLANGE",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 29250011301,
      "DESCRIPTION": "FLANGE",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 4011001122,
      "DESCRIPTION": "SCREW",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.666666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 10,
      "WH REFERENCE": "WH12",
      "TREND": 2
    },
    {
      "PART NUMBER": 29250014591,
      "DESCRIPTION": "LUBRICATING PIPE",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 84044658,
      "DESCRIPTION": "SIDE MARKING LAMP",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 7,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 60193266,
      "DESCRIPTION": "HYDRAULIC RETURN FILTER * SCC550A / SCC750A",
      "PRODUCT CATEGORY": "YPG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 5,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 4110003071,
      "DESCRIPTION": "PROPELLER SHAFT",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.25,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "RFC",
      "TREND": 0.67
    },
    {
      "PART NUMBER": 4110000991019,
      "DESCRIPTION": "OIL COOLER",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 12,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 60263488,
      "DESCRIPTION": "OIL FILTER",
      "PRODUCT CATEGORY": "YTG",
      "ITEM MOVEMENT BRANCH": "AVERAGE (F2)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 2.33333333333333,
      "MAINTANING STOCK QTY": 5,
      "AVAILABLE": 6,
      "SHELF LIFE": 2.571428571428575,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0.29
    },
    {
      "PART NUMBER": 85135446,
      "DESCRIPTION": "OIL, COMPRESSOR 125ML FM10/A30C 1161629 ",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 14,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 5222845321,
      "DESCRIPTION": "RUBBER SPRING 552419Z00C ",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 20,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 5223236938,
      "DESCRIPTION": "SEALING RING 432359Z00A",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 16668474,
      "DESCRIPTION": "15711010 FUEL FILTER",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 1,
      "SHELF LIFE": 12.000000000000005,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 17201956,
      "DESCRIPTION": "FILTER CARTRIDGE",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 1,
      "SHELF LIFE": 12.000000000000005,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 5,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 17324107,
      "DESCRIPTION": "15714574 FILTER CARTRIDGE 43977461 ",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 14522199,
      "DESCRIPTION": "FAN",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 4,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 14585975,
      "DESCRIPTION": "FAN SHROUD",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 14725906,
      "DESCRIPTION": "GAUGE 14532362 1141-01720",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 1,
      "SHELF LIFE": 3.000000000000003,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 22,
      "WH REFERENCE": "",
      "TREND": 1.5
    },
    {
      "PART NUMBER": 5223236940,
      "DESCRIPTION": "SEALING RING 432359Z00B",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 85020561,
      "DESCRIPTION": "RADIATOR, EXCH",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 23111328,
      "DESCRIPTION": "SHOCK ABSORBER 21170510",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 4,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 29290037551,
      "DESCRIPTION": "WIPER MOTOR",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 22923707,
      "DESCRIPTION": "IDLER PULLEY 8086970",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 10,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 20992763,
      "DESCRIPTION": "AIR HOSE",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 11,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 4110002852014,
      "DESCRIPTION": "AIR FILTER INSERT",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.666666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 3,
      "SHELF LIFE": 4.500000000000004,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 1.5
    },
    {
      "PART NUMBER": 4130001709,
      "DESCRIPTION": "SWITCH",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 5223236891,
      "DESCRIPTION": "SEALING RING 400359Z00B",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 2,
      "SHELF LIFE": 12.000000000000048,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 129,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 60137527,
      "DESCRIPTION": "LIQUID RESERVOIR",
      "PRODUCT CATEGORY": "YCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 6,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": "A210609000354",
      "DESCRIPTION": "O-RING (175A)",
      "PRODUCT CATEGORY": "YPG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.833333333333333,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 1,
      "SHELF LIFE": 1.2000000000000004,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "RFC",
      "TREND": 0
    },
    {
      "PART NUMBER": 960698,
      "DESCRIPTION": "FILTER, OIL ENG",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 59,
      "WH REFERENCE": "",
      "TREND": 1
    },
    {
      "PART NUMBER": 6911907,
      "DESCRIPTION": "FUEL FILTER ELEMENT KIT 7406650",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 1
    },
    {
      "PART NUMBER": 22343374,
      "DESCRIPTION": "WIRING HARNESS",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 14702254,
      "DESCRIPTION": "HORN 14654753 14545503",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.416666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 19,
      "WH REFERENCE": "WH12",
      "TREND": 0.8
    },
    {
      "PART NUMBER": 4120007012,
      "DESCRIPTION": "SPRING BRAKE CHAMBER",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 23140886,
      "DESCRIPTION": "CONTROL UNIT 21855896",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 14663701,
      "DESCRIPTION": "NUT 14880573 ",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 61,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 273,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 997410,
      "DESCRIPTION": "HEXAGON NUT 96703657 979021 955785",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.5,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 224,
      "WH REFERENCE": "WH12",
      "TREND": 2
    },
    {
      "PART NUMBER": 4120008483012,
      "DESCRIPTION": "SEALING KIT",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 14617017,
      "DESCRIPTION": "GASKET",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 14709480,
      "DESCRIPTION": "IDLER 14656780",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 6,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 14589122,
      "DESCRIPTION": "SEALING KIT",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 1,
      "SHELF LIFE": 12.000000000000005,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 6578169,
      "DESCRIPTION": "HOSE (PER FOOT)",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.416666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "RFC",
      "TREND": 0
    },
    {
      "PART NUMBER": 15009280,
      "DESCRIPTION": "SEAL",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 13,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 5222702709,
      "DESCRIPTION": "FUEL LEVEL SENSOR",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 5,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 5223058616,
      "DESCRIPTION": "BRAKE CHAMBER 5222842686",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 5223236939,
      "DESCRIPTION": "SEALING RING 430519Z00D",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 14717312,
      "DESCRIPTION": "ROLLER EC140 14550631 ",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 12,
      "WH REFERENCE": "WH12",
      "TREND": 2
    },
    {
      "PART NUMBER": 12782905,
      "DESCRIPTION": "COVER",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 4110002791,
      "DESCRIPTION": "SEALING RING",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 1.16666666666666,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 2,
      "SHELF LIFE": 1.714285714285724,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 1.71
    },
    {
      "PART NUMBER": 14704792,
      "DESCRIPTION": "NET",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 4030000260,
      "DESCRIPTION": "SEALING RING",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 36,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 28100006661,
      "DESCRIPTION": "SPACER SLEEVE 4110001903120",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 50,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 28100006671,
      "DESCRIPTION": "SHIM",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 465,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 28100006681,
      "DESCRIPTION": "SHIM",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 16,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 28100006691,
      "DESCRIPTION": "SHIM",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 235,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 28100006701,
      "DESCRIPTION": "SHIM",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 49,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 28100006711,
      "DESCRIPTION": "SLEEVE",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 52,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 4030001195,
      "DESCRIPTION": "SEALING RING",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 50,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 28100006651,
      "DESCRIPTION": "SLEEVE",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 7,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 4030000258,
      "DESCRIPTION": "O-RING",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 171,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 28140010081,
      "DESCRIPTION": "CUTTING EDGE",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 9,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 11659727,
      "DESCRIPTION": "ASSEMBLY, BUMPERSJC6150S3.2800",
      "PRODUCT CATEGORY": "YCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 11251512,
      "DESCRIPTION": "LEFT SMALL STEP",
      "PRODUCT CATEGORY": "YCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 60110287,
      "DESCRIPTION": "PLAIN WASHER 992047",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.666666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 22,
      "SHELF LIFE": 33.000000000000036,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 525,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 22988765,
      "DESCRIPTION": "23044511 FUEL FILTER 21879886 ",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.25,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 1,
      "SHELF LIFE": 4,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 8,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 4110002902,
      "DESCRIPTION": "FAN",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 5227334,
      "DESCRIPTION": "ROLLER BEARING",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 14560265,
      "DESCRIPTION": "ROLLER BEARING",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": "4120006349Z",
      "DESCRIPTION": "AIR CYLINDER",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 4190002589,
      "DESCRIPTION": "WIPER BLADE",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 5,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 4130002221,
      "DESCRIPTION": "FUEL LEVEL SENSOR",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 60118361,
      "DESCRIPTION": "CLUTCH MASTER PUMP",
      "PRODUCT CATEGORY": "YPG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 1
    },
    {
      "PART NUMBER": 14719601,
      "DESCRIPTION": "BREATHER 1040-20311",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 7,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 7291240,
      "DESCRIPTION": "TRANSMISSION FILTER",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 10,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 7289231,
      "DESCRIPTION": "FUEL PREFILTER KIT",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 1
    },
    {
      "PART NUMBER": 22085772,
      "DESCRIPTION": "MODULATOR                ",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 29070021581,
      "DESCRIPTION": "BRAKE DISC",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 60110002,
      "DESCRIPTION": "HEXAGON SCREW 990520",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 1,
      "SHELF LIFE": 3.000000000000003,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 143,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 7290383,
      "DESCRIPTION": "CAP W/ BREATHER FILTER",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 7,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 7290371,
      "DESCRIPTION": "HYDRAULIC RETURN FILTER",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 4,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 7290377,
      "DESCRIPTION": "HYDRAULIC RETURN FILTER",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 5,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 16064048,
      "DESCRIPTION": "TIMER RELAY 16053915",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 10,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 96702386,
      "DESCRIPTION": "HEXAGON SCREW",
      "PRODUCT CATEGORY": "VCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 6,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 14684603,
      "DESCRIPTION": "WEATHERSTRIP",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 14729840,
      "DESCRIPTION": "SEAL 14601683 7117-30120 14528713",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 4,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 29290039031,
      "DESCRIPTION": "GLASS",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WH REFERENCE": "",
      "TREND": 1
    },
    {
      "PART NUMBER": 4030001196,
      "DESCRIPTION": "O-RING",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 6,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 28100006581,
      "DESCRIPTION": "CONNECTION  FLANGE",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 5,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 4120007867004,
      "DESCRIPTION": "SEALING KIT,TILT 3713CH",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 4120006628004,
      "DESCRIPTION": "SEAL KIT, LIFT  3713CH",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 8,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 12743622,
      "DESCRIPTION": "COIL-HEATER",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 14716428,
      "DESCRIPTION": "SHIM 1173-00610",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 54,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 14734376,
      "DESCRIPTION": "SEAT 14659740 14549409 14684305",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 4110000054005,
      "DESCRIPTION": "O-RING",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 4110000054035,
      "DESCRIPTION": "WASHER",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 37,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 12824620,
      "DESCRIPTION": "CONTROL",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 16052009,
      "DESCRIPTION": "CONTROL CABLE",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 17253413,
      "DESCRIPTION": "SEALING KIT",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 4043001507,
      "DESCRIPTION": "WELD PIN",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.25,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "RFC",
      "TREND": 0
    },
    {
      "PART NUMBER": 4043001505,
      "DESCRIPTION": "WELD PIN",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.25,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 9,
      "WH REFERENCE": "WH12",
      "TREND": 0
    },
    {
      "PART NUMBER": 4043001508,
      "DESCRIPTION": "BUSHING",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.5,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 7,
      "WH REFERENCE": "WH12",
      "TREND": 0
    },
    {
      "PART NUMBER": 60125019,
      "DESCRIPTION": "DIESEL COARSE FILTER CORE",
      "PRODUCT CATEGORY": "YCG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.25,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 1,
      "SHELF LIFE": 4,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WH REFERENCE": "",
      "TREND": 0.67
    },
    {
      "PART NUMBER": "8240-03610",
      "DESCRIPTION": "SCREW",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 28140007521,
      "DESCRIPTION": "CUTTING EDGE G9165",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 4043000128,
      "DESCRIPTION": "SEALING RING",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 30,
      "WH REFERENCE": "WH12",
      "TREND": 2
    },
    {
      "PART NUMBER": 5284811166,
      "DESCRIPTION": "TEMPERATURE SENSOR 277309Z00A",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 58,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 60358721,
      "DESCRIPTION": "FUEL/WATER SEPARATOR FILTER CORE (SANY LOGO)",
      "PRODUCT CATEGORY": "YPG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 1,
      "SHELF LIFE": 12.000000000000005,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 60327521,
      "DESCRIPTION": "DIESEL COARSE FILTER CORE",
      "PRODUCT CATEGORY": "YPG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 60328867,
      "DESCRIPTION": "ENGINE OIL FILTER",
      "PRODUCT CATEGORY": "YCG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.666666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 8,
      "SHELF LIFE": 12.000000000000012,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 41,
      "WH REFERENCE": "",
      "TREND": 0.5
    },
    {
      "PART NUMBER": 14737201,
      "DESCRIPTION": "14780710 PLASTIC HOSE",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 11701471,
      "DESCRIPTION": "SEALING RING 8230-02520 ",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 60110299,
      "DESCRIPTION": "WASHER 992045",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 5.33333333333333,
      "MAINTANING STOCK QTY": 11,
      "AVAILABLE": 5,
      "SHELF LIFE": 0.9375000000000006,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 6,
      "APRD QTY": 6,
      "WH12": 242,
      "WH REFERENCE": "WH12",
      "TREND": 2
    },
    {
      "PART NUMBER": 997420,
      "DESCRIPTION": "HEXAGON SCREW",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.5,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 36,
      "WH REFERENCE": "WH12",
      "TREND": 0
    },
    {
      "PART NUMBER": 29050011363,
      "DESCRIPTION": "GASKET 29050011362",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 14,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 14679710,
      "DESCRIPTION": "COVER",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 6577822,
      "DESCRIPTION": "HOSE, BULK ",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.5,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 3,
      "WH REFERENCE": "WH12",
      "TREND": 0
    },
    {
      "PART NUMBER": 4120002669004,
      "DESCRIPTION": "SEALING RING",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 34,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 4110003497,
      "DESCRIPTION": "CONTROL MECHANISM",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 14883909,
      "DESCRIPTION": "LOCK NUT",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 28,
      "WH REFERENCE": "WH12",
      "TREND": 2
    },
    {
      "PART NUMBER": 60113095,
      "DESCRIPTION": "FLANGE SCREW",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 7,
      "WH REFERENCE": "WH12",
      "TREND": 2
    },
    {
      "PART NUMBER": 14716429,
      "DESCRIPTION": "SHIM 1173-00620",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 47,
      "WH REFERENCE": "WH12",
      "TREND": 2
    },
    {
      "PART NUMBER": 24426014,
      "DESCRIPTION": "SHIM",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 5,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 21184969,
      "DESCRIPTION": "INJECTOR",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 20475034,
      "DESCRIPTION": "GEAR SHIFT CABLE",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": "1CM1450",
      "DESCRIPTION": "SCREW",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.666666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 16,
      "WH REFERENCE": "WH12",
      "TREND": 2
    },
    {
      "PART NUMBER": 997437,
      "DESCRIPTION": "HEXAGON SCREW",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 12,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 60328868,
      "DESCRIPTION": "FUEL/WATER SEPARATOR FILTER CORE",
      "PRODUCT CATEGORY": "YCG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.25,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 1,
      "SHELF LIFE": 4,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 9,
      "WH REFERENCE": "",
      "TREND": 0.67
    },
    {
      "PART NUMBER": 4120004036001,
      "DESCRIPTION": "SLEEVE",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 29020001101,
      "DESCRIPTION": "FUEL FILTER",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 14,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 4130002496,
      "DESCRIPTION": "FUEL LEVEL SENSOR",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 11,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 29250001081,
      "DESCRIPTION": "PIN",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 4,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 4015000428,
      "DESCRIPTION": "WASHER",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 6,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 29250001031,
      "DESCRIPTION": "FLANGE",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 29250001051,
      "DESCRIPTION": "FLANGE",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 29250017661,
      "DESCRIPTION": "PIN",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 22,
      "WH REFERENCE": "",
      "TREND": 1
    },
    {
      "PART NUMBER": 29250009441,
      "DESCRIPTION": "SLEEVE",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 29250000091,
      "DESCRIPTION": "FLANGE",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 13,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 29250009451,
      "DESCRIPTION": "SLEEVE",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3,
      "WH REFERENCE": "",
      "TREND": 1
    },
    {
      "PART NUMBER": 29250009461,
      "DESCRIPTION": "FLANGE",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WH REFERENCE": "",
      "TREND": 1
    },
    {
      "PART NUMBER": 29250009421,
      "DESCRIPTION": "SLEEVE",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WH REFERENCE": "",
      "TREND": 1
    },
    {
      "PART NUMBER": 29250004061,
      "DESCRIPTION": "LUBRICATING PIPE",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 14721846,
      "DESCRIPTION": "CLAMP",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 5,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 6670207,
      "DESCRIPTION": "FILTER, OIL HYD",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.25,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 1,
      "SHELF LIFE": 4,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3,
      "WH REFERENCE": "",
      "TREND": 1.33
    },
    {
      "PART NUMBER": 14737252,
      "DESCRIPTION": "GASKET",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 32,
      "WH REFERENCE": "",
      "TREND": 1
    },
    {
      "PART NUMBER": 29250011331,
      "DESCRIPTION": "SHIM",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 29270009421,
      "DESCRIPTION": "PLASTIC PLUG",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 27,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 4120009227,
      "DESCRIPTION": "AIR CYLINDER",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.416666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 1,
      "SHELF LIFE": 2.400000000000004,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0.8
    },
    {
      "PART NUMBER": 14884948,
      "DESCRIPTION": "SPRING WASHER",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.833333333333333,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 15,
      "SHELF LIFE": 18.000000000000007,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 108,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 4120006889,
      "DESCRIPTION": "BRAKE VALVE",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 1
    },
    {
      "PART NUMBER": 60326015,
      "DESCRIPTION": "OIL PRESSURE ELEMENT",
      "PRODUCT CATEGORY": "YPG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "RFC",
      "TREND": 0.5
    },
    {
      "PART NUMBER": 4120001739081,
      "DESCRIPTION": "PISTON",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 1,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 11,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 4110004221,
      "DESCRIPTION": "PROPELLER SHAFT 4110002912",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 60242353,
      "DESCRIPTION": "DIESEL FUEL CLEANER",
      "PRODUCT CATEGORY": "YCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 4,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 60017381,
      "DESCRIPTION": "FILTER",
      "PRODUCT CATEGORY": "YCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 8,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 60320099,
      "DESCRIPTION": "ELEMENT KIT, FUEL FILTER",
      "PRODUCT CATEGORY": "YCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 5,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 60320126,
      "DESCRIPTION": "ELEMENT, FUEL FILTER",
      "PRODUCT CATEGORY": "YCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 7,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 60333601,
      "DESCRIPTION": "OIL FILTER ELEMENT",
      "PRODUCT CATEGORY": "YCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 4,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 4,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 23226845,
      "DESCRIPTION": "24119885 HOLLOW SHAFT 21901696 ",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 4110003380073,
      "DESCRIPTION": "STARTER MOTOR 4110001007158 4110001007111 4130001902",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 60193268,
      "DESCRIPTION": "AIR FILTER WITH LOCK",
      "PRODUCT CATEGORY": "YCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 22,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 60345316,
      "DESCRIPTION": "FILTER ELEMENT",
      "PRODUCT CATEGORY": "YCG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 1,
      "SHELF LIFE": 6.000000000000024,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 14682685,
      "DESCRIPTION": "BATTERY CABLE",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 4110000846061,
      "DESCRIPTION": "BREATHER CAP",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 7275347,
      "DESCRIPTION": "ELEMENT, FILTER",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.25,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 1,
      "SHELF LIFE": 4,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WH REFERENCE": "",
      "TREND": 0.67
    },
    {
      "PART NUMBER": 7004451,
      "DESCRIPTION": "BELT V",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 20920600,
      "DESCRIPTION": "SILENCER",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 1,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 82619052,
      "DESCRIPTION": "HEADLAMP PROTECTOR       ",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 15057699,
      "DESCRIPTION": "THRUST WASHER",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 14672152,
      "DESCRIPTION": "SOLENOID VALVE",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 60168540,
      "DESCRIPTION": "FUEL WATER SEPARATOR",
      "PRODUCT CATEGORY": "YCG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.25,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 1,
      "SHELF LIFE": 4,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 5,
      "WH REFERENCE": "",
      "TREND": 0.67
    },
    {
      "PART NUMBER": 4190002588,
      "DESCRIPTION": "WIPER ARM",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 22206133,
      "DESCRIPTION": "GASKET                   ",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 14,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": "443429Z00A",
      "DESCRIPTION": "REPAIR KIT",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 7,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 4015000032,
      "DESCRIPTION": "SPRING WASHER",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.666666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 4,
      "WH REFERENCE": "WH12",
      "TREND": 2
    },
    {
      "PART NUMBER": 14737813,
      "DESCRIPTION": "RECOIL SPRING 14709314 14663170",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 9,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": "A819700000631",
      "DESCRIPTION": "SHEAVE ASSEMBLY QY52.1.6",
      "PRODUCT CATEGORY": "YCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 4110002410004,
      "DESCRIPTION": "SEALING RING",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 5,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 82637695,
      "DESCRIPTION": "MUDGUARD                 ",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 7000988,
      "DESCRIPTION": "TOOTH,PIN-ON ( BUCKET TOOTH OF P/N: 7158805 - 24\" DIGGING BUCKET)",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 12,
      "WH REFERENCE": "WH12",
      "TREND": 2
    },
    {
      "PART NUMBER": "4DM14",
      "DESCRIPTION": "NUT",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.666666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 0,
      "WH REFERENCE": "RFC",
      "TREND": 2
    },
    {
      "PART NUMBER": 23150684,
      "DESCRIPTION": "HORN 21857295",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 984975,
      "DESCRIPTION": "TERMINAL",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 33,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 993380,
      "DESCRIPTION": "HOUSING",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 14,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 14638992,
      "DESCRIPTION": "CHECK VALVE",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 4,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 60110219,
      "DESCRIPTION": "WASHER",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 60350360,
      "DESCRIPTION": "DIESEL FILTER CORE",
      "PRODUCT CATEGORY": "YCG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 1,
      "SHELF LIFE": 3.000000000000003,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WH REFERENCE": "",
      "TREND": 1
    },
    {
      "PART NUMBER": 60348720,
      "DESCRIPTION": "COMPRESSOR",
      "PRODUCT CATEGORY": "YCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 7,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 29050023541,
      "DESCRIPTION": "NUT",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": "22.4.JSM33*2HXL",
      "DESCRIPTION": "360Â° ROTARY JOINT",
      "PRODUCT CATEGORY": "PHG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 1
    },
    {
      "PART NUMBER": 4110002354002,
      "DESCRIPTION": "FUEL FILTER INSERT",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 30,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 4110000054103,
      "DESCRIPTION": "NIPPLE",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 4110000054267,
      "DESCRIPTION": "HOSE ASSEMBLY",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 14883767,
      "DESCRIPTION": "HOSE CLAMP",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 29050027361,
      "DESCRIPTION": "FLANGE",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 0,
      "WH REFERENCE": "RFC",
      "TREND": 2
    },
    {
      "PART NUMBER": 4110001015010,
      "DESCRIPTION": "FLYWHEEL HOUSING",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 21564394,
      "DESCRIPTION": "MASTER CYLINDER",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 29050025851,
      "DESCRIPTION": "SEALING SLEEVE",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 7100002101,
      "DESCRIPTION": "O-RING",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 3030900178,
      "DESCRIPTION": "DRIVE SHAFT GEAR",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 4,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 28100006641,
      "DESCRIPTION": "ROUND NUT",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 1,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 4190003578,
      "DESCRIPTION": "DRUM BRAKE 4110001903012 ",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 60348780,
      "DESCRIPTION": "AIR FILTER KIT",
      "PRODUCT CATEGORY": "YCG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 1,
      "SHELF LIFE": 3.000000000000003,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 9,
      "WH REFERENCE": "",
      "TREND": 0.5
    },
    {
      "PART NUMBER": 14514458,
      "DESCRIPTION": "SEAL_ENGINE REA",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 4,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 29290034631,
      "DESCRIPTION": "INSTRUMENT PANEL",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 14744967,
      "DESCRIPTION": "14750657 FILTER CARTRIDGE 14532687",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.25,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 0,
      "WH REFERENCE": "RFC",
      "TREND": 0.67
    },
    {
      "PART NUMBER": 14684602,
      "DESCRIPTION": "GLASS",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 29070001031,
      "DESCRIPTION": "BOLT",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 2,
      "MAINTANING STOCK QTY": 4,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 4,
      "APRD QTY": 4,
      "WH12": 0,
      "WH REFERENCE": "RFC",
      "TREND": 2
    },
    {
      "PART NUMBER": 23285701,
      "DESCRIPTION": "OIL LEVEL SENSOR 22022794",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 13,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 60119268,
      "DESCRIPTION": " IGNITION LOCK ASSEMBLY",
      "PRODUCT CATEGORY": "YTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 4,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 17516676,
      "DESCRIPTION": "SEALING KIT",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 4110000991031,
      "DESCRIPTION": "BEARING SHELL 4110000991032 4110000991030",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 4,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 26,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 7312069,
      "DESCRIPTION": "FUEL STRAINER",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 5,
      "WH REFERENCE": "",
      "TREND": 1
    },
    {
      "PART NUMBER": 7004877,
      "DESCRIPTION": "DRAIN FILTER",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 4,
      "WH REFERENCE": "",
      "TREND": 1
    },
    {
      "PART NUMBER": 10125894,
      "DESCRIPTION": "BUMPER BLOCK",
      "PRODUCT CATEGORY": "YCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.666666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 1,
      "WH REFERENCE": "WH12",
      "TREND": 0
    },
    {
      "PART NUMBER": 60159795,
      "DESCRIPTION": "OBLIQUITY SENSOR",
      "PRODUCT CATEGORY": "YCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 16284724,
      "DESCRIPTION": "WIPER BLADE 14508629 ",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 38,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 23191319,
      "DESCRIPTION": "OIL COOLER 20511773 22592869",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": "15058186PQ",
      "DESCRIPTION": "SUPER HYDRAULIC OIL  ISO VG68",
      "PRODUCT CATEGORY": "LUV",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 38.25,
      "MAINTANING STOCK QTY": 77,
      "AVAILABLE": 52,
      "SHELF LIFE": 1.3594771241830066,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 25,
      "APRD QTY": 20,
      "WH12": 10,
      "WH REFERENCE": "PARTIAL WH12",
      "TREND": 2
    },
    {
      "PART NUMBER": "15067098PQ",
      "DESCRIPTION": "AUTOMATIC TRANSMISSION FLUID  AT101",
      "PRODUCT CATEGORY": "LUV",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 2,
      "SHELF LIFE": 6.000000000000006,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1571,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": "15067522PQ",
      "DESCRIPTION": "SUPER TRANSMISSION OIL  85W/140  OIL API GL-5 99990-1Z05J",
      "PRODUCT CATEGORY": "LUV",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 5.16666666666666,
      "MAINTANING STOCK QTY": 10,
      "AVAILABLE": 12,
      "SHELF LIFE": 2.3225806451612936,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": "15067640PQ",
      "DESCRIPTION": "GREASE, LITHIUM  EP2 ",
      "PRODUCT CATEGORY": "LUV",
      "ITEM MOVEMENT BRANCH": "AVERAGE (F2)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 6.5,
      "MAINTANING STOCK QTY": 13,
      "AVAILABLE": 32,
      "SHELF LIFE": 4.923076923076923,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1441,
      "WH REFERENCE": "",
      "TREND": 0.79
    },
    {
      "PART NUMBER": "15143928PQ",
      "DESCRIPTION": "AUTOMATIC TRANSMISSION FLUID AT102  L150G/A40F",
      "PRODUCT CATEGORY": "LUV",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 1.16666666666666,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 2,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "RFC",
      "TREND": 2
    },
    {
      "PART NUMBER": 20532891,
      "DESCRIPTION": "GASKET",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 9,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 21707132,
      "DESCRIPTION": "OIL FILTER, BYPASS",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "AVERAGE (F2)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 1.41666666666666,
      "MAINTANING STOCK QTY": 3,
      "AVAILABLE": 10,
      "SHELF LIFE": 7.058823529411797,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 1.06
    },
    {
      "PART NUMBER": 21707133,
      "DESCRIPTION": "23658092 OIL FILTER",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 1.41666666666666,
      "MAINTANING STOCK QTY": 3,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 3,
      "APRD QTY": 3,
      "WH12": 0,
      "WH REFERENCE": "RFC",
      "TREND": 0.12
    },
    {
      "PART NUMBER": 980521,
      "DESCRIPTION": "NUT, HEXAGON",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.5,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 8,
      "WH REFERENCE": "WH12",
      "TREND": 2
    },
    {
      "PART NUMBER": 969161,
      "DESCRIPTION": "BLADE FUSE 10A A35D/G930/L150F/EC210B/EC460B",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 2,
      "MAINTANING STOCK QTY": 4,
      "AVAILABLE": 4,
      "SHELF LIFE": 2,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 54,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 984738,
      "DESCRIPTION": "FLANGE SCREW 13947760",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 31,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 990861,
      "DESCRIPTION": "FLANGE SCREW  60113094 946472",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 41,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 990941,
      "DESCRIPTION": "FLANGE NUT 971095 60113141",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 990970,
      "DESCRIPTION": "HEX NUT 13971072 997409 979019",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 124,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 992521,
      "DESCRIPTION": "BULB 24V/21W G930/L150F 967708",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 4,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 30,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 1161277,
      "DESCRIPTION": "SEALANT 20G (BLACK) 11713514",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 20367490,
      "DESCRIPTION": "RELAY, STARTER NL10/FM400/EC460B/EC700B/G930/L150F",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 20532237,
      "DESCRIPTION": "COOLANT FILTER 1699830 ADAPTED QTY 102",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 5,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 21190817,
      "DESCRIPTION": "V-BELT_ALTERNATOR FM9/FM10 20412535",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 21482601,
      "DESCRIPTION": "GASKET FM10 8148172",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 22,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 21531072,
      "DESCRIPTION": "SENSOR_TEMPERATURE FM10/FM340/EC210B/EC290B/EC700B/G710B/G930/BL71/A40F 20513340",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 1,
      "SHELF LIFE": 12.000000000000005,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 6,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 22567321,
      "DESCRIPTION": "COOLANT (READY MIXED) 5L/GAL YELLOW  85108913",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "AVERAGE (F2)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 5,
      "MAINTANING STOCK QTY": 10,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 10,
      "APRD QTY": 0,
      "WH12": 100,
      "WH REFERENCE": "WH12",
      "TREND": 0.9
    },
    {
      "PART NUMBER": 22899626,
      "DESCRIPTION": "PRESSURE SENSOR 21634021 20898038",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 1,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 8,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": "9451-02211",
      "DESCRIPTION": "HOSE ASSEMBLY",
      "PRODUCT CATEGORY": "VOH",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": "9453-02207",
      "DESCRIPTION": "HOSE ASSEMBLY",
      "PRODUCT CATEGORY": "VOH",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 10,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": "9453-03229",
      "DESCRIPTION": "HOSE ASSEMBLY",
      "PRODUCT CATEGORY": "VOH",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 10,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 937177,
      "DESCRIPTION": "HOSE ASSEMBLY",
      "PRODUCT CATEGORY": "VOH",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": "9453-02336",
      "DESCRIPTION": "HOSE ASSEMBLY",
      "PRODUCT CATEGORY": "VOH",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": "9453-02213",
      "DESCRIPTION": "HOSE ASSEMBLY",
      "PRODUCT CATEGORY": "VOH",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 4040000051,
      "DESCRIPTION": "WASHER",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.416666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 6,
      "WH REFERENCE": "WH12",
      "TREND": 0
    },
    {
      "PART NUMBER": 4011001261,
      "DESCRIPTION": "SCREW",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 8,
      "WH REFERENCE": "WH12",
      "TREND": 0
    },
    {
      "PART NUMBER": 60339522,
      "DESCRIPTION": "POLY V BELT",
      "PRODUCT CATEGORY": "YCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 60350359,
      "DESCRIPTION": "POLY V BELT",
      "PRODUCT CATEGORY": "YCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 3030900177,
      "DESCRIPTION": "DRIVE SHAFT GEAR",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 60339521,
      "DESCRIPTION": "AUTOMATIC TENSIONER",
      "PRODUCT CATEGORY": "YCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 60272760,
      "DESCRIPTION": "COMPRESSOR",
      "PRODUCT CATEGORY": "YCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 4110000727162,
      "DESCRIPTION": "14403813 FUEL FILTER INSERT",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 3,
      "SHELF LIFE": 18.000000000000075,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 100,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 11214252,
      "DESCRIPTION": "FILTER",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.583333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 13,
      "SHELF LIFE": 22.285714285714295,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 181,
      "WH REFERENCE": "",
      "TREND": 0.29
    },
    {
      "PART NUMBER": 11210241,
      "DESCRIPTION": "AIR FILTER",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 14,
      "SHELF LIFE": 84.00000000000034,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 75,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 11211360,
      "DESCRIPTION": "HYDRAULIC FLUID FILTER",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.416666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 8,
      "SHELF LIFE": 19.20000000000003,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 256,
      "WH REFERENCE": "",
      "TREND": 0.4
    },
    {
      "PART NUMBER": 11211213,
      "DESCRIPTION": "HYDRAULIC FLUID FILTER",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.416666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 8,
      "SHELF LIFE": 19.20000000000003,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 314,
      "WH REFERENCE": "",
      "TREND": 0.4
    },
    {
      "PART NUMBER": 11210987,
      "DESCRIPTION": "FILTER",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 14,
      "SHELF LIFE": 84.00000000000034,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 154,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 11211189,
      "DESCRIPTION": "HYDRAULIC FLUID FILTER",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.583333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 9,
      "SHELF LIFE": 15.428571428571436,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 123,
      "WH REFERENCE": "",
      "TREND": 0.86
    },
    {
      "PART NUMBER": 14405925,
      "DESCRIPTION": "AIR FILTER INSERT",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 10,
      "SHELF LIFE": 30.000000000000032,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 177,
      "WH REFERENCE": "",
      "TREND": 0.5
    },
    {
      "PART NUMBER": 14403810,
      "DESCRIPTION": "AIR FILTER",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.25,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 5,
      "SHELF LIFE": 20,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 15,
      "WH REFERENCE": "",
      "TREND": 0.67
    },
    {
      "PART NUMBER": 4110002196003,
      "DESCRIPTION": "INJECTOR ",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 13,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": "B222100000601",
      "DESCRIPTION": "PRESSURE OIL FILTER",
      "PRODUCT CATEGORY": "YCG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 1,
      "SHELF LIFE": 3.000000000000003,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 5,
      "WH REFERENCE": "",
      "TREND": 0.5
    },
    {
      "PART NUMBER": 29220020671,
      "DESCRIPTION": "BRAKE PIPE",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 14720769,
      "DESCRIPTION": "SENSOR",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 60358722,
      "DESCRIPTION": "FILTER ELEMENT",
      "PRODUCT CATEGORY": "YPG",
      "ITEM MOVEMENT BRANCH": "AVERAGE (F2)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 1.16666666666666,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 3,
      "SHELF LIFE": 2.571428571428586,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0.29
    },
    {
      "PART NUMBER": 14723460,
      "DESCRIPTION": "SEAL 14530367",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 5,
      "WH REFERENCE": "WH12",
      "TREND": 1
    },
    {
      "PART NUMBER": 17473102,
      "DESCRIPTION": "PIN 21",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 1.25,
      "MAINTANING STOCK QTY": 3,
      "AVAILABLE": 10,
      "SHELF LIFE": 8,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 381,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 17493102,
      "DESCRIPTION": "RETAINER 21",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 5,
      "SHELF LIFE": 15.000000000000016,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 12,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 14732381,
      "DESCRIPTION": "TOOTH, NEW VTS GPE 21",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.833333333333333,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 10,
      "SHELF LIFE": 12.000000000000004,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 146,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 14732382,
      "DESCRIPTION": "TOOTH, NEW VTS ARXE 21",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.416666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 354,
      "WH REFERENCE": "WH12",
      "TREND": 0
    },
    {
      "PART NUMBER": 23417523,
      "DESCRIPTION": "CYLINDER ",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 11,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 15057732,
      "DESCRIPTION": "VALVE SEAL",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": "9453-05212",
      "DESCRIPTION": "HOSE ASSEMBLY",
      "PRODUCT CATEGORY": "VOH",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": "9453-02214",
      "DESCRIPTION": "HOSE ASSEMBLY",
      "PRODUCT CATEGORY": "VOH",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": "9453-02216",
      "DESCRIPTION": "HOSE ASSEMBLY",
      "PRODUCT CATEGORY": "VOH",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 936218,
      "DESCRIPTION": "HOSE ASSEMBLY",
      "PRODUCT CATEGORY": "VOH",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 8,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 14603541,
      "DESCRIPTION": "HOSE",
      "PRODUCT CATEGORY": "VOH",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": "9453-04208",
      "DESCRIPTION": "HOSE ASSEMBLY",
      "PRODUCT CATEGORY": "VOH",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": "9453-04218",
      "DESCRIPTION": "HOSE ASSEMBLY",
      "PRODUCT CATEGORY": "VOH",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 23149532,
      "DESCRIPTION": "BRAKE PAD KIT 21496550",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.75,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 3,
      "SHELF LIFE": 4,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WH REFERENCE": "",
      "TREND": 0.44
    },
    {
      "PART NUMBER": 14884939,
      "DESCRIPTION": "NUT 9112-13001",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.916666666666666,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 2,
      "APRD QTY": 0,
      "WH12": 34,
      "WH REFERENCE": "WH12",
      "TREND": 0
    },
    {
      "PART NUMBER": 4110000591035,
      "DESCRIPTION": "NUT 411000054332 ",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 13,
      "WH REFERENCE": "WH12",
      "TREND": 0
    },
    {
      "PART NUMBER": 4120004492,
      "DESCRIPTION": "FILTER INSERT",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 67,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 29250009001,
      "DESCRIPTION": "PIN",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 7196904,
      "DESCRIPTION": "KIT, SEAL",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 6,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 14529190,
      "DESCRIPTION": "SWITCH",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 17287189,
      "DESCRIPTION": "SEALING KIT EW145B",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 43,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": "131288000077A",
      "DESCRIPTION": "FUEL FILTER ELEMENT",
      "PRODUCT CATEGORY": "YCG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 14,
      "WH REFERENCE": "",
      "TREND": 1
    },
    {
      "PART NUMBER": 14729891,
      "DESCRIPTION": "MAGNET",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 23307415,
      "DESCRIPTION": " VACUUM SWITCH 20565673 22996282",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 4120005980012,
      "DESCRIPTION": "BUSHING",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 17208525,
      "DESCRIPTION": "V-RIBBED BELT 15078690 ",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 982558,
      "DESCRIPTION": "BULB 21W ",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 1,
      "SHELF LIFE": 12.000000000000005,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 18,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 14749664,
      "DESCRIPTION": "53807004 CONDENSER 14591537 ",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 21178542,
      "DESCRIPTION": "REPAIR KIT 24425916",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "RFC",
      "TREND": 0
    },
    {
      "PART NUMBER": 23657265,
      "DESCRIPTION": "COOLANT PUMP D6E/D4E 21247955 21125771",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.416666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 2,
      "WH REFERENCE": "WH12",
      "TREND": 1.2
    },
    {
      "PART NUMBER": 21795915,
      "DESCRIPTION": "V-BELT PULLEY",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.416666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 1,
      "SHELF LIFE": 2.400000000000004,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WH REFERENCE": "",
      "TREND": 1.2
    },
    {
      "PART NUMBER": 4110002360007,
      "DESCRIPTION": "PUMP COMPANION",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 14,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 160604020017,
      "DESCRIPTION": "FUEL SEPARATOR",
      "PRODUCT CATEGORY": "YPG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.25,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 2,
      "SHELF LIFE": 8,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 44,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": "160603020014A",
      "DESCRIPTION": "FUEL-WATER FILTER",
      "PRODUCT CATEGORY": "YCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 12,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 4110003478002,
      "DESCRIPTION": "O-RING 4110000054004",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 14,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 997466,
      "DESCRIPTION": "978941 HEXAGON SCREW 978941 9011-21617",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 4.83333333333333,
      "MAINTANING STOCK QTY": 10,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 10,
      "APRD QTY": 10,
      "WH12": 50,
      "WH REFERENCE": "WH12",
      "TREND": 2
    },
    {
      "PART NUMBER": 60282117,
      "DESCRIPTION": "DIESEL FILTER",
      "PRODUCT CATEGORY": "YCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 5,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 4190002690,
      "DESCRIPTION": "DRIVER'S SEAT",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 9,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 29300007731,
      "DESCRIPTION": "SEAT SUPPORT",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 28100006631,
      "DESCRIPTION": "COVER",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 17532105,
      "DESCRIPTION": "CONTROL PANEL 15155940 17444646",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 29250017701,
      "DESCRIPTION": "SHIM",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 29250017711,
      "DESCRIPTION": "SHIM",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 29250017721,
      "DESCRIPTION": "SHIM",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 14732612,
      "DESCRIPTION": "DOOR",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 11211325,
      "DESCRIPTION": "STRAINER",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 184,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 4110000076032,
      "DESCRIPTION": "GEAR PUMP",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 14672980,
      "DESCRIPTION": "MIRROR 14634766 14708934 14564772",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 30,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 14749665,
      "DESCRIPTION": "CONDENSER, A/C EC210B 14591539 14645946",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 4,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 14672981,
      "DESCRIPTION": "MIRROR",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 11205297,
      "DESCRIPTION": "SIDE WINDOW",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 1,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 4120002274,
      "DESCRIPTION": "ACCUMULATOR",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 4030000097,
      "DESCRIPTION": "O-RING",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": "29100001051A",
      "DESCRIPTION": "FILTER INSERT 29100001051",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.75,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 3,
      "SHELF LIFE": 4,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 78,
      "WH REFERENCE": "",
      "TREND": 1.11
    },
    {
      "PART NUMBER": 14750655,
      "DESCRIPTION": "FILTER CARTRIDGE 14711980",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.25,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 1,
      "SHELF LIFE": 4,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 61,
      "WH REFERENCE": "",
      "TREND": 1.33
    },
    {
      "PART NUMBER": 14400779,
      "DESCRIPTION": "OIL FILTER 4110000727085 4110000509164",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 1.08333333333333,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 8,
      "SHELF LIFE": 7.384615384615408,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 757,
      "WH REFERENCE": "",
      "TREND": 0.62
    },
    {
      "PART NUMBER": 29170036831,
      "DESCRIPTION": "BUCKET",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 23752844,
      "DESCRIPTION": "FAN RING",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 5,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 14722585,
      "DESCRIPTION": "SPROCKET, ENDURANCE 14557971 EC140B/D",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 29250017691,
      "DESCRIPTION": "NUT",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 11217422,
      "DESCRIPTION": "TOOTH",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 5,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 11213340,
      "DESCRIPTION": "PIN",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 11223287,
      "DESCRIPTION": "RETAINER",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 993677,
      "DESCRIPTION": "GASKET",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 7006098,
      "DESCRIPTION": "COUPLER",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 61018500,
      "DESCRIPTION": "DIESEL COARSE FILTER CORE",
      "PRODUCT CATEGORY": "YPG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 3,
      "SHELF LIFE": 36.000000000000014,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 85,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 60359943,
      "DESCRIPTION": "HEIGHT LIMIT SWITCH",
      "PRODUCT CATEGORY": "YCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 4,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 60284008,
      "DESCRIPTION": "60284007 EXPANSION VALVE",
      "PRODUCT CATEGORY": "YPG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 14682684,
      "DESCRIPTION": "14790923 BATTERY CABLE",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": "360456-1",
      "DESCRIPTION": "SWIVEL HYDRAULIC MOTOR",
      "PRODUCT CATEGORY": "PHG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": "SP-10",
      "DESCRIPTION": "AC COMPRESSOR OIL 250ML",
      "PRODUCT CATEGORY": "LUB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 20475033,
      "DESCRIPTION": "GEAR SHIFT CABLE ",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 997471,
      "DESCRIPTION": "HEXAGON SCREW 983260",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.25,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 11,
      "WH REFERENCE": "WH12",
      "TREND": 2
    },
    {
      "PART NUMBER": "160602010079A",
      "DESCRIPTION": "AIR FILTER ASSY (USE INDIVIDUAL PART NUMBERS)",
      "PRODUCT CATEGORY": "YTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 60358720,
      "DESCRIPTION": "OIL WATER SEPARATOR ASSY",
      "PRODUCT CATEGORY": "YTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 8,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 60263755,
      "DESCRIPTION": "STARTER",
      "PRODUCT CATEGORY": "YTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 60263487,
      "DESCRIPTION": "FUEL FILTER",
      "PRODUCT CATEGORY": "YTG",
      "ITEM MOVEMENT BRANCH": "AVERAGE (F2)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 2.33333333333333,
      "MAINTANING STOCK QTY": 5,
      "AVAILABLE": 2,
      "SHELF LIFE": 0.8571428571428584,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 3,
      "APRD QTY": 3,
      "WH12": 101,
      "WH REFERENCE": "WH12",
      "TREND": 0.29
    },
    {
      "PART NUMBER": "141002010002B",
      "DESCRIPTION": "ALTERNATOR",
      "PRODUCT CATEGORY": "YTG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": "152503990036A",
      "DESCRIPTION": "RELAY VALVE",
      "PRODUCT CATEGORY": "YTG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 1,
      "WH REFERENCE": "WH12",
      "TREND": 0.5
    },
    {
      "PART NUMBER": "131488000229A",
      "DESCRIPTION": "LOWER BRAKE FRICTION PLATE",
      "PRODUCT CATEGORY": "YTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 1,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 87,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 60126917,
      "DESCRIPTION": "MAIN CARTRIDGE OF AIR CLEANER",
      "PRODUCT CATEGORY": "YPG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 11,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 60126914,
      "DESCRIPTION": "SAFETY CARTRIDGE OF AIR CLEANER",
      "PRODUCT CATEGORY": "YPG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 6,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 30,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 15714570,
      "DESCRIPTION": "FUEL FILTER 17270913 ",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 24,
      "WH REFERENCE": "",
      "TREND": 1
    },
    {
      "PART NUMBER": 15714574,
      "DESCRIPTION": "OIL FILTER 43977461 17324107 ",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 1,
      "SHELF LIFE": 12.000000000000005,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 11,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 15711010,
      "DESCRIPTION": "FUEL FILTER 16668474",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 17,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 14682738,
      "DESCRIPTION": "SPIDER",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 4,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 14720757,
      "DESCRIPTION": "SENSOR 14559246 ",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": "TP1000424916",
      "DESCRIPTION": "FUEL WATER SEPARATOR DH24",
      "PRODUCT CATEGORY": "SDG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 35,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 23479847,
      "DESCRIPTION": "GASKET 20858564 ",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 4120002263005,
      "DESCRIPTION": "BUSHING 4120000558009 4120002263406",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 18,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 21769814,
      "DESCRIPTION": "OIL COOLER 21159227 ",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 60183685,
      "DESCRIPTION": "TEMPERATURE SENSOR",
      "PRODUCT CATEGORY": "YPG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 6,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 12846533,
      "DESCRIPTION": "THROTTLE CABLE",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 23481701,
      "DESCRIPTION": "PLAIN WASHER",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": "ADBLUE20L",
      "DESCRIPTION": "ADBLUE - UREA SOLUTION ",
      "PRODUCT CATEGORY": "ZZY",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 381.666666666666,
      "MAINTANING STOCK QTY": 763,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 763,
      "APRD QTY": 763,
      "WH12": 10248,
      "WH REFERENCE": "WH12",
      "TREND": 0.81
    },
    {
      "PART NUMBER": "R134A",
      "DESCRIPTION": "REFRIGERANT (PER KILO) 13.5 KL PER CYLINDER",
      "PRODUCT CATEGORY": "LUP",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 1,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 1,
      "SHELF LIFE": 1,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 1.5,
      "WH REFERENCE": "WH12",
      "TREND": 2
    },
    {
      "PART NUMBER": 11708371,
      "DESCRIPTION": "LIMITED SLIP GEAR OIL SAE 85W-90LS API-GL5 15195533",
      "PRODUCT CATEGORY": "LUT",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 1.33333333333333,
      "MAINTANING STOCK QTY": 3,
      "AVAILABLE": 24,
      "SHELF LIFE": 18.000000000000046,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 177,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": "DOT3",
      "DESCRIPTION": "BRAKE FLUID (1 LITER)",
      "PRODUCT CATEGORY": "LUT",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 17533661,
      "DESCRIPTION": "OIL FILTER 21707133 (X8)",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "AVERAGE (F2)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 3.25,
      "MAINTANING STOCK QTY": 7,
      "AVAILABLE": 2,
      "SHELF LIFE": 0.6153846153846154,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 5,
      "APRD QTY": 5,
      "WH12": 197,
      "WH REFERENCE": "WH12",
      "TREND": 0.87
    },
    {
      "PART NUMBER": 14884680,
      "DESCRIPTION": "SCREW 9012-53021",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.916666666666666,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 2,
      "SHELF LIFE": 2.1818181818181834,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 38,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 6669287,
      "DESCRIPTION": "SEAL",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 9,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 14717724,
      "DESCRIPTION": "PUMP 14531858",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 21574984,
      "DESCRIPTION": "DOSAGE VALVE",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 1,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": "A820301021138",
      "DESCRIPTION": "SUSPENSION SPRING EYE PIN",
      "PRODUCT CATEGORY": "YCG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "RFC",
      "TREND": 0
    },
    {
      "PART NUMBER": 17535679,
      "DESCRIPTION": "OIL FILTER 17457469 3831236 (X10)",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "FAST (F1)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 9.91666666666666,
      "MAINTANING STOCK QTY": 20,
      "AVAILABLE": 21,
      "SHELF LIFE": 2.1176470588235308,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 252,
      "WH REFERENCE": "",
      "TREND": 1.13
    },
    {
      "PART NUMBER": "168899001038A",
      "DESCRIPTION": "FILTER EJ200-1105140A YC",
      "PRODUCT CATEGORY": "YPG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 6,
      "SHELF LIFE": 72.00000000000003,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 37,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": "168899001032A",
      "DESCRIPTION": "OIL STRAINER ASSEMBLY",
      "PRODUCT CATEGORY": "YPG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 5,
      "SHELF LIFE": 60.00000000000002,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 16,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": "168899001037A",
      "DESCRIPTION": "FILTER EJ200-1105140A YC",
      "PRODUCT CATEGORY": "YPG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 1,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 67,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 990973,
      "DESCRIPTION": "HEXAGON NUT 997411 979022",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.5,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 1,
      "SHELF LIFE": 2,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 10,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 7196450,
      "DESCRIPTION": "KIT SEAL",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 7166330,
      "DESCRIPTION": "KIT SEAL",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 61021989,
      "DESCRIPTION": "WHEEL BOLT",
      "PRODUCT CATEGORY": "YPG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 20,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 597,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 61021990,
      "DESCRIPTION": "WHEEL NUT",
      "PRODUCT CATEGORY": "YPG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 20,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 630,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 160604020018,
      "DESCRIPTION": "DIESEL FINE FILTER CORE",
      "PRODUCT CATEGORY": "YPG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.416666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 4,
      "SHELF LIFE": 9.600000000000016,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 31,
      "WH REFERENCE": "",
      "TREND": 0.4
    },
    {
      "PART NUMBER": 23807658,
      "DESCRIPTION": "SENSOR 20482772",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 5,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": "A229900008929",
      "DESCRIPTION": "ENGINE REAR SUSPENSION CUSHION",
      "PRODUCT CATEGORY": "YCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": "130699000042A",
      "DESCRIPTION": "THROWOUT BEARING, CLUTCH",
      "PRODUCT CATEGORY": "YTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": "130699000043A",
      "DESCRIPTION": "DRIVEN DISC ASSEMBLY, CLUTCH",
      "PRODUCT CATEGORY": "YTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 4110004010002,
      "DESCRIPTION": "BEARING SHELL",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": "29.07.DNDC XBN",
      "DESCRIPTION": "SOLENOID VALVE",
      "PRODUCT CATEGORY": "PHG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 15714575,
      "DESCRIPTION": "OIL FILTER 17246027",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 13,
      "WH REFERENCE": "",
      "TREND": 1
    },
    {
      "PART NUMBER": 60343613,
      "DESCRIPTION": "CLUTCH BOOSTER",
      "PRODUCT CATEGORY": "YCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 5223958455,
      "DESCRIPTION": "OIL FILTER KIT, 5223476572",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.666666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 2,
      "SHELF LIFE": 3.000000000000003,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 36,
      "WH REFERENCE": "",
      "TREND": 0.5
    },
    {
      "PART NUMBER": 14750657,
      "DESCRIPTION": "FILTER CARTRIDGE 14744967 14532687",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "AVERAGE (F2)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 1.41666666666666,
      "MAINTANING STOCK QTY": 3,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 3,
      "APRD QTY": 3,
      "WH12": 12,
      "WH REFERENCE": "WH12",
      "TREND": 1.06
    },
    {
      "PART NUMBER": 60274683,
      "DESCRIPTION": "BRAKE CLUTCH PEDAL ASSEMBLY",
      "PRODUCT CATEGORY": "YPG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 17515206,
      "DESCRIPTION": "WATER SEPARATOR",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 38,
      "WH REFERENCE": "WH12",
      "TREND": 1.5
    },
    {
      "PART NUMBER": "131488000859A ",
      "DESCRIPTION": "REAR, BRAKE LINING, 16MM",
      "PRODUCT CATEGORY": "YTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 1.33333333333333,
      "MAINTANING STOCK QTY": 3,
      "AVAILABLE": 16,
      "SHELF LIFE": 12.00000000000003,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 750,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": "160602020036A",
      "DESCRIPTION": "AIR FILTER, MAIN",
      "PRODUCT CATEGORY": "YTG",
      "ITEM MOVEMENT BRANCH": "AVERAGE (F2)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 1.16666666666666,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 1,
      "SHELF LIFE": 0.857142857142862,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 10,
      "WH REFERENCE": "WH12",
      "TREND": 0.29
    },
    {
      "PART NUMBER": 61021071,
      "DESCRIPTION": "WHEEL BOLT",
      "PRODUCT CATEGORY": "YTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 87,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 61021072,
      "DESCRIPTION": "WHEEL NUT",
      "PRODUCT CATEGORY": "YTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 413,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": "131401020468B022",
      "DESCRIPTION": "BOLT",
      "PRODUCT CATEGORY": "YTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 3,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 39,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": "131401020468B026",
      "DESCRIPTION": "NUT",
      "PRODUCT CATEGORY": "YTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 3,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": "141502000307A",
      "DESCRIPTION": "AIR CONDITIONING COMPRESSOR",
      "PRODUCT CATEGORY": "YTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 26,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": "131401020472A013",
      "DESCRIPTION": "RIVET",
      "PRODUCT CATEGORY": "YTG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 33.3333333333333,
      "MAINTANING STOCK QTY": 67,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 67,
      "APRD QTY": 67,
      "WH12": 4396,
      "WH REFERENCE": "WH12",
      "TREND": 1.3
    },
    {
      "PART NUMBER": "160602030026A",
      "DESCRIPTION": "AIR FILTER, SAFETY",
      "PRODUCT CATEGORY": "YTG",
      "ITEM MOVEMENT BRANCH": "AVERAGE (F2)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.5,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 1,
      "SHELF LIFE": 2,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 8,
      "WH REFERENCE": "",
      "TREND": 0.33
    },
    {
      "PART NUMBER": "131402010260A010",
      "DESCRIPTION": "BRAKE SHOE",
      "PRODUCT CATEGORY": "YTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": "131401020472A006",
      "DESCRIPTION": "SHOE, BRAKE",
      "PRODUCT CATEGORY": "YTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 18,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 17500251,
      "DESCRIPTION": "PRIMARY FILTER ",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 11,
      "WH REFERENCE": "",
      "TREND": 1
    },
    {
      "PART NUMBER": 17500253,
      "DESCRIPTION": "SAFETY FILTER",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 7,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 14884806,
      "DESCRIPTION": "NIPPLE 9412-11090",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 4,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 23779943,
      "DESCRIPTION": "AIR PRODUCTION MODULATOR",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 23690622,
      "DESCRIPTION": "SERVICE KIT( AIR DRIER FILTER)",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.5,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 2,
      "SHELF LIFE": 4,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 39,
      "WH REFERENCE": "",
      "TREND": 0.67
    },
    {
      "PART NUMBER": 23974640,
      "DESCRIPTION": "24318489 COOLANT PUMP 21247955 21404502",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "AVERAGE (F2)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 1,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 2,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "RFC",
      "TREND": 1
    },
    {
      "PART NUMBER": 4120004899,
      "DESCRIPTION": "FILTER INSERT",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 1,
      "SHELF LIFE": 6.000000000000024,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 88,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 4120005303003,
      "DESCRIPTION": "FILTER INSERT",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 47,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 60151839,
      "DESCRIPTION": "FUEL FILTER ELEMENT",
      "PRODUCT CATEGORY": "YCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 12,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 17271168,
      "DESCRIPTION": "O-RING",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 4,
      "WH REFERENCE": "",
      "TREND": 1
    },
    {
      "PART NUMBER": 16658928,
      "DESCRIPTION": "O-RING",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 9,
      "WH REFERENCE": "",
      "TREND": 1
    },
    {
      "PART NUMBER": 23658092,
      "DESCRIPTION": "OIL FILTER",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 1.16666666666666,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 1,
      "SHELF LIFE": 0.857142857142862,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 0,
      "WH REFERENCE": "RFC",
      "TREND": 2
    },
    {
      "PART NUMBER": 4110001475095,
      "DESCRIPTION": "FUEL FILTER INSERT 4110001475082",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 1,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 161,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 7167163,
      "DESCRIPTION": "BOWL, FUEL ",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 1
    },
    {
      "PART NUMBER": 4110003435018,
      "DESCRIPTION": "GASKET",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 4110003435022,
      "DESCRIPTION": "GASKET",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 8,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 60146760,
      "DESCRIPTION": "DRIVE GEAR OF AUXILIARY BOX",
      "PRODUCT CATEGORY": "YCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": "488209Z01E",
      "DESCRIPTION": "LINK ROD",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 11705253,
      "DESCRIPTION": "WASHER",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 7408600,
      "DESCRIPTION": "FILTER AIR INNER",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 13,
      "WH REFERENCE": "",
      "TREND": 1
    },
    {
      "PART NUMBER": 7408601,
      "DESCRIPTION": "FILTER AIR OUTER",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 6,
      "WH REFERENCE": "",
      "TREND": 1
    },
    {
      "PART NUMBER": 4110000555215,
      "DESCRIPTION": "FUEL FILTER HOUSING",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 11220925,
      "DESCRIPTION": "FILTER NETTING",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 217,
      "WH REFERENCE": "WH12",
      "TREND": 0
    },
    {
      "PART NUMBER": 5223683591,
      "DESCRIPTION": "SHOCK ABSORBER",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 5,
      "WH REFERENCE": "WH12",
      "TREND": 0
    },
    {
      "PART NUMBER": "DOT3BENDIX",
      "DESCRIPTION": "BENDIX BRAKE FLUID DOT3(900ML)",
      "PRODUCT CATEGORY": "LUT",
      "ITEM MOVEMENT BRANCH": "AVERAGE (F2)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 8.58333333333333,
      "MAINTANING STOCK QTY": 17,
      "AVAILABLE": 5,
      "SHELF LIFE": 0.5825242718446604,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 12,
      "APRD QTY": 12,
      "WH12": 142,
      "WH REFERENCE": "WH12",
      "TREND": 0.99
    },
    {
      "PART NUMBER": 60207264,
      "DESCRIPTION": "ELEMENT SAFETY AIR CLEANER ",
      "PRODUCT CATEGORY": "YCG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.25,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 5,
      "WH REFERENCE": "WH12",
      "TREND": 0.67
    },
    {
      "PART NUMBER": 60207265,
      "DESCRIPTION": "ELEMENT PRIMARY AIR CLEANER",
      "PRODUCT CATEGORY": "YCG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.25,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 1,
      "SHELF LIFE": 4,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WH REFERENCE": "",
      "TREND": 0.67
    },
    {
      "PART NUMBER": 14756414,
      "DESCRIPTION": "COVER 14514023",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 8,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 4110000054296,
      "DESCRIPTION": "PUSH ROD",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 2,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 5,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 4110015600,
      "DESCRIPTION": "SUPPORT BEARING SHAFT 4110000487",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 4110001949018,
      "DESCRIPTION": "CONNECTING ROD 4110000991029",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 2,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 4,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 6685521,
      "DESCRIPTION": "PULLEY",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": "131402010260A011",
      "DESCRIPTION": "UPPER BRAKE LINING, WITH ALARM FUNCTION",
      "PRODUCT CATEGORY": "YTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 1,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 405,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": "131402010260A012",
      "DESCRIPTION": "RIVET, FOR LINING 131402010260A011",
      "PRODUCT CATEGORY": "YPG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 22,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 4110001031002,
      "DESCRIPTION": "TURBOCHARGER",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 4190003196,
      "DESCRIPTION": "PANEL",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 12804222,
      "DESCRIPTION": "PLATE",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 977747,
      "DESCRIPTION": "HEXAGON SCREW",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 12804670,
      "DESCRIPTION": "BRACKET",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 16051536,
      "DESCRIPTION": "BUZZER",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 21421260,
      "DESCRIPTION": "SWITCH",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 13462163,
      "DESCRIPTION": "O-RING",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 16055022,
      "DESCRIPTION": "SEAT",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 997493,
      "DESCRIPTION": "SIX POINT SOCKET SCREW 969405",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 913111,
      "DESCRIPTION": "CLEVIS PIN",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 14691364,
      "DESCRIPTION": "FLEXIBLE COUPLING 14522374",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 14726181,
      "DESCRIPTION": "COVER",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 14674408,
      "DESCRIPTION": "PIPE",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 4,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 14685159,
      "DESCRIPTION": "BATTERY CABLE",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 60275828,
      "DESCRIPTION": "ENGINE OIL CLEANER ELEMENT",
      "PRODUCT CATEGORY": "YCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 10,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": "160101080007A016",
      "DESCRIPTION": "OIL FILTER ELEMENT",
      "PRODUCT CATEGORY": "YPG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 5,
      "WH REFERENCE": "",
      "TREND": 1
    },
    {
      "PART NUMBER": 4110000054019,
      "DESCRIPTION": "SPRING",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 4110015815005,
      "DESCRIPTION": "CRANKSHAFT 4110000909105",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 23044511,
      "DESCRIPTION": "FUEL FILTER 15126069",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "AVERAGE (F2)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 2.75,
      "MAINTANING STOCK QTY": 6,
      "AVAILABLE": 5,
      "SHELF LIFE": 1.8181818181818181,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 99,
      "WH REFERENCE": "WH12",
      "TREND": 0.97
    },
    {
      "PART NUMBER": 969147,
      "DESCRIPTION": "SIX POINT SOCKET SCREW",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 9,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 14752088,
      "DESCRIPTION": "SEALING KIT",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 11173801,
      "DESCRIPTION": "STRAINER  4949022",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 4,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 14403813,
      "DESCRIPTION": "FUEL FILTER 4110000727162",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.833333333333333,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 4,
      "SHELF LIFE": 4.800000000000002,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 504,
      "WH REFERENCE": "",
      "TREND": 0.4
    },
    {
      "PART NUMBER": 29270001041,
      "DESCRIPTION": "REAR AXLE BRIDGE",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 14780710,
      "DESCRIPTION": "PLASTIC HOSE 14737201",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 90,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": "7273-10360",
      "DESCRIPTION": "CAP",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 60113128,
      "DESCRIPTION": "FLANGE SCREW 995266",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 20,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 20793501,
      "DESCRIPTION": "PLUG",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 21100405,
      "DESCRIPTION": "VALVE SEAT",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 21100407,
      "DESCRIPTION": "SPRING",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 21100410,
      "DESCRIPTION": "SLEEVE",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 20862028,
      "DESCRIPTION": "PLUNGER",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": "131488000859L",
      "DESCRIPTION": "BRAKE LINING 16MM SANY DT",
      "PRODUCT CATEGORY": "ZZZ",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 2.66666666666666,
      "MAINTANING STOCK QTY": 5,
      "AVAILABLE": 4,
      "SHELF LIFE": 1.5000000000000038,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "RFC",
      "TREND": 1
    },
    {
      "PART NUMBER": "29CM1250",
      "DESCRIPTION": "BOLT",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 7143561,
      "DESCRIPTION": "O-RING ",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 0,
      "WH REFERENCE": "RFC",
      "TREND": 2
    },
    {
      "PART NUMBER": 4110001551002,
      "DESCRIPTION": "FUEL HOSE",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 29020015351,
      "DESCRIPTION": "FUEL FILTER ASSY (TANK)",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 1,
      "SHELF LIFE": 6.000000000000024,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 24017079,
      "DESCRIPTION": "FAN",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 60352070,
      "DESCRIPTION": "FAN CLUTCH",
      "PRODUCT CATEGORY": "YCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 61022011,
      "DESCRIPTION": "REDUCER",
      "PRODUCT CATEGORY": "YPG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 960628,
      "DESCRIPTION": "PLUG",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 23699488,
      "DESCRIPTION": "VALVE COTTER 20459857",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 44,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": "FS20020",
      "DESCRIPTION": " FUEL WATER SEPERATOR",
      "PRODUCT CATEGORY": "CUF",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 18,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 23877099,
      "DESCRIPTION": "BEARING HOUSING 21817834 ",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": "SCC005027363",
      "DESCRIPTION": "CRAWLER PAD",
      "PRODUCT CATEGORY": "YCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 6,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 14750647,
      "DESCRIPTION": "FILTER ELEMENT",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 2,
      "SHELF LIFE": 24.00000000000001,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 14536343,
      "DESCRIPTION": "AS FLYWHEEL",
      "PRODUCT CATEGORY": "YPG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 21516451,
      "DESCRIPTION": "FUEL TANK                ",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 22739212,
      "DESCRIPTION": "TANK BRACKET             ",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 22672756,
      "DESCRIPTION": "MOUNTING STRAP           ",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 20405565,
      "DESCRIPTION": "FLANGE",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 5,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": "150499000008A",
      "DESCRIPTION": "MANUAL PNEUMATIC VALVE",
      "PRODUCT CATEGORY": "YTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 14521529,
      "DESCRIPTION": "VALVE GUIDE",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 14521521,
      "DESCRIPTION": "GUIDE",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 7004842,
      "DESCRIPTION": "COMPRESSOR ASSEMBLY",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": "130699000044A",
      "DESCRIPTION": "PRESSURE PLATE ASSEMBLY, CLUTCH",
      "PRODUCT CATEGORY": "YTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 4,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 24060142,
      "DESCRIPTION": "STEERING PUMP            ",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 61001560,
      "DESCRIPTION": "RUBBER SPRING",
      "PRODUCT CATEGORY": "YCG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.666666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 0,
      "WH REFERENCE": "RFC",
      "TREND": 2
    },
    {
      "PART NUMBER": 11211150,
      "DESCRIPTION": "FILTER INSERT",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 43,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 11211149,
      "DESCRIPTION": "FILTER",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.416666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 1,
      "SHELF LIFE": 2.400000000000004,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 120,
      "WH REFERENCE": "",
      "TREND": 0.4
    },
    {
      "PART NUMBER": 54116585,
      "DESCRIPTION": "ALTERNATOR (ENDURANCE)",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 4,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 11388082,
      "DESCRIPTION": "53032790 CONTROL UNIT 21766717 11443993",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 14453494,
      "DESCRIPTION": "STEEL WIRE ROPE",
      "PRODUCT CATEGORY": "YCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 23871484,
      "DESCRIPTION": "CONTROL VALVE            ",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 5,
      "WH REFERENCE": "",
      "TREND": 1
    },
    {
      "PART NUMBER": "160101040001A001",
      "DESCRIPTION": "V BELT",
      "PRODUCT CATEGORY": "YPG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": "160101040001A002",
      "DESCRIPTION": "V BELT",
      "PRODUCT CATEGORY": "YPG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 14,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": "168899000953A",
      "DESCRIPTION": "ALTERNATOR ASSEMBLY",
      "PRODUCT CATEGORY": "YPG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": "123109Z01B ",
      "DESCRIPTION": "FLYWHEEL",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": "29.09.DG05-82CL-DC24",
      "DESCRIPTION": "COIL",
      "PRODUCT CATEGORY": "PHG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 5223994853,
      "DESCRIPTION": "DISC 301009Z01A ",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 4,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 3030900121,
      "DESCRIPTION": "DRIVE SHAFT GEAR",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 14648463,
      "DESCRIPTION": "PIN",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 5,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 14648464,
      "DESCRIPTION": "PIN",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": "160602010092A001",
      "DESCRIPTION": "AIR FILTER KIT",
      "PRODUCT CATEGORY": "YPG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 1
    },
    {
      "PART NUMBER": 14400991,
      "DESCRIPTION": "BUCKET TOOTH",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.416666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 5,
      "SHELF LIFE": 12.000000000000018,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 4110000011081,
      "DESCRIPTION": "SEAT",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 11210768,
      "DESCRIPTION": "PIN",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.416666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 5,
      "SHELF LIFE": 12.000000000000018,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 11210753,
      "DESCRIPTION": "WASHER",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.416666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 5,
      "SHELF LIFE": 12.000000000000018,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 12291280,
      "DESCRIPTION": "ARRESTER",
      "PRODUCT CATEGORY": "YCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 54315408,
      "DESCRIPTION": "FUEL FILTER 20805349",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "AVERAGE (F2)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 2.5,
      "MAINTANING STOCK QTY": 5,
      "AVAILABLE": 3,
      "SHELF LIFE": 1.2,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 2,
      "APRD QTY": 2,
      "WH12": 359,
      "WH REFERENCE": "WH12",
      "TREND": 2
    },
    {
      "PART NUMBER": "22.4.JSM36*2HXL",
      "DESCRIPTION": "ROTARY JOINT",
      "PRODUCT CATEGORY": "PHG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 5,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 4110000076435,
      "DESCRIPTION": "SEALING RING 4110000076366",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 29050033351,
      "DESCRIPTION": "SEALING RETAINER",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 10943425,
      "DESCRIPTION": "FRONT WINDOW GLASS",
      "PRODUCT CATEGORY": "YCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 14703194,
      "DESCRIPTION": "O-RING",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 2,
      "SHELF LIFE": 12.000000000000048,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 6,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 14619871,
      "DESCRIPTION": "SEALING KIT",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 2,
      "SHELF LIFE": 6.000000000000006,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 14573762,
      "DESCRIPTION": "BEARING",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 0,
      "WH REFERENCE": "RFC",
      "TREND": 2
    },
    {
      "PART NUMBER": "FDJ007138164",
      "DESCRIPTION": "EXHAUST VALVE BRIDGE",
      "PRODUCT CATEGORY": "YPG",
      "ITEM MOVEMENT BRANCH": "AVERAGE (F2)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 4.5,
      "MAINTANING STOCK QTY": 9,
      "AVAILABLE": 9,
      "SHELF LIFE": 2,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 617,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 24046345,
      "DESCRIPTION": "FUEL PUMP 22905123",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 14518437,
      "DESCRIPTION": "ASSEMBLY, SWITCH, LIFT HEIGHT LIMIT",
      "PRODUCT CATEGORY": "YCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 7172745,
      "DESCRIPTION": "BUSHING WEAR",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 60119976,
      "DESCRIPTION": "HEADLAMP LEFT",
      "PRODUCT CATEGORY": "YCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 7168895,
      "DESCRIPTION": "HARNESS, AC CLUTCH",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 54864268,
      "DESCRIPTION": "FUEL FILTER HOUSING 11110708",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 23,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 24078863,
      "DESCRIPTION": "COOLANT",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 2.25,
      "MAINTANING STOCK QTY": 5,
      "AVAILABLE": 9,
      "SHELF LIFE": 4,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 226,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 22761626,
      "DESCRIPTION": "RADIATOR BLIND",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 24318489,
      "DESCRIPTION": "COOLANT PUMP 23974640",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.5,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 1,
      "SHELF LIFE": 2,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 30,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 29250001111,
      "DESCRIPTION": "PLATE",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 24046348,
      "DESCRIPTION": "FUEL PUMP 22803123",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 1,
      "SHELF LIFE": 12.000000000000005,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 7007301,
      "DESCRIPTION": "SWITCH ROCKER",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 11012624,
      "DESCRIPTION": "RIGHT DOOR GLASS",
      "PRODUCT CATEGORY": "YCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 5223822029,
      "DESCRIPTION": "SHOCK ABSORBER (FRONT -LH)",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 5223822030,
      "DESCRIPTION": "SHOCK ABSORBER (FRONT -RH)",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": "29.09.770924",
      "DESCRIPTION": "COIL",
      "PRODUCT CATEGORY": "PHG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 23871482,
      "DESCRIPTION": "CONTROL VALVE            ",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 12466937,
      "DESCRIPTION": "WIRE ROPE, EXTENSION, OUTRIGGER",
      "PRODUCT CATEGORY": "YCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": "122709Z00C",
      "DESCRIPTION": "CRANKSHAFT SEAL",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 60359141,
      "DESCRIPTION": "BRAKE PEDAL AND BRAKE VALVE ASSEMBLY",
      "PRODUCT CATEGORY": "YPG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 60118168,
      "DESCRIPTION": "ARM,REGULATING",
      "PRODUCT CATEGORY": "YPG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 29050037551,
      "DESCRIPTION": "GASKET",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 60118167,
      "DESCRIPTION": "AUTOMATIC BRAKE ADJUSTER\t",
      "PRODUCT CATEGORY": "YTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 14562269,
      "DESCRIPTION": "AIR DUCT",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 11110213,
      "DESCRIPTION": "COVER",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 60238123,
      "DESCRIPTION": "FOOT OPERATED VALVE",
      "PRODUCT CATEGORY": "YCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 7292867,
      "DESCRIPTION": "JOYSTICK LOCK SWITCH",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 7292925,
      "DESCRIPTION": "COIL",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 2
    },
    {
      "PART NUMBER": 0,
      "DESCRIPTION": "",
      "PRODUCT CATEGORY": 0,
      "ITEM MOVEMENT BRANCH": 0,
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 0,
      "DESCRIPTION": "",
      "PRODUCT CATEGORY": 0,
      "ITEM MOVEMENT BRANCH": 0,
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 0,
      "DESCRIPTION": "",
      "PRODUCT CATEGORY": 0,
      "ITEM MOVEMENT BRANCH": 0,
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 0,
      "DESCRIPTION": "",
      "PRODUCT CATEGORY": 0,
      "ITEM MOVEMENT BRANCH": 0,
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 0,
      "DESCRIPTION": "",
      "PRODUCT CATEGORY": 0,
      "ITEM MOVEMENT BRANCH": 0,
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 0,
      "DESCRIPTION": "",
      "PRODUCT CATEGORY": 0,
      "ITEM MOVEMENT BRANCH": 0,
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 0,
      "DESCRIPTION": "",
      "PRODUCT CATEGORY": 0,
      "ITEM MOVEMENT BRANCH": 0,
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 0,
      "DESCRIPTION": "",
      "PRODUCT CATEGORY": 0,
      "ITEM MOVEMENT BRANCH": 0,
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 0,
      "DESCRIPTION": "",
      "PRODUCT CATEGORY": 0,
      "ITEM MOVEMENT BRANCH": 0,
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 0,
      "DESCRIPTION": "",
      "PRODUCT CATEGORY": 0,
      "ITEM MOVEMENT BRANCH": 0,
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 0,
      "DESCRIPTION": "",
      "PRODUCT CATEGORY": 0,
      "ITEM MOVEMENT BRANCH": 0,
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 0,
      "DESCRIPTION": "",
      "PRODUCT CATEGORY": 0,
      "ITEM MOVEMENT BRANCH": 0,
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 0,
      "DESCRIPTION": "",
      "PRODUCT CATEGORY": 0,
      "ITEM MOVEMENT BRANCH": 0,
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 0,
      "DESCRIPTION": "",
      "PRODUCT CATEGORY": 0,
      "ITEM MOVEMENT BRANCH": 0,
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 0,
      "DESCRIPTION": "",
      "PRODUCT CATEGORY": 0,
      "ITEM MOVEMENT BRANCH": 0,
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 0,
      "DESCRIPTION": "",
      "PRODUCT CATEGORY": 0,
      "ITEM MOVEMENT BRANCH": 0,
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 0,
      "DESCRIPTION": "",
      "PRODUCT CATEGORY": 0,
      "ITEM MOVEMENT BRANCH": 0,
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 0,
      "DESCRIPTION": "",
      "PRODUCT CATEGORY": 0,
      "ITEM MOVEMENT BRANCH": 0,
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 0,
      "DESCRIPTION": "",
      "PRODUCT CATEGORY": 0,
      "ITEM MOVEMENT BRANCH": 0,
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 0,
      "DESCRIPTION": "",
      "PRODUCT CATEGORY": 0,
      "ITEM MOVEMENT BRANCH": 0,
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 0,
      "DESCRIPTION": "",
      "PRODUCT CATEGORY": 0,
      "ITEM MOVEMENT BRANCH": 0,
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 0,
      "DESCRIPTION": "",
      "PRODUCT CATEGORY": 0,
      "ITEM MOVEMENT BRANCH": 0,
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 0,
      "DESCRIPTION": "",
      "PRODUCT CATEGORY": 0,
      "ITEM MOVEMENT BRANCH": 0,
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 0,
      "DESCRIPTION": "",
      "PRODUCT CATEGORY": 0,
      "ITEM MOVEMENT BRANCH": 0,
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 0,
      "DESCRIPTION": "",
      "PRODUCT CATEGORY": 0,
      "ITEM MOVEMENT BRANCH": 0,
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 0,
      "DESCRIPTION": "",
      "PRODUCT CATEGORY": 0,
      "ITEM MOVEMENT BRANCH": 0,
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 0,
      "DESCRIPTION": "",
      "PRODUCT CATEGORY": 0,
      "ITEM MOVEMENT BRANCH": 0,
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 0,
      "DESCRIPTION": "",
      "PRODUCT CATEGORY": 0,
      "ITEM MOVEMENT BRANCH": 0,
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 0,
      "DESCRIPTION": "",
      "PRODUCT CATEGORY": 0,
      "ITEM MOVEMENT BRANCH": 0,
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 0,
      "DESCRIPTION": "",
      "PRODUCT CATEGORY": 0,
      "ITEM MOVEMENT BRANCH": 0,
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 0,
      "DESCRIPTION": "",
      "PRODUCT CATEGORY": 0,
      "ITEM MOVEMENT BRANCH": 0,
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 0,
      "DESCRIPTION": "",
      "PRODUCT CATEGORY": 0,
      "ITEM MOVEMENT BRANCH": 0,
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 0,
      "DESCRIPTION": "",
      "PRODUCT CATEGORY": 0,
      "ITEM MOVEMENT BRANCH": 0,
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 0,
      "DESCRIPTION": "",
      "PRODUCT CATEGORY": 0,
      "ITEM MOVEMENT BRANCH": 0,
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 0,
      "DESCRIPTION": "",
      "PRODUCT CATEGORY": 0,
      "ITEM MOVEMENT BRANCH": 0,
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 0,
      "DESCRIPTION": "",
      "PRODUCT CATEGORY": 0,
      "ITEM MOVEMENT BRANCH": 0,
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 0,
      "DESCRIPTION": "",
      "PRODUCT CATEGORY": 0,
      "ITEM MOVEMENT BRANCH": 0,
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 0,
      "DESCRIPTION": "",
      "PRODUCT CATEGORY": 0,
      "ITEM MOVEMENT BRANCH": 0,
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 0,
      "DESCRIPTION": "",
      "PRODUCT CATEGORY": 0,
      "ITEM MOVEMENT BRANCH": 0,
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 0,
      "DESCRIPTION": "",
      "PRODUCT CATEGORY": 0,
      "ITEM MOVEMENT BRANCH": 0,
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 0,
      "DESCRIPTION": "",
      "PRODUCT CATEGORY": 0,
      "ITEM MOVEMENT BRANCH": 0,
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 0,
      "DESCRIPTION": "",
      "PRODUCT CATEGORY": 0,
      "ITEM MOVEMENT BRANCH": 0,
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 0,
      "DESCRIPTION": "",
      "PRODUCT CATEGORY": 0,
      "ITEM MOVEMENT BRANCH": 0,
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 0,
      "DESCRIPTION": "",
      "PRODUCT CATEGORY": 0,
      "ITEM MOVEMENT BRANCH": 0,
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 0,
      "DESCRIPTION": "",
      "PRODUCT CATEGORY": 0,
      "ITEM MOVEMENT BRANCH": 0,
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 0,
      "DESCRIPTION": "",
      "PRODUCT CATEGORY": 0,
      "ITEM MOVEMENT BRANCH": 0,
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 0,
      "DESCRIPTION": "",
      "PRODUCT CATEGORY": 0,
      "ITEM MOVEMENT BRANCH": 0,
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 0,
      "DESCRIPTION": "",
      "PRODUCT CATEGORY": 0,
      "ITEM MOVEMENT BRANCH": 0,
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 0,
      "DESCRIPTION": "",
      "PRODUCT CATEGORY": 0,
      "ITEM MOVEMENT BRANCH": 0,
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 0,
      "DESCRIPTION": "",
      "PRODUCT CATEGORY": 0,
      "ITEM MOVEMENT BRANCH": 0,
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 0,
      "DESCRIPTION": "",
      "PRODUCT CATEGORY": 0,
      "ITEM MOVEMENT BRANCH": 0,
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 0,
      "DESCRIPTION": "",
      "PRODUCT CATEGORY": 0,
      "ITEM MOVEMENT BRANCH": 0,
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 0,
      "DESCRIPTION": "",
      "PRODUCT CATEGORY": 0,
      "ITEM MOVEMENT BRANCH": 0,
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 0,
      "DESCRIPTION": "",
      "PRODUCT CATEGORY": 0,
      "ITEM MOVEMENT BRANCH": 0,
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 0,
      "DESCRIPTION": "",
      "PRODUCT CATEGORY": 0,
      "ITEM MOVEMENT BRANCH": 0,
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 0,
      "DESCRIPTION": "",
      "PRODUCT CATEGORY": 0,
      "ITEM MOVEMENT BRANCH": 0,
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 0,
      "DESCRIPTION": "",
      "PRODUCT CATEGORY": 0,
      "ITEM MOVEMENT BRANCH": 0,
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 0,
      "DESCRIPTION": "",
      "PRODUCT CATEGORY": 0,
      "ITEM MOVEMENT BRANCH": 0,
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 0,
      "DESCRIPTION": "",
      "PRODUCT CATEGORY": 0,
      "ITEM MOVEMENT BRANCH": 0,
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 0,
      "DESCRIPTION": "",
      "PRODUCT CATEGORY": 0,
      "ITEM MOVEMENT BRANCH": 0,
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 0,
      "DESCRIPTION": "",
      "PRODUCT CATEGORY": 0,
      "ITEM MOVEMENT BRANCH": 0,
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 0,
      "DESCRIPTION": "",
      "PRODUCT CATEGORY": 0,
      "ITEM MOVEMENT BRANCH": 0,
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 0,
      "DESCRIPTION": "",
      "PRODUCT CATEGORY": 0,
      "ITEM MOVEMENT BRANCH": 0,
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 0,
      "DESCRIPTION": "",
      "PRODUCT CATEGORY": 0,
      "ITEM MOVEMENT BRANCH": 0,
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 0,
      "DESCRIPTION": "",
      "PRODUCT CATEGORY": 0,
      "ITEM MOVEMENT BRANCH": 0,
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 0,
      "DESCRIPTION": "",
      "PRODUCT CATEGORY": 0,
      "ITEM MOVEMENT BRANCH": 0,
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 0,
      "DESCRIPTION": "",
      "PRODUCT CATEGORY": 0,
      "ITEM MOVEMENT BRANCH": 0,
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 0,
      "DESCRIPTION": "",
      "PRODUCT CATEGORY": 0,
      "ITEM MOVEMENT BRANCH": 0,
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 0,
      "DESCRIPTION": "",
      "PRODUCT CATEGORY": 0,
      "ITEM MOVEMENT BRANCH": 0,
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 0,
      "DESCRIPTION": "",
      "PRODUCT CATEGORY": 0,
      "ITEM MOVEMENT BRANCH": 0,
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 0,
      "DESCRIPTION": "",
      "PRODUCT CATEGORY": 0,
      "ITEM MOVEMENT BRANCH": 0,
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 0,
      "DESCRIPTION": "",
      "PRODUCT CATEGORY": 0,
      "ITEM MOVEMENT BRANCH": 0,
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 0,
      "DESCRIPTION": "",
      "PRODUCT CATEGORY": 0,
      "ITEM MOVEMENT BRANCH": 0,
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 0,
      "DESCRIPTION": "",
      "PRODUCT CATEGORY": 0,
      "ITEM MOVEMENT BRANCH": 0,
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 0,
      "DESCRIPTION": "",
      "PRODUCT CATEGORY": 0,
      "ITEM MOVEMENT BRANCH": 0,
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 0,
      "DESCRIPTION": "",
      "PRODUCT CATEGORY": 0,
      "ITEM MOVEMENT BRANCH": 0,
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 0,
      "DESCRIPTION": "",
      "PRODUCT CATEGORY": 0,
      "ITEM MOVEMENT BRANCH": 0,
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 0,
      "DESCRIPTION": "",
      "PRODUCT CATEGORY": 0,
      "ITEM MOVEMENT BRANCH": 0,
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 0,
      "DESCRIPTION": "",
      "PRODUCT CATEGORY": 0,
      "ITEM MOVEMENT BRANCH": 0,
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 0,
      "DESCRIPTION": "",
      "PRODUCT CATEGORY": 0,
      "ITEM MOVEMENT BRANCH": 0,
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 0,
      "DESCRIPTION": "",
      "PRODUCT CATEGORY": 0,
      "ITEM MOVEMENT BRANCH": 0,
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 0,
      "DESCRIPTION": "",
      "PRODUCT CATEGORY": 0,
      "ITEM MOVEMENT BRANCH": 0,
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 0,
      "DESCRIPTION": "",
      "PRODUCT CATEGORY": 0,
      "ITEM MOVEMENT BRANCH": 0,
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 0,
      "DESCRIPTION": "",
      "PRODUCT CATEGORY": 0,
      "ITEM MOVEMENT BRANCH": 0,
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 0,
      "DESCRIPTION": "",
      "PRODUCT CATEGORY": 0,
      "ITEM MOVEMENT BRANCH": 0,
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 0,
      "DESCRIPTION": "",
      "PRODUCT CATEGORY": 0,
      "ITEM MOVEMENT BRANCH": 0,
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 0,
      "DESCRIPTION": "",
      "PRODUCT CATEGORY": 0,
      "ITEM MOVEMENT BRANCH": 0,
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 0,
      "DESCRIPTION": "",
      "PRODUCT CATEGORY": 0,
      "ITEM MOVEMENT BRANCH": 0,
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 0,
      "DESCRIPTION": "",
      "PRODUCT CATEGORY": 0,
      "ITEM MOVEMENT BRANCH": 0,
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 0,
      "DESCRIPTION": "",
      "PRODUCT CATEGORY": 0,
      "ITEM MOVEMENT BRANCH": 0,
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 0,
      "DESCRIPTION": "",
      "PRODUCT CATEGORY": 0,
      "ITEM MOVEMENT BRANCH": 0,
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 0,
      "DESCRIPTION": "",
      "PRODUCT CATEGORY": 0,
      "ITEM MOVEMENT BRANCH": 0,
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 0,
      "DESCRIPTION": "",
      "PRODUCT CATEGORY": 0,
      "ITEM MOVEMENT BRANCH": 0,
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 0,
      "DESCRIPTION": "",
      "PRODUCT CATEGORY": 0,
      "ITEM MOVEMENT BRANCH": 0,
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 0,
      "DESCRIPTION": "",
      "PRODUCT CATEGORY": 0,
      "ITEM MOVEMENT BRANCH": 0,
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 0,
      "DESCRIPTION": "",
      "PRODUCT CATEGORY": 0,
      "ITEM MOVEMENT BRANCH": 0,
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 0,
      "DESCRIPTION": "",
      "PRODUCT CATEGORY": 0,
      "ITEM MOVEMENT BRANCH": 0,
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 0,
      "DESCRIPTION": "",
      "PRODUCT CATEGORY": 0,
      "ITEM MOVEMENT BRANCH": 0,
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 0,
      "DESCRIPTION": "",
      "PRODUCT CATEGORY": 0,
      "ITEM MOVEMENT BRANCH": 0,
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 0,
      "DESCRIPTION": "",
      "PRODUCT CATEGORY": 0,
      "ITEM MOVEMENT BRANCH": 0,
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 0,
      "DESCRIPTION": "",
      "PRODUCT CATEGORY": 0,
      "ITEM MOVEMENT BRANCH": 0,
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 0,
      "DESCRIPTION": "",
      "PRODUCT CATEGORY": 0,
      "ITEM MOVEMENT BRANCH": 0,
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 0,
      "DESCRIPTION": "",
      "PRODUCT CATEGORY": 0,
      "ITEM MOVEMENT BRANCH": 0,
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 0,
      "DESCRIPTION": "",
      "PRODUCT CATEGORY": 0,
      "ITEM MOVEMENT BRANCH": 0,
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 0,
      "DESCRIPTION": "",
      "PRODUCT CATEGORY": 0,
      "ITEM MOVEMENT BRANCH": 0,
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 0,
      "DESCRIPTION": "",
      "PRODUCT CATEGORY": 0,
      "ITEM MOVEMENT BRANCH": 0,
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 0,
      "DESCRIPTION": "",
      "PRODUCT CATEGORY": 0,
      "ITEM MOVEMENT BRANCH": 0,
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 0,
      "DESCRIPTION": "",
      "PRODUCT CATEGORY": 0,
      "ITEM MOVEMENT BRANCH": 0,
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 0,
      "DESCRIPTION": "",
      "PRODUCT CATEGORY": 0,
      "ITEM MOVEMENT BRANCH": 0,
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 0,
      "DESCRIPTION": "",
      "PRODUCT CATEGORY": 0,
      "ITEM MOVEMENT BRANCH": 0,
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 0,
      "DESCRIPTION": "",
      "PRODUCT CATEGORY": 0,
      "ITEM MOVEMENT BRANCH": 0,
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 0,
      "DESCRIPTION": "",
      "PRODUCT CATEGORY": 0,
      "ITEM MOVEMENT BRANCH": 0,
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 0,
      "DESCRIPTION": "",
      "PRODUCT CATEGORY": 0,
      "ITEM MOVEMENT BRANCH": 0,
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 0,
      "DESCRIPTION": "",
      "PRODUCT CATEGORY": 0,
      "ITEM MOVEMENT BRANCH": 0,
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 0,
      "DESCRIPTION": "",
      "PRODUCT CATEGORY": 0,
      "ITEM MOVEMENT BRANCH": 0,
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 0,
      "DESCRIPTION": "",
      "PRODUCT CATEGORY": 0,
      "ITEM MOVEMENT BRANCH": 0,
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 0,
      "DESCRIPTION": "",
      "PRODUCT CATEGORY": 0,
      "ITEM MOVEMENT BRANCH": 0,
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 0,
      "DESCRIPTION": "",
      "PRODUCT CATEGORY": 0,
      "ITEM MOVEMENT BRANCH": 0,
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 0,
      "DESCRIPTION": "",
      "PRODUCT CATEGORY": 0,
      "ITEM MOVEMENT BRANCH": 0,
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 0,
      "DESCRIPTION": "",
      "PRODUCT CATEGORY": 0,
      "ITEM MOVEMENT BRANCH": 0,
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 0,
      "DESCRIPTION": "",
      "PRODUCT CATEGORY": 0,
      "ITEM MOVEMENT BRANCH": 0,
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 0,
      "DESCRIPTION": "",
      "PRODUCT CATEGORY": 0,
      "ITEM MOVEMENT BRANCH": 0,
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 0,
      "DESCRIPTION": "",
      "PRODUCT CATEGORY": 0,
      "ITEM MOVEMENT BRANCH": 0,
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 0,
      "DESCRIPTION": "",
      "PRODUCT CATEGORY": 0,
      "ITEM MOVEMENT BRANCH": 0,
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 0,
      "DESCRIPTION": "",
      "PRODUCT CATEGORY": 0,
      "ITEM MOVEMENT BRANCH": 0,
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 0,
      "DESCRIPTION": "",
      "PRODUCT CATEGORY": 0,
      "ITEM MOVEMENT BRANCH": 0,
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 0,
      "DESCRIPTION": "",
      "PRODUCT CATEGORY": 0,
      "ITEM MOVEMENT BRANCH": 0,
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 0,
      "DESCRIPTION": "",
      "PRODUCT CATEGORY": 0,
      "ITEM MOVEMENT BRANCH": 0,
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 0,
      "DESCRIPTION": "",
      "PRODUCT CATEGORY": 0,
      "ITEM MOVEMENT BRANCH": 0,
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 0,
      "DESCRIPTION": "",
      "PRODUCT CATEGORY": 0,
      "ITEM MOVEMENT BRANCH": 0,
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 0,
      "DESCRIPTION": "",
      "PRODUCT CATEGORY": 0,
      "ITEM MOVEMENT BRANCH": 0,
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 0,
      "DESCRIPTION": "",
      "PRODUCT CATEGORY": 0,
      "ITEM MOVEMENT BRANCH": 0,
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 0,
      "DESCRIPTION": "",
      "PRODUCT CATEGORY": 0,
      "ITEM MOVEMENT BRANCH": 0,
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 0,
      "DESCRIPTION": "",
      "PRODUCT CATEGORY": 0,
      "ITEM MOVEMENT BRANCH": 0,
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 0,
      "DESCRIPTION": "",
      "PRODUCT CATEGORY": 0,
      "ITEM MOVEMENT BRANCH": 0,
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 0,
      "DESCRIPTION": "",
      "PRODUCT CATEGORY": 0,
      "ITEM MOVEMENT BRANCH": 0,
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 0,
      "DESCRIPTION": "",
      "PRODUCT CATEGORY": 0,
      "ITEM MOVEMENT BRANCH": 0,
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 0,
      "DESCRIPTION": "",
      "PRODUCT CATEGORY": 0,
      "ITEM MOVEMENT BRANCH": 0,
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 0,
      "DESCRIPTION": "",
      "PRODUCT CATEGORY": 0,
      "ITEM MOVEMENT BRANCH": 0,
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 0,
      "DESCRIPTION": "",
      "PRODUCT CATEGORY": 0,
      "ITEM MOVEMENT BRANCH": 0,
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 0,
      "DESCRIPTION": "",
      "PRODUCT CATEGORY": 0,
      "ITEM MOVEMENT BRANCH": 0,
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 0,
      "DESCRIPTION": "",
      "PRODUCT CATEGORY": 0,
      "ITEM MOVEMENT BRANCH": 0,
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 0,
      "DESCRIPTION": "",
      "PRODUCT CATEGORY": 0,
      "ITEM MOVEMENT BRANCH": 0,
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 0,
      "DESCRIPTION": "",
      "PRODUCT CATEGORY": 0,
      "ITEM MOVEMENT BRANCH": 0,
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 0,
      "DESCRIPTION": "",
      "PRODUCT CATEGORY": 0,
      "ITEM MOVEMENT BRANCH": 0,
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 0,
      "DESCRIPTION": "",
      "PRODUCT CATEGORY": 0,
      "ITEM MOVEMENT BRANCH": 0,
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 0,
      "DESCRIPTION": "",
      "PRODUCT CATEGORY": 0,
      "ITEM MOVEMENT BRANCH": 0,
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 0,
      "DESCRIPTION": "",
      "PRODUCT CATEGORY": 0,
      "ITEM MOVEMENT BRANCH": 0,
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 0,
      "DESCRIPTION": "",
      "PRODUCT CATEGORY": 0,
      "ITEM MOVEMENT BRANCH": 0,
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 0,
      "DESCRIPTION": "",
      "PRODUCT CATEGORY": 0,
      "ITEM MOVEMENT BRANCH": 0,
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 0,
      "DESCRIPTION": "",
      "PRODUCT CATEGORY": 0,
      "ITEM MOVEMENT BRANCH": 0,
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 0,
      "DESCRIPTION": "",
      "PRODUCT CATEGORY": 0,
      "ITEM MOVEMENT BRANCH": 0,
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 0,
      "DESCRIPTION": "",
      "PRODUCT CATEGORY": 0,
      "ITEM MOVEMENT BRANCH": 0,
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 0,
      "DESCRIPTION": "",
      "PRODUCT CATEGORY": 0,
      "ITEM MOVEMENT BRANCH": 0,
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 0,
      "DESCRIPTION": "",
      "PRODUCT CATEGORY": 0,
      "ITEM MOVEMENT BRANCH": 0,
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 0,
      "DESCRIPTION": "",
      "PRODUCT CATEGORY": 0,
      "ITEM MOVEMENT BRANCH": 0,
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 0,
      "DESCRIPTION": "",
      "PRODUCT CATEGORY": 0,
      "ITEM MOVEMENT BRANCH": 0,
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 0,
      "DESCRIPTION": "",
      "PRODUCT CATEGORY": 0,
      "ITEM MOVEMENT BRANCH": 0,
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 0,
      "DESCRIPTION": "",
      "PRODUCT CATEGORY": 0,
      "ITEM MOVEMENT BRANCH": 0,
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 0,
      "DESCRIPTION": "",
      "PRODUCT CATEGORY": 0,
      "ITEM MOVEMENT BRANCH": 0,
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 0,
      "DESCRIPTION": "",
      "PRODUCT CATEGORY": 0,
      "ITEM MOVEMENT BRANCH": 0,
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 0,
      "DESCRIPTION": "",
      "PRODUCT CATEGORY": 0,
      "ITEM MOVEMENT BRANCH": 0,
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 0,
      "DESCRIPTION": "",
      "PRODUCT CATEGORY": 0,
      "ITEM MOVEMENT BRANCH": 0,
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 0,
      "DESCRIPTION": "",
      "PRODUCT CATEGORY": 0,
      "ITEM MOVEMENT BRANCH": 0,
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 0,
      "DESCRIPTION": "",
      "PRODUCT CATEGORY": 0,
      "ITEM MOVEMENT BRANCH": 0,
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 0,
      "DESCRIPTION": "",
      "PRODUCT CATEGORY": 0,
      "ITEM MOVEMENT BRANCH": 0,
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 0,
      "DESCRIPTION": "",
      "PRODUCT CATEGORY": 0,
      "ITEM MOVEMENT BRANCH": 0,
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 0,
      "DESCRIPTION": "",
      "PRODUCT CATEGORY": 0,
      "ITEM MOVEMENT BRANCH": 0,
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 0,
      "DESCRIPTION": "",
      "PRODUCT CATEGORY": 0,
      "ITEM MOVEMENT BRANCH": 0,
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 0,
      "DESCRIPTION": "",
      "PRODUCT CATEGORY": 0,
      "ITEM MOVEMENT BRANCH": 0,
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 0,
      "DESCRIPTION": "",
      "PRODUCT CATEGORY": 0,
      "ITEM MOVEMENT BRANCH": 0,
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 0,
      "DESCRIPTION": "",
      "PRODUCT CATEGORY": 0,
      "ITEM MOVEMENT BRANCH": 0,
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 0,
      "DESCRIPTION": "",
      "PRODUCT CATEGORY": 0,
      "ITEM MOVEMENT BRANCH": 0,
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 0,
      "DESCRIPTION": "",
      "PRODUCT CATEGORY": 0,
      "ITEM MOVEMENT BRANCH": 0,
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 0,
      "DESCRIPTION": "",
      "PRODUCT CATEGORY": 0,
      "ITEM MOVEMENT BRANCH": 0,
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 0,
      "DESCRIPTION": "",
      "PRODUCT CATEGORY": 0,
      "ITEM MOVEMENT BRANCH": 0,
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 0,
      "DESCRIPTION": "",
      "PRODUCT CATEGORY": 0,
      "ITEM MOVEMENT BRANCH": 0,
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 0,
      "DESCRIPTION": "",
      "PRODUCT CATEGORY": 0,
      "ITEM MOVEMENT BRANCH": 0,
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 0,
      "DESCRIPTION": "",
      "PRODUCT CATEGORY": 0,
      "ITEM MOVEMENT BRANCH": 0,
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 0,
      "DESCRIPTION": "",
      "PRODUCT CATEGORY": 0,
      "ITEM MOVEMENT BRANCH": 0,
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 0,
      "DESCRIPTION": "",
      "PRODUCT CATEGORY": 0,
      "ITEM MOVEMENT BRANCH": 0,
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 0,
      "DESCRIPTION": "",
      "PRODUCT CATEGORY": 0,
      "ITEM MOVEMENT BRANCH": 0,
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 0,
      "DESCRIPTION": "",
      "PRODUCT CATEGORY": 0,
      "ITEM MOVEMENT BRANCH": 0,
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 0,
      "DESCRIPTION": "",
      "PRODUCT CATEGORY": 0,
      "ITEM MOVEMENT BRANCH": 0,
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 0,
      "DESCRIPTION": "",
      "PRODUCT CATEGORY": 0,
      "ITEM MOVEMENT BRANCH": 0,
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 0,
      "DESCRIPTION": "",
      "PRODUCT CATEGORY": 0,
      "ITEM MOVEMENT BRANCH": 0,
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 0,
      "DESCRIPTION": "",
      "PRODUCT CATEGORY": 0,
      "ITEM MOVEMENT BRANCH": 0,
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 0,
      "DESCRIPTION": "",
      "PRODUCT CATEGORY": 0,
      "ITEM MOVEMENT BRANCH": 0,
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 0,
      "DESCRIPTION": "",
      "PRODUCT CATEGORY": 0,
      "ITEM MOVEMENT BRANCH": 0,
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 0,
      "DESCRIPTION": "",
      "PRODUCT CATEGORY": 0,
      "ITEM MOVEMENT BRANCH": 0,
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 0,
      "DESCRIPTION": "",
      "PRODUCT CATEGORY": 0,
      "ITEM MOVEMENT BRANCH": 0,
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 0,
      "DESCRIPTION": "",
      "PRODUCT CATEGORY": 0,
      "ITEM MOVEMENT BRANCH": 0,
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 0,
      "DESCRIPTION": "",
      "PRODUCT CATEGORY": 0,
      "ITEM MOVEMENT BRANCH": 0,
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 0,
      "DESCRIPTION": "",
      "PRODUCT CATEGORY": 0,
      "ITEM MOVEMENT BRANCH": 0,
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 0,
      "DESCRIPTION": "",
      "PRODUCT CATEGORY": 0,
      "ITEM MOVEMENT BRANCH": 0,
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 0,
      "DESCRIPTION": "",
      "PRODUCT CATEGORY": 0,
      "ITEM MOVEMENT BRANCH": 0,
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 0,
      "DESCRIPTION": "",
      "PRODUCT CATEGORY": 0,
      "ITEM MOVEMENT BRANCH": 0,
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 0,
      "DESCRIPTION": "",
      "PRODUCT CATEGORY": 0,
      "ITEM MOVEMENT BRANCH": 0,
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 0,
      "DESCRIPTION": "",
      "PRODUCT CATEGORY": 0,
      "ITEM MOVEMENT BRANCH": 0,
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 0,
      "DESCRIPTION": "",
      "PRODUCT CATEGORY": 0,
      "ITEM MOVEMENT BRANCH": 0,
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 0,
      "DESCRIPTION": "",
      "PRODUCT CATEGORY": 0,
      "ITEM MOVEMENT BRANCH": 0,
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 0,
      "DESCRIPTION": "",
      "PRODUCT CATEGORY": 0,
      "ITEM MOVEMENT BRANCH": 0,
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 0,
      "DESCRIPTION": "",
      "PRODUCT CATEGORY": 0,
      "ITEM MOVEMENT BRANCH": 0,
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 0,
      "DESCRIPTION": "",
      "PRODUCT CATEGORY": 0,
      "ITEM MOVEMENT BRANCH": 0,
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 0,
      "DESCRIPTION": "",
      "PRODUCT CATEGORY": 0,
      "ITEM MOVEMENT BRANCH": 0,
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 0,
      "DESCRIPTION": "",
      "PRODUCT CATEGORY": 0,
      "ITEM MOVEMENT BRANCH": 0,
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 0,
      "DESCRIPTION": "",
      "PRODUCT CATEGORY": 0,
      "ITEM MOVEMENT BRANCH": 0,
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 0,
      "DESCRIPTION": "",
      "PRODUCT CATEGORY": 0,
      "ITEM MOVEMENT BRANCH": 0,
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 0,
      "DESCRIPTION": "",
      "PRODUCT CATEGORY": 0,
      "ITEM MOVEMENT BRANCH": 0,
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 0,
      "DESCRIPTION": "",
      "PRODUCT CATEGORY": 0,
      "ITEM MOVEMENT BRANCH": 0,
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 0,
      "DESCRIPTION": "",
      "PRODUCT CATEGORY": 0,
      "ITEM MOVEMENT BRANCH": 0,
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 0,
      "DESCRIPTION": "",
      "PRODUCT CATEGORY": 0,
      "ITEM MOVEMENT BRANCH": 0,
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 0,
      "DESCRIPTION": "",
      "PRODUCT CATEGORY": 0,
      "ITEM MOVEMENT BRANCH": 0,
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 0,
      "DESCRIPTION": "",
      "PRODUCT CATEGORY": 0,
      "ITEM MOVEMENT BRANCH": 0,
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 0,
      "DESCRIPTION": "",
      "PRODUCT CATEGORY": 0,
      "ITEM MOVEMENT BRANCH": 0,
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 0,
      "DESCRIPTION": "",
      "PRODUCT CATEGORY": 0,
      "ITEM MOVEMENT BRANCH": 0,
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 0,
      "DESCRIPTION": "",
      "PRODUCT CATEGORY": 0,
      "ITEM MOVEMENT BRANCH": 0,
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 0,
      "DESCRIPTION": "",
      "PRODUCT CATEGORY": 0,
      "ITEM MOVEMENT BRANCH": 0,
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 0,
      "DESCRIPTION": "",
      "PRODUCT CATEGORY": 0,
      "ITEM MOVEMENT BRANCH": 0,
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 0,
      "DESCRIPTION": "",
      "PRODUCT CATEGORY": 0,
      "ITEM MOVEMENT BRANCH": 0,
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 0,
      "DESCRIPTION": "",
      "PRODUCT CATEGORY": 0,
      "ITEM MOVEMENT BRANCH": 0,
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 0,
      "DESCRIPTION": "",
      "PRODUCT CATEGORY": 0,
      "ITEM MOVEMENT BRANCH": 0,
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 0,
      "DESCRIPTION": "",
      "PRODUCT CATEGORY": 0,
      "ITEM MOVEMENT BRANCH": 0,
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 0,
      "DESCRIPTION": "",
      "PRODUCT CATEGORY": 0,
      "ITEM MOVEMENT BRANCH": 0,
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 0,
      "DESCRIPTION": "",
      "PRODUCT CATEGORY": 0,
      "ITEM MOVEMENT BRANCH": 0,
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 0,
      "DESCRIPTION": "",
      "PRODUCT CATEGORY": 0,
      "ITEM MOVEMENT BRANCH": 0,
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 0,
      "DESCRIPTION": "",
      "PRODUCT CATEGORY": 0,
      "ITEM MOVEMENT BRANCH": 0,
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 0,
      "DESCRIPTION": "",
      "PRODUCT CATEGORY": 0,
      "ITEM MOVEMENT BRANCH": 0,
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 0,
      "DESCRIPTION": "",
      "PRODUCT CATEGORY": 0,
      "ITEM MOVEMENT BRANCH": 0,
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 0,
      "DESCRIPTION": "",
      "PRODUCT CATEGORY": 0,
      "ITEM MOVEMENT BRANCH": 0,
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 0,
      "DESCRIPTION": "",
      "PRODUCT CATEGORY": 0,
      "ITEM MOVEMENT BRANCH": 0,
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 0,
      "DESCRIPTION": "",
      "PRODUCT CATEGORY": 0,
      "ITEM MOVEMENT BRANCH": 0,
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 0,
      "DESCRIPTION": "",
      "PRODUCT CATEGORY": 0,
      "ITEM MOVEMENT BRANCH": 0,
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 0,
      "DESCRIPTION": "",
      "PRODUCT CATEGORY": 0,
      "ITEM MOVEMENT BRANCH": 0,
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 0,
      "DESCRIPTION": "",
      "PRODUCT CATEGORY": 0,
      "ITEM MOVEMENT BRANCH": 0,
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 0,
      "DESCRIPTION": "",
      "PRODUCT CATEGORY": 0,
      "ITEM MOVEMENT BRANCH": 0,
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 0,
      "DESCRIPTION": "",
      "PRODUCT CATEGORY": 0,
      "ITEM MOVEMENT BRANCH": 0,
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 0,
      "DESCRIPTION": "",
      "PRODUCT CATEGORY": 0,
      "ITEM MOVEMENT BRANCH": 0,
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 0,
      "DESCRIPTION": "",
      "PRODUCT CATEGORY": 0,
      "ITEM MOVEMENT BRANCH": 0,
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 0,
      "DESCRIPTION": "",
      "PRODUCT CATEGORY": 0,
      "ITEM MOVEMENT BRANCH": 0,
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 0,
      "DESCRIPTION": "",
      "PRODUCT CATEGORY": 0,
      "ITEM MOVEMENT BRANCH": 0,
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 0,
      "DESCRIPTION": "",
      "PRODUCT CATEGORY": 0,
      "ITEM MOVEMENT BRANCH": 0,
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 0,
      "DESCRIPTION": "",
      "PRODUCT CATEGORY": 0,
      "ITEM MOVEMENT BRANCH": 0,
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 0,
      "DESCRIPTION": "",
      "PRODUCT CATEGORY": 0,
      "ITEM MOVEMENT BRANCH": 0,
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 0,
      "DESCRIPTION": "",
      "PRODUCT CATEGORY": 0,
      "ITEM MOVEMENT BRANCH": 0,
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 0,
      "DESCRIPTION": "",
      "PRODUCT CATEGORY": 0,
      "ITEM MOVEMENT BRANCH": 0,
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 0,
      "DESCRIPTION": "",
      "PRODUCT CATEGORY": 0,
      "ITEM MOVEMENT BRANCH": 0,
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 0,
      "DESCRIPTION": "",
      "PRODUCT CATEGORY": 0,
      "ITEM MOVEMENT BRANCH": 0,
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 0,
      "DESCRIPTION": "",
      "PRODUCT CATEGORY": 0,
      "ITEM MOVEMENT BRANCH": 0,
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 0,
      "DESCRIPTION": "",
      "PRODUCT CATEGORY": 0,
      "ITEM MOVEMENT BRANCH": 0,
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 0,
      "DESCRIPTION": "",
      "PRODUCT CATEGORY": 0,
      "ITEM MOVEMENT BRANCH": 0,
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 0,
      "DESCRIPTION": "",
      "PRODUCT CATEGORY": 0,
      "ITEM MOVEMENT BRANCH": 0,
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 0,
      "DESCRIPTION": "",
      "PRODUCT CATEGORY": 0,
      "ITEM MOVEMENT BRANCH": 0,
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 0,
      "DESCRIPTION": "",
      "PRODUCT CATEGORY": 0,
      "ITEM MOVEMENT BRANCH": 0,
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 0,
      "DESCRIPTION": "",
      "PRODUCT CATEGORY": 0,
      "ITEM MOVEMENT BRANCH": 0,
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 0,
      "DESCRIPTION": "",
      "PRODUCT CATEGORY": 0,
      "ITEM MOVEMENT BRANCH": 0,
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 0,
      "DESCRIPTION": "",
      "PRODUCT CATEGORY": 0,
      "ITEM MOVEMENT BRANCH": 0,
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 0,
      "DESCRIPTION": "",
      "PRODUCT CATEGORY": 0,
      "ITEM MOVEMENT BRANCH": 0,
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 0,
      "DESCRIPTION": "",
      "PRODUCT CATEGORY": 0,
      "ITEM MOVEMENT BRANCH": 0,
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 0,
      "DESCRIPTION": "",
      "PRODUCT CATEGORY": 0,
      "ITEM MOVEMENT BRANCH": 0,
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 0,
      "DESCRIPTION": "",
      "PRODUCT CATEGORY": 0,
      "ITEM MOVEMENT BRANCH": 0,
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 0,
      "DESCRIPTION": "",
      "PRODUCT CATEGORY": 0,
      "ITEM MOVEMENT BRANCH": 0,
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 0,
      "DESCRIPTION": "",
      "PRODUCT CATEGORY": 0,
      "ITEM MOVEMENT BRANCH": 0,
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 0,
      "DESCRIPTION": "",
      "PRODUCT CATEGORY": 0,
      "ITEM MOVEMENT BRANCH": 0,
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 0,
      "DESCRIPTION": "",
      "PRODUCT CATEGORY": 0,
      "ITEM MOVEMENT BRANCH": 0,
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 0,
      "DESCRIPTION": "",
      "PRODUCT CATEGORY": 0,
      "ITEM MOVEMENT BRANCH": 0,
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 0,
      "DESCRIPTION": "",
      "PRODUCT CATEGORY": 0,
      "ITEM MOVEMENT BRANCH": 0,
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 0,
      "DESCRIPTION": "",
      "PRODUCT CATEGORY": 0,
      "ITEM MOVEMENT BRANCH": 0,
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 0,
      "DESCRIPTION": "",
      "PRODUCT CATEGORY": 0,
      "ITEM MOVEMENT BRANCH": 0,
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 0,
      "DESCRIPTION": "",
      "PRODUCT CATEGORY": 0,
      "ITEM MOVEMENT BRANCH": 0,
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 0,
      "DESCRIPTION": "",
      "PRODUCT CATEGORY": 0,
      "ITEM MOVEMENT BRANCH": 0,
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 0,
      "DESCRIPTION": "",
      "PRODUCT CATEGORY": 0,
      "ITEM MOVEMENT BRANCH": 0,
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 0,
      "DESCRIPTION": "",
      "PRODUCT CATEGORY": 0,
      "ITEM MOVEMENT BRANCH": 0,
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 0,
      "DESCRIPTION": "",
      "PRODUCT CATEGORY": 0,
      "ITEM MOVEMENT BRANCH": 0,
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 0,
      "DESCRIPTION": "",
      "PRODUCT CATEGORY": 0,
      "ITEM MOVEMENT BRANCH": 0,
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 0,
      "DESCRIPTION": "",
      "PRODUCT CATEGORY": 0,
      "ITEM MOVEMENT BRANCH": 0,
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 0,
      "DESCRIPTION": "",
      "PRODUCT CATEGORY": 0,
      "ITEM MOVEMENT BRANCH": 0,
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 0,
      "DESCRIPTION": "",
      "PRODUCT CATEGORY": 0,
      "ITEM MOVEMENT BRANCH": 0,
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 0,
      "DESCRIPTION": "",
      "PRODUCT CATEGORY": 0,
      "ITEM MOVEMENT BRANCH": 0,
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 0,
      "DESCRIPTION": "",
      "PRODUCT CATEGORY": 0,
      "ITEM MOVEMENT BRANCH": 0,
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 0,
      "DESCRIPTION": "",
      "PRODUCT CATEGORY": 0,
      "ITEM MOVEMENT BRANCH": 0,
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 0,
      "DESCRIPTION": "",
      "PRODUCT CATEGORY": 0,
      "ITEM MOVEMENT BRANCH": 0,
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 0,
      "DESCRIPTION": "",
      "PRODUCT CATEGORY": 0,
      "ITEM MOVEMENT BRANCH": 0,
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 0,
      "DESCRIPTION": "",
      "PRODUCT CATEGORY": 0,
      "ITEM MOVEMENT BRANCH": 0,
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 0,
      "DESCRIPTION": "",
      "PRODUCT CATEGORY": 0,
      "ITEM MOVEMENT BRANCH": 0,
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 0,
      "DESCRIPTION": "",
      "PRODUCT CATEGORY": 0,
      "ITEM MOVEMENT BRANCH": 0,
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 0,
      "DESCRIPTION": "",
      "PRODUCT CATEGORY": 0,
      "ITEM MOVEMENT BRANCH": 0,
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 0,
      "DESCRIPTION": "",
      "PRODUCT CATEGORY": 0,
      "ITEM MOVEMENT BRANCH": 0,
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 0,
      "DESCRIPTION": "",
      "PRODUCT CATEGORY": 0,
      "ITEM MOVEMENT BRANCH": 0,
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 0,
      "DESCRIPTION": "",
      "PRODUCT CATEGORY": 0,
      "ITEM MOVEMENT BRANCH": 0,
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 0,
      "DESCRIPTION": "",
      "PRODUCT CATEGORY": 0,
      "ITEM MOVEMENT BRANCH": 0,
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 0,
      "DESCRIPTION": "",
      "PRODUCT CATEGORY": 0,
      "ITEM MOVEMENT BRANCH": 0,
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 0,
      "DESCRIPTION": "",
      "PRODUCT CATEGORY": 0,
      "ITEM MOVEMENT BRANCH": 0,
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 0,
      "DESCRIPTION": "",
      "PRODUCT CATEGORY": 0,
      "ITEM MOVEMENT BRANCH": 0,
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 0,
      "DESCRIPTION": "",
      "PRODUCT CATEGORY": 0,
      "ITEM MOVEMENT BRANCH": 0,
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 0,
      "DESCRIPTION": "",
      "PRODUCT CATEGORY": 0,
      "ITEM MOVEMENT BRANCH": 0,
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 0,
      "DESCRIPTION": "",
      "PRODUCT CATEGORY": 0,
      "ITEM MOVEMENT BRANCH": 0,
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 0,
      "DESCRIPTION": "",
      "PRODUCT CATEGORY": 0,
      "ITEM MOVEMENT BRANCH": 0,
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 0,
      "DESCRIPTION": "",
      "PRODUCT CATEGORY": 0,
      "ITEM MOVEMENT BRANCH": 0,
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 0,
      "DESCRIPTION": "",
      "PRODUCT CATEGORY": 0,
      "ITEM MOVEMENT BRANCH": 0,
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 0,
      "DESCRIPTION": "",
      "PRODUCT CATEGORY": 0,
      "ITEM MOVEMENT BRANCH": 0,
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 0,
      "DESCRIPTION": "",
      "PRODUCT CATEGORY": 0,
      "ITEM MOVEMENT BRANCH": 0,
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 0,
      "DESCRIPTION": "",
      "PRODUCT CATEGORY": 0,
      "ITEM MOVEMENT BRANCH": 0,
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 0,
      "DESCRIPTION": "",
      "PRODUCT CATEGORY": 0,
      "ITEM MOVEMENT BRANCH": 0,
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 0,
      "DESCRIPTION": "",
      "PRODUCT CATEGORY": 0,
      "ITEM MOVEMENT BRANCH": 0,
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 0,
      "DESCRIPTION": "",
      "PRODUCT CATEGORY": 0,
      "ITEM MOVEMENT BRANCH": 0,
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 0,
      "DESCRIPTION": "",
      "PRODUCT CATEGORY": 0,
      "ITEM MOVEMENT BRANCH": 0,
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 0,
      "DESCRIPTION": "",
      "PRODUCT CATEGORY": 0,
      "ITEM MOVEMENT BRANCH": 0,
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 0,
      "DESCRIPTION": "",
      "PRODUCT CATEGORY": 0,
      "ITEM MOVEMENT BRANCH": 0,
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 0,
      "DESCRIPTION": "",
      "PRODUCT CATEGORY": 0,
      "ITEM MOVEMENT BRANCH": 0,
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 0,
      "DESCRIPTION": "",
      "PRODUCT CATEGORY": 0,
      "ITEM MOVEMENT BRANCH": 0,
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 0,
      "DESCRIPTION": "",
      "PRODUCT CATEGORY": 0,
      "ITEM MOVEMENT BRANCH": 0,
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 0,
      "DESCRIPTION": "",
      "PRODUCT CATEGORY": 0,
      "ITEM MOVEMENT BRANCH": 0,
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 0,
      "DESCRIPTION": "",
      "PRODUCT CATEGORY": 0,
      "ITEM MOVEMENT BRANCH": 0,
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 0,
      "DESCRIPTION": "",
      "PRODUCT CATEGORY": 0,
      "ITEM MOVEMENT BRANCH": 0,
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 0,
      "DESCRIPTION": "",
      "PRODUCT CATEGORY": 0,
      "ITEM MOVEMENT BRANCH": 0,
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    },
    {
      "PART NUMBER": 0,
      "DESCRIPTION": "",
      "PRODUCT CATEGORY": 0,
      "ITEM MOVEMENT BRANCH": 0,
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WH REFERENCE": "",
      "TREND": 0
    }
  ];


  export const replenishData = [
    {
      "PART NUMBER": "A229900004769",
      "DESCRIPTION": "BRAKE LINING, FRONT",
      "PRODUCT CATEGORY": "YPG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 60,
      "WHXX": 24,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 60060763,
      "DESCRIPTION": "LINING BRAKE REAR",
      "PRODUCT CATEGORY": "YPG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 62,
      "WHXX": 28,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 16001025,
      "DESCRIPTION": "CONTROL VALVE",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "B222100000392",
      "DESCRIPTION": "FILTER ELEMENT, OIL SUCTION",
      "PRODUCT CATEGORY": "YPG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 10,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4021000042,
      "DESCRIPTION": "BEARING GB9163-GE60ES-2RS",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 4,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 29250004011,
      "DESCRIPTION": "FLANGE",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 29250004021,
      "DESCRIPTION": "FLANGE",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 5,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14636301,
      "DESCRIPTION": "I-ECU EC210B SN35001- 35001- 70001- 80001-  14390065",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 1,
      "SHELF LIFE": 6.000000000000024,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 7,
      "WHXX": 7,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.375533808099405,
      "MEAN": 0.153846153846153,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": 11700373,
      "DESCRIPTION": "NUT",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4120000063,
      "DESCRIPTION": "TRANS PUMP  LG956L",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 5,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 984728,
      "DESCRIPTION": "13948356 FLANGE SCREW",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 58,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 15048183,
      "DESCRIPTION": "OIL LEVEL SENSOR 11144494",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 21486081,
      "DESCRIPTION": "SEALING RING 20412568",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 15,
      "WHXX": 11,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 990973,
      "DESCRIPTION": "HEX NUT 979022",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 2,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 227,
      "WHXX": 4,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "A222100000582",
      "DESCRIPTION": "ELEMENT, FILTER, MAIN",
      "PRODUCT CATEGORY": "YPG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.25,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 2,
      "STD": 0.832050294337843,
      "MEAN": 0.23076923076923,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 4
    },
    {
      "PART NUMBER": "A222100000574",
      "DESCRIPTION": "AIR FILTER, SAFETY  CF1430MANN",
      "PRODUCT CATEGORY": "YPG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.25,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 1,
      "SHELF LIFE": 4,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 5,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.832050294337843,
      "MEAN": 0.23076923076923,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 4
    },
    {
      "PART NUMBER": "B222100000212",
      "DESCRIPTION": "FILTER CORE  B222100000257  HF6870",
      "PRODUCT CATEGORY": "YPG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.666666666666666,
      "MAINTANING STOCK QTY": 3,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 3,
      "APRD QTY": 3,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 0.5,
      "STD": 1.70970082853022,
      "MEAN": 0.615384615384615,
      "COV": 2.7782638463616,
      "SHELF LIFE_1": 4.500000000000004
    },
    {
      "PART NUMBER": 95208971,
      "DESCRIPTION": "FITTING",
      "PRODUCT CATEGORY": "VCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 17,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4110000885,
      "DESCRIPTION": "BELT, COMPRESSOR (A17-490) LG956",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 1,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 13,
      "WHXX": 1,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 20460945,
      "DESCRIPTION": "TURBO CHARGER EC140B BL71PLUS",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 2,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14508324,
      "DESCRIPTION": "LEVER",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 3,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 21581522,
      "DESCRIPTION": "GASKET KIT",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "C01220",
      "DESCRIPTION": "ROD, MOIL POINT SB40",
      "PRODUCT CATEGORY": "SOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 4,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 20459986,
      "DESCRIPTION": "SCREW",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 3030900232,
      "DESCRIPTION": "SHIM",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.916666666666666,
      "MAINTANING STOCK QTY": 4,
      "AVAILABLE": 3,
      "SHELF LIFE": 3.272727272727275,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 57,
      "WHXX": 1,
      "WH REFERENCE": "WHXX",
      "TREND": 0.91,
      "STD": 1.40511884705848,
      "MEAN": 0.846153846153846,
      "COV": 1.66059500106912,
      "SHELF LIFE_1": 4.363636363636367
    },
    {
      "PART NUMBER": 2030900061,
      "DESCRIPTION": "SEALING RING 3030900113 3030900163",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 1,
      "MAINTANING STOCK QTY": 4,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 4,
      "APRD QTY": 0,
      "WH12": 19,
      "WHXX": 3,
      "WH REFERENCE": "WH12 + WHXX",
      "TREND": 0.83,
      "STD": 2.28988858759323,
      "MEAN": 0.923076923076923,
      "COV": 2.48071263655933,
      "SHELF LIFE_1": 4
    },
    {
      "PART NUMBER": 3030900110,
      "DESCRIPTION": "SEAL",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 1,
      "SHELF LIFE": 6.000000000000024,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 44,
      "WHXX": 4,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": 3030900111,
      "DESCRIPTION": "SEAL",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 1,
      "SHELF LIFE": 6.000000000000024,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 28,
      "WHXX": 17,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": 3030900140,
      "DESCRIPTION": "REVERSE FIRST SPEED DRIVEN DISK",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 1.5,
      "MAINTANING STOCK QTY": 6,
      "AVAILABLE": 6,
      "SHELF LIFE": 4,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 14,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 1.11,
      "STD": 3.40437124734415,
      "MEAN": 1.38461538461538,
      "COV": 2.45871256752633,
      "SHELF LIFE_1": 4
    },
    {
      "PART NUMBER": 2030900028,
      "DESCRIPTION": "REVERSE FIRST SPEED DRIVE DISK",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 1.5,
      "MAINTANING STOCK QTY": 6,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 6,
      "APRD QTY": 6,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 1.11,
      "STD": 3.40437124734415,
      "MEAN": 1.38461538461538,
      "COV": 2.45871256752633,
      "SHELF LIFE_1": 4
    },
    {
      "PART NUMBER": 29050011321,
      "DESCRIPTION": "GASKET",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 26,
      "WHXX": 4,
      "WH REFERENCE": "WHXX",
      "TREND": 1,
      "STD": 0.375533808099405,
      "MEAN": 0.153846153846153,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": 4021000015,
      "DESCRIPTION": "BALL BEARING",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 15,
      "WHXX": 5,
      "WH REFERENCE": "WHXX",
      "TREND": 1,
      "STD": 0.375533808099405,
      "MEAN": 0.153846153846153,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": 2030900020,
      "DESCRIPTION": "DRIVE DISK ASS‚ÄÔY FOR THE SECOND SPEED",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 6,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 1,
      "STD": 0.75106761619881,
      "MEAN": 0.307692307692307,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 3.000000000000003
    },
    {
      "PART NUMBER": 3030900106,
      "DESCRIPTION": "DRIVEN DISK FOR THE SECOND SPEED",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 8,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 1,
      "STD": 0.375533808099405,
      "MEAN": 0.153846153846153,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": 4021000016,
      "DESCRIPTION": "BALL BEARING ",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 15,
      "WHXX": 1,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4021000024,
      "DESCRIPTION": "BALL BEARING ",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 3,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 0.5,
      "STD": 0.85485041426511,
      "MEAN": 0.307692307692307,
      "COV": 2.7782638463616,
      "SHELF LIFE_1": 3.000000000000003
    },
    {
      "PART NUMBER": 4015000026,
      "DESCRIPTION": "WASHER",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 91,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 930761,
      "DESCRIPTION": "EXPANDING NUT",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 12,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 2,
      "STD": 1.10940039245045,
      "MEAN": 0.307692307692307,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 3.000000000000003
    },
    {
      "PART NUMBER": 4110001015031,
      "DESCRIPTION": "TURBOCHARGER ",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.416666666666666,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 2,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 0,
      "STD": 0.869718492622903,
      "MEAN": 0.384615384615384,
      "COV": 2.26126808081954,
      "SHELF LIFE_1": 4.800000000000008
    },
    {
      "PART NUMBER": 4110001015025,
      "DESCRIPTION": "BELT, V RIBBED ALTERNATOR",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.25,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 70,
      "WHXX": 1,
      "WH REFERENCE": "WHXX",
      "TREND": 0.67,
      "STD": 0.438529009653514,
      "MEAN": 0.23076923076923,
      "COV": 1.90029237516522,
      "SHELF LIFE_1": 4
    },
    {
      "PART NUMBER": 20550001,
      "DESCRIPTION": "INJECTION PUMP SD110",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 60022614,
      "DESCRIPTION": "COVER, OIL TANK",
      "PRODUCT CATEGORY": "YPG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 101,
      "WHXX": 10,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 85102464,
      "DESCRIPTION": "ENGINE OIL VDS-3 SAE 15W-40 VTC 15067197",
      "PRODUCT CATEGORY": "LUV",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 17.3333333333333,
      "MAINTANING STOCK QTY": 69,
      "AVAILABLE": 432,
      "SHELF LIFE": 24.92307692307697,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 6216,
      "WHXX": 1312,
      "WH REFERENCE": "",
      "TREND": 1.54,
      "STD": 37.841775856849,
      "MEAN": 16,
      "COV": 2.36511099105306,
      "SHELF LIFE_1": 24.92307692307697
    },
    {
      "PART NUMBER": "99990-1Z03J",
      "DESCRIPTION": "GEAR OIL HD GL-4 SAE 80W-90 VTC/UD",
      "PRODUCT CATEGORY": "LUV",
      "ITEM MOVEMENT BRANCH": "AVERAGE (F2)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 117.666666666666,
      "MAINTANING STOCK QTY": 471,
      "AVAILABLE": 360,
      "SHELF LIFE": 3.059490084985853,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 111,
      "APRD QTY": 120,
      "WH12": 2220,
      "WHXX": 40,
      "WH REFERENCE": "WH12 + WHXX",
      "TREND": 0.48,
      "STD": 226.755940187366,
      "MEAN": 108.615384615384,
      "COV": 2.0876963331698,
      "SHELF LIFE_1": 4.002832861189824
    },
    {
      "PART NUMBER": 21244697,
      "DESCRIPTION": "RELAY",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 8,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 984756,
      "DESCRIPTION": "FLANGE SCREW 965186 13965186",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.916666666666666,
      "MAINTANING STOCK QTY": 4,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 4,
      "APRD QTY": 4,
      "WH12": 26,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 2,
      "STD": 3.05085107923876,
      "MEAN": 0.846153846153846,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 4.363636363636367
    },
    {
      "PART NUMBER": "99990-1Z05J",
      "DESCRIPTION": "GEAR OIL HD GL-5 SAE 85W-140 VTC/UD",
      "PRODUCT CATEGORY": "LUV",
      "ITEM MOVEMENT BRANCH": "AVERAGE (F2)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 220.333333333333,
      "MAINTANING STOCK QTY": 881,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 881,
      "APRD QTY": 880,
      "WH12": 9852,
      "WHXX": 140,
      "WH REFERENCE": "WH12 + WHXX",
      "TREND": 0.99,
      "STD": 347.23995988882,
      "MEAN": 203.384615384615,
      "COV": 1.70730691322037,
      "SHELF LIFE_1": 3.9984871406959215
    },
    {
      "PART NUMBER": 984758,
      "DESCRIPTION": "FLANGE SCREW 965188",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 7,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 94,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4130000912,
      "DESCRIPTION": "BATTERY RELAY ",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 13,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 43844471,
      "DESCRIPTION": "VALVE, STEERING SD110",
      "PRODUCT CATEGORY": "VCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 60030172,
      "DESCRIPTION": "STRAIGHT WAY BLOWER ASSEMBLY",
      "PRODUCT CATEGORY": "YPG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 9,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4120002264401,
      "DESCRIPTION": "SEALING KIT 3713CH ",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 4,
      "WHXX": 1,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4120002263401,
      "DESCRIPTION": "SEALING KIT 3713CH",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 9,
      "WHXX": 3,
      "WH REFERENCE": "WHXX",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": "C61252",
      "DESCRIPTION": "ROD PIN SB121",
      "PRODUCT CATEGORY": "SOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.25,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 2,
      "SHELF LIFE": 8,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 20,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.599144689515278,
      "MEAN": 0.23076923076923,
      "COV": 2.5962936545662,
      "SHELF LIFE_1": 8
    },
    {
      "PART NUMBER": "C61169",
      "DESCRIPTION": "STOP PIN D17.5-L250 SBN121",
      "PRODUCT CATEGORY": "SOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "C61241",
      "DESCRIPTION": "RING BUSH-SB121",
      "PRODUCT CATEGORY": "SOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "D81150",
      "DESCRIPTION": "RUBBER PLUG SB121",
      "PRODUCT CATEGORY": "SOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.5,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 2,
      "SHELF LIFE": 4,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 6,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0.67,
      "STD": 0.877058019307029,
      "MEAN": 0.461538461538461,
      "COV": 1.90029237516522,
      "SHELF LIFE_1": 4
    },
    {
      "PART NUMBER": 11109241,
      "DESCRIPTION": "CLAMP 14881506",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 15,
      "WHXX": 12,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4043000015,
      "DESCRIPTION": "PIN LGB301-85*130R*245-40CR",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 2,
      "WH REFERENCE": "WHXX",
      "TREND": 0,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": 4043000043,
      "DESCRIPTION": "SPACER ",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 16,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 17234545,
      "DESCRIPTION": "RUBBER COVER",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": 17237872,
      "DESCRIPTION": "NEEDLE",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 3,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 17237890,
      "DESCRIPTION": "RUBBER COVER",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 6,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 984742,
      "DESCRIPTION": "FLANGE SCREW 965182",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 6,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 6,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4043000031,
      "DESCRIPTION": "BUSH ",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.416666666666666,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 2,
      "APRD QTY": 0,
      "WH12": 7,
      "WHXX": 3,
      "WH REFERENCE": "WHXX",
      "TREND": 0.8,
      "STD": 0.767947647788304,
      "MEAN": 0.384615384615384,
      "COV": 1.99666388424959,
      "SHELF LIFE_1": 4.800000000000008
    },
    {
      "PART NUMBER": 4120002264408,
      "DESCRIPTION": "BUSHING",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 990677,
      "DESCRIPTION": "O-RING",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 5,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 14,
      "WHXX": 11,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4130000875,
      "DESCRIPTION": "STARTING SWITCH,   LG936L / LG956L",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 4,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4110001015055,
      "DESCRIPTION": "INJECTOR ",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 0,
      "WHXX": 1,
      "WH REFERENCE": "WHXX",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": 11712176,
      "DESCRIPTION": "SEALING RING",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.5,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 2,
      "APRD QTY": 2,
      "WH12": 11,
      "WHXX": 12,
      "WH REFERENCE": "WHXX",
      "TREND": 1.33,
      "STD": 0.877058019307029,
      "MEAN": 0.461538461538461,
      "COV": 1.90029237516522,
      "SHELF LIFE_1": 4
    },
    {
      "PART NUMBER": 4013000203,
      "DESCRIPTION": "NUT, WHEEL HUB ",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 3.33333333333333,
      "MAINTANING STOCK QTY": 13,
      "AVAILABLE": 1,
      "SHELF LIFE": 0.3000000000000003,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 12,
      "APRD QTY": 12,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 1.5,
      "STD": 4.80384461415261,
      "MEAN": 3.07692307692307,
      "COV": 1.56124949959959,
      "SHELF LIFE_1": 3.900000000000004
    },
    {
      "PART NUMBER": 4110000697002,
      "DESCRIPTION": "AIR FILTER, OUTER LG918 ",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "AVERAGE (F2)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 1.08333333333333,
      "MAINTANING STOCK QTY": 4,
      "AVAILABLE": 2,
      "SHELF LIFE": 1.846153846153852,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 2,
      "APRD QTY": 2,
      "WH12": 24,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 1.23,
      "STD": 1.15470053837925,
      "MEAN": 1,
      "COV": 1.15470053837925,
      "SHELF LIFE_1": 3.692307692307704
    },
    {
      "PART NUMBER": 4110000697003,
      "DESCRIPTION": "AIR FILTER, INNER LG918",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 1,
      "SHELF LIFE": 12.000000000000005,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 27,
      "WHXX": 2,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 12.000000000000005
    },
    {
      "PART NUMBER": 14532290,
      "DESCRIPTION": "O-RING 1081-00890",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 0,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": 14552079,
      "DESCRIPTION": "SEAL",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 0,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": "L01118",
      "DESCRIPTION": "SSL01118 ROD, (D135) MOILPOINT SU+85",
      "PRODUCT CATEGORY": "SOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 0,
      "STD": 0.375533808099405,
      "MEAN": 0.153846153846153,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": 14578415,
      "DESCRIPTION": "HOSE",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 2,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4130001229,
      "DESCRIPTION": "TRANS PRESSURE SENSOR",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4030000789,
      "DESCRIPTION": "OIL SEAL",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.833333333333333,
      "MAINTANING STOCK QTY": 3,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 3,
      "APRD QTY": 3,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 1.6,
      "STD": 2.24179415327122,
      "MEAN": 0.769230769230769,
      "COV": 2.91433239925258,
      "SHELF LIFE_1": 3.6000000000000014
    },
    {
      "PART NUMBER": 21720407,
      "DESCRIPTION": "CONTROL UNIT 21067820",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 6,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 21789668,
      "DESCRIPTION": "GEAR SHIFT CABLE FM9/FM340 21002852",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 84035947,
      "DESCRIPTION": "WIPER BLADE 20826591",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 2,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 98,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4110000037001,
      "DESCRIPTION": "STARTER MOTOR LGG8180",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "A810102070176",
      "DESCRIPTION": "RADIATOR",
      "PRODUCT CATEGORY": "YPG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 21503575,
      "DESCRIPTION": "SEALING RING 1549651",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 24,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14530605,
      "DESCRIPTION": "HEADLIGHTS",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 21793275,
      "DESCRIPTION": "GUIDE WASHER",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 4,
      "WHXX": 5,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 59162149,
      "DESCRIPTION": "LATCH",
      "PRODUCT CATEGORY": "VCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 60013610,
      "DESCRIPTION": "FILTER CORE",
      "PRODUCT CATEGORY": "YPG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 21028667,
      "DESCRIPTION": "ELEMENT, FILTER SCC500E",
      "PRODUCT CATEGORY": "YCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 7,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 1635242,
      "DESCRIPTION": "SPRING LEAF FM9/FM10",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 984735,
      "DESCRIPTION": "FLANGE SCREW 945444",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 49,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4110001020,
      "DESCRIPTION": "RADIATOR- LY-LG956-2B",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 12787620,
      "DESCRIPTION": "EMERGENCY SWITCH 43910579",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 4,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 21063612,
      "DESCRIPTION": "EVAPORATOR",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 1,
      "SHELF LIFE": 6.000000000000024,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": 60013292,
      "DESCRIPTION": "FUEL FILTER",
      "PRODUCT CATEGORY": "YCG",
      "ITEM MOVEMENT BRANCH": "AVERAGE (F2)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 1,
      "MAINTANING STOCK QTY": 4,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 4,
      "APRD QTY": 4,
      "WH12": 16,
      "WHXX": 4,
      "WH REFERENCE": "WHXX",
      "TREND": 0.33,
      "STD": 1.89127551586834,
      "MEAN": 0.923076923076923,
      "COV": 2.04888180885737,
      "SHELF LIFE_1": 4
    },
    {
      "PART NUMBER": "B222100000235",
      "DESCRIPTION": "HYDRAULIC SUCTION FILTER ",
      "PRODUCT CATEGORY": "YCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 2,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "A222100000119",
      "DESCRIPTION": "PILOT FILTER ELEMENT",
      "PRODUCT CATEGORY": "YCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 3,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 22,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4043000338,
      "DESCRIPTION": "PIN LGB301-110*212R*405/2-40CR",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4089460,
      "DESCRIPTION": "PISTON RING SET",
      "PRODUCT CATEGORY": "CUG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 29250000051,
      "DESCRIPTION": "BUSH, FRONT FRAME LG956L/LG958",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "B222100000451",
      "DESCRIPTION": "FILTER CORE, HYD SY5260THB QY50CY",
      "PRODUCT CATEGORY": "YPG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "4130000419A",
      "DESCRIPTION": "RECEIVER LG958",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 8,
      "WHXX": 4,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4130001685,
      "DESCRIPTION": "FORWARD LIGHTNING QZD-1 4130000797 LG936",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 9,
      "SHELF LIFE": 27.00000000000003,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 26,
      "WHXX": 3,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.75106761619881,
      "MEAN": 0.307692307692307,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 27.00000000000003
    },
    {
      "PART NUMBER": 4110000565088,
      "DESCRIPTION": "OIL FILTER 4110000997322",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 177,
      "WHXX": 3,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 7200002385,
      "DESCRIPTION": "FUEL FILTER LG936",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "FAST (F1)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 6.75,
      "MAINTANING STOCK QTY": 27,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 27,
      "APRD QTY": 27,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 1.19,
      "STD": 5.00256344543885,
      "MEAN": 6.23076923076923,
      "COV": 0.802880552971667,
      "SHELF LIFE_1": 4
    },
    {
      "PART NUMBER": 15078846,
      "DESCRIPTION": "GASKET",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4120001805,
      "DESCRIPTION": "STEERING ",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4120001715,
      "DESCRIPTION": "GEAR PUMP ",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4110000991027,
      "DESCRIPTION": "AIR FILTER SET",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.5,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 5,
      "SHELF LIFE": 10,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 28,
      "WHXX": 8,
      "WH REFERENCE": "",
      "TREND": 0.33,
      "STD": 0.776250025806184,
      "MEAN": 0.461538461538461,
      "COV": 1.6818750559134,
      "SHELF LIFE_1": 10
    },
    {
      "PART NUMBER": 4110000054305,
      "DESCRIPTION": "OIL FILTER",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "AVERAGE (F2)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 1.58333333333333,
      "MAINTANING STOCK QTY": 6,
      "AVAILABLE": 8,
      "SHELF LIFE": 5.05263157894738,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 82,
      "WHXX": 17,
      "WH REFERENCE": "",
      "TREND": 1.37,
      "STD": 1.50639661750508,
      "MEAN": 1.46153846153846,
      "COV": 1.03069242250348,
      "SHELF LIFE_1": 5.05263157894738
    },
    {
      "PART NUMBER": 21928687,
      "DESCRIPTION": "DIPSTICK 20482782",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 5,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 20716956,
      "DESCRIPTION": "MIRROR",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.25,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 7,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 1.33,
      "STD": 0.599144689515278,
      "MEAN": 0.23076923076923,
      "COV": 2.5962936545662,
      "SHELF LIFE_1": 4
    },
    {
      "PART NUMBER": 14530651,
      "DESCRIPTION": "CATCH",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14639653,
      "DESCRIPTION": "CAP_FUEL_AIR_VENT EC210B/EC460B 14528919 14626786",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 4,
      "WHXX": 5,
      "WH REFERENCE": "WHXX",
      "TREND": 2,
      "STD": 0.480384461415261,
      "MEAN": 0.307692307692307,
      "COV": 1.56124949959959,
      "SHELF LIFE_1": 3.000000000000003
    },
    {
      "PART NUMBER": 17202573,
      "DESCRIPTION": "PRESSURE SENSOR",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14595186,
      "DESCRIPTION": "HOSE",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 3,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 20183372,
      "DESCRIPTION": "SWITCH",
      "PRODUCT CATEGORY": "VCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3,
      "WHXX": 2,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14529766,
      "DESCRIPTION": "RING",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 5,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 13305198,
      "DESCRIPTION": "HOUR RECORDER",
      "PRODUCT CATEGORY": "VCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 1,
      "SHELF LIFE": 12.000000000000005,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 12.000000000000005
    },
    {
      "PART NUMBER": 14558022,
      "DESCRIPTION": "O-RING",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 4,
      "WHXX": 4,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14558048,
      "DESCRIPTION": "THRUST PLATE",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 2,
      "WHXX": 5,
      "WH REFERENCE": "WHXX",
      "TREND": 2,
      "STD": 0.375533808099405,
      "MEAN": 0.153846153846153,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": 6213127,
      "DESCRIPTION": "O-RING-14558009",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.25,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 12,
      "WHXX": 20,
      "WH REFERENCE": "WHXX",
      "TREND": 2,
      "STD": 0.832050294337843,
      "MEAN": 0.23076923076923,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 4
    },
    {
      "PART NUMBER": 11991458,
      "DESCRIPTION": "O-RING 14558007",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 5,
      "WHXX": 4,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14272462,
      "DESCRIPTION": "LOCK WASHER",
      "PRODUCT CATEGORY": "VCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 1.33333333333333,
      "MAINTANING STOCK QTY": 5,
      "AVAILABLE": 10,
      "SHELF LIFE": 7.5000000000000195,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 51,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 4.43760156980183,
      "MEAN": 1.23076923076923,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 7.5000000000000195
    },
    {
      "PART NUMBER": 70893482,
      "DESCRIPTION": "SPRING GAS 12798215",
      "PRODUCT CATEGORY": "VCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 14,
      "WHXX": 8,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 5292738,
      "DESCRIPTION": "THERMOSTAT",
      "PRODUCT CATEGORY": "CUG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 983311,
      "DESCRIPTION": "HEXAGON NUT",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.5,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 2,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 0,
      "STD": 1.66410058867568,
      "MEAN": 0.461538461538461,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 4
    },
    {
      "PART NUMBER": 4110000189006,
      "DESCRIPTION": "FUEL/WATER  FILTER ASSY  LG936L DEUTZ ENG",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 1.25,
      "MAINTANING STOCK QTY": 5,
      "AVAILABLE": 6,
      "SHELF LIFE": 4.8,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 126,
      "WHXX": 18,
      "WH REFERENCE": "",
      "TREND": 1.07,
      "STD": 1.46322439872551,
      "MEAN": 1.15384615384615,
      "COV": 1.26812781222877,
      "SHELF LIFE_1": 4.8
    },
    {
      "PART NUMBER": 4110000189005,
      "DESCRIPTION": "V-BELT, FAN / ALT.  ",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 6,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 39,
      "WHXX": 4,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4110000501,
      "DESCRIPTION": "BELT, A/C",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.916666666666666,
      "MAINTANING STOCK QTY": 4,
      "AVAILABLE": 3,
      "SHELF LIFE": 3.272727272727275,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 2,
      "WHXX": 1,
      "WH REFERENCE": "WHXX",
      "TREND": 1.45,
      "STD": 1.46322439872551,
      "MEAN": 0.846153846153846,
      "COV": 1.72926519849378,
      "SHELF LIFE_1": 4.363636363636367
    },
    {
      "PART NUMBER": "A222100000575",
      "DESCRIPTION": "OIL FILTER  SCC500 ",
      "PRODUCT CATEGORY": "YCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 1,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "A222100000645",
      "DESCRIPTION": "FUEL FILTER",
      "PRODUCT CATEGORY": "YCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 11418522,
      "DESCRIPTION": "PROPORTIONAL VALVE",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 1,
      "SHELF LIFE": 12.000000000000005,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 12.000000000000005
    },
    {
      "PART NUMBER": "7273-10930",
      "DESCRIPTION": "SCREW_ORIFICE",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 4,
      "WH REFERENCE": "WHXX",
      "TREND": 1,
      "STD": 0.375533808099405,
      "MEAN": 0.153846153846153,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": "8320-02810",
      "DESCRIPTION": "WASHER",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 6,
      "WHXX": 13,
      "WH REFERENCE": "WHXX",
      "TREND": 2,
      "STD": 0.75106761619881,
      "MEAN": 0.307692307692307,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 3.000000000000003
    },
    {
      "PART NUMBER": 7346454,
      "DESCRIPTION": "O-RING",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 7,
      "WHXX": 10,
      "WH REFERENCE": "WHXX",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": 3104044,
      "DESCRIPTION": "SEALING RING",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 8,
      "WHXX": 2,
      "WH REFERENCE": "WHXX",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": 14558040,
      "DESCRIPTION": "DISC",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 1.66666666666666,
      "MAINTANING STOCK QTY": 7,
      "AVAILABLE": 7,
      "SHELF LIFE": 4.200000000000016,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 10,
      "WHXX": 55,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 5.54700196225229,
      "MEAN": 1.53846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 4.200000000000016
    },
    {
      "PART NUMBER": 14558041,
      "DESCRIPTION": "DISC",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.916666666666666,
      "MAINTANING STOCK QTY": 4,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 4,
      "APRD QTY": 4,
      "WH12": 8,
      "WHXX": 26,
      "WH REFERENCE": "WHXX",
      "TREND": 2,
      "STD": 3.05085107923876,
      "MEAN": 0.846153846153846,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 4.363636363636367
    },
    {
      "PART NUMBER": 14558042,
      "DESCRIPTION": "DISC",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.583333333333333,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 2,
      "APRD QTY": 2,
      "WH12": 16,
      "WHXX": 14,
      "WH REFERENCE": "WHXX",
      "TREND": 2,
      "STD": 1.9414506867883,
      "MEAN": 0.538461538461538,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 3.42857142857143
    },
    {
      "PART NUMBER": 14558046,
      "DESCRIPTION": "SHIM",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 2,
      "WHXX": 2,
      "WH REFERENCE": "WHXX",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": 14558047,
      "DESCRIPTION": "SPRING",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 3,
      "WHXX": 5,
      "WH REFERENCE": "WHXX",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": 14558049,
      "DESCRIPTION": "CAGE",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 2,
      "WHXX": 6,
      "WH REFERENCE": "WHXX",
      "TREND": 2,
      "STD": 0.375533808099405,
      "MEAN": 0.153846153846153,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": 11705188,
      "DESCRIPTION": "WASHER",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 11705193,
      "DESCRIPTION": "FRICTION DISC",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 1,
      "MAINTANING STOCK QTY": 4,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 4,
      "APRD QTY": 4,
      "WH12": 0,
      "WHXX": 13,
      "WH REFERENCE": "WHXX",
      "TREND": 2,
      "STD": 3.32820117735137,
      "MEAN": 0.923076923076923,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 4
    },
    {
      "PART NUMBER": 14558030,
      "DESCRIPTION": "O-RING",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.25,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 8,
      "WHXX": 9,
      "WH REFERENCE": "WHXX",
      "TREND": 2,
      "STD": 0.832050294337843,
      "MEAN": 0.23076923076923,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 4
    },
    {
      "PART NUMBER": 14558031,
      "DESCRIPTION": "GASKET",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 6,
      "WHXX": 3,
      "WH REFERENCE": "WHXX",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": 14558034,
      "DESCRIPTION": "GASKET",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 7,
      "WHXX": 5,
      "WH REFERENCE": "WHXX",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": 14558035,
      "DESCRIPTION": "GASKET",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 8,
      "WHXX": 4,
      "WH REFERENCE": "WHXX",
      "TREND": 2,
      "STD": 0.375533808099405,
      "MEAN": 0.153846153846153,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": 14558036,
      "DESCRIPTION": "GASKET",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 10,
      "WHXX": 10,
      "WH REFERENCE": "WHXX",
      "TREND": 2,
      "STD": 0.375533808099405,
      "MEAN": 0.153846153846153,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": 14558037,
      "DESCRIPTION": "GASKET",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 10,
      "WHXX": 5,
      "WH REFERENCE": "WHXX",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": 14558059,
      "DESCRIPTION": "BALL BEARING",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 3,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 11706773,
      "DESCRIPTION": "THRUST BEARING",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3,
      "WHXX": 5,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 11715510,
      "DESCRIPTION": "ROTOR KIT",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3,
      "WHXX": 3,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 11706719,
      "DESCRIPTION": "O-RING 8220-02680",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3,
      "WHXX": 6,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14577801,
      "DESCRIPTION": "SEALING KIT, BOOM CYL.   EW145B/PRIME",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 13,
      "SHELF LIFE": 78.00000000000031,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 8,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 78.00000000000031
    },
    {
      "PART NUMBER": 14577802,
      "DESCRIPTION": "SEALING KIT, ARM CYL. EW145B/PRIME",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.416666666666666,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 4,
      "SHELF LIFE": 9.600000000000016,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 9,
      "WHXX": 7,
      "WH REFERENCE": "",
      "TREND": 0.8,
      "STD": 0.506369683541833,
      "MEAN": 0.384615384615384,
      "COV": 1.31656117720876,
      "SHELF LIFE_1": 9.600000000000016
    },
    {
      "PART NUMBER": 14589125,
      "DESCRIPTION": "SEALING KIT, BUCKET CYL.  EW145B",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.25,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 1,
      "SHELF LIFE": 4,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 18,
      "WHXX": 8,
      "WH REFERENCE": "",
      "TREND": 0.67,
      "STD": 0.438529009653514,
      "MEAN": 0.23076923076923,
      "COV": 1.90029237516522,
      "SHELF LIFE_1": 4
    },
    {
      "PART NUMBER": 21955032,
      "DESCRIPTION": "V-RIBBED BELT, FAN FM370 20983634",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 4,
      "WHXX": 1,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 17216318,
      "DESCRIPTION": "PRESS SENSOR G930/L150F 11419573  11038813 ",
      "PRODUCT CATEGORY": "VGG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 2,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "A229900008854",
      "DESCRIPTION": "ELECTRONIC THROTTLE PEDAL",
      "PRODUCT CATEGORY": "YCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 13933868,
      "DESCRIPTION": "ELBOW NIPPLE",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 5,
      "WHXX": 5,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14543195,
      "DESCRIPTION": "PIN 1172-06870  14512689 ",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 1635244,
      "DESCRIPTION": "SPRING LEAF",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 1635245,
      "DESCRIPTION": "SPRING LEAF NOTLPA",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 946810,
      "DESCRIPTION": "LOCK NUT",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 47,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 2,
      "STD": 1.10940039245045,
      "MEAN": 0.307692307692307,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 3.000000000000003
    },
    {
      "PART NUMBER": 14630975,
      "DESCRIPTION": "WIPER ARM",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 1,
      "SHELF LIFE": 3.000000000000003,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 7,
      "WHXX": 5,
      "WH REFERENCE": "",
      "TREND": 0.5,
      "STD": 0.480384461415261,
      "MEAN": 0.307692307692307,
      "COV": 1.56124949959959,
      "SHELF LIFE_1": 3.000000000000003
    },
    {
      "PART NUMBER": 977005,
      "DESCRIPTION": "O-RING",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.583333333333333,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 2,
      "SHELF LIFE": 3.42857142857143,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 33,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 1.33012434352235,
      "MEAN": 0.538461538461538,
      "COV": 2.47023092368437,
      "SHELF LIFE_1": 3.42857142857143
    },
    {
      "PART NUMBER": 14632032,
      "DESCRIPTION": "WIPER BLADE",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.583333333333333,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 5,
      "SHELF LIFE": 8.571428571428577,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 5,
      "WH REFERENCE": "",
      "TREND": 0.57,
      "STD": 0.877058019307029,
      "MEAN": 0.538461538461538,
      "COV": 1.62882203585591,
      "SHELF LIFE_1": 8.571428571428577
    },
    {
      "PART NUMBER": 15082401,
      "DESCRIPTION": "WINDOW",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 992248,
      "DESCRIPTION": "ELBOW NIPPLE",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4190002150,
      "DESCRIPTION": "AIR SPRING",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 11705229,
      "DESCRIPTION": "NEEDLE BEARING",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "23Y-58-11000",
      "DESCRIPTION": "A/C RESERVOIR (SD22/SD23/SD32)",
      "PRODUCT CATEGORY": "SDG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 17201798,
      "DESCRIPTION": "SWITCH",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 1,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 29050011381,
      "DESCRIPTION": "SEALING SPACER",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 22,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 1,
      "STD": 0.375533808099405,
      "MEAN": 0.153846153846153,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": 4030000011,
      "DESCRIPTION": "O-RING GB1235-20*2.4",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.25,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 1,
      "SHELF LIFE": 4,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 1,
      "WH REFERENCE": "",
      "TREND": 1.33,
      "STD": 0.599144689515278,
      "MEAN": 0.23076923076923,
      "COV": 2.5962936545662,
      "SHELF LIFE_1": 4
    },
    {
      "PART NUMBER": 4021000012,
      "DESCRIPTION": "BEARING ",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 40,
      "WHXX": 6,
      "WH REFERENCE": "WHXX",
      "TREND": 1,
      "STD": 0.375533808099405,
      "MEAN": 0.153846153846153,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": 4021000019,
      "DESCRIPTION": "BEARING ",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.5,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 2,
      "APRD QTY": 0,
      "WH12": 52,
      "WHXX": 9,
      "WH REFERENCE": "WHXX",
      "TREND": 1,
      "STD": 1.12660142429821,
      "MEAN": 0.461538461538461,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 4
    },
    {
      "PART NUMBER": 4021000020,
      "DESCRIPTION": "BEARING ",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 11,
      "WHXX": 6,
      "WH REFERENCE": "WHXX",
      "TREND": 1,
      "STD": 0.75106761619881,
      "MEAN": 0.307692307692307,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 3.000000000000003
    },
    {
      "PART NUMBER": 4021000040,
      "DESCRIPTION": "THRUST BALL BEARING GB301-51111",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 20,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 1,
      "STD": 0.375533808099405,
      "MEAN": 0.153846153846153,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": 4021000013,
      "DESCRIPTION": "BALL BEARING GB276-6016",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 4,
      "WH REFERENCE": "WHXX",
      "TREND": 1,
      "STD": 0.375533808099405,
      "MEAN": 0.153846153846153,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": 4021000011,
      "DESCRIPTION": "BALL BEARING",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 10,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 1,
      "STD": 0.375533808099405,
      "MEAN": 0.153846153846153,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": 3030900168,
      "DESCRIPTION": "ISOLATED BUSHING",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 16,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 3030900146,
      "DESCRIPTION": "SEALING RING",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 23,
      "WHXX": 1,
      "WH REFERENCE": "WHXX",
      "TREND": 1,
      "STD": 0.375533808099405,
      "MEAN": 0.153846153846153,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": 4021000076,
      "DESCRIPTION": "BEARING ",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 4,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4030000012,
      "DESCRIPTION": "O-RING GB1235-22*2.4",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 11,
      "WHXX": 3,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4030000731,
      "DESCRIPTION": "O-RING GB1235-30*2.4",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 4,
      "WHXX": 3,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 16847926,
      "DESCRIPTION": "WASHER 15182663",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.666666666666666,
      "MAINTANING STOCK QTY": 3,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 3,
      "APRD QTY": 3,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "PARTIAL WH12",
      "TREND": 2,
      "STD": 2.21880078490091,
      "MEAN": 0.615384615384615,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 4.500000000000004
    },
    {
      "PART NUMBER": 996519,
      "DESCRIPTION": "BALL BEARING 993853",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 5,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14616326,
      "DESCRIPTION": "PIN, BUCKET EC210B 1172-06910, 14512676 14543130 ",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 5,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 15107882,
      "DESCRIPTION": "WHEEL STUD",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 10,
      "MAINTANING STOCK QTY": 40,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 40,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 0,
      "STD": 33.2820117735137,
      "MEAN": 9.23076923076923,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 4
    },
    {
      "PART NUMBER": 4130000860,
      "DESCRIPTION": "PRESSURE SWITCH YK210B",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 12,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 21900852,
      "DESCRIPTION": "FUEL FILTER HOUSING, FWS",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 996518,
      "DESCRIPTION": "BALL BEARING EC210B 993852",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 2,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 1,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 21615194,
      "DESCRIPTION": "DISC",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 11,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 29250004041,
      "DESCRIPTION": "UP ARTICULATION PIN",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14635576,
      "DESCRIPTION": "PIN, MASTER EC460B/EC480D",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.416666666666666,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 2,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 3,
      "WH REFERENCE": "WHXX",
      "TREND": 0.4,
      "STD": 0.869718492622904,
      "MEAN": 0.384615384615384,
      "COV": 2.26126808081955,
      "SHELF LIFE_1": 4.800000000000008
    },
    {
      "PART NUMBER": 14378640,
      "DESCRIPTION": "DIRECTION INDICATOR",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 11,
      "WHXX": 6,
      "WH REFERENCE": "WHXX",
      "TREND": 1,
      "STD": 0.630425171956115,
      "MEAN": 0.307692307692307,
      "COV": 2.04888180885737,
      "SHELF LIFE_1": 3.000000000000003
    },
    {
      "PART NUMBER": 14570116,
      "DESCRIPTION": "PIN, REG EC460B/EC480D",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.5,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 2,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 5,
      "WH REFERENCE": "WHXX",
      "TREND": 0.67,
      "STD": 0.967417922046845,
      "MEAN": 0.461538461538461,
      "COV": 2.09607216443483,
      "SHELF LIFE_1": 4
    },
    {
      "PART NUMBER": 14570590,
      "DESCRIPTION": "BUSHING, REG. EC460B/EC480D",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.5,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 2,
      "APRD QTY": 0,
      "WH12": 3,
      "WHXX": 4,
      "WH REFERENCE": "WHXX",
      "TREND": 0.67,
      "STD": 0.967417922046845,
      "MEAN": 0.461538461538461,
      "COV": 2.09607216443483,
      "SHELF LIFE_1": 4
    },
    {
      "PART NUMBER": 14570591,
      "DESCRIPTION": "BUSHING, MASTER EC460B/EC480D",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.416666666666666,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 2,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 3,
      "WH REFERENCE": "WHXX",
      "TREND": 0.4,
      "STD": 0.869718492622904,
      "MEAN": 0.384615384615384,
      "COV": 2.26126808081955,
      "SHELF LIFE_1": 4.800000000000008
    },
    {
      "PART NUMBER": 14570114,
      "DESCRIPTION": "LINK EC460B/EC480D",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.5,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 2,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 7,
      "WH REFERENCE": "WHXX",
      "TREND": 0.67,
      "STD": 0.967417922046845,
      "MEAN": 0.461538461538461,
      "COV": 2.09607216443483,
      "SHELF LIFE_1": 4
    },
    {
      "PART NUMBER": 14570115,
      "DESCRIPTION": "LINK EC460B/EC480D",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.5,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 2,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 7,
      "WH REFERENCE": "WHXX",
      "TREND": 0.67,
      "STD": 0.967417922046845,
      "MEAN": 0.461538461538461,
      "COV": 2.09607216443483,
      "SHELF LIFE_1": 4
    },
    {
      "PART NUMBER": 4130000326,
      "DESCRIPTION": "POSITION SWITCH  ",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 12,
      "WHXX": 1,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4130000861,
      "DESCRIPTION": "PRESSURE TRANSDUCER",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 18,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14671766,
      "DESCRIPTION": "RECOIL SPRING 14532269 ",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "402269Z00A",
      "DESCRIPTION": "WHEEL NUT",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 2.5,
      "MAINTANING STOCK QTY": 10,
      "AVAILABLE": 23,
      "SHELF LIFE": 9.2,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 5,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 8.32050294337843,
      "MEAN": 2.3076923076923,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 9.2
    },
    {
      "PART NUMBER": "252309Z00B",
      "DESCRIPTION": "RELAY",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 20,
      "WHXX": 8,
      "WH REFERENCE": "WHXX",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": "451799Z00A",
      "DESCRIPTION": "AIR DRIER FILTER KIT 21620181 20972915 21508133",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "AVERAGE (F2)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 3.41666666666666,
      "MAINTANING STOCK QTY": 14,
      "AVAILABLE": 3,
      "SHELF LIFE": 0.8780487804878067,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 11,
      "APRD QTY": 11,
      "WH12": 14,
      "WHXX": 13,
      "WH REFERENCE": "WHXX",
      "TREND": 0.83,
      "STD": 3.76045550977878,
      "MEAN": 3.15384615384615,
      "COV": 1.19233955188107,
      "SHELF LIFE_1": 4.097560975609764
    },
    {
      "PART NUMBER": "252309Z00C",
      "DESCRIPTION": "RELAY",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 7,
      "WHXX": 6,
      "WH REFERENCE": "WHXX",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": "252309Z00A",
      "DESCRIPTION": "RELAY",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 1,
      "SHELF LIFE": 6.000000000000024,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 458,
      "WHXX": 8,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": "252309Z00D",
      "DESCRIPTION": "RELAY",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 42,
      "WHXX": 3,
      "WH REFERENCE": "WHXX",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": "149739Z00A",
      "DESCRIPTION": "TEMPERATURE SENSOR",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 7,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "963019Z01C",
      "DESCRIPTION": "5224039760 MIRROR KIT",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 2,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "265559Z00B",
      "DESCRIPTION": "5223502052 TAIL LAMP CLUSTER; LEFT",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "265559Z00A",
      "DESCRIPTION": "TAIL LAMP CLUSTER; RIGHT",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 1,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "302009Z01B",
      "DESCRIPTION": "CLUTCH CWE370, CGE420, CWE420",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "301009Z00A",
      "DESCRIPTION": "5223994807 CLUTCH",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "304029Z00A",
      "DESCRIPTION": "RELEASE BEARING",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 3,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 15,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "487169Z00A",
      "DESCRIPTION": "STEERING FILTER",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "AVERAGE (F2)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 3.5,
      "MAINTANING STOCK QTY": 14,
      "AVAILABLE": 13,
      "SHELF LIFE": 3.7142857142857144,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 38,
      "WHXX": 137,
      "WH REFERENCE": "WHXX",
      "TREND": 1.33,
      "STD": 4.76364437088954,
      "MEAN": 3.23076923076923,
      "COV": 1.47446135289438,
      "SHELF LIFE_1": 4
    },
    {
      "PART NUMBER": "255659Z00A",
      "DESCRIPTION": "SPEED SENSOR",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 4,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 16819626,
      "DESCRIPTION": "ACTUATOR",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "A229900003077",
      "DESCRIPTION": "BATTERY BOX COVER ASSEMBLY",
      "PRODUCT CATEGORY": "YCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 21743197,
      "DESCRIPTION": "AIR CLEANER 21064484  147719Z00A",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.666666666666666,
      "MAINTANING STOCK QTY": 3,
      "AVAILABLE": 7,
      "SHELF LIFE": 10.50000000000001,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 1,
      "STD": 1.50213523239762,
      "MEAN": 0.615384615384615,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 10.50000000000001
    },
    {
      "PART NUMBER": 29170042151,
      "DESCRIPTION": "BOLT ON EDGE ( KIT )",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 1,
      "SHELF LIFE": 3.000000000000003,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0.5,
      "STD": 0.630425171956115,
      "MEAN": 0.307692307692307,
      "COV": 2.04888180885737,
      "SHELF LIFE_1": 3.000000000000003
    },
    {
      "PART NUMBER": 4030000065,
      "DESCRIPTION": "LUBRICATING NIPPLE 29270008001 ",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.666666666666666,
      "MAINTANING STOCK QTY": 3,
      "AVAILABLE": 3,
      "SHELF LIFE": 4.500000000000004,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 5,
      "WHXX": 4,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 2.21880078490091,
      "MEAN": 0.615384615384615,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 4.500000000000004
    },
    {
      "PART NUMBER": 4120000506,
      "DESCRIPTION": "SPRING BRAKE CYLINDER  LG936  LG956L",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 9,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 60154188,
      "DESCRIPTION": "RIGHT CONNECTOR (CAFF)",
      "PRODUCT CATEGORY": "YPG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 60154192,
      "DESCRIPTION": "LEFT CONNECTOR (CAFF)",
      "PRODUCT CATEGORY": "YPG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 21611489,
      "DESCRIPTION": "GASKET L60C/L70C/L90E/BL71/EC210B 20459202",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 1.16666666666666,
      "MAINTANING STOCK QTY": 5,
      "AVAILABLE": 7,
      "SHELF LIFE": 6.000000000000034,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 54,
      "WHXX": 16,
      "WH REFERENCE": "",
      "TREND": 0.86,
      "STD": 1.32045058354704,
      "MEAN": 1.07692307692307,
      "COV": 1.22613268472226,
      "SHELF LIFE_1": 6.000000000000034
    },
    {
      "PART NUMBER": 21789667,
      "DESCRIPTION": "GEAR SHIFT CABLE FM9/FM340  21343551",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 4,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 20450728,
      "DESCRIPTION": "BOLT",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4110000371001,
      "DESCRIPTION": "ALTERNATOR ",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14616736,
      "DESCRIPTION": "CONTROL UNIT",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 21695313,
      "DESCRIPTION": "E-ECU A35D/EC460C/EC700B 20814604",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 2,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 16631876,
      "DESCRIPTION": "AIR FILTER, PRIMARY 14519261",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.25,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 1,
      "SHELF LIFE": 4,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 26,
      "WHXX": 14,
      "WH REFERENCE": "",
      "TREND": 0.67,
      "STD": 0.599144689515278,
      "MEAN": 0.23076923076923,
      "COV": 2.5962936545662,
      "SHELF LIFE_1": 4
    },
    {
      "PART NUMBER": 21003566,
      "DESCRIPTION": "INTERMEDIATE RELAY 4RD 007 903-02",
      "PRODUCT CATEGORY": "YCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "A210111000090",
      "DESCRIPTION": "BOLT-M8√Ó25  01010-31225",
      "PRODUCT CATEGORY": "YCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 85,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "A210405000007",
      "DESCRIPTION": "UNTERLEGSCHEIBE D8,4",
      "PRODUCT CATEGORY": "YCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 40,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "A210307000012",
      "DESCRIPTION": "NUT-M8",
      "PRODUCT CATEGORY": "YCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.583333333333333,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 4,
      "SHELF LIFE": 6.85714285714286,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 5,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 1.9414506867883,
      "MEAN": 0.538461538461538,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.85714285714286
    },
    {
      "PART NUMBER": 20798699,
      "DESCRIPTION": "INLET VALVE 21896409 ",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 12,
      "WHXX": 2,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4110000556128,
      "DESCRIPTION": "SEALING WASHER ",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 11,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 990853,
      "DESCRIPTION": "O-RING",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.666666666666666,
      "MAINTANING STOCK QTY": 3,
      "AVAILABLE": 3,
      "SHELF LIFE": 4.500000000000004,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 17,
      "WHXX": 43,
      "WH REFERENCE": "",
      "TREND": 1,
      "STD": 0.960768922830522,
      "MEAN": 0.615384615384615,
      "COV": 1.56124949959959,
      "SHELF LIFE_1": 4.500000000000004
    },
    {
      "PART NUMBER": "8220-02620",
      "DESCRIPTION": "O-RING",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 1,
      "SHELF LIFE": 6.000000000000024,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 7,
      "WHXX": 3,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": "A240700000339",
      "DESCRIPTION": "DC CONTACTOR MZJ-200A/006",
      "PRODUCT CATEGORY": "YCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14616327,
      "DESCRIPTION": "PIN, LINK TO BUCKET  EC210B 14544079",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 1,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "A210401000016",
      "DESCRIPTION": "SPRING WASHER",
      "PRODUCT CATEGORY": "YCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": "A210307000016",
      "DESCRIPTION": "NUT-M6",
      "PRODUCT CATEGORY": "YCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": 11381209,
      "DESCRIPTION": "53032790 ELECTRONIC UNIT FOR SN -280198 11443993",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "L51109",
      "DESCRIPTION": "THROUGH BOLT",
      "PRODUCT CATEGORY": "SOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 8,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 11110702,
      "DESCRIPTION": "FILTER HOUSING, FWS  W/ HEATER 11110708",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.5,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 1,
      "SHELF LIFE": 2,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 3,
      "WHXX": 3,
      "WH REFERENCE": "WHXX",
      "TREND": 1.33,
      "STD": 0.660225291773524,
      "MEAN": 0.461538461538461,
      "COV": 1.43048813217597,
      "SHELF LIFE_1": 4
    },
    {
      "PART NUMBER": 4120000553401,
      "DESCRIPTION": "SEALING KIT, ST CYL.  ",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 12,
      "WHXX": 1,
      "WH REFERENCE": "WHXX",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": 17286432,
      "DESCRIPTION": "PRESSURE SWITCH A40G",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "327059Z00C",
      "DESCRIPTION": "SENSOR",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 6,
      "WHXX": 27,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "307059Z00B",
      "DESCRIPTION": "OVERHAUL KIT, CLUTCH SERVO",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 4,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 6,
      "WHXX": 18,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 994785,
      "DESCRIPTION": "SIX POINT SOCKET SCREW 969422",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 1.16666666666666,
      "MAINTANING STOCK QTY": 5,
      "AVAILABLE": 5,
      "SHELF LIFE": 4.28571428571431,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 104,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 3.32820117735137,
      "MEAN": 1.07692307692307,
      "COV": 3.09047252182627,
      "SHELF LIFE_1": 4.28571428571431
    },
    {
      "PART NUMBER": 954494,
      "DESCRIPTION": "BATTERY CABLE",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 4,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": "211409Z00B",
      "DESCRIPTION": "V-RIBBED BELT  8PK1560",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 25,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "344379Z00B",
      "DESCRIPTION": "BALL JOINT",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 9,
      "WHXX": 7,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "963479Z00A",
      "DESCRIPTION": "COVER",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 18,
      "WHXX": 2,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "963479Z00C",
      "DESCRIPTION": "COVER",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 10,
      "WHXX": 4,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "165289Z00A",
      "DESCRIPTION": "AIR FILTER-SECONDARY",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "AVERAGE (F2)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 3.08333333333333,
      "MAINTANING STOCK QTY": 12,
      "AVAILABLE": 7,
      "SHELF LIFE": 2.270270270270273,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 5,
      "APRD QTY": 5,
      "WH12": 10,
      "WHXX": 37,
      "WH REFERENCE": "WHXX",
      "TREND": 1.14,
      "STD": 3.55542200603889,
      "MEAN": 2.84615384615384,
      "COV": 1.24920232644609,
      "SHELF LIFE_1": 3.891891891891896
    },
    {
      "PART NUMBER": "165289Z00B",
      "DESCRIPTION": "AIR FILTER-PRIMARY",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "AVERAGE (F2)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 4.66666666666666,
      "MAINTANING STOCK QTY": 19,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 19,
      "APRD QTY": 19,
      "WH12": 34,
      "WHXX": 43,
      "WH REFERENCE": "WHXX",
      "TREND": 1.07,
      "STD": 5.37563353451813,
      "MEAN": 4.3076923076923,
      "COV": 1.24791492765599,
      "SHELF LIFE_1": 4.071428571428577
    },
    {
      "PART NUMBER": "301009Z00B",
      "DESCRIPTION": "CLUTCH DISC",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 8,
      "WHXX": 2,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "260109Z00D",
      "DESCRIPTION": "5224056780 HEADLAMP",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "260109Z00C",
      "DESCRIPTION": "5224056781 HEADLAMP",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "288909Z00A",
      "DESCRIPTION": "WIPER BLADE",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 6,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "164019Z00A",
      "DESCRIPTION": "5222899626 FUEL PRESSURE SENSOR",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "211409Z00A",
      "DESCRIPTION": "V-RIBBED BELT",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 4,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 6,
      "WHXX": 2,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "147719Z00A",
      "DESCRIPTION": "FUEL VENTILATION FILTER 21743197",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "AVERAGE (F2)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 3.16666666666666,
      "MAINTANING STOCK QTY": 13,
      "AVAILABLE": 11,
      "SHELF LIFE": 3.4736842105263235,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 2,
      "APRD QTY": 2,
      "WH12": 0,
      "WHXX": 2,
      "WH REFERENCE": "WHXX",
      "TREND": 1.37,
      "STD": 3.83973823894152,
      "MEAN": 2.92307692307692,
      "COV": 1.31359466069052,
      "SHELF LIFE_1": 4.105263157894746
    },
    {
      "PART NUMBER": "203039Z00C",
      "DESCRIPTION": "COMBINATION VALVE",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "231009Z00A",
      "DESCRIPTION": "ALTERNATOR",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "288909Z00B",
      "DESCRIPTION": "WIPER BLADE",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 5,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "306209Z00A",
      "DESCRIPTION": "MASTER CYLINDER",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 7,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "146769Z01C",
      "DESCRIPTION": "SEALING RING, OIL PAN",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "FAST (F1)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 5.16666666666666,
      "MAINTANING STOCK QTY": 21,
      "AVAILABLE": 6,
      "SHELF LIFE": 1.1612903225806468,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 15,
      "APRD QTY": 0,
      "WH12": 143,
      "WHXX": 42,
      "WH REFERENCE": "WHXX",
      "TREND": 0.71,
      "STD": 3.63212165163939,
      "MEAN": 4.76923076923076,
      "COV": 0.761573894698582,
      "SHELF LIFE_1": 4.064516129032263
    },
    {
      "PART NUMBER": "154-70-11143",
      "DESCRIPTION": "154-71-41270V010 PLOW BOLT & NUT- CUTTING EDGE",
      "PRODUCT CATEGORY": "SDG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 12.75,
      "MAINTANING STOCK QTY": 51,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 51,
      "APRD QTY": 0,
      "WH12": 131,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 0.33,
      "STD": 35.2471129932505,
      "MEAN": 11.7692307692307,
      "COV": 2.99485273798861,
      "SHELF LIFE_1": 4
    },
    {
      "PART NUMBER": 4130001551,
      "DESCRIPTION": "WHITE REAR TAILIGHT",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 15010225,
      "DESCRIPTION": "BRAKE PLATE",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4021000018,
      "DESCRIPTION": "BEARING ",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 6,
      "WHXX": 3,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14618826,
      "DESCRIPTION": "FUEL HOSE",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 2,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "333029Z00A",
      "DESCRIPTION": "GASKET",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 8,
      "WHXX": 9,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "333029Z00B",
      "DESCRIPTION": "GASKET",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 24,
      "WHXX": 9,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "333029Z00C",
      "DESCRIPTION": "GASKET",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 23,
      "WHXX": 9,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "726119Z00A",
      "DESCRIPTION": "WEATHERSTRIP",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 7,
      "WHXX": 5,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 9014536073,
      "DESCRIPTION": "ENGINE, REMAN  D6E EAE2 ENG. SN 70001- / 80001-  EC210B PRIME",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "911119Z02A",
      "DESCRIPTION": "FLANGE NUT",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.416666666666666,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 2,
      "APRD QTY": 0,
      "WH12": 93,
      "WHXX": 131,
      "WH REFERENCE": "WHXX",
      "TREND": 0,
      "STD": 1.38675049056307,
      "MEAN": 0.384615384615384,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 4.800000000000008
    },
    {
      "PART NUMBER": "276949Z00A",
      "DESCRIPTION": "5282685304 CAB FILTER",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.5,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 2,
      "SHELF LIFE": 4,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0.33,
      "STD": 0.967417922046845,
      "MEAN": 0.461538461538461,
      "COV": 2.09607216443483,
      "SHELF LIFE_1": 4
    },
    {
      "PART NUMBER": 22089205,
      "DESCRIPTION": "BELT TENSIONER 20554253 22088967 ",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 8,
      "WHXX": 7,
      "WH REFERENCE": "WHXX",
      "TREND": 1,
      "STD": 0.375533808099405,
      "MEAN": 0.153846153846153,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": 59155309,
      "DESCRIPTION": "SOLENOID VALVE",
      "PRODUCT CATEGORY": "VCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 2,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 994448,
      "DESCRIPTION": "FLANGE SCREW 948336",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 32,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 20849976,
      "DESCRIPTION": "VALVE SEAT",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 24,
      "WHXX": 8,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 20849979,
      "DESCRIPTION": "VALVE SEAT",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 10,
      "WHXX": 15,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 20798533,
      "DESCRIPTION": "EXHAUST VALVE",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 12,
      "WHXX": 11,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 15144849,
      "DESCRIPTION": "RETAINER",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 2,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 8,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 11143271,
      "DESCRIPTION": "SEALING RING",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 2,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 9,
      "WHXX": 10,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 15144848,
      "DESCRIPTION": "INTERMEDIATE RING",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 2,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 8,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "C31446",
      "DESCRIPTION": "ROD PIN  SU+85",
      "PRODUCT CATEGORY": "SOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 1,
      "SHELF LIFE": 3.000000000000003,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.75106761619881,
      "MEAN": 0.307692307692307,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 3.000000000000003
    },
    {
      "PART NUMBER": "99990-1Z01J",
      "DESCRIPTION": "UD DIESEL ENGINE OIL MEGA MULTI 3 SAE 15W-40",
      "PRODUCT CATEGORY": "LUV",
      "ITEM MOVEMENT BRANCH": "AVERAGE (F2)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 243,
      "MAINTANING STOCK QTY": 972,
      "AVAILABLE": 332,
      "SHELF LIFE": 1.3662551440329218,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 640,
      "APRD QTY": 640,
      "WH12": 2760,
      "WHXX": 408,
      "WH REFERENCE": "WH12 + WHXX",
      "TREND": 0.61,
      "STD": 407.108377178891,
      "MEAN": 224.307692307692,
      "COV": 1.81495504229272,
      "SHELF LIFE_1": 4
    },
    {
      "PART NUMBER": 4011000186,
      "DESCRIPTION": "BOLT ",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 32,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 22243151,
      "DESCRIPTION": "CABLE HARNESS 22068267",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 1,
      "SHELF LIFE": 6.000000000000024,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 2,
      "WH REFERENCE": "",
      "TREND": 1,
      "STD": 0.375533808099405,
      "MEAN": 0.153846153846153,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": 43977354,
      "DESCRIPTION": "OIL FILTER  SD45D/F  LF3925",
      "PRODUCT CATEGORY": "VCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.25,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 12,
      "WHXX": 3,
      "WH REFERENCE": "WHXX",
      "TREND": 0,
      "STD": 0.832050294337843,
      "MEAN": 0.23076923076923,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 4
    },
    {
      "PART NUMBER": 43977388,
      "DESCRIPTION": "FUEL FILTER, SD45/SD70/DD70D",
      "PRODUCT CATEGORY": "VCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.25,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 20,
      "WHXX": 1,
      "WH REFERENCE": "WHXX",
      "TREND": 0,
      "STD": 0.832050294337843,
      "MEAN": 0.23076923076923,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 4
    },
    {
      "PART NUMBER": 14558043,
      "DESCRIPTION": "DISC",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 3,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14558044,
      "DESCRIPTION": "WASHER",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 3,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "A222100000166",
      "DESCRIPTION": "AIR FILTER KIT  ",
      "PRODUCT CATEGORY": "YPG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.416666666666666,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 5,
      "SHELF LIFE": 12.000000000000018,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 17,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0.8,
      "STD": 0.767947647788304,
      "MEAN": 0.384615384615384,
      "COV": 1.99666388424959,
      "SHELF LIFE_1": 12.000000000000018
    },
    {
      "PART NUMBER": 4110000509232,
      "DESCRIPTION": "FUEL FILTER",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.666666666666666,
      "MAINTANING STOCK QTY": 3,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 3,
      "APRD QTY": 3,
      "WH12": 120,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 1.25,
      "STD": 1.19292787840544,
      "MEAN": 0.615384615384615,
      "COV": 1.93850780240885,
      "SHELF LIFE_1": 4.500000000000004
    },
    {
      "PART NUMBER": 4110001169001,
      "DESCRIPTION": "AIR FILTER ( INNER & OUTER)",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 1.08333333333333,
      "MAINTANING STOCK QTY": 4,
      "AVAILABLE": 1,
      "SHELF LIFE": 0.923076923076926,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 3,
      "APRD QTY": 3,
      "WH12": 38,
      "WHXX": 6,
      "WH REFERENCE": "WHXX",
      "TREND": 0.31,
      "STD": 2.48327740429189,
      "MEAN": 1,
      "COV": 2.48327740429189,
      "SHELF LIFE_1": 3.692307692307704
    },
    {
      "PART NUMBER": 4120004037,
      "DESCRIPTION": "FILTER INSERT, HYD ASSY. ( THIS IS ASSY, USE 4120004037001 FOR PMS)",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 11,
      "WHXX": 3,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4120004036003,
      "DESCRIPTION": "FILTER INSERT",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "AVERAGE (F2)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 1.08333333333333,
      "MAINTANING STOCK QTY": 4,
      "AVAILABLE": 2,
      "SHELF LIFE": 1.846153846153852,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 2,
      "APRD QTY": 2,
      "WH12": 45,
      "WHXX": 4,
      "WH REFERENCE": "WHXX",
      "TREND": 1.54,
      "STD": 1.47196014438797,
      "MEAN": 1,
      "COV": 1.47196014438797,
      "SHELF LIFE_1": 3.692307692307704
    },
    {
      "PART NUMBER": "C11226",
      "DESCRIPTION": "DIAPHARGM",
      "PRODUCT CATEGORY": "SOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 1,
      "SHELF LIFE": 12.000000000000005,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 12.000000000000005
    },
    {
      "PART NUMBER": 4015000024,
      "DESCRIPTION": "RING SNAP ",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 26,
      "WHXX": 7,
      "WH REFERENCE": "WHXX",
      "TREND": 1,
      "STD": 0.75106761619881,
      "MEAN": 0.307692307692307,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 3.000000000000003
    },
    {
      "PART NUMBER": 14510586,
      "DESCRIPTION": "AIR CLEANER ASSY,  EC210BLC",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 990610,
      "DESCRIPTION": "O-RING",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 8,
      "WHXX": 1,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14522575,
      "DESCRIPTION": "CLIP",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 4,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "150-30-13442",
      "DESCRIPTION": "PACKING",
      "PRODUCT CATEGORY": "SDG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.5,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 2,
      "APRD QTY": 2,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 1.33,
      "STD": 1.19828937903055,
      "MEAN": 0.461538461538461,
      "COV": 2.5962936545662,
      "SHELF LIFE_1": 4
    },
    {
      "PART NUMBER": 60101256,
      "DESCRIPTION": "HYDRAULIC RETURN FILTER",
      "PRODUCT CATEGORY": "YCG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 1,
      "SHELF LIFE": 3.000000000000003,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 4,
      "WHXX": 1,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 1.10940039245045,
      "MEAN": 0.307692307692307,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 3.000000000000003
    },
    {
      "PART NUMBER": 14558028,
      "DESCRIPTION": "RING",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14558029,
      "DESCRIPTION": "RING",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "214009Z00E",
      "DESCRIPTION": "RADIATOR 214109Z00C",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 2,
      "WH REFERENCE": "WHXX",
      "TREND": 1,
      "STD": 0.375533808099405,
      "MEAN": 0.153846153846153,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": "218019Z01B",
      "DESCRIPTION": "CHARGE AIR COOLER",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 11705190,
      "DESCRIPTION": "DISC",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 0,
      "WHXX": 3,
      "WH REFERENCE": "WHXX",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": 12760005,
      "DESCRIPTION": "CABLE",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3,
      "WHXX": 2,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14521609,
      "DESCRIPTION": "ELEMENT",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 6,
      "WHXX": 9,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 20739572,
      "DESCRIPTION": "CLAMP 8149651",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 44,
      "WHXX": 7,
      "WH REFERENCE": "WHXX",
      "TREND": 0,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": 4043000027,
      "DESCRIPTION": "BUSH ",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.666666666666666,
      "MAINTANING STOCK QTY": 3,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 3,
      "APRD QTY": 0,
      "WH12": 16,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 0.75,
      "STD": 1.04390784542678,
      "MEAN": 0.615384615384615,
      "COV": 1.69635024881852,
      "SHELF LIFE_1": 4.500000000000004
    },
    {
      "PART NUMBER": 21103569,
      "DESCRIPTION": "RUBBER MOULDING",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 1,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 15118696,
      "DESCRIPTION": "KEYBOARD",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.666666666666666,
      "MAINTANING STOCK QTY": 3,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 3,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 2,
      "WH REFERENCE": "PARTIAL WHXX",
      "TREND": 0.5,
      "STD": 1.12089707663561,
      "MEAN": 0.615384615384615,
      "COV": 1.82145774953286,
      "SHELF LIFE_1": 4.500000000000004
    },
    {
      "PART NUMBER": "146829Z00B",
      "DESCRIPTION": "F-NIPPLE",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.833333333333333,
      "MAINTANING STOCK QTY": 3,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 3,
      "APRD QTY": 0,
      "WH12": 32,
      "WHXX": 85,
      "WH REFERENCE": "WHXX",
      "TREND": 0,
      "STD": 2.77350098112614,
      "MEAN": 0.769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 3.6000000000000014
    },
    {
      "PART NUMBER": "146719Z00C",
      "DESCRIPTION": "SWIVEL",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.833333333333333,
      "MAINTANING STOCK QTY": 3,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 3,
      "APRD QTY": 0,
      "WH12": 93,
      "WHXX": 65,
      "WH REFERENCE": "WHXX",
      "TREND": 0,
      "STD": 2.77350098112614,
      "MEAN": 0.769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 3.6000000000000014
    },
    {
      "PART NUMBER": 43844422,
      "DESCRIPTION": "HYDRAULIC MOTOR  SD100C/D / SD110",
      "PRODUCT CATEGORY": "VCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14589153,
      "DESCRIPTION": "SEALING KIT",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14385098,
      "DESCRIPTION": "VALVE",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "146729Z00D",
      "DESCRIPTION": "NIPPLE",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 10,
      "WHXX": 16,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "32624NA00A",
      "DESCRIPTION": "NIPPLE",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.833333333333333,
      "MAINTANING STOCK QTY": 3,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 3,
      "APRD QTY": 0,
      "WH12": 25,
      "WHXX": 15,
      "WH REFERENCE": "WHXX",
      "TREND": 0,
      "STD": 2.77350098112614,
      "MEAN": 0.769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 3.6000000000000014
    },
    {
      "PART NUMBER": "2002200Z0D",
      "DESCRIPTION": "V-CLAMP",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 8,
      "WHXX": 4,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "2002200Z0E",
      "DESCRIPTION": "EXHAUST PIPE CLAMP",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 18,
      "WHXX": 2,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "282299Z04B",
      "DESCRIPTION": "FLANGE SCREW",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 39,
      "WHXX": 50,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "111129Z00A",
      "DESCRIPTION": "GASKET",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 5,
      "WHXX": 8,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "150109Z00A",
      "DESCRIPTION": "OIL PUMP",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14634767,
      "DESCRIPTION": "SUPPORT 14522195",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 4,
      "WHXX": 1,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "32624NA00B",
      "DESCRIPTION": "SWIVEL",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 15,
      "WHXX": 4,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4120000742,
      "DESCRIPTION": "CHARGE VALVE FOR TIRE",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4856263,
      "DESCRIPTION": "HOSE, AIR FILTER  L150C",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 952967,
      "DESCRIPTION": "HOSE",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "E91120",
      "DESCRIPTION": "FRONT HEAD PIN  SU+85",
      "PRODUCT CATEGORY": "SOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 2,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": "E91121",
      "DESCRIPTION": "STOP PIN  SU+85",
      "PRODUCT CATEGORY": "SOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.5,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 2,
      "SHELF LIFE": 4,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 1.33,
      "STD": 0.877058019307029,
      "MEAN": 0.461538461538461,
      "COV": 1.90029237516522,
      "SHELF LIFE_1": 4
    },
    {
      "PART NUMBER": 2050900054,
      "DESCRIPTION": "BEARING, AXLE  LG936L",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 5,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 15074447,
      "DESCRIPTION": "WINDSCREEN  A35F / A40F",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 20854656,
      "DESCRIPTION": "CYL. LINER KIT  D7DEAE2   EC290B",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 1,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "797409Z00A",
      "DESCRIPTION": "5224079398 WINDSHIELD",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 4,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "611109Z00D",
      "DESCRIPTION": "FRONT PANEL",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "231529Z00A",
      "DESCRIPTION": "FLANGE NUT",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 117,
      "WHXX": 120,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "4120002263J",
      "DESCRIPTION": "4120002263T LIFT CYLINDER  LG956L",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4015000030,
      "DESCRIPTION": "WASHER, LOCK ",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 4.33333333333333,
      "MAINTANING STOCK QTY": 17,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 17,
      "APRD QTY": 17,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 1.15,
      "STD": 5.29150262212918,
      "MEAN": 4,
      "COV": 1.32287565553229,
      "SHELF LIFE_1": 3.9230769230769256
    },
    {
      "PART NUMBER": 13976433,
      "DESCRIPTION": "FLANGE NUT L120F",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 1.58333333333333,
      "MAINTANING STOCK QTY": 6,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 6,
      "APRD QTY": 6,
      "WH12": 26,
      "WHXX": 30,
      "WH REFERENCE": "WHXX",
      "TREND": 2,
      "STD": 5.26965186413967,
      "MEAN": 1.46153846153846,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 3.7894736842105345
    },
    {
      "PART NUMBER": 14508740,
      "DESCRIPTION": "SENSOR LEVEL, EC210B/290B/360B/460B",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14511214,
      "DESCRIPTION": "O-RING KIT (EXCAVATORS)",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 3,
      "SHELF LIFE": 18.000000000000075,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 1,
      "STD": 0.375533808099405,
      "MEAN": 0.153846153846153,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 18.000000000000075
    },
    {
      "PART NUMBER": 14514378,
      "DESCRIPTION": "WIRE HARNESS",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 22,
      "WHXX": 4,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14517747,
      "DESCRIPTION": "ADAPTER",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14517748,
      "DESCRIPTION": "ADAPTER",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3,
      "WHXX": 3,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14517754,
      "DESCRIPTION": "HOSE CLAMP",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 13,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14518023,
      "DESCRIPTION": "PROPELLER SHAFT EW145B",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14523552,
      "DESCRIPTION": "TOOTH (VTS) 30GPE EC210B/EC240B 14523946",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 3.33333333333333,
      "MAINTANING STOCK QTY": 13,
      "AVAILABLE": 107,
      "SHELF LIFE": 32.10000000000003,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 716,
      "WHXX": 59,
      "WH REFERENCE": "",
      "TREND": 1.5,
      "STD": 4.34859246311452,
      "MEAN": 3.07692307692307,
      "COV": 1.41329255051221,
      "SHELF LIFE_1": 32.10000000000003
    },
    {
      "PART NUMBER": 14528387,
      "DESCRIPTION": "OIL FILTER EC55B",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.833333333333333,
      "MAINTANING STOCK QTY": 3,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 3,
      "APRD QTY": 3,
      "WH12": 6,
      "WHXX": 17,
      "WH REFERENCE": "WHXX",
      "TREND": 1.6,
      "STD": 1.23516841994969,
      "MEAN": 0.769230769230769,
      "COV": 1.6057189459346,
      "SHELF LIFE_1": 3.6000000000000014
    },
    {
      "PART NUMBER": 14529152,
      "DESCRIPTION": "STARTER SWITCH",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 1,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 6,
      "WHXX": 3,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14529757,
      "DESCRIPTION": "O-RING",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.5,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 2,
      "APRD QTY": 0,
      "WH12": 14,
      "WHXX": 62,
      "WH REFERENCE": "WHXX",
      "TREND": 0,
      "STD": 1.66410058867568,
      "MEAN": 0.461538461538461,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 4
    },
    {
      "PART NUMBER": 14531072,
      "DESCRIPTION": "FLEXIBLE COUPLING, HYD SYSTEM  EC210B / EC290B",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 1,
      "SHELF LIFE": 6.000000000000024,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 9,
      "WHXX": 9,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": 14533603,
      "DESCRIPTION": "O-RING",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.5,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 2,
      "APRD QTY": 0,
      "WH12": 77,
      "WHXX": 62,
      "WH REFERENCE": "WHXX",
      "TREND": 0,
      "STD": 1.66410058867568,
      "MEAN": 0.461538461538461,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 4
    },
    {
      "PART NUMBER": 14535448,
      "DESCRIPTION": "BUSHING",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14537622,
      "DESCRIPTION": "WASHER, TOOTH EC290B 1070-13030",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 2.16666666666666,
      "MAINTANING STOCK QTY": 9,
      "AVAILABLE": 4,
      "SHELF LIFE": 1.846153846153852,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 5,
      "APRD QTY": 5,
      "WH12": 89,
      "WHXX": 24,
      "WH REFERENCE": "WHXX",
      "TREND": 2,
      "STD": 5.65685424949238,
      "MEAN": 2,
      "COV": 2.82842712474619,
      "SHELF LIFE_1": 4.153846153846167
    },
    {
      "PART NUMBER": 14541720,
      "DESCRIPTION": "SENSOR_LEVEL EC210B",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 4,
      "WHXX": 2,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14543949,
      "DESCRIPTION": "PIN, BOOM EC210B",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 1,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 2,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14545465,
      "DESCRIPTION": "ADJUSTING DEVICE",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 1,
      "SHELF LIFE": 6.000000000000024,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 5,
      "WHXX": 12,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.375533808099405,
      "MEAN": 0.153846153846153,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": 14545883,
      "DESCRIPTION": "COUPLING 1-1/4\"",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 4,
      "WHXX": 1,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14547395,
      "DESCRIPTION": "PISTON BUSHING",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 61,
      "WHXX": 1,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14576774,
      "DESCRIPTION": "BLOWER MOTOR, A/C UNIT  14514331 ",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.416666666666666,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 2,
      "APRD QTY": 2,
      "WH12": 1,
      "WHXX": 2,
      "WH REFERENCE": "WHXX",
      "TREND": 2,
      "STD": 0.65044363558799,
      "MEAN": 0.384615384615384,
      "COV": 1.69115345252877,
      "SHELF LIFE_1": 4.800000000000008
    },
    {
      "PART NUMBER": 14579495,
      "DESCRIPTION": "COOLING FAN, ENG  EC210B (TROPICAL) ",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14616040,
      "DESCRIPTION": "TOGGLE SWITCH 14526156",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 4,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14880450,
      "DESCRIPTION": "LOCK NUT, HEX SE",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 21,
      "WHXX": 7,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 15038313,
      "DESCRIPTION": "SENSOR, ACC. RETARDER 11708270",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 3,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 15047153,
      "DESCRIPTION": "HOUR RECORDER 73152",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 2,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 15082295,
      "DESCRIPTION": "STARTER SWITCH A30C/G710B/G930 11039220",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 15082742,
      "DESCRIPTION": "COMPRESSOR EC290B 11412632",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.25,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 1,
      "SHELF LIFE": 4,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 32,
      "WHXX": 1,
      "WH REFERENCE": "",
      "TREND": 1.33,
      "STD": 0.438529009653514,
      "MEAN": 0.23076923076923,
      "COV": 1.90029237516522,
      "SHELF LIFE_1": 4
    },
    {
      "PART NUMBER": 15103658,
      "DESCRIPTION": "PRESSURE SENSOR 11444373",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3,
      "WHXX": 2,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 15126069,
      "DESCRIPTION": "54315806 FUEL FILTER (X8)",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "AVERAGE (F2)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 2.91666666666666,
      "MAINTANING STOCK QTY": 12,
      "AVAILABLE": 20,
      "SHELF LIFE": 6.857142857142873,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 14,
      "WH REFERENCE": "",
      "TREND": 0.57,
      "STD": 1.79743406854583,
      "MEAN": 2.69230769230769,
      "COV": 0.667618368317024,
      "SHELF LIFE_1": 6.857142857142873
    },
    {
      "PART NUMBER": 184838,
      "DESCRIPTION": "ROLLER BEARING",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": 20405822,
      "DESCRIPTION": "SEALING RING EC210B",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 3,
      "WHXX": 6,
      "WH REFERENCE": "WHXX",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": 20412308,
      "DESCRIPTION": "HEX NUT L120F",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.666666666666666,
      "MAINTANING STOCK QTY": 3,
      "AVAILABLE": 15,
      "SHELF LIFE": 22.500000000000025,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 58,
      "WHXX": 33,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 2.21880078490091,
      "MEAN": 0.615384615384615,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 22.500000000000025
    },
    {
      "PART NUMBER": 20412314,
      "DESCRIPTION": "STUD",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.666666666666666,
      "MAINTANING STOCK QTY": 3,
      "AVAILABLE": 15,
      "SHELF LIFE": 22.500000000000025,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 21,
      "WHXX": 32,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 2.21880078490091,
      "MEAN": 0.615384615384615,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 22.500000000000025
    },
    {
      "PART NUMBER": 20412325,
      "DESCRIPTION": "SEAL RING",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 12,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 20412589,
      "DESCRIPTION": "FILLER CAP ( ENG. D7D EAE2) G710B EC290B",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.416666666666666,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 2,
      "APRD QTY": 2,
      "WH12": 9,
      "WHXX": 16,
      "WH REFERENCE": "WHXX",
      "TREND": 1.6,
      "STD": 0.506369683541833,
      "MEAN": 0.384615384615384,
      "COV": 1.31656117720876,
      "SHELF LIFE_1": 4.800000000000008
    },
    {
      "PART NUMBER": 20450707,
      "DESCRIPTION": "TACHOMETER EC210B/G700B/G930/L60E",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.416666666666666,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 2,
      "SHELF LIFE": 4.800000000000008,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 7,
      "WHXX": 9,
      "WH REFERENCE": "",
      "TREND": 0.8,
      "STD": 0.65044363558799,
      "MEAN": 0.384615384615384,
      "COV": 1.69115345252877,
      "SHELF LIFE_1": 4.800000000000008
    },
    {
      "PART NUMBER": 20450736,
      "DESCRIPTION": "THERMOSTAT BL71/G710B/G930/EC210B",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.583333333333333,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 1,
      "SHELF LIFE": 1.714285714285715,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 18,
      "WHXX": 7,
      "WH REFERENCE": "WHXX",
      "TREND": 1.43,
      "STD": 1.12660142429821,
      "MEAN": 0.538461538461538,
      "COV": 2.0922597879824,
      "SHELF LIFE_1": 3.42857142857143
    },
    {
      "PART NUMBER": 20459203,
      "DESCRIPTION": "GASKET L60C/L70C/L90E/BL71/EC210B",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.416666666666666,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 2,
      "SHELF LIFE": 4.800000000000008,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 14,
      "WHXX": 6,
      "WH REFERENCE": "",
      "TREND": 0.8,
      "STD": 0.506369683541833,
      "MEAN": 0.384615384615384,
      "COV": 1.31656117720876,
      "SHELF LIFE_1": 4.800000000000008
    },
    {
      "PART NUMBER": 20459961,
      "DESCRIPTION": "SEALING RING, WATER PUMP EC210B/G700B/G930/SD110",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.5,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 2,
      "APRD QTY": 2,
      "WH12": 0,
      "WHXX": 43,
      "WH REFERENCE": "WHXX",
      "TREND": 1.67,
      "STD": 1.12660142429821,
      "MEAN": 0.461538461538461,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 4
    },
    {
      "PART NUMBER": 20460022,
      "DESCRIPTION": "GASKET",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 1,
      "SHELF LIFE": 12.000000000000005,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 10,
      "WHXX": 10,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 12.000000000000005
    },
    {
      "PART NUMBER": 20502536,
      "DESCRIPTION": "SEALING RING  EC210B",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 1,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 2,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 20505959,
      "DESCRIPTION": "HEX NUT L120E",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 1,
      "MAINTANING STOCK QTY": 4,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 4,
      "APRD QTY": 4,
      "WH12": 140,
      "WHXX": 25,
      "WH REFERENCE": "WHXX",
      "TREND": 2,
      "STD": 3.32820117735137,
      "MEAN": 0.923076923076923,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 4
    },
    {
      "PART NUMBER": 20531832,
      "DESCRIPTION": "24052228 GASKET",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 7,
      "WHXX": 8,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 20557208,
      "DESCRIPTION": "GASKET EC210B/BL71/L60E/L120E",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.5,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 2,
      "APRD QTY": 2,
      "WH12": 2,
      "WHXX": 6,
      "WH REFERENCE": "WHXX",
      "TREND": 2,
      "STD": 1.66410058867568,
      "MEAN": 0.461538461538461,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 4
    },
    {
      "PART NUMBER": 20758403,
      "DESCRIPTION": "24063494 HEATER L120E/EC210B 20498227",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 20775463,
      "DESCRIPTION": "PULLEY, ALTERNATOR EC210B",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 19,
      "WHXX": 5,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 20798076,
      "DESCRIPTION": "O-RING",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 6,
      "WHXX": 4,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 20798827,
      "DESCRIPTION": "O-RING",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 1.33333333333333,
      "MAINTANING STOCK QTY": 5,
      "AVAILABLE": 15,
      "SHELF LIFE": 11.250000000000028,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 50,
      "WH REFERENCE": "",
      "TREND": 1.25,
      "STD": 2.38585575681089,
      "MEAN": 1.23076923076923,
      "COV": 1.93850780240885,
      "SHELF LIFE_1": 11.250000000000028
    },
    {
      "PART NUMBER": 20805349,
      "DESCRIPTION": "54315408 FUEL FILTER (X10)",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "FAST (F1)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 20.75,
      "MAINTANING STOCK QTY": 83,
      "AVAILABLE": 15,
      "SHELF LIFE": 0.7228915662650602,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 68,
      "APRD QTY": 68,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 0.49,
      "STD": 17.420515462361,
      "MEAN": 19.1538461538461,
      "COV": 0.909504823336116,
      "SHELF LIFE_1": 4
    },
    {
      "PART NUMBER": 20850096,
      "DESCRIPTION": "BIG END BEARING KIT  D6EEAE2/3 ",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.5,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 2,
      "SHELF LIFE": 4,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 7,
      "WHXX": 11,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 1.66410058867568,
      "MEAN": 0.461538461538461,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 4
    },
    {
      "PART NUMBER": 20865990,
      "DESCRIPTION": "CYL. LINER KIT  D7EEAE2  SN: 17001-, 35001-,85001-  EC290B PRIME",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 16,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 20933261,
      "DESCRIPTION": "GASKET L120F",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 4,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 26,
      "WHXX": 10,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 20943385,
      "DESCRIPTION": "CYL. HEAD GASKET 1.4MM 1HOLE L120F EC290B",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 2,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 20949868,
      "DESCRIPTION": "CYLINDER HEAD GASKET 1.5MM 2HOLES EC290B",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 4,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4789826,
      "DESCRIPTION": "O-RING KIT, WHEEL   ARTIC/LOADER",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 13,
      "WHXX": 3,
      "WH REFERENCE": "WHXX",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": 4870186,
      "DESCRIPTION": "SEALING RING",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 2,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 6848760,
      "DESCRIPTION": "O-RING A35D",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.416666666666666,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 2,
      "APRD QTY": 2,
      "WH12": 0,
      "WHXX": 15,
      "WH REFERENCE": "WHXX",
      "TREND": 2,
      "STD": 1.38675049056307,
      "MEAN": 0.384615384615384,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 4.800000000000008
    },
    {
      "PART NUMBER": 79089,
      "DESCRIPTION": "DURAMIDE G930",
      "PRODUCT CATEGORY": "VGG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 5,
      "WHXX": 6,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 914167,
      "DESCRIPTION": "NIPPLE EC210B/EC460B/G930",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 2.16666666666666,
      "MAINTANING STOCK QTY": 9,
      "AVAILABLE": 8,
      "SHELF LIFE": 3.692307692307704,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 708,
      "WHXX": 65,
      "WH REFERENCE": "WHXX",
      "TREND": 0.15,
      "STD": 6.04152298679728,
      "MEAN": 2,
      "COV": 3.02076149339864,
      "SHELF LIFE_1": 4.153846153846167
    },
    {
      "PART NUMBER": 943470,
      "DESCRIPTION": "HOSE CLAMP",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 15,
      "WHXX": 21,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 946471,
      "DESCRIPTION": "FLANGE SCREW",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.5,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 2,
      "APRD QTY": 0,
      "WH12": 33,
      "WHXX": 45,
      "WH REFERENCE": "WHXX",
      "TREND": 0,
      "STD": 1.66410058867568,
      "MEAN": 0.461538461538461,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 4
    },
    {
      "PART NUMBER": 947972,
      "DESCRIPTION": "WHEEL LUG NUT A30/A25C/L150F",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 10,
      "MAINTANING STOCK QTY": 40,
      "AVAILABLE": 3,
      "SHELF LIFE": 0.3,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 37,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 0,
      "STD": 33.2820117735137,
      "MEAN": 9.23076923076923,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 4
    },
    {
      "PART NUMBER": 949874,
      "DESCRIPTION": "FLANGE LOCK NUT",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 11,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": 955921,
      "DESCRIPTION": "WASHER SPRING 9213-08000",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 1,
      "SHELF LIFE": 3.000000000000003,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 29,
      "WHXX": 54,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 1.10940039245045,
      "MEAN": 0.307692307692307,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 3.000000000000003
    },
    {
      "PART NUMBER": 955926,
      "DESCRIPTION": "SPRING WASHER (16MM) 01602-01648",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 1,
      "MAINTANING STOCK QTY": 4,
      "AVAILABLE": 4,
      "SHELF LIFE": 4,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1571,
      "WHXX": 30,
      "WH REFERENCE": "",
      "TREND": 1.33,
      "STD": 2.39657875806111,
      "MEAN": 0.923076923076923,
      "COV": 2.5962936545662,
      "SHELF LIFE_1": 4
    },
    {
      "PART NUMBER": 960254,
      "DESCRIPTION": "O-RING EC210B/240B/290B 9511-22190 ",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.666666666666666,
      "MAINTANING STOCK QTY": 3,
      "AVAILABLE": 3,
      "SHELF LIFE": 4.500000000000004,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 110,
      "WHXX": 13,
      "WH REFERENCE": "",
      "TREND": 1,
      "STD": 0.960768922830522,
      "MEAN": 0.615384615384615,
      "COV": 1.56124949959959,
      "SHELF LIFE_1": 4.500000000000004
    },
    {
      "PART NUMBER": 960265,
      "DESCRIPTION": "O-RING 14573761",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 967711,
      "DESCRIPTION": "992518 BULB 4W 24V G930/L150F",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 969404,
      "DESCRIPTION": "SIX POINT SOCKET SCREW",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 10,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 2,
      "STD": 1.10940039245045,
      "MEAN": 0.307692307692307,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 3.000000000000003
    },
    {
      "PART NUMBER": 977525,
      "DESCRIPTION": "V-BELT, A/C EC210B/L120E/G930",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.416666666666666,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 2,
      "SHELF LIFE": 4.800000000000008,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 5,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.869718492622904,
      "MEAN": 0.384615384615384,
      "COV": 2.26126808081955,
      "SHELF LIFE_1": 4.800000000000008
    },
    {
      "PART NUMBER": 978722,
      "DESCRIPTION": "V-BELT, FAN EC210B/L120E/G930 15078709 ",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 5,
      "SHELF LIFE": 30.00000000000012,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 5,
      "WHXX": 17,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 30.00000000000012
    },
    {
      "PART NUMBER": 978999,
      "DESCRIPTION": "BLADE FUSE 20A 11039328 A30C/EC210B-P/EC460B/C/EC700B/G930/L150F/FM400",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.416666666666666,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 20,
      "SHELF LIFE": 48.00000000000007,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 9,
      "WHXX": 2,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 1.38675049056307,
      "MEAN": 0.384615384615384,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 48.00000000000007
    },
    {
      "PART NUMBER": 980827,
      "DESCRIPTION": "O-RING A30",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3,
      "WHXX": 25,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 983260,
      "DESCRIPTION": "997471 SCREW HEX HD",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 983304,
      "DESCRIPTION": "997408 NUT_HEX",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 983509,
      "DESCRIPTION": "O-RING 14533610 14534048",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 3,
      "SHELF LIFE": 36.000000000000014,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 49,
      "WHXX": 10,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 36.000000000000014
    },
    {
      "PART NUMBER": 990743,
      "DESCRIPTION": "O-RING EC210B 949239 ",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.25,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 67,
      "WHXX": 14,
      "WH REFERENCE": "WHXX",
      "TREND": 2,
      "STD": 0.832050294337843,
      "MEAN": 0.23076923076923,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 4
    },
    {
      "PART NUMBER": 990851,
      "DESCRIPTION": "O-RING",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 34,
      "WHXX": 10,
      "WH REFERENCE": "WHXX",
      "TREND": 1,
      "STD": 0.375533808099405,
      "MEAN": 0.153846153846153,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": 992520,
      "DESCRIPTION": "BULB 10W 24V 13949185 967710",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 1,
      "SHELF LIFE": 6.000000000000024,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 24,
      "WHXX": 4,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": 80681281,
      "DESCRIPTION": "HYDRAULIC FILTER  ABG5820/ABG8820B",
      "PRODUCT CATEGORY": "VPG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.25,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 0,
      "STD": 0.832050294337843,
      "MEAN": 0.23076923076923,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 4
    },
    {
      "PART NUMBER": "1030-61460",
      "DESCRIPTION": "ELEMENT, PILOT HYDRAULIC_",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "FAST (F1)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 5.33333333333333,
      "MAINTANING STOCK QTY": 21,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 21,
      "APRD QTY": 0,
      "WH12": 863,
      "WHXX": 121,
      "WH REFERENCE": "WHXX",
      "TREND": 0.94,
      "STD": 6.62648145023106,
      "MEAN": 4.92307692307692,
      "COV": 1.34600404457818,
      "SHELF LIFE_1": 3.937500000000002
    },
    {
      "PART NUMBER": "1030-61680",
      "DESCRIPTION": "ELEMENT, HYDRAULIC EW145B",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.916666666666666,
      "MAINTANING STOCK QTY": 4,
      "AVAILABLE": 38,
      "SHELF LIFE": 41.45454545454549,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 4,
      "WHXX": 12,
      "WH REFERENCE": "",
      "TREND": 0.73,
      "STD": 0.987096233585649,
      "MEAN": 0.846153846153846,
      "COV": 1.16656827605576,
      "SHELF LIFE_1": 41.45454545454549
    },
    {
      "PART NUMBER": 11110283,
      "DESCRIPTION": "AIR FILTER_PRI EC140B / EW145B",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "FAST (F1)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 5.33333333333333,
      "MAINTANING STOCK QTY": 21,
      "AVAILABLE": 22,
      "SHELF LIFE": 4.125000000000003,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 675,
      "WHXX": 23,
      "WH REFERENCE": "",
      "TREND": 0.56,
      "STD": 7.76167441623195,
      "MEAN": 4.92307692307692,
      "COV": 1.57659011579711,
      "SHELF LIFE_1": 4.125000000000003
    },
    {
      "PART NUMBER": 11110284,
      "DESCRIPTION": "AIR FILTER  SEC",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "AVERAGE (F2)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 3.83333333333333,
      "MAINTANING STOCK QTY": 15,
      "AVAILABLE": 6,
      "SHELF LIFE": 1.5652173913043492,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 9,
      "APRD QTY": 9,
      "WH12": 220,
      "WHXX": 36,
      "WH REFERENCE": "WHXX",
      "TREND": 0.52,
      "STD": 6.2930568170456,
      "MEAN": 3.53846153846153,
      "COV": 1.77847257873027,
      "SHELF LIFE_1": 3.913043478260873
    },
    {
      "PART NUMBER": 11110532,
      "DESCRIPTION": "AIR FILTER, PRIM EC700B 15013668",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 15,
      "SHELF LIFE": 90.00000000000037,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 89,
      "WHXX": 10,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.375533808099405,
      "MEAN": 0.153846153846153,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 90.00000000000037
    },
    {
      "PART NUMBER": 11110533,
      "DESCRIPTION": "AIR FILTER, SECONDARY 15193232 ",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 5,
      "SHELF LIFE": 15.000000000000016,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 32,
      "WHXX": 13,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 1.10940039245045,
      "MEAN": 0.307692307692307,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 15.000000000000016
    },
    {
      "PART NUMBER": 11110670,
      "DESCRIPTION": "FUEL WATER SEPARATOR EC210B/290B/L90E/BL71/G710B",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3,
      "WHXX": 3,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 11110737,
      "DESCRIPTION": "54864253 BOWL, FWS W/O HEATER  EC210B/290B/G930/L120F 11110674",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 1,
      "STD": 0.375533808099405,
      "MEAN": 0.153846153846153,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": "1122-01690",
      "DESCRIPTION": "182040 BULB_ROOM LAMP EC460C 7019926",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 3,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 3,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "1141-00010",
      "DESCRIPTION": "STRAINER, HYD TANK",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "AVERAGE (F2)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 2.08333333333333,
      "MAINTANING STOCK QTY": 8,
      "AVAILABLE": 9,
      "SHELF LIFE": 4.320000000000007,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3,
      "WHXX": 2,
      "WH REFERENCE": "",
      "TREND": 0.72,
      "STD": 2.75262112847429,
      "MEAN": 1.92307692307692,
      "COV": 1.43136298680663,
      "SHELF LIFE_1": 4.320000000000007
    },
    {
      "PART NUMBER": 11417118,
      "DESCRIPTION": "TOOTH 10GPL VTS L70F",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 8,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "1146-06870",
      "DESCRIPTION": "PAD",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 1,
      "SHELF LIFE": 12.000000000000005,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 48,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 12.000000000000005
    },
    {
      "PART NUMBER": 11708550,
      "DESCRIPTION": "OIL FILTER EC140B",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.833333333333333,
      "MAINTANING STOCK QTY": 3,
      "AVAILABLE": 3,
      "SHELF LIFE": 3.6000000000000014,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 34,
      "WHXX": 11,
      "WH REFERENCE": "",
      "TREND": 1.2,
      "STD": 1.16575055606864,
      "MEAN": 0.769230769230769,
      "COV": 1.51547572288924,
      "SHELF LIFE_1": 3.6000000000000014
    },
    {
      "PART NUMBER": 11708551,
      "DESCRIPTION": "OIL FILTER",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.416666666666666,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 3,
      "SHELF LIFE": 7.200000000000011,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 51,
      "WHXX": 38,
      "WH REFERENCE": "",
      "TREND": 0.4,
      "STD": 1.12089707663561,
      "MEAN": 0.384615384615384,
      "COV": 2.91433239925258,
      "SHELF LIFE_1": 7.200000000000011
    },
    {
      "PART NUMBER": 11709635,
      "DESCRIPTION": "V-BELT, FAN DRIVE",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "AVERAGE (F2)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 1.91666666666666,
      "MAINTANING STOCK QTY": 8,
      "AVAILABLE": 5,
      "SHELF LIFE": 2.608695652173922,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 3,
      "APRD QTY": 3,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 1.22,
      "STD": 1.78670302297491,
      "MEAN": 1.76923076923076,
      "COV": 1.00987562168147,
      "SHELF LIFE_1": 4.173913043478275
    },
    {
      "PART NUMBER": "1171-00171",
      "DESCRIPTION": "SIDE BLADE RH EC210B",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 1,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "1171-00181",
      "DESCRIPTION": "SIDE BLADE LH EC210B",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 1,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 11988962,
      "DESCRIPTION": "FUEL FILTER",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 23,
      "WHXX": 19,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 11988975,
      "DESCRIPTION": "BALL BRG-8220-05830",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 0,
      "WHXX": 4,
      "WH REFERENCE": "WHXX",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": 13947622,
      "DESCRIPTION": "PLANE GASKET",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 3,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 77,
      "WHXX": 18,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 13955894,
      "DESCRIPTION": "994868 WASHER",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14386186,
      "DESCRIPTION": "MIRROR",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 5,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14500233,
      "DESCRIPTION": "ELEMENT, BREATHER",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 44,
      "SHELF LIFE": 528.0000000000002,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 49,
      "WHXX": 60,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 528.0000000000002
    },
    {
      "PART NUMBER": 14501846,
      "DESCRIPTION": "SEAL",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 3,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14502207,
      "DESCRIPTION": "RADIATOR CAP",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 3,
      "WH REFERENCE": "WHXX",
      "TREND": 1,
      "STD": 0.375533808099405,
      "MEAN": 0.153846153846153,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": 14503269,
      "DESCRIPTION": "FILTER, CAB",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "FAST (F1)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 5.41666666666666,
      "MAINTANING STOCK QTY": 22,
      "AVAILABLE": 48,
      "SHELF LIFE": 8.861538461538473,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 101,
      "WHXX": 4,
      "WH REFERENCE": "",
      "TREND": 0.74,
      "STD": 6.24499799839839,
      "MEAN": 5,
      "COV": 1.24899959967967,
      "SHELF LIFE_1": 8.861538461538473
    },
    {
      "PART NUMBER": 14504913,
      "DESCRIPTION": "HOSE EC210B",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.25,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 5,
      "WH REFERENCE": "WHXX",
      "TREND": 0.67,
      "STD": 0.438529009653514,
      "MEAN": 0.23076923076923,
      "COV": 1.90029237516522,
      "SHELF LIFE_1": 4
    },
    {
      "PART NUMBER": 14505515,
      "DESCRIPTION": "SENSOR EC210B/290B/360B/460B/700B",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 6,
      "WHXX": 1,
      "WH REFERENCE": "WHXX",
      "TREND": 2,
      "STD": 0.375533808099405,
      "MEAN": 0.153846153846153,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": 14506407,
      "DESCRIPTION": "SEAL KIT",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 8,
      "WHXX": 3,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14506606,
      "DESCRIPTION": "SEAL KIT",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 10,
      "WHXX": 6,
      "WH REFERENCE": "WHXX",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": 14506770,
      "DESCRIPTION": "ROD, CAB DOOR  EC210B",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 5,
      "WHXX": 3,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14506789,
      "DESCRIPTION": "CABLE",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 5,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14506844,
      "DESCRIPTION": "GLASS_FRONT LOWER EC210B/EC460B",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 4,
      "WHXX": 5,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14506889,
      "DESCRIPTION": "SEAL KIT_CONTROL VALVE EC210B",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.75,
      "MAINTANING STOCK QTY": 3,
      "AVAILABLE": 3,
      "SHELF LIFE": 4,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 14,
      "WH REFERENCE": "",
      "TREND": 1.33,
      "STD": 0.947330933431341,
      "MEAN": 0.692307692307692,
      "COV": 1.36836690384527,
      "SHELF LIFE_1": 4
    },
    {
      "PART NUMBER": 14508632,
      "DESCRIPTION": "WIPER BLADE _LOWER EC210B/EC460B/EC700B",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 3,
      "SHELF LIFE": 36.000000000000014,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 4,
      "WHXX": 2,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 36.000000000000014
    },
    {
      "PART NUMBER": 14508854,
      "DESCRIPTION": "LATCH_SIDE DOOR EC210B/EC460B",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 11,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14508911,
      "DESCRIPTION": "RADIAL SEAL",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 20,
      "WHXX": 3,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14509331,
      "DESCRIPTION": "VALVE, EXPANSION A/C",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "AVERAGE (F2)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 1.25,
      "MAINTANING STOCK QTY": 5,
      "AVAILABLE": 6,
      "SHELF LIFE": 4.8,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 22,
      "WHXX": 8,
      "WH REFERENCE": "",
      "TREND": 1.6,
      "STD": 1.81870621808259,
      "MEAN": 1.15384615384615,
      "COV": 1.57621205567158,
      "SHELF LIFE_1": 4.8
    },
    {
      "PART NUMBER": 14509377,
      "DESCRIPTION": "RECEIVER DRIER EC210B/EC460B/EC700B",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3,
      "WHXX": 2,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14509379,
      "DESCRIPTION": "14776946  ELEMENT, HYD RETURN_",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "AVERAGE (F2)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 3.66666666666666,
      "MAINTANING STOCK QTY": 15,
      "AVAILABLE": 33,
      "SHELF LIFE": 9.000000000000016,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 332,
      "WHXX": 43,
      "WH REFERENCE": "",
      "TREND": 0.91,
      "STD": 3.79777262656374,
      "MEAN": 3.38461538461538,
      "COV": 1.1220691851211,
      "SHELF LIFE_1": 9.000000000000016
    },
    {
      "PART NUMBER": 14510496,
      "DESCRIPTION": "CHANNEL",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 4,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14510584,
      "DESCRIPTION": "HOSE_INLET EC210B",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 4,
      "WH REFERENCE": "WHXX",
      "TREND": 1,
      "STD": 0.375533808099405,
      "MEAN": 0.153846153846153,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": 14510585,
      "DESCRIPTION": "HOSE_OUTLET EC210B",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.25,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 4,
      "WH REFERENCE": "WHXX",
      "TREND": 0.67,
      "STD": 0.438529009653514,
      "MEAN": 0.23076923076923,
      "COV": 1.90029237516522,
      "SHELF LIFE_1": 4
    },
    {
      "PART NUMBER": 14511594,
      "DESCRIPTION": "BRG_KIT-100-GUMZ8",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.416666666666666,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 2,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 7,
      "WH REFERENCE": "WHXX",
      "TREND": 0,
      "STD": 1.38675049056307,
      "MEAN": 0.384615384615384,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 4.800000000000008
    },
    {
      "PART NUMBER": 14513033,
      "DESCRIPTION": "GAS SPRING EC210B/EC460B",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 4,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14513798,
      "DESCRIPTION": "PLATE",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 5,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14514007,
      "DESCRIPTION": "BRACKET_REAR EX",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 5,
      "WHXX": 5,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14514329,
      "DESCRIPTION": "RESISTOR_CERAMIC",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 4,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14514330,
      "DESCRIPTION": "SENSOR EC210B/290B/360B/460B",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3,
      "WHXX": 2,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14514937,
      "DESCRIPTION": "SEAL KIT_T/JOINT EC210B 14500512",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 10,
      "WHXX": 6,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14515566,
      "DESCRIPTION": "V-BELT EC210B 3911577",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.25,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 4,
      "WHXX": 9,
      "WH REFERENCE": "WHXX",
      "TREND": 0,
      "STD": 0.832050294337843,
      "MEAN": 0.23076923076923,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 4
    },
    {
      "PART NUMBER": 14515827,
      "DESCRIPTION": "COUPLING EC210B",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 1,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14515828,
      "DESCRIPTION": "ELEMENT COUPLING EC210B",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 13,
      "WHXX": 4,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14518349,
      "DESCRIPTION": "V-ECU EC210B/EC290B/EC700B",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 3,
      "WH REFERENCE": "WHXX",
      "TREND": 0,
      "STD": 0.375533808099405,
      "MEAN": 0.153846153846153,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": 14522412,
      "DESCRIPTION": "WIPER ARM, UPPER EC210B/EC290B/EC460B/EW145B",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 1,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 12,
      "WHXX": 2,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14522909,
      "DESCRIPTION": "CUSHION",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 2,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14522962,
      "DESCRIPTION": "ADAPTER (VTS) EA30BL40 EC210B/240B",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.416666666666666,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 2,
      "SHELF LIFE": 4.800000000000008,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 7,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 1.38675049056307,
      "MEAN": 0.384615384615384,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 4.800000000000008
    },
    {
      "PART NUMBER": 14522963,
      "DESCRIPTION": "ADAPTER (VTS) EA40BL50 EC290B",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.5,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 6,
      "SHELF LIFE": 12,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 6,
      "WHXX": 6,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 1.66410058867568,
      "MEAN": 0.461538461538461,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 12
    },
    {
      "PART NUMBER": 14523946,
      "DESCRIPTION": "TOOTH 30AMRE EC210B/EC240B 14523552",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.5,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 3,
      "SHELF LIFE": 6,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 478,
      "WHXX": 8,
      "WH REFERENCE": "",
      "TREND": 0.67,
      "STD": 1.19828937903055,
      "MEAN": 0.461538461538461,
      "COV": 2.5962936545662,
      "SHELF LIFE_1": 6
    },
    {
      "PART NUMBER": 14524170,
      "DESCRIPTION": "CARTRIDGE, HYDRAULIC_",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "FAST (F1)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 5.25,
      "MAINTANING STOCK QTY": 21,
      "AVAILABLE": 32,
      "SHELF LIFE": 6.095238095238095,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 462,
      "WHXX": 126,
      "WH REFERENCE": "",
      "TREND": 0.92,
      "STD": 5.698627815041,
      "MEAN": 4.84615384615384,
      "COV": 1.17590732691322,
      "SHELF LIFE_1": 6.095238095238095
    },
    {
      "PART NUMBER": 14524449,
      "DESCRIPTION": "GASKET KIT, HYD TANK EC210B",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.916666666666666,
      "MAINTANING STOCK QTY": 4,
      "AVAILABLE": 4,
      "SHELF LIFE": 4.363636363636367,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 12,
      "WHXX": 22,
      "WH REFERENCE": "",
      "TREND": 1.09,
      "STD": 1.81870621808259,
      "MEAN": 0.846153846153846,
      "COV": 2.14938007591579,
      "SHELF LIFE_1": 4.363636363636367
    },
    {
      "PART NUMBER": 14524710,
      "DESCRIPTION": "PIN 65P (VTS) EC360B/EC460B",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 1.91666666666666,
      "MAINTANING STOCK QTY": 8,
      "AVAILABLE": 10,
      "SHELF LIFE": 5.217391304347844,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 47,
      "WHXX": 30,
      "WH REFERENCE": "",
      "TREND": 0.52,
      "STD": 3.37030775434148,
      "MEAN": 1.76923076923076,
      "COV": 1.90495655680171,
      "SHELF LIFE_1": 5.217391304347844
    },
    {
      "PART NUMBER": 14524711,
      "DESCRIPTION": "LOCKING DEVICE 80P ( VTS) EC700B",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.416666666666666,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 10,
      "SHELF LIFE": 24.000000000000036,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 9,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 1.38675049056307,
      "MEAN": 0.384615384615384,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 24.000000000000036
    },
    {
      "PART NUMBER": 14526158,
      "DESCRIPTION": "STARTER SWITCH EC210B/EC460B 1123-02820 ",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.25,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 4,
      "SHELF LIFE": 16,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 2,
      "WH REFERENCE": "",
      "TREND": 0.67,
      "STD": 0.438529009653514,
      "MEAN": 0.23076923076923,
      "COV": 1.90029237516522,
      "SHELF LIFE_1": 16
    },
    {
      "PART NUMBER": 14526509,
      "DESCRIPTION": "ADAPTER (VTS) EA80BL70 EC700B",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.75,
      "MAINTANING STOCK QTY": 3,
      "AVAILABLE": 5,
      "SHELF LIFE": 6.666666666666667,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 1.11,
      "STD": 1.70218562367207,
      "MEAN": 0.692307692307692,
      "COV": 2.45871256752633,
      "SHELF LIFE_1": 6.666666666666667
    },
    {
      "PART NUMBER": 14526510,
      "DESCRIPTION": "TOOTH (VTS) 65GPE EC460B 14624280",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 2,
      "MAINTANING STOCK QTY": 8,
      "AVAILABLE": 10,
      "SHELF LIFE": 5,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 99,
      "WHXX": 7,
      "WH REFERENCE": "",
      "TREND": 0.58,
      "STD": 3.53190585581764,
      "MEAN": 1.84615384615384,
      "COV": 1.91311567190122,
      "SHELF LIFE_1": 5
    },
    {
      "PART NUMBER": 14526776,
      "DESCRIPTION": "ADAPTER (VTS) EA65BL50 EC460B / EC480D",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 26,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14527149,
      "DESCRIPTION": "DISPLAY PANEL 14521370 14527158",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14527267,
      "DESCRIPTION": "REPAIR SET, SOLENOID EC210B/EC460B/EC700B/BL71 14512987  ",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 3,
      "SHELF LIFE": 9.000000000000009,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 1,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.85485041426511,
      "MEAN": 0.307692307692307,
      "COV": 2.7782638463616,
      "SHELF LIFE_1": 9.000000000000009
    },
    {
      "PART NUMBER": 14527864,
      "DESCRIPTION": "PIN, HOR (KTS) EW145B",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 1.66666666666666,
      "MAINTANING STOCK QTY": 7,
      "AVAILABLE": 35,
      "SHELF LIFE": 21.00000000000008,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 202,
      "WHXX": 122,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 3.15212585978057,
      "MEAN": 1.53846153846153,
      "COV": 2.04888180885737,
      "SHELF LIFE_1": 21.00000000000008
    },
    {
      "PART NUMBER": 14528382,
      "DESCRIPTION": "CLAMP",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.25,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 3,
      "SHELF LIFE": 12,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 4,
      "WHXX": 2,
      "WH REFERENCE": "",
      "TREND": 0.67,
      "STD": 0.438529009653514,
      "MEAN": 0.23076923076923,
      "COV": 1.90029237516522,
      "SHELF LIFE_1": 12
    },
    {
      "PART NUMBER": 14529050,
      "DESCRIPTION": "O-RING",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.666666666666666,
      "MAINTANING STOCK QTY": 3,
      "AVAILABLE": 1,
      "SHELF LIFE": 1.5000000000000016,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 2,
      "APRD QTY": 2,
      "WH12": 68,
      "WHXX": 31,
      "WH REFERENCE": "WHXX",
      "TREND": 1.75,
      "STD": 1.19292787840544,
      "MEAN": 0.615384615384615,
      "COV": 1.93850780240885,
      "SHELF LIFE_1": 4.500000000000004
    },
    {
      "PART NUMBER": 14529051,
      "DESCRIPTION": "O-RING, A/C",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.666666666666666,
      "MAINTANING STOCK QTY": 3,
      "AVAILABLE": 3,
      "SHELF LIFE": 4.500000000000004,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 60,
      "WHXX": 12,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 1.50213523239762,
      "MEAN": 0.615384615384615,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 4.500000000000004
    },
    {
      "PART NUMBER": 14529052,
      "DESCRIPTION": "O-RING",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.666666666666666,
      "MAINTANING STOCK QTY": 3,
      "AVAILABLE": 4,
      "SHELF LIFE": 6.000000000000006,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 174,
      "WHXX": 8,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 1.50213523239762,
      "MEAN": 0.615384615384615,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 6.000000000000006
    },
    {
      "PART NUMBER": 14529132,
      "DESCRIPTION": "DIODE W/ HARNESS EC460B",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 5,
      "WHXX": 2,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14529178,
      "DESCRIPTION": "KEY_PLASTIC EC210B-P/EC460B",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 2,
      "SHELF LIFE": 12.000000000000048,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 7,
      "WHXX": 18,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 12.000000000000048
    },
    {
      "PART NUMBER": 14530079,
      "DESCRIPTION": "WIPER MOTOR, UPPER EC210B/EC290B/EC460B / EW145B",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14530362,
      "DESCRIPTION": "LINK LH EC210BLC",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 4,
      "WHXX": 2,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14530363,
      "DESCRIPTION": "LINK RH EC210BLC",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14530364,
      "DESCRIPTION": "PIN, TRACK REG EC210BLC * 1182-00040 14515724 ",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 19,
      "WHXX": 2,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14530365,
      "DESCRIPTION": "BUSHING, REG EC210BLC",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 2,
      "WH REFERENCE": "WHXX",
      "TREND": 1,
      "STD": 0.375533808099405,
      "MEAN": 0.153846153846153,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": 14530367,
      "DESCRIPTION": "14723460 SEAL, TRACK EC210B",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14530385,
      "DESCRIPTION": "SEAL, TRACK EC360B/EC460B",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.666666666666666,
      "MAINTANING STOCK QTY": 3,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 3,
      "APRD QTY": 0,
      "WH12": 13,
      "WHXX": 10,
      "WH REFERENCE": "WHXX",
      "TREND": 1,
      "STD": 1.32529629004621,
      "MEAN": 0.615384615384615,
      "COV": 2.15360647132509,
      "SHELF LIFE_1": 4.500000000000004
    },
    {
      "PART NUMBER": 14530648,
      "DESCRIPTION": "GLASS, CAB DOOR EC210B / EC290B",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14530989,
      "DESCRIPTION": "FILTER, HYDRAULIC TANK, SUCTION",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 8,
      "WHXX": 11,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14531010,
      "DESCRIPTION": "PIN",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 6,
      "WH REFERENCE": "WHXX",
      "TREND": 0,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": 14531109,
      "DESCRIPTION": "SEAL",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 7,
      "WHXX": 10,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14531866,
      "DESCRIPTION": "STRAINER ASSY EC210B/EC240B/EW170",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.416666666666666,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 2,
      "SHELF LIFE": 4.800000000000008,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 47,
      "WHXX": 12,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.767947647788304,
      "MEAN": 0.384615384615384,
      "COV": 1.99666388424959,
      "SHELF LIFE_1": 4.800000000000008
    },
    {
      "PART NUMBER": 14532260,
      "DESCRIPTION": "STRAINER",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 3,
      "SHELF LIFE": 36.000000000000014,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 7,
      "WHXX": 6,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 36.000000000000014
    },
    {
      "PART NUMBER": 14532276,
      "DESCRIPTION": "PLUG EC210BLC 1081-00300",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 2,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 2,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14532390,
      "DESCRIPTION": "RECOIL SPRING EC210BLC",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 8,
      "WHXX": 8,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14532473,
      "DESCRIPTION": "SPACER, MASTER PIN EC460B/EC480D",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.5,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 2,
      "APRD QTY": 0,
      "WH12": 14,
      "WHXX": 5,
      "WH REFERENCE": "WHXX",
      "TREND": 0.67,
      "STD": 0.967417922046845,
      "MEAN": 0.461538461538461,
      "COV": 2.09607216443483,
      "SHELF LIFE_1": 4
    },
    {
      "PART NUMBER": 14532687,
      "DESCRIPTION": "14750657  FILTER, HYD LEAKAGE EW145B 14744967 ",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 10,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14533862,
      "DESCRIPTION": "SPACER, MASTER PIN EC210BLC",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 5,
      "WHXX": 9,
      "WH REFERENCE": "WHXX",
      "TREND": 1,
      "STD": 0.75106761619881,
      "MEAN": 0.307692307692307,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 3.000000000000003
    },
    {
      "PART NUMBER": 14537348,
      "DESCRIPTION": "STRAINER, FUEL TANK  EC210B",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 42,
      "WHXX": 103,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14540724,
      "DESCRIPTION": "ADAPTER A20-30, HOR. (ET)/(KTS) EC210B EC220D",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.916666666666666,
      "MAINTANING STOCK QTY": 4,
      "AVAILABLE": 1,
      "SHELF LIFE": 1.0909090909090917,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 3,
      "APRD QTY": 3,
      "WH12": 0,
      "WHXX": 3,
      "WH REFERENCE": "WHXX",
      "TREND": 1.09,
      "STD": 1.8639632438326,
      "MEAN": 0.846153846153846,
      "COV": 2.20286565180217,
      "SHELF LIFE_1": 4.363636363636367
    },
    {
      "PART NUMBER": 14540725,
      "DESCRIPTION": "PIN A20-30, HOR. (KTS) 1070-61320  EC210B/EW145B/EW170 1070-61320",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "AVERAGE (F2)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 7.33333333333333,
      "MAINTANING STOCK QTY": 29,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 29,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 0.8,
      "STD": 5.68849491157145,
      "MEAN": 6.76923076923076,
      "COV": 0.840345839209419,
      "SHELF LIFE_1": 3.9545454545454564
    },
    {
      "PART NUMBER": 14540726,
      "DESCRIPTION": "PIN, TOOTH_HOR (KTS) EC240B/EC290B 1070-13040",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 1.66666666666666,
      "MAINTANING STOCK QTY": 7,
      "AVAILABLE": 2,
      "SHELF LIFE": 1.2000000000000048,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 5,
      "APRD QTY": 5,
      "WH12": 23,
      "WHXX": 23,
      "WH REFERENCE": "WHXX",
      "TREND": 2,
      "STD": 5.25381424324881,
      "MEAN": 1.53846153846153,
      "COV": 3.41497925811172,
      "SHELF LIFE_1": 4.200000000000016
    },
    {
      "PART NUMBER": 14548911,
      "DESCRIPTION": "FUEL HOSE",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14551131,
      "DESCRIPTION": "GLASS, WINDSHIELD  EC210B 14506807",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 7,
      "WHXX": 4,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14552332,
      "DESCRIPTION": "SEAL KIT_SWING MOTOR EC210B/EC460B",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 26,
      "WHXX": 4,
      "WH REFERENCE": "WHXX",
      "TREND": 0,
      "STD": 0.375533808099405,
      "MEAN": 0.153846153846153,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": 14555298,
      "DESCRIPTION": "SEAL KIT, MAIN PUMP  EC210B",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 1,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 4,
      "WHXX": 3,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14560201,
      "DESCRIPTION": "SEAL",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 11,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 108,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14560206,
      "DESCRIPTION": "SEAL, BOOM  EC210B / EC290B",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 19,
      "WHXX": 21,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14562193,
      "DESCRIPTION": "SWITCH, PRESSURE EC460B 14529294",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.833333333333333,
      "MAINTANING STOCK QTY": 3,
      "AVAILABLE": 2,
      "SHELF LIFE": 2.400000000000001,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 15,
      "WHXX": 26,
      "WH REFERENCE": "WHXX",
      "TREND": 0.6,
      "STD": 1.16575055606864,
      "MEAN": 0.769230769230769,
      "COV": 1.51547572288924,
      "SHELF LIFE_1": 3.6000000000000014
    },
    {
      "PART NUMBER": 14589129,
      "DESCRIPTION": "SEAL KIT, BOOM CYL.  EC210B 14515051 ",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "AVERAGE (F2)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 2.66666666666666,
      "MAINTANING STOCK QTY": 11,
      "AVAILABLE": 11,
      "SHELF LIFE": 4.125000000000011,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 35,
      "WHXX": 18,
      "WH REFERENCE": "",
      "TREND": 1.06,
      "STD": 2.4019223070763,
      "MEAN": 2.46153846153846,
      "COV": 0.975780937249749,
      "SHELF LIFE_1": 4.125000000000011
    },
    {
      "PART NUMBER": 14589131,
      "DESCRIPTION": "SEALING KIT, DIPPER ARM CYL. EC210B",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "AVERAGE (F2)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 2,
      "MAINTANING STOCK QTY": 8,
      "AVAILABLE": 9,
      "SHELF LIFE": 4.5,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 4,
      "WH REFERENCE": "",
      "TREND": 1.42,
      "STD": 2.7942248133771,
      "MEAN": 1.84615384615384,
      "COV": 1.51353844057926,
      "SHELF LIFE_1": 4.5
    },
    {
      "PART NUMBER": 14589132,
      "DESCRIPTION": "SEALING KIT, BUCKET CYL.  EC210B / EC140BP DIPPER ARM CYL.",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.833333333333333,
      "MAINTANING STOCK QTY": 3,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 3,
      "APRD QTY": 0,
      "WH12": 9,
      "WHXX": 5,
      "WH REFERENCE": "WHXX",
      "TREND": 1,
      "STD": 1.01273936708366,
      "MEAN": 0.769230769230769,
      "COV": 1.31656117720876,
      "SHELF LIFE_1": 3.6000000000000014
    },
    {
      "PART NUMBER": 14589140,
      "DESCRIPTION": "SEALING KIT, BUCKET CYL  ME EC460B/EC360B ARM CYL",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 29,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14589143,
      "DESCRIPTION": "SEALING KIT, BOOM / BUCKET CYL.  EC460B 14513726 ",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 1,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 24,
      "WHXX": 5,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14591545,
      "DESCRIPTION": "SWITCH_RECEIVER DRIER EC460B/EC700B 14541016 ",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.75,
      "MAINTANING STOCK QTY": 3,
      "AVAILABLE": 3,
      "SHELF LIFE": 4,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 5,
      "WHXX": 1,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 1.49357598761135,
      "MEAN": 0.692307692307692,
      "COV": 2.15738753766084,
      "SHELF LIFE_1": 4
    },
    {
      "PART NUMBER": 14596399,
      "DESCRIPTION": "FILTER, BREATHER HYD EC210B_",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "FAST (F1)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 7.66666666666666,
      "MAINTANING STOCK QTY": 31,
      "AVAILABLE": 11,
      "SHELF LIFE": 1.4347826086956534,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 20,
      "APRD QTY": 20,
      "WH12": 286,
      "WHXX": 56,
      "WH REFERENCE": "WHXX",
      "TREND": 0.91,
      "STD": 6.10275263660503,
      "MEAN": 7.07692307692307,
      "COV": 0.862345481259407,
      "SHELF LIFE_1": 4.043478260869569
    },
    {
      "PART NUMBER": 14603276,
      "DESCRIPTION": "BOLT, T/SHOE  EC480D  14532467, 1182-00880",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 3.16666666666666,
      "MAINTANING STOCK QTY": 13,
      "AVAILABLE": 13,
      "SHELF LIFE": 4.105263157894746,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3110,
      "WHXX": 45,
      "WH REFERENCE": "",
      "TREND": 1.47,
      "STD": 5.80892328608234,
      "MEAN": 2.92307692307692,
      "COV": 1.98726322944922,
      "SHELF LIFE_1": 4.105263157894746
    },
    {
      "PART NUMBER": 14880438,
      "DESCRIPTION": "WEATHERSTRIP",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 3,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14880552,
      "DESCRIPTION": "BOLT, T/SHOE EC210B  14880553",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 3.66666666666666,
      "MAINTANING STOCK QTY": 15,
      "AVAILABLE": 116,
      "SHELF LIFE": 31.636363636363694,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 318,
      "WHXX": 238,
      "WH REFERENCE": "",
      "TREND": 1.09,
      "STD": 8.30199234660433,
      "MEAN": 3.38461538461538,
      "COV": 2.45286137513309,
      "SHELF LIFE_1": 31.636363636363694
    },
    {
      "PART NUMBER": 14880553,
      "DESCRIPTION": "BOLT, T/SHOE EC210BLC 14880552",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 65,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1014,
      "WHXX": 1483,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14880692,
      "DESCRIPTION": "RING_CORD EC210B",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 23,
      "WHXX": 8,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14881185,
      "DESCRIPTION": "RING CORD",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 5,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 127,
      "WHXX": 10,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14881276,
      "DESCRIPTION": "V-BELT, A/C EC210B/EC290B SN17001~19730/L120F / EW145B",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "AVERAGE (F2)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 4.91666666666666,
      "MAINTANING STOCK QTY": 20,
      "AVAILABLE": 8,
      "SHELF LIFE": 1.627118644067799,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 12,
      "APRD QTY": 12,
      "WH12": 0,
      "WHXX": 44,
      "WH REFERENCE": "WHXX",
      "TREND": 1.15,
      "STD": 3.90758630988885,
      "MEAN": 4.53846153846153,
      "COV": 0.860993593704323,
      "SHELF LIFE_1": 4.067796610169498
    },
    {
      "PART NUMBER": 15078671,
      "DESCRIPTION": "V-BELT, FAN DRV EC210B SN35001/50001/70001",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "AVERAGE (F2)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 1.58333333333333,
      "MAINTANING STOCK QTY": 6,
      "AVAILABLE": 7,
      "SHELF LIFE": 4.421052631578957,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 74,
      "WHXX": 15,
      "WH REFERENCE": "",
      "TREND": 1.05,
      "STD": 1.50639661750508,
      "MEAN": 1.46153846153846,
      "COV": 1.03069242250348,
      "SHELF LIFE_1": 4.421052631578957
    },
    {
      "PART NUMBER": 20374662,
      "DESCRIPTION": " RELAY_24V",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 3,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 17,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 20405599,
      "DESCRIPTION": "SEALING RING EC210B",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 12,
      "WHXX": 6,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 20405792,
      "DESCRIPTION": "SCREW",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 13,
      "WHXX": 13,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 20405885,
      "DESCRIPTION": "SCREW EC210B",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 2,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 32,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 20405969,
      "DESCRIPTION": "GASKET",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 13,
      "WHXX": 10,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 20405970,
      "DESCRIPTION": "GASKET",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3,
      "WHXX": 5,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 20450754,
      "DESCRIPTION": "SCREW EC210B",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 22,
      "WHXX": 10,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 20459025,
      "DESCRIPTION": "RETAINING RING EC210B",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 1,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 6,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 20459191,
      "DESCRIPTION": "PLUG EC210B",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 1,
      "SHELF LIFE": 3.000000000000003,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 8,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 1,
      "STD": 0.480384461415261,
      "MEAN": 0.307692307692307,
      "COV": 1.56124949959959,
      "SHELF LIFE_1": 3.000000000000003
    },
    {
      "PART NUMBER": 20459192,
      "DESCRIPTION": "GASKET EC210B",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.416666666666666,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 2,
      "SHELF LIFE": 4.800000000000008,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 6,
      "WHXX": 2,
      "WH REFERENCE": "",
      "TREND": 0.8,
      "STD": 0.506369683541833,
      "MEAN": 0.384615384615384,
      "COV": 1.31656117720876,
      "SHELF LIFE_1": 4.800000000000008
    },
    {
      "PART NUMBER": 20459193,
      "DESCRIPTION": "SEALING RING EC210B",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.833333333333333,
      "MAINTANING STOCK QTY": 3,
      "AVAILABLE": 6,
      "SHELF LIFE": 7.200000000000003,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 96,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0.8,
      "STD": 1.01273936708366,
      "MEAN": 0.769230769230769,
      "COV": 1.31656117720876,
      "SHELF LIFE_1": 7.200000000000003
    },
    {
      "PART NUMBER": 20459212,
      "DESCRIPTION": "GASKET EC210B/L60/70/90E",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 34,
      "WHXX": 20,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 20459214,
      "DESCRIPTION": "WASHER EC210B",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 9,
      "WHXX": 8,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 20459215,
      "DESCRIPTION": "WASHER EC210B/EC460B",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 8,
      "WHXX": 9,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 20459218,
      "DESCRIPTION": "GASKET EC210B",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 72,
      "WHXX": 15,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 20459863,
      "DESCRIPTION": "PULLEY",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 1,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 20459953,
      "DESCRIPTION": "SEALING RING",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 6,
      "WHXX": 4,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 20459960,
      "DESCRIPTION": "PULLEY (ENG. D6D EAE2) EC210B",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 6,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 20460061,
      "DESCRIPTION": "SEALING RING EC210B",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 28,
      "WHXX": 36,
      "WH REFERENCE": "WHXX",
      "TREND": 0,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": 20460813,
      "DESCRIPTION": "SEALING RING",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 4,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 20482686,
      "DESCRIPTION": "ALTERNATOR EC210B",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 1,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 20482730,
      "DESCRIPTION": "GUIDE TUBE EC210B",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 20516934,
      "DESCRIPTION": "FUEL FILTER ASSY",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 1,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 3,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 20766790,
      "DESCRIPTION": "BIG END BEARING KIT  21290112",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.583333333333333,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 3,
      "SHELF LIFE": 5.142857142857146,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 9,
      "WHXX": 48,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 1.9414506867883,
      "MEAN": 0.538461538461538,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 5.142857142857146
    },
    {
      "PART NUMBER": 20796066,
      "DESCRIPTION": "RELAY",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 4,
      "WHXX": 4,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 20798186,
      "DESCRIPTION": "CYL HEAD GASKET, 1.37MM 2 BORES  EC210B",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 11,
      "WHXX": 4,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 20798191,
      "DESCRIPTION": "CYL. HEAD GASKET, 1.47MM 3 BORES EC210B",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3,
      "WHXX": 2,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 20798683,
      "DESCRIPTION": "FUEL INJECTOR ENG: D6D_EGE3 EC210B  REMAN 9020798683",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.666666666666666,
      "MAINTANING STOCK QTY": 3,
      "AVAILABLE": 7,
      "SHELF LIFE": 10.50000000000001,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 45,
      "WHXX": 4,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 1.66024403519791,
      "MEAN": 0.615384615384615,
      "COV": 2.69789655719661,
      "SHELF LIFE_1": 10.50000000000001
    },
    {
      "PART NUMBER": 20799731,
      "DESCRIPTION": "WASHER",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 1,
      "MAINTANING STOCK QTY": 4,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 4,
      "APRD QTY": 4,
      "WH12": 8,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 2,
      "STD": 3.32820117735137,
      "MEAN": 0.923076923076923,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 4
    },
    {
      "PART NUMBER": 20840491,
      "DESCRIPTION": "OIL PAN EC210B",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 1,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 20879322,
      "DESCRIPTION": "PRESSURE CAP",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 20973777,
      "DESCRIPTION": "SERVICE KIT, FUEL LINES EC210B/EC290B/G930",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.5,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 2,
      "SHELF LIFE": 4,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 4,
      "WHXX": 11,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 1.19828937903055,
      "MEAN": 0.461538461538461,
      "COV": 2.5962936545662,
      "SHELF LIFE_1": 4
    },
    {
      "PART NUMBER": 21141943,
      "DESCRIPTION": "SENSOR EC210B/EC290B/G700B/L60E",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 5,
      "WHXX": 3,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 21141955,
      "DESCRIPTION": "RACEWAY WASHER 20405899",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 12,
      "WHXX": 12,
      "WH REFERENCE": "WHXX",
      "TREND": 0,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": 21142728,
      "DESCRIPTION": "ADAPTER",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 3,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 21147446,
      "DESCRIPTION": "HIGH-PRESSURE PUMP  REMAN 9021147446",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 1,
      "SHELF LIFE": 6.000000000000024,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 10,
      "WHXX": 1,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": 21247955,
      "DESCRIPTION": "23657265 COOLANT PUMP (MUST BE SUPPLIED W/ D6E PULLEY 21795915 / D4E PULLEY 20798526 21247955)",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 1,
      "SHELF LIFE": 12.000000000000005,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 1,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 12.000000000000005
    },
    {
      "PART NUMBER": 21291011,
      "DESCRIPTION": "SWITCH_PRESSURE EC210B/EC290B/G930 20585158 20450687",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 1,
      "SHELF LIFE": 6.000000000000024,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3,
      "WHXX": 5,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.375533808099405,
      "MEAN": 0.153846153846153,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": 21511352,
      "DESCRIPTION": "FUEL PUMP EC210B SD110 21211878 20524154 20518337",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.25,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 1,
      "WH REFERENCE": "WHXX",
      "TREND": 0.67,
      "STD": 0.438529009653514,
      "MEAN": 0.23076923076923,
      "COV": 1.90029237516522,
      "SHELF LIFE_1": 4
    },
    {
      "PART NUMBER": 3280048,
      "DESCRIPTION": "INJECTOR NOZZLE ",
      "PRODUCT CATEGORY": "CUG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 60100002,
      "DESCRIPTION": "E-ECU EC210B EC290B",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 4,
      "WHXX": 1,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "7123-52560",
      "DESCRIPTION": "WHEEL NUT",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 1.08333333333333,
      "MAINTANING STOCK QTY": 4,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 4,
      "APRD QTY": 4,
      "WH12": 80,
      "WHXX": 58,
      "WH REFERENCE": "WHXX",
      "TREND": 1.23,
      "STD": 2.51661147842358,
      "MEAN": 1,
      "COV": 2.51661147842358,
      "SHELF LIFE_1": 3.692307692307704
    },
    {
      "PART NUMBER": "7273-10751",
      "DESCRIPTION": "CAP, MCV  EC210B",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.666666666666666,
      "MAINTANING STOCK QTY": 3,
      "AVAILABLE": 3,
      "SHELF LIFE": 4.500000000000004,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 47,
      "WHXX": 4,
      "WH REFERENCE": "",
      "TREND": 1.25,
      "STD": 0.869718492622904,
      "MEAN": 0.615384615384615,
      "COV": 1.41329255051221,
      "SHELF LIFE_1": 4.500000000000004
    },
    {
      "PART NUMBER": "8038-00010",
      "DESCRIPTION": "SEAL KIT_RECOIL EC210B",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 10,
      "WHXX": 11,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "8230-02830",
      "DESCRIPTION": "ELEMENT BREATHER, HYDRAULIC TANK",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "AVERAGE (F2)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 1.5,
      "MAINTANING STOCK QTY": 6,
      "AVAILABLE": 4,
      "SHELF LIFE": 2.6666666666666665,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 2,
      "APRD QTY": 2,
      "WH12": 14,
      "WHXX": 38,
      "WH REFERENCE": "WHXX",
      "TREND": 1.11,
      "STD": 1.80455264717055,
      "MEAN": 1.38461538461538,
      "COV": 1.30328802295651,
      "SHELF LIFE_1": 4
    },
    {
      "PART NUMBER": "8230-26520",
      "DESCRIPTION": "ROLLER BEARING",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 24,
      "WHXX": 5,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "8230-36840",
      "DESCRIPTION": "SEAL KIT, RCV  EC210B/EC460B/EC700B",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 7,
      "WHXX": 16,
      "WH REFERENCE": "WHXX",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": 85108352,
      "DESCRIPTION": "CRANKSHAFT SEAL EC700B/FM440 20441481",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "9131-11000",
      "DESCRIPTION": "LOCK NUT EC700B",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 27,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 955918,
      "DESCRIPTION": "SPRING WASHER",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 2,
      "WHXX": 8,
      "WH REFERENCE": "WHXX",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": 955919,
      "DESCRIPTION": "SPRING WASHER",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 1.16666666666666,
      "MAINTANING STOCK QTY": 5,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 5,
      "APRD QTY": 5,
      "WH12": 21,
      "WHXX": 85,
      "WH REFERENCE": "WHXX",
      "TREND": 2,
      "STD": 3.32820117735137,
      "MEAN": 1.07692307692307,
      "COV": 3.09047252182627,
      "SHELF LIFE_1": 4.28571428571431
    },
    {
      "PART NUMBER": 955922,
      "DESCRIPTION": "SPRING WASHER 10.5MM 9213-10000 EC700B",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 2.08333333333333,
      "MAINTANING STOCK QTY": 8,
      "AVAILABLE": 8,
      "SHELF LIFE": 3.840000000000006,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 411,
      "WHXX": 141,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 5.0408586977078,
      "MEAN": 1.92307692307692,
      "COV": 2.62124652280805,
      "SHELF LIFE_1": 3.840000000000006
    },
    {
      "PART NUMBER": 955925,
      "DESCRIPTION": "WASHER 9213-14000",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 2,
      "MAINTANING STOCK QTY": 8,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 8,
      "APRD QTY": 8,
      "WH12": 156,
      "WHXX": 55,
      "WH REFERENCE": "WHXX",
      "TREND": 2,
      "STD": 6.65640235470275,
      "MEAN": 1.84615384615384,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 4
    },
    {
      "PART NUMBER": 955927,
      "DESCRIPTION": "SPRING WASHER 01602-01854",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 2.91666666666666,
      "MAINTANING STOCK QTY": 12,
      "AVAILABLE": 2,
      "SHELF LIFE": 0.6857142857142873,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 10,
      "APRD QTY": 10,
      "WH12": 387,
      "WHXX": 48,
      "WH REFERENCE": "WHXX",
      "TREND": 1.43,
      "STD": 3.88125012903092,
      "MEAN": 2.69230769230769,
      "COV": 1.44160719078291,
      "SHELF LIFE_1": 4.114285714285724
    },
    {
      "PART NUMBER": 960259,
      "DESCRIPTION": "O-RING EC460B 9511-22230",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.583333333333333,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 3,
      "SHELF LIFE": 5.142857142857146,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 73,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 1.14,
      "STD": 0.967417922046845,
      "MEAN": 0.538461538461538,
      "COV": 1.79663328380128,
      "SHELF LIFE_1": 5.142857142857146
    },
    {
      "PART NUMBER": "9612-08040",
      "DESCRIPTION": "BUSHING",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 2,
      "SHELF LIFE": 6.000000000000006,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 16,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 1.10940039245045,
      "MEAN": 0.307692307692307,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000006
    },
    {
      "PART NUMBER": "9612-09540",
      "DESCRIPTION": "CYL BUSHING EC210B",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3,
      "WHXX": 5,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 969589,
      "DESCRIPTION": "SCREW",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.25,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 75,
      "WHXX": 19,
      "WH REFERENCE": "WHXX",
      "TREND": 0,
      "STD": 0.832050294337843,
      "MEAN": 0.23076923076923,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 4
    },
    {
      "PART NUMBER": 978937,
      "DESCRIPTION": "60110032 BOLT HEX HD",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 983303,
      "DESCRIPTION": "HEXAGON NUT EC210B",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 3,
      "SHELF LIFE": 9.000000000000009,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 55,
      "WHXX": 67,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 1.10940039245045,
      "MEAN": 0.307692307692307,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 9.000000000000009
    },
    {
      "PART NUMBER": 983497,
      "DESCRIPTION": "O-RING 9511-12010",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 8.41666666666666,
      "MAINTANING STOCK QTY": 34,
      "AVAILABLE": 18,
      "SHELF LIFE": 2.13861386138614,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 16,
      "APRD QTY": 16,
      "WH12": 31,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 1.98,
      "STD": 27.7132755376487,
      "MEAN": 7.76923076923076,
      "COV": 3.56705526722211,
      "SHELF LIFE_1": 4.039603960396042
    },
    {
      "PART NUMBER": 983502,
      "DESCRIPTION": "O-RING EC210B/EC460C 9511-12011",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.75,
      "MAINTANING STOCK QTY": 3,
      "AVAILABLE": 10,
      "SHELF LIFE": 13.333333333333334,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 211,
      "WHXX": 61,
      "WH REFERENCE": "",
      "TREND": 0.67,
      "STD": 1.03155347127648,
      "MEAN": 0.692307692307692,
      "COV": 1.49002168073269,
      "SHELF LIFE_1": 13.333333333333334
    },
    {
      "PART NUMBER": 983505,
      "DESCRIPTION": "O-RING 9511-12014",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 126,
      "WHXX": 36,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 983524,
      "DESCRIPTION": "O-RING EC210B 9511-12024",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 2,
      "SHELF LIFE": 6.000000000000006,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 231,
      "WHXX": 30,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 1.10940039245045,
      "MEAN": 0.307692307692307,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000006
    },
    {
      "PART NUMBER": 983527,
      "DESCRIPTION": "O-RING EC700B",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 7,
      "SHELF LIFE": 84.00000000000003,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 50,
      "WHXX": 104,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 84.00000000000003
    },
    {
      "PART NUMBER": 983530,
      "DESCRIPTION": "O-RING EC210B",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.25,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 11,
      "WHXX": 26,
      "WH REFERENCE": "WHXX",
      "TREND": 0,
      "STD": 0.832050294337843,
      "MEAN": 0.23076923076923,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 4
    },
    {
      "PART NUMBER": 990557,
      "DESCRIPTION": "O-RING EC210B",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 297,
      "WHXX": 31,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 990566,
      "DESCRIPTION": "O-RING",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.666666666666666,
      "MAINTANING STOCK QTY": 3,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 3,
      "APRD QTY": 0,
      "WH12": 84,
      "WHXX": 94,
      "WH REFERENCE": "WHXX",
      "TREND": 0,
      "STD": 2.21880078490091,
      "MEAN": 0.615384615384615,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 4.500000000000004
    },
    {
      "PART NUMBER": 990583,
      "DESCRIPTION": "O-RING 9511-22035",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.666666666666666,
      "MAINTANING STOCK QTY": 3,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 3,
      "APRD QTY": 0,
      "WH12": 3,
      "WHXX": 15,
      "WH REFERENCE": "WHXX",
      "TREND": 0,
      "STD": 2.21880078490091,
      "MEAN": 0.615384615384615,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 4.500000000000004
    },
    {
      "PART NUMBER": 990596,
      "DESCRIPTION": "O-RING",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 59,
      "WHXX": 10,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 990740,
      "DESCRIPTION": "O-RING EC210B",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 1.33333333333333,
      "MAINTANING STOCK QTY": 5,
      "AVAILABLE": 5,
      "SHELF LIFE": 3.7500000000000098,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 315,
      "WHXX": 34,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 4.43760156980183,
      "MEAN": 1.23076923076923,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 3.7500000000000098
    },
    {
      "PART NUMBER": 15048941,
      "DESCRIPTION": "FLASHER RELAY 1089286 11170048",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 14,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 1594228,
      "DESCRIPTION": "TEMPERATURE SENSOR FM340",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 1635305,
      "DESCRIPTION": "SPRING LEAF NO. 1, REAR  FL10/FM400",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 1635322,
      "DESCRIPTION": "SPRING LEAF FM440",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 1674083,
      "DESCRIPTION": "PRESS CAP_EXP TANK FM10 20879323",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 1,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 4,
      "WHXX": 63,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 20367490,
      "DESCRIPTION": "RELAY, STARTER NL10/FM400/EC460B/EC700B/G930/L150F",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 4,
      "SHELF LIFE": 24.000000000000096,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 33,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.375533808099405,
      "MEAN": 0.153846153846153,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 24.000000000000096
    },
    {
      "PART NUMBER": 20378932,
      "DESCRIPTION": "FUSE 5A FM9/FM400",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 2,
      "SHELF LIFE": 12.000000000000048,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 11,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 12.000000000000048
    },
    {
      "PART NUMBER": 20378934,
      "DESCRIPTION": "FUSE 10A FM9/FM400",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.666666666666666,
      "MAINTANING STOCK QTY": 3,
      "AVAILABLE": 8,
      "SHELF LIFE": 12.000000000000012,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 117,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 1.55662356498831,
      "MEAN": 0.615384615384615,
      "COV": 2.529513293106,
      "SHELF LIFE_1": 12.000000000000012
    },
    {
      "PART NUMBER": 20378935,
      "DESCRIPTION": "FUSE 15A FM9/FM400",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.416666666666666,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 8,
      "SHELF LIFE": 19.20000000000003,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 153,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 1.38675049056307,
      "MEAN": 0.384615384615384,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 19.20000000000003
    },
    {
      "PART NUMBER": 3283333,
      "DESCRIPTION": "GASKET_CYL HEAD 4BT3.9",
      "PRODUCT CATEGORY": "CUG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.25,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 0.67,
      "STD": 0.599144689515278,
      "MEAN": 0.23076923076923,
      "COV": 2.5962936545662,
      "SHELF LIFE_1": 4
    },
    {
      "PART NUMBER": 3802100,
      "DESCRIPTION": "PISTON KIT 4BT",
      "PRODUCT CATEGORY": "CUG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 1,
      "MAINTANING STOCK QTY": 4,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 4,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 0,
      "STD": 2.39657875806111,
      "MEAN": 0.923076923076923,
      "COV": 2.5962936545662,
      "SHELF LIFE_1": 4
    },
    {
      "PART NUMBER": 3917539,
      "DESCRIPTION": "INJECTOR PUMP",
      "PRODUCT CATEGORY": "CUG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 3964432,
      "DESCRIPTION": "STARTER ASSY  - 4B3.9",
      "PRODUCT CATEGORY": "CUG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 3969562,
      "DESCRIPTION": "CONROD BRG STD 3901170 3939859",
      "PRODUCT CATEGORY": "CUG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 1,
      "MAINTANING STOCK QTY": 4,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 4,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 0,
      "STD": 2.39657875806111,
      "MEAN": 0.923076923076923,
      "COV": 2.5962936545662,
      "SHELF LIFE_1": 4
    },
    {
      "PART NUMBER": 4893693,
      "DESCRIPTION": "BEARING, CONROD STD",
      "PRODUCT CATEGORY": "CUG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 1,
      "MAINTANING STOCK QTY": 4,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 4,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 0,
      "STD": 2.39657875806111,
      "MEAN": 0.923076923076923,
      "COV": 2.5962936545662,
      "SHELF LIFE_1": 4
    },
    {
      "PART NUMBER": 4983584,
      "DESCRIPTION": "FUEL TRANSFER PUMP SD100D/4BT ENGINE",
      "PRODUCT CATEGORY": "CUG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "FF42000",
      "DESCRIPTION": "FUEL, CARTRIDGE FF5052 P553004",
      "PRODUCT CATEGORY": "CUF",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 1,
      "SHELF LIFE": 12.000000000000005,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 102,
      "WHXX": 11,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 12.000000000000005
    },
    {
      "PART NUMBER": "FF5052",
      "DESCRIPTION": "FUEL, CARTRIDGE FF42000 P550440",
      "PRODUCT CATEGORY": "CUF",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "FF5612",
      "DESCRIPTION": "FUEL FILTER",
      "PRODUCT CATEGORY": "CUF",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 1,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "FS1000",
      "DESCRIPTION": "FF/WS FILTER, SPIN-ON P551000 FS1212",
      "PRODUCT CATEGORY": "CUF",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 2,
      "STD": 0.75106761619881,
      "MEAN": 0.307692307692307,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 3.000000000000003
    },
    {
      "PART NUMBER": "FS1212",
      "DESCRIPTION": "FWS FILTER, SPIN-ON P558000 P558020",
      "PRODUCT CATEGORY": "CUF",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 1,
      "STD": 0.630425171956115,
      "MEAN": 0.307692307692307,
      "COV": 2.04888180885737,
      "SHELF LIFE_1": 3.000000000000003
    },
    {
      "PART NUMBER": "FS1242B",
      "DESCRIPTION": "FWS FILTER W/ BOWL, SPIN-ON",
      "PRODUCT CATEGORY": "CUF",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 1,
      "SHELF LIFE": 6.000000000000024,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 51,
      "WHXX": 4,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": "FS1280",
      "DESCRIPTION": "FF/WS FILTER, SPIN-ON SD100D P551329",
      "PRODUCT CATEGORY": "CUF",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.416666666666666,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 2,
      "APRD QTY": 2,
      "WH12": 54,
      "WHXX": 21,
      "WH REFERENCE": "WHXX",
      "TREND": 1.2,
      "STD": 0.506369683541833,
      "MEAN": 0.384615384615384,
      "COV": 1.31656117720876,
      "SHELF LIFE_1": 4.800000000000008
    },
    {
      "PART NUMBER": "FS19532",
      "DESCRIPTION": "WATER SEPARATOR LG958 R90P",
      "PRODUCT CATEGORY": "CUF",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 2,
      "SHELF LIFE": 24.00000000000001,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 17,
      "WHXX": 8,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 24.00000000000001
    },
    {
      "PART NUMBER": "HF6072",
      "DESCRIPTION": "HYDRAULIC, CARTRIDGE P551158 175-49-11580",
      "PRODUCT CATEGORY": "CUF",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 1,
      "SHELF LIFE": 12.000000000000005,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 12.000000000000005
    },
    {
      "PART NUMBER": "LF3349",
      "DESCRIPTION": "LUBE FILTER P558615",
      "PRODUCT CATEGORY": "CUF",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 53,
      "WHXX": 2,
      "WH REFERENCE": "WHXX",
      "TREND": 1,
      "STD": 0.375533808099405,
      "MEAN": 0.153846153846153,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": "LF3403",
      "DESCRIPTION": "LUBE, FULL FLOW SPIN-ON P550162",
      "PRODUCT CATEGORY": "CUF",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 36786457,
      "DESCRIPTION": "SWITCH IGNITION L5K",
      "PRODUCT CATEGORY": "DOP",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 36845493,
      "DESCRIPTION": "FUEL TANK FILTER L5K 36845501",
      "PRODUCT CATEGORY": "DOP",
      "ITEM MOVEMENT BRANCH": "AVERAGE (F2)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 2.25,
      "MAINTANING STOCK QTY": 9,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 9,
      "APRD QTY": 9,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "PARTIAL WH12",
      "TREND": 0.52,
      "STD": 4.29071747033714,
      "MEAN": 2.07692307692307,
      "COV": 2.0659010042364,
      "SHELF LIFE_1": 4
    },
    {
      "PART NUMBER": 36847747,
      "DESCRIPTION": "LAMP, 1000W MH (LT) L5K",
      "PRODUCT CATEGORY": "DOP",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.583333333333333,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 2,
      "SHELF LIFE": 3.42857142857143,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 8,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 1.9414506867883,
      "MEAN": 0.538461538461538,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 3.42857142857143
    },
    {
      "PART NUMBER": 36870566,
      "DESCRIPTION": "FUEL FILTER L5K 70965099",
      "PRODUCT CATEGORY": "DOP",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 1,
      "MAINTANING STOCK QTY": 4,
      "AVAILABLE": 4,
      "SHELF LIFE": 4,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 47,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 3.32820117735137,
      "MEAN": 0.923076923076923,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 4
    },
    {
      "PART NUMBER": 36870574,
      "DESCRIPTION": "OIL FILTER L5K 70867403",
      "PRODUCT CATEGORY": "DOP",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 1.16666666666666,
      "MAINTANING STOCK QTY": 5,
      "AVAILABLE": 4,
      "SHELF LIFE": 3.428571428571448,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 38,
      "WHXX": 1,
      "WH REFERENCE": "WHXX",
      "TREND": 0.29,
      "STD": 3.32820117735137,
      "MEAN": 1.07692307692307,
      "COV": 3.09047252182627,
      "SHELF LIFE_1": 4.28571428571431
    },
    {
      "PART NUMBER": 36888170,
      "DESCRIPTION": "ALTERNATOR, KUBOTA",
      "PRODUCT CATEGORY": "DOP",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 36888683,
      "DESCRIPTION": "BALLAST CAPACITOR L5K",
      "PRODUCT CATEGORY": "DOP",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 36899706,
      "DESCRIPTION": "PRO TEC OIL, COMP. FLUID (PAIL)",
      "PRODUCT CATEGORY": "DOP",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.833333333333333,
      "MAINTANING STOCK QTY": 3,
      "AVAILABLE": 4,
      "SHELF LIFE": 4.800000000000002,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 119,
      "WHXX": 30,
      "WH REFERENCE": "",
      "TREND": 0.8,
      "STD": 1.30088727117598,
      "MEAN": 0.769230769230769,
      "COV": 1.69115345252877,
      "SHELF LIFE_1": 4.800000000000002
    },
    {
      "PART NUMBER": 58854316,
      "DESCRIPTION": "LOCKWASHER",
      "PRODUCT CATEGORY": "VCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 8,
      "WHXX": 7,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "01602-02060",
      "DESCRIPTION": "LOCK WASHER",
      "PRODUCT CATEGORY": "KOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 12.8333333333333,
      "MAINTANING STOCK QTY": 51,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 51,
      "APRD QTY": 51,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 2,
      "STD": 39.8033628266217,
      "MEAN": 11.8461538461538,
      "COV": 3.36002413471482,
      "SHELF LIFE_1": 3.9740259740259845
    },
    {
      "PART NUMBER": "01643-31032",
      "DESCRIPTION": "FLAT WASHER",
      "PRODUCT CATEGORY": "KOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 1,
      "MAINTANING STOCK QTY": 4,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 4,
      "APRD QTY": 4,
      "WH12": 17,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 2,
      "STD": 3.32820117735137,
      "MEAN": 0.923076923076923,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 4
    },
    {
      "PART NUMBER": "01643-31232",
      "DESCRIPTION": "FLAT WASHER",
      "PRODUCT CATEGORY": "KOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 1.33333333333333,
      "MAINTANING STOCK QTY": 5,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 5,
      "APRD QTY": 5,
      "WH12": 6,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 2,
      "STD": 4.43760156980183,
      "MEAN": 1.23076923076923,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 3.7500000000000098
    },
    {
      "PART NUMBER": "01643-31845",
      "DESCRIPTION": "FLATWASHER 01643-21845",
      "PRODUCT CATEGORY": "SDG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 21.3333333333333,
      "MAINTANING STOCK QTY": 85,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 85,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 0.25,
      "STD": 51.9348704555115,
      "MEAN": 19.6923076923076,
      "COV": 2.63731764031894,
      "SHELF LIFE_1": 3.984375000000006
    },
    {
      "PART NUMBER": "07056-10045",
      "DESCRIPTION": "OIL STRAINER, T/C  SD16  16Y-60-00013",
      "PRODUCT CATEGORY": "SDG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "07700-40460",
      "DESCRIPTION": "VALVE",
      "PRODUCT CATEGORY": "KOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "09244-02508",
      "DESCRIPTION": "PIN ASSY.",
      "PRODUCT CATEGORY": "KOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 1,
      "MAINTANING STOCK QTY": 4,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 4,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 0,
      "STD": 3.32820117735137,
      "MEAN": 0.923076923076923,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 4
    },
    {
      "PART NUMBER": "150-30-13460",
      "DESCRIPTION": "SNAP RING",
      "PRODUCT CATEGORY": "KOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.5,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 2,
      "APRD QTY": 2,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 1.33,
      "STD": 1.19828937903055,
      "MEAN": 0.461538461538461,
      "COV": 2.5962936545662,
      "SHELF LIFE_1": 4
    },
    {
      "PART NUMBER": "150-30-13480",
      "DESCRIPTION": "RING",
      "PRODUCT CATEGORY": "KOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.5,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 2,
      "APRD QTY": 2,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 1.33,
      "STD": 1.19828937903055,
      "MEAN": 0.461538461538461,
      "COV": 2.5962936545662,
      "SHELF LIFE_1": 4
    },
    {
      "PART NUMBER": "154-32-05001G",
      "DESCRIPTION": "T/S BOLT W/ NUT D80 20MM",
      "PRODUCT CATEGORY": "KOR",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 2.75,
      "MAINTANING STOCK QTY": 11,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 11,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 0,
      "STD": 6.35892266314801,
      "MEAN": 2.53846153846153,
      "COV": 2.505030140028,
      "SHELF LIFE_1": 4
    },
    {
      "PART NUMBER": "155-30-13230",
      "DESCRIPTION": "SPRING",
      "PRODUCT CATEGORY": "KOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 2.33333333333333,
      "MAINTANING STOCK QTY": 9,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 9,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 0.86,
      "STD": 5.32049737408941,
      "MEAN": 2.15384615384615,
      "COV": 2.47023092368436,
      "SHELF LIFE_1": 3.857142857142863
    },
    {
      "PART NUMBER": "195-30-17960",
      "DESCRIPTION": "SEAT B",
      "PRODUCT CATEGORY": "KOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 2.33333333333333,
      "MAINTANING STOCK QTY": 9,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 9,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 0.86,
      "STD": 5.32049737408941,
      "MEAN": 2.15384615384615,
      "COV": 2.47023092368436,
      "SHELF LIFE_1": 3.857142857142863
    },
    {
      "PART NUMBER": 10021425,
      "DESCRIPTION": "FILTER ELEMENT",
      "PRODUCT CATEGORY": "LIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 10021426,
      "DESCRIPTION": "SAFETY ELEMENT",
      "PRODUCT CATEGORY": "LIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 10035245,
      "DESCRIPTION": "FILTER ELEMENT",
      "PRODUCT CATEGORY": "LIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 10044302,
      "DESCRIPTION": "FUEL PRE-FILTER",
      "PRODUCT CATEGORY": "LIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 10044303,
      "DESCRIPTION": "10429946 FUEL FILTER FINE",
      "PRODUCT CATEGORY": "LIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 10297295,
      "DESCRIPTION": "EASY CHANGE FILTER",
      "PRODUCT CATEGORY": "LIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 10429946,
      "DESCRIPTION": "FUEL PRE-FILTER 10044303",
      "PRODUCT CATEGORY": "LIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 10440983,
      "DESCRIPTION": "EASY CHANGE FILTER",
      "PRODUCT CATEGORY": "LIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 21739130,
      "DESCRIPTION": "EXHAUST VALVE 20755883",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3,
      "WHXX": 24,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 20460214,
      "DESCRIPTION": "CLIP",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 10,
      "WHXX": 5,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "A210417000095",
      "DESCRIPTION": "RETAINER RING 95GB895.1",
      "PRODUCT CATEGORY": "YPG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 9,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "B230101002126",
      "DESCRIPTION": "ZBR RING",
      "PRODUCT CATEGORY": "YPG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 27,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "B230101001161",
      "DESCRIPTION": "CRGS-TYPE SEAL RING",
      "PRODUCT CATEGORY": "YPG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 18,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 60044253,
      "DESCRIPTION": "ANTI DUST B230101001859",
      "PRODUCT CATEGORY": "YPG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 11,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4021000199,
      "DESCRIPTION": "SPHERICAL PLAIN BEARING 4120001004009 4120006629011",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 4,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 15058186,
      "DESCRIPTION": "SUPER HYDRAULIC OIL  ISO VG68",
      "PRODUCT CATEGORY": "LUV",
      "ITEM MOVEMENT BRANCH": "FAST (F1)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 2284.66666666666,
      "MAINTANING STOCK QTY": 9139,
      "AVAILABLE": 8472,
      "SHELF LIFE": 3.708199591479439,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 667,
      "APRD QTY": 660,
      "WH12": 4828,
      "WHXX": 4215,
      "WH REFERENCE": "WHXX",
      "TREND": 1.05,
      "STD": 929.042738659751,
      "MEAN": 2108.92307692307,
      "COV": 0.440529457345227,
      "SHELF LIFE_1": 4.000145900204272
    },
    {
      "PART NUMBER": 15058290,
      "DESCRIPTION": "SUPER WET BRAKE TRANSAXLE OIL WB102 ",
      "PRODUCT CATEGORY": "LUV",
      "ITEM MOVEMENT BRANCH": "AVERAGE (F2)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 159.333333333333,
      "MAINTANING STOCK QTY": 637,
      "AVAILABLE": 520,
      "SHELF LIFE": 3.2635983263598396,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 117,
      "APRD QTY": 120,
      "WH12": 1136,
      "WHXX": 8,
      "WH REFERENCE": "WH12 + WHXX",
      "TREND": 0.89,
      "STD": 200.25419743352,
      "MEAN": 147.076923076923,
      "COV": 1.36156096581368,
      "SHELF LIFE_1": 3.9979079497908034
    },
    {
      "PART NUMBER": 15067098,
      "DESCRIPTION": "AUTOMATIC TRANSMISSION FLUID AT101",
      "PRODUCT CATEGORY": "LUV",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 76.6666666666666,
      "MAINTANING STOCK QTY": 307,
      "AVAILABLE": 262,
      "SHELF LIFE": 3.417391304347829,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 45,
      "APRD QTY": 40,
      "WH12": 1661,
      "WHXX": 1,
      "WH REFERENCE": "WH12 + WHXX",
      "TREND": 0.61,
      "STD": 176.230036148851,
      "MEAN": 70.7692307692307,
      "COV": 2.49020703253812,
      "SHELF LIFE_1": 4.00434782608696
    },
    {
      "PART NUMBER": 15067197,
      "DESCRIPTION": "ULTRA DIESEL ENGINE OIL 15W/40 VDS-3 85102464",
      "PRODUCT CATEGORY": "LUV",
      "ITEM MOVEMENT BRANCH": "FAST (F1)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 1613.33333333333,
      "MAINTANING STOCK QTY": 6453,
      "AVAILABLE": 5406,
      "SHELF LIFE": 3.3508264462809985,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1047,
      "APRD QTY": 1040,
      "WH12": 10902,
      "WHXX": 2090,
      "WH REFERENCE": "WHXX",
      "TREND": 0.93,
      "STD": 677.633892531721,
      "MEAN": 1489.23076923076,
      "COV": 0.455022758414895,
      "SHELF LIFE_1": 3.9997933884297603
    },
    {
      "PART NUMBER": 15067515,
      "DESCRIPTION": "SUPER TRANSMISSION OIL  80W/90  API GL-5",
      "PRODUCT CATEGORY": "LUV",
      "ITEM MOVEMENT BRANCH": "FAST (F1)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 428.666666666666,
      "MAINTANING STOCK QTY": 1715,
      "AVAILABLE": 1023,
      "SHELF LIFE": 2.3864696734059136,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 692,
      "APRD QTY": 700,
      "WH12": 1714,
      "WHXX": 20,
      "WH REFERENCE": "WH12 + WHXX",
      "TREND": 1.04,
      "STD": 327.603262248558,
      "MEAN": 395.692307692307,
      "COV": 0.827924263069839,
      "SHELF LIFE_1": 4.000777604976678
    },
    {
      "PART NUMBER": 15067522,
      "DESCRIPTION": "SUPER TRANSMISSION OIL  85W/140  OIL API GL-5 99990-1Z05J",
      "PRODUCT CATEGORY": "LUV",
      "ITEM MOVEMENT BRANCH": "FAST (F1)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 335,
      "MAINTANING STOCK QTY": 1340,
      "AVAILABLE": 1216,
      "SHELF LIFE": 3.629850746268657,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 124,
      "APRD QTY": 120,
      "WH12": 6688,
      "WHXX": 457,
      "WH REFERENCE": "WHXX",
      "TREND": 0.98,
      "STD": 169.118377596953,
      "MEAN": 309.230769230769,
      "COV": 0.546900226059798,
      "SHELF LIFE_1": 4
    },
    {
      "PART NUMBER": 15067644,
      "DESCRIPTION": "WET BRAKE TRANSAXLE OIL WB101",
      "PRODUCT CATEGORY": "LUV",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 110,
      "MAINTANING STOCK QTY": 440,
      "AVAILABLE": 400,
      "SHELF LIFE": 3.6363636363636362,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 40,
      "APRD QTY": 40,
      "WH12": 1252,
      "WHXX": 20,
      "WH REFERENCE": "WH12 + WHXX",
      "TREND": 0,
      "STD": 282.307342974701,
      "MEAN": 101.538461538461,
      "COV": 2.78029958990236,
      "SHELF LIFE_1": 4
    },
    {
      "PART NUMBER": 15143928,
      "DESCRIPTION": "AUTOMATIC TRANSMISSION FLUID AT102  L150G/A40F",
      "PRODUCT CATEGORY": "LUV",
      "ITEM MOVEMENT BRANCH": "AVERAGE (F2)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 75.6666666666666,
      "MAINTANING STOCK QTY": 303,
      "AVAILABLE": 320,
      "SHELF LIFE": 4.229074889867845,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2532,
      "WHXX": 22,
      "WH REFERENCE": "",
      "TREND": 0.66,
      "STD": 98.6254245059272,
      "MEAN": 69.8461538461538,
      "COV": 1.41203801605402,
      "SHELF LIFE_1": 4.229074889867845
    },
    {
      "PART NUMBER": 15198387,
      "DESCRIPTION": "SUPER GEAR OIL 75W80 GO102 L150G/A40F 15057965 20L/PAIL",
      "PRODUCT CATEGORY": "LUV",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 80,
      "MAINTANING STOCK QTY": 320,
      "AVAILABLE": 792,
      "SHELF LIFE": 9.9,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 6000,
      "WHXX": 1940,
      "WH REFERENCE": "",
      "TREND": 1.04,
      "STD": 159.663106860375,
      "MEAN": 73.8461538461538,
      "COV": 2.16210457206758,
      "SHELF LIFE_1": 9.9
    },
    {
      "PART NUMBER": "175-49-11580",
      "DESCRIPTION": "FILTER ELEMENT, STRG  SD22  16Y-75-23200  HF6072 ",
      "PRODUCT CATEGORY": "SDG",
      "ITEM MOVEMENT BRANCH": "AVERAGE (F2)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.5,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 2,
      "SHELF LIFE": 4,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 1.67,
      "STD": 0.776250025806184,
      "MEAN": 0.461538461538461,
      "COV": 1.6818750559134,
      "SHELF LIFE_1": 4
    },
    {
      "PART NUMBER": "175-60-27380",
      "DESCRIPTION": "FILTER ELEMENT, HYD.  SD22    HF6101",
      "PRODUCT CATEGORY": "SDG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 1,
      "SHELF LIFE": 6.000000000000024,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 6,
      "WHXX": 1,
      "WH REFERENCE": "",
      "TREND": 1,
      "STD": 0.375533808099405,
      "MEAN": 0.153846153846153,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": 3325963,
      "DESCRIPTION": "BELT, V RIBBED FAN DRV SD22",
      "PRODUCT CATEGORY": "SDG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "6127-81-7412T",
      "DESCRIPTION": "AIR FILTER KIT  SD22  (AF4504M+AF1791)",
      "PRODUCT CATEGORY": "SDG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 1,
      "SHELF LIFE": 6.000000000000024,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 19,
      "WHXX": 1,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.375533808099405,
      "MEAN": 0.153846153846153,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": "D2500-00000",
      "DESCRIPTION": "SWITCH, STARTING SD22",
      "PRODUCT CATEGORY": "SDG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 13351382,
      "DESCRIPTION": "CONTROL,THROTTLE",
      "PRODUCT CATEGORY": "VCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 13465547,
      "DESCRIPTION": "FUEL FILTER FS19616 SD100DC QSB",
      "PRODUCT CATEGORY": "VCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 3,
      "SHELF LIFE": 18.000000000000075,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 32,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.375533808099405,
      "MEAN": 0.153846153846153,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 18.000000000000075
    },
    {
      "PART NUMBER": 13469606,
      "DESCRIPTION": "COIL SD110",
      "PRODUCT CATEGORY": "VCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 4,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 15155620,
      "DESCRIPTION": "OIL FILTER 43922913",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.916666666666666,
      "MAINTANING STOCK QTY": 4,
      "AVAILABLE": 7,
      "SHELF LIFE": 7.636363636363642,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 30,
      "WHXX": 58,
      "WH REFERENCE": "",
      "TREND": 0.73,
      "STD": 1.46322439872551,
      "MEAN": 0.846153846153846,
      "COV": 1.72926519849378,
      "SHELF LIFE_1": 7.636363636363642
    },
    {
      "PART NUMBER": 20163150,
      "DESCRIPTION": "FUEL FILTER, PRIM 35374677 59477570 FF42000",
      "PRODUCT CATEGORY": "VCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.25,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 10,
      "WHXX": 1,
      "WH REFERENCE": "WHXX",
      "TREND": 0,
      "STD": 0.832050294337843,
      "MEAN": 0.23076923076923,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 4
    },
    {
      "PART NUMBER": 43904234,
      "DESCRIPTION": "AIR FILTER INSERT HP935WCU/SD100D 43931930",
      "PRODUCT CATEGORY": "VCG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.25,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 25,
      "WHXX": 1,
      "WH REFERENCE": "WHXX",
      "TREND": 0.67,
      "STD": 0.438529009653514,
      "MEAN": 0.23076923076923,
      "COV": 1.90029237516522,
      "SHELF LIFE_1": 4
    },
    {
      "PART NUMBER": 43908268,
      "DESCRIPTION": "FUEL GAUGE",
      "PRODUCT CATEGORY": "VCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 3,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 43911668,
      "DESCRIPTION": "IN-LINE FILTER FF5079 P550974",
      "PRODUCT CATEGORY": "VCG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.916666666666666,
      "MAINTANING STOCK QTY": 4,
      "AVAILABLE": 1,
      "SHELF LIFE": 1.0909090909090917,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 3,
      "APRD QTY": 3,
      "WH12": 24,
      "WHXX": 4,
      "WH REFERENCE": "WHXX",
      "TREND": 0.73,
      "STD": 1.34450448407296,
      "MEAN": 0.846153846153846,
      "COV": 1.5889598448135,
      "SHELF LIFE_1": 4.363636363636367
    },
    {
      "PART NUMBER": 43921394,
      "DESCRIPTION": "OIL ELEMENT SD100D HF6700",
      "PRODUCT CATEGORY": "VCG",
      "ITEM MOVEMENT BRANCH": "AVERAGE (F2)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 4.41666666666666,
      "MAINTANING STOCK QTY": 18,
      "AVAILABLE": 13,
      "SHELF LIFE": 2.943396226415099,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 5,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 29,
      "WH REFERENCE": "WHXX",
      "TREND": 0.53,
      "STD": 7.66443668794972,
      "MEAN": 4.07692307692307,
      "COV": 1.87995616874238,
      "SHELF LIFE_1": 4.075471698113214
    },
    {
      "PART NUMBER": 43922400,
      "DESCRIPTION": "HYD OIL FILTER SD160 HF6556 SD110B",
      "PRODUCT CATEGORY": "VCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 2,
      "SHELF LIFE": 24.00000000000001,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 34,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 24.00000000000001
    },
    {
      "PART NUMBER": 43923093,
      "DESCRIPTION": "FUEL FILTER 36846",
      "PRODUCT CATEGORY": "VCG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.5,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 3,
      "SHELF LIFE": 6,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 121,
      "WHXX": 58,
      "WH REFERENCE": "",
      "TREND": 1.33,
      "STD": 1.12660142429821,
      "MEAN": 0.461538461538461,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 6
    },
    {
      "PART NUMBER": 43924422,
      "DESCRIPTION": "FUEL FILTER, PRIM SD100D FF42000",
      "PRODUCT CATEGORY": "VCG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.583333333333333,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 3,
      "SHELF LIFE": 5.142857142857146,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 55,
      "WHXX": 99,
      "WH REFERENCE": "",
      "TREND": 1.14,
      "STD": 1.19828937903055,
      "MEAN": 0.538461538461538,
      "COV": 2.22539456105674,
      "SHELF LIFE_1": 5.142857142857146
    },
    {
      "PART NUMBER": 43927185,
      "DESCRIPTION": "FILTER INSERT DD80 43922285",
      "PRODUCT CATEGORY": "VCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.25,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 41,
      "WHXX": 15,
      "WH REFERENCE": "WHXX",
      "TREND": 0,
      "STD": 0.832050294337843,
      "MEAN": 0.23076923076923,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 4
    },
    {
      "PART NUMBER": 43931922,
      "DESCRIPTION": "ELEMENT A/F SD100D 35318252 AF1733K P182059",
      "PRODUCT CATEGORY": "VCG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.25,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 1,
      "SHELF LIFE": 4,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 3,
      "WH REFERENCE": "",
      "TREND": 0.67,
      "STD": 0.438529009653514,
      "MEAN": 0.23076923076923,
      "COV": 1.90029237516522,
      "SHELF LIFE_1": 4
    },
    {
      "PART NUMBER": 59058057,
      "DESCRIPTION": "CAP, FUEL",
      "PRODUCT CATEGORY": "VCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 59093351,
      "DESCRIPTION": "VENTING, DIESEL TANK SD110",
      "PRODUCT CATEGORY": "VCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.25,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 6,
      "WH REFERENCE": "WHXX",
      "TREND": 0,
      "STD": 0.832050294337843,
      "MEAN": 0.23076923076923,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 4
    },
    {
      "PART NUMBER": 59122085,
      "DESCRIPTION": "BRAKE VALVE DD80 SD110",
      "PRODUCT CATEGORY": "VCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 59155317,
      "DESCRIPTION": "SOLENOID, BRAKE & DESTROKE VALVE SD110",
      "PRODUCT CATEGORY": "VCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 2,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 59286336,
      "DESCRIPTION": "COUPLING PIECE SD100D-C / SD110D",
      "PRODUCT CATEGORY": "VCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 3,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 59309104,
      "DESCRIPTION": "FAN BELT SD100D/C 4BT 56\"",
      "PRODUCT CATEGORY": "VCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 29,
      "WHXX": 4,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 59524447,
      "DESCRIPTION": "STEM, VALVE 4275-5",
      "PRODUCT CATEGORY": "VCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 5,
      "WHXX": 6,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 59648543,
      "DESCRIPTION": "SWITCH",
      "PRODUCT CATEGORY": "VCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 2,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 96702329,
      "DESCRIPTION": "997953 CAPSCREW 96718887 ",
      "PRODUCT CATEGORY": "VCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 96738596,
      "DESCRIPTION": "FLAT WASHER",
      "PRODUCT CATEGORY": "VCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 8,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 48,
      "WHXX": 83,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 11419486,
      "DESCRIPTION": " TEMPERATURE SENSOR G930/A40F 14508670",
      "PRODUCT CATEGORY": "VGG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 3,
      "WH REFERENCE": "WHXX",
      "TREND": 1,
      "STD": 0.375533808099405,
      "MEAN": 0.153846153846153,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": "12119-20",
      "DESCRIPTION": "SHIM",
      "PRODUCT CATEGORY": "VGG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 12724048,
      "DESCRIPTION": "BOOSTER G930",
      "PRODUCT CATEGORY": "VGG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 12724306,
      "DESCRIPTION": "REP KIT_BLADE LIFT G710B/G930",
      "PRODUCT CATEGORY": "VGG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 1,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3,
      "WHXX": 3,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 12725151,
      "DESCRIPTION": "REPAIR KIT G930",
      "PRODUCT CATEGORY": "VGG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 11,
      "WHXX": 5,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 12729935,
      "DESCRIPTION": "WASHER, PLAIN (PLOW BOLT 3/4\") G710B/G930",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 20,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 70,
      "WHXX": 13,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 12742526,
      "DESCRIPTION": "FILTER, CAB G930",
      "PRODUCT CATEGORY": "VGG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 4,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 33,
      "WHXX": 22,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 12742527,
      "DESCRIPTION": "FILTER, CAB G930",
      "PRODUCT CATEGORY": "VGG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 2,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 11,
      "WHXX": 39,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 12743273,
      "DESCRIPTION": "COIL G930",
      "PRODUCT CATEGORY": "VGG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 12743408,
      "DESCRIPTION": "ELEMENT G930",
      "PRODUCT CATEGORY": "VGG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 3,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 29,
      "WHXX": 7,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 12744293,
      "DESCRIPTION": "PLATE G930",
      "PRODUCT CATEGORY": "VGG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3,
      "WHXX": 3,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 12745093,
      "DESCRIPTION": "END PANEL (5HOLES) 22\" MLBD 5/8\" BOLTS G930",
      "PRODUCT CATEGORY": "VGG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3,
      "WHXX": 4,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14016107,
      "DESCRIPTION": "RET. RING 19B-218",
      "PRODUCT CATEGORY": "VGG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 15,
      "WHXX": 14,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 19629,
      "DESCRIPTION": "184059 PLAIN BEARING",
      "PRODUCT CATEGORY": "VGG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 4,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 2010900046,
      "DESCRIPTION": "FUEL STRAINER, TANK LG936L",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 62,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 2030900065,
      "DESCRIPTION": "OIL STRAINER, TRANS. LG956L",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "AVERAGE (F2)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 1.41666666666666,
      "MAINTANING STOCK QTY": 6,
      "AVAILABLE": 6,
      "SHELF LIFE": 4.235294117647078,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 38,
      "WHXX": 18,
      "WH REFERENCE": "",
      "TREND": 1.18,
      "STD": 1.75045781557356,
      "MEAN": 1.3076923076923,
      "COV": 1.33858538837978,
      "SHELF LIFE_1": 4.235294117647078
    },
    {
      "PART NUMBER": 20792871,
      "DESCRIPTION": "GASKET G930",
      "PRODUCT CATEGORY": "VGG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3,
      "WHXX": 3,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 20798114,
      "DESCRIPTION": "UNIT INJECTOR ENG: D7E EAE2 EC290B",
      "PRODUCT CATEGORY": "VGG",
      "ITEM MOVEMENT BRANCH": "AVERAGE (F2)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 4.08333333333333,
      "MAINTANING STOCK QTY": 16,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 16,
      "APRD QTY": 16,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 1.18,
      "STD": 6.2470505861279,
      "MEAN": 3.76923076923076,
      "COV": 1.65738076774822,
      "SHELF LIFE_1": 3.9183673469387785
    },
    {
      "PART NUMBER": 20799064,
      "DESCRIPTION": "SEALING RING G930",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.833333333333333,
      "MAINTANING STOCK QTY": 3,
      "AVAILABLE": 5,
      "SHELF LIFE": 6.000000000000002,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 70,
      "WHXX": 233,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 1.92153784566104,
      "MEAN": 0.769230769230769,
      "COV": 2.49799919935935,
      "SHELF LIFE_1": 6.000000000000002
    },
    {
      "PART NUMBER": 20799719,
      "DESCRIPTION": "BOLT G930",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.833333333333333,
      "MAINTANING STOCK QTY": 3,
      "AVAILABLE": 3,
      "SHELF LIFE": 3.6000000000000014,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 6,
      "WHXX": 9,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 1.92153784566104,
      "MEAN": 0.769230769230769,
      "COV": 2.49799919935935,
      "SHELF LIFE_1": 3.6000000000000014
    },
    {
      "PART NUMBER": 21147445,
      "DESCRIPTION": "HIGH-PRESSURE PUMP L120F 20795413",
      "PRODUCT CATEGORY": "VGG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.833333333333333,
      "MAINTANING STOCK QTY": 3,
      "AVAILABLE": 2,
      "SHELF LIFE": 2.400000000000001,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 1.2,
      "STD": 1.5358952955766,
      "MEAN": 0.769230769230769,
      "COV": 1.99666388424959,
      "SHELF LIFE_1": 3.6000000000000014
    },
    {
      "PART NUMBER": 21342980,
      "DESCRIPTION": "23479910 GASKET, TURBOCHARGER 20792408",
      "PRODUCT CATEGORY": "VGG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 2,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 2,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 29070000081,
      "DESCRIPTION": "RIM NUT   ",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 16.1666666666666,
      "MAINTANING STOCK QTY": 65,
      "AVAILABLE": 52,
      "SHELF LIFE": 3.2164948453608377,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 13,
      "APRD QTY": 13,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 1.69,
      "STD": 39.518901676838,
      "MEAN": 14.9230769230769,
      "COV": 2.64817382370564,
      "SHELF LIFE_1": 4.020618556701048
    },
    {
      "PART NUMBER": 29070000621,
      "DESCRIPTION": "RIM BOLT  ",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 17.0833333333333,
      "MAINTANING STOCK QTY": 68,
      "AVAILABLE": 44,
      "SHELF LIFE": 2.575609756097566,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 24,
      "APRD QTY": 24,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 1.9,
      "STD": 39.7537290623027,
      "MEAN": 15.7692307692307,
      "COV": 2.52096818443871,
      "SHELF LIFE_1": 3.9804878048780563
    },
    {
      "PART NUMBER": 29100000061,
      "DESCRIPTION": "OIL STRAINER, HYDRAULIC",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "AVERAGE (F2)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 1.16666666666666,
      "MAINTANING STOCK QTY": 5,
      "AVAILABLE": 5,
      "SHELF LIFE": 4.28571428571431,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 113,
      "WHXX": 47,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 2.62873665669583,
      "MEAN": 1.07692307692307,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 4.28571428571431
    },
    {
      "PART NUMBER": 29100004061,
      "DESCRIPTION": "FILTER INSERT  LG938",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.916666666666666,
      "MAINTANING STOCK QTY": 4,
      "AVAILABLE": 3,
      "SHELF LIFE": 3.272727272727275,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 0,
      "WHXX": 7,
      "WH REFERENCE": "WHXX",
      "TREND": 1.27,
      "STD": 0.898717034272917,
      "MEAN": 0.846153846153846,
      "COV": 1.06212013141344,
      "SHELF LIFE_1": 4.363636363636367
    },
    {
      "PART NUMBER": 29170019701,
      "DESCRIPTION": "BOLT, CORNER LG958",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 12.3333333333333,
      "MAINTANING STOCK QTY": 49,
      "AVAILABLE": 45,
      "SHELF LIFE": 3.6486486486486585,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 4,
      "APRD QTY": 0,
      "WH12": 55,
      "WHXX": 3,
      "WH REFERENCE": "WH12 + WHXX",
      "TREND": 0.92,
      "STD": 24.1023458800814,
      "MEAN": 11.3846153846153,
      "COV": 2.11709794892606,
      "SHELF LIFE_1": 3.9729729729729835
    },
    {
      "PART NUMBER": 29170019711,
      "DESCRIPTION": "BOLT, BUCKET EDGES  LG958",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "AVERAGE (F2)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 40.0833333333333,
      "MAINTANING STOCK QTY": 160,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 160,
      "APRD QTY": 160,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 1.34,
      "STD": 41.6112965431263,
      "MEAN": 37,
      "COV": 1.12462963630071,
      "SHELF LIFE_1": 3.991683991683995
    },
    {
      "PART NUMBER": 29170020531,
      "DESCRIPTION": "SPACER, BUCKET TOOTH  LG956 / LG958",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "AVERAGE (F2)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 19.8333333333333,
      "MAINTANING STOCK QTY": 79,
      "AVAILABLE": 74,
      "SHELF LIFE": 3.731092436974796,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 5,
      "APRD QTY": 5,
      "WH12": 30,
      "WHXX": 7,
      "WH REFERENCE": "WHXX",
      "TREND": 1.33,
      "STD": 26.7157401026205,
      "MEAN": 18.3076923076923,
      "COV": 1.45926311484902,
      "SHELF LIFE_1": 3.983193277310931
    },
    {
      "PART NUMBER": 29170036961,
      "DESCRIPTION": "CORNER TEETH RH, BUCKET  ",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 1,
      "SHELF LIFE": 12.000000000000005,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 1,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 12.000000000000005
    },
    {
      "PART NUMBER": 29170036971,
      "DESCRIPTION": "TEETH, BUCKET  ",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 5,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 36,
      "WHXX": 17,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 29170036981,
      "DESCRIPTION": "CORNER TEETH LH, BUCKET  ",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 1,
      "SHELF LIFE": 12.000000000000005,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 1,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 12.000000000000005
    },
    {
      "PART NUMBER": 29170039941,
      "DESCRIPTION": "TOOTH,  BUCKET  LG956 / LG958",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "AVERAGE (F2)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 10.3333333333333,
      "MAINTANING STOCK QTY": 41,
      "AVAILABLE": 11,
      "SHELF LIFE": 1.0645161290322616,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 30,
      "APRD QTY": 30,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 1.61,
      "STD": 14.0452565220159,
      "MEAN": 9.53846153846153,
      "COV": 1.4724865708565,
      "SHELF LIFE_1": 3.967741935483884
    },
    {
      "PART NUMBER": 29170039951,
      "DESCRIPTION": "SIDE CUTTER  LH, BUCKET  LG956 / LG958 29170019681",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 2.16666666666666,
      "MAINTANING STOCK QTY": 9,
      "AVAILABLE": 5,
      "SHELF LIFE": 2.307692307692315,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 4,
      "APRD QTY": 4,
      "WH12": 0,
      "WHXX": 2,
      "WH REFERENCE": "PARTIAL WHXX",
      "TREND": 1.69,
      "STD": 3.34165627596057,
      "MEAN": 2,
      "COV": 1.67082813798028,
      "SHELF LIFE_1": 4.153846153846167
    },
    {
      "PART NUMBER": 29170039961,
      "DESCRIPTION": "SIDE CUTTER  RH, BUCKET  LG956 / LG958 29170019691",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 2.08333333333333,
      "MAINTANING STOCK QTY": 8,
      "AVAILABLE": 6,
      "SHELF LIFE": 2.880000000000005,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 2,
      "APRD QTY": 2,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 1.68,
      "STD": 3.14805597317716,
      "MEAN": 1.92307692307692,
      "COV": 1.63698910605212,
      "SHELF LIFE_1": 3.840000000000006
    },
    {
      "PART NUMBER": 29250000061,
      "DESCRIPTION": "SPACER, FRNT FRAME  ",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 16,
      "WHXX": 5,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 29250000071,
      "DESCRIPTION": "SPACER, FRNT FRAME  ",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 2,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 29250000081,
      "DESCRIPTION": "SPACER, FRNT FRAME  ",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 46,
      "WHXX": 39,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4013000016,
      "DESCRIPTION": "NUT",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 1.16666666666666,
      "MAINTANING STOCK QTY": 5,
      "AVAILABLE": 7,
      "SHELF LIFE": 6.000000000000034,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 40,
      "WHXX": 30,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 3.8829013735766,
      "MEAN": 1.07692307692307,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000034
    },
    {
      "PART NUMBER": 4013000020,
      "DESCRIPTION": "NUT ",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "AVERAGE (F2)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 39.3333333333333,
      "MAINTANING STOCK QTY": 157,
      "AVAILABLE": 38,
      "SHELF LIFE": 0.9661016949152551,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 119,
      "APRD QTY": 119,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 1.2,
      "STD": 37.0211304873865,
      "MEAN": 36.3076923076923,
      "COV": 1.01964978037293,
      "SHELF LIFE_1": 3.991525423728817
    },
    {
      "PART NUMBER": 4015000001,
      "DESCRIPTION": "WASHER ",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 1.16666666666666,
      "MAINTANING STOCK QTY": 5,
      "AVAILABLE": 7,
      "SHELF LIFE": 6.000000000000034,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 21,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 3.8829013735766,
      "MEAN": 1.07692307692307,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000034
    },
    {
      "PART NUMBER": 4015000176,
      "DESCRIPTION": "WASHER ",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 17.3333333333333,
      "MAINTANING STOCK QTY": 69,
      "AVAILABLE": 67,
      "SHELF LIFE": 3.865384615384623,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 2,
      "APRD QTY": 0,
      "WH12": 62,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 0.63,
      "STD": 24.4404037064311,
      "MEAN": 16,
      "COV": 1.52752523165194,
      "SHELF LIFE_1": 3.9807692307692384
    },
    {
      "PART NUMBER": 4021000058,
      "DESCRIPTION": "BEARING, FRONT FRAME  LG956L/LG958",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 11,
      "WHXX": 1,
      "WH REFERENCE": "WHXX",
      "TREND": 0,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": 4030000048,
      "DESCRIPTION": "SKELETON OIL SEAL ",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4040000011,
      "DESCRIPTION": "BOLT, BUCKET ",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 10,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 20,
      "WHXX": 10,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4043000033,
      "DESCRIPTION": "SPACE LG958",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 17,
      "WHXX": 8,
      "WH REFERENCE": "WHXX",
      "TREND": 0,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": 4043000034,
      "DESCRIPTION": "SPACE LG958",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 14,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4043000055,
      "DESCRIPTION": "PUSTRING LG958",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.666666666666666,
      "MAINTANING STOCK QTY": 3,
      "AVAILABLE": 1,
      "SHELF LIFE": 1.5000000000000016,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 2,
      "APRD QTY": 2,
      "WH12": 16,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 2,
      "STD": 1.50213523239762,
      "MEAN": 0.615384615384615,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 4.500000000000004
    },
    {
      "PART NUMBER": 4043000056,
      "DESCRIPTION": "PUSTRING LG958",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 1.5,
      "MAINTANING STOCK QTY": 6,
      "AVAILABLE": 4,
      "SHELF LIFE": 2.6666666666666665,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 2,
      "APRD QTY": 2,
      "WH12": 34,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 1.33,
      "STD": 3.59486813709166,
      "MEAN": 1.38461538461538,
      "COV": 2.5962936545662,
      "SHELF LIFE_1": 4
    },
    {
      "PART NUMBER": 4043000057,
      "DESCRIPTION": "PUSTRING, FRONT FRAME  ",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.833333333333333,
      "MAINTANING STOCK QTY": 3,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 3,
      "APRD QTY": 3,
      "WH12": 5,
      "WHXX": 3,
      "WH REFERENCE": "WHXX",
      "TREND": 1.6,
      "STD": 2.24179415327122,
      "MEAN": 0.769230769230769,
      "COV": 2.91433239925258,
      "SHELF LIFE_1": 3.6000000000000014
    },
    {
      "PART NUMBER": 4043000058,
      "DESCRIPTION": "PUSTRING ",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.5,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 2,
      "APRD QTY": 2,
      "WH12": 10,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 1.33,
      "STD": 1.19828937903055,
      "MEAN": 0.461538461538461,
      "COV": 2.5962936545662,
      "SHELF LIFE_1": 4
    },
    {
      "PART NUMBER": 4043000059,
      "DESCRIPTION": "O-RING ",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 1.66666666666666,
      "MAINTANING STOCK QTY": 7,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 7,
      "APRD QTY": 0,
      "WH12": 42,
      "WHXX": 3,
      "WH REFERENCE": "WH12 + WHXX",
      "TREND": 0.8,
      "STD": 3.07179059115321,
      "MEAN": 1.53846153846153,
      "COV": 1.99666388424959,
      "SHELF LIFE_1": 4.200000000000016
    },
    {
      "PART NUMBER": 4043000060,
      "DESCRIPTION": "PIN LG958",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 4,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4043000173,
      "DESCRIPTION": "PIN ",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.5,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 2,
      "APRD QTY": 0,
      "WH12": 9,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 0.67,
      "STD": 0.877058019307029,
      "MEAN": 0.461538461538461,
      "COV": 1.90029237516522,
      "SHELF LIFE_1": 4
    },
    {
      "PART NUMBER": 4043000337,
      "DESCRIPTION": "BOLT",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 1,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 52,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4110000076368,
      "DESCRIPTION": "OIL FILTER, SPIN-ON LG938 7200001778",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "AVERAGE (F2)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 1.08333333333333,
      "MAINTANING STOCK QTY": 4,
      "AVAILABLE": 1,
      "SHELF LIFE": 0.923076923076926,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 3,
      "APRD QTY": 3,
      "WH12": 32,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 1.38,
      "STD": 1.2909944487358,
      "MEAN": 1,
      "COV": 1.2909944487358,
      "SHELF LIFE_1": 3.692307692307704
    },
    {
      "PART NUMBER": 4110000081265,
      "DESCRIPTION": "FUEL FILTER LG936 ",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 9,
      "SHELF LIFE": 27.00000000000003,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 191,
      "WHXX": 16,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 1.10940039245045,
      "MEAN": 0.307692307692307,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 27.00000000000003
    },
    {
      "PART NUMBER": 4110000081312,
      "DESCRIPTION": "FILTER FWS LG936/LG956 7200002428",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 8,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 10,
      "WHXX": 3,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4110000179020,
      "DESCRIPTION": "OIL FILTER LG936L",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 1,
      "SHELF LIFE": 3.000000000000003,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 54,
      "WHXX": 24,
      "WH REFERENCE": "",
      "TREND": 1,
      "STD": 0.480384461415261,
      "MEAN": 0.307692307692307,
      "COV": 1.56124949959959,
      "SHELF LIFE_1": 3.000000000000003
    },
    {
      "PART NUMBER": 4110000556209,
      "DESCRIPTION": "OIL FILTER  LG956L",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "FAST (F1)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 21.5,
      "MAINTANING STOCK QTY": 86,
      "AVAILABLE": 6,
      "SHELF LIFE": 0.27906976744186046,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 80,
      "APRD QTY": 80,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 1.4,
      "STD": 24.4057580427452,
      "MEAN": 19.8461538461538,
      "COV": 1.22974749827786,
      "SHELF LIFE_1": 4
    },
    {
      "PART NUMBER": 4110000589001,
      "DESCRIPTION": "FUEL FILTER",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "FAST (F1)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 29.4166666666666,
      "MAINTANING STOCK QTY": 118,
      "AVAILABLE": 88,
      "SHELF LIFE": 2.9915014164306015,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 30,
      "APRD QTY": 30,
      "WH12": 48,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 1.1,
      "STD": 30.4051699382581,
      "MEAN": 27.1538461538461,
      "COV": 1.11973713653642,
      "SHELF LIFE_1": 4.011331444759215
    },
    {
      "PART NUMBER": 4110000589016,
      "DESCRIPTION": "AIR FILTER KIT LG956L",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.833333333333333,
      "MAINTANING STOCK QTY": 3,
      "AVAILABLE": 4,
      "SHELF LIFE": 4.800000000000002,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 79,
      "WHXX": 2,
      "WH REFERENCE": "",
      "TREND": 0.8,
      "STD": 1.09192842819833,
      "MEAN": 0.769230769230769,
      "COV": 1.41950695665783,
      "SHELF LIFE_1": 4.800000000000002
    },
    {
      "PART NUMBER": 4110000613,
      "DESCRIPTION": "FILTER FILTER, FUEL TANK CAP LG956L/LG936L",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.25,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 49,
      "WHXX": 3,
      "WH REFERENCE": "WHXX",
      "TREND": 1.33,
      "STD": 0.438529009653514,
      "MEAN": 0.23076923076923,
      "COV": 1.90029237516522,
      "SHELF LIFE_1": 4
    },
    {
      "PART NUMBER": 4110000679001,
      "DESCRIPTION": "14405944 AIR FILTER, OUTER LG936L ",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "AVERAGE (F2)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 2.5,
      "MAINTANING STOCK QTY": 10,
      "AVAILABLE": 1,
      "SHELF LIFE": 0.4,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 9,
      "APRD QTY": 9,
      "WH12": 137,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 1.13,
      "STD": 1.54836555678428,
      "MEAN": 2.3076923076923,
      "COV": 0.670958407939855,
      "SHELF LIFE_1": 4
    },
    {
      "PART NUMBER": 4110000679002,
      "DESCRIPTION": "AIR FILTER, INNER LG936L 14405945",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.5,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 1,
      "SHELF LIFE": 2,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 56,
      "WHXX": 3,
      "WH REFERENCE": "WHXX",
      "TREND": 1.33,
      "STD": 0.660225291773524,
      "MEAN": 0.461538461538461,
      "COV": 1.43048813217597,
      "SHELF LIFE_1": 4
    },
    {
      "PART NUMBER": 4110001016004,
      "DESCRIPTION": "FILTER, FWS  LG956L 4110000112006",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "FAST (F1)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 12.0833333333333,
      "MAINTANING STOCK QTY": 48,
      "AVAILABLE": 35,
      "SHELF LIFE": 2.896551724137939,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 13,
      "APRD QTY": 13,
      "WH12": 9,
      "WHXX": 0,
      "WH REFERENCE": "PARTIAL WH12",
      "TREND": 1.42,
      "STD": 12.4153544307452,
      "MEAN": 11.1538461538461,
      "COV": 1.11310074206681,
      "SHELF LIFE_1": 3.9724137931034593
    },
    {
      "PART NUMBER": 4120000065,
      "DESCRIPTION": "SAFETY VALVE LG958",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 1.33333333333333,
      "MAINTANING STOCK QTY": 5,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 5,
      "APRD QTY": 5,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 2,
      "STD": 4.43760156980183,
      "MEAN": 1.23076923076923,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 3.7500000000000098
    },
    {
      "PART NUMBER": 4120000066,
      "DESCRIPTION": "WATER DRAIN VALVE ",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 7,
      "WHXX": 2,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4120000084,
      "DESCRIPTION": "AIR DRYER (ST-50G) LG956/LG958",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.75,
      "MAINTANING STOCK QTY": 3,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 3,
      "APRD QTY": 0,
      "WH12": 45,
      "WHXX": 1,
      "WH REFERENCE": "WH12 + WHXX",
      "TREND": 0.67,
      "STD": 0.75106761619881,
      "MEAN": 0.692307692307692,
      "COV": 1.0848754456205,
      "SHELF LIFE_1": 4
    },
    {
      "PART NUMBER": 4120000094,
      "DESCRIPTION": "OIL LEVER METER ",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 15,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4120000759,
      "DESCRIPTION": "BRAKE VALVE ",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.416666666666666,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 2,
      "APRD QTY": 2,
      "WH12": 11,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 2,
      "STD": 1.38675049056307,
      "MEAN": 0.384615384615384,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 4.800000000000008
    },
    {
      "PART NUMBER": 4120000760,
      "DESCRIPTION": "PRESSURE SWITCH ",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 4,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4120001739,
      "DESCRIPTION": "DISK BRAKE CALIPER ",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 3.91666666666666,
      "MAINTANING STOCK QTY": 16,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 16,
      "APRD QTY": 16,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 1.83,
      "STD": 9.10621089823187,
      "MEAN": 3.61538461538461,
      "COV": 2.51873918461732,
      "SHELF LIFE_1": 4.08510638297873
    },
    {
      "PART NUMBER": 4120001739016,
      "DESCRIPTION": "BRAKE PLATE LG938 LG956",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "AVERAGE (F2)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 30,
      "MAINTANING STOCK QTY": 120,
      "AVAILABLE": 5,
      "SHELF LIFE": 0.16666666666666666,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 115,
      "APRD QTY": 115,
      "WH12": 0,
      "WHXX": 2,
      "WH REFERENCE": "PARTIAL WHXX",
      "TREND": 1.38,
      "STD": 29.187282091648,
      "MEAN": 27.6923076923076,
      "COV": 1.05398518664284,
      "SHELF LIFE_1": 4
    },
    {
      "PART NUMBER": 4120001954001,
      "DESCRIPTION": "FILTER INSERT, HYDRAULIC",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "FAST (F1)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 6.75,
      "MAINTANING STOCK QTY": 27,
      "AVAILABLE": 26,
      "SHELF LIFE": 3.8518518518518516,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 112,
      "WHXX": 5,
      "WH REFERENCE": "WHXX",
      "TREND": 1.53,
      "STD": 8.02240452476527,
      "MEAN": 6.23076923076923,
      "COV": 1.28754640520924,
      "SHELF LIFE_1": 4
    },
    {
      "PART NUMBER": 4130000009,
      "DESCRIPTION": "HORN DL125-24V  LG956L",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 8,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4130000542,
      "DESCRIPTION": "LEFT FRONT HEAD LAMP LFLDH4-24V  LG956L/LG936",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.25,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 1,
      "SHELF LIFE": 4,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0.67,
      "STD": 0.438529009653514,
      "MEAN": 0.23076923076923,
      "COV": 1.90029237516522,
      "SHELF LIFE_1": 4
    },
    {
      "PART NUMBER": 4130000962,
      "DESCRIPTION": "RELAY SHUT-OFF SOLENOID JCC100/IC24-48  LG956L",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 5,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4190000153001,
      "DESCRIPTION": "FUEL FILTER SEP.  LG936L",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 9,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 46,
      "WHXX": 21,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4341,
      "DESCRIPTION": "BREATHER",
      "PRODUCT CATEGORY": "VGG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 20,
      "WHXX": 10,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 53063,
      "DESCRIPTION": "CAPSCREW-BRASS G930",
      "PRODUCT CATEGORY": "VGG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 4,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 20,
      "WHXX": 29,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 54934,
      "DESCRIPTION": "PIN-BRASS-CIRCL G930",
      "PRODUCT CATEGORY": "VGG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 8,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 67,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 60100000,
      "DESCRIPTION": "ELECTRONIC UNIT E-ECU G930 EC210B EC290B",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.5,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 2,
      "SHELF LIFE": 4,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 6,
      "WHXX": 2,
      "WH REFERENCE": "",
      "TREND": 1.67,
      "STD": 0.660225291773524,
      "MEAN": 0.461538461538461,
      "COV": 1.43048813217597,
      "SHELF LIFE_1": 4
    },
    {
      "PART NUMBER": 69510,
      "DESCRIPTION": "SNAP RING-EXT-H G930",
      "PRODUCT CATEGORY": "VGG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 12,
      "WHXX": 5,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 69772,
      "DESCRIPTION": "CRANK ARM G930",
      "PRODUCT CATEGORY": "VGG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 69779,
      "DESCRIPTION": "SPACER-PINION G930",
      "PRODUCT CATEGORY": "VGG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 6,
      "WHXX": 4,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 69781,
      "DESCRIPTION": "WEAR PLATE G930",
      "PRODUCT CATEGORY": "VGG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 5,
      "WHXX": 2,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 75439,
      "DESCRIPTION": "CUTTING EDGE 5/8\" X 6\" X 7FT 15 (5/8\") HOLES",
      "PRODUCT CATEGORY": "VGG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 5,
      "WHXX": 3,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 75442,
      "DESCRIPTION": "CUTTING EDGE 1\" X 8\" X 7FT 15(5/8\") HOLES",
      "PRODUCT CATEGORY": "VGG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 4,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 76282,
      "DESCRIPTION": "MASTER CYLINDER G930",
      "PRODUCT CATEGORY": "VGG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 76288,
      "DESCRIPTION": "FLOW SWITCH G930",
      "PRODUCT CATEGORY": "VGG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 8111,
      "DESCRIPTION": "16015376 SCARIFIER TIP G930 75703",
      "PRODUCT CATEGORY": "VGG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 87022,
      "DESCRIPTION": "DURAMIDE G930",
      "PRODUCT CATEGORY": "VGG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 2,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 983336,
      "DESCRIPTION": "WASHER",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 10,
      "WHXX": 8,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 984507,
      "DESCRIPTION": "PLOW BOLT (5/8\"X2 1/2) 0.63\"NC X40F G930",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 20,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 44,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 990202,
      "DESCRIPTION": "HEX. SOCKET SCREW G930",
      "PRODUCT CATEGORY": "VGG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 16,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 990427,
      "DESCRIPTION": "NIPPLE G930",
      "PRODUCT CATEGORY": "VGG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 991039,
      "DESCRIPTION": "HEX NUT, PLOW BOLT (5/8\"X2 1/2) 30A-10ZI G930 ",
      "PRODUCT CATEGORY": "VGG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 20,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 160,
      "WHXX": 58,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 11000233,
      "DESCRIPTION": "O-RING",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 5,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 11026989,
      "DESCRIPTION": "16854341 STEERING DAMPER, FRAME  A30/L120/L150F",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 11033996,
      "DESCRIPTION": "AIR FILTER, PRIMARY",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 65,
      "WHXX": 12,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 11033997,
      "DESCRIPTION": "FILTER, SAFETY 15193228 ",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 59,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 1,
      "STD": 0.375533808099405,
      "MEAN": 0.153846153846153,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": 11033998,
      "DESCRIPTION": "AIR FILTER, PRIMARY",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "AVERAGE (F2)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 1.58333333333333,
      "MAINTANING STOCK QTY": 6,
      "AVAILABLE": 4,
      "SHELF LIFE": 2.52631578947369,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 2,
      "APRD QTY": 2,
      "WH12": 23,
      "WHXX": 9,
      "WH REFERENCE": "WHXX",
      "TREND": 1.16,
      "STD": 1.9414506867883,
      "MEAN": 1.46153846153846,
      "COV": 1.32836099622357,
      "SHELF LIFE_1": 3.7894736842105345
    },
    {
      "PART NUMBER": 11033999,
      "DESCRIPTION": "AIR FILTER, SAFETY  15193226 ",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.916666666666666,
      "MAINTANING STOCK QTY": 4,
      "AVAILABLE": 1,
      "SHELF LIFE": 1.0909090909090917,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 3,
      "APRD QTY": 3,
      "WH12": 53,
      "WHXX": 1,
      "WH REFERENCE": "WH12 + WHXX",
      "TREND": 0.91,
      "STD": 1.62512326948623,
      "MEAN": 0.846153846153846,
      "COV": 1.92060022757464,
      "SHELF LIFE_1": 4.363636363636367
    },
    {
      "PART NUMBER": 11035001,
      "DESCRIPTION": "BEARING",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.25,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 2,
      "STD": 0.832050294337843,
      "MEAN": 0.23076923076923,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 4
    },
    {
      "PART NUMBER": 11035819,
      "DESCRIPTION": "PLANET GEAR",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.25,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 2,
      "STD": 0.832050294337843,
      "MEAN": 0.23076923076923,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 4
    },
    {
      "PART NUMBER": 11039553,
      "DESCRIPTION": "TAIL LAMP L150F",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 1,
      "SHELF LIFE": 3.000000000000003,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 11,
      "WHXX": 4,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.75106761619881,
      "MEAN": 0.307692307692307,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 3.000000000000003
    },
    {
      "PART NUMBER": 11039563,
      "DESCRIPTION": "B-UP WRN UNIT L70/120C/G930",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 1,
      "SHELF LIFE": 6.000000000000024,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 16,
      "WHXX": 15,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.375533808099405,
      "MEAN": 0.153846153846153,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": 11039846,
      "DESCRIPTION": "WORK LAMP_70W/24V A30/EC210B/EC460B/L60/70E",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 1.66666666666666,
      "MAINTANING STOCK QTY": 7,
      "AVAILABLE": 7,
      "SHELF LIFE": 4.200000000000016,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 60,
      "WHXX": 29,
      "WH REFERENCE": "",
      "TREND": 0.5,
      "STD": 2.6650636207348,
      "MEAN": 1.53846153846153,
      "COV": 1.73229135347762,
      "SHELF LIFE_1": 4.200000000000016
    },
    {
      "PART NUMBER": 11039856,
      "DESCRIPTION": "REAR WORKING LIGHT",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 9,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 11056114,
      "DESCRIPTION": "SPHERE BEARING A30/35C/A40F",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 1,
      "STD": 0.75106761619881,
      "MEAN": 0.307692307692307,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 3.000000000000003
    },
    {
      "PART NUMBER": 11081029,
      "DESCRIPTION": "SQ NECK SCREW L120F",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 1.58333333333333,
      "MAINTANING STOCK QTY": 6,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 6,
      "APRD QTY": 6,
      "WH12": 36,
      "WHXX": 29,
      "WH REFERENCE": "WHXX",
      "TREND": 2,
      "STD": 5.26965186413967,
      "MEAN": 1.46153846153846,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 3.7894736842105345
    },
    {
      "PART NUMBER": 11100354,
      "DESCRIPTION": "NUT CAP L150F",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 10,
      "MAINTANING STOCK QTY": 40,
      "AVAILABLE": 23,
      "SHELF LIFE": 2.3,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 17,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 250,
      "WH REFERENCE": "WHXX",
      "TREND": 0,
      "STD": 33.2820117735137,
      "MEAN": 9.23076923076923,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 4
    },
    {
      "PART NUMBER": 11103303,
      "DESCRIPTION": "O-RING",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": 11110022,
      "DESCRIPTION": "AIR FILTER, PRIMARY 15193225",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.75,
      "MAINTANING STOCK QTY": 3,
      "AVAILABLE": 11,
      "SHELF LIFE": 14.666666666666666,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 57,
      "WHXX": 53,
      "WH REFERENCE": "",
      "TREND": 0.67,
      "STD": 0.75106761619881,
      "MEAN": 0.692307692307692,
      "COV": 1.0848754456205,
      "SHELF LIFE_1": 14.666666666666666
    },
    {
      "PART NUMBER": 11110023,
      "DESCRIPTION": "SAFETY FILTER, INNER 15193225",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.25,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 15,
      "SHELF LIFE": 60,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 153,
      "WHXX": 17,
      "WH REFERENCE": "",
      "TREND": 1.33,
      "STD": 0.438529009653514,
      "MEAN": 0.23076923076923,
      "COV": 1.90029237516522,
      "SHELF LIFE_1": 60
    },
    {
      "PART NUMBER": 11110175,
      "DESCRIPTION": "AIR FILTER, PRIM 15193224",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "FAST (F1)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 7.33333333333333,
      "MAINTANING STOCK QTY": 29,
      "AVAILABLE": 2,
      "SHELF LIFE": 0.2727272727272728,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 27,
      "APRD QTY": 27,
      "WH12": 366,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 0.98,
      "STD": 4.76364437088954,
      "MEAN": 6.76923076923076,
      "COV": 0.703720191154138,
      "SHELF LIFE_1": 3.9545454545454564
    },
    {
      "PART NUMBER": 11110176,
      "DESCRIPTION": "AIR FILTER, SAFETY",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "FAST (F1)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 4.5,
      "MAINTANING STOCK QTY": 18,
      "AVAILABLE": 6,
      "SHELF LIFE": 1.3333333333333333,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 12,
      "APRD QTY": 12,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "PARTIAL WH12",
      "TREND": 0.7,
      "STD": 4.86220378439917,
      "MEAN": 4.15384615384615,
      "COV": 1.17053054068868,
      "SHELF LIFE_1": 4
    },
    {
      "PART NUMBER": 11110668,
      "DESCRIPTION": "PRIMARY FILTER,  FWS",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "FAST (F1)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 4.58333333333333,
      "MAINTANING STOCK QTY": 18,
      "AVAILABLE": 4,
      "SHELF LIFE": 0.8727272727272732,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 14,
      "APRD QTY": 14,
      "WH12": 3,
      "WHXX": 51,
      "WH REFERENCE": "WHXX",
      "TREND": 0.8,
      "STD": 4.53052326179228,
      "MEAN": 4.23076923076923,
      "COV": 1.07085095278726,
      "SHELF LIFE_1": 3.92727272727273
    },
    {
      "PART NUMBER": 11110683,
      "DESCRIPTION": "PRIMARY FILTER, FWS (X6)",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "FAST (F1)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 32.3333333333333,
      "MAINTANING STOCK QTY": 129,
      "AVAILABLE": 115,
      "SHELF LIFE": 3.556701030927839,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 14,
      "APRD QTY": 14,
      "WH12": 165,
      "WHXX": 125,
      "WH REFERENCE": "WHXX",
      "TREND": 0.86,
      "STD": 16.0667677409311,
      "MEAN": 29.8461538461538,
      "COV": 0.53831953771161,
      "SHELF LIFE_1": 3.9896907216494886
    },
    {
      "PART NUMBER": 11110709,
      "DESCRIPTION": "WATER SEPARATOR W/O HEATER  EC210B  EW145B 11110703_",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.416666666666666,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 2,
      "SHELF LIFE": 4.800000000000008,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 4,
      "WHXX": 5,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.869718492622904,
      "MEAN": 0.384615384615384,
      "COV": 2.26126808081955,
      "SHELF LIFE_1": 4.800000000000008
    },
    {
      "PART NUMBER": 11117464,
      "DESCRIPTION": "60114321 WASHER A40F",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 9,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 11121152,
      "DESCRIPTION": "REAR VIEW MIRROR 11007435 11057974",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 4,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 11142033,
      "DESCRIPTION": "WEAR PLATE L120E",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 2,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 11142263,
      "DESCRIPTION": "SEGMENT, BUCKET L=260MM (VOE93899) 2.88M  L120F/G",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 4,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 11147756,
      "DESCRIPTION": "SIGN LAMP L60/120E/BL71",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 2,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 11,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 11164457,
      "DESCRIPTION": "RECEIVER DRIER A35D/L150F",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 1,
      "SHELF LIFE": 6.000000000000024,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 14,
      "WHXX": 5,
      "WH REFERENCE": "",
      "TREND": 1,
      "STD": 0.375533808099405,
      "MEAN": 0.153846153846153,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": 11168079,
      "DESCRIPTION": "SUN GEAR",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 11170009,
      "DESCRIPTION": "WORK LAMP L70D/L120D/L150F  6627847",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 15,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": 11170064,
      "DESCRIPTION": "LEVEL GUARD",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 6,
      "WHXX": 1,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 11170101,
      "DESCRIPTION": "ROTATING BEACON EC210B 2174120",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3,
      "WHXX": 3,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 11172907,
      "DESCRIPTION": "AIR FILTER, FUEL TANK BREATHER 8152009  (X12)",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "FAST (F1)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 13.0833333333333,
      "MAINTANING STOCK QTY": 52,
      "AVAILABLE": 57,
      "SHELF LIFE": 4.356687898089183,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 10,
      "WH REFERENCE": "",
      "TREND": 0.78,
      "STD": 11.3978180547969,
      "MEAN": 12.076923076923,
      "COV": 0.943768373964082,
      "SHELF LIFE_1": 4.356687898089183
    },
    {
      "PART NUMBER": 11204584,
      "DESCRIPTION": "REAR VIEW MIRROR EC210B-P/EC460B",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.5,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 2,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 13,
      "WH REFERENCE": "WHXX",
      "TREND": 0,
      "STD": 1.66410058867568,
      "MEAN": 0.461538461538461,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 4
    },
    {
      "PART NUMBER": 11417144,
      "DESCRIPTION": "LOCKING DEVICE 10P VTS L70F",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 9,
      "WHXX": 14,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 11417145,
      "DESCRIPTION": "LOCKING DEVICE 15P  EC/EW145/B  L120F",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.416666666666666,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 5,
      "SHELF LIFE": 12.000000000000018,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 35,
      "WHXX": 21,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 1.38675049056307,
      "MEAN": 0.384615384615384,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 12.000000000000018
    },
    {
      "PART NUMBER": 11417147,
      "DESCRIPTION": "LOCKING DEVICE 30P (VTS) EC210B/EC240B",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 4,
      "MAINTANING STOCK QTY": 16,
      "AVAILABLE": 67,
      "SHELF LIFE": 16.75,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 35,
      "WH REFERENCE": "",
      "TREND": 1.29,
      "STD": 4.25018853277152,
      "MEAN": 3.69230769230769,
      "COV": 1.15109272762562,
      "SHELF LIFE_1": 16.75
    },
    {
      "PART NUMBER": 11445025,
      "DESCRIPTION": "FILTER CARTRIDGE, HYDRAULIC",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.5,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 2,
      "APRD QTY": 2,
      "WH12": 0,
      "WHXX": 5,
      "WH REFERENCE": "WHXX",
      "TREND": 0.67,
      "STD": 0.877058019307029,
      "MEAN": 0.461538461538461,
      "COV": 1.90029237516522,
      "SHELF LIFE_1": 4
    },
    {
      "PART NUMBER": 11448509,
      "DESCRIPTION": "FILTER CARTRIDGE, TRANS 11036607",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 2.08333333333333,
      "MAINTANING STOCK QTY": 8,
      "AVAILABLE": 14,
      "SHELF LIFE": 6.720000000000011,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 24,
      "WHXX": 14,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 3.17441696645589,
      "MEAN": 1.92307692307692,
      "COV": 1.65069682255706,
      "SHELF LIFE_1": 6.720000000000011
    },
    {
      "PART NUMBER": 1161277,
      "DESCRIPTION": "SEALANT 20G (BLACK) 11713514",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.833333333333333,
      "MAINTANING STOCK QTY": 3,
      "AVAILABLE": 3,
      "SHELF LIFE": 3.6000000000000014,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 56,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 2.77350098112614,
      "MEAN": 0.769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 3.6000000000000014
    },
    {
      "PART NUMBER": 11700348,
      "DESCRIPTION": "O-RING",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 57,
      "WHXX": 54,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 11703980,
      "DESCRIPTION": "AIR FILTER, CAB",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 1.16666666666666,
      "MAINTANING STOCK QTY": 5,
      "AVAILABLE": 5,
      "SHELF LIFE": 4.28571428571431,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 228,
      "WHXX": 8,
      "WH REFERENCE": "",
      "TREND": 0.71,
      "STD": 1.49786172378819,
      "MEAN": 1.07692307692307,
      "COV": 1.39087160066046,
      "SHELF LIFE_1": 4.28571428571431
    },
    {
      "PART NUMBER": 11705485,
      "DESCRIPTION": "AIR  FILTER, CAB",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3,
      "WHXX": 4,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 11707077,
      "DESCRIPTION": "BREATHER ELEMENT L60/70E/120E/L150E/F/A30E/A35D/E",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "AVERAGE (F2)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 4.41666666666666,
      "MAINTANING STOCK QTY": 18,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 18,
      "APRD QTY": 0,
      "WH12": 31,
      "WHXX": 18,
      "WH REFERENCE": "WHXX",
      "TREND": 0.45,
      "STD": 3.49908412925293,
      "MEAN": 4.07692307692307,
      "COV": 0.858265918496002,
      "SHELF LIFE_1": 4.075471698113214
    },
    {
      "PART NUMBER": 11707544,
      "DESCRIPTION": "17410286 FILTER CARTRIDGE, BRAKE SYSTEM  A35D/E A40F",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.25,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 19,
      "SHELF LIFE": 76,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 6,
      "WHXX": 4,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.599144689515278,
      "MEAN": 0.23076923076923,
      "COV": 2.5962936545662,
      "SHELF LIFE_1": 76
    },
    {
      "PART NUMBER": 11707766,
      "DESCRIPTION": "O-RING A40F 3094874",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 10,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 11709359,
      "DESCRIPTION": "O-RING BL71",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 11711074,
      "DESCRIPTION": "FUEL FILTER",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "FAST (F1)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 7,
      "MAINTANING STOCK QTY": 28,
      "AVAILABLE": 3,
      "SHELF LIFE": 0.42857142857142855,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 25,
      "APRD QTY": 25,
      "WH12": 17,
      "WHXX": 40,
      "WH REFERENCE": "WHXX",
      "TREND": 0.67,
      "STD": 9.01494485669384,
      "MEAN": 6.46153846153846,
      "COV": 1.39517003734547,
      "SHELF LIFE_1": 4
    },
    {
      "PART NUMBER": 11713138,
      "DESCRIPTION": "FILTER HOUSING, FWS",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 1,
      "SHELF LIFE": 12.000000000000005,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 1,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 12.000000000000005
    },
    {
      "PART NUMBER": 11713240,
      "DESCRIPTION": "FUEL FILTER 14520578 ",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.916666666666666,
      "MAINTANING STOCK QTY": 4,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 4,
      "APRD QTY": 4,
      "WH12": 0,
      "WHXX": 24,
      "WH REFERENCE": "WHXX",
      "TREND": 1.45,
      "STD": 1.34450448407296,
      "MEAN": 0.846153846153846,
      "COV": 1.5889598448135,
      "SHELF LIFE_1": 4.363636363636367
    },
    {
      "PART NUMBER": 11715266,
      "DESCRIPTION": "GASKET A40F",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 11716553,
      "DESCRIPTION": "ENGINE OVERHAUL KIT  D6DEAE2 / D6DEOE2 (SN: -1060653)  EW145B / EC210B",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 11883618,
      "DESCRIPTION": "AIR FILTER, PRIMARY",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.25,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 0,
      "STD": 0.832050294337843,
      "MEAN": 0.23076923076923,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 4
    },
    {
      "PART NUMBER": 11883619,
      "DESCRIPTION": "AIR FILTER, SAFETY , 43921493 15193231 ",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.5,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 2,
      "APRD QTY": 2,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 0,
      "STD": 1.66410058867568,
      "MEAN": 0.461538461538461,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 4
    },
    {
      "PART NUMBER": 11988156,
      "DESCRIPTION": "SEAL BL71",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 11988971,
      "DESCRIPTION": "DISC 8220-11890",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 1,
      "MAINTANING STOCK QTY": 4,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 4,
      "APRD QTY": 4,
      "WH12": 0,
      "WHXX": 12,
      "WH REFERENCE": "WHXX",
      "TREND": 2,
      "STD": 2.25320284859643,
      "MEAN": 0.923076923076923,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 4
    },
    {
      "PART NUMBER": 13914468,
      "DESCRIPTION": "SNAP RING A40D 8230-25980",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 21,
      "WHXX": 4,
      "WH REFERENCE": "WHXX",
      "TREND": 1,
      "STD": 0.75106761619881,
      "MEAN": 0.307692307692307,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 3.000000000000003
    },
    {
      "PART NUMBER": 13947281,
      "DESCRIPTION": "GASKET EC210B",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.5,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 2,
      "SHELF LIFE": 4,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 10,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 1.33,
      "STD": 1.19828937903055,
      "MEAN": 0.461538461538461,
      "COV": 2.5962936545662,
      "SHELF LIFE_1": 4
    },
    {
      "PART NUMBER": 13947624,
      "DESCRIPTION": "7011998 PLANE GASKET ",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 20,
      "WHXX": 12,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 13948511,
      "DESCRIPTION": "O-RING",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 5,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 13963059,
      "DESCRIPTION": "SET SCREW",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 72,
      "WHXX": 50,
      "WH REFERENCE": "WHXX",
      "TREND": 1,
      "STD": 0.375533808099405,
      "MEAN": 0.153846153846153,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": 13963103,
      "DESCRIPTION": "990976 LOCK NUT",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 15,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 13969162,
      "DESCRIPTION": "BLADE FUSE 15A A35D/G930/L150F/EC210B-P/EC460B/C 969162",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 28,
      "SHELF LIFE": 168.00000000000068,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 75,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 168.00000000000068
    },
    {
      "PART NUMBER": 20443754,
      "DESCRIPTION": "O-RING A35D 3522588",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 30,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 20488307,
      "DESCRIPTION": "SERVICE KIT FM9/FM10/FM340",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 1,
      "SHELF LIFE": 6.000000000000024,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 5,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": 20503552,
      "DESCRIPTION": "RUBBER CUSHION",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": 20519534,
      "DESCRIPTION": "SEALING MOULDING FM9/FM400",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 1,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 20524936,
      "DESCRIPTION": "SENSOR FM340/EC210B/EC290B/G930 20556500",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.416666666666666,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 2,
      "SHELF LIFE": 4.800000000000008,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.869718492622904,
      "MEAN": 0.384615384615384,
      "COV": 2.26126808081955,
      "SHELF LIFE_1": 4.800000000000008
    },
    {
      "PART NUMBER": 20572459,
      "DESCRIPTION": "SUN VISOR FM340",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 20579690,
      "DESCRIPTION": "SEALING RING, OIL PAN",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 1.58333333333333,
      "MAINTANING STOCK QTY": 6,
      "AVAILABLE": 25,
      "SHELF LIFE": 15.78947368421056,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 37,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 1.37,
      "STD": 2.75727476007816,
      "MEAN": 1.46153846153846,
      "COV": 1.88655641479032,
      "SHELF LIFE_1": 15.78947368421056
    },
    {
      "PART NUMBER": 20708247,
      "DESCRIPTION": "O-RING 20443761 3537521",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 5,
      "SHELF LIFE": 30.00000000000012,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 33,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 30.00000000000012
    },
    {
      "PART NUMBER": 20779040,
      "DESCRIPTION": "OIL FILTER KIT ASSEMBLY (TRANSMISSION)",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 1.16666666666666,
      "MAINTANING STOCK QTY": 5,
      "AVAILABLE": 5,
      "SHELF LIFE": 4.28571428571431,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 16,
      "WHXX": 2,
      "WH REFERENCE": "",
      "TREND": 1.14,
      "STD": 2.13937445925744,
      "MEAN": 1.07692307692307,
      "COV": 1.98656199788191,
      "SHELF LIFE_1": 4.28571428571431
    },
    {
      "PART NUMBER": 20872874,
      "DESCRIPTION": "WIPER BLADE",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 0,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": 20910229,
      "DESCRIPTION": "LENS, TAIL LAMP FM9/FM10/FM340",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.416666666666666,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 2,
      "APRD QTY": 2,
      "WH12": 4,
      "WHXX": 1,
      "WH REFERENCE": "WH12 + WHXX",
      "TREND": 2,
      "STD": 1.12089707663561,
      "MEAN": 0.384615384615384,
      "COV": 2.91433239925258,
      "SHELF LIFE_1": 4.800000000000008
    },
    {
      "PART NUMBER": 20917279,
      "DESCRIPTION": "HAND PUMP, LHD (+ 85110122 ADAPTER)",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 1,
      "SHELF LIFE": 3.000000000000003,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 1.5,
      "STD": 0.630425171956115,
      "MEAN": 0.307692307692307,
      "COV": 2.04888180885737,
      "SHELF LIFE_1": 3.000000000000003
    },
    {
      "PART NUMBER": 20998367,
      "DESCRIPTION": "FUEL FILTER, PRI ADAPTED QTY 6 PCS",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 92,
      "WHXX": 5,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 20998807,
      "DESCRIPTION": "OIL FILTER",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 10,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 21122035,
      "DESCRIPTION": "MODULATOR, REAR FM370",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 21244638,
      "DESCRIPTION": "GRILLE",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 21303956,
      "DESCRIPTION": "WHEEL NUT FM10/FM340 21402076 ",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.833333333333333,
      "MAINTANING STOCK QTY": 3,
      "AVAILABLE": 20,
      "SHELF LIFE": 24.000000000000007,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 2.77350098112614,
      "MEAN": 0.769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 24.000000000000007
    },
    {
      "PART NUMBER": 21312237,
      "DESCRIPTION": "CHARGE AIR HOSE",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 3,
      "SHELF LIFE": 18.000000000000075,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 18.000000000000075
    },
    {
      "PART NUMBER": 21321093,
      "DESCRIPTION": "WINDSCREEN, CLEAR FM9/FM10/FM400",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 21339283,
      "DESCRIPTION": "GEAR LEVER CARRIER",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 21347087,
      "DESCRIPTION": "CRANKSHAFT SEAL 85108423",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 10,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 21380475,
      "DESCRIPTION": "FUEL FILTER WATER SEPARATOR",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 26,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 13,
      "WHXX": 8,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 21380488,
      "DESCRIPTION": "FUEL FILTER WATER SEPARATOR",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 2.41666666666666,
      "MAINTANING STOCK QTY": 10,
      "AVAILABLE": 29,
      "SHELF LIFE": 12.000000000000034,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 65,
      "WHXX": 5,
      "WH REFERENCE": "",
      "TREND": 0.62,
      "STD": 3.29529579233402,
      "MEAN": 2.23076923076923,
      "COV": 1.47720156208077,
      "SHELF LIFE_1": 12.000000000000034
    },
    {
      "PART NUMBER": 21620181,
      "DESCRIPTION": "AIR DRIER FILTER",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.666666666666666,
      "MAINTANING STOCK QTY": 3,
      "AVAILABLE": 15,
      "SHELF LIFE": 22.500000000000025,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 8,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 1.75,
      "STD": 1.93814608747902,
      "MEAN": 0.615384615384615,
      "COV": 3.14948739215341,
      "SHELF LIFE_1": 22.500000000000025
    },
    {
      "PART NUMBER": 257658,
      "DESCRIPTION": "LEAF SPRING_REAR FM400/FM440",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 6,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 3988823,
      "DESCRIPTION": "HOSE ASSY FM10/FM340",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 1,
      "SHELF LIFE": 3.000000000000003,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 1.5,
      "STD": 0.630425171956115,
      "MEAN": 0.307692307692307,
      "COV": 2.04888180885737,
      "SHELF LIFE_1": 3.000000000000003
    },
    {
      "PART NUMBER": 8097084,
      "DESCRIPTION": "COVER FM10",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 8143122,
      "DESCRIPTION": "SCREW",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 24,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 0,
      "STD": 1.10940039245045,
      "MEAN": 0.307692307692307,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 3.000000000000003
    },
    {
      "PART NUMBER": 8189632,
      "DESCRIPTION": "WIPER ARM FM9/FM400",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": 85110482,
      "DESCRIPTION": "HOSE ASSEMBLY FM340 ~ 85110483 ",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.75,
      "MAINTANING STOCK QTY": 3,
      "AVAILABLE": 3,
      "SHELF LIFE": 4,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 6,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 1.56,
      "STD": 1.37747446344238,
      "MEAN": 0.692307692307692,
      "COV": 1.98968533608345,
      "SHELF LIFE_1": 4
    },
    {
      "PART NUMBER": 85110486,
      "DESCRIPTION": "HOSE KIT FM340/FM400",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 914468,
      "DESCRIPTION": "RETAINING RING",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 33,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 0,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": 914474,
      "DESCRIPTION": "RETAINING RING",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.25,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 12,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 2,
      "STD": 0.832050294337843,
      "MEAN": 0.23076923076923,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 4
    },
    {
      "PART NUMBER": 943903,
      "DESCRIPTION": "992517 BULB_70W 24V A35D/EC210B-P/EC460B/C/EC700B/G930/L150F/FM10/FM400",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 14,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 0,
      "STD": 1.10940039245045,
      "MEAN": 0.307692307692307,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 3.000000000000003
    },
    {
      "PART NUMBER": 945988,
      "DESCRIPTION": "LOCK NUT",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 946817,
      "DESCRIPTION": "SCREW",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 2,
      "SHELF LIFE": 12.000000000000048,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 12.000000000000048
    },
    {
      "PART NUMBER": 949329,
      "DESCRIPTION": "GASKET FM10/EC460C",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 1.33333333333333,
      "MAINTANING STOCK QTY": 5,
      "AVAILABLE": 95,
      "SHELF LIFE": 71.25000000000018,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 98,
      "WHXX": 200,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 4.43760156980183,
      "MEAN": 1.23076923076923,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 71.25000000000018
    },
    {
      "PART NUMBER": 967708,
      "DESCRIPTION": "992521 BULB 21W 24V A35D/G930/L150F/FM10/FM400",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 8,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 970742,
      "DESCRIPTION": "BULB A35D 24V 21/5W NOTLPA",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 10,
      "SHELF LIFE": 120.00000000000004,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 2,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 120.00000000000004
    },
    {
      "PART NUMBER": 970926,
      "DESCRIPTION": "984855 HEXAGON SCREW 980060 966360",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.666666666666666,
      "MAINTANING STOCK QTY": 3,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 3,
      "APRD QTY": 3,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 2,
      "STD": 2.21880078490091,
      "MEAN": 0.615384615384615,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 4.500000000000004
    },
    {
      "PART NUMBER": 971069,
      "DESCRIPTION": "NUT",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.5,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 2,
      "SHELF LIFE": 4,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 44,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 1.66410058867568,
      "MEAN": 0.461538461538461,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 4
    },
    {
      "PART NUMBER": 982322,
      "DESCRIPTION": "FUSE FM340",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 10,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 2,
      "STD": 1.10940039245045,
      "MEAN": 0.307692307692307,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 3.000000000000003
    },
    {
      "PART NUMBER": 983472,
      "DESCRIPTION": "CABLE TIE ADAPTED QTY 100",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.833333333333333,
      "MAINTANING STOCK QTY": 3,
      "AVAILABLE": 3,
      "SHELF LIFE": 3.6000000000000014,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 189,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 2.77350098112614,
      "MEAN": 0.769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 3.6000000000000014
    },
    {
      "PART NUMBER": 990037,
      "DESCRIPTION": "BULB 70W 24V A35D/G930/L150F/FM10/FM400",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 5,
      "SHELF LIFE": 60.00000000000002,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 49,
      "WHXX": 4,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 60.00000000000002
    },
    {
      "PART NUMBER": 992065,
      "DESCRIPTION": "O-RING",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 10,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 992519,
      "DESCRIPTION": "BULB 5W/24V FM10/FM400",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 1.16666666666666,
      "MAINTANING STOCK QTY": 5,
      "AVAILABLE": 9,
      "SHELF LIFE": 7.714285714285758,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 26,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 2.90004420832793,
      "MEAN": 1.07692307692307,
      "COV": 2.69289819344737,
      "SHELF LIFE_1": 7.714285714285758
    },
    {
      "PART NUMBER": 992521,
      "DESCRIPTION": "BULB 24V/21W G930/L150F 967708",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 2.91666666666666,
      "MAINTANING STOCK QTY": 12,
      "AVAILABLE": 27,
      "SHELF LIFE": 9.25714285714288,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 312,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 1.26,
      "STD": 4.97300404491784,
      "MEAN": 2.69230769230769,
      "COV": 1.84711578811234,
      "SHELF LIFE_1": 9.25714285714288
    },
    {
      "PART NUMBER": 994555,
      "DESCRIPTION": "HOSE CLAMP",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 109,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 8102116,
      "DESCRIPTION": "BREATHER FILTER ASSY",
      "PRODUCT CATEGORY": "VTS",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.5,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 2,
      "SHELF LIFE": 4,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 11,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 1.33,
      "STD": 1.19828937903055,
      "MEAN": 0.461538461538461,
      "COV": 2.5962936545662,
      "SHELF LIFE_1": 4
    },
    {
      "PART NUMBER": "14750667H",
      "DESCRIPTION": "CONTROL-PNEUMATIC-1-TS/LF",
      "PRODUCT CATEGORY": "VTS",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "14896991A",
      "DESCRIPTION": "RETURN FILTER ELEMENT",
      "PRODUCT CATEGORY": "VTS",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 1.16666666666666,
      "MAINTANING STOCK QTY": 5,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 5,
      "APRD QTY": 5,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 0.57,
      "STD": 2.62873665669583,
      "MEAN": 1.07692307692307,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 4.28571428571431
    },
    {
      "PART NUMBER": "A210609000087",
      "DESCRIPTION": "O-RING 24002743",
      "PRODUCT CATEGORY": "YPG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 1,
      "SHELF LIFE": 6.000000000000024,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 16,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": "A820606010004",
      "DESCRIPTION": "SEAL RING 150A 60C1816.1.13-3",
      "PRODUCT CATEGORY": "YPG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 1.66666666666666,
      "MAINTANING STOCK QTY": 7,
      "AVAILABLE": 5,
      "SHELF LIFE": 3.000000000000012,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 2,
      "APRD QTY": 2,
      "WH12": 94,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 2,
      "STD": 5.54700196225229,
      "MEAN": 1.53846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 4.200000000000016
    },
    {
      "PART NUMBER": "A820606010023",
      "DESCRIPTION": "SEAL RING FOR 125A",
      "PRODUCT CATEGORY": "YPG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 42.5,
      "MAINTANING STOCK QTY": 170,
      "AVAILABLE": 73,
      "SHELF LIFE": 1.7176470588235293,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 97,
      "APRD QTY": 97,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 2,
      "STD": 141.448550037433,
      "MEAN": 39.2307692307692,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 4
    },
    {
      "PART NUMBER": "B222100000116",
      "DESCRIPTION": "FILTER ELEMENT, HYD.",
      "PRODUCT CATEGORY": "YPG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "B222100000159",
      "DESCRIPTION": "HYDRAULIC FILTER SCC500 HF6872",
      "PRODUCT CATEGORY": "YCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "B222100000445",
      "DESCRIPTION": "FILTER CORE, HYD SCC1500C  HF6864",
      "PRODUCT CATEGORY": "YCG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 2,
      "SHELF LIFE": 6.000000000000006,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 5,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 1.10940039245045,
      "MEAN": 0.307692307692307,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000006
    },
    {
      "PART NUMBER": 11435548,
      "DESCRIPTION": "GAS SPRING",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 4,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14626370,
      "DESCRIPTION": "HOUR COUNTER EC210B/EC290B 14530130",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 5,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "C11195",
      "DESCRIPTION": "SSC11195 ROD, (D100) MOIL POINT SB50/SU+55 TR-F",
      "PRODUCT CATEGORY": "SOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "C31211",
      "DESCRIPTION": "SSC31211 ROD, (D140) MOIL POINT SB81/A ",
      "PRODUCT CATEGORY": "SOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 11103371,
      "DESCRIPTION": "PISTON SEAL L120E/F",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": 11103372,
      "DESCRIPTION": "PISTON SEAL L120E/F",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": 29020008421,
      "DESCRIPTION": "STRAINER ASSEMBLY, FUEL TANK  LG956",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 5,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 29170036941,
      "DESCRIPTION": "CUTTING EDGE  LG936L",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 6,
      "WHXX": 3,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 11060946,
      "DESCRIPTION": "RUBBER ELEMENT  A35F",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": 14521945,
      "DESCRIPTION": "CATCH",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 4,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 984753,
      "DESCRIPTION": "FLANGE SCREW",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 66,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 90898,
      "DESCRIPTION": "GAUGE",
      "PRODUCT CATEGORY": "VGG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14513267,
      "DESCRIPTION": "RELIEF VALVE",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 6,
      "WHXX": 2,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 992518,
      "DESCRIPTION": "BULB 967711",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 2,
      "SHELF LIFE": 24.00000000000001,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 60,
      "WHXX": 72,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 24.00000000000001
    },
    {
      "PART NUMBER": 15144740,
      "DESCRIPTION": "STARTER SWITCH SD110",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 31,
      "WHXX": 1,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 11198489,
      "DESCRIPTION": "RADIATOR GRILLE",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 2,
      "STD": 0.375533808099405,
      "MEAN": 0.153846153846153,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": 914465,
      "DESCRIPTION": "RETAINING RING FM330",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 490,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "LF9009VE",
      "DESCRIPTION": "LUBE FILTER",
      "PRODUCT CATEGORY": "CUF",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 1,
      "SHELF LIFE": 12.000000000000005,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 10,
      "WHXX": 2,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 12.000000000000005
    },
    {
      "PART NUMBER": 13965190,
      "DESCRIPTION": "SCREW",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 20555065,
      "DESCRIPTION": "SCREW",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 44,
      "WHXX": 7,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 11102861,
      "DESCRIPTION": "SEALING RING",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 0,
      "WHXX": 1,
      "WH REFERENCE": "WHXX",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": 1635243,
      "DESCRIPTION": "SPRING LEAF",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 20943389,
      "DESCRIPTION": "CYL. HEAD GASKET 1.6MM 3HOLES EC290B",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 2,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14376598,
      "DESCRIPTION": "14630764 RELAY 14529156 1123-01900",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.25,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 2,
      "STD": 0.832050294337843,
      "MEAN": 0.23076923076923,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 4
    },
    {
      "PART NUMBER": 29220001251,
      "DESCRIPTION": "CONNECTOR-JOINT",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4030000552,
      "DESCRIPTION": "O-RING",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 0,
      "WHXX": 1,
      "WH REFERENCE": "WHXX",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": 950629,
      "DESCRIPTION": "PIN, BRAKE PISTON L120F",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 2,
      "STD": 1.10940039245045,
      "MEAN": 0.307692307692307,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 3.000000000000003
    },
    {
      "PART NUMBER": 21312236,
      "DESCRIPTION": "CHARGE AIR HOSE 20589122",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 9,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 11443400,
      "DESCRIPTION": "11383470 ECC, ELECTRONIC UNIT",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "16Y-04C-02000",
      "DESCRIPTION": "CAP, FUEL TANK",
      "PRODUCT CATEGORY": "SDG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 4,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": 3972382,
      "DESCRIPTION": "BELT, V RIBBED",
      "PRODUCT CATEGORY": "CUG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 3022474,
      "DESCRIPTION": "WATER PUMP",
      "PRODUCT CATEGORY": "SDG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4130000523,
      "DESCRIPTION": "WATER TEMPERATURE GAUGE ",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 5,
      "WHXX": 1,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4130000286,
      "DESCRIPTION": "OIL TEMPERATURE GAUGE ",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 13,
      "WHXX": 1,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 21761288,
      "DESCRIPTION": "TAIL LAMP LH FM9/FM440 21652942",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.5,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 2,
      "SHELF LIFE": 4,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 1.19828937903055,
      "MEAN": 0.461538461538461,
      "COV": 2.5962936545662,
      "SHELF LIFE_1": 4
    },
    {
      "PART NUMBER": 14523551,
      "DESCRIPTION": "TOOTH 15GPE VTS EW145B",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 2,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 49,
      "WHXX": 40,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14522960,
      "DESCRIPTION": "ADAPTER EA15BL30 VTS EW145B",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 17,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 0,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": 20764159,
      "DESCRIPTION": "997957 HEXAGON SCREW 20764159",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 48,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 20542497,
      "DESCRIPTION": "NUT",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 13,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 20764147,
      "DESCRIPTION": "WASHER",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 9,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4130000420,
      "DESCRIPTION": "COMPRESSOR ASSY A/C 4130002212",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 7,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 907816,
      "DESCRIPTION": "SPLIT PIN A40F 907813",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.416666666666666,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 2,
      "APRD QTY": 2,
      "WH12": 0,
      "WHXX": 15,
      "WH REFERENCE": "WHXX",
      "TREND": 2,
      "STD": 1.12089707663561,
      "MEAN": 0.384615384615384,
      "COV": 2.91433239925258,
      "SHELF LIFE_1": 4.800000000000008
    },
    {
      "PART NUMBER": 14622357,
      "DESCRIPTION": "DRAIN",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 6,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "C31246",
      "DESCRIPTION": "RUBBER PLUG",
      "PRODUCT CATEGORY": "SOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 2,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": 949800,
      "DESCRIPTION": "HOSE",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 4,
      "WHXX": 4,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14530666,
      "DESCRIPTION": "WASHER",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 1,
      "SHELF LIFE": 12.000000000000005,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 131,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 12.000000000000005
    },
    {
      "PART NUMBER": 14500291,
      "DESCRIPTION": "CLIP 14500290",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 64,
      "WHXX": 23,
      "WH REFERENCE": "WHXX",
      "TREND": 0,
      "STD": 1.10940039245045,
      "MEAN": 0.307692307692307,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 3.000000000000003
    },
    {
      "PART NUMBER": 14506431,
      "DESCRIPTION": "CONNECTOR",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 6,
      "WHXX": 6,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14518258,
      "DESCRIPTION": "HOSE",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 11705231,
      "DESCRIPTION": "FILTER, GEARBOX",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 21,
      "WHXX": 12,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 20412762,
      "DESCRIPTION": "O-RING",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 34,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 11700334,
      "DESCRIPTION": "SCREW",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 1.5,
      "MAINTANING STOCK QTY": 6,
      "AVAILABLE": 6,
      "SHELF LIFE": 4,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 18,
      "WHXX": 34,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 4.99230176602706,
      "MEAN": 1.38461538461538,
      "COV": 3.60555127546399,
      "SHELF LIFE_1": 4
    },
    {
      "PART NUMBER": 4120001739007,
      "DESCRIPTION": "RETAINER RING",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.666666666666666,
      "MAINTANING STOCK QTY": 3,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 3,
      "APRD QTY": 3,
      "WH12": 224,
      "WHXX": 27,
      "WH REFERENCE": "WHXX",
      "TREND": 2,
      "STD": 1.50213523239762,
      "MEAN": 0.615384615384615,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 4.500000000000004
    },
    {
      "PART NUMBER": 4120001739008,
      "DESCRIPTION": "SQUARE SEAL",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 1,
      "MAINTANING STOCK QTY": 4,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 4,
      "APRD QTY": 0,
      "WH12": 282,
      "WHXX": 35,
      "WH REFERENCE": "WHXX",
      "TREND": 0.67,
      "STD": 2.39657875806111,
      "MEAN": 0.923076923076923,
      "COV": 2.5962936545662,
      "SHELF LIFE_1": 4
    },
    {
      "PART NUMBER": 4110000301,
      "DESCRIPTION": "DRIVE BELT  ",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4120001739011,
      "DESCRIPTION": "INTERNAL PLIERS",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 6,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4120001739009,
      "DESCRIPTION": "CYLINDER PISTON",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.666666666666666,
      "MAINTANING STOCK QTY": 3,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 3,
      "APRD QTY": 3,
      "WH12": 193,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 2,
      "STD": 1.50213523239762,
      "MEAN": 0.615384615384615,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 4.500000000000004
    },
    {
      "PART NUMBER": 4120001739004,
      "DESCRIPTION": "O-RING",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 1,
      "SHELF LIFE": 3.000000000000003,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 24,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 1.10940039245045,
      "MEAN": 0.307692307692307,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 3.000000000000003
    },
    {
      "PART NUMBER": 4190000599,
      "DESCRIPTION": "WIPER KIT",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4110000076366,
      "DESCRIPTION": "4110000076435 SEALING RING ",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 1,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4021000023,
      "DESCRIPTION": "BEARING ",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 15,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 1,
      "STD": 0.375533808099405,
      "MEAN": 0.153846153846153,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": 4011000084,
      "DESCRIPTION": "BOLT ",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 134,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4110000367112,
      "DESCRIPTION": "EX. FRICTIONAL DISC 0501 309 330",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 13,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 122,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4110000367113,
      "DESCRIPTION": "IN. FRICTIONAL DISC 4644 308 329",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 9,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 105,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4110000367114,
      "DESCRIPTION": "IN. FRICTIONAL DISC 4644 308 330",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 24,
      "WHXX": 1,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4110000367115,
      "DESCRIPTION": "IN. FRICTIONAL DISC 0769 129 022",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 9,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4110000076258,
      "DESCRIPTION": "SEALING RING ",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 48,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4110000367118,
      "DESCRIPTION": "STOPPING WASHER 4644 308 265",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 22,
      "WHXX": 3,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4110000367101,
      "DESCRIPTION": "THRUST WASHER ",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 6,
      "WHXX": 6,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4110000367105,
      "DESCRIPTION": "SEALING RING ",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 14,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4110000076353,
      "DESCRIPTION": "SPECIAL SEALING RING 0750 112 140",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 20,
      "WHXX": 4,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4110000076247,
      "DESCRIPTION": "THRUST WASHER 0730 150 779 DUP",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 25,
      "WHXX": 3,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4110000076295,
      "DESCRIPTION": "FLEXIBLE RETAINING RING 0730 513 611",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 30,
      "WHXX": 16,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4110000076191,
      "DESCRIPTION": "LIMIT PLATE 4642 308 555",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 16,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4110000076067,
      "DESCRIPTION": "EX. FRICTIONAL DISC 0501 309 329 D=137,5 S=2,0",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 6,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 31,
      "WHXX": 30,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4110000076068,
      "DESCRIPTION": "IN. FRICTIONAL DISC 4642 308 330 S=1,5 4110000076074",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 26,
      "WHXX": 23,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4110000076069,
      "DESCRIPTION": "IN. FRICTIONAL DISC 4642 308 332 S=2,0 4110000076074",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 21,
      "WHXX": 20,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4110000076107,
      "DESCRIPTION": "IN. FRICTIONAL DISC 4642 308 331 S=2,5 4110000076074",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 10,
      "WHXX": 12,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 7200001482,
      "DESCRIPTION": "SEAL PLATE 4644301262",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4110000367072,
      "DESCRIPTION": "GEAR PUMP",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 1,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4110000076309,
      "DESCRIPTION": "REGULATING SHIM 1204 308 062",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 4,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4110000076391,
      "DESCRIPTION": "PLATE",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 1,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4110000076281,
      "DESCRIPTION": "GASKET 4110000076043  4110000076040",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 39,
      "WHXX": 23,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 20459855,
      "DESCRIPTION": "VALVE SEAL",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 10,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 150,
      "WHXX": 22,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 20890422,
      "DESCRIPTION": "CYLINDER LINER KIT D6EEAE2/3  EC210B PRIME",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 1,
      "SHELF LIFE": 12.000000000000005,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 12.000000000000005
    },
    {
      "PART NUMBER": 21496591,
      "DESCRIPTION": "LIP SEAL 20459168",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 14,
      "WHXX": 3,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 21531890,
      "DESCRIPTION": "GASKET 20537029",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 3,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "154-78-31330",
      "DESCRIPTION": "GUARD PLATE",
      "PRODUCT CATEGORY": "SDG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.5,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 2,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 0,
      "STD": 1.66410058867568,
      "MEAN": 0.461538461538461,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 4
    },
    {
      "PART NUMBER": 21638691,
      "DESCRIPTION": "FUEL REGULATOR 21103266",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.666666666666666,
      "MAINTANING STOCK QTY": 3,
      "AVAILABLE": 3,
      "SHELF LIFE": 4.500000000000004,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 17,
      "WHXX": 8,
      "WH REFERENCE": "",
      "TREND": 1.75,
      "STD": 1.38675049056307,
      "MEAN": 0.615384615384615,
      "COV": 2.25346954716499,
      "SHELF LIFE_1": 4.500000000000004
    },
    {
      "PART NUMBER": 21527767,
      "DESCRIPTION": "O-RING 20799838",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 5,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 6212888,
      "DESCRIPTION": "SUPPORT BEARING",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 20515173,
      "DESCRIPTION": "GASKET",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 4,
      "WHXX": 4,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 21165497,
      "DESCRIPTION": "FUEL PIPE",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 3,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14559054,
      "DESCRIPTION": "SEALING",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 89,
      "WHXX": 10,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4880495,
      "DESCRIPTION": "O-RING",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3,
      "WHXX": 12,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 20405721,
      "DESCRIPTION": "CORE PLUG",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 13,
      "SHELF LIFE": 39.00000000000004,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 46,
      "WHXX": 36,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 1.10940039245045,
      "MEAN": 0.307692307692307,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 39.00000000000004
    },
    {
      "PART NUMBER": 992515,
      "DESCRIPTION": "BULB 75/70W FM10/FM400 973892 13964763 964763",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 3,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 11431708,
      "DESCRIPTION": "COLLAR",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 17214676,
      "DESCRIPTION": "EXPANSION TANK ASSY EC210B, EC290B  15047209",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 3,
      "SHELF LIFE": 9.000000000000009,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 8,
      "WHXX": 1,
      "WH REFERENCE": "",
      "TREND": 0.5,
      "STD": 0.630425171956115,
      "MEAN": 0.307692307692307,
      "COV": 2.04888180885737,
      "SHELF LIFE_1": 9.000000000000009
    },
    {
      "PART NUMBER": 21354759,
      "DESCRIPTION": "EXPANSION VALVE",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 2,
      "SHELF LIFE": 12.000000000000048,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 12.000000000000048
    },
    {
      "PART NUMBER": 14527865,
      "DESCRIPTION": "ADAPTER A15-30, HOR. (KTS) EC140B/EW145B",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 1,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "A210401000017",
      "DESCRIPTION": "WASHER LOCK 8GB93",
      "PRODUCT CATEGORY": "YPG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.583333333333333,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 1,
      "SHELF LIFE": 1.714285714285715,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 2,
      "STD": 1.9414506867883,
      "MEAN": 0.538461538461538,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 3.42857142857143
    },
    {
      "PART NUMBER": 15145555,
      "DESCRIPTION": "O-RING",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 7,
      "WHXX": 2,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 991044,
      "DESCRIPTION": "O-RING",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 8,
      "WHXX": 5,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4110000507,
      "DESCRIPTION": "FILTER ASSY, T/C  LG956L",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 13,
      "WHXX": 3,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 993324,
      "DESCRIPTION": "O-RING 932043",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 27,
      "WHXX": 23,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4120001739005,
      "DESCRIPTION": "OUTER PLIERS",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 8,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 18,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4120001739006,
      "DESCRIPTION": "DUST CAP ",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.666666666666666,
      "MAINTANING STOCK QTY": 3,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 3,
      "APRD QTY": 3,
      "WH12": 0,
      "WHXX": 3,
      "WH REFERENCE": "WHXX",
      "TREND": 2,
      "STD": 1.50213523239762,
      "MEAN": 0.615384615384615,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 4.500000000000004
    },
    {
      "PART NUMBER": 29070000071,
      "DESCRIPTION": "BRAKE PLATE LG956/LG958",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 29,
      "WHXX": 3,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14512396,
      "DESCRIPTION": "INLET HOSE",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 3,
      "WH REFERENCE": "WHXX",
      "TREND": 1,
      "STD": 0.375533808099405,
      "MEAN": 0.153846153846153,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": 3979593,
      "DESCRIPTION": "FILLER CAP, EXP TANK",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 5,
      "WHXX": 3,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 20724977,
      "DESCRIPTION": "VOLTAGE CONVERTER 20752237",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 3,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 15153124,
      "DESCRIPTION": "TEMPERATURE SENSOR L150F 11145324",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 2,
      "STD": 0.375533808099405,
      "MEAN": 0.153846153846153,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": 983242,
      "DESCRIPTION": "997421 HEXAGON SCREW",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 29,
      "WHXX": 14,
      "WH REFERENCE": "WHXX",
      "TREND": 2,
      "STD": 1.10940039245045,
      "MEAN": 0.307692307692307,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 3.000000000000003
    },
    {
      "PART NUMBER": 4110000081107,
      "DESCRIPTION": "OIL FILTER 7200002405 LG956/LG958",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 2,
      "SHELF LIFE": 12.000000000000048,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 125,
      "WHXX": 35,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.375533808099405,
      "MEAN": 0.153846153846153,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 12.000000000000048
    },
    {
      "PART NUMBER": "9444-10312",
      "DESCRIPTION": "RUBBER HOSE",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 12,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14529762,
      "DESCRIPTION": "PLATE",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 57,
      "WHXX": 6,
      "WH REFERENCE": "WHXX",
      "TREND": 0,
      "STD": 1.10940039245045,
      "MEAN": 0.307692307692307,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 3.000000000000003
    },
    {
      "PART NUMBER": 14529761,
      "DESCRIPTION": "PLATE",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.25,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 30,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 0,
      "STD": 0.832050294337843,
      "MEAN": 0.23076923076923,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 4
    },
    {
      "PART NUMBER": 4130000543,
      "DESCRIPTION": "FRONT HEAD LAMP  RH  LFRDH4-24  LG956L",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.25,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 1,
      "SHELF LIFE": 4,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0.67,
      "STD": 0.438529009653514,
      "MEAN": 0.23076923076923,
      "COV": 1.90029237516522,
      "SHELF LIFE_1": 4
    },
    {
      "PART NUMBER": 15195930,
      "DESCRIPTION": "KIT, WATER SEPARATOR BOWL L150F/G",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 10,
      "WHXX": 7,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 20405652,
      "DESCRIPTION": "SEALING RING",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 32,
      "WHXX": 6,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4130000873,
      "DESCRIPTION": "ENABLE RELAY",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14540003,
      "DESCRIPTION": "PIN, BUCKET EC210B 14512678",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 6,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4130000121,
      "DESCRIPTION": "ANNUNCIATOR  KSHM-12F4  LG956L/LG938/L",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "D81151",
      "DESCRIPTION": "RUBBER PLUG  SU+85/SB121/SB60-UP SERIES",
      "PRODUCT CATEGORY": "SOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.416666666666666,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 2,
      "SHELF LIFE": 4.800000000000008,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 7,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0.8,
      "STD": 0.767947647788304,
      "MEAN": 0.384615384615384,
      "COV": 1.99666388424959,
      "SHELF LIFE_1": 4.800000000000008
    },
    {
      "PART NUMBER": 14621703,
      "DESCRIPTION": "SEALING RING EC210B 8230-27160",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3,
      "WHXX": 1,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 20459246,
      "DESCRIPTION": "SCREW",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 3,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 6,
      "WHXX": 11,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "B222100000751",
      "DESCRIPTION": "FUEL FILTER CORE",
      "PRODUCT CATEGORY": "YCG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.5,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 5,
      "SHELF LIFE": 10,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 17,
      "WHXX": 2,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 1.66410058867568,
      "MEAN": 0.461538461538461,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 10
    },
    {
      "PART NUMBER": "B222100000424",
      "DESCRIPTION": "FUEL FILTER ",
      "PRODUCT CATEGORY": "YPG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.5,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 7,
      "SHELF LIFE": 14,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 56,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 1.66410058867568,
      "MEAN": 0.461538461538461,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 14
    },
    {
      "PART NUMBER": "A222100000600",
      "DESCRIPTION": "AIR FILTER, OUTER",
      "PRODUCT CATEGORY": "YCG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.583333333333333,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 4,
      "SHELF LIFE": 6.85714285714286,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3,
      "WHXX": 1,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 1.9414506867883,
      "MEAN": 0.538461538461538,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.85714285714286
    },
    {
      "PART NUMBER": "A222100000599",
      "DESCRIPTION": "AIR FILTER, SAFETY  QY25C/5OC",
      "PRODUCT CATEGORY": "YCG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.583333333333333,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 4,
      "SHELF LIFE": 6.85714285714286,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 5,
      "WHXX": 1,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 1.66410058867568,
      "MEAN": 0.538461538461538,
      "COV": 3.09047252182627,
      "SHELF LIFE_1": 6.85714285714286
    },
    {
      "PART NUMBER": 21312238,
      "DESCRIPTION": "CHARGE AIR HOSE",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 1,
      "SHELF LIFE": 6.000000000000024,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": 12729862,
      "DESCRIPTION": "WASHER, PLOW BOLT (3/4\") G930",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 11,
      "WHXX": 7,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 12741621,
      "DESCRIPTION": "WASHER",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 37,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 990201,
      "DESCRIPTION": "SCREW",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 6,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 12736497,
      "DESCRIPTION": "SEAL",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3,
      "WHXX": 3,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 12736498,
      "DESCRIPTION": "BEARING",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 5,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 12736499,
      "DESCRIPTION": "BEARING",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 7,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 12735791,
      "DESCRIPTION": "BEARING",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 906645,
      "DESCRIPTION": "ROUND NUT",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 5,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 907035,
      "DESCRIPTION": "LOCK WASHER",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 6,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 990950,
      "DESCRIPTION": "FLANGE LOCK NUT 948645",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 101,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 29070000331,
      "DESCRIPTION": "WASHER",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.666666666666666,
      "MAINTANING STOCK QTY": 3,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 3,
      "APRD QTY": 0,
      "WH12": 27,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 0,
      "STD": 2.21880078490091,
      "MEAN": 0.615384615384615,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 4.500000000000004
    },
    {
      "PART NUMBER": 29070000381,
      "DESCRIPTION": "WASHER THRUST",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 0,
      "STD": 1.10940039245045,
      "MEAN": 0.307692307692307,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 3.000000000000003
    },
    {
      "PART NUMBER": 14595621,
      "DESCRIPTION": "PUMP, HYDRAULIC EC210BLC",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 17204355,
      "DESCRIPTION": "ALTERNATOR_80A EC210B/G710B/G930/L150F 22098738 20849350 ",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.916666666666666,
      "MAINTANING STOCK QTY": 4,
      "AVAILABLE": 2,
      "SHELF LIFE": 2.1818181818181834,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 2,
      "APRD QTY": 0,
      "WH12": 33,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 0.73,
      "STD": 0.688737231721194,
      "MEAN": 0.846153846153846,
      "COV": 0.813962182943229,
      "SHELF LIFE_1": 4.363636363636367
    },
    {
      "PART NUMBER": 4130000426,
      "DESCRIPTION": "ALARM BUTTON SWITCH",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 3283767,
      "DESCRIPTION": "GASKET",
      "PRODUCT CATEGORY": "CUG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "154-32-11113",
      "DESCRIPTION": "T/SHOE 610MM(24\")",
      "PRODUCT CATEGORY": "ZZZ",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.666666666666666,
      "MAINTANING STOCK QTY": 3,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 3,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 0.5,
      "STD": 1.44559454541845,
      "MEAN": 0.615384615384615,
      "COV": 2.34909113630498,
      "SHELF LIFE_1": 4.500000000000004
    },
    {
      "PART NUMBER": "154-27-12273A",
      "DESCRIPTION": "SPROCKET SEGMENT 5T",
      "PRODUCT CATEGORY": "SDG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 4.16666666666666,
      "MAINTANING STOCK QTY": 17,
      "AVAILABLE": 1,
      "SHELF LIFE": 0.24000000000000038,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 16,
      "APRD QTY": 16,
      "WH12": 10,
      "WHXX": 0,
      "WH REFERENCE": "PARTIAL WH12",
      "TREND": 1.2,
      "STD": 9.3527727960407,
      "MEAN": 3.84615384615384,
      "COV": 2.43172092697058,
      "SHELF LIFE_1": 4.080000000000006
    },
    {
      "PART NUMBER": 12746330,
      "DESCRIPTION": "WHEEL HUB",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 990200,
      "DESCRIPTION": "SCREW",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 22,
      "WHXX": 16,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 990951,
      "DESCRIPTION": "FLANGE LOCK NUT M10X11.4 ~71083 971098 13971098",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 65,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4041000020,
      "DESCRIPTION": "O-RING LGB308-440*3.5",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 16,
      "WHXX": 21,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4030000146,
      "DESCRIPTION": "OIL SEAL, AXLE HG4-692-SG170*200*15 LG958",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 1,
      "SHELF LIFE": 6.000000000000024,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 14,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.375533808099405,
      "MEAN": 0.153846153846153,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": 4804410,
      "DESCRIPTION": "SEALING RING",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 5,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 11110705,
      "DESCRIPTION": "RADIATOR EC24B/EC290B L70G L120E/F",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 21761154,
      "DESCRIPTION": "TAIL LAMP CLUSTER 21097450",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.666666666666666,
      "MAINTANING STOCK QTY": 3,
      "AVAILABLE": 3,
      "SHELF LIFE": 4.500000000000004,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 7,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 1.5,
      "STD": 1.70970082853022,
      "MEAN": 0.615384615384615,
      "COV": 2.7782638463616,
      "SHELF LIFE_1": 4.500000000000004
    },
    {
      "PART NUMBER": 1635357,
      "DESCRIPTION": "SPRING LEAF",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 9,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 20799762,
      "DESCRIPTION": "CYLINDER HEAD L120F",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 15015574,
      "DESCRIPTION": "SHIM",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4110000331007,
      "DESCRIPTION": "STARTER MOTOR  LG 936 L",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 4,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 59286344,
      "DESCRIPTION": "DRIVE SLEEVE  SD100 / SD110",
      "PRODUCT CATEGORY": "VCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4110000025006,
      "DESCRIPTION": "STARTER ",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 5,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 13949268,
      "DESCRIPTION": "SEALING RING",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 4,
      "WHXX": 10,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 16021529,
      "DESCRIPTION": "CONTROL UNIT",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 4,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 59815449,
      "DESCRIPTION": "CONTROL LEVER",
      "PRODUCT CATEGORY": "VCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 21707132,
      "DESCRIPTION": "OIL FILTER, BYPASS (X8)",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "FAST (F1)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 5.16666666666666,
      "MAINTANING STOCK QTY": 21,
      "AVAILABLE": 25,
      "SHELF LIFE": 4.838709677419361,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 16,
      "WHXX": 60,
      "WH REFERENCE": "",
      "TREND": 0.71,
      "STD": 4.08562206919676,
      "MEAN": 4.76923076923076,
      "COV": 0.856662691928354,
      "SHELF LIFE_1": 4.838709677419361
    },
    {
      "PART NUMBER": 4030000536,
      "DESCRIPTION": "O-RING ",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 4,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4110000507007,
      "DESCRIPTION": "FILTER INSERT, T/C",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "AVERAGE (F2)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 2.66666666666666,
      "MAINTANING STOCK QTY": 11,
      "AVAILABLE": 3,
      "SHELF LIFE": 1.1250000000000029,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 8,
      "APRD QTY": 8,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 1.56,
      "STD": 3.47887397049161,
      "MEAN": 2.46153846153846,
      "COV": 1.41329255051221,
      "SHELF LIFE_1": 4.125000000000011
    },
    {
      "PART NUMBER": "9412-22050",
      "DESCRIPTION": "CONNECTOR",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 6,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4110001015026,
      "DESCRIPTION": "V-RIBBED BELT, FAN",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.25,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 44,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 0.67,
      "STD": 0.438529009653514,
      "MEAN": 0.23076923076923,
      "COV": 1.90029237516522,
      "SHELF LIFE_1": 4
    },
    {
      "PART NUMBER": 21628356,
      "DESCRIPTION": "24065172 CRANKSHAFT SEAL 20459115",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 2,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 5,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4021000038,
      "DESCRIPTION": "BEARING GB297-32224",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 3,
      "WHXX": 1,
      "WH REFERENCE": "WHXX",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": 4021000034,
      "DESCRIPTION": "BEARING GB297-32028",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 15,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": 14548265,
      "DESCRIPTION": "FUEL HOSE",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14576425,
      "DESCRIPTION": "SWITCH",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 21707134,
      "DESCRIPTION": "OIL FILTER 466634",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 1.83333333333333,
      "MAINTANING STOCK QTY": 7,
      "AVAILABLE": 25,
      "SHELF LIFE": 13.636363636363662,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 374,
      "WHXX": 95,
      "WH REFERENCE": "",
      "TREND": 0.55,
      "STD": 3.25024653897247,
      "MEAN": 1.69230769230769,
      "COV": 1.92060022757464,
      "SHELF LIFE_1": 13.636363636363662
    },
    {
      "PART NUMBER": 20460811,
      "DESCRIPTION": "BRACKET",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 0,
      "WHXX": 4,
      "WH REFERENCE": "WHXX",
      "TREND": 2,
      "STD": 0.375533808099405,
      "MEAN": 0.153846153846153,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": 14559479,
      "DESCRIPTION": "FUEL FILTER",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 4,
      "SHELF LIFE": 24.000000000000096,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 31,
      "WHXX": 7,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 24.000000000000096
    },
    {
      "PART NUMBER": 15066534,
      "DESCRIPTION": "FUEL FILTER",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 3,
      "SHELF LIFE": 18.000000000000075,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 19,
      "WHXX": 9,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 18.000000000000075
    },
    {
      "PART NUMBER": "1040-20410",
      "DESCRIPTION": "OIL FILTER",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.916666666666666,
      "MAINTANING STOCK QTY": 4,
      "AVAILABLE": 2,
      "SHELF LIFE": 2.1818181818181834,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 2,
      "APRD QTY": 2,
      "WH12": 31,
      "WHXX": 14,
      "WH REFERENCE": "WHXX",
      "TREND": 1.09,
      "STD": 1.51910905062549,
      "MEAN": 0.846153846153846,
      "COV": 1.79531069619377,
      "SHELF LIFE_1": 4.363636363636367
    },
    {
      "PART NUMBER": 14377007,
      "DESCRIPTION": "STRAINER, SUCTION HYD.",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 1.5,
      "MAINTANING STOCK QTY": 6,
      "AVAILABLE": 2,
      "SHELF LIFE": 1.3333333333333333,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 4,
      "APRD QTY": 0,
      "WH12": 8,
      "WHXX": 9,
      "WH REFERENCE": "WHXX",
      "TREND": 0.33,
      "STD": 2.75494892688477,
      "MEAN": 1.38461538461538,
      "COV": 1.98968533608345,
      "SHELF LIFE_1": 4
    },
    {
      "PART NUMBER": 21707133,
      "DESCRIPTION": "17533661 OIL FILTER",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 32,
      "SHELF LIFE": 192.00000000000077,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 192.00000000000077
    },
    {
      "PART NUMBER": 12743123,
      "DESCRIPTION": "O-RING",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 1.08333333333333,
      "MAINTANING STOCK QTY": 4,
      "AVAILABLE": 7,
      "SHELF LIFE": 6.461538461538482,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 6,
      "WHXX": 11,
      "WH REFERENCE": "",
      "TREND": 0.62,
      "STD": 1.15470053837925,
      "MEAN": 1,
      "COV": 1.15470053837925,
      "SHELF LIFE_1": 6.461538461538482
    },
    {
      "PART NUMBER": 4030000046,
      "DESCRIPTION": "FRAME OIL SEAL HG4-692-PD130*160*12",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 1,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 51,
      "WHXX": 1,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14529765,
      "DESCRIPTION": "BEARING",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 4,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14540405,
      "DESCRIPTION": "COLLAR",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 993325,
      "DESCRIPTION": "O-RING 13948700 948700 944706",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 277,
      "WHXX": 38,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 992517,
      "DESCRIPTION": "BULB_70W 24V A35D/EC210B/EC460B/C/EC700B/G930/L150F/FM10/FM400 943903",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 9,
      "SHELF LIFE": 54.00000000000022,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 14,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 54.00000000000022
    },
    {
      "PART NUMBER": "16Y-60-00013",
      "DESCRIPTION": "07056-10045 OIL STRAINER,SD16",
      "PRODUCT CATEGORY": "SDG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4120001739053,
      "DESCRIPTION": "DUST CAP ",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 15,
      "WHXX": 9,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 70930474,
      "DESCRIPTION": "FUSE 60A",
      "PRODUCT CATEGORY": "VCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 5,
      "WHXX": 4,
      "WH REFERENCE": "WHXX",
      "TREND": 0,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": 70606165,
      "DESCRIPTION": "STRAINER, HYD TANK SD110",
      "PRODUCT CATEGORY": "VCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 2,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 8,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14622355,
      "DESCRIPTION": "FUEL FILTER, EXTRA FWS_",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "FAST (F1)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 17.9166666666666,
      "MAINTANING STOCK QTY": 72,
      "AVAILABLE": 34,
      "SHELF LIFE": 1.8976744186046581,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 38,
      "APRD QTY": 38,
      "WH12": 1379,
      "WHXX": 176,
      "WH REFERENCE": "WHXX",
      "TREND": 0.86,
      "STD": 7.89108552540338,
      "MEAN": 16.5384615384615,
      "COV": 0.477135403861599,
      "SHELF LIFE_1": 4.018604651162805
    },
    {
      "PART NUMBER": 14523235,
      "DESCRIPTION": "WASHER",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 26,
      "MAINTANING STOCK QTY": 104,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 104,
      "APRD QTY": 104,
      "WH12": 39,
      "WHXX": 0,
      "WH REFERENCE": "PARTIAL WH12",
      "TREND": 2,
      "STD": 86.5332306111357,
      "MEAN": 24,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 4
    },
    {
      "PART NUMBER": 14561585,
      "DESCRIPTION": "VENTING, HYD. EC210B 14524178",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 2,
      "SHELF LIFE": 12.000000000000048,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 141,
      "WHXX": 55,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 12.000000000000048
    },
    {
      "PART NUMBER": 14517943,
      "DESCRIPTION": "BUSHING 9624-11617",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 24,
      "WHXX": 4,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14547552,
      "DESCRIPTION": "SHIM 0.5T",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 91,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14547553,
      "DESCRIPTION": "SHIM 1.0T",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 119,
      "WHXX": 44,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14614180,
      "DESCRIPTION": "BOTTOM ROLLER SN -12151  14568594  EC360B/EC460B/EC480D",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 11,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 17214674,
      "DESCRIPTION": "TANK, EXPANSION EC210B 11110410 ",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.75,
      "MAINTANING STOCK QTY": 3,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 3,
      "APRD QTY": 3,
      "WH12": 2,
      "WHXX": 5,
      "WH REFERENCE": "WHXX",
      "TREND": 1.11,
      "STD": 1.49357598761135,
      "MEAN": 0.692307692307692,
      "COV": 2.15738753766084,
      "SHELF LIFE_1": 4
    },
    {
      "PART NUMBER": 11708829,
      "DESCRIPTION": "O-RING",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 16,
      "WH REFERENCE": "WHXX",
      "TREND": 0,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": 15067640,
      "DESCRIPTION": "GREASE, LITHIUM  EP2 ",
      "PRODUCT CATEGORY": "LUV",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3181,
      "WHXX": 16,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 21141948,
      "DESCRIPTION": "MAIN BEARING KIT 20405516",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.416666666666666,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 2,
      "APRD QTY": 0,
      "WH12": 17,
      "WHXX": 44,
      "WH REFERENCE": "WHXX",
      "TREND": 0,
      "STD": 1.38675049056307,
      "MEAN": 0.384615384615384,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 4.800000000000008
    },
    {
      "PART NUMBER": 4030000047,
      "DESCRIPTION": "OIL SEAL HG4-692-PD150*180*16",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3,
      "WHXX": 3,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 29070001231,
      "DESCRIPTION": "COVER OF OIL SAL",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 29070001071,
      "DESCRIPTION": "BRAKE DISC  ",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 1,
      "WH REFERENCE": "WHXX",
      "TREND": 1,
      "STD": 0.375533808099405,
      "MEAN": 0.153846153846153,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": "C31127",
      "DESCRIPTION": "STOP PIN SB81/A",
      "PRODUCT CATEGORY": "SOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": "C31226",
      "DESCRIPTION": "ROD PIN SB81/A",
      "PRODUCT CATEGORY": "SOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": 20412745,
      "DESCRIPTION": "CORE PLUG",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 14,
      "SHELF LIFE": 84.00000000000034,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 31,
      "WHXX": 35,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 84.00000000000034
    },
    {
      "PART NUMBER": 14550602,
      "DESCRIPTION": "FUEL HOSE 14559325",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 23,
      "WHXX": 4,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4110000556002,
      "DESCRIPTION": "ALTERNATOR 1.5KW (612600090206D) LG956L",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.25,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 6,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 0.67,
      "STD": 0.438529009653514,
      "MEAN": 0.23076923076923,
      "COV": 1.90029237516522,
      "SHELF LIFE_1": 4
    },
    {
      "PART NUMBER": 43922517,
      "DESCRIPTION": "STEERING CYLINDER SD110 13188339 58783507",
      "PRODUCT CATEGORY": "VCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 12746657,
      "DESCRIPTION": "NUT",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 10,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 12746660,
      "DESCRIPTION": "NUT",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 10,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14591830,
      "DESCRIPTION": "FUEL FILL PUMP 14539462 ",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 7,
      "WHXX": 2,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14532688,
      "DESCRIPTION": "FILTER CARTRIDGE, SERVO SYSTEM",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "AVERAGE (F2)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 2.58333333333333,
      "MAINTANING STOCK QTY": 10,
      "AVAILABLE": 8,
      "SHELF LIFE": 3.096774193548391,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 2,
      "APRD QTY": 2,
      "WH12": 46,
      "WHXX": 13,
      "WH REFERENCE": "WHXX",
      "TREND": 0.9,
      "STD": 2.66265724225698,
      "MEAN": 2.38461538461538,
      "COV": 1.11659819836583,
      "SHELF LIFE_1": 3.870967741935489
    },
    {
      "PART NUMBER": 15195958,
      "DESCRIPTION": "GASKET KIT (1 KIT=10PCS)",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 3,
      "SHELF LIFE": 36.000000000000014,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 41,
      "WHXX": 2,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 36.000000000000014
    },
    {
      "PART NUMBER": "C61227",
      "DESCRIPTION": "SSC61227 ROD, (D155) MOIL POINT",
      "PRODUCT CATEGORY": "SOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 15080985,
      "DESCRIPTION": "HOSE",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 20805850,
      "DESCRIPTION": "SEALING",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14562924,
      "DESCRIPTION": "SPRING PACKAGE  EC210B",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 10,
      "WHXX": 10,
      "WH REFERENCE": "WHXX",
      "TREND": 1,
      "STD": 0.375533808099405,
      "MEAN": 0.153846153846153,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": 7200001639,
      "DESCRIPTION": "NEEDLE BEARING 0635303204",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 8,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 20450694,
      "DESCRIPTION": "SCREW",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 7,
      "WHXX": 3,
      "WH REFERENCE": "WHXX",
      "TREND": 1,
      "STD": 0.375533808099405,
      "MEAN": 0.153846153846153,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": 14516446,
      "DESCRIPTION": "ELBOW",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4110000076282,
      "DESCRIPTION": "GASKET 4642 331 216 ?=0.8 4110000076136",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 2,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4110000367051,
      "DESCRIPTION": "GASKET 4644311209 4110000367133 4110001321070 4110001905011 4110001905012",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 4,
      "WHXX": 11,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 21508091,
      "DESCRIPTION": "COVER KIT D13A ENG. 20979871",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 21294062,
      "DESCRIPTION": "GASKET",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 6,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 943481,
      "DESCRIPTION": "HOSE CLAMP",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 4,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14880956,
      "DESCRIPTION": "HOSE",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 5,
      "WHXX": 4,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "XC8286057",
      "DESCRIPTION": "BUCKET ASSY (GP) 0.92M3 G5 VTS EC210B",
      "PRODUCT CATEGORY": "VOA",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 12778469,
      "DESCRIPTION": "HOSE",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 1,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3,
      "WHXX": 3,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 12778473,
      "DESCRIPTION": "HOSE",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 1,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 3,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 21489734,
      "DESCRIPTION": "CABLE HARNESS",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 914515,
      "DESCRIPTION": "RETAINING RING",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 16,
      "WHXX": 4,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4110000076367,
      "DESCRIPTION": "SEALING RING FOR SHAFT ",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 3,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4110000076270,
      "DESCRIPTION": "REGULATING SHIM 0769 120 468",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3,
      "WHXX": 6,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4110000367125,
      "DESCRIPTION": "NEEDLE BEARINGK35*50,06*30 0735 358 069",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 3,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4110000076256,
      "DESCRIPTION": "SEALING RING 0734 317 252",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 3,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4110000076027,
      "DESCRIPTION": "SEALING RING ",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 8,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4110000555021,
      "DESCRIPTION": "BELT, V-RIBBED A3911562 LG938L C3288812 4110000127009",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 29,
      "WHXX": 2,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 21299547,
      "DESCRIPTION": "PISTON RING KIT",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 10,
      "WHXX": 14,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 12779101,
      "DESCRIPTION": "CABLE HARNESS",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 990861,
      "DESCRIPTION": "FLANGE SCREW 946472",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 89,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 976047,
      "DESCRIPTION": "O-RING",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 2,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 13,
      "WHXX": 9,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 20460215,
      "DESCRIPTION": "CLIP",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 5,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4110000556085,
      "DESCRIPTION": "THERMOSTAT ASSEMBLY ",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 0,
      "STD": 0.375533808099405,
      "MEAN": 0.153846153846153,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": 21647837,
      "DESCRIPTION": "TURBOCHARGER, ENG. D6E EAE2 EC210B 20873313 ",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 3,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 11156766,
      "DESCRIPTION": "BOLT ON EDGE L90 / L120",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 11156765,
      "DESCRIPTION": "BOLT ON EDGE L90 / L120",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 21620133,
      "DESCRIPTION": "V RIBBED BELT EC290B",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 10,
      "WHXX": 22,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14536585,
      "DESCRIPTION": "DIODE",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.666666666666666,
      "MAINTANING STOCK QTY": 3,
      "AVAILABLE": 2,
      "SHELF LIFE": 3.000000000000003,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 23,
      "WHXX": 35,
      "WH REFERENCE": "WHXX",
      "TREND": 2,
      "STD": 2.21880078490091,
      "MEAN": 0.615384615384615,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 4.500000000000004
    },
    {
      "PART NUMBER": 11205832,
      "DESCRIPTION": "WIPER ARM",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 5,
      "WHXX": 1,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14550884,
      "DESCRIPTION": "SOLENOID COIL",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.583333333333333,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 2,
      "APRD QTY": 2,
      "WH12": 9,
      "WHXX": 3,
      "WH REFERENCE": "WHXX",
      "TREND": 1.43,
      "STD": 1.12660142429821,
      "MEAN": 0.538461538461538,
      "COV": 2.0922597879824,
      "SHELF LIFE_1": 3.42857142857143
    },
    {
      "PART NUMBER": 20459311,
      "DESCRIPTION": "BELT, FAN (10PK) EC480D",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "AVERAGE (F2)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 2.83333333333333,
      "MAINTANING STOCK QTY": 11,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 11,
      "APRD QTY": 11,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 1.41,
      "STD": 2.93082643459888,
      "MEAN": 2.61538461538461,
      "COV": 1.12061010734663,
      "SHELF LIFE_1": 3.8823529411764754
    },
    {
      "PART NUMBER": 14625659,
      "DESCRIPTION": "SEALING KIT",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 5,
      "WHXX": 2,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 29010011921,
      "DESCRIPTION": "CONTROL CABLE",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 19,
      "WHXX": 2,
      "WH REFERENCE": "WHXX",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": 20795296,
      "DESCRIPTION": "SOLENOID VALVE",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 13478078,
      "DESCRIPTION": "OIL FILTER DD38HF",
      "PRODUCT CATEGORY": "VCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 10,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 54477161,
      "DESCRIPTION": "FUEL FILTER DD38HF",
      "PRODUCT CATEGORY": "VCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 10,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 54477153,
      "DESCRIPTION": "FUEL FILTER DD38HF",
      "PRODUCT CATEGORY": "VCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4120002263101,
      "DESCRIPTION": "SEALING KIT, LIFT CYL. ",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.416666666666666,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 2,
      "APRD QTY": 0,
      "WH12": 11,
      "WHXX": 4,
      "WH REFERENCE": "WHXX",
      "TREND": 0.4,
      "STD": 1.38675049056307,
      "MEAN": 0.384615384615384,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 4.800000000000008
    },
    {
      "PART NUMBER": 4120002264101,
      "DESCRIPTION": "SEALING KIT, TILT CYL, (371368)  LG956L",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 9,
      "WHXX": 1,
      "WH REFERENCE": "WHXX",
      "TREND": 0,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": 4120000553101,
      "DESCRIPTION": "SEALING KIT, STEERING CYL. ",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 2,
      "SHELF LIFE": 6.000000000000006,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 15,
      "WHXX": 2,
      "WH REFERENCE": "",
      "TREND": 1,
      "STD": 1.10940039245045,
      "MEAN": 0.307692307692307,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000006
    },
    {
      "PART NUMBER": 13974079,
      "DESCRIPTION": "ALLEN HEAD SCREW",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 2,
      "STD": 1.10940039245045,
      "MEAN": 0.307692307692307,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 3.000000000000003
    },
    {
      "PART NUMBER": 11700285,
      "DESCRIPTION": "BEARING BUSHING",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 9,
      "WHXX": 10,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 21761263,
      "DESCRIPTION": "TAIL LAMP CLUSTER FM340 21063895",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "C51191",
      "DESCRIPTION": "CHARGING VALVE ASSEMBLY",
      "PRODUCT CATEGORY": "SOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 994000,
      "DESCRIPTION": "ELBOW NIPPLE",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 10,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "7114-12371",
      "DESCRIPTION": "SEAL_RADIAL",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.5,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 2,
      "SHELF LIFE": 4,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 48,
      "WHXX": 21,
      "WH REFERENCE": "",
      "TREND": 1,
      "STD": 0.967417922046845,
      "MEAN": 0.461538461538461,
      "COV": 2.09607216443483,
      "SHELF LIFE_1": 4
    },
    {
      "PART NUMBER": 70932538,
      "DESCRIPTION": "ENGINE ANCHORAGE SD110",
      "PRODUCT CATEGORY": "VCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 3,
      "SHELF LIFE": 9.000000000000009,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 20,
      "WHXX": 14,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 1.10940039245045,
      "MEAN": 0.307692307692307,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 9.000000000000009
    },
    {
      "PART NUMBER": 2851010,
      "DESCRIPTION": "O-RING 2851023",
      "PRODUCT CATEGORY": "SOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 10,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 7,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 2841217,
      "DESCRIPTION": "BACK-UP RING",
      "PRODUCT CATEGORY": "SOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 7,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "C31210",
      "DESCRIPTION": "VALVE ADJUSTER",
      "PRODUCT CATEGORY": "SOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4101306,
      "DESCRIPTION": "HEXAGON NUT",
      "PRODUCT CATEGORY": "SOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 21758906,
      "DESCRIPTION": "INSERT, CAB AIR FILTER FM400 8143691",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 1.41666666666666,
      "MAINTANING STOCK QTY": 6,
      "AVAILABLE": 7,
      "SHELF LIFE": 4.941176470588259,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 60,
      "WHXX": 1,
      "WH REFERENCE": "",
      "TREND": 0.82,
      "STD": 2.81023769411697,
      "MEAN": 1.3076923076923,
      "COV": 2.14900529550121,
      "SHELF LIFE_1": 4.941176470588259
    },
    {
      "PART NUMBER": "B222100000508",
      "DESCRIPTION": "B222100000533 AUXILIARY CARTRIDGE, AIR CLEANER",
      "PRODUCT CATEGORY": "YCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 20459838,
      "DESCRIPTION": "BOLT",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 8,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 23,
      "WHXX": 24,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 11700284,
      "DESCRIPTION": "BEARING BUSHING",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 22,
      "WHXX": 11,
      "WH REFERENCE": "WHXX",
      "TREND": 0,
      "STD": 1.10940039245045,
      "MEAN": 0.307692307692307,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 3.000000000000003
    },
    {
      "PART NUMBER": 29010020351,
      "DESCRIPTION": "CONTROL CABLE",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 9,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 70917182,
      "DESCRIPTION": "CABLE, CONTROL LEVER  SD110",
      "PRODUCT CATEGORY": "VCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 15,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14542155,
      "DESCRIPTION": "AIR FILTER, PRIMARY",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 3,
      "SHELF LIFE": 36.000000000000014,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 18,
      "WHXX": 14,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 36.000000000000014
    },
    {
      "PART NUMBER": 14524470,
      "DESCRIPTION": "OIL FILTER  EC55B/EW60C 14523962",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.25,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 1,
      "SHELF LIFE": 4,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 19,
      "WHXX": 8,
      "WH REFERENCE": "",
      "TREND": 1.33,
      "STD": 0.599144689515278,
      "MEAN": 0.23076923076923,
      "COV": 2.5962936545662,
      "SHELF LIFE_1": 4
    },
    {
      "PART NUMBER": 11039847,
      "DESCRIPTION": "WORK LAMP",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 8,
      "WHXX": 10,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14612048,
      "DESCRIPTION": "FILTER, A/C CONDENSER  EC210B  SN 38328~/ 74214~",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.75,
      "MAINTANING STOCK QTY": 3,
      "AVAILABLE": 3,
      "SHELF LIFE": 4,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 35,
      "WHXX": 15,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 1.18213192894697,
      "MEAN": 0.692307692307692,
      "COV": 1.70752389736785,
      "SHELF LIFE_1": 4
    },
    {
      "PART NUMBER": 14881287,
      "DESCRIPTION": "V-BELT",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 3,
      "SHELF LIFE": 18.000000000000075,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 21,
      "WHXX": 5,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 18.000000000000075
    },
    {
      "PART NUMBER": 990603,
      "DESCRIPTION": "O-RING",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 1,
      "SHELF LIFE": 6.000000000000024,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 21,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": 14589150,
      "DESCRIPTION": "SEALING KIT",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 3,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14632775,
      "DESCRIPTION": "V-BELT 15074767",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 1,
      "SHELF LIFE": 6.000000000000024,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 5,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": 14622356,
      "DESCRIPTION": "BOWL, EXTRA WATER SEPARATOR",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 1,
      "SHELF LIFE": 6.000000000000024,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 4,
      "WHXX": 3,
      "WH REFERENCE": "",
      "TREND": 1,
      "STD": 0.375533808099405,
      "MEAN": 0.153846153846153,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": 14594697,
      "DESCRIPTION": "V-ECU EC210BLR",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4804563,
      "DESCRIPTION": "TENSIONING BAND",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 4,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": 43993021,
      "DESCRIPTION": "HOUSING",
      "PRODUCT CATEGORY": "VCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 1,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 960631,
      "DESCRIPTION": "PLUG 9415-22051",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 17,
      "WHXX": 7,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 976041,
      "DESCRIPTION": "O-RING",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 9,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": 468947,
      "DESCRIPTION": "SEALING RING",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 13,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14511752,
      "DESCRIPTION": "SEAL",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.25,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 18,
      "WHXX": 8,
      "WH REFERENCE": "WHXX",
      "TREND": 0.67,
      "STD": 0.599144689515278,
      "MEAN": 0.23076923076923,
      "COV": 2.5962936545662,
      "SHELF LIFE_1": 4
    },
    {
      "PART NUMBER": "7114-11950",
      "DESCRIPTION": "SEAL_DUST",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.25,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 16,
      "WHXX": 10,
      "WH REFERENCE": "WHXX",
      "TREND": 0.67,
      "STD": 0.599144689515278,
      "MEAN": 0.23076923076923,
      "COV": 2.5962936545662,
      "SHELF LIFE_1": 4
    },
    {
      "PART NUMBER": 59190975,
      "DESCRIPTION": "PIVOT PIN",
      "PRODUCT CATEGORY": "VCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 2851051,
      "DESCRIPTION": "O-RING SB81A SB121",
      "PRODUCT CATEGORY": "SOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 2844901,
      "DESCRIPTION": "BACK-UP RING SB81A SB121",
      "PRODUCT CATEGORY": "SOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 2850014,
      "DESCRIPTION": "O-RING SB81A",
      "PRODUCT CATEGORY": "SOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 2850003,
      "DESCRIPTION": "O-RING SB81A",
      "PRODUCT CATEGORY": "SOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 9,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 11705143,
      "DESCRIPTION": "O-RING",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 13,
      "WHXX": 20,
      "WH REFERENCE": "WHXX",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": 14630764,
      "DESCRIPTION": "RELAY_24V G930/EC210B/EC460B/EC700B/A35D/L120E/G930 14594545 14376598",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.25,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 5,
      "SHELF LIFE": 20,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 16,
      "WHXX": 17,
      "WH REFERENCE": "",
      "TREND": 0.67,
      "STD": 0.438529009653514,
      "MEAN": 0.23076923076923,
      "COV": 1.90029237516522,
      "SHELF LIFE_1": 20
    },
    {
      "PART NUMBER": 21598183,
      "DESCRIPTION": "TURBOCHARGER, ENG. D6E EAE3 EC210C 20933297",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 1,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 1,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 46678140,
      "DESCRIPTION": "FAN, COOLING SD110",
      "PRODUCT CATEGORY": "VCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 2,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 95098679,
      "DESCRIPTION": "SPRING PIN",
      "PRODUCT CATEGORY": "VCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 8,
      "WHXX": 13,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14519262,
      "DESCRIPTION": "AIR FILTER, SAFETY EC55B 15193234 ",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.416666666666666,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 3,
      "SHELF LIFE": 7.200000000000011,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 110,
      "WHXX": 8,
      "WH REFERENCE": "",
      "TREND": 1.2,
      "STD": 0.767947647788304,
      "MEAN": 0.384615384615384,
      "COV": 1.99666388424959,
      "SHELF LIFE_1": 7.200000000000011
    },
    {
      "PART NUMBER": 3938162,
      "DESCRIPTION": "GASKET, OIL PAN",
      "PRODUCT CATEGORY": "CUG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 17225331,
      "DESCRIPTION": "KEY",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 2,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 993320,
      "DESCRIPTION": "O-RING L330C/BL71 932039",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.5,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 2,
      "SHELF LIFE": 4,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 131,
      "WHXX": 23,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 1.66410058867568,
      "MEAN": 0.461538461538461,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 4
    },
    {
      "PART NUMBER": "150-70-21346",
      "DESCRIPTION": "BIT END, RH",
      "PRODUCT CATEGORY": "SDG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 1,
      "MAINTANING STOCK QTY": 4,
      "AVAILABLE": 1,
      "SHELF LIFE": 1,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 3,
      "APRD QTY": 0,
      "WH12": 5,
      "WHXX": 1,
      "WH REFERENCE": "WH12 + WHXX",
      "TREND": 0.17,
      "STD": 2.17797836159814,
      "MEAN": 0.923076923076923,
      "COV": 2.35947655839799,
      "SHELF LIFE_1": 4
    },
    {
      "PART NUMBER": "150-70-21356",
      "DESCRIPTION": "END BIT, LH",
      "PRODUCT CATEGORY": "SDG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 1,
      "MAINTANING STOCK QTY": 4,
      "AVAILABLE": 1,
      "SHELF LIFE": 1,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 3,
      "APRD QTY": 0,
      "WH12": 5,
      "WHXX": 1,
      "WH REFERENCE": "WH12 + WHXX",
      "TREND": 0.17,
      "STD": 2.17797836159814,
      "MEAN": 0.923076923076923,
      "COV": 2.35947655839799,
      "SHELF LIFE_1": 4
    },
    {
      "PART NUMBER": "154-70-11314",
      "DESCRIPTION": "EDGE CUTTING-SD22",
      "PRODUCT CATEGORY": "SDG",
      "ITEM MOVEMENT BRANCH": "AVERAGE (F2)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 1.08333333333333,
      "MAINTANING STOCK QTY": 4,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 4,
      "APRD QTY": 0,
      "WH12": 6,
      "WHXX": 1,
      "WH REFERENCE": "WH12 + WHXX",
      "TREND": 0.15,
      "STD": 1.95789002074512,
      "MEAN": 1,
      "COV": 1.95789002074512,
      "SHELF LIFE_1": 3.692307692307704
    },
    {
      "PART NUMBER": "175-78-31230",
      "DESCRIPTION": "POINT, RIPPER",
      "PRODUCT CATEGORY": "SDG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 1,
      "MAINTANING STOCK QTY": 4,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 4,
      "APRD QTY": 0,
      "WH12": 7,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 0,
      "STD": 3.32820117735137,
      "MEAN": 0.923076923076923,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 4
    },
    {
      "PART NUMBER": 14507432,
      "DESCRIPTION": "ROCKER SWITCH",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 1,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 12746650,
      "DESCRIPTION": "SCREW",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.5,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 2,
      "APRD QTY": 2,
      "WH12": 0,
      "WHXX": 11,
      "WH REFERENCE": "WHXX",
      "TREND": 2,
      "STD": 1.66410058867568,
      "MEAN": 0.461538461538461,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 4
    },
    {
      "PART NUMBER": "C61139",
      "DESCRIPTION": "DIAPHRAGM SU+85/SB121",
      "PRODUCT CATEGORY": "SOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.25,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 3,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 0.67,
      "STD": 0.599144689515278,
      "MEAN": 0.23076923076923,
      "COV": 2.5962936545662,
      "SHELF LIFE_1": 4
    },
    {
      "PART NUMBER": 14508698,
      "DESCRIPTION": "PANEL",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 20482732,
      "DESCRIPTION": "BOLT",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 3,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 20551332,
      "DESCRIPTION": "CABLE HARNESS",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3,
      "WHXX": 4,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 15011845,
      "DESCRIPTION": "BRAKE DISC L120F",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 15603435,
      "DESCRIPTION": "TOOTH, BUCKET BL71",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.416666666666666,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 2,
      "APRD QTY": 2,
      "WH12": 51,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 2,
      "STD": 1.38675049056307,
      "MEAN": 0.384615384615384,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 4.800000000000008
    },
    {
      "PART NUMBER": 15603422,
      "DESCRIPTION": "PIN, TOOTH BL71",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.416666666666666,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 2,
      "APRD QTY": 2,
      "WH12": 85,
      "WHXX": 8,
      "WH REFERENCE": "WHXX",
      "TREND": 2,
      "STD": 1.38675049056307,
      "MEAN": 0.384615384615384,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 4.800000000000008
    },
    {
      "PART NUMBER": 4130000427,
      "DESCRIPTION": "POWER SWITCH ",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 12,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 20482731,
      "DESCRIPTION": "BRACKET",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 2,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 13959262,
      "DESCRIPTION": "60112995 HEX. SOCKET SCREW",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.416666666666666,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 2,
      "APRD QTY": 2,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 2,
      "STD": 1.38675049056307,
      "MEAN": 0.384615384615384,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 4.800000000000008
    },
    {
      "PART NUMBER": 11055748,
      "DESCRIPTION": "WASHER",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 3,
      "WH REFERENCE": "WHXX",
      "TREND": 1,
      "STD": 0.75106761619881,
      "MEAN": 0.307692307692307,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 3.000000000000003
    },
    {
      "PART NUMBER": 14542156,
      "DESCRIPTION": "FILTER, SAFETY 15193236 ",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 3,
      "SHELF LIFE": 36.000000000000014,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 8,
      "WHXX": 18,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 36.000000000000014
    },
    {
      "PART NUMBER": 4190000138,
      "DESCRIPTION": "FAN, COOLING ",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 1,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14536511,
      "DESCRIPTION": "WATER SEPARATOR, EC55B",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.583333333333333,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 3,
      "SHELF LIFE": 5.142857142857146,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 20,
      "WHXX": 13,
      "WH REFERENCE": "",
      "TREND": 1.43,
      "STD": 1.05003052458683,
      "MEAN": 0.538461538461538,
      "COV": 1.9500566885184,
      "SHELF LIFE_1": 5.142857142857146
    },
    {
      "PART NUMBER": 13980834,
      "DESCRIPTION": "TUBE",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 4,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 15052786,
      "DESCRIPTION": "CAB FILTER BL71/L60/70E/L120E/L150E/F/A30E/A35D/E 11703979",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "FAST (F1)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 6.25,
      "MAINTANING STOCK QTY": 25,
      "AVAILABLE": 7,
      "SHELF LIFE": 1.12,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 18,
      "APRD QTY": 18,
      "WH12": 0,
      "WHXX": 19,
      "WH REFERENCE": "WHXX",
      "TREND": 1.01,
      "STD": 3.51553329652477,
      "MEAN": 5.76923076923076,
      "COV": 0.609359104730961,
      "SHELF LIFE_1": 4
    },
    {
      "PART NUMBER": 79509,
      "DESCRIPTION": "PINION",
      "PRODUCT CATEGORY": "VGG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 29220001321,
      "DESCRIPTION": "TUBE OF AXLE",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14621335,
      "DESCRIPTION": "EXTRA FWS FILTER ASSY  EC210BLC",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 2,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 1,
      "STD": 0.375533808099405,
      "MEAN": 0.153846153846153,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": 14647065,
      "DESCRIPTION": "HOSE 14586973",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 1,
      "SHELF LIFE": 6.000000000000024,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 7,
      "WHXX": 2,
      "WH REFERENCE": "",
      "TREND": 1,
      "STD": 0.375533808099405,
      "MEAN": 0.153846153846153,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": 14530607,
      "DESCRIPTION": "HEADLAMP",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 2,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "213379Z00B",
      "DESCRIPTION": "BOWL",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 2,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "801019Z00A",
      "DESCRIPTION": "5224079620 DOOR WINDOW PANE",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "331769Z00A",
      "DESCRIPTION": "SWITCH",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 7,
      "WHXX": 3,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "333419Z00A",
      "DESCRIPTION": "PIN",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.5,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 2,
      "APRD QTY": 0,
      "WH12": 21,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 0,
      "STD": 1.66410058867568,
      "MEAN": 0.461538461538461,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 4
    },
    {
      "PART NUMBER": 4030000311,
      "DESCRIPTION": "PLUG",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 15,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "A222100000393",
      "DESCRIPTION": "FILTER CORE ",
      "PRODUCT CATEGORY": "YPG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 6,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": 4110000076352,
      "DESCRIPTION": "SPECIAL SEALING RING ",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 6,
      "WHXX": 2,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4110000076268,
      "DESCRIPTION": "REGULATING ",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 8,
      "WHXX": 3,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4110000367099,
      "DESCRIPTION": "THRUST WASHER 0730 150 759",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 16,
      "WHXX": 3,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4110000727085,
      "DESCRIPTION": "14400779  OIL FILTER",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 29070000591,
      "DESCRIPTION": "SPACER(GASKET)",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 13,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "C3X005",
      "DESCRIPTION": "C3X005ES SEAL KIT SB81",
      "PRODUCT CATEGORY": "SOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14643849,
      "DESCRIPTION": "TRANSFER GEARBOX",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 20838727,
      "DESCRIPTION": "SWITCH 14531840",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 1,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 5,
      "WHXX": 2,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 11428148,
      "DESCRIPTION": "ROCKER ARM",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 1,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 4,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 15178089,
      "DESCRIPTION": "ORING 11705252",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 31,
      "WHXX": 12,
      "WH REFERENCE": "WHXX",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": "23Y-58D-15000",
      "DESCRIPTION": "AC SWITCH PEDAL CONTROL",
      "PRODUCT CATEGORY": "SDG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "911119Z01D",
      "DESCRIPTION": "FLANGE NUT",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 13,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 178,
      "WHXX": 142,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 11701842,
      "DESCRIPTION": "ADAPTER",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 0,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": 14641479,
      "DESCRIPTION": "FILLER CAP 14626785 14528922 14529069",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3,
      "WHXX": 3,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 20459172,
      "DESCRIPTION": "SEALING RING",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 7,
      "WHXX": 6,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "306209Z00B",
      "DESCRIPTION": "MASTER CYLINDER, 24-38",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.833333333333333,
      "MAINTANING STOCK QTY": 3,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 3,
      "APRD QTY": 0,
      "WH12": 3,
      "WHXX": 2,
      "WH REFERENCE": "WH12 + WHXX",
      "TREND": 0,
      "STD": 2.77350098112614,
      "MEAN": 0.769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 3.6000000000000014
    },
    {
      "PART NUMBER": "550209Z00A",
      "DESCRIPTION": "LEAF SPRING",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.916666666666666,
      "MAINTANING STOCK QTY": 4,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 4,
      "APRD QTY": 4,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 2,
      "STD": 2.7642405179408,
      "MEAN": 0.846153846153846,
      "COV": 3.26682970302095,
      "SHELF LIFE_1": 4.363636363636367
    },
    {
      "PART NUMBER": "295579Z00A",
      "DESCRIPTION": "VALVE",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 13,
      "WHXX": 8,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "911119Z02C",
      "DESCRIPTION": "FLANGE NUT",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 4,
      "SHELF LIFE": 12.000000000000012,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 63,
      "WHXX": 62,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 1.10940039245045,
      "MEAN": 0.307692307692307,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 12.000000000000012
    },
    {
      "PART NUMBER": 4110000592008,
      "DESCRIPTION": "UPPER RADIATOR HOSE 4110001985005",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 11,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": "4514904Z2E",
      "DESCRIPTION": "T-NIPPLE",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.833333333333333,
      "MAINTANING STOCK QTY": 3,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 3,
      "APRD QTY": 0,
      "WH12": 28,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 0,
      "STD": 2.77350098112614,
      "MEAN": 0.769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 3.6000000000000014
    },
    {
      "PART NUMBER": "333029Z00E",
      "DESCRIPTION": "GASKET",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 25,
      "WHXX": 9,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "333199Z00A",
      "DESCRIPTION": "SHIM",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 13,
      "WHXX": 10,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "333569Z00A",
      "DESCRIPTION": "SEALING RING",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3,
      "WHXX": 7,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "333029Z00D",
      "DESCRIPTION": "GASKET",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 8,
      "WHXX": 9,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "995499Z00C",
      "DESCRIPTION": "HOSE ASSEMBLY",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 17,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "307059Z00C",
      "DESCRIPTION": "CLUTCH SERVO",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 5,
      "WHXX": 7,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "542209Z00A",
      "DESCRIPTION": "BUMP STOP(RUBBER)",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 10,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "542199Z00A",
      "DESCRIPTION": "U-BOLT",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 1.58333333333333,
      "MAINTANING STOCK QTY": 6,
      "AVAILABLE": 4,
      "SHELF LIFE": 2.52631578947369,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 2,
      "APRD QTY": 0,
      "WH12": 36,
      "WHXX": 10,
      "WH REFERENCE": "WHXX",
      "TREND": 0.63,
      "STD": 2.84650032541085,
      "MEAN": 1.46153846153846,
      "COV": 1.94760548580742,
      "SHELF LIFE_1": 3.7894736842105345
    },
    {
      "PART NUMBER": "542199Z00D",
      "DESCRIPTION": "U-BOLT ANCHORAGE",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "405669Z00B",
      "DESCRIPTION": "LUBRICATING NIPPLE",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 2.16666666666666,
      "MAINTANING STOCK QTY": 9,
      "AVAILABLE": 9,
      "SHELF LIFE": 4.153846153846167,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 66,
      "WHXX": 65,
      "WH REFERENCE": "",
      "TREND": 0.31,
      "STD": 5.53774924194538,
      "MEAN": 2,
      "COV": 2.76887462097269,
      "SHELF LIFE_1": 4.153846153846167
    },
    {
      "PART NUMBER": "282299Z00A",
      "DESCRIPTION": "FLANGE SCREW",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 4,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 20,
      "WHXX": 25,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "282299Z03B",
      "DESCRIPTION": "FLANGE SCREW",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 33,
      "WHXX": 38,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "911119Z03E",
      "DESCRIPTION": "FLANGE NUT ADAPTED QTY 25",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 3,
      "MAINTANING STOCK QTY": 12,
      "AVAILABLE": 12,
      "SHELF LIFE": 4,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 110,
      "WHXX": 6,
      "WH REFERENCE": "",
      "TREND": 0.67,
      "STD": 6.19346222713712,
      "MEAN": 2.76923076923076,
      "COV": 2.23652802646618,
      "SHELF LIFE_1": 4
    },
    {
      "PART NUMBER": "555469Z00D",
      "DESCRIPTION": "V-STAY",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "201269Z01E",
      "DESCRIPTION": "LOCK NUT",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 10,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "475119Z00E",
      "DESCRIPTION": "HEXAGON SCREW",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 11,
      "WHXX": 20,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "951489Z00C",
      "DESCRIPTION": "COMPRESSION SPRING",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 7,
      "WH REFERENCE": "WHXX",
      "TREND": 0,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": 14588541,
      "DESCRIPTION": "YOKE",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4110000991005,
      "DESCRIPTION": "ALTERNATOR LG936  ",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 1,
      "STD": 0.375533808099405,
      "MEAN": 0.153846153846153,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": 20551429,
      "DESCRIPTION": "FITTING",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 6,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 20460936,
      "DESCRIPTION": "HOLLOW SCREW",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 5,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14532285,
      "DESCRIPTION": "SPRING",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "4120000675Z",
      "DESCRIPTION": "PNEUMATIC CYLINDER",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.666666666666666,
      "MAINTANING STOCK QTY": 3,
      "AVAILABLE": 3,
      "SHELF LIFE": 4.500000000000004,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 54,
      "WHXX": 13,
      "WH REFERENCE": "",
      "TREND": 1,
      "STD": 1.12089707663561,
      "MEAN": 0.615384615384615,
      "COV": 1.82145774953286,
      "SHELF LIFE_1": 4.500000000000004
    },
    {
      "PART NUMBER": "4130000418A",
      "DESCRIPTION": "THERMOSTAT 4130000418",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4110000189022,
      "DESCRIPTION": "STARTER MOTOR  4110000909089",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "308129Z00B",
      "DESCRIPTION": "PIPE SET",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 8,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "452059Z00C",
      "DESCRIPTION": "LOAD SENSING VALVE",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "475119Z02C",
      "DESCRIPTION": "HEXAGON SCREW",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 15,
      "WHXX": 10,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4110001593002,
      "DESCRIPTION": "FILTER, FWS  4110001016004  ",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "AVERAGE (F2)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 1.16666666666666,
      "MAINTANING STOCK QTY": 5,
      "AVAILABLE": 1,
      "SHELF LIFE": 0.857142857142862,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 4,
      "APRD QTY": 4,
      "WH12": 25,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 1.29,
      "STD": 1.25575597825496,
      "MEAN": 1.07692307692307,
      "COV": 1.16605912266532,
      "SHELF LIFE_1": 4.28571428571431
    },
    {
      "PART NUMBER": 4110000189031,
      "DESCRIPTION": " FUEL FILTER INSERT",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 1,
      "SHELF LIFE": 6.000000000000024,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 1,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": 960247,
      "DESCRIPTION": "O-RING",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 13,
      "WHXX": 37,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "4514904Z2C",
      "DESCRIPTION": "ELBOW NIPPLE",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.833333333333333,
      "MAINTANING STOCK QTY": 3,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 3,
      "APRD QTY": 0,
      "WH12": 30,
      "WHXX": 49,
      "WH REFERENCE": "WHXX",
      "TREND": 0,
      "STD": 2.77350098112614,
      "MEAN": 0.769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 3.6000000000000014
    },
    {
      "PART NUMBER": "382179Z00A",
      "DESCRIPTION": "ORING 146129Z00D",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 1,
      "SHELF LIFE": 6.000000000000024,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 1,
      "STD": 0.375533808099405,
      "MEAN": 0.153846153846153,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": "995499Z00D",
      "DESCRIPTION": "HOSE ASSEMBLY",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 11,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "B222100000257",
      "DESCRIPTION": "FILTER CORE  B222100000212  HF6870",
      "PRODUCT CATEGORY": "YCG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 1,
      "SHELF LIFE": 6.000000000000024,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": 14535346,
      "DESCRIPTION": "ANTENNA",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 2,
      "WH REFERENCE": "WHXX",
      "TREND": 1,
      "STD": 0.375533808099405,
      "MEAN": 0.153846153846153,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": 14511974,
      "DESCRIPTION": "ROD",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "145849Z06A",
      "DESCRIPTION": "NIPPLE",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.833333333333333,
      "MAINTANING STOCK QTY": 3,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 3,
      "APRD QTY": 0,
      "WH12": 36,
      "WHXX": 9,
      "WH REFERENCE": "WHXX",
      "TREND": 0,
      "STD": 2.77350098112614,
      "MEAN": 0.769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 3.6000000000000014
    },
    {
      "PART NUMBER": 17216674,
      "DESCRIPTION": "SEALING KIT  HB21",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3,
      "WHXX": 3,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 17216672,
      "DESCRIPTION": "T/BOLT SET  HB21",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 1,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 1,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "432409Z00B",
      "DESCRIPTION": "5222455512  WHEEL STUD",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 2.5,
      "MAINTANING STOCK QTY": 10,
      "AVAILABLE": 3,
      "SHELF LIFE": 1.2,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 7,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 0,
      "STD": 8.32050294337843,
      "MEAN": 2.3076923076923,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 4
    },
    {
      "PART NUMBER": "207049Z00C",
      "DESCRIPTION": "HEAT SHIELD",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "381669Z09E",
      "DESCRIPTION": "FLANGE SCREW",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 25,
      "WHXX": 25,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "963019Z01B",
      "DESCRIPTION": "MIRROR KIT",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3,
      "WHXX": 7,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "C61451",
      "DESCRIPTION": "SEAL KIT/NOK/SU+125                        ",
      "PRODUCT CATEGORY": "SOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 11193390,
      "DESCRIPTION": "CABLE",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 979008,
      "DESCRIPTION": "FUSE 40A 46675237",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 8,
      "WHXX": 8,
      "WH REFERENCE": "WHXX",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": 14550598,
      "DESCRIPTION": "BUSHING",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 0,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": 14532448,
      "DESCRIPTION": "NUT, SPRING PACKAGE  EC140B",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 3,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14527463,
      "DESCRIPTION": "BOLT",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4013000205,
      "DESCRIPTION": "NUT, PLOW BOLT  ",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.916666666666666,
      "MAINTANING STOCK QTY": 4,
      "AVAILABLE": 6,
      "SHELF LIFE": 6.54545454545455,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 601,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 3.05085107923876,
      "MEAN": 0.846153846153846,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.54545454545455
    },
    {
      "PART NUMBER": 4110000556161,
      "DESCRIPTION": "CYLINDER LINER ",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.5,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 2,
      "APRD QTY": 2,
      "WH12": 16,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 2,
      "STD": 1.66410058867568,
      "MEAN": 0.461538461538461,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 4
    },
    {
      "PART NUMBER": 4110000556139,
      "DESCRIPTION": "SEALING RING ",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 55,
      "WHXX": 12,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4110000556066,
      "DESCRIPTION": "PISTON RING GROUP  WD10G220  612600030051  LG956L",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 1,
      "MAINTANING STOCK QTY": 4,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 4,
      "APRD QTY": 4,
      "WH12": 2,
      "WHXX": 1,
      "WH REFERENCE": "PARTIAL WHXX + WH12",
      "TREND": 2,
      "STD": 3.32820117735137,
      "MEAN": 0.923076923076923,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 4
    },
    {
      "PART NUMBER": 4110000556030,
      "DESCRIPTION": "PLAIN WASHER 30*53*1DIN71511 90003069303",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4110000924025,
      "DESCRIPTION": "WASHER  4110001525021",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4110000556087,
      "DESCRIPTION": "4110004228004 GASKET, INTAKE MANIFOLD ",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.5,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 6,
      "SHELF LIFE": 12,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 8,
      "WHXX": 6,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 1.66410058867568,
      "MEAN": 0.461538461538461,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 12
    },
    {
      "PART NUMBER": 4110000556142,
      "DESCRIPTION": "GASKET 4110000924171",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.5,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 2,
      "APRD QTY": 0,
      "WH12": 38,
      "WHXX": 6,
      "WH REFERENCE": "WHXX",
      "TREND": 0,
      "STD": 1.66410058867568,
      "MEAN": 0.461538461538461,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 4
    },
    {
      "PART NUMBER": 4110000556165,
      "DESCRIPTION": "SEALING SLEEVE ",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 281,
      "WHXX": 79,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4110000556155,
      "DESCRIPTION": "CYLINDER HEAD GASKET ",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 1,
      "MAINTANING STOCK QTY": 4,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 4,
      "APRD QTY": 0,
      "WH12": 95,
      "WHXX": 64,
      "WH REFERENCE": "WHXX",
      "TREND": 1,
      "STD": 2.25320284859643,
      "MEAN": 0.923076923076923,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 4
    },
    {
      "PART NUMBER": 85136370,
      "DESCRIPTION": "VOLVO GEARBOX OIL SAE50  80W90 -20L 85136371",
      "PRODUCT CATEGORY": "LUV",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 12.3333333333333,
      "MAINTANING STOCK QTY": 49,
      "AVAILABLE": 44,
      "SHELF LIFE": 3.5675675675675773,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 5,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 24,
      "WH REFERENCE": "WHXX",
      "TREND": 1.46,
      "STD": 21.0931997791486,
      "MEAN": 11.3846153846153,
      "COV": 1.85278106168197,
      "SHELF LIFE_1": 3.9729729729729835
    },
    {
      "PART NUMBER": "2001700Z0A",
      "DESCRIPTION": "GASKET-EXH TUBE",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 9,
      "WHXX": 8,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "2002200Z0A",
      "DESCRIPTION": "V-CLAMP",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3,
      "WHXX": 19,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "146769Z00E",
      "DESCRIPTION": "GASKET",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 42,
      "WHXX": 9,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 29350009031,
      "DESCRIPTION": "COMPRESSOR EARTH LINE",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.25,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 2,
      "STD": 0.599144689515278,
      "MEAN": 0.23076923076923,
      "COV": 2.5962936545662,
      "SHELF LIFE_1": 4
    },
    {
      "PART NUMBER": "145849Z00D",
      "DESCRIPTION": "ELBOW NIPPLE",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 1.08333333333333,
      "MAINTANING STOCK QTY": 4,
      "AVAILABLE": 4,
      "SHELF LIFE": 3.692307692307704,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 20,
      "WHXX": 6,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 2.76887462097269,
      "MEAN": 1,
      "COV": 2.76887462097269,
      "SHELF LIFE_1": 3.692307692307704
    },
    {
      "PART NUMBER": "4514904Z5A",
      "DESCRIPTION": "ANGLE RING",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 20,
      "WH REFERENCE": "WHXX",
      "TREND": 0,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": "4514904Z3B",
      "DESCRIPTION": "3-WAY NIPPLE",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 1,
      "MAINTANING STOCK QTY": 4,
      "AVAILABLE": 4,
      "SHELF LIFE": 4,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 25,
      "WHXX": 8,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 2.78273062720589,
      "MEAN": 0.923076923076923,
      "COV": 3.01462484613972,
      "SHELF LIFE_1": 4
    },
    {
      "PART NUMBER": 28140006791,
      "DESCRIPTION": "BOLT, CUTTING EDGE  LG9190",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 13,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 317,
      "WHXX": 24,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4043000218,
      "DESCRIPTION": "BUSH ",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4043000112,
      "DESCRIPTION": "WELD PIN LGB301-60*95*170G-40CR",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 45,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4190002758,
      "DESCRIPTION": "COMPRESSOR",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.916666666666666,
      "MAINTANING STOCK QTY": 4,
      "AVAILABLE": 2,
      "SHELF LIFE": 2.1818181818181834,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 2,
      "APRD QTY": 2,
      "WH12": 1,
      "WHXX": 1,
      "WH REFERENCE": "WH12 + WHXX",
      "TREND": 1.45,
      "STD": 1.14354374979373,
      "MEAN": 0.846153846153846,
      "COV": 1.35146079521077,
      "SHELF LIFE_1": 4.363636363636367
    },
    {
      "PART NUMBER": "B229900003708",
      "DESCRIPTION": "ACCUMULATOR A229900000020",
      "PRODUCT CATEGORY": "YPG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "381659Z00A",
      "DESCRIPTION": "GASKET",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 22,
      "WHXX": 33,
      "WH REFERENCE": "WHXX",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": "321369Z00A",
      "DESCRIPTION": "O-RING",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 3.41666666666666,
      "MAINTANING STOCK QTY": 14,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 14,
      "APRD QTY": 14,
      "WH12": 11,
      "WHXX": 38,
      "WH REFERENCE": "WHXX",
      "TREND": 1.85,
      "STD": 9.09987320283598,
      "MEAN": 3.15384615384615,
      "COV": 2.8853256496797,
      "SHELF LIFE_1": 4.097560975609764
    },
    {
      "PART NUMBER": 5333477,
      "DESCRIPTION": "BELT TENSIONER",
      "PRODUCT CATEGORY": "CUG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 1,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14663703,
      "DESCRIPTION": "NUT, T/S BOLT EC460B 14880576 ",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 5.16666666666666,
      "MAINTANING STOCK QTY": 21,
      "AVAILABLE": 13,
      "SHELF LIFE": 2.5161290322580676,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 8,
      "APRD QTY": 8,
      "WH12": 2074,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 1.13,
      "STD": 8.14610178913659,
      "MEAN": 4.76923076923076,
      "COV": 1.70805360094799,
      "SHELF LIFE_1": 4.064516129032263
    },
    {
      "PART NUMBER": "550459Z00E",
      "DESCRIPTION": "BUSHING KIT 550459Z00C",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 13,
      "WHXX": 1,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "261369Z01C",
      "DESCRIPTION": "STAY",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 7,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "201349Z00A",
      "DESCRIPTION": "BRACKET",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 7,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "969229Z01A ",
      "DESCRIPTION": "BLIND RIVET",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 10,
      "WHXX": 50,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14621172,
      "DESCRIPTION": "BRACKET",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 1,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14623356,
      "DESCRIPTION": "CABLE HARNESS",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 936439,
      "DESCRIPTION": "TESTING NIPPLE 58853292",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 15113226,
      "DESCRIPTION": "V-CLAMP 1629499",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 6,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 0,
      "STD": 1.10940039245045,
      "MEAN": 0.307692307692307,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 3.000000000000003
    },
    {
      "PART NUMBER": 21119083,
      "DESCRIPTION": "GASKET",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 8,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 0,
      "STD": 1.10940039245045,
      "MEAN": 0.307692307692307,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 3.000000000000003
    },
    {
      "PART NUMBER": "263109Z00B",
      "DESCRIPTION": "HORN",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 15,
      "WHXX": 4,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "654189Z00A",
      "DESCRIPTION": "GAS SPRING",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 24,
      "WHXX": 12,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "322919Z01B",
      "DESCRIPTION": "GASKET",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 10,
      "WHXX": 12,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "329349Z00B",
      "DESCRIPTION": "GASKET",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 57,
      "WHXX": 40,
      "WH REFERENCE": "WHXX",
      "TREND": 0,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": "324979Z00C",
      "DESCRIPTION": "GASKET",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 11,
      "WHXX": 9,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "798499Z00B",
      "DESCRIPTION": "RETAINER",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 18,
      "WHXX": 25,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "324979Z00D",
      "DESCRIPTION": "GASKET",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 1,
      "SHELF LIFE": 6.000000000000024,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 18,
      "WHXX": 26,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": 14671313,
      "DESCRIPTION": "SWITCH 14626841",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 4,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4021000029,
      "DESCRIPTION": "ROLLING BEARING ",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 1,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4021000028,
      "DESCRIPTION": "BEARING ",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 6,
      "WHXX": 2,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4021000035,
      "DESCRIPTION": "ROLLING BEARING ",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 1,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4021000026,
      "DESCRIPTION": "ROLLING BEARING ",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "L01110",
      "DESCRIPTION": "RING BUSH  SU+85",
      "PRODUCT CATEGORY": "SOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "272129Z00B",
      "DESCRIPTION": "EVAPORATOR",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.5,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 2,
      "SHELF LIFE": 4,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 6,
      "WHXX": 6,
      "WH REFERENCE": "",
      "TREND": 0.33,
      "STD": 0.776250025806184,
      "MEAN": 0.461538461538461,
      "COV": 1.6818750559134,
      "SHELF LIFE_1": 4
    },
    {
      "PART NUMBER": 9021306350,
      "DESCRIPTION": "STARTER APPARATUS, REMAN EC210B 21306350",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 22,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "B230101001367",
      "DESCRIPTION": "RETAINER RING 118.7√Ó125√Ó1.5 HYDAC",
      "PRODUCT CATEGORY": "YPG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.583333333333333,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 2,
      "APRD QTY": 0,
      "WH12": 7,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 0.29,
      "STD": 1.66410058867568,
      "MEAN": 0.538461538461538,
      "COV": 3.09047252182627,
      "SHELF LIFE_1": 3.42857142857143
    },
    {
      "PART NUMBER": "B230101001366",
      "DESCRIPTION": "O-RING117",
      "PRODUCT CATEGORY": "YPG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 1,
      "SHELF LIFE": 6.000000000000024,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 9,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": 4110000054003,
      "DESCRIPTION": "O-SEAL ",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 14,
      "WHXX": 17,
      "WH REFERENCE": "WHXX",
      "TREND": 0,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": 4110000054002,
      "DESCRIPTION": "O-SEAL ",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 8,
      "WHXX": 23,
      "WH REFERENCE": "WHXX",
      "TREND": 0,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": 4110000054100,
      "DESCRIPTION": "SEALING RING, CRANKCASE ",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 1,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4110000846035,
      "DESCRIPTION": "SEALING RING, FLYWHEEL ",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4110000054284,
      "DESCRIPTION": "PUMP GASKET 12158513",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 19,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4110000054006,
      "DESCRIPTION": "O-SEAL ",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 13,
      "WHXX": 1,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4110000054191,
      "DESCRIPTION": "SEALING RING ",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 14,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4120004769,
      "DESCRIPTION": "CYLINDER ",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4110001031042,
      "DESCRIPTION": "AIR COMPRESSOR ",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 1,
      "STD": 0.375533808099405,
      "MEAN": 0.153846153846153,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": 17220227,
      "DESCRIPTION": "BEARING",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 29070010931,
      "DESCRIPTION": "FRONT WHEEL HUB",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14663702,
      "DESCRIPTION": "NUT, T/S BOLT EC210B 14880574",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 3.33333333333333,
      "MAINTANING STOCK QTY": 13,
      "AVAILABLE": 52,
      "SHELF LIFE": 15.600000000000016,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1639,
      "WHXX": 1355,
      "WH REFERENCE": "",
      "TREND": 1,
      "STD": 7.5106761619881,
      "MEAN": 3.07692307692307,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 15.600000000000016
    },
    {
      "PART NUMBER": 28140007461,
      "DESCRIPTION": "CUTTING EDGE",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 1,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 28140007451,
      "DESCRIPTION": "CUTTING EDGE",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 32,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4110000054128,
      "DESCRIPTION": "CONNECTING ROD ",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 991971,
      "DESCRIPTION": "NIPPLE",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 25,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4110000057003,
      "DESCRIPTION": "GASKET 4110000054233",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 11,
      "WHXX": 5,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4110001525005,
      "DESCRIPTION": "CYLINDER LINER",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 11705144,
      "DESCRIPTION": "AIR VENT VALVE",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 6,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "L01198",
      "DESCRIPTION": "SSL01198 FRONT COVER  SU+85",
      "PRODUCT CATEGORY": "SOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14650315,
      "DESCRIPTION": "14737475 STRAINER EC210B/EC460B 14524761",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "2010200Z7B",
      "DESCRIPTION": "MUFFLER",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "7114-12121",
      "DESCRIPTION": "SEALING",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 36,
      "WHXX": 15,
      "WH REFERENCE": "WHXX",
      "TREND": 0,
      "STD": 0.375533808099405,
      "MEAN": 0.153846153846153,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": "8220-13470",
      "DESCRIPTION": "O-RING",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.666666666666666,
      "MAINTANING STOCK QTY": 3,
      "AVAILABLE": 3,
      "SHELF LIFE": 4.500000000000004,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 21,
      "WHXX": 16,
      "WH REFERENCE": "",
      "TREND": 1,
      "STD": 0.960768922830522,
      "MEAN": 0.615384615384615,
      "COV": 1.56124949959959,
      "SHELF LIFE_1": 4.500000000000004
    },
    {
      "PART NUMBER": "7114-17460",
      "DESCRIPTION": "O-RING",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.75,
      "MAINTANING STOCK QTY": 3,
      "AVAILABLE": 3,
      "SHELF LIFE": 4,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 27,
      "WHXX": 12,
      "WH REFERENCE": "",
      "TREND": 0.89,
      "STD": 1.03155347127648,
      "MEAN": 0.692307692307692,
      "COV": 1.49002168073269,
      "SHELF LIFE_1": 4
    },
    {
      "PART NUMBER": 14528776,
      "DESCRIPTION": "SEALING",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.666666666666666,
      "MAINTANING STOCK QTY": 3,
      "AVAILABLE": 3,
      "SHELF LIFE": 4.500000000000004,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 66,
      "WHXX": 6,
      "WH REFERENCE": "",
      "TREND": 0.75,
      "STD": 0.869718492622904,
      "MEAN": 0.615384615384615,
      "COV": 1.41329255051221,
      "SHELF LIFE_1": 4.500000000000004
    },
    {
      "PART NUMBER": "963229Z00A",
      "DESCRIPTION": "MIRROR GLASS",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 8,
      "WHXX": 8,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 15185168,
      "DESCRIPTION": "SEALING KIT",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 5,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 21242584,
      "DESCRIPTION": "GASKET EC210B 20405790",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 2,
      "SHELF LIFE": 24.00000000000001,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 7,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 24.00000000000001
    },
    {
      "PART NUMBER": 21896344,
      "DESCRIPTION": "INLET VALVE 20758481",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 27,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14604058,
      "DESCRIPTION": "RELAY",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 0,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": "154-71-41270V010",
      "DESCRIPTION": "BOLT & NUT 154-70-11143",
      "PRODUCT CATEGORY": "SDG",
      "ITEM MOVEMENT BRANCH": "AVERAGE (F2)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 33.8333333333333,
      "MAINTANING STOCK QTY": 135,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 135,
      "APRD QTY": 0,
      "WH12": 172,
      "WHXX": 21,
      "WH REFERENCE": "WH12 + WHXX",
      "TREND": 0.11,
      "STD": 71.4681209189965,
      "MEAN": 31.2307692307692,
      "COV": 2.28838810824373,
      "SHELF LIFE_1": 3.9901477832512353
    },
    {
      "PART NUMBER": 4043000028,
      "DESCRIPTION": "BUSH ",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 4,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 11103470,
      "DESCRIPTION": "BRAKE PISTON",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 15010424,
      "DESCRIPTION": "ADJUSTING DEVICE",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "30 DAY AVE": 0.5,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 2,
      "APRD QTY": 2,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "PARTIAL WH12",
      "TREND": 2,
      "STD": 1.66410058867568,
      "MEAN": 0.461538461538461,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 4
    },
    {
      "PART NUMBER": 14649606,
      "DESCRIPTION": "COMPRESSOR",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.666666666666666,
      "MAINTANING STOCK QTY": 3,
      "AVAILABLE": 1,
      "SHELF LIFE": 1.5000000000000016,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 2,
      "APRD QTY": 2,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 2,
      "STD": 1.26085034391223,
      "MEAN": 0.615384615384615,
      "COV": 2.04888180885737,
      "SHELF LIFE_1": 4.500000000000004
    },
    {
      "PART NUMBER": "328599Z00D",
      "DESCRIPTION": "GEAR SHIFT LEVER KNOB",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 12,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "451849Z00B",
      "DESCRIPTION": "RELAY VALVE",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3,
      "WHXX": 3,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14558072,
      "DESCRIPTION": "SHEET",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 0,
      "WHXX": 5,
      "WH REFERENCE": "WHXX",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": 21337557,
      "DESCRIPTION": "AIR FILTER-PRIMARY",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.75,
      "MAINTANING STOCK QTY": 3,
      "AVAILABLE": 4,
      "SHELF LIFE": 5.333333333333333,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 21,
      "WHXX": 1,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 1.79743406854583,
      "MEAN": 0.692307692307692,
      "COV": 2.5962936545662,
      "SHELF LIFE_1": 5.333333333333333
    },
    {
      "PART NUMBER": 21693755,
      "DESCRIPTION": "AIR FILTER ASSY",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 1,
      "MAINTANING STOCK QTY": 4,
      "AVAILABLE": 13,
      "SHELF LIFE": 13,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0.33,
      "STD": 1.97743682838577,
      "MEAN": 0.923076923076923,
      "COV": 2.14222323075126,
      "SHELF LIFE_1": 13
    },
    {
      "PART NUMBER": "651659Z00B",
      "DESCRIPTION": "CABLE, LOCK ASSY, LHD",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 17,
      "WHXX": 4,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 17429862,
      "DESCRIPTION": "MOIL POINT, TOOL VOLVO HB21",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 24,
      "WHXX": 6,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4021000033,
      "DESCRIPTION": "BEARING GB297-32024",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4021000037,
      "DESCRIPTION": "BEARING GB297-32221",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4110000659,
      "DESCRIPTION": "SPEED CHANGE OPERATING GEAR LG956L",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.25,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 11,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 2,
      "STD": 0.832050294337843,
      "MEAN": 0.23076923076923,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 4
    },
    {
      "PART NUMBER": 4090000008,
      "DESCRIPTION": "STEEL BALL ",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 7,
      "WHXX": 8,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4043000035,
      "DESCRIPTION": "SPACER ",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 163,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4014000016,
      "DESCRIPTION": "SCREW",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 8,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 0,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": 4015000022,
      "DESCRIPTION": "RING SNAP ",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 7,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 29070001261,
      "DESCRIPTION": "ISOLATED BUSHING",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 5,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 29070001271,
      "DESCRIPTION": "SPACER",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 1,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4110000556072,
      "DESCRIPTION": "OIL COOLER -61500010334",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 2,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4120001739013,
      "DESCRIPTION": "BOLT ",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 4,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 59070326,
      "DESCRIPTION": "PRESSURE SWITCH",
      "PRODUCT CATEGORY": "VCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 4,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "A220401000483",
      "DESCRIPTION": "PRESSUREREDUCINGVALVE",
      "PRODUCT CATEGORY": "YPG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 21244698,
      "DESCRIPTION": "RELAY",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 6,
      "SHELF LIFE": 72.00000000000003,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 178,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 72.00000000000003
    },
    {
      "PART NUMBER": "381029Z00A",
      "DESCRIPTION": "HEX. SOCKET SCREW",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 16,
      "WHXX": 20,
      "WH REFERENCE": "WHXX",
      "TREND": 0,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": "01010-51020",
      "DESCRIPTION": "BOLT",
      "PRODUCT CATEGORY": "SDG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 1,
      "MAINTANING STOCK QTY": 4,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 4,
      "APRD QTY": 4,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 2,
      "STD": 3.32820117735137,
      "MEAN": 0.923076923076923,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 4
    },
    {
      "PART NUMBER": 4110000076402,
      "DESCRIPTION": "SOLENOID VALVE, MCV  LG9190",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 5,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "A810299000932",
      "DESCRIPTION": "TILTING ANGLE SENSOR",
      "PRODUCT CATEGORY": "YCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 12606029,
      "DESCRIPTION": "175A CONNECTOR 60C1816.1.23A",
      "PRODUCT CATEGORY": "YPG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 2,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 2,
      "STD": 0.375533808099405,
      "MEAN": 0.153846153846153,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": 28350002251,
      "DESCRIPTION": "SLEEVE",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 15,
      "WHXX": 1,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 994781,
      "DESCRIPTION": "SIX POINT SOCKET SCREW 969408",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.25,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 22,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 2,
      "STD": 0.832050294337843,
      "MEAN": 0.23076923076923,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 4
    },
    {
      "PART NUMBER": 994926,
      "DESCRIPTION": "SIX POINT SOCKET SCREW-969587",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 47,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 2,
      "STD": 1.10940039245045,
      "MEAN": 0.307692307692307,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 3.000000000000003
    },
    {
      "PART NUMBER": "178109Z01A",
      "DESCRIPTION": "O-RING",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 87,
      "WHXX": 25,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "178109Z01B",
      "DESCRIPTION": "O-RING",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 104,
      "WHXX": 22,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "178109Z01D",
      "DESCRIPTION": "O-RING",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 1,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 52,
      "WHXX": 26,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14693120,
      "DESCRIPTION": "TOP ROLLER 14527124",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 37,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 0,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": 20517048,
      "DESCRIPTION": "HEXAGON NUT",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 3,
      "SHELF LIFE": 9.000000000000009,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 32,
      "WHXX": 23,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 1.10940039245045,
      "MEAN": 0.307692307692307,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 9.000000000000009
    },
    {
      "PART NUMBER": 29050020541,
      "DESCRIPTION": "SHIM",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 29050020581,
      "DESCRIPTION": "SHIM",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14659471,
      "DESCRIPTION": "VALVE 14531116",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 0,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": 29050000071,
      "DESCRIPTION": "BEARING SLEEVE",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 6,
      "WHXX": 10,
      "WH REFERENCE": "WHXX",
      "TREND": 1,
      "STD": 0.375533808099405,
      "MEAN": 0.153846153846153,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": 14602950,
      "DESCRIPTION": "SPROCKET  EC700 14532544 ",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 2,
      "STD": 1.10940039245045,
      "MEAN": 0.307692307692307,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 3.000000000000003
    },
    {
      "PART NUMBER": 12734398,
      "DESCRIPTION": "CLIMATE UNIT",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14588062,
      "DESCRIPTION": "GAUGE 14512936",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 4,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "921399Z00A",
      "DESCRIPTION": "REGULATOR CONTROL",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 4,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14619060,
      "DESCRIPTION": "14714050 BOLT, T/SHOE  EC700C",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.666666666666666,
      "MAINTANING STOCK QTY": 3,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 3,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 0,
      "STD": 2.21880078490091,
      "MEAN": 0.615384615384615,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 4.500000000000004
    },
    {
      "PART NUMBER": 14619061,
      "DESCRIPTION": "NUT, T/SHOE BOLT  EC700C",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 11.1666666666666,
      "MAINTANING STOCK QTY": 45,
      "AVAILABLE": 43,
      "SHELF LIFE": 3.850746268656739,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 2,
      "APRD QTY": 0,
      "WH12": 414,
      "WHXX": 74,
      "WH REFERENCE": "WHXX",
      "TREND": 0.36,
      "STD": 16.9282043514397,
      "MEAN": 10.3076923076923,
      "COV": 1.64228848185609,
      "SHELF LIFE_1": 4.02985074626868
    },
    {
      "PART NUMBER": 21687048,
      "DESCRIPTION": "HEADLAMP PROTECTOR",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.416666666666666,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 2,
      "APRD QTY": 2,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 2,
      "STD": 1.12089707663561,
      "MEAN": 0.384615384615384,
      "COV": 2.91433239925258,
      "SHELF LIFE_1": 4.800000000000008
    },
    {
      "PART NUMBER": "C31525",
      "DESCRIPTION": "SEAL KIT SU+85TR/F",
      "PRODUCT CATEGORY": "SOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.25,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 11,
      "SHELF LIFE": 44,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.599144689515278,
      "MEAN": 0.23076923076923,
      "COV": 2.5962936545662,
      "SHELF LIFE_1": 44
    },
    {
      "PART NUMBER": "L51204",
      "DESCRIPTION": "SEAL KIT SU+55TR-F ",
      "PRODUCT CATEGORY": "SOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 9,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 61,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 28110002321,
      "DESCRIPTION": "HYD FLUID FILTER",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 1,
      "SHELF LIFE": 12.000000000000005,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 17,
      "WHXX": 10,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 12.000000000000005
    },
    {
      "PART NUMBER": 4130001898,
      "DESCRIPTION": "FUEL LEVEL SENSOR",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 11,
      "WHXX": 1,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4130001086,
      "DESCRIPTION": "ECU ÂÈËÂÄ®ÊÚ∫ÊÉßÂÀ∂ÂÇÏÂÖÉ 3601115-98D",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4190002822,
      "DESCRIPTION": "EXPANSION VALVE",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.25,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 0,
      "WHXX": 1,
      "WH REFERENCE": "WHXX",
      "TREND": 2,
      "STD": 0.599144689515278,
      "MEAN": 0.23076923076923,
      "COV": 2.5962936545662,
      "SHELF LIFE_1": 4
    },
    {
      "PART NUMBER": 4130001422,
      "DESCRIPTION": "SWITCH",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4130001314,
      "DESCRIPTION": "TAIL LAMP",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 0,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": 11215524,
      "DESCRIPTION": "ROTATING BEACON",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4130001101,
      "DESCRIPTION": "WORK LAMP",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 4,
      "WHXX": 3,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4110000970112,
      "DESCRIPTION": "SEAL",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 14,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4110001841019,
      "DESCRIPTION": "4110001841042 V BELT",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 11,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 1,
      "STD": 0.375533808099405,
      "MEAN": 0.153846153846153,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": 4190002765,
      "DESCRIPTION": "SWITCH",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.666666666666666,
      "MAINTANING STOCK QTY": 3,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 3,
      "APRD QTY": 3,
      "WH12": 6,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 1.25,
      "STD": 1.04390784542678,
      "MEAN": 0.615384615384615,
      "COV": 1.69635024881852,
      "SHELF LIFE_1": 4.500000000000004
    },
    {
      "PART NUMBER": 4110001905057,
      "DESCRIPTION": "O-RING",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 38,
      "WHXX": 5,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "430519Z00C",
      "DESCRIPTION": "SEALING RING",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 3,
      "WHXX": 1,
      "WH REFERENCE": "WHXX",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": "261369Z00D",
      "DESCRIPTION": "HOUSING",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4015000167,
      "DESCRIPTION": "WASHER ",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 21,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": "995499Z00A",
      "DESCRIPTION": "HOSE ASSEMBLY",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.583333333333333,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 2,
      "APRD QTY": 0,
      "WH12": 4,
      "WHXX": 6,
      "WH REFERENCE": "WHXX",
      "TREND": 0,
      "STD": 1.9414506867883,
      "MEAN": 0.538461538461538,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 3.42857142857143
    },
    {
      "PART NUMBER": "995499Z00B",
      "DESCRIPTION": "HOSE ASSEMBLY",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.583333333333333,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 2,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 10,
      "WH REFERENCE": "WHXX",
      "TREND": 0,
      "STD": 1.9414506867883,
      "MEAN": 0.538461538461538,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 3.42857142857143
    },
    {
      "PART NUMBER": "443419Z01A",
      "DESCRIPTION": "SLACK ADJUSTER",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "502389Z00A",
      "DESCRIPTION": "BEARING",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 5,
      "WHXX": 3,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "555029Z00A",
      "DESCRIPTION": "COMPRESSION FITTING",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 12,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "542199Z01A",
      "DESCRIPTION": "U-BOLT",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 2.16666666666666,
      "MAINTANING STOCK QTY": 9,
      "AVAILABLE": 5,
      "SHELF LIFE": 2.307692307692315,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 4,
      "APRD QTY": 4,
      "WH12": 0,
      "WHXX": 2,
      "WH REFERENCE": "PARTIAL WHXX",
      "TREND": 1.69,
      "STD": 5.47722557505166,
      "MEAN": 2,
      "COV": 2.73861278752583,
      "SHELF LIFE_1": 4.153846153846167
    },
    {
      "PART NUMBER": "178109Z00A",
      "DESCRIPTION": "O-RING",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 26,
      "WHXX": 20,
      "WH REFERENCE": "WHXX",
      "TREND": 0,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": "U81275",
      "DESCRIPTION": "O-RING, CAP",
      "PRODUCT CATEGORY": "SOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 4,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 60208879,
      "DESCRIPTION": "FUEL FILTER",
      "PRODUCT CATEGORY": "YCG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.5,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 3,
      "SHELF LIFE": 6,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 20,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 1.19828937903055,
      "MEAN": 0.461538461538461,
      "COV": 2.5962936545662,
      "SHELF LIFE_1": 6
    },
    {
      "PART NUMBER": 60208878,
      "DESCRIPTION": "FUEL FILTER",
      "PRODUCT CATEGORY": "YCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 6,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 16,
      "WHXX": 7,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14515524,
      "DESCRIPTION": "CLIP 14507450",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 9,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "A220302000023",
      "DESCRIPTION": "WATER PUMP",
      "PRODUCT CATEGORY": "YPG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 21259899,
      "DESCRIPTION": "CHARGE AIR HOSE",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 2,
      "SHELF LIFE": 12.000000000000048,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 12.000000000000048
    },
    {
      "PART NUMBER": 22058741,
      "DESCRIPTION": "AIR SPRING",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 6,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 22058737,
      "DESCRIPTION": "AIR SPRING",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 5,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 11193389,
      "DESCRIPTION": "CABLE",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4110000054044,
      "DESCRIPTION": "GASKET ",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 4,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 58,
      "WHXX": 5,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "797009Z00A",
      "DESCRIPTION": "GLASS",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 980973,
      "DESCRIPTION": "HOLLOW SCREW",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.25,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 7,
      "WHXX": 2,
      "WH REFERENCE": "WHXX",
      "TREND": 1.33,
      "STD": 0.599144689515278,
      "MEAN": 0.23076923076923,
      "COV": 2.5962936545662,
      "SHELF LIFE_1": 4
    },
    {
      "PART NUMBER": 14557971,
      "DESCRIPTION": "SPROCKET EC140B",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4190002827,
      "DESCRIPTION": "HEAT EXCHANGER CORE",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 20459235,
      "DESCRIPTION": "STUD",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 3,
      "SHELF LIFE": 9.000000000000009,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 35,
      "WHXX": 35,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 1.10940039245045,
      "MEAN": 0.307692307692307,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 9.000000000000009
    },
    {
      "PART NUMBER": 21027485,
      "DESCRIPTION": "GASKET",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 34,
      "WHXX": 21,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4120002027,
      "DESCRIPTION": "CONTROL LEVER",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": 4021000043,
      "DESCRIPTION": "SPHERICAL PLAIN BEARING",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 1,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14530656,
      "DESCRIPTION": "LOCK_ASSY SASH",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 4,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 9017204355,
      "DESCRIPTION": "ALTERNATOR REMAN",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 17,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 28350001021,
      "DESCRIPTION": "WASHER",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4021000050,
      "DESCRIPTION": "BEARING ",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4015000046,
      "DESCRIPTION": "RING SNAP ",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 3,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 60034226,
      "DESCRIPTION": "OIL FILTER",
      "PRODUCT CATEGORY": "YCG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.5,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 6,
      "SHELF LIFE": 12,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3,
      "WHXX": 1,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 1.66410058867568,
      "MEAN": 0.461538461538461,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 12
    },
    {
      "PART NUMBER": 15013381,
      "DESCRIPTION": "UPPER SECTION 11414163",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": 43974310,
      "DESCRIPTION": "GAUGE TACHOMETER",
      "PRODUCT CATEGORY": "VCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4130000624,
      "DESCRIPTION": "SAFETY",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 8,
      "WH REFERENCE": "WHXX",
      "TREND": 0.5,
      "STD": 0.630425171956115,
      "MEAN": 0.307692307692307,
      "COV": 2.04888180885737,
      "SHELF LIFE_1": 3.000000000000003
    },
    {
      "PART NUMBER": 96719604,
      "DESCRIPTION": "PLAIN WASHER",
      "PRODUCT CATEGORY": "VCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4110001948041,
      "DESCRIPTION": "FILTER INSERT",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "AVERAGE (F2)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 2.25,
      "MAINTANING STOCK QTY": 9,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 9,
      "APRD QTY": 9,
      "WH12": 60,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 1.26,
      "STD": 1.32045058354704,
      "MEAN": 2.07692307692307,
      "COV": 0.63577250318932,
      "SHELF LIFE_1": 4
    },
    {
      "PART NUMBER": 4110000909074,
      "DESCRIPTION": "FILTER INSERT",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.25,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 130,
      "WHXX": 14,
      "WH REFERENCE": "WHXX",
      "TREND": 2,
      "STD": 0.438529009653514,
      "MEAN": 0.23076923076923,
      "COV": 1.90029237516522,
      "SHELF LIFE_1": 4
    },
    {
      "PART NUMBER": 4120002104001,
      "DESCRIPTION": "FILTER INSERT",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.25,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 106,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 0.67,
      "STD": 0.599144689515278,
      "MEAN": 0.23076923076923,
      "COV": 2.5962936545662,
      "SHELF LIFE_1": 4
    },
    {
      "PART NUMBER": 29350010491,
      "DESCRIPTION": "FILTER NETTING",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "FAST (F1)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 4.58333333333333,
      "MAINTANING STOCK QTY": 18,
      "AVAILABLE": 15,
      "SHELF LIFE": 3.2727272727272747,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 3,
      "APRD QTY": 3,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 1.24,
      "STD": 3.53915823687512,
      "MEAN": 4.23076923076923,
      "COV": 0.836528310534119,
      "SHELF LIFE_1": 3.92727272727273
    },
    {
      "PART NUMBER": 29350010501,
      "DESCRIPTION": "FILTER NETTING",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "FAST (F1)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 4.25,
      "MAINTANING STOCK QTY": 17,
      "AVAILABLE": 15,
      "SHELF LIFE": 3.5294117647058822,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 2,
      "APRD QTY": 2,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 1.18,
      "STD": 2.84199280029402,
      "MEAN": 3.92307692307692,
      "COV": 0.724429537329849,
      "SHELF LIFE_1": 4
    },
    {
      "PART NUMBER": 4190002863,
      "DESCRIPTION": "RESERVOIR",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.25,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 1,
      "SHELF LIFE": 4,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 1,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.599144689515278,
      "MEAN": 0.23076923076923,
      "COV": 2.5962936545662,
      "SHELF LIFE_1": 4
    },
    {
      "PART NUMBER": 4110000924103,
      "DESCRIPTION": "WATER PUMP LG956L  ",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 7,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 21602385,
      "DESCRIPTION": "22937189 FILTER CARTRIDGE",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14588542,
      "DESCRIPTION": "YOKE",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "542199Z00C",
      "DESCRIPTION": "U-BOLT",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 1.58333333333333,
      "MAINTANING STOCK QTY": 6,
      "AVAILABLE": 4,
      "SHELF LIFE": 2.52631578947369,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 2,
      "APRD QTY": 0,
      "WH12": 12,
      "WHXX": 11,
      "WH REFERENCE": "WHXX",
      "TREND": 0.63,
      "STD": 2.84650032541085,
      "MEAN": 1.46153846153846,
      "COV": 1.94760548580742,
      "SHELF LIFE_1": 3.7894736842105345
    },
    {
      "PART NUMBER": 14573760,
      "DESCRIPTION": "SEALING",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14616919,
      "DESCRIPTION": "PLATE",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14573771,
      "DESCRIPTION": "SCREW",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 17444646,
      "DESCRIPTION": "17532105 CONTROL PANEL 15155940",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "333119Z00B",
      "DESCRIPTION": "COVER",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 22383381,
      "DESCRIPTION": "LEVEL SENSOR 21391697",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 6,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "320139Z00C",
      "DESCRIPTION": "O-RING",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 8,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 17,
      "WHXX": 3,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "320139Z00A",
      "DESCRIPTION": "O-RING",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 5,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 12,
      "WHXX": 31,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "324979Z00E",
      "DESCRIPTION": "GASKET",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 37,
      "WHXX": 21,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "261809Z00C",
      "DESCRIPTION": "LAMP - ROOF MARKER",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 13,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "631029Z00B",
      "DESCRIPTION": "FENDER SIDE",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 5,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4110002147005,
      "DESCRIPTION": "4110001009040 V-BELT",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 11444203,
      "DESCRIPTION": "PANEL",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "166959Z01D",
      "DESCRIPTION": "FUEL FILTER WATER SEPARATOR",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "AVERAGE (F2)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 2.58333333333333,
      "MAINTANING STOCK QTY": 10,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 10,
      "APRD QTY": 10,
      "WH12": 35,
      "WHXX": 11,
      "WH REFERENCE": "WHXX",
      "TREND": 0.58,
      "STD": 2.66265724225698,
      "MEAN": 2.38461538461538,
      "COV": 1.11659819836583,
      "SHELF LIFE_1": 3.870967741935489
    },
    {
      "PART NUMBER": "475009Z01A",
      "DESCRIPTION": "AIR DRIER FILTER KIT",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 4,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "324879Z00D",
      "DESCRIPTION": "BEARING",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3,
      "WHXX": 2,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "963229Z00C",
      "DESCRIPTION": "MIRROR GLASS",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 17,
      "WHXX": 8,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "301009Z01A",
      "DESCRIPTION": "5223994853 CLUTCH DISC ",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "967179Z00C",
      "DESCRIPTION": "BUSHING",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 16,
      "WHXX": 34,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "967179Z00A",
      "DESCRIPTION": "BUSHING",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 25,
      "WHXX": 64,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "551219Z03B",
      "DESCRIPTION": "SPRING LEAF",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 7,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "542139Z01C",
      "DESCRIPTION": "SETTING NUT",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 100,
      "WHXX": 20,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "550579Z00A",
      "DESCRIPTION": "CENTRE BOLT",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 46,
      "WHXX": 10,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14506786,
      "DESCRIPTION": "CATCH",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 8,
      "WH REFERENCE": "WHXX",
      "TREND": 1,
      "STD": 0.375533808099405,
      "MEAN": 0.153846153846153,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": "631249Z00E",
      "DESCRIPTION": "FENDER FLARE",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 5,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4130000869,
      "DESCRIPTION": "AUXILIARY RELAY 024-1Z6GD2",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 10,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4110000924044,
      "DESCRIPTION": "FUEL PUMP",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3,
      "WHXX": 1,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 29050018231,
      "DESCRIPTION": "SHIM",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 2,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 29050018221,
      "DESCRIPTION": "SHIM",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 29050018241,
      "DESCRIPTION": "SHIM",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 1,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "555479Z01A",
      "DESCRIPTION": "BOGIE ANCHORAGE",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 6,
      "WHXX": 1,
      "WH REFERENCE": "WHXX",
      "TREND": 0,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": "911119Z00E",
      "DESCRIPTION": "HEXAGON NUT",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 2.41666666666666,
      "MAINTANING STOCK QTY": 10,
      "AVAILABLE": 11,
      "SHELF LIFE": 4.551724137931047,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 11,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 6.93005346484722,
      "MEAN": 2.23076923076923,
      "COV": 3.10657569113841,
      "SHELF LIFE_1": 4.551724137931047
    },
    {
      "PART NUMBER": 4130000618,
      "DESCRIPTION": "BULB 13498(24V 21W)",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 17411027,
      "DESCRIPTION": "PLANET CARRIER ",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": 14521612,
      "DESCRIPTION": "O-RING",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 1,
      "SHELF LIFE": 6.000000000000024,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 5,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": 15066561,
      "DESCRIPTION": "O-RING",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 1,
      "SHELF LIFE": 6.000000000000024,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 2,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": 14515742,
      "DESCRIPTION": "FILTER ELEMENT",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 1,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 10,
      "WHXX": 3,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 17411510,
      "DESCRIPTION": "EXPANSION TANK  EC210B/EC210D",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.5,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 3,
      "SHELF LIFE": 6,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 5,
      "WHXX": 3,
      "WH REFERENCE": "",
      "TREND": 0.67,
      "STD": 0.776250025806184,
      "MEAN": 0.461538461538461,
      "COV": 1.6818750559134,
      "SHELF LIFE_1": 6
    },
    {
      "PART NUMBER": 14689735,
      "DESCRIPTION": "FILTER MAIN, CAB_14506997",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "FAST (F1)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 10.0833333333333,
      "MAINTANING STOCK QTY": 40,
      "AVAILABLE": 39,
      "SHELF LIFE": 3.867768595041335,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 0,
      "WHXX": 29,
      "WH REFERENCE": "WHXX",
      "TREND": 0.91,
      "STD": 7.25011052081984,
      "MEAN": 9.3076923076923,
      "COV": 0.778937493972379,
      "SHELF LIFE_1": 3.9669421487603436
    },
    {
      "PART NUMBER": 4030000768,
      "DESCRIPTION": "SEALING RING",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 4,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 29050016421,
      "DESCRIPTION": "DRIVE SHAFT GEAR",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 5,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 0,
      "STD": 0.375533808099405,
      "MEAN": 0.153846153846153,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": "322699Z00C",
      "DESCRIPTION": "RETAINING RING",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 14,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14659238,
      "DESCRIPTION": "14778022 COMPRESSOR 15082727 11412631",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.416666666666666,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 2,
      "APRD QTY": 2,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 1.6,
      "STD": 0.65044363558799,
      "MEAN": 0.384615384615384,
      "COV": 1.69115345252877,
      "SHELF LIFE_1": 4.800000000000008
    },
    {
      "PART NUMBER": "081A66162A",
      "DESCRIPTION": "BOLT-FLG",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 42,
      "WHXX": 30,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "01010-52490",
      "DESCRIPTION": "BOLT",
      "PRODUCT CATEGORY": "SDG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 0,
      "STD": 1.10940039245045,
      "MEAN": 0.307692307692307,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 3.000000000000003
    },
    {
      "PART NUMBER": 16680661,
      "DESCRIPTION": "KIT",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.416666666666666,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 2,
      "APRD QTY": 2,
      "WH12": 20,
      "WHXX": 10,
      "WH REFERENCE": "WHXX",
      "TREND": 1.2,
      "STD": 0.767947647788304,
      "MEAN": 0.384615384615384,
      "COV": 1.99666388424959,
      "SHELF LIFE_1": 4.800000000000008
    },
    {
      "PART NUMBER": 14640101,
      "DESCRIPTION": "ECU",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 1,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "440669Z01D",
      "DESCRIPTION": "BRAKE LINING KIT 410399Z00A ",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 6,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 9,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 22610786,
      "DESCRIPTION": "SPACER WASHER ",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 4,
      "WHXX": 2,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "XC8286065",
      "DESCRIPTION": "BUCKET ASSY (GP)  3.10M3  G6",
      "PRODUCT CATEGORY": "VOA",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 11110708,
      "DESCRIPTION": "54864268 FILTER RETAINER, FWS 11110702",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "AVERAGE (F2)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 1.83333333333333,
      "MAINTANING STOCK QTY": 7,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 7,
      "APRD QTY": 7,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 0.91,
      "STD": 2.52931530209973,
      "MEAN": 1.69230769230769,
      "COV": 1.4945954057862,
      "SHELF LIFE_1": 3.8181818181818254
    },
    {
      "PART NUMBER": 29070011091,
      "DESCRIPTION": "INPUT FLANGE",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4110000189026,
      "DESCRIPTION": "PUMP GASKET 12200650",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 6,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4110001554019,
      "DESCRIPTION": "4110001554040 THRUST WASHER",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 0,
      "WHXX": 2,
      "WH REFERENCE": "WHXX",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": 22567321,
      "DESCRIPTION": "24078863 COOLANT (READY MIXED) 5L/GAL YELLOW  85108913",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 12.25,
      "MAINTANING STOCK QTY": 49,
      "AVAILABLE": 1,
      "SHELF LIFE": 0.08163265306122448,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 48,
      "APRD QTY": 48,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 1.09,
      "STD": 16.2603024540577,
      "MEAN": 11.3076923076923,
      "COV": 1.43798593131122,
      "SHELF LIFE_1": 4
    },
    {
      "PART NUMBER": 84168569,
      "DESCRIPTION": "KERB OBSERVATION",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 1,
      "SHELF LIFE": 6.000000000000024,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": "551229Z00B",
      "DESCRIPTION": "SPRING LEAF",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 11689838,
      "DESCRIPTION": "14359170 FRONT WINDOW GLASS",
      "PRODUCT CATEGORY": "YTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "270489Z04A",
      "DESCRIPTION": "PIPING",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4780963,
      "DESCRIPTION": "LINK BEARING 19630",
      "PRODUCT CATEGORY": "VGG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 2,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14594707,
      "DESCRIPTION": "14794925 ECU",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 20459015,
      "DESCRIPTION": "SCREW",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 5,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14558709,
      "DESCRIPTION": "ACCUMULATOR",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 2,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": 4110000924023,
      "DESCRIPTION": "GASKET-612600080400",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 9,
      "WHXX": 6,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "440669Z01B",
      "DESCRIPTION": "BRAKE LINING  KIT 440669Z00B ",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 2.41666666666666,
      "MAINTANING STOCK QTY": 10,
      "AVAILABLE": 14,
      "SHELF LIFE": 5.793103448275878,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 41,
      "WHXX": 39,
      "WH REFERENCE": "",
      "TREND": 0.21,
      "STD": 5.40299062485839,
      "MEAN": 2.23076923076923,
      "COV": 2.42203028010893,
      "SHELF LIFE_1": 5.793103448275878
    },
    {
      "PART NUMBER": 22768937,
      "DESCRIPTION": "TAPPET",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 84208821,
      "DESCRIPTION": "POSITION LAMP 82116545",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 5,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 22583048,
      "DESCRIPTION": "GEAR SELECTOR 22230455",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 1,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 17418256,
      "DESCRIPTION": "V RIBBED BELT ",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 1,
      "SHELF LIFE": 12.000000000000005,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 7,
      "WHXX": 7,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 12.000000000000005
    },
    {
      "PART NUMBER": 14675537,
      "DESCRIPTION": "WIPER MOTOR 11205840 ",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "A810201206088",
      "DESCRIPTION": "MOTION CONTROL ASSEMBLY",
      "PRODUCT CATEGORY": "YCG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.25,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 2,
      "SHELF LIFE": 8,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.599144689515278,
      "MEAN": 0.23076923076923,
      "COV": 2.5962936545662,
      "SHELF LIFE_1": 8
    },
    {
      "PART NUMBER": 21162862,
      "DESCRIPTION": "WIRES",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 3,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": 954493,
      "DESCRIPTION": "ELECTRIC CABLE",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 4,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": 984437,
      "DESCRIPTION": "ELECTRIC CABLE",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 8,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": 984438,
      "DESCRIPTION": "ELECTRIC CABLE",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 4,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": 60128948,
      "DESCRIPTION": "2/5 VALVE",
      "PRODUCT CATEGORY": "YPG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "4120000090Z",
      "DESCRIPTION": "PNEUMATIC CYLINDER 4120000090",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 10,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 1,
      "STD": 0.375533808099405,
      "MEAN": 0.153846153846153,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": 17418268,
      "DESCRIPTION": "V RIBBED BELT  21080993",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.416666666666666,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 1,
      "SHELF LIFE": 2.400000000000004,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 49,
      "WHXX": 13,
      "WH REFERENCE": "WHXX",
      "TREND": 0.8,
      "STD": 0.767947647788304,
      "MEAN": 0.384615384615384,
      "COV": 1.99666388424959,
      "SHELF LIFE_1": 4.800000000000008
    },
    {
      "PART NUMBER": 14652325,
      "DESCRIPTION": "HANDLE 14524738",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 4,
      "WHXX": 6,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "381669Z00C",
      "DESCRIPTION": "FLANGE SCREW",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 29,
      "WHXX": 25,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "333109Z00A",
      "DESCRIPTION": "ORING",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 5,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 17,
      "WHXX": 39,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "328339Z04A",
      "DESCRIPTION": "HEX SCREW",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 4,
      "WHXX": 20,
      "WH REFERENCE": "WHXX",
      "TREND": 0,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": 14550595,
      "DESCRIPTION": "IDLER",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "410309Z03D",
      "DESCRIPTION": "5223714923 BRAKE SHOE KIT ",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 5,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14661022,
      "DESCRIPTION": "SWITCH",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 4,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4130001509,
      "DESCRIPTION": "FUEL LEVEL SENSOR",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14592567,
      "DESCRIPTION": "LAMP",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 5,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4120000868101,
      "DESCRIPTION": "SEALING MEMBER AGGREGATE",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 1,
      "WH REFERENCE": "WHXX",
      "TREND": 0,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": 4120000867101,
      "DESCRIPTION": "SEALING MEMBER AGGREGATE",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.666666666666666,
      "MAINTANING STOCK QTY": 3,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 3,
      "APRD QTY": 0,
      "WH12": 5,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 0.5,
      "STD": 1.26085034391223,
      "MEAN": 0.615384615384615,
      "COV": 2.04888180885737,
      "SHELF LIFE_1": 4.500000000000004
    },
    {
      "PART NUMBER": 4120001004101,
      "DESCRIPTION": "SEALING MEMBER AGGREGATE",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.666666666666666,
      "MAINTANING STOCK QTY": 3,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 3,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 0.5,
      "STD": 1.26085034391223,
      "MEAN": 0.615384615384615,
      "COV": 2.04888180885737,
      "SHELF LIFE_1": 4.500000000000004
    },
    {
      "PART NUMBER": 4110002115,
      "DESCRIPTION": "CAP",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 8,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14881136,
      "DESCRIPTION": "HOSE",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 1,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 4,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14880494,
      "DESCRIPTION": "CONNECTOR",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 3,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 70932553,
      "DESCRIPTION": "RADIATOR HOSE",
      "PRODUCT CATEGORY": "VCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 3,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 0,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": 70932546,
      "DESCRIPTION": "RADIATOR HOSE",
      "PRODUCT CATEGORY": "VCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 3,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 0,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": "155-30-00118P010-01",
      "DESCRIPTION": "TRACK ROLLER ASSY, DF-155-30-00114",
      "PRODUCT CATEGORY": "SDG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 1,
      "MAINTANING STOCK QTY": 4,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 4,
      "APRD QTY": 4,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 2,
      "STD": 3.32820117735137,
      "MEAN": 0.923076923076923,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 4
    },
    {
      "PART NUMBER": "155-30-00128P010-01",
      "DESCRIPTION": "TRACK ROLLER ASSY, SF-155-30-00128P010-01",
      "PRODUCT CATEGORY": "SDG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 3.33333333333333,
      "MAINTANING STOCK QTY": 13,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 13,
      "APRD QTY": 13,
      "WH12": 9,
      "WHXX": 0,
      "WH REFERENCE": "PARTIAL WH12",
      "TREND": 1.5,
      "STD": 8.5485041426511,
      "MEAN": 3.07692307692307,
      "COV": 2.7782638463616,
      "SHELF LIFE_1": 3.900000000000004
    },
    {
      "PART NUMBER": "01010-52275",
      "DESCRIPTION": "BOLT 01010-52275",
      "PRODUCT CATEGORY": "SDG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 23.6666666666666,
      "MAINTANING STOCK QTY": 95,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 95,
      "APRD QTY": 95,
      "WH12": 34,
      "WHXX": 0,
      "WH REFERENCE": "PARTIAL WH12",
      "TREND": 1.61,
      "STD": 63.8459684890729,
      "MEAN": 21.8461538461538,
      "COV": 2.92252672661249,
      "SHELF LIFE_1": 4.014084507042265
    },
    {
      "PART NUMBER": "01643-32260",
      "DESCRIPTION": "WASHER, TRACK ROLLER",
      "PRODUCT CATEGORY": "SDG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 26.1666666666666,
      "MAINTANING STOCK QTY": 105,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 105,
      "APRD QTY": 105,
      "WH12": 30,
      "WHXX": 0,
      "WH REFERENCE": "PARTIAL WH12",
      "TREND": 1.64,
      "STD": 71.9465613654168,
      "MEAN": 24.1538461538461,
      "COV": 2.97867929219878,
      "SHELF LIFE_1": 4.012738853503195
    },
    {
      "PART NUMBER": "8216-MD-00062",
      "DESCRIPTION": "TRACK SHOE BOLT W/ NUT",
      "PRODUCT CATEGORY": "SDG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 6.66666666666666,
      "MAINTANING STOCK QTY": 27,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 27,
      "APRD QTY": 0,
      "WH12": 60,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 0,
      "STD": 22.1880078490091,
      "MEAN": 6.15384615384615,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 4.050000000000004
    },
    {
      "PART NUMBER": "8220-02230",
      "DESCRIPTION": "RING_RETAINER",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 6,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 11705232,
      "DESCRIPTION": "BALL",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 12,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 2,
      "STD": 1.10940039245045,
      "MEAN": 0.307692307692307,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 3.000000000000003
    },
    {
      "PART NUMBER": 11705233,
      "DESCRIPTION": "SPRING",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.25,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 9,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 2,
      "STD": 0.832050294337843,
      "MEAN": 0.23076923076923,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 4
    },
    {
      "PART NUMBER": 11705239,
      "DESCRIPTION": "BALL BEARING",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 4,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14558056,
      "DESCRIPTION": "ROLLER BEARING",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 3,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": 4120005062,
      "DESCRIPTION": "BREATHER CAP",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 2,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14591829,
      "DESCRIPTION": "FUEL FILLING PUMP",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 1,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 2,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14592562,
      "DESCRIPTION": "TAIL LAMP",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 2,
      "WHXX": 6,
      "WH REFERENCE": "WHXX",
      "TREND": 2,
      "STD": 0.375533808099405,
      "MEAN": 0.153846153846153,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": 20459236,
      "DESCRIPTION": "GASKET",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 4,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 20412318,
      "DESCRIPTION": "STUD",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 38,
      "WHXX": 61,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 20405590,
      "DESCRIPTION": "GASKET",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 4,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 20459171,
      "DESCRIPTION": "NIPPLE",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 7,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4110001031037,
      "DESCRIPTION": "BELT 13033963",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 1,
      "SHELF LIFE": 6.000000000000024,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 31,
      "WHXX": 9,
      "WH REFERENCE": "",
      "TREND": 1,
      "STD": 0.375533808099405,
      "MEAN": 0.153846153846153,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": 14686054,
      "DESCRIPTION": "HOSE ",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 3,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4190000020,
      "DESCRIPTION": "CLIP LG13-ZS4*250",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 46,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4110001921005,
      "DESCRIPTION": "FILTER INSERT",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 4,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 174,
      "WHXX": 16,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4120004982,
      "DESCRIPTION": "FILTER",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 2,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 106,
      "WHXX": 20,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 15035179,
      "DESCRIPTION": "FILTER, PILOT",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "FAST (F1)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 9.25,
      "MAINTANING STOCK QTY": 37,
      "AVAILABLE": 9,
      "SHELF LIFE": 0.972972972972973,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 28,
      "APRD QTY": 28,
      "WH12": 52,
      "WHXX": 63,
      "WH REFERENCE": "WHXX",
      "TREND": 0.99,
      "STD": 5.93879034562012,
      "MEAN": 8.53846153846153,
      "COV": 0.695534004441996,
      "SHELF LIFE_1": 4
    },
    {
      "PART NUMBER": 46625802,
      "DESCRIPTION": "AIR FILTER",
      "PRODUCT CATEGORY": "DOP",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 29170010601,
      "DESCRIPTION": "WEAR PLATE",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 22,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 29170010611,
      "DESCRIPTION": "WEAR PLATE",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 12,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4040000007,
      "DESCRIPTION": "BOLT LGB002-M16*70",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 1.16666666666666,
      "MAINTANING STOCK QTY": 5,
      "AVAILABLE": 5,
      "SHELF LIFE": 4.28571428571431,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 3.8829013735766,
      "MEAN": 1.07692307692307,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 4.28571428571431
    },
    {
      "PART NUMBER": 22327063,
      "DESCRIPTION": "SOLENOID VALVE",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 6,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "302009Z01E",
      "DESCRIPTION": "CLUTCH",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 2,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "A810299000577",
      "DESCRIPTION": "SANY LCD DISPLAY SCREEN ASSY",
      "PRODUCT CATEGORY": "YPG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 6,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 9060100000,
      "DESCRIPTION": "ECU REMAN",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "AVERAGE (F2)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 1.91666666666666,
      "MAINTANING STOCK QTY": 8,
      "AVAILABLE": 1,
      "SHELF LIFE": 0.5217391304347844,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 7,
      "APRD QTY": 0,
      "WH12": 14,
      "WHXX": 8,
      "WH REFERENCE": "WHXX",
      "TREND": 0.78,
      "STD": 1.42325016270542,
      "MEAN": 1.76923076923076,
      "COV": 0.80444574413785,
      "SHELF LIFE_1": 4.173913043478275
    },
    {
      "PART NUMBER": 991084,
      "DESCRIPTION": "SWIVEL",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 4,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14658433,
      "DESCRIPTION": "PIN",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 6,
      "WHXX": 26,
      "WH REFERENCE": "WHXX",
      "TREND": 1,
      "STD": 0.480384461415261,
      "MEAN": 0.307692307692307,
      "COV": 1.56124949959959,
      "SHELF LIFE_1": 3.000000000000003
    },
    {
      "PART NUMBER": 4110001905060,
      "DESCRIPTION": "GASKET",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 2,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4110001394104,
      "DESCRIPTION": "O-RING",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 2,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 3050900049,
      "DESCRIPTION": "OIL SEAL COVER",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 5,
      "WHXX": 3,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14701884,
      "DESCRIPTION": "DOOR 14634563",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 29070012891,
      "DESCRIPTION": "GEAR RING",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 3,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 29070015591,
      "DESCRIPTION": "ROUND NUT",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3,
      "WHXX": 1,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4014000033,
      "DESCRIPTION": "SCREW",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 13,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 29070012671,
      "DESCRIPTION": "SUN GEAR",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 24,
      "WHXX": 3,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 29070012661,
      "DESCRIPTION": "PLANET CARRIER",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 29070012681,
      "DESCRIPTION": "SHIM",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 1.16666666666666,
      "MAINTANING STOCK QTY": 5,
      "AVAILABLE": 4,
      "SHELF LIFE": 3.428571428571448,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 0,
      "WHXX": 9,
      "WH REFERENCE": "WHXX",
      "TREND": 1.14,
      "STD": 2.6602486870447,
      "MEAN": 1.07692307692307,
      "COV": 2.47023092368437,
      "SHELF LIFE_1": 4.28571428571431
    },
    {
      "PART NUMBER": 29070012691,
      "DESCRIPTION": "PLANET GEAR SHAFT",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.583333333333333,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 2,
      "APRD QTY": 2,
      "WH12": 0,
      "WHXX": 26,
      "WH REFERENCE": "WHXX",
      "TREND": 1.14,
      "STD": 1.33012434352235,
      "MEAN": 0.538461538461538,
      "COV": 2.47023092368437,
      "SHELF LIFE_1": 3.42857142857143
    },
    {
      "PART NUMBER": 29070012711,
      "DESCRIPTION": "PLANET GEAR",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.583333333333333,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 2,
      "APRD QTY": 2,
      "WH12": 11,
      "WHXX": 64,
      "WH REFERENCE": "WHXX",
      "TREND": 1.14,
      "STD": 1.33012434352235,
      "MEAN": 0.538461538461538,
      "COV": 2.47023092368437,
      "SHELF LIFE_1": 3.42857142857143
    },
    {
      "PART NUMBER": 4090000158,
      "DESCRIPTION": "NEEDLE ROLLER",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 15.75,
      "MAINTANING STOCK QTY": 63,
      "AVAILABLE": 54,
      "SHELF LIFE": 3.4285714285714284,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 9,
      "APRD QTY": 9,
      "WH12": 26,
      "WHXX": 2183,
      "WH REFERENCE": "WHXX",
      "TREND": 1.14,
      "STD": 35.9133572751035,
      "MEAN": 14.5384615384615,
      "COV": 2.47023092368436,
      "SHELF LIFE_1": 4
    },
    {
      "PART NUMBER": 29070012701,
      "DESCRIPTION": "RETAINING RING",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 1.16666666666666,
      "MAINTANING STOCK QTY": 5,
      "AVAILABLE": 4,
      "SHELF LIFE": 3.428571428571448,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 48,
      "WHXX": 12,
      "WH REFERENCE": "WHXX",
      "TREND": 1.14,
      "STD": 2.6602486870447,
      "MEAN": 1.07692307692307,
      "COV": 2.47023092368437,
      "SHELF LIFE_1": 4.28571428571431
    },
    {
      "PART NUMBER": 183680,
      "DESCRIPTION": "SPHERICAL PLAIN BEARING 14013647 19631",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 5,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 12809750,
      "DESCRIPTION": "STRAP ",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 10,
      "WHXX": 3,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 28140009601,
      "DESCRIPTION": "CUTTING EDGE",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 2,
      "SHELF LIFE": 12.000000000000048,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 12.000000000000048
    },
    {
      "PART NUMBER": 28140009611,
      "DESCRIPTION": "SCREW",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 1.66666666666666,
      "MAINTANING STOCK QTY": 7,
      "AVAILABLE": 15,
      "SHELF LIFE": 9.000000000000036,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 58,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 5.54700196225229,
      "MEAN": 1.53846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 9.000000000000036
    },
    {
      "PART NUMBER": 28140010441,
      "DESCRIPTION": "CUTTING EDGE",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 1,
      "SHELF LIFE": 12.000000000000005,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 16,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 12.000000000000005
    },
    {
      "PART NUMBER": 4130001966,
      "DESCRIPTION": "STARTER MOTOR",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4130001973,
      "DESCRIPTION": "ALTERNATOR",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4110002321001,
      "DESCRIPTION": "AIR FILTER INSERT",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.75,
      "MAINTANING STOCK QTY": 3,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 3,
      "APRD QTY": 3,
      "WH12": 9,
      "WHXX": 9,
      "WH REFERENCE": "WHXX",
      "TREND": 1.33,
      "STD": 1.03155347127648,
      "MEAN": 0.692307692307692,
      "COV": 1.49002168073269,
      "SHELF LIFE_1": 4
    },
    {
      "PART NUMBER": 4110002373009,
      "DESCRIPTION": "BELT",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 16,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4190001830,
      "DESCRIPTION": "BUCKET TOOTH",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.916666666666666,
      "MAINTANING STOCK QTY": 4,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 4,
      "APRD QTY": 0,
      "WH12": 40,
      "WHXX": 10,
      "WH REFERENCE": "WHXX",
      "TREND": 0.91,
      "STD": 2.07549808665108,
      "MEAN": 0.846153846153846,
      "COV": 2.45286137513309,
      "SHELF LIFE_1": 4.363636363636367
    },
    {
      "PART NUMBER": 26180007661,
      "DESCRIPTION": "RETAINER KIT 26180000191 26180000201",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.916666666666666,
      "MAINTANING STOCK QTY": 4,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 4,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 0.91,
      "STD": 2.07549808665108,
      "MEAN": 0.846153846153846,
      "COV": 2.45286137513309,
      "SHELF LIFE_1": 4.363636363636367
    },
    {
      "PART NUMBER": 21092586,
      "DESCRIPTION": "TURBOCHARGER",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 8,
      "WHXX": 9,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14576499,
      "DESCRIPTION": "BRACKET",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 8,
      "WHXX": 7,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14626783,
      "DESCRIPTION": "14759247 ODOMETER",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 16271772,
      "DESCRIPTION": "VALVE 15075800",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 1,
      "SHELF LIFE": 6.000000000000024,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 4,
      "WH REFERENCE": "",
      "TREND": 1,
      "STD": 0.375533808099405,
      "MEAN": 0.153846153846153,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": 29070012481,
      "DESCRIPTION": "PLUG",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 9,
      "WHXX": 7,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "684609Z00B",
      "DESCRIPTION": "LOCK HOUSING",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 27,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 96702279,
      "DESCRIPTION": "HEXAGON SCREW 96718838",
      "PRODUCT CATEGORY": "VCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 4,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4110000846039,
      "DESCRIPTION": "4110000054324 THRUST WASHER",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4110000846017,
      "DESCRIPTION": "4110000054324  THRUST WASHER",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4110000054124,
      "DESCRIPTION": "BUSHING",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 5,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4110000054235,
      "DESCRIPTION": "GASKET",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 7,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4110000054083,
      "DESCRIPTION": "OIL PUMP",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 60090788,
      "DESCRIPTION": "BUSHING",
      "PRODUCT CATEGORY": "YPG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 10025037,
      "DESCRIPTION": "PINSLEEVESYM5550J.4.3.4-1",
      "PRODUCT CATEGORY": "YPG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 10024462,
      "DESCRIPTION": "SHAFTGROOVE",
      "PRODUCT CATEGORY": "YPG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "726119Z00B",
      "DESCRIPTION": "WEATHERSTRIP",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "684609Z00A",
      "DESCRIPTION": "LOCK HOUSING",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 27,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14560241,
      "DESCRIPTION": "SPIDER",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 0,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": 17412747,
      "DESCRIPTION": "SEALING RING",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 6,
      "WHXX": 21,
      "WH REFERENCE": "WHXX",
      "TREND": 0.5,
      "STD": 0.630425171956115,
      "MEAN": 0.307692307692307,
      "COV": 2.04888180885737,
      "SHELF LIFE_1": 3.000000000000003
    },
    {
      "PART NUMBER": 21312943,
      "DESCRIPTION": "CABLE",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 4,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 12793261,
      "DESCRIPTION": "CABLE HARNESS",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4110002278004,
      "DESCRIPTION": "CUP",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 1,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3,
      "WHXX": 2,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4110001015014,
      "DESCRIPTION": "THERMOSWITCH",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 1,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4030000177,
      "DESCRIPTION": "O-RING",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 12,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4110000172007,
      "DESCRIPTION": "BRACKET",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 1,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 21396128,
      "DESCRIPTION": "SENSOR 20520111",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 8,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 17,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 21893128,
      "DESCRIPTION": "SOLENOID VALVE ",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 5,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 0,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": "P14691098",
      "DESCRIPTION": "ENDURANCE BUCKET 0.92M3 EC210B",
      "PRODUCT CATEGORY": "VOA",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 5222807993,
      "DESCRIPTION": "OIL LEVEL AND TEMPERATURE SENSOR",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "150169Z00A",
      "DESCRIPTION": "GASKET",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 1,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4120004037001,
      "DESCRIPTION": "FILTER INSERT",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "AVERAGE (F2)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 1.16666666666666,
      "MAINTANING STOCK QTY": 5,
      "AVAILABLE": 12,
      "SHELF LIFE": 10.285714285714343,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 23,
      "WHXX": 13,
      "WH REFERENCE": "",
      "TREND": 1.43,
      "STD": 1.32045058354704,
      "MEAN": 1.07692307692307,
      "COV": 1.22613268472226,
      "SHELF LIFE_1": 10.285714285714343
    },
    {
      "PART NUMBER": 29070015101,
      "DESCRIPTION": "SEALING COVER",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 29070015111,
      "DESCRIPTION": "DUST COVER",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 13,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "412339Z00A",
      "DESCRIPTION": "PAWL- ADAPTED QTY 5",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 13,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "464139Z00A",
      "DESCRIPTION": "PIN ADAPTED QTY 5 ",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 19,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "601079Z00A",
      "DESCRIPTION": "SPACER",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 35,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "552299Z00A",
      "DESCRIPTION": "FLANGE NUT- ADAPTED QTY 10",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 33,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "601389Z00A",
      "DESCRIPTION": "SPRING PIN- ADAPTED QTY 10",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 14,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "612079Z00B",
      "DESCRIPTION": "HEXAGON SCREW- ADAPTED QTY 10",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 24,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "552299Z00B",
      "DESCRIPTION": "FLANGE NUT ADAPTED QTY 10",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 32,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 996792,
      "DESCRIPTION": "V-RIBBED BELT, FAN/ALT BL71 20804680",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 2,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 4,
      "WHXX": 5,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4110001007001,
      "DESCRIPTION": "SENSOR",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 17408222,
      "DESCRIPTION": "EXPANSION TANK",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 1,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 4,
      "WHXX": 1,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "440669Z01C",
      "DESCRIPTION": "RIVET STUD ADAPTED QTY 56",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 9.33333333333333,
      "MAINTANING STOCK QTY": 37,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 37,
      "APRD QTY": 0,
      "WH12": 148,
      "WHXX": 1982,
      "WH REFERENCE": "WHXX",
      "TREND": 1,
      "STD": 21.0298932535667,
      "MEAN": 8.61538461538461,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 3.9642857142857157
    },
    {
      "PART NUMBER": "475119Z00B",
      "DESCRIPTION": "HEXAGON SCREW",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 33,
      "WHXX": 44,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14530463,
      "DESCRIPTION": "BRAKE DISC",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 2.5,
      "MAINTANING STOCK QTY": 10,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 10,
      "APRD QTY": 0,
      "WH12": 4,
      "WHXX": 7,
      "WH REFERENCE": "WH12 + WHXX",
      "TREND": 1,
      "STD": 3.88125012903092,
      "MEAN": 2.3076923076923,
      "COV": 1.6818750559134,
      "SHELF LIFE_1": 4
    },
    {
      "PART NUMBER": "7114-12630",
      "DESCRIPTION": "PLATE_BRAKE",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 11,
      "WHXX": 26,
      "WH REFERENCE": "WHXX",
      "TREND": 2,
      "STD": 1.10940039245045,
      "MEAN": 0.307692307692307,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 3.000000000000003
    },
    {
      "PART NUMBER": 29370019441,
      "DESCRIPTION": "DISPLAY PANEL",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 17224267,
      "DESCRIPTION": "FUEL FILTER 43977982",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.25,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 14,
      "WHXX": 10,
      "WH REFERENCE": "WHXX",
      "TREND": 0,
      "STD": 0.832050294337843,
      "MEAN": 0.23076923076923,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 4
    },
    {
      "PART NUMBER": "7114-12680",
      "DESCRIPTION": "RING",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.5,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 2,
      "APRD QTY": 0,
      "WH12": 3,
      "WHXX": 12,
      "WH REFERENCE": "WHXX",
      "TREND": 1,
      "STD": 0.776250025806184,
      "MEAN": 0.461538461538461,
      "COV": 1.6818750559134,
      "SHELF LIFE_1": 4
    },
    {
      "PART NUMBER": 14530477,
      "DESCRIPTION": "PLATE",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 24,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 992516,
      "DESCRIPTION": "BULB ADAPTED QTY 10",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 1.33333333333333,
      "MAINTANING STOCK QTY": 5,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 5,
      "APRD QTY": 0,
      "WH12": 37,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 0.63,
      "STD": 2.55453342621329,
      "MEAN": 1.23076923076923,
      "COV": 2.0755584087983,
      "SHELF LIFE_1": 3.7500000000000098
    },
    {
      "PART NUMBER": 82494763,
      "DESCRIPTION": "MODULE",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 1,
      "MAINTANING STOCK QTY": 4,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 4,
      "APRD QTY": 4,
      "WH12": 10,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 1.33,
      "STD": 2.39657875806111,
      "MEAN": 0.923076923076923,
      "COV": 2.5962936545662,
      "SHELF LIFE_1": 4
    },
    {
      "PART NUMBER": 4110000970109,
      "DESCRIPTION": "4110004072003 WATER PUMP 4110001009027",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "272139Z00A",
      "DESCRIPTION": "DESICCANT ADAPTED QTY 5",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 11,
      "SHELF LIFE": 66.00000000000027,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 48,
      "WHXX": 2,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.375533808099405,
      "MEAN": 0.153846153846153,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 66.00000000000027
    },
    {
      "PART NUMBER": "250389Z01D",
      "DESCRIPTION": "O RING ADAPTED QTY 10",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 15,
      "SHELF LIFE": 90.00000000000037,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 54,
      "WHXX": 53,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 90.00000000000037
    },
    {
      "PART NUMBER": 4110000988167,
      "DESCRIPTION": "STARTER MOTOR 4110000988095",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 21134532,
      "DESCRIPTION": "RETURN LINE",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14880431,
      "DESCRIPTION": "964832 CLEVIS PIN",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 2,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "7114-17250",
      "DESCRIPTION": "ROLLER BEARING_",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 5,
      "WHXX": 4,
      "WH REFERENCE": "WHXX",
      "TREND": 0,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": "9541-01062",
      "DESCRIPTION": "RETAINING RING",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 4,
      "WHXX": 4,
      "WH REFERENCE": "WHXX",
      "TREND": 0,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": 14646872,
      "DESCRIPTION": "FILTER ELEMENT EW145B S/N: 19764-",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "AVERAGE (F2)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 2.33333333333333,
      "MAINTANING STOCK QTY": 9,
      "AVAILABLE": 1,
      "SHELF LIFE": 0.4285714285714292,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 8,
      "APRD QTY": 8,
      "WH12": 1,
      "WHXX": 41,
      "WH REFERENCE": "WHXX",
      "TREND": 1.21,
      "STD": 1.8639632438326,
      "MEAN": 2.15384615384615,
      "COV": 0.865411506065139,
      "SHELF LIFE_1": 3.857142857142863
    },
    {
      "PART NUMBER": 15427060,
      "DESCRIPTION": "BEARING SPINDLE",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14680534,
      "DESCRIPTION": "TOOTH  (ET)  EC140B/EW145B GP 14527863",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 3.75,
      "MAINTANING STOCK QTY": 15,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 15,
      "APRD QTY": 0,
      "WH12": 148,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 0.89,
      "STD": 4.7366546671567,
      "MEAN": 3.46153846153846,
      "COV": 1.36836690384527,
      "SHELF LIFE_1": 4
    },
    {
      "PART NUMBER": 14680532,
      "DESCRIPTION": "TOOTH (ET)  EC210B/D EC220D  GP ",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "AVERAGE (F2)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 8.16666666666666,
      "MAINTANING STOCK QTY": 33,
      "AVAILABLE": 3,
      "SHELF LIFE": 0.36734693877551045,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 30,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 12,
      "WH REFERENCE": "PARTIAL WHXX",
      "TREND": 0.71,
      "STD": 6.74061550868297,
      "MEAN": 7.53846153846153,
      "COV": 0.894163281764068,
      "SHELF LIFE_1": 4.040816326530615
    },
    {
      "PART NUMBER": 14680533,
      "DESCRIPTION": "TOOTH  (ET)  EC240B/EC250D EC290B/EC300D  GP",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 1.66666666666666,
      "MAINTANING STOCK QTY": 7,
      "AVAILABLE": 2,
      "SHELF LIFE": 1.2000000000000048,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 5,
      "APRD QTY": 5,
      "WH12": 0,
      "WHXX": 2,
      "WH REFERENCE": "PARTIAL WHXX",
      "TREND": 2,
      "STD": 3.77746353645283,
      "MEAN": 1.53846153846153,
      "COV": 2.45535129869434,
      "SHELF LIFE_1": 4.200000000000016
    },
    {
      "PART NUMBER": 29090008771,
      "DESCRIPTION": "PLANET CARRIER",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 29070013111,
      "DESCRIPTION": "GEAR RING",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 2,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "9453-03615",
      "DESCRIPTION": "HOSE ASSEMBLY",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 5,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 29110000042,
      "DESCRIPTION": "29110000043 GASKET",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 16,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 969373,
      "DESCRIPTION": "60110101 SIX POINT SOCKET SCREW",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 4,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "7114-12540",
      "DESCRIPTION": "SPRING",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.25,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 6,
      "WHXX": 10,
      "WH REFERENCE": "WHXX",
      "TREND": 2,
      "STD": 0.832050294337843,
      "MEAN": 0.23076923076923,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 4
    },
    {
      "PART NUMBER": 12813371,
      "DESCRIPTION": "EXPANSION TANK 12780561 12808802",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "7114-14370",
      "DESCRIPTION": "SPACER",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 4,
      "WHXX": 6,
      "WH REFERENCE": "WHXX",
      "TREND": 0,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": 4014000118,
      "DESCRIPTION": "SCREW",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 6,
      "WHXX": 1,
      "WH REFERENCE": "WHXX",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": 4021000044,
      "DESCRIPTION": "ROLLER BEARING",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 14,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4021000045,
      "DESCRIPTION": "ROLLER BEARING",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 8,
      "WHXX": 1,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 5222677134,
      "DESCRIPTION": "FUEL FILTER 166959Z01C",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "AVERAGE (F2)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 2.83333333333333,
      "MAINTANING STOCK QTY": 11,
      "AVAILABLE": 5,
      "SHELF LIFE": 1.7647058823529433,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 6,
      "APRD QTY": 6,
      "WH12": 23,
      "WHXX": 20,
      "WH REFERENCE": "WHXX",
      "TREND": 0.47,
      "STD": 3.09673111356856,
      "MEAN": 2.61538461538461,
      "COV": 1.18404424930562,
      "SHELF LIFE_1": 3.8823529411764754
    },
    {
      "PART NUMBER": 4110000909011,
      "DESCRIPTION": "SOLENOID",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 5,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 1.5,
      "STD": 0.480384461415261,
      "MEAN": 0.307692307692307,
      "COV": 1.56124949959959,
      "SHELF LIFE_1": 3.000000000000003
    },
    {
      "PART NUMBER": 4030001002,
      "DESCRIPTION": "SEALING RING",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.666666666666666,
      "MAINTANING STOCK QTY": 3,
      "AVAILABLE": 3,
      "SHELF LIFE": 4.500000000000004,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 6,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 2.21880078490091,
      "MEAN": 0.615384615384615,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 4.500000000000004
    },
    {
      "PART NUMBER": 5282682780,
      "DESCRIPTION": "CAB CELULOSE FILTER",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "AVERAGE (F2)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 4.5,
      "MAINTANING STOCK QTY": 18,
      "AVAILABLE": 7,
      "SHELF LIFE": 1.5555555555555556,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 11,
      "APRD QTY": 11,
      "WH12": 2,
      "WHXX": 55,
      "WH REFERENCE": "WHXX",
      "TREND": 0.74,
      "STD": 4.99743523963632,
      "MEAN": 4.15384615384615,
      "COV": 1.20308626139393,
      "SHELF LIFE_1": 4
    },
    {
      "PART NUMBER": "322869Z01A",
      "DESCRIPTION": "SPACER",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "324979Z00B",
      "DESCRIPTION": "GASKET GKE370",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 14,
      "WHXX": 10,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 11305113,
      "DESCRIPTION": "HORN",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14679709,
      "DESCRIPTION": "GLASS",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 4,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "164009Z01C",
      "DESCRIPTION": "FUEL FILTER",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14696077,
      "DESCRIPTION": "RELAY 1021-00910",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 4,
      "WHXX": 2,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "150-30-13430",
      "DESCRIPTION": "RING",
      "PRODUCT CATEGORY": "SDG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 1,
      "MAINTANING STOCK QTY": 4,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 4,
      "APRD QTY": 4,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 1.33,
      "STD": 2.39657875806111,
      "MEAN": 0.923076923076923,
      "COV": 2.5962936545662,
      "SHELF LIFE_1": 4
    },
    {
      "PART NUMBER": 5222888240,
      "DESCRIPTION": "SENSOR 327059Z00D",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 6,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14680749,
      "DESCRIPTION": "SHAFT 14609430 ",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.416666666666666,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 1,
      "SHELF LIFE": 2.400000000000004,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 1.2,
      "STD": 0.960768922830522,
      "MEAN": 0.384615384615384,
      "COV": 2.49799919935935,
      "SHELF LIFE_1": 4.800000000000008
    },
    {
      "PART NUMBER": 6727475,
      "DESCRIPTION": "CAP, OIL HYD",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "AVERAGE (F2)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 1.16666666666666,
      "MAINTANING STOCK QTY": 5,
      "AVAILABLE": 7,
      "SHELF LIFE": 6.000000000000034,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 44,
      "WHXX": 3,
      "WH REFERENCE": "",
      "TREND": 0.71,
      "STD": 1.44115338424578,
      "MEAN": 1.07692307692307,
      "COV": 1.33821385679965,
      "SHELF LIFE_1": 6.000000000000034
    },
    {
      "PART NUMBER": 6674316,
      "DESCRIPTION": "SWITCH, PRESS OIL HYD",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 6,
      "WHXX": 1,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 7008814,
      "DESCRIPTION": "BULB 37.5W",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 30,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 7251340,
      "DESCRIPTION": "ASSY LIGHT HEAD RH",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 5,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 7251341,
      "DESCRIPTION": "ASSY LIGHT HEAD LH",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 9,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 6675336,
      "DESCRIPTION": "LIGHT BULB 50W",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 32,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 6654806,
      "DESCRIPTION": "FUSE 15A",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 1,
      "SHELF LIFE": 6.000000000000024,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 31,
      "WHXX": 2,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": 6702444,
      "DESCRIPTION": "FUSE 25A5",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.416666666666666,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 2,
      "SHELF LIFE": 4.800000000000008,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 36,
      "WHXX": 4,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 1.38675049056307,
      "MEAN": 0.384615384615384,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 4.800000000000008
    },
    {
      "PART NUMBER": 6718034,
      "DESCRIPTION": "FUSE 30A",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 1,
      "SHELF LIFE": 6.000000000000024,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 17,
      "WHXX": 1,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": 6679820,
      "DESCRIPTION": "SWITCH RELAY MAGNETIC",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 47,
      "WHXX": 6,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 6680418,
      "DESCRIPTION": "ASSY HANDLE",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 6680419,
      "DESCRIPTION": "LH JOYSTICK HANDLE-MANUAL CONTROL",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 15164333,
      "DESCRIPTION": "LOCK CYLINDER",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 1,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 6685190,
      "DESCRIPTION": "STARTER",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 8,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 6678205,
      "DESCRIPTION": "ALTERNATOR 90 AMP",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 1,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 7193354,
      "DESCRIPTION": "FILTER AIR RECIRCULATING",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 6,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 7176099,
      "DESCRIPTION": "FILTER AIR FRESH 10",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "AVERAGE (F2)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.666666666666666,
      "MAINTANING STOCK QTY": 3,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 3,
      "APRD QTY": 3,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 1.25,
      "STD": 1.04390784542678,
      "MEAN": 0.615384615384615,
      "COV": 1.69635024881852,
      "SHELF LIFE_1": 4.500000000000004
    },
    {
      "PART NUMBER": 7012314,
      "DESCRIPTION": "FILTER OIL HYD",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 31,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 7188792,
      "DESCRIPTION": "BELT DRIVE",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 2,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 6668819,
      "DESCRIPTION": "FILTER OIL HYD",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 22,
      "WHXX": 1,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 6661248,
      "DESCRIPTION": "FILTER OIL HYD 12",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 16,
      "WHXX": 4,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 6662855,
      "DESCRIPTION": "BELT DRIVE",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 1,
      "SHELF LIFE": 6.000000000000024,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.375533808099405,
      "MEAN": 0.153846153846153,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": 7146391,
      "DESCRIPTION": "BELT, DRIVE  S510/S570",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "AVERAGE (F2)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 1.33333333333333,
      "MAINTANING STOCK QTY": 5,
      "AVAILABLE": 6,
      "SHELF LIFE": 4.5000000000000115,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 106,
      "WHXX": 5,
      "WH REFERENCE": "",
      "TREND": 0.63,
      "STD": 1.58922654950168,
      "MEAN": 1.23076923076923,
      "COV": 1.29124657147011,
      "SHELF LIFE_1": 4.5000000000000115
    },
    {
      "PART NUMBER": 7143498,
      "DESCRIPTION": "BELT ALTERNATOR ",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 1,
      "SHELF LIFE": 3.000000000000003,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 45,
      "WHXX": 2,
      "WH REFERENCE": "",
      "TREND": 0.5,
      "STD": 0.480384461415261,
      "MEAN": 0.307692307692307,
      "COV": 1.56124949959959,
      "SHELF LIFE_1": 3.000000000000003
    },
    {
      "PART NUMBER": 7100104,
      "DESCRIPTION": "BELT ALT",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 15,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 6667352,
      "DESCRIPTION": "FILTER, FUEL W/SEPARATOR 12",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "AVERAGE (F2)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 2.58333333333333,
      "MAINTANING STOCK QTY": 10,
      "AVAILABLE": 14,
      "SHELF LIFE": 5.419354838709684,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 218,
      "WHXX": 7,
      "WH REFERENCE": "",
      "TREND": 0.39,
      "STD": 3.73136394942612,
      "MEAN": 2.38461538461538,
      "COV": 1.56476552717869,
      "SHELF LIFE_1": 5.419354838709684
    },
    {
      "PART NUMBER": 6666334,
      "DESCRIPTION": "FILTER AIR INNER",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 9,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 6675517,
      "DESCRIPTION": "FILTER OIL ENG 12",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "AVERAGE (F2)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 1.75,
      "MAINTANING STOCK QTY": 7,
      "AVAILABLE": 8,
      "SHELF LIFE": 4.571428571428571,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 155,
      "WHXX": 6,
      "WH REFERENCE": "",
      "TREND": 0.57,
      "STD": 1.50213523239762,
      "MEAN": 1.61538461538461,
      "COV": 0.929893239103289,
      "SHELF LIFE_1": 4.571428571428571
    },
    {
      "PART NUMBER": 6666375,
      "DESCRIPTION": "FILTER AIR OUTER",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.583333333333333,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 9,
      "SHELF LIFE": 15.428571428571436,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 69,
      "WHXX": 3,
      "WH REFERENCE": "",
      "TREND": 0.86,
      "STD": 0.877058019307029,
      "MEAN": 0.538461538461538,
      "COV": 1.62882203585591,
      "SHELF LIFE_1": 15.428571428571436
    },
    {
      "PART NUMBER": 6666376,
      "DESCRIPTION": "FILTER AIR INNER",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.416666666666666,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 5,
      "SHELF LIFE": 12.000000000000018,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 26,
      "WHXX": 9,
      "WH REFERENCE": "",
      "TREND": 0.8,
      "STD": 0.869718492622904,
      "MEAN": 0.384615384615384,
      "COV": 2.26126808081955,
      "SHELF LIFE_1": 12.000000000000018
    },
    {
      "PART NUMBER": 6692337,
      "DESCRIPTION": "FILTER HYD CARTRIDGE (CANISTER TYPE)",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.416666666666666,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 5,
      "SHELF LIFE": 12.000000000000018,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 32,
      "WHXX": 7,
      "WH REFERENCE": "",
      "TREND": 0.4,
      "STD": 0.65044363558799,
      "MEAN": 0.384615384615384,
      "COV": 1.69115345252877,
      "SHELF LIFE_1": 12.000000000000018
    },
    {
      "PART NUMBER": 7008043,
      "DESCRIPTION": "FILTER AIR OUTER",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 8,
      "WHXX": 4,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 7008044,
      "DESCRIPTION": "FILTER AIR INNER",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 4,
      "WHXX": 2,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 898731,
      "DESCRIPTION": "1156 BULB",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 23,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 7199903,
      "DESCRIPTION": "KIT, SEAL",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 1,
      "SHELF LIFE": 3.000000000000003,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 8,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 1,
      "STD": 0.75106761619881,
      "MEAN": 0.307692307692307,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 3.000000000000003
    },
    {
      "PART NUMBER": 7135489,
      "DESCRIPTION": "KIT, SEAL",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 1,
      "SHELF LIFE": 3.000000000000003,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 39,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 1.5,
      "STD": 0.630425171956115,
      "MEAN": 0.307692307692307,
      "COV": 2.04888180885737,
      "SHELF LIFE_1": 3.000000000000003
    },
    {
      "PART NUMBER": 7246802,
      "DESCRIPTION": "COUPLER FF FEMALE",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": 6666896,
      "DESCRIPTION": "AUGER ASSY 24 RD",
      "PRODUCT CATEGORY": "DBG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 5222416915,
      "DESCRIPTION": "LID",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 5,
      "WHXX": 10,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 6657635,
      "DESCRIPTION": "OIL FILTER",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 11,
      "WHXX": 1,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 6672467,
      "DESCRIPTION": "AIR CLEANER OUTER",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 29,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 6672468,
      "DESCRIPTION": "AIR CLEANER INNER",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 26,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 6652802,
      "DESCRIPTION": "3/8\" BRUSH BRISTLE",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 14,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1076,
      "WHXX": 40,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14590799,
      "DESCRIPTION": "MIRROR",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 4,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4030000233,
      "DESCRIPTION": "O-RING",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.5,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 2,
      "APRD QTY": 2,
      "WH12": 16,
      "WHXX": 13,
      "WH REFERENCE": "WHXX",
      "TREND": 1.33,
      "STD": 0.877058019307029,
      "MEAN": 0.461538461538461,
      "COV": 1.90029237516522,
      "SHELF LIFE_1": 4
    },
    {
      "PART NUMBER": 4130001300,
      "DESCRIPTION": "PRESSURE SWITCH",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 3,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4110000988017,
      "DESCRIPTION": "SEALING RING",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 38,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 2,
      "STD": 1.10940039245045,
      "MEAN": 0.307692307692307,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 3.000000000000003
    },
    {
      "PART NUMBER": "7114-14220",
      "DESCRIPTION": "BALL BEARING",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 8,
      "WH REFERENCE": "WHXX",
      "TREND": 0,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": 14566886,
      "DESCRIPTION": "CABLE HARNESS",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4110001009070,
      "DESCRIPTION": "SENSOR 4110001007010",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 8,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 7219324,
      "DESCRIPTION": "RELIEF VALVE",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 6718414,
      "DESCRIPTION": "SENDER ENGINE TEMP",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 8,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4110001009037,
      "DESCRIPTION": "ALTERNATOR 4130001901 ",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 1,
      "STD": 0.375533808099405,
      "MEAN": 0.153846153846153,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": 17431131,
      "DESCRIPTION": "PIN",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 2,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 992453,
      "DESCRIPTION": "FUSE",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 1.16666666666666,
      "MAINTANING STOCK QTY": 5,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 5,
      "APRD QTY": 5,
      "WH12": 21,
      "WHXX": 3,
      "WH REFERENCE": "WH12 + WHXX",
      "TREND": 2,
      "STD": 3.8829013735766,
      "MEAN": 1.07692307692307,
      "COV": 3.60555127546399,
      "SHELF LIFE_1": 4.28571428571431
    },
    {
      "PART NUMBER": 4110000054316,
      "DESCRIPTION": "TURBOCHARGER",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "320139Z00B",
      "DESCRIPTION": "O-RING",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 5,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 28,
      "WHXX": 29,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "265619Z00D",
      "DESCRIPTION": "5222884562 BRACKET",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "261029Z00D",
      "DESCRIPTION": "RETAINER",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 7,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 15424353,
      "DESCRIPTION": "24040493 STARTER MOTOR  20459062 22851167 ",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 6651512,
      "DESCRIPTION": "BACK-UP ALARM",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 29070018192,
      "DESCRIPTION": "DUST CAP",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 7,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14634521,
      "DESCRIPTION": "SWITCH",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 1,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 2,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4120004036,
      "DESCRIPTION": "4120016961 FILTER ASSY.",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 8,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 5222737545,
      "DESCRIPTION": "VALVE 324609Z00C",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 1,
      "SHELF LIFE": 6.000000000000024,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 33,
      "WHXX": 6,
      "WH REFERENCE": "",
      "TREND": 1,
      "STD": 0.375533808099405,
      "MEAN": 0.153846153846153,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": "250389Z01E",
      "DESCRIPTION": "O-RING",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 7,
      "SHELF LIFE": 42.00000000000017,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 14,
      "WHXX": 48,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 42.00000000000017
    },
    {
      "PART NUMBER": 22283480,
      "DESCRIPTION": "TURBOCHARGER 22205087 22067460",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 1,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 17457469,
      "DESCRIPTION": "17535679 OIL FILTER 3831236 ",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 15,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 15168370,
      "DESCRIPTION": "TENSIONER",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14704862,
      "DESCRIPTION": "ENGINE COOLING FAN",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 4,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14677045,
      "DESCRIPTION": "PIPE 14522382",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 1,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "213809Z00A",
      "DESCRIPTION": "HOSE",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 7,
      "WH REFERENCE": "WHXX",
      "TREND": 0,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": "324349Z00B",
      "DESCRIPTION": "CONNECTOR",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 8,
      "WHXX": 10,
      "WH REFERENCE": "WHXX",
      "TREND": 0,
      "STD": 1.10940039245045,
      "MEAN": 0.307692307692307,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 3.000000000000003
    },
    {
      "PART NUMBER": 6680018,
      "DESCRIPTION": "COUPLER, FF FEMALE",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14695920,
      "DESCRIPTION": "HOSE_DISCHARGE EC210B 14601887 ",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4061007,
      "DESCRIPTION": "ALTERNATOR",
      "PRODUCT CATEGORY": "CUG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 29070007681,
      "DESCRIPTION": "O-RING",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 13,
      "WHXX": 3,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4190002865,
      "DESCRIPTION": "4190002907003 FAN",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14686427,
      "DESCRIPTION": "SPROCKET ENDURANCE EC140B/EC210B",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 9,
      "WHXX": 2,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 82345507,
      "DESCRIPTION": "SHEAR SCREW",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 6538846,
      "DESCRIPTION": "BEARING, SWEEPER",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14592560,
      "DESCRIPTION": "TAIL LAMP EW145",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 2,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 8,
      "WHXX": 2,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 17387726,
      "DESCRIPTION": "ENGINE EC210D D5E TR 3",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 29050020551,
      "DESCRIPTION": "SHIM",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 8,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 1,
      "STD": 0.375533808099405,
      "MEAN": 0.153846153846153,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": 29050020561,
      "DESCRIPTION": "SHIM",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 1,
      "STD": 0.375533808099405,
      "MEAN": 0.153846153846153,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": 29050020571,
      "DESCRIPTION": "SHIM",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 7,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 1,
      "STD": 0.375533808099405,
      "MEAN": 0.153846153846153,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": 29050020921,
      "DESCRIPTION": "SHIM",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 6,
      "WHXX": 1,
      "WH REFERENCE": "WHXX",
      "TREND": 1,
      "STD": 0.375533808099405,
      "MEAN": 0.153846153846153,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": 7120401,
      "DESCRIPTION": "GLASS, DOOR",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4043000256,
      "DESCRIPTION": "SEALING RING",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 4,
      "WHXX": 19,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 935013,
      "DESCRIPTION": "ELBOW NIPPLE",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 12727853,
      "DESCRIPTION": "WASHER",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 15,
      "WHXX": 19,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14715404,
      "DESCRIPTION": "IDLER ENDURANCE  EC210 14695200 14686450",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 39,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14697658,
      "DESCRIPTION": "CONTROL 14631179 14590052 ",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 1,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 1,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "A210111000080",
      "DESCRIPTION": "BOLT",
      "PRODUCT CATEGORY": "YCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": 21271982,
      "DESCRIPTION": "SENSOR",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 4,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 22239056,
      "DESCRIPTION": "24159415 HEADLAMP",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.416666666666666,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 2,
      "APRD QTY": 2,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 2,
      "STD": 1.12089707663561,
      "MEAN": 0.384615384615384,
      "COV": 2.91433239925258,
      "SHELF LIFE_1": 4.800000000000008
    },
    {
      "PART NUMBER": 22239057,
      "DESCRIPTION": "24159417 HEADLAMP",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 2,
      "STD": 0.85485041426511,
      "MEAN": 0.307692307692307,
      "COV": 2.7782638463616,
      "SHELF LIFE_1": 3.000000000000003
    },
    {
      "PART NUMBER": 22384866,
      "DESCRIPTION": "STARTER SWITCH",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 5,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 6598492,
      "DESCRIPTION": "AIR FILTER OUTER",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 32,
      "WHXX": 2,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 6598362,
      "DESCRIPTION": "AIR FILTER INNER",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 37,
      "WHXX": 2,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 22662214,
      "DESCRIPTION": "CONNECTING CABLE 20450687",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 5,
      "WHXX": 4,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 29290013761,
      "DESCRIPTION": "REAR VIEW MIRROR",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 6,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "9285-11000",
      "DESCRIPTION": "PLAIN WASHER",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 2,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 5222748702,
      "DESCRIPTION": "FUEL FILTER WATER SEPARATOR 164009Z00C ",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "AVERAGE (F2)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 3.83333333333333,
      "MAINTANING STOCK QTY": 15,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 15,
      "APRD QTY": 15,
      "WH12": 77,
      "WHXX": 30,
      "WH REFERENCE": "WHXX",
      "TREND": 0.91,
      "STD": 3.99197913770819,
      "MEAN": 3.53846153846153,
      "COV": 1.1281680171784,
      "SHELF LIFE_1": 3.913043478260873
    },
    {
      "PART NUMBER": 84415558,
      "DESCRIPTION": "LOCK",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4120006350,
      "DESCRIPTION": "AIR CYLINDER LG956L 4120006350Z",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.5,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 2,
      "APRD QTY": 2,
      "WH12": 4,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 1.33,
      "STD": 0.877058019307029,
      "MEAN": 0.461538461538461,
      "COV": 1.90029237516522,
      "SHELF LIFE_1": 4
    },
    {
      "PART NUMBER": 17271141,
      "DESCRIPTION": "SENSOR",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.25,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 2,
      "SHELF LIFE": 8,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 3,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.599144689515278,
      "MEAN": 0.23076923076923,
      "COV": 2.5962936545662,
      "SHELF LIFE_1": 8
    },
    {
      "PART NUMBER": 7000769,
      "DESCRIPTION": "FUEL SHUTOFF SOLENOID",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 6691714,
      "DESCRIPTION": "SENSOR, SEATBAR BICS 6680429",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 6,
      "WHXX": 1,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 6680443,
      "DESCRIPTION": "BUSHING, MAGNET",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 60053982,
      "DESCRIPTION": "DESICCATOR",
      "PRODUCT CATEGORY": "YCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14629275,
      "DESCRIPTION": "WASHER PUMP",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 1,
      "SHELF LIFE": 12.000000000000005,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 6,
      "WHXX": 6,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 12.000000000000005
    },
    {
      "PART NUMBER": 14522473,
      "DESCRIPTION": "WASHER",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 1,
      "SHELF LIFE": 6.000000000000024,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 4,
      "WHXX": 7,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": 4110000991053,
      "DESCRIPTION": "HAND PUMP",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 2,
      "STD": 0.375533808099405,
      "MEAN": 0.153846153846153,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": 14656785,
      "DESCRIPTION": "SEALING  14532325",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 3,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 15066986,
      "DESCRIPTION": "INSULATION",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 3,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14882685,
      "DESCRIPTION": "ORING",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 28,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 50,
      "WHXX": 12,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4190002851,
      "DESCRIPTION": "SENSOR 4130002904108",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.25,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 1,
      "WHXX": 1,
      "WH REFERENCE": "WHXX",
      "TREND": 2,
      "STD": 0.599144689515278,
      "MEAN": 0.23076923076923,
      "COV": 2.5962936545662,
      "SHELF LIFE_1": 4
    },
    {
      "PART NUMBER": 14640021,
      "DESCRIPTION": " BALL BEARING",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 5,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 0,
      "STD": 0.375533808099405,
      "MEAN": 0.153846153846153,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": 14687316,
      "DESCRIPTION": "ENDURANCE, ROLLER, TOP EC210B (ET) 14545571 ",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.25,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 2,
      "SHELF LIFE": 8,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 111,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.832050294337843,
      "MEAN": 0.23076923076923,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 8
    },
    {
      "PART NUMBER": 14686458,
      "DESCRIPTION": "ENDURANCE. TRACK LINK ASSY 49L EC210BLC  14530361",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 2,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "7114-12200",
      "DESCRIPTION": "SPACER",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 5,
      "WHXX": 3,
      "WH REFERENCE": "WHXX",
      "TREND": 1,
      "STD": 0.75106761619881,
      "MEAN": 0.307692307692307,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 3.000000000000003
    },
    {
      "PART NUMBER": "9612-05030",
      "DESCRIPTION": "BEARING 14880949",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.416666666666666,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 2,
      "APRD QTY": 0,
      "WH12": 8,
      "WHXX": 5,
      "WH REFERENCE": "WHXX",
      "TREND": 0.8,
      "STD": 0.767947647788304,
      "MEAN": 0.384615384615384,
      "COV": 1.99666388424959,
      "SHELF LIFE_1": 4.800000000000008
    },
    {
      "PART NUMBER": "164049Z00D",
      "DESCRIPTION": "SEALING RING",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 8,
      "WHXX": 6,
      "WH REFERENCE": "WHXX",
      "TREND": 0,
      "STD": 0.375533808099405,
      "MEAN": 0.153846153846153,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": "140129Z00D",
      "DESCRIPTION": "SEALING RING",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 1.25,
      "MAINTANING STOCK QTY": 5,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 5,
      "APRD QTY": 5,
      "WH12": 39,
      "WHXX": 34,
      "WH REFERENCE": "WHXX",
      "TREND": 1.47,
      "STD": 3.15822085585523,
      "MEAN": 1.15384615384615,
      "COV": 2.7371247417412,
      "SHELF LIFE_1": 4
    },
    {
      "PART NUMBER": "210879Z00A",
      "DESCRIPTION": "O-RING",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 10,
      "WHXX": 19,
      "WH REFERENCE": "WHXX",
      "TREND": 0,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": 5222374837,
      "DESCRIPTION": "SEALING RING",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 6,
      "WH REFERENCE": "WHXX",
      "TREND": 0,
      "STD": 0.75106761619881,
      "MEAN": 0.307692307692307,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 3.000000000000003
    },
    {
      "PART NUMBER": "152189Z00C",
      "DESCRIPTION": "SEALING RING",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 11,
      "WH REFERENCE": "WHXX",
      "TREND": 0,
      "STD": 0.375533808099405,
      "MEAN": 0.153846153846153,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": 22175635,
      "DESCRIPTION": "STARTER APPARATUS SD110B 21798914",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3,
      "WHXX": 1,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "275859Z00D",
      "DESCRIPTION": "WIRING HARNESS",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "271039Z00A",
      "DESCRIPTION": "CABLE",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 9,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14699400,
      "DESCRIPTION": "LOCK 14506769",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 4,
      "WHXX": 5,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 17418267,
      "DESCRIPTION": "V-BELT 21080427",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.583333333333333,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 2,
      "SHELF LIFE": 3.42857142857143,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 2,
      "WH REFERENCE": "",
      "TREND": 0.29,
      "STD": 0.877058019307029,
      "MEAN": 0.538461538461538,
      "COV": 1.62882203585591,
      "SHELF LIFE_1": 3.42857142857143
    },
    {
      "PART NUMBER": 4190002907003,
      "DESCRIPTION": "4130015120001 FAN G9138 4190002865",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 1,
      "STD": 0.75106761619881,
      "MEAN": 0.307692307692307,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 3.000000000000003
    },
    {
      "PART NUMBER": "FF5706",
      "DESCRIPTION": "FUEL FILTER",
      "PRODUCT CATEGORY": "CUF",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 21448926,
      "DESCRIPTION": "ROCKER ARM",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 5,
      "WHXX": 6,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14668664,
      "DESCRIPTION": "EXPANSION TANK 14644804 17454270",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 4,
      "WHXX": 7,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 17418271,
      "DESCRIPTION": "V-RIBBED BELT A40G 20908490",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.5,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 2,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 5,
      "WH REFERENCE": "WHXX",
      "TREND": 0.33,
      "STD": 1.12660142429821,
      "MEAN": 0.461538461538461,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 4
    },
    {
      "PART NUMBER": 4021000368,
      "DESCRIPTION": "ROLLER BEARING 2810000340006",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 10,
      "SHELF LIFE": 60.00000000000024,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 87,
      "WHXX": 6,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 60.00000000000024
    },
    {
      "PART NUMBER": 4021000322,
      "DESCRIPTION": "BEARING  2810000340011",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 10,
      "SHELF LIFE": 60.00000000000024,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 98,
      "WHXX": 4,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 60.00000000000024
    },
    {
      "PART NUMBER": 14688861,
      "DESCRIPTION": "FILTER ELEMENT (EW140/EW145) 14539482_",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "FAST (F1)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 9.16666666666666,
      "MAINTANING STOCK QTY": 37,
      "AVAILABLE": 24,
      "SHELF LIFE": 2.61818181818182,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 13,
      "APRD QTY": 13,
      "WH12": 53,
      "WHXX": 61,
      "WH REFERENCE": "WHXX",
      "TREND": 1.07,
      "STD": 5.69525218369629,
      "MEAN": 8.46153846153846,
      "COV": 0.673075258073198,
      "SHELF LIFE_1": 4.036363636363639
    },
    {
      "PART NUMBER": 14711981,
      "DESCRIPTION": "PILOT ELEMENT (EW140/EW145) 14532686",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "FAST (F1)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 6.75,
      "MAINTANING STOCK QTY": 27,
      "AVAILABLE": 18,
      "SHELF LIFE": 2.6666666666666665,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 9,
      "APRD QTY": 0,
      "WH12": 51,
      "WHXX": 54,
      "WH REFERENCE": "WHXX",
      "TREND": 0.89,
      "STD": 4.28473737339575,
      "MEAN": 6.23076923076923,
      "COV": 0.687673899433886,
      "SHELF LIFE_1": 4
    },
    {
      "PART NUMBER": 14658431,
      "DESCRIPTION": "LINK EC210B",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 10,
      "WHXX": 1,
      "WH REFERENCE": "WHXX",
      "TREND": 1,
      "STD": 0.375533808099405,
      "MEAN": 0.153846153846153,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": 14658432,
      "DESCRIPTION": "LINK EC210B ",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 12,
      "WHXX": 1,
      "WH REFERENCE": "WHXX",
      "TREND": 0.5,
      "STD": 0.630425171956115,
      "MEAN": 0.307692307692307,
      "COV": 2.04888180885737,
      "SHELF LIFE_1": 3.000000000000003
    },
    {
      "PART NUMBER": 14658434,
      "DESCRIPTION": "PIN",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 3,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 1,
      "STD": 0.480384461415261,
      "MEAN": 0.307692307692307,
      "COV": 1.56124949959959,
      "SHELF LIFE_1": 3.000000000000003
    },
    {
      "PART NUMBER": 14650586,
      "DESCRIPTION": "BUSHING",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.25,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 1,
      "WH REFERENCE": "WHXX",
      "TREND": 0.67,
      "STD": 0.438529009653514,
      "MEAN": 0.23076923076923,
      "COV": 1.90029237516522,
      "SHELF LIFE_1": 4
    },
    {
      "PART NUMBER": 4130001731,
      "DESCRIPTION": "LAMP LG956L",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 15,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 22768927,
      "DESCRIPTION": "CONDENSER 22212990 ",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 1,
      "SHELF LIFE": 6.000000000000024,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": 9017216587,
      "DESCRIPTION": "REMAN ENGINE EW145B",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 21792306,
      "DESCRIPTION": "GASKET 20559834",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 1,
      "SHELF LIFE": 12.000000000000005,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 14,
      "WHXX": 12,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 12.000000000000005
    },
    {
      "PART NUMBER": 6516722,
      "DESCRIPTION": "HYDRAULIC OIL FILTER",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 2,
      "SHELF LIFE": 24.00000000000001,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 24,
      "WHXX": 5,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 24.00000000000001
    },
    {
      "PART NUMBER": 6692836,
      "DESCRIPTION": "HYDRAULIC TANK CAP",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 1,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 6,
      "WHXX": 1,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 7010252,
      "DESCRIPTION": "A/C COMPRESSOR BELT",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 4,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 6685105,
      "DESCRIPTION": "WATER PUMP",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 7025590,
      "DESCRIPTION": "ENGINE OIL FILTER",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 7,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 15,
      "WHXX": 5,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 7010351,
      "DESCRIPTION": "FUEL FILTER",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 6,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 10,
      "WHXX": 4,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 7004883,
      "DESCRIPTION": "RETURN FILTER",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 3,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 7013148,
      "DESCRIPTION": "FUEL FILTER ELEMENT",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 9,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 8,
      "WHXX": 4,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4013000193,
      "DESCRIPTION": "NUT",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 37,
      "WHXX": 47,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 29070011032,
      "DESCRIPTION": "SCREW",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 32,
      "WHXX": 22,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 7277987,
      "DESCRIPTION": "AUXILIARY CONTROL CONTROLLER (7012048)",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14662510,
      "DESCRIPTION": "CONTROL LOCKOUT LEVER EC210D",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 2,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 22480372,
      "DESCRIPTION": "FUEL FILTER FM12",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 64,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4110000991,
      "DESCRIPTION": "ENGINE LG936L",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4110000991032,
      "DESCRIPTION": "4110000991031 BEARING SHELL",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4110000991030,
      "DESCRIPTION": "4110000991031 BEARING SHELL",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 1,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 5222771970,
      "DESCRIPTION": "5223658117 OIL FILTER 152009Z00A 21707134",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.666666666666666,
      "MAINTANING STOCK QTY": 3,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 3,
      "APRD QTY": 3,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 0.5,
      "STD": 1.70970082853022,
      "MEAN": 0.615384615384615,
      "COV": 2.7782638463616,
      "SHELF LIFE_1": 4.500000000000004
    },
    {
      "PART NUMBER": 5222771975,
      "DESCRIPTION": "OIL FILTER BYPASS 152009Z00B ",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "AVERAGE (F2)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 5.33333333333333,
      "MAINTANING STOCK QTY": 21,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 21,
      "APRD QTY": 21,
      "WH12": 129,
      "WHXX": 17,
      "WH REFERENCE": "WH12 + WHXX",
      "TREND": 0.84,
      "STD": 6.03409118345558,
      "MEAN": 4.92307692307692,
      "COV": 1.22567477163941,
      "SHELF LIFE_1": 3.937500000000002
    },
    {
      "PART NUMBER": 5222748694,
      "DESCRIPTION": "FUEL FILTER 152009Z00D",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "FAST (F1)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 3.91666666666666,
      "MAINTANING STOCK QTY": 16,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 16,
      "APRD QTY": 16,
      "WH12": 64,
      "WHXX": 23,
      "WH REFERENCE": "WHXX",
      "TREND": 0.89,
      "STD": 4.33086714832148,
      "MEAN": 3.61538461538461,
      "COV": 1.19789942400381,
      "SHELF LIFE_1": 4.08510638297873
    },
    {
      "PART NUMBER": 16688307,
      "DESCRIPTION": "SENSOR 14509344 ",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 1.33333333333333,
      "MAINTANING STOCK QTY": 5,
      "AVAILABLE": 7,
      "SHELF LIFE": 5.250000000000013,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 13,
      "WHXX": 25,
      "WH REFERENCE": "",
      "TREND": 0.25,
      "STD": 2.7127429585153,
      "MEAN": 1.23076923076923,
      "COV": 2.20410365379368,
      "SHELF LIFE_1": 5.250000000000013
    },
    {
      "PART NUMBER": 22282222,
      "DESCRIPTION": "KEY",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14559058,
      "DESCRIPTION": "SEALING STRIP",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 34,
      "WHXX": 13,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 982320,
      "DESCRIPTION": "FUSE",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 9,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 2,
      "STD": 1.10940039245045,
      "MEAN": 0.307692307692307,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 3.000000000000003
    },
    {
      "PART NUMBER": 6735884,
      "DESCRIPTION": "IDLER ASSY, BELT DRIVE",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 29,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 0.5,
      "STD": 0.480384461415261,
      "MEAN": 0.307692307692307,
      "COV": 1.56124949959959,
      "SHELF LIFE_1": 3.000000000000003
    },
    {
      "PART NUMBER": 4110003158,
      "DESCRIPTION": "BRAKE SHOE KIT",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 14,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 0,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": 7230495,
      "DESCRIPTION": "LIFT MANUAL VALVE",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 29050011362,
      "DESCRIPTION": "29050011363  GASKET",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 17,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 29050025561,
      "DESCRIPTION": "SHIM",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 4,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 1,
      "STD": 0.375533808099405,
      "MEAN": 0.153846153846153,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": 4110001009040,
      "DESCRIPTION": "V-BELT 4110002147005 ",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 9,
      "WHXX": 3,
      "WH REFERENCE": "WHXX",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": 6661611,
      "DESCRIPTION": "ALTERNATOR",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 21583792,
      "DESCRIPTION": "KIT",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14679620,
      "DESCRIPTION": "GLASS EC210D",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 6,
      "WHXX": 3,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 82354791,
      "DESCRIPTION": "23515346 VENTILATION FILTER FH 400",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": 12746651,
      "DESCRIPTION": "SCREW",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 13,
      "WHXX": 19,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14697657,
      "DESCRIPTION": "CONTROL 14631180 14590054",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 8,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 2,
      "STD": 0.375533808099405,
      "MEAN": 0.153846153846153,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": 14708228,
      "DESCRIPTION": "WINDSCREEN 14630898 14506805",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 4,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 7234460,
      "DESCRIPTION": "WHEEL RIM",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 6,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 22803123,
      "DESCRIPTION": "24046348 FUEL PUMP 20980697 ",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 1,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 17470368,
      "DESCRIPTION": "VCS COOLANT (40:60 READY MIXED) 18L/PAIL YELLOW ",
      "PRODUCT CATEGORY": "LUV",
      "ITEM MOVEMENT BRANCH": "FAST (F1)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 222,
      "MAINTANING STOCK QTY": 888,
      "AVAILABLE": 504,
      "SHELF LIFE": 2.27027027027027,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 384,
      "APRD QTY": 378,
      "WH12": 4867,
      "WHXX": 18,
      "WH REFERENCE": "WH12 + WHXX",
      "TREND": 0.73,
      "STD": 293.896371061428,
      "MEAN": 204.923076923076,
      "COV": 1.43417898791237,
      "SHELF LIFE_1": 4
    },
    {
      "PART NUMBER": 4110000054118,
      "DESCRIPTION": "PULLEY",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 1,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4110000555268,
      "DESCRIPTION": "ALTERNATOR",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 5222401537,
      "DESCRIPTION": "FILLER CAP 172529Z00B",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 3,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 5,
      "WHXX": 4,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 7136559,
      "DESCRIPTION": "SOLENOID ASSEMBLY",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "B230101001368",
      "DESCRIPTION": "DUST RING",
      "PRODUCT CATEGORY": "YCG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.5,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 2,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 0,
      "STD": 1.66410058867568,
      "MEAN": 0.461538461538461,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 4
    },
    {
      "PART NUMBER": 6989019,
      "DESCRIPTION": "WIRE BRISTLE 21 3/8-CONVOLUTED",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 9,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2172,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 9021647837,
      "DESCRIPTION": "TURBOCHARGER REMAN EC210B",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 9021092586,
      "DESCRIPTION": "TURBOCHARGER REMAN EC210D",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 4,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 1,
      "STD": 0.375533808099405,
      "MEAN": 0.153846153846153,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": "322179Z00C",
      "DESCRIPTION": "GEAR GKE370",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 1,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14686465,
      "DESCRIPTION": "TRACK SHOE, ENDURANCE",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.75,
      "MAINTANING STOCK QTY": 3,
      "AVAILABLE": 19,
      "SHELF LIFE": 25.333333333333332,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 20,
      "WHXX": 56,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 1.70218562367207,
      "MEAN": 0.692307692307692,
      "COV": 2.45871256752633,
      "SHELF LIFE_1": 25.333333333333332
    },
    {
      "PART NUMBER": 14715403,
      "DESCRIPTION": "BOTTOM ROLLER, ENDURANCE EC210B",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 1.66666666666666,
      "MAINTANING STOCK QTY": 7,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 7,
      "APRD QTY": 0,
      "WH12": 78,
      "WHXX": 22,
      "WH REFERENCE": "WHXX",
      "TREND": 0.4,
      "STD": 4.48358830654243,
      "MEAN": 1.53846153846153,
      "COV": 2.91433239925258,
      "SHELF LIFE_1": 4.200000000000016
    },
    {
      "PART NUMBER": 6676029,
      "DESCRIPTION": "LOCK SPOOL SOLENOID",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4110000127008,
      "DESCRIPTION": "FUEL PUMP LG936L",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 8,
      "WHXX": 5,
      "WH REFERENCE": "WHXX",
      "TREND": 2,
      "STD": 0.375533808099405,
      "MEAN": 0.153846153846153,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": 4130001732,
      "DESCRIPTION": "LAMP",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 6680441,
      "DESCRIPTION": "BUSHING",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 6,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 0,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": 22383382,
      "DESCRIPTION": "LEVEL SENSOR",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 4,
      "WHXX": 1,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 995871,
      "DESCRIPTION": "GASKET",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 6,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14561415,
      "DESCRIPTION": "SWITCH EC210D",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 3,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 17007124,
      "DESCRIPTION": "BACK-UP WARNING UNIT SD110B",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 22905123,
      "DESCRIPTION": "24046345 FUEL PUMP 21620116 21019945",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "AVERAGE (F2)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 1.16666666666666,
      "MAINTANING STOCK QTY": 5,
      "AVAILABLE": 1,
      "SHELF LIFE": 0.857142857142862,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 4,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 1,
      "STD": 1.11516355015295,
      "MEAN": 1.07692307692307,
      "COV": 1.03550901085631,
      "SHELF LIFE_1": 4.28571428571431
    },
    {
      "PART NUMBER": 4110001031031,
      "DESCRIPTION": "CONNECTOR 4110001525018",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 8,
      "WHXX": 4,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 12811798,
      "DESCRIPTION": "FILLER CAP",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 3,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 17410278,
      "DESCRIPTION": "FILTER ELEMENT A40G",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.666666666666666,
      "MAINTANING STOCK QTY": 3,
      "AVAILABLE": 2,
      "SHELF LIFE": 3.000000000000003,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 10,
      "WHXX": 5,
      "WH REFERENCE": "WHXX",
      "TREND": 0,
      "STD": 1.19292787840544,
      "MEAN": 0.615384615384615,
      "COV": 1.93850780240885,
      "SHELF LIFE_1": 4.500000000000004
    },
    {
      "PART NUMBER": 17410282,
      "DESCRIPTION": "FILTER ELEMENT A40G",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 2.08333333333333,
      "MAINTANING STOCK QTY": 8,
      "AVAILABLE": 10,
      "SHELF LIFE": 4.800000000000008,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 38,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 3.32820117735137,
      "MEAN": 1.92307692307692,
      "COV": 1.73066461222271,
      "SHELF LIFE_1": 4.800000000000008
    },
    {
      "PART NUMBER": 5235899,
      "DESCRIPTION": "BUSHING",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 3,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 15185410,
      "DESCRIPTION": "ROLLER BEARING",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 17460623,
      "DESCRIPTION": "SEALING RING ",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 3,
      "WH REFERENCE": "WHXX",
      "TREND": 0,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": 1161937,
      "DESCRIPTION": "TRANSMISSION OIL 85W-140  20L ",
      "PRODUCT CATEGORY": "LUV",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 13.3333333333333,
      "MAINTANING STOCK QTY": 53,
      "AVAILABLE": 516,
      "SHELF LIFE": 38.700000000000095,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2972,
      "WHXX": 388,
      "WH REFERENCE": "",
      "TREND": 1.95,
      "STD": 43.1883175086824,
      "MEAN": 12.3076923076923,
      "COV": 3.50905079758045,
      "SHELF LIFE_1": 38.700000000000095
    },
    {
      "PART NUMBER": 70930466,
      "DESCRIPTION": "FUSE HOLDER",
      "PRODUCT CATEGORY": "VCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "U81266",
      "DESCRIPTION": "CHARGING VALVE",
      "PRODUCT CATEGORY": "SOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 6625575,
      "DESCRIPTION": "FUSE 20A",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 9,
      "WHXX": 1,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 22089218,
      "DESCRIPTION": "BELT TENSIONER EC210D",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 1,
      "SHELF LIFE": 6.000000000000024,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 8,
      "WHXX": 2,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.375533808099405,
      "MEAN": 0.153846153846153,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": 6571857,
      "DESCRIPTION": "ALTERNATOR BELT",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14703234,
      "DESCRIPTION": "SHIM",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 3,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 21,
      "WHXX": 6,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "322179Z00A",
      "DESCRIPTION": "GEAR",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4110000054253,
      "DESCRIPTION": "FUEL FILTER",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 7,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "83D4",
      "DESCRIPTION": "NUT",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 11,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4110000011114,
      "DESCRIPTION": "PLATE",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4110000011115,
      "DESCRIPTION": "PLATE",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "307089Z00A",
      "DESCRIPTION": "GASKET",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 34,
      "WHXX": 25,
      "WH REFERENCE": "WHXX",
      "TREND": 0,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": 22899626,
      "DESCRIPTION": "PRESSURE SENSOR 21634021 ",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 1,
      "SHELF LIFE": 12.000000000000005,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 12.000000000000005
    },
    {
      "PART NUMBER": "4120006350Z",
      "DESCRIPTION": "4120006350  AIR CYLINDER",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4190002587,
      "DESCRIPTION": "WIPER MOTOR",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14560268,
      "DESCRIPTION": "O-RING",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 2.33333333333333,
      "MAINTANING STOCK QTY": 9,
      "AVAILABLE": 21,
      "SHELF LIFE": 9.000000000000012,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 18,
      "WHXX": 29,
      "WH REFERENCE": "",
      "TREND": 0.71,
      "STD": 2.51151195650992,
      "MEAN": 2.15384615384615,
      "COV": 1.16605912266532,
      "SHELF LIFE_1": 9.000000000000012
    },
    {
      "PART NUMBER": 14560264,
      "DESCRIPTION": "RING",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.916666666666666,
      "MAINTANING STOCK QTY": 4,
      "AVAILABLE": 8,
      "SHELF LIFE": 8.727272727272734,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 8,
      "WHXX": 8,
      "WH REFERENCE": "",
      "TREND": 0.73,
      "STD": 0.987096233585649,
      "MEAN": 0.846153846153846,
      "COV": 1.16656827605576,
      "SHELF LIFE_1": 8.727272727272734
    },
    {
      "PART NUMBER": 4043000026,
      "DESCRIPTION": "BUSHING",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.5,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 2,
      "SHELF LIFE": 4,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0.67,
      "STD": 0.877058019307029,
      "MEAN": 0.461538461538461,
      "COV": 1.90029237516522,
      "SHELF LIFE_1": 4
    },
    {
      "PART NUMBER": 16053916,
      "DESCRIPTION": "TIMER RELAY 16064049",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 2,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "A810201206238",
      "DESCRIPTION": "PRESSURE SENSOR 4 PIN",
      "PRODUCT CATEGORY": "YCG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 4,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": 17489661,
      "DESCRIPTION": "HYDRAULIC VALVE",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14690316,
      "DESCRIPTION": "FILTER ELEMENT 14569658",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "AVERAGE (F2)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 2.83333333333333,
      "MAINTANING STOCK QTY": 11,
      "AVAILABLE": 8,
      "SHELF LIFE": 2.823529411764709,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 3,
      "APRD QTY": 3,
      "WH12": 32,
      "WHXX": 19,
      "WH REFERENCE": "WHXX",
      "TREND": 0.76,
      "STD": 2.46774058396412,
      "MEAN": 2.61538461538461,
      "COV": 0.943547870339223,
      "SHELF LIFE_1": 3.8823529411764754
    },
    {
      "PART NUMBER": 7231756,
      "DESCRIPTION": "COOLING FAN",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 7200001489,
      "DESCRIPTION": "GASKET",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 7200001480,
      "DESCRIPTION": "GASKET",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "C03228",
      "DESCRIPTION": "SOUND PLUG",
      "PRODUCT CATEGORY": "SOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 3,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 0,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": 4130001540,
      "DESCRIPTION": "TAIL LAMP LG8180",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4130001541,
      "DESCRIPTION": "TAIL LAMP LGG8180",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14629276,
      "DESCRIPTION": "SEAL",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 1,
      "SHELF LIFE": 12.000000000000005,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 5,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 12.000000000000005
    },
    {
      "PART NUMBER": "381669Z03D",
      "DESCRIPTION": "FLANGE SCREW",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 1.66666666666666,
      "MAINTANING STOCK QTY": 7,
      "AVAILABLE": 7,
      "SHELF LIFE": 4.200000000000016,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 16,
      "WHXX": 41,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 5.54700196225229,
      "MEAN": 1.53846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 4.200000000000016
    },
    {
      "PART NUMBER": "FLUID41",
      "DESCRIPTION": "FLUID 41 (5L/CAN)",
      "PRODUCT CATEGORY": "LUB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4120004041012,
      "DESCRIPTION": "SEALING RING KIT",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.75,
      "MAINTANING STOCK QTY": 3,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 3,
      "APRD QTY": 3,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 2,
      "STD": 2.49615088301353,
      "MEAN": 0.692307692307692,
      "COV": 3.60555127546399,
      "SHELF LIFE_1": 4
    },
    {
      "PART NUMBER": 22107652,
      "DESCRIPTION": "DESICCANT",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 1,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 6,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 5222889270,
      "DESCRIPTION": "GEAR SHIFT LEVER C 328359Z00A ",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 6625298,
      "DESCRIPTION": "PUMP BELT",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 15,
      "WHXX": 1,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 5222364174,
      "DESCRIPTION": "WHEEL STUD 432409Z00A",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 1.66666666666666,
      "MAINTANING STOCK QTY": 7,
      "AVAILABLE": 5,
      "SHELF LIFE": 3.000000000000012,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 2,
      "APRD QTY": 2,
      "WH12": 0,
      "WHXX": 5,
      "WH REFERENCE": "WHXX",
      "TREND": 2,
      "STD": 5.54700196225229,
      "MEAN": 1.53846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 4.200000000000016
    },
    {
      "PART NUMBER": 60110356,
      "DESCRIPTION": "SCREW 990514 1629544 ",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 268,
      "WHXX": 40,
      "WH REFERENCE": "WHXX",
      "TREND": 0,
      "STD": 1.10940039245045,
      "MEAN": 0.307692307692307,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 3.000000000000003
    },
    {
      "PART NUMBER": "324609Z00B",
      "DESCRIPTION": "VALVE",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.5,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 2,
      "APRD QTY": 0,
      "WH12": 10,
      "WHXX": 8,
      "WH REFERENCE": "WHXX",
      "TREND": 0,
      "STD": 1.66410058867568,
      "MEAN": 0.461538461538461,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 4
    },
    {
      "PART NUMBER": 4130001413,
      "DESCRIPTION": "LAMP",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 24,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4110000054311,
      "DESCRIPTION": "WATER SEPARATOR",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 1,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 11,
      "WHXX": 1,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4110000054211,
      "DESCRIPTION": "PLUG",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4110000054241,
      "DESCRIPTION": "VALVE GUIDE",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 6,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4110000054242,
      "DESCRIPTION": "VALVE GUIDE",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 13,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4110000054216,
      "DESCRIPTION": "VALVE SEAT",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4110000054097,
      "DESCRIPTION": "VALVE SEAT",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 6,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 60208877,
      "DESCRIPTION": "FILTRATION CORE",
      "PRODUCT CATEGORY": "YCG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.25,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 5,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 2,
      "STD": 0.832050294337843,
      "MEAN": 0.23076923076923,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 4
    },
    {
      "PART NUMBER": 4120002264406,
      "DESCRIPTION": "BUSHING",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 22435058,
      "DESCRIPTION": "SENSOR 20820494",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.25,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 1,
      "SHELF LIFE": 4,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 4,
      "WHXX": 2,
      "WH REFERENCE": "",
      "TREND": 1.33,
      "STD": 0.438529009653514,
      "MEAN": 0.23076923076923,
      "COV": 1.90029237516522,
      "SHELF LIFE_1": 4
    },
    {
      "PART NUMBER": 4120006350001,
      "DESCRIPTION": "SEALING RING KIT",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 4,
      "WHXX": 6,
      "WH REFERENCE": "WHXX",
      "TREND": 0,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": 4030000130,
      "DESCRIPTION": "O-RING",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 35,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 15185355,
      "DESCRIPTION": "SHAFT",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4110000991012,
      "DESCRIPTION": "WATER PIPE",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 29220001261,
      "DESCRIPTION": "BLOCK",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14640020,
      "DESCRIPTION": "SUPPORT 14534462 ",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 7,
      "WH REFERENCE": "WHXX",
      "TREND": 1,
      "STD": 0.75106761619881,
      "MEAN": 0.307692307692307,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 3.000000000000003
    },
    {
      "PART NUMBER": 14880126,
      "DESCRIPTION": "T-NIPPLE",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "250389Z00E",
      "DESCRIPTION": "O-RING",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 8,
      "SHELF LIFE": 48.00000000000019,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 22,
      "WHXX": 62,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 48.00000000000019
    },
    {
      "PART NUMBER": 14640073,
      "DESCRIPTION": "HOSE",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 3,
      "WH REFERENCE": "WHXX",
      "TREND": 0,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": 976568,
      "DESCRIPTION": "HOSE CLAMP",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 6,
      "WHXX": 16,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14882198,
      "DESCRIPTION": "HOSE",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.25,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 5,
      "WH REFERENCE": "WHXX",
      "TREND": 0,
      "STD": 0.832050294337843,
      "MEAN": 0.23076923076923,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 4
    },
    {
      "PART NUMBER": 14682233,
      "DESCRIPTION": "RADIATOR HOSE",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 4,
      "WH REFERENCE": "WHXX",
      "TREND": 0,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": 14656784,
      "DESCRIPTION": "BUSHING",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 3,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14882689,
      "DESCRIPTION": "O-RING",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 173,
      "WHXX": 50,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4110003167001,
      "DESCRIPTION": " TRANSMISSION OIL FILTER L956F",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "FAST (F1)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 7.91666666666666,
      "MAINTANING STOCK QTY": 32,
      "AVAILABLE": 31,
      "SHELF LIFE": 3.915789473684214,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 1.54,
      "STD": 15.0797027788161,
      "MEAN": 7.3076923076923,
      "COV": 2.06353827499589,
      "SHELF LIFE_1": 4.042105263157898
    },
    {
      "PART NUMBER": 4110001755023,
      "DESCRIPTION": "AIR FILTER LG956F",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "FAST (F1)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 8.75,
      "MAINTANING STOCK QTY": 35,
      "AVAILABLE": 6,
      "SHELF LIFE": 0.6857142857142857,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 29,
      "APRD QTY": 29,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 1.01,
      "STD": 11.4852190405867,
      "MEAN": 8.07692307692307,
      "COV": 1.42197950026312,
      "SHELF LIFE_1": 4
    },
    {
      "PART NUMBER": 4110001544001,
      "DESCRIPTION": "AIR FILTER INSERT LG956F",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "FAST (F1)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 5.33333333333333,
      "MAINTANING STOCK QTY": 21,
      "AVAILABLE": 22,
      "SHELF LIFE": 4.125000000000003,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 132,
      "WHXX": 8,
      "WH REFERENCE": "",
      "TREND": 1.56,
      "STD": 6.98166095879696,
      "MEAN": 4.92307692307692,
      "COV": 1.41814988225563,
      "SHELF LIFE_1": 4.125000000000003
    },
    {
      "PART NUMBER": 29100001051,
      "DESCRIPTION": "29100001051A  FILTER INSERT LG956F",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 17428016,
      "DESCRIPTION": "RUBBER COVER",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 2,
      "STD": 1.10940039245045,
      "MEAN": 0.307692307692307,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 3.000000000000003
    },
    {
      "PART NUMBER": 4110002287,
      "DESCRIPTION": "FAN",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14560274,
      "DESCRIPTION": "DISC",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 1,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 8,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14560276,
      "DESCRIPTION": "DISC",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 3,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 3,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14558957,
      "DESCRIPTION": "SCREW",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.5,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 2,
      "APRD QTY": 2,
      "WH12": 13,
      "WHXX": 15,
      "WH REFERENCE": "WHXX",
      "TREND": 2,
      "STD": 1.66410058867568,
      "MEAN": 0.461538461538461,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 4
    },
    {
      "PART NUMBER": 11705004,
      "DESCRIPTION": "WASHER",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.5,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 2,
      "APRD QTY": 2,
      "WH12": 0,
      "WHXX": 15,
      "WH REFERENCE": "WHXX",
      "TREND": 2,
      "STD": 1.66410058867568,
      "MEAN": 0.461538461538461,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 4
    },
    {
      "PART NUMBER": 11705003,
      "DESCRIPTION": "COMPRESSION SPRING",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.5,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 2,
      "APRD QTY": 2,
      "WH12": 0,
      "WHXX": 15,
      "WH REFERENCE": "WHXX",
      "TREND": 2,
      "STD": 1.66410058867568,
      "MEAN": 0.461538461538461,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 4
    },
    {
      "PART NUMBER": 14558956,
      "DESCRIPTION": "SPRING",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.5,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 2,
      "APRD QTY": 2,
      "WH12": 0,
      "WHXX": 15,
      "WH REFERENCE": "WHXX",
      "TREND": 2,
      "STD": 1.66410058867568,
      "MEAN": 0.461538461538461,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 4
    },
    {
      "PART NUMBER": 14560269,
      "DESCRIPTION": "O-RING",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 1.33333333333333,
      "MAINTANING STOCK QTY": 5,
      "AVAILABLE": 5,
      "SHELF LIFE": 3.7500000000000098,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 8,
      "WHXX": 2,
      "WH REFERENCE": "",
      "TREND": 0.63,
      "STD": 1.09192842819833,
      "MEAN": 1.23076923076923,
      "COV": 0.887191847911149,
      "SHELF LIFE_1": 3.7500000000000098
    },
    {
      "PART NUMBER": 14560273,
      "DESCRIPTION": "RING",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 1,
      "MAINTANING STOCK QTY": 4,
      "AVAILABLE": 12,
      "SHELF LIFE": 12,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 9,
      "WHXX": 5,
      "WH REFERENCE": "",
      "TREND": 0.5,
      "STD": 1.11516355015295,
      "MEAN": 0.923076923076923,
      "COV": 1.20809384599903,
      "SHELF LIFE_1": 12
    },
    {
      "PART NUMBER": 14560272,
      "DESCRIPTION": "O-RING",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 1.08333333333333,
      "MAINTANING STOCK QTY": 4,
      "AVAILABLE": 7,
      "SHELF LIFE": 6.461538461538482,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3,
      "WHXX": 9,
      "WH REFERENCE": "",
      "TREND": 0.46,
      "STD": 1,
      "MEAN": 1,
      "COV": 1,
      "SHELF LIFE_1": 6.461538461538482
    },
    {
      "PART NUMBER": 11704997,
      "DESCRIPTION": "SEALING RING",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.833333333333333,
      "MAINTANING STOCK QTY": 3,
      "AVAILABLE": 8,
      "SHELF LIFE": 9.600000000000003,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0.6,
      "STD": 0.926808695996298,
      "MEAN": 0.769230769230769,
      "COV": 1.20485130479518,
      "SHELF LIFE_1": 9.600000000000003
    },
    {
      "PART NUMBER": 11704999,
      "DESCRIPTION": "BACK-UP RING",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.833333333333333,
      "MAINTANING STOCK QTY": 3,
      "AVAILABLE": 9,
      "SHELF LIFE": 10.800000000000004,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 9,
      "WHXX": 3,
      "WH REFERENCE": "",
      "TREND": 0.6,
      "STD": 0.926808695996298,
      "MEAN": 0.769230769230769,
      "COV": 1.20485130479518,
      "SHELF LIFE_1": 10.800000000000004
    },
    {
      "PART NUMBER": 17410286,
      "DESCRIPTION": "FILTER ELEMENT 11707544",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 6,
      "SHELF LIFE": 18.000000000000018,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 19,
      "WHXX": 17,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.75106761619881,
      "MEAN": 0.307692307692307,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 18.000000000000018
    },
    {
      "PART NUMBER": 996813,
      "DESCRIPTION": "FLANGE SCREW",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 7020981,
      "DESCRIPTION": "GLOW PLUG",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 5,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 7000645,
      "DESCRIPTION": "CYLINDER HEAD GASKET",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "B222100000641",
      "DESCRIPTION": "PRIMARY AIR FILTER",
      "PRODUCT CATEGORY": "YCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 1,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "B222100000640",
      "DESCRIPTION": "SECONDARY AIR FILTER",
      "PRODUCT CATEGORY": "YCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 2,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 7000626,
      "DESCRIPTION": "SEAL",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 60274433,
      "DESCRIPTION": "OIL FILTER ",
      "PRODUCT CATEGORY": "YPG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 1,
      "SHELF LIFE": 6.000000000000024,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": 60274432,
      "DESCRIPTION": "FUEL FILTER",
      "PRODUCT CATEGORY": "YPG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "9751-15016 ",
      "DESCRIPTION": "GROMMET",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 9,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14552494,
      "DESCRIPTION": "SEAL",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14623350,
      "DESCRIPTION": "BRACKET",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 1,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14686497,
      "DESCRIPTION": "CABLE HARNESS",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 1,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14685140,
      "DESCRIPTION": "ROTATING BEACON",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14562064,
      "DESCRIPTION": "STAY",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 1,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 4,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14600015,
      "DESCRIPTION": "HOSE",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14695913,
      "DESCRIPTION": "HOSE",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14643925,
      "DESCRIPTION": "SWITCH",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 5,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 22191895,
      "DESCRIPTION": "CRANKSHAFT SEAL",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 22879098,
      "DESCRIPTION": "DRAIN VALVE 20450851 ",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 4,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 20934592,
      "DESCRIPTION": "RELAY",
      "PRODUCT CATEGORY": "VCG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 1,
      "SHELF LIFE": 3.000000000000003,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 9,
      "WHXX": 30,
      "WH REFERENCE": "",
      "TREND": 1,
      "STD": 0.75106761619881,
      "MEAN": 0.307692307692307,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 3.000000000000003
    },
    {
      "PART NUMBER": 29070012892,
      "DESCRIPTION": "GEAR RING",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 9,
      "WHXX": 8,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 5282675288,
      "DESCRIPTION": "CONDENSER",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3,
      "WHXX": 3,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14687776,
      "DESCRIPTION": "BRACKET 14594431",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 1,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14708935,
      "DESCRIPTION": "MIRROR 14639120",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 4,
      "WHXX": 2,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14640102,
      "DESCRIPTION": "I-ECU",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14685008,
      "DESCRIPTION": "CHARGE AIR HOSE",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14638178,
      "DESCRIPTION": "CABLE HARNESS",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14561413,
      "DESCRIPTION": "SWITCH",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 3,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4110001905014,
      "DESCRIPTION": "RETAINING RING",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 8,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 60255743,
      "DESCRIPTION": "ALTERNATOR -QSB5.9 ENGINE",
      "PRODUCT CATEGORY": "YCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14640023,
      "DESCRIPTION": "ROLLER BEARING  7114-12490",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.416666666666666,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 2,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 5,
      "WH REFERENCE": "WHXX",
      "TREND": 0.8,
      "STD": 0.767947647788304,
      "MEAN": 0.384615384615384,
      "COV": 1.99666388424959,
      "SHELF LIFE_1": 4.800000000000008
    },
    {
      "PART NUMBER": 29050022301,
      "DESCRIPTION": "SHIM",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 3,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 29050022291,
      "DESCRIPTION": "SHIM",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 3,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 29050023111,
      "DESCRIPTION": "GASKET",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 21,
      "WHXX": 3,
      "WH REFERENCE": "WHXX",
      "TREND": 1,
      "STD": 0.375533808099405,
      "MEAN": 0.153846153846153,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": 4021000057,
      "DESCRIPTION": "BALL BEARING",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 17,
      "WHXX": 4,
      "WH REFERENCE": "WHXX",
      "TREND": 1,
      "STD": 0.75106761619881,
      "MEAN": 0.307692307692307,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 3.000000000000003
    },
    {
      "PART NUMBER": 4021000055,
      "DESCRIPTION": "BALL BEARING",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 14,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 1,
      "STD": 0.375533808099405,
      "MEAN": 0.153846153846153,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": 5220844736,
      "DESCRIPTION": "COVER",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 3,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 60,
      "WHXX": 10,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 60256244,
      "DESCRIPTION": "VACUUM METER",
      "PRODUCT CATEGORY": "YPG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 21225667,
      "DESCRIPTION": "CONTACT REEL",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 5,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 21004310,
      "DESCRIPTION": "DC CONTACTOR- MZJ-50A/006",
      "PRODUCT CATEGORY": "YCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 58748,
      "DESCRIPTION": "CAP 53036",
      "PRODUCT CATEGORY": "VGG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14656782,
      "DESCRIPTION": "SHAFT",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 2,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 60024962,
      "DESCRIPTION": "MULTI-WAY VALVE PROTECTIVE ELECTROMAGNET",
      "PRODUCT CATEGORY": "YCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 5,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 20,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14883778,
      "DESCRIPTION": "HOSE CLAMP 9313-10425 ",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 8,
      "WHXX": 7,
      "WH REFERENCE": "WHXX",
      "TREND": 1.5,
      "STD": 0.85485041426511,
      "MEAN": 0.307692307692307,
      "COV": 2.7782638463616,
      "SHELF LIFE_1": 3.000000000000003
    },
    {
      "PART NUMBER": 997459,
      "DESCRIPTION": "HEXAGON SCREW 978936",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 183,
      "WHXX": 20,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 29050022061,
      "DESCRIPTION": "FLANGE",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 12743620,
      "DESCRIPTION": "THERMOSTAT",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 60222777,
      "DESCRIPTION": "OIL SUCTION FILTER CORE",
      "PRODUCT CATEGORY": "YCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 11,
      "WHXX": 3,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 28360002211,
      "DESCRIPTION": "LOCK PLATE",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 29170042101,
      "DESCRIPTION": "WEAR PLATE",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 5,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 0,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": 29170042111,
      "DESCRIPTION": "WEAR PLATE",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 15065343,
      "DESCRIPTION": "STARTER",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 12802324,
      "DESCRIPTION": "SHOCK ABSORBER",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 1,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 29110000043,
      "DESCRIPTION": "GASKET 29110000042",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 19,
      "WHXX": 4,
      "WH REFERENCE": "WHXX",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": 4110001527,
      "DESCRIPTION": "ABSORBER",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 4,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 2,
      "STD": 1.10940039245045,
      "MEAN": 0.307692307692307,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 3.000000000000003
    },
    {
      "PART NUMBER": 60110025,
      "DESCRIPTION": "HEXAGON SCREW 991649 ",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 15.3333333333333,
      "MAINTANING STOCK QTY": 61,
      "AVAILABLE": 10,
      "SHELF LIFE": 0.6521739130434797,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 51,
      "APRD QTY": 51,
      "WH12": 0,
      "WHXX": 116,
      "WH REFERENCE": "WHXX",
      "TREND": 2,
      "STD": 34.6358344152559,
      "MEAN": 14.1538461538461,
      "COV": 2.44709699673003,
      "SHELF LIFE_1": 3.978260869565226
    },
    {
      "PART NUMBER": 12803013,
      "DESCRIPTION": "THROTTLE CABLE",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 2,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 7016331,
      "DESCRIPTION": "FILTER, OIL 800AJ, 1250AJP",
      "PRODUCT CATEGORY": "JLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4016000043,
      "DESCRIPTION": "SPLIT PIN",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 1,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 13,
      "WHXX": 1,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 21793270,
      "DESCRIPTION": "GASKET EC210B/G930 20559835",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 1,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 10,
      "WHXX": 5,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 17418494,
      "DESCRIPTION": "BATTERY MAIN SWITCH 3987034",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.75,
      "MAINTANING STOCK QTY": 3,
      "AVAILABLE": 2,
      "SHELF LIFE": 2.6666666666666665,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 3,
      "WHXX": 6,
      "WH REFERENCE": "WHXX",
      "TREND": 1.33,
      "STD": 0.630425171956115,
      "MEAN": 0.692307692307692,
      "COV": 0.910614137269944,
      "SHELF LIFE_1": 4
    },
    {
      "PART NUMBER": 997436,
      "DESCRIPTION": "HEXAGON SCREW 983252 983272 96713326",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 16,
      "WHXX": 53,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4110000556263,
      "DESCRIPTION": "ALTERNATOR",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": 12802160,
      "DESCRIPTION": "SOLENOID",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3,
      "WHXX": 1,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 17252660,
      "DESCRIPTION": "14780982 PRESSURE SENSOR",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 979064,
      "DESCRIPTION": "V-RIBBED BELT",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 2,
      "WHXX": 6,
      "WH REFERENCE": "WHXX",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": 4043000752,
      "DESCRIPTION": "SHAFT SLEEVE 29160008141",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 6,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": 17418113,
      "DESCRIPTION": "FILTER CARTRIDGE, HYDRAULIC  11037868",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 1,
      "SHELF LIFE": 6.000000000000024,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 6,
      "WHXX": 10,
      "WH REFERENCE": "",
      "TREND": 1,
      "STD": 0.375533808099405,
      "MEAN": 0.153846153846153,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": 4110000784,
      "DESCRIPTION": "AIR CLEANER",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 5222719933,
      "DESCRIPTION": "FAN HUB 210469Z03C",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 2,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 12,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 11705466,
      "DESCRIPTION": "GAS SPRING",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 4,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14538415,
      "DESCRIPTION": "PLATE",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14538416,
      "DESCRIPTION": "PLATE",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14708934,
      "DESCRIPTION": "14672980 MIRROR 14634766",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14534865,
      "DESCRIPTION": "SEAL",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 1,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14614042,
      "DESCRIPTION": "WASHER PUMP",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 3,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14691235,
      "DESCRIPTION": "SEAL",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 6,
      "WHXX": 2,
      "WH REFERENCE": "WHXX",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": "295349Z00A",
      "DESCRIPTION": "HEX. SOCKET SCREW",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 10,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14557503,
      "DESCRIPTION": "COVER",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 3,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4110000970215,
      "DESCRIPTION": "VALVE COVER GASKET",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4110000970108,
      "DESCRIPTION": "THERMOSTAT",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 1,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 6,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14689356,
      "DESCRIPTION": "BLADE 14538414",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 6670472,
      "DESCRIPTION": "NUT",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4130002911003,
      "DESCRIPTION": "TEMPERATURE SENSOR",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 66,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "270389Z00A",
      "DESCRIPTION": "O-RING",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 23,
      "WHXX": 24,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14725313,
      "DESCRIPTION": "SELECTOR UNIT 14659778 14708419",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 4,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 7002980,
      "DESCRIPTION": "PRESSURE SWITCH",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 7,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14880424,
      "DESCRIPTION": "CAP NUT",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 3,
      "WH REFERENCE": "WHXX",
      "TREND": 0,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": 14679691,
      "DESCRIPTION": "WINDSHIELD EC210D",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 5222802279,
      "DESCRIPTION": "V-RIBBED BELT",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 1.91666666666666,
      "MAINTANING STOCK QTY": 8,
      "AVAILABLE": 6,
      "SHELF LIFE": 3.1304347826087064,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 2,
      "APRD QTY": 2,
      "WH12": 0,
      "WHXX": 2,
      "WH REFERENCE": "WHXX",
      "TREND": 1.91,
      "STD": 5.79013307495095,
      "MEAN": 1.76923076923076,
      "COV": 3.2726839119288,
      "SHELF LIFE_1": 4.173913043478275
    },
    {
      "PART NUMBER": 14674063,
      "DESCRIPTION": "SEALING KIT",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 9,
      "WHXX": 4,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 7281063,
      "DESCRIPTION": "DOOR SEAL",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14371518,
      "DESCRIPTION": "HEADLAMP",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 4,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14595818,
      "DESCRIPTION": "GAS SPRING",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 1,
      "SHELF LIFE": 12.000000000000005,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 10,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 12.000000000000005
    },
    {
      "PART NUMBER": 14532310,
      "DESCRIPTION": "SEAL",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 8,
      "WH REFERENCE": "WHXX",
      "TREND": 0,
      "STD": 1.10940039245045,
      "MEAN": 0.307692307692307,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 3.000000000000003
    },
    {
      "PART NUMBER": 14647523,
      "DESCRIPTION": "GEAR",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14714851,
      "DESCRIPTION": "SWITCH 14588962",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 2,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 22967564,
      "DESCRIPTION": "MAIN BEARING 20850106",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 19,
      "WHXX": 23,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 997434,
      "DESCRIPTION": "HEX SCREW 983250",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 24,
      "WHXX": 21,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 997435,
      "DESCRIPTION": "HEXAGON SCREW 983251",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.666666666666666,
      "MAINTANING STOCK QTY": 3,
      "AVAILABLE": 3,
      "SHELF LIFE": 4.500000000000004,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 89,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 2.21880078490091,
      "MEAN": 0.615384615384615,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 4.500000000000004
    },
    {
      "PART NUMBER": 4110001007009,
      "DESCRIPTION": "SENSOR",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4110001015003,
      "DESCRIPTION": "CONTROL CABLE",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 11,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4110002483001,
      "DESCRIPTION": "STARTER MOTOR",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": 22446845,
      "DESCRIPTION": "MAIN SWITCH",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 5,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14691909,
      "DESCRIPTION": "FILTER ELEMENT",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 5,
      "WHXX": 6,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 16000089,
      "DESCRIPTION": "THERMOSTAT G930",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14594708,
      "DESCRIPTION": "CONTROL UNIT",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 22745695,
      "DESCRIPTION": "BRACKET",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 3,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 20913851,
      "DESCRIPTION": "ADJUSTING SCREW",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 20794115,
      "DESCRIPTION": "RETAINING RING",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 26,
      "WHXX": 7,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "282299Z00B",
      "DESCRIPTION": "FLANGE SCREW",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 4,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 6,
      "WHXX": 36,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14711980,
      "DESCRIPTION": "14750655 FILTER CARTRIDGE",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 60252396,
      "DESCRIPTION": "FUEL/WATER SEPARATOR ELEMENT ",
      "PRODUCT CATEGORY": "YCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 6,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 10,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "A222100000380",
      "DESCRIPTION": "OIL FILTER",
      "PRODUCT CATEGORY": "YCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 3,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 45,
      "WHXX": 8,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 13274788,
      "DESCRIPTION": "MARK PLATE FOR RELIEF VALVE GJB312C8R.1 ",
      "PRODUCT CATEGORY": "YPG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14715423,
      "DESCRIPTION": "BOTTOM ROLLER, ENDURANCE EC55",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14686445,
      "DESCRIPTION": "IDLER, ENDURANCE EC55",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 3,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 0,
      "STD": 0.375533808099405,
      "MEAN": 0.153846153846153,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": 29070013171,
      "DESCRIPTION": "SHIM",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 20,
      "WHXX": 94,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 29070013181,
      "DESCRIPTION": "SHIM",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 26,
      "WHXX": 73,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 3050900043,
      "DESCRIPTION": "PLANET GEAR SHAFT",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 9,
      "WHXX": 45,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 3050900042,
      "DESCRIPTION": "RETAINING RING",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 15,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 42,
      "WHXX": 111,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 3050900041,
      "DESCRIPTION": "PLANET GEAR",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 12,
      "WHXX": 14,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4090000003,
      "DESCRIPTION": "NEEDLE ROLLER",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 70,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 350,
      "WHXX": 1306,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "443419Z01B",
      "DESCRIPTION": "SLACK ADJUSTER",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 7319444,
      "DESCRIPTION": "FILTER ELEMENT (SPIN-ON TYPE)",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "AVERAGE (F2)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 1.41666666666666,
      "MAINTANING STOCK QTY": 6,
      "AVAILABLE": 19,
      "SHELF LIFE": 13.411764705882415,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 82,
      "WHXX": 5,
      "WH REFERENCE": "",
      "TREND": 0.82,
      "STD": 1.84321347039237,
      "MEAN": 1.3076923076923,
      "COV": 1.40951618324122,
      "SHELF LIFE_1": 13.411764705882415
    },
    {
      "PART NUMBER": 14702253,
      "DESCRIPTION": "HORN 14545502 14654752 ",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 1,
      "SHELF LIFE": 3.000000000000003,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 19,
      "WHXX": 4,
      "WH REFERENCE": "",
      "TREND": 1,
      "STD": 0.480384461415261,
      "MEAN": 0.307692307692307,
      "COV": 1.56124949959959,
      "SHELF LIFE_1": 3.000000000000003
    },
    {
      "PART NUMBER": "165289Z01A",
      "DESCRIPTION": "AIR FILTER-PRIMARY",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.583333333333333,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 2,
      "APRD QTY": 2,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 0.29,
      "STD": 1.39136531360295,
      "MEAN": 0.538461538461538,
      "COV": 2.58396415383405,
      "SHELF LIFE_1": 3.42857142857143
    },
    {
      "PART NUMBER": "165289Z00E",
      "DESCRIPTION": "AIR FILTER INSERT - CRONER 14T",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 0.5,
      "STD": 0.85485041426511,
      "MEAN": 0.307692307692307,
      "COV": 2.7782638463616,
      "SHELF LIFE_1": 3.000000000000003
    },
    {
      "PART NUMBER": "47500NA04D",
      "DESCRIPTION": "AIR DRIER FILTER KIT",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.75,
      "MAINTANING STOCK QTY": 3,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 3,
      "APRD QTY": 3,
      "WH12": 1,
      "WHXX": 4,
      "WH REFERENCE": "WHXX",
      "TREND": 0.22,
      "STD": 1.9315199276138,
      "MEAN": 0.692307692307692,
      "COV": 2.78997322877549,
      "SHELF LIFE_1": 4
    },
    {
      "PART NUMBER": "276949Z00B",
      "DESCRIPTION": "CAB FILTER",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.666666666666666,
      "MAINTANING STOCK QTY": 3,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 3,
      "APRD QTY": 3,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 0.5,
      "STD": 1.44559454541845,
      "MEAN": 0.615384615384615,
      "COV": 2.34909113630498,
      "SHELF LIFE_1": 4.500000000000004
    },
    {
      "PART NUMBER": "797409Z00C",
      "DESCRIPTION": "WINDSHIELD - CRONER",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 1,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 15171144,
      "DESCRIPTION": "FILLER CAP",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 5260111183,
      "DESCRIPTION": "HEX. SOCKET SCR 805199Z02A ",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.666666666666666,
      "MAINTANING STOCK QTY": 3,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 3,
      "APRD QTY": 0,
      "WH12": 53,
      "WHXX": 33,
      "WH REFERENCE": "WHXX",
      "TREND": 0,
      "STD": 2.21880078490091,
      "MEAN": 0.615384615384615,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 4.500000000000004
    },
    {
      "PART NUMBER": 5222842811,
      "DESCRIPTION": "5224247405 BRAKE CHAMBER 443109Z02A",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 60110035,
      "DESCRIPTION": "HEXAGON SCREW 983373 ",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 2,
      "MAINTANING STOCK QTY": 8,
      "AVAILABLE": 8,
      "SHELF LIFE": 4,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 27,
      "WHXX": 19,
      "WH REFERENCE": "",
      "TREND": 0.83,
      "STD": 3.78255103173669,
      "MEAN": 1.84615384615384,
      "COV": 2.04888180885737,
      "SHELF LIFE_1": 4
    },
    {
      "PART NUMBER": 11443993,
      "DESCRIPTION": "53032790 CONTROL UNIT 11381209 SN 280199-  11388082 ",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 29070020651,
      "DESCRIPTION": "BRAKE DISC",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 5,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 0,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": 4013000064,
      "DESCRIPTION": "LOCK NUT",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 1,
      "MAINTANING STOCK QTY": 4,
      "AVAILABLE": 4,
      "SHELF LIFE": 4,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 10,
      "WH REFERENCE": "",
      "TREND": 0.67,
      "STD": 2.39657875806111,
      "MEAN": 0.923076923076923,
      "COV": 2.5962936545662,
      "SHELF LIFE_1": 4
    },
    {
      "PART NUMBER": 29090001031,
      "DESCRIPTION": "SCREW",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.666666666666666,
      "MAINTANING STOCK QTY": 3,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 3,
      "APRD QTY": 0,
      "WH12": 16,
      "WHXX": 5,
      "WH REFERENCE": "WHXX",
      "TREND": 0,
      "STD": 2.21880078490091,
      "MEAN": 0.615384615384615,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 4.500000000000004
    },
    {
      "PART NUMBER": 6680161,
      "DESCRIPTION": "RUBBER TRACK",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": 17438619,
      "DESCRIPTION": "FILTER ELEMENT 11445025 ",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 146,
      "WHXX": 31,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14881383,
      "DESCRIPTION": "RUBBER HOSE",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 8,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 997482,
      "DESCRIPTION": "HEX. SCREW 983743",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 10.6666666666666,
      "MAINTANING STOCK QTY": 43,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 43,
      "APRD QTY": 43,
      "WH12": 16,
      "WHXX": 0,
      "WH REFERENCE": "PARTIAL WH12",
      "TREND": 2,
      "STD": 27.1626157118141,
      "MEAN": 9.84615384615384,
      "COV": 2.75870315823112,
      "SHELF LIFE_1": 4.031250000000025
    },
    {
      "PART NUMBER": 14558017,
      "DESCRIPTION": "COVER",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 4,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14640024,
      "DESCRIPTION": "ROLLER BEARING",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.5,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 2,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 6,
      "WH REFERENCE": "WHXX",
      "TREND": 1,
      "STD": 0.776250025806184,
      "MEAN": 0.461538461538461,
      "COV": 1.6818750559134,
      "SHELF LIFE_1": 4
    },
    {
      "PART NUMBER": 5223058602,
      "DESCRIPTION": "5224122470 BRAKE CHAMBER",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 1,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 1,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14709151,
      "DESCRIPTION": "WIRING HARNESS",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 60193266,
      "DESCRIPTION": "HYDRAULIC RETURN FILTER * SCC550A / SCC750A",
      "PRODUCT CATEGORY": "YPG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 2,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 5,
      "WHXX": 4,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4110001531001,
      "DESCRIPTION": "SPIDER  4110000485001 ",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 17,
      "WHXX": 1,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 84092304,
      "DESCRIPTION": "WIPER MOTOR",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 1,
      "WHXX": 1,
      "WH REFERENCE": "WHXX",
      "TREND": 2,
      "STD": 0.375533808099405,
      "MEAN": 0.153846153846153,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": 5229625,
      "DESCRIPTION": "SEALING RING",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 15185363,
      "DESCRIPTION": "O-RING",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 5,
      "WHXX": 5,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 5222931497,
      "DESCRIPTION": "CAM ROLLER",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": 14709332,
      "DESCRIPTION": "SEAL 14559059 ",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 19,
      "WHXX": 7,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 29090014451,
      "DESCRIPTION": "REAR AXLE LG936L",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 5223058600,
      "DESCRIPTION": "BRAKE CHAMBER 5222842739 413209Z00E",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 1,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 2,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 17446531,
      "DESCRIPTION": "PLANETARY GEAR 14558054",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.25,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 6,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 2,
      "STD": 0.832050294337843,
      "MEAN": 0.23076923076923,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 4
    },
    {
      "PART NUMBER": 6644965,
      "DESCRIPTION": "BALL JOINT",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14722573,
      "DESCRIPTION": "IDLER",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 60113080,
      "DESCRIPTION": "984734 FLANGE SCREW 13946173 ",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 6,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 29,
      "WHXX": 6,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 997446,
      "DESCRIPTION": "HEXAGON SCREW 978932",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 1.66666666666666,
      "MAINTANING STOCK QTY": 7,
      "AVAILABLE": 61,
      "SHELF LIFE": 36.60000000000014,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 41,
      "WHXX": 74,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 5.54700196225229,
      "MEAN": 1.53846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 36.60000000000014
    },
    {
      "PART NUMBER": 997494,
      "DESCRIPTION": "SIX POINT SOCKET SCREW 969407",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 20,
      "WHXX": 16,
      "WH REFERENCE": "WHXX",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": 1101312,
      "DESCRIPTION": "ELEMENT, MAIN",
      "PRODUCT CATEGORY": "JLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 1101313,
      "DESCRIPTION": "ELEMENT, SAFETY",
      "PRODUCT CATEGORY": "JLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 5222844507,
      "DESCRIPTION": "SPRING WASHER",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 151,
      "WHXX": 17,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 5222844485,
      "DESCRIPTION": "PLATE",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 87,
      "WHXX": 4,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4110000991019,
      "DESCRIPTION": "OIL COOLER",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 12,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4110000054215,
      "DESCRIPTION": "EXHAUST VALVE 4110000909080",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 13,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4110000054096,
      "DESCRIPTION": "INLET VALVE",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 13,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 5222523792,
      "DESCRIPTION": "CHARGE AIR HOSE",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 7,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 82355056,
      "DESCRIPTION": "ELECTRIC MOTOR",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.5,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 2,
      "APRD QTY": 2,
      "WH12": 8,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 2,
      "STD": 1.66410058867568,
      "MEAN": 0.461538461538461,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 4
    },
    {
      "PART NUMBER": "322739Z00D ",
      "DESCRIPTION": "BEARING",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 16632962,
      "DESCRIPTION": "PRIMARY FILTER",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 8,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 16632963,
      "DESCRIPTION": "SAFETY FILTER",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 6553411,
      "DESCRIPTION": "PRESS FIT BUSHING 5",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 60263488,
      "DESCRIPTION": "OIL FILTER",
      "PRODUCT CATEGORY": "YTG",
      "ITEM MOVEMENT BRANCH": "AVERAGE (F2)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 2.16666666666666,
      "MAINTANING STOCK QTY": 9,
      "AVAILABLE": 6,
      "SHELF LIFE": 2.769230769230778,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 3,
      "APRD QTY": 3,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 0.62,
      "STD": 2.82842712474619,
      "MEAN": 2,
      "COV": 1.41421356237309,
      "SHELF LIFE_1": 4.153846153846167
    },
    {
      "PART NUMBER": 6715865,
      "DESCRIPTION": "BUSHING",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 0,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": "17C640",
      "DESCRIPTION": "BOLT-(MOQ 5PCS)",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 4,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 0,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": "97D6",
      "DESCRIPTION": "NUT-10",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 9,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 0,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": 5222802271,
      "DESCRIPTION": "V-RIBBED BELT",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 1.83333333333333,
      "MAINTANING STOCK QTY": 7,
      "AVAILABLE": 6,
      "SHELF LIFE": 3.2727272727272787,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 2,
      "WHXX": 16,
      "WH REFERENCE": "WHXX",
      "TREND": 1.91,
      "STD": 5.51338697149364,
      "MEAN": 1.69230769230769,
      "COV": 3.25791048315533,
      "SHELF LIFE_1": 3.8181818181818254
    },
    {
      "PART NUMBER": 5222923707,
      "DESCRIPTION": "IDLER PULLEY",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 4,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 12,
      "WHXX": 5,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 5222845321,
      "DESCRIPTION": "RUBBER SPRING 552419Z00C ",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 20,
      "WHXX": 20,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 12815454,
      "DESCRIPTION": "CONTROL CABLE",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 3,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 5223236938,
      "DESCRIPTION": "SEALING RING 432359Z00A",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3,
      "WHXX": 8,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 16668474,
      "DESCRIPTION": "15711010 FUEL FILTER",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 17201956,
      "DESCRIPTION": "FILTER CARTRIDGE",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 5,
      "WHXX": 13,
      "WH REFERENCE": "WHXX",
      "TREND": 1,
      "STD": 0.375533808099405,
      "MEAN": 0.153846153846153,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": 17324107,
      "DESCRIPTION": "15714574 FILTER CARTRIDGE 43977461 ",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 2,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 0,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": 6519178,
      "DESCRIPTION": "BUMPER",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 16,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 0,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": "A210609000067",
      "DESCRIPTION": "O-RING-53",
      "PRODUCT CATEGORY": "YCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": 10182105,
      "DESCRIPTION": "RETAINER RING",
      "PRODUCT CATEGORY": "YCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": "B230101001155",
      "DESCRIPTION": "SEAL RING",
      "PRODUCT CATEGORY": "YCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "B230101001370",
      "DESCRIPTION": "GUIDE RING",
      "PRODUCT CATEGORY": "YCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "B230101001158",
      "DESCRIPTION": "GUIDE RING",
      "PRODUCT CATEGORY": "YCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": 10250733,
      "DESCRIPTION": "BACK-UP RING",
      "PRODUCT CATEGORY": "YCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 44,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": 5222827993,
      "DESCRIPTION": "SENSOR 2269100Z2C",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 1,
      "SHELF LIFE": 12.000000000000005,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 6,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 12.000000000000005
    },
    {
      "PART NUMBER": 4130001873,
      "DESCRIPTION": "FUSE AND RELAY UNIT",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14585975,
      "DESCRIPTION": "FAN SHROUD",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 1,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14725906,
      "DESCRIPTION": "GAUGE 14532362 1141-01720",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 1.25,
      "MAINTANING STOCK QTY": 5,
      "AVAILABLE": 2,
      "SHELF LIFE": 1.6,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 3,
      "APRD QTY": 3,
      "WH12": 22,
      "WHXX": 23,
      "WH REFERENCE": "WHXX",
      "TREND": 1.2,
      "STD": 1.72463299700515,
      "MEAN": 1.15384615384615,
      "COV": 1.49468193073779,
      "SHELF LIFE_1": 4
    },
    {
      "PART NUMBER": 15088068,
      "DESCRIPTION": "BRAKE KIT L120F",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 6680695,
      "DESCRIPTION": "SEAL",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 2,
      "STD": 1.10940039245045,
      "MEAN": 0.307692307692307,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 3.000000000000003
    },
    {
      "PART NUMBER": 16681896,
      "DESCRIPTION": "RADIATOR GRILLE",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 5223236940,
      "DESCRIPTION": "SEALING RING 432359Z00B",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 1,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 3,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4190002281,
      "DESCRIPTION": "DOOR LOCK",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 22923707,
      "DESCRIPTION": "IDLER PULLEY 8086970",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 10,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 20992763,
      "DESCRIPTION": "AIR HOSE",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 11,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 880500,
      "DESCRIPTION": "ELECTRICAL CONTROL HARNESS ASSEMBLY",
      "PRODUCT CATEGORY": "PHG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4030000707,
      "DESCRIPTION": "O-RING",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 24,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4110000076050,
      "DESCRIPTION": "SEALING RING",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 4,
      "WHXX": 9,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4030000611,
      "DESCRIPTION": "O-RING",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 5,
      "WHXX": 17,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 22221048,
      "DESCRIPTION": "BUSHING",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.666666666666666,
      "MAINTANING STOCK QTY": 3,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 3,
      "APRD QTY": 3,
      "WH12": 10,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 2,
      "STD": 1.70970082853022,
      "MEAN": 0.615384615384615,
      "COV": 2.7782638463616,
      "SHELF LIFE_1": 4.500000000000004
    },
    {
      "PART NUMBER": 29070007661,
      "DESCRIPTION": "PLANET CARRIER",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 3050900037,
      "DESCRIPTION": "GEAR RING 29070015341",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 3,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 3050900040,
      "DESCRIPTION": "SUN GEAR",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 2,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 997500,
      "DESCRIPTION": "SCREW ",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 1,
      "MAINTANING STOCK QTY": 4,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 4,
      "APRD QTY": 4,
      "WH12": 0,
      "WHXX": 27,
      "WH REFERENCE": "WHXX",
      "TREND": 2,
      "STD": 3.32820117735137,
      "MEAN": 0.923076923076923,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 4
    },
    {
      "PART NUMBER": 4110002852014,
      "DESCRIPTION": "AIR FILTER INSERT",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 1,
      "SHELF LIFE": 3.000000000000003,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 3,
      "WH REFERENCE": "",
      "TREND": 1.5,
      "STD": 0.85485041426511,
      "MEAN": 0.307692307692307,
      "COV": 2.7782638463616,
      "SHELF LIFE_1": 3.000000000000003
    },
    {
      "PART NUMBER": 5223236891,
      "DESCRIPTION": "SEALING RING 400359Z00B",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.25,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 5,
      "SHELF LIFE": 20,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 129,
      "WHXX": 14,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.438529009653514,
      "MEAN": 0.23076923076923,
      "COV": 1.90029237516522,
      "SHELF LIFE_1": 20
    },
    {
      "PART NUMBER": "A210609000354",
      "DESCRIPTION": "O-RING (175A)",
      "PRODUCT CATEGORY": "YPG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.916666666666666,
      "MAINTANING STOCK QTY": 4,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 4,
      "APRD QTY": 4,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 2,
      "STD": 2.7642405179408,
      "MEAN": 0.846153846153846,
      "COV": 3.26682970302095,
      "SHELF LIFE_1": 4.363636363636367
    },
    {
      "PART NUMBER": 612600114993,
      "DESCRIPTION": "TP612600114993 AIR FILTER ASSY",
      "PRODUCT CATEGORY": "SDG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 960698,
      "DESCRIPTION": "FILTER, OIL ENG",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.25,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 59,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 2,
      "STD": 0.599144689515278,
      "MEAN": 0.23076923076923,
      "COV": 2.5962936545662,
      "SHELF LIFE_1": 4
    },
    {
      "PART NUMBER": 6911907,
      "DESCRIPTION": "FUEL FILTER ELEMENT KIT 7406650",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 2,
      "STD": 0.75106761619881,
      "MEAN": 0.307692307692307,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 3.000000000000003
    },
    {
      "PART NUMBER": 7251998,
      "DESCRIPTION": "FILTER, FUEL",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 12,
      "WHXX": 1,
      "WH REFERENCE": "WHXX",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": 14702254,
      "DESCRIPTION": "HORN 14654753 14545503",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 2,
      "SHELF LIFE": 6.000000000000006,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 19,
      "WHXX": 1,
      "WH REFERENCE": "",
      "TREND": 1,
      "STD": 0.480384461415261,
      "MEAN": 0.307692307692307,
      "COV": 1.56124949959959,
      "SHELF LIFE_1": 6.000000000000006
    },
    {
      "PART NUMBER": 4120007012,
      "DESCRIPTION": "SPRING BRAKE CHAMBER",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": 997858,
      "DESCRIPTION": "HEX SOCKET SCREW 984079",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 5,
      "SHELF LIFE": 60.00000000000002,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 315,
      "WHXX": 27,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 60.00000000000002
    },
    {
      "PART NUMBER": 23140886,
      "DESCRIPTION": "CONTROL UNIT 21855896",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14701419,
      "DESCRIPTION": "SWITCH",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 3,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "308569Z01B",
      "DESCRIPTION": "308009Z00C HOSE ASSEMBLY",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 16,
      "WHXX": 21,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14523095,
      "DESCRIPTION": "BUSHING",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 1,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 9,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 997410,
      "DESCRIPTION": "HEXAGON NUT 96703657 979021 955785",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 224,
      "WHXX": 29,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 997868,
      "DESCRIPTION": "HEX. SOCKET SCREW 992542",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 88,
      "WHXX": 12,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14722625,
      "DESCRIPTION": "SOLENOID COIL",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 2,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14577460,
      "DESCRIPTION": "RELAY",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 23,
      "WHXX": 5,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14708320,
      "DESCRIPTION": "TOP ROLLER",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 6,
      "WH REFERENCE": "WHXX",
      "TREND": 1,
      "STD": 0.375533808099405,
      "MEAN": 0.153846153846153,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": 14709480,
      "DESCRIPTION": "IDLER 14656780",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 6,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14713823,
      "DESCRIPTION": "SEAL",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3,
      "WHXX": 2,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 22068223,
      "DESCRIPTION": "WIRING HARNESS",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 1,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 997513,
      "DESCRIPTION": "HEX. SOCKET SCREW 984084 ",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 40,
      "WHXX": 31,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 17501886,
      "DESCRIPTION": "SOLENOID COIL",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 5,
      "WHXX": 3,
      "WH REFERENCE": "WHXX",
      "TREND": 2,
      "STD": 0.375533808099405,
      "MEAN": 0.153846153846153,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": 6716621,
      "DESCRIPTION": "FUSE COVER",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 6701535,
      "DESCRIPTION": "CENTERING SPRING",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 0,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": 996777,
      "DESCRIPTION": "FLANGE SCREW 20412324 ",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 15,
      "WHXX": 6,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 5223058616,
      "DESCRIPTION": "BRAKE CHAMBER 5222842686",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 2,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 14,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 60034238,
      "DESCRIPTION": "BELT",
      "PRODUCT CATEGORY": "YCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4130002912,
      "DESCRIPTION": "PANEL 4190002760 ",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.25,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 6,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 2,
      "STD": 0.599144689515278,
      "MEAN": 0.23076923076923,
      "COV": 2.5962936545662,
      "SHELF LIFE_1": 4
    },
    {
      "PART NUMBER": 5223236939,
      "DESCRIPTION": "SEALING RING 430519Z00D",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 1,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 1,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "164109Z00D",
      "DESCRIPTION": "BOWL",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 5222847639,
      "DESCRIPTION": "SPRING LEAF (LEAF 3)",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 5222847640,
      "DESCRIPTION": "SPRING LEAF (LEAF 4)",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 12782905,
      "DESCRIPTION": "COVER",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 60113303,
      "DESCRIPTION": "PLAIN WASHER 930853 906837",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.666666666666666,
      "MAINTANING STOCK QTY": 3,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 3,
      "APRD QTY": 3,
      "WH12": 95,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 2,
      "STD": 2.21880078490091,
      "MEAN": 0.615384615384615,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 4.500000000000004
    },
    {
      "PART NUMBER": 4110002791,
      "DESCRIPTION": "SEALING RING",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 3,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14700703,
      "DESCRIPTION": "SEALING KIT",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.25,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 1,
      "SHELF LIFE": 4,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 5,
      "WHXX": 1,
      "WH REFERENCE": "",
      "TREND": 0.67,
      "STD": 0.599144689515278,
      "MEAN": 0.23076923076923,
      "COV": 2.5962936545662,
      "SHELF LIFE_1": 4
    },
    {
      "PART NUMBER": 997963,
      "DESCRIPTION": "HEXAGON SCREW 991639 ",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 2,
      "MAINTANING STOCK QTY": 8,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 8,
      "APRD QTY": 8,
      "WH12": 0,
      "WHXX": 58,
      "WH REFERENCE": "WHXX",
      "TREND": 2,
      "STD": 6.65640235470275,
      "MEAN": 1.84615384615384,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 4
    },
    {
      "PART NUMBER": 4110001525036,
      "DESCRIPTION": "GASKET",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 2,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14619031,
      "DESCRIPTION": "CHAIN",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 2,
      "STD": 0.75106761619881,
      "MEAN": 0.307692307692307,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 3.000000000000003
    },
    {
      "PART NUMBER": 997990,
      "DESCRIPTION": "SCREW 992968",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 8.66666666666666,
      "MAINTANING STOCK QTY": 35,
      "AVAILABLE": 35,
      "SHELF LIFE": 4.038461538461541,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 126,
      "WHXX": 146,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 23.4946802489414,
      "MEAN": 8,
      "COV": 2.93683503111768,
      "SHELF LIFE_1": 4.038461538461541
    },
    {
      "PART NUMBER": 14663554,
      "DESCRIPTION": "TOGGLE SWITCH",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4110001929,
      "DESCRIPTION": "CAP",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 11204727,
      "DESCRIPTION": "REAR WINDOW",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14530462,
      "DESCRIPTION": "NUT",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 3,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 60110287,
      "DESCRIPTION": "PLAIN WASHER 992047",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 4.33333333333333,
      "MAINTANING STOCK QTY": 17,
      "AVAILABLE": 9,
      "SHELF LIFE": 2.0769230769230784,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 8,
      "APRD QTY": 8,
      "WH12": 525,
      "WHXX": 72,
      "WH REFERENCE": "WHXX",
      "TREND": 1.85,
      "STD": 13.2664991614216,
      "MEAN": 4,
      "COV": 3.3166247903554,
      "SHELF LIFE_1": 3.9230769230769256
    },
    {
      "PART NUMBER": "259459Z00C",
      "DESCRIPTION": "CONTROL UNIT",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "A221500000266",
      "DESCRIPTION": "BALANCE BEAM BEARING",
      "PRODUCT CATEGORY": "YCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 4,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 22988765,
      "DESCRIPTION": "23044511 FUEL FILTER 21879886 ",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 1.5,
      "MAINTANING STOCK QTY": 6,
      "AVAILABLE": 17,
      "SHELF LIFE": 11.333333333333334,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 8,
      "WHXX": 6,
      "WH REFERENCE": "",
      "TREND": 0.11,
      "STD": 2.63117405792108,
      "MEAN": 1.38461538461538,
      "COV": 1.90029237516522,
      "SHELF LIFE_1": 11.333333333333334
    },
    {
      "PART NUMBER": 4110002902,
      "DESCRIPTION": "FAN",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.25,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 0,
      "STD": 0.438529009653514,
      "MEAN": 0.23076923076923,
      "COV": 1.90029237516522,
      "SHELF LIFE_1": 4
    },
    {
      "PART NUMBER": 29290037461,
      "DESCRIPTION": "GLASS",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "963019Z01D",
      "DESCRIPTION": "MIRROR KIT",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 60110300,
      "DESCRIPTION": "WASHER 992057",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 8.66666666666666,
      "MAINTANING STOCK QTY": 35,
      "AVAILABLE": 27,
      "SHELF LIFE": 3.1153846153846176,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 8,
      "APRD QTY": 8,
      "WH12": 431,
      "WHXX": 231,
      "WH REFERENCE": "WHXX",
      "TREND": 2,
      "STD": 23.4946802489414,
      "MEAN": 8,
      "COV": 2.93683503111768,
      "SHELF LIFE_1": 4.038461538461541
    },
    {
      "PART NUMBER": 14681250,
      "DESCRIPTION": "14797204 IDLER ",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 2,
      "STD": 1.10940039245045,
      "MEAN": 0.307692307692307,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 3.000000000000003
    },
    {
      "PART NUMBER": "282299Z05B",
      "DESCRIPTION": "FLANGE SCREW",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 23,
      "WHXX": 35,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 5227334,
      "DESCRIPTION": "ROLLER BEARING",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.5,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 2,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 3,
      "WH REFERENCE": "WHXX",
      "TREND": 0.67,
      "STD": 0.877058019307029,
      "MEAN": 0.461538461538461,
      "COV": 1.90029237516522,
      "SHELF LIFE_1": 4
    },
    {
      "PART NUMBER": 14560265,
      "DESCRIPTION": "ROLLER BEARING",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.5,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 2,
      "SHELF LIFE": 4,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 9,
      "WH REFERENCE": "",
      "TREND": 0.67,
      "STD": 0.877058019307029,
      "MEAN": 0.461538461538461,
      "COV": 1.90029237516522,
      "SHELF LIFE_1": 4
    },
    {
      "PART NUMBER": 14560271,
      "DESCRIPTION": "PISTON",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 5,
      "WH REFERENCE": "WHXX",
      "TREND": 1,
      "STD": 0.375533808099405,
      "MEAN": 0.153846153846153,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": 17444453,
      "DESCRIPTION": "PIN",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.5,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 2,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 19,
      "WH REFERENCE": "WHXX",
      "TREND": 1,
      "STD": 1.12660142429821,
      "MEAN": 0.461538461538461,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 4
    },
    {
      "PART NUMBER": 17230686,
      "DESCRIPTION": "PLANET CARRIER",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 60099313,
      "DESCRIPTION": "WIDE FILTER NET ASSEMBLY",
      "PRODUCT CATEGORY": "YCG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.5,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 2,
      "SHELF LIFE": 4,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 7,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 1.66410058867568,
      "MEAN": 0.461538461538461,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 4
    },
    {
      "PART NUMBER": "8230-25680",
      "DESCRIPTION": "PLUG",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "4120006349Z",
      "DESCRIPTION": "AIR CYLINDER",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3,
      "WHXX": 1,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4190002589,
      "DESCRIPTION": "WIPER BLADE",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 5,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4130002221,
      "DESCRIPTION": "FUEL LEVEL SENSOR",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14716435,
      "DESCRIPTION": "SHIM 1173-00180",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 50,
      "WHXX": 9,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 22943668,
      "DESCRIPTION": "SWITCH 21967897          ",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14719601,
      "DESCRIPTION": "BREATHER 1040-20311",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.25,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 1,
      "SHELF LIFE": 4,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 7,
      "WHXX": 6,
      "WH REFERENCE": "",
      "TREND": 0.67,
      "STD": 0.599144689515278,
      "MEAN": 0.23076923076923,
      "COV": 2.5962936545662,
      "SHELF LIFE_1": 4
    },
    {
      "PART NUMBER": 7289106,
      "DESCRIPTION": "OIL FILTER",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 7289231,
      "DESCRIPTION": "FUEL PREFILTER KIT",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 1,
      "SHELF LIFE": 6.000000000000024,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": "23Y-62B-00000P020",
      "DESCRIPTION": "LIFT CYLINDER, REPAIR KIT",
      "PRODUCT CATEGORY": "SDG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 4,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": 5220374662,
      "DESCRIPTION": "RELAY",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 13,
      "WHXX": 2,
      "WH REFERENCE": "WHXX",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": 29070021581,
      "DESCRIPTION": "BRAKE DISC",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 0,
      "STD": 0.375533808099405,
      "MEAN": 0.153846153846153,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": 60110002,
      "DESCRIPTION": "HEXAGON SCREW 990520",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 143,
      "WHXX": 22,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 7289295,
      "DESCRIPTION": "INNER AIR FILTER",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 1,
      "SHELF LIFE": 6.000000000000024,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": 7290371,
      "DESCRIPTION": "HYDRAULIC RETURN FILTER",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 1,
      "SHELF LIFE": 6.000000000000024,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 4,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": 7290377,
      "DESCRIPTION": "HYDRAULIC RETURN FILTER",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 1,
      "SHELF LIFE": 6.000000000000024,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 5,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": 7291588,
      "DESCRIPTION": "STABILIZER CYLINDER REPAIR KIT",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 1,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 7291602,
      "DESCRIPTION": "BOOM CYLINDER REPAIR KIT",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 60113296,
      "DESCRIPTION": "WASHER 13940090",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 169,
      "WHXX": 19,
      "WH REFERENCE": "WHXX",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": 4120001739090,
      "DESCRIPTION": "PIN",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 9,
      "WHXX": 10,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 96702386,
      "DESCRIPTION": "HEXAGON SCREW",
      "PRODUCT CATEGORY": "VCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 6,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 2,
      "STD": 1.10940039245045,
      "MEAN": 0.307692307692307,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 3.000000000000003
    },
    {
      "PART NUMBER": 4110000970022,
      "DESCRIPTION": "V-BELT 4110000970219 ",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 8,
      "WHXX": 10,
      "WH REFERENCE": "WHXX",
      "TREND": 1,
      "STD": 0.375533808099405,
      "MEAN": 0.153846153846153,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": 7332632,
      "DESCRIPTION": "THERMOSTAT ASSY",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14729840,
      "DESCRIPTION": "SEAL 14601683 7117-30120 14528713",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.416666666666666,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 2,
      "SHELF LIFE": 4.800000000000008,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 4,
      "WHXX": 9,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.65044363558799,
      "MEAN": 0.384615384615384,
      "COV": 1.69115345252877,
      "SHELF LIFE_1": 4.800000000000008
    },
    {
      "PART NUMBER": 12829641,
      "DESCRIPTION": "WIRING HARNESS",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 1,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "ADBLUE20L",
      "DESCRIPTION": "ADBLUE - UREA SOLUTION",
      "PRODUCT CATEGORY": "ZZZ",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 60110301,
      "DESCRIPTION": "WASHER 992049 ",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 16,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 0,
      "STD": 1.10940039245045,
      "MEAN": 0.307692307692307,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 3.000000000000003
    },
    {
      "PART NUMBER": 29290039031,
      "DESCRIPTION": "GLASS",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 23260129,
      "DESCRIPTION": "FILTER",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 1,
      "SHELF LIFE": 6.000000000000024,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": 4110002816,
      "DESCRIPTION": "WHEEL RIM",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 0,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": 60193267,
      "DESCRIPTION": "ELEMENT GP OIL RETURN FILTER",
      "PRODUCT CATEGORY": "YPG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 4,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4120007867004,
      "DESCRIPTION": "SEALING KIT,TILT 3713CH",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 2,
      "MAINTANING STOCK QTY": 8,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 8,
      "APRD QTY": 8,
      "WH12": 0,
      "WHXX": 2,
      "WH REFERENCE": "PARTIAL WHXX",
      "TREND": 2,
      "STD": 6.65640235470275,
      "MEAN": 1.84615384615384,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 4
    },
    {
      "PART NUMBER": 4120006628004,
      "DESCRIPTION": "SEAL KIT, LIFT  3713CH",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.666666666666666,
      "MAINTANING STOCK QTY": 3,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 3,
      "APRD QTY": 3,
      "WH12": 8,
      "WHXX": 3,
      "WH REFERENCE": "WHXX",
      "TREND": 2,
      "STD": 2.21880078490091,
      "MEAN": 0.615384615384615,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 4.500000000000004
    },
    {
      "PART NUMBER": 12829642,
      "DESCRIPTION": "WIRING HARNESS",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 1,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14883743,
      "DESCRIPTION": "SET SCREW",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 98,
      "WHXX": 4,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 16232085,
      "DESCRIPTION": "CONDENSER",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.25,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 0,
      "WHXX": 2,
      "WH REFERENCE": "WHXX",
      "TREND": 2,
      "STD": 0.438529009653514,
      "MEAN": 0.23076923076923,
      "COV": 1.90029237516522,
      "SHELF LIFE_1": 4
    },
    {
      "PART NUMBER": 60110385,
      "DESCRIPTION": "PLAIN WASHER 14522862  1012-00160",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 36,
      "WHXX": 12,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 12824620,
      "DESCRIPTION": "CONTROL",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 11143309,
      "DESCRIPTION": "SEALING RING",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "FS36230",
      "DESCRIPTION": "FUEL WATER SEPARATOR",
      "PRODUCT CATEGORY": "CUF",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 1,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4120006027,
      "DESCRIPTION": "WATER SEPARATOR",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 2,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 18,
      "WHXX": 10,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14689737,
      "DESCRIPTION": "AIR CONDITIONING UNIT 14670627 14532728",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 1,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "01643-32060",
      "DESCRIPTION": "WASHER-01643-51845",
      "PRODUCT CATEGORY": "SDG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 9.33333333333333,
      "MAINTANING STOCK QTY": 37,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 37,
      "APRD QTY": 37,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 2,
      "STD": 26.6256094188109,
      "MEAN": 8.61538461538461,
      "COV": 3.09047252182627,
      "SHELF LIFE_1": 3.9642857142857157
    },
    {
      "PART NUMBER": "308569Z01C",
      "DESCRIPTION": "HOSE ASSEMBLY",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 4,
      "WHXX": 4,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 16052009,
      "DESCRIPTION": "CONTROL CABLE",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 6,
      "WH REFERENCE": "WHXX",
      "TREND": 1,
      "STD": 0.375533808099405,
      "MEAN": 0.153846153846153,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": 12809850,
      "DESCRIPTION": "SHOCK ABSORBER 13304787",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 3.33333333333333,
      "MAINTANING STOCK QTY": 13,
      "AVAILABLE": 4,
      "SHELF LIFE": 1.2000000000000013,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 9,
      "APRD QTY": 9,
      "WH12": 25,
      "WHXX": 77,
      "WH REFERENCE": "WHXX",
      "TREND": 1.2,
      "STD": 3.70723478398519,
      "MEAN": 3.07692307692307,
      "COV": 1.20485130479518,
      "SHELF LIFE_1": 3.900000000000004
    },
    {
      "PART NUMBER": 23260134,
      "DESCRIPTION": "23690622 AIR DRIER FILTER KIT 22223804 ",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 4,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4043001505,
      "DESCRIPTION": "WELD PIN",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 9,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 0,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": 4043001508,
      "DESCRIPTION": "BUSHING",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 7,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 0,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": 14710741,
      "DESCRIPTION": "WIRING HARNESS 14687616",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 15,
      "WHXX": 5,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4190000412,
      "DESCRIPTION": "RIM",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 35,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 20515171,
      "DESCRIPTION": "PLUG",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 1,
      "SHELF LIFE": 12.000000000000005,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 6,
      "WHXX": 5,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 12.000000000000005
    },
    {
      "PART NUMBER": 995177,
      "DESCRIPTION": "HEX. SOCKET SCREW",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.666666666666666,
      "MAINTANING STOCK QTY": 3,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 3,
      "APRD QTY": 3,
      "WH12": 24,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 2,
      "STD": 2.21880078490091,
      "MEAN": 0.615384615384615,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 4.500000000000004
    },
    {
      "PART NUMBER": 14558097,
      "DESCRIPTION": "SCREW",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 7,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14558071,
      "DESCRIPTION": "FLANGE",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "8320-04230",
      "DESCRIPTION": "SCREW",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 11,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 60125019,
      "DESCRIPTION": "DIESEL COARSE FILTER CORE",
      "PRODUCT CATEGORY": "YCG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.416666666666666,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 1,
      "SHELF LIFE": 2.400000000000004,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 0.4,
      "STD": 0.767947647788304,
      "MEAN": 0.384615384615384,
      "COV": 1.99666388424959,
      "SHELF LIFE_1": 4.800000000000008
    },
    {
      "PART NUMBER": 5222845443,
      "DESCRIPTION": "5223994830 DISC",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14530832,
      "DESCRIPTION": "SEAL",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14716434,
      "DESCRIPTION": "SHIM 1173-00190",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 33,
      "WHXX": 27,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 46696397,
      "DESCRIPTION": "PRIMARY AIR FILTER",
      "PRODUCT CATEGORY": "DOP",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 1.16666666666666,
      "MAINTANING STOCK QTY": 5,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 5,
      "APRD QTY": 5,
      "WH12": 10,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 0.71,
      "STD": 2.62873665669583,
      "MEAN": 1.07692307692307,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 4.28571428571431
    },
    {
      "PART NUMBER": 46692659,
      "DESCRIPTION": "BALLAST",
      "PRODUCT CATEGORY": "DOP",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4110003706,
      "DESCRIPTION": "WATER SEPARATOR  4110001593",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 60113094,
      "DESCRIPTION": "990861 FLANGE SCREW",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.416666666666666,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 2,
      "APRD QTY": 2,
      "WH12": 0,
      "WHXX": 3,
      "WH REFERENCE": "WHXX",
      "TREND": 2,
      "STD": 1.38675049056307,
      "MEAN": 0.384615384615384,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 4.800000000000008
    },
    {
      "PART NUMBER": 5284811166,
      "DESCRIPTION": "TEMPERATURE SENSOR 277309Z00A",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 4,
      "SHELF LIFE": 24.000000000000096,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 58,
      "WHXX": 9,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.375533808099405,
      "MEAN": 0.153846153846153,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 24.000000000000096
    },
    {
      "PART NUMBER": 5222845441,
      "DESCRIPTION": "DISC",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 1.08333333333333,
      "MAINTANING STOCK QTY": 4,
      "AVAILABLE": 3,
      "SHELF LIFE": 2.769230769230778,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 1.38,
      "STD": 2.76887462097269,
      "MEAN": 1,
      "COV": 2.76887462097269,
      "SHELF LIFE_1": 3.692307692307704
    },
    {
      "PART NUMBER": 60358721,
      "DESCRIPTION": "FUEL/WATER SEPARATOR FILTER CORE (SANY LOGO)",
      "PRODUCT CATEGORY": "YPG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 9,
      "SHELF LIFE": 27.00000000000003,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 1,
      "STD": 0.75106761619881,
      "MEAN": 0.307692307692307,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 27.00000000000003
    },
    {
      "PART NUMBER": 60327521,
      "DESCRIPTION": "DIESEL COARSE FILTER CORE",
      "PRODUCT CATEGORY": "YPG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.583333333333333,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 2,
      "APRD QTY": 2,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 0.57,
      "STD": 1.19828937903055,
      "MEAN": 0.538461538461538,
      "COV": 2.22539456105674,
      "SHELF LIFE_1": 3.42857142857143
    },
    {
      "PART NUMBER": 60328867,
      "DESCRIPTION": "ENGINE OIL FILTER",
      "PRODUCT CATEGORY": "YCG",
      "ITEM MOVEMENT BRANCH": "AVERAGE (F2)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 3.25,
      "MAINTANING STOCK QTY": 13,
      "AVAILABLE": 7,
      "SHELF LIFE": 2.1538461538461537,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 6,
      "APRD QTY": 6,
      "WH12": 41,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 0.72,
      "STD": 3.60555127546398,
      "MEAN": 3,
      "COV": 1.20185042515466,
      "SHELF LIFE_1": 4
    },
    {
      "PART NUMBER": 11701471,
      "DESCRIPTION": "SEALING RING 8230-02520 ",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3,
      "WHXX": 3,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4110000509047,
      "DESCRIPTION": "OIL FILLER CAP",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 70868286,
      "DESCRIPTION": "V-BELT",
      "PRODUCT CATEGORY": "DOP",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 4,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 24,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 60110299,
      "DESCRIPTION": "WASHER 992045",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 16,
      "SHELF LIFE": 48.00000000000005,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 242,
      "WHXX": 314,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 1.10940039245045,
      "MEAN": 0.307692307692307,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 48.00000000000005
    },
    {
      "PART NUMBER": 14574620,
      "DESCRIPTION": "AXLE",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 997420,
      "DESCRIPTION": "HEXAGON SCREW",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 2,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 36,
      "WHXX": 27,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 46676925,
      "DESCRIPTION": "BREAKER, CIRCUIT WITH BATON HANDLE, 15A",
      "PRODUCT CATEGORY": "DOP",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 2,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": "1112-00830",
      "DESCRIPTION": "PLATE",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 0,
      "WHXX": 8,
      "WH REFERENCE": "WHXX",
      "TREND": 2,
      "STD": 1.10940039245045,
      "MEAN": 0.307692307692307,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 3.000000000000003
    },
    {
      "PART NUMBER": 4110001007015,
      "DESCRIPTION": "4110002513029  ALTERNATOR",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 82461903,
      "DESCRIPTION": "PIPE                     ",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 3,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": 29050011363,
      "DESCRIPTION": "GASKET 29050011362",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 14,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14539459,
      "DESCRIPTION": "COVER",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 60169400,
      "DESCRIPTION": "CONDENSER CORE ASSY.",
      "PRODUCT CATEGORY": "YPG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14404757,
      "DESCRIPTION": "WATER PUMP",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 5223138604,
      "DESCRIPTION": "LEAF SPRING  5222834630 ",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 1.16666666666666,
      "MAINTANING STOCK QTY": 5,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 5,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 1,
      "WH REFERENCE": "PARTIAL WHXX + WH12",
      "TREND": 0.29,
      "STD": 2.7827306272059,
      "MEAN": 1.07692307692307,
      "COV": 2.58396415383405,
      "SHELF LIFE_1": 4.28571428571431
    },
    {
      "PART NUMBER": 22944256,
      "DESCRIPTION": "FLANGE NUT 20382386 ",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.666666666666666,
      "MAINTANING STOCK QTY": 3,
      "AVAILABLE": 3,
      "SHELF LIFE": 4.500000000000004,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 158,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 2.21880078490091,
      "MEAN": 0.615384615384615,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 4.500000000000004
    },
    {
      "PART NUMBER": 17496167,
      "DESCRIPTION": "DIRECTIONAL VALVE",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14701773,
      "DESCRIPTION": "VALVE",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 1,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 3,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 60113115,
      "DESCRIPTION": "FLANGE SCREW",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.833333333333333,
      "MAINTANING STOCK QTY": 3,
      "AVAILABLE": 8,
      "SHELF LIFE": 9.600000000000003,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 218,
      "WHXX": 10,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 2.77350098112614,
      "MEAN": 0.769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 9.600000000000003
    },
    {
      "PART NUMBER": 7221530,
      "DESCRIPTION": "HORN, ALARM",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14727432,
      "DESCRIPTION": "WIRING HARNESS",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14722918,
      "DESCRIPTION": "GAUGE 14588061 14514385",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 10,
      "WHXX": 6,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14883766,
      "DESCRIPTION": "HOSE CLAMP",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 9,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14701764,
      "DESCRIPTION": "HOSE",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14701774,
      "DESCRIPTION": "BRACKET",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14587635,
      "DESCRIPTION": "CLIP",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3,
      "WHXX": 17,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4110003497,
      "DESCRIPTION": "CONTROL MECHANISM",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 20860677,
      "DESCRIPTION": "SEALING RING",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 20,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 5223058608,
      "DESCRIPTION": "BRAKE CHAMBER 5222842699 ",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 23236852,
      "DESCRIPTION": "FRONT SPRING 257952 ",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14561414,
      "DESCRIPTION": "SWITCH",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14656788,
      "DESCRIPTION": "PIN",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 2,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 6,
      "WHXX": 5,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 29040008451,
      "DESCRIPTION": "SEALING RING",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 12,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 997412,
      "DESCRIPTION": "HEXAGON NUT 980521 983312 96703681",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 2.16666666666666,
      "MAINTANING STOCK QTY": 9,
      "AVAILABLE": 9,
      "SHELF LIFE": 4.153846153846167,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 7,
      "WHXX": 64,
      "WH REFERENCE": "",
      "TREND": 0.77,
      "STD": 4.54606056566195,
      "MEAN": 2,
      "COV": 2.27303028283097,
      "SHELF LIFE_1": 4.153846153846167
    },
    {
      "PART NUMBER": 14661334,
      "DESCRIPTION": "CUSHION 14603125 ",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 0,
      "WHXX": 8,
      "WH REFERENCE": "WHXX",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": 14661333,
      "DESCRIPTION": "CUSHION 14603124 ",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 0,
      "WHXX": 8,
      "WH REFERENCE": "WHXX",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": 84472607,
      "DESCRIPTION": "COMPRESSOR",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 1,
      "SHELF LIFE": 6.000000000000024,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": 997983,
      "DESCRIPTION": "HEXAGON SCREW",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 120,
      "WHXX": 6,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 20569635,
      "DESCRIPTION": "SHIM 24426017 ",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 0,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": 60113082,
      "DESCRIPTION": "FLANGE SCREW",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 6,
      "WHXX": 24,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 7000987,
      "DESCRIPTION": "BUCKET TOOTH-PIN ON BUCKET",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 12,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "1CM1450",
      "DESCRIPTION": "SCREW",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 16,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 997437,
      "DESCRIPTION": "HEXAGON SCREW",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 1.33333333333333,
      "MAINTANING STOCK QTY": 5,
      "AVAILABLE": 5,
      "SHELF LIFE": 3.7500000000000098,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 12,
      "WHXX": 31,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 4.43760156980183,
      "MEAN": 1.23076923076923,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 3.7500000000000098
    },
    {
      "PART NUMBER": 60328868,
      "DESCRIPTION": "FUEL/WATER SEPARATOR FILTER CORE",
      "PRODUCT CATEGORY": "YCG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.583333333333333,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 2,
      "APRD QTY": 2,
      "WH12": 9,
      "WHXX": 2,
      "WH REFERENCE": "WHXX",
      "TREND": 0.86,
      "STD": 1.12660142429821,
      "MEAN": 0.538461538461538,
      "COV": 2.0922597879824,
      "SHELF LIFE_1": 3.42857142857143
    },
    {
      "PART NUMBER": 96718598,
      "DESCRIPTION": "PLAIN WASHER",
      "PRODUCT CATEGORY": "VCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 0,
      "WHXX": 35,
      "WH REFERENCE": "WHXX",
      "TREND": 2,
      "STD": 1.10940039245045,
      "MEAN": 0.307692307692307,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 3.000000000000003
    },
    {
      "PART NUMBER": 5222737238,
      "DESCRIPTION": "BUSHING",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 100,
      "WHXX": 17,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4190002761,
      "DESCRIPTION": "WIRING HARNESS",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 2,
      "STD": 0.375533808099405,
      "MEAN": 0.153846153846153,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": 4130002496,
      "DESCRIPTION": "FUEL LEVEL SENSOR",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 11,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 29250001081,
      "DESCRIPTION": "PIN",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 4,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4011001250,
      "DESCRIPTION": "SCREW",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 6,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 50,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4015000428,
      "DESCRIPTION": "WASHER",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 10,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 3,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 29250001031,
      "DESCRIPTION": "FLANGE",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 29250001051,
      "DESCRIPTION": "FLANGE",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 29250017661,
      "DESCRIPTION": "PIN",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 22,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 29250009441,
      "DESCRIPTION": "SLEEVE",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 29250000091,
      "DESCRIPTION": "FLANGE",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 13,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 29250009451,
      "DESCRIPTION": "SLEEVE",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3,
      "WHXX": 1,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 29250009461,
      "DESCRIPTION": "FLANGE",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 29250009421,
      "DESCRIPTION": "SLEEVE",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14540123,
      "DESCRIPTION": "DIFFERENTIAL",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 1,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "7114-12530",
      "DESCRIPTION": "PIN",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 24,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "7114-12560",
      "DESCRIPTION": "RING",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.416666666666666,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 2,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 6,
      "WH REFERENCE": "WHXX",
      "TREND": 0.8,
      "STD": 0.767947647788304,
      "MEAN": 0.384615384615384,
      "COV": 1.99666388424959,
      "SHELF LIFE_1": 4.800000000000008
    },
    {
      "PART NUMBER": "927839Z00D",
      "DESCRIPTION": "MAGNETIC PLUG",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 18,
      "SHELF LIFE": 108.00000000000044,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 9,
      "WHXX": 28,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 108.00000000000044
    },
    {
      "PART NUMBER": 14679698,
      "DESCRIPTION": "LOCK BRACE",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14709863,
      "DESCRIPTION": "SEALING",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4110001782004,
      "DESCRIPTION": "OIL FILTER",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 93,
      "WHXX": 2,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4110000563007,
      "DESCRIPTION": "FUEL FILTER 4110001026085 4110000563003",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 18,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 31,
      "WHXX": 9,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4110000108006,
      "DESCRIPTION": "FUEL FILTER",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 1,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 24,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14721846,
      "DESCRIPTION": "CLAMP",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 1,
      "SHELF LIFE": 6.000000000000024,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 5,
      "WHXX": 3,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": 6670207,
      "DESCRIPTION": "FILTER, OIL HYD",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3,
      "WHXX": 2,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 997383,
      "DESCRIPTION": "WASHER 992040",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 200,
      "WHXX": 163,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14737252,
      "DESCRIPTION": "GASKET",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.416666666666666,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 2,
      "SHELF LIFE": 4.800000000000008,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 32,
      "WHXX": 2,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 1.12089707663561,
      "MEAN": 0.384615384615384,
      "COV": 2.91433239925258,
      "SHELF LIFE_1": 4.800000000000008
    },
    {
      "PART NUMBER": 29270009421,
      "DESCRIPTION": "PLASTIC PLUG",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 1,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 27,
      "WHXX": 3,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4110000076189,
      "DESCRIPTION": "RETAINING RING",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 24,
      "WHXX": 10,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4110001394117,
      "DESCRIPTION": "RETAINING RING",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 9,
      "WHXX": 1,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4110000076302,
      "DESCRIPTION": "RETAINING RING",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 40,
      "WHXX": 12,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4110000076199,
      "DESCRIPTION": "EXTENSION BAR",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3,
      "WHXX": 3,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4110000367110,
      "DESCRIPTION": "RETAINING RING",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 13,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4110000367109,
      "DESCRIPTION": "BEARING",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4110000367111,
      "DESCRIPTION": "BEARING",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 8,
      "WHXX": 5,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4110000367043,
      "DESCRIPTION": "CYLINDER PISTON",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 5,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4110000076251,
      "DESCRIPTION": "TENSION SPRING",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 12,
      "WHXX": 9,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4110000367108,
      "DESCRIPTION": "RETAINING RING",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4110000367107,
      "DESCRIPTION": "NEEDLE BEARING",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 10,
      "WHXX": 2,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4110000367104,
      "DESCRIPTION": "PRESSURE PLATE",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4110000367106,
      "DESCRIPTION": "NEEDLE BEARING",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4110000076294,
      "DESCRIPTION": "RETAINING RING",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 30,
      "WHXX": 15,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4110000367103,
      "DESCRIPTION": "WASHER",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3,
      "WHXX": 1,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4110000367100,
      "DESCRIPTION": "THRUST WASHER",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 12,
      "WHXX": 3,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4110000367127,
      "DESCRIPTION": "BEARING",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 11,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4110000076062,
      "DESCRIPTION": "NEEDLE BEARING",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4110000076060,
      "DESCRIPTION": "NEEDLE BEARING",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 12,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4110000076296,
      "DESCRIPTION": "RETAINING RING",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 1,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4110000367123,
      "DESCRIPTION": "RUBBER WASHER",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 22,
      "WHXX": 2,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4110000076322,
      "DESCRIPTION": "ROLLER",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 20,
      "WHXX": 36,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 21380490,
      "DESCRIPTION": "FUEL FILTER WATER SEPARATOR",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.25,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 6,
      "SHELF LIFE": 24,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 10,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.832050294337843,
      "MEAN": 0.23076923076923,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 24
    },
    {
      "PART NUMBER": 21716424,
      "DESCRIPTION": "AIR CLEANER - PRIMARY FILTER",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 4,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14711075,
      "DESCRIPTION": "WIRING HARNESS",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14702367,
      "DESCRIPTION": "WIRING HARNESS",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14713704,
      "DESCRIPTION": "WIRING HARNESS",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 6682034,
      "DESCRIPTION": "MOTOR DRIVE",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4015000119,
      "DESCRIPTION": "WASHER",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 20,
      "WHXX": 10,
      "WH REFERENCE": "WHXX",
      "TREND": 2,
      "STD": 1.10940039245045,
      "MEAN": 0.307692307692307,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 3.000000000000003
    },
    {
      "PART NUMBER": 14884948,
      "DESCRIPTION": "SPRING WASHER",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "AVERAGE (F2)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 5.83333333333333,
      "MAINTANING STOCK QTY": 23,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 23,
      "APRD QTY": 0,
      "WH12": 108,
      "WHXX": 372,
      "WH REFERENCE": "WHXX",
      "TREND": 0.57,
      "STD": 6.58961887540371,
      "MEAN": 5.38461538461538,
      "COV": 1.22378636257497,
      "SHELF LIFE_1": 3.942857142857145
    },
    {
      "PART NUMBER": 4120006889,
      "DESCRIPTION": "BRAKE VALVE",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 5223273542,
      "DESCRIPTION": "5223476572 OIL FILTER 152049Z00C ",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14725894,
      "DESCRIPTION": "WIRING HARNESS",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4110000367098,
      "DESCRIPTION": "SHAFT",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4110000367033,
      "DESCRIPTION": "CONVERTER HOUSING",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 1,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14679690,
      "DESCRIPTION": "SEALING STRIP",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 4,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 60326015,
      "DESCRIPTION": "OIL PRESSURE ELEMENT",
      "PRODUCT CATEGORY": "YPG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.833333333333333,
      "MAINTANING STOCK QTY": 3,
      "AVAILABLE": 5,
      "SHELF LIFE": 6.000000000000002,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 2.77350098112614,
      "MEAN": 0.769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000002
    },
    {
      "PART NUMBER": 4110000367121,
      "DESCRIPTION": "4110000367359 THRUST WASHER",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 4,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4110004221,
      "DESCRIPTION": "PROPELLER SHAFT 4110002912",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 60242353,
      "DESCRIPTION": "DIESEL FUEL CLEANER",
      "PRODUCT CATEGORY": "YCG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.5,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 3,
      "SHELF LIFE": 6,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 4,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 1.19828937903055,
      "MEAN": 0.461538461538461,
      "COV": 2.5962936545662,
      "SHELF LIFE_1": 6
    },
    {
      "PART NUMBER": 60320099,
      "DESCRIPTION": "ELEMENT KIT, FUEL FILTER",
      "PRODUCT CATEGORY": "YCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 5,
      "WHXX": 4,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 60320126,
      "DESCRIPTION": "ELEMENT, FUEL FILTER",
      "PRODUCT CATEGORY": "YCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 7,
      "WHXX": 4,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 60333601,
      "DESCRIPTION": "OIL FILTER ELEMENT",
      "PRODUCT CATEGORY": "YCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 4,
      "WHXX": 4,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 23226845,
      "DESCRIPTION": "24119885 HOLLOW SHAFT 21901696 ",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 2,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 2,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 5222748120,
      "DESCRIPTION": "O-RING",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 11,
      "WHXX": 10,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4110000076360,
      "DESCRIPTION": "SHAFT",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 7200002550,
      "DESCRIPTION": "BALL",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 7,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 60345316,
      "DESCRIPTION": "FILTER ELEMENT",
      "PRODUCT CATEGORY": "YCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 3,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 3,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 15038635,
      "DESCRIPTION": "OVERHAUL KIT",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 4,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 6680403,
      "DESCRIPTION": "VALVE",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 6675731,
      "DESCRIPTION": "COIL",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 6668820,
      "DESCRIPTION": "NUT",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14704595,
      "DESCRIPTION": "TOGGLE SWITCH",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 6,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14682685,
      "DESCRIPTION": "BATTERY CABLE",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 4,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 22803341,
      "DESCRIPTION": "PUMP",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 22803447,
      "DESCRIPTION": "BELT",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 1,
      "SHELF LIFE": 12.000000000000005,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 5,
      "WHXX": 3,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 12.000000000000005
    },
    {
      "PART NUMBER": 29290039051,
      "DESCRIPTION": "GLASS",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "115-75-58000",
      "DESCRIPTION": "OIL SUCTION FILTER",
      "PRODUCT CATEGORY": "SDG",
      "ITEM MOVEMENT BRANCH": "AVERAGE (F2)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 1.66666666666666,
      "MAINTANING STOCK QTY": 7,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 7,
      "APRD QTY": 7,
      "WH12": 21,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 0.2,
      "STD": 4.38967319617654,
      "MEAN": 1.53846153846153,
      "COV": 2.85328757751475,
      "SHELF LIFE_1": 4.200000000000016
    },
    {
      "PART NUMBER": 12724648,
      "DESCRIPTION": "HYDRAULIC CYLINDER",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4110000076104,
      "DESCRIPTION": "NEEDLE",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 2,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 29170181023,
      "DESCRIPTION": "BUCKET 29170087822",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "152119Z00B",
      "DESCRIPTION": "GASKET",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 17,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4110000509217,
      "DESCRIPTION": "VALVE",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4120003758,
      "DESCRIPTION": "BRAKE VALVE",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 15,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4110002901016,
      "DESCRIPTION": "HAND PUMP",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 5222455512,
      "DESCRIPTION": "WHEEL STUD",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 4.16666666666666,
      "MAINTANING STOCK QTY": 17,
      "AVAILABLE": 9,
      "SHELF LIFE": 2.1600000000000037,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 8,
      "APRD QTY": 8,
      "WH12": 0,
      "WHXX": 4,
      "WH REFERENCE": "PARTIAL WHXX",
      "TREND": 2,
      "STD": 11.2089707663561,
      "MEAN": 3.84615384615384,
      "COV": 2.91433239925258,
      "SHELF LIFE_1": 4.080000000000006
    },
    {
      "PART NUMBER": 5223058609,
      "DESCRIPTION": "BRAKE CHAMBER",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 2,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 10,
      "WHXX": 3,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 7275347,
      "DESCRIPTION": "ELEMENT, FILTER",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 82461913,
      "DESCRIPTION": "HOSE                     ",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 2,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": 84164453,
      "DESCRIPTION": "HOSE                     ",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 5,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": 84055661,
      "DESCRIPTION": "COOLANT PIPE             ",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": 21196040,
      "DESCRIPTION": "BRACKET",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 22080280,
      "DESCRIPTION": "BRACKET",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 22080281,
      "DESCRIPTION": "BRACKET",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 21305187,
      "DESCRIPTION": "BAR",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 21374700,
      "DESCRIPTION": "BRACKET",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 21641263,
      "DESCRIPTION": "BRACKET",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 21671842,
      "DESCRIPTION": "HEAT SHIELD",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 20722721,
      "DESCRIPTION": "AIR INLET                ",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 1,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4110000076053,
      "DESCRIPTION": "BEARING",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 6,
      "WHXX": 10,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4110000076229,
      "DESCRIPTION": "NEEDLE BEARING",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 5,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4110000076321,
      "DESCRIPTION": "BEARING",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 10,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 6715866,
      "DESCRIPTION": "BUSHING",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4110000076428,
      "DESCRIPTION": "BRUSH RETAINER COVER",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 5,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4190002742,
      "DESCRIPTION": "HOSE ASSEMBLY",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.583333333333333,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 2,
      "APRD QTY": 2,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 1.14,
      "STD": 1.05003052458683,
      "MEAN": 0.538461538461538,
      "COV": 1.9500566885184,
      "SHELF LIFE_1": 3.42857142857143
    },
    {
      "PART NUMBER": 7000673,
      "DESCRIPTION": "GASKET, COVER VALVE",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "324859Z00C",
      "DESCRIPTION": "BALL",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 9,
      "WHXX": 19,
      "WH REFERENCE": "WHXX",
      "TREND": 0,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": 7304852,
      "DESCRIPTION": "CARTRIDGE VALVE",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 13,
      "WHXX": 1,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14568000,
      "DESCRIPTION": "CONTROL LEVER",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 4,
      "WHXX": 2,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 6663289,
      "DESCRIPTION": "FUSE, 5A",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 10,
      "WHXX": 1,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 23369953,
      "DESCRIPTION": "COUPLING HEAD",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 6,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14694038,
      "DESCRIPTION": "DRIVE UNIT",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4120006094,
      "DESCRIPTION": "OIL COOLER",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "443429Z00A",
      "DESCRIPTION": "REPAIR KIT",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 11,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 7,
      "WHXX": 25,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4015000032,
      "DESCRIPTION": "SPRING WASHER",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.666666666666666,
      "MAINTANING STOCK QTY": 3,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 3,
      "APRD QTY": 0,
      "WH12": 4,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 0,
      "STD": 2.21880078490091,
      "MEAN": 0.615384615384615,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 4.500000000000004
    },
    {
      "PART NUMBER": "461279Z00A",
      "DESCRIPTION": "PIN",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 0,
      "WHXX": 12,
      "WH REFERENCE": "WHXX",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": "431149Z00A",
      "DESCRIPTION": "CASE",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14737813,
      "DESCRIPTION": "RECOIL SPRING 14709314 14663170",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 1,
      "SHELF LIFE": 6.000000000000024,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 9,
      "WHXX": 3,
      "WH REFERENCE": "",
      "TREND": 1,
      "STD": 0.375533808099405,
      "MEAN": 0.153846153846153,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": 5223187972,
      "DESCRIPTION": "PLUG",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 71,
      "WHXX": 24,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 60273568,
      "DESCRIPTION": "BLOWER",
      "PRODUCT CATEGORY": "YPG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4110000145087,
      "DESCRIPTION": "BALL VALVE",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 5,
      "WHXX": 12,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 60318536,
      "DESCRIPTION": "60149733 LENGTH SENSOR",
      "PRODUCT CATEGORY": "YCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "A210404000009",
      "DESCRIPTION": "WASHER",
      "PRODUCT CATEGORY": "YCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 5,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 2,
      "STD": 1.10940039245045,
      "MEAN": 0.307692307692307,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 3.000000000000003
    },
    {
      "PART NUMBER": 23446985,
      "DESCRIPTION": "BATTERY (MAINTENANCE)",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 23446987,
      "DESCRIPTION": "BATTERY (ENHANCED FLOODED BATTERY EXTRA)",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14645737,
      "DESCRIPTION": "BUSHING",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 29070024091,
      "DESCRIPTION": "COVER",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 4,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4030001093,
      "DESCRIPTION": "SEALING RING",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 5,
      "WHXX": 6,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 29070019841,
      "DESCRIPTION": "O-RING",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 6,
      "WHXX": 5,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "C61267",
      "DESCRIPTION": "FRONT COVER(EX TYPE)/D224*178/SNCM439",
      "PRODUCT CATEGORY": "SOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 1,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 5223387867,
      "DESCRIPTION": "PUMP UNIT",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 2,
      "STD": 0.375533808099405,
      "MEAN": 0.153846153846153,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": 5223473091,
      "DESCRIPTION": "COMPRESSION SPRING 951489Z00B",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.416666666666666,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 2,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 1,
      "WH REFERENCE": "PARTIAL WHXX",
      "TREND": 0,
      "STD": 1.38675049056307,
      "MEAN": 0.384615384615384,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 4.800000000000008
    },
    {
      "PART NUMBER": 29270007831,
      "DESCRIPTION": "BUSHING",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 9,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 29270008591,
      "DESCRIPTION": "PIN",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 5282675887,
      "DESCRIPTION": "CAP",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 10,
      "WHXX": 7,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 5333035,
      "DESCRIPTION": "PUMP,WATER",
      "PRODUCT CATEGORY": "CUG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 5223592666,
      "DESCRIPTION": "KING PIN KIT 5222881536",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4190000595,
      "DESCRIPTION": "LOCK",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4190002907002,
      "DESCRIPTION": "CONDENSER",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.416666666666666,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 2,
      "SHELF LIFE": 4.800000000000008,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0.8,
      "STD": 0.960768922830522,
      "MEAN": 0.384615384615384,
      "COV": 2.49799919935935,
      "SHELF LIFE_1": 4.800000000000008
    },
    {
      "PART NUMBER": 4190002907004,
      "DESCRIPTION": "RETURN STRAINER",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.25,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 2,
      "STD": 0.438529009653514,
      "MEAN": 0.23076923076923,
      "COV": 1.90029237516522,
      "SHELF LIFE_1": 4
    },
    {
      "PART NUMBER": 14746240,
      "DESCRIPTION": "14780710 PLASTIC HOSE 14737201",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 156,
      "WHXX": 10,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "1725101Z0B",
      "DESCRIPTION": "FILLER CAP LOCKABLE",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 2,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 7,
      "WHXX": 2,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 60110297,
      "DESCRIPTION": "WASHER 992042",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 1.66666666666666,
      "MAINTANING STOCK QTY": 7,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 7,
      "APRD QTY": 7,
      "WH12": 72,
      "WHXX": 47,
      "WH REFERENCE": "WHXX",
      "TREND": 2,
      "STD": 5.54700196225229,
      "MEAN": 1.53846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 4.200000000000016
    },
    {
      "PART NUMBER": 12814491,
      "DESCRIPTION": "SPACER",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 2,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "158-40-A0000P050-01",
      "DESCRIPTION": "CARRIER ROLLER",
      "PRODUCT CATEGORY": "SDG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 1,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14558944,
      "DESCRIPTION": "SEAL",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14638992,
      "DESCRIPTION": "CHECK VALVE",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 4,
      "WHXX": 2,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 11222279,
      "DESCRIPTION": "LIFTHEIGHTLIMITSWITCHASSEMBLYQ150D.",
      "PRODUCT CATEGORY": "YCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14638193,
      "DESCRIPTION": "ROD 14575022",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 5,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 5473238,
      "DESCRIPTION": "KIT,WATER PUMP",
      "PRODUCT CATEGORY": "CUG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 7163219,
      "DESCRIPTION": "KIT, SEAL",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14737811,
      "DESCRIPTION": "RECOIL SPRING",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 2,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14685547,
      "DESCRIPTION": "TRAVEL MOTOR 14500160",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "22.4.JSM33*2HXL",
      "DESCRIPTION": "360¬∞ ROTARY JOINT",
      "PRODUCT CATEGORY": "PHG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": 4120003296,
      "DESCRIPTION": "FILTER INSERT",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 32,
      "WHXX": 36,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14671760,
      "DESCRIPTION": "RECOIL SPRING",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4110002354001,
      "DESCRIPTION": "OIL FILTER",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 47,
      "WHXX": 6,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4110002354002,
      "DESCRIPTION": "FUEL FILTER INSERT",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 3,
      "SHELF LIFE": 9.000000000000009,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 30,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 1.10940039245045,
      "MEAN": 0.307692307692307,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 9.000000000000009
    },
    {
      "PART NUMBER": 23381562,
      "DESCRIPTION": "FILTER",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.583333333333333,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 5,
      "SHELF LIFE": 8.571428571428577,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 58,
      "WHXX": 4,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 1.45002210416396,
      "MEAN": 0.538461538461538,
      "COV": 2.69289819344737,
      "SHELF LIFE_1": 8.571428571428577
    },
    {
      "PART NUMBER": 5223488187,
      "DESCRIPTION": "SYNCHRONIZER 326109Z01D  ",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14883767,
      "DESCRIPTION": "HOSE CLAMP",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 4,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 22216579,
      "DESCRIPTION": "CAMSHAFT",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14720764,
      "DESCRIPTION": "SENSOR 14601315",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 3030900178,
      "DESCRIPTION": "DRIVE SHAFT GEAR",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 4,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14650866,
      "DESCRIPTION": "SWITCH",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 1,
      "SHELF LIFE": 12.000000000000005,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 2,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 12.000000000000005
    },
    {
      "PART NUMBER": 14737841,
      "DESCRIPTION": "VALVE 8230-00990 14630785 14531520 14562315",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.583333333333333,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 2,
      "SHELF LIFE": 3.42857142857143,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 20,
      "WHXX": 14,
      "WH REFERENCE": "",
      "TREND": 1.14,
      "STD": 0.776250025806184,
      "MEAN": 0.538461538461538,
      "COV": 1.44160719078291,
      "SHELF LIFE_1": 3.42857142857143
    },
    {
      "PART NUMBER": 14652624,
      "DESCRIPTION": "PLATE",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.25,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 2,
      "STD": 0.832050294337843,
      "MEAN": 0.23076923076923,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 4
    },
    {
      "PART NUMBER": 997492,
      "DESCRIPTION": "SIX POINT SOCKET SCREW",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 10,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 60348780,
      "DESCRIPTION": "AIR FILTER KIT",
      "PRODUCT CATEGORY": "YCG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 1,
      "SHELF LIFE": 3.000000000000003,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 9,
      "WHXX": 4,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 1.10940039245045,
      "MEAN": 0.307692307692307,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 3.000000000000003
    },
    {
      "PART NUMBER": 14744967,
      "DESCRIPTION": "14750657 FILTER CARTRIDGE 14532687",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.666666666666666,
      "MAINTANING STOCK QTY": 3,
      "AVAILABLE": 1,
      "SHELF LIFE": 1.5000000000000016,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 2,
      "APRD QTY": 2,
      "WH12": 0,
      "WHXX": 15,
      "WH REFERENCE": "WHXX",
      "TREND": 0.5,
      "STD": 1.12089707663561,
      "MEAN": 0.615384615384615,
      "COV": 1.82145774953286,
      "SHELF LIFE_1": 4.500000000000004
    },
    {
      "PART NUMBER": 14531688,
      "DESCRIPTION": "SOCKET",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 5223579293,
      "DESCRIPTION": "SHOCK ABSORBER ",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 2,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "562039Z00E",
      "DESCRIPTION": "SHOCK ABSORBER ",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 60113647,
      "DESCRIPTION": "CROSS RECESSED SCREW",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 0,
      "WHXX": 9,
      "WH REFERENCE": "WHXX",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": 60188333,
      "DESCRIPTION": "LINEAR STEPPING MOTO",
      "PRODUCT CATEGORY": "YPG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 11797975,
      "DESCRIPTION": "FRONT PANEL BODY ASSY",
      "PRODUCT CATEGORY": "YTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 29070001031,
      "DESCRIPTION": "BOLT",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.666666666666666,
      "MAINTANING STOCK QTY": 3,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 3,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 0,
      "STD": 2.21880078490091,
      "MEAN": 0.615384615384615,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 4.500000000000004
    },
    {
      "PART NUMBER": 994868,
      "DESCRIPTION": "WASHER` 13955894 96719745",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.416666666666666,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 3,
      "SHELF LIFE": 7.200000000000011,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 14,
      "WHXX": 60,
      "WH REFERENCE": "",
      "TREND": 1.6,
      "STD": 1.1208970766356,
      "MEAN": 0.384615384615384,
      "COV": 2.91433239925258,
      "SHELF LIFE_1": 7.200000000000011
    },
    {
      "PART NUMBER": 23285701,
      "DESCRIPTION": "OIL LEVEL SENSOR 22022794",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 13,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 5222931438,
      "DESCRIPTION": "SPRING",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 11,
      "WH REFERENCE": "WHXX",
      "TREND": 1,
      "STD": 0.75106761619881,
      "MEAN": 0.307692307692307,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 3.000000000000003
    },
    {
      "PART NUMBER": 14749759,
      "DESCRIPTION": "14675562 SEALING KIT",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 2,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4120003757,
      "DESCRIPTION": "PRESSURE SWITCH",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 4,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4110000556223,
      "DESCRIPTION": "WATER SEPARATOR",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4190002764,
      "DESCRIPTION": "HOSE",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.583333333333333,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 2,
      "APRD QTY": 2,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 1.14,
      "STD": 1.05003052458683,
      "MEAN": 0.538461538461538,
      "COV": 1.9500566885184,
      "SHELF LIFE_1": 3.42857142857143
    },
    {
      "PART NUMBER": 4190002946,
      "DESCRIPTION": "PIPE",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.25,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 2,
      "STD": 0.599144689515278,
      "MEAN": 0.23076923076923,
      "COV": 2.5962936545662,
      "SHELF LIFE_1": 4
    },
    {
      "PART NUMBER": 4190002907,
      "DESCRIPTION": "CONDENSER",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.25,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 2,
      "STD": 0.438529009653514,
      "MEAN": 0.23076923076923,
      "COV": 1.90029237516522,
      "SHELF LIFE_1": 4
    },
    {
      "PART NUMBER": 4130002904,
      "DESCRIPTION": "EVAPORATOR 4190002757 4130002911",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.666666666666666,
      "MAINTANING STOCK QTY": 3,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 3,
      "APRD QTY": 3,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 2,
      "STD": 1.93814608747902,
      "MEAN": 0.615384615384615,
      "COV": 3.14948739215341,
      "SHELF LIFE_1": 4.500000000000004
    },
    {
      "PART NUMBER": 4190002766,
      "DESCRIPTION": "WATER PIPE",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.25,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 2,
      "STD": 0.599144689515278,
      "MEAN": 0.23076923076923,
      "COV": 2.5962936545662,
      "SHELF LIFE_1": 4
    },
    {
      "PART NUMBER": 29010067911,
      "DESCRIPTION": "CONTROL CABLE",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 0,
      "WHXX": 3,
      "WH REFERENCE": "WHXX",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": 4190002947,
      "DESCRIPTION": "PIPE",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.25,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 2,
      "STD": 0.599144689515278,
      "MEAN": 0.23076923076923,
      "COV": 2.5962936545662,
      "SHELF LIFE_1": 4
    },
    {
      "PART NUMBER": 5222859763,
      "DESCRIPTION": "FLANGE SCREW 111139Z00B",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 48,
      "WHXX": 48,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4110000991031,
      "DESCRIPTION": "BEARING SHELL 4110000991032 4110000991030",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.5,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 2,
      "APRD QTY": 0,
      "WH12": 26,
      "WHXX": 28,
      "WH REFERENCE": "WHXX",
      "TREND": 0,
      "STD": 1.39136531360294,
      "MEAN": 0.461538461538461,
      "COV": 3.01462484613972,
      "SHELF LIFE_1": 4
    },
    {
      "PART NUMBER": 12788543,
      "DESCRIPTION": "SWITCH",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.25,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 0,
      "WHXX": 3,
      "WH REFERENCE": "WHXX",
      "TREND": 2,
      "STD": 0.599144689515278,
      "MEAN": 0.23076923076923,
      "COV": 2.5962936545662,
      "SHELF LIFE_1": 4
    },
    {
      "PART NUMBER": 7312069,
      "DESCRIPTION": "FUEL STRAINER",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 5,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 7004877,
      "DESCRIPTION": "DRAIN FILTER",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 4,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 29050020491,
      "DESCRIPTION": "SHIM",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 29050020501,
      "DESCRIPTION": "SHIM",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 29050020511,
      "DESCRIPTION": "SHIM",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 29050020931,
      "DESCRIPTION": "SHIM",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 29040008401,
      "DESCRIPTION": "GASKET",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 11,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 10125894,
      "DESCRIPTION": "BUMPER BLOCK",
      "PRODUCT CATEGORY": "YCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 2,
      "STD": 1.10940039245045,
      "MEAN": 0.307692307692307,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 3.000000000000003
    },
    {
      "PART NUMBER": "A810201206126",
      "DESCRIPTION": "PRESSURE SENSOR",
      "PRODUCT CATEGORY": "YCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 16284724,
      "DESCRIPTION": "WIPER BLADE 14508629 ",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 1,
      "SHELF LIFE": 3.000000000000003,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 38,
      "WHXX": 5,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.630425171956115,
      "MEAN": 0.307692307692307,
      "COV": 2.04888180885737,
      "SHELF LIFE_1": 3.000000000000003
    },
    {
      "PART NUMBER": 23191319,
      "DESCRIPTION": "OIL COOLER 20511773 22592869",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 3,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 0.5,
      "STD": 0.480384461415261,
      "MEAN": 0.307692307692307,
      "COV": 1.56124949959959,
      "SHELF LIFE_1": 3.000000000000003
    },
    {
      "PART NUMBER": "15058186PQ",
      "DESCRIPTION": "SUPER HYDRAULIC OIL  ISO VG68",
      "PRODUCT CATEGORY": "LUV",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 18,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 10,
      "WHXX": 41,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "15067522PQ",
      "DESCRIPTION": "SUPER TRANSMISSION OIL  85W/140  OIL API GL-5 99990-1Z05J",
      "PRODUCT CATEGORY": "LUV",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 2,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 107,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "15067640PQ",
      "DESCRIPTION": "GREASE, LITHIUM  EP2 ",
      "PRODUCT CATEGORY": "LUV",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 42,
      "MAINTANING STOCK QTY": 168,
      "AVAILABLE": 1,
      "SHELF LIFE": 0.023809523809523808,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 167,
      "APRD QTY": 160,
      "WH12": 1441,
      "WHXX": 17,
      "WH REFERENCE": "WH12 + WHXX",
      "TREND": 0.89,
      "STD": 57.3035104307956,
      "MEAN": 38.7692307692307,
      "COV": 1.47806673730226,
      "SHELF LIFE_1": 4
    },
    {
      "PART NUMBER": 21707132,
      "DESCRIPTION": "OIL FILTER, BYPASS",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 1.33333333333333,
      "MAINTANING STOCK QTY": 5,
      "AVAILABLE": 31,
      "SHELF LIFE": 23.25000000000006,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 8,
      "WH REFERENCE": "",
      "TREND": 1.38,
      "STD": 2.16617351389672,
      "MEAN": 1.23076923076923,
      "COV": 1.76001598004109,
      "SHELF LIFE_1": 23.25000000000006
    },
    {
      "PART NUMBER": 21707133,
      "DESCRIPTION": "23658092 OIL FILTER",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 2.41666666666666,
      "MAINTANING STOCK QTY": 10,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 10,
      "APRD QTY": 10,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 0.9,
      "STD": 5.47956576980704,
      "MEAN": 2.23076923076923,
      "COV": 2.45635706922384,
      "SHELF LIFE_1": 4.13793103448277
    },
    {
      "PART NUMBER": 21707134,
      "DESCRIPTION": "OIL FILTER",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.416666666666666,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 16,
      "SHELF LIFE": 38.40000000000006,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 115,
      "WHXX": 11,
      "WH REFERENCE": "",
      "TREND": 0.8,
      "STD": 0.960768922830522,
      "MEAN": 0.384615384615384,
      "COV": 2.49799919935935,
      "SHELF LIFE_1": 38.40000000000006
    },
    {
      "PART NUMBER": 980521,
      "DESCRIPTION": "NUT, HEXAGON",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 8,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 0,
      "STD": 1.10940039245045,
      "MEAN": 0.307692307692307,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 3.000000000000003
    },
    {
      "PART NUMBER": 914465,
      "DESCRIPTION": "RETAINING RING 19C-137 12750353",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 40,
      "WHXX": 9,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 955892,
      "DESCRIPTION": "60113297 WASHER",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 15,
      "WHXX": 1,
      "WH REFERENCE": "WHXX",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": 969161,
      "DESCRIPTION": "BLADE FUSE 10A A35D/G930/L150F/EC210B/EC460B",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.416666666666666,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 21,
      "SHELF LIFE": 50.40000000000008,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 54,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 1.38675049056307,
      "MEAN": 0.384615384615384,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 50.40000000000008
    },
    {
      "PART NUMBER": 969163,
      "DESCRIPTION": "BLADE FUSE 25A A35D/G930/EC460C/FM400",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 10,
      "SHELF LIFE": 60.00000000000024,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 4,
      "WHXX": 12,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 60.00000000000024
    },
    {
      "PART NUMBER": 970742,
      "DESCRIPTION": "BULB A35D 24V 21/5W NOTLPA",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 5,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 984725,
      "DESCRIPTION": "FLANGE SCREW 946544",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.25,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 22,
      "WH REFERENCE": "WHXX",
      "TREND": 0,
      "STD": 0.832050294337843,
      "MEAN": 0.23076923076923,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 4
    },
    {
      "PART NUMBER": 984738,
      "DESCRIPTION": "FLANGE SCREW 13947760",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 4,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 31,
      "WHXX": 15,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 984751,
      "DESCRIPTION": "FLANGE SCREW 60113259",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 7,
      "WHXX": 27,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 984756,
      "DESCRIPTION": "FLANGE SCREW 965186 13965186",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.75,
      "MAINTANING STOCK QTY": 3,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 3,
      "APRD QTY": 3,
      "WH12": 0,
      "WHXX": 20,
      "WH REFERENCE": "WHXX",
      "TREND": 2,
      "STD": 2.49615088301353,
      "MEAN": 0.692307692307692,
      "COV": 3.60555127546399,
      "SHELF LIFE_1": 4
    },
    {
      "PART NUMBER": 990861,
      "DESCRIPTION": "FLANGE SCREW  60113094 946472",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 41,
      "WHXX": 9,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 990950,
      "DESCRIPTION": "FLANGE LOCK NUT 948645 60113167",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 61,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 992515,
      "DESCRIPTION": "BULB 75/70W FM10/FM400 973892 13964763 964763",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.25,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 21,
      "WHXX": 22,
      "WH REFERENCE": "WHXX",
      "TREND": 0,
      "STD": 0.599144689515278,
      "MEAN": 0.23076923076923,
      "COV": 2.5962936545662,
      "SHELF LIFE_1": 4
    },
    {
      "PART NUMBER": 992516,
      "DESCRIPTION": "BULB ADAPTED QTY 10 943904",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.666666666666666,
      "MAINTANING STOCK QTY": 3,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 3,
      "APRD QTY": 0,
      "WH12": 17,
      "WHXX": 51,
      "WH REFERENCE": "WHXX",
      "TREND": 0,
      "STD": 1.50213523239762,
      "MEAN": 0.615384615384615,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 4.500000000000004
    },
    {
      "PART NUMBER": 992517,
      "DESCRIPTION": "BULB_70W 24V A35D/EC210B/EC460B/C/EC700B/G930/L150F/FM10/FM400 943903",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 33,
      "WHXX": 25,
      "WH REFERENCE": "WHXX",
      "TREND": 0,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": 992521,
      "DESCRIPTION": "BULB 24V/21W G930/L150F 967708",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.583333333333333,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 3,
      "SHELF LIFE": 5.142857142857146,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 30,
      "WHXX": 31,
      "WH REFERENCE": "",
      "TREND": 1.43,
      "STD": 1.19828937903055,
      "MEAN": 0.538461538461538,
      "COV": 2.22539456105674,
      "SHELF LIFE_1": 5.142857142857146
    },
    {
      "PART NUMBER": 1674083,
      "DESCRIPTION": "PRESS CAP_EXP TANK FM10 20879323",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 4,
      "WHXX": 3,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 20367490,
      "DESCRIPTION": "RELAY, STARTER NL10/FM400/EC460B/EC700B/G930/L150F",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 20538793,
      "DESCRIPTION": "SEALING STRIP",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 3,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 21271982,
      "DESCRIPTION": "SENSOR",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 9,
      "WHXX": 4,
      "WH REFERENCE": "WHXX",
      "TREND": 0,
      "STD": 0.375533808099405,
      "MEAN": 0.153846153846153,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": 21415427,
      "DESCRIPTION": "SEALING RING FM9/FM10/FM340/EC700B",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 10,
      "WHXX": 3,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 21531072,
      "DESCRIPTION": "SENSOR_TEMPERATURE FM10/FM340/EC210B/EC290B/EC700B/G710B/G930/BL71/A40F 20513340",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 1,
      "SHELF LIFE": 12.000000000000005,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 6,
      "WHXX": 3,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 12.000000000000005
    },
    {
      "PART NUMBER": 22567321,
      "DESCRIPTION": "COOLANT (READY MIXED) 5L/GAL YELLOW  85108913",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "AVERAGE (F2)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 14.6666666666666,
      "MAINTANING STOCK QTY": 59,
      "AVAILABLE": 59,
      "SHELF LIFE": 4.022727272727291,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 100,
      "WHXX": 82,
      "WH REFERENCE": "",
      "TREND": 0.97,
      "STD": 18.7688366919537,
      "MEAN": 13.5384615384615,
      "COV": 1.38633452838295,
      "SHELF LIFE_1": 4.022727272727291
    },
    {
      "PART NUMBER": 22899626,
      "DESCRIPTION": "PRESSURE SENSOR 21634021 20898038",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 1,
      "SHELF LIFE": 6.000000000000024,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 8,
      "WHXX": 5,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.375533808099405,
      "MEAN": 0.153846153846153,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": 22902431,
      "DESCRIPTION": "24070789 PUMP UNIT FH400 22197705 FM10/FM340/FM370/FM400/EC700B",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 85135446,
      "DESCRIPTION": "OIL, COMPRESSOR 125ML FM10/A30C 1161629 ",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 3,
      "SHELF LIFE": 18.000000000000075,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 26,
      "WHXX": 8,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 18.000000000000075
    },
    {
      "PART NUMBER": 23387844,
      "DESCRIPTION": "SIDE MARKING LAMP",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 3,
      "MAINTANING STOCK QTY": 12,
      "AVAILABLE": 12,
      "SHELF LIFE": 4,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 14,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0.56,
      "STD": 5.32531448451397,
      "MEAN": 2.76923076923076,
      "COV": 1.92303023051893,
      "SHELF LIFE_1": 4
    },
    {
      "PART NUMBER": 4110001755009,
      "DESCRIPTION": "INJECTION PUMP",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 6669267,
      "DESCRIPTION": "SEAL",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 16,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "4110000677001Y",
      "DESCRIPTION": "OIL FILTER",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "AVERAGE (F2)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 1.16666666666666,
      "MAINTANING STOCK QTY": 5,
      "AVAILABLE": 2,
      "SHELF LIFE": 1.714285714285724,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 3,
      "APRD QTY": 3,
      "WH12": 68,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 1.14,
      "STD": 1.1875421719907,
      "MEAN": 1.07692307692307,
      "COV": 1.10271773113422,
      "SHELF LIFE_1": 4.28571428571431
    },
    {
      "PART NUMBER": 4110000787007,
      "DESCRIPTION": "FUEL FILTER",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "AVERAGE (F2)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 1.25,
      "MAINTANING STOCK QTY": 5,
      "AVAILABLE": 2,
      "SHELF LIFE": 1.6,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 3,
      "APRD QTY": 3,
      "WH12": 264,
      "WHXX": 20,
      "WH REFERENCE": "WHXX",
      "TREND": 1.2,
      "STD": 1.28102523044069,
      "MEAN": 1.15384615384615,
      "COV": 1.11022186638193,
      "SHELF LIFE_1": 4
    },
    {
      "PART NUMBER": 14884949,
      "DESCRIPTION": "SPRING WASHER",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 17,
      "WHXX": 12,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4120000761,
      "DESCRIPTION": "BREATHER CAP",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 82,
      "WHXX": 2,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4120002319001,
      "DESCRIPTION": "FILTER INSERT",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.5,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 2,
      "APRD QTY": 2,
      "WH12": 82,
      "WHXX": 4,
      "WH REFERENCE": "WHXX",
      "TREND": 1.33,
      "STD": 0.660225291773524,
      "MEAN": 0.461538461538461,
      "COV": 1.43048813217597,
      "SHELF LIFE_1": 4
    },
    {
      "PART NUMBER": 4120002103001,
      "DESCRIPTION": "FILTER INSERT",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 1,
      "MAINTANING STOCK QTY": 4,
      "AVAILABLE": 1,
      "SHELF LIFE": 1,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 3,
      "APRD QTY": 3,
      "WH12": 103,
      "WHXX": 6,
      "WH REFERENCE": "WHXX",
      "TREND": 1,
      "STD": 1.03774904332554,
      "MEAN": 0.923076923076923,
      "COV": 1.12422813026933,
      "SHELF LIFE_1": 4
    },
    {
      "PART NUMBER": 4190000173001,
      "DESCRIPTION": "FILTER INSERT",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 1,
      "MAINTANING STOCK QTY": 4,
      "AVAILABLE": 1,
      "SHELF LIFE": 1,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 3,
      "APRD QTY": 3,
      "WH12": 59,
      "WHXX": 3,
      "WH REFERENCE": "WHXX",
      "TREND": 1,
      "STD": 1.03774904332554,
      "MEAN": 0.923076923076923,
      "COV": 1.12422813026933,
      "SHELF LIFE_1": 4
    },
    {
      "PART NUMBER": 4120000721001,
      "DESCRIPTION": "FILTER INSERT",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 3,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 91,
      "WHXX": 3,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4190002509008,
      "DESCRIPTION": "AIR FILTER INSERT",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 65,
      "WHXX": 1,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4110000322001,
      "DESCRIPTION": "SAFETTY ELEMENT OF AIR CLEANER",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 74,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4110000322002,
      "DESCRIPTION": "PRMIARY ELEMENT OF AIR CLEANER",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 1,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 70,
      "WHXX": 3,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4190002205006,
      "DESCRIPTION": "FILTER ELEMENT",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.416666666666666,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 2,
      "APRD QTY": 2,
      "WH12": 58,
      "WHXX": 8,
      "WH REFERENCE": "WHXX",
      "TREND": 1.6,
      "STD": 0.65044363558799,
      "MEAN": 0.384615384615384,
      "COV": 1.69115345252877,
      "SHELF LIFE_1": 4.800000000000008
    },
    {
      "PART NUMBER": "4110000677002Y",
      "DESCRIPTION": "FUEL FILTER",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "AVERAGE (F2)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 1.16666666666666,
      "MAINTANING STOCK QTY": 5,
      "AVAILABLE": 2,
      "SHELF LIFE": 1.714285714285724,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 3,
      "APRD QTY": 3,
      "WH12": 138,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 1.14,
      "STD": 1.1875421719907,
      "MEAN": 1.07692307692307,
      "COV": 1.10271773113422,
      "SHELF LIFE_1": 4.28571428571431
    },
    {
      "PART NUMBER": 23307414,
      "DESCRIPTION": "VACUUM SWITCH 20560843 ",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 1,
      "SHELF LIFE": 12.000000000000005,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 11,
      "WHXX": 3,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 12.000000000000005
    },
    {
      "PART NUMBER": 6213184,
      "DESCRIPTION": "SCREW",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 2,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4040000051,
      "DESCRIPTION": "WASHER",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.666666666666666,
      "MAINTANING STOCK QTY": 3,
      "AVAILABLE": 3,
      "SHELF LIFE": 4.500000000000004,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 6,
      "WHXX": 3,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 2.21880078490091,
      "MEAN": 0.615384615384615,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 4.500000000000004
    },
    {
      "PART NUMBER": 4011001261,
      "DESCRIPTION": "SCREW",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.666666666666666,
      "MAINTANING STOCK QTY": 3,
      "AVAILABLE": 3,
      "SHELF LIFE": 4.500000000000004,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 8,
      "WHXX": 2,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 2.21880078490091,
      "MEAN": 0.615384615384615,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 4.500000000000004
    },
    {
      "PART NUMBER": 14710704,
      "DESCRIPTION": "WIRING HARNESS",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14734295,
      "DESCRIPTION": "WIRING HARNESS 14704000",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14555877,
      "DESCRIPTION": "WIRING HARNESS",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 0,
      "WHXX": 2,
      "WH REFERENCE": "WHXX",
      "TREND": 2,
      "STD": 0.375533808099405,
      "MEAN": 0.153846153846153,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": "119139Z00A",
      "DESCRIPTION": "BRACKET",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 4,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 3030900177,
      "DESCRIPTION": "DRIVE SHAFT GEAR",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4110000556202,
      "DESCRIPTION": "HOSE 4110001985004",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 36,
      "WHXX": 1,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14726137,
      "DESCRIPTION": "WIRING HARNESS",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14726138,
      "DESCRIPTION": "WIRING HARNESS",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 29050022281,
      "DESCRIPTION": "SHIM",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 7222791,
      "DESCRIPTION": "FILTER, AIR RECIRCULATING",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 7,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 29040009571,
      "DESCRIPTION": "SHIM",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 29040009581,
      "DESCRIPTION": "SHIM",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4021000110,
      "DESCRIPTION": "BALL BEARING",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 5,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 61019283,
      "DESCRIPTION": "CLUTCH SLAVE CYLINDER",
      "PRODUCT CATEGORY": "YCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 65,
      "WHXX": 2,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "A810301040447",
      "DESCRIPTION": "PISTON HEAD",
      "PRODUCT CATEGORY": "YPG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": 14882694,
      "DESCRIPTION": "O-RING",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 72,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 0,
      "STD": 1.10940039245045,
      "MEAN": 0.307692307692307,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 3.000000000000003
    },
    {
      "PART NUMBER": 14550214,
      "DESCRIPTION": "GEAR PUMP",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14728476,
      "DESCRIPTION": "WIPER MOTOR 14630974",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.25,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 0,
      "WHXX": 2,
      "WH REFERENCE": "WHXX",
      "TREND": 1.33,
      "STD": 0.438529009653514,
      "MEAN": 0.23076923076923,
      "COV": 1.90029237516522,
      "SHELF LIFE_1": 4
    },
    {
      "PART NUMBER": 4190001802,
      "DESCRIPTION": "BUCKET TOOTH",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 2.25,
      "MAINTANING STOCK QTY": 9,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 9,
      "APRD QTY": 9,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 1.56,
      "STD": 3.30306772716764,
      "MEAN": 2.07692307692307,
      "COV": 1.59036594271034,
      "SHELF LIFE_1": 4
    },
    {
      "PART NUMBER": 4190001804,
      "DESCRIPTION": "WASHER",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 2.25,
      "MAINTANING STOCK QTY": 9,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 9,
      "APRD QTY": 9,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 1.56,
      "STD": 3.30306772716764,
      "MEAN": 2.07692307692307,
      "COV": 1.59036594271034,
      "SHELF LIFE_1": 4
    },
    {
      "PART NUMBER": 4190001805,
      "DESCRIPTION": "PIN",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 2.25,
      "MAINTANING STOCK QTY": 9,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 9,
      "APRD QTY": 9,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 1.56,
      "STD": 3.30306772716764,
      "MEAN": 2.07692307692307,
      "COV": 1.59036594271034,
      "SHELF LIFE_1": 4
    },
    {
      "PART NUMBER": 4110002116002,
      "DESCRIPTION": "ALTERNATOR",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4110000727162,
      "DESCRIPTION": "14403813 FUEL FILTER INSERT",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "AVERAGE (F2)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 2.33333333333333,
      "MAINTANING STOCK QTY": 9,
      "AVAILABLE": 7,
      "SHELF LIFE": 3.0000000000000044,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 2,
      "APRD QTY": 2,
      "WH12": 100,
      "WHXX": 8,
      "WH REFERENCE": "WHXX",
      "TREND": 0.86,
      "STD": 2.37508434398141,
      "MEAN": 2.15384615384615,
      "COV": 1.10271773113422,
      "SHELF LIFE_1": 3.857142857142863
    },
    {
      "PART NUMBER": 11214252,
      "DESCRIPTION": "FILTER",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "FAST (F1)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 4.5,
      "MAINTANING STOCK QTY": 18,
      "AVAILABLE": 36,
      "SHELF LIFE": 8,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 181,
      "WHXX": 15,
      "WH REFERENCE": "",
      "TREND": 1.04,
      "STD": 3.26205849748677,
      "MEAN": 4.15384615384615,
      "COV": 0.785310379024592,
      "SHELF LIFE_1": 8
    },
    {
      "PART NUMBER": 11210241,
      "DESCRIPTION": "AIR FILTER",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.583333333333333,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 15,
      "SHELF LIFE": 25.714285714285726,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 75,
      "WHXX": 22,
      "WH REFERENCE": "",
      "TREND": 1.14,
      "STD": 0.967417922046845,
      "MEAN": 0.538461538461538,
      "COV": 1.79663328380128,
      "SHELF LIFE_1": 25.714285714285726
    },
    {
      "PART NUMBER": 11211360,
      "DESCRIPTION": "HYDRAULIC FLUID FILTER",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "AVERAGE (F2)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 2.66666666666666,
      "MAINTANING STOCK QTY": 11,
      "AVAILABLE": 15,
      "SHELF LIFE": 5.625000000000014,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 256,
      "WHXX": 11,
      "WH REFERENCE": "",
      "TREND": 1.13,
      "STD": 2.53690706094989,
      "MEAN": 2.46153846153846,
      "COV": 1.03061849351089,
      "SHELF LIFE_1": 5.625000000000014
    },
    {
      "PART NUMBER": 11211213,
      "DESCRIPTION": "HYDRAULIC FLUID FILTER",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "AVERAGE (F2)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 2.66666666666666,
      "MAINTANING STOCK QTY": 11,
      "AVAILABLE": 13,
      "SHELF LIFE": 4.875000000000012,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 314,
      "WHXX": 9,
      "WH REFERENCE": "",
      "TREND": 1.13,
      "STD": 2.53690706094989,
      "MEAN": 2.46153846153846,
      "COV": 1.03061849351089,
      "SHELF LIFE_1": 4.875000000000012
    },
    {
      "PART NUMBER": 11210987,
      "DESCRIPTION": "FILTER",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.666666666666666,
      "MAINTANING STOCK QTY": 3,
      "AVAILABLE": 14,
      "SHELF LIFE": 21.00000000000002,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 154,
      "WHXX": 18,
      "WH REFERENCE": "",
      "TREND": 1.25,
      "STD": 0.960768922830522,
      "MEAN": 0.615384615384615,
      "COV": 1.56124949959959,
      "SHELF LIFE_1": 21.00000000000002
    },
    {
      "PART NUMBER": 11211189,
      "DESCRIPTION": "HYDRAULIC FLUID FILTER",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "AVERAGE (F2)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 2.5,
      "MAINTANING STOCK QTY": 10,
      "AVAILABLE": 28,
      "SHELF LIFE": 11.2,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 123,
      "WHXX": 14,
      "WH REFERENCE": "",
      "TREND": 1.13,
      "STD": 1.97419246717129,
      "MEAN": 2.3076923076923,
      "COV": 0.855483402440895,
      "SHELF LIFE_1": 11.2
    },
    {
      "PART NUMBER": 14405925,
      "DESCRIPTION": "AIR FILTER INSERT",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 1,
      "MAINTANING STOCK QTY": 4,
      "AVAILABLE": 14,
      "SHELF LIFE": 14,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 177,
      "WHXX": 13,
      "WH REFERENCE": "",
      "TREND": 1.17,
      "STD": 1.49786172378819,
      "MEAN": 0.923076923076923,
      "COV": 1.62268353410387,
      "SHELF LIFE_1": 14
    },
    {
      "PART NUMBER": 14403810,
      "DESCRIPTION": "AIR FILTER",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.833333333333333,
      "MAINTANING STOCK QTY": 3,
      "AVAILABLE": 3,
      "SHELF LIFE": 3.6000000000000014,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 15,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 1.2,
      "STD": 1.01273936708366,
      "MEAN": 0.769230769230769,
      "COV": 1.31656117720876,
      "SHELF LIFE_1": 3.6000000000000014
    },
    {
      "PART NUMBER": 4110000727198,
      "DESCRIPTION": "V-BELT",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 5,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14402462,
      "DESCRIPTION": "V-BELT",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 10,
      "WHXX": 1,
      "WH REFERENCE": "WHXX",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": 4110003380015,
      "DESCRIPTION": "4110004069036 SENSOR",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4110003380016,
      "DESCRIPTION": "4110003380090 SENSOR",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4041002225,
      "DESCRIPTION": "HOSE ASSEMBLY",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 17410989,
      "DESCRIPTION": "BRAKE KIT",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 4,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 60110391,
      "DESCRIPTION": "PLAIN WASHER",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 5,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 997473,
      "DESCRIPTION": "HEXAGON SCREW 978943 992062",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 64,
      "WHXX": 39,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14729117,
      "DESCRIPTION": "WASHER PUMP 14629274",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 2,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4011001299,
      "DESCRIPTION": "SCREW",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 1,
      "SHELF LIFE": 3.000000000000003,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 1.10940039245045,
      "MEAN": 0.307692307692307,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 3.000000000000003
    },
    {
      "PART NUMBER": 5223285701,
      "DESCRIPTION": "OIL LEVEL SENSOR",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4120001004007,
      "DESCRIPTION": "SEALING RING KIT",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 0,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": 1001186466,
      "DESCRIPTION": "SENSOR,DUAL AXIS (0XC1)",
      "PRODUCT CATEGORY": "JLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14603022,
      "DESCRIPTION": "WASHER",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 12,
      "WHXX": 21,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 60220631,
      "DESCRIPTION": "PARALLEL LOWER CARRIER ROLLER",
      "PRODUCT CATEGORY": "YPG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 3.41666666666666,
      "MAINTANING STOCK QTY": 14,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 14,
      "APRD QTY": 14,
      "WH12": 11,
      "WHXX": 0,
      "WH REFERENCE": "PARTIAL WH12",
      "TREND": 2,
      "STD": 11.3713540226171,
      "MEAN": 3.15384615384615,
      "COV": 3.60555127546399,
      "SHELF LIFE_1": 4.097560975609764
    },
    {
      "PART NUMBER": "35C412",
      "DESCRIPTION": "BOLT",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 6631418,
      "DESCRIPTION": "RING, RETAINING",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 0,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": "4F6106",
      "DESCRIPTION": "PIN",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 0,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": "53D6",
      "DESCRIPTION": "NUT",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 0,
      "STD": 1.10940039245045,
      "MEAN": 0.307692307692307,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 3.000000000000003
    },
    {
      "PART NUMBER": 60358722,
      "DESCRIPTION": "FILTER ELEMENT",
      "PRODUCT CATEGORY": "YPG",
      "ITEM MOVEMENT BRANCH": "AVERAGE (F2)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.833333333333333,
      "MAINTANING STOCK QTY": 3,
      "AVAILABLE": 2,
      "SHELF LIFE": 2.400000000000001,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 0,
      "WHXX": 32,
      "WH REFERENCE": "WHXX",
      "TREND": 1.2,
      "STD": 0.832050294337843,
      "MEAN": 0.769230769230769,
      "COV": 1.08166538263919,
      "SHELF LIFE_1": 3.6000000000000014
    },
    {
      "PART NUMBER": 976945,
      "DESCRIPTION": "994849 WASHER",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 12,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 984993,
      "DESCRIPTION": "HOSE CLAMP ",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.666666666666666,
      "MAINTANING STOCK QTY": 3,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 3,
      "APRD QTY": 3,
      "WH12": 54,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 2,
      "STD": 2.21880078490091,
      "MEAN": 0.615384615384615,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 4.500000000000004
    },
    {
      "PART NUMBER": "9131-11600",
      "DESCRIPTION": "LOCK NUT_HEX SE",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 0,
      "WHXX": 8,
      "WH REFERENCE": "WHXX",
      "TREND": 2,
      "STD": 1.10940039245045,
      "MEAN": 0.307692307692307,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 3.000000000000003
    },
    {
      "PART NUMBER": 14708503,
      "DESCRIPTION": "PIN",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.5,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 2,
      "APRD QTY": 2,
      "WH12": 0,
      "WHXX": 2,
      "WH REFERENCE": "WHXX",
      "TREND": 2,
      "STD": 1.66410058867568,
      "MEAN": 0.461538461538461,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 4
    },
    {
      "PART NUMBER": 14708505,
      "DESCRIPTION": "BUSHING",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.5,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 2,
      "APRD QTY": 2,
      "WH12": 0,
      "WHXX": 1,
      "WH REFERENCE": "PARTIAL WHXX",
      "TREND": 2,
      "STD": 1.66410058867568,
      "MEAN": 0.461538461538461,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 4
    },
    {
      "PART NUMBER": 14723460,
      "DESCRIPTION": "SEAL 14530367",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 1.66666666666666,
      "MAINTANING STOCK QTY": 7,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 7,
      "APRD QTY": 7,
      "WH12": 5,
      "WHXX": 31,
      "WH REFERENCE": "WHXX",
      "TREND": 1.8,
      "STD": 3.92885446865844,
      "MEAN": 1.53846153846153,
      "COV": 2.55375540462798,
      "SHELF LIFE_1": 4.200000000000016
    },
    {
      "PART NUMBER": 17473102,
      "DESCRIPTION": "PIN 21",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 2.08333333333333,
      "MAINTANING STOCK QTY": 8,
      "AVAILABLE": 18,
      "SHELF LIFE": 8.640000000000015,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 381,
      "WHXX": 141,
      "WH REFERENCE": "",
      "TREND": 1.2,
      "STD": 2.53184841770916,
      "MEAN": 1.92307692307692,
      "COV": 1.31656117720876,
      "SHELF LIFE_1": 8.640000000000015
    },
    {
      "PART NUMBER": 17473106,
      "DESCRIPTION": "PIN 66",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 7.5,
      "MAINTANING STOCK QTY": 30,
      "AVAILABLE": 26,
      "SHELF LIFE": 3.466666666666667,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 4,
      "APRD QTY": 0,
      "WH12": 41,
      "WHXX": 55,
      "WH REFERENCE": "WHXX",
      "TREND": 0.93,
      "STD": 12.3184248077256,
      "MEAN": 6.92307692307692,
      "COV": 1.77932802778258,
      "SHELF LIFE_1": 4
    },
    {
      "PART NUMBER": 17493106,
      "DESCRIPTION": "RETAINER 66",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.666666666666666,
      "MAINTANING STOCK QTY": 3,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 3,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 6,
      "WH REFERENCE": "WHXX",
      "TREND": 0.25,
      "STD": 1.93814608747902,
      "MEAN": 0.615384615384615,
      "COV": 3.14948739215341,
      "SHELF LIFE_1": 4.500000000000004
    },
    {
      "PART NUMBER": 14732381,
      "DESCRIPTION": "TOOTH, NEW VTS GPE 21",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 2.08333333333333,
      "MAINTANING STOCK QTY": 8,
      "AVAILABLE": 8,
      "SHELF LIFE": 3.840000000000006,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 146,
      "WHXX": 61,
      "WH REFERENCE": "",
      "TREND": 1.2,
      "STD": 2.53184841770916,
      "MEAN": 1.92307692307692,
      "COV": 1.31656117720876,
      "SHELF LIFE_1": 3.840000000000006
    },
    {
      "PART NUMBER": 14732401,
      "DESCRIPTION": "TOOTH, NEW VTS GPE 66",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "AVERAGE (F2)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 7.5,
      "MAINTANING STOCK QTY": 30,
      "AVAILABLE": 26,
      "SHELF LIFE": 3.466666666666667,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 4,
      "APRD QTY": 0,
      "WH12": 310,
      "WHXX": 4,
      "WH REFERENCE": "WHXX",
      "TREND": 0.8,
      "STD": 11.7577034213144,
      "MEAN": 6.92307692307692,
      "COV": 1.69833493863431,
      "SHELF LIFE_1": 4
    },
    {
      "PART NUMBER": 14732402,
      "DESCRIPTION": "TOOTH, NEW VTS ARXE 66",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 6,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 27,
      "WHXX": 8,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14672731,
      "DESCRIPTION": "PROPELLER SHAFT",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 1,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 23417523,
      "DESCRIPTION": "CYLINDER ",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 11,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14737832,
      "DESCRIPTION": "PISTON 14533163 14532283",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 1,
      "SHELF LIFE": 6.000000000000024,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3,
      "WHXX": 4,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": 14730968,
      "DESCRIPTION": "14675561 SEALING KIT, BOOM CYL",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 5,
      "WHXX": 2,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 997882,
      "DESCRIPTION": "HEX. SOCKET SCREW",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 3,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 42,
      "WHXX": 5,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 6710659,
      "DESCRIPTION": "BUSHING",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 0,
      "STD": 1.10940039245045,
      "MEAN": 0.307692307692307,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 3.000000000000003
    },
    {
      "PART NUMBER": 6713589,
      "DESCRIPTION": "CLEVIS",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 0,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": 6713887,
      "DESCRIPTION": "BOLT",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 0,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": 22281893,
      "DESCRIPTION": "V-RIBBED BELT",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.416666666666666,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 2,
      "APRD QTY": 0,
      "WH12": 7,
      "WHXX": 10,
      "WH REFERENCE": "WHXX",
      "TREND": 0.8,
      "STD": 0.65044363558799,
      "MEAN": 0.384615384615384,
      "COV": 1.69115345252877,
      "SHELF LIFE_1": 4.800000000000008
    },
    {
      "PART NUMBER": 4110003229003,
      "DESCRIPTION": "ALTERNATOR",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.416666666666666,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 2,
      "APRD QTY": 2,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 1.6,
      "STD": 0.960768922830522,
      "MEAN": 0.384615384615384,
      "COV": 2.49799919935935,
      "SHELF LIFE_1": 4.800000000000008
    },
    {
      "PART NUMBER": 992454,
      "DESCRIPTION": "FUSE",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 47,
      "WHXX": 8,
      "WH REFERENCE": "WHXX",
      "TREND": 1,
      "STD": 0.375533808099405,
      "MEAN": 0.153846153846153,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": 23491400,
      "DESCRIPTION": "CLUTCH 20806454",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 997408,
      "DESCRIPTION": "HEXAGON NUT 983304 ",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 1.33333333333333,
      "MAINTANING STOCK QTY": 5,
      "AVAILABLE": 5,
      "SHELF LIFE": 3.7500000000000098,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 12,
      "WHXX": 55,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 4.43760156980183,
      "MEAN": 1.23076923076923,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 3.7500000000000098
    },
    {
      "PART NUMBER": "430739Z00A ",
      "DESCRIPTION": "PLUG",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 7.08333333333333,
      "MAINTANING STOCK QTY": 28,
      "AVAILABLE": 8,
      "SHELF LIFE": 1.1294117647058828,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 20,
      "APRD QTY": 20,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 1.88,
      "STD": 22.1156633204289,
      "MEAN": 6.53846153846153,
      "COV": 3.38239556665383,
      "SHELF LIFE_1": 3.9529411764705897
    },
    {
      "PART NUMBER": "432469Z00A",
      "DESCRIPTION": "430739Z00A PLUG",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 1.16666666666666,
      "MAINTANING STOCK QTY": 5,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 5,
      "APRD QTY": 5,
      "WH12": 0,
      "WHXX": 36,
      "WH REFERENCE": "WHXX",
      "TREND": 1.43,
      "STD": 2.90004420832793,
      "MEAN": 1.07692307692307,
      "COV": 2.69289819344737,
      "SHELF LIFE_1": 4.28571428571431
    },
    {
      "PART NUMBER": 14884939,
      "DESCRIPTION": "NUT 9112-13001",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 1,
      "MAINTANING STOCK QTY": 4,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 4,
      "APRD QTY": 0,
      "WH12": 34,
      "WHXX": 5,
      "WH REFERENCE": "WHXX",
      "TREND": 1,
      "STD": 2.25320284859643,
      "MEAN": 0.923076923076923,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 4
    },
    {
      "PART NUMBER": 11203464,
      "DESCRIPTION": "SIDE WINDOW",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 2,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4130039,
      "DESCRIPTION": "HELISERT COIL :SB081,100,121,SB,SU+55,85,105",
      "PRODUCT CATEGORY": "SOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 4,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4110000591035,
      "DESCRIPTION": "NUT 411000054332 ",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 13,
      "WHXX": 6,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14573909,
      "DESCRIPTION": "DIPPER ARM CYLINDER",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14726295,
      "DESCRIPTION": "FUSE BOX",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 2,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4043000467,
      "DESCRIPTION": "BUSHING",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 4,
      "WHXX": 1,
      "WH REFERENCE": "WHXX",
      "TREND": 0,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": 14645434,
      "DESCRIPTION": "ALTERNATOR",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14725312,
      "DESCRIPTION": "SHIFT UNIT",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14612972,
      "DESCRIPTION": "HOSE ASSEMBLY",
      "PRODUCT CATEGORY": "VOH",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 5223229028,
      "DESCRIPTION": "5224222120¬† WHEEL NUT ",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 1.66666666666666,
      "MAINTANING STOCK QTY": 7,
      "AVAILABLE": 8,
      "SHELF LIFE": 4.800000000000019,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 5,
      "WHXX": 32,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 5.54700196225229,
      "MEAN": 1.53846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 4.800000000000019
    },
    {
      "PART NUMBER": "967110Z000",
      "DESCRIPTION": "HINGE",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 5,
      "WH REFERENCE": "WHXX",
      "TREND": 0,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": 4110000555174,
      "DESCRIPTION": "WATER PUMP",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 5223726004,
      "DESCRIPTION": "SENSOR",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 10,
      "WHXX": 4,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "967120Z000",
      "DESCRIPTION": "HINGE",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 13,
      "WHXX": 5,
      "WH REFERENCE": "WHXX",
      "TREND": 0,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": 14560255,
      "DESCRIPTION": "WASHER",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14560256,
      "DESCRIPTION": "WASHER",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 6634631,
      "DESCRIPTION": "LOCK RING",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4110000367018,
      "DESCRIPTION": "BEARING",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 2,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4120004492,
      "DESCRIPTION": "FILTER INSERT",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 67,
      "WHXX": 25,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4120005390,
      "DESCRIPTION": "FILTER INSERT",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 35,
      "WHXX": 7,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 16940716,
      "DESCRIPTION": "GAS SPRING",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 6,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 12769848,
      "DESCRIPTION": "GEAR PUMP",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 29220020701,
      "DESCRIPTION": "AIR TANK",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": 5222747762,
      "DESCRIPTION": "GASKET",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 8,
      "WHXX": 24,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14531020,
      "DESCRIPTION": "PIN",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "84G3712",
      "DESCRIPTION": "BOLT 5",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 1.08333333333333,
      "MAINTANING STOCK QTY": 4,
      "AVAILABLE": 1,
      "SHELF LIFE": 0.923076923076926,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 3,
      "APRD QTY": 0,
      "WH12": 5,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 0,
      "STD": 3.60555127546398,
      "MEAN": 1,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 3.692307692307704
    },
    {
      "PART NUMBER": 7168173,
      "DESCRIPTION": "BRACKET COVER",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 5222746873,
      "DESCRIPTION": "EXHAUST PIPE",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 5222794568,
      "DESCRIPTION": "EXHAUST PIPE",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 10071378,
      "DESCRIPTION": "Q500A SEALING ELEMENT PACKAGE",
      "PRODUCT CATEGORY": "YCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 7,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 29050027111,
      "DESCRIPTION": "FLANGE",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 17500365,
      "DESCRIPTION": "MIRROR 11121366",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 2,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 5220745892,
      "DESCRIPTION": "MOUNTING STRAP",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.25,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 7,
      "WHXX": 5,
      "WH REFERENCE": "WHXX",
      "TREND": 0,
      "STD": 0.832050294337843,
      "MEAN": 0.23076923076923,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 4
    },
    {
      "PART NUMBER": 4130002901,
      "DESCRIPTION": "TAIL LIGHT",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 22658275,
      "DESCRIPTION": "RUBBER MOULDING",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 21426987,
      "DESCRIPTION": "SPEED SENSOR",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 1,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 2,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4110000367131,
      "DESCRIPTION": "RUBBER WASHER",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 5,
      "WHXX": 3,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 29050019331,
      "DESCRIPTION": "WASHER",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 0,
      "WHXX": 2,
      "WH REFERENCE": "WHXX",
      "TREND": 2,
      "STD": 1.10940039245045,
      "MEAN": 0.307692307692307,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 3.000000000000003
    },
    {
      "PART NUMBER": 14733381,
      "DESCRIPTION": "SEALING KIT",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 1,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 17325561,
      "DESCRIPTION": "PRESSURE SENSOR",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 17287189,
      "DESCRIPTION": "SEALING KIT EW145B",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 1,
      "SHELF LIFE": 6.000000000000024,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 43,
      "WHXX": 11,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": 7325333,
      "DESCRIPTION": "KIT SEAL",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 8,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": 23307415,
      "DESCRIPTION": " VACUUM SWITCH 20565673 22996282",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 1,
      "SHELF LIFE": 12.000000000000005,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 12,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 12.000000000000005
    },
    {
      "PART NUMBER": 17208525,
      "DESCRIPTION": "V-RIBBED BELT 15078690 ",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 1,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3,
      "WHXX": 2,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14731469,
      "DESCRIPTION": "IDLER, ENDURANCE",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 2,
      "SHELF LIFE": 24.00000000000001,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 20,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 24.00000000000001
    },
    {
      "PART NUMBER": 14723426,
      "DESCRIPTION": "SPROCKET ENDURANCE",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 15,
      "WHXX": 1,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 22306614,
      "DESCRIPTION": "OIL FILLER PIPE          ",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 4,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 982558,
      "DESCRIPTION": "BULB 21W ",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 18,
      "WHXX": 8,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14749664,
      "DESCRIPTION": "53807004 CONDENSER 14591537 ",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 17511983,
      "DESCRIPTION": "VOLVO SYNTHETIC DRUM ECCENTRIC OIL",
      "PRODUCT CATEGORY": "LUV",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 19,
      "MAINTANING STOCK QTY": 76,
      "AVAILABLE": 292,
      "SHELF LIFE": 15.368421052631579,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 4320,
      "WHXX": 288,
      "WH REFERENCE": "",
      "TREND": 0.25,
      "STD": 55.3663787639745,
      "MEAN": 17.5384615384615,
      "COV": 3.15685492952486,
      "SHELF LIFE_1": 15.368421052631579
    },
    {
      "PART NUMBER": 23657265,
      "DESCRIPTION": "COOLANT PUMP D6E/D4E 21247955 21125771",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 17,
      "SHELF LIFE": 102.00000000000041,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 4,
      "WH REFERENCE": "",
      "TREND": 1,
      "STD": 0.375533808099405,
      "MEAN": 0.153846153846153,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 102.00000000000041
    },
    {
      "PART NUMBER": 21795915,
      "DESCRIPTION": "V-BELT PULLEY",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.416666666666666,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 4,
      "SHELF LIFE": 9.600000000000016,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 16,
      "WH REFERENCE": "",
      "TREND": 0.8,
      "STD": 0.506369683541833,
      "MEAN": 0.384615384615384,
      "COV": 1.31656117720876,
      "SHELF LIFE_1": 9.600000000000016
    },
    {
      "PART NUMBER": 997953,
      "DESCRIPTION": "HEXAGON SCREW 96718887 96703111 983720 96702329 993029",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.666666666666666,
      "MAINTANING STOCK QTY": 3,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 3,
      "APRD QTY": 0,
      "WH12": 179,
      "WHXX": 877,
      "WH REFERENCE": "WHXX",
      "TREND": 0,
      "STD": 2.21880078490091,
      "MEAN": 0.615384615384615,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 4.500000000000004
    },
    {
      "PART NUMBER": 22952495,
      "DESCRIPTION": "U-BOLT ",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 63,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 2,
      "STD": 1.10940039245045,
      "MEAN": 0.307692307692307,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 3.000000000000003
    },
    {
      "PART NUMBER": "160603020014A",
      "DESCRIPTION": "FUEL-WATER FILTER",
      "PRODUCT CATEGORY": "YCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 12,
      "WHXX": 1,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 1001225432,
      "DESCRIPTION": "FILTER, FUEL ELEMENT 70024962",
      "PRODUCT CATEGORY": "JLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 26350003331,
      "DESCRIPTION": "ADJUSTING BAR",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.25,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 2,
      "STD": 0.599144689515278,
      "MEAN": 0.23076923076923,
      "COV": 2.5962936545662,
      "SHELF LIFE_1": 4
    },
    {
      "PART NUMBER": 4110001680002,
      "DESCRIPTION": "HANDLE",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 5,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14638977,
      "DESCRIPTION": "OIL COOLER 14518315",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14739343,
      "DESCRIPTION": "NUT",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 21934272,
      "DESCRIPTION": "HOSE                     ",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": 14749667,
      "DESCRIPTION": "53806996 CONDENSER 14602245 14645543",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": 14560261,
      "DESCRIPTION": "RING",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 16900698,
      "DESCRIPTION": "COOLANT PUMP",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 2,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 17522218,
      "DESCRIPTION": "O-RING",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 6,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 5223686649,
      "DESCRIPTION": "SEALING RING",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 6,
      "WHXX": 1,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 29250017701,
      "DESCRIPTION": "SHIM",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 1,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 29250017711,
      "DESCRIPTION": "SHIM",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 1,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 29250017721,
      "DESCRIPTION": "SHIM",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 21244698,
      "DESCRIPTION": "RELAY 14591812 ",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.25,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 1,
      "SHELF LIFE": 4,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 34,
      "WHXX": 7,
      "WH REFERENCE": "",
      "TREND": 1.33,
      "STD": 0.599144689515278,
      "MEAN": 0.23076923076923,
      "COV": 2.5962936545662,
      "SHELF LIFE_1": 4
    },
    {
      "PART NUMBER": 7137769,
      "DESCRIPTION": "KIT, SEAL",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14762630,
      "DESCRIPTION": "ROLLER 14683330 ",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 5260112798,
      "DESCRIPTION": "SPRING WASHER",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 2.83333333333333,
      "MAINTANING STOCK QTY": 11,
      "AVAILABLE": 11,
      "SHELF LIFE": 3.8823529411764754,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 104,
      "WHXX": 20,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 8.30199234660433,
      "MEAN": 2.61538461538461,
      "COV": 3.17429119134871,
      "SHELF LIFE_1": 3.8823529411764754
    },
    {
      "PART NUMBER": 7283056,
      "DESCRIPTION": "SEAL HUB",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 11211325,
      "DESCRIPTION": "STRAINER",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 1,
      "MAINTANING STOCK QTY": 4,
      "AVAILABLE": 6,
      "SHELF LIFE": 6,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 184,
      "WHXX": 29,
      "WH REFERENCE": "",
      "TREND": 0.67,
      "STD": 1.89127551586834,
      "MEAN": 0.923076923076923,
      "COV": 2.04888180885737,
      "SHELF LIFE_1": 6
    },
    {
      "PART NUMBER": 4110002373023,
      "DESCRIPTION": "WATER PUMP",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14671837,
      "DESCRIPTION": "FUSE HOLDER",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 984996,
      "DESCRIPTION": "HOSE CLAMP",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.416666666666666,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 2,
      "APRD QTY": 2,
      "WH12": 25,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 2,
      "STD": 1.38675049056307,
      "MEAN": 0.384615384615384,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 4.800000000000008
    },
    {
      "PART NUMBER": 16891063,
      "DESCRIPTION": "ELBOW NIPPLE",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 7,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 2,
      "STD": 0.375533808099405,
      "MEAN": 0.153846153846153,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": 4120000560514,
      "DESCRIPTION": "RETAINING RING",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 6,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 936658,
      "DESCRIPTION": "HOSE ASSEMBLY",
      "PRODUCT CATEGORY": "VOH",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "TPWF2076",
      "DESCRIPTION": "WATER FILTER",
      "PRODUCT CATEGORY": "SDG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4110002230037,
      "DESCRIPTION": "BUSHING",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 9,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4013000382,
      "DESCRIPTION": "NUT",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.916666666666666,
      "MAINTANING STOCK QTY": 4,
      "AVAILABLE": 4,
      "SHELF LIFE": 4.363636363636367,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 22,
      "WHXX": 12,
      "WH REFERENCE": "",
      "TREND": 0.18,
      "STD": 2.7642405179408,
      "MEAN": 0.846153846153846,
      "COV": 3.26682970302095,
      "SHELF LIFE_1": 4.363636363636367
    },
    {
      "PART NUMBER": "324099Z00D",
      "DESCRIPTION": "REMOTE CONTROL",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 1,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 5223476572,
      "DESCRIPTION": "5223958455 OIL FILTER KIT 5223273542",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 17478948,
      "DESCRIPTION": "TANK UNIT 15010278",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 2,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 3,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14672980,
      "DESCRIPTION": "MIRROR 14634766 14708934 14564772",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 30,
      "WHXX": 35,
      "WH REFERENCE": "WHXX",
      "TREND": 0.5,
      "STD": 0.85485041426511,
      "MEAN": 0.307692307692307,
      "COV": 2.7782638463616,
      "SHELF LIFE_1": 3.000000000000003
    },
    {
      "PART NUMBER": 14749665,
      "DESCRIPTION": "CONDENSER, A/C EC210B 14591539 14645946",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 4,
      "WHXX": 7,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14672981,
      "DESCRIPTION": "MIRROR",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14736879,
      "DESCRIPTION": "MUFFLER 14726098",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "A210111000091",
      "DESCRIPTION": "BOLT M8√Ó30",
      "PRODUCT CATEGORY": "YPG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.583333333333333,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 2,
      "APRD QTY": 2,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 2,
      "STD": 1.9414506867883,
      "MEAN": 0.538461538461538,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 3.42857142857143
    },
    {
      "PART NUMBER": "A210404000005",
      "DESCRIPTION": "WASHER 8GB961",
      "PRODUCT CATEGORY": "YPG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 1.16666666666666,
      "MAINTANING STOCK QTY": 5,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 5,
      "APRD QTY": 5,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 2,
      "STD": 3.8829013735766,
      "MEAN": 1.07692307692307,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 4.28571428571431
    },
    {
      "PART NUMBER": "B222100000288",
      "DESCRIPTION": "V2 DUSTER CARD POLYESTER FILTER CORE",
      "PRODUCT CATEGORY": "YPG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 2.33333333333333,
      "MAINTANING STOCK QTY": 9,
      "AVAILABLE": 9,
      "SHELF LIFE": 3.857142857142863,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 19,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 7.7658027471532,
      "MEAN": 2.15384615384615,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 3.857142857142863
    },
    {
      "PART NUMBER": 14675561,
      "DESCRIPTION": "SEALING KIT(BOOM CYL) 14730968",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 1,
      "SHELF LIFE": 6.000000000000024,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 28,
      "WHXX": 52,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": 7214545,
      "DESCRIPTION": "CABLE, THROTTLE",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 15018560,
      "DESCRIPTION": "WINDSHIELD",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 11205297,
      "DESCRIPTION": "SIDE WINDOW",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 3,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14767219,
      "DESCRIPTION": "TIPPING VALVE",
      "PRODUCT CATEGORY": "VTS",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4110000991047,
      "DESCRIPTION": "MAIN BEARING KIT",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4110001525009,
      "DESCRIPTION": "HOSE",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 1,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4120002274,
      "DESCRIPTION": "ACCUMULATOR",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4110003706001,
      "DESCRIPTION": "CUP",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 8,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14736786,
      "DESCRIPTION": "RADIATOR 17522415 17424334",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 2,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 60110032,
      "DESCRIPTION": "HEXAGON SCREW 978937",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 10,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 14,
      "WHXX": 83,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "29100001051A",
      "DESCRIPTION": "FILTER INSERT 29100001051",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "AVERAGE (F2)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 3.91666666666666,
      "MAINTANING STOCK QTY": 16,
      "AVAILABLE": 16,
      "SHELF LIFE": 4.08510638297873,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 78,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 1.49,
      "STD": 6.64001081247188,
      "MEAN": 3.61538461538461,
      "COV": 1.83659873536456,
      "SHELF LIFE_1": 4.08510638297873
    },
    {
      "PART NUMBER": 5223616626,
      "DESCRIPTION": "PRESSURE SENSOR",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 3,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 2,
      "STD": 0.375533808099405,
      "MEAN": 0.153846153846153,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": 14750655,
      "DESCRIPTION": "FILTER CARTRIDGE 14711980",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "AVERAGE (F2)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 1.08333333333333,
      "MAINTANING STOCK QTY": 4,
      "AVAILABLE": 4,
      "SHELF LIFE": 3.692307692307704,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 61,
      "WHXX": 26,
      "WH REFERENCE": "",
      "TREND": 1.08,
      "STD": 1,
      "MEAN": 1,
      "COV": 1,
      "SHELF LIFE_1": 3.692307692307704
    },
    {
      "PART NUMBER": 23605013,
      "DESCRIPTION": "PRESSURE PIPE 21363940",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3,
      "WHXX": 2,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14400779,
      "DESCRIPTION": "OIL FILTER 4110000727085 4110000509164",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "FAST (F1)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 6.5,
      "MAINTANING STOCK QTY": 26,
      "AVAILABLE": 46,
      "SHELF LIFE": 7.076923076923077,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 757,
      "WHXX": 77,
      "WH REFERENCE": "",
      "TREND": 0.92,
      "STD": 6.09644705272396,
      "MEAN": 6,
      "COV": 1.01607450878732,
      "SHELF LIFE_1": 7.076923076923077
    },
    {
      "PART NUMBER": 14675562,
      "DESCRIPTION": "SEALING KIT(ARM CYL) 14749759",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 8,
      "WHXX": 31,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14766277,
      "DESCRIPTION": "SEALING KIT(BUCKET CYL) 14730969",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 57,
      "WHXX": 45,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4110000322005,
      "DESCRIPTION": "INDICATOR",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 7414581,
      "DESCRIPTION": "FILTER HYDRAULIC WITH O-RINGS 7024037",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "AVERAGE (F2)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 1,
      "MAINTANING STOCK QTY": 4,
      "AVAILABLE": 9,
      "SHELF LIFE": 9,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 47,
      "WHXX": 4,
      "WH REFERENCE": "",
      "TREND": 0.5,
      "STD": 1.44115338424578,
      "MEAN": 0.923076923076923,
      "COV": 1.56124949959959,
      "SHELF LIFE_1": 9
    },
    {
      "PART NUMBER": 16813698,
      "DESCRIPTION": "FUEL TANK",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14743649,
      "DESCRIPTION": "BOTTOM ROLLER, ENDURANCE  EC200D 14708190",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 4,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 29250017691,
      "DESCRIPTION": "NUT",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 1001225430,
      "DESCRIPTION": "FUEL FILTER ELEMENT, PRIMARY ",
      "PRODUCT CATEGORY": "JLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3,
      "WHXX": 1,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 11217422,
      "DESCRIPTION": "TOOTH",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 1.25,
      "MAINTANING STOCK QTY": 5,
      "AVAILABLE": 5,
      "SHELF LIFE": 4,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0.67,
      "STD": 2.19264504826757,
      "MEAN": 1.15384615384615,
      "COV": 1.90029237516522,
      "SHELF LIFE_1": 4
    },
    {
      "PART NUMBER": 11213340,
      "DESCRIPTION": "PIN",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 1,
      "MAINTANING STOCK QTY": 4,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 4,
      "APRD QTY": 4,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 2,
      "STD": 1.89127551586834,
      "MEAN": 0.923076923076923,
      "COV": 2.04888180885737,
      "SHELF LIFE_1": 4
    },
    {
      "PART NUMBER": 11223287,
      "DESCRIPTION": "RETAINER",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 1,
      "MAINTANING STOCK QTY": 4,
      "AVAILABLE": 1,
      "SHELF LIFE": 1,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 3,
      "APRD QTY": 3,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 2,
      "STD": 1.89127551586834,
      "MEAN": 0.923076923076923,
      "COV": 2.04888180885737,
      "SHELF LIFE_1": 4
    },
    {
      "PART NUMBER": 5223071799,
      "DESCRIPTION": "REPAIR KIT",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 24,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 15045742,
      "DESCRIPTION": "DOOR WINDOW PANE",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4110002112,
      "DESCRIPTION": "V-BELT",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 5,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": 5200994875,
      "DESCRIPTION": "WASHER",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 20,
      "WHXX": 12,
      "WH REFERENCE": "WHXX",
      "TREND": 0,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": 14632907,
      "DESCRIPTION": "14660202 ALTERNATOR",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14619600,
      "DESCRIPTION": "SEAL",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 0,
      "STD": 1.10940039245045,
      "MEAN": 0.307692307692307,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 3.000000000000003
    },
    {
      "PART NUMBER": 997996,
      "DESCRIPTION": "HEXAGON SCREW 991646",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 5,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14717654,
      "DESCRIPTION": "CLAMP",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.833333333333333,
      "MAINTANING STOCK QTY": 3,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 3,
      "APRD QTY": 3,
      "WH12": 0,
      "WHXX": 14,
      "WH REFERENCE": "WHXX",
      "TREND": 2,
      "STD": 2.77350098112614,
      "MEAN": 0.769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 3.6000000000000014
    },
    {
      "PART NUMBER": 7286432,
      "DESCRIPTION": "DRIER, A/C ASSY",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 7021380,
      "DESCRIPTION": "RESISTOR",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 61018500,
      "DESCRIPTION": "DIESEL COARSE FILTER CORE",
      "PRODUCT CATEGORY": "YPG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.666666666666666,
      "MAINTANING STOCK QTY": 3,
      "AVAILABLE": 6,
      "SHELF LIFE": 9.000000000000009,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 85,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 1,
      "STD": 1.50213523239762,
      "MEAN": 0.615384615384615,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 9.000000000000009
    },
    {
      "PART NUMBER": 23344580,
      "DESCRIPTION": "AIR SPRING ",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 60284008,
      "DESCRIPTION": "60284007 EXPANSION VALVE",
      "PRODUCT CATEGORY": "YPG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 17210651,
      "DESCRIPTION": "SEALING KIT",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 7349796,
      "DESCRIPTION": "CAP CANISTER HYDRAULIC",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.25,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 1,
      "SHELF LIFE": 4,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 40,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.438529009653514,
      "MEAN": 0.23076923076923,
      "COV": 1.90029237516522,
      "SHELF LIFE_1": 4
    },
    {
      "PART NUMBER": 4110003115021,
      "DESCRIPTION": "4110001015023 TENSION PULLEY",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 13,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 2,
      "STD": 1.10940039245045,
      "MEAN": 0.307692307692307,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 3.000000000000003
    },
    {
      "PART NUMBER": 6680970,
      "DESCRIPTION": "BREATHER",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4110002230043,
      "DESCRIPTION": "NEEDLE BEARING",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 2,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 10,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 29270018131,
      "DESCRIPTION": "PIN",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 997471,
      "DESCRIPTION": "HEXAGON SCREW 983260",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 11,
      "WHXX": 38,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 60263371,
      "DESCRIPTION": "FUEL FILTER ASSY",
      "PRODUCT CATEGORY": "YTG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.666666666666666,
      "MAINTANING STOCK QTY": 3,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 3,
      "APRD QTY": 3,
      "WH12": 0,
      "WHXX": 1,
      "WH REFERENCE": "PARTIAL WHXX",
      "TREND": 0.5,
      "STD": 1.26085034391223,
      "MEAN": 0.615384615384615,
      "COV": 2.04888180885737,
      "SHELF LIFE_1": 4.500000000000004
    },
    {
      "PART NUMBER": 60358720,
      "DESCRIPTION": "OIL WATER SEPARATOR ASSY",
      "PRODUCT CATEGORY": "YTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 8,
      "WHXX": 20,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 61009978,
      "DESCRIPTION": "CLUTCH",
      "PRODUCT CATEGORY": "YTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 60263487,
      "DESCRIPTION": "FUEL FILTER",
      "PRODUCT CATEGORY": "YTG",
      "ITEM MOVEMENT BRANCH": "AVERAGE (F2)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 1.66666666666666,
      "MAINTANING STOCK QTY": 7,
      "AVAILABLE": 7,
      "SHELF LIFE": 4.200000000000016,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 101,
      "WHXX": 6,
      "WH REFERENCE": "",
      "TREND": 0.8,
      "STD": 2.96128870075694,
      "MEAN": 1.53846153846153,
      "COV": 1.92483765549201,
      "SHELF LIFE_1": 4.200000000000016
    },
    {
      "PART NUMBER": 13782568,
      "DESCRIPTION": "POWER SUPPLY DEVICE",
      "PRODUCT CATEGORY": "YTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 61005716,
      "DESCRIPTION": "CLUTCH RELEASE BEARING",
      "PRODUCT CATEGORY": "YTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 22696301,
      "DESCRIPTION": "IDLER PULLEY 21766717",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.25,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 49,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 0,
      "STD": 0.832050294337843,
      "MEAN": 0.23076923076923,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 4
    },
    {
      "PART NUMBER": 22745698,
      "DESCRIPTION": "BRACKET",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 60126917,
      "DESCRIPTION": "MAIN CARTRIDGE OF AIR CLEANER",
      "PRODUCT CATEGORY": "YPG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 2,
      "SHELF LIFE": 6.000000000000006,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 11,
      "WHXX": 1,
      "WH REFERENCE": "",
      "TREND": 1,
      "STD": 0.75106761619881,
      "MEAN": 0.307692307692307,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 6.000000000000006
    },
    {
      "PART NUMBER": 60126914,
      "DESCRIPTION": "SAFETY CARTRIDGE OF AIR CLEANER",
      "PRODUCT CATEGORY": "YPG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 2,
      "SHELF LIFE": 6.000000000000006,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 30,
      "WHXX": 2,
      "WH REFERENCE": "",
      "TREND": 1,
      "STD": 0.75106761619881,
      "MEAN": 0.307692307692307,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 6.000000000000006
    },
    {
      "PART NUMBER": 70918479,
      "DESCRIPTION": "AIR FILTER",
      "PRODUCT CATEGORY": "VCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.25,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 0,
      "STD": 0.832050294337843,
      "MEAN": 0.23076923076923,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 4
    },
    {
      "PART NUMBER": 43918879,
      "DESCRIPTION": "RETURN FILTER",
      "PRODUCT CATEGORY": "VCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.5,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 2,
      "APRD QTY": 2,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 0,
      "STD": 1.66410058867568,
      "MEAN": 0.461538461538461,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 4
    },
    {
      "PART NUMBER": 14535803,
      "DESCRIPTION": "BUSHING",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": 14566450,
      "DESCRIPTION": "BUSHING",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "115H-60-02000",
      "DESCRIPTION": "HYDRAULIC OIL RETURN FILTER, DH24",
      "PRODUCT CATEGORY": "SDG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 1.41666666666666,
      "MAINTANING STOCK QTY": 6,
      "AVAILABLE": 1,
      "SHELF LIFE": 0.7058823529411797,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 5,
      "APRD QTY": 5,
      "WH12": 13,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 0.12,
      "STD": 4.42313266408578,
      "MEAN": 1.3076923076923,
      "COV": 3.38239556665383,
      "SHELF LIFE_1": 4.235294117647078
    },
    {
      "PART NUMBER": 15714570,
      "DESCRIPTION": "FUEL FILTER 17270913 ",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 24,
      "WHXX": 3,
      "WH REFERENCE": "WHXX",
      "TREND": 1,
      "STD": 0.75106761619881,
      "MEAN": 0.307692307692307,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 3.000000000000003
    },
    {
      "PART NUMBER": "154-27-10006V030",
      "DESCRIPTION": "SPOCKET BOLT & NUT",
      "PRODUCT CATEGORY": "SDG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 42.5833333333333,
      "MAINTANING STOCK QTY": 170,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 170,
      "APRD QTY": 170,
      "WH12": 89,
      "WHXX": 0,
      "WH REFERENCE": "PARTIAL WH12",
      "TREND": 1.17,
      "STD": 86.2818874536487,
      "MEAN": 39.3076923076923,
      "COV": 2.19503823267599,
      "SHELF LIFE_1": 3.9921722113502964
    },
    {
      "PART NUMBER": 15714574,
      "DESCRIPTION": "OIL FILTER 43977461 17324107 ",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.25,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 11,
      "WHXX": 9,
      "WH REFERENCE": "WHXX",
      "TREND": 0.67,
      "STD": 0.438529009653514,
      "MEAN": 0.23076923076923,
      "COV": 1.90029237516522,
      "SHELF LIFE_1": 4
    },
    {
      "PART NUMBER": 997469,
      "DESCRIPTION": "980466 HEXAGON SCREW",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 0,
      "WHXX": 113,
      "WH REFERENCE": "WHXX",
      "TREND": 2,
      "STD": 1.10940039245045,
      "MEAN": 0.307692307692307,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 3.000000000000003
    },
    {
      "PART NUMBER": 14703817,
      "DESCRIPTION": "DOOR CATCH 14376506 ",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 1,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 4,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 907814,
      "DESCRIPTION": "14880451 SPLIT PIN",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 2,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 8,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 15711010,
      "DESCRIPTION": "FUEL FILTER 16668474",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 17,
      "WHXX": 6,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 22935402,
      "DESCRIPTION": "U-BOLT ",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 1,
      "MAINTANING STOCK QTY": 4,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 4,
      "APRD QTY": 4,
      "WH12": 2,
      "WHXX": 0,
      "WH REFERENCE": "PARTIAL WH12",
      "TREND": 2,
      "STD": 3.32820117735137,
      "MEAN": 0.923076923076923,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 4
    },
    {
      "PART NUMBER": 22422785,
      "DESCRIPTION": "SENSOR 21097978 22329559",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 2,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 5,
      "WHXX": 1,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4110003478017,
      "DESCRIPTION": "FUEL FILTER ASSY",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 1,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 20,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14550630,
      "DESCRIPTION": "ROD",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 20514313,
      "DESCRIPTION": "SWITCH",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 1,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 15048941,
      "DESCRIPTION": "FLASHER RELAY 11170048 11039244",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 3,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 1,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 17212421,
      "DESCRIPTION": "SOLENOID VALVE",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.833333333333333,
      "MAINTANING STOCK QTY": 3,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 3,
      "APRD QTY": 3,
      "WH12": 0,
      "WHXX": 12,
      "WH REFERENCE": "WHXX",
      "TREND": 2,
      "STD": 2.77350098112614,
      "MEAN": 0.769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 3.6000000000000014
    },
    {
      "PART NUMBER": 14676340,
      "DESCRIPTION": "53489684 TOP ROLLER",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": 14717289,
      "DESCRIPTION": "BOTTOM ROLLER 14602330",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 1.66666666666666,
      "MAINTANING STOCK QTY": 7,
      "AVAILABLE": 4,
      "SHELF LIFE": 2.4000000000000097,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 3,
      "APRD QTY": 3,
      "WH12": 30,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 2,
      "STD": 5.54700196225229,
      "MEAN": 1.53846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 4.200000000000016
    },
    {
      "PART NUMBER": 29250018651,
      "DESCRIPTION": "SHIM",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 28,
      "WHXX": 4,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 29250001211,
      "DESCRIPTION": "LUBRICATING PIPE",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14573784,
      "DESCRIPTION": "PLUG",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14745995,
      "DESCRIPTION": "DIAPHRAGM 17216250 ",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 5,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 61020095,
      "DESCRIPTION": "AIR CLEANER FILTER (OUTER)",
      "PRODUCT CATEGORY": "YCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 14,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4120006629,
      "DESCRIPTION": "STEERING CYLINDER",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": "TP612600090816",
      "DESCRIPTION": "ALTERNATOR DH24",
      "PRODUCT CATEGORY": "SDG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "TP1000422382",
      "DESCRIPTION": "FUEL FINE FILTER DH24",
      "PRODUCT CATEGORY": "SDG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 18,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 41,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "TP1000964807",
      "DESCRIPTION": "FUEL COARSE FILTER DH24",
      "PRODUCT CATEGORY": "SDG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 7,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 2,
      "STD": 0.375533808099405,
      "MEAN": 0.153846153846153,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": "TP1000424655",
      "DESCRIPTION": "OIL FILTER DH24",
      "PRODUCT CATEGORY": "SDG",
      "ITEM MOVEMENT BRANCH": "AVERAGE (F2)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 1.08333333333333,
      "MAINTANING STOCK QTY": 4,
      "AVAILABLE": 1,
      "SHELF LIFE": 0.923076923076926,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 3,
      "APRD QTY": 3,
      "WH12": 9,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 0,
      "STD": 3.60555127546398,
      "MEAN": 1,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 3.692307692307704
    },
    {
      "PART NUMBER": "114H-75-08200",
      "DESCRIPTION": "HYDRAULIC FILTER DH24",
      "PRODUCT CATEGORY": "SDG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 1.58333333333333,
      "MAINTANING STOCK QTY": 6,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 6,
      "APRD QTY": 6,
      "WH12": 19,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 0.11,
      "STD": 4.40861627224432,
      "MEAN": 1.46153846153846,
      "COV": 3.01642165995664,
      "SHELF LIFE_1": 3.7894736842105345
    },
    {
      "PART NUMBER": "TP1000424916",
      "DESCRIPTION": "FUEL WATER SEPARATOR DH24",
      "PRODUCT CATEGORY": "SDG",
      "ITEM MOVEMENT BRANCH": "AVERAGE (F2)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 3.5,
      "MAINTANING STOCK QTY": 14,
      "AVAILABLE": 4,
      "SHELF LIFE": 1.1428571428571428,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 10,
      "APRD QTY": 0,
      "WH12": 35,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 0,
      "STD": 8.92705481624862,
      "MEAN": 3.23076923076923,
      "COV": 2.76313601455314,
      "SHELF LIFE_1": 4
    },
    {
      "PART NUMBER": 23479847,
      "DESCRIPTION": "GASKET 20858564 ",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 11,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4130000415013,
      "DESCRIPTION": "4130000415004 RELAY",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 18,
      "WHXX": 1,
      "WH REFERENCE": "WHXX",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": 60113866,
      "DESCRIPTION": "WASHER 13960143 60113311 ",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 0,
      "WHXX": 13,
      "WH REFERENCE": "WHXX",
      "TREND": 2,
      "STD": 1.10940039245045,
      "MEAN": 0.307692307692307,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 3.000000000000003
    },
    {
      "PART NUMBER": 4110000991063,
      "DESCRIPTION": "SERVICE KIT",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 5223670578,
      "DESCRIPTION": "SHOCK ABSORBER ",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.416666666666666,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 2,
      "APRD QTY": 0,
      "WH12": 14,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 0,
      "STD": 1.38675049056307,
      "MEAN": 0.384615384615384,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 4.800000000000008
    },
    {
      "PART NUMBER": 22674901,
      "DESCRIPTION": "BELT TENSIONER ",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 3,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 0,
      "STD": 1.10940039245045,
      "MEAN": 0.307692307692307,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 3.000000000000003
    },
    {
      "PART NUMBER": 23236853,
      "DESCRIPTION": "FRONT SPRING ",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.833333333333333,
      "MAINTANING STOCK QTY": 3,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 3,
      "APRD QTY": 3,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 1.2,
      "STD": 1.48064435037847,
      "MEAN": 0.769230769230769,
      "COV": 1.92483765549201,
      "SHELF LIFE_1": 3.6000000000000014
    },
    {
      "PART NUMBER": 84479349,
      "DESCRIPTION": "FAN MOTOR ",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 4,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": 14359170,
      "DESCRIPTION": "FRONT WINDOW GLASS",
      "PRODUCT CATEGORY": "YPG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 2,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 60110039,
      "DESCRIPTION": "HEXAGON SCREW 13955419",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.666666666666666,
      "MAINTANING STOCK QTY": 3,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 3,
      "APRD QTY": 3,
      "WH12": 0,
      "WHXX": 15,
      "WH REFERENCE": "WHXX",
      "TREND": 2,
      "STD": 2.21880078490091,
      "MEAN": 0.615384615384615,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 4.500000000000004
    },
    {
      "PART NUMBER": 984752,
      "DESCRIPTION": "FLANGE SCREW 60113092 ",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 14,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 3537507,
      "DESCRIPTION": " O-RING",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 1,
      "MAINTANING STOCK QTY": 4,
      "AVAILABLE": 4,
      "SHELF LIFE": 4,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 28,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 3.32820117735137,
      "MEAN": 0.923076923076923,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 4
    },
    {
      "PART NUMBER": 3537521,
      "DESCRIPTION": " O-RING",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 4,
      "SHELF LIFE": 24.000000000000096,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3,
      "WHXX": 10,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 24.000000000000096
    },
    {
      "PART NUMBER": 70918495,
      "DESCRIPTION": "FILTER",
      "PRODUCT CATEGORY": "VCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.25,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 0,
      "STD": 0.832050294337843,
      "MEAN": 0.23076923076923,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 4
    },
    {
      "PART NUMBER": 20799728,
      "DESCRIPTION": "STUD 20405587",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 1,
      "MAINTANING STOCK QTY": 4,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 4,
      "APRD QTY": 4,
      "WH12": 0,
      "WHXX": 16,
      "WH REFERENCE": "WHXX",
      "TREND": 2,
      "STD": 3.32820117735137,
      "MEAN": 0.923076923076923,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 4
    },
    {
      "PART NUMBER": 14725651,
      "DESCRIPTION": "DOOR CATCH 14636279 ",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3,
      "WHXX": 3,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 7007302,
      "DESCRIPTION": "SWITCH ROCKER ",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "DOT4",
      "DESCRIPTION": "BRAKE FLUID 1161968 (1L) ",
      "PRODUCT CATEGORY": "LUP",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 6,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "ADBLUE20L",
      "DESCRIPTION": "ADBLUE - UREA SOLUTION ",
      "PRODUCT CATEGORY": "ZZY",
      "ITEM MOVEMENT BRANCH": "FAST (F1)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 1638.33333333333,
      "MAINTANING STOCK QTY": 6553,
      "AVAILABLE": 1580,
      "SHELF LIFE": 0.9643947100712125,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 4973,
      "APRD QTY": 4973,
      "WH12": 10248,
      "WHXX": 760,
      "WH REFERENCE": "WH12 + WHXX",
      "TREND": 0.74,
      "STD": 1367.12687686106,
      "MEAN": 1512.30769230769,
      "COV": 0.904000478087176,
      "SHELF LIFE_1": 3.999796541200415
    },
    {
      "PART NUMBER": 14762190,
      "DESCRIPTION": "CAB 14745861 14739507",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 2,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 17460844,
      "DESCRIPTION": "CAP",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3,
      "WHXX": 7,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 11708371,
      "DESCRIPTION": "LIMITED SLIP GEAR OIL SAE 85W-90LS API-GL5 15195533",
      "PRODUCT CATEGORY": "LUT",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 27.6666666666666,
      "MAINTANING STOCK QTY": 111,
      "AVAILABLE": 68,
      "SHELF LIFE": 2.4578313253012105,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 43,
      "APRD QTY": 40,
      "WH12": 177,
      "WHXX": 264,
      "WH REFERENCE": "WHXX",
      "TREND": 1.11,
      "STD": 47.1462536239013,
      "MEAN": 25.5384615384615,
      "COV": 1.84608824430938,
      "SHELF LIFE_1": 4.012048192771094
    },
    {
      "PART NUMBER": 984734,
      "DESCRIPTION": "FLANGE SCREW 13946173  60113080 ",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 1,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 69,
      "WHXX": 6,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 5223714921,
      "DESCRIPTION": "5224202070 SERVICE KIT 441029Z00A",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 1,
      "SHELF LIFE": 3.000000000000003,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 6,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 1.10940039245045,
      "MEAN": 0.307692307692307,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 3.000000000000003
    },
    {
      "PART NUMBER": 990989,
      "DESCRIPTION": "LOCK NUT 997395 983313 981314",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 22,
      "WHXX": 13,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 17533661,
      "DESCRIPTION": "OIL FILTER 21707133 (X8)",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "FAST (F1)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 10,
      "MAINTANING STOCK QTY": 40,
      "AVAILABLE": 15,
      "SHELF LIFE": 1.5,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 25,
      "APRD QTY": 25,
      "WH12": 197,
      "WHXX": 31,
      "WH REFERENCE": "WHXX",
      "TREND": 0.45,
      "STD": 8.72882052125644,
      "MEAN": 9.23076923076923,
      "COV": 0.945622223136115,
      "SHELF LIFE_1": 4
    },
    {
      "PART NUMBER": 14884680,
      "DESCRIPTION": "SCREW 9012-53021",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 3,
      "MAINTANING STOCK QTY": 12,
      "AVAILABLE": 8,
      "SHELF LIFE": 2.6666666666666665,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 4,
      "APRD QTY": 4,
      "WH12": 38,
      "WHXX": 20,
      "WH REFERENCE": "WHXX",
      "TREND": 1.67,
      "STD": 6.75960854578929,
      "MEAN": 2.76923076923076,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 4
    },
    {
      "PART NUMBER": "01700-065-0390-370-18GA",
      "DESCRIPTION": "PIN 65 X 390",
      "PRODUCT CATEGORY": "EIK",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": "037CAT315-PNR-EWT",
      "DESCRIPTION": "PIN & RETAINER 9J2308 / 8E6259",
      "PRODUCT CATEGORY": "EIK",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 2,
      "MAINTANING STOCK QTY": 8,
      "AVAILABLE": 3,
      "SHELF LIFE": 1.5,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 5,
      "APRD QTY": 5,
      "WH12": 113,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 1.33,
      "STD": 4.79315751612222,
      "MEAN": 1.84615384615384,
      "COV": 2.5962936545662,
      "SHELF LIFE_1": 4
    },
    {
      "PART NUMBER": "037CAT315-ADP-EWT",
      "DESCRIPTION": "ADAPTER 3G6304 3302",
      "PRODUCT CATEGORY": "EIK",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 64,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "037CAT315-TIP-EWT",
      "DESCRIPTION": "TIP 1U3302",
      "PRODUCT CATEGORY": "EIK",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 2,
      "MAINTANING STOCK QTY": 8,
      "AVAILABLE": 5,
      "SHELF LIFE": 2.5,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 3,
      "APRD QTY": 3,
      "WH12": 111,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 1.33,
      "STD": 4.79315751612222,
      "MEAN": 1.84615384615384,
      "COV": 2.5962936545662,
      "SHELF LIFE_1": 4
    },
    {
      "PART NUMBER": 17532113,
      "DESCRIPTION": "CONTROL PANEL 17201774 17444661",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4190000034,
      "DESCRIPTION": "VACUUM BOOSTER",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 4,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 21806167,
      "DESCRIPTION": "FUSE,  32V/23A",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 10,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 2,
      "STD": 1.10940039245045,
      "MEAN": 0.307692307692307,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 3.000000000000003
    },
    {
      "PART NUMBER": 23796624,
      "DESCRIPTION": "BASE PLATE 23796624",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 10,
      "WHXX": 1,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "160602020026A",
      "DESCRIPTION": "AIR FILTER ELEMENT",
      "PRODUCT CATEGORY": "YPG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 17535679,
      "DESCRIPTION": "OIL FILTER 17457469 3831236 (X10)",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "FAST (F1)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 29.6666666666666,
      "MAINTANING STOCK QTY": 119,
      "AVAILABLE": 10,
      "SHELF LIFE": 0.337078651685394,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 109,
      "APRD QTY": 109,
      "WH12": 252,
      "WHXX": 161,
      "WH REFERENCE": "WHXX",
      "TREND": 0.85,
      "STD": 15.8405516609453,
      "MEAN": 27.3846153846153,
      "COV": 0.578447111214294,
      "SHELF LIFE_1": 4.011235955056189
    },
    {
      "PART NUMBER": "168899001038A",
      "DESCRIPTION": "FILTER EJ200-1105140A YC",
      "PRODUCT CATEGORY": "YPG",
      "ITEM MOVEMENT BRANCH": "AVERAGE (F2)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.666666666666666,
      "MAINTANING STOCK QTY": 3,
      "AVAILABLE": 3,
      "SHELF LIFE": 4.500000000000004,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 37,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 1,
      "STD": 1.50213523239762,
      "MEAN": 0.615384615384615,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 4.500000000000004
    },
    {
      "PART NUMBER": "168899001032A",
      "DESCRIPTION": "OIL STRAINER ASSEMBLY",
      "PRODUCT CATEGORY": "YPG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 1,
      "SHELF LIFE": 3.000000000000003,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 16,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 1,
      "STD": 0.75106761619881,
      "MEAN": 0.307692307692307,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 3.000000000000003
    },
    {
      "PART NUMBER": "168899001037A",
      "DESCRIPTION": "FILTER EJ200-1105140A YC",
      "PRODUCT CATEGORY": "YPG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 67,
      "WHXX": 3,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 990973,
      "DESCRIPTION": "HEXAGON NUT 997411 979022",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 10,
      "WHXX": 4,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 7196450,
      "DESCRIPTION": "KIT SEAL",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14614556,
      "DESCRIPTION": "RECOIL SPRING EC750DL",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.416666666666666,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 2,
      "APRD QTY": 2,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 1.6,
      "STD": 0.869718492622904,
      "MEAN": 0.384615384615384,
      "COV": 2.26126808081955,
      "SHELF LIFE_1": 4.800000000000008
    },
    {
      "PART NUMBER": 60335271,
      "DESCRIPTION": "BELT,POLYWEDGE",
      "PRODUCT CATEGORY": "YPG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 15,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 49,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 160604020018,
      "DESCRIPTION": "DIESEL FINE FILTER CORE",
      "PRODUCT CATEGORY": "YPG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.25,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 1,
      "SHELF LIFE": 4,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 31,
      "WHXX": 1,
      "WH REFERENCE": "",
      "TREND": 1.33,
      "STD": 0.599144689515278,
      "MEAN": 0.23076923076923,
      "COV": 2.5962936545662,
      "SHELF LIFE_1": 4
    },
    {
      "PART NUMBER": 14732391,
      "DESCRIPTION": "TOOTH, NEW VTS GPE 41",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.5,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 2,
      "SHELF LIFE": 4,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 262,
      "WHXX": 4,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 1.66410058867568,
      "MEAN": 0.461538461538461,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 4
    },
    {
      "PART NUMBER": 17473104,
      "DESCRIPTION": "PIN 41",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.5,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 2,
      "SHELF LIFE": 4,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 541,
      "WHXX": 7,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 1.66410058867568,
      "MEAN": 0.461538461538461,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 4
    },
    {
      "PART NUMBER": 17493104,
      "DESCRIPTION": "RETAINER 41",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.5,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 2,
      "APRD QTY": 2,
      "WH12": 42,
      "WHXX": 11,
      "WH REFERENCE": "WHXX",
      "TREND": 2,
      "STD": 1.66410058867568,
      "MEAN": 0.461538461538461,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 4
    },
    {
      "PART NUMBER": "C61355",
      "DESCRIPTION": "UPPER BRASS RETAINER/SU+125/D205*L78",
      "PRODUCT CATEGORY": "SOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4120006629007,
      "DESCRIPTION": "SEALING KIT",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.666666666666666,
      "MAINTANING STOCK QTY": 3,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 3,
      "APRD QTY": 3,
      "WH12": 62,
      "WHXX": 2,
      "WH REFERENCE": "WH12 + WHXX",
      "TREND": 2,
      "STD": 2.21880078490091,
      "MEAN": 0.615384615384615,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 4.500000000000004
    },
    {
      "PART NUMBER": 23807658,
      "DESCRIPTION": "SENSOR 20482772",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 5,
      "WHXX": 3,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14696775,
      "DESCRIPTION": "ENGINE COOLING FAN",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4120016310001,
      "DESCRIPTION": "FILTER INSERT",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 50,
      "WHXX": 3,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "A229900003075",
      "DESCRIPTION": "ISOLATOR, TRANSMISSION MOUNT",
      "PRODUCT CATEGORY": "YCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 997475,
      "DESCRIPTION": "HEXAGON SCREW 978950",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 4.33333333333333,
      "MAINTANING STOCK QTY": 17,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 17,
      "APRD QTY": 17,
      "WH12": 20,
      "WHXX": 47,
      "WH REFERENCE": "WHXX",
      "TREND": 1.85,
      "STD": 9.52190457139046,
      "MEAN": 4,
      "COV": 2.38047614284761,
      "SHELF LIFE_1": 3.9230769230769256
    },
    {
      "PART NUMBER": "130699000043A",
      "DESCRIPTION": "DRIVEN DISC ASSEMBLY, CLUTCH",
      "PRODUCT CATEGORY": "YTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 5282685304,
      "DESCRIPTION": "FILTER",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 1.33333333333333,
      "MAINTANING STOCK QTY": 5,
      "AVAILABLE": 5,
      "SHELF LIFE": 3.7500000000000098,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 10,
      "WHXX": 13,
      "WH REFERENCE": "",
      "TREND": 1.25,
      "STD": 2.42053183391054,
      "MEAN": 1.23076923076923,
      "COV": 1.96668211505231,
      "SHELF LIFE_1": 3.7500000000000098
    },
    {
      "PART NUMBER": 14689355,
      "DESCRIPTION": "BLADE",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 5223237153,
      "DESCRIPTION": "HUB",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "006B12A-165-AAL",
      "DESCRIPTION": "BOLT ¬Æ‚ÄÙ X 61/2‚ÄÙ ",
      "PRODUCT CATEGORY": "EIK",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "007LN12A-AA",
      "DESCRIPTION": "LOCK NUT ¬Æ‚ÄÙ",
      "PRODUCT CATEGORY": "EIK",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 7,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "006B24M-080-AAH",
      "DESCRIPTION": "BOLT M24 X 80",
      "PRODUCT CATEGORY": "EIK",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 32,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "008SW24M-AA",
      "DESCRIPTION": "SPRING WASHER M24",
      "PRODUCT CATEGORY": "EIK",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 32,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "007N24M-AA",
      "DESCRIPTION": "NUT M24",
      "PRODUCT CATEGORY": "EIK",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 32,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "037EC200BCL-SC-EWT",
      "DESCRIPTION": "SIDE CUTTER (LEFT)",
      "PRODUCT CATEGORY": "EIK",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "037EC200BCR-SC-EWT",
      "DESCRIPTION": "SIDE CUTTER (RIGHT)",
      "PRODUCT CATEGORY": "EIK",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14699314,
      "DESCRIPTION": "SLIDING WINDOW",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 5223919366,
      "DESCRIPTION": "LATCH",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 70,
      "WHXX": 10,
      "WH REFERENCE": "WHXX",
      "TREND": 0,
      "STD": 1.10940039245045,
      "MEAN": 0.307692307692307,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 3.000000000000003
    },
    {
      "PART NUMBER": 46696269,
      "DESCRIPTION": "LAMP, 1050W MH SMALL",
      "PRODUCT CATEGORY": "DOP",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 14,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": 257653,
      "DESCRIPTION": "LEAF SPRING",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 5,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 95653614,
      "DESCRIPTION": "TOOTH ADAPTER",
      "PRODUCT CATEGORY": "VCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 0,
      "WHXX": 3,
      "WH REFERENCE": "WHXX",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": 11147716,
      "DESCRIPTION": "FITTING",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 0,
      "WHXX": 7,
      "WH REFERENCE": "WHXX",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": 5223976027,
      "DESCRIPTION": "TURN SIGNAL SWITCH 5223607332",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 2,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 17459093,
      "DESCRIPTION": "MIRROR",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4013000157,
      "DESCRIPTION": "LOCK NUT",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": 15714575,
      "DESCRIPTION": "OIL FILTER 17246027",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 13,
      "WHXX": 18,
      "WH REFERENCE": "WHXX",
      "TREND": 1,
      "STD": 0.75106761619881,
      "MEAN": 0.307692307692307,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 3.000000000000003
    },
    {
      "PART NUMBER": 14713404,
      "DESCRIPTION": "HOSE",
      "PRODUCT CATEGORY": "VOH",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14727790,
      "DESCRIPTION": "BUSHING 14512446",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 5223958455,
      "DESCRIPTION": "OIL FILTER KIT, 5223476572",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "AVERAGE (F2)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 2.66666666666666,
      "MAINTANING STOCK QTY": 11,
      "AVAILABLE": 7,
      "SHELF LIFE": 2.6250000000000067,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 4,
      "APRD QTY": 4,
      "WH12": 36,
      "WHXX": 14,
      "WH REFERENCE": "WHXX",
      "TREND": 0.69,
      "STD": 2.69615110281875,
      "MEAN": 2.46153846153846,
      "COV": 1.09531138552011,
      "SHELF LIFE_1": 4.125000000000011
    },
    {
      "PART NUMBER": 14750657,
      "DESCRIPTION": "FILTER CARTRIDGE 14744967 14532687",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "FAST (F1)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 5.16666666666666,
      "MAINTANING STOCK QTY": 21,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 21,
      "APRD QTY": 21,
      "WH12": 12,
      "WHXX": 0,
      "WH REFERENCE": "PARTIAL WH12",
      "TREND": 1,
      "STD": 3.56262651597212,
      "MEAN": 4.76923076923076,
      "COV": 0.747002333994155,
      "SHELF LIFE_1": 4.064516129032263
    },
    {
      "PART NUMBER": 14730964,
      "DESCRIPTION": "SEALING KIT 14589133",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 1,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 9,
      "WHXX": 3,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14771505,
      "DESCRIPTION": "CONNECTING ROD 14559439",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 29350010291,
      "DESCRIPTION": "COPPER TUBE",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 2,
      "STD": 0.75106761619881,
      "MEAN": 0.307692307692307,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 3.000000000000003
    },
    {
      "PART NUMBER": 5223714924,
      "DESCRIPTION": "5224202065  BRAKE KIT",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 17515206,
      "DESCRIPTION": "WATER SEPARATOR",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "AVERAGE (F2)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 3.41666666666666,
      "MAINTANING STOCK QTY": 14,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 14,
      "APRD QTY": 14,
      "WH12": 38,
      "WHXX": 30,
      "WH REFERENCE": "WHXX",
      "TREND": 1.07,
      "STD": 3.76045550977878,
      "MEAN": 3.15384615384615,
      "COV": 1.19233955188107,
      "SHELF LIFE_1": 4.097560975609764
    },
    {
      "PART NUMBER": 4190002741,
      "DESCRIPTION": "PIPE",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "160602020036A",
      "DESCRIPTION": "AIR FILTER, MAIN",
      "PRODUCT CATEGORY": "YTG",
      "ITEM MOVEMENT BRANCH": "AVERAGE (F2)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.583333333333333,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 1,
      "SHELF LIFE": 1.714285714285715,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 10,
      "WHXX": 5,
      "WH REFERENCE": "WHXX",
      "TREND": 0.57,
      "STD": 1.12660142429821,
      "MEAN": 0.538461538461538,
      "COV": 2.0922597879824,
      "SHELF LIFE_1": 3.42857142857143
    },
    {
      "PART NUMBER": 61021071,
      "DESCRIPTION": "WHEEL BOLT",
      "PRODUCT CATEGORY": "YTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.833333333333333,
      "MAINTANING STOCK QTY": 3,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 3,
      "APRD QTY": 0,
      "WH12": 87,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 0,
      "STD": 2.77350098112614,
      "MEAN": 0.769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 3.6000000000000014
    },
    {
      "PART NUMBER": 61021072,
      "DESCRIPTION": "WHEEL NUT",
      "PRODUCT CATEGORY": "YTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.833333333333333,
      "MAINTANING STOCK QTY": 3,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 3,
      "APRD QTY": 0,
      "WH12": 413,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 0,
      "STD": 2.77350098112614,
      "MEAN": 0.769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 3.6000000000000014
    },
    {
      "PART NUMBER": "141502000307A",
      "DESCRIPTION": "AIR CONDITIONING COMPRESSOR",
      "PRODUCT CATEGORY": "YTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 26,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "160602030026A",
      "DESCRIPTION": "AIR FILTER, SAFETY",
      "PRODUCT CATEGORY": "YTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 8,
      "WHXX": 8,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 13631416,
      "DESCRIPTION": "ASSEMBLY, LEFT PANEL REINFORCED PLATE",
      "PRODUCT CATEGORY": "YTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 17500251,
      "DESCRIPTION": "PRIMARY FILTER ",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 1.08333333333333,
      "MAINTANING STOCK QTY": 4,
      "AVAILABLE": 2,
      "SHELF LIFE": 1.846153846153852,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 2,
      "APRD QTY": 2,
      "WH12": 11,
      "WHXX": 14,
      "WH REFERENCE": "WHXX",
      "TREND": 0.46,
      "STD": 1.2909944487358,
      "MEAN": 1,
      "COV": 1.2909944487358,
      "SHELF LIFE_1": 3.692307692307704
    },
    {
      "PART NUMBER": 12445554,
      "DESCRIPTION": "ASSY, FRONT PANEL, REINFORCED PIPE",
      "PRODUCT CATEGORY": "YTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 17500253,
      "DESCRIPTION": "SAFETY FILTER",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 1,
      "SHELF LIFE": 3.000000000000003,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 7,
      "WHXX": 5,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.75106761619881,
      "MEAN": 0.307692307692307,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 3.000000000000003
    },
    {
      "PART NUMBER": 17500260,
      "DESCRIPTION": "PRIMARY FILTER",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 5,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 17500263,
      "DESCRIPTION": "SAFETY FILTER",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 12,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 17526239,
      "DESCRIPTION": "BOWL",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4110000970017,
      "DESCRIPTION": "FUEL PUMP 4110001841016",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 6,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14539108,
      "DESCRIPTION": "BRACKET",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14550492,
      "DESCRIPTION": "HOSE",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14741634,
      "DESCRIPTION": "HOSE",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 7400454,
      "DESCRIPTION": "FILTER FUEL",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 10,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 23690622,
      "DESCRIPTION": "SERVICE KIT( AIR DRIER FILTER)",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 1.16666666666666,
      "MAINTANING STOCK QTY": 5,
      "AVAILABLE": 6,
      "SHELF LIFE": 5.142857142857172,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 39,
      "WHXX": 1,
      "WH REFERENCE": "",
      "TREND": 1.57,
      "STD": 2.39657875806111,
      "MEAN": 1.07692307692307,
      "COV": 2.22539456105674,
      "SHELF LIFE_1": 5.142857142857172
    },
    {
      "PART NUMBER": 5223919397,
      "DESCRIPTION": "SLEEVE TUBE",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.666666666666666,
      "MAINTANING STOCK QTY": 3,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 3,
      "APRD QTY": 0,
      "WH12": 47,
      "WHXX": 30,
      "WH REFERENCE": "WHXX",
      "TREND": 0,
      "STD": 2.21880078490091,
      "MEAN": 0.615384615384615,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 4.500000000000004
    },
    {
      "PART NUMBER": 23974640,
      "DESCRIPTION": "24318489 COOLANT PUMP 21247955 21404502",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "AVERAGE (F2)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 2.41666666666666,
      "MAINTANING STOCK QTY": 10,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 10,
      "APRD QTY": 10,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 1.17,
      "STD": 1.96442723432922,
      "MEAN": 2.23076923076923,
      "COV": 0.880605311940686,
      "SHELF LIFE_1": 4.13793103448277
    },
    {
      "PART NUMBER": 60270562,
      "DESCRIPTION": "ASSEMBLY-OIL FILTER",
      "PRODUCT CATEGORY": "YCG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 2,
      "STD": 1.10940039245045,
      "MEAN": 0.307692307692307,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 3.000000000000003
    },
    {
      "PART NUMBER": 60034227,
      "DESCRIPTION": "OIL FILTER",
      "PRODUCT CATEGORY": "YCG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 1,
      "SHELF LIFE": 3.000000000000003,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 9,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 1.10940039245045,
      "MEAN": 0.307692307692307,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 3.000000000000003
    },
    {
      "PART NUMBER": 141502000076,
      "DESCRIPTION": "DESICCANT",
      "PRODUCT CATEGORY": "YTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 7,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "381669Z02E",
      "DESCRIPTION": "FLANGE SCREW",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.833333333333333,
      "MAINTANING STOCK QTY": 3,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 3,
      "APRD QTY": 0,
      "WH12": 5,
      "WHXX": 40,
      "WH REFERENCE": "WHXX",
      "TREND": 0,
      "STD": 2.77350098112614,
      "MEAN": 0.769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 3.6000000000000014
    },
    {
      "PART NUMBER": 7393870,
      "DESCRIPTION": "FILTER OIL ",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 1,
      "SHELF LIFE": 3.000000000000003,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 88,
      "WHXX": 1,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.75106761619881,
      "MEAN": 0.307692307692307,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 3.000000000000003
    },
    {
      "PART NUMBER": 5223670583,
      "DESCRIPTION": "SHOCK ABSORBER",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 1,
      "SHELF LIFE": 6.000000000000024,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 11,
      "WHXX": 2,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": 14788290,
      "DESCRIPTION": "SEAL",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4120004826,
      "DESCRIPTION": "BREATHER CAP",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 26,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": 4120004899,
      "DESCRIPTION": "FILTER INSERT",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.416666666666666,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 2,
      "APRD QTY": 2,
      "WH12": 88,
      "WHXX": 2,
      "WH REFERENCE": "WHXX",
      "TREND": 1.2,
      "STD": 0.767947647788304,
      "MEAN": 0.384615384615384,
      "COV": 1.99666388424959,
      "SHELF LIFE_1": 4.800000000000008
    },
    {
      "PART NUMBER": 4120005303003,
      "DESCRIPTION": "FILTER INSERT",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 1,
      "SHELF LIFE": 6.000000000000024,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 47,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": 14619311,
      "DESCRIPTION": "SEGMENT",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 6,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "542159Z01D",
      "DESCRIPTION": "PIN",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 1,
      "MAINTANING STOCK QTY": 4,
      "AVAILABLE": 2,
      "SHELF LIFE": 2,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 2,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 4,
      "WH REFERENCE": "WHXX",
      "TREND": 0.17,
      "STD": 2.75262112847429,
      "MEAN": 0.923076923076923,
      "COV": 2.98200622251381,
      "SHELF LIFE_1": 4
    },
    {
      "PART NUMBER": 5223890468,
      "DESCRIPTION": "CYLINDER",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3,
      "WHXX": 1,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4130000503,
      "DESCRIPTION": "ROCKER SWITCH",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 16678175,
      "DESCRIPTION": "GASKET",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 17,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 17271168,
      "DESCRIPTION": "O-RING",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 4,
      "WHXX": 17,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 16658928,
      "DESCRIPTION": "O-RING",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 9,
      "WHXX": 33,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 5223714925,
      "DESCRIPTION": "BRAKE SHOE KIT",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.416666666666666,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 2,
      "SHELF LIFE": 4.800000000000008,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 20,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0.4,
      "STD": 1.1208970766356,
      "MEAN": 0.384615384615384,
      "COV": 2.91433239925258,
      "SHELF LIFE_1": 4.800000000000008
    },
    {
      "PART NUMBER": 14712690,
      "DESCRIPTION": "FILTER",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 0,
      "WHXX": 11,
      "WH REFERENCE": "WHXX",
      "TREND": 0,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": 23658092,
      "DESCRIPTION": "OIL FILTER",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.75,
      "MAINTANING STOCK QTY": 3,
      "AVAILABLE": 42,
      "SHELF LIFE": 56,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 10,
      "WH REFERENCE": "",
      "TREND": 1.78,
      "STD": 1.70218562367207,
      "MEAN": 0.692307692307692,
      "COV": 2.45871256752633,
      "SHELF LIFE_1": 56
    },
    {
      "PART NUMBER": 997484,
      "DESCRIPTION": "HEXAGON SCREW 983696",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.583333333333333,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 2,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 9,
      "WH REFERENCE": "WHXX",
      "TREND": 0,
      "STD": 1.05003052458683,
      "MEAN": 0.538461538461538,
      "COV": 1.9500566885184,
      "SHELF LIFE_1": 3.42857142857143
    },
    {
      "PART NUMBER": 4110001475095,
      "DESCRIPTION": "FUEL FILTER INSERT 4110001475082",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 8,
      "SHELF LIFE": 48.00000000000019,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 161,
      "WHXX": 6,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 48.00000000000019
    },
    {
      "PART NUMBER": "SSL01200",
      "DESCRIPTION": "FRONT COVER",
      "PRODUCT CATEGORY": "SOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 5223813747,
      "DESCRIPTION": "HEADLAMP",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 5223658117,
      "DESCRIPTION": "OIL FILTER 5222771970 152009Z00A",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "AVERAGE (F2)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 6.08333333333333,
      "MAINTANING STOCK QTY": 24,
      "AVAILABLE": 19,
      "SHELF LIFE": 3.123287671232878,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 5,
      "APRD QTY": 5,
      "WH12": 186,
      "WHXX": 34,
      "WH REFERENCE": "WHXX",
      "TREND": 1.23,
      "STD": 7.365895075034,
      "MEAN": 5.61538461538461,
      "COV": 1.31173473938961,
      "SHELF LIFE_1": 3.945205479452057
    },
    {
      "PART NUMBER": 7322705,
      "DESCRIPTION": "TIRE HEAVY DUTY 10X16.5 10PLY",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "LF17535",
      "DESCRIPTION": "LUBE FILTER",
      "PRODUCT CATEGORY": "CUF",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 15184181,
      "DESCRIPTION": "JOINT",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 0,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": 29070015581,
      "DESCRIPTION": "ROUND NUT",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4110001554040,
      "DESCRIPTION": " THRUST RING 4110001554019 4110001554020",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 2,
      "STD": 1.10940039245045,
      "MEAN": 0.307692307692307,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 3.000000000000003
    },
    {
      "PART NUMBER": 11445396,
      "DESCRIPTION": "CHECK VALVE",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 1,
      "WH REFERENCE": "WHXX",
      "TREND": 0,
      "STD": 1.10940039245045,
      "MEAN": 0.307692307692307,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 3.000000000000003
    },
    {
      "PART NUMBER": 9014594707,
      "DESCRIPTION": "9014794925 ECU",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 7408600,
      "DESCRIPTION": "FILTER AIR INNER",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 13,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 7408601,
      "DESCRIPTION": "FILTER AIR OUTER",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.25,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 1,
      "SHELF LIFE": 4,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 6,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.599144689515278,
      "MEAN": 0.23076923076923,
      "COV": 2.5962936545662,
      "SHELF LIFE_1": 4
    },
    {
      "PART NUMBER": "SSL01118",
      "DESCRIPTION": "ROD, (D135) MOILPOINT SU+85",
      "PRODUCT CATEGORY": "SOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.416666666666666,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 2,
      "SHELF LIFE": 4.800000000000008,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 24,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.767947647788304,
      "MEAN": 0.384615384615384,
      "COV": 1.99666388424959,
      "SHELF LIFE_1": 4.800000000000008
    },
    {
      "PART NUMBER": 5223488073,
      "DESCRIPTION": "POWER TAKE-OFF",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "SSL01198",
      "DESCRIPTION": "FRONT COVER/GENERAL EX/SB70",
      "PRODUCT CATEGORY": "SOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14759225,
      "DESCRIPTION": "WIRING HARNESS",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14596256,
      "DESCRIPTION": "HOSE",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14720505,
      "DESCRIPTION": "14778019 AIR CONDITIONING UNIT",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14881135,
      "DESCRIPTION": "HOSE",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 70921614,
      "DESCRIPTION": "HARNESS CANOPY LAMPPAVER",
      "PRODUCT CATEGORY": "VCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 70860382,
      "DESCRIPTION": "GUARD",
      "PRODUCT CATEGORY": "VCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 70871884,
      "DESCRIPTION": "VIBRATION DAMPER",
      "PRODUCT CATEGORY": "VCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 96705249,
      "DESCRIPTION": "HEXAGON SCREW",
      "PRODUCT CATEGORY": "VCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 94327905,
      "DESCRIPTION": "PLAIN WASHER",
      "PRODUCT CATEGORY": "VCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 70908710,
      "DESCRIPTION": "SEALING KIT",
      "PRODUCT CATEGORY": "VCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 70908652,
      "DESCRIPTION": "SEALING KIT",
      "PRODUCT CATEGORY": "VCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 12780718,
      "DESCRIPTION": "TAIL LIGHT",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 12780719,
      "DESCRIPTION": "TAIL LIGHT",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 12834256,
      "DESCRIPTION": "LAMP",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 12820135,
      "DESCRIPTION": "WORK LAMP",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 12791508,
      "DESCRIPTION": "CLAMPING STRIP",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4110000909061,
      "DESCRIPTION": "HOSE",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 7184231,
      "DESCRIPTION": "VALVE, CONTROL SERVICE",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4130000627,
      "DESCRIPTION": "INDICATOR LAMP",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 5224014098,
      "DESCRIPTION": "FLANGE SCREW",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 15,
      "WH REFERENCE": "WHXX",
      "TREND": 0,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": 5224014097,
      "DESCRIPTION": "FLANGE SCREW",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 15,
      "WH REFERENCE": "WHXX",
      "TREND": 0,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": 5224014100,
      "DESCRIPTION": "NORD LOCK ANTI-LOOSEN WASHER",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 35,
      "WHXX": 31,
      "WH REFERENCE": "WHXX",
      "TREND": 0,
      "STD": 1.10940039245045,
      "MEAN": 0.307692307692307,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 3.000000000000003
    },
    {
      "PART NUMBER": 14644257,
      "DESCRIPTION": "BRACKET",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14521837,
      "DESCRIPTION": "SPACER",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 28120005471,
      "DESCRIPTION": "GASKET",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4110003906,
      "DESCRIPTION": "WATER SEPARATOR",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4110004069036,
      "DESCRIPTION": "SENSOR 4110003380015",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3,
      "WHXX": 3,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4011373,
      "DESCRIPTION": "SOCKET BOLT : SU+85,105",
      "PRODUCT CATEGORY": "SOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 4,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 11220925,
      "DESCRIPTION": "FILTER NETTING",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.666666666666666,
      "MAINTANING STOCK QTY": 3,
      "AVAILABLE": 64,
      "SHELF LIFE": 96.0000000000001,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 217,
      "WHXX": 48,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 2.21880078490091,
      "MEAN": 0.615384615384615,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 96.0000000000001
    },
    {
      "PART NUMBER": 5223683591,
      "DESCRIPTION": "SHOCK ABSORBER",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 2,
      "SHELF LIFE": 12.000000000000048,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 5,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 12.000000000000048
    },
    {
      "PART NUMBER": 5223763956,
      "DESCRIPTION": "HEADLAMP LH",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 5223763958,
      "DESCRIPTION": "HEADLAMP RH",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 5223891584,
      "DESCRIPTION": "WIRES",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 1,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 9,
      "WHXX": 21,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14638550,
      "DESCRIPTION": "BRACKET 14641567",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 23235621,
      "DESCRIPTION": "ARM",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 26,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 7135536,
      "DESCRIPTION": "SEAL KIT",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 6568974,
      "DESCRIPTION": "KIT, AXLE SEAL",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.666666666666666,
      "MAINTANING STOCK QTY": 3,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 3,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 0,
      "STD": 2.21880078490091,
      "MEAN": 0.615384615384615,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 4.500000000000004
    },
    {
      "PART NUMBER": 3974866,
      "DESCRIPTION": "BEARING, AXLE",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.666666666666666,
      "MAINTANING STOCK QTY": 3,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 3,
      "APRD QTY": 0,
      "WH12": 6,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 0,
      "STD": 2.21880078490091,
      "MEAN": 0.615384615384615,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 4.500000000000004
    },
    {
      "PART NUMBER": 14747096,
      "DESCRIPTION": "BOLT",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 5,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "A820204000495",
      "DESCRIPTION": "BUSHING, BOGIE",
      "PRODUCT CATEGORY": "YCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "A820301021118",
      "DESCRIPTION": "COTTER PIN, REAR SPRING",
      "PRODUCT CATEGORY": "YCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "A210355000009",
      "DESCRIPTION": "NUT M30√Ó2GB9457",
      "PRODUCT CATEGORY": "YCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "C63390",
      "DESCRIPTION": "UPPER CUSHION",
      "PRODUCT CATEGORY": "SOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "C63391",
      "DESCRIPTION": "UPPER CUSHION GUIDE",
      "PRODUCT CATEGORY": "SOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "A820201020000",
      "DESCRIPTION": "PIN",
      "PRODUCT CATEGORY": "YCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "A820299001611",
      "DESCRIPTION": "RING, SPACE",
      "PRODUCT CATEGORY": "YCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 46549754,
      "DESCRIPTION": "CAPACITOR ALT MECC ALTE",
      "PRODUCT CATEGORY": "DOP",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 1,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 21897549,
      "DESCRIPTION": "ALTERNATOR 21615649",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14734221,
      "DESCRIPTION": "WORK LAMP",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14732412,
      "DESCRIPTION": "14791902 WORK LAMP",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 60159534,
      "DESCRIPTION": "FUEL CLEANER FILTER CORE - FF5612",
      "PRODUCT CATEGORY": "YCG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 1,
      "SHELF LIFE": 3.000000000000003,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 10,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 1.10940039245045,
      "MEAN": 0.307692307692307,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 3.000000000000003
    },
    {
      "PART NUMBER": 13724531,
      "DESCRIPTION": "PULL ASSEMBLY",
      "PRODUCT CATEGORY": "YPG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "A210111000018",
      "DESCRIPTION": "BOLT",
      "PRODUCT CATEGORY": "YPG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "A210319000001",
      "DESCRIPTION": "NUT",
      "PRODUCT CATEGORY": "YPG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 10,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4110000054296,
      "DESCRIPTION": "PUSH ROD",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 5,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4130001978,
      "DESCRIPTION": "PUSH PULL CONTROL",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4110001949018,
      "DESCRIPTION": "CONNECTING ROD 4110000991029",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 4,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14695933,
      "DESCRIPTION": "CONTROL UNIT",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "487129Z01A",
      "DESCRIPTION": "HOSE",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 4,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 16290793,
      "DESCRIPTION": "FILTER ELEMENT",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.25,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 2,
      "SHELF LIFE": 8,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 67,
      "WHXX": 15,
      "WH REFERENCE": "",
      "TREND": 0.67,
      "STD": 0.438529009653514,
      "MEAN": 0.23076923076923,
      "COV": 1.90029237516522,
      "SHELF LIFE_1": 8
    },
    {
      "PART NUMBER": 14778024,
      "DESCRIPTION": "AIR CONDITIONING UNIT 14726895",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "261319Z01C",
      "DESCRIPTION": "TAIL LIGHT",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 37,
      "WHXX": 2,
      "WH REFERENCE": "WHXX",
      "TREND": 2,
      "STD": 1.10940039245045,
      "MEAN": 0.307692307692307,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 3.000000000000003
    },
    {
      "PART NUMBER": 5223502052,
      "DESCRIPTION": "TAIL LIGHT",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 1,
      "SHELF LIFE": 3.000000000000003,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 22,
      "WHXX": 4,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 1.10940039245045,
      "MEAN": 0.307692307692307,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 3.000000000000003
    },
    {
      "PART NUMBER": 11705472,
      "DESCRIPTION": "SCREW",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 43926765,
      "DESCRIPTION": "AIR CLEANER",
      "PRODUCT CATEGORY": "VCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14732836,
      "DESCRIPTION": "TRACK CHAIN 14547301 ",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "152599000172A001",
      "DESCRIPTION": "DRYING CYLINDER",
      "PRODUCT CATEGORY": "YTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 213,
      "WHXX": 9,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "543709Z00B",
      "DESCRIPTION": "SENSOR WHEEL",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 96704580,
      "DESCRIPTION": "LOCK NUT",
      "PRODUCT CATEGORY": "VCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 0,
      "WHXX": 87,
      "WH REFERENCE": "WHXX",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": 4110001015128,
      "DESCRIPTION": "AIR COMPRESSOR 4110001015033",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 1,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 913111,
      "DESCRIPTION": "CLEVIS PIN",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 60158886,
      "DESCRIPTION": "GEARPUMP",
      "PRODUCT CATEGORY": "YCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14740272,
      "DESCRIPTION": "CAB",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "542159Z01C",
      "DESCRIPTION": "PIN",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4130001879,
      "DESCRIPTION": "SWITCH",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 16866694,
      "DESCRIPTION": "EXHAUST PIPE ELBOW",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 2,
      "WH REFERENCE": "WHXX",
      "TREND": 0,
      "STD": 0.375533808099405,
      "MEAN": 0.153846153846153,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": 14691364,
      "DESCRIPTION": "FLEXIBLE COUPLING 14522374",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14739511,
      "DESCRIPTION": "HANDLE 14529995",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14780714,
      "DESCRIPTION": "AIR FILTER 4880805",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "540409Z01C",
      "DESCRIPTION": "BUSHING",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.833333333333333,
      "MAINTANING STOCK QTY": 3,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 3,
      "APRD QTY": 3,
      "WH12": 14,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 2,
      "STD": 2.77350098112614,
      "MEAN": 0.769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 3.6000000000000014
    },
    {
      "PART NUMBER": 4110000924182,
      "DESCRIPTION": "GEAR",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 29030029721,
      "DESCRIPTION": "HOSE",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 9014636301,
      "DESCRIPTION": "ELECTRONIC UNIT",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4110000076426,
      "DESCRIPTION": "SHIM 4110000076271",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3,
      "WHXX": 4,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4110001321383,
      "DESCRIPTION": "SHIM 4110000076271",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3,
      "WHXX": 4,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 7176170,
      "DESCRIPTION": "HOSE HYDRAULIC FORMED",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "160101080007A016",
      "DESCRIPTION": "OIL FILTER ELEMENT",
      "PRODUCT CATEGORY": "YPG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 5,
      "WHXX": 1,
      "WH REFERENCE": "WHXX",
      "TREND": 0,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": 4110015815005,
      "DESCRIPTION": "CRANKSHAFT 4110000909105",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 23044511,
      "DESCRIPTION": "FUEL FILTER 15126069",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 1,
      "MAINTANING STOCK QTY": 4,
      "AVAILABLE": 5,
      "SHELF LIFE": 5,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 99,
      "WHXX": 10,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 2.28988858759323,
      "MEAN": 0.923076923076923,
      "COV": 2.48071263655933,
      "SHELF LIFE_1": 5
    },
    {
      "PART NUMBER": 4110001321009,
      "DESCRIPTION": "SHIM 4110000076271",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 1,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 2,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 60110070,
      "DESCRIPTION": "LOCK NUT",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "30 DAY AVE": 0.666666666666666,
      "MAINTANING STOCK QTY": 3,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 3,
      "APRD QTY": 3,
      "WH12": 12,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 2,
      "STD": 2.21880078490091,
      "MEAN": 0.615384615384615,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 4.500000000000004
    },
    {
      "PART NUMBER": 17510496,
      "DESCRIPTION": "PROPELLER SHAFT",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 4,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 1,
      "STD": 0.375533808099405,
      "MEAN": 0.153846153846153,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": 14619051,
      "DESCRIPTION": "TRACK SHOE",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 42,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 0,
      "STD": 1.10940039245045,
      "MEAN": 0.307692307692307,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 3.000000000000003
    },
    {
      "PART NUMBER": 15184180,
      "DESCRIPTION": "JOINT",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 0,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": 29050023491,
      "DESCRIPTION": "SPACER SLEEVE",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14530606,
      "DESCRIPTION": "BRACKET",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 1,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14652625,
      "DESCRIPTION": "PLATE",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.25,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 0,
      "WHXX": 2,
      "WH REFERENCE": "WHXX",
      "TREND": 2,
      "STD": 0.832050294337843,
      "MEAN": 0.23076923076923,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 4
    },
    {
      "PART NUMBER": "275009Z00C",
      "DESCRIPTION": "CONTROL PANEL",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 7291660,
      "DESCRIPTION": "KIT, REPAIR",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 1,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 17535118,
      "DESCRIPTION": "FILTER RETAINER 15115797",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 6,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 1,
      "STD": 0.375533808099405,
      "MEAN": 0.153846153846153,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": 14403813,
      "DESCRIPTION": "FUEL FILTER 4110000727162",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "AVERAGE (F2)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 3.58333333333333,
      "MAINTANING STOCK QTY": 14,
      "AVAILABLE": 101,
      "SHELF LIFE": 28.186046511627932,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 504,
      "WHXX": 52,
      "WH REFERENCE": "",
      "TREND": 0.93,
      "STD": 4.42313266408578,
      "MEAN": 3.3076923076923,
      "COV": 1.33722615425849,
      "SHELF LIFE_1": 28.186046511627932
    },
    {
      "PART NUMBER": 9020460945,
      "DESCRIPTION": "TURBOCHARGER",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4110000991044,
      "DESCRIPTION": "PISTON KIT",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 6,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4110000991041,
      "DESCRIPTION": "CYLINDER HEAD KIT",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 6,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4110001009036,
      "DESCRIPTION": "BRACKET",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4110001009051,
      "DESCRIPTION": "SCREW",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 4,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4110001007088,
      "DESCRIPTION": "SCREW",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4110000556271,
      "DESCRIPTION": "BEARING SHELL 4110000556110 4110000556109",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.5,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 2,
      "APRD QTY": 2,
      "WH12": 9,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 2,
      "STD": 1.66410058867568,
      "MEAN": 0.461538461538461,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 4
    },
    {
      "PART NUMBER": 17483046,
      "DESCRIPTION": "PARKING BRAKE VALVE 17202132",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 2,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "323089Z00A",
      "DESCRIPTION": "PLUG",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 16,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14714050,
      "DESCRIPTION": "BOLT 14619060",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 10.5,
      "MAINTANING STOCK QTY": 42,
      "AVAILABLE": 37,
      "SHELF LIFE": 3.5238095238095237,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 5,
      "APRD QTY": 0,
      "WH12": 31,
      "WHXX": 74,
      "WH REFERENCE": "WHXX",
      "TREND": 0.38,
      "STD": 17.1628698813485,
      "MEAN": 9.69230769230769,
      "COV": 1.77077228934548,
      "SHELF LIFE_1": 4
    },
    {
      "PART NUMBER": 5278618571,
      "DESCRIPTION": "COMPRESSOR",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 19,
      "WHXX": 8,
      "WH REFERENCE": "WHXX",
      "TREND": 0,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": 20459181,
      "DESCRIPTION": "CLAMP",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14496140,
      "DESCRIPTION": "MAIN VALVE ASSEMBLY",
      "PRODUCT CATEGORY": "YCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 7023149,
      "DESCRIPTION": "JOYSTICK, RH ASSY",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 1,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4120000898006,
      "DESCRIPTION": "O-RING",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4120000898014,
      "DESCRIPTION": "SEALING RING",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 4,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4120000898015,
      "DESCRIPTION": "DUST COVER",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 4,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4120000898001,
      "DESCRIPTION": "BRAKE PAD",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 10,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14731779,
      "DESCRIPTION": "TOOTH",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.833333333333333,
      "MAINTANING STOCK QTY": 3,
      "AVAILABLE": 30,
      "SHELF LIFE": 36.000000000000014,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 25,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 2.77350098112614,
      "MEAN": 0.769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 36.000000000000014
    },
    {
      "PART NUMBER": 14740815,
      "DESCRIPTION": "LOCK",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.833333333333333,
      "MAINTANING STOCK QTY": 3,
      "AVAILABLE": 30,
      "SHELF LIFE": 36.000000000000014,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 25,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 2.77350098112614,
      "MEAN": 0.769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 36.000000000000014
    },
    {
      "PART NUMBER": 14740816,
      "DESCRIPTION": "RETAINER",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.833333333333333,
      "MAINTANING STOCK QTY": 3,
      "AVAILABLE": 26,
      "SHELF LIFE": 31.20000000000001,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 2.77350098112614,
      "MEAN": 0.769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 31.20000000000001
    },
    {
      "PART NUMBER": 4110002230035,
      "DESCRIPTION": "NEEDLE BEARING",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 2,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 20405902,
      "DESCRIPTION": "GASKET",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 5224009155,
      "DESCRIPTION": "SENSOR",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 1,
      "SHELF LIFE": 3.000000000000003,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 27,
      "WHXX": 6,
      "WH REFERENCE": "",
      "TREND": 1.5,
      "STD": 0.630425171956115,
      "MEAN": 0.307692307692307,
      "COV": 2.04888180885737,
      "SHELF LIFE_1": 3.000000000000003
    },
    {
      "PART NUMBER": 4110000991043,
      "DESCRIPTION": "CYLINDER LINER KIT",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 6,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4110000991045,
      "DESCRIPTION": "CAMSHAFT BEARING KIT",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "TP4061007",
      "DESCRIPTION": "ALTERNATOR",
      "PRODUCT CATEGORY": "SDG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 11216211,
      "DESCRIPTION": "WIPER BLADE 14400348",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 10,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 60247900,
      "DESCRIPTION": "PRESSURE SWITCH",
      "PRODUCT CATEGORY": "YPG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 11052508,
      "DESCRIPTION": "BOLT",
      "PRODUCT CATEGORY": "YCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 11,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "A820301021095",
      "DESCRIPTION": "PIN",
      "PRODUCT CATEGORY": "YCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "A820301021100",
      "DESCRIPTION": "ROD",
      "PRODUCT CATEGORY": "YCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 23935622,
      "DESCRIPTION": "BRACKET 20805520",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 6515541,
      "DESCRIPTION": "FILTER, OIL HYD 12",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 30,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 6563995,
      "DESCRIPTION": "KIT FILTER",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 8,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 3885315,
      "DESCRIPTION": "FILTER, FUEL 12",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 26,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 84474457,
      "DESCRIPTION": "HOSE                     ",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": 84474434,
      "DESCRIPTION": "HOSE                     ",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": "SSC31211L0N",
      "DESCRIPTION": "ROD(MOIL POINT) SB81",
      "PRODUCT CATEGORY": "SOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 2,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14558098,
      "DESCRIPTION": "TUBE",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 3,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14558095,
      "DESCRIPTION": "TUBE",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 2,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4110001015046,
      "DESCRIPTION": "GEAR",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 60110101,
      "DESCRIPTION": "SIX POINT SOCKET SCREW 969373",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 0,
      "WHXX": 6,
      "WH REFERENCE": "WHXX",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": 17461758,
      "DESCRIPTION": "CIRCUIT BOARD",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 7030400,
      "DESCRIPTION": "GLOW PLUG",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14543309,
      "DESCRIPTION": "HOSE",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "130288000679A",
      "DESCRIPTION": "EXHAUST VALVE ASSEMBLY",
      "PRODUCT CATEGORY": "YTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "1132-00010",
      "DESCRIPTION": "COVER_TRAVEL MO",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 3,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 11220517,
      "DESCRIPTION": "LEVEL SENSOR",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 5223567814,
      "DESCRIPTION": "WEATHERSTRIP",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 7,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4120006628,
      "DESCRIPTION": "BOOM CYLINDER",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": 14747393,
      "DESCRIPTION": "HEX. SOCKET SCREW",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14747394,
      "DESCRIPTION": "SPRING WASHER",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14748398,
      "DESCRIPTION": "HEX. SOCKET SCREW",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14748204,
      "DESCRIPTION": "WASHER",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "C13196",
      "DESCRIPTION": "ROD PLUG",
      "PRODUCT CATEGORY": "SOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 23251714,
      "DESCRIPTION": "ADBLUE TANK              ",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 2050900006,
      "DESCRIPTION": "FINAL DRIVE",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 9014640102,
      "DESCRIPTION": "ECU",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 16015376,
      "DESCRIPTION": "TOOTH 8111",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 14,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 84479333,
      "DESCRIPTION": "REGULATOR CONTROL        ",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 3,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": 5224202071,
      "DESCRIPTION": "SPRING",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.833333333333333,
      "MAINTANING STOCK QTY": 3,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 3,
      "APRD QTY": 0,
      "WH12": 74,
      "WHXX": 18,
      "WH REFERENCE": "WHXX",
      "TREND": 0.6,
      "STD": 2.04751256218556,
      "MEAN": 0.769230769230769,
      "COV": 2.66176633084123,
      "SHELF LIFE_1": 3.6000000000000014
    },
    {
      "PART NUMBER": 11709361,
      "DESCRIPTION": "LOCK RING",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 3,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 11211150,
      "DESCRIPTION": "FILTER INSERT",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.25,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 15,
      "SHELF LIFE": 60,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 43,
      "WHXX": 7,
      "WH REFERENCE": "",
      "TREND": 0.67,
      "STD": 0.599144689515278,
      "MEAN": 0.23076923076923,
      "COV": 2.5962936545662,
      "SHELF LIFE_1": 60
    },
    {
      "PART NUMBER": 11211149,
      "DESCRIPTION": "FILTER",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "AVERAGE (F2)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 2.33333333333333,
      "MAINTANING STOCK QTY": 9,
      "AVAILABLE": 44,
      "SHELF LIFE": 18.857142857142886,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 120,
      "WHXX": 22,
      "WH REFERENCE": "",
      "TREND": 1,
      "STD": 2.51151195650992,
      "MEAN": 2.15384615384615,
      "COV": 1.16605912266532,
      "SHELF LIFE_1": 18.857142857142886
    },
    {
      "PART NUMBER": 14408713,
      "DESCRIPTION": "FILTER ELEMENT",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.416666666666666,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 7,
      "SHELF LIFE": 16.800000000000026,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0.8,
      "STD": 0.65044363558799,
      "MEAN": 0.384615384615384,
      "COV": 1.69115345252877,
      "SHELF LIFE_1": 16.800000000000026
    },
    {
      "PART NUMBER": 11218232,
      "DESCRIPTION": "FILTER INSERT",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.916666666666666,
      "MAINTANING STOCK QTY": 4,
      "AVAILABLE": 1,
      "SHELF LIFE": 1.0909090909090917,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 3,
      "APRD QTY": 3,
      "WH12": 34,
      "WHXX": 3,
      "WH REFERENCE": "WHXX",
      "TREND": 0.91,
      "STD": 0.987096233585649,
      "MEAN": 0.846153846153846,
      "COV": 1.16656827605576,
      "SHELF LIFE_1": 4.363636363636367
    },
    {
      "PART NUMBER": 54116585,
      "DESCRIPTION": "ALTERNATOR (ENDURANCE)",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 4,
      "WHXX": 2,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4000015,
      "DESCRIPTION": "SENSOR, ANGLE",
      "PRODUCT CATEGORY": "JLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4120016961,
      "DESCRIPTION": "FILTER ASSY. 4120004036",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 937879,
      "DESCRIPTION": "NIPPLE",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 15142021,
      "DESCRIPTION": "LOCK CATCH",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 11388082,
      "DESCRIPTION": "53032790 CONTROL UNIT 21766717 11443993",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 22696301,
      "DESCRIPTION": "IDLER PULLEY 21766717",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 2,
      "SHELF LIFE": 24.00000000000001,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 24.00000000000001
    },
    {
      "PART NUMBER": 11217578,
      "DESCRIPTION": "FILTER 11218596",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 3,
      "SHELF LIFE": 36.000000000000014,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 9,
      "WHXX": 3,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 36.000000000000014
    },
    {
      "PART NUMBER": "A229900003567",
      "DESCRIPTION": "FITTING, ELBOW",
      "PRODUCT CATEGORY": "YCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 7340039,
      "DESCRIPTION": "HARNESS ENGINE",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 7276760,
      "DESCRIPTION": "HARNESS, MFR",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 6673188,
      "DESCRIPTION": "KIT SEAL",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 24063494,
      "DESCRIPTION": "HEATER 20758403",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 3,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 23417546,
      "DESCRIPTION": "POSITION SENSOR          ",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 1,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 12945110,
      "DESCRIPTION": "A810201206451 DISPLAY ASSEMBLY",
      "PRODUCT CATEGORY": "YCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "TP1000424655A",
      "DESCRIPTION": "OIL¬†FILTER¬†ELEMENT",
      "PRODUCT CATEGORY": "SDG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 4.5,
      "MAINTANING STOCK QTY": 18,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 18,
      "APRD QTY": 18,
      "WH12": 30,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 0,
      "STD": 14.0999181667019,
      "MEAN": 4.15384615384615,
      "COV": 3.39442474383566,
      "SHELF LIFE_1": 4
    },
    {
      "PART NUMBER": 4120003852001,
      "DESCRIPTION": "NIPPLE",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 15099562,
      "DESCRIPTION": "SLIDING WINDOW",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 6,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 17459094,
      "DESCRIPTION": "REAR VIEW MIRROR",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 4,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14746778,
      "DESCRIPTION": "SEAL",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 5,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "TP217638",
      "DESCRIPTION": "BELT' WATER PUMP",
      "PRODUCT CATEGORY": "SDG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 4,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "01700-070-0440-420-20GA",
      "DESCRIPTION": "PIN 70 X 440",
      "PRODUCT CATEGORY": "EIK",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "02536624-7-125",
      "DESCRIPTION": "O-RING 7 X 125",
      "PRODUCT CATEGORY": "EIK",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "006B12A-140-AAL",
      "DESCRIPTION": "BOLT ¬Æ‚ÄÙ X 5 ¬Ω‚ÄÙ",
      "PRODUCT CATEGORY": "EIK",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 7,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "01700-070-0400-375-20GA",
      "DESCRIPTION": "PIN 70 X 400",
      "PRODUCT CATEGORY": "EIK",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "01700-080-0400-375-20GA",
      "DESCRIPTION": "PIN 80 X 400",
      "PRODUCT CATEGORY": "EIK",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "025080-095-05",
      "DESCRIPTION": "DUST SEAL 80 X 95 X 5",
      "PRODUCT CATEGORY": "EIK",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 4,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "011080-095-080-LA",
      "DESCRIPTION": "BUSH 80 X 95 X 80",
      "PRODUCT CATEGORY": "EIK",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "011070-085-070-LA",
      "DESCRIPTION": "BUSH 70 X 85 X 70",
      "PRODUCT CATEGORY": "EIK",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "025070-085-05",
      "DESCRIPTION": "DUST SEAL 70 X 85 X 5",
      "PRODUCT CATEGORY": "EIK",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 6,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 11383470,
      "DESCRIPTION": "ELECTRONIC UNIT 11443400",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "275429Z00A",
      "DESCRIPTION": "CABLE",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 28,
      "WHXX": 9,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "154-30-25111",
      "DESCRIPTION": "158-40-A0000P050-01 CARRIER ROLLER ASSY",
      "PRODUCT CATEGORY": "SDG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4130002911007,
      "DESCRIPTION": "SEALING RING",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": 4130002911008,
      "DESCRIPTION": "SEALING RING",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": 14794925,
      "DESCRIPTION": "CONTROL UNIT 14594707",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.25,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 1,
      "SHELF LIFE": 4,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 3,
      "WH REFERENCE": "",
      "TREND": 1.33,
      "STD": 0.599144689515278,
      "MEAN": 0.23076923076923,
      "COV": 2.5962936545662,
      "SHELF LIFE_1": 4
    },
    {
      "PART NUMBER": 16812522,
      "DESCRIPTION": "FUEL TANK",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 1,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 13057289,
      "DESCRIPTION": "WHEEL HOUSE LINERFRONT LEFT",
      "PRODUCT CATEGORY": "YPG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 12173327,
      "DESCRIPTION": "BRACKET, RIGHT UPPER, BUMP",
      "PRODUCT CATEGORY": "YPG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14741183,
      "DESCRIPTION": "HOSE ASSEMBLY",
      "PRODUCT CATEGORY": "VOH",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "A210405000014",
      "DESCRIPTION": "WASHER",
      "PRODUCT CATEGORY": "YCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "A820201010000",
      "DESCRIPTION": "CLIPPING KEYHQC5420J.32-6",
      "PRODUCT CATEGORY": "YCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14630450,
      "DESCRIPTION": "ACTUATOR",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 96747001,
      "DESCRIPTION": "HEX. SOCKET SCREW 96725007",
      "PRODUCT CATEGORY": "VCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 1.33333333333333,
      "MAINTANING STOCK QTY": 5,
      "AVAILABLE": 11,
      "SHELF LIFE": 8.250000000000021,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 52,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 4.43760156980183,
      "MEAN": 1.23076923076923,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 8.250000000000021
    },
    {
      "PART NUMBER": 4110000924141,
      "DESCRIPTION": "STUD",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4110001015042,
      "DESCRIPTION": "FLANGE",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 15060436,
      "DESCRIPTION": "PLATE",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 1,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 15015618,
      "DESCRIPTION": "PRE-CLEANER (L120F)",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 54982898,
      "DESCRIPTION": "PRE-CLEANER (L60F)",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 9014794925,
      "DESCRIPTION": "REMAN ECU 9014594707",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 16880355,
      "DESCRIPTION": "COVER PLATE 15060428",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 2,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 46830351,
      "DESCRIPTION": "ENGINE LUBE OIL FILTER (ORDER USING DOOSAN PORTABLE)",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "AVERAGE (F2)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 2.25,
      "MAINTANING STOCK QTY": 9,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 9,
      "APRD QTY": 9,
      "WH12": 8,
      "WHXX": 0,
      "WH REFERENCE": "PARTIAL WH12",
      "TREND": 0.52,
      "STD": 4.29071747033714,
      "MEAN": 2.07692307692307,
      "COV": 2.0659010042364,
      "SHELF LIFE_1": 4
    },
    {
      "PART NUMBER": 46830389,
      "DESCRIPTION": "FUEL FILTER",
      "PRODUCT CATEGORY": "DOP",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 1.16666666666666,
      "MAINTANING STOCK QTY": 5,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 5,
      "APRD QTY": 5,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 0.71,
      "STD": 2.62873665669583,
      "MEAN": 1.07692307692307,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 4.28571428571431
    },
    {
      "PART NUMBER": 11712379,
      "DESCRIPTION": "CYLINDER HEAD KIT",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 5223994853,
      "DESCRIPTION": "DISC 301009Z01A ",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 4,
      "WHXX": 2,
      "WH REFERENCE": "WHXX",
      "TREND": 0,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": 14648462,
      "DESCRIPTION": "LINK",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 1,
      "SHELF LIFE": 12.000000000000005,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 4,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 12.000000000000005
    },
    {
      "PART NUMBER": 14648461,
      "DESCRIPTION": "LINK",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 1,
      "SHELF LIFE": 12.000000000000005,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 4,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 12.000000000000005
    },
    {
      "PART NUMBER": 14648463,
      "DESCRIPTION": "PIN",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 5,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14648464,
      "DESCRIPTION": "PIN",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 1,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14648465,
      "DESCRIPTION": "BUSHING",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 2,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14648466,
      "DESCRIPTION": "BUSHING",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14648467,
      "DESCRIPTION": "SEAL",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 8,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14648468,
      "DESCRIPTION": "SPACER",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 6,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 5223714918,
      "DESCRIPTION": "BRAKE SHOE KIT",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.666666666666666,
      "MAINTANING STOCK QTY": 3,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 3,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "PARTIAL WH12",
      "TREND": 0,
      "STD": 2.21880078490091,
      "MEAN": 0.615384615384615,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 4.500000000000004
    },
    {
      "PART NUMBER": "160602010092A001",
      "DESCRIPTION": "AIR FILTER KIT",
      "PRODUCT CATEGORY": "YPG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 0,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": 58848771,
      "DESCRIPTION": "ELBOW",
      "PRODUCT CATEGORY": "VCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 2,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 59617084,
      "DESCRIPTION": "ELBOW",
      "PRODUCT CATEGORY": "VCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 54765326,
      "DESCRIPTION": "SEALING KIT",
      "PRODUCT CATEGORY": "VCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 58943648,
      "DESCRIPTION": "NIPPLE",
      "PRODUCT CATEGORY": "VCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 1,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4110002531014,
      "DESCRIPTION": "OIL COOLER",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 29220014991,
      "DESCRIPTION": "AIR TANK",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14400991,
      "DESCRIPTION": "BUCKET TOOTH",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 3.08333333333333,
      "MAINTANING STOCK QTY": 12,
      "AVAILABLE": 1,
      "SHELF LIFE": 0.3243243243243247,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 11,
      "APRD QTY": 11,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 1.62,
      "STD": 3.80451823157137,
      "MEAN": 2.84615384615384,
      "COV": 1.33672262190345,
      "SHELF LIFE_1": 3.891891891891896
    },
    {
      "PART NUMBER": 4110002196013,
      "DESCRIPTION": "V-RIBBED BELT",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": 11216209,
      "DESCRIPTION": "WIPER ARM",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14521398,
      "DESCRIPTION": "ENGINE",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 11210768,
      "DESCRIPTION": "PIN",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 1.41666666666666,
      "MAINTANING STOCK QTY": 6,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 6,
      "APRD QTY": 6,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 1.76,
      "STD": 2.98285700251217,
      "MEAN": 1.3076923076923,
      "COV": 2.28100829603871,
      "SHELF LIFE_1": 4.235294117647078
    },
    {
      "PART NUMBER": 11210753,
      "DESCRIPTION": "WASHER",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 1.41666666666666,
      "MAINTANING STOCK QTY": 6,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 6,
      "APRD QTY": 6,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 1.76,
      "STD": 2.98285700251217,
      "MEAN": 1.3076923076923,
      "COV": 2.28100829603871,
      "SHELF LIFE_1": 4.235294117647078
    },
    {
      "PART NUMBER": 11223871,
      "DESCRIPTION": "RING",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 2,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": 4190003801,
      "DESCRIPTION": "LOCK",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4130015120001,
      "DESCRIPTION": "FAN 4190002907003",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.916666666666666,
      "MAINTANING STOCK QTY": 4,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 4,
      "APRD QTY": 4,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 2,
      "STD": 2.07549808665108,
      "MEAN": 0.846153846153846,
      "COV": 2.45286137513309,
      "SHELF LIFE_1": 4.363636363636367
    },
    {
      "PART NUMBER": "115H-58-00002",
      "DESCRIPTION": "BELT",
      "PRODUCT CATEGORY": "SDG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 0,
      "STD": 0.375533808099405,
      "MEAN": 0.153846153846153,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": "TP612600062230",
      "DESCRIPTION": "BELT",
      "PRODUCT CATEGORY": "SDG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.833333333333333,
      "MAINTANING STOCK QTY": 3,
      "AVAILABLE": 2,
      "SHELF LIFE": 2.400000000000001,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 7,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 0,
      "STD": 1.96442723432922,
      "MEAN": 0.769230769230769,
      "COV": 2.55375540462798,
      "SHELF LIFE_1": 3.6000000000000014
    },
    {
      "PART NUMBER": 11095146,
      "DESCRIPTION": "SUPPORT PLATE",
      "PRODUCT CATEGORY": "YCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 10024463,
      "DESCRIPTION": "SLIDE PLATE",
      "PRODUCT CATEGORY": "YCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14778022,
      "DESCRIPTION": "COMPRESSOR 14659238 15082727",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 10136121,
      "DESCRIPTION": "SEAL, DUST",
      "PRODUCT CATEGORY": "YCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 7232486,
      "DESCRIPTION": "SWITCH FAN",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14698883,
      "DESCRIPTION": "PRINTED CIRCUIT BOARD",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 29030019421,
      "DESCRIPTION": "RUBBER BUSHING",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4110002795,
      "DESCRIPTION": "CLAMP",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 29030025821,
      "DESCRIPTION": "RUBBER PLATE",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 5223804500,
      "DESCRIPTION": "VALVE HOUSING",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14759247,
      "DESCRIPTION": "ODOMETER 14626783",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 5,
      "WHXX": 2,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 11831591,
      "DESCRIPTION": "ASSEMBLY,MOUNTING SUPPORT 3,LEFT COVER L",
      "PRODUCT CATEGORY": "YTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 11934802,
      "DESCRIPTION": "ASSEMBLY,MOUNTING SUPPORT 3 ,RIGHT COVER",
      "PRODUCT CATEGORY": "YTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "160603010047A001 ",
      "DESCRIPTION": "FILTER ELEMENT",
      "PRODUCT CATEGORY": "YTG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 8,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 0,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": 23974646,
      "DESCRIPTION": "FAN BRACKET",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 28280004922,
      "DESCRIPTION": "HOOD",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 1001099717,
      "DESCRIPTION": "SENSOR,CAN ANGLE, 90 DEG",
      "PRODUCT CATEGORY": "JLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 3050900009,
      "DESCRIPTION": "SEALING COVER",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 5222834631,
      "DESCRIPTION": "LEAF SPRING",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 1.16666666666666,
      "MAINTANING STOCK QTY": 5,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 5,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 8,
      "WH REFERENCE": "WHXX",
      "TREND": 0.43,
      "STD": 2.75262112847429,
      "MEAN": 1.07692307692307,
      "COV": 2.55600533358327,
      "SHELF LIFE_1": 4.28571428571431
    },
    {
      "PART NUMBER": 14881104,
      "DESCRIPTION": "FUEL HOSE",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4130002904108,
      "DESCRIPTION": "4190002851 TEMPERATURE SENSOR",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.416666666666666,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 2,
      "SHELF LIFE": 4.800000000000008,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0.8,
      "STD": 0.960768922830522,
      "MEAN": 0.384615384615384,
      "COV": 2.49799919935935,
      "SHELF LIFE_1": 4.800000000000008
    },
    {
      "PART NUMBER": 4130002904107,
      "DESCRIPTION": "RELAY",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 2,
      "STD": 0.375533808099405,
      "MEAN": 0.153846153846153,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": 4130002904104,
      "DESCRIPTION": "CELL CORE ",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 2,
      "STD": 0.375533808099405,
      "MEAN": 0.153846153846153,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": 11711989,
      "DESCRIPTION": "MAGNET",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 54315408,
      "DESCRIPTION": "FUEL FILTER 20805349",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "FAST (F1)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 8.16666666666666,
      "MAINTANING STOCK QTY": 33,
      "AVAILABLE": 2,
      "SHELF LIFE": 0.24489795918367366,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 31,
      "APRD QTY": 31,
      "WH12": 359,
      "WHXX": 264,
      "WH REFERENCE": "WHXX",
      "TREND": 1.92,
      "STD": 10.5720968009771,
      "MEAN": 7.53846153846153,
      "COV": 1.40242100421125,
      "SHELF LIFE_1": 4.040816326530615
    },
    {
      "PART NUMBER": 13625105,
      "DESCRIPTION": "OIL SEPARATOR",
      "PRODUCT CATEGORY": "YTG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 7,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 0,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": 6672427,
      "DESCRIPTION": "FUEL PUMP",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4110001007128,
      "DESCRIPTION": "INJECTION PUMP",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.5,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 2,
      "APRD QTY": 2,
      "WH12": 7,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 2,
      "STD": 1.66410058867568,
      "MEAN": 0.461538461538461,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 4
    },
    {
      "PART NUMBER": 60251057,
      "DESCRIPTION": "RADIATOR",
      "PRODUCT CATEGORY": "YPG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "156-40-01000V010-01",
      "DESCRIPTION": "FRONT¬†IDLER,¬†ASSEMBLY",
      "PRODUCT CATEGORY": "SDG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.833333333333333,
      "MAINTANING STOCK QTY": 3,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 3,
      "APRD QTY": 3,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 1.6,
      "STD": 1.7394369852458,
      "MEAN": 0.769230769230769,
      "COV": 2.26126808081955,
      "SHELF LIFE_1": 3.6000000000000014
    },
    {
      "PART NUMBER": "115H-40-01000-01",
      "DESCRIPTION": "CARRIER¬†ROLLER",
      "PRODUCT CATEGORY": "SDG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 1.33333333333333,
      "MAINTANING STOCK QTY": 5,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 5,
      "APRD QTY": 5,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 1.5,
      "STD": 3.41940165706044,
      "MEAN": 1.23076923076923,
      "COV": 2.7782638463616,
      "SHELF LIFE_1": 3.7500000000000098
    },
    {
      "PART NUMBER": 4120004568,
      "DESCRIPTION": "NIPPLE",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14031386,
      "DESCRIPTION": "MUFFLER SUPPORT",
      "PRODUCT CATEGORY": "YPG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "156-40-02000-01",
      "DESCRIPTION": "TENSION¬†DEVICE",
      "PRODUCT CATEGORY": "SDG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.5,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 2,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 0.67,
      "STD": 1.19828937903055,
      "MEAN": 0.461538461538461,
      "COV": 2.5962936545662,
      "SHELF LIFE_1": 4
    },
    {
      "PART NUMBER": "156-40-02003",
      "DESCRIPTION": "RECOIL¬†SPRING",
      "PRODUCT CATEGORY": "SDG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 0,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": "115H-40-00003",
      "DESCRIPTION": "GASKET",
      "PRODUCT CATEGORY": "SDG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 0,
      "STD": 1.10940039245045,
      "MEAN": 0.307692307692307,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 3.000000000000003
    },
    {
      "PART NUMBER": "115H-40-00002",
      "DESCRIPTION": "RETAINER",
      "PRODUCT CATEGORY": "SDG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 0,
      "STD": 1.10940039245045,
      "MEAN": 0.307692307692307,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 3.000000000000003
    },
    {
      "PART NUMBER": "156-40-00005",
      "DESCRIPTION": "SEAL¬†RING",
      "PRODUCT CATEGORY": "SDG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 0,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": "37C620",
      "DESCRIPTION": "BOLT CARRIAGE",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 2,
      "STD": 1.10940039245045,
      "MEAN": 0.307692307692307,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 3.000000000000003
    },
    {
      "PART NUMBER": 5224332262,
      "DESCRIPTION": "VALVE",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 106,
      "WHXX": 13,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14544980,
      "DESCRIPTION": "LATCH",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 1,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 11819035,
      "DESCRIPTION": "LOGO",
      "PRODUCT CATEGORY": "YPG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 60120467,
      "DESCRIPTION": "FRONT PANEL HINGE ASSY",
      "PRODUCT CATEGORY": "YPG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 13265068,
      "DESCRIPTION": "FRONT PANEL RIGHT REINFORCED PLATE ASSY(",
      "PRODUCT CATEGORY": "YPG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "A220400000005",
      "DESCRIPTION": "BALL VALVE Q11F-16P-DN15GB15185",
      "PRODUCT CATEGORY": "YPG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14642497,
      "DESCRIPTION": "BATTERY CABLE",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 15033174,
      "DESCRIPTION": "LOCK SCREW",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "130201000125A001",
      "DESCRIPTION": "GAS-PATH CONTROL VALVE",
      "PRODUCT CATEGORY": "YPG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "TP3040292",
      "DESCRIPTION": "BELT",
      "PRODUCT CATEGORY": "SDG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 3,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": 54864253,
      "DESCRIPTION": "BOWL 11110737",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 9,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 29050033351,
      "DESCRIPTION": "SEALING RETAINER",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 5223994830,
      "DESCRIPTION": "DISC",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 1,
      "SHELF LIFE": 6.000000000000024,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 4,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.375533808099405,
      "MEAN": 0.153846153846153,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": "156-82-00001",
      "DESCRIPTION": "BLADE",
      "PRODUCT CATEGORY": "SDG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 2,
      "MAINTANING STOCK QTY": 8,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 8,
      "APRD QTY": 0,
      "WH12": 7,
      "WHXX": 0,
      "WH REFERENCE": "PARTIAL WH12",
      "TREND": 0.17,
      "STD": 5.50524225694858,
      "MEAN": 1.84615384615384,
      "COV": 2.98200622251381,
      "SHELF LIFE_1": 4
    },
    {
      "PART NUMBER": "115H-03B-01000",
      "DESCRIPTION": "RADIATOR",
      "PRODUCT CATEGORY": "SDG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "114H-58D-09000",
      "DESCRIPTION": "COMPRESSOR",
      "PRODUCT CATEGORY": "SDG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "114H-58D-06000",
      "DESCRIPTION": "EVAPORATOR",
      "PRODUCT CATEGORY": "SDG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "113K-58-01000",
      "DESCRIPTION": "CONDENSER",
      "PRODUCT CATEGORY": "SDG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "15B-63A-03000P020",
      "DESCRIPTION": "TILT¬†CYL.¬†SEAL¬†KIT¬†ASSY",
      "PRODUCT CATEGORY": "SDG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 0,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": "15B-62A-01000P020",
      "DESCRIPTION": "BLADE¬†LIFT¬†CYL.¬†SEAL¬†KIT¬†ASSY.",
      "PRODUCT CATEGORY": "SDG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 0,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": 964832,
      "DESCRIPTION": "CLEVIS PIN 14880431",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 2,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 6,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14599866,
      "DESCRIPTION": "SWITCH",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 1,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14376014,
      "DESCRIPTION": "CONTROL LEVER",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 1,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14376013,
      "DESCRIPTION": "BUSHING",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 1,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 13,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 60153144,
      "DESCRIPTION": "EXPANSIONWATERTANKAS",
      "PRODUCT CATEGORY": "YPG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4110003496001,
      "DESCRIPTION": "FILTER INSERT",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 48,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4110003496002,
      "DESCRIPTION": "FILTER INSERT",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 48,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 28360003211,
      "DESCRIPTION": "RIPPER TOOTH",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.666666666666666,
      "MAINTANING STOCK QTY": 3,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 3,
      "APRD QTY": 0,
      "WH12": 11,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 0,
      "STD": 2.21880078490091,
      "MEAN": 0.615384615384615,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 4.500000000000004
    },
    {
      "PART NUMBER": 14615925,
      "DESCRIPTION": "WIRING HARNESS",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 6707101,
      "DESCRIPTION": "SEAL NEOPRENE",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "7D4",
      "DESCRIPTION": "NUT HEX JAM",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 6737524,
      "DESCRIPTION": "HANDLE CONTROL",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 1001151975,
      "DESCRIPTION": "CONTROL,ACCESSORY MODULE",
      "PRODUCT CATEGORY": "JLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4110003380090,
      "DESCRIPTION": "SENSOR 4110003380016 ",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 5224202065,
      "DESCRIPTION": "BRAKE KIT",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 9,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14703194,
      "DESCRIPTION": "O-RING",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 6,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14616918,
      "DESCRIPTION": "WASHER",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14573769,
      "DESCRIPTION": "COLLAR",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14573768,
      "DESCRIPTION": "NEEDLE BEARING",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14619871,
      "DESCRIPTION": "SEALING KIT",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14573762,
      "DESCRIPTION": "BEARING",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14573763,
      "DESCRIPTION": "RING",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14573764,
      "DESCRIPTION": "RING",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14573765,
      "DESCRIPTION": "PLUG",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14573772,
      "DESCRIPTION": "GEAR",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14573773,
      "DESCRIPTION": "RING",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14573774,
      "DESCRIPTION": "RETAINER",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14573775,
      "DESCRIPTION": "PLANETARY GEAR",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14573776,
      "DESCRIPTION": "NEEDLE BEARING",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14573777,
      "DESCRIPTION": "BEARING RACE",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14616920,
      "DESCRIPTION": "PLATE",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14573779,
      "DESCRIPTION": "PIN",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14573780,
      "DESCRIPTION": "GEAR",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14616921,
      "DESCRIPTION": "COVER",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14718303,
      "DESCRIPTION": "THRUST RING 8230-02890",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4120007618002,
      "DESCRIPTION": "VALVE",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 5223488082,
      "DESCRIPTION": "DUST COVER",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 9,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 7210676,
      "DESCRIPTION": "PANEL, LH",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "Q15003",
      "DESCRIPTION": "AUTO GREASING CARTRIDGE",
      "PRODUCT CATEGORY": "SOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 8.33333333333333,
      "MAINTANING STOCK QTY": 33,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 33,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 0,
      "STD": 27.7350098112614,
      "MEAN": 7.69230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 3.9600000000000013
    },
    {
      "PART NUMBER": "TP612600062227",
      "DESCRIPTION": "AUTOMATIC TENSIONER",
      "PRODUCT CATEGORY": "SDG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "07016-00908",
      "DESCRIPTION": "RING, DUST",
      "PRODUCT CATEGORY": "SDG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 1,
      "MAINTANING STOCK QTY": 4,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 4,
      "APRD QTY": 4,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 1.33,
      "STD": 2.39657875806111,
      "MEAN": 0.923076923076923,
      "COV": 2.5962936545662,
      "SHELF LIFE_1": 4
    },
    {
      "PART NUMBER": 15184179,
      "DESCRIPTION": "JOINT",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 0,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": 29090007651,
      "DESCRIPTION": "AXLE CASING",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 7311865,
      "DESCRIPTION": "FITTING GREASE",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": 21262793,
      "DESCRIPTION": "SPRING BRAKE CYLINDER",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 0,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": 20818765,
      "DESCRIPTION": "HEADLAMP",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 60110519,
      "DESCRIPTION": "ARM. CAMSHAFT. REAR LH",
      "PRODUCT CATEGORY": "YCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14708138,
      "DESCRIPTION": "SPINDLE",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14672988,
      "DESCRIPTION": "HOUSING",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 7166172,
      "DESCRIPTION": "HANDLE",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 7166173,
      "DESCRIPTION": "HANDLE",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 7023150,
      "DESCRIPTION": "JOYSTICK, LH ASSY",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 1,
      "SHELF LIFE": 12.000000000000005,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 12.000000000000005
    },
    {
      "PART NUMBER": 14523887,
      "DESCRIPTION": "RADIATOR",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "FDJ007138164",
      "DESCRIPTION": "EXHAUST VALVE BRIDGE",
      "PRODUCT CATEGORY": "YPG",
      "ITEM MOVEMENT BRANCH": "AVERAGE (F2)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 3,
      "MAINTANING STOCK QTY": 12,
      "AVAILABLE": 24,
      "SHELF LIFE": 8,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 617,
      "WHXX": 104,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 9.98460353205412,
      "MEAN": 2.76923076923076,
      "COV": 3.60555127546399,
      "SHELF LIFE_1": 8
    },
    {
      "PART NUMBER": 24046345,
      "DESCRIPTION": "FUEL PUMP 22905123",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 3,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 2,
      "STD": 0.75106761619881,
      "MEAN": 0.307692307692307,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 3.000000000000003
    },
    {
      "PART NUMBER": 5224222120,
      "DESCRIPTION": "WHEEL NUT",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 4.16666666666666,
      "MAINTANING STOCK QTY": 17,
      "AVAILABLE": 13,
      "SHELF LIFE": 3.120000000000005,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 4,
      "APRD QTY": 4,
      "WH12": 10,
      "WHXX": 87,
      "WH REFERENCE": "WHXX",
      "TREND": 2,
      "STD": 11.2089707663561,
      "MEAN": 3.84615384615384,
      "COV": 2.91433239925258,
      "SHELF LIFE_1": 4.080000000000006
    },
    {
      "PART NUMBER": 12794457,
      "DESCRIPTION": "FUSE",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 8,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "TP612630060836",
      "DESCRIPTION": "IDLER",
      "PRODUCT CATEGORY": "SDG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.25,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 1.33,
      "STD": 0.599144689515278,
      "MEAN": 0.23076923076923,
      "COV": 2.5962936545662,
      "SHELF LIFE_1": 4
    },
    {
      "PART NUMBER": "TP612600040702",
      "DESCRIPTION": "SUPPORT BLOCK",
      "PRODUCT CATEGORY": "SDG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.25,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 1.33,
      "STD": 0.599144689515278,
      "MEAN": 0.23076923076923,
      "COV": 2.5962936545662,
      "SHELF LIFE_1": 4
    },
    {
      "PART NUMBER": 20410139,
      "DESCRIPTION": "BRAKE CHAMBER",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14574768,
      "DESCRIPTION": "WIRING HARNESS",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 612600062371,
      "DESCRIPTION": "BELT TENSIONER",
      "PRODUCT CATEGORY": "ZZZ",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 2,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": 4110001908005,
      "DESCRIPTION": "RADIATOR",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 13708877,
      "DESCRIPTION": "BATTERY FRAME",
      "PRODUCT CATEGORY": "YPG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 13710231,
      "DESCRIPTION": "CUSHION BLOCK OF BATTERY FRAME",
      "PRODUCT CATEGORY": "YPG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 13710232,
      "DESCRIPTION": "BACKUP PLATE OF BATTERY FRAME",
      "PRODUCT CATEGORY": "YPG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 13710230,
      "DESCRIPTION": "SEALING BOARD OF BATTERY FRAME",
      "PRODUCT CATEGORY": "YPG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 10067631,
      "DESCRIPTION": "RIGHT ANGLE ADAPTOR BODY",
      "PRODUCT CATEGORY": "YPG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 10088216,
      "DESCRIPTION": "TRANSITIONAL JOINT GJB312C8R152-5",
      "PRODUCT CATEGORY": "YPG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "A229900001360",
      "DESCRIPTION": "WASHER",
      "PRODUCT CATEGORY": "YPG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 11819349,
      "DESCRIPTION": "PLATE, LOCKING",
      "PRODUCT CATEGORY": "YPG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 12173328,
      "DESCRIPTION": "BRACKET, LEFT UPPER, BUMP",
      "PRODUCT CATEGORY": "YPG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 12648540,
      "DESCRIPTION": "MOUNTING PLATE",
      "PRODUCT CATEGORY": "YPG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "A210306000011",
      "DESCRIPTION": "NUT",
      "PRODUCT CATEGORY": "YPG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "A229900003218",
      "DESCRIPTION": "CONNECTOR UPPER MIXER",
      "PRODUCT CATEGORY": "YPG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "A229900005597",
      "DESCRIPTION": "RIGHT ANGLE TERMINAL ADAPTOR",
      "PRODUCT CATEGORY": "YPG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "A239900000410",
      "DESCRIPTION": "NYLON HOSE (PER CM)",
      "PRODUCT CATEGORY": "YPG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 5223228934,
      "DESCRIPTION": "STEERING GEAR",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "01643-30823",
      "DESCRIPTION": "WASHER",
      "PRODUCT CATEGORY": "SDG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 2,
      "STD": 1.10940039245045,
      "MEAN": 0.307692307692307,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 3.000000000000003
    },
    {
      "PART NUMBER": "130201000042A003",
      "DESCRIPTION": "HIGH AND LOW SHIFT SWITCH",
      "PRODUCT CATEGORY": "YTG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.583333333333333,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 2,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 0,
      "STD": 1.9414506867883,
      "MEAN": 0.538461538461538,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 3.42857142857143
    },
    {
      "PART NUMBER": 14685160,
      "DESCRIPTION": "BATTERY CABLE",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4110001908,
      "DESCRIPTION": "RADIATOR",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14622367,
      "DESCRIPTION": "BRACKET",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 9,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14622369,
      "DESCRIPTION": "WIRING HARNESS",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 1,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14640075,
      "DESCRIPTION": "HOSE 14618177",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 1,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "9325-10045",
      "DESCRIPTION": "SPRING PIN",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.666666666666666,
      "MAINTANING STOCK QTY": 3,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 3,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 0,
      "STD": 2.21880078490091,
      "MEAN": 0.615384615384615,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 4.500000000000004
    },
    {
      "PART NUMBER": 14532411,
      "DESCRIPTION": "SHAFT",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 0,
      "STD": 1.10940039245045,
      "MEAN": 0.307692307692307,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 3.000000000000003
    },
    {
      "PART NUMBER": 14532307,
      "DESCRIPTION": "BUSHING",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.666666666666666,
      "MAINTANING STOCK QTY": 3,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 3,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 0,
      "STD": 2.21880078490091,
      "MEAN": 0.615384615384615,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 4.500000000000004
    },
    {
      "PART NUMBER": "329569Z00B",
      "DESCRIPTION": "O-RING",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 32,
      "WHXX": 9,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "8216-MG-411561-01",
      "DESCRIPTION": "TRACK ASS'Y DH24",
      "PRODUCT CATEGORY": "SDG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.666666666666666,
      "MAINTANING STOCK QTY": 3,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 3,
      "APRD QTY": 3,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 1.5,
      "STD": 1.70970082853022,
      "MEAN": 0.615384615384615,
      "COV": 2.7782638463616,
      "SHELF LIFE_1": 4.500000000000004
    },
    {
      "PART NUMBER": 14388290,
      "DESCRIPTION": "CLAMP",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 8,
      "WH REFERENCE": "WHXX",
      "TREND": 0,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": 14640071,
      "DESCRIPTION": "HOSE",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 1,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14566371,
      "DESCRIPTION": "CLIP",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 9,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14554917,
      "DESCRIPTION": "CLUTCH",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "C63211",
      "DESCRIPTION": "ROD PLUG",
      "PRODUCT CATEGORY": "SOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 0,
      "STD": 1.10940039245045,
      "MEAN": 0.307692307692307,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 3.000000000000003
    },
    {
      "PART NUMBER": 60185935,
      "DESCRIPTION": "DISTANCE ROD ASSEMBLY",
      "PRODUCT CATEGORY": "YCG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": 612600062230,
      "DESCRIPTION": "WEICHAI BELT",
      "PRODUCT CATEGORY": "ZZZ",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.75,
      "MAINTANING STOCK QTY": 3,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 3,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 0,
      "STD": 2.49615088301353,
      "MEAN": 0.692307692307692,
      "COV": 3.60555127546399,
      "SHELF LIFE_1": 4
    },
    {
      "PART NUMBER": 14550599,
      "DESCRIPTION": "SHAFT",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "SSC61267",
      "DESCRIPTION": "FRONT COVER",
      "PRODUCT CATEGORY": "SOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "C71113",
      "DESCRIPTION": "DIAPHRAGM",
      "PRODUCT CATEGORY": "SOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 940337,
      "DESCRIPTION": "RING TERMINAL",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 11039713,
      "DESCRIPTION": "BRACKET",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14587774,
      "DESCRIPTION": "FUSE HOLDER",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 977973,
      "DESCRIPTION": "RING TERMINAL",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 24076067,
      "DESCRIPTION": "WHEEL STUD",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.833333333333333,
      "MAINTANING STOCK QTY": 3,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 3,
      "APRD QTY": 3,
      "WH12": 214,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 2,
      "STD": 2.77350098112614,
      "MEAN": 0.769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 3.6000000000000014
    },
    {
      "PART NUMBER": 784112,
      "DESCRIPTION": "COVER",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 16352431,
      "DESCRIPTION": "BEARING HOUSING 16809376",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 1,
      "STD": 0.75106761619881,
      "MEAN": 0.307692307692307,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 3.000000000000003
    },
    {
      "PART NUMBER": 60112995,
      "DESCRIPTION": "HEX. SOCKET SCREW 13959262",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.583333333333333,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 2,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 12,
      "WH REFERENCE": "WHXX",
      "TREND": 0.29,
      "STD": 1.66410058867568,
      "MEAN": 0.538461538461538,
      "COV": 3.09047252182627,
      "SHELF LIFE_1": 3.42857142857143
    },
    {
      "PART NUMBER": 4043003091,
      "DESCRIPTION": "PIN",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4190001196,
      "DESCRIPTION": "SEALING RING",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 0,
      "STD": 1.10940039245045,
      "MEAN": 0.307692307692307,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 3.000000000000003
    },
    {
      "PART NUMBER": 4043000395,
      "DESCRIPTION": "SHIM",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 0,
      "STD": 1.10940039245045,
      "MEAN": 0.307692307692307,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 3.000000000000003
    },
    {
      "PART NUMBER": 26170013901,
      "DESCRIPTION": "BUSHING",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 0,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": 17391236,
      "DESCRIPTION": "LEVEL",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 53032790,
      "DESCRIPTION": "ELECTRONIC UNIT 11381209 11388082 11443993",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 46827785,
      "DESCRIPTION": "SWITCH, LLOP, 0.8 BAR, NO TYPE",
      "PRODUCT CATEGORY": "DOP",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "156-40-16000",
      "DESCRIPTION": "BUSHING",
      "PRODUCT CATEGORY": "SDG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 0,
      "STD": 1.10940039245045,
      "MEAN": 0.307692307692307,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 3.000000000000003
    },
    {
      "PART NUMBER": "156-40-15000",
      "DESCRIPTION": "BUSHING",
      "PRODUCT CATEGORY": "SDG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 0,
      "STD": 1.10940039245045,
      "MEAN": 0.307692307692307,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 3.000000000000003
    },
    {
      "PART NUMBER": "115H-31-00002",
      "DESCRIPTION": "SHAFT",
      "PRODUCT CATEGORY": "SDG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 0,
      "STD": 1.10940039245045,
      "MEAN": 0.307692307692307,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 3.000000000000003
    },
    {
      "PART NUMBER": "07177-09040",
      "DESCRIPTION": "BUSHING",
      "PRODUCT CATEGORY": "SDG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 1.5,
      "MAINTANING STOCK QTY": 6,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 6,
      "APRD QTY": 6,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 1.33,
      "STD": 3.59486813709166,
      "MEAN": 1.38461538461538,
      "COV": 2.5962936545662,
      "SHELF LIFE_1": 4
    },
    {
      "PART NUMBER": "156-40-00004",
      "DESCRIPTION": "SEAT",
      "PRODUCT CATEGORY": "SDG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 0,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": 22771624,
      "DESCRIPTION": "CONTROL UNIT             ",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.416666666666666,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 2,
      "APRD QTY": 2,
      "WH12": 6,
      "WHXX": 1,
      "WH REFERENCE": "WH12 + WHXX",
      "TREND": 1.6,
      "STD": 1.12089707663561,
      "MEAN": 0.384615384615384,
      "COV": 2.91433239925258,
      "SHELF LIFE_1": 4.800000000000008
    },
    {
      "PART NUMBER": 16668459,
      "DESCRIPTION": "GASKET",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "115H-18-A0001V020-CD",
      "DESCRIPTION": "FINAL DRIVE LEFT",
      "PRODUCT CATEGORY": "SDG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "115H-18-A0002V020-CD",
      "DESCRIPTION": "FINAL DRIVE RIGHT",
      "PRODUCT CATEGORY": "SDG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 54864268,
      "DESCRIPTION": "FUEL FILTER HOUSING 11110708",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.25,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 23,
      "WHXX": 28,
      "WH REFERENCE": "WHXX",
      "TREND": 2,
      "STD": 0.599144689515278,
      "MEAN": 0.23076923076923,
      "COV": 2.5962936545662,
      "SHELF LIFE_1": 4
    },
    {
      "PART NUMBER": 937483,
      "DESCRIPTION": "HOSE ASSEMBLY",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 15800781,
      "DESCRIPTION": "HOSE ASSEMBLY",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 17386372,
      "DESCRIPTION": "EXHAUST BRAKE",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 16879262,
      "DESCRIPTION": "EXHAUST PIPE",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 16848496,
      "DESCRIPTION": "EXHAUST PIPE",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 7161979,
      "DESCRIPTION": "KIT LIFT 4-POINT",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 7153379,
      "DESCRIPTION": "LEVER, STEERING",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 7145147,
      "DESCRIPTION": "LEVER, STEERING",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 938450,
      "DESCRIPTION": "HOSE ASSEMBLY",
      "PRODUCT CATEGORY": "VOH",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14713405,
      "DESCRIPTION": "HOSE ASSEMBLY",
      "PRODUCT CATEGORY": "VOH",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 20459925,
      "DESCRIPTION": "VIBRATION DAMPER",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 60273569,
      "DESCRIPTION": "EVAPORATOR CORE",
      "PRODUCT CATEGORY": "YPG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 17201959,
      "DESCRIPTION": "BOWL",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 23658635,
      "DESCRIPTION": "CONTROL UNIT ",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 24318489,
      "DESCRIPTION": "COOLANT PUMP 23974640",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 30,
      "WHXX": 10,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4110015996,
      "DESCRIPTION": "FAN 4110002109",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": 60222779,
      "DESCRIPTION": "FILTER CORE ",
      "PRODUCT CATEGORY": "YCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14633611,
      "DESCRIPTION": "PUMP 14553215",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "175-30-24131",
      "DESCRIPTION": "RIVETING ASSY",
      "PRODUCT CATEGORY": "SDG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 1.16666666666666,
      "MAINTANING STOCK QTY": 5,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 5,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 0.86,
      "STD": 2.6602486870447,
      "MEAN": 1.07692307692307,
      "COV": 2.47023092368436,
      "SHELF LIFE_1": 4.28571428571431
    },
    {
      "PART NUMBER": "156-31-02000",
      "DESCRIPTION": "OIL SEAL COMPONENT",
      "PRODUCT CATEGORY": "SDG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.666666666666666,
      "MAINTANING STOCK QTY": 3,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 3,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 0,
      "STD": 2.21880078490091,
      "MEAN": 0.615384615384615,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 4.500000000000004
    },
    {
      "PART NUMBER": "GB9163-GE60ES",
      "DESCRIPTION": "JOINT BEARING",
      "PRODUCT CATEGORY": "SDG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 0,
      "STD": 1.10940039245045,
      "MEAN": 0.307692307692307,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 3.000000000000003
    },
    {
      "PART NUMBER": "04065-09030",
      "DESCRIPTION": "RETAINER",
      "PRODUCT CATEGORY": "SDG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.666666666666666,
      "MAINTANING STOCK QTY": 3,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 3,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 0,
      "STD": 2.21880078490091,
      "MEAN": 0.615384615384615,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 4.500000000000004
    },
    {
      "PART NUMBER": "156-31-00002",
      "DESCRIPTION": "BUSTED 45¬†",
      "PRODUCT CATEGORY": "SDG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 0,
      "STD": 1.10940039245045,
      "MEAN": 0.307692307692307,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 3.000000000000003
    },
    {
      "PART NUMBER": 7171222,
      "DESCRIPTION": "BRACKET BLOCK FUSE",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 7223425,
      "DESCRIPTION": "DECAL, FUSE",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 7143971,
      "DESCRIPTION": "SEATBAR",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 7329955,
      "DESCRIPTION": "BRACKET ARM",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 0,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": "10C616",
      "DESCRIPTION": "BOLT SHRT SQ NK RHD 3/8-16X1 GRD 5",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 0,
      "STD": 1.10940039245045,
      "MEAN": 0.307692307692307,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 3.000000000000003
    },
    {
      "PART NUMBER": "111379Z01D",
      "DESCRIPTION": "SEALING RING",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 0,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": "E93446",
      "DESCRIPTION": "WEAR PLATE",
      "PRODUCT CATEGORY": "SOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.5,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 2,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 0,
      "STD": 1.66410058867568,
      "MEAN": 0.461538461538461,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 4
    },
    {
      "PART NUMBER": "E93447",
      "DESCRIPTION": "WEAR PLATE(D/C)",
      "PRODUCT CATEGORY": "SOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.5,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 2,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 0,
      "STD": 1.66410058867568,
      "MEAN": 0.461538461538461,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 4
    },
    {
      "PART NUMBER": 12242300,
      "DESCRIPTION": "LEFT HEADLIGHT COVER R",
      "PRODUCT CATEGORY": "YTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 5223822029,
      "DESCRIPTION": "SHOCK ABSORBER (FRONT -LH)",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 5223822030,
      "DESCRIPTION": "SHOCK ABSORBER (FRONT -RH)",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14506388,
      "DESCRIPTION": "GAUGE",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 21671171,
      "DESCRIPTION": "TIMING GEAR PLATE",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 23468467,
      "DESCRIPTION": "CYLINDER LINER KIT",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14733931,
      "DESCRIPTION": "TRACK LINK",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.5,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 2,
      "APRD QTY": 2,
      "WH12": 0,
      "WHXX": 12,
      "WH REFERENCE": "WHXX",
      "TREND": 2,
      "STD": 1.66410058867568,
      "MEAN": 0.461538461538461,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 4
    },
    {
      "PART NUMBER": 14733932,
      "DESCRIPTION": "TRACK LINK",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.5,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 2,
      "APRD QTY": 2,
      "WH12": 0,
      "WHXX": 11,
      "WH REFERENCE": "WHXX",
      "TREND": 2,
      "STD": 1.66410058867568,
      "MEAN": 0.461538461538461,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 4
    },
    {
      "PART NUMBER": 4120004030,
      "DESCRIPTION": "HYDRAULIC LOCK",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 5222748121,
      "DESCRIPTION": "SNAP RING",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 7,
      "WHXX": 10,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 7496373,
      "DESCRIPTION": "CAP CANISTER HYDRAULIC",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 41,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 7163421,
      "DESCRIPTION": "PEDAL ASSEMBLY",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": 24159417,
      "DESCRIPTION": "HEADLAMP                 ",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 5,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 2,
      "STD": 0.630425171956115,
      "MEAN": 0.307692307692307,
      "COV": 2.04888180885737,
      "SHELF LIFE_1": 3.000000000000003
    },
    {
      "PART NUMBER": 7312863,
      "DESCRIPTION": "ENGINE HARNESS",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 7281000,
      "DESCRIPTION": "CAB HARNESS",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 7007303,
      "DESCRIPTION": "HARNESS",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 7004806,
      "DESCRIPTION": "INSERT PARKING BRAKE",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 7004916,
      "DESCRIPTION": "SWITCH ROCKER",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 7006812,
      "DESCRIPTION": "LAMP PILOT",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4190000037,
      "DESCRIPTION": "MASTER CYLINDER",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 983521,
      "DESCRIPTION": "O-RING",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 12,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 24159415,
      "DESCRIPTION": "HEADLAMP                 ",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 6,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": 12766934,
      "DESCRIPTION": "MACHINING DRAWING",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "542159Z01E",
      "DESCRIPTION": "PIN",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 1,
      "MAINTANING STOCK QTY": 4,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 4,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 16,
      "WH REFERENCE": "WHXX",
      "TREND": 0.17,
      "STD": 3.0403272428457,
      "MEAN": 0.923076923076923,
      "COV": 3.29368784641617,
      "SHELF LIFE_1": 4
    },
    {
      "PART NUMBER": 13722787,
      "DESCRIPTION": "QS762 SEALING PACKAGE",
      "PRODUCT CATEGORY": "YCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "01010-52080",
      "DESCRIPTION": "BOLT M20X80",
      "PRODUCT CATEGORY": "SDG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 5.33333333333333,
      "MAINTANING STOCK QTY": 21,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 21,
      "APRD QTY": 21,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 2,
      "STD": 13.6776066282417,
      "MEAN": 4.92307692307692,
      "COV": 2.7782638463616,
      "SHELF LIFE_1": 3.937500000000002
    },
    {
      "PART NUMBER": 16850066,
      "DESCRIPTION": "COOLING COIL",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 2,
      "STD": 0.375533808099405,
      "MEAN": 0.153846153846153,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": "01010-82045",
      "DESCRIPTION": "BOLT",
      "PRODUCT CATEGORY": "SDG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 1.33333333333333,
      "MAINTANING STOCK QTY": 5,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 5,
      "APRD QTY": 5,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 2,
      "STD": 4.43760156980183,
      "MEAN": 1.23076923076923,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 3.7500000000000098
    },
    {
      "PART NUMBER": 11050593,
      "DESCRIPTION": "RUBBER CUSHION",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 2,
      "STD": 1.10940039245045,
      "MEAN": 0.307692307692307,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 3.000000000000003
    },
    {
      "PART NUMBER": 11060194,
      "DESCRIPTION": "SPACER",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 2,
      "STD": 1.10940039245045,
      "MEAN": 0.307692307692307,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 3.000000000000003
    },
    {
      "PART NUMBER": 4011000915,
      "DESCRIPTION": "SCREW",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 2,
      "STD": 1.10940039245045,
      "MEAN": 0.307692307692307,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 3.000000000000003
    },
    {
      "PART NUMBER": 4110000453,
      "DESCRIPTION": "ABSORBER",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 4,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 2,
      "STD": 1.10940039245045,
      "MEAN": 0.307692307692307,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 3.000000000000003
    },
    {
      "PART NUMBER": 29310024461,
      "DESCRIPTION": "WASHER",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 2,
      "STD": 1.10940039245045,
      "MEAN": 0.307692307692307,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 3.000000000000003
    },
    {
      "PART NUMBER": 4011000378,
      "DESCRIPTION": "BOLT",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": 17476848,
      "DESCRIPTION": "HYDRAULIC OIL COOLER",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": 4130003515,
      "DESCRIPTION": "EVAPORATOR",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 2,
      "STD": 1.10940039245045,
      "MEAN": 0.307692307692307,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 3.000000000000003
    },
    {
      "PART NUMBER": 4130003052,
      "DESCRIPTION": "AIR CONDITIONING COMPRESSOR",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4190002199,
      "DESCRIPTION": "EXPANSION VALVE",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4190002201,
      "DESCRIPTION": "VALVE",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "B222100000561",
      "DESCRIPTION": "WATER SEPARATOR",
      "PRODUCT CATEGORY": "YCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 60108785,
      "DESCRIPTION": "ROLLER",
      "PRODUCT CATEGORY": "YPG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 1.66666666666666,
      "MAINTANING STOCK QTY": 7,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 7,
      "APRD QTY": 7,
      "WH12": 5,
      "WHXX": 0,
      "WH REFERENCE": "PARTIAL WH12",
      "TREND": 2,
      "STD": 5.54700196225229,
      "MEAN": 1.53846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 4.200000000000016
    },
    {
      "PART NUMBER": 4190002195,
      "DESCRIPTION": "CONDENSER",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 4130002911009,
      "DESCRIPTION": "HOSE",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": 12825949,
      "DESCRIPTION": "WIRING HARNESS",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 16054756,
      "DESCRIPTION": "WIRING HARNESS",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 12814015,
      "DESCRIPTION": "PLATE",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 13914197,
      "DESCRIPTION": "TENSION SPRING",
      "PRODUCT CATEGORY": "VCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 59929059,
      "DESCRIPTION": "CLEVIS",
      "PRODUCT CATEGORY": "VCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14671838,
      "DESCRIPTION": "TERMINAL",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 29350010201,
      "DESCRIPTION": "WATER PIPE",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.25,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 2,
      "STD": 0.832050294337843,
      "MEAN": 0.23076923076923,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 4
    },
    {
      "PART NUMBER": 29350010211,
      "DESCRIPTION": "WATER PIPE",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.25,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 2,
      "STD": 0.832050294337843,
      "MEAN": 0.23076923076923,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 4
    },
    {
      "PART NUMBER": 4190002907007,
      "DESCRIPTION": "HOSE",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": "117K-01-00020",
      "DESCRIPTION": "COVER",
      "PRODUCT CATEGORY": "SDG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 2,
      "STD": 0.375533808099405,
      "MEAN": 0.153846153846153,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": 14558286,
      "DESCRIPTION": "WIRING HARNESS",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "TP1000544065",
      "DESCRIPTION": "OUTLET PIPE GASKET",
      "PRODUCT CATEGORY": "SDG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.5,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 2,
      "APRD QTY": 2,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 2,
      "STD": 1.66410058867568,
      "MEAN": 0.461538461538461,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 4
    },
    {
      "PART NUMBER": "TP610800110416",
      "DESCRIPTION": "EXHAUST PIPE BOLT",
      "PRODUCT CATEGORY": "SDG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.916666666666666,
      "MAINTANING STOCK QTY": 4,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 4,
      "APRD QTY": 4,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 2,
      "STD": 3.05085107923876,
      "MEAN": 0.846153846153846,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 4.363636363636367
    },
    {
      "PART NUMBER": "TP612600116274",
      "DESCRIPTION": "EXHAUST PIPE BOLT",
      "PRODUCT CATEGORY": "SDG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "TP90011420036",
      "DESCRIPTION": "HEXAGON FLANGE BOLT",
      "PRODUCT CATEGORY": "SDG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 2,
      "STD": 1.10940039245045,
      "MEAN": 0.307692307692307,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 3.000000000000003
    },
    {
      "PART NUMBER": "TP615G60110032",
      "DESCRIPTION": "SHIM",
      "PRODUCT CATEGORY": "SDG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 29170085771,
      "DESCRIPTION": "WEAR PLATE",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "402069Z00D",
      "DESCRIPTION": "BRAKE DRUM",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 24040481,
      "DESCRIPTION": "STARTER MOTOR 22851149",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14259076,
      "DESCRIPTION": "RIGHT FRONT WHEEL REAR DASHBOARD ANTI-SPLASH",
      "PRODUCT CATEGORY": "YPG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14150767,
      "DESCRIPTION": "RIGHT FRONT HEADLAMP POLE ASSEMBLY",
      "PRODUCT CATEGORY": "YPG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 12052718,
      "DESCRIPTION": "RIGHT WHEEL MIDDLE DECORATION STRIP",
      "PRODUCT CATEGORY": "YPG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 13275025,
      "DESCRIPTION": "FRONT WHEEL DASHBOARD LEFT CENTRAL SUPPORT",
      "PRODUCT CATEGORY": "YPG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 13025425,
      "DESCRIPTION": "ASSY-JR,3-GRADE PEDAL, RH",
      "PRODUCT CATEGORY": "YPG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14259072,
      "DESCRIPTION": "250H ANTI SPLASH REAR BAFFLE",
      "PRODUCT CATEGORY": "YPG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14259073,
      "DESCRIPTION": "MUDFLAP",
      "PRODUCT CATEGORY": "YPG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14259074,
      "DESCRIPTION": "DASHBOARD STRIP",
      "PRODUCT CATEGORY": "YPG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "156-40-00013",
      "DESCRIPTION": "SHIELD BOLT",
      "PRODUCT CATEGORY": "SDG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 3,
      "MAINTANING STOCK QTY": 12,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 12,
      "APRD QTY": 12,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 2,
      "STD": 9.98460353205412,
      "MEAN": 2.76923076923076,
      "COV": 3.60555127546399,
      "SHELF LIFE_1": 4
    },
    {
      "PART NUMBER": "01580-12218",
      "DESCRIPTION": "NUT M22",
      "PRODUCT CATEGORY": "SDG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 2.5,
      "MAINTANING STOCK QTY": 10,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 10,
      "APRD QTY": 10,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 2,
      "STD": 8.32050294337843,
      "MEAN": 2.3076923076923,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 4
    },
    {
      "PART NUMBER": "156-40-00014",
      "DESCRIPTION": "SLEEVE",
      "PRODUCT CATEGORY": "SDG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 2.5,
      "MAINTANING STOCK QTY": 10,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 10,
      "APRD QTY": 10,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 2,
      "STD": 8.32050294337843,
      "MEAN": 2.3076923076923,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 4
    },
    {
      "PART NUMBER": "01010-51235",
      "DESCRIPTION": "BOLT M12X35",
      "PRODUCT CATEGORY": "SDG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 1.33333333333333,
      "MAINTANING STOCK QTY": 5,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 5,
      "APRD QTY": 5,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 2,
      "STD": 4.43760156980183,
      "MEAN": 1.23076923076923,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 3.7500000000000098
    },
    {
      "PART NUMBER": "156-40-02004",
      "DESCRIPTION": "NUT",
      "PRODUCT CATEGORY": "SDG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 2,
      "STD": 1.10940039245045,
      "MEAN": 0.307692307692307,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 3.000000000000003
    },
    {
      "PART NUMBER": "156-40-02005",
      "DESCRIPTION": "LINING BLOCK",
      "PRODUCT CATEGORY": "SDG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 2,
      "STD": 1.10940039245045,
      "MEAN": 0.307692307692307,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 3.000000000000003
    },
    {
      "PART NUMBER": "14Y-40-02100",
      "DESCRIPTION": "GRASE GUN",
      "PRODUCT CATEGORY": "SDG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 2,
      "STD": 1.10940039245045,
      "MEAN": 0.307692307692307,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 3.000000000000003
    },
    {
      "PART NUMBER": "07021-00890",
      "DESCRIPTION": "OIL CUP 90 ¬∞ M8X1",
      "PRODUCT CATEGORY": "SDG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 2,
      "STD": 1.10940039245045,
      "MEAN": 0.307692307692307,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 3.000000000000003
    },
    {
      "PART NUMBER": "14Y-40-02200",
      "DESCRIPTION": "BAFFLE",
      "PRODUCT CATEGORY": "SDG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 2,
      "STD": 1.10940039245045,
      "MEAN": 0.307692307692307,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 3.000000000000003
    },
    {
      "PART NUMBER": 13255240,
      "DESCRIPTION": "BUMPER LOWER BRACKET",
      "PRODUCT CATEGORY": "YTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 17222542,
      "DESCRIPTION": "WIRING HARNESS",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 11223889,
      "DESCRIPTION": "SEALING RING",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": 11223888,
      "DESCRIPTION": "SEALING RING",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": 11223886,
      "DESCRIPTION": "SEALING RING",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": 11223879,
      "DESCRIPTION": "O-RING",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": 11223869,
      "DESCRIPTION": "O-RING",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 4,
      "SHELF LIFE": 12.000000000000012,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 1.10940039245045,
      "MEAN": 0.307692307692307,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 12.000000000000012
    },
    {
      "PART NUMBER": 11223876,
      "DESCRIPTION": "O-RING",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": 14589168,
      "DESCRIPTION": "SEALING KIT",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "TP612650110003",
      "DESCRIPTION": "EXHAUST LINER",
      "PRODUCT CATEGORY": "SDG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.5,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 2,
      "APRD QTY": 2,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 2,
      "STD": 1.66410058867568,
      "MEAN": 0.461538461538461,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 4
    },
    {
      "PART NUMBER": "TP61260110162",
      "DESCRIPTION": "SHIM",
      "PRODUCT CATEGORY": "SDG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 2,
      "STD": 1.10940039245045,
      "MEAN": 0.307692307692307,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 3.000000000000003
    },
    {
      "PART NUMBER": 23961982,
      "DESCRIPTION": "REAR-VIEW MIRROR         ",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": "16.30.080.129",
      "DESCRIPTION": "BOLT",
      "PRODUCT CATEGORY": "PHG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.416666666666666,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 2,
      "APRD QTY": 2,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 2,
      "STD": 1.12089707663561,
      "MEAN": 0.384615384615384,
      "COV": 2.91433239925258,
      "SHELF LIFE_1": 4.800000000000008
    },
    {
      "PART NUMBER": 22935396,
      "DESCRIPTION": "U-BOLT              ",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 1,
      "MAINTANING STOCK QTY": 4,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 4,
      "APRD QTY": 4,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 2,
      "STD": 2.39657875806111,
      "MEAN": 0.923076923076923,
      "COV": 2.5962936545662,
      "SHELF LIFE_1": 4
    },
    {
      "PART NUMBER": "01011-82045 ",
      "DESCRIPTION": "BOLTS(IDLER)",
      "PRODUCT CATEGORY": "SDG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 4,
      "MAINTANING STOCK QTY": 16,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 16,
      "APRD QTY": 16,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 2,
      "STD": 13.3128047094055,
      "MEAN": 3.69230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 4
    },
    {
      "PART NUMBER": "SSL01123",
      "DESCRIPTION": "FRONT COVER/SILENCE/SB70 L01123",
      "PRODUCT CATEGORY": "SOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 14552493,
      "DESCRIPTION": "CABLE",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 22417696,
      "DESCRIPTION": "CABLE",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 23252913,
      "DESCRIPTION": "CONTROL UNIT             ",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 2,
      "STD": 1.10940039245045,
      "MEAN": 0.307692307692307,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 3.000000000000003
    },
    {
      "PART NUMBER": 7442381,
      "DESCRIPTION": "WINDOW RIGHT ASSEMBLY",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "TP1000410660",
      "DESCRIPTION": "TENSIONING WHEEL ASSEMBLY",
      "PRODUCT CATEGORY": "SDG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": "160101110018A088",
      "DESCRIPTION": "WATER SEPARATOR FILTER ELEMENT",
      "PRODUCT CATEGORY": "YCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 20578225,
      "DESCRIPTION": "POWER CABLE",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 2,
      "STD": 1.10940039245045,
      "MEAN": 0.307692307692307,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 3.000000000000003
    },
    {
      "PART NUMBER": 20774880,
      "DESCRIPTION": "WIRES",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": 14723445,
      "DESCRIPTION": "TRACK GUARD",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.666666666666666,
      "MAINTANING STOCK QTY": 3,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 3,
      "APRD QTY": 3,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 2,
      "STD": 2.21880078490091,
      "MEAN": 0.615384615384615,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 4.500000000000004
    },
    {
      "PART NUMBER": 992453,
      "DESCRIPTION": "FUSE",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.833333333333333,
      "MAINTANING STOCK QTY": 3,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 3,
      "APRD QTY": 3,
      "WH12": 25,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 2,
      "STD": 2.77350098112614,
      "MEAN": 0.769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 3.6000000000000014
    },
    {
      "PART NUMBER": 4110000509213,
      "DESCRIPTION": "HOLLOW SCREW",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": 23659479,
      "DESCRIPTION": "WIRES                    ",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 2,
      "STD": 1.10940039245045,
      "MEAN": 0.307692307692307,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 3.000000000000003
    },
    {
      "PART NUMBER": 22039393,
      "DESCRIPTION": "WIRING HARNESS 21311690",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 17367461,
      "DESCRIPTION": "HUB RETAINER",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 53489684,
      "DESCRIPTION": "ROLLER 14676340",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "30 DAY AVE": 0.5,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 2,
      "APRD QTY": 2,
      "WH12": 4,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 2,
      "STD": 1.66410058867568,
      "MEAN": 0.461538461538461,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 4
    },
    {
      "PART NUMBER": 23962007,
      "DESCRIPTION": "REAR-VIEW MIRROR         ",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": 4190003393,
      "DESCRIPTION": "HOSE",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": 4130002467,
      "DESCRIPTION": "HOSE",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": 4130002468,
      "DESCRIPTION": "HOSE",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "TP612600083020",
      "DESCRIPTION": "HIGH PRESSURE OIL PIPE ASSEMBLY",
      "PRODUCT CATEGORY": "SDG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": "TP61800060172",
      "DESCRIPTION": "BUILT IN THERMOSTAT",
      "PRODUCT CATEGORY": "SDG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": "TP680340065",
      "DESCRIPTION": "SEAL RING",
      "PRODUCT CATEGORY": "SDG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024
    },
    {
      "PART NUMBER": "TP612600160099",
      "DESCRIPTION": "OUTLET PIPE GASKET",
      "PRODUCT CATEGORY": "SDG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 23659350,
      "DESCRIPTION": "WIRES                    ",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "30 DAY AVE": 0.666666666666666,
      "MAINTANING STOCK QTY": 3,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 3,
      "APRD QTY": 3,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 2,
      "STD": 2.21880078490091,
      "MEAN": 0.615384615384615,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 4.500000000000004
    },
    {
      "PART NUMBER": "571747HOK",
      "DESCRIPTION": "SDLG HYDRAULIC OIL VG68",
      "PRODUCT CATEGORY": "SLUB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "30 DAY AVE": 48.3333333333333,
      "MAINTANING STOCK QTY": 193,
      "AVAILABLE": 300,
      "SHELF LIFE": 6.206896551724142,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3980,
      "WHXX": 2160,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 160.863056905316,
      "MEAN": 44.6153846153846,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.206896551724142
    },
    {
      "PART NUMBER": 17237235,
      "DESCRIPTION": "KIT",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    },
    {
      "PART NUMBER": 17237189,
      "DESCRIPTION": "TOOL KIT",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0
    }
  ];




export const VolvoCEGrid = [
  {
    headerText: 'ITEM CODE',
    field: 'ITEM CODE',
    textAlign: 'Center',
    width: '12%',
  },
  {
    headerText: 'PART NUMBER',
    field: 'PART NUMBER',
    textAlign: 'Left',
    width: '12%',
  },
  {
    headerText: 'DESCRIPTION',
    field: 'DESCRIPTION',
    textAlign: 'Left',
    width: '20%',
  },
  {
    headerText: 'CAT',
    field: 'CAT',
    textAlign: 'Center',
    width: '9%',
  },
  {
    headerText: 'ON HAND',
    field: 'ON HAND',
    textAlign: 'Center',
    width: '10%',
  },
  {
    headerText: 'SHELF LIFE MTHS',
    field: 'SHELF LIFE MTHS',
    textAlign: 'Center',
    width: '11%',
    format: "N2"
  },
  {
    headerText: 'CURRENT STOCKING STATUS',
    field: 'CURRENT STOCKING STATUS',
    textAlign: 'Center',
    width: '15%',
  },
  {
    headerText: 'AVE. 30 DAYS',
    field: 'AVE 30 DAYS',
    textAlign: 'Center',
    width: '1%',
  },
  {
    headerText: 'TREND',
    field: 'TREND',
    textAlign: 'Center',
    width: '10%',
    format: 'p2',
  },
  {
    headerText: 'ON ORDER',
    field: 'ON ORDER',
    textAlign: 'Center',
    width: '12%',
  },
  {
    headerText: 'ON BACKORDER',
    field: 'ON BACKORDER',
    textAlign: 'Center',
    width: '9%',
  },
  {
    headerText: 'ROQ',
    field: 'ROQ',
    textAlign: 'Center',
    width: '8%',
  },
  {
    headerText: 'APPROVED QTY',
    field: 'APPROVED QTY',
    textAlign: 'Center',
    width: '10%',
  },
  {
    headerText: 'TOTAL',
    field: 'TOTAL',
    textAlign: 'Center',
    width: '10%',
  },
];

export const replenishGrid = [
  {
    headerText: 'PART NUM',
    field: 'PART NUMBER',
    textAlign: 'Left',
    width: '15%',
  },
  {
    headerText: 'DESCRIPTION',
    field: 'DESCRIPTION',
    textAlign: 'Left',
    width: '20%',
  },
  {
    headerText: 'CAT',
    field: 'PRODUCT CATEGORY',
    textAlign: 'Center',
    width: '6%',
  },
  {
    headerText: 'BR MVMT',
    field: 'ITEM MOVEMENT BRANCH',
    textAlign: 'Center',
    width: '10%',
  },
  {
    headerText: 'PWIDE MVMV',
    field: 'ITEM MOVEMENT PHILWIDE',
    textAlign: 'Center',
    width: '10%',
  },
  {
    headerText: '30 DAY',
    field: '30 DAY AVE',
    textAlign: 'Center',
    width: '6%',
    format: 'N1',
  },
  {
    headerText: 'M STK QTY',
    field: 'MAINTANING STOCK QTY',
    textAlign: 'Center',
    width: '6%',
  },
  {
    headerText: 'AVAIL',
    field: 'AVAILABLE',
    textAlign: 'Center',
    width: '6%',
  },
  {
    headerText: 'REC QTY',
    field: 'ROQ',
    textAlign: 'Center',
    width: '6%',
  },
  {
    headerText: 'APRV QTY',
    field: 'APRD QTY',
    textAlign: 'Center',
    width: '6%',
  },
  {
    headerText: 'WH12',
    field: 'WH12',
    textAlign: 'Center',
    width: '6%',
  },
  {
    headerText: 'WHXX',
    field: 'WHXX',
    textAlign: 'Center',
    width: '6%',
  },
  {
    headerText: 'WH REF',
    field: 'WH REFERENCE',
    textAlign: 'Center',
    width: '6%',
  },
  {
    headerText: 'TREND',
    field: 'TREND',
    textAlign: 'Center',
    width: '6%',
    format: 'P1',
  },
  {
    headerText: 'CONS FC',
    field: 'SHELF LIFE',
    textAlign: 'Center',
    width: '8%',
    format: 'N1',
  },
];
export const replenishGrid2 = [
  {
    headerText: 'PART NUM',
    field: 'PART NUMBER',
    textAlign: 'Left',
    width: '15%',
  },
  {
    headerText: 'DESCRIPTION',
    field: 'DESCRIPTION',
    textAlign: 'Left',
    width: '20%',
  },
  {
    headerText: 'CAT',
    field: 'PRODUCT CATEGORY',
    textAlign: 'Center',
    width: '6%',
  },
  {
    headerText: 'BR MVMT',
    field: 'ITEM MOVEMENT BRANCH',
    textAlign: 'Center',
    width: '10%',
  },
  {
    headerText: 'PWIDE MVMV',
    field: 'ITEM MOVEMENT PHILWIDE',
    textAlign: 'Center',
    width: '10%',
  },
  {
    headerText: '30 DAY',
    field: '30 DAY AVE',
    textAlign: 'Center',
    width: '6%',
    format: 'N2',
  },
  {
    headerText: 'M STK QTY',
    field: 'MAINTANING STOCK QTY',
    textAlign: 'Center',
    width: '6%',
  },
  {
    headerText: 'AVAIL',
    field: 'AVAILABLE',
    textAlign: 'Center',
    width: '6%',
  },
  {
    headerText: 'REC QTY',
    field: 'ROQ',
    textAlign: 'Center',
    width: '6%',
  },
  {
    headerText: 'APRV QTY',
    field: 'APRD QTY',
    textAlign: 'Center',
    width: '6%',
  },
  {
    headerText: 'WH12',
    field: 'WH12',
    textAlign: 'Center',
    width: '6%',
  },
  {
    headerText: 'WH REF',
    field: 'WH REFERENCE',
    textAlign: 'Center',
    width: '6%',
  },
  {
    headerText: 'TREND',
    field: 'TREND',
    textAlign: 'Center',
    width: '6%',
    format: 'P1',
  },
];


export const replenishcompGrid = [
  {
    headerText: 'PART NUMBER',
    field: 'PART NUM',
    textAlign: 'Center',
    width: '15%',
  },
  {
    headerText: 'DESCRIPTION',
    field: 'DESCRIPTION',
    textAlign: 'Left',
    width: '20%',
  },
  {
    headerText: 'PRODUCT CATEGORY',
    field: 'CAT',
    textAlign: 'Left',
    width: '10%',
  },
  {
    headerText: 'ITEM MOVEMENT BRANCH',
    field: 'BR MVMT',
    textAlign: 'Center',
    width: '15%',
  },
  {
    headerText: 'ITEM MOVEMENT PHILWIDE',
    field: 'PWIDE MVMV',
    textAlign: 'Center',
    width: '15%',
  },
  {
    headerText: '30 DAY AVE',
    field: '30 DAY',
    textAlign: 'Center',
    width: '11%',
  },
  {
    headerText: 'MAINTANING STOCK QTY',
    field: 'M STK QTY',
    textAlign: 'Center',
    width: '15%',
  },
  {
    headerText: 'AVAILABLE',
    field: 'AVAIL',
    textAlign: 'Center',
    width: '10%',
  },
  {
    headerText: 'ROQ',
    field: 'REC QTY',
    textAlign: 'Center',
    width: '8%',
  },
  {
    headerText: 'APRD QTY',
    field: 'APRV QTY',
    textAlign: 'Center',
    width: '10%',
  },
  {
    headerText: 'WH12',
    field: 'WH12',
    textAlign: 'Center',
    width: '10%',
  },
  {
    headerText: 'WHXX',
    field: 'WHXX',
    textAlign: 'Center',
    width: '10%',
  },
  {
    headerText: 'WH REFERENCE',
    field: 'WH REF',
    textAlign: 'Center',
    width: '10%',
  },
  {
    headerText: 'TREND',
    field: 'TREND',
    textAlign: 'Center',
    width: '10%',
  },
  {
    headerText: 'STD',
    field: 'STD',
    textAlign: 'Center',
    width: '10%',
  },
  {
    headerText: 'MEAN',
    field: 'MEAN',
    textAlign: 'Center',
    width: '10%',
  },
  {
    headerText: 'COV',
    field: 'COV',
    textAlign: 'Center',
    width: '10%',
  },
  {
    headerText: 'CONS FC',
    field: 'CONS FC',
    textAlign: 'Center',
    width: '10%',
  },
  {
    headerText: 'STOCK STATUS',
    field: 'STOCK STATUS',
    textAlign: 'Center',
    width: '10%',
  },
];

export const ptrfGrid = [
  {
    headerText: 'PART NUM',
    field: 'PART NUMBER',
    textAlign: 'Left',
    width: '12%',
  },
  {
    headerText: 'CAT',
    field: 'PARTS CATEGORY',
    textAlign: 'Left',
    width: '8%',
  },
  {
    headerText: 'REC QTY',
    field: 'ROQ',
    textAlign: 'Left',
    width: '8%',
  },
  {
    headerText: 'APRV QTY',
    field: 'APPROVED QTY',
    textAlign: 'Left',
    width: '8%',
  },
  {
    headerText: 'UOM',
    field: 'UOM',
    textAlign: 'Left',
    width: '8%',
  },
  {
    headerText: 'REMARKS',
    field: 'REMARKS',
    textAlign: 'Left',
    width: '15%',
  },
  {
    headerText: 'WH REF',
    field: 'WH REF',
    textAlign: 'Left',
    width: '10%',
  },
  {
    headerText: 'BU',
    field: 'BU',
    textAlign: 'Left',
    width: '11%',
  },
];

export const ptrfGrid2 = [
  {
    headerText: 'PART NUM',
    field: 'PART NUMBER',
    textAlign: 'Left',
    width: '15%',
  },
  {
    headerText: 'CAT',
    field: 'PARTS CATEGORY',
    textAlign: 'Left',
    width: '6%',
  },
  {
    headerText: 'REC QTY',
    field: 'ROQ',
    textAlign: 'Center',
    width: '6%',
  },
  {
    headerText: 'APRV QTY',
    field: 'APPROVED QTY',
    textAlign: 'Center',
    width: '6%',
  },
  {
    headerText: 'UOM',
    field: 'UOM',
    textAlign: 'Center',
    width: '6%',
  },
  {
    headerText: 'PART NUM',
    field: 'PART NUMBER_1',
    textAlign: 'Left',
    width: '15%',
  },
  {
    headerText: 'CAT',
    field: 'PARTS CATEGORY_1',
    textAlign: 'Left',
    width: '6%',
  },
  {
    headerText: 'REC QTY',
    field: 'ROQ_1',
    textAlign: 'Center',
    width: '6%',
  },
  {
    headerText: 'APRV QTY',
    field: 'APPROVED QTY_1',
    textAlign: 'Center',
    width: '6%',
  },
  {
    headerText: 'UOM',
    field: 'UOM_1',
    textAlign: 'Center',
    width: '6%',
  },
  {
    headerText: 'REMARKS',
    field: 'REMARKS',
    textAlign: 'Center',
    width: '10%',
  },
  {
    headerText: 'WH REF',
    field: 'WH REF',
    textAlign: 'Center',
    width: '6%',
  },
  {
    headerText: 'BU',
    field: 'BU',
    textAlign: 'Center',
    width: '10%',
  },
];


export const ptrfData2 = [
    {
      "PART NUMBER": "A229900004769",
      "PARTS CATEGORY": "YPG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "A229900004769",
      "PARTS CATEGORY_1": "YPG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 60060763,
      "PARTS CATEGORY": "YPG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 60060763,
      "PARTS CATEGORY_1": "YPG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 16001025,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 16001025,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": "B222100000392",
      "PARTS CATEGORY": "YPG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "B222100000392",
      "PARTS CATEGORY_1": "YPG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 4021000042,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4021000042,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 29250004011,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 29250004011,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 29250004021,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 29250004021,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14636301,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14636301,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 11700373,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 11700373,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4120000063,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4120000063,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 984728,
      "PARTS CATEGORY": "VTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 984728,
      "PARTS CATEGORY_1": "VTG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 15048183,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 15048183,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 21486081,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 21486081,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 990973,
      "PARTS CATEGORY": "VTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 990973,
      "PARTS CATEGORY_1": "VTG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": "A222100000582",
      "PARTS CATEGORY": "YPG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "A222100000582",
      "PARTS CATEGORY_1": "YPG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 1,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "CMI"
    },
    {
      "PART NUMBER": "A222100000574",
      "PARTS CATEGORY": "YPG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "A222100000574",
      "PARTS CATEGORY_1": "YPG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": "B222100000212",
      "PARTS CATEGORY": "YPG",
      "ROQ": 3,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "B222100000212",
      "PARTS CATEGORY_1": "YPG",
      "ROQ_1": 3,
      "APPROVED QTY_1": 3,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 95208971,
      "PARTS CATEGORY": "VCG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 95208971,
      "PARTS CATEGORY_1": "VCG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110000885,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4110000885,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 20460945,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 20460945,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14508324,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14508324,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 21581522,
      "PARTS CATEGORY": "VTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 21581522,
      "PARTS CATEGORY_1": "VTG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": "C01220",
      "PARTS CATEGORY": "SOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "C01220",
      "PARTS CATEGORY_1": "SOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 20459986,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 20459986,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 3030900232,
      "PARTS CATEGORY": "VLG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 3030900232,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 2030900061,
      "PARTS CATEGORY": "VLG",
      "ROQ": 4,
      "APPROVED QTY": 3,
      "UOM": "PC",
      "PART NUMBER_1": 2030900061,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 4,
      "APPROVED QTY_1": -3,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12 + WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 3030900110,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 3030900110,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 3030900111,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 3030900111,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 3030900140,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 3030900140,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 2030900028,
      "PARTS CATEGORY": "VLG",
      "ROQ": 6,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 2030900028,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 6,
      "APPROVED QTY_1": 6,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 29050011321,
      "PARTS CATEGORY": "VLG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 29050011321,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4021000015,
      "PARTS CATEGORY": "VLG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4021000015,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 2030900020,
      "PARTS CATEGORY": "VLG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 2030900020,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 3030900106,
      "PARTS CATEGORY": "VLG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 3030900106,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4021000016,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4021000016,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4021000024,
      "PARTS CATEGORY": "VLG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4021000024,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4015000026,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4015000026,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 930761,
      "PARTS CATEGORY": "VTG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 930761,
      "PARTS CATEGORY_1": "VTG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 1,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 4110001015031,
      "PARTS CATEGORY": "VLG",
      "ROQ": 2,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4110001015031,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 2,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110001015025,
      "PARTS CATEGORY": "VLG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4110001015025,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 20550001,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 20550001,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 60022614,
      "PARTS CATEGORY": "YPG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 60022614,
      "PARTS CATEGORY_1": "YPG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 85102464,
      "PARTS CATEGORY": "LUV",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 85102464,
      "PARTS CATEGORY_1": "LUV",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "LUBRICANTS"
    },
    {
      "PART NUMBER": "99990-1Z03J",
      "PARTS CATEGORY": "LUV",
      "ROQ": 111,
      "APPROVED QTY": 40,
      "UOM": "PC",
      "PART NUMBER_1": "99990-1Z03J",
      "PARTS CATEGORY_1": "LUV",
      "ROQ_1": 111,
      "APPROVED QTY_1": 80,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12 + WHXX",
      "BU": "LUBRICANTS"
    },
    {
      "PART NUMBER": 21244697,
      "PARTS CATEGORY": "VTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 21244697,
      "PARTS CATEGORY_1": "VTG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 984756,
      "PARTS CATEGORY": "VTG",
      "ROQ": 4,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 984756,
      "PARTS CATEGORY_1": "VTG",
      "ROQ_1": 4,
      "APPROVED QTY_1": 4,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": "99990-1Z05J",
      "PARTS CATEGORY": "LUV",
      "ROQ": 881,
      "APPROVED QTY": 140,
      "UOM": "PC",
      "PART NUMBER_1": "99990-1Z05J",
      "PARTS CATEGORY_1": "LUV",
      "ROQ_1": 881,
      "APPROVED QTY_1": 740,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12 + WHXX",
      "BU": "LUBRICANTS"
    },
    {
      "PART NUMBER": 984758,
      "PARTS CATEGORY": "VTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 984758,
      "PARTS CATEGORY_1": "VTG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 4130000912,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4130000912,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 43844471,
      "PARTS CATEGORY": "VCG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 43844471,
      "PARTS CATEGORY_1": "VCG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 60030172,
      "PARTS CATEGORY": "YPG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 60030172,
      "PARTS CATEGORY_1": "YPG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 4120002264401,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4120002264401,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4120002263401,
      "PARTS CATEGORY": "VLG",
      "ROQ": 1,
      "APPROVED QTY": 1,
      "UOM": "PC",
      "PART NUMBER_1": 4120002263401,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": "C61252",
      "PARTS CATEGORY": "SOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "C61252",
      "PARTS CATEGORY_1": "SOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": "C61169",
      "PARTS CATEGORY": "SOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "C61169",
      "PARTS CATEGORY_1": "SOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": "C61241",
      "PARTS CATEGORY": "SOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "C61241",
      "PARTS CATEGORY_1": "SOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": "D81150",
      "PARTS CATEGORY": "SOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "D81150",
      "PARTS CATEGORY_1": "SOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 11109241,
      "PARTS CATEGORY": "VSG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 11109241,
      "PARTS CATEGORY_1": "VSG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4043000015,
      "PARTS CATEGORY": "VLG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4043000015,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4043000043,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4043000043,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 17234545,
      "PARTS CATEGORY": "VOG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 17234545,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 1,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 17237872,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 17237872,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 17237890,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 17237890,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 984742,
      "PARTS CATEGORY": "VTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 984742,
      "PARTS CATEGORY_1": "VTG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 4043000031,
      "PARTS CATEGORY": "VLG",
      "ROQ": 2,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4043000031,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 2,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4120002264408,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4120002264408,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 990677,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 990677,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4130000875,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4130000875,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110001015055,
      "PARTS CATEGORY": "VLG",
      "ROQ": 1,
      "APPROVED QTY": 1,
      "UOM": "PC",
      "PART NUMBER_1": 4110001015055,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 11712176,
      "PARTS CATEGORY": "VOG",
      "ROQ": 2,
      "APPROVED QTY": 2,
      "UOM": "PC",
      "PART NUMBER_1": 11712176,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 2,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4013000203,
      "PARTS CATEGORY": "VLG",
      "ROQ": 12,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4013000203,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 12,
      "APPROVED QTY_1": 12,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110000697002,
      "PARTS CATEGORY": "VLG",
      "ROQ": 2,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4110000697002,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 2,
      "APPROVED QTY_1": 2,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110000697003,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4110000697003,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14532290,
      "PARTS CATEGORY": "VOG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14532290,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14552079,
      "PARTS CATEGORY": "VOG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14552079,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": "L01118",
      "PARTS CATEGORY": "SOG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "L01118",
      "PARTS CATEGORY_1": "SOG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 14578415,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14578415,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4130001229,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4130001229,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4030000789,
      "PARTS CATEGORY": "VLG",
      "ROQ": 3,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4030000789,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 3,
      "APPROVED QTY_1": 3,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 21720407,
      "PARTS CATEGORY": "VTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 21720407,
      "PARTS CATEGORY_1": "VTG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 21789668,
      "PARTS CATEGORY": "VTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 21789668,
      "PARTS CATEGORY_1": "VTG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 84035947,
      "PARTS CATEGORY": "VTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 84035947,
      "PARTS CATEGORY_1": "VTG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 4110000037001,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4110000037001,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": "A810102070176",
      "PARTS CATEGORY": "YPG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "A810102070176",
      "PARTS CATEGORY_1": "YPG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 21503575,
      "PARTS CATEGORY": "VTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 21503575,
      "PARTS CATEGORY_1": "VTG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 14530605,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14530605,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 21793275,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 21793275,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 59162149,
      "PARTS CATEGORY": "VCG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 59162149,
      "PARTS CATEGORY_1": "VCG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 60013610,
      "PARTS CATEGORY": "YPG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 60013610,
      "PARTS CATEGORY_1": "YPG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 21028667,
      "PARTS CATEGORY": "YCG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 21028667,
      "PARTS CATEGORY_1": "YCG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 1635242,
      "PARTS CATEGORY": "VTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 1635242,
      "PARTS CATEGORY_1": "VTG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 984735,
      "PARTS CATEGORY": "VTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 984735,
      "PARTS CATEGORY_1": "VTG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 4110001020,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4110001020,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 12787620,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 12787620,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 21063612,
      "PARTS CATEGORY": "VTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 21063612,
      "PARTS CATEGORY_1": "VTG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 60013292,
      "PARTS CATEGORY": "YCG",
      "ROQ": 4,
      "APPROVED QTY": 4,
      "UOM": "PC",
      "PART NUMBER_1": 60013292,
      "PARTS CATEGORY_1": "YCG",
      "ROQ_1": 4,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "CMI"
    },
    {
      "PART NUMBER": "B222100000235",
      "PARTS CATEGORY": "YCG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "B222100000235",
      "PARTS CATEGORY_1": "YCG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": "A222100000119",
      "PARTS CATEGORY": "YCG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "A222100000119",
      "PARTS CATEGORY_1": "YCG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 4043000338,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4043000338,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4089460,
      "PARTS CATEGORY": "CUG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4089460,
      "PARTS CATEGORY_1": "CUG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": 0
    },
    {
      "PART NUMBER": 29250000051,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 29250000051,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": "B222100000451",
      "PARTS CATEGORY": "YPG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "B222100000451",
      "PARTS CATEGORY_1": "YPG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": "4130000419A",
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "4130000419A",
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4130001685,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4130001685,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110000565088,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4110000565088,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 7200002385,
      "PARTS CATEGORY": "VLG",
      "ROQ": 27,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 7200002385,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 27,
      "APPROVED QTY_1": 27,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 15078846,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 15078846,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4120001805,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4120001805,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4120001715,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4120001715,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110000991027,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4110000991027,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110000054305,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4110000054305,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 21928687,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 21928687,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 20716956,
      "PARTS CATEGORY": "VTG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 20716956,
      "PARTS CATEGORY_1": "VTG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 1,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 14530651,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14530651,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14639653,
      "PARTS CATEGORY": "VOG",
      "ROQ": 1,
      "APPROVED QTY": 1,
      "UOM": "PC",
      "PART NUMBER_1": 14639653,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 17202573,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 17202573,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14595186,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14595186,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 20183372,
      "PARTS CATEGORY": "VCG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 20183372,
      "PARTS CATEGORY_1": "VCG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14529766,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14529766,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 13305198,
      "PARTS CATEGORY": "VCG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 13305198,
      "PARTS CATEGORY_1": "VCG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14558022,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14558022,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14558048,
      "PARTS CATEGORY": "VOG",
      "ROQ": 1,
      "APPROVED QTY": 1,
      "UOM": "PC",
      "PART NUMBER_1": 14558048,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 6213127,
      "PARTS CATEGORY": "VOG",
      "ROQ": 1,
      "APPROVED QTY": 1,
      "UOM": "PC",
      "PART NUMBER_1": 6213127,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 11991458,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 11991458,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14272462,
      "PARTS CATEGORY": "VCG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14272462,
      "PARTS CATEGORY_1": "VCG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 70893482,
      "PARTS CATEGORY": "VCG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 70893482,
      "PARTS CATEGORY_1": "VCG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 5292738,
      "PARTS CATEGORY": "CUG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 5292738,
      "PARTS CATEGORY_1": "CUG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": 0
    },
    {
      "PART NUMBER": 983311,
      "PARTS CATEGORY": "VOG",
      "ROQ": 2,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 983311,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 2,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110000189006,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4110000189006,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110000189005,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4110000189005,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110000501,
      "PARTS CATEGORY": "VLG",
      "ROQ": 1,
      "APPROVED QTY": 1,
      "UOM": "PC",
      "PART NUMBER_1": 4110000501,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": "A222100000575",
      "PARTS CATEGORY": "YCG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "A222100000575",
      "PARTS CATEGORY_1": "YCG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": "A222100000645",
      "PARTS CATEGORY": "YCG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "A222100000645",
      "PARTS CATEGORY_1": "YCG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 11418522,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 11418522,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": "7273-10930",
      "PARTS CATEGORY": "VSG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "7273-10930",
      "PARTS CATEGORY_1": "VSG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": "8320-02810",
      "PARTS CATEGORY": "VSG",
      "ROQ": 1,
      "APPROVED QTY": 1,
      "UOM": "PC",
      "PART NUMBER_1": "8320-02810",
      "PARTS CATEGORY_1": "VSG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 7346454,
      "PARTS CATEGORY": "VOG",
      "ROQ": 1,
      "APPROVED QTY": 1,
      "UOM": "PC",
      "PART NUMBER_1": 7346454,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 3104044,
      "PARTS CATEGORY": "VOG",
      "ROQ": 1,
      "APPROVED QTY": 1,
      "UOM": "PC",
      "PART NUMBER_1": 3104044,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14558040,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14558040,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14558041,
      "PARTS CATEGORY": "VOG",
      "ROQ": 4,
      "APPROVED QTY": 4,
      "UOM": "PC",
      "PART NUMBER_1": 14558041,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 4,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14558042,
      "PARTS CATEGORY": "VOG",
      "ROQ": 2,
      "APPROVED QTY": 2,
      "UOM": "PC",
      "PART NUMBER_1": 14558042,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 2,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14558046,
      "PARTS CATEGORY": "VOG",
      "ROQ": 1,
      "APPROVED QTY": 1,
      "UOM": "PC",
      "PART NUMBER_1": 14558046,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14558047,
      "PARTS CATEGORY": "VOG",
      "ROQ": 1,
      "APPROVED QTY": 1,
      "UOM": "PC",
      "PART NUMBER_1": 14558047,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14558049,
      "PARTS CATEGORY": "VOG",
      "ROQ": 1,
      "APPROVED QTY": 1,
      "UOM": "PC",
      "PART NUMBER_1": 14558049,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 11705188,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 11705188,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 11705193,
      "PARTS CATEGORY": "VOG",
      "ROQ": 4,
      "APPROVED QTY": 4,
      "UOM": "PC",
      "PART NUMBER_1": 11705193,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 4,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14558030,
      "PARTS CATEGORY": "VOG",
      "ROQ": 1,
      "APPROVED QTY": 1,
      "UOM": "PC",
      "PART NUMBER_1": 14558030,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14558031,
      "PARTS CATEGORY": "VOG",
      "ROQ": 1,
      "APPROVED QTY": 1,
      "UOM": "PC",
      "PART NUMBER_1": 14558031,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14558034,
      "PARTS CATEGORY": "VOG",
      "ROQ": 1,
      "APPROVED QTY": 1,
      "UOM": "PC",
      "PART NUMBER_1": 14558034,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14558035,
      "PARTS CATEGORY": "VOG",
      "ROQ": 1,
      "APPROVED QTY": 1,
      "UOM": "PC",
      "PART NUMBER_1": 14558035,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14558036,
      "PARTS CATEGORY": "VOG",
      "ROQ": 1,
      "APPROVED QTY": 1,
      "UOM": "PC",
      "PART NUMBER_1": 14558036,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14558037,
      "PARTS CATEGORY": "VOG",
      "ROQ": 1,
      "APPROVED QTY": 1,
      "UOM": "PC",
      "PART NUMBER_1": 14558037,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14558059,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14558059,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 11706773,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 11706773,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 11715510,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 11715510,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 11706719,
      "PARTS CATEGORY": "VSG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 11706719,
      "PARTS CATEGORY_1": "VSG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14577801,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14577801,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14577802,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14577802,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14589125,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14589125,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 21955032,
      "PARTS CATEGORY": "VTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 21955032,
      "PARTS CATEGORY_1": "VTG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 17216318,
      "PARTS CATEGORY": "VGG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 17216318,
      "PARTS CATEGORY_1": "VGG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": "A229900008854",
      "PARTS CATEGORY": "YCG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "A229900008854",
      "PARTS CATEGORY_1": "YCG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 13933868,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 13933868,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14543195,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14543195,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 1635244,
      "PARTS CATEGORY": "VTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 1635244,
      "PARTS CATEGORY_1": "VTG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 1635245,
      "PARTS CATEGORY": "VTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 1635245,
      "PARTS CATEGORY_1": "VTG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 946810,
      "PARTS CATEGORY": "VTG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 946810,
      "PARTS CATEGORY_1": "VTG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 1,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 14630975,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14630975,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 977005,
      "PARTS CATEGORY": "VTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 977005,
      "PARTS CATEGORY_1": "VTG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 14632032,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14632032,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 15082401,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 15082401,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 992248,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 992248,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4190002150,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4190002150,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 11705229,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 11705229,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": "23Y-58-11000",
      "PARTS CATEGORY": "SDG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "23Y-58-11000",
      "PARTS CATEGORY_1": "SDG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 17201798,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 17201798,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 29050011381,
      "PARTS CATEGORY": "VLG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 29050011381,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4030000011,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4030000011,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4021000012,
      "PARTS CATEGORY": "VLG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4021000012,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4021000019,
      "PARTS CATEGORY": "VLG",
      "ROQ": 2,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4021000019,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 2,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4021000020,
      "PARTS CATEGORY": "VLG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4021000020,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4021000040,
      "PARTS CATEGORY": "VLG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4021000040,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4021000013,
      "PARTS CATEGORY": "VLG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4021000013,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4021000011,
      "PARTS CATEGORY": "VLG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4021000011,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 3030900168,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 3030900168,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 3030900146,
      "PARTS CATEGORY": "VLG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 3030900146,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4021000076,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4021000076,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4030000012,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4030000012,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4030000731,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4030000731,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 16847926,
      "PARTS CATEGORY": "VOG",
      "ROQ": 3,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 16847926,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 3,
      "APPROVED QTY_1": 1,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "PARTIAL WH12",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 996519,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 996519,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14616326,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14616326,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 15107882,
      "PARTS CATEGORY": "VOG",
      "ROQ": 40,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 15107882,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 40,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4130000860,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4130000860,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 21900852,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 21900852,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 996518,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 996518,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 21615194,
      "PARTS CATEGORY": "VTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 21615194,
      "PARTS CATEGORY_1": "VTG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 29250004041,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 29250004041,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14635576,
      "PARTS CATEGORY": "VOG",
      "ROQ": 2,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14635576,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 2,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14378640,
      "PARTS CATEGORY": "VOG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14378640,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14570116,
      "PARTS CATEGORY": "VOG",
      "ROQ": 2,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14570116,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 2,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14570590,
      "PARTS CATEGORY": "VOG",
      "ROQ": 2,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14570590,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 2,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14570591,
      "PARTS CATEGORY": "VOG",
      "ROQ": 2,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14570591,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 2,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14570114,
      "PARTS CATEGORY": "VOG",
      "ROQ": 2,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14570114,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 2,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14570115,
      "PARTS CATEGORY": "VOG",
      "ROQ": 2,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14570115,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 2,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4130000326,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4130000326,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4130000861,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4130000861,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14671766,
      "PARTS CATEGORY": "VSG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14671766,
      "PARTS CATEGORY_1": "VSG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": "402269Z00A",
      "PARTS CATEGORY": "NIG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "402269Z00A",
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": "252309Z00B",
      "PARTS CATEGORY": "NIG",
      "ROQ": 1,
      "APPROVED QTY": 1,
      "UOM": "PC",
      "PART NUMBER_1": "252309Z00B",
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": "451799Z00A",
      "PARTS CATEGORY": "NIG",
      "ROQ": 11,
      "APPROVED QTY": 11,
      "UOM": "PC",
      "PART NUMBER_1": "451799Z00A",
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 11,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": "252309Z00C",
      "PARTS CATEGORY": "NIG",
      "ROQ": 1,
      "APPROVED QTY": 1,
      "UOM": "PC",
      "PART NUMBER_1": "252309Z00C",
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": "252309Z00A",
      "PARTS CATEGORY": "NIG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "252309Z00A",
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": "252309Z00D",
      "PARTS CATEGORY": "NIG",
      "ROQ": 1,
      "APPROVED QTY": 1,
      "UOM": "PC",
      "PART NUMBER_1": "252309Z00D",
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": "149739Z00A",
      "PARTS CATEGORY": "NIG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "149739Z00A",
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": "963019Z01C",
      "PARTS CATEGORY": "NIG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "963019Z01C",
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": "265559Z00B",
      "PARTS CATEGORY": "NIG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "265559Z00B",
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": "265559Z00A",
      "PARTS CATEGORY": "NIG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "265559Z00A",
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": "302009Z01B",
      "PARTS CATEGORY": "NIG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "302009Z01B",
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": "301009Z00A",
      "PARTS CATEGORY": "NIG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "301009Z00A",
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": "304029Z00A",
      "PARTS CATEGORY": "NIG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "304029Z00A",
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": "487169Z00A",
      "PARTS CATEGORY": "NIG",
      "ROQ": 1,
      "APPROVED QTY": 1,
      "UOM": "PC",
      "PART NUMBER_1": "487169Z00A",
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": "255659Z00A",
      "PARTS CATEGORY": "NIG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "255659Z00A",
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 16819626,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 16819626,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": "A229900003077",
      "PARTS CATEGORY": "YCG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "A229900003077",
      "PARTS CATEGORY_1": "YCG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 21743197,
      "PARTS CATEGORY": "VTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 21743197,
      "PARTS CATEGORY_1": "VTG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 29170042151,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 29170042151,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4030000065,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4030000065,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4120000506,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4120000506,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 60154188,
      "PARTS CATEGORY": "YPG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 60154188,
      "PARTS CATEGORY_1": "YPG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 60154192,
      "PARTS CATEGORY": "YPG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 60154192,
      "PARTS CATEGORY_1": "YPG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 21611489,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 21611489,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 21789667,
      "PARTS CATEGORY": "VTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 21789667,
      "PARTS CATEGORY_1": "VTG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 20450728,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 20450728,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110000371001,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4110000371001,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14616736,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14616736,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 21695313,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 21695313,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 16631876,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 16631876,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 21003566,
      "PARTS CATEGORY": "YCG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 21003566,
      "PARTS CATEGORY_1": "YCG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": "A210111000090",
      "PARTS CATEGORY": "YCG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "A210111000090",
      "PARTS CATEGORY_1": "YCG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": "A210405000007",
      "PARTS CATEGORY": "YCG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "A210405000007",
      "PARTS CATEGORY_1": "YCG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": "A210307000012",
      "PARTS CATEGORY": "YCG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "A210307000012",
      "PARTS CATEGORY_1": "YCG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 20798699,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 20798699,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110000556128,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4110000556128,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 990853,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 990853,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": "8220-02620",
      "PARTS CATEGORY": "VSG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "8220-02620",
      "PARTS CATEGORY_1": "VSG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": "A240700000339",
      "PARTS CATEGORY": "YCG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "A240700000339",
      "PARTS CATEGORY_1": "YCG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 14616327,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14616327,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": "A210401000016",
      "PARTS CATEGORY": "YCG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "A210401000016",
      "PARTS CATEGORY_1": "YCG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 1,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "CMI"
    },
    {
      "PART NUMBER": "A210307000016",
      "PARTS CATEGORY": "YCG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "A210307000016",
      "PARTS CATEGORY_1": "YCG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 1,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 11381209,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 11381209,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": "L51109",
      "PARTS CATEGORY": "SOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "L51109",
      "PARTS CATEGORY_1": "SOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 11110702,
      "PARTS CATEGORY": "VSG",
      "ROQ": 1,
      "APPROVED QTY": 1,
      "UOM": "PC",
      "PART NUMBER_1": 11110702,
      "PARTS CATEGORY_1": "VSG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4120000553401,
      "PARTS CATEGORY": "VLG",
      "ROQ": 1,
      "APPROVED QTY": 1,
      "UOM": "PC",
      "PART NUMBER_1": 4120000553401,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 17286432,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 17286432,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": "327059Z00C",
      "PARTS CATEGORY": "NIG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "327059Z00C",
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": "307059Z00B",
      "PARTS CATEGORY": "NIG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "307059Z00B",
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 994785,
      "PARTS CATEGORY": "VTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 994785,
      "PARTS CATEGORY_1": "VTG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 954494,
      "PARTS CATEGORY": "VTG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 954494,
      "PARTS CATEGORY_1": "VTG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 1,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": "211409Z00B",
      "PARTS CATEGORY": "NIG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "211409Z00B",
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": "344379Z00B",
      "PARTS CATEGORY": "NIG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "344379Z00B",
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": "963479Z00A",
      "PARTS CATEGORY": "NIG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "963479Z00A",
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": "963479Z00C",
      "PARTS CATEGORY": "NIG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "963479Z00C",
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": "165289Z00A",
      "PARTS CATEGORY": "NIG",
      "ROQ": 5,
      "APPROVED QTY": 5,
      "UOM": "PC",
      "PART NUMBER_1": "165289Z00A",
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 5,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": "165289Z00B",
      "PARTS CATEGORY": "NIG",
      "ROQ": 19,
      "APPROVED QTY": 19,
      "UOM": "PC",
      "PART NUMBER_1": "165289Z00B",
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 19,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": "301009Z00B",
      "PARTS CATEGORY": "NIG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "301009Z00B",
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": "260109Z00D",
      "PARTS CATEGORY": "NIG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "260109Z00D",
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": "260109Z00C",
      "PARTS CATEGORY": "NIG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "260109Z00C",
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": "288909Z00A",
      "PARTS CATEGORY": "NIG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "288909Z00A",
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": "164019Z00A",
      "PARTS CATEGORY": "NIG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "164019Z00A",
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": "211409Z00A",
      "PARTS CATEGORY": "NIG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "211409Z00A",
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": "147719Z00A",
      "PARTS CATEGORY": "NIG",
      "ROQ": 2,
      "APPROVED QTY": 2,
      "UOM": "PC",
      "PART NUMBER_1": "147719Z00A",
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 2,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": "203039Z00C",
      "PARTS CATEGORY": "NIG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "203039Z00C",
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": "231009Z00A",
      "PARTS CATEGORY": "NIG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "231009Z00A",
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": "288909Z00B",
      "PARTS CATEGORY": "NIG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "288909Z00B",
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": "306209Z00A",
      "PARTS CATEGORY": "NIG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "306209Z00A",
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": "146769Z01C",
      "PARTS CATEGORY": "NIG",
      "ROQ": 15,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "146769Z01C",
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 15,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": "154-70-11143",
      "PARTS CATEGORY": "SDG",
      "ROQ": 51,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "154-70-11143",
      "PARTS CATEGORY_1": "SDG",
      "ROQ_1": 51,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 4130001551,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4130001551,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 15010225,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 15010225,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4021000018,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4021000018,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14618826,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14618826,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": "333029Z00A",
      "PARTS CATEGORY": "NIG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "333029Z00A",
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": "333029Z00B",
      "PARTS CATEGORY": "NIG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "333029Z00B",
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": "333029Z00C",
      "PARTS CATEGORY": "NIG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "333029Z00C",
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": "726119Z00A",
      "PARTS CATEGORY": "NIG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "726119Z00A",
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 9014536073,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 9014536073,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": "911119Z02A",
      "PARTS CATEGORY": "NIG",
      "ROQ": 2,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "911119Z02A",
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 2,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": "276949Z00A",
      "PARTS CATEGORY": "NIG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "276949Z00A",
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 22089205,
      "PARTS CATEGORY": "VOG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 22089205,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 59155309,
      "PARTS CATEGORY": "VCG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 59155309,
      "PARTS CATEGORY_1": "VCG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 994448,
      "PARTS CATEGORY": "VTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 994448,
      "PARTS CATEGORY_1": "VTG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 20849976,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 20849976,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 20849979,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 20849979,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 20798533,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 20798533,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 15144849,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 15144849,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 11143271,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 11143271,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 15144848,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 15144848,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": "C31446",
      "PARTS CATEGORY": "SOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "C31446",
      "PARTS CATEGORY_1": "SOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": "99990-1Z01J",
      "PARTS CATEGORY": "LUV",
      "ROQ": 640,
      "APPROVED QTY": 408,
      "UOM": "PC",
      "PART NUMBER_1": "99990-1Z01J",
      "PARTS CATEGORY_1": "LUV",
      "ROQ_1": 640,
      "APPROVED QTY_1": 232,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12 + WHXX",
      "BU": "LUBRICANTS"
    },
    {
      "PART NUMBER": 4011000186,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4011000186,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 22243151,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 22243151,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 43977354,
      "PARTS CATEGORY": "VCG",
      "ROQ": 1,
      "APPROVED QTY": 1,
      "UOM": "PC",
      "PART NUMBER_1": 43977354,
      "PARTS CATEGORY_1": "VCG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 43977388,
      "PARTS CATEGORY": "VCG",
      "ROQ": 1,
      "APPROVED QTY": 1,
      "UOM": "PC",
      "PART NUMBER_1": 43977388,
      "PARTS CATEGORY_1": "VCG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14558043,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14558043,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14558044,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14558044,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": "A222100000166",
      "PARTS CATEGORY": "YPG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "A222100000166",
      "PARTS CATEGORY_1": "YPG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 4110000509232,
      "PARTS CATEGORY": "VLG",
      "ROQ": 3,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4110000509232,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 3,
      "APPROVED QTY_1": 3,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110001169001,
      "PARTS CATEGORY": "VLG",
      "ROQ": 3,
      "APPROVED QTY": 3,
      "UOM": "PC",
      "PART NUMBER_1": 4110001169001,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 3,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4120004037,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4120004037,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4120004036003,
      "PARTS CATEGORY": "VLG",
      "ROQ": 2,
      "APPROVED QTY": 2,
      "UOM": "PC",
      "PART NUMBER_1": 4120004036003,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 2,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": "C11226",
      "PARTS CATEGORY": "SOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "C11226",
      "PARTS CATEGORY_1": "SOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 4015000024,
      "PARTS CATEGORY": "VLG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4015000024,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14510586,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14510586,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 990610,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 990610,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14522575,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14522575,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": "150-30-13442",
      "PARTS CATEGORY": "SDG",
      "ROQ": 2,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "150-30-13442",
      "PARTS CATEGORY_1": "SDG",
      "ROQ_1": 2,
      "APPROVED QTY_1": 2,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 60101256,
      "PARTS CATEGORY": "YCG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 60101256,
      "PARTS CATEGORY_1": "YCG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 14558028,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14558028,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14558029,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14558029,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": "214009Z00E",
      "PARTS CATEGORY": "NIG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "214009Z00E",
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": "218019Z01B",
      "PARTS CATEGORY": "NIG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "218019Z01B",
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 11705190,
      "PARTS CATEGORY": "VOG",
      "ROQ": 1,
      "APPROVED QTY": 1,
      "UOM": "PC",
      "PART NUMBER_1": 11705190,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 12760005,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 12760005,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14521609,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14521609,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 20739572,
      "PARTS CATEGORY": "VOG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 20739572,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4043000027,
      "PARTS CATEGORY": "VLG",
      "ROQ": 3,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4043000027,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 3,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 21103569,
      "PARTS CATEGORY": "VTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 21103569,
      "PARTS CATEGORY_1": "VTG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 15118696,
      "PARTS CATEGORY": "VOG",
      "ROQ": 3,
      "APPROVED QTY": 2,
      "UOM": "PC",
      "PART NUMBER_1": 15118696,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 3,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "PARTIAL WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": "146829Z00B",
      "PARTS CATEGORY": "NIG",
      "ROQ": 3,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "146829Z00B",
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 3,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": "146719Z00C",
      "PARTS CATEGORY": "NIG",
      "ROQ": 3,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "146719Z00C",
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 3,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 43844422,
      "PARTS CATEGORY": "VCG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 43844422,
      "PARTS CATEGORY_1": "VCG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14589153,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14589153,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14385098,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14385098,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": "146729Z00D",
      "PARTS CATEGORY": "NIG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "146729Z00D",
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": "32624NA00A",
      "PARTS CATEGORY": "NIG",
      "ROQ": 3,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "32624NA00A",
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 3,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": "2002200Z0D",
      "PARTS CATEGORY": "NIG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "2002200Z0D",
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": "2002200Z0E",
      "PARTS CATEGORY": "NIG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "2002200Z0E",
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": "282299Z04B",
      "PARTS CATEGORY": "NIG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "282299Z04B",
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": "111129Z00A",
      "PARTS CATEGORY": "NIG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "111129Z00A",
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": "150109Z00A",
      "PARTS CATEGORY": "NIG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "150109Z00A",
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 14634767,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14634767,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": "32624NA00B",
      "PARTS CATEGORY": "NIG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "32624NA00B",
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 4120000742,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4120000742,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4856263,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4856263,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 952967,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 952967,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": "E91120",
      "PARTS CATEGORY": "SOG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "E91120",
      "PARTS CATEGORY_1": "SOG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 1,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "CMI"
    },
    {
      "PART NUMBER": "E91121",
      "PARTS CATEGORY": "SOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "E91121",
      "PARTS CATEGORY_1": "SOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 2050900054,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 2050900054,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 15074447,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 15074447,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 20854656,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 20854656,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": "797409Z00A",
      "PARTS CATEGORY": "NIG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "797409Z00A",
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": "611109Z00D",
      "PARTS CATEGORY": "NIG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "611109Z00D",
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": "231529Z00A",
      "PARTS CATEGORY": "NIG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "231529Z00A",
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": "4120002263J",
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "4120002263J",
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4015000030,
      "PARTS CATEGORY": "VLG",
      "ROQ": 17,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4015000030,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 17,
      "APPROVED QTY_1": 17,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 13976433,
      "PARTS CATEGORY": "VOG",
      "ROQ": 6,
      "APPROVED QTY": 6,
      "UOM": "PC",
      "PART NUMBER_1": 13976433,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 6,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14508740,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14508740,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14511214,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14511214,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14514378,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14514378,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14517747,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14517747,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14517748,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14517748,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14517754,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14517754,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14518023,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14518023,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14523552,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14523552,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14528387,
      "PARTS CATEGORY": "VOG",
      "ROQ": 3,
      "APPROVED QTY": 3,
      "UOM": "PC",
      "PART NUMBER_1": 14528387,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 3,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14529152,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14529152,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14529757,
      "PARTS CATEGORY": "VOG",
      "ROQ": 2,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14529757,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 2,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14531072,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14531072,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14533603,
      "PARTS CATEGORY": "VOG",
      "ROQ": 2,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14533603,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 2,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14535448,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14535448,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14537622,
      "PARTS CATEGORY": "VOG",
      "ROQ": 5,
      "APPROVED QTY": 5,
      "UOM": "PC",
      "PART NUMBER_1": 14537622,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 5,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14541720,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14541720,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14543949,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14543949,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14545465,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14545465,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14545883,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14545883,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14547395,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14547395,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14576774,
      "PARTS CATEGORY": "VOG",
      "ROQ": 2,
      "APPROVED QTY": 2,
      "UOM": "PC",
      "PART NUMBER_1": 14576774,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 2,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14579495,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14579495,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14616040,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14616040,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14880450,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14880450,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 15038313,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 15038313,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 15047153,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 15047153,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 15082295,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 15082295,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 15082742,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 15082742,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 15103658,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 15103658,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 15126069,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 15126069,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 184838,
      "PARTS CATEGORY": "VOG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 184838,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 1,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 20405822,
      "PARTS CATEGORY": "VOG",
      "ROQ": 1,
      "APPROVED QTY": 1,
      "UOM": "PC",
      "PART NUMBER_1": 20405822,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 20412308,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 20412308,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 20412314,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 20412314,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 20412325,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 20412325,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 20412589,
      "PARTS CATEGORY": "VOG",
      "ROQ": 2,
      "APPROVED QTY": 2,
      "UOM": "PC",
      "PART NUMBER_1": 20412589,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 2,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 20450707,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 20450707,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 20450736,
      "PARTS CATEGORY": "VOG",
      "ROQ": 1,
      "APPROVED QTY": 1,
      "UOM": "PC",
      "PART NUMBER_1": 20450736,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 20459203,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 20459203,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 20459961,
      "PARTS CATEGORY": "VOG",
      "ROQ": 2,
      "APPROVED QTY": 2,
      "UOM": "PC",
      "PART NUMBER_1": 20459961,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 2,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 20460022,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 20460022,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 20502536,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 20502536,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 20505959,
      "PARTS CATEGORY": "VOG",
      "ROQ": 4,
      "APPROVED QTY": 4,
      "UOM": "PC",
      "PART NUMBER_1": 20505959,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 4,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 20531832,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 20531832,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 20557208,
      "PARTS CATEGORY": "VOG",
      "ROQ": 2,
      "APPROVED QTY": 2,
      "UOM": "PC",
      "PART NUMBER_1": 20557208,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 2,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 20758403,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 20758403,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 20775463,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 20775463,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 20798076,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 20798076,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 20798827,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 20798827,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 20805349,
      "PARTS CATEGORY": "VOG",
      "ROQ": 68,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 20805349,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 68,
      "APPROVED QTY_1": 68,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 20850096,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 20850096,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 20865990,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 20865990,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 20933261,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 20933261,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 20943385,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 20943385,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 20949868,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 20949868,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4789826,
      "PARTS CATEGORY": "VOG",
      "ROQ": 1,
      "APPROVED QTY": 1,
      "UOM": "PC",
      "PART NUMBER_1": 4789826,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4870186,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4870186,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 6848760,
      "PARTS CATEGORY": "VOG",
      "ROQ": 2,
      "APPROVED QTY": 2,
      "UOM": "PC",
      "PART NUMBER_1": 6848760,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 2,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 79089,
      "PARTS CATEGORY": "VGG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 79089,
      "PARTS CATEGORY_1": "VGG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 914167,
      "PARTS CATEGORY": "VOG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 914167,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 943470,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 943470,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 946471,
      "PARTS CATEGORY": "VOG",
      "ROQ": 2,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 946471,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 2,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 947972,
      "PARTS CATEGORY": "VOG",
      "ROQ": 37,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 947972,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 37,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 949874,
      "PARTS CATEGORY": "VOG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 949874,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 1,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 955921,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 955921,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 955926,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 955926,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 960254,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 960254,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 960265,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 960265,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 967711,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 967711,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 969404,
      "PARTS CATEGORY": "VOG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 969404,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 1,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 977525,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 977525,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 978722,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 978722,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 978999,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 978999,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 980827,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 980827,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 983260,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 983260,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 983304,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 983304,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 983509,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 983509,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 990743,
      "PARTS CATEGORY": "VOG",
      "ROQ": 1,
      "APPROVED QTY": 1,
      "UOM": "PC",
      "PART NUMBER_1": 990743,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 990851,
      "PARTS CATEGORY": "VOG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 990851,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 992520,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 992520,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 80681281,
      "PARTS CATEGORY": "VPG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 80681281,
      "PARTS CATEGORY_1": "VPG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 1,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": "1030-61460",
      "PARTS CATEGORY": "VSG",
      "ROQ": 21,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "1030-61460",
      "PARTS CATEGORY_1": "VSG",
      "ROQ_1": 21,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": "1030-61680",
      "PARTS CATEGORY": "VSG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "1030-61680",
      "PARTS CATEGORY_1": "VSG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 11110283,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 11110283,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 11110284,
      "PARTS CATEGORY": "VSG",
      "ROQ": 9,
      "APPROVED QTY": 9,
      "UOM": "PC",
      "PART NUMBER_1": 11110284,
      "PARTS CATEGORY_1": "VSG",
      "ROQ_1": 9,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 11110532,
      "PARTS CATEGORY": "VSG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 11110532,
      "PARTS CATEGORY_1": "VSG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 11110533,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 11110533,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 11110670,
      "PARTS CATEGORY": "VSG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 11110670,
      "PARTS CATEGORY_1": "VSG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 11110737,
      "PARTS CATEGORY": "VSG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 11110737,
      "PARTS CATEGORY_1": "VSG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": "1122-01690",
      "PARTS CATEGORY": "VSG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "1122-01690",
      "PARTS CATEGORY_1": "VSG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": "1141-00010",
      "PARTS CATEGORY": "VSG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "1141-00010",
      "PARTS CATEGORY_1": "VSG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 11417118,
      "PARTS CATEGORY": "VSG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 11417118,
      "PARTS CATEGORY_1": "VSG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": "1146-06870",
      "PARTS CATEGORY": "VSG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "1146-06870",
      "PARTS CATEGORY_1": "VSG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 11708550,
      "PARTS CATEGORY": "VSG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 11708550,
      "PARTS CATEGORY_1": "VSG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 11708551,
      "PARTS CATEGORY": "VSG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 11708551,
      "PARTS CATEGORY_1": "VSG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 11709635,
      "PARTS CATEGORY": "VSG",
      "ROQ": 3,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 11709635,
      "PARTS CATEGORY_1": "VSG",
      "ROQ_1": 3,
      "APPROVED QTY_1": 3,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": "1171-00171",
      "PARTS CATEGORY": "VSG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "1171-00171",
      "PARTS CATEGORY_1": "VSG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": "1171-00181",
      "PARTS CATEGORY": "VSG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "1171-00181",
      "PARTS CATEGORY_1": "VSG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 11988962,
      "PARTS CATEGORY": "VSG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 11988962,
      "PARTS CATEGORY_1": "VSG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 11988975,
      "PARTS CATEGORY": "VSG",
      "ROQ": 1,
      "APPROVED QTY": 1,
      "UOM": "PC",
      "PART NUMBER_1": 11988975,
      "PARTS CATEGORY_1": "VSG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 13947622,
      "PARTS CATEGORY": "VSG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 13947622,
      "PARTS CATEGORY_1": "VSG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 13955894,
      "PARTS CATEGORY": "VSG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 13955894,
      "PARTS CATEGORY_1": "VSG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14386186,
      "PARTS CATEGORY": "VSG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14386186,
      "PARTS CATEGORY_1": "VSG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14500233,
      "PARTS CATEGORY": "VSG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14500233,
      "PARTS CATEGORY_1": "VSG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14501846,
      "PARTS CATEGORY": "VSG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14501846,
      "PARTS CATEGORY_1": "VSG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14502207,
      "PARTS CATEGORY": "VSG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14502207,
      "PARTS CATEGORY_1": "VSG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14503269,
      "PARTS CATEGORY": "VSG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14503269,
      "PARTS CATEGORY_1": "VSG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14504913,
      "PARTS CATEGORY": "VSG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14504913,
      "PARTS CATEGORY_1": "VSG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14505515,
      "PARTS CATEGORY": "VSG",
      "ROQ": 1,
      "APPROVED QTY": 1,
      "UOM": "PC",
      "PART NUMBER_1": 14505515,
      "PARTS CATEGORY_1": "VSG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14506407,
      "PARTS CATEGORY": "VSG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14506407,
      "PARTS CATEGORY_1": "VSG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14506606,
      "PARTS CATEGORY": "VSG",
      "ROQ": 1,
      "APPROVED QTY": 1,
      "UOM": "PC",
      "PART NUMBER_1": 14506606,
      "PARTS CATEGORY_1": "VSG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14506770,
      "PARTS CATEGORY": "VSG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14506770,
      "PARTS CATEGORY_1": "VSG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14506789,
      "PARTS CATEGORY": "VSG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14506789,
      "PARTS CATEGORY_1": "VSG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14506844,
      "PARTS CATEGORY": "VSG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14506844,
      "PARTS CATEGORY_1": "VSG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14506889,
      "PARTS CATEGORY": "VSG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14506889,
      "PARTS CATEGORY_1": "VSG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14508632,
      "PARTS CATEGORY": "VSG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14508632,
      "PARTS CATEGORY_1": "VSG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14508854,
      "PARTS CATEGORY": "VSG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14508854,
      "PARTS CATEGORY_1": "VSG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14508911,
      "PARTS CATEGORY": "VSG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14508911,
      "PARTS CATEGORY_1": "VSG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14509331,
      "PARTS CATEGORY": "VSG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14509331,
      "PARTS CATEGORY_1": "VSG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14509377,
      "PARTS CATEGORY": "VSG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14509377,
      "PARTS CATEGORY_1": "VSG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14509379,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14509379,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14510496,
      "PARTS CATEGORY": "VSG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14510496,
      "PARTS CATEGORY_1": "VSG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14510584,
      "PARTS CATEGORY": "VSG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14510584,
      "PARTS CATEGORY_1": "VSG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14510585,
      "PARTS CATEGORY": "VSG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14510585,
      "PARTS CATEGORY_1": "VSG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14511594,
      "PARTS CATEGORY": "VSG",
      "ROQ": 2,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14511594,
      "PARTS CATEGORY_1": "VSG",
      "ROQ_1": 2,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14513033,
      "PARTS CATEGORY": "VSG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14513033,
      "PARTS CATEGORY_1": "VSG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14513798,
      "PARTS CATEGORY": "VSG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14513798,
      "PARTS CATEGORY_1": "VSG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14514007,
      "PARTS CATEGORY": "VSG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14514007,
      "PARTS CATEGORY_1": "VSG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14514329,
      "PARTS CATEGORY": "VSG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14514329,
      "PARTS CATEGORY_1": "VSG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14514330,
      "PARTS CATEGORY": "VSG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14514330,
      "PARTS CATEGORY_1": "VSG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14514937,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14514937,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14515566,
      "PARTS CATEGORY": "VSG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14515566,
      "PARTS CATEGORY_1": "VSG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14515827,
      "PARTS CATEGORY": "VSG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14515827,
      "PARTS CATEGORY_1": "VSG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14515828,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14515828,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14518349,
      "PARTS CATEGORY": "VSG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14518349,
      "PARTS CATEGORY_1": "VSG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14522412,
      "PARTS CATEGORY": "VSG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14522412,
      "PARTS CATEGORY_1": "VSG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14522909,
      "PARTS CATEGORY": "VSG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14522909,
      "PARTS CATEGORY_1": "VSG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14522962,
      "PARTS CATEGORY": "VSG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14522962,
      "PARTS CATEGORY_1": "VSG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14522963,
      "PARTS CATEGORY": "VSG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14522963,
      "PARTS CATEGORY_1": "VSG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14523946,
      "PARTS CATEGORY": "VSG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14523946,
      "PARTS CATEGORY_1": "VSG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14524170,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14524170,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14524449,
      "PARTS CATEGORY": "VSG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14524449,
      "PARTS CATEGORY_1": "VSG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14524710,
      "PARTS CATEGORY": "VSG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14524710,
      "PARTS CATEGORY_1": "VSG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14524711,
      "PARTS CATEGORY": "VSG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14524711,
      "PARTS CATEGORY_1": "VSG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14526158,
      "PARTS CATEGORY": "VSG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14526158,
      "PARTS CATEGORY_1": "VSG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14526509,
      "PARTS CATEGORY": "VSG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14526509,
      "PARTS CATEGORY_1": "VSG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14526510,
      "PARTS CATEGORY": "VSG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14526510,
      "PARTS CATEGORY_1": "VSG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14526776,
      "PARTS CATEGORY": "VSG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14526776,
      "PARTS CATEGORY_1": "VSG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14527149,
      "PARTS CATEGORY": "VSG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14527149,
      "PARTS CATEGORY_1": "VSG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14527267,
      "PARTS CATEGORY": "VSG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14527267,
      "PARTS CATEGORY_1": "VSG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14527864,
      "PARTS CATEGORY": "VSG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14527864,
      "PARTS CATEGORY_1": "VSG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14528382,
      "PARTS CATEGORY": "VSG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14528382,
      "PARTS CATEGORY_1": "VSG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14529050,
      "PARTS CATEGORY": "VSG",
      "ROQ": 2,
      "APPROVED QTY": 2,
      "UOM": "PC",
      "PART NUMBER_1": 14529050,
      "PARTS CATEGORY_1": "VSG",
      "ROQ_1": 2,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14529051,
      "PARTS CATEGORY": "VSG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14529051,
      "PARTS CATEGORY_1": "VSG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14529052,
      "PARTS CATEGORY": "VSG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14529052,
      "PARTS CATEGORY_1": "VSG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14529132,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14529132,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14529178,
      "PARTS CATEGORY": "VSG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14529178,
      "PARTS CATEGORY_1": "VSG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14530079,
      "PARTS CATEGORY": "VSG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14530079,
      "PARTS CATEGORY_1": "VSG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14530362,
      "PARTS CATEGORY": "VSG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14530362,
      "PARTS CATEGORY_1": "VSG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14530363,
      "PARTS CATEGORY": "VSG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14530363,
      "PARTS CATEGORY_1": "VSG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14530364,
      "PARTS CATEGORY": "VSG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14530364,
      "PARTS CATEGORY_1": "VSG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14530365,
      "PARTS CATEGORY": "VSG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14530365,
      "PARTS CATEGORY_1": "VSG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14530367,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14530367,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14530385,
      "PARTS CATEGORY": "VSG",
      "ROQ": 3,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14530385,
      "PARTS CATEGORY_1": "VSG",
      "ROQ_1": 3,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14530648,
      "PARTS CATEGORY": "VSG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14530648,
      "PARTS CATEGORY_1": "VSG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14530989,
      "PARTS CATEGORY": "VSG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14530989,
      "PARTS CATEGORY_1": "VSG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14531010,
      "PARTS CATEGORY": "VSG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14531010,
      "PARTS CATEGORY_1": "VSG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14531109,
      "PARTS CATEGORY": "VSG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14531109,
      "PARTS CATEGORY_1": "VSG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14531866,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14531866,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14532260,
      "PARTS CATEGORY": "VSG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14532260,
      "PARTS CATEGORY_1": "VSG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14532276,
      "PARTS CATEGORY": "VSG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14532276,
      "PARTS CATEGORY_1": "VSG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14532390,
      "PARTS CATEGORY": "VSG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14532390,
      "PARTS CATEGORY_1": "VSG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14532473,
      "PARTS CATEGORY": "VSG",
      "ROQ": 2,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14532473,
      "PARTS CATEGORY_1": "VSG",
      "ROQ_1": 2,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14532687,
      "PARTS CATEGORY": "VSG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14532687,
      "PARTS CATEGORY_1": "VSG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14533862,
      "PARTS CATEGORY": "VSG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14533862,
      "PARTS CATEGORY_1": "VSG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14537348,
      "PARTS CATEGORY": "VSG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14537348,
      "PARTS CATEGORY_1": "VSG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14540724,
      "PARTS CATEGORY": "VSG",
      "ROQ": 3,
      "APPROVED QTY": 3,
      "UOM": "PC",
      "PART NUMBER_1": 14540724,
      "PARTS CATEGORY_1": "VSG",
      "ROQ_1": 3,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14540725,
      "PARTS CATEGORY": "VSG",
      "ROQ": 29,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14540725,
      "PARTS CATEGORY_1": "VSG",
      "ROQ_1": 29,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14540726,
      "PARTS CATEGORY": "VSG",
      "ROQ": 5,
      "APPROVED QTY": 5,
      "UOM": "PC",
      "PART NUMBER_1": 14540726,
      "PARTS CATEGORY_1": "VSG",
      "ROQ_1": 5,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14548911,
      "PARTS CATEGORY": "VSG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14548911,
      "PARTS CATEGORY_1": "VSG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14551131,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14551131,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14552332,
      "PARTS CATEGORY": "VSG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14552332,
      "PARTS CATEGORY_1": "VSG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14555298,
      "PARTS CATEGORY": "VSG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14555298,
      "PARTS CATEGORY_1": "VSG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14560201,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14560201,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14560206,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14560206,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14562193,
      "PARTS CATEGORY": "VOG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14562193,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14589129,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14589129,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14589131,
      "PARTS CATEGORY": "VSG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14589131,
      "PARTS CATEGORY_1": "VSG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14589132,
      "PARTS CATEGORY": "VOG",
      "ROQ": 3,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14589132,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 3,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14589140,
      "PARTS CATEGORY": "VSG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14589140,
      "PARTS CATEGORY_1": "VSG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14589143,
      "PARTS CATEGORY": "VSG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14589143,
      "PARTS CATEGORY_1": "VSG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14591545,
      "PARTS CATEGORY": "VSG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14591545,
      "PARTS CATEGORY_1": "VSG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14596399,
      "PARTS CATEGORY": "VSG",
      "ROQ": 20,
      "APPROVED QTY": 20,
      "UOM": "PC",
      "PART NUMBER_1": 14596399,
      "PARTS CATEGORY_1": "VSG",
      "ROQ_1": 20,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14603276,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14603276,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14880438,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14880438,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14880552,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14880552,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14880553,
      "PARTS CATEGORY": "VSG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14880553,
      "PARTS CATEGORY_1": "VSG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14880692,
      "PARTS CATEGORY": "VSG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14880692,
      "PARTS CATEGORY_1": "VSG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14881185,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14881185,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14881276,
      "PARTS CATEGORY": "VOG",
      "ROQ": 12,
      "APPROVED QTY": 12,
      "UOM": "PC",
      "PART NUMBER_1": 14881276,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 12,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 15078671,
      "PARTS CATEGORY": "VSG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 15078671,
      "PARTS CATEGORY_1": "VSG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 20374662,
      "PARTS CATEGORY": "VSG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 20374662,
      "PARTS CATEGORY_1": "VSG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 20405599,
      "PARTS CATEGORY": "VSG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 20405599,
      "PARTS CATEGORY_1": "VSG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 20405792,
      "PARTS CATEGORY": "VSG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 20405792,
      "PARTS CATEGORY_1": "VSG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 20405885,
      "PARTS CATEGORY": "VSG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 20405885,
      "PARTS CATEGORY_1": "VSG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 20405969,
      "PARTS CATEGORY": "VSG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 20405969,
      "PARTS CATEGORY_1": "VSG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 20405970,
      "PARTS CATEGORY": "VSG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 20405970,
      "PARTS CATEGORY_1": "VSG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 20450754,
      "PARTS CATEGORY": "VSG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 20450754,
      "PARTS CATEGORY_1": "VSG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 20459025,
      "PARTS CATEGORY": "VSG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 20459025,
      "PARTS CATEGORY_1": "VSG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 20459191,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 20459191,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 20459192,
      "PARTS CATEGORY": "VSG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 20459192,
      "PARTS CATEGORY_1": "VSG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 20459193,
      "PARTS CATEGORY": "VSG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 20459193,
      "PARTS CATEGORY_1": "VSG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 20459212,
      "PARTS CATEGORY": "VSG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 20459212,
      "PARTS CATEGORY_1": "VSG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 20459214,
      "PARTS CATEGORY": "VSG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 20459214,
      "PARTS CATEGORY_1": "VSG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 20459215,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 20459215,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 20459218,
      "PARTS CATEGORY": "VSG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 20459218,
      "PARTS CATEGORY_1": "VSG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 20459863,
      "PARTS CATEGORY": "VSG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 20459863,
      "PARTS CATEGORY_1": "VSG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 20459953,
      "PARTS CATEGORY": "VSG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 20459953,
      "PARTS CATEGORY_1": "VSG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 20459960,
      "PARTS CATEGORY": "VSG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 20459960,
      "PARTS CATEGORY_1": "VSG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 20460061,
      "PARTS CATEGORY": "VSG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 20460061,
      "PARTS CATEGORY_1": "VSG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 20460813,
      "PARTS CATEGORY": "VSG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 20460813,
      "PARTS CATEGORY_1": "VSG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 20482686,
      "PARTS CATEGORY": "VSG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 20482686,
      "PARTS CATEGORY_1": "VSG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 20482730,
      "PARTS CATEGORY": "VSG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 20482730,
      "PARTS CATEGORY_1": "VSG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 20516934,
      "PARTS CATEGORY": "VSG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 20516934,
      "PARTS CATEGORY_1": "VSG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 20766790,
      "PARTS CATEGORY": "VSG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 20766790,
      "PARTS CATEGORY_1": "VSG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 20796066,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 20796066,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 20798186,
      "PARTS CATEGORY": "VSG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 20798186,
      "PARTS CATEGORY_1": "VSG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 20798191,
      "PARTS CATEGORY": "VSG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 20798191,
      "PARTS CATEGORY_1": "VSG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 20798683,
      "PARTS CATEGORY": "VSG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 20798683,
      "PARTS CATEGORY_1": "VSG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 20799731,
      "PARTS CATEGORY": "VSG",
      "ROQ": 4,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 20799731,
      "PARTS CATEGORY_1": "VSG",
      "ROQ_1": 4,
      "APPROVED QTY_1": 4,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 20840491,
      "PARTS CATEGORY": "VSG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 20840491,
      "PARTS CATEGORY_1": "VSG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 20879322,
      "PARTS CATEGORY": "VSG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 20879322,
      "PARTS CATEGORY_1": "VSG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 20973777,
      "PARTS CATEGORY": "VSG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 20973777,
      "PARTS CATEGORY_1": "VSG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 21141943,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 21141943,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 21141955,
      "PARTS CATEGORY": "VSG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 21141955,
      "PARTS CATEGORY_1": "VSG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 21142728,
      "PARTS CATEGORY": "VSG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 21142728,
      "PARTS CATEGORY_1": "VSG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 21147446,
      "PARTS CATEGORY": "VSG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 21147446,
      "PARTS CATEGORY_1": "VSG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 21247955,
      "PARTS CATEGORY": "VSG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 21247955,
      "PARTS CATEGORY_1": "VSG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 21291011,
      "PARTS CATEGORY": "VSG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 21291011,
      "PARTS CATEGORY_1": "VSG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 21511352,
      "PARTS CATEGORY": "VSG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 21511352,
      "PARTS CATEGORY_1": "VSG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 3280048,
      "PARTS CATEGORY": "CUG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 3280048,
      "PARTS CATEGORY_1": "CUG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": 0
    },
    {
      "PART NUMBER": 60100002,
      "PARTS CATEGORY": "VSG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 60100002,
      "PARTS CATEGORY_1": "VSG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": "7123-52560",
      "PARTS CATEGORY": "VSG",
      "ROQ": 4,
      "APPROVED QTY": 4,
      "UOM": "PC",
      "PART NUMBER_1": "7123-52560",
      "PARTS CATEGORY_1": "VSG",
      "ROQ_1": 4,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": "7273-10751",
      "PARTS CATEGORY": "VSG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "7273-10751",
      "PARTS CATEGORY_1": "VSG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": "8038-00010",
      "PARTS CATEGORY": "VSG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "8038-00010",
      "PARTS CATEGORY_1": "VSG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": "8230-02830",
      "PARTS CATEGORY": "VSG",
      "ROQ": 2,
      "APPROVED QTY": 2,
      "UOM": "PC",
      "PART NUMBER_1": "8230-02830",
      "PARTS CATEGORY_1": "VSG",
      "ROQ_1": 2,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": "8230-26520",
      "PARTS CATEGORY": "VSG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "8230-26520",
      "PARTS CATEGORY_1": "VSG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": "8230-36840",
      "PARTS CATEGORY": "VSG",
      "ROQ": 1,
      "APPROVED QTY": 1,
      "UOM": "PC",
      "PART NUMBER_1": "8230-36840",
      "PARTS CATEGORY_1": "VSG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 85108352,
      "PARTS CATEGORY": "VTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 85108352,
      "PARTS CATEGORY_1": "VTG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": "9131-11000",
      "PARTS CATEGORY": "VSG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "9131-11000",
      "PARTS CATEGORY_1": "VSG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 955918,
      "PARTS CATEGORY": "VSG",
      "ROQ": 1,
      "APPROVED QTY": 1,
      "UOM": "PC",
      "PART NUMBER_1": 955918,
      "PARTS CATEGORY_1": "VSG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 955919,
      "PARTS CATEGORY": "VSG",
      "ROQ": 5,
      "APPROVED QTY": 5,
      "UOM": "PC",
      "PART NUMBER_1": 955919,
      "PARTS CATEGORY_1": "VSG",
      "ROQ_1": 5,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 955922,
      "PARTS CATEGORY": "VSG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 955922,
      "PARTS CATEGORY_1": "VSG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 955925,
      "PARTS CATEGORY": "VSG",
      "ROQ": 8,
      "APPROVED QTY": 8,
      "UOM": "PC",
      "PART NUMBER_1": 955925,
      "PARTS CATEGORY_1": "VSG",
      "ROQ_1": 8,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 955927,
      "PARTS CATEGORY": "VSG",
      "ROQ": 10,
      "APPROVED QTY": 10,
      "UOM": "PC",
      "PART NUMBER_1": 955927,
      "PARTS CATEGORY_1": "VSG",
      "ROQ_1": 10,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 960259,
      "PARTS CATEGORY": "VSG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 960259,
      "PARTS CATEGORY_1": "VSG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": "9612-08040",
      "PARTS CATEGORY": "VSG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "9612-08040",
      "PARTS CATEGORY_1": "VSG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": "9612-09540",
      "PARTS CATEGORY": "VSG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "9612-09540",
      "PARTS CATEGORY_1": "VSG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 969589,
      "PARTS CATEGORY": "VSG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 969589,
      "PARTS CATEGORY_1": "VSG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 978937,
      "PARTS CATEGORY": "VSG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 978937,
      "PARTS CATEGORY_1": "VSG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 983303,
      "PARTS CATEGORY": "VSG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 983303,
      "PARTS CATEGORY_1": "VSG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 983497,
      "PARTS CATEGORY": "VSG",
      "ROQ": 16,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 983497,
      "PARTS CATEGORY_1": "VSG",
      "ROQ_1": 16,
      "APPROVED QTY_1": 16,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 983502,
      "PARTS CATEGORY": "VSG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 983502,
      "PARTS CATEGORY_1": "VSG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 983505,
      "PARTS CATEGORY": "VSG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 983505,
      "PARTS CATEGORY_1": "VSG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 983524,
      "PARTS CATEGORY": "VSG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 983524,
      "PARTS CATEGORY_1": "VSG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 983527,
      "PARTS CATEGORY": "VSG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 983527,
      "PARTS CATEGORY_1": "VSG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 983530,
      "PARTS CATEGORY": "VSG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 983530,
      "PARTS CATEGORY_1": "VSG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 990557,
      "PARTS CATEGORY": "VSG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 990557,
      "PARTS CATEGORY_1": "VSG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 990566,
      "PARTS CATEGORY": "VSG",
      "ROQ": 3,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 990566,
      "PARTS CATEGORY_1": "VSG",
      "ROQ_1": 3,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 990583,
      "PARTS CATEGORY": "VSG",
      "ROQ": 3,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 990583,
      "PARTS CATEGORY_1": "VSG",
      "ROQ_1": 3,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 990596,
      "PARTS CATEGORY": "VSG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 990596,
      "PARTS CATEGORY_1": "VSG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 990740,
      "PARTS CATEGORY": "VSG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 990740,
      "PARTS CATEGORY_1": "VSG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 15048941,
      "PARTS CATEGORY": "VTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 15048941,
      "PARTS CATEGORY_1": "VTG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 1594228,
      "PARTS CATEGORY": "VTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 1594228,
      "PARTS CATEGORY_1": "VTG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 1635305,
      "PARTS CATEGORY": "VTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 1635305,
      "PARTS CATEGORY_1": "VTG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 1635322,
      "PARTS CATEGORY": "VTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 1635322,
      "PARTS CATEGORY_1": "VTG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 1674083,
      "PARTS CATEGORY": "VTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 1674083,
      "PARTS CATEGORY_1": "VTG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 20367490,
      "PARTS CATEGORY": "VTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 20367490,
      "PARTS CATEGORY_1": "VTG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 20378932,
      "PARTS CATEGORY": "VTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 20378932,
      "PARTS CATEGORY_1": "VTG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 20378934,
      "PARTS CATEGORY": "VTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 20378934,
      "PARTS CATEGORY_1": "VTG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 20378935,
      "PARTS CATEGORY": "VTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 20378935,
      "PARTS CATEGORY_1": "VTG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 3283333,
      "PARTS CATEGORY": "CUG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 3283333,
      "PARTS CATEGORY_1": "CUG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": 0
    },
    {
      "PART NUMBER": 3802100,
      "PARTS CATEGORY": "CUG",
      "ROQ": 4,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 3802100,
      "PARTS CATEGORY_1": "CUG",
      "ROQ_1": 4,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": 0
    },
    {
      "PART NUMBER": 3917539,
      "PARTS CATEGORY": "CUG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 3917539,
      "PARTS CATEGORY_1": "CUG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": 0
    },
    {
      "PART NUMBER": 3964432,
      "PARTS CATEGORY": "CUG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 3964432,
      "PARTS CATEGORY_1": "CUG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": 0
    },
    {
      "PART NUMBER": 3969562,
      "PARTS CATEGORY": "CUG",
      "ROQ": 4,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 3969562,
      "PARTS CATEGORY_1": "CUG",
      "ROQ_1": 4,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": 0
    },
    {
      "PART NUMBER": 4893693,
      "PARTS CATEGORY": "CUG",
      "ROQ": 4,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4893693,
      "PARTS CATEGORY_1": "CUG",
      "ROQ_1": 4,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": 0
    },
    {
      "PART NUMBER": 4983584,
      "PARTS CATEGORY": "CUG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4983584,
      "PARTS CATEGORY_1": "CUG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": 0
    },
    {
      "PART NUMBER": "FF42000",
      "PARTS CATEGORY": "CUF",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "FF42000",
      "PARTS CATEGORY_1": "CUF",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "OLD"
    },
    {
      "PART NUMBER": "FF5052",
      "PARTS CATEGORY": "CUF",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "FF5052",
      "PARTS CATEGORY_1": "CUF",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "OLD"
    },
    {
      "PART NUMBER": "FF5612",
      "PARTS CATEGORY": "CUF",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "FF5612",
      "PARTS CATEGORY_1": "CUF",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "OLD"
    },
    {
      "PART NUMBER": "FS1000",
      "PARTS CATEGORY": "CUF",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "FS1000",
      "PARTS CATEGORY_1": "CUF",
      "ROQ_1": 1,
      "APPROVED QTY_1": 1,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "OLD"
    },
    {
      "PART NUMBER": "FS1212",
      "PARTS CATEGORY": "CUF",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "FS1212",
      "PARTS CATEGORY_1": "CUF",
      "ROQ_1": 1,
      "APPROVED QTY_1": 1,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "OLD"
    },
    {
      "PART NUMBER": "FS1242B",
      "PARTS CATEGORY": "CUF",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "FS1242B",
      "PARTS CATEGORY_1": "CUF",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "OLD"
    },
    {
      "PART NUMBER": "FS1280",
      "PARTS CATEGORY": "CUF",
      "ROQ": 2,
      "APPROVED QTY": 2,
      "UOM": "PC",
      "PART NUMBER_1": "FS1280",
      "PARTS CATEGORY_1": "CUF",
      "ROQ_1": 2,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "OLD"
    },
    {
      "PART NUMBER": "FS19532",
      "PARTS CATEGORY": "CUF",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "FS19532",
      "PARTS CATEGORY_1": "CUF",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "OLD"
    },
    {
      "PART NUMBER": "HF6072",
      "PARTS CATEGORY": "CUF",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "HF6072",
      "PARTS CATEGORY_1": "CUF",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "OLD"
    },
    {
      "PART NUMBER": "LF3349",
      "PARTS CATEGORY": "CUF",
      "ROQ": 1,
      "APPROVED QTY": 1,
      "UOM": "PC",
      "PART NUMBER_1": "LF3349",
      "PARTS CATEGORY_1": "CUF",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "OLD"
    },
    {
      "PART NUMBER": "LF3403",
      "PARTS CATEGORY": "CUF",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "LF3403",
      "PARTS CATEGORY_1": "CUF",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "OLD"
    },
    {
      "PART NUMBER": 36786457,
      "PARTS CATEGORY": "DOP",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 36786457,
      "PARTS CATEGORY_1": "DOP",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 36845493,
      "PARTS CATEGORY": "DOP",
      "ROQ": 9,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 36845493,
      "PARTS CATEGORY_1": "DOP",
      "ROQ_1": 9,
      "APPROVED QTY_1": 1,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "PARTIAL WH12",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 36847747,
      "PARTS CATEGORY": "DOP",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 36847747,
      "PARTS CATEGORY_1": "DOP",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 36870566,
      "PARTS CATEGORY": "DOP",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 36870566,
      "PARTS CATEGORY_1": "DOP",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 36870574,
      "PARTS CATEGORY": "DOP",
      "ROQ": 1,
      "APPROVED QTY": 1,
      "UOM": "PC",
      "PART NUMBER_1": 36870574,
      "PARTS CATEGORY_1": "DOP",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 36888170,
      "PARTS CATEGORY": "DOP",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 36888170,
      "PARTS CATEGORY_1": "DOP",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 36888683,
      "PARTS CATEGORY": "DOP",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 36888683,
      "PARTS CATEGORY_1": "DOP",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 36899706,
      "PARTS CATEGORY": "DOP",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 36899706,
      "PARTS CATEGORY_1": "DOP",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 58854316,
      "PARTS CATEGORY": "VCG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 58854316,
      "PARTS CATEGORY_1": "VCG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": "01602-02060",
      "PARTS CATEGORY": "KOG",
      "ROQ": 51,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "01602-02060",
      "PARTS CATEGORY_1": "KOG",
      "ROQ_1": 51,
      "APPROVED QTY_1": 51,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": 0
    },
    {
      "PART NUMBER": "01643-31032",
      "PARTS CATEGORY": "KOG",
      "ROQ": 4,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "01643-31032",
      "PARTS CATEGORY_1": "KOG",
      "ROQ_1": 4,
      "APPROVED QTY_1": 4,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": 0
    },
    {
      "PART NUMBER": "01643-31232",
      "PARTS CATEGORY": "KOG",
      "ROQ": 5,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "01643-31232",
      "PARTS CATEGORY_1": "KOG",
      "ROQ_1": 5,
      "APPROVED QTY_1": 5,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": 0
    },
    {
      "PART NUMBER": "01643-31845",
      "PARTS CATEGORY": "SDG",
      "ROQ": 85,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "01643-31845",
      "PARTS CATEGORY_1": "SDG",
      "ROQ_1": 85,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "CMI"
    },
    {
      "PART NUMBER": "07056-10045",
      "PARTS CATEGORY": "SDG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "07056-10045",
      "PARTS CATEGORY_1": "SDG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": "07700-40460",
      "PARTS CATEGORY": "KOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "07700-40460",
      "PARTS CATEGORY_1": "KOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": 0
    },
    {
      "PART NUMBER": "09244-02508",
      "PARTS CATEGORY": "KOG",
      "ROQ": 4,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "09244-02508",
      "PARTS CATEGORY_1": "KOG",
      "ROQ_1": 4,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": 0
    },
    {
      "PART NUMBER": "150-30-13460",
      "PARTS CATEGORY": "KOG",
      "ROQ": 2,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "150-30-13460",
      "PARTS CATEGORY_1": "KOG",
      "ROQ_1": 2,
      "APPROVED QTY_1": 2,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": 0
    },
    {
      "PART NUMBER": "150-30-13480",
      "PARTS CATEGORY": "KOG",
      "ROQ": 2,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "150-30-13480",
      "PARTS CATEGORY_1": "KOG",
      "ROQ_1": 2,
      "APPROVED QTY_1": 2,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": 0
    },
    {
      "PART NUMBER": "154-32-05001G",
      "PARTS CATEGORY": "KOR",
      "ROQ": 11,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "154-32-05001G",
      "PARTS CATEGORY_1": "KOR",
      "ROQ_1": 11,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": 0
    },
    {
      "PART NUMBER": "155-30-13230",
      "PARTS CATEGORY": "KOG",
      "ROQ": 9,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "155-30-13230",
      "PARTS CATEGORY_1": "KOG",
      "ROQ_1": 9,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": 0
    },
    {
      "PART NUMBER": "195-30-17960",
      "PARTS CATEGORY": "KOG",
      "ROQ": 9,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "195-30-17960",
      "PARTS CATEGORY_1": "KOG",
      "ROQ_1": 9,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": 0
    },
    {
      "PART NUMBER": 10021425,
      "PARTS CATEGORY": "LIG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 10021425,
      "PARTS CATEGORY_1": "LIG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": 0
    },
    {
      "PART NUMBER": 10021426,
      "PARTS CATEGORY": "LIG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 10021426,
      "PARTS CATEGORY_1": "LIG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": 0
    },
    {
      "PART NUMBER": 10035245,
      "PARTS CATEGORY": "LIG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 10035245,
      "PARTS CATEGORY_1": "LIG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": 0
    },
    {
      "PART NUMBER": 10044302,
      "PARTS CATEGORY": "LIG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 10044302,
      "PARTS CATEGORY_1": "LIG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": 0
    },
    {
      "PART NUMBER": 10044303,
      "PARTS CATEGORY": "LIG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 10044303,
      "PARTS CATEGORY_1": "LIG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": 0
    },
    {
      "PART NUMBER": 10297295,
      "PARTS CATEGORY": "LIG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 10297295,
      "PARTS CATEGORY_1": "LIG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": 0
    },
    {
      "PART NUMBER": 10429946,
      "PARTS CATEGORY": "LIG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 10429946,
      "PARTS CATEGORY_1": "LIG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": 0
    },
    {
      "PART NUMBER": 10440983,
      "PARTS CATEGORY": "LIG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 10440983,
      "PARTS CATEGORY_1": "LIG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": 0
    },
    {
      "PART NUMBER": 21739130,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 21739130,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 20460214,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 20460214,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": "A210417000095",
      "PARTS CATEGORY": "YPG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "A210417000095",
      "PARTS CATEGORY_1": "YPG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": "B230101002126",
      "PARTS CATEGORY": "YPG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "B230101002126",
      "PARTS CATEGORY_1": "YPG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": "B230101001161",
      "PARTS CATEGORY": "YPG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "B230101001161",
      "PARTS CATEGORY_1": "YPG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 60044253,
      "PARTS CATEGORY": "YPG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 60044253,
      "PARTS CATEGORY_1": "YPG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 4021000199,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4021000199,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 15058186,
      "PARTS CATEGORY": "LUV",
      "ROQ": 667,
      "APPROVED QTY": 660,
      "UOM": "PC",
      "PART NUMBER_1": 15058186,
      "PARTS CATEGORY_1": "LUV",
      "ROQ_1": 667,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "LUBRICANTS"
    },
    {
      "PART NUMBER": 15058290,
      "PARTS CATEGORY": "LUV",
      "ROQ": 117,
      "APPROVED QTY": 8,
      "UOM": "PC",
      "PART NUMBER_1": 15058290,
      "PARTS CATEGORY_1": "LUV",
      "ROQ_1": 117,
      "APPROVED QTY_1": 112,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12 + WHXX",
      "BU": "LUBRICANTS"
    },
    {
      "PART NUMBER": 15067098,
      "PARTS CATEGORY": "LUV",
      "ROQ": 45,
      "APPROVED QTY": 1,
      "UOM": "PC",
      "PART NUMBER_1": 15067098,
      "PARTS CATEGORY_1": "LUV",
      "ROQ_1": 45,
      "APPROVED QTY_1": 39,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12 + WHXX",
      "BU": "LUBRICANTS"
    },
    {
      "PART NUMBER": 15067197,
      "PARTS CATEGORY": "LUV",
      "ROQ": 1047,
      "APPROVED QTY": 1040,
      "UOM": "PC",
      "PART NUMBER_1": 15067197,
      "PARTS CATEGORY_1": "LUV",
      "ROQ_1": 1047,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "LUBRICANTS"
    },
    {
      "PART NUMBER": 15067515,
      "PARTS CATEGORY": "LUV",
      "ROQ": 692,
      "APPROVED QTY": 20,
      "UOM": "PC",
      "PART NUMBER_1": 15067515,
      "PARTS CATEGORY_1": "LUV",
      "ROQ_1": 692,
      "APPROVED QTY_1": 680,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12 + WHXX",
      "BU": "LUBRICANTS"
    },
    {
      "PART NUMBER": 15067522,
      "PARTS CATEGORY": "LUV",
      "ROQ": 124,
      "APPROVED QTY": 120,
      "UOM": "PC",
      "PART NUMBER_1": 15067522,
      "PARTS CATEGORY_1": "LUV",
      "ROQ_1": 124,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "LUBRICANTS"
    },
    {
      "PART NUMBER": 15067644,
      "PARTS CATEGORY": "LUV",
      "ROQ": 40,
      "APPROVED QTY": 20,
      "UOM": "PC",
      "PART NUMBER_1": 15067644,
      "PARTS CATEGORY_1": "LUV",
      "ROQ_1": 40,
      "APPROVED QTY_1": 20,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12 + WHXX",
      "BU": "LUBRICANTS"
    },
    {
      "PART NUMBER": 15143928,
      "PARTS CATEGORY": "LUV",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 15143928,
      "PARTS CATEGORY_1": "LUV",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "LUBRICANTS"
    },
    {
      "PART NUMBER": 15198387,
      "PARTS CATEGORY": "LUV",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 15198387,
      "PARTS CATEGORY_1": "LUV",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "LUBRICANTS"
    },
    {
      "PART NUMBER": "175-49-11580",
      "PARTS CATEGORY": "SDG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "175-49-11580",
      "PARTS CATEGORY_1": "SDG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": "175-60-27380",
      "PARTS CATEGORY": "SDG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "175-60-27380",
      "PARTS CATEGORY_1": "SDG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 3325963,
      "PARTS CATEGORY": "SDG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 3325963,
      "PARTS CATEGORY_1": "SDG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": "6127-81-7412T",
      "PARTS CATEGORY": "SDG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "6127-81-7412T",
      "PARTS CATEGORY_1": "SDG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": "D2500-00000",
      "PARTS CATEGORY": "SDG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "D2500-00000",
      "PARTS CATEGORY_1": "SDG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 13351382,
      "PARTS CATEGORY": "VCG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 13351382,
      "PARTS CATEGORY_1": "VCG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 13465547,
      "PARTS CATEGORY": "VCG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 13465547,
      "PARTS CATEGORY_1": "VCG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 13469606,
      "PARTS CATEGORY": "VCG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 13469606,
      "PARTS CATEGORY_1": "VCG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 15155620,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 15155620,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 20163150,
      "PARTS CATEGORY": "VCG",
      "ROQ": 1,
      "APPROVED QTY": 1,
      "UOM": "PC",
      "PART NUMBER_1": 20163150,
      "PARTS CATEGORY_1": "VCG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 43904234,
      "PARTS CATEGORY": "VCG",
      "ROQ": 1,
      "APPROVED QTY": 1,
      "UOM": "PC",
      "PART NUMBER_1": 43904234,
      "PARTS CATEGORY_1": "VCG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 43908268,
      "PARTS CATEGORY": "VCG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 43908268,
      "PARTS CATEGORY_1": "VCG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 43911668,
      "PARTS CATEGORY": "VCG",
      "ROQ": 3,
      "APPROVED QTY": 3,
      "UOM": "PC",
      "PART NUMBER_1": 43911668,
      "PARTS CATEGORY_1": "VCG",
      "ROQ_1": 3,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 43921394,
      "PARTS CATEGORY": "VCG",
      "ROQ": 5,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 43921394,
      "PARTS CATEGORY_1": "VCG",
      "ROQ_1": 5,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 43922400,
      "PARTS CATEGORY": "VCG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 43922400,
      "PARTS CATEGORY_1": "VCG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 43923093,
      "PARTS CATEGORY": "VCG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 43923093,
      "PARTS CATEGORY_1": "VCG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 43924422,
      "PARTS CATEGORY": "VCG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 43924422,
      "PARTS CATEGORY_1": "VCG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 43927185,
      "PARTS CATEGORY": "VCG",
      "ROQ": 1,
      "APPROVED QTY": 1,
      "UOM": "PC",
      "PART NUMBER_1": 43927185,
      "PARTS CATEGORY_1": "VCG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 43931922,
      "PARTS CATEGORY": "VCG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 43931922,
      "PARTS CATEGORY_1": "VCG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 59058057,
      "PARTS CATEGORY": "VCG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 59058057,
      "PARTS CATEGORY_1": "VCG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 59093351,
      "PARTS CATEGORY": "VCG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 59093351,
      "PARTS CATEGORY_1": "VCG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 59122085,
      "PARTS CATEGORY": "VCG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 59122085,
      "PARTS CATEGORY_1": "VCG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 59155317,
      "PARTS CATEGORY": "VCG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 59155317,
      "PARTS CATEGORY_1": "VCG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 59286336,
      "PARTS CATEGORY": "VCG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 59286336,
      "PARTS CATEGORY_1": "VCG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 59309104,
      "PARTS CATEGORY": "VCG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 59309104,
      "PARTS CATEGORY_1": "VCG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 59524447,
      "PARTS CATEGORY": "VCG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 59524447,
      "PARTS CATEGORY_1": "VCG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 59648543,
      "PARTS CATEGORY": "VCG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 59648543,
      "PARTS CATEGORY_1": "VCG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 96702329,
      "PARTS CATEGORY": "VCG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 96702329,
      "PARTS CATEGORY_1": "VCG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 96738596,
      "PARTS CATEGORY": "VCG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 96738596,
      "PARTS CATEGORY_1": "VCG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 11419486,
      "PARTS CATEGORY": "VGG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 11419486,
      "PARTS CATEGORY_1": "VGG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": "12119-20",
      "PARTS CATEGORY": "VGG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "12119-20",
      "PARTS CATEGORY_1": "VGG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 12724048,
      "PARTS CATEGORY": "VGG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 12724048,
      "PARTS CATEGORY_1": "VGG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 12724306,
      "PARTS CATEGORY": "VGG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 12724306,
      "PARTS CATEGORY_1": "VGG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 12725151,
      "PARTS CATEGORY": "VGG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 12725151,
      "PARTS CATEGORY_1": "VGG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 12729935,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 12729935,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 12742526,
      "PARTS CATEGORY": "VGG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 12742526,
      "PARTS CATEGORY_1": "VGG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 12742527,
      "PARTS CATEGORY": "VGG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 12742527,
      "PARTS CATEGORY_1": "VGG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 12743273,
      "PARTS CATEGORY": "VGG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 12743273,
      "PARTS CATEGORY_1": "VGG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 12743408,
      "PARTS CATEGORY": "VGG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 12743408,
      "PARTS CATEGORY_1": "VGG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 12744293,
      "PARTS CATEGORY": "VGG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 12744293,
      "PARTS CATEGORY_1": "VGG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 12745093,
      "PARTS CATEGORY": "VGG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 12745093,
      "PARTS CATEGORY_1": "VGG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14016107,
      "PARTS CATEGORY": "VGG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14016107,
      "PARTS CATEGORY_1": "VGG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 19629,
      "PARTS CATEGORY": "VGG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 19629,
      "PARTS CATEGORY_1": "VGG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 2010900046,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 2010900046,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 2030900065,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 2030900065,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 20792871,
      "PARTS CATEGORY": "VGG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 20792871,
      "PARTS CATEGORY_1": "VGG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 20798114,
      "PARTS CATEGORY": "VGG",
      "ROQ": 16,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 20798114,
      "PARTS CATEGORY_1": "VGG",
      "ROQ_1": 16,
      "APPROVED QTY_1": 16,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 20799064,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 20799064,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 20799719,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 20799719,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 21147445,
      "PARTS CATEGORY": "VGG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 21147445,
      "PARTS CATEGORY_1": "VGG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 1,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 21342980,
      "PARTS CATEGORY": "VGG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 21342980,
      "PARTS CATEGORY_1": "VGG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 29070000081,
      "PARTS CATEGORY": "VLG",
      "ROQ": 13,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 29070000081,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 13,
      "APPROVED QTY_1": 13,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 29070000621,
      "PARTS CATEGORY": "VLG",
      "ROQ": 24,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 29070000621,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 24,
      "APPROVED QTY_1": 24,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 29100000061,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 29100000061,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 29100004061,
      "PARTS CATEGORY": "VLG",
      "ROQ": 1,
      "APPROVED QTY": 1,
      "UOM": "PC",
      "PART NUMBER_1": 29100004061,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 29170019701,
      "PARTS CATEGORY": "VLG",
      "ROQ": 4,
      "APPROVED QTY": 3,
      "UOM": "PC",
      "PART NUMBER_1": 29170019701,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 4,
      "APPROVED QTY_1": -3,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12 + WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 29170019711,
      "PARTS CATEGORY": "VLG",
      "ROQ": 160,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 29170019711,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 160,
      "APPROVED QTY_1": 160,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 29170020531,
      "PARTS CATEGORY": "VLG",
      "ROQ": 5,
      "APPROVED QTY": 5,
      "UOM": "PC",
      "PART NUMBER_1": 29170020531,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 5,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 29170036961,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 29170036961,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 29170036971,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 29170036971,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 29170036981,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 29170036981,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 29170039941,
      "PARTS CATEGORY": "VLG",
      "ROQ": 30,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 29170039941,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 30,
      "APPROVED QTY_1": 30,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 29170039951,
      "PARTS CATEGORY": "VLG",
      "ROQ": 4,
      "APPROVED QTY": 2,
      "UOM": "PC",
      "PART NUMBER_1": 29170039951,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 4,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "PARTIAL WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 29170039961,
      "PARTS CATEGORY": "VLG",
      "ROQ": 2,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 29170039961,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 2,
      "APPROVED QTY_1": 2,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 29250000061,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 29250000061,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 29250000071,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 29250000071,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 29250000081,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 29250000081,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4013000016,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4013000016,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4013000020,
      "PARTS CATEGORY": "VLG",
      "ROQ": 119,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4013000020,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 119,
      "APPROVED QTY_1": 119,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4015000001,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4015000001,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4015000176,
      "PARTS CATEGORY": "VLG",
      "ROQ": 2,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4015000176,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 2,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4021000058,
      "PARTS CATEGORY": "VLG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4021000058,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4030000048,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4030000048,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4040000011,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4040000011,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4043000033,
      "PARTS CATEGORY": "VLG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4043000033,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4043000034,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4043000034,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4043000055,
      "PARTS CATEGORY": "VLG",
      "ROQ": 2,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4043000055,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 2,
      "APPROVED QTY_1": 2,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4043000056,
      "PARTS CATEGORY": "VLG",
      "ROQ": 2,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4043000056,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 2,
      "APPROVED QTY_1": 2,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4043000057,
      "PARTS CATEGORY": "VLG",
      "ROQ": 3,
      "APPROVED QTY": 3,
      "UOM": "PC",
      "PART NUMBER_1": 4043000057,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 3,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4043000058,
      "PARTS CATEGORY": "VLG",
      "ROQ": 2,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4043000058,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 2,
      "APPROVED QTY_1": 2,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4043000059,
      "PARTS CATEGORY": "VLG",
      "ROQ": 7,
      "APPROVED QTY": 3,
      "UOM": "PC",
      "PART NUMBER_1": 4043000059,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 7,
      "APPROVED QTY_1": -3,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12 + WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4043000060,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4043000060,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4043000173,
      "PARTS CATEGORY": "VLG",
      "ROQ": 2,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4043000173,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 2,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4043000337,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4043000337,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110000076368,
      "PARTS CATEGORY": "VLG",
      "ROQ": 3,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4110000076368,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 3,
      "APPROVED QTY_1": 3,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110000081265,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4110000081265,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110000081312,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4110000081312,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110000179020,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4110000179020,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110000556209,
      "PARTS CATEGORY": "VLG",
      "ROQ": 80,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4110000556209,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 80,
      "APPROVED QTY_1": 80,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110000589001,
      "PARTS CATEGORY": "VLG",
      "ROQ": 30,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4110000589001,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 30,
      "APPROVED QTY_1": 30,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110000589016,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4110000589016,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110000613,
      "PARTS CATEGORY": "VLG",
      "ROQ": 1,
      "APPROVED QTY": 1,
      "UOM": "PC",
      "PART NUMBER_1": 4110000613,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110000679001,
      "PARTS CATEGORY": "VLG",
      "ROQ": 9,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4110000679001,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 9,
      "APPROVED QTY_1": 9,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110000679002,
      "PARTS CATEGORY": "VLG",
      "ROQ": 1,
      "APPROVED QTY": 1,
      "UOM": "PC",
      "PART NUMBER_1": 4110000679002,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110001016004,
      "PARTS CATEGORY": "VLG",
      "ROQ": 13,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4110001016004,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 13,
      "APPROVED QTY_1": 9,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "PARTIAL WH12",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4120000065,
      "PARTS CATEGORY": "VLG",
      "ROQ": 5,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4120000065,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 5,
      "APPROVED QTY_1": 5,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4120000066,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4120000066,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4120000084,
      "PARTS CATEGORY": "VLG",
      "ROQ": 3,
      "APPROVED QTY": 1,
      "UOM": "PC",
      "PART NUMBER_1": 4120000084,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 3,
      "APPROVED QTY_1": -1,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12 + WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4120000094,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4120000094,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4120000759,
      "PARTS CATEGORY": "VLG",
      "ROQ": 2,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4120000759,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 2,
      "APPROVED QTY_1": 2,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4120000760,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4120000760,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4120001739,
      "PARTS CATEGORY": "VLG",
      "ROQ": 16,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4120001739,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 16,
      "APPROVED QTY_1": 16,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4120001739016,
      "PARTS CATEGORY": "VLG",
      "ROQ": 115,
      "APPROVED QTY": 2,
      "UOM": "PC",
      "PART NUMBER_1": 4120001739016,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 115,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "PARTIAL WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4120001954001,
      "PARTS CATEGORY": "VLG",
      "ROQ": 1,
      "APPROVED QTY": 1,
      "UOM": "PC",
      "PART NUMBER_1": 4120001954001,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4130000009,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4130000009,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4130000542,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4130000542,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4130000962,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4130000962,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4190000153001,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4190000153001,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4341,
      "PARTS CATEGORY": "VGG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4341,
      "PARTS CATEGORY_1": "VGG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 53063,
      "PARTS CATEGORY": "VGG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 53063,
      "PARTS CATEGORY_1": "VGG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 54934,
      "PARTS CATEGORY": "VGG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 54934,
      "PARTS CATEGORY_1": "VGG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 60100000,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 60100000,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 69510,
      "PARTS CATEGORY": "VGG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 69510,
      "PARTS CATEGORY_1": "VGG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 69772,
      "PARTS CATEGORY": "VGG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 69772,
      "PARTS CATEGORY_1": "VGG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 69779,
      "PARTS CATEGORY": "VGG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 69779,
      "PARTS CATEGORY_1": "VGG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 69781,
      "PARTS CATEGORY": "VGG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 69781,
      "PARTS CATEGORY_1": "VGG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 75439,
      "PARTS CATEGORY": "VGG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 75439,
      "PARTS CATEGORY_1": "VGG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 75442,
      "PARTS CATEGORY": "VGG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 75442,
      "PARTS CATEGORY_1": "VGG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 76282,
      "PARTS CATEGORY": "VGG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 76282,
      "PARTS CATEGORY_1": "VGG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 76288,
      "PARTS CATEGORY": "VGG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 76288,
      "PARTS CATEGORY_1": "VGG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 8111,
      "PARTS CATEGORY": "VGG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 8111,
      "PARTS CATEGORY_1": "VGG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 87022,
      "PARTS CATEGORY": "VGG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 87022,
      "PARTS CATEGORY_1": "VGG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 983336,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 983336,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 984507,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 984507,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 990202,
      "PARTS CATEGORY": "VGG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 990202,
      "PARTS CATEGORY_1": "VGG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 990427,
      "PARTS CATEGORY": "VGG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 990427,
      "PARTS CATEGORY_1": "VGG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 991039,
      "PARTS CATEGORY": "VGG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 991039,
      "PARTS CATEGORY_1": "VGG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 11000233,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 11000233,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 11026989,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 11026989,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 11033996,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 11033996,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 11033997,
      "PARTS CATEGORY": "VOG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 11033997,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 1,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 11033998,
      "PARTS CATEGORY": "VOG",
      "ROQ": 2,
      "APPROVED QTY": 2,
      "UOM": "PC",
      "PART NUMBER_1": 11033998,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 2,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 11033999,
      "PARTS CATEGORY": "VOG",
      "ROQ": 3,
      "APPROVED QTY": 1,
      "UOM": "PC",
      "PART NUMBER_1": 11033999,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 3,
      "APPROVED QTY_1": 2,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12 + WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 11035001,
      "PARTS CATEGORY": "VOG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 11035001,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 1,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 11035819,
      "PARTS CATEGORY": "VOG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 11035819,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 1,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 11039553,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 11039553,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 11039563,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 11039563,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 11039846,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 11039846,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 11039856,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 11039856,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 11056114,
      "PARTS CATEGORY": "VOG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 11056114,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 11081029,
      "PARTS CATEGORY": "VOG",
      "ROQ": 6,
      "APPROVED QTY": 6,
      "UOM": "PC",
      "PART NUMBER_1": 11081029,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 6,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 11100354,
      "PARTS CATEGORY": "VOG",
      "ROQ": 17,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 11100354,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 17,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 11103303,
      "PARTS CATEGORY": "VOG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 11103303,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 1,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 11110022,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 11110022,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 11110023,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 11110023,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 11110175,
      "PARTS CATEGORY": "VOG",
      "ROQ": 27,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 11110175,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 27,
      "APPROVED QTY_1": 27,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 11110176,
      "PARTS CATEGORY": "VOG",
      "ROQ": 12,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 11110176,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 12,
      "APPROVED QTY_1": 1,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "PARTIAL WH12",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 11110668,
      "PARTS CATEGORY": "VOG",
      "ROQ": 14,
      "APPROVED QTY": 14,
      "UOM": "PC",
      "PART NUMBER_1": 11110668,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 14,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 11110683,
      "PARTS CATEGORY": "VOG",
      "ROQ": 14,
      "APPROVED QTY": 14,
      "UOM": "PC",
      "PART NUMBER_1": 11110683,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 14,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 11110709,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 11110709,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 11117464,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 11117464,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 11121152,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 11121152,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 11142033,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 11142033,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 11142263,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 11142263,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 11147756,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 11147756,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 11164457,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 11164457,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 11168079,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 11168079,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 11170009,
      "PARTS CATEGORY": "VOG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 11170009,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 1,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 11170064,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 11170064,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 11170101,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 11170101,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 11172907,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 11172907,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 11204584,
      "PARTS CATEGORY": "VOG",
      "ROQ": 2,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 11204584,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 2,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 11417144,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 11417144,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 11417145,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 11417145,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 11417147,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 11417147,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 11445025,
      "PARTS CATEGORY": "VOG",
      "ROQ": 2,
      "APPROVED QTY": 2,
      "UOM": "PC",
      "PART NUMBER_1": 11445025,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 2,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 11448509,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 11448509,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 1161277,
      "PARTS CATEGORY": "VTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 1161277,
      "PARTS CATEGORY_1": "VTG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 11700348,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 11700348,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 11703980,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 11703980,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 11705485,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 11705485,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 11707077,
      "PARTS CATEGORY": "VOG",
      "ROQ": 18,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 11707077,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 18,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 11707544,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 11707544,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 11707766,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 11707766,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 11709359,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 11709359,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 11711074,
      "PARTS CATEGORY": "VOG",
      "ROQ": 25,
      "APPROVED QTY": 25,
      "UOM": "PC",
      "PART NUMBER_1": 11711074,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 25,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 11713138,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 11713138,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 11713240,
      "PARTS CATEGORY": "VOG",
      "ROQ": 4,
      "APPROVED QTY": 4,
      "UOM": "PC",
      "PART NUMBER_1": 11713240,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 4,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 11715266,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 11715266,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 11716553,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 11716553,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 11883618,
      "PARTS CATEGORY": "VOG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 11883618,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 1,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 11883619,
      "PARTS CATEGORY": "VOG",
      "ROQ": 2,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 11883619,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 2,
      "APPROVED QTY_1": 2,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 11988156,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 11988156,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 11988971,
      "PARTS CATEGORY": "VOG",
      "ROQ": 4,
      "APPROVED QTY": 4,
      "UOM": "PC",
      "PART NUMBER_1": 11988971,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 4,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 13914468,
      "PARTS CATEGORY": "VOG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 13914468,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 13947281,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 13947281,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 13947624,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 13947624,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 13948511,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 13948511,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 13963059,
      "PARTS CATEGORY": "VOG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 13963059,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 13963103,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 13963103,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 13969162,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 13969162,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 20443754,
      "PARTS CATEGORY": "VTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 20443754,
      "PARTS CATEGORY_1": "VTG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 20488307,
      "PARTS CATEGORY": "VTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 20488307,
      "PARTS CATEGORY_1": "VTG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 20503552,
      "PARTS CATEGORY": "VTG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 20503552,
      "PARTS CATEGORY_1": "VTG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 1,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 20519534,
      "PARTS CATEGORY": "VTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 20519534,
      "PARTS CATEGORY_1": "VTG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 20524936,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 20524936,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 20572459,
      "PARTS CATEGORY": "VTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 20572459,
      "PARTS CATEGORY_1": "VTG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 20579690,
      "PARTS CATEGORY": "VTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 20579690,
      "PARTS CATEGORY_1": "VTG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 20708247,
      "PARTS CATEGORY": "VTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 20708247,
      "PARTS CATEGORY_1": "VTG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 20779040,
      "PARTS CATEGORY": "VTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 20779040,
      "PARTS CATEGORY_1": "VTG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 20872874,
      "PARTS CATEGORY": "VTG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 20872874,
      "PARTS CATEGORY_1": "VTG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 20910229,
      "PARTS CATEGORY": "VTG",
      "ROQ": 2,
      "APPROVED QTY": 1,
      "UOM": "PC",
      "PART NUMBER_1": 20910229,
      "PARTS CATEGORY_1": "VTG",
      "ROQ_1": 2,
      "APPROVED QTY_1": 1,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12 + WHXX",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 20917279,
      "PARTS CATEGORY": "VTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 20917279,
      "PARTS CATEGORY_1": "VTG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 20998367,
      "PARTS CATEGORY": "VTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 20998367,
      "PARTS CATEGORY_1": "VTG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 20998807,
      "PARTS CATEGORY": "VTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 20998807,
      "PARTS CATEGORY_1": "VTG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 21122035,
      "PARTS CATEGORY": "VTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 21122035,
      "PARTS CATEGORY_1": "VTG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 21244638,
      "PARTS CATEGORY": "VTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 21244638,
      "PARTS CATEGORY_1": "VTG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 21303956,
      "PARTS CATEGORY": "VTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 21303956,
      "PARTS CATEGORY_1": "VTG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 21312237,
      "PARTS CATEGORY": "VTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 21312237,
      "PARTS CATEGORY_1": "VTG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 21321093,
      "PARTS CATEGORY": "VTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 21321093,
      "PARTS CATEGORY_1": "VTG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 21339283,
      "PARTS CATEGORY": "VTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 21339283,
      "PARTS CATEGORY_1": "VTG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 21347087,
      "PARTS CATEGORY": "VTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 21347087,
      "PARTS CATEGORY_1": "VTG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 21380475,
      "PARTS CATEGORY": "VTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 21380475,
      "PARTS CATEGORY_1": "VTG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 21380488,
      "PARTS CATEGORY": "VTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 21380488,
      "PARTS CATEGORY_1": "VTG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 21620181,
      "PARTS CATEGORY": "VTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 21620181,
      "PARTS CATEGORY_1": "VTG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 257658,
      "PARTS CATEGORY": "VTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 257658,
      "PARTS CATEGORY_1": "VTG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 3988823,
      "PARTS CATEGORY": "VTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 3988823,
      "PARTS CATEGORY_1": "VTG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 8097084,
      "PARTS CATEGORY": "VTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 8097084,
      "PARTS CATEGORY_1": "VTG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 8143122,
      "PARTS CATEGORY": "VTG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 8143122,
      "PARTS CATEGORY_1": "VTG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 8189632,
      "PARTS CATEGORY": "VTG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 8189632,
      "PARTS CATEGORY_1": "VTG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 1,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 85110482,
      "PARTS CATEGORY": "VTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 85110482,
      "PARTS CATEGORY_1": "VTG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 85110486,
      "PARTS CATEGORY": "VTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 85110486,
      "PARTS CATEGORY_1": "VTG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 914468,
      "PARTS CATEGORY": "VTG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 914468,
      "PARTS CATEGORY_1": "VTG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 914474,
      "PARTS CATEGORY": "VTG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 914474,
      "PARTS CATEGORY_1": "VTG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 1,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 943903,
      "PARTS CATEGORY": "VTG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 943903,
      "PARTS CATEGORY_1": "VTG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 945988,
      "PARTS CATEGORY": "VTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 945988,
      "PARTS CATEGORY_1": "VTG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 946817,
      "PARTS CATEGORY": "VTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 946817,
      "PARTS CATEGORY_1": "VTG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 949329,
      "PARTS CATEGORY": "VTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 949329,
      "PARTS CATEGORY_1": "VTG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 967708,
      "PARTS CATEGORY": "VTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 967708,
      "PARTS CATEGORY_1": "VTG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 970742,
      "PARTS CATEGORY": "VTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 970742,
      "PARTS CATEGORY_1": "VTG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 970926,
      "PARTS CATEGORY": "VTG",
      "ROQ": 3,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 970926,
      "PARTS CATEGORY_1": "VTG",
      "ROQ_1": 3,
      "APPROVED QTY_1": 3,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 971069,
      "PARTS CATEGORY": "VTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 971069,
      "PARTS CATEGORY_1": "VTG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 982322,
      "PARTS CATEGORY": "VTG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 982322,
      "PARTS CATEGORY_1": "VTG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 1,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 983472,
      "PARTS CATEGORY": "VTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 983472,
      "PARTS CATEGORY_1": "VTG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 990037,
      "PARTS CATEGORY": "VTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 990037,
      "PARTS CATEGORY_1": "VTG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 992065,
      "PARTS CATEGORY": "VTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 992065,
      "PARTS CATEGORY_1": "VTG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 992519,
      "PARTS CATEGORY": "VTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 992519,
      "PARTS CATEGORY_1": "VTG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 992521,
      "PARTS CATEGORY": "VTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 992521,
      "PARTS CATEGORY_1": "VTG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 994555,
      "PARTS CATEGORY": "VTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 994555,
      "PARTS CATEGORY_1": "VTG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 8102116,
      "PARTS CATEGORY": "VTS",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 8102116,
      "PARTS CATEGORY_1": "VTS",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": "14750667H",
      "PARTS CATEGORY": "VTS",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "14750667H",
      "PARTS CATEGORY_1": "VTS",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": "14896991A",
      "PARTS CATEGORY": "VTS",
      "ROQ": 5,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "14896991A",
      "PARTS CATEGORY_1": "VTS",
      "ROQ_1": 5,
      "APPROVED QTY_1": 5,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": "A210609000087",
      "PARTS CATEGORY": "YPG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "A210609000087",
      "PARTS CATEGORY_1": "YPG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": "A820606010004",
      "PARTS CATEGORY": "YPG",
      "ROQ": 2,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "A820606010004",
      "PARTS CATEGORY_1": "YPG",
      "ROQ_1": 2,
      "APPROVED QTY_1": 2,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "CMI"
    },
    {
      "PART NUMBER": "A820606010023",
      "PARTS CATEGORY": "YPG",
      "ROQ": 97,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "A820606010023",
      "PARTS CATEGORY_1": "YPG",
      "ROQ_1": 97,
      "APPROVED QTY_1": 97,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "CMI"
    },
    {
      "PART NUMBER": "B222100000116",
      "PARTS CATEGORY": "YPG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "B222100000116",
      "PARTS CATEGORY_1": "YPG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": "B222100000159",
      "PARTS CATEGORY": "YCG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "B222100000159",
      "PARTS CATEGORY_1": "YCG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": "B222100000445",
      "PARTS CATEGORY": "YCG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "B222100000445",
      "PARTS CATEGORY_1": "YCG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 11435548,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 11435548,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14626370,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14626370,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": "C11195",
      "PARTS CATEGORY": "SOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "C11195",
      "PARTS CATEGORY_1": "SOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": "C31211",
      "PARTS CATEGORY": "SOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "C31211",
      "PARTS CATEGORY_1": "SOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 11103371,
      "PARTS CATEGORY": "VOG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 11103371,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 1,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 11103372,
      "PARTS CATEGORY": "VOG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 11103372,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 1,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 29020008421,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 29020008421,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 29170036941,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 29170036941,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 11060946,
      "PARTS CATEGORY": "VOG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 11060946,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 1,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14521945,
      "PARTS CATEGORY": "VSG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14521945,
      "PARTS CATEGORY_1": "VSG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 984753,
      "PARTS CATEGORY": "VTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 984753,
      "PARTS CATEGORY_1": "VTG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 90898,
      "PARTS CATEGORY": "VGG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 90898,
      "PARTS CATEGORY_1": "VGG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14513267,
      "PARTS CATEGORY": "VSG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14513267,
      "PARTS CATEGORY_1": "VSG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 992518,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 992518,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 15144740,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 15144740,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 11198489,
      "PARTS CATEGORY": "VOG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 11198489,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 1,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 914465,
      "PARTS CATEGORY": "VTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 914465,
      "PARTS CATEGORY_1": "VTG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": "LF9009VE",
      "PARTS CATEGORY": "CUF",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "LF9009VE",
      "PARTS CATEGORY_1": "CUF",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "OLD"
    },
    {
      "PART NUMBER": 13965190,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 13965190,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 20555065,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 20555065,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 11102861,
      "PARTS CATEGORY": "VOG",
      "ROQ": 1,
      "APPROVED QTY": 1,
      "UOM": "PC",
      "PART NUMBER_1": 11102861,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 1635243,
      "PARTS CATEGORY": "VTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 1635243,
      "PARTS CATEGORY_1": "VTG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 20943389,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 20943389,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14376598,
      "PARTS CATEGORY": "VOG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14376598,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 1,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 29220001251,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 29220001251,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4030000552,
      "PARTS CATEGORY": "VLG",
      "ROQ": 1,
      "APPROVED QTY": 1,
      "UOM": "PC",
      "PART NUMBER_1": 4030000552,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 950629,
      "PARTS CATEGORY": "VOG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 950629,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 1,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 21312236,
      "PARTS CATEGORY": "VTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 21312236,
      "PARTS CATEGORY_1": "VTG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 11443400,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 11443400,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": "16Y-04C-02000",
      "PARTS CATEGORY": "SDG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "16Y-04C-02000",
      "PARTS CATEGORY_1": "SDG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 1,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 3972382,
      "PARTS CATEGORY": "CUG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 3972382,
      "PARTS CATEGORY_1": "CUG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": 0
    },
    {
      "PART NUMBER": 3022474,
      "PARTS CATEGORY": "SDG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 3022474,
      "PARTS CATEGORY_1": "SDG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 4130000523,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4130000523,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4130000286,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4130000286,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 21761288,
      "PARTS CATEGORY": "VTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 21761288,
      "PARTS CATEGORY_1": "VTG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 14523551,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14523551,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14522960,
      "PARTS CATEGORY": "VOG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14522960,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 20764159,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 20764159,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 20542497,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 20542497,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 20764147,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 20764147,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4130000420,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4130000420,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 907816,
      "PARTS CATEGORY": "VOG",
      "ROQ": 2,
      "APPROVED QTY": 2,
      "UOM": "PC",
      "PART NUMBER_1": 907816,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 2,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14622357,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14622357,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": "C31246",
      "PARTS CATEGORY": "SOG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "C31246",
      "PARTS CATEGORY_1": "SOG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 1,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 949800,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 949800,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14530666,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14530666,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14500291,
      "PARTS CATEGORY": "VOG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14500291,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14506431,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14506431,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14518258,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14518258,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 11705231,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 11705231,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 20412762,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 20412762,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 11700334,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 11700334,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4120001739007,
      "PARTS CATEGORY": "VLG",
      "ROQ": 3,
      "APPROVED QTY": 3,
      "UOM": "PC",
      "PART NUMBER_1": 4120001739007,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 3,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4120001739008,
      "PARTS CATEGORY": "VLG",
      "ROQ": 4,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4120001739008,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 4,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110000301,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4110000301,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4120001739011,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4120001739011,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4120001739009,
      "PARTS CATEGORY": "VLG",
      "ROQ": 3,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4120001739009,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 3,
      "APPROVED QTY_1": 3,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4120001739004,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4120001739004,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4190000599,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4190000599,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110000076366,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4110000076366,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4021000023,
      "PARTS CATEGORY": "VLG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4021000023,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4011000084,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4011000084,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110000367112,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4110000367112,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110000367113,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4110000367113,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110000367114,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4110000367114,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110000367115,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4110000367115,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110000076258,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4110000076258,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110000367118,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4110000367118,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110000367101,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4110000367101,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110000367105,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4110000367105,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110000076353,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4110000076353,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110000076247,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4110000076247,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110000076295,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4110000076295,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110000076191,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4110000076191,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110000076067,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4110000076067,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110000076068,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4110000076068,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110000076069,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4110000076069,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110000076107,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4110000076107,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 7200001482,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 7200001482,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110000367072,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4110000367072,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110000076309,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4110000076309,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110000076391,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4110000076391,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110000076281,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4110000076281,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 20459855,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 20459855,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 20890422,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 20890422,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 21496591,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 21496591,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 21531890,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 21531890,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": "154-78-31330",
      "PARTS CATEGORY": "SDG",
      "ROQ": 2,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "154-78-31330",
      "PARTS CATEGORY_1": "SDG",
      "ROQ_1": 2,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 21638691,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 21638691,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 21527767,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 21527767,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 6212888,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 6212888,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 20515173,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 20515173,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 21165497,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 21165497,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14559054,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14559054,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4880495,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4880495,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 20405721,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 20405721,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 992515,
      "PARTS CATEGORY": "VTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 992515,
      "PARTS CATEGORY_1": "VTG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 11431708,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 11431708,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 17214676,
      "PARTS CATEGORY": "VSG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 17214676,
      "PARTS CATEGORY_1": "VSG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 21354759,
      "PARTS CATEGORY": "VTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 21354759,
      "PARTS CATEGORY_1": "VTG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 14527865,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14527865,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": "A210401000017",
      "PARTS CATEGORY": "YPG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "A210401000017",
      "PARTS CATEGORY_1": "YPG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 1,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 15145555,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 15145555,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 991044,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 991044,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110000507,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4110000507,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 993324,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 993324,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4120001739005,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4120001739005,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4120001739006,
      "PARTS CATEGORY": "VLG",
      "ROQ": 3,
      "APPROVED QTY": 3,
      "UOM": "PC",
      "PART NUMBER_1": 4120001739006,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 3,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 29070000071,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 29070000071,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14512396,
      "PARTS CATEGORY": "VOG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14512396,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 3979593,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 3979593,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 20724977,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 20724977,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 15153124,
      "PARTS CATEGORY": "VOG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 15153124,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 1,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 983242,
      "PARTS CATEGORY": "VOG",
      "ROQ": 1,
      "APPROVED QTY": 1,
      "UOM": "PC",
      "PART NUMBER_1": 983242,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110000081107,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4110000081107,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": "9444-10312",
      "PARTS CATEGORY": "VSG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "9444-10312",
      "PARTS CATEGORY_1": "VSG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14529762,
      "PARTS CATEGORY": "VOG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14529762,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14529761,
      "PARTS CATEGORY": "VOG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14529761,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4130000543,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4130000543,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 15195930,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 15195930,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 20405652,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 20405652,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4130000873,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4130000873,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14540003,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14540003,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4130000121,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4130000121,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": "D81151",
      "PARTS CATEGORY": "SOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "D81151",
      "PARTS CATEGORY_1": "SOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 14621703,
      "PARTS CATEGORY": "VSG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14621703,
      "PARTS CATEGORY_1": "VSG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 20459246,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 20459246,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": "B222100000751",
      "PARTS CATEGORY": "YCG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "B222100000751",
      "PARTS CATEGORY_1": "YCG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": "B222100000424",
      "PARTS CATEGORY": "YPG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "B222100000424",
      "PARTS CATEGORY_1": "YPG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": "A222100000600",
      "PARTS CATEGORY": "YCG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "A222100000600",
      "PARTS CATEGORY_1": "YCG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": "A222100000599",
      "PARTS CATEGORY": "YCG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "A222100000599",
      "PARTS CATEGORY_1": "YCG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 21312238,
      "PARTS CATEGORY": "VTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 21312238,
      "PARTS CATEGORY_1": "VTG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 12729862,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 12729862,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 12741621,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 12741621,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 990201,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 990201,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 12736497,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 12736497,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 12736498,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 12736498,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 12736499,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 12736499,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 12735791,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 12735791,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 906645,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 906645,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 907035,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 907035,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 990950,
      "PARTS CATEGORY": "VTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 990950,
      "PARTS CATEGORY_1": "VTG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 29070000331,
      "PARTS CATEGORY": "VLG",
      "ROQ": 3,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 29070000331,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 3,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 29070000381,
      "PARTS CATEGORY": "VLG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 29070000381,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14595621,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14595621,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 17204355,
      "PARTS CATEGORY": "VOG",
      "ROQ": 2,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 17204355,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 2,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4130000426,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4130000426,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 3283767,
      "PARTS CATEGORY": "CUG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 3283767,
      "PARTS CATEGORY_1": "CUG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": 0
    },
    {
      "PART NUMBER": "154-32-11113",
      "PARTS CATEGORY": "ZZZ",
      "ROQ": 3,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "154-32-11113",
      "PARTS CATEGORY_1": "ZZZ",
      "ROQ_1": 3,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "CMI"
    },
    {
      "PART NUMBER": "154-27-12273A",
      "PARTS CATEGORY": "SDG",
      "ROQ": 16,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "154-27-12273A",
      "PARTS CATEGORY_1": "SDG",
      "ROQ_1": 16,
      "APPROVED QTY_1": 10,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "PARTIAL WH12",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 12746330,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 12746330,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 990200,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 990200,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 990951,
      "PARTS CATEGORY": "VTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 990951,
      "PARTS CATEGORY_1": "VTG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 4041000020,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4041000020,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4030000146,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4030000146,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4804410,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4804410,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 11110705,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 11110705,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 21761154,
      "PARTS CATEGORY": "VTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 21761154,
      "PARTS CATEGORY_1": "VTG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 1635357,
      "PARTS CATEGORY": "VTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 1635357,
      "PARTS CATEGORY_1": "VTG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 20799762,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 20799762,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 15015574,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 15015574,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110000331007,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4110000331007,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 59286344,
      "PARTS CATEGORY": "VCG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 59286344,
      "PARTS CATEGORY_1": "VCG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110000025006,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4110000025006,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 13949268,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 13949268,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 16021529,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 16021529,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 59815449,
      "PARTS CATEGORY": "VCG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 59815449,
      "PARTS CATEGORY_1": "VCG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 21707132,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 21707132,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4030000536,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4030000536,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110000507007,
      "PARTS CATEGORY": "VLG",
      "ROQ": 8,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4110000507007,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 8,
      "APPROVED QTY_1": 8,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": "9412-22050",
      "PARTS CATEGORY": "VSG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "9412-22050",
      "PARTS CATEGORY_1": "VSG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110001015026,
      "PARTS CATEGORY": "VLG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4110001015026,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 21628356,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 21628356,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4021000038,
      "PARTS CATEGORY": "VLG",
      "ROQ": 1,
      "APPROVED QTY": 1,
      "UOM": "PC",
      "PART NUMBER_1": 4021000038,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4021000034,
      "PARTS CATEGORY": "VLG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4021000034,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 1,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14548265,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14548265,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14576425,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14576425,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 21707134,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 21707134,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 20460811,
      "PARTS CATEGORY": "VOG",
      "ROQ": 1,
      "APPROVED QTY": 1,
      "UOM": "PC",
      "PART NUMBER_1": 20460811,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14559479,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14559479,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 15066534,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 15066534,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": "1040-20410",
      "PARTS CATEGORY": "VSG",
      "ROQ": 2,
      "APPROVED QTY": 2,
      "UOM": "PC",
      "PART NUMBER_1": "1040-20410",
      "PARTS CATEGORY_1": "VSG",
      "ROQ_1": 2,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14377007,
      "PARTS CATEGORY": "VOG",
      "ROQ": 4,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14377007,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 4,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 21707133,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 21707133,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 12743123,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 12743123,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4030000046,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4030000046,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14529765,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14529765,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14540405,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14540405,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 993325,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 993325,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 992517,
      "PARTS CATEGORY": "VTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 992517,
      "PARTS CATEGORY_1": "VTG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": "16Y-60-00013",
      "PARTS CATEGORY": "SDG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "16Y-60-00013",
      "PARTS CATEGORY_1": "SDG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 4120001739053,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4120001739053,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 70930474,
      "PARTS CATEGORY": "VCG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 70930474,
      "PARTS CATEGORY_1": "VCG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 70606165,
      "PARTS CATEGORY": "VCG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 70606165,
      "PARTS CATEGORY_1": "VCG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14622355,
      "PARTS CATEGORY": "VOG",
      "ROQ": 38,
      "APPROVED QTY": 38,
      "UOM": "PC",
      "PART NUMBER_1": 14622355,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 38,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14523235,
      "PARTS CATEGORY": "VOG",
      "ROQ": 104,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14523235,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 104,
      "APPROVED QTY_1": 39,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "PARTIAL WH12",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14561585,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14561585,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14517943,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14517943,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14547552,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14547552,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14547553,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14547553,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14614180,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14614180,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 17214674,
      "PARTS CATEGORY": "VOG",
      "ROQ": 3,
      "APPROVED QTY": 3,
      "UOM": "PC",
      "PART NUMBER_1": 17214674,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 3,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 11708829,
      "PARTS CATEGORY": "VOG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 11708829,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 15067640,
      "PARTS CATEGORY": "LUV",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 15067640,
      "PARTS CATEGORY_1": "LUV",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "LUBRICANTS"
    },
    {
      "PART NUMBER": 21141948,
      "PARTS CATEGORY": "VOG",
      "ROQ": 2,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 21141948,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 2,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4030000047,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4030000047,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 29070001231,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 29070001231,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 29070001071,
      "PARTS CATEGORY": "VLG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 29070001071,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": "C31127",
      "PARTS CATEGORY": "SOG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "C31127",
      "PARTS CATEGORY_1": "SOG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 1,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "CMI"
    },
    {
      "PART NUMBER": "C31226",
      "PARTS CATEGORY": "SOG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "C31226",
      "PARTS CATEGORY_1": "SOG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 1,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 20412745,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 20412745,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14550602,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14550602,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110000556002,
      "PARTS CATEGORY": "VLG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4110000556002,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 43922517,
      "PARTS CATEGORY": "VCG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 43922517,
      "PARTS CATEGORY_1": "VCG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 12746657,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 12746657,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 12746660,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 12746660,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14591830,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14591830,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14532688,
      "PARTS CATEGORY": "VOG",
      "ROQ": 2,
      "APPROVED QTY": 2,
      "UOM": "PC",
      "PART NUMBER_1": 14532688,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 2,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 15195958,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 15195958,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": "C61227",
      "PARTS CATEGORY": "SOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "C61227",
      "PARTS CATEGORY_1": "SOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 15080985,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 15080985,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 20805850,
      "PARTS CATEGORY": "VTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 20805850,
      "PARTS CATEGORY_1": "VTG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 14562924,
      "PARTS CATEGORY": "VOG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14562924,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 7200001639,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 7200001639,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 20450694,
      "PARTS CATEGORY": "VOG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 20450694,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14516446,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14516446,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110000076282,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4110000076282,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110000367051,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4110000367051,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 21508091,
      "PARTS CATEGORY": "VTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 21508091,
      "PARTS CATEGORY_1": "VTG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 21294062,
      "PARTS CATEGORY": "VTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 21294062,
      "PARTS CATEGORY_1": "VTG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 943481,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 943481,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14880956,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14880956,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": "XC8286057",
      "PARTS CATEGORY": "VOA",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "XC8286057",
      "PARTS CATEGORY_1": "VOA",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": 0
    },
    {
      "PART NUMBER": 12778469,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 12778469,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 12778473,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 12778473,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 21489734,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 21489734,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 914515,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 914515,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110000076367,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4110000076367,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110000076270,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4110000076270,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110000367125,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4110000367125,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110000076256,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4110000076256,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110000076027,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4110000076027,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110000555021,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4110000555021,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 21299547,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 21299547,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 12779101,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 12779101,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 990861,
      "PARTS CATEGORY": "VTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 990861,
      "PARTS CATEGORY_1": "VTG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 976047,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 976047,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 20460215,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 20460215,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110000556085,
      "PARTS CATEGORY": "VLG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4110000556085,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 21647837,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 21647837,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 11156766,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 11156766,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 11156765,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 11156765,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 21620133,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 21620133,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14536585,
      "PARTS CATEGORY": "VOG",
      "ROQ": 1,
      "APPROVED QTY": 1,
      "UOM": "PC",
      "PART NUMBER_1": 14536585,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 11205832,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 11205832,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14550884,
      "PARTS CATEGORY": "VOG",
      "ROQ": 2,
      "APPROVED QTY": 2,
      "UOM": "PC",
      "PART NUMBER_1": 14550884,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 2,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 20459311,
      "PARTS CATEGORY": "VOG",
      "ROQ": 11,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 20459311,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 11,
      "APPROVED QTY_1": 11,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14625659,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14625659,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 29010011921,
      "PARTS CATEGORY": "VLG",
      "ROQ": 1,
      "APPROVED QTY": 1,
      "UOM": "PC",
      "PART NUMBER_1": 29010011921,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 20795296,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 20795296,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 13478078,
      "PARTS CATEGORY": "VCG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 13478078,
      "PARTS CATEGORY_1": "VCG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 54477161,
      "PARTS CATEGORY": "VCG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 54477161,
      "PARTS CATEGORY_1": "VCG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 54477153,
      "PARTS CATEGORY": "VCG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 54477153,
      "PARTS CATEGORY_1": "VCG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4120002263101,
      "PARTS CATEGORY": "VLG",
      "ROQ": 2,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4120002263101,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 2,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4120002264101,
      "PARTS CATEGORY": "VLG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4120002264101,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4120000553101,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4120000553101,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 13974079,
      "PARTS CATEGORY": "VOG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 13974079,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 1,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 11700285,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 11700285,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 21761263,
      "PARTS CATEGORY": "VTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 21761263,
      "PARTS CATEGORY_1": "VTG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": "C51191",
      "PARTS CATEGORY": "SOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "C51191",
      "PARTS CATEGORY_1": "SOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 994000,
      "PARTS CATEGORY": "VTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 994000,
      "PARTS CATEGORY_1": "VTG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": "7114-12371",
      "PARTS CATEGORY": "VSG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "7114-12371",
      "PARTS CATEGORY_1": "VSG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 70932538,
      "PARTS CATEGORY": "VCG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 70932538,
      "PARTS CATEGORY_1": "VCG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 2851010,
      "PARTS CATEGORY": "SOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 2851010,
      "PARTS CATEGORY_1": "SOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 2841217,
      "PARTS CATEGORY": "SOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 2841217,
      "PARTS CATEGORY_1": "SOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": "C31210",
      "PARTS CATEGORY": "SOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "C31210",
      "PARTS CATEGORY_1": "SOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 4101306,
      "PARTS CATEGORY": "SOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4101306,
      "PARTS CATEGORY_1": "SOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 21758906,
      "PARTS CATEGORY": "VTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 21758906,
      "PARTS CATEGORY_1": "VTG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": "B222100000508",
      "PARTS CATEGORY": "YCG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "B222100000508",
      "PARTS CATEGORY_1": "YCG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 20459838,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 20459838,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 11700284,
      "PARTS CATEGORY": "VOG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 11700284,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 29010020351,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 29010020351,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 70917182,
      "PARTS CATEGORY": "VCG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 70917182,
      "PARTS CATEGORY_1": "VCG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14542155,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14542155,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14524470,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14524470,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 11039847,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 11039847,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14612048,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14612048,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14881287,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14881287,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 990603,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 990603,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14589150,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14589150,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14632775,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14632775,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14622356,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14622356,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14594697,
      "PARTS CATEGORY": "VSG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14594697,
      "PARTS CATEGORY_1": "VSG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4804563,
      "PARTS CATEGORY": "VOG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4804563,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 1,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 43993021,
      "PARTS CATEGORY": "VCG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 43993021,
      "PARTS CATEGORY_1": "VCG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 960631,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 960631,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 976041,
      "PARTS CATEGORY": "VTG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 976041,
      "PARTS CATEGORY_1": "VTG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 1,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 468947,
      "PARTS CATEGORY": "VTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 468947,
      "PARTS CATEGORY_1": "VTG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 14511752,
      "PARTS CATEGORY": "VOG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14511752,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": "7114-11950",
      "PARTS CATEGORY": "VSG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "7114-11950",
      "PARTS CATEGORY_1": "VSG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 59190975,
      "PARTS CATEGORY": "VCG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 59190975,
      "PARTS CATEGORY_1": "VCG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 2851051,
      "PARTS CATEGORY": "SOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 2851051,
      "PARTS CATEGORY_1": "SOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 2844901,
      "PARTS CATEGORY": "SOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 2844901,
      "PARTS CATEGORY_1": "SOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 2850014,
      "PARTS CATEGORY": "SOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 2850014,
      "PARTS CATEGORY_1": "SOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 2850003,
      "PARTS CATEGORY": "SOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 2850003,
      "PARTS CATEGORY_1": "SOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 11705143,
      "PARTS CATEGORY": "VOG",
      "ROQ": 1,
      "APPROVED QTY": 1,
      "UOM": "PC",
      "PART NUMBER_1": 11705143,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14630764,
      "PARTS CATEGORY": "VSG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14630764,
      "PARTS CATEGORY_1": "VSG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 21598183,
      "PARTS CATEGORY": "VSG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 21598183,
      "PARTS CATEGORY_1": "VSG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 46678140,
      "PARTS CATEGORY": "VCG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 46678140,
      "PARTS CATEGORY_1": "VCG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 95098679,
      "PARTS CATEGORY": "VCG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 95098679,
      "PARTS CATEGORY_1": "VCG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14519262,
      "PARTS CATEGORY": "VSG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14519262,
      "PARTS CATEGORY_1": "VSG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 3938162,
      "PARTS CATEGORY": "CUG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 3938162,
      "PARTS CATEGORY_1": "CUG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": 0
    },
    {
      "PART NUMBER": 17225331,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 17225331,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 993320,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 993320,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": "150-70-21346",
      "PARTS CATEGORY": "SDG",
      "ROQ": 3,
      "APPROVED QTY": 1,
      "UOM": "PC",
      "PART NUMBER_1": "150-70-21346",
      "PARTS CATEGORY_1": "SDG",
      "ROQ_1": 3,
      "APPROVED QTY_1": -1,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12 + WHXX",
      "BU": "CMI"
    },
    {
      "PART NUMBER": "150-70-21356",
      "PARTS CATEGORY": "SDG",
      "ROQ": 3,
      "APPROVED QTY": 1,
      "UOM": "PC",
      "PART NUMBER_1": "150-70-21356",
      "PARTS CATEGORY_1": "SDG",
      "ROQ_1": 3,
      "APPROVED QTY_1": -1,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12 + WHXX",
      "BU": "CMI"
    },
    {
      "PART NUMBER": "154-70-11314",
      "PARTS CATEGORY": "SDG",
      "ROQ": 4,
      "APPROVED QTY": 1,
      "UOM": "PC",
      "PART NUMBER_1": "154-70-11314",
      "PARTS CATEGORY_1": "SDG",
      "ROQ_1": 4,
      "APPROVED QTY_1": -1,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12 + WHXX",
      "BU": "CMI"
    },
    {
      "PART NUMBER": "175-78-31230",
      "PARTS CATEGORY": "SDG",
      "ROQ": 4,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "175-78-31230",
      "PARTS CATEGORY_1": "SDG",
      "ROQ_1": 4,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 14507432,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14507432,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 12746650,
      "PARTS CATEGORY": "VOG",
      "ROQ": 2,
      "APPROVED QTY": 2,
      "UOM": "PC",
      "PART NUMBER_1": 12746650,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 2,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": "C61139",
      "PARTS CATEGORY": "SOG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "C61139",
      "PARTS CATEGORY_1": "SOG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 14508698,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14508698,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 20482732,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 20482732,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 20551332,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 20551332,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 15011845,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 15011845,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 15603435,
      "PARTS CATEGORY": "VOG",
      "ROQ": 2,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 15603435,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 2,
      "APPROVED QTY_1": 2,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 15603422,
      "PARTS CATEGORY": "VOG",
      "ROQ": 2,
      "APPROVED QTY": 2,
      "UOM": "PC",
      "PART NUMBER_1": 15603422,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 2,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4130000427,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4130000427,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 20482731,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 20482731,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 13959262,
      "PARTS CATEGORY": "VOG",
      "ROQ": 2,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 13959262,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 2,
      "APPROVED QTY_1": 2,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 11055748,
      "PARTS CATEGORY": "VOG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 11055748,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14542156,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14542156,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4190000138,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4190000138,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14536511,
      "PARTS CATEGORY": "VSG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14536511,
      "PARTS CATEGORY_1": "VSG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 13980834,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 13980834,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 15052786,
      "PARTS CATEGORY": "VOG",
      "ROQ": 18,
      "APPROVED QTY": 18,
      "UOM": "PC",
      "PART NUMBER_1": 15052786,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 18,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 79509,
      "PARTS CATEGORY": "VGG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 79509,
      "PARTS CATEGORY_1": "VGG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 29220001321,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 29220001321,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14621335,
      "PARTS CATEGORY": "VOG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14621335,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 1,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14647065,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14647065,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14530607,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14530607,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": "213379Z00B",
      "PARTS CATEGORY": "NIG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "213379Z00B",
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": "801019Z00A",
      "PARTS CATEGORY": "NIG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "801019Z00A",
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": "331769Z00A",
      "PARTS CATEGORY": "NIG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "331769Z00A",
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": "333419Z00A",
      "PARTS CATEGORY": "NIG",
      "ROQ": 2,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "333419Z00A",
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 2,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 4030000311,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4030000311,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": "A222100000393",
      "PARTS CATEGORY": "YPG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "A222100000393",
      "PARTS CATEGORY_1": "YPG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 1,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 4110000076352,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4110000076352,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110000076268,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4110000076268,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110000367099,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4110000367099,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110000727085,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4110000727085,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 29070000591,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 29070000591,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": "C3X005",
      "PARTS CATEGORY": "SOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "C3X005",
      "PARTS CATEGORY_1": "SOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 14643849,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14643849,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 20838727,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 20838727,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 11428148,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 11428148,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 15178089,
      "PARTS CATEGORY": "VOG",
      "ROQ": 1,
      "APPROVED QTY": 1,
      "UOM": "PC",
      "PART NUMBER_1": 15178089,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": "23Y-58D-15000",
      "PARTS CATEGORY": "SDG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "23Y-58D-15000",
      "PARTS CATEGORY_1": "SDG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": "911119Z01D",
      "PARTS CATEGORY": "NIG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "911119Z01D",
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 11701842,
      "PARTS CATEGORY": "VOG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 11701842,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14641479,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14641479,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 20459172,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 20459172,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": "306209Z00B",
      "PARTS CATEGORY": "NIG",
      "ROQ": 3,
      "APPROVED QTY": 2,
      "UOM": "PC",
      "PART NUMBER_1": "306209Z00B",
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 3,
      "APPROVED QTY_1": -2,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12 + WHXX",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": "550209Z00A",
      "PARTS CATEGORY": "NIG",
      "ROQ": 4,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "550209Z00A",
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 4,
      "APPROVED QTY_1": 4,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": "295579Z00A",
      "PARTS CATEGORY": "NIG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "295579Z00A",
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": "911119Z02C",
      "PARTS CATEGORY": "NIG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "911119Z02C",
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 4110000592008,
      "PARTS CATEGORY": "VLG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4110000592008,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 1,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": "4514904Z2E",
      "PARTS CATEGORY": "NIG",
      "ROQ": 3,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "4514904Z2E",
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 3,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": "333029Z00E",
      "PARTS CATEGORY": "NIG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "333029Z00E",
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": "333199Z00A",
      "PARTS CATEGORY": "NIG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "333199Z00A",
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": "333569Z00A",
      "PARTS CATEGORY": "NIG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "333569Z00A",
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": "333029Z00D",
      "PARTS CATEGORY": "NIG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "333029Z00D",
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": "995499Z00C",
      "PARTS CATEGORY": "NIG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "995499Z00C",
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": "307059Z00C",
      "PARTS CATEGORY": "NIG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "307059Z00C",
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": "542209Z00A",
      "PARTS CATEGORY": "NIG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "542209Z00A",
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": "542199Z00A",
      "PARTS CATEGORY": "NIG",
      "ROQ": 2,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "542199Z00A",
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 2,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": "542199Z00D",
      "PARTS CATEGORY": "NIG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "542199Z00D",
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": "405669Z00B",
      "PARTS CATEGORY": "NIG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "405669Z00B",
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": "282299Z00A",
      "PARTS CATEGORY": "NIG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "282299Z00A",
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": "282299Z03B",
      "PARTS CATEGORY": "NIG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "282299Z03B",
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": "911119Z03E",
      "PARTS CATEGORY": "NIG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "911119Z03E",
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": "555469Z00D",
      "PARTS CATEGORY": "NIG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "555469Z00D",
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": "201269Z01E",
      "PARTS CATEGORY": "NIG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "201269Z01E",
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": "475119Z00E",
      "PARTS CATEGORY": "NIG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "475119Z00E",
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": "951489Z00C",
      "PARTS CATEGORY": "NIG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "951489Z00C",
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 14588541,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14588541,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110000991005,
      "PARTS CATEGORY": "VLG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4110000991005,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 20551429,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 20551429,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 20460936,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 20460936,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14532285,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14532285,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": "4120000675Z",
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "4120000675Z",
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": "4130000418A",
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "4130000418A",
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110000189022,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4110000189022,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": "308129Z00B",
      "PARTS CATEGORY": "NIG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "308129Z00B",
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": "452059Z00C",
      "PARTS CATEGORY": "NIG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "452059Z00C",
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": "475119Z02C",
      "PARTS CATEGORY": "NIG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "475119Z02C",
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 4110001593002,
      "PARTS CATEGORY": "VLG",
      "ROQ": 4,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4110001593002,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 4,
      "APPROVED QTY_1": 4,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110000189031,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4110000189031,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 960247,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 960247,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": "4514904Z2C",
      "PARTS CATEGORY": "NIG",
      "ROQ": 3,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "4514904Z2C",
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 3,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": "382179Z00A",
      "PARTS CATEGORY": "NIG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "382179Z00A",
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": "995499Z00D",
      "PARTS CATEGORY": "NIG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "995499Z00D",
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": "B222100000257",
      "PARTS CATEGORY": "YCG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "B222100000257",
      "PARTS CATEGORY_1": "YCG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 14535346,
      "PARTS CATEGORY": "VOG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14535346,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14511974,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14511974,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": "145849Z06A",
      "PARTS CATEGORY": "NIG",
      "ROQ": 3,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "145849Z06A",
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 3,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 17216674,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 17216674,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 17216672,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 17216672,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": "432409Z00B",
      "PARTS CATEGORY": "NIG",
      "ROQ": 7,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "432409Z00B",
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 7,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": "207049Z00C",
      "PARTS CATEGORY": "NIG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "207049Z00C",
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": "381669Z09E",
      "PARTS CATEGORY": "NIG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "381669Z09E",
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": "963019Z01B",
      "PARTS CATEGORY": "NIG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "963019Z01B",
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": "C61451",
      "PARTS CATEGORY": "SOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "C61451",
      "PARTS CATEGORY_1": "SOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 11193390,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 11193390,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 979008,
      "PARTS CATEGORY": "VOG",
      "ROQ": 1,
      "APPROVED QTY": 1,
      "UOM": "PC",
      "PART NUMBER_1": 979008,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14550598,
      "PARTS CATEGORY": "VOG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14550598,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14532448,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14532448,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14527463,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14527463,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4013000205,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4013000205,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110000556161,
      "PARTS CATEGORY": "VLG",
      "ROQ": 2,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4110000556161,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 2,
      "APPROVED QTY_1": 2,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110000556139,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4110000556139,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110000556066,
      "PARTS CATEGORY": "VLG",
      "ROQ": 4,
      "APPROVED QTY": 1,
      "UOM": "PC",
      "PART NUMBER_1": 4110000556066,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 4,
      "APPROVED QTY_1": 2,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "PARTIAL WHXX + WH12",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110000556030,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4110000556030,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110000924025,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4110000924025,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110000556087,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4110000556087,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110000556142,
      "PARTS CATEGORY": "VLG",
      "ROQ": 2,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4110000556142,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 2,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110000556165,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4110000556165,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110000556155,
      "PARTS CATEGORY": "VLG",
      "ROQ": 4,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4110000556155,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 4,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 85136370,
      "PARTS CATEGORY": "LUV",
      "ROQ": 5,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 85136370,
      "PARTS CATEGORY_1": "LUV",
      "ROQ_1": 5,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "LUBRICANTS"
    },
    {
      "PART NUMBER": "2001700Z0A",
      "PARTS CATEGORY": "NIG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "2001700Z0A",
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": "2002200Z0A",
      "PARTS CATEGORY": "NIG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "2002200Z0A",
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": "146769Z00E",
      "PARTS CATEGORY": "NIG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "146769Z00E",
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 29350009031,
      "PARTS CATEGORY": "VLG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 29350009031,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 1,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": "145849Z00D",
      "PARTS CATEGORY": "NIG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "145849Z00D",
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": "4514904Z5A",
      "PARTS CATEGORY": "NIG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "4514904Z5A",
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": "4514904Z3B",
      "PARTS CATEGORY": "NIG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "4514904Z3B",
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 28140006791,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 28140006791,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4043000218,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4043000218,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4043000112,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4043000112,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4190002758,
      "PARTS CATEGORY": "VLG",
      "ROQ": 2,
      "APPROVED QTY": 1,
      "UOM": "PC",
      "PART NUMBER_1": 4190002758,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 2,
      "APPROVED QTY_1": 1,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12 + WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": "B229900003708",
      "PARTS CATEGORY": "YPG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "B229900003708",
      "PARTS CATEGORY_1": "YPG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": "381659Z00A",
      "PARTS CATEGORY": "NIG",
      "ROQ": 1,
      "APPROVED QTY": 1,
      "UOM": "PC",
      "PART NUMBER_1": "381659Z00A",
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": "321369Z00A",
      "PARTS CATEGORY": "NIG",
      "ROQ": 14,
      "APPROVED QTY": 14,
      "UOM": "PC",
      "PART NUMBER_1": "321369Z00A",
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 14,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 5333477,
      "PARTS CATEGORY": "CUG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 5333477,
      "PARTS CATEGORY_1": "CUG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": 0
    },
    {
      "PART NUMBER": 14663703,
      "PARTS CATEGORY": "VOG",
      "ROQ": 8,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14663703,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 8,
      "APPROVED QTY_1": 8,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": "550459Z00E",
      "PARTS CATEGORY": "NIG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "550459Z00E",
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": "261369Z01C",
      "PARTS CATEGORY": "NIG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "261369Z01C",
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": "201349Z00A",
      "PARTS CATEGORY": "NIG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "201349Z00A",
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": "969229Z01A ",
      "PARTS CATEGORY": "NIG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "969229Z01A ",
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 14621172,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14621172,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14623356,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14623356,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 936439,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 936439,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 15113226,
      "PARTS CATEGORY": "VOG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 15113226,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 21119083,
      "PARTS CATEGORY": "VOG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 21119083,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": "263109Z00B",
      "PARTS CATEGORY": "NIG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "263109Z00B",
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": "654189Z00A",
      "PARTS CATEGORY": "NIG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "654189Z00A",
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": "322919Z01B",
      "PARTS CATEGORY": "NIG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "322919Z01B",
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": "329349Z00B",
      "PARTS CATEGORY": "NIG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "329349Z00B",
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": "324979Z00C",
      "PARTS CATEGORY": "NIG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "324979Z00C",
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": "798499Z00B",
      "PARTS CATEGORY": "NIG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "798499Z00B",
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": "324979Z00D",
      "PARTS CATEGORY": "NIG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "324979Z00D",
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 14671313,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14671313,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4021000029,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4021000029,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4021000028,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4021000028,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4021000035,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4021000035,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4021000026,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4021000026,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": "L01110",
      "PARTS CATEGORY": "SOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "L01110",
      "PARTS CATEGORY_1": "SOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": "272129Z00B",
      "PARTS CATEGORY": "NIG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "272129Z00B",
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 9021306350,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 9021306350,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": "B230101001367",
      "PARTS CATEGORY": "YPG",
      "ROQ": 2,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "B230101001367",
      "PARTS CATEGORY_1": "YPG",
      "ROQ_1": 2,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "CMI"
    },
    {
      "PART NUMBER": "B230101001366",
      "PARTS CATEGORY": "YPG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "B230101001366",
      "PARTS CATEGORY_1": "YPG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 4110000054003,
      "PARTS CATEGORY": "VLG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4110000054003,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110000054002,
      "PARTS CATEGORY": "VLG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4110000054002,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110000054100,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4110000054100,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110000846035,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4110000846035,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110000054284,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4110000054284,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110000054006,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4110000054006,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110000054191,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4110000054191,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4120004769,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4120004769,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110001031042,
      "PARTS CATEGORY": "VLG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4110001031042,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 17220227,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 17220227,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 29070010931,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 29070010931,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14663702,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14663702,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 28140007461,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 28140007461,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 28140007451,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 28140007451,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110000054128,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4110000054128,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 991971,
      "PARTS CATEGORY": "VTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 991971,
      "PARTS CATEGORY_1": "VTG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 4110000057003,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4110000057003,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110001525005,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4110001525005,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 11705144,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 11705144,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": "L01198",
      "PARTS CATEGORY": "SOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "L01198",
      "PARTS CATEGORY_1": "SOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 14650315,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14650315,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": "2010200Z7B",
      "PARTS CATEGORY": "NIG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "2010200Z7B",
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": "7114-12121",
      "PARTS CATEGORY": "VSG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "7114-12121",
      "PARTS CATEGORY_1": "VSG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": "8220-13470",
      "PARTS CATEGORY": "VSG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "8220-13470",
      "PARTS CATEGORY_1": "VSG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": "7114-17460",
      "PARTS CATEGORY": "VSG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "7114-17460",
      "PARTS CATEGORY_1": "VSG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14528776,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14528776,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": "963229Z00A",
      "PARTS CATEGORY": "NIG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "963229Z00A",
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 15185168,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 15185168,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 21242584,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 21242584,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 21896344,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 21896344,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14604058,
      "PARTS CATEGORY": "VOG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14604058,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": "154-71-41270V010",
      "PARTS CATEGORY": "SDG",
      "ROQ": 135,
      "APPROVED QTY": 21,
      "UOM": "PC",
      "PART NUMBER_1": "154-71-41270V010",
      "PARTS CATEGORY_1": "SDG",
      "ROQ_1": 135,
      "APPROVED QTY_1": -21,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12 + WHXX",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 4043000028,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4043000028,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 11103470,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 11103470,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 15010424,
      "PARTS CATEGORY": "VOG",
      "ROQ": 2,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 15010424,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 2,
      "APPROVED QTY_1": 1,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "PARTIAL WH12",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14649606,
      "PARTS CATEGORY": "VOG",
      "ROQ": 2,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14649606,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 2,
      "APPROVED QTY_1": 2,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": "328599Z00D",
      "PARTS CATEGORY": "NIG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "328599Z00D",
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": "451849Z00B",
      "PARTS CATEGORY": "NIG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "451849Z00B",
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 14558072,
      "PARTS CATEGORY": "VOG",
      "ROQ": 1,
      "APPROVED QTY": 1,
      "UOM": "PC",
      "PART NUMBER_1": 14558072,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 21337557,
      "PARTS CATEGORY": "VTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 21337557,
      "PARTS CATEGORY_1": "VTG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 21693755,
      "PARTS CATEGORY": "VTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 21693755,
      "PARTS CATEGORY_1": "VTG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": "651659Z00B",
      "PARTS CATEGORY": "NIG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "651659Z00B",
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 17429862,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 17429862,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4021000033,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4021000033,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4021000037,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4021000037,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110000659,
      "PARTS CATEGORY": "VLG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4110000659,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 1,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4090000008,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4090000008,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4043000035,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4043000035,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4014000016,
      "PARTS CATEGORY": "VLG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4014000016,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4015000022,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4015000022,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 29070001261,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 29070001261,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 29070001271,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 29070001271,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110000556072,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4110000556072,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4120001739013,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4120001739013,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 59070326,
      "PARTS CATEGORY": "VCG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 59070326,
      "PARTS CATEGORY_1": "VCG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": "A220401000483",
      "PARTS CATEGORY": "YPG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "A220401000483",
      "PARTS CATEGORY_1": "YPG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 21244698,
      "PARTS CATEGORY": "VTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 21244698,
      "PARTS CATEGORY_1": "VTG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": "381029Z00A",
      "PARTS CATEGORY": "NIG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "381029Z00A",
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": "01010-51020",
      "PARTS CATEGORY": "SDG",
      "ROQ": 4,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "01010-51020",
      "PARTS CATEGORY_1": "SDG",
      "ROQ_1": 4,
      "APPROVED QTY_1": 4,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 4110000076402,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4110000076402,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": "A810299000932",
      "PARTS CATEGORY": "YCG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "A810299000932",
      "PARTS CATEGORY_1": "YCG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 12606029,
      "PARTS CATEGORY": "YPG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 12606029,
      "PARTS CATEGORY_1": "YPG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 1,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 28350002251,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 28350002251,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 994781,
      "PARTS CATEGORY": "VTG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 994781,
      "PARTS CATEGORY_1": "VTG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 1,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 994926,
      "PARTS CATEGORY": "VTG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 994926,
      "PARTS CATEGORY_1": "VTG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 1,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": "178109Z01A",
      "PARTS CATEGORY": "NIG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "178109Z01A",
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": "178109Z01B",
      "PARTS CATEGORY": "NIG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "178109Z01B",
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": "178109Z01D",
      "PARTS CATEGORY": "NIG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "178109Z01D",
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 14693120,
      "PARTS CATEGORY": "VOG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14693120,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 20517048,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 20517048,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 29050020541,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 29050020541,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 29050020581,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 29050020581,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14659471,
      "PARTS CATEGORY": "VOG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14659471,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 29050000071,
      "PARTS CATEGORY": "VLG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 29050000071,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14602950,
      "PARTS CATEGORY": "VOG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14602950,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 1,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 12734398,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 12734398,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14588062,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14588062,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": "921399Z00A",
      "PARTS CATEGORY": "NIG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "921399Z00A",
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 14619060,
      "PARTS CATEGORY": "VOG",
      "ROQ": 3,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14619060,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 3,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14619061,
      "PARTS CATEGORY": "VOG",
      "ROQ": 2,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14619061,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 2,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 21687048,
      "PARTS CATEGORY": "VTG",
      "ROQ": 2,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 21687048,
      "PARTS CATEGORY_1": "VTG",
      "ROQ_1": 2,
      "APPROVED QTY_1": 2,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": "C31525",
      "PARTS CATEGORY": "SOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "C31525",
      "PARTS CATEGORY_1": "SOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": "L51204",
      "PARTS CATEGORY": "SOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "L51204",
      "PARTS CATEGORY_1": "SOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 28110002321,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 28110002321,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4130001898,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4130001898,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4130001086,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4130001086,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4190002822,
      "PARTS CATEGORY": "VLG",
      "ROQ": 1,
      "APPROVED QTY": 1,
      "UOM": "PC",
      "PART NUMBER_1": 4190002822,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4130001422,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4130001422,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4130001314,
      "PARTS CATEGORY": "VLG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4130001314,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 11215524,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 11215524,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4130001101,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4130001101,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110000970112,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4110000970112,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110001841019,
      "PARTS CATEGORY": "VLG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4110001841019,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4190002765,
      "PARTS CATEGORY": "VLG",
      "ROQ": 3,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4190002765,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 3,
      "APPROVED QTY_1": 3,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110001905057,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4110001905057,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": "430519Z00C",
      "PARTS CATEGORY": "NIG",
      "ROQ": 1,
      "APPROVED QTY": 1,
      "UOM": "PC",
      "PART NUMBER_1": "430519Z00C",
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": "261369Z00D",
      "PARTS CATEGORY": "NIG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "261369Z00D",
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 4015000167,
      "PARTS CATEGORY": "VLG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4015000167,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 1,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": "995499Z00A",
      "PARTS CATEGORY": "NIG",
      "ROQ": 2,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "995499Z00A",
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 2,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": "995499Z00B",
      "PARTS CATEGORY": "NIG",
      "ROQ": 2,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "995499Z00B",
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 2,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": "443419Z01A",
      "PARTS CATEGORY": "NIG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "443419Z01A",
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": "502389Z00A",
      "PARTS CATEGORY": "NIG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "502389Z00A",
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": "555029Z00A",
      "PARTS CATEGORY": "NIG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "555029Z00A",
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": "542199Z01A",
      "PARTS CATEGORY": "NIG",
      "ROQ": 4,
      "APPROVED QTY": 2,
      "UOM": "PC",
      "PART NUMBER_1": "542199Z01A",
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 4,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "PARTIAL WHXX",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": "178109Z00A",
      "PARTS CATEGORY": "NIG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "178109Z00A",
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": "U81275",
      "PARTS CATEGORY": "SOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "U81275",
      "PARTS CATEGORY_1": "SOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 60208879,
      "PARTS CATEGORY": "YCG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 60208879,
      "PARTS CATEGORY_1": "YCG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 60208878,
      "PARTS CATEGORY": "YCG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 60208878,
      "PARTS CATEGORY_1": "YCG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 14515524,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14515524,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": "A220302000023",
      "PARTS CATEGORY": "YPG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "A220302000023",
      "PARTS CATEGORY_1": "YPG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 21259899,
      "PARTS CATEGORY": "VTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 21259899,
      "PARTS CATEGORY_1": "VTG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 22058741,
      "PARTS CATEGORY": "VTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 22058741,
      "PARTS CATEGORY_1": "VTG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 22058737,
      "PARTS CATEGORY": "VTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 22058737,
      "PARTS CATEGORY_1": "VTG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 11193389,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 11193389,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110000054044,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4110000054044,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": "797009Z00A",
      "PARTS CATEGORY": "NIG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "797009Z00A",
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 980973,
      "PARTS CATEGORY": "VOG",
      "ROQ": 1,
      "APPROVED QTY": 1,
      "UOM": "PC",
      "PART NUMBER_1": 980973,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14557971,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14557971,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4190002827,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4190002827,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 20459235,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 20459235,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 21027485,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 21027485,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4120002027,
      "PARTS CATEGORY": "VLG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4120002027,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 1,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4021000043,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4021000043,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14530656,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14530656,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 9017204355,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 9017204355,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 28350001021,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 28350001021,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4021000050,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4021000050,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4015000046,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4015000046,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 60034226,
      "PARTS CATEGORY": "YCG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 60034226,
      "PARTS CATEGORY_1": "YCG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 15013381,
      "PARTS CATEGORY": "VOG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 15013381,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 1,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 43974310,
      "PARTS CATEGORY": "VCG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 43974310,
      "PARTS CATEGORY_1": "VCG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4130000624,
      "PARTS CATEGORY": "VLG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4130000624,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 96719604,
      "PARTS CATEGORY": "VCG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 96719604,
      "PARTS CATEGORY_1": "VCG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110001948041,
      "PARTS CATEGORY": "VLG",
      "ROQ": 9,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4110001948041,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 9,
      "APPROVED QTY_1": 9,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110000909074,
      "PARTS CATEGORY": "VLG",
      "ROQ": 1,
      "APPROVED QTY": 1,
      "UOM": "PC",
      "PART NUMBER_1": 4110000909074,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4120002104001,
      "PARTS CATEGORY": "VLG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4120002104001,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 1,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 29350010491,
      "PARTS CATEGORY": "VLG",
      "ROQ": 3,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 29350010491,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 3,
      "APPROVED QTY_1": 3,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 29350010501,
      "PARTS CATEGORY": "VLG",
      "ROQ": 2,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 29350010501,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 2,
      "APPROVED QTY_1": 2,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4190002863,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4190002863,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110000924103,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4110000924103,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 21602385,
      "PARTS CATEGORY": "VTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 21602385,
      "PARTS CATEGORY_1": "VTG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 14588542,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14588542,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": "542199Z00C",
      "PARTS CATEGORY": "NIG",
      "ROQ": 2,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "542199Z00C",
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 2,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 14573760,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14573760,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14616919,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14616919,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14573771,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14573771,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 17444646,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 17444646,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": "333119Z00B",
      "PARTS CATEGORY": "NIG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "333119Z00B",
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 22383381,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 22383381,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": "320139Z00C",
      "PARTS CATEGORY": "NIG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "320139Z00C",
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": "320139Z00A",
      "PARTS CATEGORY": "NIG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "320139Z00A",
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": "324979Z00E",
      "PARTS CATEGORY": "NIG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "324979Z00E",
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": "261809Z00C",
      "PARTS CATEGORY": "NIG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "261809Z00C",
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": "631029Z00B",
      "PARTS CATEGORY": "NIG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "631029Z00B",
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 4110002147005,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4110002147005,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 11444203,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 11444203,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": "166959Z01D",
      "PARTS CATEGORY": "NIG",
      "ROQ": 10,
      "APPROVED QTY": 10,
      "UOM": "PC",
      "PART NUMBER_1": "166959Z01D",
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 10,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": "475009Z01A",
      "PARTS CATEGORY": "NIG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "475009Z01A",
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": "324879Z00D",
      "PARTS CATEGORY": "NIG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "324879Z00D",
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": "963229Z00C",
      "PARTS CATEGORY": "NIG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "963229Z00C",
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": "301009Z01A",
      "PARTS CATEGORY": "NIG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "301009Z01A",
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": "967179Z00C",
      "PARTS CATEGORY": "NIG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "967179Z00C",
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": "967179Z00A",
      "PARTS CATEGORY": "NIG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "967179Z00A",
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": "551219Z03B",
      "PARTS CATEGORY": "NIG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "551219Z03B",
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": "542139Z01C",
      "PARTS CATEGORY": "NIG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "542139Z01C",
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": "550579Z00A",
      "PARTS CATEGORY": "NIG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "550579Z00A",
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 14506786,
      "PARTS CATEGORY": "VOG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14506786,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": "631249Z00E",
      "PARTS CATEGORY": "NIG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "631249Z00E",
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 4130000869,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4130000869,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110000924044,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4110000924044,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 29050018231,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 29050018231,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 29050018221,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 29050018221,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 29050018241,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 29050018241,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": "555479Z01A",
      "PARTS CATEGORY": "NIG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "555479Z01A",
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": "911119Z00E",
      "PARTS CATEGORY": "NIG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "911119Z00E",
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 4130000618,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4130000618,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 17411027,
      "PARTS CATEGORY": "VOG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 17411027,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 1,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14521612,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14521612,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 15066561,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 15066561,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14515742,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14515742,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 17411510,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 17411510,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14689735,
      "PARTS CATEGORY": "VOG",
      "ROQ": 1,
      "APPROVED QTY": 1,
      "UOM": "PC",
      "PART NUMBER_1": 14689735,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4030000768,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4030000768,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 29050016421,
      "PARTS CATEGORY": "VLG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 29050016421,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": "322699Z00C",
      "PARTS CATEGORY": "NIG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "322699Z00C",
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 14659238,
      "PARTS CATEGORY": "VOG",
      "ROQ": 2,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14659238,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 2,
      "APPROVED QTY_1": 2,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": "081A66162A",
      "PARTS CATEGORY": "NIG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "081A66162A",
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": "01010-52490",
      "PARTS CATEGORY": "SDG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "01010-52490",
      "PARTS CATEGORY_1": "SDG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 16680661,
      "PARTS CATEGORY": "VOG",
      "ROQ": 2,
      "APPROVED QTY": 2,
      "UOM": "PC",
      "PART NUMBER_1": 16680661,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 2,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14640101,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14640101,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": "440669Z01D",
      "PARTS CATEGORY": "NIG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "440669Z01D",
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 22610786,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 22610786,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": "XC8286065",
      "PARTS CATEGORY": "VOA",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "XC8286065",
      "PARTS CATEGORY_1": "VOA",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": 0
    },
    {
      "PART NUMBER": 11110708,
      "PARTS CATEGORY": "VOG",
      "ROQ": 7,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 11110708,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 7,
      "APPROVED QTY_1": 7,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 29070011091,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 29070011091,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110000189026,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4110000189026,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110001554019,
      "PARTS CATEGORY": "VLG",
      "ROQ": 1,
      "APPROVED QTY": 1,
      "UOM": "PC",
      "PART NUMBER_1": 4110001554019,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 22567321,
      "PARTS CATEGORY": "VTG",
      "ROQ": 48,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 22567321,
      "PARTS CATEGORY_1": "VTG",
      "ROQ_1": 48,
      "APPROVED QTY_1": 48,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 84168569,
      "PARTS CATEGORY": "VTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 84168569,
      "PARTS CATEGORY_1": "VTG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": "551229Z00B",
      "PARTS CATEGORY": "NIG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "551229Z00B",
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 11689838,
      "PARTS CATEGORY": "YTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 11689838,
      "PARTS CATEGORY_1": "YTG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": "270489Z04A",
      "PARTS CATEGORY": "NIG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "270489Z04A",
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 4780963,
      "PARTS CATEGORY": "VGG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4780963,
      "PARTS CATEGORY_1": "VGG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14594707,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14594707,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 20459015,
      "PARTS CATEGORY": "VSG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 20459015,
      "PARTS CATEGORY_1": "VSG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14558709,
      "PARTS CATEGORY": "VOG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14558709,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 1,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110000924023,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4110000924023,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": "440669Z01B",
      "PARTS CATEGORY": "NIG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "440669Z01B",
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 22768937,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 22768937,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 84208821,
      "PARTS CATEGORY": "VTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 84208821,
      "PARTS CATEGORY_1": "VTG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 22583048,
      "PARTS CATEGORY": "VTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 22583048,
      "PARTS CATEGORY_1": "VTG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 17418256,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 17418256,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14675537,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14675537,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": "A810201206088",
      "PARTS CATEGORY": "YCG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "A810201206088",
      "PARTS CATEGORY_1": "YCG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 21162862,
      "PARTS CATEGORY": "VTG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 21162862,
      "PARTS CATEGORY_1": "VTG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 1,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 954493,
      "PARTS CATEGORY": "VTG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 954493,
      "PARTS CATEGORY_1": "VTG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 1,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 984437,
      "PARTS CATEGORY": "VTG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 984437,
      "PARTS CATEGORY_1": "VTG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 1,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 984438,
      "PARTS CATEGORY": "VTG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 984438,
      "PARTS CATEGORY_1": "VTG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 1,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 60128948,
      "PARTS CATEGORY": "YPG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 60128948,
      "PARTS CATEGORY_1": "YPG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": "4120000090Z",
      "PARTS CATEGORY": "VLG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "4120000090Z",
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 17418268,
      "PARTS CATEGORY": "VOG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 17418268,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14652325,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14652325,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": "381669Z00C",
      "PARTS CATEGORY": "NIG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "381669Z00C",
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": "333109Z00A",
      "PARTS CATEGORY": "NIG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "333109Z00A",
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": "328339Z04A",
      "PARTS CATEGORY": "NIG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "328339Z04A",
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 14550595,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14550595,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": "410309Z03D",
      "PARTS CATEGORY": "NIG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "410309Z03D",
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 14661022,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14661022,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4130001509,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4130001509,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14592567,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14592567,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4120000868101,
      "PARTS CATEGORY": "VLG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4120000868101,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4120000867101,
      "PARTS CATEGORY": "VLG",
      "ROQ": 3,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4120000867101,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 3,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4120001004101,
      "PARTS CATEGORY": "VLG",
      "ROQ": 3,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4120001004101,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 3,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110002115,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4110002115,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14881136,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14881136,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14880494,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14880494,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 70932553,
      "PARTS CATEGORY": "VCG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 70932553,
      "PARTS CATEGORY_1": "VCG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 70932546,
      "PARTS CATEGORY": "VCG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 70932546,
      "PARTS CATEGORY_1": "VCG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": "155-30-00118P010-01",
      "PARTS CATEGORY": "SDG",
      "ROQ": 4,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "155-30-00118P010-01",
      "PARTS CATEGORY_1": "SDG",
      "ROQ_1": 4,
      "APPROVED QTY_1": 4,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "CMI"
    },
    {
      "PART NUMBER": "155-30-00128P010-01",
      "PARTS CATEGORY": "SDG",
      "ROQ": 13,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "155-30-00128P010-01",
      "PARTS CATEGORY_1": "SDG",
      "ROQ_1": 13,
      "APPROVED QTY_1": 9,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "PARTIAL WH12",
      "BU": "CMI"
    },
    {
      "PART NUMBER": "01010-52275",
      "PARTS CATEGORY": "SDG",
      "ROQ": 95,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "01010-52275",
      "PARTS CATEGORY_1": "SDG",
      "ROQ_1": 95,
      "APPROVED QTY_1": 34,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "PARTIAL WH12",
      "BU": "CMI"
    },
    {
      "PART NUMBER": "01643-32260",
      "PARTS CATEGORY": "SDG",
      "ROQ": 105,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "01643-32260",
      "PARTS CATEGORY_1": "SDG",
      "ROQ_1": 105,
      "APPROVED QTY_1": 30,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "PARTIAL WH12",
      "BU": "CMI"
    },
    {
      "PART NUMBER": "8216-MD-00062",
      "PARTS CATEGORY": "SDG",
      "ROQ": 27,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "8216-MD-00062",
      "PARTS CATEGORY_1": "SDG",
      "ROQ_1": 27,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "CMI"
    },
    {
      "PART NUMBER": "8220-02230",
      "PARTS CATEGORY": "VSG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "8220-02230",
      "PARTS CATEGORY_1": "VSG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 11705232,
      "PARTS CATEGORY": "VOG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 11705232,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 1,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 11705233,
      "PARTS CATEGORY": "VOG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 11705233,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 1,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 11705239,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 11705239,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14558056,
      "PARTS CATEGORY": "VOG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14558056,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 1,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4120005062,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4120005062,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14591829,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14591829,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14592562,
      "PARTS CATEGORY": "VOG",
      "ROQ": 1,
      "APPROVED QTY": 1,
      "UOM": "PC",
      "PART NUMBER_1": 14592562,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 20459236,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 20459236,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 20412318,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 20412318,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 20405590,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 20405590,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 20459171,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 20459171,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110001031037,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4110001031037,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14686054,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14686054,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4190000020,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4190000020,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110001921005,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4110001921005,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4120004982,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4120004982,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 15035179,
      "PARTS CATEGORY": "VOG",
      "ROQ": 28,
      "APPROVED QTY": 28,
      "UOM": "PC",
      "PART NUMBER_1": 15035179,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 28,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 46625802,
      "PARTS CATEGORY": "DOP",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 46625802,
      "PARTS CATEGORY_1": "DOP",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 29170010601,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 29170010601,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 29170010611,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 29170010611,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4040000007,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4040000007,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 22327063,
      "PARTS CATEGORY": "VTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 22327063,
      "PARTS CATEGORY_1": "VTG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": "302009Z01E",
      "PARTS CATEGORY": "NIG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "302009Z01E",
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": "A810299000577",
      "PARTS CATEGORY": "YPG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "A810299000577",
      "PARTS CATEGORY_1": "YPG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 9060100000,
      "PARTS CATEGORY": "VOG",
      "ROQ": 7,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 9060100000,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 7,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 991084,
      "PARTS CATEGORY": "VTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 991084,
      "PARTS CATEGORY_1": "VTG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 14658433,
      "PARTS CATEGORY": "VOG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14658433,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110001905060,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4110001905060,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110001394104,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4110001394104,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 3050900049,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 3050900049,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14701884,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14701884,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 29070012891,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 29070012891,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 29070015591,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 29070015591,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4014000033,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4014000033,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 29070012671,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 29070012671,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 29070012661,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 29070012661,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 29070012681,
      "PARTS CATEGORY": "VLG",
      "ROQ": 1,
      "APPROVED QTY": 1,
      "UOM": "PC",
      "PART NUMBER_1": 29070012681,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 29070012691,
      "PARTS CATEGORY": "VLG",
      "ROQ": 2,
      "APPROVED QTY": 2,
      "UOM": "PC",
      "PART NUMBER_1": 29070012691,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 2,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 29070012711,
      "PARTS CATEGORY": "VLG",
      "ROQ": 2,
      "APPROVED QTY": 2,
      "UOM": "PC",
      "PART NUMBER_1": 29070012711,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 2,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4090000158,
      "PARTS CATEGORY": "VLG",
      "ROQ": 9,
      "APPROVED QTY": 9,
      "UOM": "PC",
      "PART NUMBER_1": 4090000158,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 9,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 29070012701,
      "PARTS CATEGORY": "VLG",
      "ROQ": 1,
      "APPROVED QTY": 1,
      "UOM": "PC",
      "PART NUMBER_1": 29070012701,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 183680,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 183680,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 12809750,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 12809750,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 28140009601,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 28140009601,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 28140009611,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 28140009611,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 28140010441,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 28140010441,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4130001966,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4130001966,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4130001973,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4130001973,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110002321001,
      "PARTS CATEGORY": "VLG",
      "ROQ": 3,
      "APPROVED QTY": 3,
      "UOM": "PC",
      "PART NUMBER_1": 4110002321001,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 3,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110002373009,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4110002373009,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4190001830,
      "PARTS CATEGORY": "VLG",
      "ROQ": 4,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4190001830,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 4,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 26180007661,
      "PARTS CATEGORY": "VLG",
      "ROQ": 4,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 26180007661,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 4,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 21092586,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 21092586,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14576499,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14576499,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14626783,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14626783,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 16271772,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 16271772,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 29070012481,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 29070012481,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": "684609Z00B",
      "PARTS CATEGORY": "NIG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "684609Z00B",
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 96702279,
      "PARTS CATEGORY": "VCG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 96702279,
      "PARTS CATEGORY_1": "VCG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110000846039,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4110000846039,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110000846017,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4110000846017,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110000054124,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4110000054124,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110000054235,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4110000054235,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110000054083,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4110000054083,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 60090788,
      "PARTS CATEGORY": "YPG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 60090788,
      "PARTS CATEGORY_1": "YPG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 10025037,
      "PARTS CATEGORY": "YPG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 10025037,
      "PARTS CATEGORY_1": "YPG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 10024462,
      "PARTS CATEGORY": "YPG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 10024462,
      "PARTS CATEGORY_1": "YPG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": "726119Z00B",
      "PARTS CATEGORY": "NIG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "726119Z00B",
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": "684609Z00A",
      "PARTS CATEGORY": "NIG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "684609Z00A",
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 14560241,
      "PARTS CATEGORY": "VOG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14560241,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 17412747,
      "PARTS CATEGORY": "VOG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 17412747,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 21312943,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 21312943,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 12793261,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 12793261,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110002278004,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4110002278004,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110001015014,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4110001015014,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4030000177,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4030000177,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110000172007,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4110000172007,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 21396128,
      "PARTS CATEGORY": "VTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 21396128,
      "PARTS CATEGORY_1": "VTG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 21893128,
      "PARTS CATEGORY": "VTG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 21893128,
      "PARTS CATEGORY_1": "VTG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": "P14691098",
      "PARTS CATEGORY": "VOA",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "P14691098",
      "PARTS CATEGORY_1": "VOA",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": 0
    },
    {
      "PART NUMBER": 5222807993,
      "PARTS CATEGORY": "NIG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 5222807993,
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": "150169Z00A",
      "PARTS CATEGORY": "NIG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "150169Z00A",
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 4120004037001,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4120004037001,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 29070015101,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 29070015101,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 29070015111,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 29070015111,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": "412339Z00A",
      "PARTS CATEGORY": "NIG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "412339Z00A",
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": "464139Z00A",
      "PARTS CATEGORY": "NIG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "464139Z00A",
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": "601079Z00A",
      "PARTS CATEGORY": "NIG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "601079Z00A",
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": "552299Z00A",
      "PARTS CATEGORY": "NIG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "552299Z00A",
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": "601389Z00A",
      "PARTS CATEGORY": "NIG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "601389Z00A",
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": "612079Z00B",
      "PARTS CATEGORY": "NIG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "612079Z00B",
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": "552299Z00B",
      "PARTS CATEGORY": "NIG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "552299Z00B",
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 996792,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 996792,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110001007001,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4110001007001,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 17408222,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 17408222,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": "440669Z01C",
      "PARTS CATEGORY": "NIG",
      "ROQ": 37,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "440669Z01C",
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 37,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": "475119Z00B",
      "PARTS CATEGORY": "NIG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "475119Z00B",
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 14530463,
      "PARTS CATEGORY": "VOG",
      "ROQ": 10,
      "APPROVED QTY": 7,
      "UOM": "PC",
      "PART NUMBER_1": 14530463,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 10,
      "APPROVED QTY_1": -7,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12 + WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": "7114-12630",
      "PARTS CATEGORY": "VSG",
      "ROQ": 1,
      "APPROVED QTY": 1,
      "UOM": "PC",
      "PART NUMBER_1": "7114-12630",
      "PARTS CATEGORY_1": "VSG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 29370019441,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 29370019441,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 17224267,
      "PARTS CATEGORY": "VOG",
      "ROQ": 1,
      "APPROVED QTY": 1,
      "UOM": "PC",
      "PART NUMBER_1": 17224267,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": "7114-12680",
      "PARTS CATEGORY": "VSG",
      "ROQ": 2,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "7114-12680",
      "PARTS CATEGORY_1": "VSG",
      "ROQ_1": 2,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14530477,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14530477,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 992516,
      "PARTS CATEGORY": "VTG",
      "ROQ": 5,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 992516,
      "PARTS CATEGORY_1": "VTG",
      "ROQ_1": 5,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 82494763,
      "PARTS CATEGORY": "VTG",
      "ROQ": 4,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 82494763,
      "PARTS CATEGORY_1": "VTG",
      "ROQ_1": 4,
      "APPROVED QTY_1": 4,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 4110000970109,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4110000970109,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": "272139Z00A",
      "PARTS CATEGORY": "NIG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "272139Z00A",
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": "250389Z01D",
      "PARTS CATEGORY": "NIG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "250389Z01D",
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 4110000988167,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4110000988167,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 21134532,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 21134532,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14880431,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14880431,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": "7114-17250",
      "PARTS CATEGORY": "VSG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "7114-17250",
      "PARTS CATEGORY_1": "VSG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": "9541-01062",
      "PARTS CATEGORY": "VSG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "9541-01062",
      "PARTS CATEGORY_1": "VSG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14646872,
      "PARTS CATEGORY": "VOG",
      "ROQ": 8,
      "APPROVED QTY": 8,
      "UOM": "PC",
      "PART NUMBER_1": 14646872,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 8,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 15427060,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 15427060,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14680534,
      "PARTS CATEGORY": "VOG",
      "ROQ": 15,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14680534,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 15,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14680532,
      "PARTS CATEGORY": "VOG",
      "ROQ": 30,
      "APPROVED QTY": 12,
      "UOM": "PC",
      "PART NUMBER_1": 14680532,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 30,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "PARTIAL WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14680533,
      "PARTS CATEGORY": "VOG",
      "ROQ": 5,
      "APPROVED QTY": 2,
      "UOM": "PC",
      "PART NUMBER_1": 14680533,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 5,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "PARTIAL WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 29090008771,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 29090008771,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 29070013111,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 29070013111,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": "9453-03615",
      "PARTS CATEGORY": "VSG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "9453-03615",
      "PARTS CATEGORY_1": "VSG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 29110000042,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 29110000042,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 969373,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 969373,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": "7114-12540",
      "PARTS CATEGORY": "VSG",
      "ROQ": 1,
      "APPROVED QTY": 1,
      "UOM": "PC",
      "PART NUMBER_1": "7114-12540",
      "PARTS CATEGORY_1": "VSG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 12813371,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 12813371,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": "7114-14370",
      "PARTS CATEGORY": "VSG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "7114-14370",
      "PARTS CATEGORY_1": "VSG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4014000118,
      "PARTS CATEGORY": "VLG",
      "ROQ": 1,
      "APPROVED QTY": 1,
      "UOM": "PC",
      "PART NUMBER_1": 4014000118,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4021000044,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4021000044,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4021000045,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4021000045,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 5222677134,
      "PARTS CATEGORY": "NIG",
      "ROQ": 6,
      "APPROVED QTY": 6,
      "UOM": "PC",
      "PART NUMBER_1": 5222677134,
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 6,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 4110000909011,
      "PARTS CATEGORY": "VLG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4110000909011,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 1,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4030001002,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4030001002,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 5282682780,
      "PARTS CATEGORY": "NIG",
      "ROQ": 11,
      "APPROVED QTY": 11,
      "UOM": "PC",
      "PART NUMBER_1": 5282682780,
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 11,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": "322869Z01A",
      "PARTS CATEGORY": "NIG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "322869Z01A",
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": "324979Z00B",
      "PARTS CATEGORY": "NIG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "324979Z00B",
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 11305113,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 11305113,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14679709,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14679709,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": "164009Z01C",
      "PARTS CATEGORY": "NIG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "164009Z01C",
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 14696077,
      "PARTS CATEGORY": "VSG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14696077,
      "PARTS CATEGORY_1": "VSG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": "150-30-13430",
      "PARTS CATEGORY": "SDG",
      "ROQ": 4,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "150-30-13430",
      "PARTS CATEGORY_1": "SDG",
      "ROQ_1": 4,
      "APPROVED QTY_1": 4,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 5222888240,
      "PARTS CATEGORY": "NIG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 5222888240,
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 14680749,
      "PARTS CATEGORY": "VOG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14680749,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 1,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 6727475,
      "PARTS CATEGORY": "DOB",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 6727475,
      "PARTS CATEGORY_1": "DOB",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 6674316,
      "PARTS CATEGORY": "DOB",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 6674316,
      "PARTS CATEGORY_1": "DOB",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 7008814,
      "PARTS CATEGORY": "DOB",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 7008814,
      "PARTS CATEGORY_1": "DOB",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 7251340,
      "PARTS CATEGORY": "DOB",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 7251340,
      "PARTS CATEGORY_1": "DOB",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 7251341,
      "PARTS CATEGORY": "DOB",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 7251341,
      "PARTS CATEGORY_1": "DOB",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 6675336,
      "PARTS CATEGORY": "DOB",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 6675336,
      "PARTS CATEGORY_1": "DOB",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 6654806,
      "PARTS CATEGORY": "DOB",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 6654806,
      "PARTS CATEGORY_1": "DOB",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 6702444,
      "PARTS CATEGORY": "DOB",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 6702444,
      "PARTS CATEGORY_1": "DOB",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 6718034,
      "PARTS CATEGORY": "DOB",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 6718034,
      "PARTS CATEGORY_1": "DOB",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 6679820,
      "PARTS CATEGORY": "DOB",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 6679820,
      "PARTS CATEGORY_1": "DOB",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 6680418,
      "PARTS CATEGORY": "DOB",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 6680418,
      "PARTS CATEGORY_1": "DOB",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 6680419,
      "PARTS CATEGORY": "DOB",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 6680419,
      "PARTS CATEGORY_1": "DOB",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 15164333,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 15164333,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 6685190,
      "PARTS CATEGORY": "DOB",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 6685190,
      "PARTS CATEGORY_1": "DOB",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 6678205,
      "PARTS CATEGORY": "DOB",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 6678205,
      "PARTS CATEGORY_1": "DOB",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 7193354,
      "PARTS CATEGORY": "DOB",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 7193354,
      "PARTS CATEGORY_1": "DOB",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 7176099,
      "PARTS CATEGORY": "DOB",
      "ROQ": 3,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 7176099,
      "PARTS CATEGORY_1": "DOB",
      "ROQ_1": 3,
      "APPROVED QTY_1": 3,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 7012314,
      "PARTS CATEGORY": "DOB",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 7012314,
      "PARTS CATEGORY_1": "DOB",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 7188792,
      "PARTS CATEGORY": "DOB",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 7188792,
      "PARTS CATEGORY_1": "DOB",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 6668819,
      "PARTS CATEGORY": "DOB",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 6668819,
      "PARTS CATEGORY_1": "DOB",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 6661248,
      "PARTS CATEGORY": "DOB",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 6661248,
      "PARTS CATEGORY_1": "DOB",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 6662855,
      "PARTS CATEGORY": "DOB",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 6662855,
      "PARTS CATEGORY_1": "DOB",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 7146391,
      "PARTS CATEGORY": "DOB",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 7146391,
      "PARTS CATEGORY_1": "DOB",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 7143498,
      "PARTS CATEGORY": "DOB",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 7143498,
      "PARTS CATEGORY_1": "DOB",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 7100104,
      "PARTS CATEGORY": "DOB",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 7100104,
      "PARTS CATEGORY_1": "DOB",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 6667352,
      "PARTS CATEGORY": "DOB",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 6667352,
      "PARTS CATEGORY_1": "DOB",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 6666334,
      "PARTS CATEGORY": "DOB",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 6666334,
      "PARTS CATEGORY_1": "DOB",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 6675517,
      "PARTS CATEGORY": "DOB",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 6675517,
      "PARTS CATEGORY_1": "DOB",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 6666375,
      "PARTS CATEGORY": "DOB",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 6666375,
      "PARTS CATEGORY_1": "DOB",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 6666376,
      "PARTS CATEGORY": "DOB",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 6666376,
      "PARTS CATEGORY_1": "DOB",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 6692337,
      "PARTS CATEGORY": "DOB",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 6692337,
      "PARTS CATEGORY_1": "DOB",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 7008043,
      "PARTS CATEGORY": "DOB",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 7008043,
      "PARTS CATEGORY_1": "DOB",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 7008044,
      "PARTS CATEGORY": "DOB",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 7008044,
      "PARTS CATEGORY_1": "DOB",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 898731,
      "PARTS CATEGORY": "DOB",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 898731,
      "PARTS CATEGORY_1": "DOB",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 7199903,
      "PARTS CATEGORY": "DOB",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 7199903,
      "PARTS CATEGORY_1": "DOB",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 7135489,
      "PARTS CATEGORY": "DOB",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 7135489,
      "PARTS CATEGORY_1": "DOB",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 7246802,
      "PARTS CATEGORY": "DOB",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 7246802,
      "PARTS CATEGORY_1": "DOB",
      "ROQ_1": 1,
      "APPROVED QTY_1": 1,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 6666896,
      "PARTS CATEGORY": "DBG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 6666896,
      "PARTS CATEGORY_1": "DBG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": 0
    },
    {
      "PART NUMBER": 5222416915,
      "PARTS CATEGORY": "NIG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 5222416915,
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 6657635,
      "PARTS CATEGORY": "DOB",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 6657635,
      "PARTS CATEGORY_1": "DOB",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 6672467,
      "PARTS CATEGORY": "DOB",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 6672467,
      "PARTS CATEGORY_1": "DOB",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 6672468,
      "PARTS CATEGORY": "DOB",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 6672468,
      "PARTS CATEGORY_1": "DOB",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 6652802,
      "PARTS CATEGORY": "DOB",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 6652802,
      "PARTS CATEGORY_1": "DOB",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 14590799,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14590799,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4030000233,
      "PARTS CATEGORY": "VLG",
      "ROQ": 2,
      "APPROVED QTY": 2,
      "UOM": "PC",
      "PART NUMBER_1": 4030000233,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 2,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4130001300,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4130001300,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110000988017,
      "PARTS CATEGORY": "VLG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4110000988017,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 1,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": "7114-14220",
      "PARTS CATEGORY": "VSG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "7114-14220",
      "PARTS CATEGORY_1": "VSG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14566886,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14566886,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110001009070,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4110001009070,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 7219324,
      "PARTS CATEGORY": "DOB",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 7219324,
      "PARTS CATEGORY_1": "DOB",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 6718414,
      "PARTS CATEGORY": "DOB",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 6718414,
      "PARTS CATEGORY_1": "DOB",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 4110001009037,
      "PARTS CATEGORY": "VLG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4110001009037,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 17431131,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 17431131,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 992453,
      "PARTS CATEGORY": "VOG",
      "ROQ": 5,
      "APPROVED QTY": 3,
      "UOM": "PC",
      "PART NUMBER_1": 992453,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 5,
      "APPROVED QTY_1": 2,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12 + WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110000054316,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4110000054316,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": "320139Z00B",
      "PARTS CATEGORY": "NIG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "320139Z00B",
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": "265619Z00D",
      "PARTS CATEGORY": "NIG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "265619Z00D",
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": "261029Z00D",
      "PARTS CATEGORY": "NIG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "261029Z00D",
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 15424353,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 15424353,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 6651512,
      "PARTS CATEGORY": "DOB",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 6651512,
      "PARTS CATEGORY_1": "DOB",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 29070018192,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 29070018192,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14634521,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14634521,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4120004036,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4120004036,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 5222737545,
      "PARTS CATEGORY": "NIG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 5222737545,
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": "250389Z01E",
      "PARTS CATEGORY": "NIG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "250389Z01E",
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 22283480,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 22283480,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 17457469,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 17457469,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 15168370,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 15168370,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14704862,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14704862,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14677045,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14677045,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": "213809Z00A",
      "PARTS CATEGORY": "NIG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "213809Z00A",
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": "324349Z00B",
      "PARTS CATEGORY": "NIG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "324349Z00B",
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 6680018,
      "PARTS CATEGORY": "DOB",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 6680018,
      "PARTS CATEGORY_1": "DOB",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 14695920,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14695920,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4061007,
      "PARTS CATEGORY": "CUG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4061007,
      "PARTS CATEGORY_1": "CUG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": 0
    },
    {
      "PART NUMBER": 29070007681,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 29070007681,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4190002865,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4190002865,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14686427,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14686427,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 82345507,
      "PARTS CATEGORY": "VTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 82345507,
      "PARTS CATEGORY_1": "VTG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 6538846,
      "PARTS CATEGORY": "DOB",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 6538846,
      "PARTS CATEGORY_1": "DOB",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 14592560,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14592560,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 17387726,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 17387726,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 29050020551,
      "PARTS CATEGORY": "VLG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 29050020551,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 29050020561,
      "PARTS CATEGORY": "VLG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 29050020561,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 29050020571,
      "PARTS CATEGORY": "VLG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 29050020571,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 29050020921,
      "PARTS CATEGORY": "VLG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 29050020921,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 7120401,
      "PARTS CATEGORY": "DOB",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 7120401,
      "PARTS CATEGORY_1": "DOB",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 4043000256,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4043000256,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 935013,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 935013,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 12727853,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 12727853,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14715404,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14715404,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14697658,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14697658,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": "A210111000080",
      "PARTS CATEGORY": "YCG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "A210111000080",
      "PARTS CATEGORY_1": "YCG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 1,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 21271982,
      "PARTS CATEGORY": "VTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 21271982,
      "PARTS CATEGORY_1": "VTG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 22239056,
      "PARTS CATEGORY": "VTG",
      "ROQ": 2,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 22239056,
      "PARTS CATEGORY_1": "VTG",
      "ROQ_1": 2,
      "APPROVED QTY_1": 2,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 22239057,
      "PARTS CATEGORY": "VTG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 22239057,
      "PARTS CATEGORY_1": "VTG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 1,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 22384866,
      "PARTS CATEGORY": "VTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 22384866,
      "PARTS CATEGORY_1": "VTG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 6598492,
      "PARTS CATEGORY": "DOB",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 6598492,
      "PARTS CATEGORY_1": "DOB",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 6598362,
      "PARTS CATEGORY": "DOB",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 6598362,
      "PARTS CATEGORY_1": "DOB",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 22662214,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 22662214,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 29290013761,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 29290013761,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": "9285-11000",
      "PARTS CATEGORY": "VSG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "9285-11000",
      "PARTS CATEGORY_1": "VSG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 5222748702,
      "PARTS CATEGORY": "NIG",
      "ROQ": 15,
      "APPROVED QTY": 15,
      "UOM": "PC",
      "PART NUMBER_1": 5222748702,
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 15,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 84415558,
      "PARTS CATEGORY": "VTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 84415558,
      "PARTS CATEGORY_1": "VTG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 4120006350,
      "PARTS CATEGORY": "VLG",
      "ROQ": 2,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4120006350,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 2,
      "APPROVED QTY_1": 2,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 17271141,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 17271141,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 7000769,
      "PARTS CATEGORY": "DOB",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 7000769,
      "PARTS CATEGORY_1": "DOB",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 6691714,
      "PARTS CATEGORY": "DOB",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 6691714,
      "PARTS CATEGORY_1": "DOB",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 6680443,
      "PARTS CATEGORY": "DOB",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 6680443,
      "PARTS CATEGORY_1": "DOB",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 60053982,
      "PARTS CATEGORY": "YCG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 60053982,
      "PARTS CATEGORY_1": "YCG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 14629275,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14629275,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14522473,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14522473,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110000991053,
      "PARTS CATEGORY": "VLG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4110000991053,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 1,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14656785,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14656785,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 15066986,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 15066986,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14882685,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14882685,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4190002851,
      "PARTS CATEGORY": "VLG",
      "ROQ": 1,
      "APPROVED QTY": 1,
      "UOM": "PC",
      "PART NUMBER_1": 4190002851,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14640021,
      "PARTS CATEGORY": "VOG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14640021,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14687316,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14687316,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14686458,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14686458,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": "7114-12200",
      "PARTS CATEGORY": "VSG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "7114-12200",
      "PARTS CATEGORY_1": "VSG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": "9612-05030",
      "PARTS CATEGORY": "VSG",
      "ROQ": 2,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "9612-05030",
      "PARTS CATEGORY_1": "VSG",
      "ROQ_1": 2,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": "164049Z00D",
      "PARTS CATEGORY": "NIG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "164049Z00D",
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": "140129Z00D",
      "PARTS CATEGORY": "NIG",
      "ROQ": 5,
      "APPROVED QTY": 5,
      "UOM": "PC",
      "PART NUMBER_1": "140129Z00D",
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 5,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": "210879Z00A",
      "PARTS CATEGORY": "NIG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "210879Z00A",
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 5222374837,
      "PARTS CATEGORY": "NIG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 5222374837,
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": "152189Z00C",
      "PARTS CATEGORY": "NIG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "152189Z00C",
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 22175635,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 22175635,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": "275859Z00D",
      "PARTS CATEGORY": "NIG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "275859Z00D",
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": "271039Z00A",
      "PARTS CATEGORY": "NIG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "271039Z00A",
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 14699400,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14699400,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 17418267,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 17418267,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4190002907003,
      "PARTS CATEGORY": "VLG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4190002907003,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": "FF5706",
      "PARTS CATEGORY": "CUF",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "FF5706",
      "PARTS CATEGORY_1": "CUF",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "OLD"
    },
    {
      "PART NUMBER": 21448926,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 21448926,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14668664,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14668664,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 17418271,
      "PARTS CATEGORY": "VOG",
      "ROQ": 2,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 17418271,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 2,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4021000368,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4021000368,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4021000322,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4021000322,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14688861,
      "PARTS CATEGORY": "VOG",
      "ROQ": 13,
      "APPROVED QTY": 13,
      "UOM": "PC",
      "PART NUMBER_1": 14688861,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 13,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14711981,
      "PARTS CATEGORY": "VOG",
      "ROQ": 9,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14711981,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 9,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14658431,
      "PARTS CATEGORY": "VOG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14658431,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14658432,
      "PARTS CATEGORY": "VOG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14658432,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14658434,
      "PARTS CATEGORY": "VOG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14658434,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14650586,
      "PARTS CATEGORY": "VOG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14650586,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4130001731,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4130001731,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 22768927,
      "PARTS CATEGORY": "VTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 22768927,
      "PARTS CATEGORY_1": "VTG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 9017216587,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 9017216587,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 21792306,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 21792306,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 6516722,
      "PARTS CATEGORY": "DOB",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 6516722,
      "PARTS CATEGORY_1": "DOB",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 6692836,
      "PARTS CATEGORY": "DOB",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 6692836,
      "PARTS CATEGORY_1": "DOB",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 7010252,
      "PARTS CATEGORY": "DOB",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 7010252,
      "PARTS CATEGORY_1": "DOB",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 6685105,
      "PARTS CATEGORY": "DOB",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 6685105,
      "PARTS CATEGORY_1": "DOB",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 7025590,
      "PARTS CATEGORY": "DOB",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 7025590,
      "PARTS CATEGORY_1": "DOB",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 7010351,
      "PARTS CATEGORY": "DOB",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 7010351,
      "PARTS CATEGORY_1": "DOB",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 7004883,
      "PARTS CATEGORY": "DOB",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 7004883,
      "PARTS CATEGORY_1": "DOB",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 7013148,
      "PARTS CATEGORY": "DOB",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 7013148,
      "PARTS CATEGORY_1": "DOB",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 4013000193,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4013000193,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 29070011032,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 29070011032,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 7277987,
      "PARTS CATEGORY": "DOB",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 7277987,
      "PARTS CATEGORY_1": "DOB",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 14662510,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14662510,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 22480372,
      "PARTS CATEGORY": "VTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 22480372,
      "PARTS CATEGORY_1": "VTG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 4110000991,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4110000991,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110000991032,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4110000991032,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110000991030,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4110000991030,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 5222771970,
      "PARTS CATEGORY": "NIG",
      "ROQ": 3,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 5222771970,
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 3,
      "APPROVED QTY_1": 3,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 5222771975,
      "PARTS CATEGORY": "NIG",
      "ROQ": 21,
      "APPROVED QTY": 17,
      "UOM": "PC",
      "PART NUMBER_1": 5222771975,
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 21,
      "APPROVED QTY_1": 4,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12 + WHXX",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 5222748694,
      "PARTS CATEGORY": "NIG",
      "ROQ": 16,
      "APPROVED QTY": 16,
      "UOM": "PC",
      "PART NUMBER_1": 5222748694,
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 16,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 16688307,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 16688307,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 22282222,
      "PARTS CATEGORY": "VTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 22282222,
      "PARTS CATEGORY_1": "VTG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 14559058,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14559058,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 982320,
      "PARTS CATEGORY": "VOG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 982320,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 1,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 6735884,
      "PARTS CATEGORY": "DOB",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 6735884,
      "PARTS CATEGORY_1": "DOB",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 4110003158,
      "PARTS CATEGORY": "VLG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4110003158,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 7230495,
      "PARTS CATEGORY": "DOB",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 7230495,
      "PARTS CATEGORY_1": "DOB",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 29050011362,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 29050011362,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 29050025561,
      "PARTS CATEGORY": "VLG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 29050025561,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110001009040,
      "PARTS CATEGORY": "VLG",
      "ROQ": 1,
      "APPROVED QTY": 1,
      "UOM": "PC",
      "PART NUMBER_1": 4110001009040,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 6661611,
      "PARTS CATEGORY": "DOB",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 6661611,
      "PARTS CATEGORY_1": "DOB",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 21583792,
      "PARTS CATEGORY": "VTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 21583792,
      "PARTS CATEGORY_1": "VTG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 14679620,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14679620,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 82354791,
      "PARTS CATEGORY": "VTG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 82354791,
      "PARTS CATEGORY_1": "VTG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 1,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 12746651,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 12746651,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14697657,
      "PARTS CATEGORY": "VOG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14697657,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 1,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14708228,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14708228,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 7234460,
      "PARTS CATEGORY": "DOB",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 7234460,
      "PARTS CATEGORY_1": "DOB",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 22803123,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 22803123,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 17470368,
      "PARTS CATEGORY": "LUV",
      "ROQ": 384,
      "APPROVED QTY": 18,
      "UOM": "PC",
      "PART NUMBER_1": 17470368,
      "PARTS CATEGORY_1": "LUV",
      "ROQ_1": 384,
      "APPROVED QTY_1": 360,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12 + WHXX",
      "BU": "LUBRICANTS"
    },
    {
      "PART NUMBER": 4110000054118,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4110000054118,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110000555268,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4110000555268,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 5222401537,
      "PARTS CATEGORY": "NIG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 5222401537,
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 7136559,
      "PARTS CATEGORY": "DOB",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 7136559,
      "PARTS CATEGORY_1": "DOB",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": "B230101001368",
      "PARTS CATEGORY": "YCG",
      "ROQ": 2,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "B230101001368",
      "PARTS CATEGORY_1": "YCG",
      "ROQ_1": 2,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 6989019,
      "PARTS CATEGORY": "DOB",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 6989019,
      "PARTS CATEGORY_1": "DOB",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 9021647837,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 9021647837,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 9021092586,
      "PARTS CATEGORY": "VOG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 9021092586,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": "322179Z00C",
      "PARTS CATEGORY": "NIG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "322179Z00C",
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 14686465,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14686465,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14715403,
      "PARTS CATEGORY": "VOG",
      "ROQ": 7,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14715403,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 7,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 6676029,
      "PARTS CATEGORY": "DOB",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 6676029,
      "PARTS CATEGORY_1": "DOB",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 4110000127008,
      "PARTS CATEGORY": "VLG",
      "ROQ": 1,
      "APPROVED QTY": 1,
      "UOM": "PC",
      "PART NUMBER_1": 4110000127008,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4130001732,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4130001732,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 6680441,
      "PARTS CATEGORY": "DOB",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 6680441,
      "PARTS CATEGORY_1": "DOB",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 22383382,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 22383382,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 995871,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 995871,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14561415,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14561415,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 17007124,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 17007124,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 22905123,
      "PARTS CATEGORY": "VOG",
      "ROQ": 4,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 22905123,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 4,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110001031031,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4110001031031,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 12811798,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 12811798,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 17410278,
      "PARTS CATEGORY": "VOG",
      "ROQ": 1,
      "APPROVED QTY": 1,
      "UOM": "PC",
      "PART NUMBER_1": 17410278,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 17410282,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 17410282,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 5235899,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 5235899,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 15185410,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 15185410,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 17460623,
      "PARTS CATEGORY": "VOG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 17460623,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 1161937,
      "PARTS CATEGORY": "LUV",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 1161937,
      "PARTS CATEGORY_1": "LUV",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "LUBRICANTS"
    },
    {
      "PART NUMBER": 70930466,
      "PARTS CATEGORY": "VCG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 70930466,
      "PARTS CATEGORY_1": "VCG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": "U81266",
      "PARTS CATEGORY": "SOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "U81266",
      "PARTS CATEGORY_1": "SOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 6625575,
      "PARTS CATEGORY": "DOB",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 6625575,
      "PARTS CATEGORY_1": "DOB",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 22089218,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 22089218,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 6571857,
      "PARTS CATEGORY": "DOB",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 6571857,
      "PARTS CATEGORY_1": "DOB",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 14703234,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14703234,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": "322179Z00A",
      "PARTS CATEGORY": "NIG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "322179Z00A",
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 4110000054253,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4110000054253,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": "83D4",
      "PARTS CATEGORY": "DOB",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "83D4",
      "PARTS CATEGORY_1": "DOB",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 4110000011114,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4110000011114,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110000011115,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4110000011115,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": "307089Z00A",
      "PARTS CATEGORY": "NIG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "307089Z00A",
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 22899626,
      "PARTS CATEGORY": "VTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 22899626,
      "PARTS CATEGORY_1": "VTG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": "4120006350Z",
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "4120006350Z",
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4190002587,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4190002587,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14560268,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14560268,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14560264,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14560264,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4043000026,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4043000026,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 16053916,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 16053916,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": "A810201206238",
      "PARTS CATEGORY": "YCG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "A810201206238",
      "PARTS CATEGORY_1": "YCG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 1,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 17489661,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 17489661,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14690316,
      "PARTS CATEGORY": "VOG",
      "ROQ": 3,
      "APPROVED QTY": 3,
      "UOM": "PC",
      "PART NUMBER_1": 14690316,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 3,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 7231756,
      "PARTS CATEGORY": "DOB",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 7231756,
      "PARTS CATEGORY_1": "DOB",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 7200001489,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 7200001489,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 7200001480,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 7200001480,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": "C03228",
      "PARTS CATEGORY": "SOG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "C03228",
      "PARTS CATEGORY_1": "SOG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 4130001540,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4130001540,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4130001541,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4130001541,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14629276,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14629276,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": "381669Z03D",
      "PARTS CATEGORY": "NIG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "381669Z03D",
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": "FLUID41",
      "PARTS CATEGORY": "LUB",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "FLUID41",
      "PARTS CATEGORY_1": "LUB",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "LUBRICANTS"
    },
    {
      "PART NUMBER": 4120004041012,
      "PARTS CATEGORY": "VLG",
      "ROQ": 3,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4120004041012,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 3,
      "APPROVED QTY_1": 3,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 22107652,
      "PARTS CATEGORY": "VTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 22107652,
      "PARTS CATEGORY_1": "VTG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 5222889270,
      "PARTS CATEGORY": "NIG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 5222889270,
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 6625298,
      "PARTS CATEGORY": "DOB",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 6625298,
      "PARTS CATEGORY_1": "DOB",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 5222364174,
      "PARTS CATEGORY": "NIG",
      "ROQ": 2,
      "APPROVED QTY": 2,
      "UOM": "PC",
      "PART NUMBER_1": 5222364174,
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 2,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 60110356,
      "PARTS CATEGORY": "VOG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 60110356,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": "324609Z00B",
      "PARTS CATEGORY": "NIG",
      "ROQ": 2,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "324609Z00B",
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 2,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 4130001413,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4130001413,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110000054311,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4110000054311,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110000054211,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4110000054211,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110000054241,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4110000054241,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110000054242,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4110000054242,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110000054216,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4110000054216,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110000054097,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4110000054097,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 60208877,
      "PARTS CATEGORY": "YCG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 60208877,
      "PARTS CATEGORY_1": "YCG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 1,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 4120002264406,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4120002264406,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 22435058,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 22435058,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4120006350001,
      "PARTS CATEGORY": "VLG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4120006350001,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4030000130,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4030000130,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 15185355,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 15185355,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110000991012,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4110000991012,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 29220001261,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 29220001261,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14640020,
      "PARTS CATEGORY": "VOG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14640020,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14880126,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14880126,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": "250389Z00E",
      "PARTS CATEGORY": "NIG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "250389Z00E",
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 14640073,
      "PARTS CATEGORY": "VOG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14640073,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 976568,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 976568,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14882198,
      "PARTS CATEGORY": "VOG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14882198,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14682233,
      "PARTS CATEGORY": "VOG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14682233,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14656784,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14656784,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14882689,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14882689,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110003167001,
      "PARTS CATEGORY": "VLG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4110003167001,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 1,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110001755023,
      "PARTS CATEGORY": "VLG",
      "ROQ": 29,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4110001755023,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 29,
      "APPROVED QTY_1": 29,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110001544001,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4110001544001,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 29100001051,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 29100001051,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 17428016,
      "PARTS CATEGORY": "VOG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 17428016,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 1,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110002287,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4110002287,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14560274,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14560274,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14560276,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14560276,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14558957,
      "PARTS CATEGORY": "VOG",
      "ROQ": 2,
      "APPROVED QTY": 2,
      "UOM": "PC",
      "PART NUMBER_1": 14558957,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 2,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 11705004,
      "PARTS CATEGORY": "VOG",
      "ROQ": 2,
      "APPROVED QTY": 2,
      "UOM": "PC",
      "PART NUMBER_1": 11705004,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 2,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 11705003,
      "PARTS CATEGORY": "VOG",
      "ROQ": 2,
      "APPROVED QTY": 2,
      "UOM": "PC",
      "PART NUMBER_1": 11705003,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 2,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14558956,
      "PARTS CATEGORY": "VOG",
      "ROQ": 2,
      "APPROVED QTY": 2,
      "UOM": "PC",
      "PART NUMBER_1": 14558956,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 2,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14560269,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14560269,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14560273,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14560273,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14560272,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14560272,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 11704997,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 11704997,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 11704999,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 11704999,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 17410286,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 17410286,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 996813,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 996813,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 7020981,
      "PARTS CATEGORY": "DOB",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 7020981,
      "PARTS CATEGORY_1": "DOB",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 7000645,
      "PARTS CATEGORY": "DOB",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 7000645,
      "PARTS CATEGORY_1": "DOB",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": "B222100000641",
      "PARTS CATEGORY": "YCG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "B222100000641",
      "PARTS CATEGORY_1": "YCG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": "B222100000640",
      "PARTS CATEGORY": "YCG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "B222100000640",
      "PARTS CATEGORY_1": "YCG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 7000626,
      "PARTS CATEGORY": "DOB",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 7000626,
      "PARTS CATEGORY_1": "DOB",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 60274433,
      "PARTS CATEGORY": "YPG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 60274433,
      "PARTS CATEGORY_1": "YPG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 60274432,
      "PARTS CATEGORY": "YPG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 60274432,
      "PARTS CATEGORY_1": "YPG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": "9751-15016 ",
      "PARTS CATEGORY": "VSG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "9751-15016 ",
      "PARTS CATEGORY_1": "VSG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14552494,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14552494,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14623350,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14623350,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14686497,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14686497,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14685140,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14685140,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14562064,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14562064,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14600015,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14600015,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14695913,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14695913,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14643925,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14643925,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 22191895,
      "PARTS CATEGORY": "VTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 22191895,
      "PARTS CATEGORY_1": "VTG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 22879098,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 22879098,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 20934592,
      "PARTS CATEGORY": "VCG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 20934592,
      "PARTS CATEGORY_1": "VCG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 29070012892,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 29070012892,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 5282675288,
      "PARTS CATEGORY": "NIG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 5282675288,
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 14687776,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14687776,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14708935,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14708935,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14640102,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14640102,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14685008,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14685008,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14638178,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14638178,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14561413,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14561413,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110001905014,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4110001905014,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 60255743,
      "PARTS CATEGORY": "YCG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 60255743,
      "PARTS CATEGORY_1": "YCG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 14640023,
      "PARTS CATEGORY": "VOG",
      "ROQ": 2,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14640023,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 2,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 29050022301,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 29050022301,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 29050022291,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 29050022291,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 29050023111,
      "PARTS CATEGORY": "VLG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 29050023111,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4021000057,
      "PARTS CATEGORY": "VLG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4021000057,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4021000055,
      "PARTS CATEGORY": "VLG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4021000055,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 5220844736,
      "PARTS CATEGORY": "NIG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 5220844736,
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 60256244,
      "PARTS CATEGORY": "YPG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 60256244,
      "PARTS CATEGORY_1": "YPG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 21225667,
      "PARTS CATEGORY": "VTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 21225667,
      "PARTS CATEGORY_1": "VTG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 21004310,
      "PARTS CATEGORY": "YCG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 21004310,
      "PARTS CATEGORY_1": "YCG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 58748,
      "PARTS CATEGORY": "VGG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 58748,
      "PARTS CATEGORY_1": "VGG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14656782,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14656782,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 60024962,
      "PARTS CATEGORY": "YCG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 60024962,
      "PARTS CATEGORY_1": "YCG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 14883778,
      "PARTS CATEGORY": "VOG",
      "ROQ": 1,
      "APPROVED QTY": 1,
      "UOM": "PC",
      "PART NUMBER_1": 14883778,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 997459,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 997459,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 29050022061,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 29050022061,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 12743620,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 12743620,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 60222777,
      "PARTS CATEGORY": "YCG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 60222777,
      "PARTS CATEGORY_1": "YCG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 28360002211,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 28360002211,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 29170042101,
      "PARTS CATEGORY": "VLG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 29170042101,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 29170042111,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 29170042111,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 15065343,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 15065343,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 12802324,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 12802324,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 29110000043,
      "PARTS CATEGORY": "VLG",
      "ROQ": 1,
      "APPROVED QTY": 1,
      "UOM": "PC",
      "PART NUMBER_1": 29110000043,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110001527,
      "PARTS CATEGORY": "VLG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4110001527,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 1,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 60110025,
      "PARTS CATEGORY": "VOG",
      "ROQ": 51,
      "APPROVED QTY": 51,
      "UOM": "PC",
      "PART NUMBER_1": 60110025,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 51,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 12803013,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 12803013,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 7016331,
      "PARTS CATEGORY": "JLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 7016331,
      "PARTS CATEGORY_1": "JLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 4016000043,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4016000043,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 21793270,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 21793270,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 17418494,
      "PARTS CATEGORY": "VOG",
      "ROQ": 1,
      "APPROVED QTY": 1,
      "UOM": "PC",
      "PART NUMBER_1": 17418494,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 997436,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 997436,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110000556263,
      "PARTS CATEGORY": "VLG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4110000556263,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 1,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 12802160,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 12802160,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 17252660,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 17252660,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 979064,
      "PARTS CATEGORY": "VOG",
      "ROQ": 1,
      "APPROVED QTY": 1,
      "UOM": "PC",
      "PART NUMBER_1": 979064,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4043000752,
      "PARTS CATEGORY": "VLG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4043000752,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 1,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 17418113,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 17418113,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110000784,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4110000784,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 5222719933,
      "PARTS CATEGORY": "NIG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 5222719933,
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 11705466,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 11705466,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14538415,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14538415,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14538416,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14538416,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14708934,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14708934,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14534865,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14534865,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14614042,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14614042,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14691235,
      "PARTS CATEGORY": "VOG",
      "ROQ": 1,
      "APPROVED QTY": 1,
      "UOM": "PC",
      "PART NUMBER_1": 14691235,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": "295349Z00A",
      "PARTS CATEGORY": "NIG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "295349Z00A",
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 14557503,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14557503,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110000970215,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4110000970215,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110000970108,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4110000970108,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14689356,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14689356,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 6670472,
      "PARTS CATEGORY": "DOB",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 6670472,
      "PARTS CATEGORY_1": "DOB",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 4130002911003,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4130002911003,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": "270389Z00A",
      "PARTS CATEGORY": "NIG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "270389Z00A",
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 14725313,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14725313,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 7002980,
      "PARTS CATEGORY": "DOB",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 7002980,
      "PARTS CATEGORY_1": "DOB",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 14880424,
      "PARTS CATEGORY": "VOG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14880424,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14679691,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14679691,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 5222802279,
      "PARTS CATEGORY": "NIG",
      "ROQ": 2,
      "APPROVED QTY": 2,
      "UOM": "PC",
      "PART NUMBER_1": 5222802279,
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 2,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 14674063,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14674063,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 7281063,
      "PARTS CATEGORY": "DOB",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 7281063,
      "PARTS CATEGORY_1": "DOB",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 14371518,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14371518,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14595818,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14595818,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14532310,
      "PARTS CATEGORY": "VOG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14532310,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14647523,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14647523,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14714851,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14714851,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 22967564,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 22967564,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 997434,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 997434,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 997435,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 997435,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110001007009,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4110001007009,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110001015003,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4110001015003,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110002483001,
      "PARTS CATEGORY": "VLG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4110002483001,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 1,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 22446845,
      "PARTS CATEGORY": "VTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 22446845,
      "PARTS CATEGORY_1": "VTG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 14691909,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14691909,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 16000089,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 16000089,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14594708,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14594708,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 22745695,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 22745695,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 20913851,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 20913851,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 20794115,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 20794115,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": "282299Z00B",
      "PARTS CATEGORY": "NIG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "282299Z00B",
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 14711980,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14711980,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 60252396,
      "PARTS CATEGORY": "YCG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 60252396,
      "PARTS CATEGORY_1": "YCG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": "A222100000380",
      "PARTS CATEGORY": "YCG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "A222100000380",
      "PARTS CATEGORY_1": "YCG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 13274788,
      "PARTS CATEGORY": "YPG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 13274788,
      "PARTS CATEGORY_1": "YPG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 14715423,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14715423,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14686445,
      "PARTS CATEGORY": "VOG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14686445,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 29070013171,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 29070013171,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 29070013181,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 29070013181,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 3050900043,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 3050900043,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 3050900042,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 3050900042,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 3050900041,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 3050900041,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4090000003,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4090000003,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": "443419Z01B",
      "PARTS CATEGORY": "NIG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "443419Z01B",
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 7319444,
      "PARTS CATEGORY": "DOB",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 7319444,
      "PARTS CATEGORY_1": "DOB",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 14702253,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14702253,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": "165289Z01A",
      "PARTS CATEGORY": "NIG",
      "ROQ": 2,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "165289Z01A",
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 2,
      "APPROVED QTY_1": 2,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": "165289Z00E",
      "PARTS CATEGORY": "NIG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "165289Z00E",
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 1,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": "47500NA04D",
      "PARTS CATEGORY": "NIG",
      "ROQ": 3,
      "APPROVED QTY": 3,
      "UOM": "PC",
      "PART NUMBER_1": "47500NA04D",
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 3,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": "276949Z00B",
      "PARTS CATEGORY": "NIG",
      "ROQ": 3,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "276949Z00B",
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 3,
      "APPROVED QTY_1": 3,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": "797409Z00C",
      "PARTS CATEGORY": "NIG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "797409Z00C",
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 15171144,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 15171144,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 5260111183,
      "PARTS CATEGORY": "NIG",
      "ROQ": 3,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 5260111183,
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 3,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 5222842811,
      "PARTS CATEGORY": "NIG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 5222842811,
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 60110035,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 60110035,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 11443993,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 11443993,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 29070020651,
      "PARTS CATEGORY": "VLG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 29070020651,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4013000064,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4013000064,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 29090001031,
      "PARTS CATEGORY": "VLG",
      "ROQ": 3,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 29090001031,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 3,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 6680161,
      "PARTS CATEGORY": "DOB",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 6680161,
      "PARTS CATEGORY_1": "DOB",
      "ROQ_1": 1,
      "APPROVED QTY_1": 1,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 17438619,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 17438619,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14881383,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14881383,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 997482,
      "PARTS CATEGORY": "VOG",
      "ROQ": 43,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 997482,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 43,
      "APPROVED QTY_1": 16,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "PARTIAL WH12",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14558017,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14558017,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14640024,
      "PARTS CATEGORY": "VOG",
      "ROQ": 2,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14640024,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 2,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 5223058602,
      "PARTS CATEGORY": "NIG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 5223058602,
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 14709151,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14709151,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 60193266,
      "PARTS CATEGORY": "YPG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 60193266,
      "PARTS CATEGORY_1": "YPG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 4110001531001,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4110001531001,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 84092304,
      "PARTS CATEGORY": "VTG",
      "ROQ": 1,
      "APPROVED QTY": 1,
      "UOM": "PC",
      "PART NUMBER_1": 84092304,
      "PARTS CATEGORY_1": "VTG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 5229625,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 5229625,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 15185363,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 15185363,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 5222931497,
      "PARTS CATEGORY": "NIG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 5222931497,
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 1,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 14709332,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14709332,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 29090014451,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 29090014451,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 5223058600,
      "PARTS CATEGORY": "NIG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 5223058600,
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 17446531,
      "PARTS CATEGORY": "VOG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 17446531,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 1,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 6644965,
      "PARTS CATEGORY": "DOB",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 6644965,
      "PARTS CATEGORY_1": "DOB",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 14722573,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14722573,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 60113080,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 60113080,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 997446,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 997446,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 997494,
      "PARTS CATEGORY": "VOG",
      "ROQ": 1,
      "APPROVED QTY": 1,
      "UOM": "PC",
      "PART NUMBER_1": 997494,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 1101312,
      "PARTS CATEGORY": "JLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 1101312,
      "PARTS CATEGORY_1": "JLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 1101313,
      "PARTS CATEGORY": "JLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 1101313,
      "PARTS CATEGORY_1": "JLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 5222844507,
      "PARTS CATEGORY": "NIG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 5222844507,
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 5222844485,
      "PARTS CATEGORY": "NIG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 5222844485,
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 4110000991019,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4110000991019,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110000054215,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4110000054215,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110000054096,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4110000054096,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 5222523792,
      "PARTS CATEGORY": "NIG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 5222523792,
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 82355056,
      "PARTS CATEGORY": "VTG",
      "ROQ": 2,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 82355056,
      "PARTS CATEGORY_1": "VTG",
      "ROQ_1": 2,
      "APPROVED QTY_1": 2,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": "322739Z00D ",
      "PARTS CATEGORY": "NIG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "322739Z00D ",
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 16632962,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 16632962,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 16632963,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 16632963,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 6553411,
      "PARTS CATEGORY": "DOB",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 6553411,
      "PARTS CATEGORY_1": "DOB",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 60263488,
      "PARTS CATEGORY": "YTG",
      "ROQ": 3,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 60263488,
      "PARTS CATEGORY_1": "YTG",
      "ROQ_1": 3,
      "APPROVED QTY_1": 3,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 6715865,
      "PARTS CATEGORY": "DOB",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 6715865,
      "PARTS CATEGORY_1": "DOB",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "CMI"
    },
    {
      "PART NUMBER": "17C640",
      "PARTS CATEGORY": "DOB",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "17C640",
      "PARTS CATEGORY_1": "DOB",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "CMI"
    },
    {
      "PART NUMBER": "97D6",
      "PARTS CATEGORY": "DOB",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "97D6",
      "PARTS CATEGORY_1": "DOB",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 5222802271,
      "PARTS CATEGORY": "NIG",
      "ROQ": 1,
      "APPROVED QTY": 1,
      "UOM": "PC",
      "PART NUMBER_1": 5222802271,
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 5222923707,
      "PARTS CATEGORY": "NIG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 5222923707,
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 5222845321,
      "PARTS CATEGORY": "NIG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 5222845321,
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 12815454,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 12815454,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 5223236938,
      "PARTS CATEGORY": "NIG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 5223236938,
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 16668474,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 16668474,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 17201956,
      "PARTS CATEGORY": "VOG",
      "ROQ": 1,
      "APPROVED QTY": 1,
      "UOM": "PC",
      "PART NUMBER_1": 17201956,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 17324107,
      "PARTS CATEGORY": "VOG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 17324107,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 1,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 6519178,
      "PARTS CATEGORY": "DOB",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 6519178,
      "PARTS CATEGORY_1": "DOB",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "CMI"
    },
    {
      "PART NUMBER": "A210609000067",
      "PARTS CATEGORY": "YCG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "A210609000067",
      "PARTS CATEGORY_1": "YCG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 1,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 10182105,
      "PARTS CATEGORY": "YCG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 10182105,
      "PARTS CATEGORY_1": "YCG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 1,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "CMI"
    },
    {
      "PART NUMBER": "B230101001155",
      "PARTS CATEGORY": "YCG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "B230101001155",
      "PARTS CATEGORY_1": "YCG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": "B230101001370",
      "PARTS CATEGORY": "YCG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "B230101001370",
      "PARTS CATEGORY_1": "YCG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": "B230101001158",
      "PARTS CATEGORY": "YCG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "B230101001158",
      "PARTS CATEGORY_1": "YCG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 1,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 10250733,
      "PARTS CATEGORY": "YCG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 10250733,
      "PARTS CATEGORY_1": "YCG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 1,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 5222827993,
      "PARTS CATEGORY": "NIG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 5222827993,
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 4130001873,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4130001873,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14585975,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14585975,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14725906,
      "PARTS CATEGORY": "VOG",
      "ROQ": 3,
      "APPROVED QTY": 3,
      "UOM": "PC",
      "PART NUMBER_1": 14725906,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 3,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 15088068,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 15088068,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 6680695,
      "PARTS CATEGORY": "DOB",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 6680695,
      "PARTS CATEGORY_1": "DOB",
      "ROQ_1": 1,
      "APPROVED QTY_1": 1,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 16681896,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 16681896,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 5223236940,
      "PARTS CATEGORY": "NIG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 5223236940,
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 4190002281,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4190002281,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 22923707,
      "PARTS CATEGORY": "VTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 22923707,
      "PARTS CATEGORY_1": "VTG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 20992763,
      "PARTS CATEGORY": "VTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 20992763,
      "PARTS CATEGORY_1": "VTG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 880500,
      "PARTS CATEGORY": "PHG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 880500,
      "PARTS CATEGORY_1": "PHG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 4030000707,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4030000707,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110000076050,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4110000076050,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4030000611,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4030000611,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 22221048,
      "PARTS CATEGORY": "VTG",
      "ROQ": 3,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 22221048,
      "PARTS CATEGORY_1": "VTG",
      "ROQ_1": 3,
      "APPROVED QTY_1": 3,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 29070007661,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 29070007661,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 3050900037,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 3050900037,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 3050900040,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 3050900040,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 997500,
      "PARTS CATEGORY": "VOG",
      "ROQ": 4,
      "APPROVED QTY": 4,
      "UOM": "PC",
      "PART NUMBER_1": 997500,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 4,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110002852014,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4110002852014,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 5223236891,
      "PARTS CATEGORY": "NIG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 5223236891,
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": "A210609000354",
      "PARTS CATEGORY": "YPG",
      "ROQ": 4,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "A210609000354",
      "PARTS CATEGORY_1": "YPG",
      "ROQ_1": 4,
      "APPROVED QTY_1": 4,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 612600114993,
      "PARTS CATEGORY": "SDG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 612600114993,
      "PARTS CATEGORY_1": "SDG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 960698,
      "PARTS CATEGORY": "DOB",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 960698,
      "PARTS CATEGORY_1": "DOB",
      "ROQ_1": 1,
      "APPROVED QTY_1": 1,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 6911907,
      "PARTS CATEGORY": "DOB",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 6911907,
      "PARTS CATEGORY_1": "DOB",
      "ROQ_1": 1,
      "APPROVED QTY_1": 1,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 7251998,
      "PARTS CATEGORY": "DOB",
      "ROQ": 1,
      "APPROVED QTY": 1,
      "UOM": "PC",
      "PART NUMBER_1": 7251998,
      "PARTS CATEGORY_1": "DOB",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 14702254,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14702254,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4120007012,
      "PARTS CATEGORY": "VLG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4120007012,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 1,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 997858,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 997858,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 23140886,
      "PARTS CATEGORY": "VTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 23140886,
      "PARTS CATEGORY_1": "VTG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 14701419,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14701419,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": "308569Z01B",
      "PARTS CATEGORY": "NIG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "308569Z01B",
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 14523095,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14523095,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 997410,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 997410,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 997868,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 997868,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14722625,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14722625,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14577460,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14577460,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14708320,
      "PARTS CATEGORY": "VOG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14708320,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14709480,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14709480,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14713823,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14713823,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 22068223,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 22068223,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 997513,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 997513,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 17501886,
      "PARTS CATEGORY": "VOG",
      "ROQ": 1,
      "APPROVED QTY": 1,
      "UOM": "PC",
      "PART NUMBER_1": 17501886,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 6716621,
      "PARTS CATEGORY": "DOB",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 6716621,
      "PARTS CATEGORY_1": "DOB",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 6701535,
      "PARTS CATEGORY": "DOB",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 6701535,
      "PARTS CATEGORY_1": "DOB",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 996777,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 996777,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 5223058616,
      "PARTS CATEGORY": "NIG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 5223058616,
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 60034238,
      "PARTS CATEGORY": "YCG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 60034238,
      "PARTS CATEGORY_1": "YCG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 4130002912,
      "PARTS CATEGORY": "VLG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4130002912,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 1,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 5223236939,
      "PARTS CATEGORY": "NIG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 5223236939,
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": "164109Z00D",
      "PARTS CATEGORY": "NIG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "164109Z00D",
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 5222847639,
      "PARTS CATEGORY": "NIG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 5222847639,
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 5222847640,
      "PARTS CATEGORY": "NIG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 5222847640,
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 12782905,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 12782905,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 60113303,
      "PARTS CATEGORY": "VOG",
      "ROQ": 3,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 60113303,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 3,
      "APPROVED QTY_1": 3,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110002791,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4110002791,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14700703,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14700703,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 997963,
      "PARTS CATEGORY": "VOG",
      "ROQ": 8,
      "APPROVED QTY": 8,
      "UOM": "PC",
      "PART NUMBER_1": 997963,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 8,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110001525036,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4110001525036,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14619031,
      "PARTS CATEGORY": "VOG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14619031,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 1,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 997990,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 997990,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14663554,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14663554,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110001929,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4110001929,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 11204727,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 11204727,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14530462,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14530462,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 60110287,
      "PARTS CATEGORY": "VOG",
      "ROQ": 8,
      "APPROVED QTY": 8,
      "UOM": "PC",
      "PART NUMBER_1": 60110287,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 8,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": "259459Z00C",
      "PARTS CATEGORY": "NIG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "259459Z00C",
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": "A221500000266",
      "PARTS CATEGORY": "YCG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "A221500000266",
      "PARTS CATEGORY_1": "YCG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 22988765,
      "PARTS CATEGORY": "VTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 22988765,
      "PARTS CATEGORY_1": "VTG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 4110002902,
      "PARTS CATEGORY": "VLG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4110002902,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 29290037461,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 29290037461,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": "963019Z01D",
      "PARTS CATEGORY": "NIG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "963019Z01D",
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 60110300,
      "PARTS CATEGORY": "VOG",
      "ROQ": 8,
      "APPROVED QTY": 8,
      "UOM": "PC",
      "PART NUMBER_1": 60110300,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 8,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14681250,
      "PARTS CATEGORY": "VOG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14681250,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 1,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": "282299Z05B",
      "PARTS CATEGORY": "NIG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "282299Z05B",
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 5227334,
      "PARTS CATEGORY": "VOG",
      "ROQ": 2,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 5227334,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 2,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14560265,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14560265,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14560271,
      "PARTS CATEGORY": "VOG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14560271,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 17444453,
      "PARTS CATEGORY": "VOG",
      "ROQ": 2,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 17444453,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 2,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 17230686,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 17230686,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 60099313,
      "PARTS CATEGORY": "YCG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 60099313,
      "PARTS CATEGORY_1": "YCG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": "8230-25680",
      "PARTS CATEGORY": "VSG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "8230-25680",
      "PARTS CATEGORY_1": "VSG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": "4120006349Z",
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "4120006349Z",
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4190002589,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4190002589,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4130002221,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4130002221,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14716435,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14716435,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 22943668,
      "PARTS CATEGORY": "VTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 22943668,
      "PARTS CATEGORY_1": "VTG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 14719601,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14719601,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 7289106,
      "PARTS CATEGORY": "DOB",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 7289106,
      "PARTS CATEGORY_1": "DOB",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 7289231,
      "PARTS CATEGORY": "DOB",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 7289231,
      "PARTS CATEGORY_1": "DOB",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": "23Y-62B-00000P020",
      "PARTS CATEGORY": "SDG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "23Y-62B-00000P020",
      "PARTS CATEGORY_1": "SDG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 1,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 5220374662,
      "PARTS CATEGORY": "NIG",
      "ROQ": 1,
      "APPROVED QTY": 1,
      "UOM": "PC",
      "PART NUMBER_1": 5220374662,
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 29070021581,
      "PARTS CATEGORY": "VLG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 29070021581,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 60110002,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 60110002,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 7289295,
      "PARTS CATEGORY": "DOB",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 7289295,
      "PARTS CATEGORY_1": "DOB",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 7290371,
      "PARTS CATEGORY": "DOB",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 7290371,
      "PARTS CATEGORY_1": "DOB",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 7290377,
      "PARTS CATEGORY": "DOB",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 7290377,
      "PARTS CATEGORY_1": "DOB",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 7291588,
      "PARTS CATEGORY": "DOB",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 7291588,
      "PARTS CATEGORY_1": "DOB",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 7291602,
      "PARTS CATEGORY": "DOB",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 7291602,
      "PARTS CATEGORY_1": "DOB",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 60113296,
      "PARTS CATEGORY": "VOG",
      "ROQ": 1,
      "APPROVED QTY": 1,
      "UOM": "PC",
      "PART NUMBER_1": 60113296,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4120001739090,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4120001739090,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 96702386,
      "PARTS CATEGORY": "VCG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 96702386,
      "PARTS CATEGORY_1": "VCG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 1,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110000970022,
      "PARTS CATEGORY": "VLG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4110000970022,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 7332632,
      "PARTS CATEGORY": "DOB",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 7332632,
      "PARTS CATEGORY_1": "DOB",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 14729840,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14729840,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 12829641,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 12829641,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": "ADBLUE20L",
      "PARTS CATEGORY": "ZZZ",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "ADBLUE20L",
      "PARTS CATEGORY_1": "ZZZ",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 60110301,
      "PARTS CATEGORY": "VOG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 60110301,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 29290039031,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 29290039031,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 23260129,
      "PARTS CATEGORY": "VTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 23260129,
      "PARTS CATEGORY_1": "VTG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 4110002816,
      "PARTS CATEGORY": "VLG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4110002816,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 60193267,
      "PARTS CATEGORY": "YPG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 60193267,
      "PARTS CATEGORY_1": "YPG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 4120007867004,
      "PARTS CATEGORY": "VLG",
      "ROQ": 8,
      "APPROVED QTY": 2,
      "UOM": "PC",
      "PART NUMBER_1": 4120007867004,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 8,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "PARTIAL WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4120006628004,
      "PARTS CATEGORY": "VLG",
      "ROQ": 3,
      "APPROVED QTY": 3,
      "UOM": "PC",
      "PART NUMBER_1": 4120006628004,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 3,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 12829642,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 12829642,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14883743,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14883743,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 16232085,
      "PARTS CATEGORY": "VOG",
      "ROQ": 1,
      "APPROVED QTY": 1,
      "UOM": "PC",
      "PART NUMBER_1": 16232085,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 60110385,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 60110385,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 12824620,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 12824620,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 11143309,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 11143309,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": "FS36230",
      "PARTS CATEGORY": "CUF",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "FS36230",
      "PARTS CATEGORY_1": "CUF",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "OLD"
    },
    {
      "PART NUMBER": 4120006027,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4120006027,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14689737,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14689737,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": "01643-32060",
      "PARTS CATEGORY": "SDG",
      "ROQ": 37,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "01643-32060",
      "PARTS CATEGORY_1": "SDG",
      "ROQ_1": 37,
      "APPROVED QTY_1": 37,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "CMI"
    },
    {
      "PART NUMBER": "308569Z01C",
      "PARTS CATEGORY": "NIG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "308569Z01C",
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 16052009,
      "PARTS CATEGORY": "VOG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 16052009,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 12809850,
      "PARTS CATEGORY": "VOG",
      "ROQ": 9,
      "APPROVED QTY": 9,
      "UOM": "PC",
      "PART NUMBER_1": 12809850,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 9,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 23260134,
      "PARTS CATEGORY": "VTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 23260134,
      "PARTS CATEGORY_1": "VTG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 4043001505,
      "PARTS CATEGORY": "VLG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4043001505,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4043001508,
      "PARTS CATEGORY": "VLG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4043001508,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14710741,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14710741,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4190000412,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4190000412,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 20515171,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 20515171,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 995177,
      "PARTS CATEGORY": "VOG",
      "ROQ": 3,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 995177,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 3,
      "APPROVED QTY_1": 3,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14558097,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14558097,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14558071,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14558071,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": "8320-04230",
      "PARTS CATEGORY": "VSG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "8320-04230",
      "PARTS CATEGORY_1": "VSG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 60125019,
      "PARTS CATEGORY": "YCG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 60125019,
      "PARTS CATEGORY_1": "YCG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 1,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 5222845443,
      "PARTS CATEGORY": "NIG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 5222845443,
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 14530832,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14530832,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14716434,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14716434,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 46696397,
      "PARTS CATEGORY": "DOP",
      "ROQ": 5,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 46696397,
      "PARTS CATEGORY_1": "DOP",
      "ROQ_1": 5,
      "APPROVED QTY_1": 5,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 46692659,
      "PARTS CATEGORY": "DOP",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 46692659,
      "PARTS CATEGORY_1": "DOP",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 4110003706,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4110003706,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 60113094,
      "PARTS CATEGORY": "VOG",
      "ROQ": 2,
      "APPROVED QTY": 2,
      "UOM": "PC",
      "PART NUMBER_1": 60113094,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 2,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 5284811166,
      "PARTS CATEGORY": "NIG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 5284811166,
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 5222845441,
      "PARTS CATEGORY": "NIG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 5222845441,
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 1,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 60358721,
      "PARTS CATEGORY": "YPG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 60358721,
      "PARTS CATEGORY_1": "YPG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 60327521,
      "PARTS CATEGORY": "YPG",
      "ROQ": 2,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 60327521,
      "PARTS CATEGORY_1": "YPG",
      "ROQ_1": 2,
      "APPROVED QTY_1": 2,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 60328867,
      "PARTS CATEGORY": "YCG",
      "ROQ": 6,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 60328867,
      "PARTS CATEGORY_1": "YCG",
      "ROQ_1": 6,
      "APPROVED QTY_1": 6,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 11701471,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 11701471,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110000509047,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4110000509047,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 70868286,
      "PARTS CATEGORY": "DOP",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 70868286,
      "PARTS CATEGORY_1": "DOP",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 60110299,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 60110299,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14574620,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14574620,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 997420,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 997420,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 46676925,
      "PARTS CATEGORY": "DOP",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 46676925,
      "PARTS CATEGORY_1": "DOP",
      "ROQ_1": 1,
      "APPROVED QTY_1": 1,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "CMI"
    },
    {
      "PART NUMBER": "1112-00830",
      "PARTS CATEGORY": "VSG",
      "ROQ": 1,
      "APPROVED QTY": 1,
      "UOM": "PC",
      "PART NUMBER_1": "1112-00830",
      "PARTS CATEGORY_1": "VSG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110001007015,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4110001007015,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 82461903,
      "PARTS CATEGORY": "VTG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 82461903,
      "PARTS CATEGORY_1": "VTG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 1,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 29050011363,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 29050011363,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14539459,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14539459,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 60169400,
      "PARTS CATEGORY": "YPG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 60169400,
      "PARTS CATEGORY_1": "YPG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 14404757,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14404757,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 5223138604,
      "PARTS CATEGORY": "NIG",
      "ROQ": 5,
      "APPROVED QTY": 1,
      "UOM": "PC",
      "PART NUMBER_1": 5223138604,
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 5,
      "APPROVED QTY_1": 1,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "PARTIAL WHXX + WH12",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 22944256,
      "PARTS CATEGORY": "VTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 22944256,
      "PARTS CATEGORY_1": "VTG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 17496167,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 17496167,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14701773,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14701773,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 60113115,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 60113115,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 7221530,
      "PARTS CATEGORY": "DOB",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 7221530,
      "PARTS CATEGORY_1": "DOB",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 14727432,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14727432,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14722918,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14722918,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14883766,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14883766,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14701764,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14701764,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14701774,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14701774,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14587635,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14587635,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110003497,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4110003497,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 20860677,
      "PARTS CATEGORY": "VTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 20860677,
      "PARTS CATEGORY_1": "VTG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 5223058608,
      "PARTS CATEGORY": "NIG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 5223058608,
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 23236852,
      "PARTS CATEGORY": "VTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 23236852,
      "PARTS CATEGORY_1": "VTG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 14561414,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14561414,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14656788,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14656788,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 29040008451,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 29040008451,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 997412,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 997412,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14661334,
      "PARTS CATEGORY": "VOG",
      "ROQ": 1,
      "APPROVED QTY": 1,
      "UOM": "PC",
      "PART NUMBER_1": 14661334,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14661333,
      "PARTS CATEGORY": "VOG",
      "ROQ": 1,
      "APPROVED QTY": 1,
      "UOM": "PC",
      "PART NUMBER_1": 14661333,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 84472607,
      "PARTS CATEGORY": "VTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 84472607,
      "PARTS CATEGORY_1": "VTG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 997983,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 997983,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 20569635,
      "PARTS CATEGORY": "VOG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 20569635,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 60113082,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 60113082,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 7000987,
      "PARTS CATEGORY": "DOB",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 7000987,
      "PARTS CATEGORY_1": "DOB",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": "1CM1450",
      "PARTS CATEGORY": "DOB",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "1CM1450",
      "PARTS CATEGORY_1": "DOB",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 997437,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 997437,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 60328868,
      "PARTS CATEGORY": "YCG",
      "ROQ": 2,
      "APPROVED QTY": 2,
      "UOM": "PC",
      "PART NUMBER_1": 60328868,
      "PARTS CATEGORY_1": "YCG",
      "ROQ_1": 2,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 96718598,
      "PARTS CATEGORY": "VCG",
      "ROQ": 1,
      "APPROVED QTY": 1,
      "UOM": "PC",
      "PART NUMBER_1": 96718598,
      "PARTS CATEGORY_1": "VCG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 5222737238,
      "PARTS CATEGORY": "NIG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 5222737238,
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 4190002761,
      "PARTS CATEGORY": "VLG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4190002761,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 1,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4130002496,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4130002496,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 29250001081,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 29250001081,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4011001250,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4011001250,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4015000428,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4015000428,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 29250001031,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 29250001031,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 29250001051,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 29250001051,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 29250017661,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 29250017661,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 29250009441,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 29250009441,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 29250000091,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 29250000091,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 29250009451,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 29250009451,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 29250009461,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 29250009461,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 29250009421,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 29250009421,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14540123,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14540123,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": "7114-12530",
      "PARTS CATEGORY": "VSG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "7114-12530",
      "PARTS CATEGORY_1": "VSG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": "7114-12560",
      "PARTS CATEGORY": "VSG",
      "ROQ": 2,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "7114-12560",
      "PARTS CATEGORY_1": "VSG",
      "ROQ_1": 2,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": "927839Z00D",
      "PARTS CATEGORY": "NIG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "927839Z00D",
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 14679698,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14679698,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14709863,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14709863,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110001782004,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4110001782004,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110000563007,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4110000563007,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110000108006,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4110000108006,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14721846,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14721846,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 6670207,
      "PARTS CATEGORY": "DOB",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 6670207,
      "PARTS CATEGORY_1": "DOB",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 997383,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 997383,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14737252,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14737252,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 29270009421,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 29270009421,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110000076189,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4110000076189,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110001394117,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4110001394117,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110000076302,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4110000076302,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110000076199,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4110000076199,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110000367110,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4110000367110,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110000367109,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4110000367109,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110000367111,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4110000367111,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110000367043,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4110000367043,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110000076251,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4110000076251,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110000367108,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4110000367108,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110000367107,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4110000367107,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110000367104,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4110000367104,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110000367106,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4110000367106,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110000076294,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4110000076294,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110000367103,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4110000367103,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110000367100,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4110000367100,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110000367127,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4110000367127,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110000076062,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4110000076062,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110000076060,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4110000076060,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110000076296,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4110000076296,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110000367123,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4110000367123,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110000076322,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4110000076322,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 21380490,
      "PARTS CATEGORY": "VTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 21380490,
      "PARTS CATEGORY_1": "VTG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 21716424,
      "PARTS CATEGORY": "VTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 21716424,
      "PARTS CATEGORY_1": "VTG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 14711075,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14711075,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14702367,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14702367,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14713704,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14713704,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 6682034,
      "PARTS CATEGORY": "DOB",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 6682034,
      "PARTS CATEGORY_1": "DOB",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 4015000119,
      "PARTS CATEGORY": "VLG",
      "ROQ": 1,
      "APPROVED QTY": 1,
      "UOM": "PC",
      "PART NUMBER_1": 4015000119,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14884948,
      "PARTS CATEGORY": "VOG",
      "ROQ": 23,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14884948,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 23,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4120006889,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4120006889,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 5223273542,
      "PARTS CATEGORY": "NIG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 5223273542,
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 14725894,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14725894,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110000367098,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4110000367098,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110000367033,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4110000367033,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14679690,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14679690,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 60326015,
      "PARTS CATEGORY": "YPG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 60326015,
      "PARTS CATEGORY_1": "YPG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 4110000367121,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4110000367121,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110004221,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4110004221,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 60242353,
      "PARTS CATEGORY": "YCG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 60242353,
      "PARTS CATEGORY_1": "YCG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 60320099,
      "PARTS CATEGORY": "YCG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 60320099,
      "PARTS CATEGORY_1": "YCG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 60320126,
      "PARTS CATEGORY": "YCG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 60320126,
      "PARTS CATEGORY_1": "YCG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 60333601,
      "PARTS CATEGORY": "YCG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 60333601,
      "PARTS CATEGORY_1": "YCG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 23226845,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 23226845,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 5222748120,
      "PARTS CATEGORY": "NIG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 5222748120,
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 4110000076360,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4110000076360,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 7200002550,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 7200002550,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 60345316,
      "PARTS CATEGORY": "YCG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 60345316,
      "PARTS CATEGORY_1": "YCG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 15038635,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 15038635,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 6680403,
      "PARTS CATEGORY": "DOB",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 6680403,
      "PARTS CATEGORY_1": "DOB",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 6675731,
      "PARTS CATEGORY": "DOB",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 6675731,
      "PARTS CATEGORY_1": "DOB",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 6668820,
      "PARTS CATEGORY": "DOB",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 6668820,
      "PARTS CATEGORY_1": "DOB",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 14704595,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14704595,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14682685,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14682685,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 22803341,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 22803341,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 22803447,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 22803447,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 29290039051,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 29290039051,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": "115-75-58000",
      "PARTS CATEGORY": "SDG",
      "ROQ": 7,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "115-75-58000",
      "PARTS CATEGORY_1": "SDG",
      "ROQ_1": 7,
      "APPROVED QTY_1": 7,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 12724648,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 12724648,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110000076104,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4110000076104,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 29170181023,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 29170181023,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": "152119Z00B",
      "PARTS CATEGORY": "NIG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "152119Z00B",
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 4110000509217,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4110000509217,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4120003758,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4120003758,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110002901016,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4110002901016,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 5222455512,
      "PARTS CATEGORY": "NIG",
      "ROQ": 8,
      "APPROVED QTY": 4,
      "UOM": "PC",
      "PART NUMBER_1": 5222455512,
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 8,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "PARTIAL WHXX",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 5223058609,
      "PARTS CATEGORY": "NIG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 5223058609,
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 7275347,
      "PARTS CATEGORY": "DOB",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 7275347,
      "PARTS CATEGORY_1": "DOB",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 82461913,
      "PARTS CATEGORY": "VTG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 82461913,
      "PARTS CATEGORY_1": "VTG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 1,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 84164453,
      "PARTS CATEGORY": "VTG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 84164453,
      "PARTS CATEGORY_1": "VTG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 1,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 84055661,
      "PARTS CATEGORY": "VTG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 84055661,
      "PARTS CATEGORY_1": "VTG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 1,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 21196040,
      "PARTS CATEGORY": "VTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 21196040,
      "PARTS CATEGORY_1": "VTG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 22080280,
      "PARTS CATEGORY": "VTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 22080280,
      "PARTS CATEGORY_1": "VTG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 22080281,
      "PARTS CATEGORY": "VTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 22080281,
      "PARTS CATEGORY_1": "VTG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 21305187,
      "PARTS CATEGORY": "VTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 21305187,
      "PARTS CATEGORY_1": "VTG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 21374700,
      "PARTS CATEGORY": "VTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 21374700,
      "PARTS CATEGORY_1": "VTG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 21641263,
      "PARTS CATEGORY": "VTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 21641263,
      "PARTS CATEGORY_1": "VTG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 21671842,
      "PARTS CATEGORY": "VTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 21671842,
      "PARTS CATEGORY_1": "VTG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 20722721,
      "PARTS CATEGORY": "VTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 20722721,
      "PARTS CATEGORY_1": "VTG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 4110000076053,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4110000076053,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110000076229,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4110000076229,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110000076321,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4110000076321,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 6715866,
      "PARTS CATEGORY": "DOB",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 6715866,
      "PARTS CATEGORY_1": "DOB",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 4110000076428,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4110000076428,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4190002742,
      "PARTS CATEGORY": "VLG",
      "ROQ": 2,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4190002742,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 2,
      "APPROVED QTY_1": 2,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 7000673,
      "PARTS CATEGORY": "DOB",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 7000673,
      "PARTS CATEGORY_1": "DOB",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": "324859Z00C",
      "PARTS CATEGORY": "NIG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "324859Z00C",
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 7304852,
      "PARTS CATEGORY": "DOB",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 7304852,
      "PARTS CATEGORY_1": "DOB",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 14568000,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14568000,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 6663289,
      "PARTS CATEGORY": "DOB",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 6663289,
      "PARTS CATEGORY_1": "DOB",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 23369953,
      "PARTS CATEGORY": "VTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 23369953,
      "PARTS CATEGORY_1": "VTG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 14694038,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14694038,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4120006094,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4120006094,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": "443429Z00A",
      "PARTS CATEGORY": "NIG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "443429Z00A",
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 4015000032,
      "PARTS CATEGORY": "VLG",
      "ROQ": 3,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4015000032,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 3,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": "461279Z00A",
      "PARTS CATEGORY": "NIG",
      "ROQ": 1,
      "APPROVED QTY": 1,
      "UOM": "PC",
      "PART NUMBER_1": "461279Z00A",
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": "431149Z00A",
      "PARTS CATEGORY": "NIG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "431149Z00A",
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 14737813,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14737813,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 5223187972,
      "PARTS CATEGORY": "NIG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 5223187972,
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 60273568,
      "PARTS CATEGORY": "YPG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 60273568,
      "PARTS CATEGORY_1": "YPG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 4110000145087,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4110000145087,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 60318536,
      "PARTS CATEGORY": "YCG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 60318536,
      "PARTS CATEGORY_1": "YCG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": "A210404000009",
      "PARTS CATEGORY": "YCG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "A210404000009",
      "PARTS CATEGORY_1": "YCG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 1,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 23446985,
      "PARTS CATEGORY": "VTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 23446985,
      "PARTS CATEGORY_1": "VTG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 23446987,
      "PARTS CATEGORY": "VTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 23446987,
      "PARTS CATEGORY_1": "VTG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 14645737,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14645737,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 29070024091,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 29070024091,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4030001093,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4030001093,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 29070019841,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 29070019841,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": "C61267",
      "PARTS CATEGORY": "SOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "C61267",
      "PARTS CATEGORY_1": "SOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 5223387867,
      "PARTS CATEGORY": "NIG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 5223387867,
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 1,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 5223473091,
      "PARTS CATEGORY": "NIG",
      "ROQ": 2,
      "APPROVED QTY": 1,
      "UOM": "PC",
      "PART NUMBER_1": 5223473091,
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 2,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "PARTIAL WHXX",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 29270007831,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 29270007831,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 29270008591,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 29270008591,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 5282675887,
      "PARTS CATEGORY": "NIG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 5282675887,
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 5333035,
      "PARTS CATEGORY": "CUG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 5333035,
      "PARTS CATEGORY_1": "CUG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": 0
    },
    {
      "PART NUMBER": 5223592666,
      "PARTS CATEGORY": "NIG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 5223592666,
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 4190000595,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4190000595,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4190002907002,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4190002907002,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4190002907004,
      "PARTS CATEGORY": "VLG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4190002907004,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 1,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14746240,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14746240,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": "1725101Z0B",
      "PARTS CATEGORY": "NIG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "1725101Z0B",
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 60110297,
      "PARTS CATEGORY": "VOG",
      "ROQ": 7,
      "APPROVED QTY": 7,
      "UOM": "PC",
      "PART NUMBER_1": 60110297,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 7,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 12814491,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 12814491,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": "158-40-A0000P050-01",
      "PARTS CATEGORY": "SDG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "158-40-A0000P050-01",
      "PARTS CATEGORY_1": "SDG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 14558944,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14558944,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14638992,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14638992,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 11222279,
      "PARTS CATEGORY": "YCG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 11222279,
      "PARTS CATEGORY_1": "YCG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 14638193,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14638193,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 5473238,
      "PARTS CATEGORY": "CUG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 5473238,
      "PARTS CATEGORY_1": "CUG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": 0
    },
    {
      "PART NUMBER": 7163219,
      "PARTS CATEGORY": "DOB",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 7163219,
      "PARTS CATEGORY_1": "DOB",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 14737811,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14737811,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14685547,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14685547,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": "22.4.JSM33*2HXL",
      "PARTS CATEGORY": "PHG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "22.4.JSM33*2HXL",
      "PARTS CATEGORY_1": "PHG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 1,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 4120003296,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4120003296,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14671760,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14671760,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110002354001,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4110002354001,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110002354002,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4110002354002,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 23381562,
      "PARTS CATEGORY": "VTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 23381562,
      "PARTS CATEGORY_1": "VTG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 5223488187,
      "PARTS CATEGORY": "NIG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 5223488187,
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 14883767,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14883767,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 22216579,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 22216579,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14720764,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14720764,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 3030900178,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 3030900178,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14650866,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14650866,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14737841,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14737841,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14652624,
      "PARTS CATEGORY": "VOG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14652624,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 1,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 997492,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 997492,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 60348780,
      "PARTS CATEGORY": "YCG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 60348780,
      "PARTS CATEGORY_1": "YCG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 14744967,
      "PARTS CATEGORY": "VOG",
      "ROQ": 2,
      "APPROVED QTY": 2,
      "UOM": "PC",
      "PART NUMBER_1": 14744967,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 2,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14531688,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14531688,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 5223579293,
      "PARTS CATEGORY": "NIG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 5223579293,
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": "562039Z00E",
      "PARTS CATEGORY": "NIG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "562039Z00E",
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 60113647,
      "PARTS CATEGORY": "VOG",
      "ROQ": 1,
      "APPROVED QTY": 1,
      "UOM": "PC",
      "PART NUMBER_1": 60113647,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 60188333,
      "PARTS CATEGORY": "YPG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 60188333,
      "PARTS CATEGORY_1": "YPG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 11797975,
      "PARTS CATEGORY": "YTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 11797975,
      "PARTS CATEGORY_1": "YTG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 29070001031,
      "PARTS CATEGORY": "VLG",
      "ROQ": 3,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 29070001031,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 3,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 994868,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 994868,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 23285701,
      "PARTS CATEGORY": "VTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 23285701,
      "PARTS CATEGORY_1": "VTG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 5222931438,
      "PARTS CATEGORY": "NIG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 5222931438,
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 14749759,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14749759,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4120003757,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4120003757,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110000556223,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4110000556223,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4190002764,
      "PARTS CATEGORY": "VLG",
      "ROQ": 2,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4190002764,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 2,
      "APPROVED QTY_1": 2,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4190002946,
      "PARTS CATEGORY": "VLG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4190002946,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 1,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4190002907,
      "PARTS CATEGORY": "VLG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4190002907,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 1,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4130002904,
      "PARTS CATEGORY": "VLG",
      "ROQ": 3,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4130002904,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 3,
      "APPROVED QTY_1": 3,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4190002766,
      "PARTS CATEGORY": "VLG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4190002766,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 1,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 29010067911,
      "PARTS CATEGORY": "VLG",
      "ROQ": 1,
      "APPROVED QTY": 1,
      "UOM": "PC",
      "PART NUMBER_1": 29010067911,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4190002947,
      "PARTS CATEGORY": "VLG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4190002947,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 1,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 5222859763,
      "PARTS CATEGORY": "NIG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 5222859763,
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 4110000991031,
      "PARTS CATEGORY": "VLG",
      "ROQ": 2,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4110000991031,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 2,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 12788543,
      "PARTS CATEGORY": "VOG",
      "ROQ": 1,
      "APPROVED QTY": 1,
      "UOM": "PC",
      "PART NUMBER_1": 12788543,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 7312069,
      "PARTS CATEGORY": "DOB",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 7312069,
      "PARTS CATEGORY_1": "DOB",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 7004877,
      "PARTS CATEGORY": "DOB",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 7004877,
      "PARTS CATEGORY_1": "DOB",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 29050020491,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 29050020491,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 29050020501,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 29050020501,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 29050020511,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 29050020511,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 29050020931,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 29050020931,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 29040008401,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 29040008401,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 10125894,
      "PARTS CATEGORY": "YCG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 10125894,
      "PARTS CATEGORY_1": "YCG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 1,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "CMI"
    },
    {
      "PART NUMBER": "A810201206126",
      "PARTS CATEGORY": "YCG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "A810201206126",
      "PARTS CATEGORY_1": "YCG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 16284724,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 16284724,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 23191319,
      "PARTS CATEGORY": "VOG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 23191319,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": "15058186PQ",
      "PARTS CATEGORY": "LUV",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "15058186PQ",
      "PARTS CATEGORY_1": "LUV",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "LUBRICANTS"
    },
    {
      "PART NUMBER": "15067522PQ",
      "PARTS CATEGORY": "LUV",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "15067522PQ",
      "PARTS CATEGORY_1": "LUV",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "LUBRICANTS"
    },
    {
      "PART NUMBER": "15067640PQ",
      "PARTS CATEGORY": "LUV",
      "ROQ": 167,
      "APPROVED QTY": 17,
      "UOM": "PC",
      "PART NUMBER_1": "15067640PQ",
      "PARTS CATEGORY_1": "LUV",
      "ROQ_1": 167,
      "APPROVED QTY_1": 143,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12 + WHXX",
      "BU": "LUBRICANTS"
    },
    {
      "PART NUMBER": 21707132,
      "PARTS CATEGORY": "VTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 21707132,
      "PARTS CATEGORY_1": "VTG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 21707133,
      "PARTS CATEGORY": "VTG",
      "ROQ": 10,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 21707133,
      "PARTS CATEGORY_1": "VTG",
      "ROQ_1": 10,
      "APPROVED QTY_1": 10,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 21707134,
      "PARTS CATEGORY": "VTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 21707134,
      "PARTS CATEGORY_1": "VTG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 980521,
      "PARTS CATEGORY": "VTG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 980521,
      "PARTS CATEGORY_1": "VTG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 914465,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 914465,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 955892,
      "PARTS CATEGORY": "VOG",
      "ROQ": 1,
      "APPROVED QTY": 1,
      "UOM": "PC",
      "PART NUMBER_1": 955892,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 969161,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 969161,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 969163,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 969163,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 970742,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 970742,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 984725,
      "PARTS CATEGORY": "VOG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 984725,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 984738,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 984738,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 984751,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 984751,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 984756,
      "PARTS CATEGORY": "VOG",
      "ROQ": 3,
      "APPROVED QTY": 3,
      "UOM": "PC",
      "PART NUMBER_1": 984756,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 3,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 990861,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 990861,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 990950,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 990950,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 992515,
      "PARTS CATEGORY": "VOG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 992515,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 992516,
      "PARTS CATEGORY": "VOG",
      "ROQ": 3,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 992516,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 3,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 992517,
      "PARTS CATEGORY": "VOG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 992517,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 992521,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 992521,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 1674083,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 1674083,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 20367490,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 20367490,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 20538793,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 20538793,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 21271982,
      "PARTS CATEGORY": "VOG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 21271982,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 21415427,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 21415427,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 21531072,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 21531072,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 22567321,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 22567321,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 22899626,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 22899626,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 22902431,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 22902431,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 85135446,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 85135446,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 23387844,
      "PARTS CATEGORY": "VTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 23387844,
      "PARTS CATEGORY_1": "VTG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 4110001755009,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4110001755009,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 6669267,
      "PARTS CATEGORY": "DOB",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 6669267,
      "PARTS CATEGORY_1": "DOB",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": "4110000677001Y",
      "PARTS CATEGORY": "VLG",
      "ROQ": 3,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "4110000677001Y",
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 3,
      "APPROVED QTY_1": 3,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110000787007,
      "PARTS CATEGORY": "VLG",
      "ROQ": 3,
      "APPROVED QTY": 3,
      "UOM": "PC",
      "PART NUMBER_1": 4110000787007,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 3,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14884949,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14884949,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4120000761,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4120000761,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4120002319001,
      "PARTS CATEGORY": "VLG",
      "ROQ": 2,
      "APPROVED QTY": 2,
      "UOM": "PC",
      "PART NUMBER_1": 4120002319001,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 2,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4120002103001,
      "PARTS CATEGORY": "VLG",
      "ROQ": 3,
      "APPROVED QTY": 3,
      "UOM": "PC",
      "PART NUMBER_1": 4120002103001,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 3,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4190000173001,
      "PARTS CATEGORY": "VLG",
      "ROQ": 3,
      "APPROVED QTY": 3,
      "UOM": "PC",
      "PART NUMBER_1": 4190000173001,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 3,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4120000721001,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4120000721001,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4190002509008,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4190002509008,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110000322001,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4110000322001,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110000322002,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4110000322002,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4190002205006,
      "PARTS CATEGORY": "VLG",
      "ROQ": 2,
      "APPROVED QTY": 2,
      "UOM": "PC",
      "PART NUMBER_1": 4190002205006,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 2,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": "4110000677002Y",
      "PARTS CATEGORY": "VLG",
      "ROQ": 3,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "4110000677002Y",
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 3,
      "APPROVED QTY_1": 3,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 23307414,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 23307414,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 6213184,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 6213184,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4040000051,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4040000051,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4011001261,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4011001261,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14710704,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14710704,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14734295,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14734295,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14555877,
      "PARTS CATEGORY": "VOG",
      "ROQ": 1,
      "APPROVED QTY": 1,
      "UOM": "PC",
      "PART NUMBER_1": 14555877,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": "119139Z00A",
      "PARTS CATEGORY": "NIG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "119139Z00A",
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 3030900177,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 3030900177,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110000556202,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4110000556202,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14726137,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14726137,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14726138,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14726138,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 29050022281,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 29050022281,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 7222791,
      "PARTS CATEGORY": "DOB",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 7222791,
      "PARTS CATEGORY_1": "DOB",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 29040009571,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 29040009571,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 29040009581,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 29040009581,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4021000110,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4021000110,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 61019283,
      "PARTS CATEGORY": "YCG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 61019283,
      "PARTS CATEGORY_1": "YCG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": "A810301040447",
      "PARTS CATEGORY": "YPG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "A810301040447",
      "PARTS CATEGORY_1": "YPG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 1,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 14882694,
      "PARTS CATEGORY": "VOG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14882694,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14550214,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14550214,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14728476,
      "PARTS CATEGORY": "VOG",
      "ROQ": 1,
      "APPROVED QTY": 1,
      "UOM": "PC",
      "PART NUMBER_1": 14728476,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4190001802,
      "PARTS CATEGORY": "VLG",
      "ROQ": 9,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4190001802,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 9,
      "APPROVED QTY_1": 9,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4190001804,
      "PARTS CATEGORY": "VLG",
      "ROQ": 9,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4190001804,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 9,
      "APPROVED QTY_1": 9,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4190001805,
      "PARTS CATEGORY": "VLG",
      "ROQ": 9,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4190001805,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 9,
      "APPROVED QTY_1": 9,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110002116002,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4110002116002,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110000727162,
      "PARTS CATEGORY": "VLG",
      "ROQ": 2,
      "APPROVED QTY": 2,
      "UOM": "PC",
      "PART NUMBER_1": 4110000727162,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 2,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 11214252,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 11214252,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 11210241,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 11210241,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 11211360,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 11211360,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 11211213,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 11211213,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 11210987,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 11210987,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 11211189,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 11211189,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14405925,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14405925,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14403810,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14403810,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110000727198,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4110000727198,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14402462,
      "PARTS CATEGORY": "VLG",
      "ROQ": 1,
      "APPROVED QTY": 1,
      "UOM": "PC",
      "PART NUMBER_1": 14402462,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110003380015,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4110003380015,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110003380016,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4110003380016,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4041002225,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4041002225,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 17410989,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 17410989,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 60110391,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 60110391,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 997473,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 997473,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14729117,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14729117,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4011001299,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4011001299,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 5223285701,
      "PARTS CATEGORY": "NIG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 5223285701,
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 4120001004007,
      "PARTS CATEGORY": "VLG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4120001004007,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 1001186466,
      "PARTS CATEGORY": "JLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 1001186466,
      "PARTS CATEGORY_1": "JLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 14603022,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14603022,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 60220631,
      "PARTS CATEGORY": "YPG",
      "ROQ": 14,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 60220631,
      "PARTS CATEGORY_1": "YPG",
      "ROQ_1": 14,
      "APPROVED QTY_1": 11,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "PARTIAL WH12",
      "BU": "CMI"
    },
    {
      "PART NUMBER": "35C412",
      "PARTS CATEGORY": "DOB",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "35C412",
      "PARTS CATEGORY_1": "DOB",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 6631418,
      "PARTS CATEGORY": "DOB",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 6631418,
      "PARTS CATEGORY_1": "DOB",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "CMI"
    },
    {
      "PART NUMBER": "4F6106",
      "PARTS CATEGORY": "DOB",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "4F6106",
      "PARTS CATEGORY_1": "DOB",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "CMI"
    },
    {
      "PART NUMBER": "53D6",
      "PARTS CATEGORY": "DOB",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "53D6",
      "PARTS CATEGORY_1": "DOB",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 60358722,
      "PARTS CATEGORY": "YPG",
      "ROQ": 1,
      "APPROVED QTY": 1,
      "UOM": "PC",
      "PART NUMBER_1": 60358722,
      "PARTS CATEGORY_1": "YPG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 976945,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 976945,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 984993,
      "PARTS CATEGORY": "VTG",
      "ROQ": 3,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 984993,
      "PARTS CATEGORY_1": "VTG",
      "ROQ_1": 3,
      "APPROVED QTY_1": 3,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": "9131-11600",
      "PARTS CATEGORY": "VSG",
      "ROQ": 1,
      "APPROVED QTY": 1,
      "UOM": "PC",
      "PART NUMBER_1": "9131-11600",
      "PARTS CATEGORY_1": "VSG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14708503,
      "PARTS CATEGORY": "VOG",
      "ROQ": 2,
      "APPROVED QTY": 2,
      "UOM": "PC",
      "PART NUMBER_1": 14708503,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 2,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14708505,
      "PARTS CATEGORY": "VOG",
      "ROQ": 2,
      "APPROVED QTY": 1,
      "UOM": "PC",
      "PART NUMBER_1": 14708505,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 2,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "PARTIAL WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14723460,
      "PARTS CATEGORY": "VOG",
      "ROQ": 7,
      "APPROVED QTY": 7,
      "UOM": "PC",
      "PART NUMBER_1": 14723460,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 7,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 17473102,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 17473102,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 17473106,
      "PARTS CATEGORY": "VOG",
      "ROQ": 4,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 17473106,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 4,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 17493106,
      "PARTS CATEGORY": "VOG",
      "ROQ": 3,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 17493106,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 3,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14732381,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14732381,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14732401,
      "PARTS CATEGORY": "VOG",
      "ROQ": 4,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14732401,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 4,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14732402,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14732402,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14672731,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14672731,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 23417523,
      "PARTS CATEGORY": "VTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 23417523,
      "PARTS CATEGORY_1": "VTG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 14737832,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14737832,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14730968,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14730968,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 997882,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 997882,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 6710659,
      "PARTS CATEGORY": "DOB",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 6710659,
      "PARTS CATEGORY_1": "DOB",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 6713589,
      "PARTS CATEGORY": "DOB",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 6713589,
      "PARTS CATEGORY_1": "DOB",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 6713887,
      "PARTS CATEGORY": "DOB",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 6713887,
      "PARTS CATEGORY_1": "DOB",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 22281893,
      "PARTS CATEGORY": "VOG",
      "ROQ": 2,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 22281893,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 2,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110003229003,
      "PARTS CATEGORY": "VLG",
      "ROQ": 2,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4110003229003,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 2,
      "APPROVED QTY_1": 2,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 992454,
      "PARTS CATEGORY": "VOG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 992454,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 23491400,
      "PARTS CATEGORY": "VTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 23491400,
      "PARTS CATEGORY_1": "VTG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 997408,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 997408,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": "430739Z00A ",
      "PARTS CATEGORY": "NIG",
      "ROQ": 20,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "430739Z00A ",
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 20,
      "APPROVED QTY_1": 20,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": "432469Z00A",
      "PARTS CATEGORY": "NIG",
      "ROQ": 5,
      "APPROVED QTY": 5,
      "UOM": "PC",
      "PART NUMBER_1": "432469Z00A",
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 5,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 14884939,
      "PARTS CATEGORY": "VOG",
      "ROQ": 4,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14884939,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 4,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 11203464,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 11203464,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4130039,
      "PARTS CATEGORY": "SOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4130039,
      "PARTS CATEGORY_1": "SOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 4110000591035,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4110000591035,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14573909,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14573909,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14726295,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14726295,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4043000467,
      "PARTS CATEGORY": "VLG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4043000467,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14645434,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14645434,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14725312,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14725312,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14612972,
      "PARTS CATEGORY": "VOH",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14612972,
      "PARTS CATEGORY_1": "VOH",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": ""
    },
    {
      "PART NUMBER": 5223229028,
      "PARTS CATEGORY": "NIG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 5223229028,
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": "967110Z000",
      "PARTS CATEGORY": "NIG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "967110Z000",
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 4110000555174,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4110000555174,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 5223726004,
      "PARTS CATEGORY": "NIG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 5223726004,
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": "967120Z000",
      "PARTS CATEGORY": "NIG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "967120Z000",
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 14560255,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14560255,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14560256,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14560256,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 6634631,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 6634631,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110000367018,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4110000367018,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4120004492,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4120004492,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4120005390,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4120005390,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 16940716,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 16940716,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 12769848,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 12769848,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 29220020701,
      "PARTS CATEGORY": "VLG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 29220020701,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 1,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 5222747762,
      "PARTS CATEGORY": "NIG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 5222747762,
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 14531020,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14531020,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": "84G3712",
      "PARTS CATEGORY": "DOB",
      "ROQ": 3,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "84G3712",
      "PARTS CATEGORY_1": "DOB",
      "ROQ_1": 3,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 7168173,
      "PARTS CATEGORY": "DOB",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 7168173,
      "PARTS CATEGORY_1": "DOB",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 5222746873,
      "PARTS CATEGORY": "NIG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 5222746873,
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 5222794568,
      "PARTS CATEGORY": "NIG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 5222794568,
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 10071378,
      "PARTS CATEGORY": "YCG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 10071378,
      "PARTS CATEGORY_1": "YCG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 29050027111,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 29050027111,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 17500365,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 17500365,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 5220745892,
      "PARTS CATEGORY": "NIG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 5220745892,
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 4130002901,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4130002901,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 22658275,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 22658275,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 21426987,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 21426987,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110000367131,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4110000367131,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 29050019331,
      "PARTS CATEGORY": "VLG",
      "ROQ": 1,
      "APPROVED QTY": 1,
      "UOM": "PC",
      "PART NUMBER_1": 29050019331,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14733381,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14733381,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 17325561,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 17325561,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 17287189,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 17287189,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 7325333,
      "PARTS CATEGORY": "DOB",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 7325333,
      "PARTS CATEGORY_1": "DOB",
      "ROQ_1": 1,
      "APPROVED QTY_1": 1,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 23307415,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 23307415,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 17208525,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 17208525,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14731469,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14731469,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14723426,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14723426,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 22306614,
      "PARTS CATEGORY": "VTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 22306614,
      "PARTS CATEGORY_1": "VTG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 982558,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 982558,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14749664,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14749664,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 17511983,
      "PARTS CATEGORY": "LUV",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 17511983,
      "PARTS CATEGORY_1": "LUV",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "LUBRICANTS"
    },
    {
      "PART NUMBER": 23657265,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 23657265,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 21795915,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 21795915,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 997953,
      "PARTS CATEGORY": "VOG",
      "ROQ": 3,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 997953,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 3,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 22952495,
      "PARTS CATEGORY": "VTG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 22952495,
      "PARTS CATEGORY_1": "VTG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 1,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": "160603020014A",
      "PARTS CATEGORY": "YCG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "160603020014A",
      "PARTS CATEGORY_1": "YCG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 1001225432,
      "PARTS CATEGORY": "JLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 1001225432,
      "PARTS CATEGORY_1": "JLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 26350003331,
      "PARTS CATEGORY": "VLG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 26350003331,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 1,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110001680002,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4110001680002,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14638977,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14638977,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14739343,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14739343,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 21934272,
      "PARTS CATEGORY": "VTG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 21934272,
      "PARTS CATEGORY_1": "VTG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 1,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 14749667,
      "PARTS CATEGORY": "VOG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14749667,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 1,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14560261,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14560261,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 16900698,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 16900698,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 17522218,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 17522218,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 5223686649,
      "PARTS CATEGORY": "NIG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 5223686649,
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 29250017701,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 29250017701,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 29250017711,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 29250017711,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 29250017721,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 29250017721,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 21244698,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 21244698,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 7137769,
      "PARTS CATEGORY": "DOB",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 7137769,
      "PARTS CATEGORY_1": "DOB",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 14762630,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14762630,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 5260112798,
      "PARTS CATEGORY": "NIG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 5260112798,
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 7283056,
      "PARTS CATEGORY": "DOB",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 7283056,
      "PARTS CATEGORY_1": "DOB",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 11211325,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 11211325,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110002373023,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4110002373023,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14671837,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14671837,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 984996,
      "PARTS CATEGORY": "VTG",
      "ROQ": 2,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 984996,
      "PARTS CATEGORY_1": "VTG",
      "ROQ_1": 2,
      "APPROVED QTY_1": 2,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 16891063,
      "PARTS CATEGORY": "VOG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 16891063,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 1,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4120000560514,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4120000560514,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 936658,
      "PARTS CATEGORY": "VOH",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 936658,
      "PARTS CATEGORY_1": "VOH",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": ""
    },
    {
      "PART NUMBER": "TPWF2076",
      "PARTS CATEGORY": "SDG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "TPWF2076",
      "PARTS CATEGORY_1": "SDG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 4110002230037,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4110002230037,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4013000382,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4013000382,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": "324099Z00D",
      "PARTS CATEGORY": "NIG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "324099Z00D",
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 5223476572,
      "PARTS CATEGORY": "NIG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 5223476572,
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 17478948,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 17478948,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14672980,
      "PARTS CATEGORY": "VOG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14672980,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14749665,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14749665,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14672981,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14672981,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14736879,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14736879,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": "A210111000091",
      "PARTS CATEGORY": "YPG",
      "ROQ": 2,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "A210111000091",
      "PARTS CATEGORY_1": "YPG",
      "ROQ_1": 2,
      "APPROVED QTY_1": 2,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "CMI"
    },
    {
      "PART NUMBER": "A210404000005",
      "PARTS CATEGORY": "YPG",
      "ROQ": 5,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "A210404000005",
      "PARTS CATEGORY_1": "YPG",
      "ROQ_1": 5,
      "APPROVED QTY_1": 5,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "CMI"
    },
    {
      "PART NUMBER": "B222100000288",
      "PARTS CATEGORY": "YPG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "B222100000288",
      "PARTS CATEGORY_1": "YPG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 14675561,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14675561,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 7214545,
      "PARTS CATEGORY": "DOB",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 7214545,
      "PARTS CATEGORY_1": "DOB",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 15018560,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 15018560,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 11205297,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 11205297,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14767219,
      "PARTS CATEGORY": "VTS",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14767219,
      "PARTS CATEGORY_1": "VTS",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 4110000991047,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4110000991047,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110001525009,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4110001525009,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4120002274,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4120002274,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110003706001,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4110003706001,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14736786,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14736786,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 60110032,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 60110032,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": "29100001051A",
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "29100001051A",
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 5223616626,
      "PARTS CATEGORY": "NIG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 5223616626,
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 1,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 14750655,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14750655,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 23605013,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 23605013,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14400779,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14400779,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14675562,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14675562,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14766277,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14766277,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110000322005,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4110000322005,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 7414581,
      "PARTS CATEGORY": "DOB",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 7414581,
      "PARTS CATEGORY_1": "DOB",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 16813698,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 16813698,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14743649,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14743649,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 29250017691,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 29250017691,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 1001225430,
      "PARTS CATEGORY": "JLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 1001225430,
      "PARTS CATEGORY_1": "JLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 11217422,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 11217422,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 11213340,
      "PARTS CATEGORY": "VLG",
      "ROQ": 4,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 11213340,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 4,
      "APPROVED QTY_1": 4,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 11223287,
      "PARTS CATEGORY": "VLG",
      "ROQ": 3,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 11223287,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 3,
      "APPROVED QTY_1": 3,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 5223071799,
      "PARTS CATEGORY": "NIG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 5223071799,
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 15045742,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 15045742,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110002112,
      "PARTS CATEGORY": "VLG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4110002112,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 1,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 5200994875,
      "PARTS CATEGORY": "NIG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 5200994875,
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 14632907,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14632907,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14619600,
      "PARTS CATEGORY": "VOG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14619600,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 997996,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 997996,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14717654,
      "PARTS CATEGORY": "VOG",
      "ROQ": 3,
      "APPROVED QTY": 3,
      "UOM": "PC",
      "PART NUMBER_1": 14717654,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 3,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 7286432,
      "PARTS CATEGORY": "DOB",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 7286432,
      "PARTS CATEGORY_1": "DOB",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 7021380,
      "PARTS CATEGORY": "DOB",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 7021380,
      "PARTS CATEGORY_1": "DOB",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 61018500,
      "PARTS CATEGORY": "YPG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 61018500,
      "PARTS CATEGORY_1": "YPG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 23344580,
      "PARTS CATEGORY": "VTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 23344580,
      "PARTS CATEGORY_1": "VTG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 60284008,
      "PARTS CATEGORY": "YPG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 60284008,
      "PARTS CATEGORY_1": "YPG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 17210651,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 17210651,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 7349796,
      "PARTS CATEGORY": "DOB",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 7349796,
      "PARTS CATEGORY_1": "DOB",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 4110003115021,
      "PARTS CATEGORY": "VLG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4110003115021,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 1,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 6680970,
      "PARTS CATEGORY": "DOB",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 6680970,
      "PARTS CATEGORY_1": "DOB",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 4110002230043,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4110002230043,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 29270018131,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 29270018131,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 997471,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 997471,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 60263371,
      "PARTS CATEGORY": "YTG",
      "ROQ": 3,
      "APPROVED QTY": 1,
      "UOM": "PC",
      "PART NUMBER_1": 60263371,
      "PARTS CATEGORY_1": "YTG",
      "ROQ_1": 3,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "PARTIAL WHXX",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 60358720,
      "PARTS CATEGORY": "YTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 60358720,
      "PARTS CATEGORY_1": "YTG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 61009978,
      "PARTS CATEGORY": "YTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 61009978,
      "PARTS CATEGORY_1": "YTG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 60263487,
      "PARTS CATEGORY": "YTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 60263487,
      "PARTS CATEGORY_1": "YTG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 13782568,
      "PARTS CATEGORY": "YTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 13782568,
      "PARTS CATEGORY_1": "YTG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 61005716,
      "PARTS CATEGORY": "YTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 61005716,
      "PARTS CATEGORY_1": "YTG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 22696301,
      "PARTS CATEGORY": "VTG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 22696301,
      "PARTS CATEGORY_1": "VTG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 22745698,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 22745698,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 60126917,
      "PARTS CATEGORY": "YPG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 60126917,
      "PARTS CATEGORY_1": "YPG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 60126914,
      "PARTS CATEGORY": "YPG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 60126914,
      "PARTS CATEGORY_1": "YPG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 70918479,
      "PARTS CATEGORY": "VCG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 70918479,
      "PARTS CATEGORY_1": "VCG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 1,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 43918879,
      "PARTS CATEGORY": "VCG",
      "ROQ": 2,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 43918879,
      "PARTS CATEGORY_1": "VCG",
      "ROQ_1": 2,
      "APPROVED QTY_1": 2,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14535803,
      "PARTS CATEGORY": "VOG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14535803,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 1,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14566450,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14566450,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": "115H-60-02000",
      "PARTS CATEGORY": "SDG",
      "ROQ": 5,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "115H-60-02000",
      "PARTS CATEGORY_1": "SDG",
      "ROQ_1": 5,
      "APPROVED QTY_1": 5,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 15714570,
      "PARTS CATEGORY": "VOG",
      "ROQ": 1,
      "APPROVED QTY": 1,
      "UOM": "PC",
      "PART NUMBER_1": 15714570,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": "154-27-10006V030",
      "PARTS CATEGORY": "SDG",
      "ROQ": 170,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "154-27-10006V030",
      "PARTS CATEGORY_1": "SDG",
      "ROQ_1": 170,
      "APPROVED QTY_1": 89,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "PARTIAL WH12",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 15714574,
      "PARTS CATEGORY": "VOG",
      "ROQ": 1,
      "APPROVED QTY": 1,
      "UOM": "PC",
      "PART NUMBER_1": 15714574,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 997469,
      "PARTS CATEGORY": "VOG",
      "ROQ": 1,
      "APPROVED QTY": 1,
      "UOM": "PC",
      "PART NUMBER_1": 997469,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14703817,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14703817,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 907814,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 907814,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 15711010,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 15711010,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 22935402,
      "PARTS CATEGORY": "VTG",
      "ROQ": 4,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 22935402,
      "PARTS CATEGORY_1": "VTG",
      "ROQ_1": 4,
      "APPROVED QTY_1": 2,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "PARTIAL WH12",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 22422785,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 22422785,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110003478017,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4110003478017,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14550630,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14550630,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 20514313,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 20514313,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 15048941,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 15048941,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 17212421,
      "PARTS CATEGORY": "VOG",
      "ROQ": 3,
      "APPROVED QTY": 3,
      "UOM": "PC",
      "PART NUMBER_1": 17212421,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 3,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14676340,
      "PARTS CATEGORY": "VOG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14676340,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 1,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14717289,
      "PARTS CATEGORY": "VOG",
      "ROQ": 3,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14717289,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 3,
      "APPROVED QTY_1": 3,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 29250018651,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 29250018651,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 29250001211,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 29250001211,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14573784,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14573784,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14745995,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14745995,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 61020095,
      "PARTS CATEGORY": "YCG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 61020095,
      "PARTS CATEGORY_1": "YCG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 4120006629,
      "PARTS CATEGORY": "VLG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4120006629,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 1,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": "TP612600090816",
      "PARTS CATEGORY": "SDG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "TP612600090816",
      "PARTS CATEGORY_1": "SDG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": "TP1000422382",
      "PARTS CATEGORY": "SDG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "TP1000422382",
      "PARTS CATEGORY_1": "SDG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": "TP1000964807",
      "PARTS CATEGORY": "SDG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "TP1000964807",
      "PARTS CATEGORY_1": "SDG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 1,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "CMI"
    },
    {
      "PART NUMBER": "TP1000424655",
      "PARTS CATEGORY": "SDG",
      "ROQ": 3,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "TP1000424655",
      "PARTS CATEGORY_1": "SDG",
      "ROQ_1": 3,
      "APPROVED QTY_1": 3,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "CMI"
    },
    {
      "PART NUMBER": "114H-75-08200",
      "PARTS CATEGORY": "SDG",
      "ROQ": 6,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "114H-75-08200",
      "PARTS CATEGORY_1": "SDG",
      "ROQ_1": 6,
      "APPROVED QTY_1": 6,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "CMI"
    },
    {
      "PART NUMBER": "TP1000424916",
      "PARTS CATEGORY": "SDG",
      "ROQ": 10,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "TP1000424916",
      "PARTS CATEGORY_1": "SDG",
      "ROQ_1": 10,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 23479847,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 23479847,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4130000415013,
      "PARTS CATEGORY": "VLG",
      "ROQ": 1,
      "APPROVED QTY": 1,
      "UOM": "PC",
      "PART NUMBER_1": 4130000415013,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 60113866,
      "PARTS CATEGORY": "VOG",
      "ROQ": 1,
      "APPROVED QTY": 1,
      "UOM": "PC",
      "PART NUMBER_1": 60113866,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110000991063,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4110000991063,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 5223670578,
      "PARTS CATEGORY": "NIG",
      "ROQ": 2,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 5223670578,
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 2,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 22674901,
      "PARTS CATEGORY": "VTG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 22674901,
      "PARTS CATEGORY_1": "VTG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 23236853,
      "PARTS CATEGORY": "VTG",
      "ROQ": 3,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 23236853,
      "PARTS CATEGORY_1": "VTG",
      "ROQ_1": 3,
      "APPROVED QTY_1": 3,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 84479349,
      "PARTS CATEGORY": "VTG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 84479349,
      "PARTS CATEGORY_1": "VTG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 1,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 14359170,
      "PARTS CATEGORY": "YPG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14359170,
      "PARTS CATEGORY_1": "YPG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 60110039,
      "PARTS CATEGORY": "VOG",
      "ROQ": 3,
      "APPROVED QTY": 3,
      "UOM": "PC",
      "PART NUMBER_1": 60110039,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 3,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 984752,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 984752,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 3537507,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 3537507,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 3537521,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 3537521,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 70918495,
      "PARTS CATEGORY": "VCG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 70918495,
      "PARTS CATEGORY_1": "VCG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 1,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 20799728,
      "PARTS CATEGORY": "VOG",
      "ROQ": 4,
      "APPROVED QTY": 4,
      "UOM": "PC",
      "PART NUMBER_1": 20799728,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 4,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14725651,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14725651,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 7007302,
      "PARTS CATEGORY": "DOB",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 7007302,
      "PARTS CATEGORY_1": "DOB",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": "DOT4",
      "PARTS CATEGORY": "LUP",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "DOT4",
      "PARTS CATEGORY_1": "LUP",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "LUBRICANTS"
    },
    {
      "PART NUMBER": "ADBLUE20L",
      "PARTS CATEGORY": "ZZY",
      "ROQ": 4973,
      "APPROVED QTY": 760,
      "UOM": "PC",
      "PART NUMBER_1": "ADBLUE20L",
      "PARTS CATEGORY_1": "ZZY",
      "ROQ_1": 4973,
      "APPROVED QTY_1": 4213,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12 + WHXX",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 14762190,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14762190,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 17460844,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 17460844,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 11708371,
      "PARTS CATEGORY": "LUT",
      "ROQ": 43,
      "APPROVED QTY": 40,
      "UOM": "PC",
      "PART NUMBER_1": 11708371,
      "PARTS CATEGORY_1": "LUT",
      "ROQ_1": 43,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "LUBRICANTS"
    },
    {
      "PART NUMBER": 984734,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 984734,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 5223714921,
      "PARTS CATEGORY": "NIG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 5223714921,
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 990989,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 990989,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 17533661,
      "PARTS CATEGORY": "VOG",
      "ROQ": 25,
      "APPROVED QTY": 25,
      "UOM": "PC",
      "PART NUMBER_1": 17533661,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 25,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14884680,
      "PARTS CATEGORY": "VOG",
      "ROQ": 4,
      "APPROVED QTY": 4,
      "UOM": "PC",
      "PART NUMBER_1": 14884680,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 4,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": "01700-065-0390-370-18GA",
      "PARTS CATEGORY": "EIK",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "01700-065-0390-370-18GA",
      "PARTS CATEGORY_1": "EIK",
      "ROQ_1": 1,
      "APPROVED QTY_1": 1,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC"
    },
    {
      "PART NUMBER": "037CAT315-PNR-EWT",
      "PARTS CATEGORY": "EIK",
      "ROQ": 5,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "037CAT315-PNR-EWT",
      "PARTS CATEGORY_1": "EIK",
      "ROQ_1": 5,
      "APPROVED QTY_1": 5,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12"
    },
    {
      "PART NUMBER": "037CAT315-ADP-EWT",
      "PARTS CATEGORY": "EIK",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "037CAT315-ADP-EWT",
      "PARTS CATEGORY_1": "EIK",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": ""
    },
    {
      "PART NUMBER": "037CAT315-TIP-EWT",
      "PARTS CATEGORY": "EIK",
      "ROQ": 3,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "037CAT315-TIP-EWT",
      "PARTS CATEGORY_1": "EIK",
      "ROQ_1": 3,
      "APPROVED QTY_1": 3,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12"
    },
    {
      "PART NUMBER": 17532113,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 17532113,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4190000034,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4190000034,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 21806167,
      "PARTS CATEGORY": "VTG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 21806167,
      "PARTS CATEGORY_1": "VTG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 1,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 23796624,
      "PARTS CATEGORY": "VTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 23796624,
      "PARTS CATEGORY_1": "VTG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": "160602020026A",
      "PARTS CATEGORY": "YPG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "160602020026A",
      "PARTS CATEGORY_1": "YPG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 17535679,
      "PARTS CATEGORY": "VOG",
      "ROQ": 109,
      "APPROVED QTY": 109,
      "UOM": "PC",
      "PART NUMBER_1": 17535679,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 109,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": "168899001038A",
      "PARTS CATEGORY": "YPG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "168899001038A",
      "PARTS CATEGORY_1": "YPG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": "168899001032A",
      "PARTS CATEGORY": "YPG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "168899001032A",
      "PARTS CATEGORY_1": "YPG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": "168899001037A",
      "PARTS CATEGORY": "YPG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "168899001037A",
      "PARTS CATEGORY_1": "YPG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 990973,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 990973,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 7196450,
      "PARTS CATEGORY": "DOB",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 7196450,
      "PARTS CATEGORY_1": "DOB",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 14614556,
      "PARTS CATEGORY": "VOG",
      "ROQ": 2,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14614556,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 2,
      "APPROVED QTY_1": 2,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 60335271,
      "PARTS CATEGORY": "YPG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 60335271,
      "PARTS CATEGORY_1": "YPG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 160604020018,
      "PARTS CATEGORY": "YPG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 160604020018,
      "PARTS CATEGORY_1": "YPG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 14732391,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14732391,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 17473104,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 17473104,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 17493104,
      "PARTS CATEGORY": "VOG",
      "ROQ": 2,
      "APPROVED QTY": 2,
      "UOM": "PC",
      "PART NUMBER_1": 17493104,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 2,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": "C61355",
      "PARTS CATEGORY": "SOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "C61355",
      "PARTS CATEGORY_1": "SOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 4120006629007,
      "PARTS CATEGORY": "VLG",
      "ROQ": 3,
      "APPROVED QTY": 2,
      "UOM": "PC",
      "PART NUMBER_1": 4120006629007,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 3,
      "APPROVED QTY_1": 1,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12 + WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 23807658,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 23807658,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14696775,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14696775,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4120016310001,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4120016310001,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": "A229900003075",
      "PARTS CATEGORY": "YCG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "A229900003075",
      "PARTS CATEGORY_1": "YCG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 997475,
      "PARTS CATEGORY": "VOG",
      "ROQ": 17,
      "APPROVED QTY": 17,
      "UOM": "PC",
      "PART NUMBER_1": 997475,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 17,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": "130699000043A",
      "PARTS CATEGORY": "YTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "130699000043A",
      "PARTS CATEGORY_1": "YTG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 5282685304,
      "PARTS CATEGORY": "NIG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 5282685304,
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 14689355,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14689355,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 5223237153,
      "PARTS CATEGORY": "NIG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 5223237153,
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": "006B12A-165-AAL",
      "PARTS CATEGORY": "EIK",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "006B12A-165-AAL",
      "PARTS CATEGORY_1": "EIK",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": ""
    },
    {
      "PART NUMBER": "007LN12A-AA",
      "PARTS CATEGORY": "EIK",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "007LN12A-AA",
      "PARTS CATEGORY_1": "EIK",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": ""
    },
    {
      "PART NUMBER": "006B24M-080-AAH",
      "PARTS CATEGORY": "EIK",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "006B24M-080-AAH",
      "PARTS CATEGORY_1": "EIK",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": ""
    },
    {
      "PART NUMBER": "008SW24M-AA",
      "PARTS CATEGORY": "EIK",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "008SW24M-AA",
      "PARTS CATEGORY_1": "EIK",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": ""
    },
    {
      "PART NUMBER": "007N24M-AA",
      "PARTS CATEGORY": "EIK",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "007N24M-AA",
      "PARTS CATEGORY_1": "EIK",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": ""
    },
    {
      "PART NUMBER": "037EC200BCL-SC-EWT",
      "PARTS CATEGORY": "EIK",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "037EC200BCL-SC-EWT",
      "PARTS CATEGORY_1": "EIK",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": ""
    },
    {
      "PART NUMBER": "037EC200BCR-SC-EWT",
      "PARTS CATEGORY": "EIK",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "037EC200BCR-SC-EWT",
      "PARTS CATEGORY_1": "EIK",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": ""
    },
    {
      "PART NUMBER": 14699314,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14699314,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 5223919366,
      "PARTS CATEGORY": "NIG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 5223919366,
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 46696269,
      "PARTS CATEGORY": "DOP",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 46696269,
      "PARTS CATEGORY_1": "DOP",
      "ROQ_1": 1,
      "APPROVED QTY_1": 1,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 257653,
      "PARTS CATEGORY": "VTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 257653,
      "PARTS CATEGORY_1": "VTG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 95653614,
      "PARTS CATEGORY": "VCG",
      "ROQ": 1,
      "APPROVED QTY": 1,
      "UOM": "PC",
      "PART NUMBER_1": 95653614,
      "PARTS CATEGORY_1": "VCG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 11147716,
      "PARTS CATEGORY": "VOG",
      "ROQ": 1,
      "APPROVED QTY": 1,
      "UOM": "PC",
      "PART NUMBER_1": 11147716,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 5223976027,
      "PARTS CATEGORY": "NIG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 5223976027,
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 17459093,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 17459093,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4013000157,
      "PARTS CATEGORY": "VLG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4013000157,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 1,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 15714575,
      "PARTS CATEGORY": "VOG",
      "ROQ": 1,
      "APPROVED QTY": 1,
      "UOM": "PC",
      "PART NUMBER_1": 15714575,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14713404,
      "PARTS CATEGORY": "VOH",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14713404,
      "PARTS CATEGORY_1": "VOH",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": ""
    },
    {
      "PART NUMBER": 14727790,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14727790,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 5223958455,
      "PARTS CATEGORY": "NIG",
      "ROQ": 4,
      "APPROVED QTY": 4,
      "UOM": "PC",
      "PART NUMBER_1": 5223958455,
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 4,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 14750657,
      "PARTS CATEGORY": "VOG",
      "ROQ": 21,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14750657,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 21,
      "APPROVED QTY_1": 12,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "PARTIAL WH12",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14730964,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14730964,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14771505,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14771505,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 29350010291,
      "PARTS CATEGORY": "VLG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 29350010291,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 1,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 5223714924,
      "PARTS CATEGORY": "NIG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 5223714924,
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 17515206,
      "PARTS CATEGORY": "VOG",
      "ROQ": 14,
      "APPROVED QTY": 14,
      "UOM": "PC",
      "PART NUMBER_1": 17515206,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 14,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4190002741,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4190002741,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": "160602020036A",
      "PARTS CATEGORY": "YTG",
      "ROQ": 1,
      "APPROVED QTY": 1,
      "UOM": "PC",
      "PART NUMBER_1": "160602020036A",
      "PARTS CATEGORY_1": "YTG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 61021071,
      "PARTS CATEGORY": "YTG",
      "ROQ": 3,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 61021071,
      "PARTS CATEGORY_1": "YTG",
      "ROQ_1": 3,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 61021072,
      "PARTS CATEGORY": "YTG",
      "ROQ": 3,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 61021072,
      "PARTS CATEGORY_1": "YTG",
      "ROQ_1": 3,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "CMI"
    },
    {
      "PART NUMBER": "141502000307A",
      "PARTS CATEGORY": "YTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "141502000307A",
      "PARTS CATEGORY_1": "YTG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": "160602030026A",
      "PARTS CATEGORY": "YTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "160602030026A",
      "PARTS CATEGORY_1": "YTG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 13631416,
      "PARTS CATEGORY": "YTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 13631416,
      "PARTS CATEGORY_1": "YTG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 17500251,
      "PARTS CATEGORY": "VOG",
      "ROQ": 2,
      "APPROVED QTY": 2,
      "UOM": "PC",
      "PART NUMBER_1": 17500251,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 2,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 12445554,
      "PARTS CATEGORY": "YTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 12445554,
      "PARTS CATEGORY_1": "YTG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 17500253,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 17500253,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 17500260,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 17500260,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 17500263,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 17500263,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 17526239,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 17526239,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110000970017,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4110000970017,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14539108,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14539108,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14550492,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14550492,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14741634,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14741634,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 7400454,
      "PARTS CATEGORY": "DOB",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 7400454,
      "PARTS CATEGORY_1": "DOB",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 23690622,
      "PARTS CATEGORY": "VTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 23690622,
      "PARTS CATEGORY_1": "VTG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 5223919397,
      "PARTS CATEGORY": "NIG",
      "ROQ": 3,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 5223919397,
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 3,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 23974640,
      "PARTS CATEGORY": "VOG",
      "ROQ": 10,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 23974640,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 10,
      "APPROVED QTY_1": 10,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 60270562,
      "PARTS CATEGORY": "YCG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 60270562,
      "PARTS CATEGORY_1": "YCG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 1,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 60034227,
      "PARTS CATEGORY": "YCG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 60034227,
      "PARTS CATEGORY_1": "YCG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 141502000076,
      "PARTS CATEGORY": "YTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 141502000076,
      "PARTS CATEGORY_1": "YTG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": "381669Z02E",
      "PARTS CATEGORY": "NIG",
      "ROQ": 3,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "381669Z02E",
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 3,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 7393870,
      "PARTS CATEGORY": "DOB",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 7393870,
      "PARTS CATEGORY_1": "DOB",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 5223670583,
      "PARTS CATEGORY": "NIG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 5223670583,
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 14788290,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14788290,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4120004826,
      "PARTS CATEGORY": "VLG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4120004826,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 1,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4120004899,
      "PARTS CATEGORY": "VLG",
      "ROQ": 2,
      "APPROVED QTY": 2,
      "UOM": "PC",
      "PART NUMBER_1": 4120004899,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 2,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4120005303003,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4120005303003,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14619311,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14619311,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": "542159Z01D",
      "PARTS CATEGORY": "NIG",
      "ROQ": 2,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "542159Z01D",
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 2,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 5223890468,
      "PARTS CATEGORY": "NIG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 5223890468,
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 4130000503,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4130000503,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 16678175,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 16678175,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 17271168,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 17271168,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 16658928,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 16658928,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 5223714925,
      "PARTS CATEGORY": "NIG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 5223714925,
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 14712690,
      "PARTS CATEGORY": "VOG",
      "ROQ": 1,
      "APPROVED QTY": 1,
      "UOM": "PC",
      "PART NUMBER_1": 14712690,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 23658092,
      "PARTS CATEGORY": "VTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 23658092,
      "PARTS CATEGORY_1": "VTG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 997484,
      "PARTS CATEGORY": "VOG",
      "ROQ": 2,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 997484,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 2,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110001475095,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4110001475095,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": "SSL01200",
      "PARTS CATEGORY": "SOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "SSL01200",
      "PARTS CATEGORY_1": "SOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 5223813747,
      "PARTS CATEGORY": "NIG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 5223813747,
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 5223658117,
      "PARTS CATEGORY": "NIG",
      "ROQ": 5,
      "APPROVED QTY": 5,
      "UOM": "PC",
      "PART NUMBER_1": 5223658117,
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 5,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 7322705,
      "PARTS CATEGORY": "DOB",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 7322705,
      "PARTS CATEGORY_1": "DOB",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": "LF17535",
      "PARTS CATEGORY": "CUF",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "LF17535",
      "PARTS CATEGORY_1": "CUF",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "OLD"
    },
    {
      "PART NUMBER": 15184181,
      "PARTS CATEGORY": "VOG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 15184181,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 29070015581,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 29070015581,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110001554040,
      "PARTS CATEGORY": "VLG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4110001554040,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 1,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 11445396,
      "PARTS CATEGORY": "VOG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 11445396,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 9014594707,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 9014594707,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 7408600,
      "PARTS CATEGORY": "DOB",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 7408600,
      "PARTS CATEGORY_1": "DOB",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 7408601,
      "PARTS CATEGORY": "DOB",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 7408601,
      "PARTS CATEGORY_1": "DOB",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": "SSL01118",
      "PARTS CATEGORY": "SOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "SSL01118",
      "PARTS CATEGORY_1": "SOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 5223488073,
      "PARTS CATEGORY": "NIG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 5223488073,
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": "SSL01198",
      "PARTS CATEGORY": "SOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "SSL01198",
      "PARTS CATEGORY_1": "SOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 14759225,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14759225,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14596256,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14596256,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14720505,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14720505,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14881135,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14881135,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 70921614,
      "PARTS CATEGORY": "VCG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 70921614,
      "PARTS CATEGORY_1": "VCG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 70860382,
      "PARTS CATEGORY": "VCG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 70860382,
      "PARTS CATEGORY_1": "VCG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 70871884,
      "PARTS CATEGORY": "VCG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 70871884,
      "PARTS CATEGORY_1": "VCG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 96705249,
      "PARTS CATEGORY": "VCG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 96705249,
      "PARTS CATEGORY_1": "VCG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 94327905,
      "PARTS CATEGORY": "VCG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 94327905,
      "PARTS CATEGORY_1": "VCG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 70908710,
      "PARTS CATEGORY": "VCG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 70908710,
      "PARTS CATEGORY_1": "VCG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 70908652,
      "PARTS CATEGORY": "VCG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 70908652,
      "PARTS CATEGORY_1": "VCG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 12780718,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 12780718,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 12780719,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 12780719,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 12834256,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 12834256,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 12820135,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 12820135,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 12791508,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 12791508,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110000909061,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4110000909061,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 7184231,
      "PARTS CATEGORY": "DOB",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 7184231,
      "PARTS CATEGORY_1": "DOB",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 4130000627,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4130000627,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 5224014098,
      "PARTS CATEGORY": "NIG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 5224014098,
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 5224014097,
      "PARTS CATEGORY": "NIG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 5224014097,
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 5224014100,
      "PARTS CATEGORY": "NIG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 5224014100,
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 14644257,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14644257,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14521837,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14521837,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 28120005471,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 28120005471,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110003906,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4110003906,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110004069036,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4110004069036,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4011373,
      "PARTS CATEGORY": "SOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4011373,
      "PARTS CATEGORY_1": "SOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 11220925,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 11220925,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 5223683591,
      "PARTS CATEGORY": "NIG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 5223683591,
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 5223763956,
      "PARTS CATEGORY": "NIG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 5223763956,
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 5223763958,
      "PARTS CATEGORY": "NIG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 5223763958,
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 5223891584,
      "PARTS CATEGORY": "NIG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 5223891584,
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 14638550,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14638550,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 23235621,
      "PARTS CATEGORY": "VTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 23235621,
      "PARTS CATEGORY_1": "VTG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 7135536,
      "PARTS CATEGORY": "DOB",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 7135536,
      "PARTS CATEGORY_1": "DOB",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 6568974,
      "PARTS CATEGORY": "DOB",
      "ROQ": 3,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 6568974,
      "PARTS CATEGORY_1": "DOB",
      "ROQ_1": 3,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 3974866,
      "PARTS CATEGORY": "DOB",
      "ROQ": 3,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 3974866,
      "PARTS CATEGORY_1": "DOB",
      "ROQ_1": 3,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 14747096,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14747096,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": "A820204000495",
      "PARTS CATEGORY": "YCG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "A820204000495",
      "PARTS CATEGORY_1": "YCG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": "A820301021118",
      "PARTS CATEGORY": "YCG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "A820301021118",
      "PARTS CATEGORY_1": "YCG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": "A210355000009",
      "PARTS CATEGORY": "YCG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "A210355000009",
      "PARTS CATEGORY_1": "YCG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": "C63390",
      "PARTS CATEGORY": "SOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "C63390",
      "PARTS CATEGORY_1": "SOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": "C63391",
      "PARTS CATEGORY": "SOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "C63391",
      "PARTS CATEGORY_1": "SOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": "A820201020000",
      "PARTS CATEGORY": "YCG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "A820201020000",
      "PARTS CATEGORY_1": "YCG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": "A820299001611",
      "PARTS CATEGORY": "YCG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "A820299001611",
      "PARTS CATEGORY_1": "YCG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 46549754,
      "PARTS CATEGORY": "DOP",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 46549754,
      "PARTS CATEGORY_1": "DOP",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 21897549,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 21897549,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14734221,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14734221,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14732412,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14732412,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 60159534,
      "PARTS CATEGORY": "YCG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 60159534,
      "PARTS CATEGORY_1": "YCG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 13724531,
      "PARTS CATEGORY": "YPG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 13724531,
      "PARTS CATEGORY_1": "YPG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": "A210111000018",
      "PARTS CATEGORY": "YPG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "A210111000018",
      "PARTS CATEGORY_1": "YPG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": "A210319000001",
      "PARTS CATEGORY": "YPG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "A210319000001",
      "PARTS CATEGORY_1": "YPG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 4110000054296,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4110000054296,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4130001978,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4130001978,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110001949018,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4110001949018,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14695933,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14695933,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": "487129Z01A",
      "PARTS CATEGORY": "NIG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "487129Z01A",
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 16290793,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 16290793,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14778024,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14778024,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": "261319Z01C",
      "PARTS CATEGORY": "NIG",
      "ROQ": 1,
      "APPROVED QTY": 1,
      "UOM": "PC",
      "PART NUMBER_1": "261319Z01C",
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 5223502052,
      "PARTS CATEGORY": "NIG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 5223502052,
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 11705472,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 11705472,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 43926765,
      "PARTS CATEGORY": "VCG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 43926765,
      "PARTS CATEGORY_1": "VCG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14732836,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14732836,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": "152599000172A001",
      "PARTS CATEGORY": "YTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "152599000172A001",
      "PARTS CATEGORY_1": "YTG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": "543709Z00B",
      "PARTS CATEGORY": "NIG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "543709Z00B",
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 96704580,
      "PARTS CATEGORY": "VCG",
      "ROQ": 1,
      "APPROVED QTY": 1,
      "UOM": "PC",
      "PART NUMBER_1": 96704580,
      "PARTS CATEGORY_1": "VCG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110001015128,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4110001015128,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 913111,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 913111,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 60158886,
      "PARTS CATEGORY": "YCG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 60158886,
      "PARTS CATEGORY_1": "YCG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 14740272,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14740272,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": "542159Z01C",
      "PARTS CATEGORY": "NIG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "542159Z01C",
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 4130001879,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4130001879,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 16866694,
      "PARTS CATEGORY": "VOG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 16866694,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14691364,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14691364,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14739511,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14739511,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14780714,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14780714,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": "540409Z01C",
      "PARTS CATEGORY": "NIG",
      "ROQ": 3,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "540409Z01C",
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 3,
      "APPROVED QTY_1": 3,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 4110000924182,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4110000924182,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 29030029721,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 29030029721,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 9014636301,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 9014636301,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110000076426,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4110000076426,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110001321383,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4110001321383,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 7176170,
      "PARTS CATEGORY": "DOB",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 7176170,
      "PARTS CATEGORY_1": "DOB",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": "160101080007A016",
      "PARTS CATEGORY": "YPG",
      "ROQ": 1,
      "APPROVED QTY": 1,
      "UOM": "PC",
      "PART NUMBER_1": "160101080007A016",
      "PARTS CATEGORY_1": "YPG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 4110015815005,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4110015815005,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 23044511,
      "PARTS CATEGORY": "VTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 23044511,
      "PARTS CATEGORY_1": "VTG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 4110001321009,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4110001321009,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 60110070,
      "PARTS CATEGORY": "VOG",
      "ROQ": 3,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 60110070,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 3,
      "APPROVED QTY_1": 3,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 17510496,
      "PARTS CATEGORY": "VOG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 17510496,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14619051,
      "PARTS CATEGORY": "VOG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14619051,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 15184180,
      "PARTS CATEGORY": "VOG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 15184180,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 29050023491,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 29050023491,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14530606,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14530606,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14652625,
      "PARTS CATEGORY": "VOG",
      "ROQ": 1,
      "APPROVED QTY": 1,
      "UOM": "PC",
      "PART NUMBER_1": 14652625,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": "275009Z00C",
      "PARTS CATEGORY": "NIG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "275009Z00C",
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 7291660,
      "PARTS CATEGORY": "DOB",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 7291660,
      "PARTS CATEGORY_1": "DOB",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 17535118,
      "PARTS CATEGORY": "VOG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 17535118,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 1,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14403813,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14403813,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 9020460945,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 9020460945,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110000991044,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4110000991044,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110000991041,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4110000991041,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110001009036,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4110001009036,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110001009051,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4110001009051,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110001007088,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4110001007088,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110000556271,
      "PARTS CATEGORY": "VLG",
      "ROQ": 2,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4110000556271,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 2,
      "APPROVED QTY_1": 2,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 17483046,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 17483046,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": "323089Z00A",
      "PARTS CATEGORY": "NIG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "323089Z00A",
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 14714050,
      "PARTS CATEGORY": "VOG",
      "ROQ": 5,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14714050,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 5,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 5278618571,
      "PARTS CATEGORY": "NIG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 5278618571,
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 20459181,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 20459181,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14496140,
      "PARTS CATEGORY": "YCG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14496140,
      "PARTS CATEGORY_1": "YCG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 7023149,
      "PARTS CATEGORY": "DOB",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 7023149,
      "PARTS CATEGORY_1": "DOB",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 4120000898006,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4120000898006,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4120000898014,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4120000898014,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4120000898015,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4120000898015,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4120000898001,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4120000898001,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14731779,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14731779,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14740815,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14740815,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14740816,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14740816,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110002230035,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4110002230035,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 20405902,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 20405902,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 5224009155,
      "PARTS CATEGORY": "NIG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 5224009155,
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 4110000991043,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4110000991043,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110000991045,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4110000991045,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": "TP4061007",
      "PARTS CATEGORY": "SDG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "TP4061007",
      "PARTS CATEGORY_1": "SDG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 11216211,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 11216211,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 60247900,
      "PARTS CATEGORY": "YPG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 60247900,
      "PARTS CATEGORY_1": "YPG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 11052508,
      "PARTS CATEGORY": "YCG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 11052508,
      "PARTS CATEGORY_1": "YCG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": "A820301021095",
      "PARTS CATEGORY": "YCG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "A820301021095",
      "PARTS CATEGORY_1": "YCG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": "A820301021100",
      "PARTS CATEGORY": "YCG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "A820301021100",
      "PARTS CATEGORY_1": "YCG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 23935622,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 23935622,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 6515541,
      "PARTS CATEGORY": "DOB",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 6515541,
      "PARTS CATEGORY_1": "DOB",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 6563995,
      "PARTS CATEGORY": "DOB",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 6563995,
      "PARTS CATEGORY_1": "DOB",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 3885315,
      "PARTS CATEGORY": "DOB",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 3885315,
      "PARTS CATEGORY_1": "DOB",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 84474457,
      "PARTS CATEGORY": "VTG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 84474457,
      "PARTS CATEGORY_1": "VTG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 1,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 84474434,
      "PARTS CATEGORY": "VTG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 84474434,
      "PARTS CATEGORY_1": "VTG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 1,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": "SSC31211L0N",
      "PARTS CATEGORY": "SOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "SSC31211L0N",
      "PARTS CATEGORY_1": "SOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 14558098,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14558098,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14558095,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14558095,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110001015046,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4110001015046,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 60110101,
      "PARTS CATEGORY": "VOG",
      "ROQ": 1,
      "APPROVED QTY": 1,
      "UOM": "PC",
      "PART NUMBER_1": 60110101,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 17461758,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 17461758,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 7030400,
      "PARTS CATEGORY": "DOB",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 7030400,
      "PARTS CATEGORY_1": "DOB",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 14543309,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14543309,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": "130288000679A",
      "PARTS CATEGORY": "YTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "130288000679A",
      "PARTS CATEGORY_1": "YTG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": "1132-00010",
      "PARTS CATEGORY": "VSG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "1132-00010",
      "PARTS CATEGORY_1": "VSG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 11220517,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 11220517,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 5223567814,
      "PARTS CATEGORY": "NIG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 5223567814,
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 4120006628,
      "PARTS CATEGORY": "VLG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4120006628,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 1,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14747393,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14747393,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14747394,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14747394,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14748398,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14748398,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14748204,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14748204,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": "C13196",
      "PARTS CATEGORY": "SOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "C13196",
      "PARTS CATEGORY_1": "SOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 23251714,
      "PARTS CATEGORY": "VTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 23251714,
      "PARTS CATEGORY_1": "VTG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 2050900006,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 2050900006,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 9014640102,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 9014640102,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 16015376,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 16015376,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 84479333,
      "PARTS CATEGORY": "VTG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 84479333,
      "PARTS CATEGORY_1": "VTG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 1,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 5224202071,
      "PARTS CATEGORY": "NIG",
      "ROQ": 3,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 5224202071,
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 3,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 11709361,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 11709361,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 11211150,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 11211150,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 11211149,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 11211149,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14408713,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14408713,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 11218232,
      "PARTS CATEGORY": "VLG",
      "ROQ": 3,
      "APPROVED QTY": 3,
      "UOM": "PC",
      "PART NUMBER_1": 11218232,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 3,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 54116585,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 54116585,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4000015,
      "PARTS CATEGORY": "JLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4000015,
      "PARTS CATEGORY_1": "JLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 4120016961,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4120016961,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 937879,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 937879,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 15142021,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 15142021,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 11388082,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 11388082,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 22696301,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 22696301,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 11217578,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 11217578,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": "A229900003567",
      "PARTS CATEGORY": "YCG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "A229900003567",
      "PARTS CATEGORY_1": "YCG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 7340039,
      "PARTS CATEGORY": "DOB",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 7340039,
      "PARTS CATEGORY_1": "DOB",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 7276760,
      "PARTS CATEGORY": "DOB",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 7276760,
      "PARTS CATEGORY_1": "DOB",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 6673188,
      "PARTS CATEGORY": "DOB",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 6673188,
      "PARTS CATEGORY_1": "DOB",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 24063494,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 24063494,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 23417546,
      "PARTS CATEGORY": "VTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 23417546,
      "PARTS CATEGORY_1": "VTG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 12945110,
      "PARTS CATEGORY": "YCG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 12945110,
      "PARTS CATEGORY_1": "YCG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": "TP1000424655A",
      "PARTS CATEGORY": "SDG",
      "ROQ": 18,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "TP1000424655A",
      "PARTS CATEGORY_1": "SDG",
      "ROQ_1": 18,
      "APPROVED QTY_1": 18,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 4120003852001,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4120003852001,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 15099562,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 15099562,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 17459094,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 17459094,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14746778,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14746778,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": "TP217638",
      "PARTS CATEGORY": "SDG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "TP217638",
      "PARTS CATEGORY_1": "SDG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": "01700-070-0440-420-20GA",
      "PARTS CATEGORY": "EIK",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "01700-070-0440-420-20GA",
      "PARTS CATEGORY_1": "EIK",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": ""
    },
    {
      "PART NUMBER": "02536624-7-125",
      "PARTS CATEGORY": "EIK",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "02536624-7-125",
      "PARTS CATEGORY_1": "EIK",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": ""
    },
    {
      "PART NUMBER": "006B12A-140-AAL",
      "PARTS CATEGORY": "EIK",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "006B12A-140-AAL",
      "PARTS CATEGORY_1": "EIK",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": ""
    },
    {
      "PART NUMBER": "01700-070-0400-375-20GA",
      "PARTS CATEGORY": "EIK",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "01700-070-0400-375-20GA",
      "PARTS CATEGORY_1": "EIK",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": ""
    },
    {
      "PART NUMBER": "01700-080-0400-375-20GA",
      "PARTS CATEGORY": "EIK",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "01700-080-0400-375-20GA",
      "PARTS CATEGORY_1": "EIK",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": ""
    },
    {
      "PART NUMBER": "025080-095-05",
      "PARTS CATEGORY": "EIK",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "025080-095-05",
      "PARTS CATEGORY_1": "EIK",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": ""
    },
    {
      "PART NUMBER": "011080-095-080-LA",
      "PARTS CATEGORY": "EIK",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "011080-095-080-LA",
      "PARTS CATEGORY_1": "EIK",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": ""
    },
    {
      "PART NUMBER": "011070-085-070-LA",
      "PARTS CATEGORY": "EIK",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "011070-085-070-LA",
      "PARTS CATEGORY_1": "EIK",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": ""
    },
    {
      "PART NUMBER": "025070-085-05",
      "PARTS CATEGORY": "EIK",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "025070-085-05",
      "PARTS CATEGORY_1": "EIK",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": ""
    },
    {
      "PART NUMBER": 11383470,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 11383470,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": "275429Z00A",
      "PARTS CATEGORY": "NIG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "275429Z00A",
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": "154-30-25111",
      "PARTS CATEGORY": "SDG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "154-30-25111",
      "PARTS CATEGORY_1": "SDG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 4130002911007,
      "PARTS CATEGORY": "VLG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4130002911007,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 1,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4130002911008,
      "PARTS CATEGORY": "VLG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4130002911008,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 1,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14794925,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14794925,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 16812522,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 16812522,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 13057289,
      "PARTS CATEGORY": "YPG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 13057289,
      "PARTS CATEGORY_1": "YPG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 12173327,
      "PARTS CATEGORY": "YPG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 12173327,
      "PARTS CATEGORY_1": "YPG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 14741183,
      "PARTS CATEGORY": "VOH",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14741183,
      "PARTS CATEGORY_1": "VOH",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": ""
    },
    {
      "PART NUMBER": "A210405000014",
      "PARTS CATEGORY": "YCG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "A210405000014",
      "PARTS CATEGORY_1": "YCG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": "A820201010000",
      "PARTS CATEGORY": "YCG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "A820201010000",
      "PARTS CATEGORY_1": "YCG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 14630450,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14630450,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 96747001,
      "PARTS CATEGORY": "VCG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 96747001,
      "PARTS CATEGORY_1": "VCG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110000924141,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4110000924141,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110001015042,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4110001015042,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 15060436,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 15060436,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 15015618,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 15015618,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 54982898,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 54982898,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 9014794925,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 9014794925,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 16880355,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 16880355,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 46830351,
      "PARTS CATEGORY": "DOB",
      "ROQ": 9,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 46830351,
      "PARTS CATEGORY_1": "DOB",
      "ROQ_1": 9,
      "APPROVED QTY_1": 8,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "PARTIAL WH12",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 46830389,
      "PARTS CATEGORY": "DOP",
      "ROQ": 5,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 46830389,
      "PARTS CATEGORY_1": "DOP",
      "ROQ_1": 5,
      "APPROVED QTY_1": 5,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 11712379,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 11712379,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 5223994853,
      "PARTS CATEGORY": "NIG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 5223994853,
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 14648462,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14648462,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14648461,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14648461,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14648463,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14648463,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14648464,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14648464,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14648465,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14648465,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14648466,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14648466,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14648467,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14648467,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14648468,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14648468,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 5223714918,
      "PARTS CATEGORY": "NIG",
      "ROQ": 3,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 5223714918,
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 3,
      "APPROVED QTY_1": 1,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "PARTIAL WH12",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": "160602010092A001",
      "PARTS CATEGORY": "YPG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "160602010092A001",
      "PARTS CATEGORY_1": "YPG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 1,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 58848771,
      "PARTS CATEGORY": "VCG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 58848771,
      "PARTS CATEGORY_1": "VCG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 59617084,
      "PARTS CATEGORY": "VCG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 59617084,
      "PARTS CATEGORY_1": "VCG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 54765326,
      "PARTS CATEGORY": "VCG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 54765326,
      "PARTS CATEGORY_1": "VCG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 58943648,
      "PARTS CATEGORY": "VCG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 58943648,
      "PARTS CATEGORY_1": "VCG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110002531014,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4110002531014,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 29220014991,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 29220014991,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14400991,
      "PARTS CATEGORY": "VLG",
      "ROQ": 11,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14400991,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 11,
      "APPROVED QTY_1": 11,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110002196013,
      "PARTS CATEGORY": "VLG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4110002196013,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 1,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 11216209,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 11216209,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14521398,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14521398,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 11210768,
      "PARTS CATEGORY": "VLG",
      "ROQ": 6,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 11210768,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 6,
      "APPROVED QTY_1": 6,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 11210753,
      "PARTS CATEGORY": "VLG",
      "ROQ": 6,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 11210753,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 6,
      "APPROVED QTY_1": 6,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 11223871,
      "PARTS CATEGORY": "VLG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 11223871,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 1,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4190003801,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4190003801,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4130015120001,
      "PARTS CATEGORY": "VLG",
      "ROQ": 4,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4130015120001,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 4,
      "APPROVED QTY_1": 4,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": "115H-58-00002",
      "PARTS CATEGORY": "SDG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "115H-58-00002",
      "PARTS CATEGORY_1": "SDG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "CMI"
    },
    {
      "PART NUMBER": "TP612600062230",
      "PARTS CATEGORY": "SDG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "TP612600062230",
      "PARTS CATEGORY_1": "SDG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 11095146,
      "PARTS CATEGORY": "YCG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 11095146,
      "PARTS CATEGORY_1": "YCG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 10024463,
      "PARTS CATEGORY": "YCG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 10024463,
      "PARTS CATEGORY_1": "YCG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 14778022,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14778022,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 10136121,
      "PARTS CATEGORY": "YCG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 10136121,
      "PARTS CATEGORY_1": "YCG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 7232486,
      "PARTS CATEGORY": "DOB",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 7232486,
      "PARTS CATEGORY_1": "DOB",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 14698883,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14698883,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 29030019421,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 29030019421,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110002795,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4110002795,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 29030025821,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 29030025821,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 5223804500,
      "PARTS CATEGORY": "NIG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 5223804500,
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 14759247,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14759247,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 11831591,
      "PARTS CATEGORY": "YTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 11831591,
      "PARTS CATEGORY_1": "YTG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 11934802,
      "PARTS CATEGORY": "YTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 11934802,
      "PARTS CATEGORY_1": "YTG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": "160603010047A001 ",
      "PARTS CATEGORY": "YTG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "160603010047A001 ",
      "PARTS CATEGORY_1": "YTG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 1,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 23974646,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 23974646,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 28280004922,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 28280004922,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 1001099717,
      "PARTS CATEGORY": "JLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 1001099717,
      "PARTS CATEGORY_1": "JLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 3050900009,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 3050900009,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 5222834631,
      "PARTS CATEGORY": "NIG",
      "ROQ": 5,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 5222834631,
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 5,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 14881104,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14881104,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4130002904108,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4130002904108,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4130002904107,
      "PARTS CATEGORY": "VLG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4130002904107,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 1,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4130002904104,
      "PARTS CATEGORY": "VLG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4130002904104,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 1,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 11711989,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 11711989,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 54315408,
      "PARTS CATEGORY": "VOG",
      "ROQ": 31,
      "APPROVED QTY": 31,
      "UOM": "PC",
      "PART NUMBER_1": 54315408,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 31,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 13625105,
      "PARTS CATEGORY": "YTG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 13625105,
      "PARTS CATEGORY_1": "YTG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 6672427,
      "PARTS CATEGORY": "DOB",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 6672427,
      "PARTS CATEGORY_1": "DOB",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 4110001007128,
      "PARTS CATEGORY": "VLG",
      "ROQ": 2,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4110001007128,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 2,
      "APPROVED QTY_1": 2,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 60251057,
      "PARTS CATEGORY": "YPG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 60251057,
      "PARTS CATEGORY_1": "YPG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": "156-40-01000V010-01",
      "PARTS CATEGORY": "SDG",
      "ROQ": 3,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "156-40-01000V010-01",
      "PARTS CATEGORY_1": "SDG",
      "ROQ_1": 3,
      "APPROVED QTY_1": 3,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "CMI"
    },
    {
      "PART NUMBER": "115H-40-01000-01",
      "PARTS CATEGORY": "SDG",
      "ROQ": 5,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "115H-40-01000-01",
      "PARTS CATEGORY_1": "SDG",
      "ROQ_1": 5,
      "APPROVED QTY_1": 5,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 4120004568,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4120004568,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14031386,
      "PARTS CATEGORY": "YPG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14031386,
      "PARTS CATEGORY_1": "YPG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": "156-40-02000-01",
      "PARTS CATEGORY": "SDG",
      "ROQ": 2,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "156-40-02000-01",
      "PARTS CATEGORY_1": "SDG",
      "ROQ_1": 2,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "CMI"
    },
    {
      "PART NUMBER": "156-40-02003",
      "PARTS CATEGORY": "SDG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "156-40-02003",
      "PARTS CATEGORY_1": "SDG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "CMI"
    },
    {
      "PART NUMBER": "115H-40-00003",
      "PARTS CATEGORY": "SDG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "115H-40-00003",
      "PARTS CATEGORY_1": "SDG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "CMI"
    },
    {
      "PART NUMBER": "115H-40-00002",
      "PARTS CATEGORY": "SDG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "115H-40-00002",
      "PARTS CATEGORY_1": "SDG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "CMI"
    },
    {
      "PART NUMBER": "156-40-00005",
      "PARTS CATEGORY": "SDG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "156-40-00005",
      "PARTS CATEGORY_1": "SDG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "CMI"
    },
    {
      "PART NUMBER": "37C620",
      "PARTS CATEGORY": "DOB",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "37C620",
      "PARTS CATEGORY_1": "DOB",
      "ROQ_1": 1,
      "APPROVED QTY_1": 1,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 5224332262,
      "PARTS CATEGORY": "NIG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 5224332262,
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 14544980,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14544980,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 11819035,
      "PARTS CATEGORY": "YPG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 11819035,
      "PARTS CATEGORY_1": "YPG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 60120467,
      "PARTS CATEGORY": "YPG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 60120467,
      "PARTS CATEGORY_1": "YPG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 13265068,
      "PARTS CATEGORY": "YPG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 13265068,
      "PARTS CATEGORY_1": "YPG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": "A220400000005",
      "PARTS CATEGORY": "YPG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "A220400000005",
      "PARTS CATEGORY_1": "YPG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 14642497,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14642497,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 15033174,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 15033174,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": "130201000125A001",
      "PARTS CATEGORY": "YPG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "130201000125A001",
      "PARTS CATEGORY_1": "YPG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": "TP3040292",
      "PARTS CATEGORY": "SDG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "TP3040292",
      "PARTS CATEGORY_1": "SDG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 1,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 54864253,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 54864253,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 29050033351,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 29050033351,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 5223994830,
      "PARTS CATEGORY": "NIG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 5223994830,
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": "156-82-00001",
      "PARTS CATEGORY": "SDG",
      "ROQ": 8,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "156-82-00001",
      "PARTS CATEGORY_1": "SDG",
      "ROQ_1": 8,
      "APPROVED QTY_1": 7,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "PARTIAL WH12",
      "BU": "CMI"
    },
    {
      "PART NUMBER": "115H-03B-01000",
      "PARTS CATEGORY": "SDG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "115H-03B-01000",
      "PARTS CATEGORY_1": "SDG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": "114H-58D-09000",
      "PARTS CATEGORY": "SDG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "114H-58D-09000",
      "PARTS CATEGORY_1": "SDG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": "114H-58D-06000",
      "PARTS CATEGORY": "SDG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "114H-58D-06000",
      "PARTS CATEGORY_1": "SDG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": "113K-58-01000",
      "PARTS CATEGORY": "SDG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "113K-58-01000",
      "PARTS CATEGORY_1": "SDG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": "15B-63A-03000P020",
      "PARTS CATEGORY": "SDG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "15B-63A-03000P020",
      "PARTS CATEGORY_1": "SDG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "CMI"
    },
    {
      "PART NUMBER": "15B-62A-01000P020",
      "PARTS CATEGORY": "SDG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "15B-62A-01000P020",
      "PARTS CATEGORY_1": "SDG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 964832,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 964832,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14599866,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14599866,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14376014,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14376014,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14376013,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14376013,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 60153144,
      "PARTS CATEGORY": "YPG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 60153144,
      "PARTS CATEGORY_1": "YPG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 4110003496001,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4110003496001,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110003496002,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4110003496002,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 28360003211,
      "PARTS CATEGORY": "VLG",
      "ROQ": 3,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 28360003211,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 3,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14615925,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14615925,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 6707101,
      "PARTS CATEGORY": "DOB",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 6707101,
      "PARTS CATEGORY_1": "DOB",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": "7D4",
      "PARTS CATEGORY": "DOB",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "7D4",
      "PARTS CATEGORY_1": "DOB",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 6737524,
      "PARTS CATEGORY": "DOB",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 6737524,
      "PARTS CATEGORY_1": "DOB",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 1001151975,
      "PARTS CATEGORY": "JLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 1001151975,
      "PARTS CATEGORY_1": "JLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 4110003380090,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4110003380090,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 5224202065,
      "PARTS CATEGORY": "NIG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 5224202065,
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 14703194,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14703194,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14616918,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14616918,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14573769,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14573769,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14573768,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14573768,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14619871,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14619871,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14573762,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14573762,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14573763,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14573763,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14573764,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14573764,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14573765,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14573765,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14573772,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14573772,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14573773,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14573773,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14573774,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14573774,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14573775,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14573775,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14573776,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14573776,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14573777,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14573777,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14616920,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14616920,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14573779,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14573779,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14573780,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14573780,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14616921,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14616921,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14718303,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14718303,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4120007618002,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4120007618002,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 5223488082,
      "PARTS CATEGORY": "NIG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 5223488082,
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 7210676,
      "PARTS CATEGORY": "DOB",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 7210676,
      "PARTS CATEGORY_1": "DOB",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": "Q15003",
      "PARTS CATEGORY": "SOG",
      "ROQ": 33,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "Q15003",
      "PARTS CATEGORY_1": "SOG",
      "ROQ_1": 33,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "CMI"
    },
    {
      "PART NUMBER": "TP612600062227",
      "PARTS CATEGORY": "SDG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "TP612600062227",
      "PARTS CATEGORY_1": "SDG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": "07016-00908",
      "PARTS CATEGORY": "SDG",
      "ROQ": 4,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "07016-00908",
      "PARTS CATEGORY_1": "SDG",
      "ROQ_1": 4,
      "APPROVED QTY_1": 4,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 15184179,
      "PARTS CATEGORY": "VOG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 15184179,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 29090007651,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 29090007651,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 7311865,
      "PARTS CATEGORY": "DOB",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 7311865,
      "PARTS CATEGORY_1": "DOB",
      "ROQ_1": 1,
      "APPROVED QTY_1": 1,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 21262793,
      "PARTS CATEGORY": "VTG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 21262793,
      "PARTS CATEGORY_1": "VTG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 20818765,
      "PARTS CATEGORY": "VTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 20818765,
      "PARTS CATEGORY_1": "VTG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 60110519,
      "PARTS CATEGORY": "YCG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 60110519,
      "PARTS CATEGORY_1": "YCG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 14708138,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14708138,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14672988,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14672988,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 7166172,
      "PARTS CATEGORY": "DOB",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 7166172,
      "PARTS CATEGORY_1": "DOB",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 7166173,
      "PARTS CATEGORY": "DOB",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 7166173,
      "PARTS CATEGORY_1": "DOB",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 7023150,
      "PARTS CATEGORY": "DOB",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 7023150,
      "PARTS CATEGORY_1": "DOB",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 14523887,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14523887,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": "FDJ007138164",
      "PARTS CATEGORY": "YPG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "FDJ007138164",
      "PARTS CATEGORY_1": "YPG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 24046345,
      "PARTS CATEGORY": "VOG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 24046345,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 1,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 5224222120,
      "PARTS CATEGORY": "NIG",
      "ROQ": 4,
      "APPROVED QTY": 4,
      "UOM": "PC",
      "PART NUMBER_1": 5224222120,
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 4,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 12794457,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 12794457,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": "TP612630060836",
      "PARTS CATEGORY": "SDG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "TP612630060836",
      "PARTS CATEGORY_1": "SDG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 1,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "CMI"
    },
    {
      "PART NUMBER": "TP612600040702",
      "PARTS CATEGORY": "SDG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "TP612600040702",
      "PARTS CATEGORY_1": "SDG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 1,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 20410139,
      "PARTS CATEGORY": "VTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 20410139,
      "PARTS CATEGORY_1": "VTG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 14574768,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14574768,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 612600062371,
      "PARTS CATEGORY": "ZZZ",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 612600062371,
      "PARTS CATEGORY_1": "ZZZ",
      "ROQ_1": 1,
      "APPROVED QTY_1": 1,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 4110001908005,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4110001908005,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 13708877,
      "PARTS CATEGORY": "YPG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 13708877,
      "PARTS CATEGORY_1": "YPG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 13710231,
      "PARTS CATEGORY": "YPG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 13710231,
      "PARTS CATEGORY_1": "YPG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 13710232,
      "PARTS CATEGORY": "YPG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 13710232,
      "PARTS CATEGORY_1": "YPG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 13710230,
      "PARTS CATEGORY": "YPG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 13710230,
      "PARTS CATEGORY_1": "YPG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 10067631,
      "PARTS CATEGORY": "YPG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 10067631,
      "PARTS CATEGORY_1": "YPG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 10088216,
      "PARTS CATEGORY": "YPG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 10088216,
      "PARTS CATEGORY_1": "YPG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": "A229900001360",
      "PARTS CATEGORY": "YPG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "A229900001360",
      "PARTS CATEGORY_1": "YPG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 11819349,
      "PARTS CATEGORY": "YPG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 11819349,
      "PARTS CATEGORY_1": "YPG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 12173328,
      "PARTS CATEGORY": "YPG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 12173328,
      "PARTS CATEGORY_1": "YPG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 12648540,
      "PARTS CATEGORY": "YPG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 12648540,
      "PARTS CATEGORY_1": "YPG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": "A210306000011",
      "PARTS CATEGORY": "YPG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "A210306000011",
      "PARTS CATEGORY_1": "YPG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": "A229900003218",
      "PARTS CATEGORY": "YPG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "A229900003218",
      "PARTS CATEGORY_1": "YPG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": "A229900005597",
      "PARTS CATEGORY": "YPG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "A229900005597",
      "PARTS CATEGORY_1": "YPG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": "A239900000410",
      "PARTS CATEGORY": "YPG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "A239900000410",
      "PARTS CATEGORY_1": "YPG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 5223228934,
      "PARTS CATEGORY": "NIG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 5223228934,
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": "01643-30823",
      "PARTS CATEGORY": "SDG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "01643-30823",
      "PARTS CATEGORY_1": "SDG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 1,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "CMI"
    },
    {
      "PART NUMBER": "130201000042A003",
      "PARTS CATEGORY": "YTG",
      "ROQ": 2,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "130201000042A003",
      "PARTS CATEGORY_1": "YTG",
      "ROQ_1": 2,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 14685160,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14685160,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110001908,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4110001908,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14622367,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14622367,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14622369,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14622369,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14640075,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14640075,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": "9325-10045",
      "PARTS CATEGORY": "VSG",
      "ROQ": 3,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "9325-10045",
      "PARTS CATEGORY_1": "VSG",
      "ROQ_1": 3,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14532411,
      "PARTS CATEGORY": "VOG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14532411,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14532307,
      "PARTS CATEGORY": "VOG",
      "ROQ": 3,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14532307,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 3,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": "329569Z00B",
      "PARTS CATEGORY": "NIG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "329569Z00B",
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": "8216-MG-411561-01",
      "PARTS CATEGORY": "SDG",
      "ROQ": 3,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "8216-MG-411561-01",
      "PARTS CATEGORY_1": "SDG",
      "ROQ_1": 3,
      "APPROVED QTY_1": 3,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 14388290,
      "PARTS CATEGORY": "VOG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14388290,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14640071,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14640071,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14566371,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14566371,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14554917,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14554917,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": "C63211",
      "PARTS CATEGORY": "SOG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "C63211",
      "PARTS CATEGORY_1": "SOG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 60185935,
      "PARTS CATEGORY": "YCG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 60185935,
      "PARTS CATEGORY_1": "YCG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 1,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 612600062230,
      "PARTS CATEGORY": "ZZZ",
      "ROQ": 3,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 612600062230,
      "PARTS CATEGORY_1": "ZZZ",
      "ROQ_1": 3,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 14550599,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14550599,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": "SSC61267",
      "PARTS CATEGORY": "SOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "SSC61267",
      "PARTS CATEGORY_1": "SOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": "C71113",
      "PARTS CATEGORY": "SOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "C71113",
      "PARTS CATEGORY_1": "SOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 940337,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 940337,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 11039713,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 11039713,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14587774,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14587774,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 977973,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 977973,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 24076067,
      "PARTS CATEGORY": "VTG",
      "ROQ": 3,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 24076067,
      "PARTS CATEGORY_1": "VTG",
      "ROQ_1": 3,
      "APPROVED QTY_1": 3,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 784112,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 784112,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 16352431,
      "PARTS CATEGORY": "VOG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 16352431,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 60112995,
      "PARTS CATEGORY": "VOG",
      "ROQ": 2,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 60112995,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 2,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4043003091,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4043003091,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4190001196,
      "PARTS CATEGORY": "VLG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4190001196,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4043000395,
      "PARTS CATEGORY": "VLG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4043000395,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 26170013901,
      "PARTS CATEGORY": "VLG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 26170013901,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 17391236,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 17391236,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 53032790,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 53032790,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 46827785,
      "PARTS CATEGORY": "DOP",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 46827785,
      "PARTS CATEGORY_1": "DOP",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": "156-40-16000",
      "PARTS CATEGORY": "SDG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "156-40-16000",
      "PARTS CATEGORY_1": "SDG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "CMI"
    },
    {
      "PART NUMBER": "156-40-15000",
      "PARTS CATEGORY": "SDG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "156-40-15000",
      "PARTS CATEGORY_1": "SDG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "CMI"
    },
    {
      "PART NUMBER": "115H-31-00002",
      "PARTS CATEGORY": "SDG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "115H-31-00002",
      "PARTS CATEGORY_1": "SDG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "CMI"
    },
    {
      "PART NUMBER": "07177-09040",
      "PARTS CATEGORY": "SDG",
      "ROQ": 6,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "07177-09040",
      "PARTS CATEGORY_1": "SDG",
      "ROQ_1": 6,
      "APPROVED QTY_1": 6,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "CMI"
    },
    {
      "PART NUMBER": "156-40-00004",
      "PARTS CATEGORY": "SDG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "156-40-00004",
      "PARTS CATEGORY_1": "SDG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 22771624,
      "PARTS CATEGORY": "VTG",
      "ROQ": 2,
      "APPROVED QTY": 1,
      "UOM": "PC",
      "PART NUMBER_1": 22771624,
      "PARTS CATEGORY_1": "VTG",
      "ROQ_1": 2,
      "APPROVED QTY_1": 1,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12 + WHXX",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 16668459,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 16668459,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": "115H-18-A0001V020-CD",
      "PARTS CATEGORY": "SDG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "115H-18-A0001V020-CD",
      "PARTS CATEGORY_1": "SDG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": "115H-18-A0002V020-CD",
      "PARTS CATEGORY": "SDG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "115H-18-A0002V020-CD",
      "PARTS CATEGORY_1": "SDG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 54864268,
      "PARTS CATEGORY": "VOG",
      "ROQ": 1,
      "APPROVED QTY": 1,
      "UOM": "PC",
      "PART NUMBER_1": 54864268,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 937483,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 937483,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 15800781,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 15800781,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 17386372,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 17386372,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 16879262,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 16879262,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 16848496,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 16848496,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 7161979,
      "PARTS CATEGORY": "DOB",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 7161979,
      "PARTS CATEGORY_1": "DOB",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 7153379,
      "PARTS CATEGORY": "DOB",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 7153379,
      "PARTS CATEGORY_1": "DOB",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 7145147,
      "PARTS CATEGORY": "DOB",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 7145147,
      "PARTS CATEGORY_1": "DOB",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 938450,
      "PARTS CATEGORY": "VOH",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 938450,
      "PARTS CATEGORY_1": "VOH",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": ""
    },
    {
      "PART NUMBER": 14713405,
      "PARTS CATEGORY": "VOH",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14713405,
      "PARTS CATEGORY_1": "VOH",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": ""
    },
    {
      "PART NUMBER": 20459925,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 20459925,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 60273569,
      "PARTS CATEGORY": "YPG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 60273569,
      "PARTS CATEGORY_1": "YPG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 17201959,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 17201959,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 23658635,
      "PARTS CATEGORY": "VTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 23658635,
      "PARTS CATEGORY_1": "VTG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 24318489,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 24318489,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110015996,
      "PARTS CATEGORY": "VLG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4110015996,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 1,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 60222779,
      "PARTS CATEGORY": "YCG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 60222779,
      "PARTS CATEGORY_1": "YCG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 14633611,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14633611,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": "175-30-24131",
      "PARTS CATEGORY": "SDG",
      "ROQ": 5,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "175-30-24131",
      "PARTS CATEGORY_1": "SDG",
      "ROQ_1": 5,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "CMI"
    },
    {
      "PART NUMBER": "156-31-02000",
      "PARTS CATEGORY": "SDG",
      "ROQ": 3,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "156-31-02000",
      "PARTS CATEGORY_1": "SDG",
      "ROQ_1": 3,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "CMI"
    },
    {
      "PART NUMBER": "GB9163-GE60ES",
      "PARTS CATEGORY": "SDG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "GB9163-GE60ES",
      "PARTS CATEGORY_1": "SDG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "CMI"
    },
    {
      "PART NUMBER": "04065-09030",
      "PARTS CATEGORY": "SDG",
      "ROQ": 3,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "04065-09030",
      "PARTS CATEGORY_1": "SDG",
      "ROQ_1": 3,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "CMI"
    },
    {
      "PART NUMBER": "156-31-00002",
      "PARTS CATEGORY": "SDG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "156-31-00002",
      "PARTS CATEGORY_1": "SDG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 7171222,
      "PARTS CATEGORY": "DOB",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 7171222,
      "PARTS CATEGORY_1": "DOB",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 7223425,
      "PARTS CATEGORY": "DOB",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 7223425,
      "PARTS CATEGORY_1": "DOB",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 7143971,
      "PARTS CATEGORY": "DOB",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 7143971,
      "PARTS CATEGORY_1": "DOB",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 7329955,
      "PARTS CATEGORY": "DOB",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 7329955,
      "PARTS CATEGORY_1": "DOB",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "CMI"
    },
    {
      "PART NUMBER": "10C616",
      "PARTS CATEGORY": "DOB",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "10C616",
      "PARTS CATEGORY_1": "DOB",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "CMI"
    },
    {
      "PART NUMBER": "111379Z01D",
      "PARTS CATEGORY": "NIG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "111379Z01D",
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": "E93446",
      "PARTS CATEGORY": "SOG",
      "ROQ": 2,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "E93446",
      "PARTS CATEGORY_1": "SOG",
      "ROQ_1": 2,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "CMI"
    },
    {
      "PART NUMBER": "E93447",
      "PARTS CATEGORY": "SOG",
      "ROQ": 2,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "E93447",
      "PARTS CATEGORY_1": "SOG",
      "ROQ_1": 2,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 12242300,
      "PARTS CATEGORY": "YTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 12242300,
      "PARTS CATEGORY_1": "YTG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 5223822029,
      "PARTS CATEGORY": "NIG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 5223822029,
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 5223822030,
      "PARTS CATEGORY": "NIG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 5223822030,
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 14506388,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14506388,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 21671171,
      "PARTS CATEGORY": "VTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 21671171,
      "PARTS CATEGORY_1": "VTG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 23468467,
      "PARTS CATEGORY": "VTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 23468467,
      "PARTS CATEGORY_1": "VTG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 14733931,
      "PARTS CATEGORY": "VOG",
      "ROQ": 2,
      "APPROVED QTY": 2,
      "UOM": "PC",
      "PART NUMBER_1": 14733931,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 2,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14733932,
      "PARTS CATEGORY": "VOG",
      "ROQ": 2,
      "APPROVED QTY": 2,
      "UOM": "PC",
      "PART NUMBER_1": 14733932,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 2,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4120004030,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4120004030,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 5222748121,
      "PARTS CATEGORY": "NIG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 5222748121,
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 7496373,
      "PARTS CATEGORY": "DOB",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 7496373,
      "PARTS CATEGORY_1": "DOB",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 7163421,
      "PARTS CATEGORY": "DOB",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 7163421,
      "PARTS CATEGORY_1": "DOB",
      "ROQ_1": 1,
      "APPROVED QTY_1": 1,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 24159417,
      "PARTS CATEGORY": "VTG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 24159417,
      "PARTS CATEGORY_1": "VTG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 1,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 7312863,
      "PARTS CATEGORY": "DOB",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 7312863,
      "PARTS CATEGORY_1": "DOB",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 7281000,
      "PARTS CATEGORY": "DOB",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 7281000,
      "PARTS CATEGORY_1": "DOB",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 7007303,
      "PARTS CATEGORY": "DOB",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 7007303,
      "PARTS CATEGORY_1": "DOB",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 7004806,
      "PARTS CATEGORY": "DOB",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 7004806,
      "PARTS CATEGORY_1": "DOB",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 7004916,
      "PARTS CATEGORY": "DOB",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 7004916,
      "PARTS CATEGORY_1": "DOB",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 7006812,
      "PARTS CATEGORY": "DOB",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 7006812,
      "PARTS CATEGORY_1": "DOB",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 4190000037,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4190000037,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 983521,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 983521,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 24159415,
      "PARTS CATEGORY": "VTG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 24159415,
      "PARTS CATEGORY_1": "VTG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 1,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 12766934,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 12766934,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": "542159Z01E",
      "PARTS CATEGORY": "NIG",
      "ROQ": 4,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "542159Z01E",
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 4,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WHXX",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 13722787,
      "PARTS CATEGORY": "YCG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 13722787,
      "PARTS CATEGORY_1": "YCG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": "01010-52080",
      "PARTS CATEGORY": "SDG",
      "ROQ": 21,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "01010-52080",
      "PARTS CATEGORY_1": "SDG",
      "ROQ_1": 21,
      "APPROVED QTY_1": 21,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 16850066,
      "PARTS CATEGORY": "VOG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 16850066,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 1,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": "01010-82045",
      "PARTS CATEGORY": "SDG",
      "ROQ": 5,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "01010-82045",
      "PARTS CATEGORY_1": "SDG",
      "ROQ_1": 5,
      "APPROVED QTY_1": 5,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 11050593,
      "PARTS CATEGORY": "VOG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 11050593,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 1,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 11060194,
      "PARTS CATEGORY": "VOG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 11060194,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 1,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4011000915,
      "PARTS CATEGORY": "VLG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4011000915,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 1,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110000453,
      "PARTS CATEGORY": "VLG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4110000453,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 1,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 29310024461,
      "PARTS CATEGORY": "VLG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 29310024461,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 1,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4011000378,
      "PARTS CATEGORY": "VLG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4011000378,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 1,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 17476848,
      "PARTS CATEGORY": "VOG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 17476848,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 1,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4130003515,
      "PARTS CATEGORY": "VLG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4130003515,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 1,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4130003052,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4130003052,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4190002199,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4190002199,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4190002201,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4190002201,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": "B222100000561",
      "PARTS CATEGORY": "YCG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "B222100000561",
      "PARTS CATEGORY_1": "YCG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 60108785,
      "PARTS CATEGORY": "YPG",
      "ROQ": 7,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 60108785,
      "PARTS CATEGORY_1": "YPG",
      "ROQ_1": 7,
      "APPROVED QTY_1": 5,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "PARTIAL WH12",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 4190002195,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4190002195,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4130002911009,
      "PARTS CATEGORY": "VLG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4130002911009,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 1,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 12825949,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 12825949,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 16054756,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 16054756,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 12814015,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 12814015,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 13914197,
      "PARTS CATEGORY": "VCG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 13914197,
      "PARTS CATEGORY_1": "VCG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 59929059,
      "PARTS CATEGORY": "VCG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 59929059,
      "PARTS CATEGORY_1": "VCG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14671838,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14671838,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 29350010201,
      "PARTS CATEGORY": "VLG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 29350010201,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 1,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 29350010211,
      "PARTS CATEGORY": "VLG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 29350010211,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 1,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4190002907007,
      "PARTS CATEGORY": "VLG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4190002907007,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 1,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": "117K-01-00020",
      "PARTS CATEGORY": "SDG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "117K-01-00020",
      "PARTS CATEGORY_1": "SDG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 1,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 14558286,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14558286,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": "TP1000544065",
      "PARTS CATEGORY": "SDG",
      "ROQ": 2,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "TP1000544065",
      "PARTS CATEGORY_1": "SDG",
      "ROQ_1": 2,
      "APPROVED QTY_1": 2,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "CMI"
    },
    {
      "PART NUMBER": "TP610800110416",
      "PARTS CATEGORY": "SDG",
      "ROQ": 4,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "TP610800110416",
      "PARTS CATEGORY_1": "SDG",
      "ROQ_1": 4,
      "APPROVED QTY_1": 4,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "CMI"
    },
    {
      "PART NUMBER": "TP612600116274",
      "PARTS CATEGORY": "SDG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "TP612600116274",
      "PARTS CATEGORY_1": "SDG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": "TP90011420036",
      "PARTS CATEGORY": "SDG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "TP90011420036",
      "PARTS CATEGORY_1": "SDG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 1,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "CMI"
    },
    {
      "PART NUMBER": "TP615G60110032",
      "PARTS CATEGORY": "SDG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "TP615G60110032",
      "PARTS CATEGORY_1": "SDG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 29170085771,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 29170085771,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": "402069Z00D",
      "PARTS CATEGORY": "NIG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "402069Z00D",
      "PARTS CATEGORY_1": "NIG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 24040481,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 24040481,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14259076,
      "PARTS CATEGORY": "YPG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14259076,
      "PARTS CATEGORY_1": "YPG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 14150767,
      "PARTS CATEGORY": "YPG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14150767,
      "PARTS CATEGORY_1": "YPG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 12052718,
      "PARTS CATEGORY": "YPG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 12052718,
      "PARTS CATEGORY_1": "YPG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 13275025,
      "PARTS CATEGORY": "YPG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 13275025,
      "PARTS CATEGORY_1": "YPG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 13025425,
      "PARTS CATEGORY": "YPG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 13025425,
      "PARTS CATEGORY_1": "YPG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 14259072,
      "PARTS CATEGORY": "YPG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14259072,
      "PARTS CATEGORY_1": "YPG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 14259073,
      "PARTS CATEGORY": "YPG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14259073,
      "PARTS CATEGORY_1": "YPG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 14259074,
      "PARTS CATEGORY": "YPG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14259074,
      "PARTS CATEGORY_1": "YPG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": "156-40-00013",
      "PARTS CATEGORY": "SDG",
      "ROQ": 12,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "156-40-00013",
      "PARTS CATEGORY_1": "SDG",
      "ROQ_1": 12,
      "APPROVED QTY_1": 12,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "CMI"
    },
    {
      "PART NUMBER": "01580-12218",
      "PARTS CATEGORY": "SDG",
      "ROQ": 10,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "01580-12218",
      "PARTS CATEGORY_1": "SDG",
      "ROQ_1": 10,
      "APPROVED QTY_1": 10,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "CMI"
    },
    {
      "PART NUMBER": "156-40-00014",
      "PARTS CATEGORY": "SDG",
      "ROQ": 10,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "156-40-00014",
      "PARTS CATEGORY_1": "SDG",
      "ROQ_1": 10,
      "APPROVED QTY_1": 10,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "CMI"
    },
    {
      "PART NUMBER": "01010-51235",
      "PARTS CATEGORY": "SDG",
      "ROQ": 5,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "01010-51235",
      "PARTS CATEGORY_1": "SDG",
      "ROQ_1": 5,
      "APPROVED QTY_1": 5,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "CMI"
    },
    {
      "PART NUMBER": "156-40-02004",
      "PARTS CATEGORY": "SDG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "156-40-02004",
      "PARTS CATEGORY_1": "SDG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 1,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "CMI"
    },
    {
      "PART NUMBER": "156-40-02005",
      "PARTS CATEGORY": "SDG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "156-40-02005",
      "PARTS CATEGORY_1": "SDG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 1,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "CMI"
    },
    {
      "PART NUMBER": "14Y-40-02100",
      "PARTS CATEGORY": "SDG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "14Y-40-02100",
      "PARTS CATEGORY_1": "SDG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 1,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "CMI"
    },
    {
      "PART NUMBER": "07021-00890",
      "PARTS CATEGORY": "SDG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "07021-00890",
      "PARTS CATEGORY_1": "SDG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 1,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "CMI"
    },
    {
      "PART NUMBER": "14Y-40-02200",
      "PARTS CATEGORY": "SDG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "14Y-40-02200",
      "PARTS CATEGORY_1": "SDG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 1,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 13255240,
      "PARTS CATEGORY": "YTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 13255240,
      "PARTS CATEGORY_1": "YTG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 17222542,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 17222542,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 11223889,
      "PARTS CATEGORY": "VLG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 11223889,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 1,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 11223888,
      "PARTS CATEGORY": "VLG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 11223888,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 1,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 11223886,
      "PARTS CATEGORY": "VLG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 11223886,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 1,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 11223879,
      "PARTS CATEGORY": "VLG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 11223879,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 1,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 11223869,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 11223869,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 11223876,
      "PARTS CATEGORY": "VLG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 11223876,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 1,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14589168,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14589168,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": "TP612650110003",
      "PARTS CATEGORY": "SDG",
      "ROQ": 2,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "TP612650110003",
      "PARTS CATEGORY_1": "SDG",
      "ROQ_1": 2,
      "APPROVED QTY_1": 2,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "CMI"
    },
    {
      "PART NUMBER": "TP61260110162",
      "PARTS CATEGORY": "SDG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "TP61260110162",
      "PARTS CATEGORY_1": "SDG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 1,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 23961982,
      "PARTS CATEGORY": "VTG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 23961982,
      "PARTS CATEGORY_1": "VTG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 1,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": "16.30.080.129",
      "PARTS CATEGORY": "PHG",
      "ROQ": 2,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "16.30.080.129",
      "PARTS CATEGORY_1": "PHG",
      "ROQ_1": 2,
      "APPROVED QTY_1": 2,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 22935396,
      "PARTS CATEGORY": "VTG",
      "ROQ": 4,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 22935396,
      "PARTS CATEGORY_1": "VTG",
      "ROQ_1": 4,
      "APPROVED QTY_1": 4,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": "01011-82045 ",
      "PARTS CATEGORY": "SDG",
      "ROQ": 16,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "01011-82045 ",
      "PARTS CATEGORY_1": "SDG",
      "ROQ_1": 16,
      "APPROVED QTY_1": 16,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "CMI"
    },
    {
      "PART NUMBER": "SSL01123",
      "PARTS CATEGORY": "SOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "SSL01123",
      "PARTS CATEGORY_1": "SOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 14552493,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14552493,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 22417696,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 22417696,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 23252913,
      "PARTS CATEGORY": "VTG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 23252913,
      "PARTS CATEGORY_1": "VTG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 1,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 7442381,
      "PARTS CATEGORY": "DOB",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 7442381,
      "PARTS CATEGORY_1": "DOB",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": "TP1000410660",
      "PARTS CATEGORY": "SDG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "TP1000410660",
      "PARTS CATEGORY_1": "SDG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 1,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "CMI"
    },
    {
      "PART NUMBER": "160101110018A088",
      "PARTS CATEGORY": "YCG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "160101110018A088",
      "PARTS CATEGORY_1": "YCG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 20578225,
      "PARTS CATEGORY": "VTG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 20578225,
      "PARTS CATEGORY_1": "VTG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 1,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 20774880,
      "PARTS CATEGORY": "VTG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 20774880,
      "PARTS CATEGORY_1": "VTG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 1,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 14723445,
      "PARTS CATEGORY": "VOG",
      "ROQ": 3,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 14723445,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 3,
      "APPROVED QTY_1": 3,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 992453,
      "PARTS CATEGORY": "VTG",
      "ROQ": 3,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 992453,
      "PARTS CATEGORY_1": "VTG",
      "ROQ_1": 3,
      "APPROVED QTY_1": 3,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 4110000509213,
      "PARTS CATEGORY": "VLG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4110000509213,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 1,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 23659479,
      "PARTS CATEGORY": "VTG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 23659479,
      "PARTS CATEGORY_1": "VTG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 1,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 22039393,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 22039393,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 17367461,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 17367461,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 53489684,
      "PARTS CATEGORY": "VOG",
      "ROQ": 2,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 53489684,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 2,
      "APPROVED QTY_1": 2,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 23962007,
      "PARTS CATEGORY": "VTG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 23962007,
      "PARTS CATEGORY_1": "VTG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 1,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 4190003393,
      "PARTS CATEGORY": "VLG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4190003393,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 1,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4130002467,
      "PARTS CATEGORY": "VLG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4130002467,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 1,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4130002468,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 4130002468,
      "PARTS CATEGORY_1": "VLG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": "TP612600083020",
      "PARTS CATEGORY": "SDG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "TP612600083020",
      "PARTS CATEGORY_1": "SDG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": "TP61800060172",
      "PARTS CATEGORY": "SDG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "TP61800060172",
      "PARTS CATEGORY_1": "SDG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 1,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "CMI"
    },
    {
      "PART NUMBER": "TP680340065",
      "PARTS CATEGORY": "SDG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "TP680340065",
      "PARTS CATEGORY_1": "SDG",
      "ROQ_1": 1,
      "APPROVED QTY_1": 1,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "CMI"
    },
    {
      "PART NUMBER": "TP612600160099",
      "PARTS CATEGORY": "SDG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "TP612600160099",
      "PARTS CATEGORY_1": "SDG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 23659350,
      "PARTS CATEGORY": "VTG",
      "ROQ": 3,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 23659350,
      "PARTS CATEGORY_1": "VTG",
      "ROQ_1": 3,
      "APPROVED QTY_1": 3,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": "571747HOK",
      "PARTS CATEGORY": "SLUB",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": "571747HOK",
      "PARTS CATEGORY_1": "SLUB",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": ""
    },
    {
      "PART NUMBER": 17237235,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 17237235,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 17237189,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "PART NUMBER_1": 17237189,
      "PARTS CATEGORY_1": "VOG",
      "ROQ_1": 0,
      "APPROVED QTY_1": 0,
      "UOM_1": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    }
  ];




  export const ptrfData = [
    {
      "PART NUMBER": 20460006,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 29250001061,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4016000113,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 29250007591,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4021000042,
      "PARTS CATEGORY": "VLG",
      "ROQ": 1,
      "APPROVED QTY": 1,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 29250004031,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4040000001,
      "PARTS CATEGORY": "VLG",
      "ROQ": 1,
      "APPROVED QTY": 1,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 29250004011,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 29250004021,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14636301,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 993275,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 20405724,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 20405654,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 20412733,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": "B222100000494",
      "PARTS CATEGORY": "YPG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 21426987,
      "PARTS CATEGORY": "VTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 15048183,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 21486081,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 994849,
      "PARTS CATEGORY": "VTG",
      "ROQ": 1,
      "APPROVED QTY": 1,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 20460945,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 20964264,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 20933362,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110000011082,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110000011083,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 3030900232,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4015000013,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 29050011341,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 3030900110,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 3030900111,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4015000026,
      "PARTS CATEGORY": "VLG",
      "ROQ": 3,
      "APPROVED QTY": 3,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110001015025,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 20550001,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 85102464,
      "PARTS CATEGORY": "LUV",
      "ROQ": 12,
      "APPROVED QTY": 20,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "LUBRICANTS"
    },
    {
      "PART NUMBER": "99990-1Z03J",
      "PARTS CATEGORY": "LUV",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "LUBRICANTS"
    },
    {
      "PART NUMBER": "99990-1Z05J",
      "PARTS CATEGORY": "LUV",
      "ROQ": 6,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "LUBRICANTS"
    },
    {
      "PART NUMBER": "8220-13340",
      "PARTS CATEGORY": "VSG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4130000912,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4120002264401,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4120002263401,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4043000043,
      "PARTS CATEGORY": "VLG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 15057758,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4130000060,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4130000875,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 20828295,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 11712176,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4013000203,
      "PARTS CATEGORY": "VLG",
      "ROQ": 6,
      "APPROVED QTY": 6,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14632954,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4030000789,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 84035947,
      "PARTS CATEGORY": "VTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 1547979,
      "PARTS CATEGORY": "VTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": "8230-09800",
      "PARTS CATEGORY": "VSG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14530605,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 29010013441,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14620906,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 60013292,
      "PARTS CATEGORY": "YCG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": "B222100000235",
      "PARTS CATEGORY": "YCG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": "A222100000119",
      "PARTS CATEGORY": "YCG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 4030000101,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 29250000051,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4015000168,
      "PARTS CATEGORY": "VLG",
      "ROQ": 2,
      "APPROVED QTY": 2,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4130001685,
      "PARTS CATEGORY": "VLG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 7200002385,
      "PARTS CATEGORY": "VLG",
      "ROQ": 5,
      "APPROVED QTY": 5,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14619718,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110000991027,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110000054305,
      "PARTS CATEGORY": "VLG",
      "ROQ": 3,
      "APPROVED QTY": 3,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 20405543,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 21928687,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 24425970,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110000189006,
      "PARTS CATEGORY": "VLG",
      "ROQ": 1,
      "APPROVED QTY": 1,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110000189005,
      "PARTS CATEGORY": "VLG",
      "ROQ": 1,
      "APPROVED QTY": 1,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110000501,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14540778,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14577801,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14577802,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14589125,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14630975,
      "PARTS CATEGORY": "VOG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14550189,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 20850506,
      "PARTS CATEGORY": "VTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 14632032,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 11103129,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4021000012,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4021000019,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4021000040,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4021000013,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 996519,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14616326,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 996518,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 21615194,
      "PARTS CATEGORY": "VTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 29250004041,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14378640,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": "487169Z00A",
      "PARTS CATEGORY": "NIG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": "4130000457A",
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 29250000291,
      "PARTS CATEGORY": "VLG",
      "ROQ": 1,
      "APPROVED QTY": 1,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 21743197,
      "PARTS CATEGORY": "VTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 3901000049,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4014000024,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 29250000311,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4015000035,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4030000065,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4120000506,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 20799587,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 21611489,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 21349444,
      "PARTS CATEGORY": "VTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 16631876,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 20859647,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 20798699,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110000556128,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 990853,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14616327,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 11110702,
      "PARTS CATEGORY": "VSG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4120000553401,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": "301009Z00B",
      "PARTS CATEGORY": "NIG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": "146769Z01C",
      "PARTS CATEGORY": "NIG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": "A240700000508",
      "PARTS CATEGORY": "YPG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 14601888,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 22023120,
      "PARTS CATEGORY": "VTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 10503759,
      "PARTS CATEGORY": "YCG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 60045284,
      "PARTS CATEGORY": "YPG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 14529014,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 22089205,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 21971559,
      "PARTS CATEGORY": "VTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": "7117-30150",
      "PARTS CATEGORY": "VSG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 20798533,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": "99990-1Z01J",
      "PARTS CATEGORY": "LUV",
      "ROQ": 4,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "LUBRICANTS"
    },
    {
      "PART NUMBER": 4011000186,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 22243151,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": "A222100000166",
      "PARTS CATEGORY": "YPG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 4110000509232,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110001169001,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4120004036003,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4015000024,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 60101256,
      "PARTS CATEGORY": "YCG",
      "ROQ": 1,
      "APPROVED QTY": 1,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 60200364,
      "PARTS CATEGORY": "YCG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 29170036972,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 21302562,
      "PARTS CATEGORY": "VTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 14611902,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14528724,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": "7117-30360",
      "PARTS CATEGORY": "VSG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 21103569,
      "PARTS CATEGORY": "VTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 21055401,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14589153,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14385098,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 15173282,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4130000622,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4015000030,
      "PARTS CATEGORY": "VLG",
      "ROQ": 7,
      "APPROVED QTY": 7,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14016673,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14511215,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14513653,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14514378,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14523552,
      "PARTS CATEGORY": "VOG",
      "ROQ": 4,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14526220,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14528387,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14529995,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14545883,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14547395,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14576774,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14589155,
      "PARTS CATEGORY": "VOG",
      "ROQ": 1,
      "APPROVED QTY": 1,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 15082742,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 20411420,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 20412308,
      "PARTS CATEGORY": "VOG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 20412314,
      "PARTS CATEGORY": "VOG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 20412589,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 20450707,
      "PARTS CATEGORY": "VOG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 20450736,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 20459016,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 20459203,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 20459213,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 20459961,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 20502536,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 20775463,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 20798827,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 20805349,
      "PARTS CATEGORY": "VOG",
      "ROQ": 17,
      "APPROVED QTY": 17,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 20840801,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 20850096,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 24425916,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4881440,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": "8230-09710",
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 914167,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 925071,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 943475,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 949874,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 955921,
      "PARTS CATEGORY": "VOG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 955926,
      "PARTS CATEGORY": "VOG",
      "ROQ": 1,
      "APPROVED QTY": 1,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 960254,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 960265,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 968007,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 968564,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 969474,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 977525,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 978710,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 978722,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 978999,
      "PARTS CATEGORY": "VOG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 990601,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 990737,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 990851,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": "1030-61460",
      "PARTS CATEGORY": "VSG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": "1030-61680",
      "PARTS CATEGORY": "VSG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 11110283,
      "PARTS CATEGORY": "VOG",
      "ROQ": 1,
      "APPROVED QTY": 1,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 11110284,
      "PARTS CATEGORY": "VSG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 11110670,
      "PARTS CATEGORY": "VSG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 11110737,
      "PARTS CATEGORY": "VSG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 11110738,
      "PARTS CATEGORY": "VSG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 11172896,
      "PARTS CATEGORY": "VSG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": "1122-00940",
      "PARTS CATEGORY": "VSG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": "1141-00010",
      "PARTS CATEGORY": "VSG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 11708550,
      "PARTS CATEGORY": "VSG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 11708551,
      "PARTS CATEGORY": "VSG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 11709635,
      "PARTS CATEGORY": "VSG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": "1171-00171",
      "PARTS CATEGORY": "VSG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": "1171-00181",
      "PARTS CATEGORY": "VSG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": "1172-01071",
      "PARTS CATEGORY": "VSG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 11988962,
      "PARTS CATEGORY": "VSG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 13947622,
      "PARTS CATEGORY": "VSG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 13947628,
      "PARTS CATEGORY": "VSG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14382341,
      "PARTS CATEGORY": "VSG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14500233,
      "PARTS CATEGORY": "VSG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14503269,
      "PARTS CATEGORY": "VSG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14505515,
      "PARTS CATEGORY": "VSG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14506407,
      "PARTS CATEGORY": "VSG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14506844,
      "PARTS CATEGORY": "VSG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14506889,
      "PARTS CATEGORY": "VSG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14507705,
      "PARTS CATEGORY": "VSG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14508662,
      "PARTS CATEGORY": "VSG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14509331,
      "PARTS CATEGORY": "VSG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14509379,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14511594,
      "PARTS CATEGORY": "VSG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14514937,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14515828,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14518349,
      "PARTS CATEGORY": "VSG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14520284,
      "PARTS CATEGORY": "VSG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14522962,
      "PARTS CATEGORY": "VSG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14523264,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14523946,
      "PARTS CATEGORY": "VSG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14524170,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14524449,
      "PARTS CATEGORY": "VSG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14526158,
      "PARTS CATEGORY": "VSG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14527267,
      "PARTS CATEGORY": "VSG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14527864,
      "PARTS CATEGORY": "VSG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14529050,
      "PARTS CATEGORY": "VSG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14529051,
      "PARTS CATEGORY": "VSG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14529052,
      "PARTS CATEGORY": "VSG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14529132,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14529178,
      "PARTS CATEGORY": "VSG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14530365,
      "PARTS CATEGORY": "VSG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14530367,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14531109,
      "PARTS CATEGORY": "VSG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14531426,
      "PARTS CATEGORY": "VSG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14531866,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14532260,
      "PARTS CATEGORY": "VSG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14533862,
      "PARTS CATEGORY": "VSG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14535425,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14540004,
      "PARTS CATEGORY": "VSG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14540725,
      "PARTS CATEGORY": "VSG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14542152,
      "PARTS CATEGORY": "VSG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14544828,
      "PARTS CATEGORY": "VSG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14544984,
      "PARTS CATEGORY": "VSG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14547826,
      "PARTS CATEGORY": "VOG",
      "ROQ": 3,
      "APPROVED QTY": 3,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14551131,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14551534,
      "PARTS CATEGORY": "VSG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14552332,
      "PARTS CATEGORY": "VSG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14560201,
      "PARTS CATEGORY": "VOG",
      "ROQ": 1,
      "APPROVED QTY": 1,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14562193,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14577815,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14589129,
      "PARTS CATEGORY": "VOG",
      "ROQ": 1,
      "APPROVED QTY": 1,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14589131,
      "PARTS CATEGORY": "VSG",
      "ROQ": 1,
      "APPROVED QTY": 1,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14589132,
      "PARTS CATEGORY": "VOG",
      "ROQ": 1,
      "APPROVED QTY": 1,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14589138,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14596399,
      "PARTS CATEGORY": "VSG",
      "ROQ": 2,
      "APPROVED QTY": 2,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14600771,
      "PARTS CATEGORY": "VSG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14880552,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14880553,
      "PARTS CATEGORY": "VSG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14880692,
      "PARTS CATEGORY": "VSG",
      "ROQ": 2,
      "APPROVED QTY": 2,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14881185,
      "PARTS CATEGORY": "VOG",
      "ROQ": 2,
      "APPROVED QTY": 2,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14881234,
      "PARTS CATEGORY": "VSG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14881276,
      "PARTS CATEGORY": "VOG",
      "ROQ": 2,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 15078671,
      "PARTS CATEGORY": "VSG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 20405561,
      "PARTS CATEGORY": "VSG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 20405599,
      "PARTS CATEGORY": "VSG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 20405907,
      "PARTS CATEGORY": "VSG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 20450749,
      "PARTS CATEGORY": "VSG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 20450750,
      "PARTS CATEGORY": "VSG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 20450754,
      "PARTS CATEGORY": "VSG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 20459025,
      "PARTS CATEGORY": "VSG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 20459191,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 20459192,
      "PARTS CATEGORY": "VSG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 20459193,
      "PARTS CATEGORY": "VSG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 20459212,
      "PARTS CATEGORY": "VSG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 20459214,
      "PARTS CATEGORY": "VSG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 20459215,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 20459218,
      "PARTS CATEGORY": "VSG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 20459263,
      "PARTS CATEGORY": "VSG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 20459863,
      "PARTS CATEGORY": "VSG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 20798186,
      "PARTS CATEGORY": "VSG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 20798683,
      "PARTS CATEGORY": "VSG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 20841035,
      "PARTS CATEGORY": "VSG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 20973777,
      "PARTS CATEGORY": "VSG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 21009699,
      "PARTS CATEGORY": "VSG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 21009704,
      "PARTS CATEGORY": "VSG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 21141943,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 21142728,
      "PARTS CATEGORY": "VSG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 21247955,
      "PARTS CATEGORY": "VSG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 21291011,
      "PARTS CATEGORY": "VSG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 21584586,
      "PARTS CATEGORY": "VSG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": "7123-52560",
      "PARTS CATEGORY": "VSG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": "7273-10751",
      "PARTS CATEGORY": "VSG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": "8230-21520",
      "PARTS CATEGORY": "VSG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": "8230-21670",
      "PARTS CATEGORY": "VSG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": "8230-24860",
      "PARTS CATEGORY": "VSG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": "8230-36840",
      "PARTS CATEGORY": "VSG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": "9111-31600",
      "PARTS CATEGORY": "VSG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 955927,
      "PARTS CATEGORY": "VSG",
      "ROQ": 5,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": "9564-20750",
      "PARTS CATEGORY": "VSG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 960259,
      "PARTS CATEGORY": "VSG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 983303,
      "PARTS CATEGORY": "VSG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 983502,
      "PARTS CATEGORY": "VSG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 990557,
      "PARTS CATEGORY": "VSG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 990645,
      "PARTS CATEGORY": "VSG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 990660,
      "PARTS CATEGORY": "VSG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 990740,
      "PARTS CATEGORY": "VSG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 992047,
      "PARTS CATEGORY": "VOG",
      "ROQ": 1,
      "APPROVED QTY": 1,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 1623726,
      "PARTS CATEGORY": "VTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 1652986,
      "PARTS CATEGORY": "VTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 1677352,
      "PARTS CATEGORY": "VTG",
      "ROQ": 1,
      "APPROVED QTY": 1,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 1677355,
      "PARTS CATEGORY": "VTG",
      "ROQ": 1,
      "APPROVED QTY": 1,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 1677356,
      "PARTS CATEGORY": "VTG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 1677362,
      "PARTS CATEGORY": "VTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 1677364,
      "PARTS CATEGORY": "VTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 1677365,
      "PARTS CATEGORY": "VTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 1677366,
      "PARTS CATEGORY": "VTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 1677367,
      "PARTS CATEGORY": "VTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 1696446,
      "PARTS CATEGORY": "VTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 20399043,
      "PARTS CATEGORY": "VTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 20428438,
      "PARTS CATEGORY": "VTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 22127385,
      "PARTS CATEGORY": "DOP",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 22436331,
      "PARTS CATEGORY": "DOP",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 22496905,
      "PARTS CATEGORY": "DOP",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 22568091,
      "PARTS CATEGORY": "DOP",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 23279078,
      "PARTS CATEGORY": "DOP",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 35393685,
      "PARTS CATEGORY": "DOP",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 36845493,
      "PARTS CATEGORY": "DOP",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 15058186,
      "PARTS CATEGORY": "LUV",
      "ROQ": 1381,
      "APPROVED QTY": 1380,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "LUBRICANTS"
    },
    {
      "PART NUMBER": 15058290,
      "PARTS CATEGORY": "LUV",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "LUBRICANTS"
    },
    {
      "PART NUMBER": 15067098,
      "PARTS CATEGORY": "LUV",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "LUBRICANTS"
    },
    {
      "PART NUMBER": 15067197,
      "PARTS CATEGORY": "LUV",
      "ROQ": 763,
      "APPROVED QTY": 760,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "LUBRICANTS"
    },
    {
      "PART NUMBER": 15067515,
      "PARTS CATEGORY": "LUV",
      "ROQ": 169,
      "APPROVED QTY": 160,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "LUBRICANTS"
    },
    {
      "PART NUMBER": 15067522,
      "PARTS CATEGORY": "LUV",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "LUBRICANTS"
    },
    {
      "PART NUMBER": 15067644,
      "PARTS CATEGORY": "LUV",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "LUBRICANTS"
    },
    {
      "PART NUMBER": 15143928,
      "PARTS CATEGORY": "LUV",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "LUBRICANTS"
    },
    {
      "PART NUMBER": 15155620,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 43911668,
      "PARTS CATEGORY": "VCG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 43921394,
      "PARTS CATEGORY": "VCG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 43922400,
      "PARTS CATEGORY": "VCG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 43923093,
      "PARTS CATEGORY": "VCG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 43924422,
      "PARTS CATEGORY": "VCG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 43931922,
      "PARTS CATEGORY": "VCG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 11419486,
      "PARTS CATEGORY": "VGG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 12725151,
      "PARTS CATEGORY": "VGG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 12735741,
      "PARTS CATEGORY": "VGG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 12743047,
      "PARTS CATEGORY": "VGG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 12745093,
      "PARTS CATEGORY": "VGG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 12746142,
      "PARTS CATEGORY": "VGG",
      "ROQ": 1,
      "APPROVED QTY": 1,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 2030900065,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 20792408,
      "PARTS CATEGORY": "VGG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 20798114,
      "PARTS CATEGORY": "VGG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 20799064,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 20799719,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 29070000081,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 29070000621,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 29100000061,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 29100004061,
      "PARTS CATEGORY": "VLG",
      "ROQ": 3,
      "APPROVED QTY": 3,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 29170019701,
      "PARTS CATEGORY": "VLG",
      "ROQ": 1,
      "APPROVED QTY": 1,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 29170019711,
      "PARTS CATEGORY": "VLG",
      "ROQ": 32,
      "APPROVED QTY": 32,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 29170020531,
      "PARTS CATEGORY": "VLG",
      "ROQ": 3,
      "APPROVED QTY": 3,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 29170036961,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 29170036971,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 29170036981,
      "PARTS CATEGORY": "VLG",
      "ROQ": 1,
      "APPROVED QTY": 1,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 29170039941,
      "PARTS CATEGORY": "VLG",
      "ROQ": 4,
      "APPROVED QTY": 4,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 29170039951,
      "PARTS CATEGORY": "VLG",
      "ROQ": 2,
      "APPROVED QTY": 2,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 29170039961,
      "PARTS CATEGORY": "VLG",
      "ROQ": 1,
      "APPROVED QTY": 1,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 29250000061,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 29250000071,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 29250000081,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 29250000301,
      "PARTS CATEGORY": "VLG",
      "ROQ": 1,
      "APPROVED QTY": 1,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4013000016,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4013000020,
      "PARTS CATEGORY": "VLG",
      "ROQ": 36,
      "APPROVED QTY": 36,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4015000001,
      "PARTS CATEGORY": "VLG",
      "ROQ": 14,
      "APPROVED QTY": 14,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4015000176,
      "PARTS CATEGORY": "VLG",
      "ROQ": 3,
      "APPROVED QTY": 3,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4021000058,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4043000033,
      "PARTS CATEGORY": "VLG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4043000034,
      "PARTS CATEGORY": "VLG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4043000055,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4043000056,
      "PARTS CATEGORY": "VLG",
      "ROQ": 4,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4043000057,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4043000059,
      "PARTS CATEGORY": "VLG",
      "ROQ": 4,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4043000173,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4043000337,
      "PARTS CATEGORY": "VLG",
      "ROQ": 2,
      "APPROVED QTY": 2,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110000076001,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110000076368,
      "PARTS CATEGORY": "VLG",
      "ROQ": 2,
      "APPROVED QTY": 2,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110000081265,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110000081312,
      "PARTS CATEGORY": "VLG",
      "ROQ": 1,
      "APPROVED QTY": 1,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110000179020,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110000367002,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110000556209,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110000589001,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110000589016,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110000613,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110000679001,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110000679002,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110001016004,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4120000066,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4120000084,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4120000452,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4120001739,
      "PARTS CATEGORY": "VLG",
      "ROQ": 2,
      "APPROVED QTY": 2,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4120001739016,
      "PARTS CATEGORY": "VLG",
      "ROQ": 29,
      "APPROVED QTY": 29,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4120001954001,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4130000542,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4190000153,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 60100000,
      "PARTS CATEGORY": "VOG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 75439,
      "PARTS CATEGORY": "VGG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 11039563,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 11039846,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 11110022,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 11110023,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 11110175,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 11110176,
      "PARTS CATEGORY": "VOG",
      "ROQ": 1,
      "APPROVED QTY": 1,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 11110668,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 11110683,
      "PARTS CATEGORY": "VOG",
      "ROQ": 13,
      "APPROVED QTY": 13,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 11110709,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 11147756,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 11172907,
      "PARTS CATEGORY": "VOG",
      "ROQ": 3,
      "APPROVED QTY": 1,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "PARTIAL WH12",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 11417147,
      "PARTS CATEGORY": "VOG",
      "ROQ": 2,
      "APPROVED QTY": 2,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 11700426,
      "PARTS CATEGORY": "VOG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 11703980,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 11707077,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 11708825,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 11711074,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 11713240,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 11991047,
      "PARTS CATEGORY": "VOG",
      "ROQ": 1,
      "APPROVED QTY": 1,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 12743605,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 13947281,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 13947621,
      "PARTS CATEGORY": "VOG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 13969011,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 13969162,
      "PARTS CATEGORY": "VOG",
      "ROQ": 2,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 13970950,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 20443551,
      "PARTS CATEGORY": "VTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 20489245,
      "PARTS CATEGORY": "VTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 20524936,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 20526428,
      "PARTS CATEGORY": "VTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 20579690,
      "PARTS CATEGORY": "VTG",
      "ROQ": 6,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 20707685,
      "PARTS CATEGORY": "VTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 20734499,
      "PARTS CATEGORY": "VTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 20748450,
      "PARTS CATEGORY": "VTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 20750350,
      "PARTS CATEGORY": "VTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 20779040,
      "PARTS CATEGORY": "VTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 20781146,
      "PARTS CATEGORY": "VTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 20804638,
      "PARTS CATEGORY": "VTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 20817742,
      "PARTS CATEGORY": "VTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 20829689,
      "PARTS CATEGORY": "VTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 20835246,
      "PARTS CATEGORY": "VTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 20997774,
      "PARTS CATEGORY": "VTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 21210530,
      "PARTS CATEGORY": "VTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 21255974,
      "PARTS CATEGORY": "VTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 21347087,
      "PARTS CATEGORY": "VTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 21380475,
      "PARTS CATEGORY": "VTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 21380488,
      "PARTS CATEGORY": "VTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 21482601,
      "PARTS CATEGORY": "VTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 21620181,
      "PARTS CATEGORY": "VTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 276098,
      "PARTS CATEGORY": "VTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 276948,
      "PARTS CATEGORY": "VTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 3090954,
      "PARTS CATEGORY": "VTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 3090964,
      "PARTS CATEGORY": "VTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 3092340,
      "PARTS CATEGORY": "VTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 3093230,
      "PARTS CATEGORY": "VTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 3176550,
      "PARTS CATEGORY": "VTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 349619,
      "PARTS CATEGORY": "VTG",
      "ROQ": 1,
      "APPROVED QTY": 1,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 8086970,
      "PARTS CATEGORY": "VTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 8150543,
      "PARTS CATEGORY": "VTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 85103703,
      "PARTS CATEGORY": "VTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 8550977,
      "PARTS CATEGORY": "VTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 8550978,
      "PARTS CATEGORY": "VTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 949329,
      "PARTS CATEGORY": "VTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 977030,
      "PARTS CATEGORY": "VTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 980881,
      "PARTS CATEGORY": "VTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 982108,
      "PARTS CATEGORY": "VTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 982724,
      "PARTS CATEGORY": "VTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 983472,
      "PARTS CATEGORY": "VTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 990037,
      "PARTS CATEGORY": "VTG",
      "ROQ": 2,
      "APPROVED QTY": 2,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 992519,
      "PARTS CATEGORY": "VTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 992521,
      "PARTS CATEGORY": "VTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": "A820606010023",
      "PARTS CATEGORY": "YPG",
      "ROQ": 4,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "CMI"
    },
    {
      "PART NUMBER": "A820606030049",
      "PARTS CATEGORY": "YPG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": "A820699000015",
      "PARTS CATEGORY": "YPG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "CMI"
    },
    {
      "PART NUMBER": "B222100000116",
      "PARTS CATEGORY": "YPG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 3152527,
      "PARTS CATEGORY": "VTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 8171450,
      "PARTS CATEGORY": "VTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 14626370,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": "C11195",
      "PARTS CATEGORY": "SOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 29170036941,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14507748,
      "PARTS CATEGORY": "VSG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14505765,
      "PARTS CATEGORY": "VSG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14547554,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 21479106,
      "PARTS CATEGORY": "VTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 21261987,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 20555065,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 992328,
      "PARTS CATEGORY": "VTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 29220001251,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 21620172,
      "PARTS CATEGORY": "VTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 12745114,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": "8230-03560",
      "PARTS CATEGORY": "VSG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14535450,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": "7117-30370",
      "PARTS CATEGORY": "VSG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14541865,
      "PARTS CATEGORY": "VSG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": "8230-08940",
      "PARTS CATEGORY": "VSG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4041000771,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 907816,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14622357,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4120000087010,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 11709817,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": "9412-11090",
      "PARTS CATEGORY": "VSG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4120001739007,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4120001739008,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4021000023,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110000076281,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 11708371,
      "PARTS CATEGORY": "LUB",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "LUBRICANTS"
    },
    {
      "PART NUMBER": 20459226,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 20459855,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 21496591,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 21638691,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 20998903,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 20405643,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 20515173,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 21061009,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 993605,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14559054,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": "7117-30170",
      "PARTS CATEGORY": "VSG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 12745102,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 20405721,
      "PARTS CATEGORY": "VOG",
      "ROQ": 2,
      "APPROVED QTY": 2,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14624748,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 17214676,
      "PARTS CATEGORY": "VSG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 21354759,
      "PARTS CATEGORY": "VTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 14527865,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 20966752,
      "PARTS CATEGORY": "VTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 20459204,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14543216,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": "7117-30180",
      "PARTS CATEGORY": "VSG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": "7117-30330",
      "PARTS CATEGORY": "VSG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4120001739005,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4120001739006,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 20724977,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 21811707,
      "PARTS CATEGORY": "VTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 983242,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14529761,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14563050,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 21038626,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 21108673,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14621703,
      "PARTS CATEGORY": "VSG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": "B222100000751",
      "PARTS CATEGORY": "YCG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": "A222100000600",
      "PARTS CATEGORY": "YCG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": "A222100000599",
      "PARTS CATEGORY": "YCG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 15173332,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 17204355,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14526214,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4041000020,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4030000146,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110000025006,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 21707132,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4030000536,
      "PARTS CATEGORY": "VLG",
      "ROQ": 1,
      "APPROVED QTY": 1,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110000507007,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14622354,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110001015026,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 21628356,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4021000030,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4030000035,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14600711,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 21534097,
      "PARTS CATEGORY": "VTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 21534385,
      "PARTS CATEGORY": "VTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 14597767,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 21707134,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14559479,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 15066534,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": "1040-20410",
      "PARTS CATEGORY": "VSG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 12746143,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 21707133,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 12743123,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4015000034,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14547821,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14547822,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14211856,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": "16Y-75-23200",
      "PARTS CATEGORY": "SDG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 4120001739053,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 21483521,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14622355,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 15067640,
      "PARTS CATEGORY": "LUV",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "LUBRICANTS"
    },
    {
      "PART NUMBER": "R134A",
      "PARTS CATEGORY": "LUB",
      "ROQ": 4,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "LUBRICANTS"
    },
    {
      "PART NUMBER": 4030000047,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 20412745,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14591830,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14532688,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14562924,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 7200001514,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110000556189,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 21517690,
      "PARTS CATEGORY": "VTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 12743391,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 20460215,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110000556085,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 21647837,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14550884,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 20459311,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14550188,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": "7114-12371",
      "PARTS CATEGORY": "VSG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110000460,
      "PARTS CATEGORY": "VLG",
      "ROQ": 1,
      "APPROVED QTY": 1,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14542155,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14524470,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14612048,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 990603,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14589150,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14622356,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 20840772,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14550879,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14594697,
      "PARTS CATEGORY": "VSG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4804563,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 1547967,
      "PARTS CATEGORY": "VTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 20405559,
      "PARTS CATEGORY": "VSG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 990719,
      "PARTS CATEGORY": "VTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 468947,
      "PARTS CATEGORY": "VTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 60075090,
      "PARTS CATEGORY": "YPG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 14511752,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": "7114-11950",
      "PARTS CATEGORY": "VSG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": "DOT3",
      "PARTS CATEGORY": "LUB",
      "ROQ": 1,
      "APPROVED QTY": 1,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "LUBRICANTS"
    },
    {
      "PART NUMBER": 20796351,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14630764,
      "PARTS CATEGORY": "VSG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 21598183,
      "PARTS CATEGORY": "VSG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 20459837,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14519262,
      "PARTS CATEGORY": "VSG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14589156,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 20551332,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 15603435,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 15603422,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 15633940,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14542156,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 20799673,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14536511,
      "PARTS CATEGORY": "VSG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 15052786,
      "PARTS CATEGORY": "VOG",
      "ROQ": 2,
      "APPROVED QTY": 2,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4120001739052,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14621335,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14530607,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110000727085,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 29070000591,
      "PARTS CATEGORY": "VLG",
      "ROQ": 1,
      "APPROVED QTY": 1,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14643849,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 17253817,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": "A820606030048",
      "PARTS CATEGORY": "YPG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 12579712,
      "PARTS CATEGORY": "YPG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 14641479,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 24425954,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 1677348,
      "PARTS CATEGORY": "VTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 4110000991005,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 20551429,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": "4130000418A",
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110000189022,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 22085052,
      "PARTS CATEGORY": "VTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 4110001593002,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110000189031,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 21486084,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": "252619Z00C ",
      "PARTS CATEGORY": "NIG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 993323,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 21519716,
      "PARTS CATEGORY": "VTG",
      "ROQ": 1,
      "APPROVED QTY": 1,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 20554258,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 22203109,
      "PARTS CATEGORY": "VTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 4013000205,
      "PARTS CATEGORY": "VLG",
      "ROQ": 16,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 3030900094,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110000557022,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110000556155,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 28140006791,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4043000218,
      "PARTS CATEGORY": "VLG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4043000112,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4043000126,
      "PARTS CATEGORY": "VLG",
      "ROQ": 2,
      "APPROVED QTY": 2,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4043000129,
      "PARTS CATEGORY": "VLG",
      "ROQ": 2,
      "APPROVED QTY": 2,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4043000125,
      "PARTS CATEGORY": "VLG",
      "ROQ": 2,
      "APPROVED QTY": 2,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4190002758,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14623356,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": "307099Z00A",
      "PARTS CATEGORY": "NIG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 14631808,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110000054003,
      "PARTS CATEGORY": "VLG",
      "ROQ": 2,
      "APPROVED QTY": 2,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110000054002,
      "PARTS CATEGORY": "VLG",
      "ROQ": 2,
      "APPROVED QTY": 2,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110000054100,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110000846035,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110000054038,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110000054037,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14663702,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 28140007461,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 28140007451,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110000057003,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110001525019,
      "PARTS CATEGORY": "VLG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110001525005,
      "PARTS CATEGORY": "VLG",
      "ROQ": 2,
      "APPROVED QTY": 1,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "PARTIAL WH12",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110001525011,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110000846229,
      "PARTS CATEGORY": "VLG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": "7114-12121",
      "PARTS CATEGORY": "VSG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": "7114-12240",
      "PARTS CATEGORY": "VSG",
      "ROQ": 1,
      "APPROVED QTY": 1,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": "8220-13470",
      "PARTS CATEGORY": "VSG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": "7114-17460",
      "PARTS CATEGORY": "VSG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14528776,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 15185168,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 17416725,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 21242584,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14649606,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14558072,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 21337557,
      "PARTS CATEGORY": "VTG",
      "ROQ": 1,
      "APPROVED QTY": 1,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 21693755,
      "PARTS CATEGORY": "VTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 29050016931,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 10503761,
      "PARTS CATEGORY": "YCG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 17429862,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4043000035,
      "PARTS CATEGORY": "VLG",
      "ROQ": 1,
      "APPROVED QTY": 1,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4041000767,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 29070001271,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14683137,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110000054262,
      "PARTS CATEGORY": "VLG",
      "ROQ": 2,
      "APPROVED QTY": 2,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4040000032,
      "PARTS CATEGORY": "VLG",
      "ROQ": 12,
      "APPROVED QTY": 12,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 29170049661,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 29170049671,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 29050021561,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14589154,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110002109,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110001903103,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4190002822,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4190002825,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4190002765,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": "430519Z00C",
      "PARTS CATEGORY": "NIG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": "443419Z00D",
      "PARTS CATEGORY": "NIG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 21649619,
      "PARTS CATEGORY": "VTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 22285860,
      "PARTS CATEGORY": "VTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 20970435,
      "PARTS CATEGORY": "VTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 15173598,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14557971,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 20459235,
      "PARTS CATEGORY": "VOG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 21027485,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 21940615,
      "PARTS CATEGORY": "VTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 4021000043,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 9017204355,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14597943,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14345423,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110001948041,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 29350010491,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 29350010501,
      "PARTS CATEGORY": "VLG",
      "ROQ": 1,
      "APPROVED QTY": 1,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4190002863,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110000924103,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 24426013,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4190000608,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 11444203,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 29250004091,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4011000197,
      "PARTS CATEGORY": "VLG",
      "ROQ": 1,
      "APPROVED QTY": 1,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 345,
      "PARTS CATEGORY": "YCG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 914462,
      "PARTS CATEGORY": "VOG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 21990221,
      "PARTS CATEGORY": "VTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": "166959Z01D",
      "PARTS CATEGORY": "NIG",
      "ROQ": 1,
      "APPROVED QTY": 1,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": "119249Z01D",
      "PARTS CATEGORY": "NIG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": "324879Z00D",
      "PARTS CATEGORY": "NIG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 8158197,
      "PARTS CATEGORY": "VTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 29050018231,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 29050018221,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 29050018241,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14528726,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14521612,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 15066561,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14515742,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 17411510,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14689735,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4030000768,
      "PARTS CATEGORY": "VLG",
      "ROQ": 1,
      "APPROVED QTY": 1,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 29050012751,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 29050012741,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 29050012731,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 16680661,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": "440669Z01D",
      "PARTS CATEGORY": "NIG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 22610786,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 11110708,
      "PARTS CATEGORY": "VOG",
      "ROQ": 1,
      "APPROVED QTY": 1,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 22567321,
      "PARTS CATEGORY": "VTG",
      "ROQ": 12,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 14671764,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 60209845,
      "PARTS CATEGORY": "YPG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 14594707,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14622759,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 992340,
      "PARTS CATEGORY": "VTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 82358216,
      "PARTS CATEGORY": "VTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": "440669Z01B",
      "PARTS CATEGORY": "NIG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 4120005488006,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 22768937,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 22075800,
      "PARTS CATEGORY": "VTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 21825534,
      "PARTS CATEGORY": "VTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 4120001058,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 17418256,
      "PARTS CATEGORY": "VOG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110001948021,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110000991013,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": "4120000090Z",
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 21414908,
      "PARTS CATEGORY": "VTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 14550595,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14592566,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4120000868101,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4120001004101,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14602601,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 13933900,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4120005062,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 11217633,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110000054094,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 20924088,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14591829,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 20412318,
      "PARTS CATEGORY": "VOG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110001031037,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 20460055,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110002247100,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 15035179,
      "PARTS CATEGORY": "VOG",
      "ROQ": 1,
      "APPROVED QTY": 1,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 22327063,
      "PARTS CATEGORY": "VTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": "302009Z01E",
      "PARTS CATEGORY": "NIG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 22335631,
      "PARTS CATEGORY": "VTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 9060100000,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110000054266,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14658433,
      "PARTS CATEGORY": "VOG",
      "ROQ": 1,
      "APPROVED QTY": 1,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14701884,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4014000033,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 29070012671,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 29070012711,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 183680,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 28140009601,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 28140009611,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 28140010441,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110002321001,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 21092586,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14576499,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4130000202,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110000846152,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110000846153,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110000054295,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110000846135,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110000054235,
      "PARTS CATEGORY": "VLG",
      "ROQ": 1,
      "APPROVED QTY": 1,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110000054083,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 17412747,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 85140107,
      "PARTS CATEGORY": "VTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 20799065,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 22144327,
      "PARTS CATEGORY": "VTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 4110000555274,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 21774593,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 29250000101,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 29250000111,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 29250000121,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 82356758,
      "PARTS CATEGORY": "VTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 82356760,
      "PARTS CATEGORY": "VTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 21396128,
      "PARTS CATEGORY": "VTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": "LF16352",
      "PARTS CATEGORY": "CUF",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "OLD"
    },
    {
      "PART NUMBER": 4120004037001,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 29070015101,
      "PARTS CATEGORY": "VLG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 29070015111,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 20409787,
      "PARTS CATEGORY": "VTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 84139925,
      "PARTS CATEGORY": "VTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": "272139Z00A",
      "PARTS CATEGORY": "NIG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 21583806,
      "PARTS CATEGORY": "VTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 20409788,
      "PARTS CATEGORY": "VTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": "7114-17250",
      "PARTS CATEGORY": "VSG",
      "ROQ": 1,
      "APPROVED QTY": 1,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14646872,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14680534,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14680532,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 29070013111,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14552686,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": "7114-14370",
      "PARTS CATEGORY": "VSG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 5222677134,
      "PARTS CATEGORY": "NIG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 4110000909011,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4030001002,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 5282682780,
      "PARTS CATEGORY": "NIG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 14679709,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 22574941,
      "PARTS CATEGORY": "VTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 60017576,
      "PARTS CATEGORY": "YPG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 14680749,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 6668819,
      "PARTS CATEGORY": "DOB",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 7146391,
      "PARTS CATEGORY": "DOB",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 7143498,
      "PARTS CATEGORY": "DOB",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 6667352,
      "PARTS CATEGORY": "DOB",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 6666333,
      "PARTS CATEGORY": "DOB",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 6666334,
      "PARTS CATEGORY": "DOB",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 6675517,
      "PARTS CATEGORY": "DOB",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 6666375,
      "PARTS CATEGORY": "DOB",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 6666376,
      "PARTS CATEGORY": "DOB",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 4110001383201,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 82437188,
      "PARTS CATEGORY": "VTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 21392404,
      "PARTS CATEGORY": "VTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": "EP00",
      "PARTS CATEGORY": "LUB",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "LUBRICANTS"
    },
    {
      "PART NUMBER": 14633688,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14564695,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14564696,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": "8220-09560",
      "PARTS CATEGORY": "VSG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": "7114-14410",
      "PARTS CATEGORY": "VSG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": "7114-14200",
      "PARTS CATEGORY": "VSG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": "7114-14220",
      "PARTS CATEGORY": "VSG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 29250004081,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 992453,
      "PARTS CATEGORY": "VOG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110000054316,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 17457469,
      "PARTS CATEGORY": "VOG",
      "ROQ": 1,
      "APPROVED QTY": 1,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 22739649,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 15057777,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14695920,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14686427,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 29250008711,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4021000134,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 29250011271,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 29250011761,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 29250011281,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 29250011291,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14715404,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 22384866,
      "PARTS CATEGORY": "VTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 22472464,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 22662214,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 5222748702,
      "PARTS CATEGORY": "NIG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 4120006350,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110000991053,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 29250015711,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 29250011341,
      "PARTS CATEGORY": "VLG",
      "ROQ": 1,
      "APPROVED QTY": 1,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 29250011321,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14882685,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": "1109-27/23",
      "PARTS CATEGORY": "JJG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 14640021,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14687316,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14686458,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": "7114-12260",
      "PARTS CATEGORY": "VSG",
      "ROQ": 1,
      "APPROVED QTY": 1,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": "7114-12270",
      "PARTS CATEGORY": "VSG",
      "ROQ": 1,
      "APPROVED QTY": 1,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": "7114-12280",
      "PARTS CATEGORY": "VSG",
      "ROQ": 1,
      "APPROVED QTY": 1,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": "7114-12290",
      "PARTS CATEGORY": "VSG",
      "ROQ": 1,
      "APPROVED QTY": 1,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": "7114-12230",
      "PARTS CATEGORY": "VSG",
      "ROQ": 1,
      "APPROVED QTY": 1,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": "9612-05030",
      "PARTS CATEGORY": "VSG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14882341,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": "FF5706",
      "PARTS CATEGORY": "CUF",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "OLD"
    },
    {
      "PART NUMBER": 4011000106,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4021000368,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4021000322,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14688861,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14711981,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14658434,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14650586,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4130001731,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 21792306,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 7009365,
      "PARTS CATEGORY": "DOB",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 6516722,
      "PARTS CATEGORY": "DOB",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 6692836,
      "PARTS CATEGORY": "DOB",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 7010894,
      "PARTS CATEGORY": "DOB",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 7010252,
      "PARTS CATEGORY": "DOB",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 7025590,
      "PARTS CATEGORY": "DOB",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 7010351,
      "PARTS CATEGORY": "DOB",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 7276395,
      "PARTS CATEGORY": "DOB",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 7004883,
      "PARTS CATEGORY": "DOB",
      "ROQ": 1,
      "APPROVED QTY": 1,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 7013148,
      "PARTS CATEGORY": "DOB",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 7006085,
      "PARTS CATEGORY": "DOB",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 7005577,
      "PARTS CATEGORY": "DOB",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 12007782,
      "PARTS CATEGORY": "YCG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 4110000054039,
      "PARTS CATEGORY": "VLG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 5222748694,
      "PARTS CATEGORY": "NIG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 16688307,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 982320,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 6735884,
      "PARTS CATEGORY": "DOB",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 4110003158,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 992336,
      "PARTS CATEGORY": "VTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 29050011362,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14679620,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 22803123,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 6714212,
      "PARTS CATEGORY": "DOB",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 17470368,
      "PARTS CATEGORY": "LUV",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "LUBRICANTS"
    },
    {
      "PART NUMBER": 82356810,
      "PARTS CATEGORY": "VTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 22902431,
      "PARTS CATEGORY": "VTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 22603162,
      "PARTS CATEGORY": "VTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 4110000054118,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": "7114-14390",
      "PARTS CATEGORY": "VSG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 9021092586,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14686465,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14715403,
      "PARTS CATEGORY": "VOG",
      "ROQ": 1,
      "APPROVED QTY": 1,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110000127008,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4130001732,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 85020562,
      "PARTS CATEGORY": "VTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 22383382,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 22905123,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110001031031,
      "PARTS CATEGORY": "VLG",
      "ROQ": 1,
      "APPROVED QTY": 1,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 1161937,
      "PARTS CATEGORY": "LUV",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "LUBRICANTS"
    },
    {
      "PART NUMBER": 22089218,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 7161331,
      "PARTS CATEGORY": "DOB",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 4110000054299,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110000054330,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": "4120006350Z",
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14560268,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14560264,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4043000026,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14686569,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14690316,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 5222889270,
      "PARTS CATEGORY": "NIG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 60110356,
      "PARTS CATEGORY": "VOG",
      "ROQ": 1,
      "APPROVED QTY": 1,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14693033,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110000054192,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110000054194,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110000054170,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110000054203,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 60208877,
      "PARTS CATEGORY": "YCG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 22435058,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110000054092,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110000557016,
      "PARTS CATEGORY": "VLG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110000846100,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110000054117,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14640020,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14639732,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14882680,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 15067513,
      "PARTS CATEGORY": "LUV",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "LUBRICANTS"
    },
    {
      "PART NUMBER": 4110003167001,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110001755023,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110001544001,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14560269,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14560273,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14560272,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 11704997,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 11704999,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 22821828,
      "PARTS CATEGORY": "VTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": "B222100000641",
      "PARTS CATEGORY": "YCG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": "B222100000640",
      "PARTS CATEGORY": "YCG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 60274433,
      "PARTS CATEGORY": "YPG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 14592564,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14685140,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14685843,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14687315,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14708935,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14715425,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14695898,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14640023,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 997459,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 60222777,
      "PARTS CATEGORY": "YCG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 12802324,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4120002513,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 29110000043,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 60110025,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4016000043,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 21793270,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110000556263,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 17418113,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14614042,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14614044,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14691235,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 6655216,
      "PARTS CATEGORY": "DOB",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 4130001290,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 29010009212,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14679691,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14674063,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 22851149,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 22967564,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110002483001,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110003450018,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 20794115,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 60252396,
      "PARTS CATEGORY": "YCG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": "A222100000380",
      "PARTS CATEGORY": "YCG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 14702253,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4030000950,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": "165289Z01A",
      "PARTS CATEGORY": "NIG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": "165289Z00E",
      "PARTS CATEGORY": "NIG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 5222802305,
      "PARTS CATEGORY": "NIG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": "262619Z01C",
      "PARTS CATEGORY": "NIG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": "47500NA04D",
      "PARTS CATEGORY": "NIG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": "276949Z00B",
      "PARTS CATEGORY": "NIG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 14521527,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 29070020651,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4013000064,
      "PARTS CATEGORY": "VLG",
      "ROQ": 5,
      "APPROVED QTY": 5,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 29090001031,
      "PARTS CATEGORY": "VLG",
      "ROQ": 1,
      "APPROVED QTY": 1,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 17438619,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14558017,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14640024,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4043000127,
      "PARTS CATEGORY": "VLG",
      "ROQ": 4,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 29250011261,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 29250011301,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4011001122,
      "PARTS CATEGORY": "VLG",
      "ROQ": 1,
      "APPROVED QTY": 1,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 29250014591,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 84044658,
      "PARTS CATEGORY": "VTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 60193266,
      "PARTS CATEGORY": "YPG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 4110003071,
      "PARTS CATEGORY": "VLG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110000991019,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 60263488,
      "PARTS CATEGORY": "YTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 85135446,
      "PARTS CATEGORY": "VTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 5222845321,
      "PARTS CATEGORY": "NIG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 5223236938,
      "PARTS CATEGORY": "NIG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 16668474,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 17201956,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 17324107,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14522199,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14585975,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14725906,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 5223236940,
      "PARTS CATEGORY": "NIG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 85020561,
      "PARTS CATEGORY": "VTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 23111328,
      "PARTS CATEGORY": "VTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 29290037551,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 22923707,
      "PARTS CATEGORY": "VTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 20992763,
      "PARTS CATEGORY": "VTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 4110002852014,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4130001709,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 5223236891,
      "PARTS CATEGORY": "NIG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 60137527,
      "PARTS CATEGORY": "YCG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": "A210609000354",
      "PARTS CATEGORY": "YPG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 960698,
      "PARTS CATEGORY": "DOB",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 6911907,
      "PARTS CATEGORY": "DOB",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 22343374,
      "PARTS CATEGORY": "VTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 14702254,
      "PARTS CATEGORY": "VOG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4120007012,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 23140886,
      "PARTS CATEGORY": "VTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 14663701,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 997410,
      "PARTS CATEGORY": "VOG",
      "ROQ": 1,
      "APPROVED QTY": 1,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4120008483012,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14617017,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14709480,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14589122,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 6578169,
      "PARTS CATEGORY": "DOB",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 15009280,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 5222702709,
      "PARTS CATEGORY": "NIG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 5223058616,
      "PARTS CATEGORY": "NIG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 5223236939,
      "PARTS CATEGORY": "NIG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 14717312,
      "PARTS CATEGORY": "VOG",
      "ROQ": 1,
      "APPROVED QTY": 1,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 12782905,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110002791,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14704792,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4030000260,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 28100006661,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 28100006671,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 28100006681,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 28100006691,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 28100006701,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 28100006711,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4030001195,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 28100006651,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4030000258,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 28140010081,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 11659727,
      "PARTS CATEGORY": "YCG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 11251512,
      "PARTS CATEGORY": "YCG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 60110287,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 22988765,
      "PARTS CATEGORY": "VTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 4110002902,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 5227334,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14560265,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": "4120006349Z",
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4190002589,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4130002221,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 60118361,
      "PARTS CATEGORY": "YPG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 14719601,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 7291240,
      "PARTS CATEGORY": "DOB",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 7289231,
      "PARTS CATEGORY": "DOB",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 22085772,
      "PARTS CATEGORY": "VTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 29070021581,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 60110002,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 7290383,
      "PARTS CATEGORY": "DOB",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 7290371,
      "PARTS CATEGORY": "DOB",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 7290377,
      "PARTS CATEGORY": "DOB",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 16064048,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 96702386,
      "PARTS CATEGORY": "VCG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14684603,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14729840,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 29290039031,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4030001196,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 28100006581,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4120007867004,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4120006628004,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 12743622,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14716428,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14734376,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110000054005,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110000054035,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 12824620,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 16052009,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 17253413,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4043001507,
      "PARTS CATEGORY": "VLG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4043001505,
      "PARTS CATEGORY": "VLG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4043001508,
      "PARTS CATEGORY": "VLG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 60125019,
      "PARTS CATEGORY": "YCG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": "8240-03610",
      "PARTS CATEGORY": "VSG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 28140007521,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4043000128,
      "PARTS CATEGORY": "VLG",
      "ROQ": 1,
      "APPROVED QTY": 1,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 5284811166,
      "PARTS CATEGORY": "NIG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 60358721,
      "PARTS CATEGORY": "YPG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 60327521,
      "PARTS CATEGORY": "YPG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 60328867,
      "PARTS CATEGORY": "YCG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 14737201,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 11701471,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 60110299,
      "PARTS CATEGORY": "VOG",
      "ROQ": 6,
      "APPROVED QTY": 6,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 997420,
      "PARTS CATEGORY": "VOG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 29050011363,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14679710,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 6577822,
      "PARTS CATEGORY": "DOB",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 4120002669004,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110003497,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14883909,
      "PARTS CATEGORY": "VOG",
      "ROQ": 1,
      "APPROVED QTY": 1,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 60113095,
      "PARTS CATEGORY": "VOG",
      "ROQ": 1,
      "APPROVED QTY": 1,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14716429,
      "PARTS CATEGORY": "VOG",
      "ROQ": 1,
      "APPROVED QTY": 1,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 24426014,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 21184969,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 20475034,
      "PARTS CATEGORY": "VTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": "1CM1450",
      "PARTS CATEGORY": "DOB",
      "ROQ": 1,
      "APPROVED QTY": 1,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 997437,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 60328868,
      "PARTS CATEGORY": "YCG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 4120004036001,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 29020001101,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4130002496,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 29250001081,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4015000428,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 29250001031,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 29250001051,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 29250017661,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 29250009441,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 29250000091,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 29250009451,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 29250009461,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 29250009421,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 29250004061,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14721846,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 6670207,
      "PARTS CATEGORY": "DOB",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 14737252,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 29250011331,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 29270009421,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4120009227,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14884948,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4120006889,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 60326015,
      "PARTS CATEGORY": "YPG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 4120001739081,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110004221,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 60242353,
      "PARTS CATEGORY": "YCG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 60017381,
      "PARTS CATEGORY": "YCG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 60320099,
      "PARTS CATEGORY": "YCG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 60320126,
      "PARTS CATEGORY": "YCG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 60333601,
      "PARTS CATEGORY": "YCG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 23226845,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110003380073,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 60193268,
      "PARTS CATEGORY": "YCG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 60345316,
      "PARTS CATEGORY": "YCG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 14682685,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110000846061,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 7275347,
      "PARTS CATEGORY": "DOB",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 7004451,
      "PARTS CATEGORY": "DOB",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 20920600,
      "PARTS CATEGORY": "VTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 82619052,
      "PARTS CATEGORY": "VTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 15057699,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14672152,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 60168540,
      "PARTS CATEGORY": "YCG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 4190002588,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 22206133,
      "PARTS CATEGORY": "VTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": "443429Z00A",
      "PARTS CATEGORY": "NIG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 4015000032,
      "PARTS CATEGORY": "VLG",
      "ROQ": 1,
      "APPROVED QTY": 1,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14737813,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": "A819700000631",
      "PARTS CATEGORY": "YCG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 4110002410004,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 82637695,
      "PARTS CATEGORY": "VTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 7000988,
      "PARTS CATEGORY": "DOB",
      "ROQ": 1,
      "APPROVED QTY": 1,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "CMI"
    },
    {
      "PART NUMBER": "4DM14",
      "PARTS CATEGORY": "DOB",
      "ROQ": 1,
      "APPROVED QTY": 1,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 23150684,
      "PARTS CATEGORY": "VTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 984975,
      "PARTS CATEGORY": "VTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 993380,
      "PARTS CATEGORY": "VTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 14638992,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 60110219,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 60350360,
      "PARTS CATEGORY": "YCG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 60348720,
      "PARTS CATEGORY": "YCG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 29050023541,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": "22.4.JSM33*2HXL",
      "PARTS CATEGORY": "PHG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 4110002354002,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110000054103,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110000054267,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14883767,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 29050027361,
      "PARTS CATEGORY": "VLG",
      "ROQ": 1,
      "APPROVED QTY": 1,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110001015010,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 21564394,
      "PARTS CATEGORY": "VTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 29050025851,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 7100002101,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 3030900178,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 28100006641,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4190003578,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 60348780,
      "PARTS CATEGORY": "YCG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 14514458,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 29290034631,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14744967,
      "PARTS CATEGORY": "VOG",
      "ROQ": 1,
      "APPROVED QTY": 1,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14684602,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 29070001031,
      "PARTS CATEGORY": "VLG",
      "ROQ": 4,
      "APPROVED QTY": 4,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 23285701,
      "PARTS CATEGORY": "VTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 60119268,
      "PARTS CATEGORY": "YTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 17516676,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110000991031,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 7312069,
      "PARTS CATEGORY": "DOB",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 7004877,
      "PARTS CATEGORY": "DOB",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 10125894,
      "PARTS CATEGORY": "YCG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 60159795,
      "PARTS CATEGORY": "YCG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 16284724,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 23191319,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": "15058186PQ",
      "PARTS CATEGORY": "LUV",
      "ROQ": 25,
      "APPROVED QTY": 10,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "PARTIAL WH12",
      "BU": "LUBRICANTS"
    },
    {
      "PART NUMBER": "15067098PQ",
      "PARTS CATEGORY": "LUV",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "LUBRICANTS"
    },
    {
      "PART NUMBER": "15067522PQ",
      "PARTS CATEGORY": "LUV",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "LUBRICANTS"
    },
    {
      "PART NUMBER": "15067640PQ",
      "PARTS CATEGORY": "LUV",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "LUBRICANTS"
    },
    {
      "PART NUMBER": "15143928PQ",
      "PARTS CATEGORY": "LUV",
      "ROQ": 2,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "LUBRICANTS"
    },
    {
      "PART NUMBER": 20532891,
      "PARTS CATEGORY": "VTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 21707132,
      "PARTS CATEGORY": "VTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 21707133,
      "PARTS CATEGORY": "VTG",
      "ROQ": 3,
      "APPROVED QTY": 3,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 980521,
      "PARTS CATEGORY": "VTG",
      "ROQ": 1,
      "APPROVED QTY": 1,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 969161,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 984738,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 990861,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 990941,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 990970,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 992521,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 1161277,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 20367490,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 20532237,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 21190817,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 21482601,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 21531072,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 22567321,
      "PARTS CATEGORY": "VOG",
      "ROQ": 10,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 22899626,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": "9451-02211",
      "PARTS CATEGORY": "VOH",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": ""
    },
    {
      "PART NUMBER": "9453-02207",
      "PARTS CATEGORY": "VOH",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": ""
    },
    {
      "PART NUMBER": "9453-03229",
      "PARTS CATEGORY": "VOH",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": ""
    },
    {
      "PART NUMBER": 937177,
      "PARTS CATEGORY": "VOH",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": ""
    },
    {
      "PART NUMBER": "9453-02336",
      "PARTS CATEGORY": "VOH",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": ""
    },
    {
      "PART NUMBER": "9453-02213",
      "PARTS CATEGORY": "VOH",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": ""
    },
    {
      "PART NUMBER": 4040000051,
      "PARTS CATEGORY": "VLG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4011001261,
      "PARTS CATEGORY": "VLG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 60339522,
      "PARTS CATEGORY": "YCG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 60350359,
      "PARTS CATEGORY": "YCG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 3030900177,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 60339521,
      "PARTS CATEGORY": "YCG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 60272760,
      "PARTS CATEGORY": "YCG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 4110000727162,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 11214252,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 11210241,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 11211360,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 11211213,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 11210987,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 11211189,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14405925,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14403810,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110002196003,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": "B222100000601",
      "PARTS CATEGORY": "YCG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 29220020671,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14720769,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 60358722,
      "PARTS CATEGORY": "YPG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 14723460,
      "PARTS CATEGORY": "VOG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 17473102,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 17493102,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14732381,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14732382,
      "PARTS CATEGORY": "VOG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 23417523,
      "PARTS CATEGORY": "VTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 15057732,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": "9453-05212",
      "PARTS CATEGORY": "VOH",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": ""
    },
    {
      "PART NUMBER": "9453-02214",
      "PARTS CATEGORY": "VOH",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": ""
    },
    {
      "PART NUMBER": "9453-02216",
      "PARTS CATEGORY": "VOH",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": ""
    },
    {
      "PART NUMBER": 936218,
      "PARTS CATEGORY": "VOH",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": ""
    },
    {
      "PART NUMBER": 14603541,
      "PARTS CATEGORY": "VOH",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": ""
    },
    {
      "PART NUMBER": "9453-04208",
      "PARTS CATEGORY": "VOH",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": ""
    },
    {
      "PART NUMBER": "9453-04218",
      "PARTS CATEGORY": "VOH",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": ""
    },
    {
      "PART NUMBER": 23149532,
      "PARTS CATEGORY": "VTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 14884939,
      "PARTS CATEGORY": "VOG",
      "ROQ": 2,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110000591035,
      "PARTS CATEGORY": "VLG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4120004492,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 29250009001,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 7196904,
      "PARTS CATEGORY": "DOB",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 14529190,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 17287189,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": "131288000077A",
      "PARTS CATEGORY": "YCG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 14729891,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 23307415,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4120005980012,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 17208525,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 982558,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14749664,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 21178542,
      "PARTS CATEGORY": "VOG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 23657265,
      "PARTS CATEGORY": "VOG",
      "ROQ": 1,
      "APPROVED QTY": 1,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 21795915,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110002360007,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 160604020017,
      "PARTS CATEGORY": "YPG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": "160603020014A",
      "PARTS CATEGORY": "YCG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 4110003478002,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 997466,
      "PARTS CATEGORY": "VOG",
      "ROQ": 10,
      "APPROVED QTY": 10,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 60282117,
      "PARTS CATEGORY": "YCG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 4190002690,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 29300007731,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 28100006631,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 17532105,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 29250017701,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 29250017711,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 29250017721,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14732612,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 11211325,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110000076032,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14672980,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14749665,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14672981,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 11205297,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4120002274,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4030000097,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": "29100001051A",
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14750655,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14400779,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 29170036831,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 23752844,
      "PARTS CATEGORY": "VTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 14722585,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 29250017691,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 11217422,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 11213340,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 11223287,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 993677,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 7006098,
      "PARTS CATEGORY": "DOB",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 61018500,
      "PARTS CATEGORY": "YPG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 60359943,
      "PARTS CATEGORY": "YCG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 60284008,
      "PARTS CATEGORY": "YPG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 14682684,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": "360456-1",
      "PARTS CATEGORY": "PHG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": "SP-10",
      "PARTS CATEGORY": "LUB",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "LUBRICANTS"
    },
    {
      "PART NUMBER": 20475033,
      "PARTS CATEGORY": "VTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 997471,
      "PARTS CATEGORY": "VOG",
      "ROQ": 1,
      "APPROVED QTY": 1,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": "160602010079A",
      "PARTS CATEGORY": "YTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 60358720,
      "PARTS CATEGORY": "YTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 60263755,
      "PARTS CATEGORY": "YTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 60263487,
      "PARTS CATEGORY": "YTG",
      "ROQ": 3,
      "APPROVED QTY": 3,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "CMI"
    },
    {
      "PART NUMBER": "141002010002B",
      "PARTS CATEGORY": "YTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": "152503990036A",
      "PARTS CATEGORY": "YTG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "CMI"
    },
    {
      "PART NUMBER": "131488000229A",
      "PARTS CATEGORY": "YTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 60126917,
      "PARTS CATEGORY": "YPG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 60126914,
      "PARTS CATEGORY": "YPG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 15714570,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 15714574,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 15711010,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14682738,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14720757,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": "TP1000424916",
      "PARTS CATEGORY": "SDG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 23479847,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4120002263005,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 21769814,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 60183685,
      "PARTS CATEGORY": "YPG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 12846533,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 23481701,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": "ADBLUE20L",
      "PARTS CATEGORY": "ZZY",
      "ROQ": 763,
      "APPROVED QTY": 763,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": "R134A",
      "PARTS CATEGORY": "LUP",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "LUBRICANTS"
    },
    {
      "PART NUMBER": 11708371,
      "PARTS CATEGORY": "LUT",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "LUBRICANTS"
    },
    {
      "PART NUMBER": "DOT3",
      "PARTS CATEGORY": "LUT",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "LUBRICANTS"
    },
    {
      "PART NUMBER": 17533661,
      "PARTS CATEGORY": "VOG",
      "ROQ": 5,
      "APPROVED QTY": 5,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14884680,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 6669287,
      "PARTS CATEGORY": "DOB",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 14717724,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 21574984,
      "PARTS CATEGORY": "VTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": "A820301021138",
      "PARTS CATEGORY": "YCG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 17535679,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": "168899001038A",
      "PARTS CATEGORY": "YPG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": "168899001032A",
      "PARTS CATEGORY": "YPG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": "168899001037A",
      "PARTS CATEGORY": "YPG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 990973,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 7196450,
      "PARTS CATEGORY": "DOB",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 7166330,
      "PARTS CATEGORY": "DOB",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 61021989,
      "PARTS CATEGORY": "YPG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 61021990,
      "PARTS CATEGORY": "YPG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 160604020018,
      "PARTS CATEGORY": "YPG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 23807658,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": "A229900008929",
      "PARTS CATEGORY": "YCG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": "130699000042A",
      "PARTS CATEGORY": "YTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": "130699000043A",
      "PARTS CATEGORY": "YTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 4110004010002,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": "29.07.DNDC XBN",
      "PARTS CATEGORY": "PHG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 15714575,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 60343613,
      "PARTS CATEGORY": "YCG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 5223958455,
      "PARTS CATEGORY": "NIG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 14750657,
      "PARTS CATEGORY": "VOG",
      "ROQ": 3,
      "APPROVED QTY": 3,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 60274683,
      "PARTS CATEGORY": "YPG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 17515206,
      "PARTS CATEGORY": "VOG",
      "ROQ": 1,
      "APPROVED QTY": 1,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": "131488000859A ",
      "PARTS CATEGORY": "YTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": "160602020036A",
      "PARTS CATEGORY": "YTG",
      "ROQ": 1,
      "APPROVED QTY": 1,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 61021071,
      "PARTS CATEGORY": "YTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 61021072,
      "PARTS CATEGORY": "YTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": "131401020468B022",
      "PARTS CATEGORY": "YTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": "131401020468B026",
      "PARTS CATEGORY": "YTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": "141502000307A",
      "PARTS CATEGORY": "YTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": "131401020472A013",
      "PARTS CATEGORY": "YTG",
      "ROQ": 67,
      "APPROVED QTY": 67,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "CMI"
    },
    {
      "PART NUMBER": "160602030026A",
      "PARTS CATEGORY": "YTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": "131402010260A010",
      "PARTS CATEGORY": "YTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": "131401020472A006",
      "PARTS CATEGORY": "YTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 17500251,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 17500253,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14884806,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 23779943,
      "PARTS CATEGORY": "VTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 23690622,
      "PARTS CATEGORY": "VTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 23974640,
      "PARTS CATEGORY": "VOG",
      "ROQ": 2,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4120004899,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4120005303003,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 60151839,
      "PARTS CATEGORY": "YCG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 17271168,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 16658928,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 23658092,
      "PARTS CATEGORY": "VTG",
      "ROQ": 1,
      "APPROVED QTY": 1,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 4110001475095,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 7167163,
      "PARTS CATEGORY": "DOB",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 4110003435018,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110003435022,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 60146760,
      "PARTS CATEGORY": "YCG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": "488209Z01E",
      "PARTS CATEGORY": "NIG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 11705253,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 7408600,
      "PARTS CATEGORY": "DOB",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 7408601,
      "PARTS CATEGORY": "DOB",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 4110000555215,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 11220925,
      "PARTS CATEGORY": "VLG",
      "ROQ": 1,
      "APPROVED QTY": 1,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 5223683591,
      "PARTS CATEGORY": "NIG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": "DOT3BENDIX",
      "PARTS CATEGORY": "LUT",
      "ROQ": 12,
      "APPROVED QTY": 12,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "LUBRICANTS"
    },
    {
      "PART NUMBER": 60207264,
      "PARTS CATEGORY": "YCG",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 60207265,
      "PARTS CATEGORY": "YCG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 14756414,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110000054296,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110015600,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110001949018,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 6685521,
      "PARTS CATEGORY": "DOB",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": "131402010260A011",
      "PARTS CATEGORY": "YTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": "131402010260A012",
      "PARTS CATEGORY": "YPG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 4110001031002,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4190003196,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 12804222,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 977747,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 12804670,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 16051536,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 21421260,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 13462163,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 16055022,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 997493,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 913111,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14691364,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14726181,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14674408,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14685159,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 60275828,
      "PARTS CATEGORY": "YCG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": "160101080007A016",
      "PARTS CATEGORY": "YPG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 4110000054019,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110015815005,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 23044511,
      "PARTS CATEGORY": "VTG",
      "ROQ": 1,
      "APPROVED QTY": 1,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 969147,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14752088,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 11173801,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14403813,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 29270001041,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14780710,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": "7273-10360",
      "PARTS CATEGORY": "VSG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 60113128,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 20793501,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 21100405,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 21100407,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 21100410,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 20862028,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": "131488000859L",
      "PARTS CATEGORY": "ZZZ",
      "ROQ": 1,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "CMI"
    },
    {
      "PART NUMBER": "29CM1250",
      "PARTS CATEGORY": "DOB",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 7143561,
      "PARTS CATEGORY": "DOB",
      "ROQ": 1,
      "APPROVED QTY": 1,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 4110001551002,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 29020015351,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 24017079,
      "PARTS CATEGORY": "VTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 60352070,
      "PARTS CATEGORY": "YCG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 61022011,
      "PARTS CATEGORY": "YPG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 960628,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 23699488,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": "FS20020",
      "PARTS CATEGORY": "CUF",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "OLD"
    },
    {
      "PART NUMBER": 23877099,
      "PARTS CATEGORY": "VTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": "SCC005027363",
      "PARTS CATEGORY": "YCG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 14750647,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14536343,
      "PARTS CATEGORY": "YPG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 21516451,
      "PARTS CATEGORY": "VTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 22739212,
      "PARTS CATEGORY": "VTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 22672756,
      "PARTS CATEGORY": "VTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 20405565,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": "150499000008A",
      "PARTS CATEGORY": "YTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 14521529,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14521521,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 7004842,
      "PARTS CATEGORY": "DOB",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": "130699000044A",
      "PARTS CATEGORY": "YTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 24060142,
      "PARTS CATEGORY": "VTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 61001560,
      "PARTS CATEGORY": "YCG",
      "ROQ": 1,
      "APPROVED QTY": 1,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 11211150,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 11211149,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 54116585,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 11388082,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14453494,
      "PARTS CATEGORY": "YCG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 23871484,
      "PARTS CATEGORY": "VTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": "160101040001A001",
      "PARTS CATEGORY": "YPG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": "160101040001A002",
      "PARTS CATEGORY": "YPG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": "168899000953A",
      "PARTS CATEGORY": "YPG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": "123109Z01B ",
      "PARTS CATEGORY": "NIG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": "29.09.DG05-82CL-DC24",
      "PARTS CATEGORY": "PHG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 5223994853,
      "PARTS CATEGORY": "NIG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 3030900121,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14648463,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14648464,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": "160602010092A001",
      "PARTS CATEGORY": "YPG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 14400991,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 4110000011081,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 11210768,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 11210753,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 12291280,
      "PARTS CATEGORY": "YCG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 54315408,
      "PARTS CATEGORY": "VOG",
      "ROQ": 2,
      "APPROVED QTY": 2,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "WH12",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": "22.4.JSM36*2HXL",
      "PARTS CATEGORY": "PHG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 4110000076435,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 29050033351,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 10943425,
      "PARTS CATEGORY": "YCG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 14703194,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14619871,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14573762,
      "PARTS CATEGORY": "VOG",
      "ROQ": 1,
      "APPROVED QTY": 1,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "RFC",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": "FDJ007138164",
      "PARTS CATEGORY": "YPG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 24046345,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 14518437,
      "PARTS CATEGORY": "YCG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 7172745,
      "PARTS CATEGORY": "DOB",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 60119976,
      "PARTS CATEGORY": "YCG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 7168895,
      "PARTS CATEGORY": "DOB",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 54864268,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 24078863,
      "PARTS CATEGORY": "VTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 22761626,
      "PARTS CATEGORY": "VTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 24318489,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 29250001111,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 24046348,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 7007301,
      "PARTS CATEGORY": "DOB",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 11012624,
      "PARTS CATEGORY": "YCG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 5223822029,
      "PARTS CATEGORY": "NIG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 5223822030,
      "PARTS CATEGORY": "NIG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": "29.09.770924",
      "PARTS CATEGORY": "PHG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 23871482,
      "PARTS CATEGORY": "VTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 12466937,
      "PARTS CATEGORY": "YCG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": "122709Z00C",
      "PARTS CATEGORY": "NIG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "PRIMEQUEST"
    },
    {
      "PART NUMBER": 60359141,
      "PARTS CATEGORY": "YPG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 60118168,
      "PARTS CATEGORY": "YPG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 29050037551,
      "PARTS CATEGORY": "VLG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 60118167,
      "PARTS CATEGORY": "YTG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 14562269,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 11110213,
      "PARTS CATEGORY": "VOG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "TOPSPOT"
    },
    {
      "PART NUMBER": 60238123,
      "PARTS CATEGORY": "YCG",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 7292867,
      "PARTS CATEGORY": "DOB",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    },
    {
      "PART NUMBER": 7292925,
      "PARTS CATEGORY": "DOB",
      "ROQ": 0,
      "APPROVED QTY": 0,
      "UOM": "PC",
      "REMARKS": "Approved Stocking",
      "WH REF": "",
      "BU": "CMI"
    }
  ];

  export const replenishcompData = [
    {
      "PART NUMBER": "A229900004769",
      "DESCRIPTION": "BRAKE LINING, FRONT",
      "PRODUCT CATEGORY": "YPG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 60,
      "WHXX": 24,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 60060763,
      "DESCRIPTION": "LINING BRAKE REAR",
      "PRODUCT CATEGORY": "YPG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 62,
      "WHXX": 28,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 16001025,
      "DESCRIPTION": "CONTROL VALVE",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "B222100000392",
      "DESCRIPTION": "FILTER ELEMENT, OIL SUCTION",
      "PRODUCT CATEGORY": "YPG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 10,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4021000042,
      "DESCRIPTION": "BEARING GB9163-GE60ES-2RS",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 4,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 29250004011,
      "DESCRIPTION": "FLANGE",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 29250004021,
      "DESCRIPTION": "FLANGE",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 5,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14636301,
      "DESCRIPTION": "I-ECU EC210B SN35001- 35001- 70001- 80001-  14390065",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 1,
      "SHELF LIFE": 6.000000000000024,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 7,
      "WHXX": 7,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.375533808099405,
      "MEAN": 0.153846153846153,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 11700373,
      "DESCRIPTION": "NUT",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4120000063,
      "DESCRIPTION": "TRANS PUMP  LG956L",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 5,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 984728,
      "DESCRIPTION": "13948356 FLANGE SCREW",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 58,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 15048183,
      "DESCRIPTION": "OIL LEVEL SENSOR 11144494",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 21486081,
      "DESCRIPTION": "SEALING RING 20412568",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 15,
      "WHXX": 11,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 990973,
      "DESCRIPTION": "HEX NUT 979022",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 2,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 227,
      "WHXX": 4,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "A222100000582",
      "DESCRIPTION": "ELEMENT, FILTER, MAIN",
      "PRODUCT CATEGORY": "YPG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.25,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 2,
      "STD": 0.832050294337843,
      "MEAN": 0.23076923076923,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 4,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": "A222100000574",
      "DESCRIPTION": "AIR FILTER, SAFETY  CF1430MANN",
      "PRODUCT CATEGORY": "YPG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.25,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 1,
      "SHELF LIFE": 4,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 5,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.832050294337843,
      "MEAN": 0.23076923076923,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 4,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": "B222100000212",
      "DESCRIPTION": "FILTER CORE  B222100000257  HF6870",
      "PRODUCT CATEGORY": "YPG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.666666666666666,
      "MAINTANING STOCK QTY": 3,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 3,
      "APRD QTY": 3,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 0.5,
      "STD": 1.70970082853022,
      "MEAN": 0.615384615384615,
      "COV": 2.7782638463616,
      "SHELF LIFE_1": 4.500000000000004,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 95208971,
      "DESCRIPTION": "FITTING",
      "PRODUCT CATEGORY": "VCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 17,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4110000885,
      "DESCRIPTION": "BELT, COMPRESSOR (A17-490) LG956",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 1,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 13,
      "WHXX": 1,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 20460945,
      "DESCRIPTION": "TURBO CHARGER EC140B BL71PLUS",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 2,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14508324,
      "DESCRIPTION": "LEVER",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 3,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 21581522,
      "DESCRIPTION": "GASKET KIT",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "C01220",
      "DESCRIPTION": "ROD, MOIL POINT SB40",
      "PRODUCT CATEGORY": "SOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 4,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 20459986,
      "DESCRIPTION": "SCREW",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 3030900232,
      "DESCRIPTION": "SHIM",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.916666666666666,
      "MAINTANING STOCK QTY": 4,
      "AVAILABLE": 3,
      "SHELF LIFE": 3.272727272727275,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 57,
      "WHXX": 1,
      "WH REFERENCE": "WHXX",
      "TREND": 0.91,
      "STD": 1.40511884705848,
      "MEAN": 0.846153846153846,
      "COV": 1.66059500106912,
      "SHELF LIFE_1": 4.363636363636367,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 2030900061,
      "DESCRIPTION": "SEALING RING 3030900113 3030900163",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 1,
      "MAINTANING STOCK QTY": 4,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 4,
      "APRD QTY": 0,
      "WH12": 19,
      "WHXX": 3,
      "WH REFERENCE": "WH12 + WHXX",
      "TREND": 0.83,
      "STD": 2.28988858759323,
      "MEAN": 0.923076923076923,
      "COV": 2.48071263655933,
      "SHELF LIFE_1": 4,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 3030900110,
      "DESCRIPTION": "SEAL",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 1,
      "SHELF LIFE": 6.000000000000024,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 44,
      "WHXX": 4,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 3030900111,
      "DESCRIPTION": "SEAL",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 1,
      "SHELF LIFE": 6.000000000000024,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 28,
      "WHXX": 17,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 3030900140,
      "DESCRIPTION": "REVERSE FIRST SPEED DRIVEN DISK",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 1.5,
      "MAINTANING STOCK QTY": 6,
      "AVAILABLE": 6,
      "SHELF LIFE": 4,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 14,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 1.11,
      "STD": 3.40437124734415,
      "MEAN": 1.38461538461538,
      "COV": 2.45871256752633,
      "SHELF LIFE_1": 4,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 2030900028,
      "DESCRIPTION": "REVERSE FIRST SPEED DRIVE DISK",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 1.5,
      "MAINTANING STOCK QTY": 6,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 6,
      "APRD QTY": 6,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 1.11,
      "STD": 3.40437124734415,
      "MEAN": 1.38461538461538,
      "COV": 2.45871256752633,
      "SHELF LIFE_1": 4,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 29050011321,
      "DESCRIPTION": "GASKET",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 26,
      "WHXX": 4,
      "WH REFERENCE": "WHXX",
      "TREND": 1,
      "STD": 0.375533808099405,
      "MEAN": 0.153846153846153,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 4021000015,
      "DESCRIPTION": "BALL BEARING",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 15,
      "WHXX": 5,
      "WH REFERENCE": "WHXX",
      "TREND": 1,
      "STD": 0.375533808099405,
      "MEAN": 0.153846153846153,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 2030900020,
      "DESCRIPTION": "DRIVE DISK ASS‚ÄÔY FOR THE SECOND SPEED",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 6,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 1,
      "STD": 0.75106761619881,
      "MEAN": 0.307692307692307,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 3.000000000000003,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 3030900106,
      "DESCRIPTION": "DRIVEN DISK FOR THE SECOND SPEED",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 8,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 1,
      "STD": 0.375533808099405,
      "MEAN": 0.153846153846153,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 4021000016,
      "DESCRIPTION": "BALL BEARING ",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 15,
      "WHXX": 1,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4021000024,
      "DESCRIPTION": "BALL BEARING ",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 3,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 0.5,
      "STD": 0.85485041426511,
      "MEAN": 0.307692307692307,
      "COV": 2.7782638463616,
      "SHELF LIFE_1": 3.000000000000003,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 4015000026,
      "DESCRIPTION": "WASHER",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 91,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 930761,
      "DESCRIPTION": "EXPANDING NUT",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 12,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 2,
      "STD": 1.10940039245045,
      "MEAN": 0.307692307692307,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 3.000000000000003,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 4110001015031,
      "DESCRIPTION": "TURBOCHARGER ",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.416666666666666,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 2,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 0,
      "STD": 0.869718492622903,
      "MEAN": 0.384615384615384,
      "COV": 2.26126808081954,
      "SHELF LIFE_1": 4.800000000000008,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 4110001015025,
      "DESCRIPTION": "BELT, V RIBBED ALTERNATOR",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.25,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 70,
      "WHXX": 1,
      "WH REFERENCE": "WHXX",
      "TREND": 0.67,
      "STD": 0.438529009653514,
      "MEAN": 0.23076923076923,
      "COV": 1.90029237516522,
      "SHELF LIFE_1": 4,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 20550001,
      "DESCRIPTION": "INJECTION PUMP SD110",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 60022614,
      "DESCRIPTION": "COVER, OIL TANK",
      "PRODUCT CATEGORY": "YPG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 101,
      "WHXX": 10,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 85102464,
      "DESCRIPTION": "ENGINE OIL VDS-3 SAE 15W-40 VTC 15067197",
      "PRODUCT CATEGORY": "LUV",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 17.3333333333333,
      "MAINTANING STOCK QTY": 69,
      "AVAILABLE": 432,
      "SHELF LIFE": 24.92307692307697,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 6216,
      "WHXX": 1312,
      "WH REFERENCE": "",
      "TREND": 1.54,
      "STD": 37.841775856849,
      "MEAN": 16,
      "COV": 2.36511099105306,
      "SHELF LIFE_1": 24.92307692307697,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": "99990-1Z03J",
      "DESCRIPTION": "GEAR OIL HD GL-4 SAE 80W-90 VTC/UD",
      "PRODUCT CATEGORY": "LUV",
      "ITEM MOVEMENT BRANCH": "AVERAGE (F2)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 117.666666666666,
      "MAINTANING STOCK QTY": 471,
      "AVAILABLE": 360,
      "SHELF LIFE": 3.059490084985853,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 111,
      "APRD QTY": 120,
      "WH12": 2220,
      "WHXX": 40,
      "WH REFERENCE": "WH12 + WHXX",
      "TREND": 0.48,
      "STD": 226.755940187366,
      "MEAN": 108.615384615384,
      "COV": 2.0876963331698,
      "SHELF LIFE_1": 4.002832861189824,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 21244697,
      "DESCRIPTION": "RELAY",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 8,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 984756,
      "DESCRIPTION": "FLANGE SCREW 965186 13965186",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.916666666666666,
      "MAINTANING STOCK QTY": 4,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 4,
      "APRD QTY": 4,
      "WH12": 26,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 2,
      "STD": 3.05085107923876,
      "MEAN": 0.846153846153846,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 4.363636363636367,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": "99990-1Z05J",
      "DESCRIPTION": "GEAR OIL HD GL-5 SAE 85W-140 VTC/UD",
      "PRODUCT CATEGORY": "LUV",
      "ITEM MOVEMENT BRANCH": "AVERAGE (F2)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 220.333333333333,
      "MAINTANING STOCK QTY": 881,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 881,
      "APRD QTY": 880,
      "WH12": 9852,
      "WHXX": 140,
      "WH REFERENCE": "WH12 + WHXX",
      "TREND": 0.99,
      "STD": 347.23995988882,
      "MEAN": 203.384615384615,
      "COV": 1.70730691322037,
      "SHELF LIFE_1": 3.9984871406959215,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 984758,
      "DESCRIPTION": "FLANGE SCREW 965188",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 7,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 94,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4130000912,
      "DESCRIPTION": "BATTERY RELAY ",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 13,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 43844471,
      "DESCRIPTION": "VALVE, STEERING SD110",
      "PRODUCT CATEGORY": "VCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 60030172,
      "DESCRIPTION": "STRAIGHT WAY BLOWER ASSEMBLY",
      "PRODUCT CATEGORY": "YPG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 9,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4120002264401,
      "DESCRIPTION": "SEALING KIT 3713CH ",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 4,
      "WHXX": 1,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4120002263401,
      "DESCRIPTION": "SEALING KIT 3713CH",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 9,
      "WHXX": 3,
      "WH REFERENCE": "WHXX",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": "C61252",
      "DESCRIPTION": "ROD PIN SB121",
      "PRODUCT CATEGORY": "SOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.25,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 2,
      "SHELF LIFE": 8,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 20,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.599144689515278,
      "MEAN": 0.23076923076923,
      "COV": 2.5962936545662,
      "SHELF LIFE_1": 8,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": "C61169",
      "DESCRIPTION": "STOP PIN D17.5-L250 SBN121",
      "PRODUCT CATEGORY": "SOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "C61241",
      "DESCRIPTION": "RING BUSH-SB121",
      "PRODUCT CATEGORY": "SOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "D81150",
      "DESCRIPTION": "RUBBER PLUG SB121",
      "PRODUCT CATEGORY": "SOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.5,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 2,
      "SHELF LIFE": 4,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 6,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0.67,
      "STD": 0.877058019307029,
      "MEAN": 0.461538461538461,
      "COV": 1.90029237516522,
      "SHELF LIFE_1": 4,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 11109241,
      "DESCRIPTION": "CLAMP 14881506",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 15,
      "WHXX": 12,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4043000015,
      "DESCRIPTION": "PIN LGB301-85*130R*245-40CR",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 2,
      "WH REFERENCE": "WHXX",
      "TREND": 0,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 4043000043,
      "DESCRIPTION": "SPACER ",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 16,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 17234545,
      "DESCRIPTION": "RUBBER COVER",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 17237872,
      "DESCRIPTION": "NEEDLE",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 3,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 17237890,
      "DESCRIPTION": "RUBBER COVER",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 6,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 984742,
      "DESCRIPTION": "FLANGE SCREW 965182",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 6,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 6,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4043000031,
      "DESCRIPTION": "BUSH ",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.416666666666666,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 2,
      "APRD QTY": 0,
      "WH12": 7,
      "WHXX": 3,
      "WH REFERENCE": "WHXX",
      "TREND": 0.8,
      "STD": 0.767947647788304,
      "MEAN": 0.384615384615384,
      "COV": 1.99666388424959,
      "SHELF LIFE_1": 4.800000000000008,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 4120002264408,
      "DESCRIPTION": "BUSHING",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 990677,
      "DESCRIPTION": "O-RING",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 5,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 14,
      "WHXX": 11,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4130000875,
      "DESCRIPTION": "STARTING SWITCH,   LG936L / LG956L",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 4,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4110001015055,
      "DESCRIPTION": "INJECTOR ",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 0,
      "WHXX": 1,
      "WH REFERENCE": "WHXX",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 11712176,
      "DESCRIPTION": "SEALING RING",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.5,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 2,
      "APRD QTY": 2,
      "WH12": 11,
      "WHXX": 12,
      "WH REFERENCE": "WHXX",
      "TREND": 1.33,
      "STD": 0.877058019307029,
      "MEAN": 0.461538461538461,
      "COV": 1.90029237516522,
      "SHELF LIFE_1": 4,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 4013000203,
      "DESCRIPTION": "NUT, WHEEL HUB ",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 3.33333333333333,
      "MAINTANING STOCK QTY": 13,
      "AVAILABLE": 1,
      "SHELF LIFE": 0.3000000000000003,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 12,
      "APRD QTY": 12,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 1.5,
      "STD": 4.80384461415261,
      "MEAN": 3.07692307692307,
      "COV": 1.56124949959959,
      "SHELF LIFE_1": 3.900000000000004,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 4110000697002,
      "DESCRIPTION": "AIR FILTER, OUTER LG918 ",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "AVERAGE (F2)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 1.08333333333333,
      "MAINTANING STOCK QTY": 4,
      "AVAILABLE": 2,
      "SHELF LIFE": 1.846153846153852,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 2,
      "APRD QTY": 2,
      "WH12": 24,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 1.23,
      "STD": 1.15470053837925,
      "MEAN": 1,
      "COV": 1.15470053837925,
      "SHELF LIFE_1": 3.692307692307704,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 4110000697003,
      "DESCRIPTION": "AIR FILTER, INNER LG918",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 1,
      "SHELF LIFE": 12.000000000000005,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 27,
      "WHXX": 2,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 12.000000000000005,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 14532290,
      "DESCRIPTION": "O-RING 1081-00890",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 0,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 14552079,
      "DESCRIPTION": "SEAL",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 0,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": "L01118",
      "DESCRIPTION": "SSL01118 ROD, (D135) MOILPOINT SU+85",
      "PRODUCT CATEGORY": "SOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 0,
      "STD": 0.375533808099405,
      "MEAN": 0.153846153846153,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 14578415,
      "DESCRIPTION": "HOSE",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 2,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4130001229,
      "DESCRIPTION": "TRANS PRESSURE SENSOR",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4030000789,
      "DESCRIPTION": "OIL SEAL",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.833333333333333,
      "MAINTANING STOCK QTY": 3,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 3,
      "APRD QTY": 3,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 1.6,
      "STD": 2.24179415327122,
      "MEAN": 0.769230769230769,
      "COV": 2.91433239925258,
      "SHELF LIFE_1": 3.6000000000000014,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 21720407,
      "DESCRIPTION": "CONTROL UNIT 21067820",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 6,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 21789668,
      "DESCRIPTION": "GEAR SHIFT CABLE FM9/FM340 21002852",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 84035947,
      "DESCRIPTION": "WIPER BLADE 20826591",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 2,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 98,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4110000037001,
      "DESCRIPTION": "STARTER MOTOR LGG8180",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "A810102070176",
      "DESCRIPTION": "RADIATOR",
      "PRODUCT CATEGORY": "YPG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 21503575,
      "DESCRIPTION": "SEALING RING 1549651",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 24,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14530605,
      "DESCRIPTION": "HEADLIGHTS",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 21793275,
      "DESCRIPTION": "GUIDE WASHER",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 4,
      "WHXX": 5,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 59162149,
      "DESCRIPTION": "LATCH",
      "PRODUCT CATEGORY": "VCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 60013610,
      "DESCRIPTION": "FILTER CORE",
      "PRODUCT CATEGORY": "YPG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 21028667,
      "DESCRIPTION": "ELEMENT, FILTER SCC500E",
      "PRODUCT CATEGORY": "YCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 7,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 1635242,
      "DESCRIPTION": "SPRING LEAF FM9/FM10",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 984735,
      "DESCRIPTION": "FLANGE SCREW 945444",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 49,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4110001020,
      "DESCRIPTION": "RADIATOR- LY-LG956-2B",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 12787620,
      "DESCRIPTION": "EMERGENCY SWITCH 43910579",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 4,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 21063612,
      "DESCRIPTION": "EVAPORATOR",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 1,
      "SHELF LIFE": 6.000000000000024,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 60013292,
      "DESCRIPTION": "FUEL FILTER",
      "PRODUCT CATEGORY": "YCG",
      "ITEM MOVEMENT BRANCH": "AVERAGE (F2)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 1,
      "MAINTANING STOCK QTY": 4,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 4,
      "APRD QTY": 4,
      "WH12": 16,
      "WHXX": 4,
      "WH REFERENCE": "WHXX",
      "TREND": 0.33,
      "STD": 1.89127551586834,
      "MEAN": 0.923076923076923,
      "COV": 2.04888180885737,
      "SHELF LIFE_1": 4,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": "B222100000235",
      "DESCRIPTION": "HYDRAULIC SUCTION FILTER ",
      "PRODUCT CATEGORY": "YCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 2,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "A222100000119",
      "DESCRIPTION": "PILOT FILTER ELEMENT",
      "PRODUCT CATEGORY": "YCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 3,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 22,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4043000338,
      "DESCRIPTION": "PIN LGB301-110*212R*405/2-40CR",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4089460,
      "DESCRIPTION": "PISTON RING SET",
      "PRODUCT CATEGORY": "CUG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 29250000051,
      "DESCRIPTION": "BUSH, FRONT FRAME LG956L/LG958",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "B222100000451",
      "DESCRIPTION": "FILTER CORE, HYD SY5260THB QY50CY",
      "PRODUCT CATEGORY": "YPG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "4130000419A",
      "DESCRIPTION": "RECEIVER LG958",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 8,
      "WHXX": 4,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4130001685,
      "DESCRIPTION": "FORWARD LIGHTNING QZD-1 4130000797 LG936",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 9,
      "SHELF LIFE": 27.00000000000003,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 26,
      "WHXX": 3,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.75106761619881,
      "MEAN": 0.307692307692307,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 27.00000000000003,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 4110000565088,
      "DESCRIPTION": "OIL FILTER 4110000997322",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 177,
      "WHXX": 3,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 7200002385,
      "DESCRIPTION": "FUEL FILTER LG936",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "FAST (F1)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 6.75,
      "MAINTANING STOCK QTY": 27,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 27,
      "APRD QTY": 27,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 1.19,
      "STD": 5.00256344543885,
      "MEAN": 6.23076923076923,
      "COV": 0.802880552971667,
      "SHELF LIFE_1": 4,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 15078846,
      "DESCRIPTION": "GASKET",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4120001805,
      "DESCRIPTION": "STEERING ",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4120001715,
      "DESCRIPTION": "GEAR PUMP ",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4110000991027,
      "DESCRIPTION": "AIR FILTER SET",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.5,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 5,
      "SHELF LIFE": 10,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 28,
      "WHXX": 8,
      "WH REFERENCE": "",
      "TREND": 0.33,
      "STD": 0.776250025806184,
      "MEAN": 0.461538461538461,
      "COV": 1.6818750559134,
      "SHELF LIFE_1": 10,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 4110000054305,
      "DESCRIPTION": "OIL FILTER",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "AVERAGE (F2)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 1.58333333333333,
      "MAINTANING STOCK QTY": 6,
      "AVAILABLE": 8,
      "SHELF LIFE": 5.05263157894738,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 82,
      "WHXX": 17,
      "WH REFERENCE": "",
      "TREND": 1.37,
      "STD": 1.50639661750508,
      "MEAN": 1.46153846153846,
      "COV": 1.03069242250348,
      "SHELF LIFE_1": 5.05263157894738,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 21928687,
      "DESCRIPTION": "DIPSTICK 20482782",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 5,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 20716956,
      "DESCRIPTION": "MIRROR",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.25,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 7,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 1.33,
      "STD": 0.599144689515278,
      "MEAN": 0.23076923076923,
      "COV": 2.5962936545662,
      "SHELF LIFE_1": 4,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 14530651,
      "DESCRIPTION": "CATCH",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14639653,
      "DESCRIPTION": "CAP_FUEL_AIR_VENT EC210B/EC460B 14528919 14626786",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 4,
      "WHXX": 5,
      "WH REFERENCE": "WHXX",
      "TREND": 2,
      "STD": 0.480384461415261,
      "MEAN": 0.307692307692307,
      "COV": 1.56124949959959,
      "SHELF LIFE_1": 3.000000000000003,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 17202573,
      "DESCRIPTION": "PRESSURE SENSOR",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14595186,
      "DESCRIPTION": "HOSE",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 3,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 20183372,
      "DESCRIPTION": "SWITCH",
      "PRODUCT CATEGORY": "VCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3,
      "WHXX": 2,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14529766,
      "DESCRIPTION": "RING",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 5,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 13305198,
      "DESCRIPTION": "HOUR RECORDER",
      "PRODUCT CATEGORY": "VCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 1,
      "SHELF LIFE": 12.000000000000005,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 12.000000000000005,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 14558022,
      "DESCRIPTION": "O-RING",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 4,
      "WHXX": 4,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14558048,
      "DESCRIPTION": "THRUST PLATE",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 2,
      "WHXX": 5,
      "WH REFERENCE": "WHXX",
      "TREND": 2,
      "STD": 0.375533808099405,
      "MEAN": 0.153846153846153,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 6213127,
      "DESCRIPTION": "O-RING-14558009",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.25,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 12,
      "WHXX": 20,
      "WH REFERENCE": "WHXX",
      "TREND": 2,
      "STD": 0.832050294337843,
      "MEAN": 0.23076923076923,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 4,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 11991458,
      "DESCRIPTION": "O-RING 14558007",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 5,
      "WHXX": 4,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14272462,
      "DESCRIPTION": "LOCK WASHER",
      "PRODUCT CATEGORY": "VCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 1.33333333333333,
      "MAINTANING STOCK QTY": 5,
      "AVAILABLE": 10,
      "SHELF LIFE": 7.5000000000000195,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 51,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 4.43760156980183,
      "MEAN": 1.23076923076923,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 7.5000000000000195,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 70893482,
      "DESCRIPTION": "SPRING GAS 12798215",
      "PRODUCT CATEGORY": "VCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 14,
      "WHXX": 8,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 5292738,
      "DESCRIPTION": "THERMOSTAT",
      "PRODUCT CATEGORY": "CUG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 983311,
      "DESCRIPTION": "HEXAGON NUT",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.5,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 2,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 0,
      "STD": 1.66410058867568,
      "MEAN": 0.461538461538461,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 4,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 4110000189006,
      "DESCRIPTION": "FUEL/WATER  FILTER ASSY  LG936L DEUTZ ENG",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 1.25,
      "MAINTANING STOCK QTY": 5,
      "AVAILABLE": 6,
      "SHELF LIFE": 4.8,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 126,
      "WHXX": 18,
      "WH REFERENCE": "",
      "TREND": 1.07,
      "STD": 1.46322439872551,
      "MEAN": 1.15384615384615,
      "COV": 1.26812781222877,
      "SHELF LIFE_1": 4.8,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 4110000189005,
      "DESCRIPTION": "V-BELT, FAN / ALT.  ",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 6,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 39,
      "WHXX": 4,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4110000501,
      "DESCRIPTION": "BELT, A/C",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.916666666666666,
      "MAINTANING STOCK QTY": 4,
      "AVAILABLE": 3,
      "SHELF LIFE": 3.272727272727275,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 2,
      "WHXX": 1,
      "WH REFERENCE": "WHXX",
      "TREND": 1.45,
      "STD": 1.46322439872551,
      "MEAN": 0.846153846153846,
      "COV": 1.72926519849378,
      "SHELF LIFE_1": 4.363636363636367,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": "A222100000575",
      "DESCRIPTION": "OIL FILTER  SCC500 ",
      "PRODUCT CATEGORY": "YCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 1,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "A222100000645",
      "DESCRIPTION": "FUEL FILTER",
      "PRODUCT CATEGORY": "YCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 11418522,
      "DESCRIPTION": "PROPORTIONAL VALVE",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 1,
      "SHELF LIFE": 12.000000000000005,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 12.000000000000005,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": "7273-10930",
      "DESCRIPTION": "SCREW_ORIFICE",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 4,
      "WH REFERENCE": "WHXX",
      "TREND": 1,
      "STD": 0.375533808099405,
      "MEAN": 0.153846153846153,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": "8320-02810",
      "DESCRIPTION": "WASHER",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 6,
      "WHXX": 13,
      "WH REFERENCE": "WHXX",
      "TREND": 2,
      "STD": 0.75106761619881,
      "MEAN": 0.307692307692307,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 3.000000000000003,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 7346454,
      "DESCRIPTION": "O-RING",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 7,
      "WHXX": 10,
      "WH REFERENCE": "WHXX",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 3104044,
      "DESCRIPTION": "SEALING RING",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 8,
      "WHXX": 2,
      "WH REFERENCE": "WHXX",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 14558040,
      "DESCRIPTION": "DISC",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 1.66666666666666,
      "MAINTANING STOCK QTY": 7,
      "AVAILABLE": 7,
      "SHELF LIFE": 4.200000000000016,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 10,
      "WHXX": 55,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 5.54700196225229,
      "MEAN": 1.53846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 4.200000000000016,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 14558041,
      "DESCRIPTION": "DISC",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.916666666666666,
      "MAINTANING STOCK QTY": 4,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 4,
      "APRD QTY": 4,
      "WH12": 8,
      "WHXX": 26,
      "WH REFERENCE": "WHXX",
      "TREND": 2,
      "STD": 3.05085107923876,
      "MEAN": 0.846153846153846,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 4.363636363636367,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 14558042,
      "DESCRIPTION": "DISC",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.583333333333333,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 2,
      "APRD QTY": 2,
      "WH12": 16,
      "WHXX": 14,
      "WH REFERENCE": "WHXX",
      "TREND": 2,
      "STD": 1.9414506867883,
      "MEAN": 0.538461538461538,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 3.42857142857143,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 14558046,
      "DESCRIPTION": "SHIM",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 2,
      "WHXX": 2,
      "WH REFERENCE": "WHXX",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 14558047,
      "DESCRIPTION": "SPRING",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 3,
      "WHXX": 5,
      "WH REFERENCE": "WHXX",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 14558049,
      "DESCRIPTION": "CAGE",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 2,
      "WHXX": 6,
      "WH REFERENCE": "WHXX",
      "TREND": 2,
      "STD": 0.375533808099405,
      "MEAN": 0.153846153846153,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 11705188,
      "DESCRIPTION": "WASHER",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 11705193,
      "DESCRIPTION": "FRICTION DISC",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 1,
      "MAINTANING STOCK QTY": 4,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 4,
      "APRD QTY": 4,
      "WH12": 0,
      "WHXX": 13,
      "WH REFERENCE": "WHXX",
      "TREND": 2,
      "STD": 3.32820117735137,
      "MEAN": 0.923076923076923,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 4,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 14558030,
      "DESCRIPTION": "O-RING",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.25,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 8,
      "WHXX": 9,
      "WH REFERENCE": "WHXX",
      "TREND": 2,
      "STD": 0.832050294337843,
      "MEAN": 0.23076923076923,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 4,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 14558031,
      "DESCRIPTION": "GASKET",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 6,
      "WHXX": 3,
      "WH REFERENCE": "WHXX",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 14558034,
      "DESCRIPTION": "GASKET",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 7,
      "WHXX": 5,
      "WH REFERENCE": "WHXX",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 14558035,
      "DESCRIPTION": "GASKET",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 8,
      "WHXX": 4,
      "WH REFERENCE": "WHXX",
      "TREND": 2,
      "STD": 0.375533808099405,
      "MEAN": 0.153846153846153,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 14558036,
      "DESCRIPTION": "GASKET",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 10,
      "WHXX": 10,
      "WH REFERENCE": "WHXX",
      "TREND": 2,
      "STD": 0.375533808099405,
      "MEAN": 0.153846153846153,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 14558037,
      "DESCRIPTION": "GASKET",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 10,
      "WHXX": 5,
      "WH REFERENCE": "WHXX",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 14558059,
      "DESCRIPTION": "BALL BEARING",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 3,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 11706773,
      "DESCRIPTION": "THRUST BEARING",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3,
      "WHXX": 5,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 11715510,
      "DESCRIPTION": "ROTOR KIT",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3,
      "WHXX": 3,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 11706719,
      "DESCRIPTION": "O-RING 8220-02680",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3,
      "WHXX": 6,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14577801,
      "DESCRIPTION": "SEALING KIT, BOOM CYL.   EW145B/PRIME",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 13,
      "SHELF LIFE": 78.00000000000031,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 8,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 78.00000000000031,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 14577802,
      "DESCRIPTION": "SEALING KIT, ARM CYL. EW145B/PRIME",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.416666666666666,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 4,
      "SHELF LIFE": 9.600000000000016,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 9,
      "WHXX": 7,
      "WH REFERENCE": "",
      "TREND": 0.8,
      "STD": 0.506369683541833,
      "MEAN": 0.384615384615384,
      "COV": 1.31656117720876,
      "SHELF LIFE_1": 9.600000000000016,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 14589125,
      "DESCRIPTION": "SEALING KIT, BUCKET CYL.  EW145B",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.25,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 1,
      "SHELF LIFE": 4,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 18,
      "WHXX": 8,
      "WH REFERENCE": "",
      "TREND": 0.67,
      "STD": 0.438529009653514,
      "MEAN": 0.23076923076923,
      "COV": 1.90029237516522,
      "SHELF LIFE_1": 4,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 21955032,
      "DESCRIPTION": "V-RIBBED BELT, FAN FM370 20983634",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 4,
      "WHXX": 1,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 17216318,
      "DESCRIPTION": "PRESS SENSOR G930/L150F 11419573  11038813 ",
      "PRODUCT CATEGORY": "VGG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 2,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "A229900008854",
      "DESCRIPTION": "ELECTRONIC THROTTLE PEDAL",
      "PRODUCT CATEGORY": "YCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 13933868,
      "DESCRIPTION": "ELBOW NIPPLE",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 5,
      "WHXX": 5,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14543195,
      "DESCRIPTION": "PIN 1172-06870  14512689 ",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 1635244,
      "DESCRIPTION": "SPRING LEAF",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 1635245,
      "DESCRIPTION": "SPRING LEAF NOTLPA",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 946810,
      "DESCRIPTION": "LOCK NUT",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 47,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 2,
      "STD": 1.10940039245045,
      "MEAN": 0.307692307692307,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 3.000000000000003,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 14630975,
      "DESCRIPTION": "WIPER ARM",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 1,
      "SHELF LIFE": 3.000000000000003,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 7,
      "WHXX": 5,
      "WH REFERENCE": "",
      "TREND": 0.5,
      "STD": 0.480384461415261,
      "MEAN": 0.307692307692307,
      "COV": 1.56124949959959,
      "SHELF LIFE_1": 3.000000000000003,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 977005,
      "DESCRIPTION": "O-RING",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.583333333333333,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 2,
      "SHELF LIFE": 3.42857142857143,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 33,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 1.33012434352235,
      "MEAN": 0.538461538461538,
      "COV": 2.47023092368437,
      "SHELF LIFE_1": 3.42857142857143,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 14632032,
      "DESCRIPTION": "WIPER BLADE",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.583333333333333,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 5,
      "SHELF LIFE": 8.571428571428577,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 5,
      "WH REFERENCE": "",
      "TREND": 0.57,
      "STD": 0.877058019307029,
      "MEAN": 0.538461538461538,
      "COV": 1.62882203585591,
      "SHELF LIFE_1": 8.571428571428577,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 15082401,
      "DESCRIPTION": "WINDOW",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 992248,
      "DESCRIPTION": "ELBOW NIPPLE",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4190002150,
      "DESCRIPTION": "AIR SPRING",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 11705229,
      "DESCRIPTION": "NEEDLE BEARING",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "23Y-58-11000",
      "DESCRIPTION": "A/C RESERVOIR (SD22/SD23/SD32)",
      "PRODUCT CATEGORY": "SDG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 17201798,
      "DESCRIPTION": "SWITCH",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 1,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 29050011381,
      "DESCRIPTION": "SEALING SPACER",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 22,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 1,
      "STD": 0.375533808099405,
      "MEAN": 0.153846153846153,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 4030000011,
      "DESCRIPTION": "O-RING GB1235-20*2.4",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.25,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 1,
      "SHELF LIFE": 4,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 1,
      "WH REFERENCE": "",
      "TREND": 1.33,
      "STD": 0.599144689515278,
      "MEAN": 0.23076923076923,
      "COV": 2.5962936545662,
      "SHELF LIFE_1": 4,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 4021000012,
      "DESCRIPTION": "BEARING ",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 40,
      "WHXX": 6,
      "WH REFERENCE": "WHXX",
      "TREND": 1,
      "STD": 0.375533808099405,
      "MEAN": 0.153846153846153,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 4021000019,
      "DESCRIPTION": "BEARING ",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.5,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 2,
      "APRD QTY": 0,
      "WH12": 52,
      "WHXX": 9,
      "WH REFERENCE": "WHXX",
      "TREND": 1,
      "STD": 1.12660142429821,
      "MEAN": 0.461538461538461,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 4,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 4021000020,
      "DESCRIPTION": "BEARING ",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 11,
      "WHXX": 6,
      "WH REFERENCE": "WHXX",
      "TREND": 1,
      "STD": 0.75106761619881,
      "MEAN": 0.307692307692307,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 3.000000000000003,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 4021000040,
      "DESCRIPTION": "THRUST BALL BEARING GB301-51111",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 20,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 1,
      "STD": 0.375533808099405,
      "MEAN": 0.153846153846153,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 4021000013,
      "DESCRIPTION": "BALL BEARING GB276-6016",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 4,
      "WH REFERENCE": "WHXX",
      "TREND": 1,
      "STD": 0.375533808099405,
      "MEAN": 0.153846153846153,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 4021000011,
      "DESCRIPTION": "BALL BEARING",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 10,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 1,
      "STD": 0.375533808099405,
      "MEAN": 0.153846153846153,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 3030900168,
      "DESCRIPTION": "ISOLATED BUSHING",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 16,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 3030900146,
      "DESCRIPTION": "SEALING RING",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 23,
      "WHXX": 1,
      "WH REFERENCE": "WHXX",
      "TREND": 1,
      "STD": 0.375533808099405,
      "MEAN": 0.153846153846153,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 4021000076,
      "DESCRIPTION": "BEARING ",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 4,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4030000012,
      "DESCRIPTION": "O-RING GB1235-22*2.4",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 11,
      "WHXX": 3,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4030000731,
      "DESCRIPTION": "O-RING GB1235-30*2.4",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 4,
      "WHXX": 3,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 16847926,
      "DESCRIPTION": "WASHER 15182663",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.666666666666666,
      "MAINTANING STOCK QTY": 3,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 3,
      "APRD QTY": 3,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "PARTIAL WH12",
      "TREND": 2,
      "STD": 2.21880078490091,
      "MEAN": 0.615384615384615,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 4.500000000000004,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 996519,
      "DESCRIPTION": "BALL BEARING 993853",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 5,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14616326,
      "DESCRIPTION": "PIN, BUCKET EC210B 1172-06910, 14512676 14543130 ",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 5,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 15107882,
      "DESCRIPTION": "WHEEL STUD",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 10,
      "MAINTANING STOCK QTY": 40,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 40,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 0,
      "STD": 33.2820117735137,
      "MEAN": 9.23076923076923,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 4,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 4130000860,
      "DESCRIPTION": "PRESSURE SWITCH YK210B",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 12,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 21900852,
      "DESCRIPTION": "FUEL FILTER HOUSING, FWS",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 996518,
      "DESCRIPTION": "BALL BEARING EC210B 993852",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 2,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 1,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 21615194,
      "DESCRIPTION": "DISC",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 11,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 29250004041,
      "DESCRIPTION": "UP ARTICULATION PIN",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14635576,
      "DESCRIPTION": "PIN, MASTER EC460B/EC480D",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.416666666666666,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 2,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 3,
      "WH REFERENCE": "WHXX",
      "TREND": 0.4,
      "STD": 0.869718492622904,
      "MEAN": 0.384615384615384,
      "COV": 2.26126808081955,
      "SHELF LIFE_1": 4.800000000000008,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 14378640,
      "DESCRIPTION": "DIRECTION INDICATOR",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 11,
      "WHXX": 6,
      "WH REFERENCE": "WHXX",
      "TREND": 1,
      "STD": 0.630425171956115,
      "MEAN": 0.307692307692307,
      "COV": 2.04888180885737,
      "SHELF LIFE_1": 3.000000000000003,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 14570116,
      "DESCRIPTION": "PIN, REG EC460B/EC480D",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.5,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 2,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 5,
      "WH REFERENCE": "WHXX",
      "TREND": 0.67,
      "STD": 0.967417922046845,
      "MEAN": 0.461538461538461,
      "COV": 2.09607216443483,
      "SHELF LIFE_1": 4,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 14570590,
      "DESCRIPTION": "BUSHING, REG. EC460B/EC480D",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.5,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 2,
      "APRD QTY": 0,
      "WH12": 3,
      "WHXX": 4,
      "WH REFERENCE": "WHXX",
      "TREND": 0.67,
      "STD": 0.967417922046845,
      "MEAN": 0.461538461538461,
      "COV": 2.09607216443483,
      "SHELF LIFE_1": 4,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 14570591,
      "DESCRIPTION": "BUSHING, MASTER EC460B/EC480D",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.416666666666666,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 2,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 3,
      "WH REFERENCE": "WHXX",
      "TREND": 0.4,
      "STD": 0.869718492622904,
      "MEAN": 0.384615384615384,
      "COV": 2.26126808081955,
      "SHELF LIFE_1": 4.800000000000008,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 14570114,
      "DESCRIPTION": "LINK EC460B/EC480D",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.5,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 2,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 7,
      "WH REFERENCE": "WHXX",
      "TREND": 0.67,
      "STD": 0.967417922046845,
      "MEAN": 0.461538461538461,
      "COV": 2.09607216443483,
      "SHELF LIFE_1": 4,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 14570115,
      "DESCRIPTION": "LINK EC460B/EC480D",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.5,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 2,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 7,
      "WH REFERENCE": "WHXX",
      "TREND": 0.67,
      "STD": 0.967417922046845,
      "MEAN": 0.461538461538461,
      "COV": 2.09607216443483,
      "SHELF LIFE_1": 4,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 4130000326,
      "DESCRIPTION": "POSITION SWITCH  ",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 12,
      "WHXX": 1,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4130000861,
      "DESCRIPTION": "PRESSURE TRANSDUCER",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 18,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14671766,
      "DESCRIPTION": "RECOIL SPRING 14532269 ",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "402269Z00A",
      "DESCRIPTION": "WHEEL NUT",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 2.5,
      "MAINTANING STOCK QTY": 10,
      "AVAILABLE": 23,
      "SHELF LIFE": 9.2,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 5,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 8.32050294337843,
      "MEAN": 2.3076923076923,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 9.2,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": "252309Z00B",
      "DESCRIPTION": "RELAY",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 20,
      "WHXX": 8,
      "WH REFERENCE": "WHXX",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": "451799Z00A",
      "DESCRIPTION": "AIR DRIER FILTER KIT 21620181 20972915 21508133",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "AVERAGE (F2)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 3.41666666666666,
      "MAINTANING STOCK QTY": 14,
      "AVAILABLE": 3,
      "SHELF LIFE": 0.8780487804878067,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 11,
      "APRD QTY": 11,
      "WH12": 14,
      "WHXX": 13,
      "WH REFERENCE": "WHXX",
      "TREND": 0.83,
      "STD": 3.76045550977878,
      "MEAN": 3.15384615384615,
      "COV": 1.19233955188107,
      "SHELF LIFE_1": 4.097560975609764,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": "252309Z00C",
      "DESCRIPTION": "RELAY",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 7,
      "WHXX": 6,
      "WH REFERENCE": "WHXX",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": "252309Z00A",
      "DESCRIPTION": "RELAY",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 1,
      "SHELF LIFE": 6.000000000000024,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 458,
      "WHXX": 8,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": "252309Z00D",
      "DESCRIPTION": "RELAY",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 42,
      "WHXX": 3,
      "WH REFERENCE": "WHXX",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": "149739Z00A",
      "DESCRIPTION": "TEMPERATURE SENSOR",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 7,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "963019Z01C",
      "DESCRIPTION": "5224039760 MIRROR KIT",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 2,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "265559Z00B",
      "DESCRIPTION": "5223502052 TAIL LAMP CLUSTER; LEFT",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "265559Z00A",
      "DESCRIPTION": "TAIL LAMP CLUSTER; RIGHT",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 1,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "302009Z01B",
      "DESCRIPTION": "CLUTCH CWE370, CGE420, CWE420",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "301009Z00A",
      "DESCRIPTION": "5223994807 CLUTCH",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "304029Z00A",
      "DESCRIPTION": "RELEASE BEARING",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 3,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 15,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "487169Z00A",
      "DESCRIPTION": "STEERING FILTER",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "AVERAGE (F2)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 3.5,
      "MAINTANING STOCK QTY": 14,
      "AVAILABLE": 13,
      "SHELF LIFE": 3.7142857142857144,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 38,
      "WHXX": 137,
      "WH REFERENCE": "WHXX",
      "TREND": 1.33,
      "STD": 4.76364437088954,
      "MEAN": 3.23076923076923,
      "COV": 1.47446135289438,
      "SHELF LIFE_1": 4,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": "255659Z00A",
      "DESCRIPTION": "SPEED SENSOR",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 4,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 16819626,
      "DESCRIPTION": "ACTUATOR",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "A229900003077",
      "DESCRIPTION": "BATTERY BOX COVER ASSEMBLY",
      "PRODUCT CATEGORY": "YCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 21743197,
      "DESCRIPTION": "AIR CLEANER 21064484  147719Z00A",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.666666666666666,
      "MAINTANING STOCK QTY": 3,
      "AVAILABLE": 7,
      "SHELF LIFE": 10.50000000000001,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 1,
      "STD": 1.50213523239762,
      "MEAN": 0.615384615384615,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 10.50000000000001,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 29170042151,
      "DESCRIPTION": "BOLT ON EDGE ( KIT )",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 1,
      "SHELF LIFE": 3.000000000000003,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0.5,
      "STD": 0.630425171956115,
      "MEAN": 0.307692307692307,
      "COV": 2.04888180885737,
      "SHELF LIFE_1": 3.000000000000003,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 4030000065,
      "DESCRIPTION": "LUBRICATING NIPPLE 29270008001 ",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.666666666666666,
      "MAINTANING STOCK QTY": 3,
      "AVAILABLE": 3,
      "SHELF LIFE": 4.500000000000004,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 5,
      "WHXX": 4,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 2.21880078490091,
      "MEAN": 0.615384615384615,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 4.500000000000004,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 4120000506,
      "DESCRIPTION": "SPRING BRAKE CYLINDER  LG936  LG956L",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 9,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 60154188,
      "DESCRIPTION": "RIGHT CONNECTOR (CAFF)",
      "PRODUCT CATEGORY": "YPG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 60154192,
      "DESCRIPTION": "LEFT CONNECTOR (CAFF)",
      "PRODUCT CATEGORY": "YPG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 21611489,
      "DESCRIPTION": "GASKET L60C/L70C/L90E/BL71/EC210B 20459202",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 1.16666666666666,
      "MAINTANING STOCK QTY": 5,
      "AVAILABLE": 7,
      "SHELF LIFE": 6.000000000000034,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 54,
      "WHXX": 16,
      "WH REFERENCE": "",
      "TREND": 0.86,
      "STD": 1.32045058354704,
      "MEAN": 1.07692307692307,
      "COV": 1.22613268472226,
      "SHELF LIFE_1": 6.000000000000034,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 21789667,
      "DESCRIPTION": "GEAR SHIFT CABLE FM9/FM340  21343551",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 4,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 20450728,
      "DESCRIPTION": "BOLT",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4110000371001,
      "DESCRIPTION": "ALTERNATOR ",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14616736,
      "DESCRIPTION": "CONTROL UNIT",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 21695313,
      "DESCRIPTION": "E-ECU A35D/EC460C/EC700B 20814604",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 2,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 16631876,
      "DESCRIPTION": "AIR FILTER, PRIMARY 14519261",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.25,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 1,
      "SHELF LIFE": 4,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 26,
      "WHXX": 14,
      "WH REFERENCE": "",
      "TREND": 0.67,
      "STD": 0.599144689515278,
      "MEAN": 0.23076923076923,
      "COV": 2.5962936545662,
      "SHELF LIFE_1": 4,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 21003566,
      "DESCRIPTION": "INTERMEDIATE RELAY 4RD 007 903-02",
      "PRODUCT CATEGORY": "YCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "A210111000090",
      "DESCRIPTION": "BOLT-M8√Ó25  01010-31225",
      "PRODUCT CATEGORY": "YCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 85,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "A210405000007",
      "DESCRIPTION": "UNTERLEGSCHEIBE D8,4",
      "PRODUCT CATEGORY": "YCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 40,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "A210307000012",
      "DESCRIPTION": "NUT-M8",
      "PRODUCT CATEGORY": "YCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.583333333333333,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 4,
      "SHELF LIFE": 6.85714285714286,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 5,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 1.9414506867883,
      "MEAN": 0.538461538461538,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.85714285714286,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 20798699,
      "DESCRIPTION": "INLET VALVE 21896409 ",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 12,
      "WHXX": 2,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4110000556128,
      "DESCRIPTION": "SEALING WASHER ",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 11,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 990853,
      "DESCRIPTION": "O-RING",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.666666666666666,
      "MAINTANING STOCK QTY": 3,
      "AVAILABLE": 3,
      "SHELF LIFE": 4.500000000000004,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 17,
      "WHXX": 43,
      "WH REFERENCE": "",
      "TREND": 1,
      "STD": 0.960768922830522,
      "MEAN": 0.615384615384615,
      "COV": 1.56124949959959,
      "SHELF LIFE_1": 4.500000000000004,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": "8220-02620",
      "DESCRIPTION": "O-RING",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 1,
      "SHELF LIFE": 6.000000000000024,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 7,
      "WHXX": 3,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": "A240700000339",
      "DESCRIPTION": "DC CONTACTOR MZJ-200A/006",
      "PRODUCT CATEGORY": "YCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14616327,
      "DESCRIPTION": "PIN, LINK TO BUCKET  EC210B 14544079",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 1,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "A210401000016",
      "DESCRIPTION": "SPRING WASHER",
      "PRODUCT CATEGORY": "YCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": "A210307000016",
      "DESCRIPTION": "NUT-M6",
      "PRODUCT CATEGORY": "YCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 11381209,
      "DESCRIPTION": "53032790 ELECTRONIC UNIT FOR SN -280198 11443993",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "L51109",
      "DESCRIPTION": "THROUGH BOLT",
      "PRODUCT CATEGORY": "SOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 8,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 11110702,
      "DESCRIPTION": "FILTER HOUSING, FWS  W/ HEATER 11110708",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.5,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 1,
      "SHELF LIFE": 2,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 3,
      "WHXX": 3,
      "WH REFERENCE": "WHXX",
      "TREND": 1.33,
      "STD": 0.660225291773524,
      "MEAN": 0.461538461538461,
      "COV": 1.43048813217597,
      "SHELF LIFE_1": 4,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 4120000553401,
      "DESCRIPTION": "SEALING KIT, ST CYL.  ",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 12,
      "WHXX": 1,
      "WH REFERENCE": "WHXX",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 17286432,
      "DESCRIPTION": "PRESSURE SWITCH A40G",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "327059Z00C",
      "DESCRIPTION": "SENSOR",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 6,
      "WHXX": 27,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "307059Z00B",
      "DESCRIPTION": "OVERHAUL KIT, CLUTCH SERVO",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 4,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 6,
      "WHXX": 18,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 994785,
      "DESCRIPTION": "SIX POINT SOCKET SCREW 969422",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 1.16666666666666,
      "MAINTANING STOCK QTY": 5,
      "AVAILABLE": 5,
      "SHELF LIFE": 4.28571428571431,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 104,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 3.32820117735137,
      "MEAN": 1.07692307692307,
      "COV": 3.09047252182627,
      "SHELF LIFE_1": 4.28571428571431,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 954494,
      "DESCRIPTION": "BATTERY CABLE",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 4,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": "211409Z00B",
      "DESCRIPTION": "V-RIBBED BELT  8PK1560",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 25,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "344379Z00B",
      "DESCRIPTION": "BALL JOINT",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 9,
      "WHXX": 7,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "963479Z00A",
      "DESCRIPTION": "COVER",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 18,
      "WHXX": 2,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "963479Z00C",
      "DESCRIPTION": "COVER",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 10,
      "WHXX": 4,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "165289Z00A",
      "DESCRIPTION": "AIR FILTER-SECONDARY",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "AVERAGE (F2)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 3.08333333333333,
      "MAINTANING STOCK QTY": 12,
      "AVAILABLE": 7,
      "SHELF LIFE": 2.270270270270273,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 5,
      "APRD QTY": 5,
      "WH12": 10,
      "WHXX": 37,
      "WH REFERENCE": "WHXX",
      "TREND": 1.14,
      "STD": 3.55542200603889,
      "MEAN": 2.84615384615384,
      "COV": 1.24920232644609,
      "SHELF LIFE_1": 3.891891891891896,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": "165289Z00B",
      "DESCRIPTION": "AIR FILTER-PRIMARY",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "AVERAGE (F2)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 4.66666666666666,
      "MAINTANING STOCK QTY": 19,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 19,
      "APRD QTY": 19,
      "WH12": 34,
      "WHXX": 43,
      "WH REFERENCE": "WHXX",
      "TREND": 1.07,
      "STD": 5.37563353451813,
      "MEAN": 4.3076923076923,
      "COV": 1.24791492765599,
      "SHELF LIFE_1": 4.071428571428577,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": "301009Z00B",
      "DESCRIPTION": "CLUTCH DISC",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 8,
      "WHXX": 2,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "260109Z00D",
      "DESCRIPTION": "5224056780 HEADLAMP",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "260109Z00C",
      "DESCRIPTION": "5224056781 HEADLAMP",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "288909Z00A",
      "DESCRIPTION": "WIPER BLADE",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 6,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "164019Z00A",
      "DESCRIPTION": "5222899626 FUEL PRESSURE SENSOR",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "211409Z00A",
      "DESCRIPTION": "V-RIBBED BELT",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 4,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 6,
      "WHXX": 2,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "147719Z00A",
      "DESCRIPTION": "FUEL VENTILATION FILTER 21743197",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "AVERAGE (F2)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 3.16666666666666,
      "MAINTANING STOCK QTY": 13,
      "AVAILABLE": 11,
      "SHELF LIFE": 3.4736842105263235,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 2,
      "APRD QTY": 2,
      "WH12": 0,
      "WHXX": 2,
      "WH REFERENCE": "WHXX",
      "TREND": 1.37,
      "STD": 3.83973823894152,
      "MEAN": 2.92307692307692,
      "COV": 1.31359466069052,
      "SHELF LIFE_1": 4.105263157894746,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": "203039Z00C",
      "DESCRIPTION": "COMBINATION VALVE",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "231009Z00A",
      "DESCRIPTION": "ALTERNATOR",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "288909Z00B",
      "DESCRIPTION": "WIPER BLADE",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 5,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "306209Z00A",
      "DESCRIPTION": "MASTER CYLINDER",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 7,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "146769Z01C",
      "DESCRIPTION": "SEALING RING, OIL PAN",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "FAST (F1)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 5.16666666666666,
      "MAINTANING STOCK QTY": 21,
      "AVAILABLE": 6,
      "SHELF LIFE": 1.1612903225806468,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 15,
      "APRD QTY": 0,
      "WH12": 143,
      "WHXX": 42,
      "WH REFERENCE": "WHXX",
      "TREND": 0.71,
      "STD": 3.63212165163939,
      "MEAN": 4.76923076923076,
      "COV": 0.761573894698582,
      "SHELF LIFE_1": 4.064516129032263,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": "154-70-11143",
      "DESCRIPTION": "154-71-41270V010 PLOW BOLT & NUT- CUTTING EDGE",
      "PRODUCT CATEGORY": "SDG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 12.75,
      "MAINTANING STOCK QTY": 51,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 51,
      "APRD QTY": 0,
      "WH12": 131,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 0.33,
      "STD": 35.2471129932505,
      "MEAN": 11.7692307692307,
      "COV": 2.99485273798861,
      "SHELF LIFE_1": 4,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 4130001551,
      "DESCRIPTION": "WHITE REAR TAILIGHT",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 15010225,
      "DESCRIPTION": "BRAKE PLATE",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4021000018,
      "DESCRIPTION": "BEARING ",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 6,
      "WHXX": 3,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14618826,
      "DESCRIPTION": "FUEL HOSE",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 2,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "333029Z00A",
      "DESCRIPTION": "GASKET",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 8,
      "WHXX": 9,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "333029Z00B",
      "DESCRIPTION": "GASKET",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 24,
      "WHXX": 9,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "333029Z00C",
      "DESCRIPTION": "GASKET",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 23,
      "WHXX": 9,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "726119Z00A",
      "DESCRIPTION": "WEATHERSTRIP",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 7,
      "WHXX": 5,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 9014536073,
      "DESCRIPTION": "ENGINE, REMAN  D6E EAE2 ENG. SN 70001- / 80001-  EC210B PRIME",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "911119Z02A",
      "DESCRIPTION": "FLANGE NUT",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.416666666666666,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 2,
      "APRD QTY": 0,
      "WH12": 93,
      "WHXX": 131,
      "WH REFERENCE": "WHXX",
      "TREND": 0,
      "STD": 1.38675049056307,
      "MEAN": 0.384615384615384,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 4.800000000000008,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": "276949Z00A",
      "DESCRIPTION": "5282685304 CAB FILTER",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.5,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 2,
      "SHELF LIFE": 4,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0.33,
      "STD": 0.967417922046845,
      "MEAN": 0.461538461538461,
      "COV": 2.09607216443483,
      "SHELF LIFE_1": 4,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 22089205,
      "DESCRIPTION": "BELT TENSIONER 20554253 22088967 ",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 8,
      "WHXX": 7,
      "WH REFERENCE": "WHXX",
      "TREND": 1,
      "STD": 0.375533808099405,
      "MEAN": 0.153846153846153,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 59155309,
      "DESCRIPTION": "SOLENOID VALVE",
      "PRODUCT CATEGORY": "VCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 2,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 994448,
      "DESCRIPTION": "FLANGE SCREW 948336",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 32,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 20849976,
      "DESCRIPTION": "VALVE SEAT",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 24,
      "WHXX": 8,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 20849979,
      "DESCRIPTION": "VALVE SEAT",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 10,
      "WHXX": 15,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 20798533,
      "DESCRIPTION": "EXHAUST VALVE",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 12,
      "WHXX": 11,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 15144849,
      "DESCRIPTION": "RETAINER",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 2,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 8,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 11143271,
      "DESCRIPTION": "SEALING RING",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 2,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 9,
      "WHXX": 10,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 15144848,
      "DESCRIPTION": "INTERMEDIATE RING",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 2,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 8,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "C31446",
      "DESCRIPTION": "ROD PIN  SU+85",
      "PRODUCT CATEGORY": "SOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 1,
      "SHELF LIFE": 3.000000000000003,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.75106761619881,
      "MEAN": 0.307692307692307,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 3.000000000000003,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": "99990-1Z01J",
      "DESCRIPTION": "UD DIESEL ENGINE OIL MEGA MULTI 3 SAE 15W-40",
      "PRODUCT CATEGORY": "LUV",
      "ITEM MOVEMENT BRANCH": "AVERAGE (F2)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 243,
      "MAINTANING STOCK QTY": 972,
      "AVAILABLE": 332,
      "SHELF LIFE": 1.3662551440329218,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 640,
      "APRD QTY": 640,
      "WH12": 2760,
      "WHXX": 408,
      "WH REFERENCE": "WH12 + WHXX",
      "TREND": 0.61,
      "STD": 407.108377178891,
      "MEAN": 224.307692307692,
      "COV": 1.81495504229272,
      "SHELF LIFE_1": 4,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 4011000186,
      "DESCRIPTION": "BOLT ",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 32,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 22243151,
      "DESCRIPTION": "CABLE HARNESS 22068267",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 1,
      "SHELF LIFE": 6.000000000000024,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 2,
      "WH REFERENCE": "",
      "TREND": 1,
      "STD": 0.375533808099405,
      "MEAN": 0.153846153846153,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 43977354,
      "DESCRIPTION": "OIL FILTER  SD45D/F  LF3925",
      "PRODUCT CATEGORY": "VCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.25,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 12,
      "WHXX": 3,
      "WH REFERENCE": "WHXX",
      "TREND": 0,
      "STD": 0.832050294337843,
      "MEAN": 0.23076923076923,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 4,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 43977388,
      "DESCRIPTION": "FUEL FILTER, SD45/SD70/DD70D",
      "PRODUCT CATEGORY": "VCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.25,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 20,
      "WHXX": 1,
      "WH REFERENCE": "WHXX",
      "TREND": 0,
      "STD": 0.832050294337843,
      "MEAN": 0.23076923076923,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 4,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 14558043,
      "DESCRIPTION": "DISC",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 3,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14558044,
      "DESCRIPTION": "WASHER",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 3,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "A222100000166",
      "DESCRIPTION": "AIR FILTER KIT  ",
      "PRODUCT CATEGORY": "YPG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.416666666666666,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 5,
      "SHELF LIFE": 12.000000000000018,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 17,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0.8,
      "STD": 0.767947647788304,
      "MEAN": 0.384615384615384,
      "COV": 1.99666388424959,
      "SHELF LIFE_1": 12.000000000000018,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 4110000509232,
      "DESCRIPTION": "FUEL FILTER",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.666666666666666,
      "MAINTANING STOCK QTY": 3,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 3,
      "APRD QTY": 3,
      "WH12": 120,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 1.25,
      "STD": 1.19292787840544,
      "MEAN": 0.615384615384615,
      "COV": 1.93850780240885,
      "SHELF LIFE_1": 4.500000000000004,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 4110001169001,
      "DESCRIPTION": "AIR FILTER ( INNER & OUTER)",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 1.08333333333333,
      "MAINTANING STOCK QTY": 4,
      "AVAILABLE": 1,
      "SHELF LIFE": 0.923076923076926,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 3,
      "APRD QTY": 3,
      "WH12": 38,
      "WHXX": 6,
      "WH REFERENCE": "WHXX",
      "TREND": 0.31,
      "STD": 2.48327740429189,
      "MEAN": 1,
      "COV": 2.48327740429189,
      "SHELF LIFE_1": 3.692307692307704,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 4120004037,
      "DESCRIPTION": "FILTER INSERT, HYD ASSY. ( THIS IS ASSY, USE 4120004037001 FOR PMS)",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 11,
      "WHXX": 3,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4120004036003,
      "DESCRIPTION": "FILTER INSERT",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "AVERAGE (F2)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 1.08333333333333,
      "MAINTANING STOCK QTY": 4,
      "AVAILABLE": 2,
      "SHELF LIFE": 1.846153846153852,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 2,
      "APRD QTY": 2,
      "WH12": 45,
      "WHXX": 4,
      "WH REFERENCE": "WHXX",
      "TREND": 1.54,
      "STD": 1.47196014438797,
      "MEAN": 1,
      "COV": 1.47196014438797,
      "SHELF LIFE_1": 3.692307692307704,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": "C11226",
      "DESCRIPTION": "DIAPHARGM",
      "PRODUCT CATEGORY": "SOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 1,
      "SHELF LIFE": 12.000000000000005,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 12.000000000000005,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 4015000024,
      "DESCRIPTION": "RING SNAP ",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 26,
      "WHXX": 7,
      "WH REFERENCE": "WHXX",
      "TREND": 1,
      "STD": 0.75106761619881,
      "MEAN": 0.307692307692307,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 3.000000000000003,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 14510586,
      "DESCRIPTION": "AIR CLEANER ASSY,  EC210BLC",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 990610,
      "DESCRIPTION": "O-RING",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 8,
      "WHXX": 1,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14522575,
      "DESCRIPTION": "CLIP",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 4,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "150-30-13442",
      "DESCRIPTION": "PACKING",
      "PRODUCT CATEGORY": "SDG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.5,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 2,
      "APRD QTY": 2,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 1.33,
      "STD": 1.19828937903055,
      "MEAN": 0.461538461538461,
      "COV": 2.5962936545662,
      "SHELF LIFE_1": 4,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 60101256,
      "DESCRIPTION": "HYDRAULIC RETURN FILTER",
      "PRODUCT CATEGORY": "YCG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 1,
      "SHELF LIFE": 3.000000000000003,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 4,
      "WHXX": 1,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 1.10940039245045,
      "MEAN": 0.307692307692307,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 3.000000000000003,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 14558028,
      "DESCRIPTION": "RING",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14558029,
      "DESCRIPTION": "RING",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "214009Z00E",
      "DESCRIPTION": "RADIATOR 214109Z00C",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 2,
      "WH REFERENCE": "WHXX",
      "TREND": 1,
      "STD": 0.375533808099405,
      "MEAN": 0.153846153846153,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": "218019Z01B",
      "DESCRIPTION": "CHARGE AIR COOLER",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 11705190,
      "DESCRIPTION": "DISC",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 0,
      "WHXX": 3,
      "WH REFERENCE": "WHXX",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 12760005,
      "DESCRIPTION": "CABLE",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3,
      "WHXX": 2,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14521609,
      "DESCRIPTION": "ELEMENT",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 6,
      "WHXX": 9,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 20739572,
      "DESCRIPTION": "CLAMP 8149651",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 44,
      "WHXX": 7,
      "WH REFERENCE": "WHXX",
      "TREND": 0,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 4043000027,
      "DESCRIPTION": "BUSH ",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.666666666666666,
      "MAINTANING STOCK QTY": 3,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 3,
      "APRD QTY": 0,
      "WH12": 16,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 0.75,
      "STD": 1.04390784542678,
      "MEAN": 0.615384615384615,
      "COV": 1.69635024881852,
      "SHELF LIFE_1": 4.500000000000004,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 21103569,
      "DESCRIPTION": "RUBBER MOULDING",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 1,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 15118696,
      "DESCRIPTION": "KEYBOARD",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.666666666666666,
      "MAINTANING STOCK QTY": 3,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 3,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 2,
      "WH REFERENCE": "PARTIAL WHXX",
      "TREND": 0.5,
      "STD": 1.12089707663561,
      "MEAN": 0.615384615384615,
      "COV": 1.82145774953286,
      "SHELF LIFE_1": 4.500000000000004,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": "146829Z00B",
      "DESCRIPTION": "F-NIPPLE",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.833333333333333,
      "MAINTANING STOCK QTY": 3,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 3,
      "APRD QTY": 0,
      "WH12": 32,
      "WHXX": 85,
      "WH REFERENCE": "WHXX",
      "TREND": 0,
      "STD": 2.77350098112614,
      "MEAN": 0.769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 3.6000000000000014,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": "146719Z00C",
      "DESCRIPTION": "SWIVEL",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.833333333333333,
      "MAINTANING STOCK QTY": 3,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 3,
      "APRD QTY": 0,
      "WH12": 93,
      "WHXX": 65,
      "WH REFERENCE": "WHXX",
      "TREND": 0,
      "STD": 2.77350098112614,
      "MEAN": 0.769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 3.6000000000000014,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 43844422,
      "DESCRIPTION": "HYDRAULIC MOTOR  SD100C/D / SD110",
      "PRODUCT CATEGORY": "VCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14589153,
      "DESCRIPTION": "SEALING KIT",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14385098,
      "DESCRIPTION": "VALVE",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "146729Z00D",
      "DESCRIPTION": "NIPPLE",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 10,
      "WHXX": 16,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "32624NA00A",
      "DESCRIPTION": "NIPPLE",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.833333333333333,
      "MAINTANING STOCK QTY": 3,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 3,
      "APRD QTY": 0,
      "WH12": 25,
      "WHXX": 15,
      "WH REFERENCE": "WHXX",
      "TREND": 0,
      "STD": 2.77350098112614,
      "MEAN": 0.769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 3.6000000000000014,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": "2002200Z0D",
      "DESCRIPTION": "V-CLAMP",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 8,
      "WHXX": 4,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "2002200Z0E",
      "DESCRIPTION": "EXHAUST PIPE CLAMP",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 18,
      "WHXX": 2,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "282299Z04B",
      "DESCRIPTION": "FLANGE SCREW",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 39,
      "WHXX": 50,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "111129Z00A",
      "DESCRIPTION": "GASKET",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 5,
      "WHXX": 8,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "150109Z00A",
      "DESCRIPTION": "OIL PUMP",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14634767,
      "DESCRIPTION": "SUPPORT 14522195",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 4,
      "WHXX": 1,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "32624NA00B",
      "DESCRIPTION": "SWIVEL",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 15,
      "WHXX": 4,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4120000742,
      "DESCRIPTION": "CHARGE VALVE FOR TIRE",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4856263,
      "DESCRIPTION": "HOSE, AIR FILTER  L150C",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 952967,
      "DESCRIPTION": "HOSE",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "E91120",
      "DESCRIPTION": "FRONT HEAD PIN  SU+85",
      "PRODUCT CATEGORY": "SOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 2,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": "E91121",
      "DESCRIPTION": "STOP PIN  SU+85",
      "PRODUCT CATEGORY": "SOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.5,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 2,
      "SHELF LIFE": 4,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 1.33,
      "STD": 0.877058019307029,
      "MEAN": 0.461538461538461,
      "COV": 1.90029237516522,
      "SHELF LIFE_1": 4,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 2050900054,
      "DESCRIPTION": "BEARING, AXLE  LG936L",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 5,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 15074447,
      "DESCRIPTION": "WINDSCREEN  A35F / A40F",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 20854656,
      "DESCRIPTION": "CYL. LINER KIT  D7DEAE2   EC290B",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 1,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "797409Z00A",
      "DESCRIPTION": "5224079398 WINDSHIELD",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 4,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "611109Z00D",
      "DESCRIPTION": "FRONT PANEL",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "231529Z00A",
      "DESCRIPTION": "FLANGE NUT",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 117,
      "WHXX": 120,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "4120002263J",
      "DESCRIPTION": "4120002263T LIFT CYLINDER  LG956L",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4015000030,
      "DESCRIPTION": "WASHER, LOCK ",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 4.33333333333333,
      "MAINTANING STOCK QTY": 17,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 17,
      "APRD QTY": 17,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 1.15,
      "STD": 5.29150262212918,
      "MEAN": 4,
      "COV": 1.32287565553229,
      "SHELF LIFE_1": 3.9230769230769256,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 13976433,
      "DESCRIPTION": "FLANGE NUT L120F",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 1.58333333333333,
      "MAINTANING STOCK QTY": 6,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 6,
      "APRD QTY": 6,
      "WH12": 26,
      "WHXX": 30,
      "WH REFERENCE": "WHXX",
      "TREND": 2,
      "STD": 5.26965186413967,
      "MEAN": 1.46153846153846,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 3.7894736842105345,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 14508740,
      "DESCRIPTION": "SENSOR LEVEL, EC210B/290B/360B/460B",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14511214,
      "DESCRIPTION": "O-RING KIT (EXCAVATORS)",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 3,
      "SHELF LIFE": 18.000000000000075,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 1,
      "STD": 0.375533808099405,
      "MEAN": 0.153846153846153,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 18.000000000000075,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 14514378,
      "DESCRIPTION": "WIRE HARNESS",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 22,
      "WHXX": 4,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14517747,
      "DESCRIPTION": "ADAPTER",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14517748,
      "DESCRIPTION": "ADAPTER",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3,
      "WHXX": 3,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14517754,
      "DESCRIPTION": "HOSE CLAMP",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 13,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14518023,
      "DESCRIPTION": "PROPELLER SHAFT EW145B",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14523552,
      "DESCRIPTION": "TOOTH (VTS) 30GPE EC210B/EC240B 14523946",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 3.33333333333333,
      "MAINTANING STOCK QTY": 13,
      "AVAILABLE": 107,
      "SHELF LIFE": 32.10000000000003,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 716,
      "WHXX": 59,
      "WH REFERENCE": "",
      "TREND": 1.5,
      "STD": 4.34859246311452,
      "MEAN": 3.07692307692307,
      "COV": 1.41329255051221,
      "SHELF LIFE_1": 32.10000000000003,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 14528387,
      "DESCRIPTION": "OIL FILTER EC55B",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.833333333333333,
      "MAINTANING STOCK QTY": 3,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 3,
      "APRD QTY": 3,
      "WH12": 6,
      "WHXX": 17,
      "WH REFERENCE": "WHXX",
      "TREND": 1.6,
      "STD": 1.23516841994969,
      "MEAN": 0.769230769230769,
      "COV": 1.6057189459346,
      "SHELF LIFE_1": 3.6000000000000014,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 14529152,
      "DESCRIPTION": "STARTER SWITCH",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 1,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 6,
      "WHXX": 3,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14529757,
      "DESCRIPTION": "O-RING",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.5,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 2,
      "APRD QTY": 0,
      "WH12": 14,
      "WHXX": 62,
      "WH REFERENCE": "WHXX",
      "TREND": 0,
      "STD": 1.66410058867568,
      "MEAN": 0.461538461538461,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 4,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 14531072,
      "DESCRIPTION": "FLEXIBLE COUPLING, HYD SYSTEM  EC210B / EC290B",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 1,
      "SHELF LIFE": 6.000000000000024,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 9,
      "WHXX": 9,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 14533603,
      "DESCRIPTION": "O-RING",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.5,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 2,
      "APRD QTY": 0,
      "WH12": 77,
      "WHXX": 62,
      "WH REFERENCE": "WHXX",
      "TREND": 0,
      "STD": 1.66410058867568,
      "MEAN": 0.461538461538461,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 4,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 14535448,
      "DESCRIPTION": "BUSHING",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14537622,
      "DESCRIPTION": "WASHER, TOOTH EC290B 1070-13030",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 2.16666666666666,
      "MAINTANING STOCK QTY": 9,
      "AVAILABLE": 4,
      "SHELF LIFE": 1.846153846153852,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 5,
      "APRD QTY": 5,
      "WH12": 89,
      "WHXX": 24,
      "WH REFERENCE": "WHXX",
      "TREND": 2,
      "STD": 5.65685424949238,
      "MEAN": 2,
      "COV": 2.82842712474619,
      "SHELF LIFE_1": 4.153846153846167,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 14541720,
      "DESCRIPTION": "SENSOR_LEVEL EC210B",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 4,
      "WHXX": 2,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14543949,
      "DESCRIPTION": "PIN, BOOM EC210B",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 1,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 2,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14545465,
      "DESCRIPTION": "ADJUSTING DEVICE",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 1,
      "SHELF LIFE": 6.000000000000024,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 5,
      "WHXX": 12,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.375533808099405,
      "MEAN": 0.153846153846153,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 14545883,
      "DESCRIPTION": "COUPLING 1-1/4\"",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 4,
      "WHXX": 1,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14547395,
      "DESCRIPTION": "PISTON BUSHING",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 61,
      "WHXX": 1,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14576774,
      "DESCRIPTION": "BLOWER MOTOR, A/C UNIT  14514331 ",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.416666666666666,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 2,
      "APRD QTY": 2,
      "WH12": 1,
      "WHXX": 2,
      "WH REFERENCE": "WHXX",
      "TREND": 2,
      "STD": 0.65044363558799,
      "MEAN": 0.384615384615384,
      "COV": 1.69115345252877,
      "SHELF LIFE_1": 4.800000000000008,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 14579495,
      "DESCRIPTION": "COOLING FAN, ENG  EC210B (TROPICAL) ",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14616040,
      "DESCRIPTION": "TOGGLE SWITCH 14526156",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 4,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14880450,
      "DESCRIPTION": "LOCK NUT, HEX SE",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 21,
      "WHXX": 7,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 15038313,
      "DESCRIPTION": "SENSOR, ACC. RETARDER 11708270",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 3,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 15047153,
      "DESCRIPTION": "HOUR RECORDER 73152",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 2,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 15082295,
      "DESCRIPTION": "STARTER SWITCH A30C/G710B/G930 11039220",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 15082742,
      "DESCRIPTION": "COMPRESSOR EC290B 11412632",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.25,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 1,
      "SHELF LIFE": 4,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 32,
      "WHXX": 1,
      "WH REFERENCE": "",
      "TREND": 1.33,
      "STD": 0.438529009653514,
      "MEAN": 0.23076923076923,
      "COV": 1.90029237516522,
      "SHELF LIFE_1": 4,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 15103658,
      "DESCRIPTION": "PRESSURE SENSOR 11444373",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3,
      "WHXX": 2,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 15126069,
      "DESCRIPTION": "54315806 FUEL FILTER (X8)",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "AVERAGE (F2)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 2.91666666666666,
      "MAINTANING STOCK QTY": 12,
      "AVAILABLE": 20,
      "SHELF LIFE": 6.857142857142873,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 14,
      "WH REFERENCE": "",
      "TREND": 0.57,
      "STD": 1.79743406854583,
      "MEAN": 2.69230769230769,
      "COV": 0.667618368317024,
      "SHELF LIFE_1": 6.857142857142873,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 184838,
      "DESCRIPTION": "ROLLER BEARING",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 20405822,
      "DESCRIPTION": "SEALING RING EC210B",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 3,
      "WHXX": 6,
      "WH REFERENCE": "WHXX",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 20412308,
      "DESCRIPTION": "HEX NUT L120F",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.666666666666666,
      "MAINTANING STOCK QTY": 3,
      "AVAILABLE": 15,
      "SHELF LIFE": 22.500000000000025,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 58,
      "WHXX": 33,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 2.21880078490091,
      "MEAN": 0.615384615384615,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 22.500000000000025,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 20412314,
      "DESCRIPTION": "STUD",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.666666666666666,
      "MAINTANING STOCK QTY": 3,
      "AVAILABLE": 15,
      "SHELF LIFE": 22.500000000000025,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 21,
      "WHXX": 32,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 2.21880078490091,
      "MEAN": 0.615384615384615,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 22.500000000000025,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 20412325,
      "DESCRIPTION": "SEAL RING",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 12,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 20412589,
      "DESCRIPTION": "FILLER CAP ( ENG. D7D EAE2) G710B EC290B",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.416666666666666,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 2,
      "APRD QTY": 2,
      "WH12": 9,
      "WHXX": 16,
      "WH REFERENCE": "WHXX",
      "TREND": 1.6,
      "STD": 0.506369683541833,
      "MEAN": 0.384615384615384,
      "COV": 1.31656117720876,
      "SHELF LIFE_1": 4.800000000000008,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 20450707,
      "DESCRIPTION": "TACHOMETER EC210B/G700B/G930/L60E",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.416666666666666,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 2,
      "SHELF LIFE": 4.800000000000008,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 7,
      "WHXX": 9,
      "WH REFERENCE": "",
      "TREND": 0.8,
      "STD": 0.65044363558799,
      "MEAN": 0.384615384615384,
      "COV": 1.69115345252877,
      "SHELF LIFE_1": 4.800000000000008,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 20450736,
      "DESCRIPTION": "THERMOSTAT BL71/G710B/G930/EC210B",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.583333333333333,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 1,
      "SHELF LIFE": 1.714285714285715,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 18,
      "WHXX": 7,
      "WH REFERENCE": "WHXX",
      "TREND": 1.43,
      "STD": 1.12660142429821,
      "MEAN": 0.538461538461538,
      "COV": 2.0922597879824,
      "SHELF LIFE_1": 3.42857142857143,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 20459203,
      "DESCRIPTION": "GASKET L60C/L70C/L90E/BL71/EC210B",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.416666666666666,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 2,
      "SHELF LIFE": 4.800000000000008,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 14,
      "WHXX": 6,
      "WH REFERENCE": "",
      "TREND": 0.8,
      "STD": 0.506369683541833,
      "MEAN": 0.384615384615384,
      "COV": 1.31656117720876,
      "SHELF LIFE_1": 4.800000000000008,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 20459961,
      "DESCRIPTION": "SEALING RING, WATER PUMP EC210B/G700B/G930/SD110",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.5,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 2,
      "APRD QTY": 2,
      "WH12": 0,
      "WHXX": 43,
      "WH REFERENCE": "WHXX",
      "TREND": 1.67,
      "STD": 1.12660142429821,
      "MEAN": 0.461538461538461,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 4,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 20460022,
      "DESCRIPTION": "GASKET",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 1,
      "SHELF LIFE": 12.000000000000005,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 10,
      "WHXX": 10,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 12.000000000000005,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 20502536,
      "DESCRIPTION": "SEALING RING  EC210B",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 1,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 2,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 20505959,
      "DESCRIPTION": "HEX NUT L120E",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 1,
      "MAINTANING STOCK QTY": 4,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 4,
      "APRD QTY": 4,
      "WH12": 140,
      "WHXX": 25,
      "WH REFERENCE": "WHXX",
      "TREND": 2,
      "STD": 3.32820117735137,
      "MEAN": 0.923076923076923,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 4,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 20531832,
      "DESCRIPTION": "24052228 GASKET",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 7,
      "WHXX": 8,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 20557208,
      "DESCRIPTION": "GASKET EC210B/BL71/L60E/L120E",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.5,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 2,
      "APRD QTY": 2,
      "WH12": 2,
      "WHXX": 6,
      "WH REFERENCE": "WHXX",
      "TREND": 2,
      "STD": 1.66410058867568,
      "MEAN": 0.461538461538461,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 4,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 20758403,
      "DESCRIPTION": "24063494 HEATER L120E/EC210B 20498227",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 20775463,
      "DESCRIPTION": "PULLEY, ALTERNATOR EC210B",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 19,
      "WHXX": 5,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 20798076,
      "DESCRIPTION": "O-RING",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 6,
      "WHXX": 4,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 20798827,
      "DESCRIPTION": "O-RING",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 1.33333333333333,
      "MAINTANING STOCK QTY": 5,
      "AVAILABLE": 15,
      "SHELF LIFE": 11.250000000000028,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 50,
      "WH REFERENCE": "",
      "TREND": 1.25,
      "STD": 2.38585575681089,
      "MEAN": 1.23076923076923,
      "COV": 1.93850780240885,
      "SHELF LIFE_1": 11.250000000000028,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 20805349,
      "DESCRIPTION": "54315408 FUEL FILTER (X10)",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "FAST (F1)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 20.75,
      "MAINTANING STOCK QTY": 83,
      "AVAILABLE": 15,
      "SHELF LIFE": 0.7228915662650602,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 68,
      "APRD QTY": 68,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 0.49,
      "STD": 17.420515462361,
      "MEAN": 19.1538461538461,
      "COV": 0.909504823336116,
      "SHELF LIFE_1": 4,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 20850096,
      "DESCRIPTION": "BIG END BEARING KIT  D6EEAE2/3 ",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.5,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 2,
      "SHELF LIFE": 4,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 7,
      "WHXX": 11,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 1.66410058867568,
      "MEAN": 0.461538461538461,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 4,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 20865990,
      "DESCRIPTION": "CYL. LINER KIT  D7EEAE2  SN: 17001-, 35001-,85001-  EC290B PRIME",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 16,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 20933261,
      "DESCRIPTION": "GASKET L120F",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 4,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 26,
      "WHXX": 10,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 20943385,
      "DESCRIPTION": "CYL. HEAD GASKET 1.4MM 1HOLE L120F EC290B",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 2,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 20949868,
      "DESCRIPTION": "CYLINDER HEAD GASKET 1.5MM 2HOLES EC290B",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 4,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4789826,
      "DESCRIPTION": "O-RING KIT, WHEEL   ARTIC/LOADER",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 13,
      "WHXX": 3,
      "WH REFERENCE": "WHXX",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 4870186,
      "DESCRIPTION": "SEALING RING",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 2,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 6848760,
      "DESCRIPTION": "O-RING A35D",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.416666666666666,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 2,
      "APRD QTY": 2,
      "WH12": 0,
      "WHXX": 15,
      "WH REFERENCE": "WHXX",
      "TREND": 2,
      "STD": 1.38675049056307,
      "MEAN": 0.384615384615384,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 4.800000000000008,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 79089,
      "DESCRIPTION": "DURAMIDE G930",
      "PRODUCT CATEGORY": "VGG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 5,
      "WHXX": 6,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 914167,
      "DESCRIPTION": "NIPPLE EC210B/EC460B/G930",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 2.16666666666666,
      "MAINTANING STOCK QTY": 9,
      "AVAILABLE": 8,
      "SHELF LIFE": 3.692307692307704,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 708,
      "WHXX": 65,
      "WH REFERENCE": "WHXX",
      "TREND": 0.15,
      "STD": 6.04152298679728,
      "MEAN": 2,
      "COV": 3.02076149339864,
      "SHELF LIFE_1": 4.153846153846167,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 943470,
      "DESCRIPTION": "HOSE CLAMP",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 15,
      "WHXX": 21,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 946471,
      "DESCRIPTION": "FLANGE SCREW",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.5,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 2,
      "APRD QTY": 0,
      "WH12": 33,
      "WHXX": 45,
      "WH REFERENCE": "WHXX",
      "TREND": 0,
      "STD": 1.66410058867568,
      "MEAN": 0.461538461538461,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 4,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 947972,
      "DESCRIPTION": "WHEEL LUG NUT A30/A25C/L150F",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 10,
      "MAINTANING STOCK QTY": 40,
      "AVAILABLE": 3,
      "SHELF LIFE": 0.3,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 37,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 0,
      "STD": 33.2820117735137,
      "MEAN": 9.23076923076923,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 4,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 949874,
      "DESCRIPTION": "FLANGE LOCK NUT",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 11,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 955921,
      "DESCRIPTION": "WASHER SPRING 9213-08000",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 1,
      "SHELF LIFE": 3.000000000000003,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 29,
      "WHXX": 54,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 1.10940039245045,
      "MEAN": 0.307692307692307,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 3.000000000000003,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 955926,
      "DESCRIPTION": "SPRING WASHER (16MM) 01602-01648",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 1,
      "MAINTANING STOCK QTY": 4,
      "AVAILABLE": 4,
      "SHELF LIFE": 4,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1571,
      "WHXX": 30,
      "WH REFERENCE": "",
      "TREND": 1.33,
      "STD": 2.39657875806111,
      "MEAN": 0.923076923076923,
      "COV": 2.5962936545662,
      "SHELF LIFE_1": 4,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 960254,
      "DESCRIPTION": "O-RING EC210B/240B/290B 9511-22190 ",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.666666666666666,
      "MAINTANING STOCK QTY": 3,
      "AVAILABLE": 3,
      "SHELF LIFE": 4.500000000000004,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 110,
      "WHXX": 13,
      "WH REFERENCE": "",
      "TREND": 1,
      "STD": 0.960768922830522,
      "MEAN": 0.615384615384615,
      "COV": 1.56124949959959,
      "SHELF LIFE_1": 4.500000000000004,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 960265,
      "DESCRIPTION": "O-RING 14573761",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 967711,
      "DESCRIPTION": "992518 BULB 4W 24V G930/L150F",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 969404,
      "DESCRIPTION": "SIX POINT SOCKET SCREW",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 10,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 2,
      "STD": 1.10940039245045,
      "MEAN": 0.307692307692307,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 3.000000000000003,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 977525,
      "DESCRIPTION": "V-BELT, A/C EC210B/L120E/G930",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.416666666666666,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 2,
      "SHELF LIFE": 4.800000000000008,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 5,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.869718492622904,
      "MEAN": 0.384615384615384,
      "COV": 2.26126808081955,
      "SHELF LIFE_1": 4.800000000000008,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 978722,
      "DESCRIPTION": "V-BELT, FAN EC210B/L120E/G930 15078709 ",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 5,
      "SHELF LIFE": 30.00000000000012,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 5,
      "WHXX": 17,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 30.00000000000012,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 978999,
      "DESCRIPTION": "BLADE FUSE 20A 11039328 A30C/EC210B-P/EC460B/C/EC700B/G930/L150F/FM400",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.416666666666666,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 20,
      "SHELF LIFE": 48.00000000000007,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 9,
      "WHXX": 2,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 1.38675049056307,
      "MEAN": 0.384615384615384,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 48.00000000000007,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 980827,
      "DESCRIPTION": "O-RING A30",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3,
      "WHXX": 25,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 983260,
      "DESCRIPTION": "997471 SCREW HEX HD",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 983304,
      "DESCRIPTION": "997408 NUT_HEX",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 983509,
      "DESCRIPTION": "O-RING 14533610 14534048",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 3,
      "SHELF LIFE": 36.000000000000014,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 49,
      "WHXX": 10,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 36.000000000000014,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 990743,
      "DESCRIPTION": "O-RING EC210B 949239 ",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.25,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 67,
      "WHXX": 14,
      "WH REFERENCE": "WHXX",
      "TREND": 2,
      "STD": 0.832050294337843,
      "MEAN": 0.23076923076923,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 4,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 990851,
      "DESCRIPTION": "O-RING",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 34,
      "WHXX": 10,
      "WH REFERENCE": "WHXX",
      "TREND": 1,
      "STD": 0.375533808099405,
      "MEAN": 0.153846153846153,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 992520,
      "DESCRIPTION": "BULB 10W 24V 13949185 967710",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 1,
      "SHELF LIFE": 6.000000000000024,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 24,
      "WHXX": 4,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 80681281,
      "DESCRIPTION": "HYDRAULIC FILTER  ABG5820/ABG8820B",
      "PRODUCT CATEGORY": "VPG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.25,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 0,
      "STD": 0.832050294337843,
      "MEAN": 0.23076923076923,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 4,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": "1030-61460",
      "DESCRIPTION": "ELEMENT, PILOT HYDRAULIC_",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "FAST (F1)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 5.33333333333333,
      "MAINTANING STOCK QTY": 21,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 21,
      "APRD QTY": 0,
      "WH12": 863,
      "WHXX": 121,
      "WH REFERENCE": "WHXX",
      "TREND": 0.94,
      "STD": 6.62648145023106,
      "MEAN": 4.92307692307692,
      "COV": 1.34600404457818,
      "SHELF LIFE_1": 3.937500000000002,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": "1030-61680",
      "DESCRIPTION": "ELEMENT, HYDRAULIC EW145B",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.916666666666666,
      "MAINTANING STOCK QTY": 4,
      "AVAILABLE": 38,
      "SHELF LIFE": 41.45454545454549,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 4,
      "WHXX": 12,
      "WH REFERENCE": "",
      "TREND": 0.73,
      "STD": 0.987096233585649,
      "MEAN": 0.846153846153846,
      "COV": 1.16656827605576,
      "SHELF LIFE_1": 41.45454545454549,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 11110283,
      "DESCRIPTION": "AIR FILTER_PRI EC140B / EW145B",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "FAST (F1)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 5.33333333333333,
      "MAINTANING STOCK QTY": 21,
      "AVAILABLE": 22,
      "SHELF LIFE": 4.125000000000003,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 675,
      "WHXX": 23,
      "WH REFERENCE": "",
      "TREND": 0.56,
      "STD": 7.76167441623195,
      "MEAN": 4.92307692307692,
      "COV": 1.57659011579711,
      "SHELF LIFE_1": 4.125000000000003,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 11110284,
      "DESCRIPTION": "AIR FILTER  SEC",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "AVERAGE (F2)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 3.83333333333333,
      "MAINTANING STOCK QTY": 15,
      "AVAILABLE": 6,
      "SHELF LIFE": 1.5652173913043492,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 9,
      "APRD QTY": 9,
      "WH12": 220,
      "WHXX": 36,
      "WH REFERENCE": "WHXX",
      "TREND": 0.52,
      "STD": 6.2930568170456,
      "MEAN": 3.53846153846153,
      "COV": 1.77847257873027,
      "SHELF LIFE_1": 3.913043478260873,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 11110532,
      "DESCRIPTION": "AIR FILTER, PRIM EC700B 15013668",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 15,
      "SHELF LIFE": 90.00000000000037,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 89,
      "WHXX": 10,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.375533808099405,
      "MEAN": 0.153846153846153,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 90.00000000000037,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 11110533,
      "DESCRIPTION": "AIR FILTER, SECONDARY 15193232 ",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 5,
      "SHELF LIFE": 15.000000000000016,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 32,
      "WHXX": 13,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 1.10940039245045,
      "MEAN": 0.307692307692307,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 15.000000000000016,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 11110670,
      "DESCRIPTION": "FUEL WATER SEPARATOR EC210B/290B/L90E/BL71/G710B",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3,
      "WHXX": 3,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 11110737,
      "DESCRIPTION": "54864253 BOWL, FWS W/O HEATER  EC210B/290B/G930/L120F 11110674",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 1,
      "STD": 0.375533808099405,
      "MEAN": 0.153846153846153,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": "1122-01690",
      "DESCRIPTION": "182040 BULB_ROOM LAMP EC460C 7019926",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 3,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 3,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "1141-00010",
      "DESCRIPTION": "STRAINER, HYD TANK",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "AVERAGE (F2)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 2.08333333333333,
      "MAINTANING STOCK QTY": 8,
      "AVAILABLE": 9,
      "SHELF LIFE": 4.320000000000007,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3,
      "WHXX": 2,
      "WH REFERENCE": "",
      "TREND": 0.72,
      "STD": 2.75262112847429,
      "MEAN": 1.92307692307692,
      "COV": 1.43136298680663,
      "SHELF LIFE_1": 4.320000000000007,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 11417118,
      "DESCRIPTION": "TOOTH 10GPL VTS L70F",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 8,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "1146-06870",
      "DESCRIPTION": "PAD",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 1,
      "SHELF LIFE": 12.000000000000005,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 48,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 12.000000000000005,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 11708550,
      "DESCRIPTION": "OIL FILTER EC140B",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.833333333333333,
      "MAINTANING STOCK QTY": 3,
      "AVAILABLE": 3,
      "SHELF LIFE": 3.6000000000000014,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 34,
      "WHXX": 11,
      "WH REFERENCE": "",
      "TREND": 1.2,
      "STD": 1.16575055606864,
      "MEAN": 0.769230769230769,
      "COV": 1.51547572288924,
      "SHELF LIFE_1": 3.6000000000000014,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 11708551,
      "DESCRIPTION": "OIL FILTER",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.416666666666666,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 3,
      "SHELF LIFE": 7.200000000000011,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 51,
      "WHXX": 38,
      "WH REFERENCE": "",
      "TREND": 0.4,
      "STD": 1.12089707663561,
      "MEAN": 0.384615384615384,
      "COV": 2.91433239925258,
      "SHELF LIFE_1": 7.200000000000011,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 11709635,
      "DESCRIPTION": "V-BELT, FAN DRIVE",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "AVERAGE (F2)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 1.91666666666666,
      "MAINTANING STOCK QTY": 8,
      "AVAILABLE": 5,
      "SHELF LIFE": 2.608695652173922,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 3,
      "APRD QTY": 3,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 1.22,
      "STD": 1.78670302297491,
      "MEAN": 1.76923076923076,
      "COV": 1.00987562168147,
      "SHELF LIFE_1": 4.173913043478275,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": "1171-00171",
      "DESCRIPTION": "SIDE BLADE RH EC210B",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 1,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "1171-00181",
      "DESCRIPTION": "SIDE BLADE LH EC210B",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 1,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 11988962,
      "DESCRIPTION": "FUEL FILTER",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 23,
      "WHXX": 19,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 11988975,
      "DESCRIPTION": "BALL BRG-8220-05830",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 0,
      "WHXX": 4,
      "WH REFERENCE": "WHXX",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 13947622,
      "DESCRIPTION": "PLANE GASKET",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 3,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 77,
      "WHXX": 18,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 13955894,
      "DESCRIPTION": "994868 WASHER",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14386186,
      "DESCRIPTION": "MIRROR",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 5,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14500233,
      "DESCRIPTION": "ELEMENT, BREATHER",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 44,
      "SHELF LIFE": 528.0000000000002,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 49,
      "WHXX": 60,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 528.0000000000002,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 14501846,
      "DESCRIPTION": "SEAL",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 3,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14502207,
      "DESCRIPTION": "RADIATOR CAP",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 3,
      "WH REFERENCE": "WHXX",
      "TREND": 1,
      "STD": 0.375533808099405,
      "MEAN": 0.153846153846153,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 14503269,
      "DESCRIPTION": "FILTER, CAB",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "FAST (F1)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 5.41666666666666,
      "MAINTANING STOCK QTY": 22,
      "AVAILABLE": 48,
      "SHELF LIFE": 8.861538461538473,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 101,
      "WHXX": 4,
      "WH REFERENCE": "",
      "TREND": 0.74,
      "STD": 6.24499799839839,
      "MEAN": 5,
      "COV": 1.24899959967967,
      "SHELF LIFE_1": 8.861538461538473,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 14504913,
      "DESCRIPTION": "HOSE EC210B",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.25,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 5,
      "WH REFERENCE": "WHXX",
      "TREND": 0.67,
      "STD": 0.438529009653514,
      "MEAN": 0.23076923076923,
      "COV": 1.90029237516522,
      "SHELF LIFE_1": 4,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 14505515,
      "DESCRIPTION": "SENSOR EC210B/290B/360B/460B/700B",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 6,
      "WHXX": 1,
      "WH REFERENCE": "WHXX",
      "TREND": 2,
      "STD": 0.375533808099405,
      "MEAN": 0.153846153846153,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 14506407,
      "DESCRIPTION": "SEAL KIT",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 8,
      "WHXX": 3,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14506606,
      "DESCRIPTION": "SEAL KIT",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 10,
      "WHXX": 6,
      "WH REFERENCE": "WHXX",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 14506770,
      "DESCRIPTION": "ROD, CAB DOOR  EC210B",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 5,
      "WHXX": 3,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14506789,
      "DESCRIPTION": "CABLE",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 5,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14506844,
      "DESCRIPTION": "GLASS_FRONT LOWER EC210B/EC460B",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 4,
      "WHXX": 5,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14506889,
      "DESCRIPTION": "SEAL KIT_CONTROL VALVE EC210B",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.75,
      "MAINTANING STOCK QTY": 3,
      "AVAILABLE": 3,
      "SHELF LIFE": 4,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 14,
      "WH REFERENCE": "",
      "TREND": 1.33,
      "STD": 0.947330933431341,
      "MEAN": 0.692307692307692,
      "COV": 1.36836690384527,
      "SHELF LIFE_1": 4,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 14508632,
      "DESCRIPTION": "WIPER BLADE _LOWER EC210B/EC460B/EC700B",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 3,
      "SHELF LIFE": 36.000000000000014,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 4,
      "WHXX": 2,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 36.000000000000014,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 14508854,
      "DESCRIPTION": "LATCH_SIDE DOOR EC210B/EC460B",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 11,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14508911,
      "DESCRIPTION": "RADIAL SEAL",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 20,
      "WHXX": 3,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14509331,
      "DESCRIPTION": "VALVE, EXPANSION A/C",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "AVERAGE (F2)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 1.25,
      "MAINTANING STOCK QTY": 5,
      "AVAILABLE": 6,
      "SHELF LIFE": 4.8,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 22,
      "WHXX": 8,
      "WH REFERENCE": "",
      "TREND": 1.6,
      "STD": 1.81870621808259,
      "MEAN": 1.15384615384615,
      "COV": 1.57621205567158,
      "SHELF LIFE_1": 4.8,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 14509377,
      "DESCRIPTION": "RECEIVER DRIER EC210B/EC460B/EC700B",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3,
      "WHXX": 2,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14509379,
      "DESCRIPTION": "14776946  ELEMENT, HYD RETURN_",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "AVERAGE (F2)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 3.66666666666666,
      "MAINTANING STOCK QTY": 15,
      "AVAILABLE": 33,
      "SHELF LIFE": 9.000000000000016,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 332,
      "WHXX": 43,
      "WH REFERENCE": "",
      "TREND": 0.91,
      "STD": 3.79777262656374,
      "MEAN": 3.38461538461538,
      "COV": 1.1220691851211,
      "SHELF LIFE_1": 9.000000000000016,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 14510496,
      "DESCRIPTION": "CHANNEL",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 4,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14510584,
      "DESCRIPTION": "HOSE_INLET EC210B",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 4,
      "WH REFERENCE": "WHXX",
      "TREND": 1,
      "STD": 0.375533808099405,
      "MEAN": 0.153846153846153,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 14510585,
      "DESCRIPTION": "HOSE_OUTLET EC210B",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.25,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 4,
      "WH REFERENCE": "WHXX",
      "TREND": 0.67,
      "STD": 0.438529009653514,
      "MEAN": 0.23076923076923,
      "COV": 1.90029237516522,
      "SHELF LIFE_1": 4,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 14511594,
      "DESCRIPTION": "BRG_KIT-100-GUMZ8",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.416666666666666,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 2,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 7,
      "WH REFERENCE": "WHXX",
      "TREND": 0,
      "STD": 1.38675049056307,
      "MEAN": 0.384615384615384,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 4.800000000000008,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 14513033,
      "DESCRIPTION": "GAS SPRING EC210B/EC460B",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 4,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14513798,
      "DESCRIPTION": "PLATE",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 5,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14514007,
      "DESCRIPTION": "BRACKET_REAR EX",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 5,
      "WHXX": 5,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14514329,
      "DESCRIPTION": "RESISTOR_CERAMIC",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 4,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14514330,
      "DESCRIPTION": "SENSOR EC210B/290B/360B/460B",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3,
      "WHXX": 2,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14514937,
      "DESCRIPTION": "SEAL KIT_T/JOINT EC210B 14500512",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 10,
      "WHXX": 6,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14515566,
      "DESCRIPTION": "V-BELT EC210B 3911577",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.25,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 4,
      "WHXX": 9,
      "WH REFERENCE": "WHXX",
      "TREND": 0,
      "STD": 0.832050294337843,
      "MEAN": 0.23076923076923,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 4,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 14515827,
      "DESCRIPTION": "COUPLING EC210B",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 1,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14515828,
      "DESCRIPTION": "ELEMENT COUPLING EC210B",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 13,
      "WHXX": 4,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14518349,
      "DESCRIPTION": "V-ECU EC210B/EC290B/EC700B",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 3,
      "WH REFERENCE": "WHXX",
      "TREND": 0,
      "STD": 0.375533808099405,
      "MEAN": 0.153846153846153,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 14522412,
      "DESCRIPTION": "WIPER ARM, UPPER EC210B/EC290B/EC460B/EW145B",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 1,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 12,
      "WHXX": 2,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14522909,
      "DESCRIPTION": "CUSHION",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 2,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14522962,
      "DESCRIPTION": "ADAPTER (VTS) EA30BL40 EC210B/240B",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.416666666666666,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 2,
      "SHELF LIFE": 4.800000000000008,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 7,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 1.38675049056307,
      "MEAN": 0.384615384615384,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 4.800000000000008,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 14522963,
      "DESCRIPTION": "ADAPTER (VTS) EA40BL50 EC290B",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.5,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 6,
      "SHELF LIFE": 12,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 6,
      "WHXX": 6,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 1.66410058867568,
      "MEAN": 0.461538461538461,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 12,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 14523946,
      "DESCRIPTION": "TOOTH 30AMRE EC210B/EC240B 14523552",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.5,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 3,
      "SHELF LIFE": 6,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 478,
      "WHXX": 8,
      "WH REFERENCE": "",
      "TREND": 0.67,
      "STD": 1.19828937903055,
      "MEAN": 0.461538461538461,
      "COV": 2.5962936545662,
      "SHELF LIFE_1": 6,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 14524170,
      "DESCRIPTION": "CARTRIDGE, HYDRAULIC_",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "FAST (F1)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 5.25,
      "MAINTANING STOCK QTY": 21,
      "AVAILABLE": 32,
      "SHELF LIFE": 6.095238095238095,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 462,
      "WHXX": 126,
      "WH REFERENCE": "",
      "TREND": 0.92,
      "STD": 5.698627815041,
      "MEAN": 4.84615384615384,
      "COV": 1.17590732691322,
      "SHELF LIFE_1": 6.095238095238095,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 14524449,
      "DESCRIPTION": "GASKET KIT, HYD TANK EC210B",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.916666666666666,
      "MAINTANING STOCK QTY": 4,
      "AVAILABLE": 4,
      "SHELF LIFE": 4.363636363636367,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 12,
      "WHXX": 22,
      "WH REFERENCE": "",
      "TREND": 1.09,
      "STD": 1.81870621808259,
      "MEAN": 0.846153846153846,
      "COV": 2.14938007591579,
      "SHELF LIFE_1": 4.363636363636367,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 14524710,
      "DESCRIPTION": "PIN 65P (VTS) EC360B/EC460B",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 1.91666666666666,
      "MAINTANING STOCK QTY": 8,
      "AVAILABLE": 10,
      "SHELF LIFE": 5.217391304347844,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 47,
      "WHXX": 30,
      "WH REFERENCE": "",
      "TREND": 0.52,
      "STD": 3.37030775434148,
      "MEAN": 1.76923076923076,
      "COV": 1.90495655680171,
      "SHELF LIFE_1": 5.217391304347844,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 14524711,
      "DESCRIPTION": "LOCKING DEVICE 80P ( VTS) EC700B",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.416666666666666,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 10,
      "SHELF LIFE": 24.000000000000036,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 9,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 1.38675049056307,
      "MEAN": 0.384615384615384,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 24.000000000000036,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 14526158,
      "DESCRIPTION": "STARTER SWITCH EC210B/EC460B 1123-02820 ",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.25,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 4,
      "SHELF LIFE": 16,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 2,
      "WH REFERENCE": "",
      "TREND": 0.67,
      "STD": 0.438529009653514,
      "MEAN": 0.23076923076923,
      "COV": 1.90029237516522,
      "SHELF LIFE_1": 16,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 14526509,
      "DESCRIPTION": "ADAPTER (VTS) EA80BL70 EC700B",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.75,
      "MAINTANING STOCK QTY": 3,
      "AVAILABLE": 5,
      "SHELF LIFE": 6.666666666666667,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 1.11,
      "STD": 1.70218562367207,
      "MEAN": 0.692307692307692,
      "COV": 2.45871256752633,
      "SHELF LIFE_1": 6.666666666666667,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 14526510,
      "DESCRIPTION": "TOOTH (VTS) 65GPE EC460B 14624280",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 2,
      "MAINTANING STOCK QTY": 8,
      "AVAILABLE": 10,
      "SHELF LIFE": 5,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 99,
      "WHXX": 7,
      "WH REFERENCE": "",
      "TREND": 0.58,
      "STD": 3.53190585581764,
      "MEAN": 1.84615384615384,
      "COV": 1.91311567190122,
      "SHELF LIFE_1": 5,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 14526776,
      "DESCRIPTION": "ADAPTER (VTS) EA65BL50 EC460B / EC480D",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 26,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14527149,
      "DESCRIPTION": "DISPLAY PANEL 14521370 14527158",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14527267,
      "DESCRIPTION": "REPAIR SET, SOLENOID EC210B/EC460B/EC700B/BL71 14512987  ",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 3,
      "SHELF LIFE": 9.000000000000009,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 1,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.85485041426511,
      "MEAN": 0.307692307692307,
      "COV": 2.7782638463616,
      "SHELF LIFE_1": 9.000000000000009,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 14527864,
      "DESCRIPTION": "PIN, HOR (KTS) EW145B",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 1.66666666666666,
      "MAINTANING STOCK QTY": 7,
      "AVAILABLE": 35,
      "SHELF LIFE": 21.00000000000008,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 202,
      "WHXX": 122,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 3.15212585978057,
      "MEAN": 1.53846153846153,
      "COV": 2.04888180885737,
      "SHELF LIFE_1": 21.00000000000008,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 14528382,
      "DESCRIPTION": "CLAMP",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.25,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 3,
      "SHELF LIFE": 12,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 4,
      "WHXX": 2,
      "WH REFERENCE": "",
      "TREND": 0.67,
      "STD": 0.438529009653514,
      "MEAN": 0.23076923076923,
      "COV": 1.90029237516522,
      "SHELF LIFE_1": 12,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 14529050,
      "DESCRIPTION": "O-RING",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.666666666666666,
      "MAINTANING STOCK QTY": 3,
      "AVAILABLE": 1,
      "SHELF LIFE": 1.5000000000000016,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 2,
      "APRD QTY": 2,
      "WH12": 68,
      "WHXX": 31,
      "WH REFERENCE": "WHXX",
      "TREND": 1.75,
      "STD": 1.19292787840544,
      "MEAN": 0.615384615384615,
      "COV": 1.93850780240885,
      "SHELF LIFE_1": 4.500000000000004,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 14529051,
      "DESCRIPTION": "O-RING, A/C",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.666666666666666,
      "MAINTANING STOCK QTY": 3,
      "AVAILABLE": 3,
      "SHELF LIFE": 4.500000000000004,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 60,
      "WHXX": 12,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 1.50213523239762,
      "MEAN": 0.615384615384615,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 4.500000000000004,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 14529052,
      "DESCRIPTION": "O-RING",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.666666666666666,
      "MAINTANING STOCK QTY": 3,
      "AVAILABLE": 4,
      "SHELF LIFE": 6.000000000000006,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 174,
      "WHXX": 8,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 1.50213523239762,
      "MEAN": 0.615384615384615,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 6.000000000000006,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 14529132,
      "DESCRIPTION": "DIODE W/ HARNESS EC460B",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 5,
      "WHXX": 2,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14529178,
      "DESCRIPTION": "KEY_PLASTIC EC210B-P/EC460B",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 2,
      "SHELF LIFE": 12.000000000000048,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 7,
      "WHXX": 18,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 12.000000000000048,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 14530079,
      "DESCRIPTION": "WIPER MOTOR, UPPER EC210B/EC290B/EC460B / EW145B",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14530362,
      "DESCRIPTION": "LINK LH EC210BLC",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 4,
      "WHXX": 2,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14530363,
      "DESCRIPTION": "LINK RH EC210BLC",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14530364,
      "DESCRIPTION": "PIN, TRACK REG EC210BLC * 1182-00040 14515724 ",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 19,
      "WHXX": 2,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14530365,
      "DESCRIPTION": "BUSHING, REG EC210BLC",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 2,
      "WH REFERENCE": "WHXX",
      "TREND": 1,
      "STD": 0.375533808099405,
      "MEAN": 0.153846153846153,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 14530367,
      "DESCRIPTION": "14723460 SEAL, TRACK EC210B",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14530385,
      "DESCRIPTION": "SEAL, TRACK EC360B/EC460B",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.666666666666666,
      "MAINTANING STOCK QTY": 3,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 3,
      "APRD QTY": 0,
      "WH12": 13,
      "WHXX": 10,
      "WH REFERENCE": "WHXX",
      "TREND": 1,
      "STD": 1.32529629004621,
      "MEAN": 0.615384615384615,
      "COV": 2.15360647132509,
      "SHELF LIFE_1": 4.500000000000004,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 14530648,
      "DESCRIPTION": "GLASS, CAB DOOR EC210B / EC290B",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14530989,
      "DESCRIPTION": "FILTER, HYDRAULIC TANK, SUCTION",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 8,
      "WHXX": 11,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14531010,
      "DESCRIPTION": "PIN",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 6,
      "WH REFERENCE": "WHXX",
      "TREND": 0,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 14531109,
      "DESCRIPTION": "SEAL",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 7,
      "WHXX": 10,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14531866,
      "DESCRIPTION": "STRAINER ASSY EC210B/EC240B/EW170",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.416666666666666,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 2,
      "SHELF LIFE": 4.800000000000008,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 47,
      "WHXX": 12,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.767947647788304,
      "MEAN": 0.384615384615384,
      "COV": 1.99666388424959,
      "SHELF LIFE_1": 4.800000000000008,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 14532260,
      "DESCRIPTION": "STRAINER",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 3,
      "SHELF LIFE": 36.000000000000014,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 7,
      "WHXX": 6,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 36.000000000000014,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 14532276,
      "DESCRIPTION": "PLUG EC210BLC 1081-00300",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 2,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 2,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14532390,
      "DESCRIPTION": "RECOIL SPRING EC210BLC",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 8,
      "WHXX": 8,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14532473,
      "DESCRIPTION": "SPACER, MASTER PIN EC460B/EC480D",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.5,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 2,
      "APRD QTY": 0,
      "WH12": 14,
      "WHXX": 5,
      "WH REFERENCE": "WHXX",
      "TREND": 0.67,
      "STD": 0.967417922046845,
      "MEAN": 0.461538461538461,
      "COV": 2.09607216443483,
      "SHELF LIFE_1": 4,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 14532687,
      "DESCRIPTION": "14750657  FILTER, HYD LEAKAGE EW145B 14744967 ",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 10,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14533862,
      "DESCRIPTION": "SPACER, MASTER PIN EC210BLC",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 5,
      "WHXX": 9,
      "WH REFERENCE": "WHXX",
      "TREND": 1,
      "STD": 0.75106761619881,
      "MEAN": 0.307692307692307,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 3.000000000000003,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 14537348,
      "DESCRIPTION": "STRAINER, FUEL TANK  EC210B",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 42,
      "WHXX": 103,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14540724,
      "DESCRIPTION": "ADAPTER A20-30, HOR. (ET)/(KTS) EC210B EC220D",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.916666666666666,
      "MAINTANING STOCK QTY": 4,
      "AVAILABLE": 1,
      "SHELF LIFE": 1.0909090909090917,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 3,
      "APRD QTY": 3,
      "WH12": 0,
      "WHXX": 3,
      "WH REFERENCE": "WHXX",
      "TREND": 1.09,
      "STD": 1.8639632438326,
      "MEAN": 0.846153846153846,
      "COV": 2.20286565180217,
      "SHELF LIFE_1": 4.363636363636367,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 14540725,
      "DESCRIPTION": "PIN A20-30, HOR. (KTS) 1070-61320  EC210B/EW145B/EW170 1070-61320",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "AVERAGE (F2)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 7.33333333333333,
      "MAINTANING STOCK QTY": 29,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 29,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 0.8,
      "STD": 5.68849491157145,
      "MEAN": 6.76923076923076,
      "COV": 0.840345839209419,
      "SHELF LIFE_1": 3.9545454545454564,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 14540726,
      "DESCRIPTION": "PIN, TOOTH_HOR (KTS) EC240B/EC290B 1070-13040",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 1.66666666666666,
      "MAINTANING STOCK QTY": 7,
      "AVAILABLE": 2,
      "SHELF LIFE": 1.2000000000000048,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 5,
      "APRD QTY": 5,
      "WH12": 23,
      "WHXX": 23,
      "WH REFERENCE": "WHXX",
      "TREND": 2,
      "STD": 5.25381424324881,
      "MEAN": 1.53846153846153,
      "COV": 3.41497925811172,
      "SHELF LIFE_1": 4.200000000000016,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 14548911,
      "DESCRIPTION": "FUEL HOSE",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14551131,
      "DESCRIPTION": "GLASS, WINDSHIELD  EC210B 14506807",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 7,
      "WHXX": 4,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14552332,
      "DESCRIPTION": "SEAL KIT_SWING MOTOR EC210B/EC460B",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 26,
      "WHXX": 4,
      "WH REFERENCE": "WHXX",
      "TREND": 0,
      "STD": 0.375533808099405,
      "MEAN": 0.153846153846153,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 14555298,
      "DESCRIPTION": "SEAL KIT, MAIN PUMP  EC210B",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 1,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 4,
      "WHXX": 3,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14560201,
      "DESCRIPTION": "SEAL",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 11,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 108,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14560206,
      "DESCRIPTION": "SEAL, BOOM  EC210B / EC290B",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 19,
      "WHXX": 21,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14562193,
      "DESCRIPTION": "SWITCH, PRESSURE EC460B 14529294",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.833333333333333,
      "MAINTANING STOCK QTY": 3,
      "AVAILABLE": 2,
      "SHELF LIFE": 2.400000000000001,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 15,
      "WHXX": 26,
      "WH REFERENCE": "WHXX",
      "TREND": 0.6,
      "STD": 1.16575055606864,
      "MEAN": 0.769230769230769,
      "COV": 1.51547572288924,
      "SHELF LIFE_1": 3.6000000000000014,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 14589129,
      "DESCRIPTION": "SEAL KIT, BOOM CYL.  EC210B 14515051 ",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "AVERAGE (F2)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 2.66666666666666,
      "MAINTANING STOCK QTY": 11,
      "AVAILABLE": 11,
      "SHELF LIFE": 4.125000000000011,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 35,
      "WHXX": 18,
      "WH REFERENCE": "",
      "TREND": 1.06,
      "STD": 2.4019223070763,
      "MEAN": 2.46153846153846,
      "COV": 0.975780937249749,
      "SHELF LIFE_1": 4.125000000000011,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 14589131,
      "DESCRIPTION": "SEALING KIT, DIPPER ARM CYL. EC210B",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "AVERAGE (F2)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 2,
      "MAINTANING STOCK QTY": 8,
      "AVAILABLE": 9,
      "SHELF LIFE": 4.5,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 4,
      "WH REFERENCE": "",
      "TREND": 1.42,
      "STD": 2.7942248133771,
      "MEAN": 1.84615384615384,
      "COV": 1.51353844057926,
      "SHELF LIFE_1": 4.5,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 14589132,
      "DESCRIPTION": "SEALING KIT, BUCKET CYL.  EC210B / EC140BP DIPPER ARM CYL.",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.833333333333333,
      "MAINTANING STOCK QTY": 3,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 3,
      "APRD QTY": 0,
      "WH12": 9,
      "WHXX": 5,
      "WH REFERENCE": "WHXX",
      "TREND": 1,
      "STD": 1.01273936708366,
      "MEAN": 0.769230769230769,
      "COV": 1.31656117720876,
      "SHELF LIFE_1": 3.6000000000000014,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 14589140,
      "DESCRIPTION": "SEALING KIT, BUCKET CYL  ME EC460B/EC360B ARM CYL",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 29,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14589143,
      "DESCRIPTION": "SEALING KIT, BOOM / BUCKET CYL.  EC460B 14513726 ",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 1,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 24,
      "WHXX": 5,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14591545,
      "DESCRIPTION": "SWITCH_RECEIVER DRIER EC460B/EC700B 14541016 ",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.75,
      "MAINTANING STOCK QTY": 3,
      "AVAILABLE": 3,
      "SHELF LIFE": 4,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 5,
      "WHXX": 1,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 1.49357598761135,
      "MEAN": 0.692307692307692,
      "COV": 2.15738753766084,
      "SHELF LIFE_1": 4,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 14596399,
      "DESCRIPTION": "FILTER, BREATHER HYD EC210B_",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "FAST (F1)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 7.66666666666666,
      "MAINTANING STOCK QTY": 31,
      "AVAILABLE": 11,
      "SHELF LIFE": 1.4347826086956534,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 20,
      "APRD QTY": 20,
      "WH12": 286,
      "WHXX": 56,
      "WH REFERENCE": "WHXX",
      "TREND": 0.91,
      "STD": 6.10275263660503,
      "MEAN": 7.07692307692307,
      "COV": 0.862345481259407,
      "SHELF LIFE_1": 4.043478260869569,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 14603276,
      "DESCRIPTION": "BOLT, T/SHOE  EC480D  14532467, 1182-00880",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 3.16666666666666,
      "MAINTANING STOCK QTY": 13,
      "AVAILABLE": 13,
      "SHELF LIFE": 4.105263157894746,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3110,
      "WHXX": 45,
      "WH REFERENCE": "",
      "TREND": 1.47,
      "STD": 5.80892328608234,
      "MEAN": 2.92307692307692,
      "COV": 1.98726322944922,
      "SHELF LIFE_1": 4.105263157894746,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 14880438,
      "DESCRIPTION": "WEATHERSTRIP",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 3,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14880552,
      "DESCRIPTION": "BOLT, T/SHOE EC210B  14880553",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 3.66666666666666,
      "MAINTANING STOCK QTY": 15,
      "AVAILABLE": 116,
      "SHELF LIFE": 31.636363636363694,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 318,
      "WHXX": 238,
      "WH REFERENCE": "",
      "TREND": 1.09,
      "STD": 8.30199234660433,
      "MEAN": 3.38461538461538,
      "COV": 2.45286137513309,
      "SHELF LIFE_1": 31.636363636363694,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 14880553,
      "DESCRIPTION": "BOLT, T/SHOE EC210BLC 14880552",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 65,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1014,
      "WHXX": 1483,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14880692,
      "DESCRIPTION": "RING_CORD EC210B",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 23,
      "WHXX": 8,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14881185,
      "DESCRIPTION": "RING CORD",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 5,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 127,
      "WHXX": 10,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14881276,
      "DESCRIPTION": "V-BELT, A/C EC210B/EC290B SN17001~19730/L120F / EW145B",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "AVERAGE (F2)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 4.91666666666666,
      "MAINTANING STOCK QTY": 20,
      "AVAILABLE": 8,
      "SHELF LIFE": 1.627118644067799,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 12,
      "APRD QTY": 12,
      "WH12": 0,
      "WHXX": 44,
      "WH REFERENCE": "WHXX",
      "TREND": 1.15,
      "STD": 3.90758630988885,
      "MEAN": 4.53846153846153,
      "COV": 0.860993593704323,
      "SHELF LIFE_1": 4.067796610169498,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 15078671,
      "DESCRIPTION": "V-BELT, FAN DRV EC210B SN35001/50001/70001",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "AVERAGE (F2)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 1.58333333333333,
      "MAINTANING STOCK QTY": 6,
      "AVAILABLE": 7,
      "SHELF LIFE": 4.421052631578957,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 74,
      "WHXX": 15,
      "WH REFERENCE": "",
      "TREND": 1.05,
      "STD": 1.50639661750508,
      "MEAN": 1.46153846153846,
      "COV": 1.03069242250348,
      "SHELF LIFE_1": 4.421052631578957,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 20374662,
      "DESCRIPTION": " RELAY_24V",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 3,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 17,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 20405599,
      "DESCRIPTION": "SEALING RING EC210B",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 12,
      "WHXX": 6,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 20405792,
      "DESCRIPTION": "SCREW",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 13,
      "WHXX": 13,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 20405885,
      "DESCRIPTION": "SCREW EC210B",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 2,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 32,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 20405969,
      "DESCRIPTION": "GASKET",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 13,
      "WHXX": 10,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 20405970,
      "DESCRIPTION": "GASKET",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3,
      "WHXX": 5,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 20450754,
      "DESCRIPTION": "SCREW EC210B",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 22,
      "WHXX": 10,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 20459025,
      "DESCRIPTION": "RETAINING RING EC210B",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 1,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 6,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 20459191,
      "DESCRIPTION": "PLUG EC210B",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 1,
      "SHELF LIFE": 3.000000000000003,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 8,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 1,
      "STD": 0.480384461415261,
      "MEAN": 0.307692307692307,
      "COV": 1.56124949959959,
      "SHELF LIFE_1": 3.000000000000003,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 20459192,
      "DESCRIPTION": "GASKET EC210B",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.416666666666666,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 2,
      "SHELF LIFE": 4.800000000000008,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 6,
      "WHXX": 2,
      "WH REFERENCE": "",
      "TREND": 0.8,
      "STD": 0.506369683541833,
      "MEAN": 0.384615384615384,
      "COV": 1.31656117720876,
      "SHELF LIFE_1": 4.800000000000008,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 20459193,
      "DESCRIPTION": "SEALING RING EC210B",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.833333333333333,
      "MAINTANING STOCK QTY": 3,
      "AVAILABLE": 6,
      "SHELF LIFE": 7.200000000000003,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 96,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0.8,
      "STD": 1.01273936708366,
      "MEAN": 0.769230769230769,
      "COV": 1.31656117720876,
      "SHELF LIFE_1": 7.200000000000003,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 20459212,
      "DESCRIPTION": "GASKET EC210B/L60/70/90E",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 34,
      "WHXX": 20,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 20459214,
      "DESCRIPTION": "WASHER EC210B",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 9,
      "WHXX": 8,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 20459215,
      "DESCRIPTION": "WASHER EC210B/EC460B",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 8,
      "WHXX": 9,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 20459218,
      "DESCRIPTION": "GASKET EC210B",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 72,
      "WHXX": 15,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 20459863,
      "DESCRIPTION": "PULLEY",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 1,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 20459953,
      "DESCRIPTION": "SEALING RING",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 6,
      "WHXX": 4,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 20459960,
      "DESCRIPTION": "PULLEY (ENG. D6D EAE2) EC210B",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 6,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 20460061,
      "DESCRIPTION": "SEALING RING EC210B",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 28,
      "WHXX": 36,
      "WH REFERENCE": "WHXX",
      "TREND": 0,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 20460813,
      "DESCRIPTION": "SEALING RING",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 4,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 20482686,
      "DESCRIPTION": "ALTERNATOR EC210B",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 1,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 20482730,
      "DESCRIPTION": "GUIDE TUBE EC210B",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 20516934,
      "DESCRIPTION": "FUEL FILTER ASSY",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 1,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 3,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 20766790,
      "DESCRIPTION": "BIG END BEARING KIT  21290112",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.583333333333333,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 3,
      "SHELF LIFE": 5.142857142857146,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 9,
      "WHXX": 48,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 1.9414506867883,
      "MEAN": 0.538461538461538,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 5.142857142857146,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 20796066,
      "DESCRIPTION": "RELAY",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 4,
      "WHXX": 4,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 20798186,
      "DESCRIPTION": "CYL HEAD GASKET, 1.37MM 2 BORES  EC210B",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 11,
      "WHXX": 4,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 20798191,
      "DESCRIPTION": "CYL. HEAD GASKET, 1.47MM 3 BORES EC210B",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3,
      "WHXX": 2,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 20798683,
      "DESCRIPTION": "FUEL INJECTOR ENG: D6D_EGE3 EC210B  REMAN 9020798683",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.666666666666666,
      "MAINTANING STOCK QTY": 3,
      "AVAILABLE": 7,
      "SHELF LIFE": 10.50000000000001,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 45,
      "WHXX": 4,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 1.66024403519791,
      "MEAN": 0.615384615384615,
      "COV": 2.69789655719661,
      "SHELF LIFE_1": 10.50000000000001,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 20799731,
      "DESCRIPTION": "WASHER",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 1,
      "MAINTANING STOCK QTY": 4,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 4,
      "APRD QTY": 4,
      "WH12": 8,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 2,
      "STD": 3.32820117735137,
      "MEAN": 0.923076923076923,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 4,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 20840491,
      "DESCRIPTION": "OIL PAN EC210B",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 1,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 20879322,
      "DESCRIPTION": "PRESSURE CAP",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 20973777,
      "DESCRIPTION": "SERVICE KIT, FUEL LINES EC210B/EC290B/G930",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.5,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 2,
      "SHELF LIFE": 4,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 4,
      "WHXX": 11,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 1.19828937903055,
      "MEAN": 0.461538461538461,
      "COV": 2.5962936545662,
      "SHELF LIFE_1": 4,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 21141943,
      "DESCRIPTION": "SENSOR EC210B/EC290B/G700B/L60E",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 5,
      "WHXX": 3,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 21141955,
      "DESCRIPTION": "RACEWAY WASHER 20405899",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 12,
      "WHXX": 12,
      "WH REFERENCE": "WHXX",
      "TREND": 0,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 21142728,
      "DESCRIPTION": "ADAPTER",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 3,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 21147446,
      "DESCRIPTION": "HIGH-PRESSURE PUMP  REMAN 9021147446",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 1,
      "SHELF LIFE": 6.000000000000024,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 10,
      "WHXX": 1,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 21247955,
      "DESCRIPTION": "23657265 COOLANT PUMP (MUST BE SUPPLIED W/ D6E PULLEY 21795915 / D4E PULLEY 20798526 21247955)",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 1,
      "SHELF LIFE": 12.000000000000005,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 1,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 12.000000000000005,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 21291011,
      "DESCRIPTION": "SWITCH_PRESSURE EC210B/EC290B/G930 20585158 20450687",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 1,
      "SHELF LIFE": 6.000000000000024,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3,
      "WHXX": 5,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.375533808099405,
      "MEAN": 0.153846153846153,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 21511352,
      "DESCRIPTION": "FUEL PUMP EC210B SD110 21211878 20524154 20518337",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.25,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 1,
      "WH REFERENCE": "WHXX",
      "TREND": 0.67,
      "STD": 0.438529009653514,
      "MEAN": 0.23076923076923,
      "COV": 1.90029237516522,
      "SHELF LIFE_1": 4,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 3280048,
      "DESCRIPTION": "INJECTOR NOZZLE ",
      "PRODUCT CATEGORY": "CUG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 60100002,
      "DESCRIPTION": "E-ECU EC210B EC290B",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 4,
      "WHXX": 1,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "7123-52560",
      "DESCRIPTION": "WHEEL NUT",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 1.08333333333333,
      "MAINTANING STOCK QTY": 4,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 4,
      "APRD QTY": 4,
      "WH12": 80,
      "WHXX": 58,
      "WH REFERENCE": "WHXX",
      "TREND": 1.23,
      "STD": 2.51661147842358,
      "MEAN": 1,
      "COV": 2.51661147842358,
      "SHELF LIFE_1": 3.692307692307704,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": "7273-10751",
      "DESCRIPTION": "CAP, MCV  EC210B",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.666666666666666,
      "MAINTANING STOCK QTY": 3,
      "AVAILABLE": 3,
      "SHELF LIFE": 4.500000000000004,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 47,
      "WHXX": 4,
      "WH REFERENCE": "",
      "TREND": 1.25,
      "STD": 0.869718492622904,
      "MEAN": 0.615384615384615,
      "COV": 1.41329255051221,
      "SHELF LIFE_1": 4.500000000000004,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": "8038-00010",
      "DESCRIPTION": "SEAL KIT_RECOIL EC210B",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 10,
      "WHXX": 11,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "8230-02830",
      "DESCRIPTION": "ELEMENT BREATHER, HYDRAULIC TANK",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "AVERAGE (F2)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 1.5,
      "MAINTANING STOCK QTY": 6,
      "AVAILABLE": 4,
      "SHELF LIFE": 2.6666666666666665,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 2,
      "APRD QTY": 2,
      "WH12": 14,
      "WHXX": 38,
      "WH REFERENCE": "WHXX",
      "TREND": 1.11,
      "STD": 1.80455264717055,
      "MEAN": 1.38461538461538,
      "COV": 1.30328802295651,
      "SHELF LIFE_1": 4,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": "8230-26520",
      "DESCRIPTION": "ROLLER BEARING",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 24,
      "WHXX": 5,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "8230-36840",
      "DESCRIPTION": "SEAL KIT, RCV  EC210B/EC460B/EC700B",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 7,
      "WHXX": 16,
      "WH REFERENCE": "WHXX",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 85108352,
      "DESCRIPTION": "CRANKSHAFT SEAL EC700B/FM440 20441481",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "9131-11000",
      "DESCRIPTION": "LOCK NUT EC700B",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 27,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 955918,
      "DESCRIPTION": "SPRING WASHER",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 2,
      "WHXX": 8,
      "WH REFERENCE": "WHXX",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 955919,
      "DESCRIPTION": "SPRING WASHER",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 1.16666666666666,
      "MAINTANING STOCK QTY": 5,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 5,
      "APRD QTY": 5,
      "WH12": 21,
      "WHXX": 85,
      "WH REFERENCE": "WHXX",
      "TREND": 2,
      "STD": 3.32820117735137,
      "MEAN": 1.07692307692307,
      "COV": 3.09047252182627,
      "SHELF LIFE_1": 4.28571428571431,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 955922,
      "DESCRIPTION": "SPRING WASHER 10.5MM 9213-10000 EC700B",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 2.08333333333333,
      "MAINTANING STOCK QTY": 8,
      "AVAILABLE": 8,
      "SHELF LIFE": 3.840000000000006,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 411,
      "WHXX": 141,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 5.0408586977078,
      "MEAN": 1.92307692307692,
      "COV": 2.62124652280805,
      "SHELF LIFE_1": 3.840000000000006,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 955925,
      "DESCRIPTION": "WASHER 9213-14000",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 2,
      "MAINTANING STOCK QTY": 8,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 8,
      "APRD QTY": 8,
      "WH12": 156,
      "WHXX": 55,
      "WH REFERENCE": "WHXX",
      "TREND": 2,
      "STD": 6.65640235470275,
      "MEAN": 1.84615384615384,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 4,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 955927,
      "DESCRIPTION": "SPRING WASHER 01602-01854",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 2.91666666666666,
      "MAINTANING STOCK QTY": 12,
      "AVAILABLE": 2,
      "SHELF LIFE": 0.6857142857142873,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 10,
      "APRD QTY": 10,
      "WH12": 387,
      "WHXX": 48,
      "WH REFERENCE": "WHXX",
      "TREND": 1.43,
      "STD": 3.88125012903092,
      "MEAN": 2.69230769230769,
      "COV": 1.44160719078291,
      "SHELF LIFE_1": 4.114285714285724,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 960259,
      "DESCRIPTION": "O-RING EC460B 9511-22230",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.583333333333333,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 3,
      "SHELF LIFE": 5.142857142857146,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 73,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 1.14,
      "STD": 0.967417922046845,
      "MEAN": 0.538461538461538,
      "COV": 1.79663328380128,
      "SHELF LIFE_1": 5.142857142857146,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": "9612-08040",
      "DESCRIPTION": "BUSHING",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 2,
      "SHELF LIFE": 6.000000000000006,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 16,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 1.10940039245045,
      "MEAN": 0.307692307692307,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000006,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": "9612-09540",
      "DESCRIPTION": "CYL BUSHING EC210B",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3,
      "WHXX": 5,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 969589,
      "DESCRIPTION": "SCREW",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.25,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 75,
      "WHXX": 19,
      "WH REFERENCE": "WHXX",
      "TREND": 0,
      "STD": 0.832050294337843,
      "MEAN": 0.23076923076923,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 4,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 978937,
      "DESCRIPTION": "60110032 BOLT HEX HD",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 983303,
      "DESCRIPTION": "HEXAGON NUT EC210B",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 3,
      "SHELF LIFE": 9.000000000000009,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 55,
      "WHXX": 67,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 1.10940039245045,
      "MEAN": 0.307692307692307,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 9.000000000000009,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 983497,
      "DESCRIPTION": "O-RING 9511-12010",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 8.41666666666666,
      "MAINTANING STOCK QTY": 34,
      "AVAILABLE": 18,
      "SHELF LIFE": 2.13861386138614,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 16,
      "APRD QTY": 16,
      "WH12": 31,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 1.98,
      "STD": 27.7132755376487,
      "MEAN": 7.76923076923076,
      "COV": 3.56705526722211,
      "SHELF LIFE_1": 4.039603960396042,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 983502,
      "DESCRIPTION": "O-RING EC210B/EC460C 9511-12011",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.75,
      "MAINTANING STOCK QTY": 3,
      "AVAILABLE": 10,
      "SHELF LIFE": 13.333333333333334,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 211,
      "WHXX": 61,
      "WH REFERENCE": "",
      "TREND": 0.67,
      "STD": 1.03155347127648,
      "MEAN": 0.692307692307692,
      "COV": 1.49002168073269,
      "SHELF LIFE_1": 13.333333333333334,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 983505,
      "DESCRIPTION": "O-RING 9511-12014",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 126,
      "WHXX": 36,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 983524,
      "DESCRIPTION": "O-RING EC210B 9511-12024",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 2,
      "SHELF LIFE": 6.000000000000006,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 231,
      "WHXX": 30,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 1.10940039245045,
      "MEAN": 0.307692307692307,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000006,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 983527,
      "DESCRIPTION": "O-RING EC700B",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 7,
      "SHELF LIFE": 84.00000000000003,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 50,
      "WHXX": 104,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 84.00000000000003,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 983530,
      "DESCRIPTION": "O-RING EC210B",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.25,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 11,
      "WHXX": 26,
      "WH REFERENCE": "WHXX",
      "TREND": 0,
      "STD": 0.832050294337843,
      "MEAN": 0.23076923076923,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 4,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 990557,
      "DESCRIPTION": "O-RING EC210B",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 297,
      "WHXX": 31,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 990566,
      "DESCRIPTION": "O-RING",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.666666666666666,
      "MAINTANING STOCK QTY": 3,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 3,
      "APRD QTY": 0,
      "WH12": 84,
      "WHXX": 94,
      "WH REFERENCE": "WHXX",
      "TREND": 0,
      "STD": 2.21880078490091,
      "MEAN": 0.615384615384615,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 4.500000000000004,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 990583,
      "DESCRIPTION": "O-RING 9511-22035",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.666666666666666,
      "MAINTANING STOCK QTY": 3,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 3,
      "APRD QTY": 0,
      "WH12": 3,
      "WHXX": 15,
      "WH REFERENCE": "WHXX",
      "TREND": 0,
      "STD": 2.21880078490091,
      "MEAN": 0.615384615384615,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 4.500000000000004,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 990596,
      "DESCRIPTION": "O-RING",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 59,
      "WHXX": 10,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 990740,
      "DESCRIPTION": "O-RING EC210B",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 1.33333333333333,
      "MAINTANING STOCK QTY": 5,
      "AVAILABLE": 5,
      "SHELF LIFE": 3.7500000000000098,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 315,
      "WHXX": 34,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 4.43760156980183,
      "MEAN": 1.23076923076923,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 3.7500000000000098,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 15048941,
      "DESCRIPTION": "FLASHER RELAY 1089286 11170048",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 14,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 1594228,
      "DESCRIPTION": "TEMPERATURE SENSOR FM340",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 1635305,
      "DESCRIPTION": "SPRING LEAF NO. 1, REAR  FL10/FM400",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 1635322,
      "DESCRIPTION": "SPRING LEAF FM440",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 1674083,
      "DESCRIPTION": "PRESS CAP_EXP TANK FM10 20879323",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 1,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 4,
      "WHXX": 63,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 20367490,
      "DESCRIPTION": "RELAY, STARTER NL10/FM400/EC460B/EC700B/G930/L150F",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 4,
      "SHELF LIFE": 24.000000000000096,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 33,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.375533808099405,
      "MEAN": 0.153846153846153,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 24.000000000000096,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 20378932,
      "DESCRIPTION": "FUSE 5A FM9/FM400",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 2,
      "SHELF LIFE": 12.000000000000048,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 11,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 12.000000000000048,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 20378934,
      "DESCRIPTION": "FUSE 10A FM9/FM400",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.666666666666666,
      "MAINTANING STOCK QTY": 3,
      "AVAILABLE": 8,
      "SHELF LIFE": 12.000000000000012,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 117,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 1.55662356498831,
      "MEAN": 0.615384615384615,
      "COV": 2.529513293106,
      "SHELF LIFE_1": 12.000000000000012,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 20378935,
      "DESCRIPTION": "FUSE 15A FM9/FM400",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.416666666666666,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 8,
      "SHELF LIFE": 19.20000000000003,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 153,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 1.38675049056307,
      "MEAN": 0.384615384615384,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 19.20000000000003,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 3283333,
      "DESCRIPTION": "GASKET_CYL HEAD 4BT3.9",
      "PRODUCT CATEGORY": "CUG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.25,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 0.67,
      "STD": 0.599144689515278,
      "MEAN": 0.23076923076923,
      "COV": 2.5962936545662,
      "SHELF LIFE_1": 4,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 3802100,
      "DESCRIPTION": "PISTON KIT 4BT",
      "PRODUCT CATEGORY": "CUG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 1,
      "MAINTANING STOCK QTY": 4,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 4,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 0,
      "STD": 2.39657875806111,
      "MEAN": 0.923076923076923,
      "COV": 2.5962936545662,
      "SHELF LIFE_1": 4,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 3917539,
      "DESCRIPTION": "INJECTOR PUMP",
      "PRODUCT CATEGORY": "CUG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 3964432,
      "DESCRIPTION": "STARTER ASSY  - 4B3.9",
      "PRODUCT CATEGORY": "CUG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 3969562,
      "DESCRIPTION": "CONROD BRG STD 3901170 3939859",
      "PRODUCT CATEGORY": "CUG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 1,
      "MAINTANING STOCK QTY": 4,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 4,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 0,
      "STD": 2.39657875806111,
      "MEAN": 0.923076923076923,
      "COV": 2.5962936545662,
      "SHELF LIFE_1": 4,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 4893693,
      "DESCRIPTION": "BEARING, CONROD STD",
      "PRODUCT CATEGORY": "CUG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 1,
      "MAINTANING STOCK QTY": 4,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 4,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 0,
      "STD": 2.39657875806111,
      "MEAN": 0.923076923076923,
      "COV": 2.5962936545662,
      "SHELF LIFE_1": 4,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 4983584,
      "DESCRIPTION": "FUEL TRANSFER PUMP SD100D/4BT ENGINE",
      "PRODUCT CATEGORY": "CUG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "FF42000",
      "DESCRIPTION": "FUEL, CARTRIDGE FF5052 P553004",
      "PRODUCT CATEGORY": "CUF",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 1,
      "SHELF LIFE": 12.000000000000005,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 102,
      "WHXX": 11,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 12.000000000000005,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": "FF5052",
      "DESCRIPTION": "FUEL, CARTRIDGE FF42000 P550440",
      "PRODUCT CATEGORY": "CUF",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "FF5612",
      "DESCRIPTION": "FUEL FILTER",
      "PRODUCT CATEGORY": "CUF",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 1,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "FS1000",
      "DESCRIPTION": "FF/WS FILTER, SPIN-ON P551000 FS1212",
      "PRODUCT CATEGORY": "CUF",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 2,
      "STD": 0.75106761619881,
      "MEAN": 0.307692307692307,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 3.000000000000003,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": "FS1212",
      "DESCRIPTION": "FWS FILTER, SPIN-ON P558000 P558020",
      "PRODUCT CATEGORY": "CUF",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 1,
      "STD": 0.630425171956115,
      "MEAN": 0.307692307692307,
      "COV": 2.04888180885737,
      "SHELF LIFE_1": 3.000000000000003,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": "FS1242B",
      "DESCRIPTION": "FWS FILTER W/ BOWL, SPIN-ON",
      "PRODUCT CATEGORY": "CUF",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 1,
      "SHELF LIFE": 6.000000000000024,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 51,
      "WHXX": 4,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": "FS1280",
      "DESCRIPTION": "FF/WS FILTER, SPIN-ON SD100D P551329",
      "PRODUCT CATEGORY": "CUF",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.416666666666666,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 2,
      "APRD QTY": 2,
      "WH12": 54,
      "WHXX": 21,
      "WH REFERENCE": "WHXX",
      "TREND": 1.2,
      "STD": 0.506369683541833,
      "MEAN": 0.384615384615384,
      "COV": 1.31656117720876,
      "SHELF LIFE_1": 4.800000000000008,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": "FS19532",
      "DESCRIPTION": "WATER SEPARATOR LG958 R90P",
      "PRODUCT CATEGORY": "CUF",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 2,
      "SHELF LIFE": 24.00000000000001,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 17,
      "WHXX": 8,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 24.00000000000001,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": "HF6072",
      "DESCRIPTION": "HYDRAULIC, CARTRIDGE P551158 175-49-11580",
      "PRODUCT CATEGORY": "CUF",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 1,
      "SHELF LIFE": 12.000000000000005,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 12.000000000000005,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": "LF3349",
      "DESCRIPTION": "LUBE FILTER P558615",
      "PRODUCT CATEGORY": "CUF",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 53,
      "WHXX": 2,
      "WH REFERENCE": "WHXX",
      "TREND": 1,
      "STD": 0.375533808099405,
      "MEAN": 0.153846153846153,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": "LF3403",
      "DESCRIPTION": "LUBE, FULL FLOW SPIN-ON P550162",
      "PRODUCT CATEGORY": "CUF",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 36786457,
      "DESCRIPTION": "SWITCH IGNITION L5K",
      "PRODUCT CATEGORY": "DOP",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 36845493,
      "DESCRIPTION": "FUEL TANK FILTER L5K 36845501",
      "PRODUCT CATEGORY": "DOP",
      "ITEM MOVEMENT BRANCH": "AVERAGE (F2)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 2.25,
      "MAINTANING STOCK QTY": 9,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 9,
      "APRD QTY": 9,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "PARTIAL WH12",
      "TREND": 0.52,
      "STD": 4.29071747033714,
      "MEAN": 2.07692307692307,
      "COV": 2.0659010042364,
      "SHELF LIFE_1": 4,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 36847747,
      "DESCRIPTION": "LAMP, 1000W MH (LT) L5K",
      "PRODUCT CATEGORY": "DOP",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.583333333333333,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 2,
      "SHELF LIFE": 3.42857142857143,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 8,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 1.9414506867883,
      "MEAN": 0.538461538461538,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 3.42857142857143,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 36870566,
      "DESCRIPTION": "FUEL FILTER L5K 70965099",
      "PRODUCT CATEGORY": "DOP",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 1,
      "MAINTANING STOCK QTY": 4,
      "AVAILABLE": 4,
      "SHELF LIFE": 4,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 47,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 3.32820117735137,
      "MEAN": 0.923076923076923,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 4,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 36870574,
      "DESCRIPTION": "OIL FILTER L5K 70867403",
      "PRODUCT CATEGORY": "DOP",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 1.16666666666666,
      "MAINTANING STOCK QTY": 5,
      "AVAILABLE": 4,
      "SHELF LIFE": 3.428571428571448,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 38,
      "WHXX": 1,
      "WH REFERENCE": "WHXX",
      "TREND": 0.29,
      "STD": 3.32820117735137,
      "MEAN": 1.07692307692307,
      "COV": 3.09047252182627,
      "SHELF LIFE_1": 4.28571428571431,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 36888170,
      "DESCRIPTION": "ALTERNATOR, KUBOTA",
      "PRODUCT CATEGORY": "DOP",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 36888683,
      "DESCRIPTION": "BALLAST CAPACITOR L5K",
      "PRODUCT CATEGORY": "DOP",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 36899706,
      "DESCRIPTION": "PRO TEC OIL, COMP. FLUID (PAIL)",
      "PRODUCT CATEGORY": "DOP",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.833333333333333,
      "MAINTANING STOCK QTY": 3,
      "AVAILABLE": 4,
      "SHELF LIFE": 4.800000000000002,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 119,
      "WHXX": 30,
      "WH REFERENCE": "",
      "TREND": 0.8,
      "STD": 1.30088727117598,
      "MEAN": 0.769230769230769,
      "COV": 1.69115345252877,
      "SHELF LIFE_1": 4.800000000000002,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 58854316,
      "DESCRIPTION": "LOCKWASHER",
      "PRODUCT CATEGORY": "VCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 8,
      "WHXX": 7,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "01602-02060",
      "DESCRIPTION": "LOCK WASHER",
      "PRODUCT CATEGORY": "KOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 12.8333333333333,
      "MAINTANING STOCK QTY": 51,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 51,
      "APRD QTY": 51,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 2,
      "STD": 39.8033628266217,
      "MEAN": 11.8461538461538,
      "COV": 3.36002413471482,
      "SHELF LIFE_1": 3.9740259740259845,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": "01643-31032",
      "DESCRIPTION": "FLAT WASHER",
      "PRODUCT CATEGORY": "KOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 1,
      "MAINTANING STOCK QTY": 4,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 4,
      "APRD QTY": 4,
      "WH12": 17,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 2,
      "STD": 3.32820117735137,
      "MEAN": 0.923076923076923,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 4,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": "01643-31232",
      "DESCRIPTION": "FLAT WASHER",
      "PRODUCT CATEGORY": "KOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 1.33333333333333,
      "MAINTANING STOCK QTY": 5,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 5,
      "APRD QTY": 5,
      "WH12": 6,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 2,
      "STD": 4.43760156980183,
      "MEAN": 1.23076923076923,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 3.7500000000000098,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": "01643-31845",
      "DESCRIPTION": "FLATWASHER 01643-21845",
      "PRODUCT CATEGORY": "SDG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 21.3333333333333,
      "MAINTANING STOCK QTY": 85,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 85,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 0.25,
      "STD": 51.9348704555115,
      "MEAN": 19.6923076923076,
      "COV": 2.63731764031894,
      "SHELF LIFE_1": 3.984375000000006,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": "07056-10045",
      "DESCRIPTION": "OIL STRAINER, T/C  SD16  16Y-60-00013",
      "PRODUCT CATEGORY": "SDG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "07700-40460",
      "DESCRIPTION": "VALVE",
      "PRODUCT CATEGORY": "KOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "09244-02508",
      "DESCRIPTION": "PIN ASSY.",
      "PRODUCT CATEGORY": "KOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 1,
      "MAINTANING STOCK QTY": 4,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 4,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 0,
      "STD": 3.32820117735137,
      "MEAN": 0.923076923076923,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 4,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": "150-30-13460",
      "DESCRIPTION": "SNAP RING",
      "PRODUCT CATEGORY": "KOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.5,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 2,
      "APRD QTY": 2,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 1.33,
      "STD": 1.19828937903055,
      "MEAN": 0.461538461538461,
      "COV": 2.5962936545662,
      "SHELF LIFE_1": 4,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": "150-30-13480",
      "DESCRIPTION": "RING",
      "PRODUCT CATEGORY": "KOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.5,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 2,
      "APRD QTY": 2,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 1.33,
      "STD": 1.19828937903055,
      "MEAN": 0.461538461538461,
      "COV": 2.5962936545662,
      "SHELF LIFE_1": 4,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": "154-32-05001G",
      "DESCRIPTION": "T/S BOLT W/ NUT D80 20MM",
      "PRODUCT CATEGORY": "KOR",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 2.75,
      "MAINTANING STOCK QTY": 11,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 11,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 0,
      "STD": 6.35892266314801,
      "MEAN": 2.53846153846153,
      "COV": 2.505030140028,
      "SHELF LIFE_1": 4,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": "155-30-13230",
      "DESCRIPTION": "SPRING",
      "PRODUCT CATEGORY": "KOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 2.33333333333333,
      "MAINTANING STOCK QTY": 9,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 9,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 0.86,
      "STD": 5.32049737408941,
      "MEAN": 2.15384615384615,
      "COV": 2.47023092368436,
      "SHELF LIFE_1": 3.857142857142863,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": "195-30-17960",
      "DESCRIPTION": "SEAT B",
      "PRODUCT CATEGORY": "KOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 2.33333333333333,
      "MAINTANING STOCK QTY": 9,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 9,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 0.86,
      "STD": 5.32049737408941,
      "MEAN": 2.15384615384615,
      "COV": 2.47023092368436,
      "SHELF LIFE_1": 3.857142857142863,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 10021425,
      "DESCRIPTION": "FILTER ELEMENT",
      "PRODUCT CATEGORY": "LIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 10021426,
      "DESCRIPTION": "SAFETY ELEMENT",
      "PRODUCT CATEGORY": "LIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 10035245,
      "DESCRIPTION": "FILTER ELEMENT",
      "PRODUCT CATEGORY": "LIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 10044302,
      "DESCRIPTION": "FUEL PRE-FILTER",
      "PRODUCT CATEGORY": "LIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 10044303,
      "DESCRIPTION": "10429946 FUEL FILTER FINE",
      "PRODUCT CATEGORY": "LIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 10297295,
      "DESCRIPTION": "EASY CHANGE FILTER",
      "PRODUCT CATEGORY": "LIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 10429946,
      "DESCRIPTION": "FUEL PRE-FILTER 10044303",
      "PRODUCT CATEGORY": "LIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 10440983,
      "DESCRIPTION": "EASY CHANGE FILTER",
      "PRODUCT CATEGORY": "LIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 21739130,
      "DESCRIPTION": "EXHAUST VALVE 20755883",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3,
      "WHXX": 24,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 20460214,
      "DESCRIPTION": "CLIP",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 10,
      "WHXX": 5,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "A210417000095",
      "DESCRIPTION": "RETAINER RING 95GB895.1",
      "PRODUCT CATEGORY": "YPG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 9,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "B230101002126",
      "DESCRIPTION": "ZBR RING",
      "PRODUCT CATEGORY": "YPG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 27,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "B230101001161",
      "DESCRIPTION": "CRGS-TYPE SEAL RING",
      "PRODUCT CATEGORY": "YPG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 18,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 60044253,
      "DESCRIPTION": "ANTI DUST B230101001859",
      "PRODUCT CATEGORY": "YPG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 11,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4021000199,
      "DESCRIPTION": "SPHERICAL PLAIN BEARING 4120001004009 4120006629011",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 4,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 15058186,
      "DESCRIPTION": "SUPER HYDRAULIC OIL  ISO VG68",
      "PRODUCT CATEGORY": "LUV",
      "ITEM MOVEMENT BRANCH": "FAST (F1)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 2284.66666666666,
      "MAINTANING STOCK QTY": 9139,
      "AVAILABLE": 8472,
      "SHELF LIFE": 3.708199591479439,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 667,
      "APRD QTY": 660,
      "WH12": 4828,
      "WHXX": 4215,
      "WH REFERENCE": "WHXX",
      "TREND": 1.05,
      "STD": 929.042738659751,
      "MEAN": 2108.92307692307,
      "COV": 0.440529457345227,
      "SHELF LIFE_1": 4.000145900204272,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 15058290,
      "DESCRIPTION": "SUPER WET BRAKE TRANSAXLE OIL WB102 ",
      "PRODUCT CATEGORY": "LUV",
      "ITEM MOVEMENT BRANCH": "AVERAGE (F2)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 159.333333333333,
      "MAINTANING STOCK QTY": 637,
      "AVAILABLE": 520,
      "SHELF LIFE": 3.2635983263598396,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 117,
      "APRD QTY": 120,
      "WH12": 1136,
      "WHXX": 8,
      "WH REFERENCE": "WH12 + WHXX",
      "TREND": 0.89,
      "STD": 200.25419743352,
      "MEAN": 147.076923076923,
      "COV": 1.36156096581368,
      "SHELF LIFE_1": 3.9979079497908034,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 15067098,
      "DESCRIPTION": "AUTOMATIC TRANSMISSION FLUID AT101",
      "PRODUCT CATEGORY": "LUV",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 76.6666666666666,
      "MAINTANING STOCK QTY": 307,
      "AVAILABLE": 262,
      "SHELF LIFE": 3.417391304347829,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 45,
      "APRD QTY": 40,
      "WH12": 1661,
      "WHXX": 1,
      "WH REFERENCE": "WH12 + WHXX",
      "TREND": 0.61,
      "STD": 176.230036148851,
      "MEAN": 70.7692307692307,
      "COV": 2.49020703253812,
      "SHELF LIFE_1": 4.00434782608696,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 15067197,
      "DESCRIPTION": "ULTRA DIESEL ENGINE OIL 15W/40 VDS-3 85102464",
      "PRODUCT CATEGORY": "LUV",
      "ITEM MOVEMENT BRANCH": "FAST (F1)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 1613.33333333333,
      "MAINTANING STOCK QTY": 6453,
      "AVAILABLE": 5406,
      "SHELF LIFE": 3.3508264462809985,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1047,
      "APRD QTY": 1040,
      "WH12": 10902,
      "WHXX": 2090,
      "WH REFERENCE": "WHXX",
      "TREND": 0.93,
      "STD": 677.633892531721,
      "MEAN": 1489.23076923076,
      "COV": 0.455022758414895,
      "SHELF LIFE_1": 3.9997933884297603,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 15067515,
      "DESCRIPTION": "SUPER TRANSMISSION OIL  80W/90  API GL-5",
      "PRODUCT CATEGORY": "LUV",
      "ITEM MOVEMENT BRANCH": "FAST (F1)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 428.666666666666,
      "MAINTANING STOCK QTY": 1715,
      "AVAILABLE": 1023,
      "SHELF LIFE": 2.3864696734059136,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 692,
      "APRD QTY": 700,
      "WH12": 1714,
      "WHXX": 20,
      "WH REFERENCE": "WH12 + WHXX",
      "TREND": 1.04,
      "STD": 327.603262248558,
      "MEAN": 395.692307692307,
      "COV": 0.827924263069839,
      "SHELF LIFE_1": 4.000777604976678,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 15067522,
      "DESCRIPTION": "SUPER TRANSMISSION OIL  85W/140  OIL API GL-5 99990-1Z05J",
      "PRODUCT CATEGORY": "LUV",
      "ITEM MOVEMENT BRANCH": "FAST (F1)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 335,
      "MAINTANING STOCK QTY": 1340,
      "AVAILABLE": 1216,
      "SHELF LIFE": 3.629850746268657,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 124,
      "APRD QTY": 120,
      "WH12": 6688,
      "WHXX": 457,
      "WH REFERENCE": "WHXX",
      "TREND": 0.98,
      "STD": 169.118377596953,
      "MEAN": 309.230769230769,
      "COV": 0.546900226059798,
      "SHELF LIFE_1": 4,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 15067644,
      "DESCRIPTION": "WET BRAKE TRANSAXLE OIL WB101",
      "PRODUCT CATEGORY": "LUV",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 110,
      "MAINTANING STOCK QTY": 440,
      "AVAILABLE": 400,
      "SHELF LIFE": 3.6363636363636362,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 40,
      "APRD QTY": 40,
      "WH12": 1252,
      "WHXX": 20,
      "WH REFERENCE": "WH12 + WHXX",
      "TREND": 0,
      "STD": 282.307342974701,
      "MEAN": 101.538461538461,
      "COV": 2.78029958990236,
      "SHELF LIFE_1": 4,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 15143928,
      "DESCRIPTION": "AUTOMATIC TRANSMISSION FLUID AT102  L150G/A40F",
      "PRODUCT CATEGORY": "LUV",
      "ITEM MOVEMENT BRANCH": "AVERAGE (F2)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 75.6666666666666,
      "MAINTANING STOCK QTY": 303,
      "AVAILABLE": 320,
      "SHELF LIFE": 4.229074889867845,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2532,
      "WHXX": 22,
      "WH REFERENCE": "",
      "TREND": 0.66,
      "STD": 98.6254245059272,
      "MEAN": 69.8461538461538,
      "COV": 1.41203801605402,
      "SHELF LIFE_1": 4.229074889867845,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 15198387,
      "DESCRIPTION": "SUPER GEAR OIL 75W80 GO102 L150G/A40F 15057965 20L/PAIL",
      "PRODUCT CATEGORY": "LUV",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 80,
      "MAINTANING STOCK QTY": 320,
      "AVAILABLE": 792,
      "SHELF LIFE": 9.9,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 6000,
      "WHXX": 1940,
      "WH REFERENCE": "",
      "TREND": 1.04,
      "STD": 159.663106860375,
      "MEAN": 73.8461538461538,
      "COV": 2.16210457206758,
      "SHELF LIFE_1": 9.9,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": "175-49-11580",
      "DESCRIPTION": "FILTER ELEMENT, STRG  SD22  16Y-75-23200  HF6072 ",
      "PRODUCT CATEGORY": "SDG",
      "ITEM MOVEMENT BRANCH": "AVERAGE (F2)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.5,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 2,
      "SHELF LIFE": 4,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 1.67,
      "STD": 0.776250025806184,
      "MEAN": 0.461538461538461,
      "COV": 1.6818750559134,
      "SHELF LIFE_1": 4,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": "175-60-27380",
      "DESCRIPTION": "FILTER ELEMENT, HYD.  SD22    HF6101",
      "PRODUCT CATEGORY": "SDG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 1,
      "SHELF LIFE": 6.000000000000024,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 6,
      "WHXX": 1,
      "WH REFERENCE": "",
      "TREND": 1,
      "STD": 0.375533808099405,
      "MEAN": 0.153846153846153,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 3325963,
      "DESCRIPTION": "BELT, V RIBBED FAN DRV SD22",
      "PRODUCT CATEGORY": "SDG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "6127-81-7412T",
      "DESCRIPTION": "AIR FILTER KIT  SD22  (AF4504M+AF1791)",
      "PRODUCT CATEGORY": "SDG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 1,
      "SHELF LIFE": 6.000000000000024,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 19,
      "WHXX": 1,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.375533808099405,
      "MEAN": 0.153846153846153,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": "D2500-00000",
      "DESCRIPTION": "SWITCH, STARTING SD22",
      "PRODUCT CATEGORY": "SDG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 13351382,
      "DESCRIPTION": "CONTROL,THROTTLE",
      "PRODUCT CATEGORY": "VCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 13465547,
      "DESCRIPTION": "FUEL FILTER FS19616 SD100DC QSB",
      "PRODUCT CATEGORY": "VCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 3,
      "SHELF LIFE": 18.000000000000075,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 32,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.375533808099405,
      "MEAN": 0.153846153846153,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 18.000000000000075,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 13469606,
      "DESCRIPTION": "COIL SD110",
      "PRODUCT CATEGORY": "VCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 4,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 15155620,
      "DESCRIPTION": "OIL FILTER 43922913",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.916666666666666,
      "MAINTANING STOCK QTY": 4,
      "AVAILABLE": 7,
      "SHELF LIFE": 7.636363636363642,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 30,
      "WHXX": 58,
      "WH REFERENCE": "",
      "TREND": 0.73,
      "STD": 1.46322439872551,
      "MEAN": 0.846153846153846,
      "COV": 1.72926519849378,
      "SHELF LIFE_1": 7.636363636363642,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 20163150,
      "DESCRIPTION": "FUEL FILTER, PRIM 35374677 59477570 FF42000",
      "PRODUCT CATEGORY": "VCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.25,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 10,
      "WHXX": 1,
      "WH REFERENCE": "WHXX",
      "TREND": 0,
      "STD": 0.832050294337843,
      "MEAN": 0.23076923076923,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 4,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 43904234,
      "DESCRIPTION": "AIR FILTER INSERT HP935WCU/SD100D 43931930",
      "PRODUCT CATEGORY": "VCG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.25,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 25,
      "WHXX": 1,
      "WH REFERENCE": "WHXX",
      "TREND": 0.67,
      "STD": 0.438529009653514,
      "MEAN": 0.23076923076923,
      "COV": 1.90029237516522,
      "SHELF LIFE_1": 4,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 43908268,
      "DESCRIPTION": "FUEL GAUGE",
      "PRODUCT CATEGORY": "VCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 3,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 43911668,
      "DESCRIPTION": "IN-LINE FILTER FF5079 P550974",
      "PRODUCT CATEGORY": "VCG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.916666666666666,
      "MAINTANING STOCK QTY": 4,
      "AVAILABLE": 1,
      "SHELF LIFE": 1.0909090909090917,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 3,
      "APRD QTY": 3,
      "WH12": 24,
      "WHXX": 4,
      "WH REFERENCE": "WHXX",
      "TREND": 0.73,
      "STD": 1.34450448407296,
      "MEAN": 0.846153846153846,
      "COV": 1.5889598448135,
      "SHELF LIFE_1": 4.363636363636367,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 43921394,
      "DESCRIPTION": "OIL ELEMENT SD100D HF6700",
      "PRODUCT CATEGORY": "VCG",
      "ITEM MOVEMENT BRANCH": "AVERAGE (F2)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 4.41666666666666,
      "MAINTANING STOCK QTY": 18,
      "AVAILABLE": 13,
      "SHELF LIFE": 2.943396226415099,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 5,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 29,
      "WH REFERENCE": "WHXX",
      "TREND": 0.53,
      "STD": 7.66443668794972,
      "MEAN": 4.07692307692307,
      "COV": 1.87995616874238,
      "SHELF LIFE_1": 4.075471698113214,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 43922400,
      "DESCRIPTION": "HYD OIL FILTER SD160 HF6556 SD110B",
      "PRODUCT CATEGORY": "VCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 2,
      "SHELF LIFE": 24.00000000000001,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 34,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 24.00000000000001,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 43923093,
      "DESCRIPTION": "FUEL FILTER 36846",
      "PRODUCT CATEGORY": "VCG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.5,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 3,
      "SHELF LIFE": 6,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 121,
      "WHXX": 58,
      "WH REFERENCE": "",
      "TREND": 1.33,
      "STD": 1.12660142429821,
      "MEAN": 0.461538461538461,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 6,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 43924422,
      "DESCRIPTION": "FUEL FILTER, PRIM SD100D FF42000",
      "PRODUCT CATEGORY": "VCG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.583333333333333,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 3,
      "SHELF LIFE": 5.142857142857146,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 55,
      "WHXX": 99,
      "WH REFERENCE": "",
      "TREND": 1.14,
      "STD": 1.19828937903055,
      "MEAN": 0.538461538461538,
      "COV": 2.22539456105674,
      "SHELF LIFE_1": 5.142857142857146,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 43927185,
      "DESCRIPTION": "FILTER INSERT DD80 43922285",
      "PRODUCT CATEGORY": "VCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.25,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 41,
      "WHXX": 15,
      "WH REFERENCE": "WHXX",
      "TREND": 0,
      "STD": 0.832050294337843,
      "MEAN": 0.23076923076923,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 4,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 43931922,
      "DESCRIPTION": "ELEMENT A/F SD100D 35318252 AF1733K P182059",
      "PRODUCT CATEGORY": "VCG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.25,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 1,
      "SHELF LIFE": 4,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 3,
      "WH REFERENCE": "",
      "TREND": 0.67,
      "STD": 0.438529009653514,
      "MEAN": 0.23076923076923,
      "COV": 1.90029237516522,
      "SHELF LIFE_1": 4,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 59058057,
      "DESCRIPTION": "CAP, FUEL",
      "PRODUCT CATEGORY": "VCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 59093351,
      "DESCRIPTION": "VENTING, DIESEL TANK SD110",
      "PRODUCT CATEGORY": "VCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.25,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 6,
      "WH REFERENCE": "WHXX",
      "TREND": 0,
      "STD": 0.832050294337843,
      "MEAN": 0.23076923076923,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 4,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 59122085,
      "DESCRIPTION": "BRAKE VALVE DD80 SD110",
      "PRODUCT CATEGORY": "VCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 59155317,
      "DESCRIPTION": "SOLENOID, BRAKE & DESTROKE VALVE SD110",
      "PRODUCT CATEGORY": "VCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 2,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 59286336,
      "DESCRIPTION": "COUPLING PIECE SD100D-C / SD110D",
      "PRODUCT CATEGORY": "VCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 3,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 59309104,
      "DESCRIPTION": "FAN BELT SD100D/C 4BT 56\"",
      "PRODUCT CATEGORY": "VCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 29,
      "WHXX": 4,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 59524447,
      "DESCRIPTION": "STEM, VALVE 4275-5",
      "PRODUCT CATEGORY": "VCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 5,
      "WHXX": 6,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 59648543,
      "DESCRIPTION": "SWITCH",
      "PRODUCT CATEGORY": "VCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 2,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 96702329,
      "DESCRIPTION": "997953 CAPSCREW 96718887 ",
      "PRODUCT CATEGORY": "VCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 96738596,
      "DESCRIPTION": "FLAT WASHER",
      "PRODUCT CATEGORY": "VCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 8,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 48,
      "WHXX": 83,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 11419486,
      "DESCRIPTION": " TEMPERATURE SENSOR G930/A40F 14508670",
      "PRODUCT CATEGORY": "VGG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 3,
      "WH REFERENCE": "WHXX",
      "TREND": 1,
      "STD": 0.375533808099405,
      "MEAN": 0.153846153846153,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": "12119-20",
      "DESCRIPTION": "SHIM",
      "PRODUCT CATEGORY": "VGG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 12724048,
      "DESCRIPTION": "BOOSTER G930",
      "PRODUCT CATEGORY": "VGG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 12724306,
      "DESCRIPTION": "REP KIT_BLADE LIFT G710B/G930",
      "PRODUCT CATEGORY": "VGG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 1,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3,
      "WHXX": 3,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 12725151,
      "DESCRIPTION": "REPAIR KIT G930",
      "PRODUCT CATEGORY": "VGG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 11,
      "WHXX": 5,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 12729935,
      "DESCRIPTION": "WASHER, PLAIN (PLOW BOLT 3/4\") G710B/G930",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 20,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 70,
      "WHXX": 13,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 12742526,
      "DESCRIPTION": "FILTER, CAB G930",
      "PRODUCT CATEGORY": "VGG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 4,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 33,
      "WHXX": 22,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 12742527,
      "DESCRIPTION": "FILTER, CAB G930",
      "PRODUCT CATEGORY": "VGG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 2,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 11,
      "WHXX": 39,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 12743273,
      "DESCRIPTION": "COIL G930",
      "PRODUCT CATEGORY": "VGG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 12743408,
      "DESCRIPTION": "ELEMENT G930",
      "PRODUCT CATEGORY": "VGG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 3,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 29,
      "WHXX": 7,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 12744293,
      "DESCRIPTION": "PLATE G930",
      "PRODUCT CATEGORY": "VGG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3,
      "WHXX": 3,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 12745093,
      "DESCRIPTION": "END PANEL (5HOLES) 22\" MLBD 5/8\" BOLTS G930",
      "PRODUCT CATEGORY": "VGG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3,
      "WHXX": 4,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14016107,
      "DESCRIPTION": "RET. RING 19B-218",
      "PRODUCT CATEGORY": "VGG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 15,
      "WHXX": 14,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 19629,
      "DESCRIPTION": "184059 PLAIN BEARING",
      "PRODUCT CATEGORY": "VGG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 4,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 2010900046,
      "DESCRIPTION": "FUEL STRAINER, TANK LG936L",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 62,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 2030900065,
      "DESCRIPTION": "OIL STRAINER, TRANS. LG956L",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "AVERAGE (F2)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 1.41666666666666,
      "MAINTANING STOCK QTY": 6,
      "AVAILABLE": 6,
      "SHELF LIFE": 4.235294117647078,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 38,
      "WHXX": 18,
      "WH REFERENCE": "",
      "TREND": 1.18,
      "STD": 1.75045781557356,
      "MEAN": 1.3076923076923,
      "COV": 1.33858538837978,
      "SHELF LIFE_1": 4.235294117647078,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 20792871,
      "DESCRIPTION": "GASKET G930",
      "PRODUCT CATEGORY": "VGG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3,
      "WHXX": 3,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 20798114,
      "DESCRIPTION": "UNIT INJECTOR ENG: D7E EAE2 EC290B",
      "PRODUCT CATEGORY": "VGG",
      "ITEM MOVEMENT BRANCH": "AVERAGE (F2)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 4.08333333333333,
      "MAINTANING STOCK QTY": 16,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 16,
      "APRD QTY": 16,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 1.18,
      "STD": 6.2470505861279,
      "MEAN": 3.76923076923076,
      "COV": 1.65738076774822,
      "SHELF LIFE_1": 3.9183673469387785,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 20799064,
      "DESCRIPTION": "SEALING RING G930",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.833333333333333,
      "MAINTANING STOCK QTY": 3,
      "AVAILABLE": 5,
      "SHELF LIFE": 6.000000000000002,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 70,
      "WHXX": 233,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 1.92153784566104,
      "MEAN": 0.769230769230769,
      "COV": 2.49799919935935,
      "SHELF LIFE_1": 6.000000000000002,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 20799719,
      "DESCRIPTION": "BOLT G930",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.833333333333333,
      "MAINTANING STOCK QTY": 3,
      "AVAILABLE": 3,
      "SHELF LIFE": 3.6000000000000014,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 6,
      "WHXX": 9,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 1.92153784566104,
      "MEAN": 0.769230769230769,
      "COV": 2.49799919935935,
      "SHELF LIFE_1": 3.6000000000000014,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 21147445,
      "DESCRIPTION": "HIGH-PRESSURE PUMP L120F 20795413",
      "PRODUCT CATEGORY": "VGG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.833333333333333,
      "MAINTANING STOCK QTY": 3,
      "AVAILABLE": 2,
      "SHELF LIFE": 2.400000000000001,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 1.2,
      "STD": 1.5358952955766,
      "MEAN": 0.769230769230769,
      "COV": 1.99666388424959,
      "SHELF LIFE_1": 3.6000000000000014,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 21342980,
      "DESCRIPTION": "23479910 GASKET, TURBOCHARGER 20792408",
      "PRODUCT CATEGORY": "VGG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 2,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 2,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 29070000081,
      "DESCRIPTION": "RIM NUT   ",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 16.1666666666666,
      "MAINTANING STOCK QTY": 65,
      "AVAILABLE": 52,
      "SHELF LIFE": 3.2164948453608377,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 13,
      "APRD QTY": 13,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 1.69,
      "STD": 39.518901676838,
      "MEAN": 14.9230769230769,
      "COV": 2.64817382370564,
      "SHELF LIFE_1": 4.020618556701048,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 29070000621,
      "DESCRIPTION": "RIM BOLT  ",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 17.0833333333333,
      "MAINTANING STOCK QTY": 68,
      "AVAILABLE": 44,
      "SHELF LIFE": 2.575609756097566,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 24,
      "APRD QTY": 24,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 1.9,
      "STD": 39.7537290623027,
      "MEAN": 15.7692307692307,
      "COV": 2.52096818443871,
      "SHELF LIFE_1": 3.9804878048780563,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 29100000061,
      "DESCRIPTION": "OIL STRAINER, HYDRAULIC",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "AVERAGE (F2)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 1.16666666666666,
      "MAINTANING STOCK QTY": 5,
      "AVAILABLE": 5,
      "SHELF LIFE": 4.28571428571431,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 113,
      "WHXX": 47,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 2.62873665669583,
      "MEAN": 1.07692307692307,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 4.28571428571431,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 29100004061,
      "DESCRIPTION": "FILTER INSERT  LG938",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.916666666666666,
      "MAINTANING STOCK QTY": 4,
      "AVAILABLE": 3,
      "SHELF LIFE": 3.272727272727275,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 0,
      "WHXX": 7,
      "WH REFERENCE": "WHXX",
      "TREND": 1.27,
      "STD": 0.898717034272917,
      "MEAN": 0.846153846153846,
      "COV": 1.06212013141344,
      "SHELF LIFE_1": 4.363636363636367,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 29170019701,
      "DESCRIPTION": "BOLT, CORNER LG958",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 12.3333333333333,
      "MAINTANING STOCK QTY": 49,
      "AVAILABLE": 45,
      "SHELF LIFE": 3.6486486486486585,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 4,
      "APRD QTY": 0,
      "WH12": 55,
      "WHXX": 3,
      "WH REFERENCE": "WH12 + WHXX",
      "TREND": 0.92,
      "STD": 24.1023458800814,
      "MEAN": 11.3846153846153,
      "COV": 2.11709794892606,
      "SHELF LIFE_1": 3.9729729729729835,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 29170019711,
      "DESCRIPTION": "BOLT, BUCKET EDGES  LG958",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "AVERAGE (F2)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 40.0833333333333,
      "MAINTANING STOCK QTY": 160,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 160,
      "APRD QTY": 160,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 1.34,
      "STD": 41.6112965431263,
      "MEAN": 37,
      "COV": 1.12462963630071,
      "SHELF LIFE_1": 3.991683991683995,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 29170020531,
      "DESCRIPTION": "SPACER, BUCKET TOOTH  LG956 / LG958",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "AVERAGE (F2)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 19.8333333333333,
      "MAINTANING STOCK QTY": 79,
      "AVAILABLE": 74,
      "SHELF LIFE": 3.731092436974796,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 5,
      "APRD QTY": 5,
      "WH12": 30,
      "WHXX": 7,
      "WH REFERENCE": "WHXX",
      "TREND": 1.33,
      "STD": 26.7157401026205,
      "MEAN": 18.3076923076923,
      "COV": 1.45926311484902,
      "SHELF LIFE_1": 3.983193277310931,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 29170036961,
      "DESCRIPTION": "CORNER TEETH RH, BUCKET  ",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 1,
      "SHELF LIFE": 12.000000000000005,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 1,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 12.000000000000005,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 29170036971,
      "DESCRIPTION": "TEETH, BUCKET  ",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 5,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 36,
      "WHXX": 17,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 29170036981,
      "DESCRIPTION": "CORNER TEETH LH, BUCKET  ",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 1,
      "SHELF LIFE": 12.000000000000005,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 1,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 12.000000000000005,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 29170039941,
      "DESCRIPTION": "TOOTH,  BUCKET  LG956 / LG958",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "AVERAGE (F2)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 10.3333333333333,
      "MAINTANING STOCK QTY": 41,
      "AVAILABLE": 11,
      "SHELF LIFE": 1.0645161290322616,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 30,
      "APRD QTY": 30,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 1.61,
      "STD": 14.0452565220159,
      "MEAN": 9.53846153846153,
      "COV": 1.4724865708565,
      "SHELF LIFE_1": 3.967741935483884,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 29170039951,
      "DESCRIPTION": "SIDE CUTTER  LH, BUCKET  LG956 / LG958 29170019681",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 2.16666666666666,
      "MAINTANING STOCK QTY": 9,
      "AVAILABLE": 5,
      "SHELF LIFE": 2.307692307692315,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 4,
      "APRD QTY": 4,
      "WH12": 0,
      "WHXX": 2,
      "WH REFERENCE": "PARTIAL WHXX",
      "TREND": 1.69,
      "STD": 3.34165627596057,
      "MEAN": 2,
      "COV": 1.67082813798028,
      "SHELF LIFE_1": 4.153846153846167,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 29170039961,
      "DESCRIPTION": "SIDE CUTTER  RH, BUCKET  LG956 / LG958 29170019691",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 2.08333333333333,
      "MAINTANING STOCK QTY": 8,
      "AVAILABLE": 6,
      "SHELF LIFE": 2.880000000000005,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 2,
      "APRD QTY": 2,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 1.68,
      "STD": 3.14805597317716,
      "MEAN": 1.92307692307692,
      "COV": 1.63698910605212,
      "SHELF LIFE_1": 3.840000000000006,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 29250000061,
      "DESCRIPTION": "SPACER, FRNT FRAME  ",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 16,
      "WHXX": 5,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 29250000071,
      "DESCRIPTION": "SPACER, FRNT FRAME  ",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 2,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 29250000081,
      "DESCRIPTION": "SPACER, FRNT FRAME  ",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 46,
      "WHXX": 39,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4013000016,
      "DESCRIPTION": "NUT",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 1.16666666666666,
      "MAINTANING STOCK QTY": 5,
      "AVAILABLE": 7,
      "SHELF LIFE": 6.000000000000034,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 40,
      "WHXX": 30,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 3.8829013735766,
      "MEAN": 1.07692307692307,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000034,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 4013000020,
      "DESCRIPTION": "NUT ",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "AVERAGE (F2)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 39.3333333333333,
      "MAINTANING STOCK QTY": 157,
      "AVAILABLE": 38,
      "SHELF LIFE": 0.9661016949152551,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 119,
      "APRD QTY": 119,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 1.2,
      "STD": 37.0211304873865,
      "MEAN": 36.3076923076923,
      "COV": 1.01964978037293,
      "SHELF LIFE_1": 3.991525423728817,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 4015000001,
      "DESCRIPTION": "WASHER ",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 1.16666666666666,
      "MAINTANING STOCK QTY": 5,
      "AVAILABLE": 7,
      "SHELF LIFE": 6.000000000000034,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 21,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 3.8829013735766,
      "MEAN": 1.07692307692307,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000034,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 4015000176,
      "DESCRIPTION": "WASHER ",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 17.3333333333333,
      "MAINTANING STOCK QTY": 69,
      "AVAILABLE": 67,
      "SHELF LIFE": 3.865384615384623,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 2,
      "APRD QTY": 0,
      "WH12": 62,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 0.63,
      "STD": 24.4404037064311,
      "MEAN": 16,
      "COV": 1.52752523165194,
      "SHELF LIFE_1": 3.9807692307692384,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 4021000058,
      "DESCRIPTION": "BEARING, FRONT FRAME  LG956L/LG958",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 11,
      "WHXX": 1,
      "WH REFERENCE": "WHXX",
      "TREND": 0,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 4030000048,
      "DESCRIPTION": "SKELETON OIL SEAL ",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4040000011,
      "DESCRIPTION": "BOLT, BUCKET ",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 10,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 20,
      "WHXX": 10,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4043000033,
      "DESCRIPTION": "SPACE LG958",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 17,
      "WHXX": 8,
      "WH REFERENCE": "WHXX",
      "TREND": 0,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 4043000034,
      "DESCRIPTION": "SPACE LG958",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 14,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4043000055,
      "DESCRIPTION": "PUSTRING LG958",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.666666666666666,
      "MAINTANING STOCK QTY": 3,
      "AVAILABLE": 1,
      "SHELF LIFE": 1.5000000000000016,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 2,
      "APRD QTY": 2,
      "WH12": 16,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 2,
      "STD": 1.50213523239762,
      "MEAN": 0.615384615384615,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 4.500000000000004,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 4043000056,
      "DESCRIPTION": "PUSTRING LG958",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 1.5,
      "MAINTANING STOCK QTY": 6,
      "AVAILABLE": 4,
      "SHELF LIFE": 2.6666666666666665,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 2,
      "APRD QTY": 2,
      "WH12": 34,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 1.33,
      "STD": 3.59486813709166,
      "MEAN": 1.38461538461538,
      "COV": 2.5962936545662,
      "SHELF LIFE_1": 4,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 4043000057,
      "DESCRIPTION": "PUSTRING, FRONT FRAME  ",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.833333333333333,
      "MAINTANING STOCK QTY": 3,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 3,
      "APRD QTY": 3,
      "WH12": 5,
      "WHXX": 3,
      "WH REFERENCE": "WHXX",
      "TREND": 1.6,
      "STD": 2.24179415327122,
      "MEAN": 0.769230769230769,
      "COV": 2.91433239925258,
      "SHELF LIFE_1": 3.6000000000000014,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 4043000058,
      "DESCRIPTION": "PUSTRING ",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.5,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 2,
      "APRD QTY": 2,
      "WH12": 10,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 1.33,
      "STD": 1.19828937903055,
      "MEAN": 0.461538461538461,
      "COV": 2.5962936545662,
      "SHELF LIFE_1": 4,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 4043000059,
      "DESCRIPTION": "O-RING ",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 1.66666666666666,
      "MAINTANING STOCK QTY": 7,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 7,
      "APRD QTY": 0,
      "WH12": 42,
      "WHXX": 3,
      "WH REFERENCE": "WH12 + WHXX",
      "TREND": 0.8,
      "STD": 3.07179059115321,
      "MEAN": 1.53846153846153,
      "COV": 1.99666388424959,
      "SHELF LIFE_1": 4.200000000000016,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 4043000060,
      "DESCRIPTION": "PIN LG958",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 4,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4043000173,
      "DESCRIPTION": "PIN ",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.5,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 2,
      "APRD QTY": 0,
      "WH12": 9,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 0.67,
      "STD": 0.877058019307029,
      "MEAN": 0.461538461538461,
      "COV": 1.90029237516522,
      "SHELF LIFE_1": 4,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 4043000337,
      "DESCRIPTION": "BOLT",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 1,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 52,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4110000076368,
      "DESCRIPTION": "OIL FILTER, SPIN-ON LG938 7200001778",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "AVERAGE (F2)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 1.08333333333333,
      "MAINTANING STOCK QTY": 4,
      "AVAILABLE": 1,
      "SHELF LIFE": 0.923076923076926,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 3,
      "APRD QTY": 3,
      "WH12": 32,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 1.38,
      "STD": 1.2909944487358,
      "MEAN": 1,
      "COV": 1.2909944487358,
      "SHELF LIFE_1": 3.692307692307704,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 4110000081265,
      "DESCRIPTION": "FUEL FILTER LG936 ",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 9,
      "SHELF LIFE": 27.00000000000003,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 191,
      "WHXX": 16,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 1.10940039245045,
      "MEAN": 0.307692307692307,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 27.00000000000003,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 4110000081312,
      "DESCRIPTION": "FILTER FWS LG936/LG956 7200002428",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 8,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 10,
      "WHXX": 3,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4110000179020,
      "DESCRIPTION": "OIL FILTER LG936L",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 1,
      "SHELF LIFE": 3.000000000000003,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 54,
      "WHXX": 24,
      "WH REFERENCE": "",
      "TREND": 1,
      "STD": 0.480384461415261,
      "MEAN": 0.307692307692307,
      "COV": 1.56124949959959,
      "SHELF LIFE_1": 3.000000000000003,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 4110000556209,
      "DESCRIPTION": "OIL FILTER  LG956L",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "FAST (F1)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 21.5,
      "MAINTANING STOCK QTY": 86,
      "AVAILABLE": 6,
      "SHELF LIFE": 0.27906976744186046,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 80,
      "APRD QTY": 80,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 1.4,
      "STD": 24.4057580427452,
      "MEAN": 19.8461538461538,
      "COV": 1.22974749827786,
      "SHELF LIFE_1": 4,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 4110000589001,
      "DESCRIPTION": "FUEL FILTER",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "FAST (F1)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 29.4166666666666,
      "MAINTANING STOCK QTY": 118,
      "AVAILABLE": 88,
      "SHELF LIFE": 2.9915014164306015,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 30,
      "APRD QTY": 30,
      "WH12": 48,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 1.1,
      "STD": 30.4051699382581,
      "MEAN": 27.1538461538461,
      "COV": 1.11973713653642,
      "SHELF LIFE_1": 4.011331444759215,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 4110000589016,
      "DESCRIPTION": "AIR FILTER KIT LG956L",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.833333333333333,
      "MAINTANING STOCK QTY": 3,
      "AVAILABLE": 4,
      "SHELF LIFE": 4.800000000000002,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 79,
      "WHXX": 2,
      "WH REFERENCE": "",
      "TREND": 0.8,
      "STD": 1.09192842819833,
      "MEAN": 0.769230769230769,
      "COV": 1.41950695665783,
      "SHELF LIFE_1": 4.800000000000002,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 4110000613,
      "DESCRIPTION": "FILTER FILTER, FUEL TANK CAP LG956L/LG936L",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.25,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 49,
      "WHXX": 3,
      "WH REFERENCE": "WHXX",
      "TREND": 1.33,
      "STD": 0.438529009653514,
      "MEAN": 0.23076923076923,
      "COV": 1.90029237516522,
      "SHELF LIFE_1": 4,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 4110000679001,
      "DESCRIPTION": "14405944 AIR FILTER, OUTER LG936L ",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "AVERAGE (F2)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 2.5,
      "MAINTANING STOCK QTY": 10,
      "AVAILABLE": 1,
      "SHELF LIFE": 0.4,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 9,
      "APRD QTY": 9,
      "WH12": 137,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 1.13,
      "STD": 1.54836555678428,
      "MEAN": 2.3076923076923,
      "COV": 0.670958407939855,
      "SHELF LIFE_1": 4,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 4110000679002,
      "DESCRIPTION": "AIR FILTER, INNER LG936L 14405945",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.5,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 1,
      "SHELF LIFE": 2,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 56,
      "WHXX": 3,
      "WH REFERENCE": "WHXX",
      "TREND": 1.33,
      "STD": 0.660225291773524,
      "MEAN": 0.461538461538461,
      "COV": 1.43048813217597,
      "SHELF LIFE_1": 4,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 4110001016004,
      "DESCRIPTION": "FILTER, FWS  LG956L 4110000112006",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "FAST (F1)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 12.0833333333333,
      "MAINTANING STOCK QTY": 48,
      "AVAILABLE": 35,
      "SHELF LIFE": 2.896551724137939,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 13,
      "APRD QTY": 13,
      "WH12": 9,
      "WHXX": 0,
      "WH REFERENCE": "PARTIAL WH12",
      "TREND": 1.42,
      "STD": 12.4153544307452,
      "MEAN": 11.1538461538461,
      "COV": 1.11310074206681,
      "SHELF LIFE_1": 3.9724137931034593,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 4120000065,
      "DESCRIPTION": "SAFETY VALVE LG958",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 1.33333333333333,
      "MAINTANING STOCK QTY": 5,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 5,
      "APRD QTY": 5,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 2,
      "STD": 4.43760156980183,
      "MEAN": 1.23076923076923,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 3.7500000000000098,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 4120000066,
      "DESCRIPTION": "WATER DRAIN VALVE ",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 7,
      "WHXX": 2,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4120000084,
      "DESCRIPTION": "AIR DRYER (ST-50G) LG956/LG958",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.75,
      "MAINTANING STOCK QTY": 3,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 3,
      "APRD QTY": 0,
      "WH12": 45,
      "WHXX": 1,
      "WH REFERENCE": "WH12 + WHXX",
      "TREND": 0.67,
      "STD": 0.75106761619881,
      "MEAN": 0.692307692307692,
      "COV": 1.0848754456205,
      "SHELF LIFE_1": 4,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 4120000094,
      "DESCRIPTION": "OIL LEVER METER ",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 15,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4120000759,
      "DESCRIPTION": "BRAKE VALVE ",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.416666666666666,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 2,
      "APRD QTY": 2,
      "WH12": 11,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 2,
      "STD": 1.38675049056307,
      "MEAN": 0.384615384615384,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 4.800000000000008,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 4120000760,
      "DESCRIPTION": "PRESSURE SWITCH ",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 4,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4120001739,
      "DESCRIPTION": "DISK BRAKE CALIPER ",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 3.91666666666666,
      "MAINTANING STOCK QTY": 16,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 16,
      "APRD QTY": 16,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 1.83,
      "STD": 9.10621089823187,
      "MEAN": 3.61538461538461,
      "COV": 2.51873918461732,
      "SHELF LIFE_1": 4.08510638297873,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 4120001739016,
      "DESCRIPTION": "BRAKE PLATE LG938 LG956",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "AVERAGE (F2)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 30,
      "MAINTANING STOCK QTY": 120,
      "AVAILABLE": 5,
      "SHELF LIFE": 0.16666666666666666,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 115,
      "APRD QTY": 115,
      "WH12": 0,
      "WHXX": 2,
      "WH REFERENCE": "PARTIAL WHXX",
      "TREND": 1.38,
      "STD": 29.187282091648,
      "MEAN": 27.6923076923076,
      "COV": 1.05398518664284,
      "SHELF LIFE_1": 4,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 4120001954001,
      "DESCRIPTION": "FILTER INSERT, HYDRAULIC",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "FAST (F1)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 6.75,
      "MAINTANING STOCK QTY": 27,
      "AVAILABLE": 26,
      "SHELF LIFE": 3.8518518518518516,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 112,
      "WHXX": 5,
      "WH REFERENCE": "WHXX",
      "TREND": 1.53,
      "STD": 8.02240452476527,
      "MEAN": 6.23076923076923,
      "COV": 1.28754640520924,
      "SHELF LIFE_1": 4,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 4130000009,
      "DESCRIPTION": "HORN DL125-24V  LG956L",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 8,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4130000542,
      "DESCRIPTION": "LEFT FRONT HEAD LAMP LFLDH4-24V  LG956L/LG936",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.25,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 1,
      "SHELF LIFE": 4,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0.67,
      "STD": 0.438529009653514,
      "MEAN": 0.23076923076923,
      "COV": 1.90029237516522,
      "SHELF LIFE_1": 4,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 4130000962,
      "DESCRIPTION": "RELAY SHUT-OFF SOLENOID JCC100/IC24-48  LG956L",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 5,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4190000153001,
      "DESCRIPTION": "FUEL FILTER SEP.  LG936L",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 9,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 46,
      "WHXX": 21,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4341,
      "DESCRIPTION": "BREATHER",
      "PRODUCT CATEGORY": "VGG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 20,
      "WHXX": 10,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 53063,
      "DESCRIPTION": "CAPSCREW-BRASS G930",
      "PRODUCT CATEGORY": "VGG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 4,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 20,
      "WHXX": 29,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 54934,
      "DESCRIPTION": "PIN-BRASS-CIRCL G930",
      "PRODUCT CATEGORY": "VGG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 8,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 67,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 60100000,
      "DESCRIPTION": "ELECTRONIC UNIT E-ECU G930 EC210B EC290B",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.5,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 2,
      "SHELF LIFE": 4,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 6,
      "WHXX": 2,
      "WH REFERENCE": "",
      "TREND": 1.67,
      "STD": 0.660225291773524,
      "MEAN": 0.461538461538461,
      "COV": 1.43048813217597,
      "SHELF LIFE_1": 4,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 69510,
      "DESCRIPTION": "SNAP RING-EXT-H G930",
      "PRODUCT CATEGORY": "VGG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 12,
      "WHXX": 5,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 69772,
      "DESCRIPTION": "CRANK ARM G930",
      "PRODUCT CATEGORY": "VGG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 69779,
      "DESCRIPTION": "SPACER-PINION G930",
      "PRODUCT CATEGORY": "VGG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 6,
      "WHXX": 4,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 69781,
      "DESCRIPTION": "WEAR PLATE G930",
      "PRODUCT CATEGORY": "VGG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 5,
      "WHXX": 2,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 75439,
      "DESCRIPTION": "CUTTING EDGE 5/8\" X 6\" X 7FT 15 (5/8\") HOLES",
      "PRODUCT CATEGORY": "VGG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 5,
      "WHXX": 3,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 75442,
      "DESCRIPTION": "CUTTING EDGE 1\" X 8\" X 7FT 15(5/8\") HOLES",
      "PRODUCT CATEGORY": "VGG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 4,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 76282,
      "DESCRIPTION": "MASTER CYLINDER G930",
      "PRODUCT CATEGORY": "VGG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 76288,
      "DESCRIPTION": "FLOW SWITCH G930",
      "PRODUCT CATEGORY": "VGG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 8111,
      "DESCRIPTION": "16015376 SCARIFIER TIP G930 75703",
      "PRODUCT CATEGORY": "VGG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 87022,
      "DESCRIPTION": "DURAMIDE G930",
      "PRODUCT CATEGORY": "VGG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 2,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 983336,
      "DESCRIPTION": "WASHER",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 10,
      "WHXX": 8,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 984507,
      "DESCRIPTION": "PLOW BOLT (5/8\"X2 1/2) 0.63\"NC X40F G930",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 20,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 44,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 990202,
      "DESCRIPTION": "HEX. SOCKET SCREW G930",
      "PRODUCT CATEGORY": "VGG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 16,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 990427,
      "DESCRIPTION": "NIPPLE G930",
      "PRODUCT CATEGORY": "VGG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 991039,
      "DESCRIPTION": "HEX NUT, PLOW BOLT (5/8\"X2 1/2) 30A-10ZI G930 ",
      "PRODUCT CATEGORY": "VGG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 20,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 160,
      "WHXX": 58,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 11000233,
      "DESCRIPTION": "O-RING",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 5,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 11026989,
      "DESCRIPTION": "16854341 STEERING DAMPER, FRAME  A30/L120/L150F",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 11033996,
      "DESCRIPTION": "AIR FILTER, PRIMARY",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 65,
      "WHXX": 12,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 11033997,
      "DESCRIPTION": "FILTER, SAFETY 15193228 ",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 59,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 1,
      "STD": 0.375533808099405,
      "MEAN": 0.153846153846153,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 11033998,
      "DESCRIPTION": "AIR FILTER, PRIMARY",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "AVERAGE (F2)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 1.58333333333333,
      "MAINTANING STOCK QTY": 6,
      "AVAILABLE": 4,
      "SHELF LIFE": 2.52631578947369,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 2,
      "APRD QTY": 2,
      "WH12": 23,
      "WHXX": 9,
      "WH REFERENCE": "WHXX",
      "TREND": 1.16,
      "STD": 1.9414506867883,
      "MEAN": 1.46153846153846,
      "COV": 1.32836099622357,
      "SHELF LIFE_1": 3.7894736842105345,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 11033999,
      "DESCRIPTION": "AIR FILTER, SAFETY  15193226 ",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.916666666666666,
      "MAINTANING STOCK QTY": 4,
      "AVAILABLE": 1,
      "SHELF LIFE": 1.0909090909090917,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 3,
      "APRD QTY": 3,
      "WH12": 53,
      "WHXX": 1,
      "WH REFERENCE": "WH12 + WHXX",
      "TREND": 0.91,
      "STD": 1.62512326948623,
      "MEAN": 0.846153846153846,
      "COV": 1.92060022757464,
      "SHELF LIFE_1": 4.363636363636367,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 11035001,
      "DESCRIPTION": "BEARING",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.25,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 2,
      "STD": 0.832050294337843,
      "MEAN": 0.23076923076923,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 4,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 11035819,
      "DESCRIPTION": "PLANET GEAR",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.25,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 2,
      "STD": 0.832050294337843,
      "MEAN": 0.23076923076923,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 4,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 11039553,
      "DESCRIPTION": "TAIL LAMP L150F",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 1,
      "SHELF LIFE": 3.000000000000003,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 11,
      "WHXX": 4,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.75106761619881,
      "MEAN": 0.307692307692307,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 3.000000000000003,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 11039563,
      "DESCRIPTION": "B-UP WRN UNIT L70/120C/G930",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 1,
      "SHELF LIFE": 6.000000000000024,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 16,
      "WHXX": 15,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.375533808099405,
      "MEAN": 0.153846153846153,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 11039846,
      "DESCRIPTION": "WORK LAMP_70W/24V A30/EC210B/EC460B/L60/70E",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 1.66666666666666,
      "MAINTANING STOCK QTY": 7,
      "AVAILABLE": 7,
      "SHELF LIFE": 4.200000000000016,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 60,
      "WHXX": 29,
      "WH REFERENCE": "",
      "TREND": 0.5,
      "STD": 2.6650636207348,
      "MEAN": 1.53846153846153,
      "COV": 1.73229135347762,
      "SHELF LIFE_1": 4.200000000000016,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 11039856,
      "DESCRIPTION": "REAR WORKING LIGHT",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 9,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 11056114,
      "DESCRIPTION": "SPHERE BEARING A30/35C/A40F",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 1,
      "STD": 0.75106761619881,
      "MEAN": 0.307692307692307,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 3.000000000000003,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 11081029,
      "DESCRIPTION": "SQ NECK SCREW L120F",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 1.58333333333333,
      "MAINTANING STOCK QTY": 6,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 6,
      "APRD QTY": 6,
      "WH12": 36,
      "WHXX": 29,
      "WH REFERENCE": "WHXX",
      "TREND": 2,
      "STD": 5.26965186413967,
      "MEAN": 1.46153846153846,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 3.7894736842105345,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 11100354,
      "DESCRIPTION": "NUT CAP L150F",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 10,
      "MAINTANING STOCK QTY": 40,
      "AVAILABLE": 23,
      "SHELF LIFE": 2.3,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 17,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 250,
      "WH REFERENCE": "WHXX",
      "TREND": 0,
      "STD": 33.2820117735137,
      "MEAN": 9.23076923076923,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 4,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 11103303,
      "DESCRIPTION": "O-RING",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 11110022,
      "DESCRIPTION": "AIR FILTER, PRIMARY 15193225",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.75,
      "MAINTANING STOCK QTY": 3,
      "AVAILABLE": 11,
      "SHELF LIFE": 14.666666666666666,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 57,
      "WHXX": 53,
      "WH REFERENCE": "",
      "TREND": 0.67,
      "STD": 0.75106761619881,
      "MEAN": 0.692307692307692,
      "COV": 1.0848754456205,
      "SHELF LIFE_1": 14.666666666666666,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 11110023,
      "DESCRIPTION": "SAFETY FILTER, INNER 15193225",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.25,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 15,
      "SHELF LIFE": 60,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 153,
      "WHXX": 17,
      "WH REFERENCE": "",
      "TREND": 1.33,
      "STD": 0.438529009653514,
      "MEAN": 0.23076923076923,
      "COV": 1.90029237516522,
      "SHELF LIFE_1": 60,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 11110175,
      "DESCRIPTION": "AIR FILTER, PRIM 15193224",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "FAST (F1)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 7.33333333333333,
      "MAINTANING STOCK QTY": 29,
      "AVAILABLE": 2,
      "SHELF LIFE": 0.2727272727272728,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 27,
      "APRD QTY": 27,
      "WH12": 366,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 0.98,
      "STD": 4.76364437088954,
      "MEAN": 6.76923076923076,
      "COV": 0.703720191154138,
      "SHELF LIFE_1": 3.9545454545454564,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 11110176,
      "DESCRIPTION": "AIR FILTER, SAFETY",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "FAST (F1)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 4.5,
      "MAINTANING STOCK QTY": 18,
      "AVAILABLE": 6,
      "SHELF LIFE": 1.3333333333333333,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 12,
      "APRD QTY": 12,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "PARTIAL WH12",
      "TREND": 0.7,
      "STD": 4.86220378439917,
      "MEAN": 4.15384615384615,
      "COV": 1.17053054068868,
      "SHELF LIFE_1": 4,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 11110668,
      "DESCRIPTION": "PRIMARY FILTER,  FWS",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "FAST (F1)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 4.58333333333333,
      "MAINTANING STOCK QTY": 18,
      "AVAILABLE": 4,
      "SHELF LIFE": 0.8727272727272732,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 14,
      "APRD QTY": 14,
      "WH12": 3,
      "WHXX": 51,
      "WH REFERENCE": "WHXX",
      "TREND": 0.8,
      "STD": 4.53052326179228,
      "MEAN": 4.23076923076923,
      "COV": 1.07085095278726,
      "SHELF LIFE_1": 3.92727272727273,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 11110683,
      "DESCRIPTION": "PRIMARY FILTER, FWS (X6)",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "FAST (F1)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 32.3333333333333,
      "MAINTANING STOCK QTY": 129,
      "AVAILABLE": 115,
      "SHELF LIFE": 3.556701030927839,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 14,
      "APRD QTY": 14,
      "WH12": 165,
      "WHXX": 125,
      "WH REFERENCE": "WHXX",
      "TREND": 0.86,
      "STD": 16.0667677409311,
      "MEAN": 29.8461538461538,
      "COV": 0.53831953771161,
      "SHELF LIFE_1": 3.9896907216494886,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 11110709,
      "DESCRIPTION": "WATER SEPARATOR W/O HEATER  EC210B  EW145B 11110703_",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.416666666666666,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 2,
      "SHELF LIFE": 4.800000000000008,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 4,
      "WHXX": 5,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.869718492622904,
      "MEAN": 0.384615384615384,
      "COV": 2.26126808081955,
      "SHELF LIFE_1": 4.800000000000008,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 11117464,
      "DESCRIPTION": "60114321 WASHER A40F",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 9,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 11121152,
      "DESCRIPTION": "REAR VIEW MIRROR 11007435 11057974",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 4,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 11142033,
      "DESCRIPTION": "WEAR PLATE L120E",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 2,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 11142263,
      "DESCRIPTION": "SEGMENT, BUCKET L=260MM (VOE93899) 2.88M  L120F/G",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 4,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 11147756,
      "DESCRIPTION": "SIGN LAMP L60/120E/BL71",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 2,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 11,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 11164457,
      "DESCRIPTION": "RECEIVER DRIER A35D/L150F",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 1,
      "SHELF LIFE": 6.000000000000024,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 14,
      "WHXX": 5,
      "WH REFERENCE": "",
      "TREND": 1,
      "STD": 0.375533808099405,
      "MEAN": 0.153846153846153,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 11168079,
      "DESCRIPTION": "SUN GEAR",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 11170009,
      "DESCRIPTION": "WORK LAMP L70D/L120D/L150F  6627847",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 15,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 11170064,
      "DESCRIPTION": "LEVEL GUARD",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 6,
      "WHXX": 1,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 11170101,
      "DESCRIPTION": "ROTATING BEACON EC210B 2174120",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3,
      "WHXX": 3,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 11172907,
      "DESCRIPTION": "AIR FILTER, FUEL TANK BREATHER 8152009  (X12)",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "FAST (F1)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 13.0833333333333,
      "MAINTANING STOCK QTY": 52,
      "AVAILABLE": 57,
      "SHELF LIFE": 4.356687898089183,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 10,
      "WH REFERENCE": "",
      "TREND": 0.78,
      "STD": 11.3978180547969,
      "MEAN": 12.076923076923,
      "COV": 0.943768373964082,
      "SHELF LIFE_1": 4.356687898089183,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 11204584,
      "DESCRIPTION": "REAR VIEW MIRROR EC210B-P/EC460B",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.5,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 2,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 13,
      "WH REFERENCE": "WHXX",
      "TREND": 0,
      "STD": 1.66410058867568,
      "MEAN": 0.461538461538461,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 4,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 11417144,
      "DESCRIPTION": "LOCKING DEVICE 10P VTS L70F",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 9,
      "WHXX": 14,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 11417145,
      "DESCRIPTION": "LOCKING DEVICE 15P  EC/EW145/B  L120F",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.416666666666666,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 5,
      "SHELF LIFE": 12.000000000000018,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 35,
      "WHXX": 21,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 1.38675049056307,
      "MEAN": 0.384615384615384,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 12.000000000000018,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 11417147,
      "DESCRIPTION": "LOCKING DEVICE 30P (VTS) EC210B/EC240B",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 4,
      "MAINTANING STOCK QTY": 16,
      "AVAILABLE": 67,
      "SHELF LIFE": 16.75,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 35,
      "WH REFERENCE": "",
      "TREND": 1.29,
      "STD": 4.25018853277152,
      "MEAN": 3.69230769230769,
      "COV": 1.15109272762562,
      "SHELF LIFE_1": 16.75,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 11445025,
      "DESCRIPTION": "FILTER CARTRIDGE, HYDRAULIC",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.5,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 2,
      "APRD QTY": 2,
      "WH12": 0,
      "WHXX": 5,
      "WH REFERENCE": "WHXX",
      "TREND": 0.67,
      "STD": 0.877058019307029,
      "MEAN": 0.461538461538461,
      "COV": 1.90029237516522,
      "SHELF LIFE_1": 4,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 11448509,
      "DESCRIPTION": "FILTER CARTRIDGE, TRANS 11036607",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 2.08333333333333,
      "MAINTANING STOCK QTY": 8,
      "AVAILABLE": 14,
      "SHELF LIFE": 6.720000000000011,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 24,
      "WHXX": 14,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 3.17441696645589,
      "MEAN": 1.92307692307692,
      "COV": 1.65069682255706,
      "SHELF LIFE_1": 6.720000000000011,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 1161277,
      "DESCRIPTION": "SEALANT 20G (BLACK) 11713514",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.833333333333333,
      "MAINTANING STOCK QTY": 3,
      "AVAILABLE": 3,
      "SHELF LIFE": 3.6000000000000014,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 56,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 2.77350098112614,
      "MEAN": 0.769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 3.6000000000000014,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 11700348,
      "DESCRIPTION": "O-RING",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 57,
      "WHXX": 54,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 11703980,
      "DESCRIPTION": "AIR FILTER, CAB",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 1.16666666666666,
      "MAINTANING STOCK QTY": 5,
      "AVAILABLE": 5,
      "SHELF LIFE": 4.28571428571431,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 228,
      "WHXX": 8,
      "WH REFERENCE": "",
      "TREND": 0.71,
      "STD": 1.49786172378819,
      "MEAN": 1.07692307692307,
      "COV": 1.39087160066046,
      "SHELF LIFE_1": 4.28571428571431,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 11705485,
      "DESCRIPTION": "AIR  FILTER, CAB",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3,
      "WHXX": 4,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 11707077,
      "DESCRIPTION": "BREATHER ELEMENT L60/70E/120E/L150E/F/A30E/A35D/E",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "AVERAGE (F2)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 4.41666666666666,
      "MAINTANING STOCK QTY": 18,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 18,
      "APRD QTY": 0,
      "WH12": 31,
      "WHXX": 18,
      "WH REFERENCE": "WHXX",
      "TREND": 0.45,
      "STD": 3.49908412925293,
      "MEAN": 4.07692307692307,
      "COV": 0.858265918496002,
      "SHELF LIFE_1": 4.075471698113214,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 11707544,
      "DESCRIPTION": "17410286 FILTER CARTRIDGE, BRAKE SYSTEM  A35D/E A40F",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.25,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 19,
      "SHELF LIFE": 76,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 6,
      "WHXX": 4,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.599144689515278,
      "MEAN": 0.23076923076923,
      "COV": 2.5962936545662,
      "SHELF LIFE_1": 76,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 11707766,
      "DESCRIPTION": "O-RING A40F 3094874",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 10,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 11709359,
      "DESCRIPTION": "O-RING BL71",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 11711074,
      "DESCRIPTION": "FUEL FILTER",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "FAST (F1)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 7,
      "MAINTANING STOCK QTY": 28,
      "AVAILABLE": 3,
      "SHELF LIFE": 0.42857142857142855,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 25,
      "APRD QTY": 25,
      "WH12": 17,
      "WHXX": 40,
      "WH REFERENCE": "WHXX",
      "TREND": 0.67,
      "STD": 9.01494485669384,
      "MEAN": 6.46153846153846,
      "COV": 1.39517003734547,
      "SHELF LIFE_1": 4,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 11713138,
      "DESCRIPTION": "FILTER HOUSING, FWS",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 1,
      "SHELF LIFE": 12.000000000000005,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 1,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 12.000000000000005,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 11713240,
      "DESCRIPTION": "FUEL FILTER 14520578 ",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.916666666666666,
      "MAINTANING STOCK QTY": 4,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 4,
      "APRD QTY": 4,
      "WH12": 0,
      "WHXX": 24,
      "WH REFERENCE": "WHXX",
      "TREND": 1.45,
      "STD": 1.34450448407296,
      "MEAN": 0.846153846153846,
      "COV": 1.5889598448135,
      "SHELF LIFE_1": 4.363636363636367,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 11715266,
      "DESCRIPTION": "GASKET A40F",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 11716553,
      "DESCRIPTION": "ENGINE OVERHAUL KIT  D6DEAE2 / D6DEOE2 (SN: -1060653)  EW145B / EC210B",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 11883618,
      "DESCRIPTION": "AIR FILTER, PRIMARY",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.25,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 0,
      "STD": 0.832050294337843,
      "MEAN": 0.23076923076923,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 4,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 11883619,
      "DESCRIPTION": "AIR FILTER, SAFETY , 43921493 15193231 ",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.5,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 2,
      "APRD QTY": 2,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 0,
      "STD": 1.66410058867568,
      "MEAN": 0.461538461538461,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 4,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 11988156,
      "DESCRIPTION": "SEAL BL71",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 11988971,
      "DESCRIPTION": "DISC 8220-11890",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 1,
      "MAINTANING STOCK QTY": 4,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 4,
      "APRD QTY": 4,
      "WH12": 0,
      "WHXX": 12,
      "WH REFERENCE": "WHXX",
      "TREND": 2,
      "STD": 2.25320284859643,
      "MEAN": 0.923076923076923,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 4,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 13914468,
      "DESCRIPTION": "SNAP RING A40D 8230-25980",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 21,
      "WHXX": 4,
      "WH REFERENCE": "WHXX",
      "TREND": 1,
      "STD": 0.75106761619881,
      "MEAN": 0.307692307692307,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 3.000000000000003,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 13947281,
      "DESCRIPTION": "GASKET EC210B",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.5,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 2,
      "SHELF LIFE": 4,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 10,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 1.33,
      "STD": 1.19828937903055,
      "MEAN": 0.461538461538461,
      "COV": 2.5962936545662,
      "SHELF LIFE_1": 4,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 13947624,
      "DESCRIPTION": "7011998 PLANE GASKET ",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 20,
      "WHXX": 12,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 13948511,
      "DESCRIPTION": "O-RING",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 5,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 13963059,
      "DESCRIPTION": "SET SCREW",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 72,
      "WHXX": 50,
      "WH REFERENCE": "WHXX",
      "TREND": 1,
      "STD": 0.375533808099405,
      "MEAN": 0.153846153846153,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 13963103,
      "DESCRIPTION": "990976 LOCK NUT",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 15,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 13969162,
      "DESCRIPTION": "BLADE FUSE 15A A35D/G930/L150F/EC210B-P/EC460B/C 969162",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 28,
      "SHELF LIFE": 168.00000000000068,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 75,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 168.00000000000068,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 20443754,
      "DESCRIPTION": "O-RING A35D 3522588",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 30,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 20488307,
      "DESCRIPTION": "SERVICE KIT FM9/FM10/FM340",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 1,
      "SHELF LIFE": 6.000000000000024,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 5,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 20503552,
      "DESCRIPTION": "RUBBER CUSHION",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 20519534,
      "DESCRIPTION": "SEALING MOULDING FM9/FM400",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 1,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 20524936,
      "DESCRIPTION": "SENSOR FM340/EC210B/EC290B/G930 20556500",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.416666666666666,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 2,
      "SHELF LIFE": 4.800000000000008,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.869718492622904,
      "MEAN": 0.384615384615384,
      "COV": 2.26126808081955,
      "SHELF LIFE_1": 4.800000000000008,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 20572459,
      "DESCRIPTION": "SUN VISOR FM340",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 20579690,
      "DESCRIPTION": "SEALING RING, OIL PAN",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 1.58333333333333,
      "MAINTANING STOCK QTY": 6,
      "AVAILABLE": 25,
      "SHELF LIFE": 15.78947368421056,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 37,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 1.37,
      "STD": 2.75727476007816,
      "MEAN": 1.46153846153846,
      "COV": 1.88655641479032,
      "SHELF LIFE_1": 15.78947368421056,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 20708247,
      "DESCRIPTION": "O-RING 20443761 3537521",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 5,
      "SHELF LIFE": 30.00000000000012,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 33,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 30.00000000000012,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 20779040,
      "DESCRIPTION": "OIL FILTER KIT ASSEMBLY (TRANSMISSION)",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 1.16666666666666,
      "MAINTANING STOCK QTY": 5,
      "AVAILABLE": 5,
      "SHELF LIFE": 4.28571428571431,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 16,
      "WHXX": 2,
      "WH REFERENCE": "",
      "TREND": 1.14,
      "STD": 2.13937445925744,
      "MEAN": 1.07692307692307,
      "COV": 1.98656199788191,
      "SHELF LIFE_1": 4.28571428571431,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 20872874,
      "DESCRIPTION": "WIPER BLADE",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 0,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 20910229,
      "DESCRIPTION": "LENS, TAIL LAMP FM9/FM10/FM340",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.416666666666666,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 2,
      "APRD QTY": 2,
      "WH12": 4,
      "WHXX": 1,
      "WH REFERENCE": "WH12 + WHXX",
      "TREND": 2,
      "STD": 1.12089707663561,
      "MEAN": 0.384615384615384,
      "COV": 2.91433239925258,
      "SHELF LIFE_1": 4.800000000000008,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 20917279,
      "DESCRIPTION": "HAND PUMP, LHD (+ 85110122 ADAPTER)",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 1,
      "SHELF LIFE": 3.000000000000003,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 1.5,
      "STD": 0.630425171956115,
      "MEAN": 0.307692307692307,
      "COV": 2.04888180885737,
      "SHELF LIFE_1": 3.000000000000003,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 20998367,
      "DESCRIPTION": "FUEL FILTER, PRI ADAPTED QTY 6 PCS",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 92,
      "WHXX": 5,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 20998807,
      "DESCRIPTION": "OIL FILTER",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 10,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 21122035,
      "DESCRIPTION": "MODULATOR, REAR FM370",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 21244638,
      "DESCRIPTION": "GRILLE",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 21303956,
      "DESCRIPTION": "WHEEL NUT FM10/FM340 21402076 ",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.833333333333333,
      "MAINTANING STOCK QTY": 3,
      "AVAILABLE": 20,
      "SHELF LIFE": 24.000000000000007,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 2.77350098112614,
      "MEAN": 0.769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 24.000000000000007,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 21312237,
      "DESCRIPTION": "CHARGE AIR HOSE",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 3,
      "SHELF LIFE": 18.000000000000075,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 18.000000000000075,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 21321093,
      "DESCRIPTION": "WINDSCREEN, CLEAR FM9/FM10/FM400",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 21339283,
      "DESCRIPTION": "GEAR LEVER CARRIER",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 21347087,
      "DESCRIPTION": "CRANKSHAFT SEAL 85108423",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 10,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 21380475,
      "DESCRIPTION": "FUEL FILTER WATER SEPARATOR",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 26,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 13,
      "WHXX": 8,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 21380488,
      "DESCRIPTION": "FUEL FILTER WATER SEPARATOR",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 2.41666666666666,
      "MAINTANING STOCK QTY": 10,
      "AVAILABLE": 29,
      "SHELF LIFE": 12.000000000000034,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 65,
      "WHXX": 5,
      "WH REFERENCE": "",
      "TREND": 0.62,
      "STD": 3.29529579233402,
      "MEAN": 2.23076923076923,
      "COV": 1.47720156208077,
      "SHELF LIFE_1": 12.000000000000034,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 21620181,
      "DESCRIPTION": "AIR DRIER FILTER",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.666666666666666,
      "MAINTANING STOCK QTY": 3,
      "AVAILABLE": 15,
      "SHELF LIFE": 22.500000000000025,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 8,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 1.75,
      "STD": 1.93814608747902,
      "MEAN": 0.615384615384615,
      "COV": 3.14948739215341,
      "SHELF LIFE_1": 22.500000000000025,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 257658,
      "DESCRIPTION": "LEAF SPRING_REAR FM400/FM440",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 6,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 3988823,
      "DESCRIPTION": "HOSE ASSY FM10/FM340",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 1,
      "SHELF LIFE": 3.000000000000003,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 1.5,
      "STD": 0.630425171956115,
      "MEAN": 0.307692307692307,
      "COV": 2.04888180885737,
      "SHELF LIFE_1": 3.000000000000003,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 8097084,
      "DESCRIPTION": "COVER FM10",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 8143122,
      "DESCRIPTION": "SCREW",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 24,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 0,
      "STD": 1.10940039245045,
      "MEAN": 0.307692307692307,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 3.000000000000003,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 8189632,
      "DESCRIPTION": "WIPER ARM FM9/FM400",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 85110482,
      "DESCRIPTION": "HOSE ASSEMBLY FM340 ~ 85110483 ",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.75,
      "MAINTANING STOCK QTY": 3,
      "AVAILABLE": 3,
      "SHELF LIFE": 4,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 6,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 1.56,
      "STD": 1.37747446344238,
      "MEAN": 0.692307692307692,
      "COV": 1.98968533608345,
      "SHELF LIFE_1": 4,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 85110486,
      "DESCRIPTION": "HOSE KIT FM340/FM400",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 914468,
      "DESCRIPTION": "RETAINING RING",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 33,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 0,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 914474,
      "DESCRIPTION": "RETAINING RING",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.25,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 12,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 2,
      "STD": 0.832050294337843,
      "MEAN": 0.23076923076923,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 4,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 943903,
      "DESCRIPTION": "992517 BULB_70W 24V A35D/EC210B-P/EC460B/C/EC700B/G930/L150F/FM10/FM400",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 14,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 0,
      "STD": 1.10940039245045,
      "MEAN": 0.307692307692307,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 3.000000000000003,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 945988,
      "DESCRIPTION": "LOCK NUT",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 946817,
      "DESCRIPTION": "SCREW",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 2,
      "SHELF LIFE": 12.000000000000048,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 12.000000000000048,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 949329,
      "DESCRIPTION": "GASKET FM10/EC460C",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 1.33333333333333,
      "MAINTANING STOCK QTY": 5,
      "AVAILABLE": 95,
      "SHELF LIFE": 71.25000000000018,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 98,
      "WHXX": 200,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 4.43760156980183,
      "MEAN": 1.23076923076923,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 71.25000000000018,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 967708,
      "DESCRIPTION": "992521 BULB 21W 24V A35D/G930/L150F/FM10/FM400",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 8,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 970742,
      "DESCRIPTION": "BULB A35D 24V 21/5W NOTLPA",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 10,
      "SHELF LIFE": 120.00000000000004,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 2,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 120.00000000000004,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 970926,
      "DESCRIPTION": "984855 HEXAGON SCREW 980060 966360",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.666666666666666,
      "MAINTANING STOCK QTY": 3,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 3,
      "APRD QTY": 3,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 2,
      "STD": 2.21880078490091,
      "MEAN": 0.615384615384615,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 4.500000000000004,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 971069,
      "DESCRIPTION": "NUT",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.5,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 2,
      "SHELF LIFE": 4,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 44,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 1.66410058867568,
      "MEAN": 0.461538461538461,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 4,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 982322,
      "DESCRIPTION": "FUSE FM340",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 10,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 2,
      "STD": 1.10940039245045,
      "MEAN": 0.307692307692307,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 3.000000000000003,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 983472,
      "DESCRIPTION": "CABLE TIE ADAPTED QTY 100",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.833333333333333,
      "MAINTANING STOCK QTY": 3,
      "AVAILABLE": 3,
      "SHELF LIFE": 3.6000000000000014,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 189,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 2.77350098112614,
      "MEAN": 0.769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 3.6000000000000014,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 990037,
      "DESCRIPTION": "BULB 70W 24V A35D/G930/L150F/FM10/FM400",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 5,
      "SHELF LIFE": 60.00000000000002,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 49,
      "WHXX": 4,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 60.00000000000002,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 992065,
      "DESCRIPTION": "O-RING",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 10,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 992519,
      "DESCRIPTION": "BULB 5W/24V FM10/FM400",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 1.16666666666666,
      "MAINTANING STOCK QTY": 5,
      "AVAILABLE": 9,
      "SHELF LIFE": 7.714285714285758,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 26,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 2.90004420832793,
      "MEAN": 1.07692307692307,
      "COV": 2.69289819344737,
      "SHELF LIFE_1": 7.714285714285758,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 992521,
      "DESCRIPTION": "BULB 24V/21W G930/L150F 967708",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 2.91666666666666,
      "MAINTANING STOCK QTY": 12,
      "AVAILABLE": 27,
      "SHELF LIFE": 9.25714285714288,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 312,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 1.26,
      "STD": 4.97300404491784,
      "MEAN": 2.69230769230769,
      "COV": 1.84711578811234,
      "SHELF LIFE_1": 9.25714285714288,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 994555,
      "DESCRIPTION": "HOSE CLAMP",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 109,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 8102116,
      "DESCRIPTION": "BREATHER FILTER ASSY",
      "PRODUCT CATEGORY": "VTS",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.5,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 2,
      "SHELF LIFE": 4,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 11,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 1.33,
      "STD": 1.19828937903055,
      "MEAN": 0.461538461538461,
      "COV": 2.5962936545662,
      "SHELF LIFE_1": 4,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": "14750667H",
      "DESCRIPTION": "CONTROL-PNEUMATIC-1-TS/LF",
      "PRODUCT CATEGORY": "VTS",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "14896991A",
      "DESCRIPTION": "RETURN FILTER ELEMENT",
      "PRODUCT CATEGORY": "VTS",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 1.16666666666666,
      "MAINTANING STOCK QTY": 5,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 5,
      "APRD QTY": 5,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 0.57,
      "STD": 2.62873665669583,
      "MEAN": 1.07692307692307,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 4.28571428571431,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": "A210609000087",
      "DESCRIPTION": "O-RING 24002743",
      "PRODUCT CATEGORY": "YPG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 1,
      "SHELF LIFE": 6.000000000000024,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 16,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": "A820606010004",
      "DESCRIPTION": "SEAL RING 150A 60C1816.1.13-3",
      "PRODUCT CATEGORY": "YPG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 1.66666666666666,
      "MAINTANING STOCK QTY": 7,
      "AVAILABLE": 5,
      "SHELF LIFE": 3.000000000000012,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 2,
      "APRD QTY": 2,
      "WH12": 94,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 2,
      "STD": 5.54700196225229,
      "MEAN": 1.53846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 4.200000000000016,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": "A820606010023",
      "DESCRIPTION": "SEAL RING FOR 125A",
      "PRODUCT CATEGORY": "YPG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 42.5,
      "MAINTANING STOCK QTY": 170,
      "AVAILABLE": 73,
      "SHELF LIFE": 1.7176470588235293,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 97,
      "APRD QTY": 97,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 2,
      "STD": 141.448550037433,
      "MEAN": 39.2307692307692,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 4,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": "B222100000116",
      "DESCRIPTION": "FILTER ELEMENT, HYD.",
      "PRODUCT CATEGORY": "YPG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "B222100000159",
      "DESCRIPTION": "HYDRAULIC FILTER SCC500 HF6872",
      "PRODUCT CATEGORY": "YCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "B222100000445",
      "DESCRIPTION": "FILTER CORE, HYD SCC1500C  HF6864",
      "PRODUCT CATEGORY": "YCG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 2,
      "SHELF LIFE": 6.000000000000006,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 5,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 1.10940039245045,
      "MEAN": 0.307692307692307,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000006,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 11435548,
      "DESCRIPTION": "GAS SPRING",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 4,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14626370,
      "DESCRIPTION": "HOUR COUNTER EC210B/EC290B 14530130",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 5,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "C11195",
      "DESCRIPTION": "SSC11195 ROD, (D100) MOIL POINT SB50/SU+55 TR-F",
      "PRODUCT CATEGORY": "SOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "C31211",
      "DESCRIPTION": "SSC31211 ROD, (D140) MOIL POINT SB81/A ",
      "PRODUCT CATEGORY": "SOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 11103371,
      "DESCRIPTION": "PISTON SEAL L120E/F",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 11103372,
      "DESCRIPTION": "PISTON SEAL L120E/F",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 29020008421,
      "DESCRIPTION": "STRAINER ASSEMBLY, FUEL TANK  LG956",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 5,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 29170036941,
      "DESCRIPTION": "CUTTING EDGE  LG936L",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 6,
      "WHXX": 3,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 11060946,
      "DESCRIPTION": "RUBBER ELEMENT  A35F",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 14521945,
      "DESCRIPTION": "CATCH",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 4,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 984753,
      "DESCRIPTION": "FLANGE SCREW",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 66,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 90898,
      "DESCRIPTION": "GAUGE",
      "PRODUCT CATEGORY": "VGG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14513267,
      "DESCRIPTION": "RELIEF VALVE",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 6,
      "WHXX": 2,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 992518,
      "DESCRIPTION": "BULB 967711",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 2,
      "SHELF LIFE": 24.00000000000001,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 60,
      "WHXX": 72,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 24.00000000000001,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 15144740,
      "DESCRIPTION": "STARTER SWITCH SD110",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 31,
      "WHXX": 1,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 11198489,
      "DESCRIPTION": "RADIATOR GRILLE",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 2,
      "STD": 0.375533808099405,
      "MEAN": 0.153846153846153,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 914465,
      "DESCRIPTION": "RETAINING RING FM330",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 490,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "LF9009VE",
      "DESCRIPTION": "LUBE FILTER",
      "PRODUCT CATEGORY": "CUF",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 1,
      "SHELF LIFE": 12.000000000000005,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 10,
      "WHXX": 2,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 12.000000000000005,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 13965190,
      "DESCRIPTION": "SCREW",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 20555065,
      "DESCRIPTION": "SCREW",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 44,
      "WHXX": 7,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 11102861,
      "DESCRIPTION": "SEALING RING",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 0,
      "WHXX": 1,
      "WH REFERENCE": "WHXX",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 1635243,
      "DESCRIPTION": "SPRING LEAF",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 20943389,
      "DESCRIPTION": "CYL. HEAD GASKET 1.6MM 3HOLES EC290B",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 2,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14376598,
      "DESCRIPTION": "14630764 RELAY 14529156 1123-01900",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.25,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 2,
      "STD": 0.832050294337843,
      "MEAN": 0.23076923076923,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 4,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 29220001251,
      "DESCRIPTION": "CONNECTOR-JOINT",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4030000552,
      "DESCRIPTION": "O-RING",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 0,
      "WHXX": 1,
      "WH REFERENCE": "WHXX",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 950629,
      "DESCRIPTION": "PIN, BRAKE PISTON L120F",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 2,
      "STD": 1.10940039245045,
      "MEAN": 0.307692307692307,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 3.000000000000003,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 21312236,
      "DESCRIPTION": "CHARGE AIR HOSE 20589122",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 9,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 11443400,
      "DESCRIPTION": "11383470 ECC, ELECTRONIC UNIT",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "16Y-04C-02000",
      "DESCRIPTION": "CAP, FUEL TANK",
      "PRODUCT CATEGORY": "SDG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 4,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 3972382,
      "DESCRIPTION": "BELT, V RIBBED",
      "PRODUCT CATEGORY": "CUG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 3022474,
      "DESCRIPTION": "WATER PUMP",
      "PRODUCT CATEGORY": "SDG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4130000523,
      "DESCRIPTION": "WATER TEMPERATURE GAUGE ",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 5,
      "WHXX": 1,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4130000286,
      "DESCRIPTION": "OIL TEMPERATURE GAUGE ",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 13,
      "WHXX": 1,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 21761288,
      "DESCRIPTION": "TAIL LAMP LH FM9/FM440 21652942",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.5,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 2,
      "SHELF LIFE": 4,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 1.19828937903055,
      "MEAN": 0.461538461538461,
      "COV": 2.5962936545662,
      "SHELF LIFE_1": 4,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 14523551,
      "DESCRIPTION": "TOOTH 15GPE VTS EW145B",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 2,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 49,
      "WHXX": 40,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14522960,
      "DESCRIPTION": "ADAPTER EA15BL30 VTS EW145B",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 17,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 0,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 20764159,
      "DESCRIPTION": "997957 HEXAGON SCREW 20764159",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 48,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 20542497,
      "DESCRIPTION": "NUT",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 13,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 20764147,
      "DESCRIPTION": "WASHER",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 9,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4130000420,
      "DESCRIPTION": "COMPRESSOR ASSY A/C 4130002212",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 7,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 907816,
      "DESCRIPTION": "SPLIT PIN A40F 907813",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.416666666666666,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 2,
      "APRD QTY": 2,
      "WH12": 0,
      "WHXX": 15,
      "WH REFERENCE": "WHXX",
      "TREND": 2,
      "STD": 1.12089707663561,
      "MEAN": 0.384615384615384,
      "COV": 2.91433239925258,
      "SHELF LIFE_1": 4.800000000000008,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 14622357,
      "DESCRIPTION": "DRAIN",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 6,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "C31246",
      "DESCRIPTION": "RUBBER PLUG",
      "PRODUCT CATEGORY": "SOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 2,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 949800,
      "DESCRIPTION": "HOSE",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 4,
      "WHXX": 4,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14530666,
      "DESCRIPTION": "WASHER",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 1,
      "SHELF LIFE": 12.000000000000005,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 131,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 12.000000000000005,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 14500291,
      "DESCRIPTION": "CLIP 14500290",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 64,
      "WHXX": 23,
      "WH REFERENCE": "WHXX",
      "TREND": 0,
      "STD": 1.10940039245045,
      "MEAN": 0.307692307692307,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 3.000000000000003,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 14506431,
      "DESCRIPTION": "CONNECTOR",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 6,
      "WHXX": 6,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14518258,
      "DESCRIPTION": "HOSE",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 11705231,
      "DESCRIPTION": "FILTER, GEARBOX",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 21,
      "WHXX": 12,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 20412762,
      "DESCRIPTION": "O-RING",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 34,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 11700334,
      "DESCRIPTION": "SCREW",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 1.5,
      "MAINTANING STOCK QTY": 6,
      "AVAILABLE": 6,
      "SHELF LIFE": 4,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 18,
      "WHXX": 34,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 4.99230176602706,
      "MEAN": 1.38461538461538,
      "COV": 3.60555127546399,
      "SHELF LIFE_1": 4,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 4120001739007,
      "DESCRIPTION": "RETAINER RING",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.666666666666666,
      "MAINTANING STOCK QTY": 3,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 3,
      "APRD QTY": 3,
      "WH12": 224,
      "WHXX": 27,
      "WH REFERENCE": "WHXX",
      "TREND": 2,
      "STD": 1.50213523239762,
      "MEAN": 0.615384615384615,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 4.500000000000004,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 4120001739008,
      "DESCRIPTION": "SQUARE SEAL",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 1,
      "MAINTANING STOCK QTY": 4,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 4,
      "APRD QTY": 0,
      "WH12": 282,
      "WHXX": 35,
      "WH REFERENCE": "WHXX",
      "TREND": 0.67,
      "STD": 2.39657875806111,
      "MEAN": 0.923076923076923,
      "COV": 2.5962936545662,
      "SHELF LIFE_1": 4,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 4110000301,
      "DESCRIPTION": "DRIVE BELT  ",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4120001739011,
      "DESCRIPTION": "INTERNAL PLIERS",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 6,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4120001739009,
      "DESCRIPTION": "CYLINDER PISTON",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.666666666666666,
      "MAINTANING STOCK QTY": 3,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 3,
      "APRD QTY": 3,
      "WH12": 193,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 2,
      "STD": 1.50213523239762,
      "MEAN": 0.615384615384615,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 4.500000000000004,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 4120001739004,
      "DESCRIPTION": "O-RING",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 1,
      "SHELF LIFE": 3.000000000000003,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 24,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 1.10940039245045,
      "MEAN": 0.307692307692307,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 3.000000000000003,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 4190000599,
      "DESCRIPTION": "WIPER KIT",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4110000076366,
      "DESCRIPTION": "4110000076435 SEALING RING ",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 1,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4021000023,
      "DESCRIPTION": "BEARING ",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 15,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 1,
      "STD": 0.375533808099405,
      "MEAN": 0.153846153846153,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 4011000084,
      "DESCRIPTION": "BOLT ",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 134,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4110000367112,
      "DESCRIPTION": "EX. FRICTIONAL DISC 0501 309 330",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 13,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 122,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4110000367113,
      "DESCRIPTION": "IN. FRICTIONAL DISC 4644 308 329",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 9,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 105,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4110000367114,
      "DESCRIPTION": "IN. FRICTIONAL DISC 4644 308 330",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 24,
      "WHXX": 1,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4110000367115,
      "DESCRIPTION": "IN. FRICTIONAL DISC 0769 129 022",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 9,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4110000076258,
      "DESCRIPTION": "SEALING RING ",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 48,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4110000367118,
      "DESCRIPTION": "STOPPING WASHER 4644 308 265",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 22,
      "WHXX": 3,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4110000367101,
      "DESCRIPTION": "THRUST WASHER ",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 6,
      "WHXX": 6,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4110000367105,
      "DESCRIPTION": "SEALING RING ",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 14,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4110000076353,
      "DESCRIPTION": "SPECIAL SEALING RING 0750 112 140",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 20,
      "WHXX": 4,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4110000076247,
      "DESCRIPTION": "THRUST WASHER 0730 150 779 DUP",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 25,
      "WHXX": 3,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4110000076295,
      "DESCRIPTION": "FLEXIBLE RETAINING RING 0730 513 611",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 30,
      "WHXX": 16,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4110000076191,
      "DESCRIPTION": "LIMIT PLATE 4642 308 555",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 16,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4110000076067,
      "DESCRIPTION": "EX. FRICTIONAL DISC 0501 309 329 D=137,5 S=2,0",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 6,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 31,
      "WHXX": 30,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4110000076068,
      "DESCRIPTION": "IN. FRICTIONAL DISC 4642 308 330 S=1,5 4110000076074",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 26,
      "WHXX": 23,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4110000076069,
      "DESCRIPTION": "IN. FRICTIONAL DISC 4642 308 332 S=2,0 4110000076074",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 21,
      "WHXX": 20,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4110000076107,
      "DESCRIPTION": "IN. FRICTIONAL DISC 4642 308 331 S=2,5 4110000076074",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 10,
      "WHXX": 12,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 7200001482,
      "DESCRIPTION": "SEAL PLATE 4644301262",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4110000367072,
      "DESCRIPTION": "GEAR PUMP",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 1,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4110000076309,
      "DESCRIPTION": "REGULATING SHIM 1204 308 062",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 4,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4110000076391,
      "DESCRIPTION": "PLATE",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 1,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4110000076281,
      "DESCRIPTION": "GASKET 4110000076043  4110000076040",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 39,
      "WHXX": 23,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 20459855,
      "DESCRIPTION": "VALVE SEAL",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 10,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 150,
      "WHXX": 22,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 20890422,
      "DESCRIPTION": "CYLINDER LINER KIT D6EEAE2/3  EC210B PRIME",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 1,
      "SHELF LIFE": 12.000000000000005,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 12.000000000000005,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 21496591,
      "DESCRIPTION": "LIP SEAL 20459168",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 14,
      "WHXX": 3,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 21531890,
      "DESCRIPTION": "GASKET 20537029",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 3,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "154-78-31330",
      "DESCRIPTION": "GUARD PLATE",
      "PRODUCT CATEGORY": "SDG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.5,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 2,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 0,
      "STD": 1.66410058867568,
      "MEAN": 0.461538461538461,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 4,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 21638691,
      "DESCRIPTION": "FUEL REGULATOR 21103266",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.666666666666666,
      "MAINTANING STOCK QTY": 3,
      "AVAILABLE": 3,
      "SHELF LIFE": 4.500000000000004,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 17,
      "WHXX": 8,
      "WH REFERENCE": "",
      "TREND": 1.75,
      "STD": 1.38675049056307,
      "MEAN": 0.615384615384615,
      "COV": 2.25346954716499,
      "SHELF LIFE_1": 4.500000000000004,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 21527767,
      "DESCRIPTION": "O-RING 20799838",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 5,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 6212888,
      "DESCRIPTION": "SUPPORT BEARING",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 20515173,
      "DESCRIPTION": "GASKET",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 4,
      "WHXX": 4,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 21165497,
      "DESCRIPTION": "FUEL PIPE",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 3,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14559054,
      "DESCRIPTION": "SEALING",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 89,
      "WHXX": 10,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4880495,
      "DESCRIPTION": "O-RING",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3,
      "WHXX": 12,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 20405721,
      "DESCRIPTION": "CORE PLUG",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 13,
      "SHELF LIFE": 39.00000000000004,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 46,
      "WHXX": 36,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 1.10940039245045,
      "MEAN": 0.307692307692307,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 39.00000000000004,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 992515,
      "DESCRIPTION": "BULB 75/70W FM10/FM400 973892 13964763 964763",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 3,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 11431708,
      "DESCRIPTION": "COLLAR",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 17214676,
      "DESCRIPTION": "EXPANSION TANK ASSY EC210B, EC290B  15047209",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 3,
      "SHELF LIFE": 9.000000000000009,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 8,
      "WHXX": 1,
      "WH REFERENCE": "",
      "TREND": 0.5,
      "STD": 0.630425171956115,
      "MEAN": 0.307692307692307,
      "COV": 2.04888180885737,
      "SHELF LIFE_1": 9.000000000000009,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 21354759,
      "DESCRIPTION": "EXPANSION VALVE",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 2,
      "SHELF LIFE": 12.000000000000048,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 12.000000000000048,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 14527865,
      "DESCRIPTION": "ADAPTER A15-30, HOR. (KTS) EC140B/EW145B",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 1,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "A210401000017",
      "DESCRIPTION": "WASHER LOCK 8GB93",
      "PRODUCT CATEGORY": "YPG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.583333333333333,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 1,
      "SHELF LIFE": 1.714285714285715,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 2,
      "STD": 1.9414506867883,
      "MEAN": 0.538461538461538,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 3.42857142857143,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 15145555,
      "DESCRIPTION": "O-RING",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 7,
      "WHXX": 2,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 991044,
      "DESCRIPTION": "O-RING",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 8,
      "WHXX": 5,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4110000507,
      "DESCRIPTION": "FILTER ASSY, T/C  LG956L",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 13,
      "WHXX": 3,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 993324,
      "DESCRIPTION": "O-RING 932043",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 27,
      "WHXX": 23,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4120001739005,
      "DESCRIPTION": "OUTER PLIERS",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 8,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 18,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4120001739006,
      "DESCRIPTION": "DUST CAP ",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.666666666666666,
      "MAINTANING STOCK QTY": 3,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 3,
      "APRD QTY": 3,
      "WH12": 0,
      "WHXX": 3,
      "WH REFERENCE": "WHXX",
      "TREND": 2,
      "STD": 1.50213523239762,
      "MEAN": 0.615384615384615,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 4.500000000000004,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 29070000071,
      "DESCRIPTION": "BRAKE PLATE LG956/LG958",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 29,
      "WHXX": 3,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14512396,
      "DESCRIPTION": "INLET HOSE",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 3,
      "WH REFERENCE": "WHXX",
      "TREND": 1,
      "STD": 0.375533808099405,
      "MEAN": 0.153846153846153,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 3979593,
      "DESCRIPTION": "FILLER CAP, EXP TANK",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 5,
      "WHXX": 3,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 20724977,
      "DESCRIPTION": "VOLTAGE CONVERTER 20752237",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 3,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 15153124,
      "DESCRIPTION": "TEMPERATURE SENSOR L150F 11145324",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 2,
      "STD": 0.375533808099405,
      "MEAN": 0.153846153846153,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 983242,
      "DESCRIPTION": "997421 HEXAGON SCREW",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 29,
      "WHXX": 14,
      "WH REFERENCE": "WHXX",
      "TREND": 2,
      "STD": 1.10940039245045,
      "MEAN": 0.307692307692307,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 3.000000000000003,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 4110000081107,
      "DESCRIPTION": "OIL FILTER 7200002405 LG956/LG958",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 2,
      "SHELF LIFE": 12.000000000000048,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 125,
      "WHXX": 35,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.375533808099405,
      "MEAN": 0.153846153846153,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 12.000000000000048,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": "9444-10312",
      "DESCRIPTION": "RUBBER HOSE",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 12,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14529762,
      "DESCRIPTION": "PLATE",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 57,
      "WHXX": 6,
      "WH REFERENCE": "WHXX",
      "TREND": 0,
      "STD": 1.10940039245045,
      "MEAN": 0.307692307692307,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 3.000000000000003,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 14529761,
      "DESCRIPTION": "PLATE",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.25,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 30,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 0,
      "STD": 0.832050294337843,
      "MEAN": 0.23076923076923,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 4,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 4130000543,
      "DESCRIPTION": "FRONT HEAD LAMP  RH  LFRDH4-24  LG956L",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.25,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 1,
      "SHELF LIFE": 4,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0.67,
      "STD": 0.438529009653514,
      "MEAN": 0.23076923076923,
      "COV": 1.90029237516522,
      "SHELF LIFE_1": 4,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 15195930,
      "DESCRIPTION": "KIT, WATER SEPARATOR BOWL L150F/G",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 10,
      "WHXX": 7,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 20405652,
      "DESCRIPTION": "SEALING RING",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 32,
      "WHXX": 6,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4130000873,
      "DESCRIPTION": "ENABLE RELAY",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14540003,
      "DESCRIPTION": "PIN, BUCKET EC210B 14512678",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 6,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4130000121,
      "DESCRIPTION": "ANNUNCIATOR  KSHM-12F4  LG956L/LG938/L",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "D81151",
      "DESCRIPTION": "RUBBER PLUG  SU+85/SB121/SB60-UP SERIES",
      "PRODUCT CATEGORY": "SOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.416666666666666,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 2,
      "SHELF LIFE": 4.800000000000008,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 7,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0.8,
      "STD": 0.767947647788304,
      "MEAN": 0.384615384615384,
      "COV": 1.99666388424959,
      "SHELF LIFE_1": 4.800000000000008,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 14621703,
      "DESCRIPTION": "SEALING RING EC210B 8230-27160",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3,
      "WHXX": 1,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 20459246,
      "DESCRIPTION": "SCREW",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 3,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 6,
      "WHXX": 11,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "B222100000751",
      "DESCRIPTION": "FUEL FILTER CORE",
      "PRODUCT CATEGORY": "YCG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.5,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 5,
      "SHELF LIFE": 10,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 17,
      "WHXX": 2,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 1.66410058867568,
      "MEAN": 0.461538461538461,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 10,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": "B222100000424",
      "DESCRIPTION": "FUEL FILTER ",
      "PRODUCT CATEGORY": "YPG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.5,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 7,
      "SHELF LIFE": 14,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 56,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 1.66410058867568,
      "MEAN": 0.461538461538461,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 14,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": "A222100000600",
      "DESCRIPTION": "AIR FILTER, OUTER",
      "PRODUCT CATEGORY": "YCG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.583333333333333,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 4,
      "SHELF LIFE": 6.85714285714286,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3,
      "WHXX": 1,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 1.9414506867883,
      "MEAN": 0.538461538461538,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.85714285714286,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": "A222100000599",
      "DESCRIPTION": "AIR FILTER, SAFETY  QY25C/5OC",
      "PRODUCT CATEGORY": "YCG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.583333333333333,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 4,
      "SHELF LIFE": 6.85714285714286,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 5,
      "WHXX": 1,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 1.66410058867568,
      "MEAN": 0.538461538461538,
      "COV": 3.09047252182627,
      "SHELF LIFE_1": 6.85714285714286,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 21312238,
      "DESCRIPTION": "CHARGE AIR HOSE",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 1,
      "SHELF LIFE": 6.000000000000024,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 12729862,
      "DESCRIPTION": "WASHER, PLOW BOLT (3/4\") G930",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 11,
      "WHXX": 7,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 12741621,
      "DESCRIPTION": "WASHER",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 37,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 990201,
      "DESCRIPTION": "SCREW",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 6,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 12736497,
      "DESCRIPTION": "SEAL",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3,
      "WHXX": 3,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 12736498,
      "DESCRIPTION": "BEARING",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 5,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 12736499,
      "DESCRIPTION": "BEARING",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 7,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 12735791,
      "DESCRIPTION": "BEARING",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 906645,
      "DESCRIPTION": "ROUND NUT",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 5,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 907035,
      "DESCRIPTION": "LOCK WASHER",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 6,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 990950,
      "DESCRIPTION": "FLANGE LOCK NUT 948645",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 101,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 29070000331,
      "DESCRIPTION": "WASHER",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.666666666666666,
      "MAINTANING STOCK QTY": 3,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 3,
      "APRD QTY": 0,
      "WH12": 27,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 0,
      "STD": 2.21880078490091,
      "MEAN": 0.615384615384615,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 4.500000000000004,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 29070000381,
      "DESCRIPTION": "WASHER THRUST",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 0,
      "STD": 1.10940039245045,
      "MEAN": 0.307692307692307,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 3.000000000000003,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 14595621,
      "DESCRIPTION": "PUMP, HYDRAULIC EC210BLC",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 17204355,
      "DESCRIPTION": "ALTERNATOR_80A EC210B/G710B/G930/L150F 22098738 20849350 ",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.916666666666666,
      "MAINTANING STOCK QTY": 4,
      "AVAILABLE": 2,
      "SHELF LIFE": 2.1818181818181834,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 2,
      "APRD QTY": 0,
      "WH12": 33,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 0.73,
      "STD": 0.688737231721194,
      "MEAN": 0.846153846153846,
      "COV": 0.813962182943229,
      "SHELF LIFE_1": 4.363636363636367,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 4130000426,
      "DESCRIPTION": "ALARM BUTTON SWITCH",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 3283767,
      "DESCRIPTION": "GASKET",
      "PRODUCT CATEGORY": "CUG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "154-32-11113",
      "DESCRIPTION": "T/SHOE 610MM(24\")",
      "PRODUCT CATEGORY": "ZZZ",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.666666666666666,
      "MAINTANING STOCK QTY": 3,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 3,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 0.5,
      "STD": 1.44559454541845,
      "MEAN": 0.615384615384615,
      "COV": 2.34909113630498,
      "SHELF LIFE_1": 4.500000000000004,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": "154-27-12273A",
      "DESCRIPTION": "SPROCKET SEGMENT 5T",
      "PRODUCT CATEGORY": "SDG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 4.16666666666666,
      "MAINTANING STOCK QTY": 17,
      "AVAILABLE": 1,
      "SHELF LIFE": 0.24000000000000038,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 16,
      "APRD QTY": 16,
      "WH12": 10,
      "WHXX": 0,
      "WH REFERENCE": "PARTIAL WH12",
      "TREND": 1.2,
      "STD": 9.3527727960407,
      "MEAN": 3.84615384615384,
      "COV": 2.43172092697058,
      "SHELF LIFE_1": 4.080000000000006,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 12746330,
      "DESCRIPTION": "WHEEL HUB",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 990200,
      "DESCRIPTION": "SCREW",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 22,
      "WHXX": 16,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 990951,
      "DESCRIPTION": "FLANGE LOCK NUT M10X11.4 ~71083 971098 13971098",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 65,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4041000020,
      "DESCRIPTION": "O-RING LGB308-440*3.5",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 16,
      "WHXX": 21,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4030000146,
      "DESCRIPTION": "OIL SEAL, AXLE HG4-692-SG170*200*15 LG958",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 1,
      "SHELF LIFE": 6.000000000000024,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 14,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.375533808099405,
      "MEAN": 0.153846153846153,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 4804410,
      "DESCRIPTION": "SEALING RING",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 5,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 11110705,
      "DESCRIPTION": "RADIATOR EC24B/EC290B L70G L120E/F",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 21761154,
      "DESCRIPTION": "TAIL LAMP CLUSTER 21097450",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.666666666666666,
      "MAINTANING STOCK QTY": 3,
      "AVAILABLE": 3,
      "SHELF LIFE": 4.500000000000004,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 7,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 1.5,
      "STD": 1.70970082853022,
      "MEAN": 0.615384615384615,
      "COV": 2.7782638463616,
      "SHELF LIFE_1": 4.500000000000004,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 1635357,
      "DESCRIPTION": "SPRING LEAF",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 9,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 20799762,
      "DESCRIPTION": "CYLINDER HEAD L120F",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 15015574,
      "DESCRIPTION": "SHIM",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4110000331007,
      "DESCRIPTION": "STARTER MOTOR  LG 936 L",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 4,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 59286344,
      "DESCRIPTION": "DRIVE SLEEVE  SD100 / SD110",
      "PRODUCT CATEGORY": "VCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4110000025006,
      "DESCRIPTION": "STARTER ",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 5,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 13949268,
      "DESCRIPTION": "SEALING RING",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 4,
      "WHXX": 10,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 16021529,
      "DESCRIPTION": "CONTROL UNIT",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 4,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 59815449,
      "DESCRIPTION": "CONTROL LEVER",
      "PRODUCT CATEGORY": "VCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 21707132,
      "DESCRIPTION": "OIL FILTER, BYPASS (X8)",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "FAST (F1)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 5.16666666666666,
      "MAINTANING STOCK QTY": 21,
      "AVAILABLE": 25,
      "SHELF LIFE": 4.838709677419361,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 16,
      "WHXX": 60,
      "WH REFERENCE": "",
      "TREND": 0.71,
      "STD": 4.08562206919676,
      "MEAN": 4.76923076923076,
      "COV": 0.856662691928354,
      "SHELF LIFE_1": 4.838709677419361,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 4030000536,
      "DESCRIPTION": "O-RING ",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 4,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4110000507007,
      "DESCRIPTION": "FILTER INSERT, T/C",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "AVERAGE (F2)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 2.66666666666666,
      "MAINTANING STOCK QTY": 11,
      "AVAILABLE": 3,
      "SHELF LIFE": 1.1250000000000029,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 8,
      "APRD QTY": 8,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 1.56,
      "STD": 3.47887397049161,
      "MEAN": 2.46153846153846,
      "COV": 1.41329255051221,
      "SHELF LIFE_1": 4.125000000000011,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": "9412-22050",
      "DESCRIPTION": "CONNECTOR",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 6,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4110001015026,
      "DESCRIPTION": "V-RIBBED BELT, FAN",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.25,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 44,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 0.67,
      "STD": 0.438529009653514,
      "MEAN": 0.23076923076923,
      "COV": 1.90029237516522,
      "SHELF LIFE_1": 4,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 21628356,
      "DESCRIPTION": "24065172 CRANKSHAFT SEAL 20459115",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 2,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 5,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4021000038,
      "DESCRIPTION": "BEARING GB297-32224",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 3,
      "WHXX": 1,
      "WH REFERENCE": "WHXX",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 4021000034,
      "DESCRIPTION": "BEARING GB297-32028",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 15,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 14548265,
      "DESCRIPTION": "FUEL HOSE",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14576425,
      "DESCRIPTION": "SWITCH",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 21707134,
      "DESCRIPTION": "OIL FILTER 466634",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 1.83333333333333,
      "MAINTANING STOCK QTY": 7,
      "AVAILABLE": 25,
      "SHELF LIFE": 13.636363636363662,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 374,
      "WHXX": 95,
      "WH REFERENCE": "",
      "TREND": 0.55,
      "STD": 3.25024653897247,
      "MEAN": 1.69230769230769,
      "COV": 1.92060022757464,
      "SHELF LIFE_1": 13.636363636363662,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 20460811,
      "DESCRIPTION": "BRACKET",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 0,
      "WHXX": 4,
      "WH REFERENCE": "WHXX",
      "TREND": 2,
      "STD": 0.375533808099405,
      "MEAN": 0.153846153846153,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 14559479,
      "DESCRIPTION": "FUEL FILTER",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 4,
      "SHELF LIFE": 24.000000000000096,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 31,
      "WHXX": 7,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 24.000000000000096,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 15066534,
      "DESCRIPTION": "FUEL FILTER",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 3,
      "SHELF LIFE": 18.000000000000075,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 19,
      "WHXX": 9,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 18.000000000000075,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": "1040-20410",
      "DESCRIPTION": "OIL FILTER",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.916666666666666,
      "MAINTANING STOCK QTY": 4,
      "AVAILABLE": 2,
      "SHELF LIFE": 2.1818181818181834,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 2,
      "APRD QTY": 2,
      "WH12": 31,
      "WHXX": 14,
      "WH REFERENCE": "WHXX",
      "TREND": 1.09,
      "STD": 1.51910905062549,
      "MEAN": 0.846153846153846,
      "COV": 1.79531069619377,
      "SHELF LIFE_1": 4.363636363636367,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 14377007,
      "DESCRIPTION": "STRAINER, SUCTION HYD.",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 1.5,
      "MAINTANING STOCK QTY": 6,
      "AVAILABLE": 2,
      "SHELF LIFE": 1.3333333333333333,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 4,
      "APRD QTY": 0,
      "WH12": 8,
      "WHXX": 9,
      "WH REFERENCE": "WHXX",
      "TREND": 0.33,
      "STD": 2.75494892688477,
      "MEAN": 1.38461538461538,
      "COV": 1.98968533608345,
      "SHELF LIFE_1": 4,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 21707133,
      "DESCRIPTION": "17533661 OIL FILTER",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 32,
      "SHELF LIFE": 192.00000000000077,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 192.00000000000077,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 12743123,
      "DESCRIPTION": "O-RING",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 1.08333333333333,
      "MAINTANING STOCK QTY": 4,
      "AVAILABLE": 7,
      "SHELF LIFE": 6.461538461538482,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 6,
      "WHXX": 11,
      "WH REFERENCE": "",
      "TREND": 0.62,
      "STD": 1.15470053837925,
      "MEAN": 1,
      "COV": 1.15470053837925,
      "SHELF LIFE_1": 6.461538461538482,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 4030000046,
      "DESCRIPTION": "FRAME OIL SEAL HG4-692-PD130*160*12",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 1,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 51,
      "WHXX": 1,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14529765,
      "DESCRIPTION": "BEARING",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 4,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14540405,
      "DESCRIPTION": "COLLAR",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 993325,
      "DESCRIPTION": "O-RING 13948700 948700 944706",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 277,
      "WHXX": 38,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 992517,
      "DESCRIPTION": "BULB_70W 24V A35D/EC210B/EC460B/C/EC700B/G930/L150F/FM10/FM400 943903",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 9,
      "SHELF LIFE": 54.00000000000022,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 14,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 54.00000000000022,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": "16Y-60-00013",
      "DESCRIPTION": "07056-10045 OIL STRAINER,SD16",
      "PRODUCT CATEGORY": "SDG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4120001739053,
      "DESCRIPTION": "DUST CAP ",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 15,
      "WHXX": 9,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 70930474,
      "DESCRIPTION": "FUSE 60A",
      "PRODUCT CATEGORY": "VCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 5,
      "WHXX": 4,
      "WH REFERENCE": "WHXX",
      "TREND": 0,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 70606165,
      "DESCRIPTION": "STRAINER, HYD TANK SD110",
      "PRODUCT CATEGORY": "VCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 2,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 8,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14622355,
      "DESCRIPTION": "FUEL FILTER, EXTRA FWS_",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "FAST (F1)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 17.9166666666666,
      "MAINTANING STOCK QTY": 72,
      "AVAILABLE": 34,
      "SHELF LIFE": 1.8976744186046581,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 38,
      "APRD QTY": 38,
      "WH12": 1379,
      "WHXX": 176,
      "WH REFERENCE": "WHXX",
      "TREND": 0.86,
      "STD": 7.89108552540338,
      "MEAN": 16.5384615384615,
      "COV": 0.477135403861599,
      "SHELF LIFE_1": 4.018604651162805,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 14523235,
      "DESCRIPTION": "WASHER",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 26,
      "MAINTANING STOCK QTY": 104,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 104,
      "APRD QTY": 104,
      "WH12": 39,
      "WHXX": 0,
      "WH REFERENCE": "PARTIAL WH12",
      "TREND": 2,
      "STD": 86.5332306111357,
      "MEAN": 24,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 4,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 14561585,
      "DESCRIPTION": "VENTING, HYD. EC210B 14524178",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 2,
      "SHELF LIFE": 12.000000000000048,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 141,
      "WHXX": 55,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 12.000000000000048,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 14517943,
      "DESCRIPTION": "BUSHING 9624-11617",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 24,
      "WHXX": 4,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14547552,
      "DESCRIPTION": "SHIM 0.5T",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 91,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14547553,
      "DESCRIPTION": "SHIM 1.0T",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 119,
      "WHXX": 44,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14614180,
      "DESCRIPTION": "BOTTOM ROLLER SN -12151  14568594  EC360B/EC460B/EC480D",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 11,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 17214674,
      "DESCRIPTION": "TANK, EXPANSION EC210B 11110410 ",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.75,
      "MAINTANING STOCK QTY": 3,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 3,
      "APRD QTY": 3,
      "WH12": 2,
      "WHXX": 5,
      "WH REFERENCE": "WHXX",
      "TREND": 1.11,
      "STD": 1.49357598761135,
      "MEAN": 0.692307692307692,
      "COV": 2.15738753766084,
      "SHELF LIFE_1": 4,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 11708829,
      "DESCRIPTION": "O-RING",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 16,
      "WH REFERENCE": "WHXX",
      "TREND": 0,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 15067640,
      "DESCRIPTION": "GREASE, LITHIUM  EP2 ",
      "PRODUCT CATEGORY": "LUV",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3181,
      "WHXX": 16,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 21141948,
      "DESCRIPTION": "MAIN BEARING KIT 20405516",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.416666666666666,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 2,
      "APRD QTY": 0,
      "WH12": 17,
      "WHXX": 44,
      "WH REFERENCE": "WHXX",
      "TREND": 0,
      "STD": 1.38675049056307,
      "MEAN": 0.384615384615384,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 4.800000000000008,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 4030000047,
      "DESCRIPTION": "OIL SEAL HG4-692-PD150*180*16",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3,
      "WHXX": 3,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 29070001231,
      "DESCRIPTION": "COVER OF OIL SAL",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 29070001071,
      "DESCRIPTION": "BRAKE DISC  ",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 1,
      "WH REFERENCE": "WHXX",
      "TREND": 1,
      "STD": 0.375533808099405,
      "MEAN": 0.153846153846153,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": "C31127",
      "DESCRIPTION": "STOP PIN SB81/A",
      "PRODUCT CATEGORY": "SOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": "C31226",
      "DESCRIPTION": "ROD PIN SB81/A",
      "PRODUCT CATEGORY": "SOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 20412745,
      "DESCRIPTION": "CORE PLUG",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 14,
      "SHELF LIFE": 84.00000000000034,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 31,
      "WHXX": 35,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 84.00000000000034,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 14550602,
      "DESCRIPTION": "FUEL HOSE 14559325",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 23,
      "WHXX": 4,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4110000556002,
      "DESCRIPTION": "ALTERNATOR 1.5KW (612600090206D) LG956L",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.25,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 6,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 0.67,
      "STD": 0.438529009653514,
      "MEAN": 0.23076923076923,
      "COV": 1.90029237516522,
      "SHELF LIFE_1": 4,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 43922517,
      "DESCRIPTION": "STEERING CYLINDER SD110 13188339 58783507",
      "PRODUCT CATEGORY": "VCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 12746657,
      "DESCRIPTION": "NUT",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 10,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 12746660,
      "DESCRIPTION": "NUT",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 10,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14591830,
      "DESCRIPTION": "FUEL FILL PUMP 14539462 ",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 7,
      "WHXX": 2,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14532688,
      "DESCRIPTION": "FILTER CARTRIDGE, SERVO SYSTEM",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "AVERAGE (F2)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 2.58333333333333,
      "MAINTANING STOCK QTY": 10,
      "AVAILABLE": 8,
      "SHELF LIFE": 3.096774193548391,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 2,
      "APRD QTY": 2,
      "WH12": 46,
      "WHXX": 13,
      "WH REFERENCE": "WHXX",
      "TREND": 0.9,
      "STD": 2.66265724225698,
      "MEAN": 2.38461538461538,
      "COV": 1.11659819836583,
      "SHELF LIFE_1": 3.870967741935489,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 15195958,
      "DESCRIPTION": "GASKET KIT (1 KIT=10PCS)",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 3,
      "SHELF LIFE": 36.000000000000014,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 41,
      "WHXX": 2,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 36.000000000000014,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": "C61227",
      "DESCRIPTION": "SSC61227 ROD, (D155) MOIL POINT",
      "PRODUCT CATEGORY": "SOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 15080985,
      "DESCRIPTION": "HOSE",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 20805850,
      "DESCRIPTION": "SEALING",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14562924,
      "DESCRIPTION": "SPRING PACKAGE  EC210B",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 10,
      "WHXX": 10,
      "WH REFERENCE": "WHXX",
      "TREND": 1,
      "STD": 0.375533808099405,
      "MEAN": 0.153846153846153,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 7200001639,
      "DESCRIPTION": "NEEDLE BEARING 0635303204",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 8,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 20450694,
      "DESCRIPTION": "SCREW",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 7,
      "WHXX": 3,
      "WH REFERENCE": "WHXX",
      "TREND": 1,
      "STD": 0.375533808099405,
      "MEAN": 0.153846153846153,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 14516446,
      "DESCRIPTION": "ELBOW",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4110000076282,
      "DESCRIPTION": "GASKET 4642 331 216 ?=0.8 4110000076136",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 2,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4110000367051,
      "DESCRIPTION": "GASKET 4644311209 4110000367133 4110001321070 4110001905011 4110001905012",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 4,
      "WHXX": 11,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 21508091,
      "DESCRIPTION": "COVER KIT D13A ENG. 20979871",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 21294062,
      "DESCRIPTION": "GASKET",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 6,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 943481,
      "DESCRIPTION": "HOSE CLAMP",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 4,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14880956,
      "DESCRIPTION": "HOSE",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 5,
      "WHXX": 4,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "XC8286057",
      "DESCRIPTION": "BUCKET ASSY (GP) 0.92M3 G5 VTS EC210B",
      "PRODUCT CATEGORY": "VOA",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 12778469,
      "DESCRIPTION": "HOSE",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 1,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3,
      "WHXX": 3,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 12778473,
      "DESCRIPTION": "HOSE",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 1,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 3,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 21489734,
      "DESCRIPTION": "CABLE HARNESS",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 914515,
      "DESCRIPTION": "RETAINING RING",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 16,
      "WHXX": 4,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4110000076367,
      "DESCRIPTION": "SEALING RING FOR SHAFT ",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 3,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4110000076270,
      "DESCRIPTION": "REGULATING SHIM 0769 120 468",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3,
      "WHXX": 6,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4110000367125,
      "DESCRIPTION": "NEEDLE BEARINGK35*50,06*30 0735 358 069",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 3,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4110000076256,
      "DESCRIPTION": "SEALING RING 0734 317 252",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 3,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4110000076027,
      "DESCRIPTION": "SEALING RING ",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 8,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4110000555021,
      "DESCRIPTION": "BELT, V-RIBBED A3911562 LG938L C3288812 4110000127009",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 29,
      "WHXX": 2,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 21299547,
      "DESCRIPTION": "PISTON RING KIT",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 10,
      "WHXX": 14,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 12779101,
      "DESCRIPTION": "CABLE HARNESS",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 990861,
      "DESCRIPTION": "FLANGE SCREW 946472",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 89,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 976047,
      "DESCRIPTION": "O-RING",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 2,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 13,
      "WHXX": 9,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 20460215,
      "DESCRIPTION": "CLIP",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 5,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4110000556085,
      "DESCRIPTION": "THERMOSTAT ASSEMBLY ",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 0,
      "STD": 0.375533808099405,
      "MEAN": 0.153846153846153,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 21647837,
      "DESCRIPTION": "TURBOCHARGER, ENG. D6E EAE2 EC210B 20873313 ",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 3,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 11156766,
      "DESCRIPTION": "BOLT ON EDGE L90 / L120",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 11156765,
      "DESCRIPTION": "BOLT ON EDGE L90 / L120",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 21620133,
      "DESCRIPTION": "V RIBBED BELT EC290B",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 10,
      "WHXX": 22,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14536585,
      "DESCRIPTION": "DIODE",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.666666666666666,
      "MAINTANING STOCK QTY": 3,
      "AVAILABLE": 2,
      "SHELF LIFE": 3.000000000000003,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 23,
      "WHXX": 35,
      "WH REFERENCE": "WHXX",
      "TREND": 2,
      "STD": 2.21880078490091,
      "MEAN": 0.615384615384615,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 4.500000000000004,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 11205832,
      "DESCRIPTION": "WIPER ARM",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 5,
      "WHXX": 1,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14550884,
      "DESCRIPTION": "SOLENOID COIL",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.583333333333333,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 2,
      "APRD QTY": 2,
      "WH12": 9,
      "WHXX": 3,
      "WH REFERENCE": "WHXX",
      "TREND": 1.43,
      "STD": 1.12660142429821,
      "MEAN": 0.538461538461538,
      "COV": 2.0922597879824,
      "SHELF LIFE_1": 3.42857142857143,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 20459311,
      "DESCRIPTION": "BELT, FAN (10PK) EC480D",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "AVERAGE (F2)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 2.83333333333333,
      "MAINTANING STOCK QTY": 11,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 11,
      "APRD QTY": 11,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 1.41,
      "STD": 2.93082643459888,
      "MEAN": 2.61538461538461,
      "COV": 1.12061010734663,
      "SHELF LIFE_1": 3.8823529411764754,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 14625659,
      "DESCRIPTION": "SEALING KIT",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 5,
      "WHXX": 2,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 29010011921,
      "DESCRIPTION": "CONTROL CABLE",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 19,
      "WHXX": 2,
      "WH REFERENCE": "WHXX",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 20795296,
      "DESCRIPTION": "SOLENOID VALVE",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 13478078,
      "DESCRIPTION": "OIL FILTER DD38HF",
      "PRODUCT CATEGORY": "VCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 10,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 54477161,
      "DESCRIPTION": "FUEL FILTER DD38HF",
      "PRODUCT CATEGORY": "VCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 10,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 54477153,
      "DESCRIPTION": "FUEL FILTER DD38HF",
      "PRODUCT CATEGORY": "VCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4120002263101,
      "DESCRIPTION": "SEALING KIT, LIFT CYL. ",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.416666666666666,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 2,
      "APRD QTY": 0,
      "WH12": 11,
      "WHXX": 4,
      "WH REFERENCE": "WHXX",
      "TREND": 0.4,
      "STD": 1.38675049056307,
      "MEAN": 0.384615384615384,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 4.800000000000008,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 4120002264101,
      "DESCRIPTION": "SEALING KIT, TILT CYL, (371368)  LG956L",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 9,
      "WHXX": 1,
      "WH REFERENCE": "WHXX",
      "TREND": 0,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 4120000553101,
      "DESCRIPTION": "SEALING KIT, STEERING CYL. ",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 2,
      "SHELF LIFE": 6.000000000000006,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 15,
      "WHXX": 2,
      "WH REFERENCE": "",
      "TREND": 1,
      "STD": 1.10940039245045,
      "MEAN": 0.307692307692307,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000006,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 13974079,
      "DESCRIPTION": "ALLEN HEAD SCREW",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 2,
      "STD": 1.10940039245045,
      "MEAN": 0.307692307692307,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 3.000000000000003,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 11700285,
      "DESCRIPTION": "BEARING BUSHING",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 9,
      "WHXX": 10,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 21761263,
      "DESCRIPTION": "TAIL LAMP CLUSTER FM340 21063895",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "C51191",
      "DESCRIPTION": "CHARGING VALVE ASSEMBLY",
      "PRODUCT CATEGORY": "SOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 994000,
      "DESCRIPTION": "ELBOW NIPPLE",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 10,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "7114-12371",
      "DESCRIPTION": "SEAL_RADIAL",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.5,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 2,
      "SHELF LIFE": 4,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 48,
      "WHXX": 21,
      "WH REFERENCE": "",
      "TREND": 1,
      "STD": 0.967417922046845,
      "MEAN": 0.461538461538461,
      "COV": 2.09607216443483,
      "SHELF LIFE_1": 4,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 70932538,
      "DESCRIPTION": "ENGINE ANCHORAGE SD110",
      "PRODUCT CATEGORY": "VCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 3,
      "SHELF LIFE": 9.000000000000009,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 20,
      "WHXX": 14,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 1.10940039245045,
      "MEAN": 0.307692307692307,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 9.000000000000009,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 2851010,
      "DESCRIPTION": "O-RING 2851023",
      "PRODUCT CATEGORY": "SOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 10,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 7,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 2841217,
      "DESCRIPTION": "BACK-UP RING",
      "PRODUCT CATEGORY": "SOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 7,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "C31210",
      "DESCRIPTION": "VALVE ADJUSTER",
      "PRODUCT CATEGORY": "SOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4101306,
      "DESCRIPTION": "HEXAGON NUT",
      "PRODUCT CATEGORY": "SOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 21758906,
      "DESCRIPTION": "INSERT, CAB AIR FILTER FM400 8143691",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 1.41666666666666,
      "MAINTANING STOCK QTY": 6,
      "AVAILABLE": 7,
      "SHELF LIFE": 4.941176470588259,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 60,
      "WHXX": 1,
      "WH REFERENCE": "",
      "TREND": 0.82,
      "STD": 2.81023769411697,
      "MEAN": 1.3076923076923,
      "COV": 2.14900529550121,
      "SHELF LIFE_1": 4.941176470588259,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": "B222100000508",
      "DESCRIPTION": "B222100000533 AUXILIARY CARTRIDGE, AIR CLEANER",
      "PRODUCT CATEGORY": "YCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 20459838,
      "DESCRIPTION": "BOLT",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 8,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 23,
      "WHXX": 24,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 11700284,
      "DESCRIPTION": "BEARING BUSHING",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 22,
      "WHXX": 11,
      "WH REFERENCE": "WHXX",
      "TREND": 0,
      "STD": 1.10940039245045,
      "MEAN": 0.307692307692307,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 3.000000000000003,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 29010020351,
      "DESCRIPTION": "CONTROL CABLE",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 9,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 70917182,
      "DESCRIPTION": "CABLE, CONTROL LEVER  SD110",
      "PRODUCT CATEGORY": "VCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 15,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14542155,
      "DESCRIPTION": "AIR FILTER, PRIMARY",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 3,
      "SHELF LIFE": 36.000000000000014,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 18,
      "WHXX": 14,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 36.000000000000014,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 14524470,
      "DESCRIPTION": "OIL FILTER  EC55B/EW60C 14523962",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.25,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 1,
      "SHELF LIFE": 4,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 19,
      "WHXX": 8,
      "WH REFERENCE": "",
      "TREND": 1.33,
      "STD": 0.599144689515278,
      "MEAN": 0.23076923076923,
      "COV": 2.5962936545662,
      "SHELF LIFE_1": 4,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 11039847,
      "DESCRIPTION": "WORK LAMP",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 8,
      "WHXX": 10,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14612048,
      "DESCRIPTION": "FILTER, A/C CONDENSER  EC210B  SN 38328~/ 74214~",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.75,
      "MAINTANING STOCK QTY": 3,
      "AVAILABLE": 3,
      "SHELF LIFE": 4,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 35,
      "WHXX": 15,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 1.18213192894697,
      "MEAN": 0.692307692307692,
      "COV": 1.70752389736785,
      "SHELF LIFE_1": 4,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 14881287,
      "DESCRIPTION": "V-BELT",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 3,
      "SHELF LIFE": 18.000000000000075,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 21,
      "WHXX": 5,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 18.000000000000075,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 990603,
      "DESCRIPTION": "O-RING",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 1,
      "SHELF LIFE": 6.000000000000024,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 21,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 14589150,
      "DESCRIPTION": "SEALING KIT",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 3,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14632775,
      "DESCRIPTION": "V-BELT 15074767",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 1,
      "SHELF LIFE": 6.000000000000024,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 5,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 14622356,
      "DESCRIPTION": "BOWL, EXTRA WATER SEPARATOR",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 1,
      "SHELF LIFE": 6.000000000000024,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 4,
      "WHXX": 3,
      "WH REFERENCE": "",
      "TREND": 1,
      "STD": 0.375533808099405,
      "MEAN": 0.153846153846153,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 14594697,
      "DESCRIPTION": "V-ECU EC210BLR",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4804563,
      "DESCRIPTION": "TENSIONING BAND",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 4,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 43993021,
      "DESCRIPTION": "HOUSING",
      "PRODUCT CATEGORY": "VCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 1,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 960631,
      "DESCRIPTION": "PLUG 9415-22051",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 17,
      "WHXX": 7,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 976041,
      "DESCRIPTION": "O-RING",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 9,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 468947,
      "DESCRIPTION": "SEALING RING",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 13,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14511752,
      "DESCRIPTION": "SEAL",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.25,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 18,
      "WHXX": 8,
      "WH REFERENCE": "WHXX",
      "TREND": 0.67,
      "STD": 0.599144689515278,
      "MEAN": 0.23076923076923,
      "COV": 2.5962936545662,
      "SHELF LIFE_1": 4,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": "7114-11950",
      "DESCRIPTION": "SEAL_DUST",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.25,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 16,
      "WHXX": 10,
      "WH REFERENCE": "WHXX",
      "TREND": 0.67,
      "STD": 0.599144689515278,
      "MEAN": 0.23076923076923,
      "COV": 2.5962936545662,
      "SHELF LIFE_1": 4,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 59190975,
      "DESCRIPTION": "PIVOT PIN",
      "PRODUCT CATEGORY": "VCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 2851051,
      "DESCRIPTION": "O-RING SB81A SB121",
      "PRODUCT CATEGORY": "SOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 2844901,
      "DESCRIPTION": "BACK-UP RING SB81A SB121",
      "PRODUCT CATEGORY": "SOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 2850014,
      "DESCRIPTION": "O-RING SB81A",
      "PRODUCT CATEGORY": "SOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 2850003,
      "DESCRIPTION": "O-RING SB81A",
      "PRODUCT CATEGORY": "SOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 9,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 11705143,
      "DESCRIPTION": "O-RING",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 13,
      "WHXX": 20,
      "WH REFERENCE": "WHXX",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 14630764,
      "DESCRIPTION": "RELAY_24V G930/EC210B/EC460B/EC700B/A35D/L120E/G930 14594545 14376598",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.25,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 5,
      "SHELF LIFE": 20,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 16,
      "WHXX": 17,
      "WH REFERENCE": "",
      "TREND": 0.67,
      "STD": 0.438529009653514,
      "MEAN": 0.23076923076923,
      "COV": 1.90029237516522,
      "SHELF LIFE_1": 20,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 21598183,
      "DESCRIPTION": "TURBOCHARGER, ENG. D6E EAE3 EC210C 20933297",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 1,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 1,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 46678140,
      "DESCRIPTION": "FAN, COOLING SD110",
      "PRODUCT CATEGORY": "VCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 2,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 95098679,
      "DESCRIPTION": "SPRING PIN",
      "PRODUCT CATEGORY": "VCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 8,
      "WHXX": 13,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14519262,
      "DESCRIPTION": "AIR FILTER, SAFETY EC55B 15193234 ",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.416666666666666,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 3,
      "SHELF LIFE": 7.200000000000011,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 110,
      "WHXX": 8,
      "WH REFERENCE": "",
      "TREND": 1.2,
      "STD": 0.767947647788304,
      "MEAN": 0.384615384615384,
      "COV": 1.99666388424959,
      "SHELF LIFE_1": 7.200000000000011,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 3938162,
      "DESCRIPTION": "GASKET, OIL PAN",
      "PRODUCT CATEGORY": "CUG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 17225331,
      "DESCRIPTION": "KEY",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 2,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 993320,
      "DESCRIPTION": "O-RING L330C/BL71 932039",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.5,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 2,
      "SHELF LIFE": 4,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 131,
      "WHXX": 23,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 1.66410058867568,
      "MEAN": 0.461538461538461,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 4,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": "150-70-21346",
      "DESCRIPTION": "BIT END, RH",
      "PRODUCT CATEGORY": "SDG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 1,
      "MAINTANING STOCK QTY": 4,
      "AVAILABLE": 1,
      "SHELF LIFE": 1,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 3,
      "APRD QTY": 0,
      "WH12": 5,
      "WHXX": 1,
      "WH REFERENCE": "WH12 + WHXX",
      "TREND": 0.17,
      "STD": 2.17797836159814,
      "MEAN": 0.923076923076923,
      "COV": 2.35947655839799,
      "SHELF LIFE_1": 4,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": "150-70-21356",
      "DESCRIPTION": "END BIT, LH",
      "PRODUCT CATEGORY": "SDG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 1,
      "MAINTANING STOCK QTY": 4,
      "AVAILABLE": 1,
      "SHELF LIFE": 1,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 3,
      "APRD QTY": 0,
      "WH12": 5,
      "WHXX": 1,
      "WH REFERENCE": "WH12 + WHXX",
      "TREND": 0.17,
      "STD": 2.17797836159814,
      "MEAN": 0.923076923076923,
      "COV": 2.35947655839799,
      "SHELF LIFE_1": 4,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": "154-70-11314",
      "DESCRIPTION": "EDGE CUTTING-SD22",
      "PRODUCT CATEGORY": "SDG",
      "ITEM MOVEMENT BRANCH": "AVERAGE (F2)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 1.08333333333333,
      "MAINTANING STOCK QTY": 4,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 4,
      "APRD QTY": 0,
      "WH12": 6,
      "WHXX": 1,
      "WH REFERENCE": "WH12 + WHXX",
      "TREND": 0.15,
      "STD": 1.95789002074512,
      "MEAN": 1,
      "COV": 1.95789002074512,
      "SHELF LIFE_1": 3.692307692307704,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": "175-78-31230",
      "DESCRIPTION": "POINT, RIPPER",
      "PRODUCT CATEGORY": "SDG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 1,
      "MAINTANING STOCK QTY": 4,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 4,
      "APRD QTY": 0,
      "WH12": 7,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 0,
      "STD": 3.32820117735137,
      "MEAN": 0.923076923076923,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 4,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 14507432,
      "DESCRIPTION": "ROCKER SWITCH",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 1,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 12746650,
      "DESCRIPTION": "SCREW",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.5,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 2,
      "APRD QTY": 2,
      "WH12": 0,
      "WHXX": 11,
      "WH REFERENCE": "WHXX",
      "TREND": 2,
      "STD": 1.66410058867568,
      "MEAN": 0.461538461538461,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 4,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": "C61139",
      "DESCRIPTION": "DIAPHRAGM SU+85/SB121",
      "PRODUCT CATEGORY": "SOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.25,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 3,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 0.67,
      "STD": 0.599144689515278,
      "MEAN": 0.23076923076923,
      "COV": 2.5962936545662,
      "SHELF LIFE_1": 4,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 14508698,
      "DESCRIPTION": "PANEL",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 20482732,
      "DESCRIPTION": "BOLT",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 3,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 20551332,
      "DESCRIPTION": "CABLE HARNESS",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3,
      "WHXX": 4,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 15011845,
      "DESCRIPTION": "BRAKE DISC L120F",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 15603435,
      "DESCRIPTION": "TOOTH, BUCKET BL71",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.416666666666666,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 2,
      "APRD QTY": 2,
      "WH12": 51,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 2,
      "STD": 1.38675049056307,
      "MEAN": 0.384615384615384,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 4.800000000000008,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 15603422,
      "DESCRIPTION": "PIN, TOOTH BL71",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.416666666666666,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 2,
      "APRD QTY": 2,
      "WH12": 85,
      "WHXX": 8,
      "WH REFERENCE": "WHXX",
      "TREND": 2,
      "STD": 1.38675049056307,
      "MEAN": 0.384615384615384,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 4.800000000000008,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 4130000427,
      "DESCRIPTION": "POWER SWITCH ",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 12,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 20482731,
      "DESCRIPTION": "BRACKET",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 2,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 13959262,
      "DESCRIPTION": "60112995 HEX. SOCKET SCREW",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.416666666666666,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 2,
      "APRD QTY": 2,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 2,
      "STD": 1.38675049056307,
      "MEAN": 0.384615384615384,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 4.800000000000008,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 11055748,
      "DESCRIPTION": "WASHER",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 3,
      "WH REFERENCE": "WHXX",
      "TREND": 1,
      "STD": 0.75106761619881,
      "MEAN": 0.307692307692307,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 3.000000000000003,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 14542156,
      "DESCRIPTION": "FILTER, SAFETY 15193236 ",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 3,
      "SHELF LIFE": 36.000000000000014,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 8,
      "WHXX": 18,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 36.000000000000014,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 4190000138,
      "DESCRIPTION": "FAN, COOLING ",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 1,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14536511,
      "DESCRIPTION": "WATER SEPARATOR, EC55B",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.583333333333333,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 3,
      "SHELF LIFE": 5.142857142857146,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 20,
      "WHXX": 13,
      "WH REFERENCE": "",
      "TREND": 1.43,
      "STD": 1.05003052458683,
      "MEAN": 0.538461538461538,
      "COV": 1.9500566885184,
      "SHELF LIFE_1": 5.142857142857146,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 13980834,
      "DESCRIPTION": "TUBE",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 4,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 15052786,
      "DESCRIPTION": "CAB FILTER BL71/L60/70E/L120E/L150E/F/A30E/A35D/E 11703979",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "FAST (F1)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 6.25,
      "MAINTANING STOCK QTY": 25,
      "AVAILABLE": 7,
      "SHELF LIFE": 1.12,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 18,
      "APRD QTY": 18,
      "WH12": 0,
      "WHXX": 19,
      "WH REFERENCE": "WHXX",
      "TREND": 1.01,
      "STD": 3.51553329652477,
      "MEAN": 5.76923076923076,
      "COV": 0.609359104730961,
      "SHELF LIFE_1": 4,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 79509,
      "DESCRIPTION": "PINION",
      "PRODUCT CATEGORY": "VGG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 29220001321,
      "DESCRIPTION": "TUBE OF AXLE",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14621335,
      "DESCRIPTION": "EXTRA FWS FILTER ASSY  EC210BLC",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 2,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 1,
      "STD": 0.375533808099405,
      "MEAN": 0.153846153846153,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 14647065,
      "DESCRIPTION": "HOSE 14586973",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 1,
      "SHELF LIFE": 6.000000000000024,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 7,
      "WHXX": 2,
      "WH REFERENCE": "",
      "TREND": 1,
      "STD": 0.375533808099405,
      "MEAN": 0.153846153846153,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 14530607,
      "DESCRIPTION": "HEADLAMP",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 2,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "213379Z00B",
      "DESCRIPTION": "BOWL",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 2,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "801019Z00A",
      "DESCRIPTION": "5224079620 DOOR WINDOW PANE",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "331769Z00A",
      "DESCRIPTION": "SWITCH",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 7,
      "WHXX": 3,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "333419Z00A",
      "DESCRIPTION": "PIN",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.5,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 2,
      "APRD QTY": 0,
      "WH12": 21,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 0,
      "STD": 1.66410058867568,
      "MEAN": 0.461538461538461,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 4,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 4030000311,
      "DESCRIPTION": "PLUG",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 15,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "A222100000393",
      "DESCRIPTION": "FILTER CORE ",
      "PRODUCT CATEGORY": "YPG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 6,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 4110000076352,
      "DESCRIPTION": "SPECIAL SEALING RING ",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 6,
      "WHXX": 2,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4110000076268,
      "DESCRIPTION": "REGULATING ",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 8,
      "WHXX": 3,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4110000367099,
      "DESCRIPTION": "THRUST WASHER 0730 150 759",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 16,
      "WHXX": 3,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4110000727085,
      "DESCRIPTION": "14400779  OIL FILTER",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 29070000591,
      "DESCRIPTION": "SPACER(GASKET)",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 13,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "C3X005",
      "DESCRIPTION": "C3X005ES SEAL KIT SB81",
      "PRODUCT CATEGORY": "SOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14643849,
      "DESCRIPTION": "TRANSFER GEARBOX",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 20838727,
      "DESCRIPTION": "SWITCH 14531840",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 1,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 5,
      "WHXX": 2,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 11428148,
      "DESCRIPTION": "ROCKER ARM",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 1,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 4,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 15178089,
      "DESCRIPTION": "ORING 11705252",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 31,
      "WHXX": 12,
      "WH REFERENCE": "WHXX",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": "23Y-58D-15000",
      "DESCRIPTION": "AC SWITCH PEDAL CONTROL",
      "PRODUCT CATEGORY": "SDG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "911119Z01D",
      "DESCRIPTION": "FLANGE NUT",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 13,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 178,
      "WHXX": 142,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 11701842,
      "DESCRIPTION": "ADAPTER",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 0,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 14641479,
      "DESCRIPTION": "FILLER CAP 14626785 14528922 14529069",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3,
      "WHXX": 3,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 20459172,
      "DESCRIPTION": "SEALING RING",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 7,
      "WHXX": 6,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "306209Z00B",
      "DESCRIPTION": "MASTER CYLINDER, 24-38",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.833333333333333,
      "MAINTANING STOCK QTY": 3,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 3,
      "APRD QTY": 0,
      "WH12": 3,
      "WHXX": 2,
      "WH REFERENCE": "WH12 + WHXX",
      "TREND": 0,
      "STD": 2.77350098112614,
      "MEAN": 0.769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 3.6000000000000014,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": "550209Z00A",
      "DESCRIPTION": "LEAF SPRING",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.916666666666666,
      "MAINTANING STOCK QTY": 4,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 4,
      "APRD QTY": 4,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 2,
      "STD": 2.7642405179408,
      "MEAN": 0.846153846153846,
      "COV": 3.26682970302095,
      "SHELF LIFE_1": 4.363636363636367,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": "295579Z00A",
      "DESCRIPTION": "VALVE",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 13,
      "WHXX": 8,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "911119Z02C",
      "DESCRIPTION": "FLANGE NUT",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 4,
      "SHELF LIFE": 12.000000000000012,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 63,
      "WHXX": 62,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 1.10940039245045,
      "MEAN": 0.307692307692307,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 12.000000000000012,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 4110000592008,
      "DESCRIPTION": "UPPER RADIATOR HOSE 4110001985005",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 11,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": "4514904Z2E",
      "DESCRIPTION": "T-NIPPLE",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.833333333333333,
      "MAINTANING STOCK QTY": 3,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 3,
      "APRD QTY": 0,
      "WH12": 28,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 0,
      "STD": 2.77350098112614,
      "MEAN": 0.769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 3.6000000000000014,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": "333029Z00E",
      "DESCRIPTION": "GASKET",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 25,
      "WHXX": 9,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "333199Z00A",
      "DESCRIPTION": "SHIM",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 13,
      "WHXX": 10,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "333569Z00A",
      "DESCRIPTION": "SEALING RING",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3,
      "WHXX": 7,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "333029Z00D",
      "DESCRIPTION": "GASKET",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 8,
      "WHXX": 9,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "995499Z00C",
      "DESCRIPTION": "HOSE ASSEMBLY",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 17,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "307059Z00C",
      "DESCRIPTION": "CLUTCH SERVO",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 5,
      "WHXX": 7,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "542209Z00A",
      "DESCRIPTION": "BUMP STOP(RUBBER)",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 10,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "542199Z00A",
      "DESCRIPTION": "U-BOLT",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 1.58333333333333,
      "MAINTANING STOCK QTY": 6,
      "AVAILABLE": 4,
      "SHELF LIFE": 2.52631578947369,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 2,
      "APRD QTY": 0,
      "WH12": 36,
      "WHXX": 10,
      "WH REFERENCE": "WHXX",
      "TREND": 0.63,
      "STD": 2.84650032541085,
      "MEAN": 1.46153846153846,
      "COV": 1.94760548580742,
      "SHELF LIFE_1": 3.7894736842105345,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": "542199Z00D",
      "DESCRIPTION": "U-BOLT ANCHORAGE",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "405669Z00B",
      "DESCRIPTION": "LUBRICATING NIPPLE",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 2.16666666666666,
      "MAINTANING STOCK QTY": 9,
      "AVAILABLE": 9,
      "SHELF LIFE": 4.153846153846167,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 66,
      "WHXX": 65,
      "WH REFERENCE": "",
      "TREND": 0.31,
      "STD": 5.53774924194538,
      "MEAN": 2,
      "COV": 2.76887462097269,
      "SHELF LIFE_1": 4.153846153846167,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": "282299Z00A",
      "DESCRIPTION": "FLANGE SCREW",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 4,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 20,
      "WHXX": 25,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "282299Z03B",
      "DESCRIPTION": "FLANGE SCREW",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 33,
      "WHXX": 38,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "911119Z03E",
      "DESCRIPTION": "FLANGE NUT ADAPTED QTY 25",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 3,
      "MAINTANING STOCK QTY": 12,
      "AVAILABLE": 12,
      "SHELF LIFE": 4,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 110,
      "WHXX": 6,
      "WH REFERENCE": "",
      "TREND": 0.67,
      "STD": 6.19346222713712,
      "MEAN": 2.76923076923076,
      "COV": 2.23652802646618,
      "SHELF LIFE_1": 4,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": "555469Z00D",
      "DESCRIPTION": "V-STAY",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "201269Z01E",
      "DESCRIPTION": "LOCK NUT",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 10,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "475119Z00E",
      "DESCRIPTION": "HEXAGON SCREW",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 11,
      "WHXX": 20,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "951489Z00C",
      "DESCRIPTION": "COMPRESSION SPRING",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 7,
      "WH REFERENCE": "WHXX",
      "TREND": 0,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 14588541,
      "DESCRIPTION": "YOKE",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4110000991005,
      "DESCRIPTION": "ALTERNATOR LG936  ",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 1,
      "STD": 0.375533808099405,
      "MEAN": 0.153846153846153,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 20551429,
      "DESCRIPTION": "FITTING",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 6,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 20460936,
      "DESCRIPTION": "HOLLOW SCREW",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 5,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14532285,
      "DESCRIPTION": "SPRING",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "4120000675Z",
      "DESCRIPTION": "PNEUMATIC CYLINDER",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.666666666666666,
      "MAINTANING STOCK QTY": 3,
      "AVAILABLE": 3,
      "SHELF LIFE": 4.500000000000004,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 54,
      "WHXX": 13,
      "WH REFERENCE": "",
      "TREND": 1,
      "STD": 1.12089707663561,
      "MEAN": 0.615384615384615,
      "COV": 1.82145774953286,
      "SHELF LIFE_1": 4.500000000000004,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": "4130000418A",
      "DESCRIPTION": "THERMOSTAT 4130000418",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4110000189022,
      "DESCRIPTION": "STARTER MOTOR  4110000909089",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "308129Z00B",
      "DESCRIPTION": "PIPE SET",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 8,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "452059Z00C",
      "DESCRIPTION": "LOAD SENSING VALVE",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "475119Z02C",
      "DESCRIPTION": "HEXAGON SCREW",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 15,
      "WHXX": 10,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4110001593002,
      "DESCRIPTION": "FILTER, FWS  4110001016004  ",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "AVERAGE (F2)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 1.16666666666666,
      "MAINTANING STOCK QTY": 5,
      "AVAILABLE": 1,
      "SHELF LIFE": 0.857142857142862,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 4,
      "APRD QTY": 4,
      "WH12": 25,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 1.29,
      "STD": 1.25575597825496,
      "MEAN": 1.07692307692307,
      "COV": 1.16605912266532,
      "SHELF LIFE_1": 4.28571428571431,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 4110000189031,
      "DESCRIPTION": " FUEL FILTER INSERT",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 1,
      "SHELF LIFE": 6.000000000000024,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 1,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 960247,
      "DESCRIPTION": "O-RING",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 13,
      "WHXX": 37,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "4514904Z2C",
      "DESCRIPTION": "ELBOW NIPPLE",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.833333333333333,
      "MAINTANING STOCK QTY": 3,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 3,
      "APRD QTY": 0,
      "WH12": 30,
      "WHXX": 49,
      "WH REFERENCE": "WHXX",
      "TREND": 0,
      "STD": 2.77350098112614,
      "MEAN": 0.769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 3.6000000000000014,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": "382179Z00A",
      "DESCRIPTION": "ORING 146129Z00D",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 1,
      "SHELF LIFE": 6.000000000000024,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 1,
      "STD": 0.375533808099405,
      "MEAN": 0.153846153846153,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": "995499Z00D",
      "DESCRIPTION": "HOSE ASSEMBLY",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 11,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "B222100000257",
      "DESCRIPTION": "FILTER CORE  B222100000212  HF6870",
      "PRODUCT CATEGORY": "YCG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 1,
      "SHELF LIFE": 6.000000000000024,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 14535346,
      "DESCRIPTION": "ANTENNA",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 2,
      "WH REFERENCE": "WHXX",
      "TREND": 1,
      "STD": 0.375533808099405,
      "MEAN": 0.153846153846153,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 14511974,
      "DESCRIPTION": "ROD",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "145849Z06A",
      "DESCRIPTION": "NIPPLE",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.833333333333333,
      "MAINTANING STOCK QTY": 3,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 3,
      "APRD QTY": 0,
      "WH12": 36,
      "WHXX": 9,
      "WH REFERENCE": "WHXX",
      "TREND": 0,
      "STD": 2.77350098112614,
      "MEAN": 0.769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 3.6000000000000014,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 17216674,
      "DESCRIPTION": "SEALING KIT  HB21",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3,
      "WHXX": 3,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 17216672,
      "DESCRIPTION": "T/BOLT SET  HB21",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 1,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 1,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "432409Z00B",
      "DESCRIPTION": "5222455512  WHEEL STUD",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 2.5,
      "MAINTANING STOCK QTY": 10,
      "AVAILABLE": 3,
      "SHELF LIFE": 1.2,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 7,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 0,
      "STD": 8.32050294337843,
      "MEAN": 2.3076923076923,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 4,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": "207049Z00C",
      "DESCRIPTION": "HEAT SHIELD",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "381669Z09E",
      "DESCRIPTION": "FLANGE SCREW",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 25,
      "WHXX": 25,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "963019Z01B",
      "DESCRIPTION": "MIRROR KIT",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3,
      "WHXX": 7,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "C61451",
      "DESCRIPTION": "SEAL KIT/NOK/SU+125                        ",
      "PRODUCT CATEGORY": "SOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 11193390,
      "DESCRIPTION": "CABLE",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 979008,
      "DESCRIPTION": "FUSE 40A 46675237",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 8,
      "WHXX": 8,
      "WH REFERENCE": "WHXX",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 14550598,
      "DESCRIPTION": "BUSHING",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 0,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 14532448,
      "DESCRIPTION": "NUT, SPRING PACKAGE  EC140B",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 3,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14527463,
      "DESCRIPTION": "BOLT",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4013000205,
      "DESCRIPTION": "NUT, PLOW BOLT  ",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.916666666666666,
      "MAINTANING STOCK QTY": 4,
      "AVAILABLE": 6,
      "SHELF LIFE": 6.54545454545455,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 601,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 3.05085107923876,
      "MEAN": 0.846153846153846,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.54545454545455,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 4110000556161,
      "DESCRIPTION": "CYLINDER LINER ",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.5,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 2,
      "APRD QTY": 2,
      "WH12": 16,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 2,
      "STD": 1.66410058867568,
      "MEAN": 0.461538461538461,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 4,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 4110000556139,
      "DESCRIPTION": "SEALING RING ",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 55,
      "WHXX": 12,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4110000556066,
      "DESCRIPTION": "PISTON RING GROUP  WD10G220  612600030051  LG956L",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 1,
      "MAINTANING STOCK QTY": 4,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 4,
      "APRD QTY": 4,
      "WH12": 2,
      "WHXX": 1,
      "WH REFERENCE": "PARTIAL WHXX + WH12",
      "TREND": 2,
      "STD": 3.32820117735137,
      "MEAN": 0.923076923076923,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 4,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 4110000556030,
      "DESCRIPTION": "PLAIN WASHER 30*53*1DIN71511 90003069303",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4110000924025,
      "DESCRIPTION": "WASHER  4110001525021",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4110000556087,
      "DESCRIPTION": "4110004228004 GASKET, INTAKE MANIFOLD ",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.5,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 6,
      "SHELF LIFE": 12,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 8,
      "WHXX": 6,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 1.66410058867568,
      "MEAN": 0.461538461538461,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 12,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 4110000556142,
      "DESCRIPTION": "GASKET 4110000924171",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.5,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 2,
      "APRD QTY": 0,
      "WH12": 38,
      "WHXX": 6,
      "WH REFERENCE": "WHXX",
      "TREND": 0,
      "STD": 1.66410058867568,
      "MEAN": 0.461538461538461,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 4,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 4110000556165,
      "DESCRIPTION": "SEALING SLEEVE ",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 281,
      "WHXX": 79,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4110000556155,
      "DESCRIPTION": "CYLINDER HEAD GASKET ",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 1,
      "MAINTANING STOCK QTY": 4,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 4,
      "APRD QTY": 0,
      "WH12": 95,
      "WHXX": 64,
      "WH REFERENCE": "WHXX",
      "TREND": 1,
      "STD": 2.25320284859643,
      "MEAN": 0.923076923076923,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 4,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 85136370,
      "DESCRIPTION": "VOLVO GEARBOX OIL SAE50  80W90 -20L 85136371",
      "PRODUCT CATEGORY": "LUV",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 12.3333333333333,
      "MAINTANING STOCK QTY": 49,
      "AVAILABLE": 44,
      "SHELF LIFE": 3.5675675675675773,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 5,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 24,
      "WH REFERENCE": "WHXX",
      "TREND": 1.46,
      "STD": 21.0931997791486,
      "MEAN": 11.3846153846153,
      "COV": 1.85278106168197,
      "SHELF LIFE_1": 3.9729729729729835,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": "2001700Z0A",
      "DESCRIPTION": "GASKET-EXH TUBE",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 9,
      "WHXX": 8,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "2002200Z0A",
      "DESCRIPTION": "V-CLAMP",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3,
      "WHXX": 19,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "146769Z00E",
      "DESCRIPTION": "GASKET",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 42,
      "WHXX": 9,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 29350009031,
      "DESCRIPTION": "COMPRESSOR EARTH LINE",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.25,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 2,
      "STD": 0.599144689515278,
      "MEAN": 0.23076923076923,
      "COV": 2.5962936545662,
      "SHELF LIFE_1": 4,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": "145849Z00D",
      "DESCRIPTION": "ELBOW NIPPLE",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 1.08333333333333,
      "MAINTANING STOCK QTY": 4,
      "AVAILABLE": 4,
      "SHELF LIFE": 3.692307692307704,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 20,
      "WHXX": 6,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 2.76887462097269,
      "MEAN": 1,
      "COV": 2.76887462097269,
      "SHELF LIFE_1": 3.692307692307704,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": "4514904Z5A",
      "DESCRIPTION": "ANGLE RING",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 20,
      "WH REFERENCE": "WHXX",
      "TREND": 0,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": "4514904Z3B",
      "DESCRIPTION": "3-WAY NIPPLE",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 1,
      "MAINTANING STOCK QTY": 4,
      "AVAILABLE": 4,
      "SHELF LIFE": 4,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 25,
      "WHXX": 8,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 2.78273062720589,
      "MEAN": 0.923076923076923,
      "COV": 3.01462484613972,
      "SHELF LIFE_1": 4,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 28140006791,
      "DESCRIPTION": "BOLT, CUTTING EDGE  LG9190",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 13,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 317,
      "WHXX": 24,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4043000218,
      "DESCRIPTION": "BUSH ",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4043000112,
      "DESCRIPTION": "WELD PIN LGB301-60*95*170G-40CR",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 45,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4190002758,
      "DESCRIPTION": "COMPRESSOR",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.916666666666666,
      "MAINTANING STOCK QTY": 4,
      "AVAILABLE": 2,
      "SHELF LIFE": 2.1818181818181834,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 2,
      "APRD QTY": 2,
      "WH12": 1,
      "WHXX": 1,
      "WH REFERENCE": "WH12 + WHXX",
      "TREND": 1.45,
      "STD": 1.14354374979373,
      "MEAN": 0.846153846153846,
      "COV": 1.35146079521077,
      "SHELF LIFE_1": 4.363636363636367,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": "B229900003708",
      "DESCRIPTION": "ACCUMULATOR A229900000020",
      "PRODUCT CATEGORY": "YPG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "381659Z00A",
      "DESCRIPTION": "GASKET",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 22,
      "WHXX": 33,
      "WH REFERENCE": "WHXX",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": "321369Z00A",
      "DESCRIPTION": "O-RING",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 3.41666666666666,
      "MAINTANING STOCK QTY": 14,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 14,
      "APRD QTY": 14,
      "WH12": 11,
      "WHXX": 38,
      "WH REFERENCE": "WHXX",
      "TREND": 1.85,
      "STD": 9.09987320283598,
      "MEAN": 3.15384615384615,
      "COV": 2.8853256496797,
      "SHELF LIFE_1": 4.097560975609764,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 5333477,
      "DESCRIPTION": "BELT TENSIONER",
      "PRODUCT CATEGORY": "CUG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 1,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14663703,
      "DESCRIPTION": "NUT, T/S BOLT EC460B 14880576 ",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 5.16666666666666,
      "MAINTANING STOCK QTY": 21,
      "AVAILABLE": 13,
      "SHELF LIFE": 2.5161290322580676,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 8,
      "APRD QTY": 8,
      "WH12": 2074,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 1.13,
      "STD": 8.14610178913659,
      "MEAN": 4.76923076923076,
      "COV": 1.70805360094799,
      "SHELF LIFE_1": 4.064516129032263,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": "550459Z00E",
      "DESCRIPTION": "BUSHING KIT 550459Z00C",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 13,
      "WHXX": 1,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "261369Z01C",
      "DESCRIPTION": "STAY",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 7,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "201349Z00A",
      "DESCRIPTION": "BRACKET",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 7,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "969229Z01A ",
      "DESCRIPTION": "BLIND RIVET",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 10,
      "WHXX": 50,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14621172,
      "DESCRIPTION": "BRACKET",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 1,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14623356,
      "DESCRIPTION": "CABLE HARNESS",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 936439,
      "DESCRIPTION": "TESTING NIPPLE 58853292",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 15113226,
      "DESCRIPTION": "V-CLAMP 1629499",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 6,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 0,
      "STD": 1.10940039245045,
      "MEAN": 0.307692307692307,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 3.000000000000003,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 21119083,
      "DESCRIPTION": "GASKET",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 8,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 0,
      "STD": 1.10940039245045,
      "MEAN": 0.307692307692307,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 3.000000000000003,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": "263109Z00B",
      "DESCRIPTION": "HORN",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 15,
      "WHXX": 4,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "654189Z00A",
      "DESCRIPTION": "GAS SPRING",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 24,
      "WHXX": 12,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "322919Z01B",
      "DESCRIPTION": "GASKET",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 10,
      "WHXX": 12,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "329349Z00B",
      "DESCRIPTION": "GASKET",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 57,
      "WHXX": 40,
      "WH REFERENCE": "WHXX",
      "TREND": 0,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": "324979Z00C",
      "DESCRIPTION": "GASKET",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 11,
      "WHXX": 9,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "798499Z00B",
      "DESCRIPTION": "RETAINER",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 18,
      "WHXX": 25,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "324979Z00D",
      "DESCRIPTION": "GASKET",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 1,
      "SHELF LIFE": 6.000000000000024,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 18,
      "WHXX": 26,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 14671313,
      "DESCRIPTION": "SWITCH 14626841",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 4,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4021000029,
      "DESCRIPTION": "ROLLING BEARING ",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 1,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4021000028,
      "DESCRIPTION": "BEARING ",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 6,
      "WHXX": 2,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4021000035,
      "DESCRIPTION": "ROLLING BEARING ",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 1,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4021000026,
      "DESCRIPTION": "ROLLING BEARING ",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "L01110",
      "DESCRIPTION": "RING BUSH  SU+85",
      "PRODUCT CATEGORY": "SOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "272129Z00B",
      "DESCRIPTION": "EVAPORATOR",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.5,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 2,
      "SHELF LIFE": 4,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 6,
      "WHXX": 6,
      "WH REFERENCE": "",
      "TREND": 0.33,
      "STD": 0.776250025806184,
      "MEAN": 0.461538461538461,
      "COV": 1.6818750559134,
      "SHELF LIFE_1": 4,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 9021306350,
      "DESCRIPTION": "STARTER APPARATUS, REMAN EC210B 21306350",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 22,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "B230101001367",
      "DESCRIPTION": "RETAINER RING 118.7√Ó125√Ó1.5 HYDAC",
      "PRODUCT CATEGORY": "YPG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.583333333333333,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 2,
      "APRD QTY": 0,
      "WH12": 7,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 0.29,
      "STD": 1.66410058867568,
      "MEAN": 0.538461538461538,
      "COV": 3.09047252182627,
      "SHELF LIFE_1": 3.42857142857143,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": "B230101001366",
      "DESCRIPTION": "O-RING117",
      "PRODUCT CATEGORY": "YPG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 1,
      "SHELF LIFE": 6.000000000000024,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 9,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 4110000054003,
      "DESCRIPTION": "O-SEAL ",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 14,
      "WHXX": 17,
      "WH REFERENCE": "WHXX",
      "TREND": 0,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 4110000054002,
      "DESCRIPTION": "O-SEAL ",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 8,
      "WHXX": 23,
      "WH REFERENCE": "WHXX",
      "TREND": 0,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 4110000054100,
      "DESCRIPTION": "SEALING RING, CRANKCASE ",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 1,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4110000846035,
      "DESCRIPTION": "SEALING RING, FLYWHEEL ",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4110000054284,
      "DESCRIPTION": "PUMP GASKET 12158513",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 19,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4110000054006,
      "DESCRIPTION": "O-SEAL ",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 13,
      "WHXX": 1,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4110000054191,
      "DESCRIPTION": "SEALING RING ",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 14,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4120004769,
      "DESCRIPTION": "CYLINDER ",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4110001031042,
      "DESCRIPTION": "AIR COMPRESSOR ",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 1,
      "STD": 0.375533808099405,
      "MEAN": 0.153846153846153,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 17220227,
      "DESCRIPTION": "BEARING",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 29070010931,
      "DESCRIPTION": "FRONT WHEEL HUB",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14663702,
      "DESCRIPTION": "NUT, T/S BOLT EC210B 14880574",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 3.33333333333333,
      "MAINTANING STOCK QTY": 13,
      "AVAILABLE": 52,
      "SHELF LIFE": 15.600000000000016,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1639,
      "WHXX": 1355,
      "WH REFERENCE": "",
      "TREND": 1,
      "STD": 7.5106761619881,
      "MEAN": 3.07692307692307,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 15.600000000000016,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 28140007461,
      "DESCRIPTION": "CUTTING EDGE",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 1,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 28140007451,
      "DESCRIPTION": "CUTTING EDGE",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 32,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4110000054128,
      "DESCRIPTION": "CONNECTING ROD ",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 991971,
      "DESCRIPTION": "NIPPLE",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 25,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4110000057003,
      "DESCRIPTION": "GASKET 4110000054233",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 11,
      "WHXX": 5,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4110001525005,
      "DESCRIPTION": "CYLINDER LINER",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 11705144,
      "DESCRIPTION": "AIR VENT VALVE",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 6,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "L01198",
      "DESCRIPTION": "SSL01198 FRONT COVER  SU+85",
      "PRODUCT CATEGORY": "SOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14650315,
      "DESCRIPTION": "14737475 STRAINER EC210B/EC460B 14524761",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "2010200Z7B",
      "DESCRIPTION": "MUFFLER",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "7114-12121",
      "DESCRIPTION": "SEALING",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 36,
      "WHXX": 15,
      "WH REFERENCE": "WHXX",
      "TREND": 0,
      "STD": 0.375533808099405,
      "MEAN": 0.153846153846153,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": "8220-13470",
      "DESCRIPTION": "O-RING",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.666666666666666,
      "MAINTANING STOCK QTY": 3,
      "AVAILABLE": 3,
      "SHELF LIFE": 4.500000000000004,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 21,
      "WHXX": 16,
      "WH REFERENCE": "",
      "TREND": 1,
      "STD": 0.960768922830522,
      "MEAN": 0.615384615384615,
      "COV": 1.56124949959959,
      "SHELF LIFE_1": 4.500000000000004,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": "7114-17460",
      "DESCRIPTION": "O-RING",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.75,
      "MAINTANING STOCK QTY": 3,
      "AVAILABLE": 3,
      "SHELF LIFE": 4,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 27,
      "WHXX": 12,
      "WH REFERENCE": "",
      "TREND": 0.89,
      "STD": 1.03155347127648,
      "MEAN": 0.692307692307692,
      "COV": 1.49002168073269,
      "SHELF LIFE_1": 4,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 14528776,
      "DESCRIPTION": "SEALING",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.666666666666666,
      "MAINTANING STOCK QTY": 3,
      "AVAILABLE": 3,
      "SHELF LIFE": 4.500000000000004,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 66,
      "WHXX": 6,
      "WH REFERENCE": "",
      "TREND": 0.75,
      "STD": 0.869718492622904,
      "MEAN": 0.615384615384615,
      "COV": 1.41329255051221,
      "SHELF LIFE_1": 4.500000000000004,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": "963229Z00A",
      "DESCRIPTION": "MIRROR GLASS",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 8,
      "WHXX": 8,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 15185168,
      "DESCRIPTION": "SEALING KIT",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 5,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 21242584,
      "DESCRIPTION": "GASKET EC210B 20405790",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 2,
      "SHELF LIFE": 24.00000000000001,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 7,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 24.00000000000001,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 21896344,
      "DESCRIPTION": "INLET VALVE 20758481",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 27,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14604058,
      "DESCRIPTION": "RELAY",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 0,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": "154-71-41270V010",
      "DESCRIPTION": "BOLT & NUT 154-70-11143",
      "PRODUCT CATEGORY": "SDG",
      "ITEM MOVEMENT BRANCH": "AVERAGE (F2)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 33.8333333333333,
      "MAINTANING STOCK QTY": 135,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 135,
      "APRD QTY": 0,
      "WH12": 172,
      "WHXX": 21,
      "WH REFERENCE": "WH12 + WHXX",
      "TREND": 0.11,
      "STD": 71.4681209189965,
      "MEAN": 31.2307692307692,
      "COV": 2.28838810824373,
      "SHELF LIFE_1": 3.9901477832512353,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 4043000028,
      "DESCRIPTION": "BUSH ",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 4,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 11103470,
      "DESCRIPTION": "BRAKE PISTON",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 15010424,
      "DESCRIPTION": "ADJUSTING DEVICE",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "30 DAY AVE": 0.5,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 2,
      "APRD QTY": 2,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "PARTIAL WH12",
      "TREND": 2,
      "STD": 1.66410058867568,
      "MEAN": 0.461538461538461,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 4,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 14649606,
      "DESCRIPTION": "COMPRESSOR",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.666666666666666,
      "MAINTANING STOCK QTY": 3,
      "AVAILABLE": 1,
      "SHELF LIFE": 1.5000000000000016,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 2,
      "APRD QTY": 2,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 2,
      "STD": 1.26085034391223,
      "MEAN": 0.615384615384615,
      "COV": 2.04888180885737,
      "SHELF LIFE_1": 4.500000000000004,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": "328599Z00D",
      "DESCRIPTION": "GEAR SHIFT LEVER KNOB",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 12,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "451849Z00B",
      "DESCRIPTION": "RELAY VALVE",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3,
      "WHXX": 3,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14558072,
      "DESCRIPTION": "SHEET",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 0,
      "WHXX": 5,
      "WH REFERENCE": "WHXX",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 21337557,
      "DESCRIPTION": "AIR FILTER-PRIMARY",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.75,
      "MAINTANING STOCK QTY": 3,
      "AVAILABLE": 4,
      "SHELF LIFE": 5.333333333333333,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 21,
      "WHXX": 1,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 1.79743406854583,
      "MEAN": 0.692307692307692,
      "COV": 2.5962936545662,
      "SHELF LIFE_1": 5.333333333333333,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 21693755,
      "DESCRIPTION": "AIR FILTER ASSY",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 1,
      "MAINTANING STOCK QTY": 4,
      "AVAILABLE": 13,
      "SHELF LIFE": 13,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0.33,
      "STD": 1.97743682838577,
      "MEAN": 0.923076923076923,
      "COV": 2.14222323075126,
      "SHELF LIFE_1": 13,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": "651659Z00B",
      "DESCRIPTION": "CABLE, LOCK ASSY, LHD",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 17,
      "WHXX": 4,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 17429862,
      "DESCRIPTION": "MOIL POINT, TOOL VOLVO HB21",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 24,
      "WHXX": 6,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4021000033,
      "DESCRIPTION": "BEARING GB297-32024",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4021000037,
      "DESCRIPTION": "BEARING GB297-32221",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4110000659,
      "DESCRIPTION": "SPEED CHANGE OPERATING GEAR LG956L",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.25,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 11,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 2,
      "STD": 0.832050294337843,
      "MEAN": 0.23076923076923,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 4,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 4090000008,
      "DESCRIPTION": "STEEL BALL ",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 7,
      "WHXX": 8,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4043000035,
      "DESCRIPTION": "SPACER ",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 163,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4014000016,
      "DESCRIPTION": "SCREW",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 8,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 0,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 4015000022,
      "DESCRIPTION": "RING SNAP ",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 7,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 29070001261,
      "DESCRIPTION": "ISOLATED BUSHING",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 5,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 29070001271,
      "DESCRIPTION": "SPACER",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 1,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4110000556072,
      "DESCRIPTION": "OIL COOLER -61500010334",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 2,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4120001739013,
      "DESCRIPTION": "BOLT ",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 4,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 59070326,
      "DESCRIPTION": "PRESSURE SWITCH",
      "PRODUCT CATEGORY": "VCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 4,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "A220401000483",
      "DESCRIPTION": "PRESSUREREDUCINGVALVE",
      "PRODUCT CATEGORY": "YPG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 21244698,
      "DESCRIPTION": "RELAY",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 6,
      "SHELF LIFE": 72.00000000000003,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 178,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 72.00000000000003,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": "381029Z00A",
      "DESCRIPTION": "HEX. SOCKET SCREW",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 16,
      "WHXX": 20,
      "WH REFERENCE": "WHXX",
      "TREND": 0,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": "01010-51020",
      "DESCRIPTION": "BOLT",
      "PRODUCT CATEGORY": "SDG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 1,
      "MAINTANING STOCK QTY": 4,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 4,
      "APRD QTY": 4,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 2,
      "STD": 3.32820117735137,
      "MEAN": 0.923076923076923,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 4,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 4110000076402,
      "DESCRIPTION": "SOLENOID VALVE, MCV  LG9190",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 5,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "A810299000932",
      "DESCRIPTION": "TILTING ANGLE SENSOR",
      "PRODUCT CATEGORY": "YCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 12606029,
      "DESCRIPTION": "175A CONNECTOR 60C1816.1.23A",
      "PRODUCT CATEGORY": "YPG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 2,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 2,
      "STD": 0.375533808099405,
      "MEAN": 0.153846153846153,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 28350002251,
      "DESCRIPTION": "SLEEVE",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 15,
      "WHXX": 1,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 994781,
      "DESCRIPTION": "SIX POINT SOCKET SCREW 969408",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.25,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 22,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 2,
      "STD": 0.832050294337843,
      "MEAN": 0.23076923076923,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 4,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 994926,
      "DESCRIPTION": "SIX POINT SOCKET SCREW-969587",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 47,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 2,
      "STD": 1.10940039245045,
      "MEAN": 0.307692307692307,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 3.000000000000003,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": "178109Z01A",
      "DESCRIPTION": "O-RING",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 87,
      "WHXX": 25,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "178109Z01B",
      "DESCRIPTION": "O-RING",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 104,
      "WHXX": 22,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "178109Z01D",
      "DESCRIPTION": "O-RING",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 1,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 52,
      "WHXX": 26,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14693120,
      "DESCRIPTION": "TOP ROLLER 14527124",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 37,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 0,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 20517048,
      "DESCRIPTION": "HEXAGON NUT",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 3,
      "SHELF LIFE": 9.000000000000009,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 32,
      "WHXX": 23,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 1.10940039245045,
      "MEAN": 0.307692307692307,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 9.000000000000009,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 29050020541,
      "DESCRIPTION": "SHIM",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 29050020581,
      "DESCRIPTION": "SHIM",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14659471,
      "DESCRIPTION": "VALVE 14531116",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 0,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 29050000071,
      "DESCRIPTION": "BEARING SLEEVE",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 6,
      "WHXX": 10,
      "WH REFERENCE": "WHXX",
      "TREND": 1,
      "STD": 0.375533808099405,
      "MEAN": 0.153846153846153,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 14602950,
      "DESCRIPTION": "SPROCKET  EC700 14532544 ",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 2,
      "STD": 1.10940039245045,
      "MEAN": 0.307692307692307,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 3.000000000000003,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 12734398,
      "DESCRIPTION": "CLIMATE UNIT",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14588062,
      "DESCRIPTION": "GAUGE 14512936",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 4,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "921399Z00A",
      "DESCRIPTION": "REGULATOR CONTROL",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 4,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14619060,
      "DESCRIPTION": "14714050 BOLT, T/SHOE  EC700C",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.666666666666666,
      "MAINTANING STOCK QTY": 3,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 3,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 0,
      "STD": 2.21880078490091,
      "MEAN": 0.615384615384615,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 4.500000000000004,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 14619061,
      "DESCRIPTION": "NUT, T/SHOE BOLT  EC700C",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 11.1666666666666,
      "MAINTANING STOCK QTY": 45,
      "AVAILABLE": 43,
      "SHELF LIFE": 3.850746268656739,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 2,
      "APRD QTY": 0,
      "WH12": 414,
      "WHXX": 74,
      "WH REFERENCE": "WHXX",
      "TREND": 0.36,
      "STD": 16.9282043514397,
      "MEAN": 10.3076923076923,
      "COV": 1.64228848185609,
      "SHELF LIFE_1": 4.02985074626868,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 21687048,
      "DESCRIPTION": "HEADLAMP PROTECTOR",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.416666666666666,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 2,
      "APRD QTY": 2,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 2,
      "STD": 1.12089707663561,
      "MEAN": 0.384615384615384,
      "COV": 2.91433239925258,
      "SHELF LIFE_1": 4.800000000000008,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": "C31525",
      "DESCRIPTION": "SEAL KIT SU+85TR/F",
      "PRODUCT CATEGORY": "SOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.25,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 11,
      "SHELF LIFE": 44,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.599144689515278,
      "MEAN": 0.23076923076923,
      "COV": 2.5962936545662,
      "SHELF LIFE_1": 44,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": "L51204",
      "DESCRIPTION": "SEAL KIT SU+55TR-F ",
      "PRODUCT CATEGORY": "SOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 9,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 61,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 28110002321,
      "DESCRIPTION": "HYD FLUID FILTER",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 1,
      "SHELF LIFE": 12.000000000000005,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 17,
      "WHXX": 10,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 12.000000000000005,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 4130001898,
      "DESCRIPTION": "FUEL LEVEL SENSOR",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 11,
      "WHXX": 1,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4130001086,
      "DESCRIPTION": "ECU ÂÈËÂÄ®ÊÚ∫ÊÉßÂÀ∂ÂÇÏÂÖÉ 3601115-98D",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4190002822,
      "DESCRIPTION": "EXPANSION VALVE",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.25,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 0,
      "WHXX": 1,
      "WH REFERENCE": "WHXX",
      "TREND": 2,
      "STD": 0.599144689515278,
      "MEAN": 0.23076923076923,
      "COV": 2.5962936545662,
      "SHELF LIFE_1": 4,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 4130001422,
      "DESCRIPTION": "SWITCH",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4130001314,
      "DESCRIPTION": "TAIL LAMP",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 0,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 11215524,
      "DESCRIPTION": "ROTATING BEACON",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4130001101,
      "DESCRIPTION": "WORK LAMP",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 4,
      "WHXX": 3,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4110000970112,
      "DESCRIPTION": "SEAL",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 14,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4110001841019,
      "DESCRIPTION": "4110001841042 V BELT",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 11,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 1,
      "STD": 0.375533808099405,
      "MEAN": 0.153846153846153,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 4190002765,
      "DESCRIPTION": "SWITCH",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.666666666666666,
      "MAINTANING STOCK QTY": 3,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 3,
      "APRD QTY": 3,
      "WH12": 6,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 1.25,
      "STD": 1.04390784542678,
      "MEAN": 0.615384615384615,
      "COV": 1.69635024881852,
      "SHELF LIFE_1": 4.500000000000004,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 4110001905057,
      "DESCRIPTION": "O-RING",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 38,
      "WHXX": 5,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "430519Z00C",
      "DESCRIPTION": "SEALING RING",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 3,
      "WHXX": 1,
      "WH REFERENCE": "WHXX",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": "261369Z00D",
      "DESCRIPTION": "HOUSING",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4015000167,
      "DESCRIPTION": "WASHER ",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 21,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": "995499Z00A",
      "DESCRIPTION": "HOSE ASSEMBLY",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.583333333333333,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 2,
      "APRD QTY": 0,
      "WH12": 4,
      "WHXX": 6,
      "WH REFERENCE": "WHXX",
      "TREND": 0,
      "STD": 1.9414506867883,
      "MEAN": 0.538461538461538,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 3.42857142857143,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": "995499Z00B",
      "DESCRIPTION": "HOSE ASSEMBLY",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.583333333333333,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 2,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 10,
      "WH REFERENCE": "WHXX",
      "TREND": 0,
      "STD": 1.9414506867883,
      "MEAN": 0.538461538461538,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 3.42857142857143,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": "443419Z01A",
      "DESCRIPTION": "SLACK ADJUSTER",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "502389Z00A",
      "DESCRIPTION": "BEARING",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 5,
      "WHXX": 3,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "555029Z00A",
      "DESCRIPTION": "COMPRESSION FITTING",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 12,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "542199Z01A",
      "DESCRIPTION": "U-BOLT",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 2.16666666666666,
      "MAINTANING STOCK QTY": 9,
      "AVAILABLE": 5,
      "SHELF LIFE": 2.307692307692315,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 4,
      "APRD QTY": 4,
      "WH12": 0,
      "WHXX": 2,
      "WH REFERENCE": "PARTIAL WHXX",
      "TREND": 1.69,
      "STD": 5.47722557505166,
      "MEAN": 2,
      "COV": 2.73861278752583,
      "SHELF LIFE_1": 4.153846153846167,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": "178109Z00A",
      "DESCRIPTION": "O-RING",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 26,
      "WHXX": 20,
      "WH REFERENCE": "WHXX",
      "TREND": 0,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": "U81275",
      "DESCRIPTION": "O-RING, CAP",
      "PRODUCT CATEGORY": "SOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 4,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 60208879,
      "DESCRIPTION": "FUEL FILTER",
      "PRODUCT CATEGORY": "YCG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.5,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 3,
      "SHELF LIFE": 6,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 20,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 1.19828937903055,
      "MEAN": 0.461538461538461,
      "COV": 2.5962936545662,
      "SHELF LIFE_1": 6,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 60208878,
      "DESCRIPTION": "FUEL FILTER",
      "PRODUCT CATEGORY": "YCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 6,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 16,
      "WHXX": 7,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14515524,
      "DESCRIPTION": "CLIP 14507450",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 9,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "A220302000023",
      "DESCRIPTION": "WATER PUMP",
      "PRODUCT CATEGORY": "YPG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 21259899,
      "DESCRIPTION": "CHARGE AIR HOSE",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 2,
      "SHELF LIFE": 12.000000000000048,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 12.000000000000048,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 22058741,
      "DESCRIPTION": "AIR SPRING",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 6,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 22058737,
      "DESCRIPTION": "AIR SPRING",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 5,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 11193389,
      "DESCRIPTION": "CABLE",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4110000054044,
      "DESCRIPTION": "GASKET ",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 4,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 58,
      "WHXX": 5,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "797009Z00A",
      "DESCRIPTION": "GLASS",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 980973,
      "DESCRIPTION": "HOLLOW SCREW",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.25,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 7,
      "WHXX": 2,
      "WH REFERENCE": "WHXX",
      "TREND": 1.33,
      "STD": 0.599144689515278,
      "MEAN": 0.23076923076923,
      "COV": 2.5962936545662,
      "SHELF LIFE_1": 4,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 14557971,
      "DESCRIPTION": "SPROCKET EC140B",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4190002827,
      "DESCRIPTION": "HEAT EXCHANGER CORE",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 20459235,
      "DESCRIPTION": "STUD",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 3,
      "SHELF LIFE": 9.000000000000009,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 35,
      "WHXX": 35,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 1.10940039245045,
      "MEAN": 0.307692307692307,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 9.000000000000009,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 21027485,
      "DESCRIPTION": "GASKET",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 34,
      "WHXX": 21,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4120002027,
      "DESCRIPTION": "CONTROL LEVER",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 4021000043,
      "DESCRIPTION": "SPHERICAL PLAIN BEARING",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 1,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14530656,
      "DESCRIPTION": "LOCK_ASSY SASH",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 4,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 9017204355,
      "DESCRIPTION": "ALTERNATOR REMAN",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 17,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 28350001021,
      "DESCRIPTION": "WASHER",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4021000050,
      "DESCRIPTION": "BEARING ",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4015000046,
      "DESCRIPTION": "RING SNAP ",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 3,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 60034226,
      "DESCRIPTION": "OIL FILTER",
      "PRODUCT CATEGORY": "YCG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.5,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 6,
      "SHELF LIFE": 12,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3,
      "WHXX": 1,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 1.66410058867568,
      "MEAN": 0.461538461538461,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 12,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 15013381,
      "DESCRIPTION": "UPPER SECTION 11414163",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 43974310,
      "DESCRIPTION": "GAUGE TACHOMETER",
      "PRODUCT CATEGORY": "VCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4130000624,
      "DESCRIPTION": "SAFETY",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 8,
      "WH REFERENCE": "WHXX",
      "TREND": 0.5,
      "STD": 0.630425171956115,
      "MEAN": 0.307692307692307,
      "COV": 2.04888180885737,
      "SHELF LIFE_1": 3.000000000000003,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 96719604,
      "DESCRIPTION": "PLAIN WASHER",
      "PRODUCT CATEGORY": "VCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4110001948041,
      "DESCRIPTION": "FILTER INSERT",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "AVERAGE (F2)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 2.25,
      "MAINTANING STOCK QTY": 9,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 9,
      "APRD QTY": 9,
      "WH12": 60,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 1.26,
      "STD": 1.32045058354704,
      "MEAN": 2.07692307692307,
      "COV": 0.63577250318932,
      "SHELF LIFE_1": 4,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 4110000909074,
      "DESCRIPTION": "FILTER INSERT",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.25,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 130,
      "WHXX": 14,
      "WH REFERENCE": "WHXX",
      "TREND": 2,
      "STD": 0.438529009653514,
      "MEAN": 0.23076923076923,
      "COV": 1.90029237516522,
      "SHELF LIFE_1": 4,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 4120002104001,
      "DESCRIPTION": "FILTER INSERT",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.25,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 106,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 0.67,
      "STD": 0.599144689515278,
      "MEAN": 0.23076923076923,
      "COV": 2.5962936545662,
      "SHELF LIFE_1": 4,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 29350010491,
      "DESCRIPTION": "FILTER NETTING",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "FAST (F1)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 4.58333333333333,
      "MAINTANING STOCK QTY": 18,
      "AVAILABLE": 15,
      "SHELF LIFE": 3.2727272727272747,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 3,
      "APRD QTY": 3,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 1.24,
      "STD": 3.53915823687512,
      "MEAN": 4.23076923076923,
      "COV": 0.836528310534119,
      "SHELF LIFE_1": 3.92727272727273,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 29350010501,
      "DESCRIPTION": "FILTER NETTING",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "FAST (F1)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 4.25,
      "MAINTANING STOCK QTY": 17,
      "AVAILABLE": 15,
      "SHELF LIFE": 3.5294117647058822,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 2,
      "APRD QTY": 2,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 1.18,
      "STD": 2.84199280029402,
      "MEAN": 3.92307692307692,
      "COV": 0.724429537329849,
      "SHELF LIFE_1": 4,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 4190002863,
      "DESCRIPTION": "RESERVOIR",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.25,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 1,
      "SHELF LIFE": 4,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 1,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.599144689515278,
      "MEAN": 0.23076923076923,
      "COV": 2.5962936545662,
      "SHELF LIFE_1": 4,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 4110000924103,
      "DESCRIPTION": "WATER PUMP LG956L  ",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 7,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 21602385,
      "DESCRIPTION": "22937189 FILTER CARTRIDGE",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14588542,
      "DESCRIPTION": "YOKE",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "542199Z00C",
      "DESCRIPTION": "U-BOLT",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 1.58333333333333,
      "MAINTANING STOCK QTY": 6,
      "AVAILABLE": 4,
      "SHELF LIFE": 2.52631578947369,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 2,
      "APRD QTY": 0,
      "WH12": 12,
      "WHXX": 11,
      "WH REFERENCE": "WHXX",
      "TREND": 0.63,
      "STD": 2.84650032541085,
      "MEAN": 1.46153846153846,
      "COV": 1.94760548580742,
      "SHELF LIFE_1": 3.7894736842105345,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 14573760,
      "DESCRIPTION": "SEALING",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14616919,
      "DESCRIPTION": "PLATE",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14573771,
      "DESCRIPTION": "SCREW",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 17444646,
      "DESCRIPTION": "17532105 CONTROL PANEL 15155940",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "333119Z00B",
      "DESCRIPTION": "COVER",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 22383381,
      "DESCRIPTION": "LEVEL SENSOR 21391697",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 6,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "320139Z00C",
      "DESCRIPTION": "O-RING",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 8,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 17,
      "WHXX": 3,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "320139Z00A",
      "DESCRIPTION": "O-RING",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 5,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 12,
      "WHXX": 31,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "324979Z00E",
      "DESCRIPTION": "GASKET",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 37,
      "WHXX": 21,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "261809Z00C",
      "DESCRIPTION": "LAMP - ROOF MARKER",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 13,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "631029Z00B",
      "DESCRIPTION": "FENDER SIDE",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 5,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4110002147005,
      "DESCRIPTION": "4110001009040 V-BELT",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 11444203,
      "DESCRIPTION": "PANEL",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "166959Z01D",
      "DESCRIPTION": "FUEL FILTER WATER SEPARATOR",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "AVERAGE (F2)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 2.58333333333333,
      "MAINTANING STOCK QTY": 10,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 10,
      "APRD QTY": 10,
      "WH12": 35,
      "WHXX": 11,
      "WH REFERENCE": "WHXX",
      "TREND": 0.58,
      "STD": 2.66265724225698,
      "MEAN": 2.38461538461538,
      "COV": 1.11659819836583,
      "SHELF LIFE_1": 3.870967741935489,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": "475009Z01A",
      "DESCRIPTION": "AIR DRIER FILTER KIT",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 4,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "324879Z00D",
      "DESCRIPTION": "BEARING",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3,
      "WHXX": 2,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "963229Z00C",
      "DESCRIPTION": "MIRROR GLASS",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 17,
      "WHXX": 8,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "301009Z01A",
      "DESCRIPTION": "5223994853 CLUTCH DISC ",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "967179Z00C",
      "DESCRIPTION": "BUSHING",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 16,
      "WHXX": 34,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "967179Z00A",
      "DESCRIPTION": "BUSHING",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 25,
      "WHXX": 64,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "551219Z03B",
      "DESCRIPTION": "SPRING LEAF",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 7,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "542139Z01C",
      "DESCRIPTION": "SETTING NUT",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 100,
      "WHXX": 20,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "550579Z00A",
      "DESCRIPTION": "CENTRE BOLT",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 46,
      "WHXX": 10,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14506786,
      "DESCRIPTION": "CATCH",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 8,
      "WH REFERENCE": "WHXX",
      "TREND": 1,
      "STD": 0.375533808099405,
      "MEAN": 0.153846153846153,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": "631249Z00E",
      "DESCRIPTION": "FENDER FLARE",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 5,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4130000869,
      "DESCRIPTION": "AUXILIARY RELAY 024-1Z6GD2",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 10,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4110000924044,
      "DESCRIPTION": "FUEL PUMP",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3,
      "WHXX": 1,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 29050018231,
      "DESCRIPTION": "SHIM",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 2,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 29050018221,
      "DESCRIPTION": "SHIM",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 29050018241,
      "DESCRIPTION": "SHIM",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 1,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "555479Z01A",
      "DESCRIPTION": "BOGIE ANCHORAGE",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 6,
      "WHXX": 1,
      "WH REFERENCE": "WHXX",
      "TREND": 0,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": "911119Z00E",
      "DESCRIPTION": "HEXAGON NUT",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 2.41666666666666,
      "MAINTANING STOCK QTY": 10,
      "AVAILABLE": 11,
      "SHELF LIFE": 4.551724137931047,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 11,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 6.93005346484722,
      "MEAN": 2.23076923076923,
      "COV": 3.10657569113841,
      "SHELF LIFE_1": 4.551724137931047,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 4130000618,
      "DESCRIPTION": "BULB 13498(24V 21W)",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 17411027,
      "DESCRIPTION": "PLANET CARRIER ",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 14521612,
      "DESCRIPTION": "O-RING",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 1,
      "SHELF LIFE": 6.000000000000024,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 5,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 15066561,
      "DESCRIPTION": "O-RING",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 1,
      "SHELF LIFE": 6.000000000000024,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 2,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 14515742,
      "DESCRIPTION": "FILTER ELEMENT",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 1,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 10,
      "WHXX": 3,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 17411510,
      "DESCRIPTION": "EXPANSION TANK  EC210B/EC210D",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.5,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 3,
      "SHELF LIFE": 6,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 5,
      "WHXX": 3,
      "WH REFERENCE": "",
      "TREND": 0.67,
      "STD": 0.776250025806184,
      "MEAN": 0.461538461538461,
      "COV": 1.6818750559134,
      "SHELF LIFE_1": 6,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 14689735,
      "DESCRIPTION": "FILTER MAIN, CAB_14506997",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "FAST (F1)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 10.0833333333333,
      "MAINTANING STOCK QTY": 40,
      "AVAILABLE": 39,
      "SHELF LIFE": 3.867768595041335,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 0,
      "WHXX": 29,
      "WH REFERENCE": "WHXX",
      "TREND": 0.91,
      "STD": 7.25011052081984,
      "MEAN": 9.3076923076923,
      "COV": 0.778937493972379,
      "SHELF LIFE_1": 3.9669421487603436,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 4030000768,
      "DESCRIPTION": "SEALING RING",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 4,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 29050016421,
      "DESCRIPTION": "DRIVE SHAFT GEAR",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 5,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 0,
      "STD": 0.375533808099405,
      "MEAN": 0.153846153846153,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": "322699Z00C",
      "DESCRIPTION": "RETAINING RING",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 14,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14659238,
      "DESCRIPTION": "14778022 COMPRESSOR 15082727 11412631",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.416666666666666,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 2,
      "APRD QTY": 2,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 1.6,
      "STD": 0.65044363558799,
      "MEAN": 0.384615384615384,
      "COV": 1.69115345252877,
      "SHELF LIFE_1": 4.800000000000008,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": "081A66162A",
      "DESCRIPTION": "BOLT-FLG",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 42,
      "WHXX": 30,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "01010-52490",
      "DESCRIPTION": "BOLT",
      "PRODUCT CATEGORY": "SDG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 0,
      "STD": 1.10940039245045,
      "MEAN": 0.307692307692307,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 3.000000000000003,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 16680661,
      "DESCRIPTION": "KIT",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.416666666666666,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 2,
      "APRD QTY": 2,
      "WH12": 20,
      "WHXX": 10,
      "WH REFERENCE": "WHXX",
      "TREND": 1.2,
      "STD": 0.767947647788304,
      "MEAN": 0.384615384615384,
      "COV": 1.99666388424959,
      "SHELF LIFE_1": 4.800000000000008,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 14640101,
      "DESCRIPTION": "ECU",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 1,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "440669Z01D",
      "DESCRIPTION": "BRAKE LINING KIT 410399Z00A ",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 6,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 9,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 22610786,
      "DESCRIPTION": "SPACER WASHER ",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 4,
      "WHXX": 2,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "XC8286065",
      "DESCRIPTION": "BUCKET ASSY (GP)  3.10M3  G6",
      "PRODUCT CATEGORY": "VOA",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 11110708,
      "DESCRIPTION": "54864268 FILTER RETAINER, FWS 11110702",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "AVERAGE (F2)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 1.83333333333333,
      "MAINTANING STOCK QTY": 7,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 7,
      "APRD QTY": 7,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 0.91,
      "STD": 2.52931530209973,
      "MEAN": 1.69230769230769,
      "COV": 1.4945954057862,
      "SHELF LIFE_1": 3.8181818181818254,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 29070011091,
      "DESCRIPTION": "INPUT FLANGE",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4110000189026,
      "DESCRIPTION": "PUMP GASKET 12200650",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 6,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4110001554019,
      "DESCRIPTION": "4110001554040 THRUST WASHER",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 0,
      "WHXX": 2,
      "WH REFERENCE": "WHXX",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 22567321,
      "DESCRIPTION": "24078863 COOLANT (READY MIXED) 5L/GAL YELLOW  85108913",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 12.25,
      "MAINTANING STOCK QTY": 49,
      "AVAILABLE": 1,
      "SHELF LIFE": 0.08163265306122448,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 48,
      "APRD QTY": 48,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 1.09,
      "STD": 16.2603024540577,
      "MEAN": 11.3076923076923,
      "COV": 1.43798593131122,
      "SHELF LIFE_1": 4,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 84168569,
      "DESCRIPTION": "KERB OBSERVATION",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 1,
      "SHELF LIFE": 6.000000000000024,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": "551229Z00B",
      "DESCRIPTION": "SPRING LEAF",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 11689838,
      "DESCRIPTION": "14359170 FRONT WINDOW GLASS",
      "PRODUCT CATEGORY": "YTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "270489Z04A",
      "DESCRIPTION": "PIPING",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4780963,
      "DESCRIPTION": "LINK BEARING 19630",
      "PRODUCT CATEGORY": "VGG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 2,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14594707,
      "DESCRIPTION": "14794925 ECU",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 20459015,
      "DESCRIPTION": "SCREW",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 5,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14558709,
      "DESCRIPTION": "ACCUMULATOR",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 2,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 4110000924023,
      "DESCRIPTION": "GASKET-612600080400",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 9,
      "WHXX": 6,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "440669Z01B",
      "DESCRIPTION": "BRAKE LINING  KIT 440669Z00B ",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 2.41666666666666,
      "MAINTANING STOCK QTY": 10,
      "AVAILABLE": 14,
      "SHELF LIFE": 5.793103448275878,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 41,
      "WHXX": 39,
      "WH REFERENCE": "",
      "TREND": 0.21,
      "STD": 5.40299062485839,
      "MEAN": 2.23076923076923,
      "COV": 2.42203028010893,
      "SHELF LIFE_1": 5.793103448275878,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 22768937,
      "DESCRIPTION": "TAPPET",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 84208821,
      "DESCRIPTION": "POSITION LAMP 82116545",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 5,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 22583048,
      "DESCRIPTION": "GEAR SELECTOR 22230455",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 1,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 17418256,
      "DESCRIPTION": "V RIBBED BELT ",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 1,
      "SHELF LIFE": 12.000000000000005,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 7,
      "WHXX": 7,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 12.000000000000005,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 14675537,
      "DESCRIPTION": "WIPER MOTOR 11205840 ",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "A810201206088",
      "DESCRIPTION": "MOTION CONTROL ASSEMBLY",
      "PRODUCT CATEGORY": "YCG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.25,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 2,
      "SHELF LIFE": 8,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.599144689515278,
      "MEAN": 0.23076923076923,
      "COV": 2.5962936545662,
      "SHELF LIFE_1": 8,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 21162862,
      "DESCRIPTION": "WIRES",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 3,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 954493,
      "DESCRIPTION": "ELECTRIC CABLE",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 4,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 984437,
      "DESCRIPTION": "ELECTRIC CABLE",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 8,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 984438,
      "DESCRIPTION": "ELECTRIC CABLE",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 4,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 60128948,
      "DESCRIPTION": "2/5 VALVE",
      "PRODUCT CATEGORY": "YPG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "4120000090Z",
      "DESCRIPTION": "PNEUMATIC CYLINDER 4120000090",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 10,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 1,
      "STD": 0.375533808099405,
      "MEAN": 0.153846153846153,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 17418268,
      "DESCRIPTION": "V RIBBED BELT  21080993",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.416666666666666,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 1,
      "SHELF LIFE": 2.400000000000004,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 49,
      "WHXX": 13,
      "WH REFERENCE": "WHXX",
      "TREND": 0.8,
      "STD": 0.767947647788304,
      "MEAN": 0.384615384615384,
      "COV": 1.99666388424959,
      "SHELF LIFE_1": 4.800000000000008,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 14652325,
      "DESCRIPTION": "HANDLE 14524738",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 4,
      "WHXX": 6,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "381669Z00C",
      "DESCRIPTION": "FLANGE SCREW",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 29,
      "WHXX": 25,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "333109Z00A",
      "DESCRIPTION": "ORING",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 5,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 17,
      "WHXX": 39,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "328339Z04A",
      "DESCRIPTION": "HEX SCREW",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 4,
      "WHXX": 20,
      "WH REFERENCE": "WHXX",
      "TREND": 0,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 14550595,
      "DESCRIPTION": "IDLER",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "410309Z03D",
      "DESCRIPTION": "5223714923 BRAKE SHOE KIT ",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 5,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14661022,
      "DESCRIPTION": "SWITCH",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 4,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4130001509,
      "DESCRIPTION": "FUEL LEVEL SENSOR",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14592567,
      "DESCRIPTION": "LAMP",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 5,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4120000868101,
      "DESCRIPTION": "SEALING MEMBER AGGREGATE",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 1,
      "WH REFERENCE": "WHXX",
      "TREND": 0,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 4120000867101,
      "DESCRIPTION": "SEALING MEMBER AGGREGATE",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.666666666666666,
      "MAINTANING STOCK QTY": 3,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 3,
      "APRD QTY": 0,
      "WH12": 5,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 0.5,
      "STD": 1.26085034391223,
      "MEAN": 0.615384615384615,
      "COV": 2.04888180885737,
      "SHELF LIFE_1": 4.500000000000004,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 4120001004101,
      "DESCRIPTION": "SEALING MEMBER AGGREGATE",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.666666666666666,
      "MAINTANING STOCK QTY": 3,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 3,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 0.5,
      "STD": 1.26085034391223,
      "MEAN": 0.615384615384615,
      "COV": 2.04888180885737,
      "SHELF LIFE_1": 4.500000000000004,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 4110002115,
      "DESCRIPTION": "CAP",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 8,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14881136,
      "DESCRIPTION": "HOSE",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 1,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 4,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14880494,
      "DESCRIPTION": "CONNECTOR",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 3,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 70932553,
      "DESCRIPTION": "RADIATOR HOSE",
      "PRODUCT CATEGORY": "VCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 3,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 0,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 70932546,
      "DESCRIPTION": "RADIATOR HOSE",
      "PRODUCT CATEGORY": "VCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 3,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 0,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": "155-30-00118P010-01",
      "DESCRIPTION": "TRACK ROLLER ASSY, DF-155-30-00114",
      "PRODUCT CATEGORY": "SDG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 1,
      "MAINTANING STOCK QTY": 4,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 4,
      "APRD QTY": 4,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 2,
      "STD": 3.32820117735137,
      "MEAN": 0.923076923076923,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 4,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": "155-30-00128P010-01",
      "DESCRIPTION": "TRACK ROLLER ASSY, SF-155-30-00128P010-01",
      "PRODUCT CATEGORY": "SDG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 3.33333333333333,
      "MAINTANING STOCK QTY": 13,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 13,
      "APRD QTY": 13,
      "WH12": 9,
      "WHXX": 0,
      "WH REFERENCE": "PARTIAL WH12",
      "TREND": 1.5,
      "STD": 8.5485041426511,
      "MEAN": 3.07692307692307,
      "COV": 2.7782638463616,
      "SHELF LIFE_1": 3.900000000000004,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": "01010-52275",
      "DESCRIPTION": "BOLT 01010-52275",
      "PRODUCT CATEGORY": "SDG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 23.6666666666666,
      "MAINTANING STOCK QTY": 95,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 95,
      "APRD QTY": 95,
      "WH12": 34,
      "WHXX": 0,
      "WH REFERENCE": "PARTIAL WH12",
      "TREND": 1.61,
      "STD": 63.8459684890729,
      "MEAN": 21.8461538461538,
      "COV": 2.92252672661249,
      "SHELF LIFE_1": 4.014084507042265,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": "01643-32260",
      "DESCRIPTION": "WASHER, TRACK ROLLER",
      "PRODUCT CATEGORY": "SDG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 26.1666666666666,
      "MAINTANING STOCK QTY": 105,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 105,
      "APRD QTY": 105,
      "WH12": 30,
      "WHXX": 0,
      "WH REFERENCE": "PARTIAL WH12",
      "TREND": 1.64,
      "STD": 71.9465613654168,
      "MEAN": 24.1538461538461,
      "COV": 2.97867929219878,
      "SHELF LIFE_1": 4.012738853503195,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": "8216-MD-00062",
      "DESCRIPTION": "TRACK SHOE BOLT W/ NUT",
      "PRODUCT CATEGORY": "SDG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 6.66666666666666,
      "MAINTANING STOCK QTY": 27,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 27,
      "APRD QTY": 0,
      "WH12": 60,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 0,
      "STD": 22.1880078490091,
      "MEAN": 6.15384615384615,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 4.050000000000004,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": "8220-02230",
      "DESCRIPTION": "RING_RETAINER",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 6,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 11705232,
      "DESCRIPTION": "BALL",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 12,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 2,
      "STD": 1.10940039245045,
      "MEAN": 0.307692307692307,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 3.000000000000003,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 11705233,
      "DESCRIPTION": "SPRING",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.25,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 9,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 2,
      "STD": 0.832050294337843,
      "MEAN": 0.23076923076923,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 4,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 11705239,
      "DESCRIPTION": "BALL BEARING",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 4,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14558056,
      "DESCRIPTION": "ROLLER BEARING",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 3,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 4120005062,
      "DESCRIPTION": "BREATHER CAP",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 2,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14591829,
      "DESCRIPTION": "FUEL FILLING PUMP",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 1,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 2,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14592562,
      "DESCRIPTION": "TAIL LAMP",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 2,
      "WHXX": 6,
      "WH REFERENCE": "WHXX",
      "TREND": 2,
      "STD": 0.375533808099405,
      "MEAN": 0.153846153846153,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 20459236,
      "DESCRIPTION": "GASKET",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 4,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 20412318,
      "DESCRIPTION": "STUD",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 38,
      "WHXX": 61,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 20405590,
      "DESCRIPTION": "GASKET",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 4,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 20459171,
      "DESCRIPTION": "NIPPLE",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 7,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4110001031037,
      "DESCRIPTION": "BELT 13033963",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 1,
      "SHELF LIFE": 6.000000000000024,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 31,
      "WHXX": 9,
      "WH REFERENCE": "",
      "TREND": 1,
      "STD": 0.375533808099405,
      "MEAN": 0.153846153846153,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 14686054,
      "DESCRIPTION": "HOSE ",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 3,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4190000020,
      "DESCRIPTION": "CLIP LG13-ZS4*250",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 46,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4110001921005,
      "DESCRIPTION": "FILTER INSERT",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 4,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 174,
      "WHXX": 16,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4120004982,
      "DESCRIPTION": "FILTER",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 2,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 106,
      "WHXX": 20,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 15035179,
      "DESCRIPTION": "FILTER, PILOT",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "FAST (F1)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 9.25,
      "MAINTANING STOCK QTY": 37,
      "AVAILABLE": 9,
      "SHELF LIFE": 0.972972972972973,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 28,
      "APRD QTY": 28,
      "WH12": 52,
      "WHXX": 63,
      "WH REFERENCE": "WHXX",
      "TREND": 0.99,
      "STD": 5.93879034562012,
      "MEAN": 8.53846153846153,
      "COV": 0.695534004441996,
      "SHELF LIFE_1": 4,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 46625802,
      "DESCRIPTION": "AIR FILTER",
      "PRODUCT CATEGORY": "DOP",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 29170010601,
      "DESCRIPTION": "WEAR PLATE",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 22,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 29170010611,
      "DESCRIPTION": "WEAR PLATE",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 12,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4040000007,
      "DESCRIPTION": "BOLT LGB002-M16*70",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 1.16666666666666,
      "MAINTANING STOCK QTY": 5,
      "AVAILABLE": 5,
      "SHELF LIFE": 4.28571428571431,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 3.8829013735766,
      "MEAN": 1.07692307692307,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 4.28571428571431,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 22327063,
      "DESCRIPTION": "SOLENOID VALVE",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 6,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "302009Z01E",
      "DESCRIPTION": "CLUTCH",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 2,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "A810299000577",
      "DESCRIPTION": "SANY LCD DISPLAY SCREEN ASSY",
      "PRODUCT CATEGORY": "YPG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 6,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 9060100000,
      "DESCRIPTION": "ECU REMAN",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "AVERAGE (F2)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 1.91666666666666,
      "MAINTANING STOCK QTY": 8,
      "AVAILABLE": 1,
      "SHELF LIFE": 0.5217391304347844,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 7,
      "APRD QTY": 0,
      "WH12": 14,
      "WHXX": 8,
      "WH REFERENCE": "WHXX",
      "TREND": 0.78,
      "STD": 1.42325016270542,
      "MEAN": 1.76923076923076,
      "COV": 0.80444574413785,
      "SHELF LIFE_1": 4.173913043478275,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 991084,
      "DESCRIPTION": "SWIVEL",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 4,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14658433,
      "DESCRIPTION": "PIN",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 6,
      "WHXX": 26,
      "WH REFERENCE": "WHXX",
      "TREND": 1,
      "STD": 0.480384461415261,
      "MEAN": 0.307692307692307,
      "COV": 1.56124949959959,
      "SHELF LIFE_1": 3.000000000000003,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 4110001905060,
      "DESCRIPTION": "GASKET",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 2,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4110001394104,
      "DESCRIPTION": "O-RING",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 2,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 3050900049,
      "DESCRIPTION": "OIL SEAL COVER",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 5,
      "WHXX": 3,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14701884,
      "DESCRIPTION": "DOOR 14634563",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 29070012891,
      "DESCRIPTION": "GEAR RING",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 3,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 29070015591,
      "DESCRIPTION": "ROUND NUT",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3,
      "WHXX": 1,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4014000033,
      "DESCRIPTION": "SCREW",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 13,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 29070012671,
      "DESCRIPTION": "SUN GEAR",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 24,
      "WHXX": 3,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 29070012661,
      "DESCRIPTION": "PLANET CARRIER",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 29070012681,
      "DESCRIPTION": "SHIM",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 1.16666666666666,
      "MAINTANING STOCK QTY": 5,
      "AVAILABLE": 4,
      "SHELF LIFE": 3.428571428571448,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 0,
      "WHXX": 9,
      "WH REFERENCE": "WHXX",
      "TREND": 1.14,
      "STD": 2.6602486870447,
      "MEAN": 1.07692307692307,
      "COV": 2.47023092368437,
      "SHELF LIFE_1": 4.28571428571431,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 29070012691,
      "DESCRIPTION": "PLANET GEAR SHAFT",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.583333333333333,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 2,
      "APRD QTY": 2,
      "WH12": 0,
      "WHXX": 26,
      "WH REFERENCE": "WHXX",
      "TREND": 1.14,
      "STD": 1.33012434352235,
      "MEAN": 0.538461538461538,
      "COV": 2.47023092368437,
      "SHELF LIFE_1": 3.42857142857143,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 29070012711,
      "DESCRIPTION": "PLANET GEAR",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.583333333333333,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 2,
      "APRD QTY": 2,
      "WH12": 11,
      "WHXX": 64,
      "WH REFERENCE": "WHXX",
      "TREND": 1.14,
      "STD": 1.33012434352235,
      "MEAN": 0.538461538461538,
      "COV": 2.47023092368437,
      "SHELF LIFE_1": 3.42857142857143,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 4090000158,
      "DESCRIPTION": "NEEDLE ROLLER",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 15.75,
      "MAINTANING STOCK QTY": 63,
      "AVAILABLE": 54,
      "SHELF LIFE": 3.4285714285714284,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 9,
      "APRD QTY": 9,
      "WH12": 26,
      "WHXX": 2183,
      "WH REFERENCE": "WHXX",
      "TREND": 1.14,
      "STD": 35.9133572751035,
      "MEAN": 14.5384615384615,
      "COV": 2.47023092368436,
      "SHELF LIFE_1": 4,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 29070012701,
      "DESCRIPTION": "RETAINING RING",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 1.16666666666666,
      "MAINTANING STOCK QTY": 5,
      "AVAILABLE": 4,
      "SHELF LIFE": 3.428571428571448,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 48,
      "WHXX": 12,
      "WH REFERENCE": "WHXX",
      "TREND": 1.14,
      "STD": 2.6602486870447,
      "MEAN": 1.07692307692307,
      "COV": 2.47023092368437,
      "SHELF LIFE_1": 4.28571428571431,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 183680,
      "DESCRIPTION": "SPHERICAL PLAIN BEARING 14013647 19631",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 5,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 12809750,
      "DESCRIPTION": "STRAP ",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 10,
      "WHXX": 3,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 28140009601,
      "DESCRIPTION": "CUTTING EDGE",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 2,
      "SHELF LIFE": 12.000000000000048,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 12.000000000000048,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 28140009611,
      "DESCRIPTION": "SCREW",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 1.66666666666666,
      "MAINTANING STOCK QTY": 7,
      "AVAILABLE": 15,
      "SHELF LIFE": 9.000000000000036,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 58,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 5.54700196225229,
      "MEAN": 1.53846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 9.000000000000036,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 28140010441,
      "DESCRIPTION": "CUTTING EDGE",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 1,
      "SHELF LIFE": 12.000000000000005,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 16,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 12.000000000000005,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 4130001966,
      "DESCRIPTION": "STARTER MOTOR",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4130001973,
      "DESCRIPTION": "ALTERNATOR",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4110002321001,
      "DESCRIPTION": "AIR FILTER INSERT",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.75,
      "MAINTANING STOCK QTY": 3,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 3,
      "APRD QTY": 3,
      "WH12": 9,
      "WHXX": 9,
      "WH REFERENCE": "WHXX",
      "TREND": 1.33,
      "STD": 1.03155347127648,
      "MEAN": 0.692307692307692,
      "COV": 1.49002168073269,
      "SHELF LIFE_1": 4,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 4110002373009,
      "DESCRIPTION": "BELT",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 16,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4190001830,
      "DESCRIPTION": "BUCKET TOOTH",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.916666666666666,
      "MAINTANING STOCK QTY": 4,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 4,
      "APRD QTY": 0,
      "WH12": 40,
      "WHXX": 10,
      "WH REFERENCE": "WHXX",
      "TREND": 0.91,
      "STD": 2.07549808665108,
      "MEAN": 0.846153846153846,
      "COV": 2.45286137513309,
      "SHELF LIFE_1": 4.363636363636367,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 26180007661,
      "DESCRIPTION": "RETAINER KIT 26180000191 26180000201",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.916666666666666,
      "MAINTANING STOCK QTY": 4,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 4,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 0.91,
      "STD": 2.07549808665108,
      "MEAN": 0.846153846153846,
      "COV": 2.45286137513309,
      "SHELF LIFE_1": 4.363636363636367,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 21092586,
      "DESCRIPTION": "TURBOCHARGER",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 8,
      "WHXX": 9,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14576499,
      "DESCRIPTION": "BRACKET",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 8,
      "WHXX": 7,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14626783,
      "DESCRIPTION": "14759247 ODOMETER",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 16271772,
      "DESCRIPTION": "VALVE 15075800",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 1,
      "SHELF LIFE": 6.000000000000024,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 4,
      "WH REFERENCE": "",
      "TREND": 1,
      "STD": 0.375533808099405,
      "MEAN": 0.153846153846153,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 29070012481,
      "DESCRIPTION": "PLUG",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 9,
      "WHXX": 7,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "684609Z00B",
      "DESCRIPTION": "LOCK HOUSING",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 27,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 96702279,
      "DESCRIPTION": "HEXAGON SCREW 96718838",
      "PRODUCT CATEGORY": "VCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 4,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4110000846039,
      "DESCRIPTION": "4110000054324 THRUST WASHER",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4110000846017,
      "DESCRIPTION": "4110000054324  THRUST WASHER",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4110000054124,
      "DESCRIPTION": "BUSHING",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 5,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4110000054235,
      "DESCRIPTION": "GASKET",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 7,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4110000054083,
      "DESCRIPTION": "OIL PUMP",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 60090788,
      "DESCRIPTION": "BUSHING",
      "PRODUCT CATEGORY": "YPG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 10025037,
      "DESCRIPTION": "PINSLEEVESYM5550J.4.3.4-1",
      "PRODUCT CATEGORY": "YPG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 10024462,
      "DESCRIPTION": "SHAFTGROOVE",
      "PRODUCT CATEGORY": "YPG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "726119Z00B",
      "DESCRIPTION": "WEATHERSTRIP",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "684609Z00A",
      "DESCRIPTION": "LOCK HOUSING",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 27,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14560241,
      "DESCRIPTION": "SPIDER",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 0,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 17412747,
      "DESCRIPTION": "SEALING RING",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 6,
      "WHXX": 21,
      "WH REFERENCE": "WHXX",
      "TREND": 0.5,
      "STD": 0.630425171956115,
      "MEAN": 0.307692307692307,
      "COV": 2.04888180885737,
      "SHELF LIFE_1": 3.000000000000003,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 21312943,
      "DESCRIPTION": "CABLE",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 4,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 12793261,
      "DESCRIPTION": "CABLE HARNESS",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4110002278004,
      "DESCRIPTION": "CUP",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 1,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3,
      "WHXX": 2,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4110001015014,
      "DESCRIPTION": "THERMOSWITCH",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 1,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4030000177,
      "DESCRIPTION": "O-RING",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 12,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4110000172007,
      "DESCRIPTION": "BRACKET",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 1,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 21396128,
      "DESCRIPTION": "SENSOR 20520111",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 8,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 17,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 21893128,
      "DESCRIPTION": "SOLENOID VALVE ",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 5,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 0,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": "P14691098",
      "DESCRIPTION": "ENDURANCE BUCKET 0.92M3 EC210B",
      "PRODUCT CATEGORY": "VOA",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 5222807993,
      "DESCRIPTION": "OIL LEVEL AND TEMPERATURE SENSOR",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "150169Z00A",
      "DESCRIPTION": "GASKET",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 1,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4120004037001,
      "DESCRIPTION": "FILTER INSERT",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "AVERAGE (F2)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 1.16666666666666,
      "MAINTANING STOCK QTY": 5,
      "AVAILABLE": 12,
      "SHELF LIFE": 10.285714285714343,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 23,
      "WHXX": 13,
      "WH REFERENCE": "",
      "TREND": 1.43,
      "STD": 1.32045058354704,
      "MEAN": 1.07692307692307,
      "COV": 1.22613268472226,
      "SHELF LIFE_1": 10.285714285714343,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 29070015101,
      "DESCRIPTION": "SEALING COVER",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 29070015111,
      "DESCRIPTION": "DUST COVER",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 13,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "412339Z00A",
      "DESCRIPTION": "PAWL- ADAPTED QTY 5",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 13,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "464139Z00A",
      "DESCRIPTION": "PIN ADAPTED QTY 5 ",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 19,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "601079Z00A",
      "DESCRIPTION": "SPACER",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 35,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "552299Z00A",
      "DESCRIPTION": "FLANGE NUT- ADAPTED QTY 10",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 33,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "601389Z00A",
      "DESCRIPTION": "SPRING PIN- ADAPTED QTY 10",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 14,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "612079Z00B",
      "DESCRIPTION": "HEXAGON SCREW- ADAPTED QTY 10",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 24,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "552299Z00B",
      "DESCRIPTION": "FLANGE NUT ADAPTED QTY 10",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 32,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 996792,
      "DESCRIPTION": "V-RIBBED BELT, FAN/ALT BL71 20804680",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 2,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 4,
      "WHXX": 5,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4110001007001,
      "DESCRIPTION": "SENSOR",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 17408222,
      "DESCRIPTION": "EXPANSION TANK",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 1,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 4,
      "WHXX": 1,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "440669Z01C",
      "DESCRIPTION": "RIVET STUD ADAPTED QTY 56",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 9.33333333333333,
      "MAINTANING STOCK QTY": 37,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 37,
      "APRD QTY": 0,
      "WH12": 148,
      "WHXX": 1982,
      "WH REFERENCE": "WHXX",
      "TREND": 1,
      "STD": 21.0298932535667,
      "MEAN": 8.61538461538461,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 3.9642857142857157,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": "475119Z00B",
      "DESCRIPTION": "HEXAGON SCREW",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 33,
      "WHXX": 44,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14530463,
      "DESCRIPTION": "BRAKE DISC",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 2.5,
      "MAINTANING STOCK QTY": 10,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 10,
      "APRD QTY": 0,
      "WH12": 4,
      "WHXX": 7,
      "WH REFERENCE": "WH12 + WHXX",
      "TREND": 1,
      "STD": 3.88125012903092,
      "MEAN": 2.3076923076923,
      "COV": 1.6818750559134,
      "SHELF LIFE_1": 4,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": "7114-12630",
      "DESCRIPTION": "PLATE_BRAKE",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 11,
      "WHXX": 26,
      "WH REFERENCE": "WHXX",
      "TREND": 2,
      "STD": 1.10940039245045,
      "MEAN": 0.307692307692307,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 3.000000000000003,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 29370019441,
      "DESCRIPTION": "DISPLAY PANEL",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 17224267,
      "DESCRIPTION": "FUEL FILTER 43977982",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.25,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 14,
      "WHXX": 10,
      "WH REFERENCE": "WHXX",
      "TREND": 0,
      "STD": 0.832050294337843,
      "MEAN": 0.23076923076923,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 4,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": "7114-12680",
      "DESCRIPTION": "RING",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.5,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 2,
      "APRD QTY": 0,
      "WH12": 3,
      "WHXX": 12,
      "WH REFERENCE": "WHXX",
      "TREND": 1,
      "STD": 0.776250025806184,
      "MEAN": 0.461538461538461,
      "COV": 1.6818750559134,
      "SHELF LIFE_1": 4,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 14530477,
      "DESCRIPTION": "PLATE",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 24,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 992516,
      "DESCRIPTION": "BULB ADAPTED QTY 10",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 1.33333333333333,
      "MAINTANING STOCK QTY": 5,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 5,
      "APRD QTY": 0,
      "WH12": 37,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 0.63,
      "STD": 2.55453342621329,
      "MEAN": 1.23076923076923,
      "COV": 2.0755584087983,
      "SHELF LIFE_1": 3.7500000000000098,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 82494763,
      "DESCRIPTION": "MODULE",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 1,
      "MAINTANING STOCK QTY": 4,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 4,
      "APRD QTY": 4,
      "WH12": 10,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 1.33,
      "STD": 2.39657875806111,
      "MEAN": 0.923076923076923,
      "COV": 2.5962936545662,
      "SHELF LIFE_1": 4,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 4110000970109,
      "DESCRIPTION": "4110004072003 WATER PUMP 4110001009027",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "272139Z00A",
      "DESCRIPTION": "DESICCANT ADAPTED QTY 5",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 11,
      "SHELF LIFE": 66.00000000000027,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 48,
      "WHXX": 2,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.375533808099405,
      "MEAN": 0.153846153846153,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 66.00000000000027,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": "250389Z01D",
      "DESCRIPTION": "O RING ADAPTED QTY 10",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 15,
      "SHELF LIFE": 90.00000000000037,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 54,
      "WHXX": 53,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 90.00000000000037,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 4110000988167,
      "DESCRIPTION": "STARTER MOTOR 4110000988095",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 21134532,
      "DESCRIPTION": "RETURN LINE",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14880431,
      "DESCRIPTION": "964832 CLEVIS PIN",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 2,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "7114-17250",
      "DESCRIPTION": "ROLLER BEARING_",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 5,
      "WHXX": 4,
      "WH REFERENCE": "WHXX",
      "TREND": 0,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": "9541-01062",
      "DESCRIPTION": "RETAINING RING",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 4,
      "WHXX": 4,
      "WH REFERENCE": "WHXX",
      "TREND": 0,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 14646872,
      "DESCRIPTION": "FILTER ELEMENT EW145B S/N: 19764-",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "AVERAGE (F2)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 2.33333333333333,
      "MAINTANING STOCK QTY": 9,
      "AVAILABLE": 1,
      "SHELF LIFE": 0.4285714285714292,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 8,
      "APRD QTY": 8,
      "WH12": 1,
      "WHXX": 41,
      "WH REFERENCE": "WHXX",
      "TREND": 1.21,
      "STD": 1.8639632438326,
      "MEAN": 2.15384615384615,
      "COV": 0.865411506065139,
      "SHELF LIFE_1": 3.857142857142863,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 15427060,
      "DESCRIPTION": "BEARING SPINDLE",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14680534,
      "DESCRIPTION": "TOOTH  (ET)  EC140B/EW145B GP 14527863",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 3.75,
      "MAINTANING STOCK QTY": 15,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 15,
      "APRD QTY": 0,
      "WH12": 148,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 0.89,
      "STD": 4.7366546671567,
      "MEAN": 3.46153846153846,
      "COV": 1.36836690384527,
      "SHELF LIFE_1": 4,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 14680532,
      "DESCRIPTION": "TOOTH (ET)  EC210B/D EC220D  GP ",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "AVERAGE (F2)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 8.16666666666666,
      "MAINTANING STOCK QTY": 33,
      "AVAILABLE": 3,
      "SHELF LIFE": 0.36734693877551045,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 30,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 12,
      "WH REFERENCE": "PARTIAL WHXX",
      "TREND": 0.71,
      "STD": 6.74061550868297,
      "MEAN": 7.53846153846153,
      "COV": 0.894163281764068,
      "SHELF LIFE_1": 4.040816326530615,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 14680533,
      "DESCRIPTION": "TOOTH  (ET)  EC240B/EC250D EC290B/EC300D  GP",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 1.66666666666666,
      "MAINTANING STOCK QTY": 7,
      "AVAILABLE": 2,
      "SHELF LIFE": 1.2000000000000048,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 5,
      "APRD QTY": 5,
      "WH12": 0,
      "WHXX": 2,
      "WH REFERENCE": "PARTIAL WHXX",
      "TREND": 2,
      "STD": 3.77746353645283,
      "MEAN": 1.53846153846153,
      "COV": 2.45535129869434,
      "SHELF LIFE_1": 4.200000000000016,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 29090008771,
      "DESCRIPTION": "PLANET CARRIER",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 29070013111,
      "DESCRIPTION": "GEAR RING",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 2,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "9453-03615",
      "DESCRIPTION": "HOSE ASSEMBLY",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 5,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 29110000042,
      "DESCRIPTION": "29110000043 GASKET",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 16,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 969373,
      "DESCRIPTION": "60110101 SIX POINT SOCKET SCREW",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 4,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "7114-12540",
      "DESCRIPTION": "SPRING",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.25,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 6,
      "WHXX": 10,
      "WH REFERENCE": "WHXX",
      "TREND": 2,
      "STD": 0.832050294337843,
      "MEAN": 0.23076923076923,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 4,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 12813371,
      "DESCRIPTION": "EXPANSION TANK 12780561 12808802",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "7114-14370",
      "DESCRIPTION": "SPACER",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 4,
      "WHXX": 6,
      "WH REFERENCE": "WHXX",
      "TREND": 0,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 4014000118,
      "DESCRIPTION": "SCREW",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 6,
      "WHXX": 1,
      "WH REFERENCE": "WHXX",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 4021000044,
      "DESCRIPTION": "ROLLER BEARING",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 14,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4021000045,
      "DESCRIPTION": "ROLLER BEARING",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 8,
      "WHXX": 1,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 5222677134,
      "DESCRIPTION": "FUEL FILTER 166959Z01C",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "AVERAGE (F2)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 2.83333333333333,
      "MAINTANING STOCK QTY": 11,
      "AVAILABLE": 5,
      "SHELF LIFE": 1.7647058823529433,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 6,
      "APRD QTY": 6,
      "WH12": 23,
      "WHXX": 20,
      "WH REFERENCE": "WHXX",
      "TREND": 0.47,
      "STD": 3.09673111356856,
      "MEAN": 2.61538461538461,
      "COV": 1.18404424930562,
      "SHELF LIFE_1": 3.8823529411764754,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 4110000909011,
      "DESCRIPTION": "SOLENOID",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 5,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 1.5,
      "STD": 0.480384461415261,
      "MEAN": 0.307692307692307,
      "COV": 1.56124949959959,
      "SHELF LIFE_1": 3.000000000000003,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 4030001002,
      "DESCRIPTION": "SEALING RING",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.666666666666666,
      "MAINTANING STOCK QTY": 3,
      "AVAILABLE": 3,
      "SHELF LIFE": 4.500000000000004,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 6,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 2.21880078490091,
      "MEAN": 0.615384615384615,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 4.500000000000004,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 5282682780,
      "DESCRIPTION": "CAB CELULOSE FILTER",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "AVERAGE (F2)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 4.5,
      "MAINTANING STOCK QTY": 18,
      "AVAILABLE": 7,
      "SHELF LIFE": 1.5555555555555556,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 11,
      "APRD QTY": 11,
      "WH12": 2,
      "WHXX": 55,
      "WH REFERENCE": "WHXX",
      "TREND": 0.74,
      "STD": 4.99743523963632,
      "MEAN": 4.15384615384615,
      "COV": 1.20308626139393,
      "SHELF LIFE_1": 4,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": "322869Z01A",
      "DESCRIPTION": "SPACER",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "324979Z00B",
      "DESCRIPTION": "GASKET GKE370",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 14,
      "WHXX": 10,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 11305113,
      "DESCRIPTION": "HORN",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14679709,
      "DESCRIPTION": "GLASS",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 4,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "164009Z01C",
      "DESCRIPTION": "FUEL FILTER",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14696077,
      "DESCRIPTION": "RELAY 1021-00910",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 4,
      "WHXX": 2,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "150-30-13430",
      "DESCRIPTION": "RING",
      "PRODUCT CATEGORY": "SDG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 1,
      "MAINTANING STOCK QTY": 4,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 4,
      "APRD QTY": 4,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 1.33,
      "STD": 2.39657875806111,
      "MEAN": 0.923076923076923,
      "COV": 2.5962936545662,
      "SHELF LIFE_1": 4,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 5222888240,
      "DESCRIPTION": "SENSOR 327059Z00D",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 6,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14680749,
      "DESCRIPTION": "SHAFT 14609430 ",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.416666666666666,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 1,
      "SHELF LIFE": 2.400000000000004,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 1.2,
      "STD": 0.960768922830522,
      "MEAN": 0.384615384615384,
      "COV": 2.49799919935935,
      "SHELF LIFE_1": 4.800000000000008,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 6727475,
      "DESCRIPTION": "CAP, OIL HYD",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "AVERAGE (F2)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 1.16666666666666,
      "MAINTANING STOCK QTY": 5,
      "AVAILABLE": 7,
      "SHELF LIFE": 6.000000000000034,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 44,
      "WHXX": 3,
      "WH REFERENCE": "",
      "TREND": 0.71,
      "STD": 1.44115338424578,
      "MEAN": 1.07692307692307,
      "COV": 1.33821385679965,
      "SHELF LIFE_1": 6.000000000000034,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 6674316,
      "DESCRIPTION": "SWITCH, PRESS OIL HYD",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 6,
      "WHXX": 1,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 7008814,
      "DESCRIPTION": "BULB 37.5W",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 30,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 7251340,
      "DESCRIPTION": "ASSY LIGHT HEAD RH",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 5,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 7251341,
      "DESCRIPTION": "ASSY LIGHT HEAD LH",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 9,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 6675336,
      "DESCRIPTION": "LIGHT BULB 50W",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 32,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 6654806,
      "DESCRIPTION": "FUSE 15A",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 1,
      "SHELF LIFE": 6.000000000000024,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 31,
      "WHXX": 2,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 6702444,
      "DESCRIPTION": "FUSE 25A5",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.416666666666666,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 2,
      "SHELF LIFE": 4.800000000000008,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 36,
      "WHXX": 4,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 1.38675049056307,
      "MEAN": 0.384615384615384,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 4.800000000000008,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 6718034,
      "DESCRIPTION": "FUSE 30A",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 1,
      "SHELF LIFE": 6.000000000000024,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 17,
      "WHXX": 1,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 6679820,
      "DESCRIPTION": "SWITCH RELAY MAGNETIC",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 47,
      "WHXX": 6,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 6680418,
      "DESCRIPTION": "ASSY HANDLE",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 6680419,
      "DESCRIPTION": "LH JOYSTICK HANDLE-MANUAL CONTROL",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 15164333,
      "DESCRIPTION": "LOCK CYLINDER",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 1,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 6685190,
      "DESCRIPTION": "STARTER",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 8,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 6678205,
      "DESCRIPTION": "ALTERNATOR 90 AMP",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 1,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 7193354,
      "DESCRIPTION": "FILTER AIR RECIRCULATING",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 6,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 7176099,
      "DESCRIPTION": "FILTER AIR FRESH 10",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "AVERAGE (F2)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.666666666666666,
      "MAINTANING STOCK QTY": 3,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 3,
      "APRD QTY": 3,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 1.25,
      "STD": 1.04390784542678,
      "MEAN": 0.615384615384615,
      "COV": 1.69635024881852,
      "SHELF LIFE_1": 4.500000000000004,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 7012314,
      "DESCRIPTION": "FILTER OIL HYD",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 31,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 7188792,
      "DESCRIPTION": "BELT DRIVE",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 2,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 6668819,
      "DESCRIPTION": "FILTER OIL HYD",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 22,
      "WHXX": 1,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 6661248,
      "DESCRIPTION": "FILTER OIL HYD 12",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 16,
      "WHXX": 4,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 6662855,
      "DESCRIPTION": "BELT DRIVE",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 1,
      "SHELF LIFE": 6.000000000000024,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.375533808099405,
      "MEAN": 0.153846153846153,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 7146391,
      "DESCRIPTION": "BELT, DRIVE  S510/S570",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "AVERAGE (F2)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 1.33333333333333,
      "MAINTANING STOCK QTY": 5,
      "AVAILABLE": 6,
      "SHELF LIFE": 4.5000000000000115,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 106,
      "WHXX": 5,
      "WH REFERENCE": "",
      "TREND": 0.63,
      "STD": 1.58922654950168,
      "MEAN": 1.23076923076923,
      "COV": 1.29124657147011,
      "SHELF LIFE_1": 4.5000000000000115,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 7143498,
      "DESCRIPTION": "BELT ALTERNATOR ",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 1,
      "SHELF LIFE": 3.000000000000003,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 45,
      "WHXX": 2,
      "WH REFERENCE": "",
      "TREND": 0.5,
      "STD": 0.480384461415261,
      "MEAN": 0.307692307692307,
      "COV": 1.56124949959959,
      "SHELF LIFE_1": 3.000000000000003,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 7100104,
      "DESCRIPTION": "BELT ALT",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 15,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 6667352,
      "DESCRIPTION": "FILTER, FUEL W/SEPARATOR 12",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "AVERAGE (F2)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 2.58333333333333,
      "MAINTANING STOCK QTY": 10,
      "AVAILABLE": 14,
      "SHELF LIFE": 5.419354838709684,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 218,
      "WHXX": 7,
      "WH REFERENCE": "",
      "TREND": 0.39,
      "STD": 3.73136394942612,
      "MEAN": 2.38461538461538,
      "COV": 1.56476552717869,
      "SHELF LIFE_1": 5.419354838709684,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 6666334,
      "DESCRIPTION": "FILTER AIR INNER",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 9,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 6675517,
      "DESCRIPTION": "FILTER OIL ENG 12",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "AVERAGE (F2)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 1.75,
      "MAINTANING STOCK QTY": 7,
      "AVAILABLE": 8,
      "SHELF LIFE": 4.571428571428571,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 155,
      "WHXX": 6,
      "WH REFERENCE": "",
      "TREND": 0.57,
      "STD": 1.50213523239762,
      "MEAN": 1.61538461538461,
      "COV": 0.929893239103289,
      "SHELF LIFE_1": 4.571428571428571,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 6666375,
      "DESCRIPTION": "FILTER AIR OUTER",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.583333333333333,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 9,
      "SHELF LIFE": 15.428571428571436,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 69,
      "WHXX": 3,
      "WH REFERENCE": "",
      "TREND": 0.86,
      "STD": 0.877058019307029,
      "MEAN": 0.538461538461538,
      "COV": 1.62882203585591,
      "SHELF LIFE_1": 15.428571428571436,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 6666376,
      "DESCRIPTION": "FILTER AIR INNER",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.416666666666666,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 5,
      "SHELF LIFE": 12.000000000000018,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 26,
      "WHXX": 9,
      "WH REFERENCE": "",
      "TREND": 0.8,
      "STD": 0.869718492622904,
      "MEAN": 0.384615384615384,
      "COV": 2.26126808081955,
      "SHELF LIFE_1": 12.000000000000018,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 6692337,
      "DESCRIPTION": "FILTER HYD CARTRIDGE (CANISTER TYPE)",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.416666666666666,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 5,
      "SHELF LIFE": 12.000000000000018,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 32,
      "WHXX": 7,
      "WH REFERENCE": "",
      "TREND": 0.4,
      "STD": 0.65044363558799,
      "MEAN": 0.384615384615384,
      "COV": 1.69115345252877,
      "SHELF LIFE_1": 12.000000000000018,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 7008043,
      "DESCRIPTION": "FILTER AIR OUTER",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 8,
      "WHXX": 4,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 7008044,
      "DESCRIPTION": "FILTER AIR INNER",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 4,
      "WHXX": 2,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 898731,
      "DESCRIPTION": "1156 BULB",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 23,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 7199903,
      "DESCRIPTION": "KIT, SEAL",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 1,
      "SHELF LIFE": 3.000000000000003,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 8,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 1,
      "STD": 0.75106761619881,
      "MEAN": 0.307692307692307,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 3.000000000000003,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 7135489,
      "DESCRIPTION": "KIT, SEAL",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 1,
      "SHELF LIFE": 3.000000000000003,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 39,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 1.5,
      "STD": 0.630425171956115,
      "MEAN": 0.307692307692307,
      "COV": 2.04888180885737,
      "SHELF LIFE_1": 3.000000000000003,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 7246802,
      "DESCRIPTION": "COUPLER FF FEMALE",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 6666896,
      "DESCRIPTION": "AUGER ASSY 24 RD",
      "PRODUCT CATEGORY": "DBG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 5222416915,
      "DESCRIPTION": "LID",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 5,
      "WHXX": 10,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 6657635,
      "DESCRIPTION": "OIL FILTER",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 11,
      "WHXX": 1,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 6672467,
      "DESCRIPTION": "AIR CLEANER OUTER",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 29,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 6672468,
      "DESCRIPTION": "AIR CLEANER INNER",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 26,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 6652802,
      "DESCRIPTION": "3/8\" BRUSH BRISTLE",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 14,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1076,
      "WHXX": 40,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14590799,
      "DESCRIPTION": "MIRROR",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 4,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4030000233,
      "DESCRIPTION": "O-RING",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.5,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 2,
      "APRD QTY": 2,
      "WH12": 16,
      "WHXX": 13,
      "WH REFERENCE": "WHXX",
      "TREND": 1.33,
      "STD": 0.877058019307029,
      "MEAN": 0.461538461538461,
      "COV": 1.90029237516522,
      "SHELF LIFE_1": 4,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 4130001300,
      "DESCRIPTION": "PRESSURE SWITCH",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 3,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4110000988017,
      "DESCRIPTION": "SEALING RING",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 38,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 2,
      "STD": 1.10940039245045,
      "MEAN": 0.307692307692307,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 3.000000000000003,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": "7114-14220",
      "DESCRIPTION": "BALL BEARING",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 8,
      "WH REFERENCE": "WHXX",
      "TREND": 0,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 14566886,
      "DESCRIPTION": "CABLE HARNESS",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4110001009070,
      "DESCRIPTION": "SENSOR 4110001007010",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 8,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 7219324,
      "DESCRIPTION": "RELIEF VALVE",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 6718414,
      "DESCRIPTION": "SENDER ENGINE TEMP",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 8,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4110001009037,
      "DESCRIPTION": "ALTERNATOR 4130001901 ",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 1,
      "STD": 0.375533808099405,
      "MEAN": 0.153846153846153,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 17431131,
      "DESCRIPTION": "PIN",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 2,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 992453,
      "DESCRIPTION": "FUSE",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 1.16666666666666,
      "MAINTANING STOCK QTY": 5,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 5,
      "APRD QTY": 5,
      "WH12": 21,
      "WHXX": 3,
      "WH REFERENCE": "WH12 + WHXX",
      "TREND": 2,
      "STD": 3.8829013735766,
      "MEAN": 1.07692307692307,
      "COV": 3.60555127546399,
      "SHELF LIFE_1": 4.28571428571431,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 4110000054316,
      "DESCRIPTION": "TURBOCHARGER",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "320139Z00B",
      "DESCRIPTION": "O-RING",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 5,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 28,
      "WHXX": 29,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "265619Z00D",
      "DESCRIPTION": "5222884562 BRACKET",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "261029Z00D",
      "DESCRIPTION": "RETAINER",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 7,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 15424353,
      "DESCRIPTION": "24040493 STARTER MOTOR  20459062 22851167 ",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 6651512,
      "DESCRIPTION": "BACK-UP ALARM",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 29070018192,
      "DESCRIPTION": "DUST CAP",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 7,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14634521,
      "DESCRIPTION": "SWITCH",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 1,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 2,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4120004036,
      "DESCRIPTION": "4120016961 FILTER ASSY.",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 8,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 5222737545,
      "DESCRIPTION": "VALVE 324609Z00C",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 1,
      "SHELF LIFE": 6.000000000000024,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 33,
      "WHXX": 6,
      "WH REFERENCE": "",
      "TREND": 1,
      "STD": 0.375533808099405,
      "MEAN": 0.153846153846153,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": "250389Z01E",
      "DESCRIPTION": "O-RING",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 7,
      "SHELF LIFE": 42.00000000000017,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 14,
      "WHXX": 48,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 42.00000000000017,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 22283480,
      "DESCRIPTION": "TURBOCHARGER 22205087 22067460",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 1,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 17457469,
      "DESCRIPTION": "17535679 OIL FILTER 3831236 ",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 15,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 15168370,
      "DESCRIPTION": "TENSIONER",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14704862,
      "DESCRIPTION": "ENGINE COOLING FAN",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 4,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14677045,
      "DESCRIPTION": "PIPE 14522382",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 1,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "213809Z00A",
      "DESCRIPTION": "HOSE",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 7,
      "WH REFERENCE": "WHXX",
      "TREND": 0,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": "324349Z00B",
      "DESCRIPTION": "CONNECTOR",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 8,
      "WHXX": 10,
      "WH REFERENCE": "WHXX",
      "TREND": 0,
      "STD": 1.10940039245045,
      "MEAN": 0.307692307692307,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 3.000000000000003,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 6680018,
      "DESCRIPTION": "COUPLER, FF FEMALE",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14695920,
      "DESCRIPTION": "HOSE_DISCHARGE EC210B 14601887 ",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4061007,
      "DESCRIPTION": "ALTERNATOR",
      "PRODUCT CATEGORY": "CUG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 29070007681,
      "DESCRIPTION": "O-RING",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 13,
      "WHXX": 3,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4190002865,
      "DESCRIPTION": "4190002907003 FAN",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14686427,
      "DESCRIPTION": "SPROCKET ENDURANCE EC140B/EC210B",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 9,
      "WHXX": 2,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 82345507,
      "DESCRIPTION": "SHEAR SCREW",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 6538846,
      "DESCRIPTION": "BEARING, SWEEPER",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14592560,
      "DESCRIPTION": "TAIL LAMP EW145",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 2,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 8,
      "WHXX": 2,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 17387726,
      "DESCRIPTION": "ENGINE EC210D D5E TR 3",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 29050020551,
      "DESCRIPTION": "SHIM",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 8,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 1,
      "STD": 0.375533808099405,
      "MEAN": 0.153846153846153,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 29050020561,
      "DESCRIPTION": "SHIM",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 1,
      "STD": 0.375533808099405,
      "MEAN": 0.153846153846153,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 29050020571,
      "DESCRIPTION": "SHIM",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 7,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 1,
      "STD": 0.375533808099405,
      "MEAN": 0.153846153846153,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 29050020921,
      "DESCRIPTION": "SHIM",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 6,
      "WHXX": 1,
      "WH REFERENCE": "WHXX",
      "TREND": 1,
      "STD": 0.375533808099405,
      "MEAN": 0.153846153846153,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 7120401,
      "DESCRIPTION": "GLASS, DOOR",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4043000256,
      "DESCRIPTION": "SEALING RING",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 4,
      "WHXX": 19,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 935013,
      "DESCRIPTION": "ELBOW NIPPLE",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 12727853,
      "DESCRIPTION": "WASHER",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 15,
      "WHXX": 19,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14715404,
      "DESCRIPTION": "IDLER ENDURANCE  EC210 14695200 14686450",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 39,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14697658,
      "DESCRIPTION": "CONTROL 14631179 14590052 ",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 1,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 1,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "A210111000080",
      "DESCRIPTION": "BOLT",
      "PRODUCT CATEGORY": "YCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 21271982,
      "DESCRIPTION": "SENSOR",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 4,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 22239056,
      "DESCRIPTION": "24159415 HEADLAMP",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.416666666666666,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 2,
      "APRD QTY": 2,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 2,
      "STD": 1.12089707663561,
      "MEAN": 0.384615384615384,
      "COV": 2.91433239925258,
      "SHELF LIFE_1": 4.800000000000008,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 22239057,
      "DESCRIPTION": "24159417 HEADLAMP",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 2,
      "STD": 0.85485041426511,
      "MEAN": 0.307692307692307,
      "COV": 2.7782638463616,
      "SHELF LIFE_1": 3.000000000000003,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 22384866,
      "DESCRIPTION": "STARTER SWITCH",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 5,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 6598492,
      "DESCRIPTION": "AIR FILTER OUTER",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 32,
      "WHXX": 2,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 6598362,
      "DESCRIPTION": "AIR FILTER INNER",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 37,
      "WHXX": 2,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 22662214,
      "DESCRIPTION": "CONNECTING CABLE 20450687",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 5,
      "WHXX": 4,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 29290013761,
      "DESCRIPTION": "REAR VIEW MIRROR",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 6,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "9285-11000",
      "DESCRIPTION": "PLAIN WASHER",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 2,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 5222748702,
      "DESCRIPTION": "FUEL FILTER WATER SEPARATOR 164009Z00C ",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "AVERAGE (F2)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 3.83333333333333,
      "MAINTANING STOCK QTY": 15,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 15,
      "APRD QTY": 15,
      "WH12": 77,
      "WHXX": 30,
      "WH REFERENCE": "WHXX",
      "TREND": 0.91,
      "STD": 3.99197913770819,
      "MEAN": 3.53846153846153,
      "COV": 1.1281680171784,
      "SHELF LIFE_1": 3.913043478260873,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 84415558,
      "DESCRIPTION": "LOCK",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4120006350,
      "DESCRIPTION": "AIR CYLINDER LG956L 4120006350Z",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.5,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 2,
      "APRD QTY": 2,
      "WH12": 4,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 1.33,
      "STD": 0.877058019307029,
      "MEAN": 0.461538461538461,
      "COV": 1.90029237516522,
      "SHELF LIFE_1": 4,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 17271141,
      "DESCRIPTION": "SENSOR",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.25,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 2,
      "SHELF LIFE": 8,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 3,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.599144689515278,
      "MEAN": 0.23076923076923,
      "COV": 2.5962936545662,
      "SHELF LIFE_1": 8,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 7000769,
      "DESCRIPTION": "FUEL SHUTOFF SOLENOID",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 6691714,
      "DESCRIPTION": "SENSOR, SEATBAR BICS 6680429",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 6,
      "WHXX": 1,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 6680443,
      "DESCRIPTION": "BUSHING, MAGNET",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 60053982,
      "DESCRIPTION": "DESICCATOR",
      "PRODUCT CATEGORY": "YCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14629275,
      "DESCRIPTION": "WASHER PUMP",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 1,
      "SHELF LIFE": 12.000000000000005,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 6,
      "WHXX": 6,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 12.000000000000005,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 14522473,
      "DESCRIPTION": "WASHER",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 1,
      "SHELF LIFE": 6.000000000000024,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 4,
      "WHXX": 7,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 4110000991053,
      "DESCRIPTION": "HAND PUMP",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 2,
      "STD": 0.375533808099405,
      "MEAN": 0.153846153846153,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 14656785,
      "DESCRIPTION": "SEALING  14532325",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 3,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 15066986,
      "DESCRIPTION": "INSULATION",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 3,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14882685,
      "DESCRIPTION": "ORING",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 28,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 50,
      "WHXX": 12,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4190002851,
      "DESCRIPTION": "SENSOR 4130002904108",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.25,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 1,
      "WHXX": 1,
      "WH REFERENCE": "WHXX",
      "TREND": 2,
      "STD": 0.599144689515278,
      "MEAN": 0.23076923076923,
      "COV": 2.5962936545662,
      "SHELF LIFE_1": 4,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 14640021,
      "DESCRIPTION": " BALL BEARING",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 5,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 0,
      "STD": 0.375533808099405,
      "MEAN": 0.153846153846153,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 14687316,
      "DESCRIPTION": "ENDURANCE, ROLLER, TOP EC210B (ET) 14545571 ",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.25,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 2,
      "SHELF LIFE": 8,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 111,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.832050294337843,
      "MEAN": 0.23076923076923,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 8,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 14686458,
      "DESCRIPTION": "ENDURANCE. TRACK LINK ASSY 49L EC210BLC  14530361",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 2,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "7114-12200",
      "DESCRIPTION": "SPACER",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 5,
      "WHXX": 3,
      "WH REFERENCE": "WHXX",
      "TREND": 1,
      "STD": 0.75106761619881,
      "MEAN": 0.307692307692307,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 3.000000000000003,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": "9612-05030",
      "DESCRIPTION": "BEARING 14880949",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.416666666666666,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 2,
      "APRD QTY": 0,
      "WH12": 8,
      "WHXX": 5,
      "WH REFERENCE": "WHXX",
      "TREND": 0.8,
      "STD": 0.767947647788304,
      "MEAN": 0.384615384615384,
      "COV": 1.99666388424959,
      "SHELF LIFE_1": 4.800000000000008,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": "164049Z00D",
      "DESCRIPTION": "SEALING RING",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 8,
      "WHXX": 6,
      "WH REFERENCE": "WHXX",
      "TREND": 0,
      "STD": 0.375533808099405,
      "MEAN": 0.153846153846153,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": "140129Z00D",
      "DESCRIPTION": "SEALING RING",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 1.25,
      "MAINTANING STOCK QTY": 5,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 5,
      "APRD QTY": 5,
      "WH12": 39,
      "WHXX": 34,
      "WH REFERENCE": "WHXX",
      "TREND": 1.47,
      "STD": 3.15822085585523,
      "MEAN": 1.15384615384615,
      "COV": 2.7371247417412,
      "SHELF LIFE_1": 4,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": "210879Z00A",
      "DESCRIPTION": "O-RING",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 10,
      "WHXX": 19,
      "WH REFERENCE": "WHXX",
      "TREND": 0,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 5222374837,
      "DESCRIPTION": "SEALING RING",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 6,
      "WH REFERENCE": "WHXX",
      "TREND": 0,
      "STD": 0.75106761619881,
      "MEAN": 0.307692307692307,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 3.000000000000003,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": "152189Z00C",
      "DESCRIPTION": "SEALING RING",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 11,
      "WH REFERENCE": "WHXX",
      "TREND": 0,
      "STD": 0.375533808099405,
      "MEAN": 0.153846153846153,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 22175635,
      "DESCRIPTION": "STARTER APPARATUS SD110B 21798914",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3,
      "WHXX": 1,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "275859Z00D",
      "DESCRIPTION": "WIRING HARNESS",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "271039Z00A",
      "DESCRIPTION": "CABLE",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 9,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14699400,
      "DESCRIPTION": "LOCK 14506769",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 4,
      "WHXX": 5,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 17418267,
      "DESCRIPTION": "V-BELT 21080427",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.583333333333333,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 2,
      "SHELF LIFE": 3.42857142857143,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 2,
      "WH REFERENCE": "",
      "TREND": 0.29,
      "STD": 0.877058019307029,
      "MEAN": 0.538461538461538,
      "COV": 1.62882203585591,
      "SHELF LIFE_1": 3.42857142857143,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 4190002907003,
      "DESCRIPTION": "4130015120001 FAN G9138 4190002865",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 1,
      "STD": 0.75106761619881,
      "MEAN": 0.307692307692307,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 3.000000000000003,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": "FF5706",
      "DESCRIPTION": "FUEL FILTER",
      "PRODUCT CATEGORY": "CUF",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 21448926,
      "DESCRIPTION": "ROCKER ARM",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 5,
      "WHXX": 6,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14668664,
      "DESCRIPTION": "EXPANSION TANK 14644804 17454270",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 4,
      "WHXX": 7,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 17418271,
      "DESCRIPTION": "V-RIBBED BELT A40G 20908490",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.5,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 2,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 5,
      "WH REFERENCE": "WHXX",
      "TREND": 0.33,
      "STD": 1.12660142429821,
      "MEAN": 0.461538461538461,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 4,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 4021000368,
      "DESCRIPTION": "ROLLER BEARING 2810000340006",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 10,
      "SHELF LIFE": 60.00000000000024,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 87,
      "WHXX": 6,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 60.00000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 4021000322,
      "DESCRIPTION": "BEARING  2810000340011",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 10,
      "SHELF LIFE": 60.00000000000024,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 98,
      "WHXX": 4,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 60.00000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 14688861,
      "DESCRIPTION": "FILTER ELEMENT (EW140/EW145) 14539482_",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "FAST (F1)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 9.16666666666666,
      "MAINTANING STOCK QTY": 37,
      "AVAILABLE": 24,
      "SHELF LIFE": 2.61818181818182,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 13,
      "APRD QTY": 13,
      "WH12": 53,
      "WHXX": 61,
      "WH REFERENCE": "WHXX",
      "TREND": 1.07,
      "STD": 5.69525218369629,
      "MEAN": 8.46153846153846,
      "COV": 0.673075258073198,
      "SHELF LIFE_1": 4.036363636363639,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 14711981,
      "DESCRIPTION": "PILOT ELEMENT (EW140/EW145) 14532686",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "FAST (F1)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 6.75,
      "MAINTANING STOCK QTY": 27,
      "AVAILABLE": 18,
      "SHELF LIFE": 2.6666666666666665,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 9,
      "APRD QTY": 0,
      "WH12": 51,
      "WHXX": 54,
      "WH REFERENCE": "WHXX",
      "TREND": 0.89,
      "STD": 4.28473737339575,
      "MEAN": 6.23076923076923,
      "COV": 0.687673899433886,
      "SHELF LIFE_1": 4,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 14658431,
      "DESCRIPTION": "LINK EC210B",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 10,
      "WHXX": 1,
      "WH REFERENCE": "WHXX",
      "TREND": 1,
      "STD": 0.375533808099405,
      "MEAN": 0.153846153846153,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 14658432,
      "DESCRIPTION": "LINK EC210B ",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 12,
      "WHXX": 1,
      "WH REFERENCE": "WHXX",
      "TREND": 0.5,
      "STD": 0.630425171956115,
      "MEAN": 0.307692307692307,
      "COV": 2.04888180885737,
      "SHELF LIFE_1": 3.000000000000003,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 14658434,
      "DESCRIPTION": "PIN",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 3,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 1,
      "STD": 0.480384461415261,
      "MEAN": 0.307692307692307,
      "COV": 1.56124949959959,
      "SHELF LIFE_1": 3.000000000000003,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 14650586,
      "DESCRIPTION": "BUSHING",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.25,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 1,
      "WH REFERENCE": "WHXX",
      "TREND": 0.67,
      "STD": 0.438529009653514,
      "MEAN": 0.23076923076923,
      "COV": 1.90029237516522,
      "SHELF LIFE_1": 4,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 4130001731,
      "DESCRIPTION": "LAMP LG956L",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 15,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 22768927,
      "DESCRIPTION": "CONDENSER 22212990 ",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 1,
      "SHELF LIFE": 6.000000000000024,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 9017216587,
      "DESCRIPTION": "REMAN ENGINE EW145B",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 21792306,
      "DESCRIPTION": "GASKET 20559834",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 1,
      "SHELF LIFE": 12.000000000000005,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 14,
      "WHXX": 12,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 12.000000000000005,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 6516722,
      "DESCRIPTION": "HYDRAULIC OIL FILTER",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 2,
      "SHELF LIFE": 24.00000000000001,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 24,
      "WHXX": 5,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 24.00000000000001,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 6692836,
      "DESCRIPTION": "HYDRAULIC TANK CAP",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 1,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 6,
      "WHXX": 1,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 7010252,
      "DESCRIPTION": "A/C COMPRESSOR BELT",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 4,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 6685105,
      "DESCRIPTION": "WATER PUMP",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 7025590,
      "DESCRIPTION": "ENGINE OIL FILTER",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 7,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 15,
      "WHXX": 5,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 7010351,
      "DESCRIPTION": "FUEL FILTER",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 6,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 10,
      "WHXX": 4,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 7004883,
      "DESCRIPTION": "RETURN FILTER",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 3,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 7013148,
      "DESCRIPTION": "FUEL FILTER ELEMENT",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 9,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 8,
      "WHXX": 4,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4013000193,
      "DESCRIPTION": "NUT",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 37,
      "WHXX": 47,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 29070011032,
      "DESCRIPTION": "SCREW",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 32,
      "WHXX": 22,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 7277987,
      "DESCRIPTION": "AUXILIARY CONTROL CONTROLLER (7012048)",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14662510,
      "DESCRIPTION": "CONTROL LOCKOUT LEVER EC210D",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 2,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 22480372,
      "DESCRIPTION": "FUEL FILTER FM12",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 64,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4110000991,
      "DESCRIPTION": "ENGINE LG936L",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4110000991032,
      "DESCRIPTION": "4110000991031 BEARING SHELL",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4110000991030,
      "DESCRIPTION": "4110000991031 BEARING SHELL",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 1,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 5222771970,
      "DESCRIPTION": "5223658117 OIL FILTER 152009Z00A 21707134",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.666666666666666,
      "MAINTANING STOCK QTY": 3,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 3,
      "APRD QTY": 3,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 0.5,
      "STD": 1.70970082853022,
      "MEAN": 0.615384615384615,
      "COV": 2.7782638463616,
      "SHELF LIFE_1": 4.500000000000004,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 5222771975,
      "DESCRIPTION": "OIL FILTER BYPASS 152009Z00B ",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "AVERAGE (F2)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 5.33333333333333,
      "MAINTANING STOCK QTY": 21,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 21,
      "APRD QTY": 21,
      "WH12": 129,
      "WHXX": 17,
      "WH REFERENCE": "WH12 + WHXX",
      "TREND": 0.84,
      "STD": 6.03409118345558,
      "MEAN": 4.92307692307692,
      "COV": 1.22567477163941,
      "SHELF LIFE_1": 3.937500000000002,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 5222748694,
      "DESCRIPTION": "FUEL FILTER 152009Z00D",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "FAST (F1)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 3.91666666666666,
      "MAINTANING STOCK QTY": 16,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 16,
      "APRD QTY": 16,
      "WH12": 64,
      "WHXX": 23,
      "WH REFERENCE": "WHXX",
      "TREND": 0.89,
      "STD": 4.33086714832148,
      "MEAN": 3.61538461538461,
      "COV": 1.19789942400381,
      "SHELF LIFE_1": 4.08510638297873,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 16688307,
      "DESCRIPTION": "SENSOR 14509344 ",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 1.33333333333333,
      "MAINTANING STOCK QTY": 5,
      "AVAILABLE": 7,
      "SHELF LIFE": 5.250000000000013,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 13,
      "WHXX": 25,
      "WH REFERENCE": "",
      "TREND": 0.25,
      "STD": 2.7127429585153,
      "MEAN": 1.23076923076923,
      "COV": 2.20410365379368,
      "SHELF LIFE_1": 5.250000000000013,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 22282222,
      "DESCRIPTION": "KEY",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14559058,
      "DESCRIPTION": "SEALING STRIP",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 34,
      "WHXX": 13,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 982320,
      "DESCRIPTION": "FUSE",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 9,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 2,
      "STD": 1.10940039245045,
      "MEAN": 0.307692307692307,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 3.000000000000003,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 6735884,
      "DESCRIPTION": "IDLER ASSY, BELT DRIVE",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 29,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 0.5,
      "STD": 0.480384461415261,
      "MEAN": 0.307692307692307,
      "COV": 1.56124949959959,
      "SHELF LIFE_1": 3.000000000000003,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 4110003158,
      "DESCRIPTION": "BRAKE SHOE KIT",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 14,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 0,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 7230495,
      "DESCRIPTION": "LIFT MANUAL VALVE",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 29050011362,
      "DESCRIPTION": "29050011363  GASKET",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 17,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 29050025561,
      "DESCRIPTION": "SHIM",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 4,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 1,
      "STD": 0.375533808099405,
      "MEAN": 0.153846153846153,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 4110001009040,
      "DESCRIPTION": "V-BELT 4110002147005 ",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 9,
      "WHXX": 3,
      "WH REFERENCE": "WHXX",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 6661611,
      "DESCRIPTION": "ALTERNATOR",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 21583792,
      "DESCRIPTION": "KIT",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14679620,
      "DESCRIPTION": "GLASS EC210D",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 6,
      "WHXX": 3,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 82354791,
      "DESCRIPTION": "23515346 VENTILATION FILTER FH 400",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 12746651,
      "DESCRIPTION": "SCREW",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 13,
      "WHXX": 19,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14697657,
      "DESCRIPTION": "CONTROL 14631180 14590054",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 8,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 2,
      "STD": 0.375533808099405,
      "MEAN": 0.153846153846153,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 14708228,
      "DESCRIPTION": "WINDSCREEN 14630898 14506805",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 4,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 7234460,
      "DESCRIPTION": "WHEEL RIM",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 6,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 22803123,
      "DESCRIPTION": "24046348 FUEL PUMP 20980697 ",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 1,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 17470368,
      "DESCRIPTION": "VCS COOLANT (40:60 READY MIXED) 18L/PAIL YELLOW ",
      "PRODUCT CATEGORY": "LUV",
      "ITEM MOVEMENT BRANCH": "FAST (F1)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 222,
      "MAINTANING STOCK QTY": 888,
      "AVAILABLE": 504,
      "SHELF LIFE": 2.27027027027027,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 384,
      "APRD QTY": 378,
      "WH12": 4867,
      "WHXX": 18,
      "WH REFERENCE": "WH12 + WHXX",
      "TREND": 0.73,
      "STD": 293.896371061428,
      "MEAN": 204.923076923076,
      "COV": 1.43417898791237,
      "SHELF LIFE_1": 4,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 4110000054118,
      "DESCRIPTION": "PULLEY",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 1,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4110000555268,
      "DESCRIPTION": "ALTERNATOR",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 5222401537,
      "DESCRIPTION": "FILLER CAP 172529Z00B",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 3,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 5,
      "WHXX": 4,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 7136559,
      "DESCRIPTION": "SOLENOID ASSEMBLY",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "B230101001368",
      "DESCRIPTION": "DUST RING",
      "PRODUCT CATEGORY": "YCG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.5,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 2,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 0,
      "STD": 1.66410058867568,
      "MEAN": 0.461538461538461,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 4,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 6989019,
      "DESCRIPTION": "WIRE BRISTLE 21 3/8-CONVOLUTED",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 9,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2172,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 9021647837,
      "DESCRIPTION": "TURBOCHARGER REMAN EC210B",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 9021092586,
      "DESCRIPTION": "TURBOCHARGER REMAN EC210D",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 4,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 1,
      "STD": 0.375533808099405,
      "MEAN": 0.153846153846153,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": "322179Z00C",
      "DESCRIPTION": "GEAR GKE370",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 1,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14686465,
      "DESCRIPTION": "TRACK SHOE, ENDURANCE",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.75,
      "MAINTANING STOCK QTY": 3,
      "AVAILABLE": 19,
      "SHELF LIFE": 25.333333333333332,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 20,
      "WHXX": 56,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 1.70218562367207,
      "MEAN": 0.692307692307692,
      "COV": 2.45871256752633,
      "SHELF LIFE_1": 25.333333333333332,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 14715403,
      "DESCRIPTION": "BOTTOM ROLLER, ENDURANCE EC210B",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 1.66666666666666,
      "MAINTANING STOCK QTY": 7,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 7,
      "APRD QTY": 0,
      "WH12": 78,
      "WHXX": 22,
      "WH REFERENCE": "WHXX",
      "TREND": 0.4,
      "STD": 4.48358830654243,
      "MEAN": 1.53846153846153,
      "COV": 2.91433239925258,
      "SHELF LIFE_1": 4.200000000000016,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 6676029,
      "DESCRIPTION": "LOCK SPOOL SOLENOID",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4110000127008,
      "DESCRIPTION": "FUEL PUMP LG936L",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 8,
      "WHXX": 5,
      "WH REFERENCE": "WHXX",
      "TREND": 2,
      "STD": 0.375533808099405,
      "MEAN": 0.153846153846153,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 4130001732,
      "DESCRIPTION": "LAMP",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 6680441,
      "DESCRIPTION": "BUSHING",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 6,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 0,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 22383382,
      "DESCRIPTION": "LEVEL SENSOR",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 4,
      "WHXX": 1,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 995871,
      "DESCRIPTION": "GASKET",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 6,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14561415,
      "DESCRIPTION": "SWITCH EC210D",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 3,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 17007124,
      "DESCRIPTION": "BACK-UP WARNING UNIT SD110B",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 22905123,
      "DESCRIPTION": "24046345 FUEL PUMP 21620116 21019945",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "AVERAGE (F2)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 1.16666666666666,
      "MAINTANING STOCK QTY": 5,
      "AVAILABLE": 1,
      "SHELF LIFE": 0.857142857142862,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 4,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 1,
      "STD": 1.11516355015295,
      "MEAN": 1.07692307692307,
      "COV": 1.03550901085631,
      "SHELF LIFE_1": 4.28571428571431,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 4110001031031,
      "DESCRIPTION": "CONNECTOR 4110001525018",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 8,
      "WHXX": 4,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 12811798,
      "DESCRIPTION": "FILLER CAP",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 3,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 17410278,
      "DESCRIPTION": "FILTER ELEMENT A40G",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.666666666666666,
      "MAINTANING STOCK QTY": 3,
      "AVAILABLE": 2,
      "SHELF LIFE": 3.000000000000003,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 10,
      "WHXX": 5,
      "WH REFERENCE": "WHXX",
      "TREND": 0,
      "STD": 1.19292787840544,
      "MEAN": 0.615384615384615,
      "COV": 1.93850780240885,
      "SHELF LIFE_1": 4.500000000000004,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 17410282,
      "DESCRIPTION": "FILTER ELEMENT A40G",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 2.08333333333333,
      "MAINTANING STOCK QTY": 8,
      "AVAILABLE": 10,
      "SHELF LIFE": 4.800000000000008,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 38,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 3.32820117735137,
      "MEAN": 1.92307692307692,
      "COV": 1.73066461222271,
      "SHELF LIFE_1": 4.800000000000008,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 5235899,
      "DESCRIPTION": "BUSHING",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 3,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 15185410,
      "DESCRIPTION": "ROLLER BEARING",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 17460623,
      "DESCRIPTION": "SEALING RING ",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 3,
      "WH REFERENCE": "WHXX",
      "TREND": 0,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 1161937,
      "DESCRIPTION": "TRANSMISSION OIL 85W-140  20L ",
      "PRODUCT CATEGORY": "LUV",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 13.3333333333333,
      "MAINTANING STOCK QTY": 53,
      "AVAILABLE": 516,
      "SHELF LIFE": 38.700000000000095,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2972,
      "WHXX": 388,
      "WH REFERENCE": "",
      "TREND": 1.95,
      "STD": 43.1883175086824,
      "MEAN": 12.3076923076923,
      "COV": 3.50905079758045,
      "SHELF LIFE_1": 38.700000000000095,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 70930466,
      "DESCRIPTION": "FUSE HOLDER",
      "PRODUCT CATEGORY": "VCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "U81266",
      "DESCRIPTION": "CHARGING VALVE",
      "PRODUCT CATEGORY": "SOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 6625575,
      "DESCRIPTION": "FUSE 20A",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 9,
      "WHXX": 1,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 22089218,
      "DESCRIPTION": "BELT TENSIONER EC210D",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 1,
      "SHELF LIFE": 6.000000000000024,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 8,
      "WHXX": 2,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.375533808099405,
      "MEAN": 0.153846153846153,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 6571857,
      "DESCRIPTION": "ALTERNATOR BELT",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14703234,
      "DESCRIPTION": "SHIM",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 3,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 21,
      "WHXX": 6,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "322179Z00A",
      "DESCRIPTION": "GEAR",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4110000054253,
      "DESCRIPTION": "FUEL FILTER",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 7,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "83D4",
      "DESCRIPTION": "NUT",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 11,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4110000011114,
      "DESCRIPTION": "PLATE",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4110000011115,
      "DESCRIPTION": "PLATE",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "307089Z00A",
      "DESCRIPTION": "GASKET",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 34,
      "WHXX": 25,
      "WH REFERENCE": "WHXX",
      "TREND": 0,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 22899626,
      "DESCRIPTION": "PRESSURE SENSOR 21634021 ",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 1,
      "SHELF LIFE": 12.000000000000005,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 12.000000000000005,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": "4120006350Z",
      "DESCRIPTION": "4120006350  AIR CYLINDER",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4190002587,
      "DESCRIPTION": "WIPER MOTOR",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14560268,
      "DESCRIPTION": "O-RING",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 2.33333333333333,
      "MAINTANING STOCK QTY": 9,
      "AVAILABLE": 21,
      "SHELF LIFE": 9.000000000000012,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 18,
      "WHXX": 29,
      "WH REFERENCE": "",
      "TREND": 0.71,
      "STD": 2.51151195650992,
      "MEAN": 2.15384615384615,
      "COV": 1.16605912266532,
      "SHELF LIFE_1": 9.000000000000012,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 14560264,
      "DESCRIPTION": "RING",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.916666666666666,
      "MAINTANING STOCK QTY": 4,
      "AVAILABLE": 8,
      "SHELF LIFE": 8.727272727272734,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 8,
      "WHXX": 8,
      "WH REFERENCE": "",
      "TREND": 0.73,
      "STD": 0.987096233585649,
      "MEAN": 0.846153846153846,
      "COV": 1.16656827605576,
      "SHELF LIFE_1": 8.727272727272734,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 4043000026,
      "DESCRIPTION": "BUSHING",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.5,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 2,
      "SHELF LIFE": 4,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0.67,
      "STD": 0.877058019307029,
      "MEAN": 0.461538461538461,
      "COV": 1.90029237516522,
      "SHELF LIFE_1": 4,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 16053916,
      "DESCRIPTION": "TIMER RELAY 16064049",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 2,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "A810201206238",
      "DESCRIPTION": "PRESSURE SENSOR 4 PIN",
      "PRODUCT CATEGORY": "YCG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 4,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 17489661,
      "DESCRIPTION": "HYDRAULIC VALVE",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14690316,
      "DESCRIPTION": "FILTER ELEMENT 14569658",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "AVERAGE (F2)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 2.83333333333333,
      "MAINTANING STOCK QTY": 11,
      "AVAILABLE": 8,
      "SHELF LIFE": 2.823529411764709,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 3,
      "APRD QTY": 3,
      "WH12": 32,
      "WHXX": 19,
      "WH REFERENCE": "WHXX",
      "TREND": 0.76,
      "STD": 2.46774058396412,
      "MEAN": 2.61538461538461,
      "COV": 0.943547870339223,
      "SHELF LIFE_1": 3.8823529411764754,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 7231756,
      "DESCRIPTION": "COOLING FAN",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 7200001489,
      "DESCRIPTION": "GASKET",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 7200001480,
      "DESCRIPTION": "GASKET",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "C03228",
      "DESCRIPTION": "SOUND PLUG",
      "PRODUCT CATEGORY": "SOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 3,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 0,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 4130001540,
      "DESCRIPTION": "TAIL LAMP LG8180",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4130001541,
      "DESCRIPTION": "TAIL LAMP LGG8180",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14629276,
      "DESCRIPTION": "SEAL",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 1,
      "SHELF LIFE": 12.000000000000005,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 5,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 12.000000000000005,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": "381669Z03D",
      "DESCRIPTION": "FLANGE SCREW",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 1.66666666666666,
      "MAINTANING STOCK QTY": 7,
      "AVAILABLE": 7,
      "SHELF LIFE": 4.200000000000016,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 16,
      "WHXX": 41,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 5.54700196225229,
      "MEAN": 1.53846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 4.200000000000016,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": "FLUID41",
      "DESCRIPTION": "FLUID 41 (5L/CAN)",
      "PRODUCT CATEGORY": "LUB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4120004041012,
      "DESCRIPTION": "SEALING RING KIT",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.75,
      "MAINTANING STOCK QTY": 3,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 3,
      "APRD QTY": 3,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 2,
      "STD": 2.49615088301353,
      "MEAN": 0.692307692307692,
      "COV": 3.60555127546399,
      "SHELF LIFE_1": 4,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 22107652,
      "DESCRIPTION": "DESICCANT",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 1,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 6,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 5222889270,
      "DESCRIPTION": "GEAR SHIFT LEVER C 328359Z00A ",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 6625298,
      "DESCRIPTION": "PUMP BELT",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 15,
      "WHXX": 1,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 5222364174,
      "DESCRIPTION": "WHEEL STUD 432409Z00A",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 1.66666666666666,
      "MAINTANING STOCK QTY": 7,
      "AVAILABLE": 5,
      "SHELF LIFE": 3.000000000000012,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 2,
      "APRD QTY": 2,
      "WH12": 0,
      "WHXX": 5,
      "WH REFERENCE": "WHXX",
      "TREND": 2,
      "STD": 5.54700196225229,
      "MEAN": 1.53846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 4.200000000000016,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 60110356,
      "DESCRIPTION": "SCREW 990514 1629544 ",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 268,
      "WHXX": 40,
      "WH REFERENCE": "WHXX",
      "TREND": 0,
      "STD": 1.10940039245045,
      "MEAN": 0.307692307692307,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 3.000000000000003,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": "324609Z00B",
      "DESCRIPTION": "VALVE",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.5,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 2,
      "APRD QTY": 0,
      "WH12": 10,
      "WHXX": 8,
      "WH REFERENCE": "WHXX",
      "TREND": 0,
      "STD": 1.66410058867568,
      "MEAN": 0.461538461538461,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 4,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 4130001413,
      "DESCRIPTION": "LAMP",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 24,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4110000054311,
      "DESCRIPTION": "WATER SEPARATOR",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 1,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 11,
      "WHXX": 1,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4110000054211,
      "DESCRIPTION": "PLUG",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4110000054241,
      "DESCRIPTION": "VALVE GUIDE",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 6,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4110000054242,
      "DESCRIPTION": "VALVE GUIDE",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 13,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4110000054216,
      "DESCRIPTION": "VALVE SEAT",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4110000054097,
      "DESCRIPTION": "VALVE SEAT",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 6,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 60208877,
      "DESCRIPTION": "FILTRATION CORE",
      "PRODUCT CATEGORY": "YCG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.25,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 5,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 2,
      "STD": 0.832050294337843,
      "MEAN": 0.23076923076923,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 4,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 4120002264406,
      "DESCRIPTION": "BUSHING",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 22435058,
      "DESCRIPTION": "SENSOR 20820494",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.25,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 1,
      "SHELF LIFE": 4,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 4,
      "WHXX": 2,
      "WH REFERENCE": "",
      "TREND": 1.33,
      "STD": 0.438529009653514,
      "MEAN": 0.23076923076923,
      "COV": 1.90029237516522,
      "SHELF LIFE_1": 4,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 4120006350001,
      "DESCRIPTION": "SEALING RING KIT",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 4,
      "WHXX": 6,
      "WH REFERENCE": "WHXX",
      "TREND": 0,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 4030000130,
      "DESCRIPTION": "O-RING",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 35,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 15185355,
      "DESCRIPTION": "SHAFT",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4110000991012,
      "DESCRIPTION": "WATER PIPE",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 29220001261,
      "DESCRIPTION": "BLOCK",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14640020,
      "DESCRIPTION": "SUPPORT 14534462 ",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 7,
      "WH REFERENCE": "WHXX",
      "TREND": 1,
      "STD": 0.75106761619881,
      "MEAN": 0.307692307692307,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 3.000000000000003,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 14880126,
      "DESCRIPTION": "T-NIPPLE",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "250389Z00E",
      "DESCRIPTION": "O-RING",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 8,
      "SHELF LIFE": 48.00000000000019,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 22,
      "WHXX": 62,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 48.00000000000019,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 14640073,
      "DESCRIPTION": "HOSE",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 3,
      "WH REFERENCE": "WHXX",
      "TREND": 0,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 976568,
      "DESCRIPTION": "HOSE CLAMP",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 6,
      "WHXX": 16,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14882198,
      "DESCRIPTION": "HOSE",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.25,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 5,
      "WH REFERENCE": "WHXX",
      "TREND": 0,
      "STD": 0.832050294337843,
      "MEAN": 0.23076923076923,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 4,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 14682233,
      "DESCRIPTION": "RADIATOR HOSE",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 4,
      "WH REFERENCE": "WHXX",
      "TREND": 0,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 14656784,
      "DESCRIPTION": "BUSHING",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 3,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14882689,
      "DESCRIPTION": "O-RING",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 173,
      "WHXX": 50,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4110003167001,
      "DESCRIPTION": " TRANSMISSION OIL FILTER L956F",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "FAST (F1)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 7.91666666666666,
      "MAINTANING STOCK QTY": 32,
      "AVAILABLE": 31,
      "SHELF LIFE": 3.915789473684214,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 1.54,
      "STD": 15.0797027788161,
      "MEAN": 7.3076923076923,
      "COV": 2.06353827499589,
      "SHELF LIFE_1": 4.042105263157898,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 4110001755023,
      "DESCRIPTION": "AIR FILTER LG956F",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "FAST (F1)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 8.75,
      "MAINTANING STOCK QTY": 35,
      "AVAILABLE": 6,
      "SHELF LIFE": 0.6857142857142857,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 29,
      "APRD QTY": 29,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 1.01,
      "STD": 11.4852190405867,
      "MEAN": 8.07692307692307,
      "COV": 1.42197950026312,
      "SHELF LIFE_1": 4,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 4110001544001,
      "DESCRIPTION": "AIR FILTER INSERT LG956F",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "FAST (F1)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 5.33333333333333,
      "MAINTANING STOCK QTY": 21,
      "AVAILABLE": 22,
      "SHELF LIFE": 4.125000000000003,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 132,
      "WHXX": 8,
      "WH REFERENCE": "",
      "TREND": 1.56,
      "STD": 6.98166095879696,
      "MEAN": 4.92307692307692,
      "COV": 1.41814988225563,
      "SHELF LIFE_1": 4.125000000000003,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 29100001051,
      "DESCRIPTION": "29100001051A  FILTER INSERT LG956F",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 17428016,
      "DESCRIPTION": "RUBBER COVER",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 2,
      "STD": 1.10940039245045,
      "MEAN": 0.307692307692307,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 3.000000000000003,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 4110002287,
      "DESCRIPTION": "FAN",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14560274,
      "DESCRIPTION": "DISC",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 1,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 8,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14560276,
      "DESCRIPTION": "DISC",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 3,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 3,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14558957,
      "DESCRIPTION": "SCREW",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.5,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 2,
      "APRD QTY": 2,
      "WH12": 13,
      "WHXX": 15,
      "WH REFERENCE": "WHXX",
      "TREND": 2,
      "STD": 1.66410058867568,
      "MEAN": 0.461538461538461,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 4,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 11705004,
      "DESCRIPTION": "WASHER",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.5,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 2,
      "APRD QTY": 2,
      "WH12": 0,
      "WHXX": 15,
      "WH REFERENCE": "WHXX",
      "TREND": 2,
      "STD": 1.66410058867568,
      "MEAN": 0.461538461538461,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 4,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 11705003,
      "DESCRIPTION": "COMPRESSION SPRING",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.5,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 2,
      "APRD QTY": 2,
      "WH12": 0,
      "WHXX": 15,
      "WH REFERENCE": "WHXX",
      "TREND": 2,
      "STD": 1.66410058867568,
      "MEAN": 0.461538461538461,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 4,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 14558956,
      "DESCRIPTION": "SPRING",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.5,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 2,
      "APRD QTY": 2,
      "WH12": 0,
      "WHXX": 15,
      "WH REFERENCE": "WHXX",
      "TREND": 2,
      "STD": 1.66410058867568,
      "MEAN": 0.461538461538461,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 4,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 14560269,
      "DESCRIPTION": "O-RING",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 1.33333333333333,
      "MAINTANING STOCK QTY": 5,
      "AVAILABLE": 5,
      "SHELF LIFE": 3.7500000000000098,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 8,
      "WHXX": 2,
      "WH REFERENCE": "",
      "TREND": 0.63,
      "STD": 1.09192842819833,
      "MEAN": 1.23076923076923,
      "COV": 0.887191847911149,
      "SHELF LIFE_1": 3.7500000000000098,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 14560273,
      "DESCRIPTION": "RING",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 1,
      "MAINTANING STOCK QTY": 4,
      "AVAILABLE": 12,
      "SHELF LIFE": 12,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 9,
      "WHXX": 5,
      "WH REFERENCE": "",
      "TREND": 0.5,
      "STD": 1.11516355015295,
      "MEAN": 0.923076923076923,
      "COV": 1.20809384599903,
      "SHELF LIFE_1": 12,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 14560272,
      "DESCRIPTION": "O-RING",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 1.08333333333333,
      "MAINTANING STOCK QTY": 4,
      "AVAILABLE": 7,
      "SHELF LIFE": 6.461538461538482,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3,
      "WHXX": 9,
      "WH REFERENCE": "",
      "TREND": 0.46,
      "STD": 1,
      "MEAN": 1,
      "COV": 1,
      "SHELF LIFE_1": 6.461538461538482,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 11704997,
      "DESCRIPTION": "SEALING RING",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.833333333333333,
      "MAINTANING STOCK QTY": 3,
      "AVAILABLE": 8,
      "SHELF LIFE": 9.600000000000003,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0.6,
      "STD": 0.926808695996298,
      "MEAN": 0.769230769230769,
      "COV": 1.20485130479518,
      "SHELF LIFE_1": 9.600000000000003,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 11704999,
      "DESCRIPTION": "BACK-UP RING",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.833333333333333,
      "MAINTANING STOCK QTY": 3,
      "AVAILABLE": 9,
      "SHELF LIFE": 10.800000000000004,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 9,
      "WHXX": 3,
      "WH REFERENCE": "",
      "TREND": 0.6,
      "STD": 0.926808695996298,
      "MEAN": 0.769230769230769,
      "COV": 1.20485130479518,
      "SHELF LIFE_1": 10.800000000000004,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 17410286,
      "DESCRIPTION": "FILTER ELEMENT 11707544",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 6,
      "SHELF LIFE": 18.000000000000018,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 19,
      "WHXX": 17,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.75106761619881,
      "MEAN": 0.307692307692307,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 18.000000000000018,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 996813,
      "DESCRIPTION": "FLANGE SCREW",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 7020981,
      "DESCRIPTION": "GLOW PLUG",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 5,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 7000645,
      "DESCRIPTION": "CYLINDER HEAD GASKET",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "B222100000641",
      "DESCRIPTION": "PRIMARY AIR FILTER",
      "PRODUCT CATEGORY": "YCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 1,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "B222100000640",
      "DESCRIPTION": "SECONDARY AIR FILTER",
      "PRODUCT CATEGORY": "YCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 2,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 7000626,
      "DESCRIPTION": "SEAL",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 60274433,
      "DESCRIPTION": "OIL FILTER ",
      "PRODUCT CATEGORY": "YPG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 1,
      "SHELF LIFE": 6.000000000000024,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 60274432,
      "DESCRIPTION": "FUEL FILTER",
      "PRODUCT CATEGORY": "YPG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "9751-15016 ",
      "DESCRIPTION": "GROMMET",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 9,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14552494,
      "DESCRIPTION": "SEAL",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14623350,
      "DESCRIPTION": "BRACKET",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 1,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14686497,
      "DESCRIPTION": "CABLE HARNESS",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 1,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14685140,
      "DESCRIPTION": "ROTATING BEACON",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14562064,
      "DESCRIPTION": "STAY",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 1,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 4,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14600015,
      "DESCRIPTION": "HOSE",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14695913,
      "DESCRIPTION": "HOSE",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14643925,
      "DESCRIPTION": "SWITCH",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 5,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 22191895,
      "DESCRIPTION": "CRANKSHAFT SEAL",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 22879098,
      "DESCRIPTION": "DRAIN VALVE 20450851 ",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 4,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 20934592,
      "DESCRIPTION": "RELAY",
      "PRODUCT CATEGORY": "VCG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 1,
      "SHELF LIFE": 3.000000000000003,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 9,
      "WHXX": 30,
      "WH REFERENCE": "",
      "TREND": 1,
      "STD": 0.75106761619881,
      "MEAN": 0.307692307692307,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 3.000000000000003,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 29070012892,
      "DESCRIPTION": "GEAR RING",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 9,
      "WHXX": 8,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 5282675288,
      "DESCRIPTION": "CONDENSER",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3,
      "WHXX": 3,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14687776,
      "DESCRIPTION": "BRACKET 14594431",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 1,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14708935,
      "DESCRIPTION": "MIRROR 14639120",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 4,
      "WHXX": 2,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14640102,
      "DESCRIPTION": "I-ECU",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14685008,
      "DESCRIPTION": "CHARGE AIR HOSE",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14638178,
      "DESCRIPTION": "CABLE HARNESS",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14561413,
      "DESCRIPTION": "SWITCH",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 3,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4110001905014,
      "DESCRIPTION": "RETAINING RING",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 8,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 60255743,
      "DESCRIPTION": "ALTERNATOR -QSB5.9 ENGINE",
      "PRODUCT CATEGORY": "YCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14640023,
      "DESCRIPTION": "ROLLER BEARING  7114-12490",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.416666666666666,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 2,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 5,
      "WH REFERENCE": "WHXX",
      "TREND": 0.8,
      "STD": 0.767947647788304,
      "MEAN": 0.384615384615384,
      "COV": 1.99666388424959,
      "SHELF LIFE_1": 4.800000000000008,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 29050022301,
      "DESCRIPTION": "SHIM",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 3,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 29050022291,
      "DESCRIPTION": "SHIM",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 3,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 29050023111,
      "DESCRIPTION": "GASKET",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 21,
      "WHXX": 3,
      "WH REFERENCE": "WHXX",
      "TREND": 1,
      "STD": 0.375533808099405,
      "MEAN": 0.153846153846153,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 4021000057,
      "DESCRIPTION": "BALL BEARING",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 17,
      "WHXX": 4,
      "WH REFERENCE": "WHXX",
      "TREND": 1,
      "STD": 0.75106761619881,
      "MEAN": 0.307692307692307,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 3.000000000000003,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 4021000055,
      "DESCRIPTION": "BALL BEARING",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 14,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 1,
      "STD": 0.375533808099405,
      "MEAN": 0.153846153846153,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 5220844736,
      "DESCRIPTION": "COVER",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 3,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 60,
      "WHXX": 10,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 60256244,
      "DESCRIPTION": "VACUUM METER",
      "PRODUCT CATEGORY": "YPG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 21225667,
      "DESCRIPTION": "CONTACT REEL",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 5,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 21004310,
      "DESCRIPTION": "DC CONTACTOR- MZJ-50A/006",
      "PRODUCT CATEGORY": "YCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 58748,
      "DESCRIPTION": "CAP 53036",
      "PRODUCT CATEGORY": "VGG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14656782,
      "DESCRIPTION": "SHAFT",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 2,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 60024962,
      "DESCRIPTION": "MULTI-WAY VALVE PROTECTIVE ELECTROMAGNET",
      "PRODUCT CATEGORY": "YCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 5,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 20,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14883778,
      "DESCRIPTION": "HOSE CLAMP 9313-10425 ",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 8,
      "WHXX": 7,
      "WH REFERENCE": "WHXX",
      "TREND": 1.5,
      "STD": 0.85485041426511,
      "MEAN": 0.307692307692307,
      "COV": 2.7782638463616,
      "SHELF LIFE_1": 3.000000000000003,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 997459,
      "DESCRIPTION": "HEXAGON SCREW 978936",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 183,
      "WHXX": 20,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 29050022061,
      "DESCRIPTION": "FLANGE",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 12743620,
      "DESCRIPTION": "THERMOSTAT",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 60222777,
      "DESCRIPTION": "OIL SUCTION FILTER CORE",
      "PRODUCT CATEGORY": "YCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 11,
      "WHXX": 3,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 28360002211,
      "DESCRIPTION": "LOCK PLATE",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 29170042101,
      "DESCRIPTION": "WEAR PLATE",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 5,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 0,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 29170042111,
      "DESCRIPTION": "WEAR PLATE",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 15065343,
      "DESCRIPTION": "STARTER",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 12802324,
      "DESCRIPTION": "SHOCK ABSORBER",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 1,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 29110000043,
      "DESCRIPTION": "GASKET 29110000042",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 19,
      "WHXX": 4,
      "WH REFERENCE": "WHXX",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 4110001527,
      "DESCRIPTION": "ABSORBER",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 4,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 2,
      "STD": 1.10940039245045,
      "MEAN": 0.307692307692307,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 3.000000000000003,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 60110025,
      "DESCRIPTION": "HEXAGON SCREW 991649 ",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 15.3333333333333,
      "MAINTANING STOCK QTY": 61,
      "AVAILABLE": 10,
      "SHELF LIFE": 0.6521739130434797,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 51,
      "APRD QTY": 51,
      "WH12": 0,
      "WHXX": 116,
      "WH REFERENCE": "WHXX",
      "TREND": 2,
      "STD": 34.6358344152559,
      "MEAN": 14.1538461538461,
      "COV": 2.44709699673003,
      "SHELF LIFE_1": 3.978260869565226,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 12803013,
      "DESCRIPTION": "THROTTLE CABLE",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 2,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 7016331,
      "DESCRIPTION": "FILTER, OIL 800AJ, 1250AJP",
      "PRODUCT CATEGORY": "JLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4016000043,
      "DESCRIPTION": "SPLIT PIN",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 1,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 13,
      "WHXX": 1,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 21793270,
      "DESCRIPTION": "GASKET EC210B/G930 20559835",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 1,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 10,
      "WHXX": 5,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 17418494,
      "DESCRIPTION": "BATTERY MAIN SWITCH 3987034",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.75,
      "MAINTANING STOCK QTY": 3,
      "AVAILABLE": 2,
      "SHELF LIFE": 2.6666666666666665,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 3,
      "WHXX": 6,
      "WH REFERENCE": "WHXX",
      "TREND": 1.33,
      "STD": 0.630425171956115,
      "MEAN": 0.692307692307692,
      "COV": 0.910614137269944,
      "SHELF LIFE_1": 4,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 997436,
      "DESCRIPTION": "HEXAGON SCREW 983252 983272 96713326",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 16,
      "WHXX": 53,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4110000556263,
      "DESCRIPTION": "ALTERNATOR",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 12802160,
      "DESCRIPTION": "SOLENOID",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3,
      "WHXX": 1,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 17252660,
      "DESCRIPTION": "14780982 PRESSURE SENSOR",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 979064,
      "DESCRIPTION": "V-RIBBED BELT",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 2,
      "WHXX": 6,
      "WH REFERENCE": "WHXX",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 4043000752,
      "DESCRIPTION": "SHAFT SLEEVE 29160008141",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 6,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 17418113,
      "DESCRIPTION": "FILTER CARTRIDGE, HYDRAULIC  11037868",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 1,
      "SHELF LIFE": 6.000000000000024,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 6,
      "WHXX": 10,
      "WH REFERENCE": "",
      "TREND": 1,
      "STD": 0.375533808099405,
      "MEAN": 0.153846153846153,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 4110000784,
      "DESCRIPTION": "AIR CLEANER",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 5222719933,
      "DESCRIPTION": "FAN HUB 210469Z03C",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 2,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 12,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 11705466,
      "DESCRIPTION": "GAS SPRING",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 4,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14538415,
      "DESCRIPTION": "PLATE",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14538416,
      "DESCRIPTION": "PLATE",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14708934,
      "DESCRIPTION": "14672980 MIRROR 14634766",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14534865,
      "DESCRIPTION": "SEAL",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 1,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14614042,
      "DESCRIPTION": "WASHER PUMP",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 3,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14691235,
      "DESCRIPTION": "SEAL",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 6,
      "WHXX": 2,
      "WH REFERENCE": "WHXX",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": "295349Z00A",
      "DESCRIPTION": "HEX. SOCKET SCREW",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 10,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14557503,
      "DESCRIPTION": "COVER",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 3,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4110000970215,
      "DESCRIPTION": "VALVE COVER GASKET",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4110000970108,
      "DESCRIPTION": "THERMOSTAT",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 1,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 6,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14689356,
      "DESCRIPTION": "BLADE 14538414",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 6670472,
      "DESCRIPTION": "NUT",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4130002911003,
      "DESCRIPTION": "TEMPERATURE SENSOR",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 66,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "270389Z00A",
      "DESCRIPTION": "O-RING",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 23,
      "WHXX": 24,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14725313,
      "DESCRIPTION": "SELECTOR UNIT 14659778 14708419",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 4,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 7002980,
      "DESCRIPTION": "PRESSURE SWITCH",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 7,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14880424,
      "DESCRIPTION": "CAP NUT",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 3,
      "WH REFERENCE": "WHXX",
      "TREND": 0,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 14679691,
      "DESCRIPTION": "WINDSHIELD EC210D",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 5222802279,
      "DESCRIPTION": "V-RIBBED BELT",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 1.91666666666666,
      "MAINTANING STOCK QTY": 8,
      "AVAILABLE": 6,
      "SHELF LIFE": 3.1304347826087064,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 2,
      "APRD QTY": 2,
      "WH12": 0,
      "WHXX": 2,
      "WH REFERENCE": "WHXX",
      "TREND": 1.91,
      "STD": 5.79013307495095,
      "MEAN": 1.76923076923076,
      "COV": 3.2726839119288,
      "SHELF LIFE_1": 4.173913043478275,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 14674063,
      "DESCRIPTION": "SEALING KIT",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 9,
      "WHXX": 4,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 7281063,
      "DESCRIPTION": "DOOR SEAL",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14371518,
      "DESCRIPTION": "HEADLAMP",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 4,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14595818,
      "DESCRIPTION": "GAS SPRING",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 1,
      "SHELF LIFE": 12.000000000000005,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 10,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 12.000000000000005,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 14532310,
      "DESCRIPTION": "SEAL",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 8,
      "WH REFERENCE": "WHXX",
      "TREND": 0,
      "STD": 1.10940039245045,
      "MEAN": 0.307692307692307,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 3.000000000000003,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 14647523,
      "DESCRIPTION": "GEAR",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14714851,
      "DESCRIPTION": "SWITCH 14588962",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 2,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 22967564,
      "DESCRIPTION": "MAIN BEARING 20850106",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 19,
      "WHXX": 23,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 997434,
      "DESCRIPTION": "HEX SCREW 983250",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 24,
      "WHXX": 21,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 997435,
      "DESCRIPTION": "HEXAGON SCREW 983251",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.666666666666666,
      "MAINTANING STOCK QTY": 3,
      "AVAILABLE": 3,
      "SHELF LIFE": 4.500000000000004,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 89,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 2.21880078490091,
      "MEAN": 0.615384615384615,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 4.500000000000004,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 4110001007009,
      "DESCRIPTION": "SENSOR",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4110001015003,
      "DESCRIPTION": "CONTROL CABLE",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 11,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4110002483001,
      "DESCRIPTION": "STARTER MOTOR",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 22446845,
      "DESCRIPTION": "MAIN SWITCH",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 5,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14691909,
      "DESCRIPTION": "FILTER ELEMENT",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 5,
      "WHXX": 6,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 16000089,
      "DESCRIPTION": "THERMOSTAT G930",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14594708,
      "DESCRIPTION": "CONTROL UNIT",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 22745695,
      "DESCRIPTION": "BRACKET",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 3,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 20913851,
      "DESCRIPTION": "ADJUSTING SCREW",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 20794115,
      "DESCRIPTION": "RETAINING RING",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 26,
      "WHXX": 7,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "282299Z00B",
      "DESCRIPTION": "FLANGE SCREW",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 4,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 6,
      "WHXX": 36,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14711980,
      "DESCRIPTION": "14750655 FILTER CARTRIDGE",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 60252396,
      "DESCRIPTION": "FUEL/WATER SEPARATOR ELEMENT ",
      "PRODUCT CATEGORY": "YCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 6,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 10,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "A222100000380",
      "DESCRIPTION": "OIL FILTER",
      "PRODUCT CATEGORY": "YCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 3,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 45,
      "WHXX": 8,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 13274788,
      "DESCRIPTION": "MARK PLATE FOR RELIEF VALVE GJB312C8R.1 ",
      "PRODUCT CATEGORY": "YPG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14715423,
      "DESCRIPTION": "BOTTOM ROLLER, ENDURANCE EC55",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14686445,
      "DESCRIPTION": "IDLER, ENDURANCE EC55",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 3,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 0,
      "STD": 0.375533808099405,
      "MEAN": 0.153846153846153,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 29070013171,
      "DESCRIPTION": "SHIM",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 20,
      "WHXX": 94,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 29070013181,
      "DESCRIPTION": "SHIM",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 26,
      "WHXX": 73,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 3050900043,
      "DESCRIPTION": "PLANET GEAR SHAFT",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 9,
      "WHXX": 45,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 3050900042,
      "DESCRIPTION": "RETAINING RING",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 15,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 42,
      "WHXX": 111,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 3050900041,
      "DESCRIPTION": "PLANET GEAR",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 12,
      "WHXX": 14,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4090000003,
      "DESCRIPTION": "NEEDLE ROLLER",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 70,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 350,
      "WHXX": 1306,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "443419Z01B",
      "DESCRIPTION": "SLACK ADJUSTER",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 7319444,
      "DESCRIPTION": "FILTER ELEMENT (SPIN-ON TYPE)",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "AVERAGE (F2)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 1.41666666666666,
      "MAINTANING STOCK QTY": 6,
      "AVAILABLE": 19,
      "SHELF LIFE": 13.411764705882415,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 82,
      "WHXX": 5,
      "WH REFERENCE": "",
      "TREND": 0.82,
      "STD": 1.84321347039237,
      "MEAN": 1.3076923076923,
      "COV": 1.40951618324122,
      "SHELF LIFE_1": 13.411764705882415,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 14702253,
      "DESCRIPTION": "HORN 14545502 14654752 ",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 1,
      "SHELF LIFE": 3.000000000000003,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 19,
      "WHXX": 4,
      "WH REFERENCE": "",
      "TREND": 1,
      "STD": 0.480384461415261,
      "MEAN": 0.307692307692307,
      "COV": 1.56124949959959,
      "SHELF LIFE_1": 3.000000000000003,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": "165289Z01A",
      "DESCRIPTION": "AIR FILTER-PRIMARY",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.583333333333333,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 2,
      "APRD QTY": 2,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 0.29,
      "STD": 1.39136531360295,
      "MEAN": 0.538461538461538,
      "COV": 2.58396415383405,
      "SHELF LIFE_1": 3.42857142857143,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": "165289Z00E",
      "DESCRIPTION": "AIR FILTER INSERT - CRONER 14T",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 0.5,
      "STD": 0.85485041426511,
      "MEAN": 0.307692307692307,
      "COV": 2.7782638463616,
      "SHELF LIFE_1": 3.000000000000003,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": "47500NA04D",
      "DESCRIPTION": "AIR DRIER FILTER KIT",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.75,
      "MAINTANING STOCK QTY": 3,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 3,
      "APRD QTY": 3,
      "WH12": 1,
      "WHXX": 4,
      "WH REFERENCE": "WHXX",
      "TREND": 0.22,
      "STD": 1.9315199276138,
      "MEAN": 0.692307692307692,
      "COV": 2.78997322877549,
      "SHELF LIFE_1": 4,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": "276949Z00B",
      "DESCRIPTION": "CAB FILTER",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.666666666666666,
      "MAINTANING STOCK QTY": 3,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 3,
      "APRD QTY": 3,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 0.5,
      "STD": 1.44559454541845,
      "MEAN": 0.615384615384615,
      "COV": 2.34909113630498,
      "SHELF LIFE_1": 4.500000000000004,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": "797409Z00C",
      "DESCRIPTION": "WINDSHIELD - CRONER",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 1,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 15171144,
      "DESCRIPTION": "FILLER CAP",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 5260111183,
      "DESCRIPTION": "HEX. SOCKET SCR 805199Z02A ",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.666666666666666,
      "MAINTANING STOCK QTY": 3,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 3,
      "APRD QTY": 0,
      "WH12": 53,
      "WHXX": 33,
      "WH REFERENCE": "WHXX",
      "TREND": 0,
      "STD": 2.21880078490091,
      "MEAN": 0.615384615384615,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 4.500000000000004,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 5222842811,
      "DESCRIPTION": "5224247405 BRAKE CHAMBER 443109Z02A",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 60110035,
      "DESCRIPTION": "HEXAGON SCREW 983373 ",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 2,
      "MAINTANING STOCK QTY": 8,
      "AVAILABLE": 8,
      "SHELF LIFE": 4,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 27,
      "WHXX": 19,
      "WH REFERENCE": "",
      "TREND": 0.83,
      "STD": 3.78255103173669,
      "MEAN": 1.84615384615384,
      "COV": 2.04888180885737,
      "SHELF LIFE_1": 4,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 11443993,
      "DESCRIPTION": "53032790 CONTROL UNIT 11381209 SN 280199-  11388082 ",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 29070020651,
      "DESCRIPTION": "BRAKE DISC",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 5,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 0,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 4013000064,
      "DESCRIPTION": "LOCK NUT",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 1,
      "MAINTANING STOCK QTY": 4,
      "AVAILABLE": 4,
      "SHELF LIFE": 4,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 10,
      "WH REFERENCE": "",
      "TREND": 0.67,
      "STD": 2.39657875806111,
      "MEAN": 0.923076923076923,
      "COV": 2.5962936545662,
      "SHELF LIFE_1": 4,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 29090001031,
      "DESCRIPTION": "SCREW",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.666666666666666,
      "MAINTANING STOCK QTY": 3,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 3,
      "APRD QTY": 0,
      "WH12": 16,
      "WHXX": 5,
      "WH REFERENCE": "WHXX",
      "TREND": 0,
      "STD": 2.21880078490091,
      "MEAN": 0.615384615384615,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 4.500000000000004,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 6680161,
      "DESCRIPTION": "RUBBER TRACK",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 17438619,
      "DESCRIPTION": "FILTER ELEMENT 11445025 ",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 146,
      "WHXX": 31,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14881383,
      "DESCRIPTION": "RUBBER HOSE",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 8,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 997482,
      "DESCRIPTION": "HEX. SCREW 983743",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 10.6666666666666,
      "MAINTANING STOCK QTY": 43,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 43,
      "APRD QTY": 43,
      "WH12": 16,
      "WHXX": 0,
      "WH REFERENCE": "PARTIAL WH12",
      "TREND": 2,
      "STD": 27.1626157118141,
      "MEAN": 9.84615384615384,
      "COV": 2.75870315823112,
      "SHELF LIFE_1": 4.031250000000025,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 14558017,
      "DESCRIPTION": "COVER",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 4,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14640024,
      "DESCRIPTION": "ROLLER BEARING",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.5,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 2,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 6,
      "WH REFERENCE": "WHXX",
      "TREND": 1,
      "STD": 0.776250025806184,
      "MEAN": 0.461538461538461,
      "COV": 1.6818750559134,
      "SHELF LIFE_1": 4,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 5223058602,
      "DESCRIPTION": "5224122470 BRAKE CHAMBER",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 1,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 1,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14709151,
      "DESCRIPTION": "WIRING HARNESS",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 60193266,
      "DESCRIPTION": "HYDRAULIC RETURN FILTER * SCC550A / SCC750A",
      "PRODUCT CATEGORY": "YPG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 2,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 5,
      "WHXX": 4,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4110001531001,
      "DESCRIPTION": "SPIDER  4110000485001 ",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 17,
      "WHXX": 1,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 84092304,
      "DESCRIPTION": "WIPER MOTOR",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 1,
      "WHXX": 1,
      "WH REFERENCE": "WHXX",
      "TREND": 2,
      "STD": 0.375533808099405,
      "MEAN": 0.153846153846153,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 5229625,
      "DESCRIPTION": "SEALING RING",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 15185363,
      "DESCRIPTION": "O-RING",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 5,
      "WHXX": 5,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 5222931497,
      "DESCRIPTION": "CAM ROLLER",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 14709332,
      "DESCRIPTION": "SEAL 14559059 ",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 19,
      "WHXX": 7,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 29090014451,
      "DESCRIPTION": "REAR AXLE LG936L",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 5223058600,
      "DESCRIPTION": "BRAKE CHAMBER 5222842739 413209Z00E",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 1,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 2,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 17446531,
      "DESCRIPTION": "PLANETARY GEAR 14558054",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.25,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 6,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 2,
      "STD": 0.832050294337843,
      "MEAN": 0.23076923076923,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 4,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 6644965,
      "DESCRIPTION": "BALL JOINT",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14722573,
      "DESCRIPTION": "IDLER",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 60113080,
      "DESCRIPTION": "984734 FLANGE SCREW 13946173 ",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 6,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 29,
      "WHXX": 6,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 997446,
      "DESCRIPTION": "HEXAGON SCREW 978932",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 1.66666666666666,
      "MAINTANING STOCK QTY": 7,
      "AVAILABLE": 61,
      "SHELF LIFE": 36.60000000000014,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 41,
      "WHXX": 74,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 5.54700196225229,
      "MEAN": 1.53846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 36.60000000000014,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 997494,
      "DESCRIPTION": "SIX POINT SOCKET SCREW 969407",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 20,
      "WHXX": 16,
      "WH REFERENCE": "WHXX",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 1101312,
      "DESCRIPTION": "ELEMENT, MAIN",
      "PRODUCT CATEGORY": "JLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 1101313,
      "DESCRIPTION": "ELEMENT, SAFETY",
      "PRODUCT CATEGORY": "JLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 5222844507,
      "DESCRIPTION": "SPRING WASHER",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 151,
      "WHXX": 17,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 5222844485,
      "DESCRIPTION": "PLATE",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 87,
      "WHXX": 4,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4110000991019,
      "DESCRIPTION": "OIL COOLER",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 12,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4110000054215,
      "DESCRIPTION": "EXHAUST VALVE 4110000909080",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 13,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4110000054096,
      "DESCRIPTION": "INLET VALVE",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 13,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 5222523792,
      "DESCRIPTION": "CHARGE AIR HOSE",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 7,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 82355056,
      "DESCRIPTION": "ELECTRIC MOTOR",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.5,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 2,
      "APRD QTY": 2,
      "WH12": 8,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 2,
      "STD": 1.66410058867568,
      "MEAN": 0.461538461538461,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 4,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": "322739Z00D ",
      "DESCRIPTION": "BEARING",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 16632962,
      "DESCRIPTION": "PRIMARY FILTER",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 8,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 16632963,
      "DESCRIPTION": "SAFETY FILTER",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 6553411,
      "DESCRIPTION": "PRESS FIT BUSHING 5",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 60263488,
      "DESCRIPTION": "OIL FILTER",
      "PRODUCT CATEGORY": "YTG",
      "ITEM MOVEMENT BRANCH": "AVERAGE (F2)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 2.16666666666666,
      "MAINTANING STOCK QTY": 9,
      "AVAILABLE": 6,
      "SHELF LIFE": 2.769230769230778,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 3,
      "APRD QTY": 3,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 0.62,
      "STD": 2.82842712474619,
      "MEAN": 2,
      "COV": 1.41421356237309,
      "SHELF LIFE_1": 4.153846153846167,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 6715865,
      "DESCRIPTION": "BUSHING",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 0,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": "17C640",
      "DESCRIPTION": "BOLT-(MOQ 5PCS)",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 4,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 0,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": "97D6",
      "DESCRIPTION": "NUT-10",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 9,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 0,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 5222802271,
      "DESCRIPTION": "V-RIBBED BELT",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 1.83333333333333,
      "MAINTANING STOCK QTY": 7,
      "AVAILABLE": 6,
      "SHELF LIFE": 3.2727272727272787,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 2,
      "WHXX": 16,
      "WH REFERENCE": "WHXX",
      "TREND": 1.91,
      "STD": 5.51338697149364,
      "MEAN": 1.69230769230769,
      "COV": 3.25791048315533,
      "SHELF LIFE_1": 3.8181818181818254,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 5222923707,
      "DESCRIPTION": "IDLER PULLEY",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 4,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 12,
      "WHXX": 5,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 5222845321,
      "DESCRIPTION": "RUBBER SPRING 552419Z00C ",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 20,
      "WHXX": 20,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 12815454,
      "DESCRIPTION": "CONTROL CABLE",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 3,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 5223236938,
      "DESCRIPTION": "SEALING RING 432359Z00A",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3,
      "WHXX": 8,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 16668474,
      "DESCRIPTION": "15711010 FUEL FILTER",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 17201956,
      "DESCRIPTION": "FILTER CARTRIDGE",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 5,
      "WHXX": 13,
      "WH REFERENCE": "WHXX",
      "TREND": 1,
      "STD": 0.375533808099405,
      "MEAN": 0.153846153846153,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 17324107,
      "DESCRIPTION": "15714574 FILTER CARTRIDGE 43977461 ",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 2,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 0,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 6519178,
      "DESCRIPTION": "BUMPER",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 16,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 0,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": "A210609000067",
      "DESCRIPTION": "O-RING-53",
      "PRODUCT CATEGORY": "YCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 10182105,
      "DESCRIPTION": "RETAINER RING",
      "PRODUCT CATEGORY": "YCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": "B230101001155",
      "DESCRIPTION": "SEAL RING",
      "PRODUCT CATEGORY": "YCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "B230101001370",
      "DESCRIPTION": "GUIDE RING",
      "PRODUCT CATEGORY": "YCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "B230101001158",
      "DESCRIPTION": "GUIDE RING",
      "PRODUCT CATEGORY": "YCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 10250733,
      "DESCRIPTION": "BACK-UP RING",
      "PRODUCT CATEGORY": "YCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 44,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 5222827993,
      "DESCRIPTION": "SENSOR 2269100Z2C",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 1,
      "SHELF LIFE": 12.000000000000005,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 6,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 12.000000000000005,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 4130001873,
      "DESCRIPTION": "FUSE AND RELAY UNIT",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14585975,
      "DESCRIPTION": "FAN SHROUD",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 1,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14725906,
      "DESCRIPTION": "GAUGE 14532362 1141-01720",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 1.25,
      "MAINTANING STOCK QTY": 5,
      "AVAILABLE": 2,
      "SHELF LIFE": 1.6,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 3,
      "APRD QTY": 3,
      "WH12": 22,
      "WHXX": 23,
      "WH REFERENCE": "WHXX",
      "TREND": 1.2,
      "STD": 1.72463299700515,
      "MEAN": 1.15384615384615,
      "COV": 1.49468193073779,
      "SHELF LIFE_1": 4,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 15088068,
      "DESCRIPTION": "BRAKE KIT L120F",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 6680695,
      "DESCRIPTION": "SEAL",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 2,
      "STD": 1.10940039245045,
      "MEAN": 0.307692307692307,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 3.000000000000003,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 16681896,
      "DESCRIPTION": "RADIATOR GRILLE",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 5223236940,
      "DESCRIPTION": "SEALING RING 432359Z00B",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 1,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 3,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4190002281,
      "DESCRIPTION": "DOOR LOCK",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 22923707,
      "DESCRIPTION": "IDLER PULLEY 8086970",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 10,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 20992763,
      "DESCRIPTION": "AIR HOSE",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 11,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 880500,
      "DESCRIPTION": "ELECTRICAL CONTROL HARNESS ASSEMBLY",
      "PRODUCT CATEGORY": "PHG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4030000707,
      "DESCRIPTION": "O-RING",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 24,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4110000076050,
      "DESCRIPTION": "SEALING RING",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 4,
      "WHXX": 9,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4030000611,
      "DESCRIPTION": "O-RING",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 5,
      "WHXX": 17,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 22221048,
      "DESCRIPTION": "BUSHING",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.666666666666666,
      "MAINTANING STOCK QTY": 3,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 3,
      "APRD QTY": 3,
      "WH12": 10,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 2,
      "STD": 1.70970082853022,
      "MEAN": 0.615384615384615,
      "COV": 2.7782638463616,
      "SHELF LIFE_1": 4.500000000000004,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 29070007661,
      "DESCRIPTION": "PLANET CARRIER",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 3050900037,
      "DESCRIPTION": "GEAR RING 29070015341",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 3,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 3050900040,
      "DESCRIPTION": "SUN GEAR",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 2,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 997500,
      "DESCRIPTION": "SCREW ",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 1,
      "MAINTANING STOCK QTY": 4,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 4,
      "APRD QTY": 4,
      "WH12": 0,
      "WHXX": 27,
      "WH REFERENCE": "WHXX",
      "TREND": 2,
      "STD": 3.32820117735137,
      "MEAN": 0.923076923076923,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 4,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 4110002852014,
      "DESCRIPTION": "AIR FILTER INSERT",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 1,
      "SHELF LIFE": 3.000000000000003,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 3,
      "WH REFERENCE": "",
      "TREND": 1.5,
      "STD": 0.85485041426511,
      "MEAN": 0.307692307692307,
      "COV": 2.7782638463616,
      "SHELF LIFE_1": 3.000000000000003,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 5223236891,
      "DESCRIPTION": "SEALING RING 400359Z00B",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.25,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 5,
      "SHELF LIFE": 20,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 129,
      "WHXX": 14,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.438529009653514,
      "MEAN": 0.23076923076923,
      "COV": 1.90029237516522,
      "SHELF LIFE_1": 20,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": "A210609000354",
      "DESCRIPTION": "O-RING (175A)",
      "PRODUCT CATEGORY": "YPG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.916666666666666,
      "MAINTANING STOCK QTY": 4,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 4,
      "APRD QTY": 4,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 2,
      "STD": 2.7642405179408,
      "MEAN": 0.846153846153846,
      "COV": 3.26682970302095,
      "SHELF LIFE_1": 4.363636363636367,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 612600114993,
      "DESCRIPTION": "TP612600114993 AIR FILTER ASSY",
      "PRODUCT CATEGORY": "SDG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 960698,
      "DESCRIPTION": "FILTER, OIL ENG",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.25,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 59,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 2,
      "STD": 0.599144689515278,
      "MEAN": 0.23076923076923,
      "COV": 2.5962936545662,
      "SHELF LIFE_1": 4,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 6911907,
      "DESCRIPTION": "FUEL FILTER ELEMENT KIT 7406650",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 2,
      "STD": 0.75106761619881,
      "MEAN": 0.307692307692307,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 3.000000000000003,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 7251998,
      "DESCRIPTION": "FILTER, FUEL",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 12,
      "WHXX": 1,
      "WH REFERENCE": "WHXX",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 14702254,
      "DESCRIPTION": "HORN 14654753 14545503",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 2,
      "SHELF LIFE": 6.000000000000006,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 19,
      "WHXX": 1,
      "WH REFERENCE": "",
      "TREND": 1,
      "STD": 0.480384461415261,
      "MEAN": 0.307692307692307,
      "COV": 1.56124949959959,
      "SHELF LIFE_1": 6.000000000000006,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 4120007012,
      "DESCRIPTION": "SPRING BRAKE CHAMBER",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 997858,
      "DESCRIPTION": "HEX SOCKET SCREW 984079",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 5,
      "SHELF LIFE": 60.00000000000002,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 315,
      "WHXX": 27,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 60.00000000000002,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 23140886,
      "DESCRIPTION": "CONTROL UNIT 21855896",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14701419,
      "DESCRIPTION": "SWITCH",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 3,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "308569Z01B",
      "DESCRIPTION": "308009Z00C HOSE ASSEMBLY",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 16,
      "WHXX": 21,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14523095,
      "DESCRIPTION": "BUSHING",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 1,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 9,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 997410,
      "DESCRIPTION": "HEXAGON NUT 96703657 979021 955785",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 224,
      "WHXX": 29,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 997868,
      "DESCRIPTION": "HEX. SOCKET SCREW 992542",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 88,
      "WHXX": 12,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14722625,
      "DESCRIPTION": "SOLENOID COIL",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 2,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14577460,
      "DESCRIPTION": "RELAY",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 23,
      "WHXX": 5,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14708320,
      "DESCRIPTION": "TOP ROLLER",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 6,
      "WH REFERENCE": "WHXX",
      "TREND": 1,
      "STD": 0.375533808099405,
      "MEAN": 0.153846153846153,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 14709480,
      "DESCRIPTION": "IDLER 14656780",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 6,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14713823,
      "DESCRIPTION": "SEAL",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3,
      "WHXX": 2,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 22068223,
      "DESCRIPTION": "WIRING HARNESS",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 1,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 997513,
      "DESCRIPTION": "HEX. SOCKET SCREW 984084 ",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 40,
      "WHXX": 31,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 17501886,
      "DESCRIPTION": "SOLENOID COIL",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 5,
      "WHXX": 3,
      "WH REFERENCE": "WHXX",
      "TREND": 2,
      "STD": 0.375533808099405,
      "MEAN": 0.153846153846153,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 6716621,
      "DESCRIPTION": "FUSE COVER",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 6701535,
      "DESCRIPTION": "CENTERING SPRING",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 0,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 996777,
      "DESCRIPTION": "FLANGE SCREW 20412324 ",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 15,
      "WHXX": 6,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 5223058616,
      "DESCRIPTION": "BRAKE CHAMBER 5222842686",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 2,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 14,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 60034238,
      "DESCRIPTION": "BELT",
      "PRODUCT CATEGORY": "YCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4130002912,
      "DESCRIPTION": "PANEL 4190002760 ",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.25,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 6,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 2,
      "STD": 0.599144689515278,
      "MEAN": 0.23076923076923,
      "COV": 2.5962936545662,
      "SHELF LIFE_1": 4,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 5223236939,
      "DESCRIPTION": "SEALING RING 430519Z00D",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 1,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 1,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "164109Z00D",
      "DESCRIPTION": "BOWL",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 5222847639,
      "DESCRIPTION": "SPRING LEAF (LEAF 3)",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 5222847640,
      "DESCRIPTION": "SPRING LEAF (LEAF 4)",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 12782905,
      "DESCRIPTION": "COVER",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 60113303,
      "DESCRIPTION": "PLAIN WASHER 930853 906837",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.666666666666666,
      "MAINTANING STOCK QTY": 3,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 3,
      "APRD QTY": 3,
      "WH12": 95,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 2,
      "STD": 2.21880078490091,
      "MEAN": 0.615384615384615,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 4.500000000000004,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 4110002791,
      "DESCRIPTION": "SEALING RING",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 3,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14700703,
      "DESCRIPTION": "SEALING KIT",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.25,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 1,
      "SHELF LIFE": 4,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 5,
      "WHXX": 1,
      "WH REFERENCE": "",
      "TREND": 0.67,
      "STD": 0.599144689515278,
      "MEAN": 0.23076923076923,
      "COV": 2.5962936545662,
      "SHELF LIFE_1": 4,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 997963,
      "DESCRIPTION": "HEXAGON SCREW 991639 ",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 2,
      "MAINTANING STOCK QTY": 8,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 8,
      "APRD QTY": 8,
      "WH12": 0,
      "WHXX": 58,
      "WH REFERENCE": "WHXX",
      "TREND": 2,
      "STD": 6.65640235470275,
      "MEAN": 1.84615384615384,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 4,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 4110001525036,
      "DESCRIPTION": "GASKET",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 2,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14619031,
      "DESCRIPTION": "CHAIN",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 2,
      "STD": 0.75106761619881,
      "MEAN": 0.307692307692307,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 3.000000000000003,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 997990,
      "DESCRIPTION": "SCREW 992968",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 8.66666666666666,
      "MAINTANING STOCK QTY": 35,
      "AVAILABLE": 35,
      "SHELF LIFE": 4.038461538461541,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 126,
      "WHXX": 146,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 23.4946802489414,
      "MEAN": 8,
      "COV": 2.93683503111768,
      "SHELF LIFE_1": 4.038461538461541,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 14663554,
      "DESCRIPTION": "TOGGLE SWITCH",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4110001929,
      "DESCRIPTION": "CAP",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 11204727,
      "DESCRIPTION": "REAR WINDOW",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14530462,
      "DESCRIPTION": "NUT",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 3,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 60110287,
      "DESCRIPTION": "PLAIN WASHER 992047",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 4.33333333333333,
      "MAINTANING STOCK QTY": 17,
      "AVAILABLE": 9,
      "SHELF LIFE": 2.0769230769230784,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 8,
      "APRD QTY": 8,
      "WH12": 525,
      "WHXX": 72,
      "WH REFERENCE": "WHXX",
      "TREND": 1.85,
      "STD": 13.2664991614216,
      "MEAN": 4,
      "COV": 3.3166247903554,
      "SHELF LIFE_1": 3.9230769230769256,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": "259459Z00C",
      "DESCRIPTION": "CONTROL UNIT",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "A221500000266",
      "DESCRIPTION": "BALANCE BEAM BEARING",
      "PRODUCT CATEGORY": "YCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 4,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 22988765,
      "DESCRIPTION": "23044511 FUEL FILTER 21879886 ",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 1.5,
      "MAINTANING STOCK QTY": 6,
      "AVAILABLE": 17,
      "SHELF LIFE": 11.333333333333334,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 8,
      "WHXX": 6,
      "WH REFERENCE": "",
      "TREND": 0.11,
      "STD": 2.63117405792108,
      "MEAN": 1.38461538461538,
      "COV": 1.90029237516522,
      "SHELF LIFE_1": 11.333333333333334,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 4110002902,
      "DESCRIPTION": "FAN",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.25,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 0,
      "STD": 0.438529009653514,
      "MEAN": 0.23076923076923,
      "COV": 1.90029237516522,
      "SHELF LIFE_1": 4,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 29290037461,
      "DESCRIPTION": "GLASS",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "963019Z01D",
      "DESCRIPTION": "MIRROR KIT",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 60110300,
      "DESCRIPTION": "WASHER 992057",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 8.66666666666666,
      "MAINTANING STOCK QTY": 35,
      "AVAILABLE": 27,
      "SHELF LIFE": 3.1153846153846176,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 8,
      "APRD QTY": 8,
      "WH12": 431,
      "WHXX": 231,
      "WH REFERENCE": "WHXX",
      "TREND": 2,
      "STD": 23.4946802489414,
      "MEAN": 8,
      "COV": 2.93683503111768,
      "SHELF LIFE_1": 4.038461538461541,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 14681250,
      "DESCRIPTION": "14797204 IDLER ",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 2,
      "STD": 1.10940039245045,
      "MEAN": 0.307692307692307,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 3.000000000000003,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": "282299Z05B",
      "DESCRIPTION": "FLANGE SCREW",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 23,
      "WHXX": 35,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 5227334,
      "DESCRIPTION": "ROLLER BEARING",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.5,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 2,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 3,
      "WH REFERENCE": "WHXX",
      "TREND": 0.67,
      "STD": 0.877058019307029,
      "MEAN": 0.461538461538461,
      "COV": 1.90029237516522,
      "SHELF LIFE_1": 4,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 14560265,
      "DESCRIPTION": "ROLLER BEARING",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.5,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 2,
      "SHELF LIFE": 4,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 9,
      "WH REFERENCE": "",
      "TREND": 0.67,
      "STD": 0.877058019307029,
      "MEAN": 0.461538461538461,
      "COV": 1.90029237516522,
      "SHELF LIFE_1": 4,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 14560271,
      "DESCRIPTION": "PISTON",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 5,
      "WH REFERENCE": "WHXX",
      "TREND": 1,
      "STD": 0.375533808099405,
      "MEAN": 0.153846153846153,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 17444453,
      "DESCRIPTION": "PIN",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.5,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 2,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 19,
      "WH REFERENCE": "WHXX",
      "TREND": 1,
      "STD": 1.12660142429821,
      "MEAN": 0.461538461538461,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 4,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 17230686,
      "DESCRIPTION": "PLANET CARRIER",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 60099313,
      "DESCRIPTION": "WIDE FILTER NET ASSEMBLY",
      "PRODUCT CATEGORY": "YCG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.5,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 2,
      "SHELF LIFE": 4,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 7,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 1.66410058867568,
      "MEAN": 0.461538461538461,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 4,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": "8230-25680",
      "DESCRIPTION": "PLUG",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "4120006349Z",
      "DESCRIPTION": "AIR CYLINDER",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3,
      "WHXX": 1,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4190002589,
      "DESCRIPTION": "WIPER BLADE",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 5,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4130002221,
      "DESCRIPTION": "FUEL LEVEL SENSOR",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14716435,
      "DESCRIPTION": "SHIM 1173-00180",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 50,
      "WHXX": 9,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 22943668,
      "DESCRIPTION": "SWITCH 21967897          ",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14719601,
      "DESCRIPTION": "BREATHER 1040-20311",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.25,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 1,
      "SHELF LIFE": 4,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 7,
      "WHXX": 6,
      "WH REFERENCE": "",
      "TREND": 0.67,
      "STD": 0.599144689515278,
      "MEAN": 0.23076923076923,
      "COV": 2.5962936545662,
      "SHELF LIFE_1": 4,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 7289106,
      "DESCRIPTION": "OIL FILTER",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 7289231,
      "DESCRIPTION": "FUEL PREFILTER KIT",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 1,
      "SHELF LIFE": 6.000000000000024,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": "23Y-62B-00000P020",
      "DESCRIPTION": "LIFT CYLINDER, REPAIR KIT",
      "PRODUCT CATEGORY": "SDG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 4,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 5220374662,
      "DESCRIPTION": "RELAY",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 13,
      "WHXX": 2,
      "WH REFERENCE": "WHXX",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 29070021581,
      "DESCRIPTION": "BRAKE DISC",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 0,
      "STD": 0.375533808099405,
      "MEAN": 0.153846153846153,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 60110002,
      "DESCRIPTION": "HEXAGON SCREW 990520",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 143,
      "WHXX": 22,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 7289295,
      "DESCRIPTION": "INNER AIR FILTER",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 1,
      "SHELF LIFE": 6.000000000000024,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 7290371,
      "DESCRIPTION": "HYDRAULIC RETURN FILTER",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 1,
      "SHELF LIFE": 6.000000000000024,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 4,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 7290377,
      "DESCRIPTION": "HYDRAULIC RETURN FILTER",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 1,
      "SHELF LIFE": 6.000000000000024,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 5,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 7291588,
      "DESCRIPTION": "STABILIZER CYLINDER REPAIR KIT",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 1,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 7291602,
      "DESCRIPTION": "BOOM CYLINDER REPAIR KIT",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 60113296,
      "DESCRIPTION": "WASHER 13940090",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 169,
      "WHXX": 19,
      "WH REFERENCE": "WHXX",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 4120001739090,
      "DESCRIPTION": "PIN",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 9,
      "WHXX": 10,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 96702386,
      "DESCRIPTION": "HEXAGON SCREW",
      "PRODUCT CATEGORY": "VCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 6,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 2,
      "STD": 1.10940039245045,
      "MEAN": 0.307692307692307,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 3.000000000000003,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 4110000970022,
      "DESCRIPTION": "V-BELT 4110000970219 ",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 8,
      "WHXX": 10,
      "WH REFERENCE": "WHXX",
      "TREND": 1,
      "STD": 0.375533808099405,
      "MEAN": 0.153846153846153,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 7332632,
      "DESCRIPTION": "THERMOSTAT ASSY",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14729840,
      "DESCRIPTION": "SEAL 14601683 7117-30120 14528713",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.416666666666666,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 2,
      "SHELF LIFE": 4.800000000000008,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 4,
      "WHXX": 9,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.65044363558799,
      "MEAN": 0.384615384615384,
      "COV": 1.69115345252877,
      "SHELF LIFE_1": 4.800000000000008,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 12829641,
      "DESCRIPTION": "WIRING HARNESS",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 1,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "ADBLUE20L",
      "DESCRIPTION": "ADBLUE - UREA SOLUTION",
      "PRODUCT CATEGORY": "ZZZ",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 60110301,
      "DESCRIPTION": "WASHER 992049 ",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 16,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 0,
      "STD": 1.10940039245045,
      "MEAN": 0.307692307692307,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 3.000000000000003,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 29290039031,
      "DESCRIPTION": "GLASS",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 23260129,
      "DESCRIPTION": "FILTER",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 1,
      "SHELF LIFE": 6.000000000000024,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 4110002816,
      "DESCRIPTION": "WHEEL RIM",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 0,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 60193267,
      "DESCRIPTION": "ELEMENT GP OIL RETURN FILTER",
      "PRODUCT CATEGORY": "YPG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 4,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4120007867004,
      "DESCRIPTION": "SEALING KIT,TILT 3713CH",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 2,
      "MAINTANING STOCK QTY": 8,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 8,
      "APRD QTY": 8,
      "WH12": 0,
      "WHXX": 2,
      "WH REFERENCE": "PARTIAL WHXX",
      "TREND": 2,
      "STD": 6.65640235470275,
      "MEAN": 1.84615384615384,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 4,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 4120006628004,
      "DESCRIPTION": "SEAL KIT, LIFT  3713CH",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.666666666666666,
      "MAINTANING STOCK QTY": 3,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 3,
      "APRD QTY": 3,
      "WH12": 8,
      "WHXX": 3,
      "WH REFERENCE": "WHXX",
      "TREND": 2,
      "STD": 2.21880078490091,
      "MEAN": 0.615384615384615,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 4.500000000000004,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 12829642,
      "DESCRIPTION": "WIRING HARNESS",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 1,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14883743,
      "DESCRIPTION": "SET SCREW",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 98,
      "WHXX": 4,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 16232085,
      "DESCRIPTION": "CONDENSER",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.25,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 0,
      "WHXX": 2,
      "WH REFERENCE": "WHXX",
      "TREND": 2,
      "STD": 0.438529009653514,
      "MEAN": 0.23076923076923,
      "COV": 1.90029237516522,
      "SHELF LIFE_1": 4,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 60110385,
      "DESCRIPTION": "PLAIN WASHER 14522862  1012-00160",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 36,
      "WHXX": 12,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 12824620,
      "DESCRIPTION": "CONTROL",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 11143309,
      "DESCRIPTION": "SEALING RING",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "FS36230",
      "DESCRIPTION": "FUEL WATER SEPARATOR",
      "PRODUCT CATEGORY": "CUF",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 1,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4120006027,
      "DESCRIPTION": "WATER SEPARATOR",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 2,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 18,
      "WHXX": 10,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14689737,
      "DESCRIPTION": "AIR CONDITIONING UNIT 14670627 14532728",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 1,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "01643-32060",
      "DESCRIPTION": "WASHER-01643-51845",
      "PRODUCT CATEGORY": "SDG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 9.33333333333333,
      "MAINTANING STOCK QTY": 37,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 37,
      "APRD QTY": 37,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 2,
      "STD": 26.6256094188109,
      "MEAN": 8.61538461538461,
      "COV": 3.09047252182627,
      "SHELF LIFE_1": 3.9642857142857157,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": "308569Z01C",
      "DESCRIPTION": "HOSE ASSEMBLY",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 4,
      "WHXX": 4,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 16052009,
      "DESCRIPTION": "CONTROL CABLE",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 6,
      "WH REFERENCE": "WHXX",
      "TREND": 1,
      "STD": 0.375533808099405,
      "MEAN": 0.153846153846153,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 12809850,
      "DESCRIPTION": "SHOCK ABSORBER 13304787",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 3.33333333333333,
      "MAINTANING STOCK QTY": 13,
      "AVAILABLE": 4,
      "SHELF LIFE": 1.2000000000000013,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 9,
      "APRD QTY": 9,
      "WH12": 25,
      "WHXX": 77,
      "WH REFERENCE": "WHXX",
      "TREND": 1.2,
      "STD": 3.70723478398519,
      "MEAN": 3.07692307692307,
      "COV": 1.20485130479518,
      "SHELF LIFE_1": 3.900000000000004,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 23260134,
      "DESCRIPTION": "23690622 AIR DRIER FILTER KIT 22223804 ",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 4,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4043001505,
      "DESCRIPTION": "WELD PIN",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 9,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 0,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 4043001508,
      "DESCRIPTION": "BUSHING",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 7,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 0,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 14710741,
      "DESCRIPTION": "WIRING HARNESS 14687616",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 15,
      "WHXX": 5,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4190000412,
      "DESCRIPTION": "RIM",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 35,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 20515171,
      "DESCRIPTION": "PLUG",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 1,
      "SHELF LIFE": 12.000000000000005,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 6,
      "WHXX": 5,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 12.000000000000005,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 995177,
      "DESCRIPTION": "HEX. SOCKET SCREW",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.666666666666666,
      "MAINTANING STOCK QTY": 3,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 3,
      "APRD QTY": 3,
      "WH12": 24,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 2,
      "STD": 2.21880078490091,
      "MEAN": 0.615384615384615,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 4.500000000000004,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 14558097,
      "DESCRIPTION": "SCREW",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 7,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14558071,
      "DESCRIPTION": "FLANGE",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "8320-04230",
      "DESCRIPTION": "SCREW",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 11,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 60125019,
      "DESCRIPTION": "DIESEL COARSE FILTER CORE",
      "PRODUCT CATEGORY": "YCG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.416666666666666,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 1,
      "SHELF LIFE": 2.400000000000004,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 0.4,
      "STD": 0.767947647788304,
      "MEAN": 0.384615384615384,
      "COV": 1.99666388424959,
      "SHELF LIFE_1": 4.800000000000008,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 5222845443,
      "DESCRIPTION": "5223994830 DISC",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14530832,
      "DESCRIPTION": "SEAL",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14716434,
      "DESCRIPTION": "SHIM 1173-00190",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 33,
      "WHXX": 27,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 46696397,
      "DESCRIPTION": "PRIMARY AIR FILTER",
      "PRODUCT CATEGORY": "DOP",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 1.16666666666666,
      "MAINTANING STOCK QTY": 5,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 5,
      "APRD QTY": 5,
      "WH12": 10,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 0.71,
      "STD": 2.62873665669583,
      "MEAN": 1.07692307692307,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 4.28571428571431,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 46692659,
      "DESCRIPTION": "BALLAST",
      "PRODUCT CATEGORY": "DOP",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4110003706,
      "DESCRIPTION": "WATER SEPARATOR  4110001593",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 60113094,
      "DESCRIPTION": "990861 FLANGE SCREW",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.416666666666666,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 2,
      "APRD QTY": 2,
      "WH12": 0,
      "WHXX": 3,
      "WH REFERENCE": "WHXX",
      "TREND": 2,
      "STD": 1.38675049056307,
      "MEAN": 0.384615384615384,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 4.800000000000008,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 5284811166,
      "DESCRIPTION": "TEMPERATURE SENSOR 277309Z00A",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 4,
      "SHELF LIFE": 24.000000000000096,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 58,
      "WHXX": 9,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.375533808099405,
      "MEAN": 0.153846153846153,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 24.000000000000096,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 5222845441,
      "DESCRIPTION": "DISC",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 1.08333333333333,
      "MAINTANING STOCK QTY": 4,
      "AVAILABLE": 3,
      "SHELF LIFE": 2.769230769230778,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 1.38,
      "STD": 2.76887462097269,
      "MEAN": 1,
      "COV": 2.76887462097269,
      "SHELF LIFE_1": 3.692307692307704,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 60358721,
      "DESCRIPTION": "FUEL/WATER SEPARATOR FILTER CORE (SANY LOGO)",
      "PRODUCT CATEGORY": "YPG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 9,
      "SHELF LIFE": 27.00000000000003,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 1,
      "STD": 0.75106761619881,
      "MEAN": 0.307692307692307,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 27.00000000000003,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 60327521,
      "DESCRIPTION": "DIESEL COARSE FILTER CORE",
      "PRODUCT CATEGORY": "YPG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.583333333333333,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 2,
      "APRD QTY": 2,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 0.57,
      "STD": 1.19828937903055,
      "MEAN": 0.538461538461538,
      "COV": 2.22539456105674,
      "SHELF LIFE_1": 3.42857142857143,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 60328867,
      "DESCRIPTION": "ENGINE OIL FILTER",
      "PRODUCT CATEGORY": "YCG",
      "ITEM MOVEMENT BRANCH": "AVERAGE (F2)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 3.25,
      "MAINTANING STOCK QTY": 13,
      "AVAILABLE": 7,
      "SHELF LIFE": 2.1538461538461537,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 6,
      "APRD QTY": 6,
      "WH12": 41,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 0.72,
      "STD": 3.60555127546398,
      "MEAN": 3,
      "COV": 1.20185042515466,
      "SHELF LIFE_1": 4,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 11701471,
      "DESCRIPTION": "SEALING RING 8230-02520 ",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3,
      "WHXX": 3,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4110000509047,
      "DESCRIPTION": "OIL FILLER CAP",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 70868286,
      "DESCRIPTION": "V-BELT",
      "PRODUCT CATEGORY": "DOP",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 4,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 24,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 60110299,
      "DESCRIPTION": "WASHER 992045",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 16,
      "SHELF LIFE": 48.00000000000005,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 242,
      "WHXX": 314,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 1.10940039245045,
      "MEAN": 0.307692307692307,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 48.00000000000005,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 14574620,
      "DESCRIPTION": "AXLE",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 997420,
      "DESCRIPTION": "HEXAGON SCREW",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 2,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 36,
      "WHXX": 27,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 46676925,
      "DESCRIPTION": "BREAKER, CIRCUIT WITH BATON HANDLE, 15A",
      "PRODUCT CATEGORY": "DOP",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 2,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": "1112-00830",
      "DESCRIPTION": "PLATE",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 0,
      "WHXX": 8,
      "WH REFERENCE": "WHXX",
      "TREND": 2,
      "STD": 1.10940039245045,
      "MEAN": 0.307692307692307,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 3.000000000000003,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 4110001007015,
      "DESCRIPTION": "4110002513029  ALTERNATOR",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 82461903,
      "DESCRIPTION": "PIPE                     ",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 3,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 29050011363,
      "DESCRIPTION": "GASKET 29050011362",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 14,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14539459,
      "DESCRIPTION": "COVER",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 60169400,
      "DESCRIPTION": "CONDENSER CORE ASSY.",
      "PRODUCT CATEGORY": "YPG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14404757,
      "DESCRIPTION": "WATER PUMP",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 5223138604,
      "DESCRIPTION": "LEAF SPRING  5222834630 ",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 1.16666666666666,
      "MAINTANING STOCK QTY": 5,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 5,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 1,
      "WH REFERENCE": "PARTIAL WHXX + WH12",
      "TREND": 0.29,
      "STD": 2.7827306272059,
      "MEAN": 1.07692307692307,
      "COV": 2.58396415383405,
      "SHELF LIFE_1": 4.28571428571431,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 22944256,
      "DESCRIPTION": "FLANGE NUT 20382386 ",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.666666666666666,
      "MAINTANING STOCK QTY": 3,
      "AVAILABLE": 3,
      "SHELF LIFE": 4.500000000000004,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 158,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 2.21880078490091,
      "MEAN": 0.615384615384615,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 4.500000000000004,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 17496167,
      "DESCRIPTION": "DIRECTIONAL VALVE",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14701773,
      "DESCRIPTION": "VALVE",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 1,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 3,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 60113115,
      "DESCRIPTION": "FLANGE SCREW",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.833333333333333,
      "MAINTANING STOCK QTY": 3,
      "AVAILABLE": 8,
      "SHELF LIFE": 9.600000000000003,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 218,
      "WHXX": 10,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 2.77350098112614,
      "MEAN": 0.769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 9.600000000000003,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 7221530,
      "DESCRIPTION": "HORN, ALARM",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14727432,
      "DESCRIPTION": "WIRING HARNESS",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14722918,
      "DESCRIPTION": "GAUGE 14588061 14514385",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 10,
      "WHXX": 6,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14883766,
      "DESCRIPTION": "HOSE CLAMP",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 9,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14701764,
      "DESCRIPTION": "HOSE",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14701774,
      "DESCRIPTION": "BRACKET",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14587635,
      "DESCRIPTION": "CLIP",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3,
      "WHXX": 17,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4110003497,
      "DESCRIPTION": "CONTROL MECHANISM",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 20860677,
      "DESCRIPTION": "SEALING RING",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 20,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 5223058608,
      "DESCRIPTION": "BRAKE CHAMBER 5222842699 ",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 23236852,
      "DESCRIPTION": "FRONT SPRING 257952 ",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14561414,
      "DESCRIPTION": "SWITCH",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14656788,
      "DESCRIPTION": "PIN",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 2,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 6,
      "WHXX": 5,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 29040008451,
      "DESCRIPTION": "SEALING RING",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 12,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 997412,
      "DESCRIPTION": "HEXAGON NUT 980521 983312 96703681",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 2.16666666666666,
      "MAINTANING STOCK QTY": 9,
      "AVAILABLE": 9,
      "SHELF LIFE": 4.153846153846167,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 7,
      "WHXX": 64,
      "WH REFERENCE": "",
      "TREND": 0.77,
      "STD": 4.54606056566195,
      "MEAN": 2,
      "COV": 2.27303028283097,
      "SHELF LIFE_1": 4.153846153846167,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 14661334,
      "DESCRIPTION": "CUSHION 14603125 ",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 0,
      "WHXX": 8,
      "WH REFERENCE": "WHXX",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 14661333,
      "DESCRIPTION": "CUSHION 14603124 ",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 0,
      "WHXX": 8,
      "WH REFERENCE": "WHXX",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 84472607,
      "DESCRIPTION": "COMPRESSOR",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 1,
      "SHELF LIFE": 6.000000000000024,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 997983,
      "DESCRIPTION": "HEXAGON SCREW",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 120,
      "WHXX": 6,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 20569635,
      "DESCRIPTION": "SHIM 24426017 ",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 0,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 60113082,
      "DESCRIPTION": "FLANGE SCREW",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 6,
      "WHXX": 24,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 7000987,
      "DESCRIPTION": "BUCKET TOOTH-PIN ON BUCKET",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 12,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "1CM1450",
      "DESCRIPTION": "SCREW",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 16,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 997437,
      "DESCRIPTION": "HEXAGON SCREW",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 1.33333333333333,
      "MAINTANING STOCK QTY": 5,
      "AVAILABLE": 5,
      "SHELF LIFE": 3.7500000000000098,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 12,
      "WHXX": 31,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 4.43760156980183,
      "MEAN": 1.23076923076923,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 3.7500000000000098,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 60328868,
      "DESCRIPTION": "FUEL/WATER SEPARATOR FILTER CORE",
      "PRODUCT CATEGORY": "YCG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.583333333333333,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 2,
      "APRD QTY": 2,
      "WH12": 9,
      "WHXX": 2,
      "WH REFERENCE": "WHXX",
      "TREND": 0.86,
      "STD": 1.12660142429821,
      "MEAN": 0.538461538461538,
      "COV": 2.0922597879824,
      "SHELF LIFE_1": 3.42857142857143,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 96718598,
      "DESCRIPTION": "PLAIN WASHER",
      "PRODUCT CATEGORY": "VCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 0,
      "WHXX": 35,
      "WH REFERENCE": "WHXX",
      "TREND": 2,
      "STD": 1.10940039245045,
      "MEAN": 0.307692307692307,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 3.000000000000003,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 5222737238,
      "DESCRIPTION": "BUSHING",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 100,
      "WHXX": 17,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4190002761,
      "DESCRIPTION": "WIRING HARNESS",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 2,
      "STD": 0.375533808099405,
      "MEAN": 0.153846153846153,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 4130002496,
      "DESCRIPTION": "FUEL LEVEL SENSOR",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 11,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 29250001081,
      "DESCRIPTION": "PIN",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 4,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4011001250,
      "DESCRIPTION": "SCREW",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 6,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 50,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4015000428,
      "DESCRIPTION": "WASHER",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 10,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 3,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 29250001031,
      "DESCRIPTION": "FLANGE",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 29250001051,
      "DESCRIPTION": "FLANGE",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 29250017661,
      "DESCRIPTION": "PIN",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 22,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 29250009441,
      "DESCRIPTION": "SLEEVE",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 29250000091,
      "DESCRIPTION": "FLANGE",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 13,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 29250009451,
      "DESCRIPTION": "SLEEVE",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3,
      "WHXX": 1,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 29250009461,
      "DESCRIPTION": "FLANGE",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 29250009421,
      "DESCRIPTION": "SLEEVE",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14540123,
      "DESCRIPTION": "DIFFERENTIAL",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 1,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "7114-12530",
      "DESCRIPTION": "PIN",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 24,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "7114-12560",
      "DESCRIPTION": "RING",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.416666666666666,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 2,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 6,
      "WH REFERENCE": "WHXX",
      "TREND": 0.8,
      "STD": 0.767947647788304,
      "MEAN": 0.384615384615384,
      "COV": 1.99666388424959,
      "SHELF LIFE_1": 4.800000000000008,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": "927839Z00D",
      "DESCRIPTION": "MAGNETIC PLUG",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 18,
      "SHELF LIFE": 108.00000000000044,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 9,
      "WHXX": 28,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 108.00000000000044,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 14679698,
      "DESCRIPTION": "LOCK BRACE",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14709863,
      "DESCRIPTION": "SEALING",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4110001782004,
      "DESCRIPTION": "OIL FILTER",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 93,
      "WHXX": 2,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4110000563007,
      "DESCRIPTION": "FUEL FILTER 4110001026085 4110000563003",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 18,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 31,
      "WHXX": 9,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4110000108006,
      "DESCRIPTION": "FUEL FILTER",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 1,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 24,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14721846,
      "DESCRIPTION": "CLAMP",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 1,
      "SHELF LIFE": 6.000000000000024,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 5,
      "WHXX": 3,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 6670207,
      "DESCRIPTION": "FILTER, OIL HYD",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3,
      "WHXX": 2,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 997383,
      "DESCRIPTION": "WASHER 992040",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 200,
      "WHXX": 163,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14737252,
      "DESCRIPTION": "GASKET",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.416666666666666,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 2,
      "SHELF LIFE": 4.800000000000008,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 32,
      "WHXX": 2,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 1.12089707663561,
      "MEAN": 0.384615384615384,
      "COV": 2.91433239925258,
      "SHELF LIFE_1": 4.800000000000008,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 29270009421,
      "DESCRIPTION": "PLASTIC PLUG",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 1,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 27,
      "WHXX": 3,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4110000076189,
      "DESCRIPTION": "RETAINING RING",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 24,
      "WHXX": 10,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4110001394117,
      "DESCRIPTION": "RETAINING RING",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 9,
      "WHXX": 1,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4110000076302,
      "DESCRIPTION": "RETAINING RING",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 40,
      "WHXX": 12,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4110000076199,
      "DESCRIPTION": "EXTENSION BAR",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3,
      "WHXX": 3,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4110000367110,
      "DESCRIPTION": "RETAINING RING",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 13,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4110000367109,
      "DESCRIPTION": "BEARING",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4110000367111,
      "DESCRIPTION": "BEARING",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 8,
      "WHXX": 5,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4110000367043,
      "DESCRIPTION": "CYLINDER PISTON",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 5,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4110000076251,
      "DESCRIPTION": "TENSION SPRING",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 12,
      "WHXX": 9,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4110000367108,
      "DESCRIPTION": "RETAINING RING",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4110000367107,
      "DESCRIPTION": "NEEDLE BEARING",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 10,
      "WHXX": 2,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4110000367104,
      "DESCRIPTION": "PRESSURE PLATE",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4110000367106,
      "DESCRIPTION": "NEEDLE BEARING",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4110000076294,
      "DESCRIPTION": "RETAINING RING",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 30,
      "WHXX": 15,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4110000367103,
      "DESCRIPTION": "WASHER",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3,
      "WHXX": 1,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4110000367100,
      "DESCRIPTION": "THRUST WASHER",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 12,
      "WHXX": 3,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4110000367127,
      "DESCRIPTION": "BEARING",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 11,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4110000076062,
      "DESCRIPTION": "NEEDLE BEARING",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4110000076060,
      "DESCRIPTION": "NEEDLE BEARING",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 12,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4110000076296,
      "DESCRIPTION": "RETAINING RING",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 1,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4110000367123,
      "DESCRIPTION": "RUBBER WASHER",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 22,
      "WHXX": 2,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4110000076322,
      "DESCRIPTION": "ROLLER",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 20,
      "WHXX": 36,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 21380490,
      "DESCRIPTION": "FUEL FILTER WATER SEPARATOR",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.25,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 6,
      "SHELF LIFE": 24,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 10,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.832050294337843,
      "MEAN": 0.23076923076923,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 24,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 21716424,
      "DESCRIPTION": "AIR CLEANER - PRIMARY FILTER",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 4,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14711075,
      "DESCRIPTION": "WIRING HARNESS",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14702367,
      "DESCRIPTION": "WIRING HARNESS",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14713704,
      "DESCRIPTION": "WIRING HARNESS",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 6682034,
      "DESCRIPTION": "MOTOR DRIVE",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4015000119,
      "DESCRIPTION": "WASHER",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 20,
      "WHXX": 10,
      "WH REFERENCE": "WHXX",
      "TREND": 2,
      "STD": 1.10940039245045,
      "MEAN": 0.307692307692307,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 3.000000000000003,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 14884948,
      "DESCRIPTION": "SPRING WASHER",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "AVERAGE (F2)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 5.83333333333333,
      "MAINTANING STOCK QTY": 23,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 23,
      "APRD QTY": 0,
      "WH12": 108,
      "WHXX": 372,
      "WH REFERENCE": "WHXX",
      "TREND": 0.57,
      "STD": 6.58961887540371,
      "MEAN": 5.38461538461538,
      "COV": 1.22378636257497,
      "SHELF LIFE_1": 3.942857142857145,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 4120006889,
      "DESCRIPTION": "BRAKE VALVE",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 5223273542,
      "DESCRIPTION": "5223476572 OIL FILTER 152049Z00C ",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14725894,
      "DESCRIPTION": "WIRING HARNESS",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4110000367098,
      "DESCRIPTION": "SHAFT",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4110000367033,
      "DESCRIPTION": "CONVERTER HOUSING",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 1,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14679690,
      "DESCRIPTION": "SEALING STRIP",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 4,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 60326015,
      "DESCRIPTION": "OIL PRESSURE ELEMENT",
      "PRODUCT CATEGORY": "YPG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.833333333333333,
      "MAINTANING STOCK QTY": 3,
      "AVAILABLE": 5,
      "SHELF LIFE": 6.000000000000002,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 2.77350098112614,
      "MEAN": 0.769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000002,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 4110000367121,
      "DESCRIPTION": "4110000367359 THRUST WASHER",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 4,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4110004221,
      "DESCRIPTION": "PROPELLER SHAFT 4110002912",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 60242353,
      "DESCRIPTION": "DIESEL FUEL CLEANER",
      "PRODUCT CATEGORY": "YCG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.5,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 3,
      "SHELF LIFE": 6,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 4,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 1.19828937903055,
      "MEAN": 0.461538461538461,
      "COV": 2.5962936545662,
      "SHELF LIFE_1": 6,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 60320099,
      "DESCRIPTION": "ELEMENT KIT, FUEL FILTER",
      "PRODUCT CATEGORY": "YCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 5,
      "WHXX": 4,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 60320126,
      "DESCRIPTION": "ELEMENT, FUEL FILTER",
      "PRODUCT CATEGORY": "YCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 7,
      "WHXX": 4,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 60333601,
      "DESCRIPTION": "OIL FILTER ELEMENT",
      "PRODUCT CATEGORY": "YCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 4,
      "WHXX": 4,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 23226845,
      "DESCRIPTION": "24119885 HOLLOW SHAFT 21901696 ",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 2,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 2,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 5222748120,
      "DESCRIPTION": "O-RING",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 11,
      "WHXX": 10,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4110000076360,
      "DESCRIPTION": "SHAFT",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 7200002550,
      "DESCRIPTION": "BALL",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 7,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 60345316,
      "DESCRIPTION": "FILTER ELEMENT",
      "PRODUCT CATEGORY": "YCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 3,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 3,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 15038635,
      "DESCRIPTION": "OVERHAUL KIT",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 4,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 6680403,
      "DESCRIPTION": "VALVE",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 6675731,
      "DESCRIPTION": "COIL",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 6668820,
      "DESCRIPTION": "NUT",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14704595,
      "DESCRIPTION": "TOGGLE SWITCH",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 6,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14682685,
      "DESCRIPTION": "BATTERY CABLE",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 4,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 22803341,
      "DESCRIPTION": "PUMP",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 22803447,
      "DESCRIPTION": "BELT",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 1,
      "SHELF LIFE": 12.000000000000005,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 5,
      "WHXX": 3,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 12.000000000000005,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 29290039051,
      "DESCRIPTION": "GLASS",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "115-75-58000",
      "DESCRIPTION": "OIL SUCTION FILTER",
      "PRODUCT CATEGORY": "SDG",
      "ITEM MOVEMENT BRANCH": "AVERAGE (F2)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 1.66666666666666,
      "MAINTANING STOCK QTY": 7,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 7,
      "APRD QTY": 7,
      "WH12": 21,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 0.2,
      "STD": 4.38967319617654,
      "MEAN": 1.53846153846153,
      "COV": 2.85328757751475,
      "SHELF LIFE_1": 4.200000000000016,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 12724648,
      "DESCRIPTION": "HYDRAULIC CYLINDER",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4110000076104,
      "DESCRIPTION": "NEEDLE",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 2,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 29170181023,
      "DESCRIPTION": "BUCKET 29170087822",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "152119Z00B",
      "DESCRIPTION": "GASKET",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 17,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4110000509217,
      "DESCRIPTION": "VALVE",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4120003758,
      "DESCRIPTION": "BRAKE VALVE",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 15,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4110002901016,
      "DESCRIPTION": "HAND PUMP",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 5222455512,
      "DESCRIPTION": "WHEEL STUD",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 4.16666666666666,
      "MAINTANING STOCK QTY": 17,
      "AVAILABLE": 9,
      "SHELF LIFE": 2.1600000000000037,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 8,
      "APRD QTY": 8,
      "WH12": 0,
      "WHXX": 4,
      "WH REFERENCE": "PARTIAL WHXX",
      "TREND": 2,
      "STD": 11.2089707663561,
      "MEAN": 3.84615384615384,
      "COV": 2.91433239925258,
      "SHELF LIFE_1": 4.080000000000006,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 5223058609,
      "DESCRIPTION": "BRAKE CHAMBER",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 2,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 10,
      "WHXX": 3,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 7275347,
      "DESCRIPTION": "ELEMENT, FILTER",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 82461913,
      "DESCRIPTION": "HOSE                     ",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 2,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 84164453,
      "DESCRIPTION": "HOSE                     ",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 5,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 84055661,
      "DESCRIPTION": "COOLANT PIPE             ",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 21196040,
      "DESCRIPTION": "BRACKET",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 22080280,
      "DESCRIPTION": "BRACKET",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 22080281,
      "DESCRIPTION": "BRACKET",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 21305187,
      "DESCRIPTION": "BAR",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 21374700,
      "DESCRIPTION": "BRACKET",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 21641263,
      "DESCRIPTION": "BRACKET",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 21671842,
      "DESCRIPTION": "HEAT SHIELD",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 20722721,
      "DESCRIPTION": "AIR INLET                ",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 1,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4110000076053,
      "DESCRIPTION": "BEARING",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 6,
      "WHXX": 10,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4110000076229,
      "DESCRIPTION": "NEEDLE BEARING",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 5,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4110000076321,
      "DESCRIPTION": "BEARING",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 10,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 6715866,
      "DESCRIPTION": "BUSHING",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4110000076428,
      "DESCRIPTION": "BRUSH RETAINER COVER",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 5,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4190002742,
      "DESCRIPTION": "HOSE ASSEMBLY",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.583333333333333,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 2,
      "APRD QTY": 2,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 1.14,
      "STD": 1.05003052458683,
      "MEAN": 0.538461538461538,
      "COV": 1.9500566885184,
      "SHELF LIFE_1": 3.42857142857143,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 7000673,
      "DESCRIPTION": "GASKET, COVER VALVE",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "324859Z00C",
      "DESCRIPTION": "BALL",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 9,
      "WHXX": 19,
      "WH REFERENCE": "WHXX",
      "TREND": 0,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 7304852,
      "DESCRIPTION": "CARTRIDGE VALVE",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 13,
      "WHXX": 1,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14568000,
      "DESCRIPTION": "CONTROL LEVER",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 4,
      "WHXX": 2,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 6663289,
      "DESCRIPTION": "FUSE, 5A",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 10,
      "WHXX": 1,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 23369953,
      "DESCRIPTION": "COUPLING HEAD",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 6,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14694038,
      "DESCRIPTION": "DRIVE UNIT",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4120006094,
      "DESCRIPTION": "OIL COOLER",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "443429Z00A",
      "DESCRIPTION": "REPAIR KIT",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 11,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 7,
      "WHXX": 25,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4015000032,
      "DESCRIPTION": "SPRING WASHER",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.666666666666666,
      "MAINTANING STOCK QTY": 3,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 3,
      "APRD QTY": 0,
      "WH12": 4,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 0,
      "STD": 2.21880078490091,
      "MEAN": 0.615384615384615,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 4.500000000000004,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": "461279Z00A",
      "DESCRIPTION": "PIN",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 0,
      "WHXX": 12,
      "WH REFERENCE": "WHXX",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": "431149Z00A",
      "DESCRIPTION": "CASE",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14737813,
      "DESCRIPTION": "RECOIL SPRING 14709314 14663170",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 1,
      "SHELF LIFE": 6.000000000000024,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 9,
      "WHXX": 3,
      "WH REFERENCE": "",
      "TREND": 1,
      "STD": 0.375533808099405,
      "MEAN": 0.153846153846153,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 5223187972,
      "DESCRIPTION": "PLUG",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 71,
      "WHXX": 24,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 60273568,
      "DESCRIPTION": "BLOWER",
      "PRODUCT CATEGORY": "YPG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4110000145087,
      "DESCRIPTION": "BALL VALVE",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 5,
      "WHXX": 12,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 60318536,
      "DESCRIPTION": "60149733 LENGTH SENSOR",
      "PRODUCT CATEGORY": "YCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "A210404000009",
      "DESCRIPTION": "WASHER",
      "PRODUCT CATEGORY": "YCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 5,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 2,
      "STD": 1.10940039245045,
      "MEAN": 0.307692307692307,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 3.000000000000003,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 23446985,
      "DESCRIPTION": "BATTERY (MAINTENANCE)",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 23446987,
      "DESCRIPTION": "BATTERY (ENHANCED FLOODED BATTERY EXTRA)",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14645737,
      "DESCRIPTION": "BUSHING",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 29070024091,
      "DESCRIPTION": "COVER",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 4,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4030001093,
      "DESCRIPTION": "SEALING RING",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 5,
      "WHXX": 6,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 29070019841,
      "DESCRIPTION": "O-RING",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 6,
      "WHXX": 5,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "C61267",
      "DESCRIPTION": "FRONT COVER(EX TYPE)/D224*178/SNCM439",
      "PRODUCT CATEGORY": "SOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 1,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 5223387867,
      "DESCRIPTION": "PUMP UNIT",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 2,
      "STD": 0.375533808099405,
      "MEAN": 0.153846153846153,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 5223473091,
      "DESCRIPTION": "COMPRESSION SPRING 951489Z00B",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.416666666666666,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 2,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 1,
      "WH REFERENCE": "PARTIAL WHXX",
      "TREND": 0,
      "STD": 1.38675049056307,
      "MEAN": 0.384615384615384,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 4.800000000000008,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 29270007831,
      "DESCRIPTION": "BUSHING",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 9,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 29270008591,
      "DESCRIPTION": "PIN",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 5282675887,
      "DESCRIPTION": "CAP",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 10,
      "WHXX": 7,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 5333035,
      "DESCRIPTION": "PUMP,WATER",
      "PRODUCT CATEGORY": "CUG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 5223592666,
      "DESCRIPTION": "KING PIN KIT 5222881536",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4190000595,
      "DESCRIPTION": "LOCK",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4190002907002,
      "DESCRIPTION": "CONDENSER",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.416666666666666,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 2,
      "SHELF LIFE": 4.800000000000008,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0.8,
      "STD": 0.960768922830522,
      "MEAN": 0.384615384615384,
      "COV": 2.49799919935935,
      "SHELF LIFE_1": 4.800000000000008,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 4190002907004,
      "DESCRIPTION": "RETURN STRAINER",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.25,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 2,
      "STD": 0.438529009653514,
      "MEAN": 0.23076923076923,
      "COV": 1.90029237516522,
      "SHELF LIFE_1": 4,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 14746240,
      "DESCRIPTION": "14780710 PLASTIC HOSE 14737201",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 156,
      "WHXX": 10,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "1725101Z0B",
      "DESCRIPTION": "FILLER CAP LOCKABLE",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 2,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 7,
      "WHXX": 2,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 60110297,
      "DESCRIPTION": "WASHER 992042",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 1.66666666666666,
      "MAINTANING STOCK QTY": 7,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 7,
      "APRD QTY": 7,
      "WH12": 72,
      "WHXX": 47,
      "WH REFERENCE": "WHXX",
      "TREND": 2,
      "STD": 5.54700196225229,
      "MEAN": 1.53846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 4.200000000000016,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 12814491,
      "DESCRIPTION": "SPACER",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 2,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "158-40-A0000P050-01",
      "DESCRIPTION": "CARRIER ROLLER",
      "PRODUCT CATEGORY": "SDG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 1,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14558944,
      "DESCRIPTION": "SEAL",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14638992,
      "DESCRIPTION": "CHECK VALVE",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 4,
      "WHXX": 2,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 11222279,
      "DESCRIPTION": "LIFTHEIGHTLIMITSWITCHASSEMBLYQ150D.",
      "PRODUCT CATEGORY": "YCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14638193,
      "DESCRIPTION": "ROD 14575022",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 5,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 5473238,
      "DESCRIPTION": "KIT,WATER PUMP",
      "PRODUCT CATEGORY": "CUG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 7163219,
      "DESCRIPTION": "KIT, SEAL",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14737811,
      "DESCRIPTION": "RECOIL SPRING",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 2,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14685547,
      "DESCRIPTION": "TRAVEL MOTOR 14500160",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "22.4.JSM33*2HXL",
      "DESCRIPTION": "360¬∞ ROTARY JOINT",
      "PRODUCT CATEGORY": "PHG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 4120003296,
      "DESCRIPTION": "FILTER INSERT",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 32,
      "WHXX": 36,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14671760,
      "DESCRIPTION": "RECOIL SPRING",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4110002354001,
      "DESCRIPTION": "OIL FILTER",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 47,
      "WHXX": 6,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4110002354002,
      "DESCRIPTION": "FUEL FILTER INSERT",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 3,
      "SHELF LIFE": 9.000000000000009,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 30,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 1.10940039245045,
      "MEAN": 0.307692307692307,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 9.000000000000009,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 23381562,
      "DESCRIPTION": "FILTER",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.583333333333333,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 5,
      "SHELF LIFE": 8.571428571428577,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 58,
      "WHXX": 4,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 1.45002210416396,
      "MEAN": 0.538461538461538,
      "COV": 2.69289819344737,
      "SHELF LIFE_1": 8.571428571428577,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 5223488187,
      "DESCRIPTION": "SYNCHRONIZER 326109Z01D  ",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14883767,
      "DESCRIPTION": "HOSE CLAMP",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 4,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 22216579,
      "DESCRIPTION": "CAMSHAFT",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14720764,
      "DESCRIPTION": "SENSOR 14601315",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 3030900178,
      "DESCRIPTION": "DRIVE SHAFT GEAR",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 4,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14650866,
      "DESCRIPTION": "SWITCH",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 1,
      "SHELF LIFE": 12.000000000000005,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 2,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 12.000000000000005,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 14737841,
      "DESCRIPTION": "VALVE 8230-00990 14630785 14531520 14562315",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.583333333333333,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 2,
      "SHELF LIFE": 3.42857142857143,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 20,
      "WHXX": 14,
      "WH REFERENCE": "",
      "TREND": 1.14,
      "STD": 0.776250025806184,
      "MEAN": 0.538461538461538,
      "COV": 1.44160719078291,
      "SHELF LIFE_1": 3.42857142857143,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 14652624,
      "DESCRIPTION": "PLATE",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.25,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 2,
      "STD": 0.832050294337843,
      "MEAN": 0.23076923076923,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 4,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 997492,
      "DESCRIPTION": "SIX POINT SOCKET SCREW",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 10,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 60348780,
      "DESCRIPTION": "AIR FILTER KIT",
      "PRODUCT CATEGORY": "YCG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 1,
      "SHELF LIFE": 3.000000000000003,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 9,
      "WHXX": 4,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 1.10940039245045,
      "MEAN": 0.307692307692307,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 3.000000000000003,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 14744967,
      "DESCRIPTION": "14750657 FILTER CARTRIDGE 14532687",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.666666666666666,
      "MAINTANING STOCK QTY": 3,
      "AVAILABLE": 1,
      "SHELF LIFE": 1.5000000000000016,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 2,
      "APRD QTY": 2,
      "WH12": 0,
      "WHXX": 15,
      "WH REFERENCE": "WHXX",
      "TREND": 0.5,
      "STD": 1.12089707663561,
      "MEAN": 0.615384615384615,
      "COV": 1.82145774953286,
      "SHELF LIFE_1": 4.500000000000004,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 14531688,
      "DESCRIPTION": "SOCKET",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 5223579293,
      "DESCRIPTION": "SHOCK ABSORBER ",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 2,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "562039Z00E",
      "DESCRIPTION": "SHOCK ABSORBER ",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 60113647,
      "DESCRIPTION": "CROSS RECESSED SCREW",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 0,
      "WHXX": 9,
      "WH REFERENCE": "WHXX",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 60188333,
      "DESCRIPTION": "LINEAR STEPPING MOTO",
      "PRODUCT CATEGORY": "YPG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 11797975,
      "DESCRIPTION": "FRONT PANEL BODY ASSY",
      "PRODUCT CATEGORY": "YTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 29070001031,
      "DESCRIPTION": "BOLT",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.666666666666666,
      "MAINTANING STOCK QTY": 3,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 3,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 0,
      "STD": 2.21880078490091,
      "MEAN": 0.615384615384615,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 4.500000000000004,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 994868,
      "DESCRIPTION": "WASHER` 13955894 96719745",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.416666666666666,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 3,
      "SHELF LIFE": 7.200000000000011,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 14,
      "WHXX": 60,
      "WH REFERENCE": "",
      "TREND": 1.6,
      "STD": 1.1208970766356,
      "MEAN": 0.384615384615384,
      "COV": 2.91433239925258,
      "SHELF LIFE_1": 7.200000000000011,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 23285701,
      "DESCRIPTION": "OIL LEVEL SENSOR 22022794",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 13,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 5222931438,
      "DESCRIPTION": "SPRING",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 11,
      "WH REFERENCE": "WHXX",
      "TREND": 1,
      "STD": 0.75106761619881,
      "MEAN": 0.307692307692307,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 3.000000000000003,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 14749759,
      "DESCRIPTION": "14675562 SEALING KIT",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 2,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4120003757,
      "DESCRIPTION": "PRESSURE SWITCH",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 4,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4110000556223,
      "DESCRIPTION": "WATER SEPARATOR",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4190002764,
      "DESCRIPTION": "HOSE",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.583333333333333,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 2,
      "APRD QTY": 2,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 1.14,
      "STD": 1.05003052458683,
      "MEAN": 0.538461538461538,
      "COV": 1.9500566885184,
      "SHELF LIFE_1": 3.42857142857143,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 4190002946,
      "DESCRIPTION": "PIPE",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.25,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 2,
      "STD": 0.599144689515278,
      "MEAN": 0.23076923076923,
      "COV": 2.5962936545662,
      "SHELF LIFE_1": 4,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 4190002907,
      "DESCRIPTION": "CONDENSER",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.25,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 2,
      "STD": 0.438529009653514,
      "MEAN": 0.23076923076923,
      "COV": 1.90029237516522,
      "SHELF LIFE_1": 4,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 4130002904,
      "DESCRIPTION": "EVAPORATOR 4190002757 4130002911",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.666666666666666,
      "MAINTANING STOCK QTY": 3,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 3,
      "APRD QTY": 3,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 2,
      "STD": 1.93814608747902,
      "MEAN": 0.615384615384615,
      "COV": 3.14948739215341,
      "SHELF LIFE_1": 4.500000000000004,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 4190002766,
      "DESCRIPTION": "WATER PIPE",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.25,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 2,
      "STD": 0.599144689515278,
      "MEAN": 0.23076923076923,
      "COV": 2.5962936545662,
      "SHELF LIFE_1": 4,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 29010067911,
      "DESCRIPTION": "CONTROL CABLE",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 0,
      "WHXX": 3,
      "WH REFERENCE": "WHXX",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 4190002947,
      "DESCRIPTION": "PIPE",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.25,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 2,
      "STD": 0.599144689515278,
      "MEAN": 0.23076923076923,
      "COV": 2.5962936545662,
      "SHELF LIFE_1": 4,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 5222859763,
      "DESCRIPTION": "FLANGE SCREW 111139Z00B",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 48,
      "WHXX": 48,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4110000991031,
      "DESCRIPTION": "BEARING SHELL 4110000991032 4110000991030",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.5,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 2,
      "APRD QTY": 0,
      "WH12": 26,
      "WHXX": 28,
      "WH REFERENCE": "WHXX",
      "TREND": 0,
      "STD": 1.39136531360294,
      "MEAN": 0.461538461538461,
      "COV": 3.01462484613972,
      "SHELF LIFE_1": 4,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 12788543,
      "DESCRIPTION": "SWITCH",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.25,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 0,
      "WHXX": 3,
      "WH REFERENCE": "WHXX",
      "TREND": 2,
      "STD": 0.599144689515278,
      "MEAN": 0.23076923076923,
      "COV": 2.5962936545662,
      "SHELF LIFE_1": 4,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 7312069,
      "DESCRIPTION": "FUEL STRAINER",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 5,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 7004877,
      "DESCRIPTION": "DRAIN FILTER",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 4,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 29050020491,
      "DESCRIPTION": "SHIM",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 29050020501,
      "DESCRIPTION": "SHIM",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 29050020511,
      "DESCRIPTION": "SHIM",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 29050020931,
      "DESCRIPTION": "SHIM",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 29040008401,
      "DESCRIPTION": "GASKET",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 11,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 10125894,
      "DESCRIPTION": "BUMPER BLOCK",
      "PRODUCT CATEGORY": "YCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 2,
      "STD": 1.10940039245045,
      "MEAN": 0.307692307692307,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 3.000000000000003,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": "A810201206126",
      "DESCRIPTION": "PRESSURE SENSOR",
      "PRODUCT CATEGORY": "YCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 16284724,
      "DESCRIPTION": "WIPER BLADE 14508629 ",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 1,
      "SHELF LIFE": 3.000000000000003,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 38,
      "WHXX": 5,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.630425171956115,
      "MEAN": 0.307692307692307,
      "COV": 2.04888180885737,
      "SHELF LIFE_1": 3.000000000000003,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 23191319,
      "DESCRIPTION": "OIL COOLER 20511773 22592869",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 3,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 0.5,
      "STD": 0.480384461415261,
      "MEAN": 0.307692307692307,
      "COV": 1.56124949959959,
      "SHELF LIFE_1": 3.000000000000003,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": "15058186PQ",
      "DESCRIPTION": "SUPER HYDRAULIC OIL  ISO VG68",
      "PRODUCT CATEGORY": "LUV",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 18,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 10,
      "WHXX": 41,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "15067522PQ",
      "DESCRIPTION": "SUPER TRANSMISSION OIL  85W/140  OIL API GL-5 99990-1Z05J",
      "PRODUCT CATEGORY": "LUV",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 2,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 107,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "15067640PQ",
      "DESCRIPTION": "GREASE, LITHIUM  EP2 ",
      "PRODUCT CATEGORY": "LUV",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 42,
      "MAINTANING STOCK QTY": 168,
      "AVAILABLE": 1,
      "SHELF LIFE": 0.023809523809523808,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 167,
      "APRD QTY": 160,
      "WH12": 1441,
      "WHXX": 17,
      "WH REFERENCE": "WH12 + WHXX",
      "TREND": 0.89,
      "STD": 57.3035104307956,
      "MEAN": 38.7692307692307,
      "COV": 1.47806673730226,
      "SHELF LIFE_1": 4,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 21707132,
      "DESCRIPTION": "OIL FILTER, BYPASS",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 1.33333333333333,
      "MAINTANING STOCK QTY": 5,
      "AVAILABLE": 31,
      "SHELF LIFE": 23.25000000000006,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 8,
      "WH REFERENCE": "",
      "TREND": 1.38,
      "STD": 2.16617351389672,
      "MEAN": 1.23076923076923,
      "COV": 1.76001598004109,
      "SHELF LIFE_1": 23.25000000000006,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 21707133,
      "DESCRIPTION": "23658092 OIL FILTER",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 2.41666666666666,
      "MAINTANING STOCK QTY": 10,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 10,
      "APRD QTY": 10,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 0.9,
      "STD": 5.47956576980704,
      "MEAN": 2.23076923076923,
      "COV": 2.45635706922384,
      "SHELF LIFE_1": 4.13793103448277,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 21707134,
      "DESCRIPTION": "OIL FILTER",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.416666666666666,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 16,
      "SHELF LIFE": 38.40000000000006,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 115,
      "WHXX": 11,
      "WH REFERENCE": "",
      "TREND": 0.8,
      "STD": 0.960768922830522,
      "MEAN": 0.384615384615384,
      "COV": 2.49799919935935,
      "SHELF LIFE_1": 38.40000000000006,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 980521,
      "DESCRIPTION": "NUT, HEXAGON",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 8,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 0,
      "STD": 1.10940039245045,
      "MEAN": 0.307692307692307,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 3.000000000000003,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 914465,
      "DESCRIPTION": "RETAINING RING 19C-137 12750353",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 40,
      "WHXX": 9,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 955892,
      "DESCRIPTION": "60113297 WASHER",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 15,
      "WHXX": 1,
      "WH REFERENCE": "WHXX",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 969161,
      "DESCRIPTION": "BLADE FUSE 10A A35D/G930/L150F/EC210B/EC460B",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.416666666666666,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 21,
      "SHELF LIFE": 50.40000000000008,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 54,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 1.38675049056307,
      "MEAN": 0.384615384615384,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 50.40000000000008,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 969163,
      "DESCRIPTION": "BLADE FUSE 25A A35D/G930/EC460C/FM400",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 10,
      "SHELF LIFE": 60.00000000000024,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 4,
      "WHXX": 12,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 60.00000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 970742,
      "DESCRIPTION": "BULB A35D 24V 21/5W NOTLPA",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 5,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 984725,
      "DESCRIPTION": "FLANGE SCREW 946544",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.25,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 22,
      "WH REFERENCE": "WHXX",
      "TREND": 0,
      "STD": 0.832050294337843,
      "MEAN": 0.23076923076923,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 4,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 984738,
      "DESCRIPTION": "FLANGE SCREW 13947760",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 4,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 31,
      "WHXX": 15,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 984751,
      "DESCRIPTION": "FLANGE SCREW 60113259",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 7,
      "WHXX": 27,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 984756,
      "DESCRIPTION": "FLANGE SCREW 965186 13965186",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.75,
      "MAINTANING STOCK QTY": 3,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 3,
      "APRD QTY": 3,
      "WH12": 0,
      "WHXX": 20,
      "WH REFERENCE": "WHXX",
      "TREND": 2,
      "STD": 2.49615088301353,
      "MEAN": 0.692307692307692,
      "COV": 3.60555127546399,
      "SHELF LIFE_1": 4,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 990861,
      "DESCRIPTION": "FLANGE SCREW  60113094 946472",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 41,
      "WHXX": 9,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 990950,
      "DESCRIPTION": "FLANGE LOCK NUT 948645 60113167",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 61,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 992515,
      "DESCRIPTION": "BULB 75/70W FM10/FM400 973892 13964763 964763",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.25,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 21,
      "WHXX": 22,
      "WH REFERENCE": "WHXX",
      "TREND": 0,
      "STD": 0.599144689515278,
      "MEAN": 0.23076923076923,
      "COV": 2.5962936545662,
      "SHELF LIFE_1": 4,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 992516,
      "DESCRIPTION": "BULB ADAPTED QTY 10 943904",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.666666666666666,
      "MAINTANING STOCK QTY": 3,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 3,
      "APRD QTY": 0,
      "WH12": 17,
      "WHXX": 51,
      "WH REFERENCE": "WHXX",
      "TREND": 0,
      "STD": 1.50213523239762,
      "MEAN": 0.615384615384615,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 4.500000000000004,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 992517,
      "DESCRIPTION": "BULB_70W 24V A35D/EC210B/EC460B/C/EC700B/G930/L150F/FM10/FM400 943903",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 33,
      "WHXX": 25,
      "WH REFERENCE": "WHXX",
      "TREND": 0,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 992521,
      "DESCRIPTION": "BULB 24V/21W G930/L150F 967708",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.583333333333333,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 3,
      "SHELF LIFE": 5.142857142857146,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 30,
      "WHXX": 31,
      "WH REFERENCE": "",
      "TREND": 1.43,
      "STD": 1.19828937903055,
      "MEAN": 0.538461538461538,
      "COV": 2.22539456105674,
      "SHELF LIFE_1": 5.142857142857146,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 1674083,
      "DESCRIPTION": "PRESS CAP_EXP TANK FM10 20879323",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 4,
      "WHXX": 3,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 20367490,
      "DESCRIPTION": "RELAY, STARTER NL10/FM400/EC460B/EC700B/G930/L150F",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 20538793,
      "DESCRIPTION": "SEALING STRIP",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 3,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 21271982,
      "DESCRIPTION": "SENSOR",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 9,
      "WHXX": 4,
      "WH REFERENCE": "WHXX",
      "TREND": 0,
      "STD": 0.375533808099405,
      "MEAN": 0.153846153846153,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 21415427,
      "DESCRIPTION": "SEALING RING FM9/FM10/FM340/EC700B",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 10,
      "WHXX": 3,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 21531072,
      "DESCRIPTION": "SENSOR_TEMPERATURE FM10/FM340/EC210B/EC290B/EC700B/G710B/G930/BL71/A40F 20513340",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 1,
      "SHELF LIFE": 12.000000000000005,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 6,
      "WHXX": 3,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 12.000000000000005,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 22567321,
      "DESCRIPTION": "COOLANT (READY MIXED) 5L/GAL YELLOW  85108913",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "AVERAGE (F2)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 14.6666666666666,
      "MAINTANING STOCK QTY": 59,
      "AVAILABLE": 59,
      "SHELF LIFE": 4.022727272727291,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 100,
      "WHXX": 82,
      "WH REFERENCE": "",
      "TREND": 0.97,
      "STD": 18.7688366919537,
      "MEAN": 13.5384615384615,
      "COV": 1.38633452838295,
      "SHELF LIFE_1": 4.022727272727291,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 22899626,
      "DESCRIPTION": "PRESSURE SENSOR 21634021 20898038",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 1,
      "SHELF LIFE": 6.000000000000024,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 8,
      "WHXX": 5,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.375533808099405,
      "MEAN": 0.153846153846153,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 22902431,
      "DESCRIPTION": "24070789 PUMP UNIT FH400 22197705 FM10/FM340/FM370/FM400/EC700B",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 85135446,
      "DESCRIPTION": "OIL, COMPRESSOR 125ML FM10/A30C 1161629 ",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 3,
      "SHELF LIFE": 18.000000000000075,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 26,
      "WHXX": 8,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 18.000000000000075,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 23387844,
      "DESCRIPTION": "SIDE MARKING LAMP",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 3,
      "MAINTANING STOCK QTY": 12,
      "AVAILABLE": 12,
      "SHELF LIFE": 4,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 14,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0.56,
      "STD": 5.32531448451397,
      "MEAN": 2.76923076923076,
      "COV": 1.92303023051893,
      "SHELF LIFE_1": 4,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 4110001755009,
      "DESCRIPTION": "INJECTION PUMP",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 6669267,
      "DESCRIPTION": "SEAL",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 16,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "4110000677001Y",
      "DESCRIPTION": "OIL FILTER",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "AVERAGE (F2)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 1.16666666666666,
      "MAINTANING STOCK QTY": 5,
      "AVAILABLE": 2,
      "SHELF LIFE": 1.714285714285724,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 3,
      "APRD QTY": 3,
      "WH12": 68,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 1.14,
      "STD": 1.1875421719907,
      "MEAN": 1.07692307692307,
      "COV": 1.10271773113422,
      "SHELF LIFE_1": 4.28571428571431,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 4110000787007,
      "DESCRIPTION": "FUEL FILTER",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "AVERAGE (F2)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 1.25,
      "MAINTANING STOCK QTY": 5,
      "AVAILABLE": 2,
      "SHELF LIFE": 1.6,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 3,
      "APRD QTY": 3,
      "WH12": 264,
      "WHXX": 20,
      "WH REFERENCE": "WHXX",
      "TREND": 1.2,
      "STD": 1.28102523044069,
      "MEAN": 1.15384615384615,
      "COV": 1.11022186638193,
      "SHELF LIFE_1": 4,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 14884949,
      "DESCRIPTION": "SPRING WASHER",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 17,
      "WHXX": 12,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4120000761,
      "DESCRIPTION": "BREATHER CAP",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 82,
      "WHXX": 2,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4120002319001,
      "DESCRIPTION": "FILTER INSERT",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.5,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 2,
      "APRD QTY": 2,
      "WH12": 82,
      "WHXX": 4,
      "WH REFERENCE": "WHXX",
      "TREND": 1.33,
      "STD": 0.660225291773524,
      "MEAN": 0.461538461538461,
      "COV": 1.43048813217597,
      "SHELF LIFE_1": 4,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 4120002103001,
      "DESCRIPTION": "FILTER INSERT",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 1,
      "MAINTANING STOCK QTY": 4,
      "AVAILABLE": 1,
      "SHELF LIFE": 1,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 3,
      "APRD QTY": 3,
      "WH12": 103,
      "WHXX": 6,
      "WH REFERENCE": "WHXX",
      "TREND": 1,
      "STD": 1.03774904332554,
      "MEAN": 0.923076923076923,
      "COV": 1.12422813026933,
      "SHELF LIFE_1": 4,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 4190000173001,
      "DESCRIPTION": "FILTER INSERT",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 1,
      "MAINTANING STOCK QTY": 4,
      "AVAILABLE": 1,
      "SHELF LIFE": 1,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 3,
      "APRD QTY": 3,
      "WH12": 59,
      "WHXX": 3,
      "WH REFERENCE": "WHXX",
      "TREND": 1,
      "STD": 1.03774904332554,
      "MEAN": 0.923076923076923,
      "COV": 1.12422813026933,
      "SHELF LIFE_1": 4,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 4120000721001,
      "DESCRIPTION": "FILTER INSERT",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 3,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 91,
      "WHXX": 3,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4190002509008,
      "DESCRIPTION": "AIR FILTER INSERT",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 65,
      "WHXX": 1,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4110000322001,
      "DESCRIPTION": "SAFETTY ELEMENT OF AIR CLEANER",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 74,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4110000322002,
      "DESCRIPTION": "PRMIARY ELEMENT OF AIR CLEANER",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 1,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 70,
      "WHXX": 3,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4190002205006,
      "DESCRIPTION": "FILTER ELEMENT",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.416666666666666,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 2,
      "APRD QTY": 2,
      "WH12": 58,
      "WHXX": 8,
      "WH REFERENCE": "WHXX",
      "TREND": 1.6,
      "STD": 0.65044363558799,
      "MEAN": 0.384615384615384,
      "COV": 1.69115345252877,
      "SHELF LIFE_1": 4.800000000000008,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": "4110000677002Y",
      "DESCRIPTION": "FUEL FILTER",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "AVERAGE (F2)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 1.16666666666666,
      "MAINTANING STOCK QTY": 5,
      "AVAILABLE": 2,
      "SHELF LIFE": 1.714285714285724,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 3,
      "APRD QTY": 3,
      "WH12": 138,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 1.14,
      "STD": 1.1875421719907,
      "MEAN": 1.07692307692307,
      "COV": 1.10271773113422,
      "SHELF LIFE_1": 4.28571428571431,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 23307414,
      "DESCRIPTION": "VACUUM SWITCH 20560843 ",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 1,
      "SHELF LIFE": 12.000000000000005,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 11,
      "WHXX": 3,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 12.000000000000005,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 6213184,
      "DESCRIPTION": "SCREW",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 2,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4040000051,
      "DESCRIPTION": "WASHER",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.666666666666666,
      "MAINTANING STOCK QTY": 3,
      "AVAILABLE": 3,
      "SHELF LIFE": 4.500000000000004,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 6,
      "WHXX": 3,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 2.21880078490091,
      "MEAN": 0.615384615384615,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 4.500000000000004,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 4011001261,
      "DESCRIPTION": "SCREW",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.666666666666666,
      "MAINTANING STOCK QTY": 3,
      "AVAILABLE": 3,
      "SHELF LIFE": 4.500000000000004,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 8,
      "WHXX": 2,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 2.21880078490091,
      "MEAN": 0.615384615384615,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 4.500000000000004,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 14710704,
      "DESCRIPTION": "WIRING HARNESS",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14734295,
      "DESCRIPTION": "WIRING HARNESS 14704000",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14555877,
      "DESCRIPTION": "WIRING HARNESS",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 0,
      "WHXX": 2,
      "WH REFERENCE": "WHXX",
      "TREND": 2,
      "STD": 0.375533808099405,
      "MEAN": 0.153846153846153,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": "119139Z00A",
      "DESCRIPTION": "BRACKET",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 4,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 3030900177,
      "DESCRIPTION": "DRIVE SHAFT GEAR",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4110000556202,
      "DESCRIPTION": "HOSE 4110001985004",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 36,
      "WHXX": 1,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14726137,
      "DESCRIPTION": "WIRING HARNESS",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14726138,
      "DESCRIPTION": "WIRING HARNESS",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 29050022281,
      "DESCRIPTION": "SHIM",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 7222791,
      "DESCRIPTION": "FILTER, AIR RECIRCULATING",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 7,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 29040009571,
      "DESCRIPTION": "SHIM",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 29040009581,
      "DESCRIPTION": "SHIM",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4021000110,
      "DESCRIPTION": "BALL BEARING",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 5,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 61019283,
      "DESCRIPTION": "CLUTCH SLAVE CYLINDER",
      "PRODUCT CATEGORY": "YCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 65,
      "WHXX": 2,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "A810301040447",
      "DESCRIPTION": "PISTON HEAD",
      "PRODUCT CATEGORY": "YPG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 14882694,
      "DESCRIPTION": "O-RING",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 72,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 0,
      "STD": 1.10940039245045,
      "MEAN": 0.307692307692307,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 3.000000000000003,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 14550214,
      "DESCRIPTION": "GEAR PUMP",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14728476,
      "DESCRIPTION": "WIPER MOTOR 14630974",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.25,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 0,
      "WHXX": 2,
      "WH REFERENCE": "WHXX",
      "TREND": 1.33,
      "STD": 0.438529009653514,
      "MEAN": 0.23076923076923,
      "COV": 1.90029237516522,
      "SHELF LIFE_1": 4,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 4190001802,
      "DESCRIPTION": "BUCKET TOOTH",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 2.25,
      "MAINTANING STOCK QTY": 9,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 9,
      "APRD QTY": 9,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 1.56,
      "STD": 3.30306772716764,
      "MEAN": 2.07692307692307,
      "COV": 1.59036594271034,
      "SHELF LIFE_1": 4,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 4190001804,
      "DESCRIPTION": "WASHER",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 2.25,
      "MAINTANING STOCK QTY": 9,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 9,
      "APRD QTY": 9,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 1.56,
      "STD": 3.30306772716764,
      "MEAN": 2.07692307692307,
      "COV": 1.59036594271034,
      "SHELF LIFE_1": 4,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 4190001805,
      "DESCRIPTION": "PIN",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 2.25,
      "MAINTANING STOCK QTY": 9,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 9,
      "APRD QTY": 9,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 1.56,
      "STD": 3.30306772716764,
      "MEAN": 2.07692307692307,
      "COV": 1.59036594271034,
      "SHELF LIFE_1": 4,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 4110002116002,
      "DESCRIPTION": "ALTERNATOR",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4110000727162,
      "DESCRIPTION": "14403813 FUEL FILTER INSERT",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "AVERAGE (F2)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 2.33333333333333,
      "MAINTANING STOCK QTY": 9,
      "AVAILABLE": 7,
      "SHELF LIFE": 3.0000000000000044,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 2,
      "APRD QTY": 2,
      "WH12": 100,
      "WHXX": 8,
      "WH REFERENCE": "WHXX",
      "TREND": 0.86,
      "STD": 2.37508434398141,
      "MEAN": 2.15384615384615,
      "COV": 1.10271773113422,
      "SHELF LIFE_1": 3.857142857142863,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 11214252,
      "DESCRIPTION": "FILTER",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "FAST (F1)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 4.5,
      "MAINTANING STOCK QTY": 18,
      "AVAILABLE": 36,
      "SHELF LIFE": 8,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 181,
      "WHXX": 15,
      "WH REFERENCE": "",
      "TREND": 1.04,
      "STD": 3.26205849748677,
      "MEAN": 4.15384615384615,
      "COV": 0.785310379024592,
      "SHELF LIFE_1": 8,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 11210241,
      "DESCRIPTION": "AIR FILTER",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.583333333333333,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 15,
      "SHELF LIFE": 25.714285714285726,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 75,
      "WHXX": 22,
      "WH REFERENCE": "",
      "TREND": 1.14,
      "STD": 0.967417922046845,
      "MEAN": 0.538461538461538,
      "COV": 1.79663328380128,
      "SHELF LIFE_1": 25.714285714285726,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 11211360,
      "DESCRIPTION": "HYDRAULIC FLUID FILTER",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "AVERAGE (F2)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 2.66666666666666,
      "MAINTANING STOCK QTY": 11,
      "AVAILABLE": 15,
      "SHELF LIFE": 5.625000000000014,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 256,
      "WHXX": 11,
      "WH REFERENCE": "",
      "TREND": 1.13,
      "STD": 2.53690706094989,
      "MEAN": 2.46153846153846,
      "COV": 1.03061849351089,
      "SHELF LIFE_1": 5.625000000000014,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 11211213,
      "DESCRIPTION": "HYDRAULIC FLUID FILTER",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "AVERAGE (F2)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 2.66666666666666,
      "MAINTANING STOCK QTY": 11,
      "AVAILABLE": 13,
      "SHELF LIFE": 4.875000000000012,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 314,
      "WHXX": 9,
      "WH REFERENCE": "",
      "TREND": 1.13,
      "STD": 2.53690706094989,
      "MEAN": 2.46153846153846,
      "COV": 1.03061849351089,
      "SHELF LIFE_1": 4.875000000000012,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 11210987,
      "DESCRIPTION": "FILTER",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.666666666666666,
      "MAINTANING STOCK QTY": 3,
      "AVAILABLE": 14,
      "SHELF LIFE": 21.00000000000002,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 154,
      "WHXX": 18,
      "WH REFERENCE": "",
      "TREND": 1.25,
      "STD": 0.960768922830522,
      "MEAN": 0.615384615384615,
      "COV": 1.56124949959959,
      "SHELF LIFE_1": 21.00000000000002,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 11211189,
      "DESCRIPTION": "HYDRAULIC FLUID FILTER",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "AVERAGE (F2)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 2.5,
      "MAINTANING STOCK QTY": 10,
      "AVAILABLE": 28,
      "SHELF LIFE": 11.2,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 123,
      "WHXX": 14,
      "WH REFERENCE": "",
      "TREND": 1.13,
      "STD": 1.97419246717129,
      "MEAN": 2.3076923076923,
      "COV": 0.855483402440895,
      "SHELF LIFE_1": 11.2,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 14405925,
      "DESCRIPTION": "AIR FILTER INSERT",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 1,
      "MAINTANING STOCK QTY": 4,
      "AVAILABLE": 14,
      "SHELF LIFE": 14,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 177,
      "WHXX": 13,
      "WH REFERENCE": "",
      "TREND": 1.17,
      "STD": 1.49786172378819,
      "MEAN": 0.923076923076923,
      "COV": 1.62268353410387,
      "SHELF LIFE_1": 14,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 14403810,
      "DESCRIPTION": "AIR FILTER",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.833333333333333,
      "MAINTANING STOCK QTY": 3,
      "AVAILABLE": 3,
      "SHELF LIFE": 3.6000000000000014,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 15,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 1.2,
      "STD": 1.01273936708366,
      "MEAN": 0.769230769230769,
      "COV": 1.31656117720876,
      "SHELF LIFE_1": 3.6000000000000014,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 4110000727198,
      "DESCRIPTION": "V-BELT",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 5,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14402462,
      "DESCRIPTION": "V-BELT",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 10,
      "WHXX": 1,
      "WH REFERENCE": "WHXX",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 4110003380015,
      "DESCRIPTION": "4110004069036 SENSOR",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4110003380016,
      "DESCRIPTION": "4110003380090 SENSOR",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4041002225,
      "DESCRIPTION": "HOSE ASSEMBLY",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 17410989,
      "DESCRIPTION": "BRAKE KIT",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 4,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 60110391,
      "DESCRIPTION": "PLAIN WASHER",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 5,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 997473,
      "DESCRIPTION": "HEXAGON SCREW 978943 992062",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 64,
      "WHXX": 39,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14729117,
      "DESCRIPTION": "WASHER PUMP 14629274",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 2,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4011001299,
      "DESCRIPTION": "SCREW",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 1,
      "SHELF LIFE": 3.000000000000003,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 1.10940039245045,
      "MEAN": 0.307692307692307,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 3.000000000000003,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 5223285701,
      "DESCRIPTION": "OIL LEVEL SENSOR",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4120001004007,
      "DESCRIPTION": "SEALING RING KIT",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 0,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 1001186466,
      "DESCRIPTION": "SENSOR,DUAL AXIS (0XC1)",
      "PRODUCT CATEGORY": "JLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14603022,
      "DESCRIPTION": "WASHER",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 12,
      "WHXX": 21,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 60220631,
      "DESCRIPTION": "PARALLEL LOWER CARRIER ROLLER",
      "PRODUCT CATEGORY": "YPG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 3.41666666666666,
      "MAINTANING STOCK QTY": 14,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 14,
      "APRD QTY": 14,
      "WH12": 11,
      "WHXX": 0,
      "WH REFERENCE": "PARTIAL WH12",
      "TREND": 2,
      "STD": 11.3713540226171,
      "MEAN": 3.15384615384615,
      "COV": 3.60555127546399,
      "SHELF LIFE_1": 4.097560975609764,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": "35C412",
      "DESCRIPTION": "BOLT",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 6631418,
      "DESCRIPTION": "RING, RETAINING",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 0,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": "4F6106",
      "DESCRIPTION": "PIN",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 0,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": "53D6",
      "DESCRIPTION": "NUT",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 0,
      "STD": 1.10940039245045,
      "MEAN": 0.307692307692307,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 3.000000000000003,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 60358722,
      "DESCRIPTION": "FILTER ELEMENT",
      "PRODUCT CATEGORY": "YPG",
      "ITEM MOVEMENT BRANCH": "AVERAGE (F2)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.833333333333333,
      "MAINTANING STOCK QTY": 3,
      "AVAILABLE": 2,
      "SHELF LIFE": 2.400000000000001,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 0,
      "WHXX": 32,
      "WH REFERENCE": "WHXX",
      "TREND": 1.2,
      "STD": 0.832050294337843,
      "MEAN": 0.769230769230769,
      "COV": 1.08166538263919,
      "SHELF LIFE_1": 3.6000000000000014,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 976945,
      "DESCRIPTION": "994849 WASHER",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 12,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 984993,
      "DESCRIPTION": "HOSE CLAMP ",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.666666666666666,
      "MAINTANING STOCK QTY": 3,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 3,
      "APRD QTY": 3,
      "WH12": 54,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 2,
      "STD": 2.21880078490091,
      "MEAN": 0.615384615384615,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 4.500000000000004,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": "9131-11600",
      "DESCRIPTION": "LOCK NUT_HEX SE",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 0,
      "WHXX": 8,
      "WH REFERENCE": "WHXX",
      "TREND": 2,
      "STD": 1.10940039245045,
      "MEAN": 0.307692307692307,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 3.000000000000003,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 14708503,
      "DESCRIPTION": "PIN",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.5,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 2,
      "APRD QTY": 2,
      "WH12": 0,
      "WHXX": 2,
      "WH REFERENCE": "WHXX",
      "TREND": 2,
      "STD": 1.66410058867568,
      "MEAN": 0.461538461538461,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 4,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 14708505,
      "DESCRIPTION": "BUSHING",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.5,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 2,
      "APRD QTY": 2,
      "WH12": 0,
      "WHXX": 1,
      "WH REFERENCE": "PARTIAL WHXX",
      "TREND": 2,
      "STD": 1.66410058867568,
      "MEAN": 0.461538461538461,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 4,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 14723460,
      "DESCRIPTION": "SEAL 14530367",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 1.66666666666666,
      "MAINTANING STOCK QTY": 7,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 7,
      "APRD QTY": 7,
      "WH12": 5,
      "WHXX": 31,
      "WH REFERENCE": "WHXX",
      "TREND": 1.8,
      "STD": 3.92885446865844,
      "MEAN": 1.53846153846153,
      "COV": 2.55375540462798,
      "SHELF LIFE_1": 4.200000000000016,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 17473102,
      "DESCRIPTION": "PIN 21",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 2.08333333333333,
      "MAINTANING STOCK QTY": 8,
      "AVAILABLE": 18,
      "SHELF LIFE": 8.640000000000015,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 381,
      "WHXX": 141,
      "WH REFERENCE": "",
      "TREND": 1.2,
      "STD": 2.53184841770916,
      "MEAN": 1.92307692307692,
      "COV": 1.31656117720876,
      "SHELF LIFE_1": 8.640000000000015,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 17473106,
      "DESCRIPTION": "PIN 66",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 7.5,
      "MAINTANING STOCK QTY": 30,
      "AVAILABLE": 26,
      "SHELF LIFE": 3.466666666666667,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 4,
      "APRD QTY": 0,
      "WH12": 41,
      "WHXX": 55,
      "WH REFERENCE": "WHXX",
      "TREND": 0.93,
      "STD": 12.3184248077256,
      "MEAN": 6.92307692307692,
      "COV": 1.77932802778258,
      "SHELF LIFE_1": 4,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 17493106,
      "DESCRIPTION": "RETAINER 66",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.666666666666666,
      "MAINTANING STOCK QTY": 3,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 3,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 6,
      "WH REFERENCE": "WHXX",
      "TREND": 0.25,
      "STD": 1.93814608747902,
      "MEAN": 0.615384615384615,
      "COV": 3.14948739215341,
      "SHELF LIFE_1": 4.500000000000004,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 14732381,
      "DESCRIPTION": "TOOTH, NEW VTS GPE 21",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 2.08333333333333,
      "MAINTANING STOCK QTY": 8,
      "AVAILABLE": 8,
      "SHELF LIFE": 3.840000000000006,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 146,
      "WHXX": 61,
      "WH REFERENCE": "",
      "TREND": 1.2,
      "STD": 2.53184841770916,
      "MEAN": 1.92307692307692,
      "COV": 1.31656117720876,
      "SHELF LIFE_1": 3.840000000000006,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 14732401,
      "DESCRIPTION": "TOOTH, NEW VTS GPE 66",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "AVERAGE (F2)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 7.5,
      "MAINTANING STOCK QTY": 30,
      "AVAILABLE": 26,
      "SHELF LIFE": 3.466666666666667,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 4,
      "APRD QTY": 0,
      "WH12": 310,
      "WHXX": 4,
      "WH REFERENCE": "WHXX",
      "TREND": 0.8,
      "STD": 11.7577034213144,
      "MEAN": 6.92307692307692,
      "COV": 1.69833493863431,
      "SHELF LIFE_1": 4,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 14732402,
      "DESCRIPTION": "TOOTH, NEW VTS ARXE 66",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 6,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 27,
      "WHXX": 8,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14672731,
      "DESCRIPTION": "PROPELLER SHAFT",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 1,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 23417523,
      "DESCRIPTION": "CYLINDER ",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 11,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14737832,
      "DESCRIPTION": "PISTON 14533163 14532283",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 1,
      "SHELF LIFE": 6.000000000000024,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3,
      "WHXX": 4,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 14730968,
      "DESCRIPTION": "14675561 SEALING KIT, BOOM CYL",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 5,
      "WHXX": 2,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 997882,
      "DESCRIPTION": "HEX. SOCKET SCREW",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 3,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 42,
      "WHXX": 5,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 6710659,
      "DESCRIPTION": "BUSHING",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 0,
      "STD": 1.10940039245045,
      "MEAN": 0.307692307692307,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 3.000000000000003,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 6713589,
      "DESCRIPTION": "CLEVIS",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 0,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 6713887,
      "DESCRIPTION": "BOLT",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 0,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 22281893,
      "DESCRIPTION": "V-RIBBED BELT",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.416666666666666,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 2,
      "APRD QTY": 0,
      "WH12": 7,
      "WHXX": 10,
      "WH REFERENCE": "WHXX",
      "TREND": 0.8,
      "STD": 0.65044363558799,
      "MEAN": 0.384615384615384,
      "COV": 1.69115345252877,
      "SHELF LIFE_1": 4.800000000000008,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 4110003229003,
      "DESCRIPTION": "ALTERNATOR",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.416666666666666,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 2,
      "APRD QTY": 2,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 1.6,
      "STD": 0.960768922830522,
      "MEAN": 0.384615384615384,
      "COV": 2.49799919935935,
      "SHELF LIFE_1": 4.800000000000008,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 992454,
      "DESCRIPTION": "FUSE",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 47,
      "WHXX": 8,
      "WH REFERENCE": "WHXX",
      "TREND": 1,
      "STD": 0.375533808099405,
      "MEAN": 0.153846153846153,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 23491400,
      "DESCRIPTION": "CLUTCH 20806454",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 997408,
      "DESCRIPTION": "HEXAGON NUT 983304 ",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 1.33333333333333,
      "MAINTANING STOCK QTY": 5,
      "AVAILABLE": 5,
      "SHELF LIFE": 3.7500000000000098,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 12,
      "WHXX": 55,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 4.43760156980183,
      "MEAN": 1.23076923076923,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 3.7500000000000098,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": "430739Z00A ",
      "DESCRIPTION": "PLUG",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 7.08333333333333,
      "MAINTANING STOCK QTY": 28,
      "AVAILABLE": 8,
      "SHELF LIFE": 1.1294117647058828,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 20,
      "APRD QTY": 20,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 1.88,
      "STD": 22.1156633204289,
      "MEAN": 6.53846153846153,
      "COV": 3.38239556665383,
      "SHELF LIFE_1": 3.9529411764705897,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": "432469Z00A",
      "DESCRIPTION": "430739Z00A PLUG",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 1.16666666666666,
      "MAINTANING STOCK QTY": 5,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 5,
      "APRD QTY": 5,
      "WH12": 0,
      "WHXX": 36,
      "WH REFERENCE": "WHXX",
      "TREND": 1.43,
      "STD": 2.90004420832793,
      "MEAN": 1.07692307692307,
      "COV": 2.69289819344737,
      "SHELF LIFE_1": 4.28571428571431,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 14884939,
      "DESCRIPTION": "NUT 9112-13001",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 1,
      "MAINTANING STOCK QTY": 4,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 4,
      "APRD QTY": 0,
      "WH12": 34,
      "WHXX": 5,
      "WH REFERENCE": "WHXX",
      "TREND": 1,
      "STD": 2.25320284859643,
      "MEAN": 0.923076923076923,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 4,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 11203464,
      "DESCRIPTION": "SIDE WINDOW",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 2,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4130039,
      "DESCRIPTION": "HELISERT COIL :SB081,100,121,SB,SU+55,85,105",
      "PRODUCT CATEGORY": "SOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 4,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4110000591035,
      "DESCRIPTION": "NUT 411000054332 ",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 13,
      "WHXX": 6,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14573909,
      "DESCRIPTION": "DIPPER ARM CYLINDER",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14726295,
      "DESCRIPTION": "FUSE BOX",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 2,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4043000467,
      "DESCRIPTION": "BUSHING",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 4,
      "WHXX": 1,
      "WH REFERENCE": "WHXX",
      "TREND": 0,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 14645434,
      "DESCRIPTION": "ALTERNATOR",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14725312,
      "DESCRIPTION": "SHIFT UNIT",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14612972,
      "DESCRIPTION": "HOSE ASSEMBLY",
      "PRODUCT CATEGORY": "VOH",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 5223229028,
      "DESCRIPTION": "5224222120¬† WHEEL NUT ",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 1.66666666666666,
      "MAINTANING STOCK QTY": 7,
      "AVAILABLE": 8,
      "SHELF LIFE": 4.800000000000019,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 5,
      "WHXX": 32,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 5.54700196225229,
      "MEAN": 1.53846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 4.800000000000019,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": "967110Z000",
      "DESCRIPTION": "HINGE",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 5,
      "WH REFERENCE": "WHXX",
      "TREND": 0,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 4110000555174,
      "DESCRIPTION": "WATER PUMP",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 5223726004,
      "DESCRIPTION": "SENSOR",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 10,
      "WHXX": 4,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "967120Z000",
      "DESCRIPTION": "HINGE",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 13,
      "WHXX": 5,
      "WH REFERENCE": "WHXX",
      "TREND": 0,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 14560255,
      "DESCRIPTION": "WASHER",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14560256,
      "DESCRIPTION": "WASHER",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 6634631,
      "DESCRIPTION": "LOCK RING",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4110000367018,
      "DESCRIPTION": "BEARING",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 2,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4120004492,
      "DESCRIPTION": "FILTER INSERT",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 67,
      "WHXX": 25,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4120005390,
      "DESCRIPTION": "FILTER INSERT",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 35,
      "WHXX": 7,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 16940716,
      "DESCRIPTION": "GAS SPRING",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 6,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 12769848,
      "DESCRIPTION": "GEAR PUMP",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 29220020701,
      "DESCRIPTION": "AIR TANK",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 5222747762,
      "DESCRIPTION": "GASKET",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 8,
      "WHXX": 24,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14531020,
      "DESCRIPTION": "PIN",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "84G3712",
      "DESCRIPTION": "BOLT 5",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 1.08333333333333,
      "MAINTANING STOCK QTY": 4,
      "AVAILABLE": 1,
      "SHELF LIFE": 0.923076923076926,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 3,
      "APRD QTY": 0,
      "WH12": 5,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 0,
      "STD": 3.60555127546398,
      "MEAN": 1,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 3.692307692307704,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 7168173,
      "DESCRIPTION": "BRACKET COVER",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 5222746873,
      "DESCRIPTION": "EXHAUST PIPE",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 5222794568,
      "DESCRIPTION": "EXHAUST PIPE",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 10071378,
      "DESCRIPTION": "Q500A SEALING ELEMENT PACKAGE",
      "PRODUCT CATEGORY": "YCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 7,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 29050027111,
      "DESCRIPTION": "FLANGE",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 17500365,
      "DESCRIPTION": "MIRROR 11121366",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 2,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 5220745892,
      "DESCRIPTION": "MOUNTING STRAP",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.25,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 7,
      "WHXX": 5,
      "WH REFERENCE": "WHXX",
      "TREND": 0,
      "STD": 0.832050294337843,
      "MEAN": 0.23076923076923,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 4,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 4130002901,
      "DESCRIPTION": "TAIL LIGHT",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 22658275,
      "DESCRIPTION": "RUBBER MOULDING",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 21426987,
      "DESCRIPTION": "SPEED SENSOR",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 1,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 2,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4110000367131,
      "DESCRIPTION": "RUBBER WASHER",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 5,
      "WHXX": 3,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 29050019331,
      "DESCRIPTION": "WASHER",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 0,
      "WHXX": 2,
      "WH REFERENCE": "WHXX",
      "TREND": 2,
      "STD": 1.10940039245045,
      "MEAN": 0.307692307692307,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 3.000000000000003,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 14733381,
      "DESCRIPTION": "SEALING KIT",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 1,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 17325561,
      "DESCRIPTION": "PRESSURE SENSOR",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 17287189,
      "DESCRIPTION": "SEALING KIT EW145B",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 1,
      "SHELF LIFE": 6.000000000000024,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 43,
      "WHXX": 11,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 7325333,
      "DESCRIPTION": "KIT SEAL",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 8,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 23307415,
      "DESCRIPTION": " VACUUM SWITCH 20565673 22996282",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 1,
      "SHELF LIFE": 12.000000000000005,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 12,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 12.000000000000005,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 17208525,
      "DESCRIPTION": "V-RIBBED BELT 15078690 ",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 1,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3,
      "WHXX": 2,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14731469,
      "DESCRIPTION": "IDLER, ENDURANCE",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 2,
      "SHELF LIFE": 24.00000000000001,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 20,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 24.00000000000001,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 14723426,
      "DESCRIPTION": "SPROCKET ENDURANCE",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 15,
      "WHXX": 1,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 22306614,
      "DESCRIPTION": "OIL FILLER PIPE          ",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 4,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 982558,
      "DESCRIPTION": "BULB 21W ",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 18,
      "WHXX": 8,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14749664,
      "DESCRIPTION": "53807004 CONDENSER 14591537 ",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 17511983,
      "DESCRIPTION": "VOLVO SYNTHETIC DRUM ECCENTRIC OIL",
      "PRODUCT CATEGORY": "LUV",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 19,
      "MAINTANING STOCK QTY": 76,
      "AVAILABLE": 292,
      "SHELF LIFE": 15.368421052631579,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 4320,
      "WHXX": 288,
      "WH REFERENCE": "",
      "TREND": 0.25,
      "STD": 55.3663787639745,
      "MEAN": 17.5384615384615,
      "COV": 3.15685492952486,
      "SHELF LIFE_1": 15.368421052631579,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 23657265,
      "DESCRIPTION": "COOLANT PUMP D6E/D4E 21247955 21125771",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 17,
      "SHELF LIFE": 102.00000000000041,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 4,
      "WH REFERENCE": "",
      "TREND": 1,
      "STD": 0.375533808099405,
      "MEAN": 0.153846153846153,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 102.00000000000041,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 21795915,
      "DESCRIPTION": "V-BELT PULLEY",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.416666666666666,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 4,
      "SHELF LIFE": 9.600000000000016,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 16,
      "WH REFERENCE": "",
      "TREND": 0.8,
      "STD": 0.506369683541833,
      "MEAN": 0.384615384615384,
      "COV": 1.31656117720876,
      "SHELF LIFE_1": 9.600000000000016,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 997953,
      "DESCRIPTION": "HEXAGON SCREW 96718887 96703111 983720 96702329 993029",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.666666666666666,
      "MAINTANING STOCK QTY": 3,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 3,
      "APRD QTY": 0,
      "WH12": 179,
      "WHXX": 877,
      "WH REFERENCE": "WHXX",
      "TREND": 0,
      "STD": 2.21880078490091,
      "MEAN": 0.615384615384615,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 4.500000000000004,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 22952495,
      "DESCRIPTION": "U-BOLT ",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 63,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 2,
      "STD": 1.10940039245045,
      "MEAN": 0.307692307692307,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 3.000000000000003,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": "160603020014A",
      "DESCRIPTION": "FUEL-WATER FILTER",
      "PRODUCT CATEGORY": "YCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 12,
      "WHXX": 1,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 1001225432,
      "DESCRIPTION": "FILTER, FUEL ELEMENT 70024962",
      "PRODUCT CATEGORY": "JLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 26350003331,
      "DESCRIPTION": "ADJUSTING BAR",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.25,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 2,
      "STD": 0.599144689515278,
      "MEAN": 0.23076923076923,
      "COV": 2.5962936545662,
      "SHELF LIFE_1": 4,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 4110001680002,
      "DESCRIPTION": "HANDLE",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 5,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14638977,
      "DESCRIPTION": "OIL COOLER 14518315",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14739343,
      "DESCRIPTION": "NUT",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 21934272,
      "DESCRIPTION": "HOSE                     ",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 14749667,
      "DESCRIPTION": "53806996 CONDENSER 14602245 14645543",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 14560261,
      "DESCRIPTION": "RING",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 16900698,
      "DESCRIPTION": "COOLANT PUMP",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 2,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 17522218,
      "DESCRIPTION": "O-RING",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 6,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 5223686649,
      "DESCRIPTION": "SEALING RING",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 6,
      "WHXX": 1,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 29250017701,
      "DESCRIPTION": "SHIM",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 1,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 29250017711,
      "DESCRIPTION": "SHIM",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 1,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 29250017721,
      "DESCRIPTION": "SHIM",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 21244698,
      "DESCRIPTION": "RELAY 14591812 ",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.25,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 1,
      "SHELF LIFE": 4,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 34,
      "WHXX": 7,
      "WH REFERENCE": "",
      "TREND": 1.33,
      "STD": 0.599144689515278,
      "MEAN": 0.23076923076923,
      "COV": 2.5962936545662,
      "SHELF LIFE_1": 4,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 7137769,
      "DESCRIPTION": "KIT, SEAL",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14762630,
      "DESCRIPTION": "ROLLER 14683330 ",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 5260112798,
      "DESCRIPTION": "SPRING WASHER",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 2.83333333333333,
      "MAINTANING STOCK QTY": 11,
      "AVAILABLE": 11,
      "SHELF LIFE": 3.8823529411764754,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 104,
      "WHXX": 20,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 8.30199234660433,
      "MEAN": 2.61538461538461,
      "COV": 3.17429119134871,
      "SHELF LIFE_1": 3.8823529411764754,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 7283056,
      "DESCRIPTION": "SEAL HUB",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 11211325,
      "DESCRIPTION": "STRAINER",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 1,
      "MAINTANING STOCK QTY": 4,
      "AVAILABLE": 6,
      "SHELF LIFE": 6,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 184,
      "WHXX": 29,
      "WH REFERENCE": "",
      "TREND": 0.67,
      "STD": 1.89127551586834,
      "MEAN": 0.923076923076923,
      "COV": 2.04888180885737,
      "SHELF LIFE_1": 6,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 4110002373023,
      "DESCRIPTION": "WATER PUMP",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14671837,
      "DESCRIPTION": "FUSE HOLDER",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 984996,
      "DESCRIPTION": "HOSE CLAMP",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.416666666666666,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 2,
      "APRD QTY": 2,
      "WH12": 25,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 2,
      "STD": 1.38675049056307,
      "MEAN": 0.384615384615384,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 4.800000000000008,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 16891063,
      "DESCRIPTION": "ELBOW NIPPLE",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 7,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 2,
      "STD": 0.375533808099405,
      "MEAN": 0.153846153846153,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 4120000560514,
      "DESCRIPTION": "RETAINING RING",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 6,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 936658,
      "DESCRIPTION": "HOSE ASSEMBLY",
      "PRODUCT CATEGORY": "VOH",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "TPWF2076",
      "DESCRIPTION": "WATER FILTER",
      "PRODUCT CATEGORY": "SDG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4110002230037,
      "DESCRIPTION": "BUSHING",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 9,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4013000382,
      "DESCRIPTION": "NUT",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.916666666666666,
      "MAINTANING STOCK QTY": 4,
      "AVAILABLE": 4,
      "SHELF LIFE": 4.363636363636367,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 22,
      "WHXX": 12,
      "WH REFERENCE": "",
      "TREND": 0.18,
      "STD": 2.7642405179408,
      "MEAN": 0.846153846153846,
      "COV": 3.26682970302095,
      "SHELF LIFE_1": 4.363636363636367,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": "324099Z00D",
      "DESCRIPTION": "REMOTE CONTROL",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 1,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 5223476572,
      "DESCRIPTION": "5223958455 OIL FILTER KIT 5223273542",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 17478948,
      "DESCRIPTION": "TANK UNIT 15010278",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 2,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 3,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14672980,
      "DESCRIPTION": "MIRROR 14634766 14708934 14564772",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 30,
      "WHXX": 35,
      "WH REFERENCE": "WHXX",
      "TREND": 0.5,
      "STD": 0.85485041426511,
      "MEAN": 0.307692307692307,
      "COV": 2.7782638463616,
      "SHELF LIFE_1": 3.000000000000003,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 14749665,
      "DESCRIPTION": "CONDENSER, A/C EC210B 14591539 14645946",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 4,
      "WHXX": 7,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14672981,
      "DESCRIPTION": "MIRROR",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14736879,
      "DESCRIPTION": "MUFFLER 14726098",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "A210111000091",
      "DESCRIPTION": "BOLT M8√Ó30",
      "PRODUCT CATEGORY": "YPG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.583333333333333,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 2,
      "APRD QTY": 2,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 2,
      "STD": 1.9414506867883,
      "MEAN": 0.538461538461538,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 3.42857142857143,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": "A210404000005",
      "DESCRIPTION": "WASHER 8GB961",
      "PRODUCT CATEGORY": "YPG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 1.16666666666666,
      "MAINTANING STOCK QTY": 5,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 5,
      "APRD QTY": 5,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 2,
      "STD": 3.8829013735766,
      "MEAN": 1.07692307692307,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 4.28571428571431,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": "B222100000288",
      "DESCRIPTION": "V2 DUSTER CARD POLYESTER FILTER CORE",
      "PRODUCT CATEGORY": "YPG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 2.33333333333333,
      "MAINTANING STOCK QTY": 9,
      "AVAILABLE": 9,
      "SHELF LIFE": 3.857142857142863,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 19,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 7.7658027471532,
      "MEAN": 2.15384615384615,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 3.857142857142863,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 14675561,
      "DESCRIPTION": "SEALING KIT(BOOM CYL) 14730968",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 1,
      "SHELF LIFE": 6.000000000000024,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 28,
      "WHXX": 52,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 7214545,
      "DESCRIPTION": "CABLE, THROTTLE",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 15018560,
      "DESCRIPTION": "WINDSHIELD",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 11205297,
      "DESCRIPTION": "SIDE WINDOW",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 3,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14767219,
      "DESCRIPTION": "TIPPING VALVE",
      "PRODUCT CATEGORY": "VTS",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4110000991047,
      "DESCRIPTION": "MAIN BEARING KIT",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4110001525009,
      "DESCRIPTION": "HOSE",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 1,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4120002274,
      "DESCRIPTION": "ACCUMULATOR",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4110003706001,
      "DESCRIPTION": "CUP",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 8,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14736786,
      "DESCRIPTION": "RADIATOR 17522415 17424334",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 2,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 60110032,
      "DESCRIPTION": "HEXAGON SCREW 978937",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 10,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 14,
      "WHXX": 83,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "29100001051A",
      "DESCRIPTION": "FILTER INSERT 29100001051",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "AVERAGE (F2)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 3.91666666666666,
      "MAINTANING STOCK QTY": 16,
      "AVAILABLE": 16,
      "SHELF LIFE": 4.08510638297873,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 78,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 1.49,
      "STD": 6.64001081247188,
      "MEAN": 3.61538461538461,
      "COV": 1.83659873536456,
      "SHELF LIFE_1": 4.08510638297873,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 5223616626,
      "DESCRIPTION": "PRESSURE SENSOR",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 3,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 2,
      "STD": 0.375533808099405,
      "MEAN": 0.153846153846153,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 14750655,
      "DESCRIPTION": "FILTER CARTRIDGE 14711980",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "AVERAGE (F2)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 1.08333333333333,
      "MAINTANING STOCK QTY": 4,
      "AVAILABLE": 4,
      "SHELF LIFE": 3.692307692307704,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 61,
      "WHXX": 26,
      "WH REFERENCE": "",
      "TREND": 1.08,
      "STD": 1,
      "MEAN": 1,
      "COV": 1,
      "SHELF LIFE_1": 3.692307692307704,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 23605013,
      "DESCRIPTION": "PRESSURE PIPE 21363940",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3,
      "WHXX": 2,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14400779,
      "DESCRIPTION": "OIL FILTER 4110000727085 4110000509164",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "FAST (F1)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 6.5,
      "MAINTANING STOCK QTY": 26,
      "AVAILABLE": 46,
      "SHELF LIFE": 7.076923076923077,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 757,
      "WHXX": 77,
      "WH REFERENCE": "",
      "TREND": 0.92,
      "STD": 6.09644705272396,
      "MEAN": 6,
      "COV": 1.01607450878732,
      "SHELF LIFE_1": 7.076923076923077,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 14675562,
      "DESCRIPTION": "SEALING KIT(ARM CYL) 14749759",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 8,
      "WHXX": 31,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14766277,
      "DESCRIPTION": "SEALING KIT(BUCKET CYL) 14730969",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 57,
      "WHXX": 45,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4110000322005,
      "DESCRIPTION": "INDICATOR",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 7414581,
      "DESCRIPTION": "FILTER HYDRAULIC WITH O-RINGS 7024037",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "AVERAGE (F2)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 1,
      "MAINTANING STOCK QTY": 4,
      "AVAILABLE": 9,
      "SHELF LIFE": 9,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 47,
      "WHXX": 4,
      "WH REFERENCE": "",
      "TREND": 0.5,
      "STD": 1.44115338424578,
      "MEAN": 0.923076923076923,
      "COV": 1.56124949959959,
      "SHELF LIFE_1": 9,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 16813698,
      "DESCRIPTION": "FUEL TANK",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14743649,
      "DESCRIPTION": "BOTTOM ROLLER, ENDURANCE  EC200D 14708190",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 4,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 29250017691,
      "DESCRIPTION": "NUT",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 1001225430,
      "DESCRIPTION": "FUEL FILTER ELEMENT, PRIMARY ",
      "PRODUCT CATEGORY": "JLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3,
      "WHXX": 1,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 11217422,
      "DESCRIPTION": "TOOTH",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 1.25,
      "MAINTANING STOCK QTY": 5,
      "AVAILABLE": 5,
      "SHELF LIFE": 4,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0.67,
      "STD": 2.19264504826757,
      "MEAN": 1.15384615384615,
      "COV": 1.90029237516522,
      "SHELF LIFE_1": 4,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 11213340,
      "DESCRIPTION": "PIN",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 1,
      "MAINTANING STOCK QTY": 4,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 4,
      "APRD QTY": 4,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 2,
      "STD": 1.89127551586834,
      "MEAN": 0.923076923076923,
      "COV": 2.04888180885737,
      "SHELF LIFE_1": 4,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 11223287,
      "DESCRIPTION": "RETAINER",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 1,
      "MAINTANING STOCK QTY": 4,
      "AVAILABLE": 1,
      "SHELF LIFE": 1,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 3,
      "APRD QTY": 3,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 2,
      "STD": 1.89127551586834,
      "MEAN": 0.923076923076923,
      "COV": 2.04888180885737,
      "SHELF LIFE_1": 4,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 5223071799,
      "DESCRIPTION": "REPAIR KIT",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 24,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 15045742,
      "DESCRIPTION": "DOOR WINDOW PANE",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4110002112,
      "DESCRIPTION": "V-BELT",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 5,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 5200994875,
      "DESCRIPTION": "WASHER",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 20,
      "WHXX": 12,
      "WH REFERENCE": "WHXX",
      "TREND": 0,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 14632907,
      "DESCRIPTION": "14660202 ALTERNATOR",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14619600,
      "DESCRIPTION": "SEAL",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 0,
      "STD": 1.10940039245045,
      "MEAN": 0.307692307692307,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 3.000000000000003,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 997996,
      "DESCRIPTION": "HEXAGON SCREW 991646",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 5,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14717654,
      "DESCRIPTION": "CLAMP",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.833333333333333,
      "MAINTANING STOCK QTY": 3,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 3,
      "APRD QTY": 3,
      "WH12": 0,
      "WHXX": 14,
      "WH REFERENCE": "WHXX",
      "TREND": 2,
      "STD": 2.77350098112614,
      "MEAN": 0.769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 3.6000000000000014,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 7286432,
      "DESCRIPTION": "DRIER, A/C ASSY",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 7021380,
      "DESCRIPTION": "RESISTOR",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 61018500,
      "DESCRIPTION": "DIESEL COARSE FILTER CORE",
      "PRODUCT CATEGORY": "YPG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.666666666666666,
      "MAINTANING STOCK QTY": 3,
      "AVAILABLE": 6,
      "SHELF LIFE": 9.000000000000009,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 85,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 1,
      "STD": 1.50213523239762,
      "MEAN": 0.615384615384615,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 9.000000000000009,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 23344580,
      "DESCRIPTION": "AIR SPRING ",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 60284008,
      "DESCRIPTION": "60284007 EXPANSION VALVE",
      "PRODUCT CATEGORY": "YPG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 17210651,
      "DESCRIPTION": "SEALING KIT",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 7349796,
      "DESCRIPTION": "CAP CANISTER HYDRAULIC",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.25,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 1,
      "SHELF LIFE": 4,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 40,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.438529009653514,
      "MEAN": 0.23076923076923,
      "COV": 1.90029237516522,
      "SHELF LIFE_1": 4,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 4110003115021,
      "DESCRIPTION": "4110001015023 TENSION PULLEY",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 13,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 2,
      "STD": 1.10940039245045,
      "MEAN": 0.307692307692307,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 3.000000000000003,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 6680970,
      "DESCRIPTION": "BREATHER",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4110002230043,
      "DESCRIPTION": "NEEDLE BEARING",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 2,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 10,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 29270018131,
      "DESCRIPTION": "PIN",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 997471,
      "DESCRIPTION": "HEXAGON SCREW 983260",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 11,
      "WHXX": 38,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 60263371,
      "DESCRIPTION": "FUEL FILTER ASSY",
      "PRODUCT CATEGORY": "YTG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.666666666666666,
      "MAINTANING STOCK QTY": 3,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 3,
      "APRD QTY": 3,
      "WH12": 0,
      "WHXX": 1,
      "WH REFERENCE": "PARTIAL WHXX",
      "TREND": 0.5,
      "STD": 1.26085034391223,
      "MEAN": 0.615384615384615,
      "COV": 2.04888180885737,
      "SHELF LIFE_1": 4.500000000000004,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 60358720,
      "DESCRIPTION": "OIL WATER SEPARATOR ASSY",
      "PRODUCT CATEGORY": "YTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 8,
      "WHXX": 20,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 61009978,
      "DESCRIPTION": "CLUTCH",
      "PRODUCT CATEGORY": "YTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 60263487,
      "DESCRIPTION": "FUEL FILTER",
      "PRODUCT CATEGORY": "YTG",
      "ITEM MOVEMENT BRANCH": "AVERAGE (F2)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 1.66666666666666,
      "MAINTANING STOCK QTY": 7,
      "AVAILABLE": 7,
      "SHELF LIFE": 4.200000000000016,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 101,
      "WHXX": 6,
      "WH REFERENCE": "",
      "TREND": 0.8,
      "STD": 2.96128870075694,
      "MEAN": 1.53846153846153,
      "COV": 1.92483765549201,
      "SHELF LIFE_1": 4.200000000000016,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 13782568,
      "DESCRIPTION": "POWER SUPPLY DEVICE",
      "PRODUCT CATEGORY": "YTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 61005716,
      "DESCRIPTION": "CLUTCH RELEASE BEARING",
      "PRODUCT CATEGORY": "YTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 22696301,
      "DESCRIPTION": "IDLER PULLEY 21766717",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.25,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 49,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 0,
      "STD": 0.832050294337843,
      "MEAN": 0.23076923076923,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 4,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 22745698,
      "DESCRIPTION": "BRACKET",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 60126917,
      "DESCRIPTION": "MAIN CARTRIDGE OF AIR CLEANER",
      "PRODUCT CATEGORY": "YPG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 2,
      "SHELF LIFE": 6.000000000000006,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 11,
      "WHXX": 1,
      "WH REFERENCE": "",
      "TREND": 1,
      "STD": 0.75106761619881,
      "MEAN": 0.307692307692307,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 6.000000000000006,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 60126914,
      "DESCRIPTION": "SAFETY CARTRIDGE OF AIR CLEANER",
      "PRODUCT CATEGORY": "YPG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 2,
      "SHELF LIFE": 6.000000000000006,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 30,
      "WHXX": 2,
      "WH REFERENCE": "",
      "TREND": 1,
      "STD": 0.75106761619881,
      "MEAN": 0.307692307692307,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 6.000000000000006,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 70918479,
      "DESCRIPTION": "AIR FILTER",
      "PRODUCT CATEGORY": "VCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.25,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 0,
      "STD": 0.832050294337843,
      "MEAN": 0.23076923076923,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 4,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 43918879,
      "DESCRIPTION": "RETURN FILTER",
      "PRODUCT CATEGORY": "VCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.5,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 2,
      "APRD QTY": 2,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 0,
      "STD": 1.66410058867568,
      "MEAN": 0.461538461538461,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 4,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 14535803,
      "DESCRIPTION": "BUSHING",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 14566450,
      "DESCRIPTION": "BUSHING",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "115H-60-02000",
      "DESCRIPTION": "HYDRAULIC OIL RETURN FILTER, DH24",
      "PRODUCT CATEGORY": "SDG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 1.41666666666666,
      "MAINTANING STOCK QTY": 6,
      "AVAILABLE": 1,
      "SHELF LIFE": 0.7058823529411797,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 5,
      "APRD QTY": 5,
      "WH12": 13,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 0.12,
      "STD": 4.42313266408578,
      "MEAN": 1.3076923076923,
      "COV": 3.38239556665383,
      "SHELF LIFE_1": 4.235294117647078,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 15714570,
      "DESCRIPTION": "FUEL FILTER 17270913 ",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 24,
      "WHXX": 3,
      "WH REFERENCE": "WHXX",
      "TREND": 1,
      "STD": 0.75106761619881,
      "MEAN": 0.307692307692307,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 3.000000000000003,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": "154-27-10006V030",
      "DESCRIPTION": "SPOCKET BOLT & NUT",
      "PRODUCT CATEGORY": "SDG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 42.5833333333333,
      "MAINTANING STOCK QTY": 170,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 170,
      "APRD QTY": 170,
      "WH12": 89,
      "WHXX": 0,
      "WH REFERENCE": "PARTIAL WH12",
      "TREND": 1.17,
      "STD": 86.2818874536487,
      "MEAN": 39.3076923076923,
      "COV": 2.19503823267599,
      "SHELF LIFE_1": 3.9921722113502964,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 15714574,
      "DESCRIPTION": "OIL FILTER 43977461 17324107 ",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.25,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 11,
      "WHXX": 9,
      "WH REFERENCE": "WHXX",
      "TREND": 0.67,
      "STD": 0.438529009653514,
      "MEAN": 0.23076923076923,
      "COV": 1.90029237516522,
      "SHELF LIFE_1": 4,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 997469,
      "DESCRIPTION": "980466 HEXAGON SCREW",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 0,
      "WHXX": 113,
      "WH REFERENCE": "WHXX",
      "TREND": 2,
      "STD": 1.10940039245045,
      "MEAN": 0.307692307692307,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 3.000000000000003,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 14703817,
      "DESCRIPTION": "DOOR CATCH 14376506 ",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 1,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 4,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 907814,
      "DESCRIPTION": "14880451 SPLIT PIN",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 2,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 8,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 15711010,
      "DESCRIPTION": "FUEL FILTER 16668474",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 17,
      "WHXX": 6,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 22935402,
      "DESCRIPTION": "U-BOLT ",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 1,
      "MAINTANING STOCK QTY": 4,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 4,
      "APRD QTY": 4,
      "WH12": 2,
      "WHXX": 0,
      "WH REFERENCE": "PARTIAL WH12",
      "TREND": 2,
      "STD": 3.32820117735137,
      "MEAN": 0.923076923076923,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 4,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 22422785,
      "DESCRIPTION": "SENSOR 21097978 22329559",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 2,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 5,
      "WHXX": 1,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4110003478017,
      "DESCRIPTION": "FUEL FILTER ASSY",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 1,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 20,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14550630,
      "DESCRIPTION": "ROD",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 20514313,
      "DESCRIPTION": "SWITCH",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 1,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 15048941,
      "DESCRIPTION": "FLASHER RELAY 11170048 11039244",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 3,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 1,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 17212421,
      "DESCRIPTION": "SOLENOID VALVE",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.833333333333333,
      "MAINTANING STOCK QTY": 3,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 3,
      "APRD QTY": 3,
      "WH12": 0,
      "WHXX": 12,
      "WH REFERENCE": "WHXX",
      "TREND": 2,
      "STD": 2.77350098112614,
      "MEAN": 0.769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 3.6000000000000014,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 14676340,
      "DESCRIPTION": "53489684 TOP ROLLER",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 14717289,
      "DESCRIPTION": "BOTTOM ROLLER 14602330",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 1.66666666666666,
      "MAINTANING STOCK QTY": 7,
      "AVAILABLE": 4,
      "SHELF LIFE": 2.4000000000000097,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 3,
      "APRD QTY": 3,
      "WH12": 30,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 2,
      "STD": 5.54700196225229,
      "MEAN": 1.53846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 4.200000000000016,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 29250018651,
      "DESCRIPTION": "SHIM",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 28,
      "WHXX": 4,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 29250001211,
      "DESCRIPTION": "LUBRICATING PIPE",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14573784,
      "DESCRIPTION": "PLUG",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14745995,
      "DESCRIPTION": "DIAPHRAGM 17216250 ",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 5,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 61020095,
      "DESCRIPTION": "AIR CLEANER FILTER (OUTER)",
      "PRODUCT CATEGORY": "YCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 14,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4120006629,
      "DESCRIPTION": "STEERING CYLINDER",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": "TP612600090816",
      "DESCRIPTION": "ALTERNATOR DH24",
      "PRODUCT CATEGORY": "SDG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "TP1000422382",
      "DESCRIPTION": "FUEL FINE FILTER DH24",
      "PRODUCT CATEGORY": "SDG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 18,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 41,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "TP1000964807",
      "DESCRIPTION": "FUEL COARSE FILTER DH24",
      "PRODUCT CATEGORY": "SDG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 7,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 2,
      "STD": 0.375533808099405,
      "MEAN": 0.153846153846153,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": "TP1000424655",
      "DESCRIPTION": "OIL FILTER DH24",
      "PRODUCT CATEGORY": "SDG",
      "ITEM MOVEMENT BRANCH": "AVERAGE (F2)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 1.08333333333333,
      "MAINTANING STOCK QTY": 4,
      "AVAILABLE": 1,
      "SHELF LIFE": 0.923076923076926,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 3,
      "APRD QTY": 3,
      "WH12": 9,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 0,
      "STD": 3.60555127546398,
      "MEAN": 1,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 3.692307692307704,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": "114H-75-08200",
      "DESCRIPTION": "HYDRAULIC FILTER DH24",
      "PRODUCT CATEGORY": "SDG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 1.58333333333333,
      "MAINTANING STOCK QTY": 6,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 6,
      "APRD QTY": 6,
      "WH12": 19,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 0.11,
      "STD": 4.40861627224432,
      "MEAN": 1.46153846153846,
      "COV": 3.01642165995664,
      "SHELF LIFE_1": 3.7894736842105345,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": "TP1000424916",
      "DESCRIPTION": "FUEL WATER SEPARATOR DH24",
      "PRODUCT CATEGORY": "SDG",
      "ITEM MOVEMENT BRANCH": "AVERAGE (F2)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 3.5,
      "MAINTANING STOCK QTY": 14,
      "AVAILABLE": 4,
      "SHELF LIFE": 1.1428571428571428,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 10,
      "APRD QTY": 0,
      "WH12": 35,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 0,
      "STD": 8.92705481624862,
      "MEAN": 3.23076923076923,
      "COV": 2.76313601455314,
      "SHELF LIFE_1": 4,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 23479847,
      "DESCRIPTION": "GASKET 20858564 ",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 11,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4130000415013,
      "DESCRIPTION": "4130000415004 RELAY",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 18,
      "WHXX": 1,
      "WH REFERENCE": "WHXX",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 60113866,
      "DESCRIPTION": "WASHER 13960143 60113311 ",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 0,
      "WHXX": 13,
      "WH REFERENCE": "WHXX",
      "TREND": 2,
      "STD": 1.10940039245045,
      "MEAN": 0.307692307692307,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 3.000000000000003,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 4110000991063,
      "DESCRIPTION": "SERVICE KIT",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 5223670578,
      "DESCRIPTION": "SHOCK ABSORBER ",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.416666666666666,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 2,
      "APRD QTY": 0,
      "WH12": 14,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 0,
      "STD": 1.38675049056307,
      "MEAN": 0.384615384615384,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 4.800000000000008,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 22674901,
      "DESCRIPTION": "BELT TENSIONER ",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 3,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 0,
      "STD": 1.10940039245045,
      "MEAN": 0.307692307692307,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 3.000000000000003,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 23236853,
      "DESCRIPTION": "FRONT SPRING ",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.833333333333333,
      "MAINTANING STOCK QTY": 3,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 3,
      "APRD QTY": 3,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 1.2,
      "STD": 1.48064435037847,
      "MEAN": 0.769230769230769,
      "COV": 1.92483765549201,
      "SHELF LIFE_1": 3.6000000000000014,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 84479349,
      "DESCRIPTION": "FAN MOTOR ",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 4,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 14359170,
      "DESCRIPTION": "FRONT WINDOW GLASS",
      "PRODUCT CATEGORY": "YPG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 2,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 60110039,
      "DESCRIPTION": "HEXAGON SCREW 13955419",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.666666666666666,
      "MAINTANING STOCK QTY": 3,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 3,
      "APRD QTY": 3,
      "WH12": 0,
      "WHXX": 15,
      "WH REFERENCE": "WHXX",
      "TREND": 2,
      "STD": 2.21880078490091,
      "MEAN": 0.615384615384615,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 4.500000000000004,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 984752,
      "DESCRIPTION": "FLANGE SCREW 60113092 ",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 14,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 3537507,
      "DESCRIPTION": " O-RING",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 1,
      "MAINTANING STOCK QTY": 4,
      "AVAILABLE": 4,
      "SHELF LIFE": 4,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 28,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 3.32820117735137,
      "MEAN": 0.923076923076923,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 4,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 3537521,
      "DESCRIPTION": " O-RING",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 4,
      "SHELF LIFE": 24.000000000000096,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3,
      "WHXX": 10,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 24.000000000000096,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 70918495,
      "DESCRIPTION": "FILTER",
      "PRODUCT CATEGORY": "VCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.25,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 0,
      "STD": 0.832050294337843,
      "MEAN": 0.23076923076923,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 4,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 20799728,
      "DESCRIPTION": "STUD 20405587",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 1,
      "MAINTANING STOCK QTY": 4,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 4,
      "APRD QTY": 4,
      "WH12": 0,
      "WHXX": 16,
      "WH REFERENCE": "WHXX",
      "TREND": 2,
      "STD": 3.32820117735137,
      "MEAN": 0.923076923076923,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 4,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 14725651,
      "DESCRIPTION": "DOOR CATCH 14636279 ",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3,
      "WHXX": 3,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 7007302,
      "DESCRIPTION": "SWITCH ROCKER ",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "DOT4",
      "DESCRIPTION": "BRAKE FLUID 1161968 (1L) ",
      "PRODUCT CATEGORY": "LUP",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 6,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "ADBLUE20L",
      "DESCRIPTION": "ADBLUE - UREA SOLUTION ",
      "PRODUCT CATEGORY": "ZZY",
      "ITEM MOVEMENT BRANCH": "FAST (F1)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 1638.33333333333,
      "MAINTANING STOCK QTY": 6553,
      "AVAILABLE": 1580,
      "SHELF LIFE": 0.9643947100712125,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 4973,
      "APRD QTY": 4973,
      "WH12": 10248,
      "WHXX": 760,
      "WH REFERENCE": "WH12 + WHXX",
      "TREND": 0.74,
      "STD": 1367.12687686106,
      "MEAN": 1512.30769230769,
      "COV": 0.904000478087176,
      "SHELF LIFE_1": 3.999796541200415,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 14762190,
      "DESCRIPTION": "CAB 14745861 14739507",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 2,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 17460844,
      "DESCRIPTION": "CAP",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3,
      "WHXX": 7,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 11708371,
      "DESCRIPTION": "LIMITED SLIP GEAR OIL SAE 85W-90LS API-GL5 15195533",
      "PRODUCT CATEGORY": "LUT",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 27.6666666666666,
      "MAINTANING STOCK QTY": 111,
      "AVAILABLE": 68,
      "SHELF LIFE": 2.4578313253012105,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 43,
      "APRD QTY": 40,
      "WH12": 177,
      "WHXX": 264,
      "WH REFERENCE": "WHXX",
      "TREND": 1.11,
      "STD": 47.1462536239013,
      "MEAN": 25.5384615384615,
      "COV": 1.84608824430938,
      "SHELF LIFE_1": 4.012048192771094,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 984734,
      "DESCRIPTION": "FLANGE SCREW 13946173  60113080 ",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 1,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 69,
      "WHXX": 6,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 5223714921,
      "DESCRIPTION": "5224202070 SERVICE KIT 441029Z00A",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 1,
      "SHELF LIFE": 3.000000000000003,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 6,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 1.10940039245045,
      "MEAN": 0.307692307692307,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 3.000000000000003,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 990989,
      "DESCRIPTION": "LOCK NUT 997395 983313 981314",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 22,
      "WHXX": 13,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 17533661,
      "DESCRIPTION": "OIL FILTER 21707133 (X8)",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "FAST (F1)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 10,
      "MAINTANING STOCK QTY": 40,
      "AVAILABLE": 15,
      "SHELF LIFE": 1.5,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 25,
      "APRD QTY": 25,
      "WH12": 197,
      "WHXX": 31,
      "WH REFERENCE": "WHXX",
      "TREND": 0.45,
      "STD": 8.72882052125644,
      "MEAN": 9.23076923076923,
      "COV": 0.945622223136115,
      "SHELF LIFE_1": 4,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 14884680,
      "DESCRIPTION": "SCREW 9012-53021",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 3,
      "MAINTANING STOCK QTY": 12,
      "AVAILABLE": 8,
      "SHELF LIFE": 2.6666666666666665,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 4,
      "APRD QTY": 4,
      "WH12": 38,
      "WHXX": 20,
      "WH REFERENCE": "WHXX",
      "TREND": 1.67,
      "STD": 6.75960854578929,
      "MEAN": 2.76923076923076,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 4,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": "01700-065-0390-370-18GA",
      "DESCRIPTION": "PIN 65 X 390",
      "PRODUCT CATEGORY": "EIK",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": "037CAT315-PNR-EWT",
      "DESCRIPTION": "PIN & RETAINER 9J2308 / 8E6259",
      "PRODUCT CATEGORY": "EIK",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 2,
      "MAINTANING STOCK QTY": 8,
      "AVAILABLE": 3,
      "SHELF LIFE": 1.5,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 5,
      "APRD QTY": 5,
      "WH12": 113,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 1.33,
      "STD": 4.79315751612222,
      "MEAN": 1.84615384615384,
      "COV": 2.5962936545662,
      "SHELF LIFE_1": 4,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": "037CAT315-ADP-EWT",
      "DESCRIPTION": "ADAPTER 3G6304 3302",
      "PRODUCT CATEGORY": "EIK",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 64,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "037CAT315-TIP-EWT",
      "DESCRIPTION": "TIP 1U3302",
      "PRODUCT CATEGORY": "EIK",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 2,
      "MAINTANING STOCK QTY": 8,
      "AVAILABLE": 5,
      "SHELF LIFE": 2.5,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 3,
      "APRD QTY": 3,
      "WH12": 111,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 1.33,
      "STD": 4.79315751612222,
      "MEAN": 1.84615384615384,
      "COV": 2.5962936545662,
      "SHELF LIFE_1": 4,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 17532113,
      "DESCRIPTION": "CONTROL PANEL 17201774 17444661",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4190000034,
      "DESCRIPTION": "VACUUM BOOSTER",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 4,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 21806167,
      "DESCRIPTION": "FUSE,  32V/23A",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 10,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 2,
      "STD": 1.10940039245045,
      "MEAN": 0.307692307692307,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 3.000000000000003,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 23796624,
      "DESCRIPTION": "BASE PLATE 23796624",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 10,
      "WHXX": 1,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "160602020026A",
      "DESCRIPTION": "AIR FILTER ELEMENT",
      "PRODUCT CATEGORY": "YPG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 17535679,
      "DESCRIPTION": "OIL FILTER 17457469 3831236 (X10)",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "FAST (F1)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 29.6666666666666,
      "MAINTANING STOCK QTY": 119,
      "AVAILABLE": 10,
      "SHELF LIFE": 0.337078651685394,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 109,
      "APRD QTY": 109,
      "WH12": 252,
      "WHXX": 161,
      "WH REFERENCE": "WHXX",
      "TREND": 0.85,
      "STD": 15.8405516609453,
      "MEAN": 27.3846153846153,
      "COV": 0.578447111214294,
      "SHELF LIFE_1": 4.011235955056189,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": "168899001038A",
      "DESCRIPTION": "FILTER EJ200-1105140A YC",
      "PRODUCT CATEGORY": "YPG",
      "ITEM MOVEMENT BRANCH": "AVERAGE (F2)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.666666666666666,
      "MAINTANING STOCK QTY": 3,
      "AVAILABLE": 3,
      "SHELF LIFE": 4.500000000000004,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 37,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 1,
      "STD": 1.50213523239762,
      "MEAN": 0.615384615384615,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 4.500000000000004,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": "168899001032A",
      "DESCRIPTION": "OIL STRAINER ASSEMBLY",
      "PRODUCT CATEGORY": "YPG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 1,
      "SHELF LIFE": 3.000000000000003,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 16,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 1,
      "STD": 0.75106761619881,
      "MEAN": 0.307692307692307,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 3.000000000000003,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": "168899001037A",
      "DESCRIPTION": "FILTER EJ200-1105140A YC",
      "PRODUCT CATEGORY": "YPG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 67,
      "WHXX": 3,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 990973,
      "DESCRIPTION": "HEXAGON NUT 997411 979022",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 10,
      "WHXX": 4,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 7196450,
      "DESCRIPTION": "KIT SEAL",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14614556,
      "DESCRIPTION": "RECOIL SPRING EC750DL",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.416666666666666,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 2,
      "APRD QTY": 2,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 1.6,
      "STD": 0.869718492622904,
      "MEAN": 0.384615384615384,
      "COV": 2.26126808081955,
      "SHELF LIFE_1": 4.800000000000008,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 60335271,
      "DESCRIPTION": "BELT,POLYWEDGE",
      "PRODUCT CATEGORY": "YPG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 15,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 49,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 160604020018,
      "DESCRIPTION": "DIESEL FINE FILTER CORE",
      "PRODUCT CATEGORY": "YPG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.25,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 1,
      "SHELF LIFE": 4,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 31,
      "WHXX": 1,
      "WH REFERENCE": "",
      "TREND": 1.33,
      "STD": 0.599144689515278,
      "MEAN": 0.23076923076923,
      "COV": 2.5962936545662,
      "SHELF LIFE_1": 4,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 14732391,
      "DESCRIPTION": "TOOTH, NEW VTS GPE 41",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.5,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 2,
      "SHELF LIFE": 4,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 262,
      "WHXX": 4,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 1.66410058867568,
      "MEAN": 0.461538461538461,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 4,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 17473104,
      "DESCRIPTION": "PIN 41",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.5,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 2,
      "SHELF LIFE": 4,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 541,
      "WHXX": 7,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 1.66410058867568,
      "MEAN": 0.461538461538461,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 4,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 17493104,
      "DESCRIPTION": "RETAINER 41",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.5,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 2,
      "APRD QTY": 2,
      "WH12": 42,
      "WHXX": 11,
      "WH REFERENCE": "WHXX",
      "TREND": 2,
      "STD": 1.66410058867568,
      "MEAN": 0.461538461538461,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 4,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": "C61355",
      "DESCRIPTION": "UPPER BRASS RETAINER/SU+125/D205*L78",
      "PRODUCT CATEGORY": "SOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4120006629007,
      "DESCRIPTION": "SEALING KIT",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.666666666666666,
      "MAINTANING STOCK QTY": 3,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 3,
      "APRD QTY": 3,
      "WH12": 62,
      "WHXX": 2,
      "WH REFERENCE": "WH12 + WHXX",
      "TREND": 2,
      "STD": 2.21880078490091,
      "MEAN": 0.615384615384615,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 4.500000000000004,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 23807658,
      "DESCRIPTION": "SENSOR 20482772",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 5,
      "WHXX": 3,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14696775,
      "DESCRIPTION": "ENGINE COOLING FAN",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4120016310001,
      "DESCRIPTION": "FILTER INSERT",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 50,
      "WHXX": 3,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "A229900003075",
      "DESCRIPTION": "ISOLATOR, TRANSMISSION MOUNT",
      "PRODUCT CATEGORY": "YCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 997475,
      "DESCRIPTION": "HEXAGON SCREW 978950",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 4.33333333333333,
      "MAINTANING STOCK QTY": 17,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 17,
      "APRD QTY": 17,
      "WH12": 20,
      "WHXX": 47,
      "WH REFERENCE": "WHXX",
      "TREND": 1.85,
      "STD": 9.52190457139046,
      "MEAN": 4,
      "COV": 2.38047614284761,
      "SHELF LIFE_1": 3.9230769230769256,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": "130699000043A",
      "DESCRIPTION": "DRIVEN DISC ASSEMBLY, CLUTCH",
      "PRODUCT CATEGORY": "YTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 5282685304,
      "DESCRIPTION": "FILTER",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 1.33333333333333,
      "MAINTANING STOCK QTY": 5,
      "AVAILABLE": 5,
      "SHELF LIFE": 3.7500000000000098,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 10,
      "WHXX": 13,
      "WH REFERENCE": "",
      "TREND": 1.25,
      "STD": 2.42053183391054,
      "MEAN": 1.23076923076923,
      "COV": 1.96668211505231,
      "SHELF LIFE_1": 3.7500000000000098,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 14689355,
      "DESCRIPTION": "BLADE",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 5223237153,
      "DESCRIPTION": "HUB",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "006B12A-165-AAL",
      "DESCRIPTION": "BOLT ¬Æ‚ÄÙ X 61/2‚ÄÙ ",
      "PRODUCT CATEGORY": "EIK",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "007LN12A-AA",
      "DESCRIPTION": "LOCK NUT ¬Æ‚ÄÙ",
      "PRODUCT CATEGORY": "EIK",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 7,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "006B24M-080-AAH",
      "DESCRIPTION": "BOLT M24 X 80",
      "PRODUCT CATEGORY": "EIK",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 32,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "008SW24M-AA",
      "DESCRIPTION": "SPRING WASHER M24",
      "PRODUCT CATEGORY": "EIK",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 32,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "007N24M-AA",
      "DESCRIPTION": "NUT M24",
      "PRODUCT CATEGORY": "EIK",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 32,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "037EC200BCL-SC-EWT",
      "DESCRIPTION": "SIDE CUTTER (LEFT)",
      "PRODUCT CATEGORY": "EIK",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "037EC200BCR-SC-EWT",
      "DESCRIPTION": "SIDE CUTTER (RIGHT)",
      "PRODUCT CATEGORY": "EIK",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14699314,
      "DESCRIPTION": "SLIDING WINDOW",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 5223919366,
      "DESCRIPTION": "LATCH",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 70,
      "WHXX": 10,
      "WH REFERENCE": "WHXX",
      "TREND": 0,
      "STD": 1.10940039245045,
      "MEAN": 0.307692307692307,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 3.000000000000003,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 46696269,
      "DESCRIPTION": "LAMP, 1050W MH SMALL",
      "PRODUCT CATEGORY": "DOP",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 14,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 257653,
      "DESCRIPTION": "LEAF SPRING",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 5,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 95653614,
      "DESCRIPTION": "TOOTH ADAPTER",
      "PRODUCT CATEGORY": "VCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 0,
      "WHXX": 3,
      "WH REFERENCE": "WHXX",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 11147716,
      "DESCRIPTION": "FITTING",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 0,
      "WHXX": 7,
      "WH REFERENCE": "WHXX",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 5223976027,
      "DESCRIPTION": "TURN SIGNAL SWITCH 5223607332",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 2,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 17459093,
      "DESCRIPTION": "MIRROR",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4013000157,
      "DESCRIPTION": "LOCK NUT",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 15714575,
      "DESCRIPTION": "OIL FILTER 17246027",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 13,
      "WHXX": 18,
      "WH REFERENCE": "WHXX",
      "TREND": 1,
      "STD": 0.75106761619881,
      "MEAN": 0.307692307692307,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 3.000000000000003,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 14713404,
      "DESCRIPTION": "HOSE",
      "PRODUCT CATEGORY": "VOH",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14727790,
      "DESCRIPTION": "BUSHING 14512446",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 5223958455,
      "DESCRIPTION": "OIL FILTER KIT, 5223476572",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "AVERAGE (F2)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 2.66666666666666,
      "MAINTANING STOCK QTY": 11,
      "AVAILABLE": 7,
      "SHELF LIFE": 2.6250000000000067,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 4,
      "APRD QTY": 4,
      "WH12": 36,
      "WHXX": 14,
      "WH REFERENCE": "WHXX",
      "TREND": 0.69,
      "STD": 2.69615110281875,
      "MEAN": 2.46153846153846,
      "COV": 1.09531138552011,
      "SHELF LIFE_1": 4.125000000000011,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 14750657,
      "DESCRIPTION": "FILTER CARTRIDGE 14744967 14532687",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "FAST (F1)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 5.16666666666666,
      "MAINTANING STOCK QTY": 21,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 21,
      "APRD QTY": 21,
      "WH12": 12,
      "WHXX": 0,
      "WH REFERENCE": "PARTIAL WH12",
      "TREND": 1,
      "STD": 3.56262651597212,
      "MEAN": 4.76923076923076,
      "COV": 0.747002333994155,
      "SHELF LIFE_1": 4.064516129032263,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 14730964,
      "DESCRIPTION": "SEALING KIT 14589133",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 1,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 9,
      "WHXX": 3,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14771505,
      "DESCRIPTION": "CONNECTING ROD 14559439",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 29350010291,
      "DESCRIPTION": "COPPER TUBE",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 2,
      "STD": 0.75106761619881,
      "MEAN": 0.307692307692307,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 3.000000000000003,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 5223714924,
      "DESCRIPTION": "5224202065  BRAKE KIT",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 17515206,
      "DESCRIPTION": "WATER SEPARATOR",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "AVERAGE (F2)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 3.41666666666666,
      "MAINTANING STOCK QTY": 14,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 14,
      "APRD QTY": 14,
      "WH12": 38,
      "WHXX": 30,
      "WH REFERENCE": "WHXX",
      "TREND": 1.07,
      "STD": 3.76045550977878,
      "MEAN": 3.15384615384615,
      "COV": 1.19233955188107,
      "SHELF LIFE_1": 4.097560975609764,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 4190002741,
      "DESCRIPTION": "PIPE",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "160602020036A",
      "DESCRIPTION": "AIR FILTER, MAIN",
      "PRODUCT CATEGORY": "YTG",
      "ITEM MOVEMENT BRANCH": "AVERAGE (F2)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.583333333333333,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 1,
      "SHELF LIFE": 1.714285714285715,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 10,
      "WHXX": 5,
      "WH REFERENCE": "WHXX",
      "TREND": 0.57,
      "STD": 1.12660142429821,
      "MEAN": 0.538461538461538,
      "COV": 2.0922597879824,
      "SHELF LIFE_1": 3.42857142857143,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 61021071,
      "DESCRIPTION": "WHEEL BOLT",
      "PRODUCT CATEGORY": "YTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.833333333333333,
      "MAINTANING STOCK QTY": 3,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 3,
      "APRD QTY": 0,
      "WH12": 87,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 0,
      "STD": 2.77350098112614,
      "MEAN": 0.769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 3.6000000000000014,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 61021072,
      "DESCRIPTION": "WHEEL NUT",
      "PRODUCT CATEGORY": "YTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.833333333333333,
      "MAINTANING STOCK QTY": 3,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 3,
      "APRD QTY": 0,
      "WH12": 413,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 0,
      "STD": 2.77350098112614,
      "MEAN": 0.769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 3.6000000000000014,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": "141502000307A",
      "DESCRIPTION": "AIR CONDITIONING COMPRESSOR",
      "PRODUCT CATEGORY": "YTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 26,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "160602030026A",
      "DESCRIPTION": "AIR FILTER, SAFETY",
      "PRODUCT CATEGORY": "YTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 8,
      "WHXX": 8,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 13631416,
      "DESCRIPTION": "ASSEMBLY, LEFT PANEL REINFORCED PLATE",
      "PRODUCT CATEGORY": "YTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 17500251,
      "DESCRIPTION": "PRIMARY FILTER ",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 1.08333333333333,
      "MAINTANING STOCK QTY": 4,
      "AVAILABLE": 2,
      "SHELF LIFE": 1.846153846153852,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 2,
      "APRD QTY": 2,
      "WH12": 11,
      "WHXX": 14,
      "WH REFERENCE": "WHXX",
      "TREND": 0.46,
      "STD": 1.2909944487358,
      "MEAN": 1,
      "COV": 1.2909944487358,
      "SHELF LIFE_1": 3.692307692307704,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 12445554,
      "DESCRIPTION": "ASSY, FRONT PANEL, REINFORCED PIPE",
      "PRODUCT CATEGORY": "YTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 17500253,
      "DESCRIPTION": "SAFETY FILTER",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 1,
      "SHELF LIFE": 3.000000000000003,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 7,
      "WHXX": 5,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.75106761619881,
      "MEAN": 0.307692307692307,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 3.000000000000003,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 17500260,
      "DESCRIPTION": "PRIMARY FILTER",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 5,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 17500263,
      "DESCRIPTION": "SAFETY FILTER",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 12,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 17526239,
      "DESCRIPTION": "BOWL",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4110000970017,
      "DESCRIPTION": "FUEL PUMP 4110001841016",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 6,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14539108,
      "DESCRIPTION": "BRACKET",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14550492,
      "DESCRIPTION": "HOSE",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14741634,
      "DESCRIPTION": "HOSE",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 7400454,
      "DESCRIPTION": "FILTER FUEL",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 10,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 23690622,
      "DESCRIPTION": "SERVICE KIT( AIR DRIER FILTER)",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 1.16666666666666,
      "MAINTANING STOCK QTY": 5,
      "AVAILABLE": 6,
      "SHELF LIFE": 5.142857142857172,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 39,
      "WHXX": 1,
      "WH REFERENCE": "",
      "TREND": 1.57,
      "STD": 2.39657875806111,
      "MEAN": 1.07692307692307,
      "COV": 2.22539456105674,
      "SHELF LIFE_1": 5.142857142857172,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 5223919397,
      "DESCRIPTION": "SLEEVE TUBE",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.666666666666666,
      "MAINTANING STOCK QTY": 3,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 3,
      "APRD QTY": 0,
      "WH12": 47,
      "WHXX": 30,
      "WH REFERENCE": "WHXX",
      "TREND": 0,
      "STD": 2.21880078490091,
      "MEAN": 0.615384615384615,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 4.500000000000004,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 23974640,
      "DESCRIPTION": "24318489 COOLANT PUMP 21247955 21404502",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "AVERAGE (F2)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 2.41666666666666,
      "MAINTANING STOCK QTY": 10,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 10,
      "APRD QTY": 10,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 1.17,
      "STD": 1.96442723432922,
      "MEAN": 2.23076923076923,
      "COV": 0.880605311940686,
      "SHELF LIFE_1": 4.13793103448277,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 60270562,
      "DESCRIPTION": "ASSEMBLY-OIL FILTER",
      "PRODUCT CATEGORY": "YCG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 2,
      "STD": 1.10940039245045,
      "MEAN": 0.307692307692307,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 3.000000000000003,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 60034227,
      "DESCRIPTION": "OIL FILTER",
      "PRODUCT CATEGORY": "YCG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 1,
      "SHELF LIFE": 3.000000000000003,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 9,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 1.10940039245045,
      "MEAN": 0.307692307692307,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 3.000000000000003,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 141502000076,
      "DESCRIPTION": "DESICCANT",
      "PRODUCT CATEGORY": "YTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 7,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "381669Z02E",
      "DESCRIPTION": "FLANGE SCREW",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.833333333333333,
      "MAINTANING STOCK QTY": 3,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 3,
      "APRD QTY": 0,
      "WH12": 5,
      "WHXX": 40,
      "WH REFERENCE": "WHXX",
      "TREND": 0,
      "STD": 2.77350098112614,
      "MEAN": 0.769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 3.6000000000000014,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 7393870,
      "DESCRIPTION": "FILTER OIL ",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 1,
      "SHELF LIFE": 3.000000000000003,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 88,
      "WHXX": 1,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.75106761619881,
      "MEAN": 0.307692307692307,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 3.000000000000003,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 5223670583,
      "DESCRIPTION": "SHOCK ABSORBER",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 1,
      "SHELF LIFE": 6.000000000000024,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 11,
      "WHXX": 2,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 14788290,
      "DESCRIPTION": "SEAL",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4120004826,
      "DESCRIPTION": "BREATHER CAP",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 26,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 4120004899,
      "DESCRIPTION": "FILTER INSERT",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.416666666666666,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 2,
      "APRD QTY": 2,
      "WH12": 88,
      "WHXX": 2,
      "WH REFERENCE": "WHXX",
      "TREND": 1.2,
      "STD": 0.767947647788304,
      "MEAN": 0.384615384615384,
      "COV": 1.99666388424959,
      "SHELF LIFE_1": 4.800000000000008,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 4120005303003,
      "DESCRIPTION": "FILTER INSERT",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 1,
      "SHELF LIFE": 6.000000000000024,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 47,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 14619311,
      "DESCRIPTION": "SEGMENT",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 6,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "542159Z01D",
      "DESCRIPTION": "PIN",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 1,
      "MAINTANING STOCK QTY": 4,
      "AVAILABLE": 2,
      "SHELF LIFE": 2,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 2,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 4,
      "WH REFERENCE": "WHXX",
      "TREND": 0.17,
      "STD": 2.75262112847429,
      "MEAN": 0.923076923076923,
      "COV": 2.98200622251381,
      "SHELF LIFE_1": 4,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 5223890468,
      "DESCRIPTION": "CYLINDER",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3,
      "WHXX": 1,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4130000503,
      "DESCRIPTION": "ROCKER SWITCH",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 16678175,
      "DESCRIPTION": "GASKET",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 17,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 17271168,
      "DESCRIPTION": "O-RING",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 4,
      "WHXX": 17,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 16658928,
      "DESCRIPTION": "O-RING",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 9,
      "WHXX": 33,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 5223714925,
      "DESCRIPTION": "BRAKE SHOE KIT",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.416666666666666,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 2,
      "SHELF LIFE": 4.800000000000008,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 20,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0.4,
      "STD": 1.1208970766356,
      "MEAN": 0.384615384615384,
      "COV": 2.91433239925258,
      "SHELF LIFE_1": 4.800000000000008,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 14712690,
      "DESCRIPTION": "FILTER",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 0,
      "WHXX": 11,
      "WH REFERENCE": "WHXX",
      "TREND": 0,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 23658092,
      "DESCRIPTION": "OIL FILTER",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.75,
      "MAINTANING STOCK QTY": 3,
      "AVAILABLE": 42,
      "SHELF LIFE": 56,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 10,
      "WH REFERENCE": "",
      "TREND": 1.78,
      "STD": 1.70218562367207,
      "MEAN": 0.692307692307692,
      "COV": 2.45871256752633,
      "SHELF LIFE_1": 56,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 997484,
      "DESCRIPTION": "HEXAGON SCREW 983696",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.583333333333333,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 2,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 9,
      "WH REFERENCE": "WHXX",
      "TREND": 0,
      "STD": 1.05003052458683,
      "MEAN": 0.538461538461538,
      "COV": 1.9500566885184,
      "SHELF LIFE_1": 3.42857142857143,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 4110001475095,
      "DESCRIPTION": "FUEL FILTER INSERT 4110001475082",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 8,
      "SHELF LIFE": 48.00000000000019,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 161,
      "WHXX": 6,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 48.00000000000019,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": "SSL01200",
      "DESCRIPTION": "FRONT COVER",
      "PRODUCT CATEGORY": "SOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 5223813747,
      "DESCRIPTION": "HEADLAMP",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 5223658117,
      "DESCRIPTION": "OIL FILTER 5222771970 152009Z00A",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "AVERAGE (F2)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 6.08333333333333,
      "MAINTANING STOCK QTY": 24,
      "AVAILABLE": 19,
      "SHELF LIFE": 3.123287671232878,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 5,
      "APRD QTY": 5,
      "WH12": 186,
      "WHXX": 34,
      "WH REFERENCE": "WHXX",
      "TREND": 1.23,
      "STD": 7.365895075034,
      "MEAN": 5.61538461538461,
      "COV": 1.31173473938961,
      "SHELF LIFE_1": 3.945205479452057,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 7322705,
      "DESCRIPTION": "TIRE HEAVY DUTY 10X16.5 10PLY",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "LF17535",
      "DESCRIPTION": "LUBE FILTER",
      "PRODUCT CATEGORY": "CUF",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 15184181,
      "DESCRIPTION": "JOINT",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 0,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 29070015581,
      "DESCRIPTION": "ROUND NUT",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4110001554040,
      "DESCRIPTION": " THRUST RING 4110001554019 4110001554020",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 2,
      "STD": 1.10940039245045,
      "MEAN": 0.307692307692307,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 3.000000000000003,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 11445396,
      "DESCRIPTION": "CHECK VALVE",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 1,
      "WH REFERENCE": "WHXX",
      "TREND": 0,
      "STD": 1.10940039245045,
      "MEAN": 0.307692307692307,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 3.000000000000003,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 9014594707,
      "DESCRIPTION": "9014794925 ECU",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 7408600,
      "DESCRIPTION": "FILTER AIR INNER",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 13,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 7408601,
      "DESCRIPTION": "FILTER AIR OUTER",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.25,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 1,
      "SHELF LIFE": 4,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 6,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.599144689515278,
      "MEAN": 0.23076923076923,
      "COV": 2.5962936545662,
      "SHELF LIFE_1": 4,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": "SSL01118",
      "DESCRIPTION": "ROD, (D135) MOILPOINT SU+85",
      "PRODUCT CATEGORY": "SOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.416666666666666,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 2,
      "SHELF LIFE": 4.800000000000008,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 24,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.767947647788304,
      "MEAN": 0.384615384615384,
      "COV": 1.99666388424959,
      "SHELF LIFE_1": 4.800000000000008,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 5223488073,
      "DESCRIPTION": "POWER TAKE-OFF",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "SSL01198",
      "DESCRIPTION": "FRONT COVER/GENERAL EX/SB70",
      "PRODUCT CATEGORY": "SOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14759225,
      "DESCRIPTION": "WIRING HARNESS",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14596256,
      "DESCRIPTION": "HOSE",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14720505,
      "DESCRIPTION": "14778019 AIR CONDITIONING UNIT",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14881135,
      "DESCRIPTION": "HOSE",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 70921614,
      "DESCRIPTION": "HARNESS CANOPY LAMPPAVER",
      "PRODUCT CATEGORY": "VCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 70860382,
      "DESCRIPTION": "GUARD",
      "PRODUCT CATEGORY": "VCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 70871884,
      "DESCRIPTION": "VIBRATION DAMPER",
      "PRODUCT CATEGORY": "VCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 96705249,
      "DESCRIPTION": "HEXAGON SCREW",
      "PRODUCT CATEGORY": "VCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 94327905,
      "DESCRIPTION": "PLAIN WASHER",
      "PRODUCT CATEGORY": "VCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 70908710,
      "DESCRIPTION": "SEALING KIT",
      "PRODUCT CATEGORY": "VCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 70908652,
      "DESCRIPTION": "SEALING KIT",
      "PRODUCT CATEGORY": "VCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 12780718,
      "DESCRIPTION": "TAIL LIGHT",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 12780719,
      "DESCRIPTION": "TAIL LIGHT",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 12834256,
      "DESCRIPTION": "LAMP",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 12820135,
      "DESCRIPTION": "WORK LAMP",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 12791508,
      "DESCRIPTION": "CLAMPING STRIP",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4110000909061,
      "DESCRIPTION": "HOSE",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 7184231,
      "DESCRIPTION": "VALVE, CONTROL SERVICE",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4130000627,
      "DESCRIPTION": "INDICATOR LAMP",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 5224014098,
      "DESCRIPTION": "FLANGE SCREW",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 15,
      "WH REFERENCE": "WHXX",
      "TREND": 0,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 5224014097,
      "DESCRIPTION": "FLANGE SCREW",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 15,
      "WH REFERENCE": "WHXX",
      "TREND": 0,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 5224014100,
      "DESCRIPTION": "NORD LOCK ANTI-LOOSEN WASHER",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 35,
      "WHXX": 31,
      "WH REFERENCE": "WHXX",
      "TREND": 0,
      "STD": 1.10940039245045,
      "MEAN": 0.307692307692307,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 3.000000000000003,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 14644257,
      "DESCRIPTION": "BRACKET",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14521837,
      "DESCRIPTION": "SPACER",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 28120005471,
      "DESCRIPTION": "GASKET",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4110003906,
      "DESCRIPTION": "WATER SEPARATOR",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4110004069036,
      "DESCRIPTION": "SENSOR 4110003380015",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3,
      "WHXX": 3,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4011373,
      "DESCRIPTION": "SOCKET BOLT : SU+85,105",
      "PRODUCT CATEGORY": "SOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 4,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 11220925,
      "DESCRIPTION": "FILTER NETTING",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.666666666666666,
      "MAINTANING STOCK QTY": 3,
      "AVAILABLE": 64,
      "SHELF LIFE": 96.0000000000001,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 217,
      "WHXX": 48,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 2.21880078490091,
      "MEAN": 0.615384615384615,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 96.0000000000001,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 5223683591,
      "DESCRIPTION": "SHOCK ABSORBER",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 2,
      "SHELF LIFE": 12.000000000000048,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 5,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 12.000000000000048,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 5223763956,
      "DESCRIPTION": "HEADLAMP LH",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 5223763958,
      "DESCRIPTION": "HEADLAMP RH",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 5223891584,
      "DESCRIPTION": "WIRES",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 1,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 9,
      "WHXX": 21,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14638550,
      "DESCRIPTION": "BRACKET 14641567",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 23235621,
      "DESCRIPTION": "ARM",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 26,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 7135536,
      "DESCRIPTION": "SEAL KIT",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 6568974,
      "DESCRIPTION": "KIT, AXLE SEAL",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.666666666666666,
      "MAINTANING STOCK QTY": 3,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 3,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 0,
      "STD": 2.21880078490091,
      "MEAN": 0.615384615384615,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 4.500000000000004,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 3974866,
      "DESCRIPTION": "BEARING, AXLE",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.666666666666666,
      "MAINTANING STOCK QTY": 3,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 3,
      "APRD QTY": 0,
      "WH12": 6,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 0,
      "STD": 2.21880078490091,
      "MEAN": 0.615384615384615,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 4.500000000000004,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 14747096,
      "DESCRIPTION": "BOLT",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 5,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "A820204000495",
      "DESCRIPTION": "BUSHING, BOGIE",
      "PRODUCT CATEGORY": "YCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "A820301021118",
      "DESCRIPTION": "COTTER PIN, REAR SPRING",
      "PRODUCT CATEGORY": "YCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "A210355000009",
      "DESCRIPTION": "NUT M30√Ó2GB9457",
      "PRODUCT CATEGORY": "YCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "C63390",
      "DESCRIPTION": "UPPER CUSHION",
      "PRODUCT CATEGORY": "SOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "C63391",
      "DESCRIPTION": "UPPER CUSHION GUIDE",
      "PRODUCT CATEGORY": "SOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "A820201020000",
      "DESCRIPTION": "PIN",
      "PRODUCT CATEGORY": "YCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "A820299001611",
      "DESCRIPTION": "RING, SPACE",
      "PRODUCT CATEGORY": "YCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 46549754,
      "DESCRIPTION": "CAPACITOR ALT MECC ALTE",
      "PRODUCT CATEGORY": "DOP",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 1,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 21897549,
      "DESCRIPTION": "ALTERNATOR 21615649",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14734221,
      "DESCRIPTION": "WORK LAMP",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14732412,
      "DESCRIPTION": "14791902 WORK LAMP",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 60159534,
      "DESCRIPTION": "FUEL CLEANER FILTER CORE - FF5612",
      "PRODUCT CATEGORY": "YCG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 1,
      "SHELF LIFE": 3.000000000000003,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 10,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 1.10940039245045,
      "MEAN": 0.307692307692307,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 3.000000000000003,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 13724531,
      "DESCRIPTION": "PULL ASSEMBLY",
      "PRODUCT CATEGORY": "YPG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "A210111000018",
      "DESCRIPTION": "BOLT",
      "PRODUCT CATEGORY": "YPG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "A210319000001",
      "DESCRIPTION": "NUT",
      "PRODUCT CATEGORY": "YPG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 10,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4110000054296,
      "DESCRIPTION": "PUSH ROD",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 5,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4130001978,
      "DESCRIPTION": "PUSH PULL CONTROL",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4110001949018,
      "DESCRIPTION": "CONNECTING ROD 4110000991029",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 4,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14695933,
      "DESCRIPTION": "CONTROL UNIT",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "487129Z01A",
      "DESCRIPTION": "HOSE",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 4,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 16290793,
      "DESCRIPTION": "FILTER ELEMENT",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.25,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 2,
      "SHELF LIFE": 8,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 67,
      "WHXX": 15,
      "WH REFERENCE": "",
      "TREND": 0.67,
      "STD": 0.438529009653514,
      "MEAN": 0.23076923076923,
      "COV": 1.90029237516522,
      "SHELF LIFE_1": 8,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 14778024,
      "DESCRIPTION": "AIR CONDITIONING UNIT 14726895",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "261319Z01C",
      "DESCRIPTION": "TAIL LIGHT",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 37,
      "WHXX": 2,
      "WH REFERENCE": "WHXX",
      "TREND": 2,
      "STD": 1.10940039245045,
      "MEAN": 0.307692307692307,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 3.000000000000003,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 5223502052,
      "DESCRIPTION": "TAIL LIGHT",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 1,
      "SHELF LIFE": 3.000000000000003,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 22,
      "WHXX": 4,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 1.10940039245045,
      "MEAN": 0.307692307692307,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 3.000000000000003,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 11705472,
      "DESCRIPTION": "SCREW",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 43926765,
      "DESCRIPTION": "AIR CLEANER",
      "PRODUCT CATEGORY": "VCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14732836,
      "DESCRIPTION": "TRACK CHAIN 14547301 ",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "152599000172A001",
      "DESCRIPTION": "DRYING CYLINDER",
      "PRODUCT CATEGORY": "YTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 213,
      "WHXX": 9,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "543709Z00B",
      "DESCRIPTION": "SENSOR WHEEL",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 96704580,
      "DESCRIPTION": "LOCK NUT",
      "PRODUCT CATEGORY": "VCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 0,
      "WHXX": 87,
      "WH REFERENCE": "WHXX",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 4110001015128,
      "DESCRIPTION": "AIR COMPRESSOR 4110001015033",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 1,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 913111,
      "DESCRIPTION": "CLEVIS PIN",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 60158886,
      "DESCRIPTION": "GEARPUMP",
      "PRODUCT CATEGORY": "YCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14740272,
      "DESCRIPTION": "CAB",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "542159Z01C",
      "DESCRIPTION": "PIN",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4130001879,
      "DESCRIPTION": "SWITCH",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 16866694,
      "DESCRIPTION": "EXHAUST PIPE ELBOW",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 2,
      "WH REFERENCE": "WHXX",
      "TREND": 0,
      "STD": 0.375533808099405,
      "MEAN": 0.153846153846153,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 14691364,
      "DESCRIPTION": "FLEXIBLE COUPLING 14522374",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14739511,
      "DESCRIPTION": "HANDLE 14529995",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14780714,
      "DESCRIPTION": "AIR FILTER 4880805",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "540409Z01C",
      "DESCRIPTION": "BUSHING",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.833333333333333,
      "MAINTANING STOCK QTY": 3,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 3,
      "APRD QTY": 3,
      "WH12": 14,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 2,
      "STD": 2.77350098112614,
      "MEAN": 0.769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 3.6000000000000014,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 4110000924182,
      "DESCRIPTION": "GEAR",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 29030029721,
      "DESCRIPTION": "HOSE",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 9014636301,
      "DESCRIPTION": "ELECTRONIC UNIT",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4110000076426,
      "DESCRIPTION": "SHIM 4110000076271",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3,
      "WHXX": 4,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4110001321383,
      "DESCRIPTION": "SHIM 4110000076271",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3,
      "WHXX": 4,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 7176170,
      "DESCRIPTION": "HOSE HYDRAULIC FORMED",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "160101080007A016",
      "DESCRIPTION": "OIL FILTER ELEMENT",
      "PRODUCT CATEGORY": "YPG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 5,
      "WHXX": 1,
      "WH REFERENCE": "WHXX",
      "TREND": 0,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 4110015815005,
      "DESCRIPTION": "CRANKSHAFT 4110000909105",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 23044511,
      "DESCRIPTION": "FUEL FILTER 15126069",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 1,
      "MAINTANING STOCK QTY": 4,
      "AVAILABLE": 5,
      "SHELF LIFE": 5,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 99,
      "WHXX": 10,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 2.28988858759323,
      "MEAN": 0.923076923076923,
      "COV": 2.48071263655933,
      "SHELF LIFE_1": 5,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 4110001321009,
      "DESCRIPTION": "SHIM 4110000076271",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 1,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 2,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 60110070,
      "DESCRIPTION": "LOCK NUT",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "30 DAY AVE": 0.666666666666666,
      "MAINTANING STOCK QTY": 3,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 3,
      "APRD QTY": 3,
      "WH12": 12,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 2,
      "STD": 2.21880078490091,
      "MEAN": 0.615384615384615,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 4.500000000000004,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 17510496,
      "DESCRIPTION": "PROPELLER SHAFT",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 4,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 1,
      "STD": 0.375533808099405,
      "MEAN": 0.153846153846153,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 14619051,
      "DESCRIPTION": "TRACK SHOE",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 42,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 0,
      "STD": 1.10940039245045,
      "MEAN": 0.307692307692307,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 3.000000000000003,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 15184180,
      "DESCRIPTION": "JOINT",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 0,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 29050023491,
      "DESCRIPTION": "SPACER SLEEVE",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14530606,
      "DESCRIPTION": "BRACKET",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 1,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14652625,
      "DESCRIPTION": "PLATE",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.25,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 0,
      "WHXX": 2,
      "WH REFERENCE": "WHXX",
      "TREND": 2,
      "STD": 0.832050294337843,
      "MEAN": 0.23076923076923,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 4,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": "275009Z00C",
      "DESCRIPTION": "CONTROL PANEL",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 7291660,
      "DESCRIPTION": "KIT, REPAIR",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 1,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 17535118,
      "DESCRIPTION": "FILTER RETAINER 15115797",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 6,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 1,
      "STD": 0.375533808099405,
      "MEAN": 0.153846153846153,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 14403813,
      "DESCRIPTION": "FUEL FILTER 4110000727162",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "AVERAGE (F2)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 3.58333333333333,
      "MAINTANING STOCK QTY": 14,
      "AVAILABLE": 101,
      "SHELF LIFE": 28.186046511627932,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 504,
      "WHXX": 52,
      "WH REFERENCE": "",
      "TREND": 0.93,
      "STD": 4.42313266408578,
      "MEAN": 3.3076923076923,
      "COV": 1.33722615425849,
      "SHELF LIFE_1": 28.186046511627932,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 9020460945,
      "DESCRIPTION": "TURBOCHARGER",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4110000991044,
      "DESCRIPTION": "PISTON KIT",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 6,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4110000991041,
      "DESCRIPTION": "CYLINDER HEAD KIT",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 6,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4110001009036,
      "DESCRIPTION": "BRACKET",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4110001009051,
      "DESCRIPTION": "SCREW",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 4,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4110001007088,
      "DESCRIPTION": "SCREW",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4110000556271,
      "DESCRIPTION": "BEARING SHELL 4110000556110 4110000556109",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.5,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 2,
      "APRD QTY": 2,
      "WH12": 9,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 2,
      "STD": 1.66410058867568,
      "MEAN": 0.461538461538461,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 4,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 17483046,
      "DESCRIPTION": "PARKING BRAKE VALVE 17202132",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 2,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "323089Z00A",
      "DESCRIPTION": "PLUG",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 16,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14714050,
      "DESCRIPTION": "BOLT 14619060",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 10.5,
      "MAINTANING STOCK QTY": 42,
      "AVAILABLE": 37,
      "SHELF LIFE": 3.5238095238095237,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 5,
      "APRD QTY": 0,
      "WH12": 31,
      "WHXX": 74,
      "WH REFERENCE": "WHXX",
      "TREND": 0.38,
      "STD": 17.1628698813485,
      "MEAN": 9.69230769230769,
      "COV": 1.77077228934548,
      "SHELF LIFE_1": 4,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 5278618571,
      "DESCRIPTION": "COMPRESSOR",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 19,
      "WHXX": 8,
      "WH REFERENCE": "WHXX",
      "TREND": 0,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 20459181,
      "DESCRIPTION": "CLAMP",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14496140,
      "DESCRIPTION": "MAIN VALVE ASSEMBLY",
      "PRODUCT CATEGORY": "YCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 7023149,
      "DESCRIPTION": "JOYSTICK, RH ASSY",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 1,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4120000898006,
      "DESCRIPTION": "O-RING",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4120000898014,
      "DESCRIPTION": "SEALING RING",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 4,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4120000898015,
      "DESCRIPTION": "DUST COVER",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 4,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4120000898001,
      "DESCRIPTION": "BRAKE PAD",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 10,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14731779,
      "DESCRIPTION": "TOOTH",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.833333333333333,
      "MAINTANING STOCK QTY": 3,
      "AVAILABLE": 30,
      "SHELF LIFE": 36.000000000000014,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 25,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 2.77350098112614,
      "MEAN": 0.769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 36.000000000000014,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 14740815,
      "DESCRIPTION": "LOCK",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.833333333333333,
      "MAINTANING STOCK QTY": 3,
      "AVAILABLE": 30,
      "SHELF LIFE": 36.000000000000014,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 25,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 2.77350098112614,
      "MEAN": 0.769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 36.000000000000014,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 14740816,
      "DESCRIPTION": "RETAINER",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.833333333333333,
      "MAINTANING STOCK QTY": 3,
      "AVAILABLE": 26,
      "SHELF LIFE": 31.20000000000001,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 2.77350098112614,
      "MEAN": 0.769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 31.20000000000001,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 4110002230035,
      "DESCRIPTION": "NEEDLE BEARING",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 2,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 20405902,
      "DESCRIPTION": "GASKET",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 5224009155,
      "DESCRIPTION": "SENSOR",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 1,
      "SHELF LIFE": 3.000000000000003,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 27,
      "WHXX": 6,
      "WH REFERENCE": "",
      "TREND": 1.5,
      "STD": 0.630425171956115,
      "MEAN": 0.307692307692307,
      "COV": 2.04888180885737,
      "SHELF LIFE_1": 3.000000000000003,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 4110000991043,
      "DESCRIPTION": "CYLINDER LINER KIT",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 6,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4110000991045,
      "DESCRIPTION": "CAMSHAFT BEARING KIT",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "TP4061007",
      "DESCRIPTION": "ALTERNATOR",
      "PRODUCT CATEGORY": "SDG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 11216211,
      "DESCRIPTION": "WIPER BLADE 14400348",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 10,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 60247900,
      "DESCRIPTION": "PRESSURE SWITCH",
      "PRODUCT CATEGORY": "YPG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 11052508,
      "DESCRIPTION": "BOLT",
      "PRODUCT CATEGORY": "YCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 11,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "A820301021095",
      "DESCRIPTION": "PIN",
      "PRODUCT CATEGORY": "YCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "A820301021100",
      "DESCRIPTION": "ROD",
      "PRODUCT CATEGORY": "YCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 23935622,
      "DESCRIPTION": "BRACKET 20805520",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 6515541,
      "DESCRIPTION": "FILTER, OIL HYD 12",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 30,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 6563995,
      "DESCRIPTION": "KIT FILTER",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 8,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 3885315,
      "DESCRIPTION": "FILTER, FUEL 12",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 26,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 84474457,
      "DESCRIPTION": "HOSE                     ",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 84474434,
      "DESCRIPTION": "HOSE                     ",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": "SSC31211L0N",
      "DESCRIPTION": "ROD(MOIL POINT) SB81",
      "PRODUCT CATEGORY": "SOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 2,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14558098,
      "DESCRIPTION": "TUBE",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 3,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14558095,
      "DESCRIPTION": "TUBE",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 2,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4110001015046,
      "DESCRIPTION": "GEAR",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 60110101,
      "DESCRIPTION": "SIX POINT SOCKET SCREW 969373",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 0,
      "WHXX": 6,
      "WH REFERENCE": "WHXX",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 17461758,
      "DESCRIPTION": "CIRCUIT BOARD",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 7030400,
      "DESCRIPTION": "GLOW PLUG",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14543309,
      "DESCRIPTION": "HOSE",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "130288000679A",
      "DESCRIPTION": "EXHAUST VALVE ASSEMBLY",
      "PRODUCT CATEGORY": "YTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "1132-00010",
      "DESCRIPTION": "COVER_TRAVEL MO",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 3,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 11220517,
      "DESCRIPTION": "LEVEL SENSOR",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 5223567814,
      "DESCRIPTION": "WEATHERSTRIP",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 7,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4120006628,
      "DESCRIPTION": "BOOM CYLINDER",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 14747393,
      "DESCRIPTION": "HEX. SOCKET SCREW",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14747394,
      "DESCRIPTION": "SPRING WASHER",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14748398,
      "DESCRIPTION": "HEX. SOCKET SCREW",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14748204,
      "DESCRIPTION": "WASHER",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "C13196",
      "DESCRIPTION": "ROD PLUG",
      "PRODUCT CATEGORY": "SOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 23251714,
      "DESCRIPTION": "ADBLUE TANK              ",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 2050900006,
      "DESCRIPTION": "FINAL DRIVE",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 9014640102,
      "DESCRIPTION": "ECU",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 16015376,
      "DESCRIPTION": "TOOTH 8111",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 14,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 84479333,
      "DESCRIPTION": "REGULATOR CONTROL        ",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 3,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 5224202071,
      "DESCRIPTION": "SPRING",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.833333333333333,
      "MAINTANING STOCK QTY": 3,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 3,
      "APRD QTY": 0,
      "WH12": 74,
      "WHXX": 18,
      "WH REFERENCE": "WHXX",
      "TREND": 0.6,
      "STD": 2.04751256218556,
      "MEAN": 0.769230769230769,
      "COV": 2.66176633084123,
      "SHELF LIFE_1": 3.6000000000000014,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 11709361,
      "DESCRIPTION": "LOCK RING",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 3,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 11211150,
      "DESCRIPTION": "FILTER INSERT",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.25,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 15,
      "SHELF LIFE": 60,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 43,
      "WHXX": 7,
      "WH REFERENCE": "",
      "TREND": 0.67,
      "STD": 0.599144689515278,
      "MEAN": 0.23076923076923,
      "COV": 2.5962936545662,
      "SHELF LIFE_1": 60,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 11211149,
      "DESCRIPTION": "FILTER",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "AVERAGE (F2)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 2.33333333333333,
      "MAINTANING STOCK QTY": 9,
      "AVAILABLE": 44,
      "SHELF LIFE": 18.857142857142886,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 120,
      "WHXX": 22,
      "WH REFERENCE": "",
      "TREND": 1,
      "STD": 2.51151195650992,
      "MEAN": 2.15384615384615,
      "COV": 1.16605912266532,
      "SHELF LIFE_1": 18.857142857142886,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 14408713,
      "DESCRIPTION": "FILTER ELEMENT",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.416666666666666,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 7,
      "SHELF LIFE": 16.800000000000026,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0.8,
      "STD": 0.65044363558799,
      "MEAN": 0.384615384615384,
      "COV": 1.69115345252877,
      "SHELF LIFE_1": 16.800000000000026,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 11218232,
      "DESCRIPTION": "FILTER INSERT",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.916666666666666,
      "MAINTANING STOCK QTY": 4,
      "AVAILABLE": 1,
      "SHELF LIFE": 1.0909090909090917,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 3,
      "APRD QTY": 3,
      "WH12": 34,
      "WHXX": 3,
      "WH REFERENCE": "WHXX",
      "TREND": 0.91,
      "STD": 0.987096233585649,
      "MEAN": 0.846153846153846,
      "COV": 1.16656827605576,
      "SHELF LIFE_1": 4.363636363636367,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 54116585,
      "DESCRIPTION": "ALTERNATOR (ENDURANCE)",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 4,
      "WHXX": 2,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4000015,
      "DESCRIPTION": "SENSOR, ANGLE",
      "PRODUCT CATEGORY": "JLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4120016961,
      "DESCRIPTION": "FILTER ASSY. 4120004036",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 937879,
      "DESCRIPTION": "NIPPLE",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 15142021,
      "DESCRIPTION": "LOCK CATCH",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 11388082,
      "DESCRIPTION": "53032790 CONTROL UNIT 21766717 11443993",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 22696301,
      "DESCRIPTION": "IDLER PULLEY 21766717",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 2,
      "SHELF LIFE": 24.00000000000001,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 24.00000000000001,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 11217578,
      "DESCRIPTION": "FILTER 11218596",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 3,
      "SHELF LIFE": 36.000000000000014,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 9,
      "WHXX": 3,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 36.000000000000014,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": "A229900003567",
      "DESCRIPTION": "FITTING, ELBOW",
      "PRODUCT CATEGORY": "YCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 7340039,
      "DESCRIPTION": "HARNESS ENGINE",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 7276760,
      "DESCRIPTION": "HARNESS, MFR",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 6673188,
      "DESCRIPTION": "KIT SEAL",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 24063494,
      "DESCRIPTION": "HEATER 20758403",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 3,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 23417546,
      "DESCRIPTION": "POSITION SENSOR          ",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 1,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 12945110,
      "DESCRIPTION": "A810201206451 DISPLAY ASSEMBLY",
      "PRODUCT CATEGORY": "YCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "TP1000424655A",
      "DESCRIPTION": "OIL¬†FILTER¬†ELEMENT",
      "PRODUCT CATEGORY": "SDG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 4.5,
      "MAINTANING STOCK QTY": 18,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 18,
      "APRD QTY": 18,
      "WH12": 30,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 0,
      "STD": 14.0999181667019,
      "MEAN": 4.15384615384615,
      "COV": 3.39442474383566,
      "SHELF LIFE_1": 4,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 4120003852001,
      "DESCRIPTION": "NIPPLE",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 15099562,
      "DESCRIPTION": "SLIDING WINDOW",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 6,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 17459094,
      "DESCRIPTION": "REAR VIEW MIRROR",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 4,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14746778,
      "DESCRIPTION": "SEAL",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 5,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "TP217638",
      "DESCRIPTION": "BELT' WATER PUMP",
      "PRODUCT CATEGORY": "SDG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 4,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "01700-070-0440-420-20GA",
      "DESCRIPTION": "PIN 70 X 440",
      "PRODUCT CATEGORY": "EIK",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "02536624-7-125",
      "DESCRIPTION": "O-RING 7 X 125",
      "PRODUCT CATEGORY": "EIK",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "006B12A-140-AAL",
      "DESCRIPTION": "BOLT ¬Æ‚ÄÙ X 5 ¬Ω‚ÄÙ",
      "PRODUCT CATEGORY": "EIK",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 7,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "01700-070-0400-375-20GA",
      "DESCRIPTION": "PIN 70 X 400",
      "PRODUCT CATEGORY": "EIK",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "01700-080-0400-375-20GA",
      "DESCRIPTION": "PIN 80 X 400",
      "PRODUCT CATEGORY": "EIK",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "025080-095-05",
      "DESCRIPTION": "DUST SEAL 80 X 95 X 5",
      "PRODUCT CATEGORY": "EIK",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 4,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "011080-095-080-LA",
      "DESCRIPTION": "BUSH 80 X 95 X 80",
      "PRODUCT CATEGORY": "EIK",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "011070-085-070-LA",
      "DESCRIPTION": "BUSH 70 X 85 X 70",
      "PRODUCT CATEGORY": "EIK",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "025070-085-05",
      "DESCRIPTION": "DUST SEAL 70 X 85 X 5",
      "PRODUCT CATEGORY": "EIK",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 6,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 11383470,
      "DESCRIPTION": "ELECTRONIC UNIT 11443400",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "275429Z00A",
      "DESCRIPTION": "CABLE",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 28,
      "WHXX": 9,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "154-30-25111",
      "DESCRIPTION": "158-40-A0000P050-01 CARRIER ROLLER ASSY",
      "PRODUCT CATEGORY": "SDG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4130002911007,
      "DESCRIPTION": "SEALING RING",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 4130002911008,
      "DESCRIPTION": "SEALING RING",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 14794925,
      "DESCRIPTION": "CONTROL UNIT 14594707",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.25,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 1,
      "SHELF LIFE": 4,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 3,
      "WH REFERENCE": "",
      "TREND": 1.33,
      "STD": 0.599144689515278,
      "MEAN": 0.23076923076923,
      "COV": 2.5962936545662,
      "SHELF LIFE_1": 4,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 16812522,
      "DESCRIPTION": "FUEL TANK",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 1,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 13057289,
      "DESCRIPTION": "WHEEL HOUSE LINERFRONT LEFT",
      "PRODUCT CATEGORY": "YPG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 12173327,
      "DESCRIPTION": "BRACKET, RIGHT UPPER, BUMP",
      "PRODUCT CATEGORY": "YPG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14741183,
      "DESCRIPTION": "HOSE ASSEMBLY",
      "PRODUCT CATEGORY": "VOH",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "A210405000014",
      "DESCRIPTION": "WASHER",
      "PRODUCT CATEGORY": "YCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "A820201010000",
      "DESCRIPTION": "CLIPPING KEYHQC5420J.32-6",
      "PRODUCT CATEGORY": "YCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14630450,
      "DESCRIPTION": "ACTUATOR",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 96747001,
      "DESCRIPTION": "HEX. SOCKET SCREW 96725007",
      "PRODUCT CATEGORY": "VCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 1.33333333333333,
      "MAINTANING STOCK QTY": 5,
      "AVAILABLE": 11,
      "SHELF LIFE": 8.250000000000021,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 52,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 4.43760156980183,
      "MEAN": 1.23076923076923,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 8.250000000000021,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 4110000924141,
      "DESCRIPTION": "STUD",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4110001015042,
      "DESCRIPTION": "FLANGE",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 15060436,
      "DESCRIPTION": "PLATE",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 1,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 15015618,
      "DESCRIPTION": "PRE-CLEANER (L120F)",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 54982898,
      "DESCRIPTION": "PRE-CLEANER (L60F)",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 9014794925,
      "DESCRIPTION": "REMAN ECU 9014594707",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 16880355,
      "DESCRIPTION": "COVER PLATE 15060428",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 2,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 46830351,
      "DESCRIPTION": "ENGINE LUBE OIL FILTER (ORDER USING DOOSAN PORTABLE)",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "AVERAGE (F2)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 2.25,
      "MAINTANING STOCK QTY": 9,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 9,
      "APRD QTY": 9,
      "WH12": 8,
      "WHXX": 0,
      "WH REFERENCE": "PARTIAL WH12",
      "TREND": 0.52,
      "STD": 4.29071747033714,
      "MEAN": 2.07692307692307,
      "COV": 2.0659010042364,
      "SHELF LIFE_1": 4,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 46830389,
      "DESCRIPTION": "FUEL FILTER",
      "PRODUCT CATEGORY": "DOP",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 1.16666666666666,
      "MAINTANING STOCK QTY": 5,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 5,
      "APRD QTY": 5,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 0.71,
      "STD": 2.62873665669583,
      "MEAN": 1.07692307692307,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 4.28571428571431,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 11712379,
      "DESCRIPTION": "CYLINDER HEAD KIT",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 5223994853,
      "DESCRIPTION": "DISC 301009Z01A ",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 4,
      "WHXX": 2,
      "WH REFERENCE": "WHXX",
      "TREND": 0,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 14648462,
      "DESCRIPTION": "LINK",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 1,
      "SHELF LIFE": 12.000000000000005,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 4,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 12.000000000000005,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 14648461,
      "DESCRIPTION": "LINK",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 1,
      "SHELF LIFE": 12.000000000000005,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 4,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 12.000000000000005,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 14648463,
      "DESCRIPTION": "PIN",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 5,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14648464,
      "DESCRIPTION": "PIN",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 1,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14648465,
      "DESCRIPTION": "BUSHING",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 2,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14648466,
      "DESCRIPTION": "BUSHING",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14648467,
      "DESCRIPTION": "SEAL",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 8,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14648468,
      "DESCRIPTION": "SPACER",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 6,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 5223714918,
      "DESCRIPTION": "BRAKE SHOE KIT",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.666666666666666,
      "MAINTANING STOCK QTY": 3,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 3,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "PARTIAL WH12",
      "TREND": 0,
      "STD": 2.21880078490091,
      "MEAN": 0.615384615384615,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 4.500000000000004,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": "160602010092A001",
      "DESCRIPTION": "AIR FILTER KIT",
      "PRODUCT CATEGORY": "YPG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 0,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 58848771,
      "DESCRIPTION": "ELBOW",
      "PRODUCT CATEGORY": "VCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 2,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 59617084,
      "DESCRIPTION": "ELBOW",
      "PRODUCT CATEGORY": "VCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 54765326,
      "DESCRIPTION": "SEALING KIT",
      "PRODUCT CATEGORY": "VCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 58943648,
      "DESCRIPTION": "NIPPLE",
      "PRODUCT CATEGORY": "VCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 1,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4110002531014,
      "DESCRIPTION": "OIL COOLER",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 29220014991,
      "DESCRIPTION": "AIR TANK",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14400991,
      "DESCRIPTION": "BUCKET TOOTH",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 3.08333333333333,
      "MAINTANING STOCK QTY": 12,
      "AVAILABLE": 1,
      "SHELF LIFE": 0.3243243243243247,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 11,
      "APRD QTY": 11,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 1.62,
      "STD": 3.80451823157137,
      "MEAN": 2.84615384615384,
      "COV": 1.33672262190345,
      "SHELF LIFE_1": 3.891891891891896,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 4110002196013,
      "DESCRIPTION": "V-RIBBED BELT",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 11216209,
      "DESCRIPTION": "WIPER ARM",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14521398,
      "DESCRIPTION": "ENGINE",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 11210768,
      "DESCRIPTION": "PIN",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 1.41666666666666,
      "MAINTANING STOCK QTY": 6,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 6,
      "APRD QTY": 6,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 1.76,
      "STD": 2.98285700251217,
      "MEAN": 1.3076923076923,
      "COV": 2.28100829603871,
      "SHELF LIFE_1": 4.235294117647078,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 11210753,
      "DESCRIPTION": "WASHER",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 1.41666666666666,
      "MAINTANING STOCK QTY": 6,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 6,
      "APRD QTY": 6,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 1.76,
      "STD": 2.98285700251217,
      "MEAN": 1.3076923076923,
      "COV": 2.28100829603871,
      "SHELF LIFE_1": 4.235294117647078,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 11223871,
      "DESCRIPTION": "RING",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 2,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 4190003801,
      "DESCRIPTION": "LOCK",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4130015120001,
      "DESCRIPTION": "FAN 4190002907003",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.916666666666666,
      "MAINTANING STOCK QTY": 4,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 4,
      "APRD QTY": 4,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 2,
      "STD": 2.07549808665108,
      "MEAN": 0.846153846153846,
      "COV": 2.45286137513309,
      "SHELF LIFE_1": 4.363636363636367,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": "115H-58-00002",
      "DESCRIPTION": "BELT",
      "PRODUCT CATEGORY": "SDG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 0,
      "STD": 0.375533808099405,
      "MEAN": 0.153846153846153,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": "TP612600062230",
      "DESCRIPTION": "BELT",
      "PRODUCT CATEGORY": "SDG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.833333333333333,
      "MAINTANING STOCK QTY": 3,
      "AVAILABLE": 2,
      "SHELF LIFE": 2.400000000000001,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 7,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 0,
      "STD": 1.96442723432922,
      "MEAN": 0.769230769230769,
      "COV": 2.55375540462798,
      "SHELF LIFE_1": 3.6000000000000014,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 11095146,
      "DESCRIPTION": "SUPPORT PLATE",
      "PRODUCT CATEGORY": "YCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 10024463,
      "DESCRIPTION": "SLIDE PLATE",
      "PRODUCT CATEGORY": "YCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14778022,
      "DESCRIPTION": "COMPRESSOR 14659238 15082727",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 10136121,
      "DESCRIPTION": "SEAL, DUST",
      "PRODUCT CATEGORY": "YCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 7232486,
      "DESCRIPTION": "SWITCH FAN",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14698883,
      "DESCRIPTION": "PRINTED CIRCUIT BOARD",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 29030019421,
      "DESCRIPTION": "RUBBER BUSHING",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4110002795,
      "DESCRIPTION": "CLAMP",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 29030025821,
      "DESCRIPTION": "RUBBER PLATE",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 5223804500,
      "DESCRIPTION": "VALVE HOUSING",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14759247,
      "DESCRIPTION": "ODOMETER 14626783",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 5,
      "WHXX": 2,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 11831591,
      "DESCRIPTION": "ASSEMBLY,MOUNTING SUPPORT 3,LEFT COVER L",
      "PRODUCT CATEGORY": "YTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 11934802,
      "DESCRIPTION": "ASSEMBLY,MOUNTING SUPPORT 3 ,RIGHT COVER",
      "PRODUCT CATEGORY": "YTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "160603010047A001 ",
      "DESCRIPTION": "FILTER ELEMENT",
      "PRODUCT CATEGORY": "YTG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 8,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 0,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 23974646,
      "DESCRIPTION": "FAN BRACKET",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 28280004922,
      "DESCRIPTION": "HOOD",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 1001099717,
      "DESCRIPTION": "SENSOR,CAN ANGLE, 90 DEG",
      "PRODUCT CATEGORY": "JLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 3050900009,
      "DESCRIPTION": "SEALING COVER",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 5222834631,
      "DESCRIPTION": "LEAF SPRING",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 1.16666666666666,
      "MAINTANING STOCK QTY": 5,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 5,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 8,
      "WH REFERENCE": "WHXX",
      "TREND": 0.43,
      "STD": 2.75262112847429,
      "MEAN": 1.07692307692307,
      "COV": 2.55600533358327,
      "SHELF LIFE_1": 4.28571428571431,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 14881104,
      "DESCRIPTION": "FUEL HOSE",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4130002904108,
      "DESCRIPTION": "4190002851 TEMPERATURE SENSOR",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.416666666666666,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 2,
      "SHELF LIFE": 4.800000000000008,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0.8,
      "STD": 0.960768922830522,
      "MEAN": 0.384615384615384,
      "COV": 2.49799919935935,
      "SHELF LIFE_1": 4.800000000000008,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 4130002904107,
      "DESCRIPTION": "RELAY",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 2,
      "STD": 0.375533808099405,
      "MEAN": 0.153846153846153,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 4130002904104,
      "DESCRIPTION": "CELL CORE ",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 2,
      "STD": 0.375533808099405,
      "MEAN": 0.153846153846153,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 11711989,
      "DESCRIPTION": "MAGNET",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 54315408,
      "DESCRIPTION": "FUEL FILTER 20805349",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "FAST (F1)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 8.16666666666666,
      "MAINTANING STOCK QTY": 33,
      "AVAILABLE": 2,
      "SHELF LIFE": 0.24489795918367366,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 31,
      "APRD QTY": 31,
      "WH12": 359,
      "WHXX": 264,
      "WH REFERENCE": "WHXX",
      "TREND": 1.92,
      "STD": 10.5720968009771,
      "MEAN": 7.53846153846153,
      "COV": 1.40242100421125,
      "SHELF LIFE_1": 4.040816326530615,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 13625105,
      "DESCRIPTION": "OIL SEPARATOR",
      "PRODUCT CATEGORY": "YTG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 7,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 0,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 6672427,
      "DESCRIPTION": "FUEL PUMP",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4110001007128,
      "DESCRIPTION": "INJECTION PUMP",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.5,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 2,
      "APRD QTY": 2,
      "WH12": 7,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 2,
      "STD": 1.66410058867568,
      "MEAN": 0.461538461538461,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 4,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 60251057,
      "DESCRIPTION": "RADIATOR",
      "PRODUCT CATEGORY": "YPG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "156-40-01000V010-01",
      "DESCRIPTION": "FRONT¬†IDLER,¬†ASSEMBLY",
      "PRODUCT CATEGORY": "SDG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.833333333333333,
      "MAINTANING STOCK QTY": 3,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 3,
      "APRD QTY": 3,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 1.6,
      "STD": 1.7394369852458,
      "MEAN": 0.769230769230769,
      "COV": 2.26126808081955,
      "SHELF LIFE_1": 3.6000000000000014,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": "115H-40-01000-01",
      "DESCRIPTION": "CARRIER¬†ROLLER",
      "PRODUCT CATEGORY": "SDG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 1.33333333333333,
      "MAINTANING STOCK QTY": 5,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 5,
      "APRD QTY": 5,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 1.5,
      "STD": 3.41940165706044,
      "MEAN": 1.23076923076923,
      "COV": 2.7782638463616,
      "SHELF LIFE_1": 3.7500000000000098,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 4120004568,
      "DESCRIPTION": "NIPPLE",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14031386,
      "DESCRIPTION": "MUFFLER SUPPORT",
      "PRODUCT CATEGORY": "YPG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "156-40-02000-01",
      "DESCRIPTION": "TENSION¬†DEVICE",
      "PRODUCT CATEGORY": "SDG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.5,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 2,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 0.67,
      "STD": 1.19828937903055,
      "MEAN": 0.461538461538461,
      "COV": 2.5962936545662,
      "SHELF LIFE_1": 4,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": "156-40-02003",
      "DESCRIPTION": "RECOIL¬†SPRING",
      "PRODUCT CATEGORY": "SDG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 0,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": "115H-40-00003",
      "DESCRIPTION": "GASKET",
      "PRODUCT CATEGORY": "SDG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 0,
      "STD": 1.10940039245045,
      "MEAN": 0.307692307692307,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 3.000000000000003,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": "115H-40-00002",
      "DESCRIPTION": "RETAINER",
      "PRODUCT CATEGORY": "SDG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 0,
      "STD": 1.10940039245045,
      "MEAN": 0.307692307692307,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 3.000000000000003,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": "156-40-00005",
      "DESCRIPTION": "SEAL¬†RING",
      "PRODUCT CATEGORY": "SDG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 0,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": "37C620",
      "DESCRIPTION": "BOLT CARRIAGE",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 2,
      "STD": 1.10940039245045,
      "MEAN": 0.307692307692307,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 3.000000000000003,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 5224332262,
      "DESCRIPTION": "VALVE",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 106,
      "WHXX": 13,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14544980,
      "DESCRIPTION": "LATCH",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 1,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 11819035,
      "DESCRIPTION": "LOGO",
      "PRODUCT CATEGORY": "YPG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 60120467,
      "DESCRIPTION": "FRONT PANEL HINGE ASSY",
      "PRODUCT CATEGORY": "YPG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 13265068,
      "DESCRIPTION": "FRONT PANEL RIGHT REINFORCED PLATE ASSY(",
      "PRODUCT CATEGORY": "YPG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "A220400000005",
      "DESCRIPTION": "BALL VALVE Q11F-16P-DN15GB15185",
      "PRODUCT CATEGORY": "YPG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14642497,
      "DESCRIPTION": "BATTERY CABLE",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 15033174,
      "DESCRIPTION": "LOCK SCREW",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "130201000125A001",
      "DESCRIPTION": "GAS-PATH CONTROL VALVE",
      "PRODUCT CATEGORY": "YPG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "TP3040292",
      "DESCRIPTION": "BELT",
      "PRODUCT CATEGORY": "SDG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 3,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 54864253,
      "DESCRIPTION": "BOWL 11110737",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 9,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 29050033351,
      "DESCRIPTION": "SEALING RETAINER",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 5223994830,
      "DESCRIPTION": "DISC",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 1,
      "SHELF LIFE": 6.000000000000024,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 4,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.375533808099405,
      "MEAN": 0.153846153846153,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": "156-82-00001",
      "DESCRIPTION": "BLADE",
      "PRODUCT CATEGORY": "SDG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 2,
      "MAINTANING STOCK QTY": 8,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 8,
      "APRD QTY": 0,
      "WH12": 7,
      "WHXX": 0,
      "WH REFERENCE": "PARTIAL WH12",
      "TREND": 0.17,
      "STD": 5.50524225694858,
      "MEAN": 1.84615384615384,
      "COV": 2.98200622251381,
      "SHELF LIFE_1": 4,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": "115H-03B-01000",
      "DESCRIPTION": "RADIATOR",
      "PRODUCT CATEGORY": "SDG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "114H-58D-09000",
      "DESCRIPTION": "COMPRESSOR",
      "PRODUCT CATEGORY": "SDG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "114H-58D-06000",
      "DESCRIPTION": "EVAPORATOR",
      "PRODUCT CATEGORY": "SDG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "113K-58-01000",
      "DESCRIPTION": "CONDENSER",
      "PRODUCT CATEGORY": "SDG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "15B-63A-03000P020",
      "DESCRIPTION": "TILT¬†CYL.¬†SEAL¬†KIT¬†ASSY",
      "PRODUCT CATEGORY": "SDG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 0,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": "15B-62A-01000P020",
      "DESCRIPTION": "BLADE¬†LIFT¬†CYL.¬†SEAL¬†KIT¬†ASSY.",
      "PRODUCT CATEGORY": "SDG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 0,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 964832,
      "DESCRIPTION": "CLEVIS PIN 14880431",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 2,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 6,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14599866,
      "DESCRIPTION": "SWITCH",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 1,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14376014,
      "DESCRIPTION": "CONTROL LEVER",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 1,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14376013,
      "DESCRIPTION": "BUSHING",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 1,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 13,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 60153144,
      "DESCRIPTION": "EXPANSIONWATERTANKAS",
      "PRODUCT CATEGORY": "YPG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4110003496001,
      "DESCRIPTION": "FILTER INSERT",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 48,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4110003496002,
      "DESCRIPTION": "FILTER INSERT",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 48,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 28360003211,
      "DESCRIPTION": "RIPPER TOOTH",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.666666666666666,
      "MAINTANING STOCK QTY": 3,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 3,
      "APRD QTY": 0,
      "WH12": 11,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 0,
      "STD": 2.21880078490091,
      "MEAN": 0.615384615384615,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 4.500000000000004,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 14615925,
      "DESCRIPTION": "WIRING HARNESS",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 6707101,
      "DESCRIPTION": "SEAL NEOPRENE",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "7D4",
      "DESCRIPTION": "NUT HEX JAM",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 6737524,
      "DESCRIPTION": "HANDLE CONTROL",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 1001151975,
      "DESCRIPTION": "CONTROL,ACCESSORY MODULE",
      "PRODUCT CATEGORY": "JLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4110003380090,
      "DESCRIPTION": "SENSOR 4110003380016 ",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 5224202065,
      "DESCRIPTION": "BRAKE KIT",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 9,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14703194,
      "DESCRIPTION": "O-RING",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 6,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14616918,
      "DESCRIPTION": "WASHER",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14573769,
      "DESCRIPTION": "COLLAR",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14573768,
      "DESCRIPTION": "NEEDLE BEARING",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14619871,
      "DESCRIPTION": "SEALING KIT",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14573762,
      "DESCRIPTION": "BEARING",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14573763,
      "DESCRIPTION": "RING",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14573764,
      "DESCRIPTION": "RING",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14573765,
      "DESCRIPTION": "PLUG",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14573772,
      "DESCRIPTION": "GEAR",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14573773,
      "DESCRIPTION": "RING",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14573774,
      "DESCRIPTION": "RETAINER",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14573775,
      "DESCRIPTION": "PLANETARY GEAR",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14573776,
      "DESCRIPTION": "NEEDLE BEARING",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14573777,
      "DESCRIPTION": "BEARING RACE",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14616920,
      "DESCRIPTION": "PLATE",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14573779,
      "DESCRIPTION": "PIN",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14573780,
      "DESCRIPTION": "GEAR",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14616921,
      "DESCRIPTION": "COVER",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14718303,
      "DESCRIPTION": "THRUST RING 8230-02890",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4120007618002,
      "DESCRIPTION": "VALVE",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 5223488082,
      "DESCRIPTION": "DUST COVER",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 9,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 7210676,
      "DESCRIPTION": "PANEL, LH",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "Q15003",
      "DESCRIPTION": "AUTO GREASING CARTRIDGE",
      "PRODUCT CATEGORY": "SOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 8.33333333333333,
      "MAINTANING STOCK QTY": 33,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 33,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 0,
      "STD": 27.7350098112614,
      "MEAN": 7.69230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 3.9600000000000013,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": "TP612600062227",
      "DESCRIPTION": "AUTOMATIC TENSIONER",
      "PRODUCT CATEGORY": "SDG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "07016-00908",
      "DESCRIPTION": "RING, DUST",
      "PRODUCT CATEGORY": "SDG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 1,
      "MAINTANING STOCK QTY": 4,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 4,
      "APRD QTY": 4,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 1.33,
      "STD": 2.39657875806111,
      "MEAN": 0.923076923076923,
      "COV": 2.5962936545662,
      "SHELF LIFE_1": 4,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 15184179,
      "DESCRIPTION": "JOINT",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 0,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 29090007651,
      "DESCRIPTION": "AXLE CASING",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 7311865,
      "DESCRIPTION": "FITTING GREASE",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 21262793,
      "DESCRIPTION": "SPRING BRAKE CYLINDER",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 0,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 20818765,
      "DESCRIPTION": "HEADLAMP",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 60110519,
      "DESCRIPTION": "ARM. CAMSHAFT. REAR LH",
      "PRODUCT CATEGORY": "YCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14708138,
      "DESCRIPTION": "SPINDLE",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14672988,
      "DESCRIPTION": "HOUSING",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 7166172,
      "DESCRIPTION": "HANDLE",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 7166173,
      "DESCRIPTION": "HANDLE",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 7023150,
      "DESCRIPTION": "JOYSTICK, LH ASSY",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 1,
      "SHELF LIFE": 12.000000000000005,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 12.000000000000005,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 14523887,
      "DESCRIPTION": "RADIATOR",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "FDJ007138164",
      "DESCRIPTION": "EXHAUST VALVE BRIDGE",
      "PRODUCT CATEGORY": "YPG",
      "ITEM MOVEMENT BRANCH": "AVERAGE (F2)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 3,
      "MAINTANING STOCK QTY": 12,
      "AVAILABLE": 24,
      "SHELF LIFE": 8,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 617,
      "WHXX": 104,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 9.98460353205412,
      "MEAN": 2.76923076923076,
      "COV": 3.60555127546399,
      "SHELF LIFE_1": 8,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 24046345,
      "DESCRIPTION": "FUEL PUMP 22905123",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 3,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 2,
      "STD": 0.75106761619881,
      "MEAN": 0.307692307692307,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 3.000000000000003,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 5224222120,
      "DESCRIPTION": "WHEEL NUT",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 4.16666666666666,
      "MAINTANING STOCK QTY": 17,
      "AVAILABLE": 13,
      "SHELF LIFE": 3.120000000000005,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 4,
      "APRD QTY": 4,
      "WH12": 10,
      "WHXX": 87,
      "WH REFERENCE": "WHXX",
      "TREND": 2,
      "STD": 11.2089707663561,
      "MEAN": 3.84615384615384,
      "COV": 2.91433239925258,
      "SHELF LIFE_1": 4.080000000000006,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 12794457,
      "DESCRIPTION": "FUSE",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 8,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "TP612630060836",
      "DESCRIPTION": "IDLER",
      "PRODUCT CATEGORY": "SDG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.25,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 1.33,
      "STD": 0.599144689515278,
      "MEAN": 0.23076923076923,
      "COV": 2.5962936545662,
      "SHELF LIFE_1": 4,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": "TP612600040702",
      "DESCRIPTION": "SUPPORT BLOCK",
      "PRODUCT CATEGORY": "SDG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.25,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 1.33,
      "STD": 0.599144689515278,
      "MEAN": 0.23076923076923,
      "COV": 2.5962936545662,
      "SHELF LIFE_1": 4,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 20410139,
      "DESCRIPTION": "BRAKE CHAMBER",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14574768,
      "DESCRIPTION": "WIRING HARNESS",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 612600062371,
      "DESCRIPTION": "BELT TENSIONER",
      "PRODUCT CATEGORY": "ZZZ",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 2,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 4110001908005,
      "DESCRIPTION": "RADIATOR",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 13708877,
      "DESCRIPTION": "BATTERY FRAME",
      "PRODUCT CATEGORY": "YPG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 13710231,
      "DESCRIPTION": "CUSHION BLOCK OF BATTERY FRAME",
      "PRODUCT CATEGORY": "YPG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 13710232,
      "DESCRIPTION": "BACKUP PLATE OF BATTERY FRAME",
      "PRODUCT CATEGORY": "YPG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 13710230,
      "DESCRIPTION": "SEALING BOARD OF BATTERY FRAME",
      "PRODUCT CATEGORY": "YPG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 10067631,
      "DESCRIPTION": "RIGHT ANGLE ADAPTOR BODY",
      "PRODUCT CATEGORY": "YPG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 10088216,
      "DESCRIPTION": "TRANSITIONAL JOINT GJB312C8R152-5",
      "PRODUCT CATEGORY": "YPG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "A229900001360",
      "DESCRIPTION": "WASHER",
      "PRODUCT CATEGORY": "YPG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 11819349,
      "DESCRIPTION": "PLATE, LOCKING",
      "PRODUCT CATEGORY": "YPG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 12173328,
      "DESCRIPTION": "BRACKET, LEFT UPPER, BUMP",
      "PRODUCT CATEGORY": "YPG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 12648540,
      "DESCRIPTION": "MOUNTING PLATE",
      "PRODUCT CATEGORY": "YPG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "A210306000011",
      "DESCRIPTION": "NUT",
      "PRODUCT CATEGORY": "YPG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "A229900003218",
      "DESCRIPTION": "CONNECTOR UPPER MIXER",
      "PRODUCT CATEGORY": "YPG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "A229900005597",
      "DESCRIPTION": "RIGHT ANGLE TERMINAL ADAPTOR",
      "PRODUCT CATEGORY": "YPG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "A239900000410",
      "DESCRIPTION": "NYLON HOSE (PER CM)",
      "PRODUCT CATEGORY": "YPG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0,
      "MEAN": 0,
      "COV": 0,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 5223228934,
      "DESCRIPTION": "STEERING GEAR",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "01643-30823",
      "DESCRIPTION": "WASHER",
      "PRODUCT CATEGORY": "SDG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 2,
      "STD": 1.10940039245045,
      "MEAN": 0.307692307692307,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 3.000000000000003,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": "130201000042A003",
      "DESCRIPTION": "HIGH AND LOW SHIFT SWITCH",
      "PRODUCT CATEGORY": "YTG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.583333333333333,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 2,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 0,
      "STD": 1.9414506867883,
      "MEAN": 0.538461538461538,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 3.42857142857143,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 14685160,
      "DESCRIPTION": "BATTERY CABLE",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4110001908,
      "DESCRIPTION": "RADIATOR",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14622367,
      "DESCRIPTION": "BRACKET",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 9,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14622369,
      "DESCRIPTION": "WIRING HARNESS",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 1,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14640075,
      "DESCRIPTION": "HOSE 14618177",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 1,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "9325-10045",
      "DESCRIPTION": "SPRING PIN",
      "PRODUCT CATEGORY": "VSG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.666666666666666,
      "MAINTANING STOCK QTY": 3,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 3,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 0,
      "STD": 2.21880078490091,
      "MEAN": 0.615384615384615,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 4.500000000000004,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 14532411,
      "DESCRIPTION": "SHAFT",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 0,
      "STD": 1.10940039245045,
      "MEAN": 0.307692307692307,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 3.000000000000003,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 14532307,
      "DESCRIPTION": "BUSHING",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.666666666666666,
      "MAINTANING STOCK QTY": 3,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 3,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 0,
      "STD": 2.21880078490091,
      "MEAN": 0.615384615384615,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 4.500000000000004,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": "329569Z00B",
      "DESCRIPTION": "O-RING",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 32,
      "WHXX": 9,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "8216-MG-411561-01",
      "DESCRIPTION": "TRACK ASS'Y DH24",
      "PRODUCT CATEGORY": "SDG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.666666666666666,
      "MAINTANING STOCK QTY": 3,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 3,
      "APRD QTY": 3,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 1.5,
      "STD": 1.70970082853022,
      "MEAN": 0.615384615384615,
      "COV": 2.7782638463616,
      "SHELF LIFE_1": 4.500000000000004,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 14388290,
      "DESCRIPTION": "CLAMP",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 8,
      "WH REFERENCE": "WHXX",
      "TREND": 0,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 14640071,
      "DESCRIPTION": "HOSE",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 1,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14566371,
      "DESCRIPTION": "CLIP",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 9,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14554917,
      "DESCRIPTION": "CLUTCH",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "C63211",
      "DESCRIPTION": "ROD PLUG",
      "PRODUCT CATEGORY": "SOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 0,
      "STD": 1.10940039245045,
      "MEAN": 0.307692307692307,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 3.000000000000003,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 60185935,
      "DESCRIPTION": "DISTANCE ROD ASSEMBLY",
      "PRODUCT CATEGORY": "YCG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 612600062230,
      "DESCRIPTION": "WEICHAI BELT",
      "PRODUCT CATEGORY": "ZZZ",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.75,
      "MAINTANING STOCK QTY": 3,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 3,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 0,
      "STD": 2.49615088301353,
      "MEAN": 0.692307692307692,
      "COV": 3.60555127546399,
      "SHELF LIFE_1": 4,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 14550599,
      "DESCRIPTION": "SHAFT",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "SSC61267",
      "DESCRIPTION": "FRONT COVER",
      "PRODUCT CATEGORY": "SOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "C71113",
      "DESCRIPTION": "DIAPHRAGM",
      "PRODUCT CATEGORY": "SOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 940337,
      "DESCRIPTION": "RING TERMINAL",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 11039713,
      "DESCRIPTION": "BRACKET",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14587774,
      "DESCRIPTION": "FUSE HOLDER",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 977973,
      "DESCRIPTION": "RING TERMINAL",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 24076067,
      "DESCRIPTION": "WHEEL STUD",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.833333333333333,
      "MAINTANING STOCK QTY": 3,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 3,
      "APRD QTY": 3,
      "WH12": 214,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 2,
      "STD": 2.77350098112614,
      "MEAN": 0.769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 3.6000000000000014,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 784112,
      "DESCRIPTION": "COVER",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 16352431,
      "DESCRIPTION": "BEARING HOUSING 16809376",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 1,
      "STD": 0.75106761619881,
      "MEAN": 0.307692307692307,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 3.000000000000003,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 60112995,
      "DESCRIPTION": "HEX. SOCKET SCREW 13959262",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.583333333333333,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 2,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 12,
      "WH REFERENCE": "WHXX",
      "TREND": 0.29,
      "STD": 1.66410058867568,
      "MEAN": 0.538461538461538,
      "COV": 3.09047252182627,
      "SHELF LIFE_1": 3.42857142857143,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 4043003091,
      "DESCRIPTION": "PIN",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4190001196,
      "DESCRIPTION": "SEALING RING",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 0,
      "STD": 1.10940039245045,
      "MEAN": 0.307692307692307,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 3.000000000000003,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 4043000395,
      "DESCRIPTION": "SHIM",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 0,
      "STD": 1.10940039245045,
      "MEAN": 0.307692307692307,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 3.000000000000003,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 26170013901,
      "DESCRIPTION": "BUSHING",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 0,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 17391236,
      "DESCRIPTION": "LEVEL",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 53032790,
      "DESCRIPTION": "ELECTRONIC UNIT 11381209 11388082 11443993",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 46827785,
      "DESCRIPTION": "SWITCH, LLOP, 0.8 BAR, NO TYPE",
      "PRODUCT CATEGORY": "DOP",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "156-40-16000",
      "DESCRIPTION": "BUSHING",
      "PRODUCT CATEGORY": "SDG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 0,
      "STD": 1.10940039245045,
      "MEAN": 0.307692307692307,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 3.000000000000003,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": "156-40-15000",
      "DESCRIPTION": "BUSHING",
      "PRODUCT CATEGORY": "SDG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 0,
      "STD": 1.10940039245045,
      "MEAN": 0.307692307692307,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 3.000000000000003,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": "115H-31-00002",
      "DESCRIPTION": "SHAFT",
      "PRODUCT CATEGORY": "SDG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 0,
      "STD": 1.10940039245045,
      "MEAN": 0.307692307692307,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 3.000000000000003,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": "07177-09040",
      "DESCRIPTION": "BUSHING",
      "PRODUCT CATEGORY": "SDG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 1.5,
      "MAINTANING STOCK QTY": 6,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 6,
      "APRD QTY": 6,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 1.33,
      "STD": 3.59486813709166,
      "MEAN": 1.38461538461538,
      "COV": 2.5962936545662,
      "SHELF LIFE_1": 4,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": "156-40-00004",
      "DESCRIPTION": "SEAT",
      "PRODUCT CATEGORY": "SDG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 0,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 22771624,
      "DESCRIPTION": "CONTROL UNIT             ",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.416666666666666,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 2,
      "APRD QTY": 2,
      "WH12": 6,
      "WHXX": 1,
      "WH REFERENCE": "WH12 + WHXX",
      "TREND": 1.6,
      "STD": 1.12089707663561,
      "MEAN": 0.384615384615384,
      "COV": 2.91433239925258,
      "SHELF LIFE_1": 4.800000000000008,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 16668459,
      "DESCRIPTION": "GASKET",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "115H-18-A0001V020-CD",
      "DESCRIPTION": "FINAL DRIVE LEFT",
      "PRODUCT CATEGORY": "SDG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "115H-18-A0002V020-CD",
      "DESCRIPTION": "FINAL DRIVE RIGHT",
      "PRODUCT CATEGORY": "SDG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 54864268,
      "DESCRIPTION": "FUEL FILTER HOUSING 11110708",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "AVERAGE (F2)",
      "30 DAY AVE": 0.25,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 23,
      "WHXX": 28,
      "WH REFERENCE": "WHXX",
      "TREND": 2,
      "STD": 0.599144689515278,
      "MEAN": 0.23076923076923,
      "COV": 2.5962936545662,
      "SHELF LIFE_1": 4,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 937483,
      "DESCRIPTION": "HOSE ASSEMBLY",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 15800781,
      "DESCRIPTION": "HOSE ASSEMBLY",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 17386372,
      "DESCRIPTION": "EXHAUST BRAKE",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 16879262,
      "DESCRIPTION": "EXHAUST PIPE",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 16848496,
      "DESCRIPTION": "EXHAUST PIPE",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 7161979,
      "DESCRIPTION": "KIT LIFT 4-POINT",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 7153379,
      "DESCRIPTION": "LEVER, STEERING",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 7145147,
      "DESCRIPTION": "LEVER, STEERING",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 938450,
      "DESCRIPTION": "HOSE ASSEMBLY",
      "PRODUCT CATEGORY": "VOH",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14713405,
      "DESCRIPTION": "HOSE ASSEMBLY",
      "PRODUCT CATEGORY": "VOH",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 20459925,
      "DESCRIPTION": "VIBRATION DAMPER",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 60273569,
      "DESCRIPTION": "EVAPORATOR CORE",
      "PRODUCT CATEGORY": "YPG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 17201959,
      "DESCRIPTION": "BOWL",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 23658635,
      "DESCRIPTION": "CONTROL UNIT ",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 24318489,
      "DESCRIPTION": "COOLANT PUMP 23974640",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "FAST (F1)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 30,
      "WHXX": 10,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4110015996,
      "DESCRIPTION": "FAN 4110002109",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 60222779,
      "DESCRIPTION": "FILTER CORE ",
      "PRODUCT CATEGORY": "YCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14633611,
      "DESCRIPTION": "PUMP 14553215",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "175-30-24131",
      "DESCRIPTION": "RIVETING ASSY",
      "PRODUCT CATEGORY": "SDG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 1.16666666666666,
      "MAINTANING STOCK QTY": 5,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 5,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 0.86,
      "STD": 2.6602486870447,
      "MEAN": 1.07692307692307,
      "COV": 2.47023092368436,
      "SHELF LIFE_1": 4.28571428571431,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": "156-31-02000",
      "DESCRIPTION": "OIL SEAL COMPONENT",
      "PRODUCT CATEGORY": "SDG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.666666666666666,
      "MAINTANING STOCK QTY": 3,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 3,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 0,
      "STD": 2.21880078490091,
      "MEAN": 0.615384615384615,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 4.500000000000004,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": "GB9163-GE60ES",
      "DESCRIPTION": "JOINT BEARING",
      "PRODUCT CATEGORY": "SDG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 0,
      "STD": 1.10940039245045,
      "MEAN": 0.307692307692307,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 3.000000000000003,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": "04065-09030",
      "DESCRIPTION": "RETAINER",
      "PRODUCT CATEGORY": "SDG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.666666666666666,
      "MAINTANING STOCK QTY": 3,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 3,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 0,
      "STD": 2.21880078490091,
      "MEAN": 0.615384615384615,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 4.500000000000004,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": "156-31-00002",
      "DESCRIPTION": "BUSTED 45¬†",
      "PRODUCT CATEGORY": "SDG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 0,
      "STD": 1.10940039245045,
      "MEAN": 0.307692307692307,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 3.000000000000003,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 7171222,
      "DESCRIPTION": "BRACKET BLOCK FUSE",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 7223425,
      "DESCRIPTION": "DECAL, FUSE",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 7143971,
      "DESCRIPTION": "SEATBAR",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 7329955,
      "DESCRIPTION": "BRACKET ARM",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 0,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": "10C616",
      "DESCRIPTION": "BOLT SHRT SQ NK RHD 3/8-16X1 GRD 5",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 0,
      "STD": 1.10940039245045,
      "MEAN": 0.307692307692307,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 3.000000000000003,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": "111379Z01D",
      "DESCRIPTION": "SEALING RING",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 0,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": "E93446",
      "DESCRIPTION": "WEAR PLATE",
      "PRODUCT CATEGORY": "SOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.5,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 2,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 0,
      "STD": 1.66410058867568,
      "MEAN": 0.461538461538461,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 4,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": "E93447",
      "DESCRIPTION": "WEAR PLATE(D/C)",
      "PRODUCT CATEGORY": "SOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.5,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 2,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 0,
      "STD": 1.66410058867568,
      "MEAN": 0.461538461538461,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 4,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 12242300,
      "DESCRIPTION": "LEFT HEADLIGHT COVER R",
      "PRODUCT CATEGORY": "YTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 5223822029,
      "DESCRIPTION": "SHOCK ABSORBER (FRONT -LH)",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 5223822030,
      "DESCRIPTION": "SHOCK ABSORBER (FRONT -RH)",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14506388,
      "DESCRIPTION": "GAUGE",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 21671171,
      "DESCRIPTION": "TIMING GEAR PLATE",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 23468467,
      "DESCRIPTION": "CYLINDER LINER KIT",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14733931,
      "DESCRIPTION": "TRACK LINK",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.5,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 2,
      "APRD QTY": 2,
      "WH12": 0,
      "WHXX": 12,
      "WH REFERENCE": "WHXX",
      "TREND": 2,
      "STD": 1.66410058867568,
      "MEAN": 0.461538461538461,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 4,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 14733932,
      "DESCRIPTION": "TRACK LINK",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.5,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 2,
      "APRD QTY": 2,
      "WH12": 0,
      "WHXX": 11,
      "WH REFERENCE": "WHXX",
      "TREND": 2,
      "STD": 1.66410058867568,
      "MEAN": 0.461538461538461,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 4,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 4120004030,
      "DESCRIPTION": "HYDRAULIC LOCK",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 5222748121,
      "DESCRIPTION": "SNAP RING",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 7,
      "WHXX": 10,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 7496373,
      "DESCRIPTION": "CAP CANISTER HYDRAULIC",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 41,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 7163421,
      "DESCRIPTION": "PEDAL ASSEMBLY",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 24159417,
      "DESCRIPTION": "HEADLAMP                 ",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 5,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 2,
      "STD": 0.630425171956115,
      "MEAN": 0.307692307692307,
      "COV": 2.04888180885737,
      "SHELF LIFE_1": 3.000000000000003,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 7312863,
      "DESCRIPTION": "ENGINE HARNESS",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 7281000,
      "DESCRIPTION": "CAB HARNESS",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 7007303,
      "DESCRIPTION": "HARNESS",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 7004806,
      "DESCRIPTION": "INSERT PARKING BRAKE",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 7004916,
      "DESCRIPTION": "SWITCH ROCKER",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 7006812,
      "DESCRIPTION": "LAMP PILOT",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4190000037,
      "DESCRIPTION": "MASTER CYLINDER",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 983521,
      "DESCRIPTION": "O-RING",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 12,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 24159415,
      "DESCRIPTION": "HEADLAMP                 ",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 6,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 12766934,
      "DESCRIPTION": "MACHINING DRAWING",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 0,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "542159Z01E",
      "DESCRIPTION": "PIN",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 1,
      "MAINTANING STOCK QTY": 4,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 4,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 16,
      "WH REFERENCE": "WHXX",
      "TREND": 0.17,
      "STD": 3.0403272428457,
      "MEAN": 0.923076923076923,
      "COV": 3.29368784641617,
      "SHELF LIFE_1": 4,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 13722787,
      "DESCRIPTION": "QS762 SEALING PACKAGE",
      "PRODUCT CATEGORY": "YCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "01010-52080",
      "DESCRIPTION": "BOLT M20X80",
      "PRODUCT CATEGORY": "SDG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 5.33333333333333,
      "MAINTANING STOCK QTY": 21,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 21,
      "APRD QTY": 21,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 2,
      "STD": 13.6776066282417,
      "MEAN": 4.92307692307692,
      "COV": 2.7782638463616,
      "SHELF LIFE_1": 3.937500000000002,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 16850066,
      "DESCRIPTION": "COOLING COIL",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 2,
      "STD": 0.375533808099405,
      "MEAN": 0.153846153846153,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": "01010-82045",
      "DESCRIPTION": "BOLT",
      "PRODUCT CATEGORY": "SDG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 1.33333333333333,
      "MAINTANING STOCK QTY": 5,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 5,
      "APRD QTY": 5,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 2,
      "STD": 4.43760156980183,
      "MEAN": 1.23076923076923,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 3.7500000000000098,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 11050593,
      "DESCRIPTION": "RUBBER CUSHION",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 2,
      "STD": 1.10940039245045,
      "MEAN": 0.307692307692307,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 3.000000000000003,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 11060194,
      "DESCRIPTION": "SPACER",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 2,
      "STD": 1.10940039245045,
      "MEAN": 0.307692307692307,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 3.000000000000003,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 4011000915,
      "DESCRIPTION": "SCREW",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 2,
      "STD": 1.10940039245045,
      "MEAN": 0.307692307692307,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 3.000000000000003,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 4110000453,
      "DESCRIPTION": "ABSORBER",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 4,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 2,
      "STD": 1.10940039245045,
      "MEAN": 0.307692307692307,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 3.000000000000003,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 29310024461,
      "DESCRIPTION": "WASHER",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 2,
      "STD": 1.10940039245045,
      "MEAN": 0.307692307692307,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 3.000000000000003,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 4011000378,
      "DESCRIPTION": "BOLT",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 17476848,
      "DESCRIPTION": "HYDRAULIC OIL COOLER",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 4130003515,
      "DESCRIPTION": "EVAPORATOR",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 2,
      "STD": 1.10940039245045,
      "MEAN": 0.307692307692307,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 3.000000000000003,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 4130003052,
      "DESCRIPTION": "AIR CONDITIONING COMPRESSOR",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4190002199,
      "DESCRIPTION": "EXPANSION VALVE",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4190002201,
      "DESCRIPTION": "VALVE",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "B222100000561",
      "DESCRIPTION": "WATER SEPARATOR",
      "PRODUCT CATEGORY": "YCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 60108785,
      "DESCRIPTION": "ROLLER",
      "PRODUCT CATEGORY": "YPG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 1.66666666666666,
      "MAINTANING STOCK QTY": 7,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 7,
      "APRD QTY": 7,
      "WH12": 5,
      "WHXX": 0,
      "WH REFERENCE": "PARTIAL WH12",
      "TREND": 2,
      "STD": 5.54700196225229,
      "MEAN": 1.53846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 4.200000000000016,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 4190002195,
      "DESCRIPTION": "CONDENSER",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 4130002911009,
      "DESCRIPTION": "HOSE",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 12825949,
      "DESCRIPTION": "WIRING HARNESS",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 16054756,
      "DESCRIPTION": "WIRING HARNESS",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 12814015,
      "DESCRIPTION": "PLATE",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 13914197,
      "DESCRIPTION": "TENSION SPRING",
      "PRODUCT CATEGORY": "VCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 59929059,
      "DESCRIPTION": "CLEVIS",
      "PRODUCT CATEGORY": "VCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14671838,
      "DESCRIPTION": "TERMINAL",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 29350010201,
      "DESCRIPTION": "WATER PIPE",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.25,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 2,
      "STD": 0.832050294337843,
      "MEAN": 0.23076923076923,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 4,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 29350010211,
      "DESCRIPTION": "WATER PIPE",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.25,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 2,
      "STD": 0.832050294337843,
      "MEAN": 0.23076923076923,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 4,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 4190002907007,
      "DESCRIPTION": "HOSE",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": "117K-01-00020",
      "DESCRIPTION": "COVER",
      "PRODUCT CATEGORY": "SDG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "SLOW (S)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 2,
      "STD": 0.375533808099405,
      "MEAN": 0.153846153846153,
      "COV": 2.44096975264613,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 14558286,
      "DESCRIPTION": "WIRING HARNESS",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "TP1000544065",
      "DESCRIPTION": "OUTLET PIPE GASKET",
      "PRODUCT CATEGORY": "SDG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.5,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 2,
      "APRD QTY": 2,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 2,
      "STD": 1.66410058867568,
      "MEAN": 0.461538461538461,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 4,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": "TP610800110416",
      "DESCRIPTION": "EXHAUST PIPE BOLT",
      "PRODUCT CATEGORY": "SDG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.916666666666666,
      "MAINTANING STOCK QTY": 4,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 4,
      "APRD QTY": 4,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 2,
      "STD": 3.05085107923876,
      "MEAN": 0.846153846153846,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 4.363636363636367,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": "TP612600116274",
      "DESCRIPTION": "EXHAUST PIPE BOLT",
      "PRODUCT CATEGORY": "SDG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "TP90011420036",
      "DESCRIPTION": "HEXAGON FLANGE BOLT",
      "PRODUCT CATEGORY": "SDG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 2,
      "STD": 1.10940039245045,
      "MEAN": 0.307692307692307,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 3.000000000000003,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": "TP615G60110032",
      "DESCRIPTION": "SHIM",
      "PRODUCT CATEGORY": "SDG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 29170085771,
      "DESCRIPTION": "WEAR PLATE",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "402069Z00D",
      "DESCRIPTION": "BRAKE DRUM",
      "PRODUCT CATEGORY": "NIG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 1,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 24040481,
      "DESCRIPTION": "STARTER MOTOR 22851149",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 2,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14259076,
      "DESCRIPTION": "RIGHT FRONT WHEEL REAR DASHBOARD ANTI-SPLASH",
      "PRODUCT CATEGORY": "YPG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14150767,
      "DESCRIPTION": "RIGHT FRONT HEADLAMP POLE ASSEMBLY",
      "PRODUCT CATEGORY": "YPG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 12052718,
      "DESCRIPTION": "RIGHT WHEEL MIDDLE DECORATION STRIP",
      "PRODUCT CATEGORY": "YPG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 13275025,
      "DESCRIPTION": "FRONT WHEEL DASHBOARD LEFT CENTRAL SUPPORT",
      "PRODUCT CATEGORY": "YPG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 13025425,
      "DESCRIPTION": "ASSY-JR,3-GRADE PEDAL, RH",
      "PRODUCT CATEGORY": "YPG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14259072,
      "DESCRIPTION": "250H ANTI SPLASH REAR BAFFLE",
      "PRODUCT CATEGORY": "YPG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14259073,
      "DESCRIPTION": "MUDFLAP",
      "PRODUCT CATEGORY": "YPG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14259074,
      "DESCRIPTION": "DASHBOARD STRIP",
      "PRODUCT CATEGORY": "YPG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "156-40-00013",
      "DESCRIPTION": "SHIELD BOLT",
      "PRODUCT CATEGORY": "SDG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 3,
      "MAINTANING STOCK QTY": 12,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 12,
      "APRD QTY": 12,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 2,
      "STD": 9.98460353205412,
      "MEAN": 2.76923076923076,
      "COV": 3.60555127546399,
      "SHELF LIFE_1": 4,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": "01580-12218",
      "DESCRIPTION": "NUT M22",
      "PRODUCT CATEGORY": "SDG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 2.5,
      "MAINTANING STOCK QTY": 10,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 10,
      "APRD QTY": 10,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 2,
      "STD": 8.32050294337843,
      "MEAN": 2.3076923076923,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 4,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": "156-40-00014",
      "DESCRIPTION": "SLEEVE",
      "PRODUCT CATEGORY": "SDG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 2.5,
      "MAINTANING STOCK QTY": 10,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 10,
      "APRD QTY": 10,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 2,
      "STD": 8.32050294337843,
      "MEAN": 2.3076923076923,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 4,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": "01010-51235",
      "DESCRIPTION": "BOLT M12X35",
      "PRODUCT CATEGORY": "SDG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 1.33333333333333,
      "MAINTANING STOCK QTY": 5,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 5,
      "APRD QTY": 5,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 2,
      "STD": 4.43760156980183,
      "MEAN": 1.23076923076923,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 3.7500000000000098,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": "156-40-02004",
      "DESCRIPTION": "NUT",
      "PRODUCT CATEGORY": "SDG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 2,
      "STD": 1.10940039245045,
      "MEAN": 0.307692307692307,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 3.000000000000003,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": "156-40-02005",
      "DESCRIPTION": "LINING BLOCK",
      "PRODUCT CATEGORY": "SDG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 2,
      "STD": 1.10940039245045,
      "MEAN": 0.307692307692307,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 3.000000000000003,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": "14Y-40-02100",
      "DESCRIPTION": "GRASE GUN",
      "PRODUCT CATEGORY": "SDG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 2,
      "STD": 1.10940039245045,
      "MEAN": 0.307692307692307,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 3.000000000000003,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": "07021-00890",
      "DESCRIPTION": "OIL CUP 90 ¬∞ M8X1",
      "PRODUCT CATEGORY": "SDG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 2,
      "STD": 1.10940039245045,
      "MEAN": 0.307692307692307,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 3.000000000000003,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": "14Y-40-02200",
      "DESCRIPTION": "BAFFLE",
      "PRODUCT CATEGORY": "SDG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 2,
      "STD": 1.10940039245045,
      "MEAN": 0.307692307692307,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 3.000000000000003,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 13255240,
      "DESCRIPTION": "BUMPER LOWER BRACKET",
      "PRODUCT CATEGORY": "YTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 17222542,
      "DESCRIPTION": "WIRING HARNESS",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 11223889,
      "DESCRIPTION": "SEALING RING",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 11223888,
      "DESCRIPTION": "SEALING RING",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 11223886,
      "DESCRIPTION": "SEALING RING",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 11223879,
      "DESCRIPTION": "O-RING",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 11223869,
      "DESCRIPTION": "O-RING",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 4,
      "SHELF LIFE": 12.000000000000012,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 1.10940039245045,
      "MEAN": 0.307692307692307,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 12.000000000000012,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 11223876,
      "DESCRIPTION": "O-RING",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 14589168,
      "DESCRIPTION": "SEALING KIT",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "TP612650110003",
      "DESCRIPTION": "EXHAUST LINER",
      "PRODUCT CATEGORY": "SDG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.5,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 2,
      "APRD QTY": 2,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 2,
      "STD": 1.66410058867568,
      "MEAN": 0.461538461538461,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 4,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": "TP61260110162",
      "DESCRIPTION": "SHIM",
      "PRODUCT CATEGORY": "SDG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 2,
      "STD": 1.10940039245045,
      "MEAN": 0.307692307692307,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 3.000000000000003,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 23961982,
      "DESCRIPTION": "REAR-VIEW MIRROR         ",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": "16.30.080.129",
      "DESCRIPTION": "BOLT",
      "PRODUCT CATEGORY": "PHG",
      "ITEM MOVEMENT BRANCH": "SLOW (S)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.416666666666666,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 2,
      "APRD QTY": 2,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 2,
      "STD": 1.12089707663561,
      "MEAN": 0.384615384615384,
      "COV": 2.91433239925258,
      "SHELF LIFE_1": 4.800000000000008,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 22935396,
      "DESCRIPTION": "U-BOLT              ",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 1,
      "MAINTANING STOCK QTY": 4,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 4,
      "APRD QTY": 4,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 2,
      "STD": 2.39657875806111,
      "MEAN": 0.923076923076923,
      "COV": 2.5962936545662,
      "SHELF LIFE_1": 4,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": "01011-82045 ",
      "DESCRIPTION": "BOLTS(IDLER)",
      "PRODUCT CATEGORY": "SDG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 4,
      "MAINTANING STOCK QTY": 16,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 16,
      "APRD QTY": 16,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 2,
      "STD": 13.3128047094055,
      "MEAN": 3.69230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 4,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": "SSL01123",
      "DESCRIPTION": "FRONT COVER/SILENCE/SB70 L01123",
      "PRODUCT CATEGORY": "SOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 14552493,
      "DESCRIPTION": "CABLE",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 22417696,
      "DESCRIPTION": "CABLE",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 23252913,
      "DESCRIPTION": "CONTROL UNIT             ",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 2,
      "STD": 1.10940039245045,
      "MEAN": 0.307692307692307,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 3.000000000000003,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 7442381,
      "DESCRIPTION": "WINDOW RIGHT ASSEMBLY",
      "PRODUCT CATEGORY": "DOB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "TP1000410660",
      "DESCRIPTION": "TENSIONING WHEEL ASSEMBLY",
      "PRODUCT CATEGORY": "SDG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": "160101110018A088",
      "DESCRIPTION": "WATER SEPARATOR FILTER ELEMENT",
      "PRODUCT CATEGORY": "YCG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 20578225,
      "DESCRIPTION": "POWER CABLE",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 2,
      "STD": 1.10940039245045,
      "MEAN": 0.307692307692307,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 3.000000000000003,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 20774880,
      "DESCRIPTION": "WIRES",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 14723445,
      "DESCRIPTION": "TRACK GUARD",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.666666666666666,
      "MAINTANING STOCK QTY": 3,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 3,
      "APRD QTY": 3,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 2,
      "STD": 2.21880078490091,
      "MEAN": 0.615384615384615,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 4.500000000000004,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 992453,
      "DESCRIPTION": "FUSE",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "ITEM MOVEMENT PHILWIDE": "NON-STOCK (N)",
      "30 DAY AVE": 0.833333333333333,
      "MAINTANING STOCK QTY": 3,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 3,
      "APRD QTY": 3,
      "WH12": 25,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 2,
      "STD": 2.77350098112614,
      "MEAN": 0.769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 3.6000000000000014,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 4110000509213,
      "DESCRIPTION": "HOLLOW SCREW",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 23659479,
      "DESCRIPTION": "WIRES                    ",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "30 DAY AVE": 0.333333333333333,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 2,
      "STD": 1.10940039245045,
      "MEAN": 0.307692307692307,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 3.000000000000003,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 22039393,
      "DESCRIPTION": "WIRING HARNESS 21311690",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 17367461,
      "DESCRIPTION": "HUB RETAINER",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 53489684,
      "DESCRIPTION": "ROLLER 14676340",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "30 DAY AVE": 0.5,
      "MAINTANING STOCK QTY": 2,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 2,
      "APRD QTY": 2,
      "WH12": 4,
      "WHXX": 0,
      "WH REFERENCE": "WH12",
      "TREND": 2,
      "STD": 1.66410058867568,
      "MEAN": 0.461538461538461,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 4,
      "STOCK STATUS": "SUFFICIENT"
    },
    {
      "PART NUMBER": 23962007,
      "DESCRIPTION": "REAR-VIEW MIRROR         ",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 4190003393,
      "DESCRIPTION": "HOSE",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 4130002467,
      "DESCRIPTION": "HOSE",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 4130002468,
      "DESCRIPTION": "HOSE",
      "PRODUCT CATEGORY": "VLG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "TP612600083020",
      "DESCRIPTION": "HIGH PRESSURE OIL PIPE ASSEMBLY",
      "PRODUCT CATEGORY": "SDG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": "TP61800060172",
      "DESCRIPTION": "BUILT IN THERMOSTAT",
      "PRODUCT CATEGORY": "SDG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": "TP680340065",
      "DESCRIPTION": "SEAL RING",
      "PRODUCT CATEGORY": "SDG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "30 DAY AVE": 0.166666666666666,
      "MAINTANING STOCK QTY": 1,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 1,
      "APRD QTY": 1,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 2,
      "STD": 0.554700196225229,
      "MEAN": 0.153846153846153,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.000000000000024,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": "TP612600160099",
      "DESCRIPTION": "OUTLET PIPE GASKET",
      "PRODUCT CATEGORY": "SDG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 23659350,
      "DESCRIPTION": "WIRES                    ",
      "PRODUCT CATEGORY": "VTG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "30 DAY AVE": 0.666666666666666,
      "MAINTANING STOCK QTY": 3,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "INSUFFICIENT",
      "ROQ": 3,
      "APRD QTY": 3,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "RFC",
      "TREND": 2,
      "STD": 2.21880078490091,
      "MEAN": 0.615384615384615,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 4.500000000000004,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": "571747HOK",
      "DESCRIPTION": "SDLG HYDRAULIC OIL VG68",
      "PRODUCT CATEGORY": "SLUB",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "30 DAY AVE": 48.3333333333333,
      "MAINTANING STOCK QTY": 193,
      "AVAILABLE": 300,
      "SHELF LIFE": 6.206896551724142,
      "ORDER STATUS": "SUFFICIENT",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 3980,
      "WHXX": 2160,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 160.863056905316,
      "MEAN": 44.6153846153846,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 6.206896551724142,
      "STOCK STATUS": "OVERSTOCK"
    },
    {
      "PART NUMBER": 17237235,
      "DESCRIPTION": "KIT",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    },
    {
      "PART NUMBER": 17237189,
      "DESCRIPTION": "TOOL KIT",
      "PRODUCT CATEGORY": "VOG",
      "ITEM MOVEMENT BRANCH": "NON-STOCK (N)",
      "30 DAY AVE": 0.0833333333333333,
      "MAINTANING STOCK QTY": 0,
      "AVAILABLE": 0,
      "SHELF LIFE": 0,
      "ORDER STATUS": "NON-STOCK",
      "ROQ": 0,
      "APRD QTY": 0,
      "WH12": 0,
      "WHXX": 0,
      "WH REFERENCE": "",
      "TREND": 2,
      "STD": 0.277350098112614,
      "MEAN": 0.0769230769230769,
      "COV": 3.60555127546398,
      "SHELF LIFE_1": 0,
      "STOCK STATUS": "NON-STOCK"
    }
  ];





export const ordersGrid = [
  {
    headerText: 'Image',
    template: gridOrderImage,
    textAlign: 'Center',
    width: '120',
  },
  {
    field: 'OrderItems',
    headerText: 'Item',
    width: '150',
    editType: 'dropdownedit',
    textAlign: 'Center',
  },
  { field: 'CustomerName',
    headerText: 'Customer Name',
    width: '150',
    textAlign: 'Center',
  },
  {
    field: 'TotalAmount',
    headerText: 'Total Amount',
    format: 'C2',
    textAlign: 'Center',
    editType: 'numericedit',
    width: '150',
  },
  {
    headerText: 'Status',
    template: gridOrderStatus,
    field: 'OrderItems',
    textAlign: 'Center',
    width: '120',
  },
  {
    field: 'OrderID',
    headerText: 'Order ID',
    width: '120',
    textAlign: 'Center',
  },

  {
    field: 'Location',
    headerText: 'Location',
    width: '150',
    textAlign: 'Center',
  },
];

export const customersData = [
  {
    CustomerID: 1001,
    CustomerName: 'Nirav Joshi',
    CustomerEmail: 'nirav@gmail.com',
    CustomerImage:
      avatar2,
    ProjectName: 'Hosting Press HTML',
    Status: 'Active',
    StatusBg: '#8BE78B',
    Weeks: '40',
    Budget: '$2.4k',
    Location: 'India',
  },
  {
    CustomerID: 1002,

    CustomerName: 'Sunil Joshi',
    CustomerEmail: 'sunil@gmail.com',
    ProjectName: 'Elite Admin',
    Status: 'Active',
    CustomerImage:
      avatar3,

    StatusBg: '#8BE78B',
    Weeks: '11',
    Budget: '$3.9k',
    Location: 'India',
  },
  {
    CustomerID: 1003,

    CustomerName: 'Andrew McDownland',
    CustomerEmail: 'andrew@gmail.com',
    ProjectName: 'Real Homes WP Theme',
    Status: 'Pending',
    CustomerImage:
      avatar4,
    StatusBg: '#FEC90F',
    Weeks: '19',
    Budget: '$24.5k',
    Location: 'USA',
  },
  {
    CustomerID: 1004,

    CustomerName: 'Christopher Jamil',
    CustomerEmail: 'jamil@gmail.com',
    ProjectName: 'MedicalPro WP Theme',
    Status: 'Completed',
    CustomerImage:
      avatar,
    StatusBg: '#8BE78B',
    Weeks: '34',
    Budget: '$16.5k',
    Location: 'USA',
  },
  {
    CustomerID: 1005,

    CustomerName: 'Michael',
    CustomerEmail: 'michael@gmail.com',
    ProjectName: 'Weekly WP Theme',
    Status: 'Cancel',
    CustomerImage:
      avatar2,
    StatusBg: 'red',
    Weeks: '34',
    Budget: '$16.5k',
    Location: 'USA',
  },
  {
    CustomerID: 1006,
    CustomerName: 'Nirav Joshi',
    CustomerEmail: 'nirav@gmail.com',
    CustomerImage:
      avatar2,
    ProjectName: 'Hosting Press HTML',
    Status: 'Active',
    StatusBg: '#8BE78B',
    Weeks: '40',
    Budget: '$2.4k',
    Location: 'India',
  },
  {
    CustomerID: 1007,

    CustomerName: 'Sunil Joshi',
    CustomerEmail: 'sunil@gmail.com',
    ProjectName: 'Elite Admin',
    Status: 'Active',
    CustomerImage:
      avatar3,

    StatusBg: '#8BE78B',
    Weeks: '11',
    Budget: '$3.9k',
    Location: 'India',
  },
  {
    CustomerID: 1008,

    CustomerName: 'Andrew McDownland',
    CustomerEmail: 'andrew@gmail.com',
    ProjectName: 'Real Homes WP Theme',
    Status: 'Pending',
    CustomerImage:
      avatar4,
    StatusBg: '#FEC90F',
    Weeks: '19',
    Budget: '$24.5k',
    Location: 'USA',
  },
  {
    CustomerID: 1009,

    CustomerName: 'Christopher Jamil',
    CustomerEmail: 'jamil@gmail.com',
    ProjectName: 'MedicalPro WP Theme',
    Status: 'Completed',
    CustomerImage:
      avatar,
    StatusBg: '#8BE78B',
    Weeks: '34',
    Budget: '$16.5k',
    Location: 'USA',
  },
  {
    CustomerID: 1010,

    CustomerName: 'Michael',
    CustomerEmail: 'michael@gmail.com',
    ProjectName: 'Weekly WP Theme',
    Status: 'Cancel',
    CustomerImage:
      avatar2,
    StatusBg: 'red',
    Weeks: '34',
    Budget: '$16.5k',
    Location: 'USA',
  },
  {
    CustomerID: 1011,
    CustomerName: 'Nirav Joshi',
    CustomerEmail: 'nirav@gmail.com',
    CustomerImage:
      avatar2,
    ProjectName: 'Hosting Press HTML',
    Status: 'Active',
    StatusBg: '#8BE78B',
    Weeks: '40',
    Budget: '$2.4k',
    Location: 'India',
  },
  {
    CustomerID: 1012,

    CustomerName: 'Sunil Joshi',
    CustomerEmail: 'sunil@gmail.com',
    ProjectName: 'Elite Admin',
    Status: 'Active',
    CustomerImage:
      avatar3,

    StatusBg: '#8BE78B',
    Weeks: '11',
    Budget: '$3.9k',
    Location: 'India',
  },
  {
    CustomerID: 1013,

    CustomerName: 'Andrew McDownland',
    CustomerEmail: 'andrew@gmail.com',
    ProjectName: 'Real Homes WP Theme',
    Status: 'Pending',
    CustomerImage:
      avatar4,
    StatusBg: '#FEC90F',
    Weeks: '19',
    Budget: '$24.5k',
    Location: 'USA',
  },
  {
    CustomerID: 1014,

    CustomerName: 'Christopher Jamil',
    CustomerEmail: 'jamil@gmail.com',
    ProjectName: 'MedicalPro WP Theme',
    Status: 'Completed',
    CustomerImage:
      avatar,
    StatusBg: '#8BE78B',
    Weeks: '34',
    Budget: '$16.5k',
    Location: 'USA',
  },
  {
    CustomerID: 1015,

    CustomerName: 'Michael',
    CustomerEmail: 'michael@gmail.com',
    ProjectName: 'Weekly WP Theme',
    Status: 'Cancel',
    CustomerImage:
      avatar2,
    StatusBg: 'red',
    Weeks: '34',
    Budget: '$16.5k',
    Location: 'USA',
  },
  {
    CustomerID: 1016,
    CustomerName: 'Nirav Joshi',
    CustomerEmail: 'nirav@gmail.com',
    CustomerImage:
      avatar2,
    ProjectName: 'Hosting Press HTML',
    Status: 'Active',
    StatusBg: '#8BE78B',
    Weeks: '40',
    Budget: '$2.4k',
    Location: 'India',
  },
  {
    CustomerID: 1017,

    CustomerName: 'Sunil Joshi',
    CustomerEmail: 'sunil@gmail.com',
    ProjectName: 'Elite Admin',
    Status: 'Active',
    CustomerImage:
      avatar3,

    StatusBg: '#8BE78B',
    Weeks: '11',
    Budget: '$3.9k',
    Location: 'India',
  },
  {
    CustomerID: 1018,

    CustomerName: 'Andrew McDownland',
    CustomerEmail: 'andrew@gmail.com',
    ProjectName: 'Real Homes WP Theme',
    Status: 'Pending',
    CustomerImage:
      avatar4,
    StatusBg: '#FEC90F',
    Weeks: '19',
    Budget: '$24.5k',
    Location: 'USA',
  },
  {
    CustomerID: 1019,

    CustomerName: 'Christopher Jamil',
    CustomerEmail: 'jamil@gmail.com',
    ProjectName: 'MedicalPro WP Theme',
    Status: 'Completed',
    CustomerImage:
      avatar,
    StatusBg: '#8BE78B',
    Weeks: '34',
    Budget: '$16.5k',
    Location: 'USA',
  },
  {
    CustomerID: 1020,

    CustomerName: 'Michael',
    CustomerEmail: 'michael@gmail.com',
    ProjectName: 'Weekly WP Theme',
    Status: 'Cancel',
    CustomerImage:
      avatar2,
    StatusBg: 'red',
    Weeks: '34',
    Budget: '$16.5k',
    Location: 'USA',
  },
  {
    CustomerID: 1021,
    CustomerName: 'Nirav Joshi',
    CustomerEmail: 'nirav@gmail.com',
    CustomerImage:
      avatar2,
    ProjectName: 'Hosting Press HTML',
    Status: 'Active',
    StatusBg: '#8BE78B',
    Weeks: '40',
    Budget: '$2.4k',
    Location: 'India',
  },
  {
    CustomerID: 1022,

    CustomerName: 'Sunil Joshi',
    CustomerEmail: 'sunil@gmail.com',
    ProjectName: 'Elite Admin',
    Status: 'Active',
    CustomerImage:
      avatar3,

    StatusBg: '#8BE78B',
    Weeks: '11',
    Budget: '$3.9k',
    Location: 'India',
  },
  {
    CustomerID: 1023,

    CustomerName: 'Andrew McDownland',
    CustomerEmail: 'andrew@gmail.com',
    ProjectName: 'Real Homes WP Theme',
    Status: 'Pending',
    CustomerImage:
      avatar4,
    StatusBg: '#FEC90F',
    Weeks: '19',
    Budget: '$24.5k',
    Location: 'USA',
  },
  {
    CustomerID: 1024,

    CustomerName: 'Christopher Jamil',
    CustomerEmail: 'jamil@gmail.com',
    ProjectName: 'MedicalPro WP Theme',
    Status: 'Completed',
    CustomerImage:
      avatar,
    StatusBg: '#8BE78B',
    Weeks: '34',
    Budget: '$16.5k',
    Location: 'USA',
  },
  {
    CustomerID: 1025,

    CustomerName: 'Michael',
    CustomerEmail: 'michael@gmail.com',
    ProjectName: 'Weekly WP Theme',
    Status: 'Cancel',
    CustomerImage:
      avatar2,
    StatusBg: 'red',
    Weeks: '34',
    Budget: '$16.5k',
    Location: 'USA',
  },
  {
    CustomerID: 1026,
    CustomerName: 'Nirav Joshi',
    CustomerEmail: 'nirav@gmail.com',
    CustomerImage:
      avatar2,
    ProjectName: 'Hosting Press HTML',
    Status: 'Active',
    StatusBg: '#8BE78B',
    Weeks: '40',
    Budget: '$2.4k',
    Location: 'India',
  },
  {
    CustomerID: 1027,

    CustomerName: 'Sunil Joshi',
    CustomerEmail: 'sunil@gmail.com',
    ProjectName: 'Elite Admin',
    Status: 'Active',
    CustomerImage:
      avatar3,

    StatusBg: '#8BE78B',
    Weeks: '11',
    Budget: '$3.9k',
    Location: 'India',
  },
  {
    CustomerID: 1028,

    CustomerName: 'Andrew McDownland',
    CustomerEmail: 'andrew@gmail.com',
    ProjectName: 'Real Homes WP Theme',
    Status: 'Pending',
    CustomerImage:
      avatar4,
    StatusBg: '#FEC90F',
    Weeks: '19',
    Budget: '$24.5k',
    Location: 'USA',
  },
  {
    CustomerID: 1029,

    CustomerName: 'Christopher Jamil',
    CustomerEmail: 'jamil@gmail.com',
    ProjectName: 'MedicalPro WP Theme',
    Status: 'Completed',
    CustomerImage:
      avatar,
    StatusBg: '#8BE78B',
    Weeks: '34',
    Budget: '$16.5k',
    Location: 'USA',
  },
  {
    CustomerID: 1030,

    CustomerName: 'Michael',
    CustomerEmail: 'michael@gmail.com',
    ProjectName: 'Weekly WP Theme',
    Status: 'Cancel',
    CustomerImage:
      avatar2,
    StatusBg: 'red',
    Weeks: '34',
    Budget: '$16.5k',
    Location: 'USA',
  },
  {
    CustomerID: 1031,
    CustomerName: 'Nirav Joshi',
    CustomerEmail: 'nirav@gmail.com',
    CustomerImage:
      avatar2,
    ProjectName: 'Hosting Press HTML',
    Status: 'Active',
    StatusBg: '#8BE78B',
    Weeks: '40',
    Budget: '$2.4k',
    Location: 'India',
  },
  {
    CustomerID: 1032,

    CustomerName: 'Sunil Joshi',
    CustomerEmail: 'sunil@gmail.com',
    ProjectName: 'Elite Admin',
    Status: 'Active',
    CustomerImage:
      avatar3,

    StatusBg: '#8BE78B',
    Weeks: '11',
    Budget: '$3.9k',
    Location: 'India',
  },
  {
    CustomerID: 1033,

    CustomerName: 'Andrew McDownland',
    CustomerEmail: 'andrew@gmail.com',
    ProjectName: 'Real Homes WP Theme',
    Status: 'Pending',
    CustomerImage:
      avatar4,
    StatusBg: '#FEC90F',
    Weeks: '19',
    Budget: '$24.5k',
    Location: 'USA',
  },
  {
    CustomerID: 1034,

    CustomerName: 'Christopher Jamil',
    CustomerEmail: 'jamil@gmail.com',
    ProjectName: 'MedicalPro WP Theme',
    Status: 'Completed',
    CustomerImage:
      avatar,
    StatusBg: '#8BE78B',
    Weeks: '34',
    Budget: '$16.5k',
    Location: 'USA',
  },
  {
    CustomerID: 1035,

    CustomerName: 'Michael',
    CustomerEmail: 'michael@gmail.com',
    ProjectName: 'Weekly WP Theme',
    Status: 'Cancel',
    CustomerImage:
      avatar2,
    StatusBg: 'red',
    Weeks: '34',
    Budget: '$16.5k',
    Location: 'USA',
  },
  {
    CustomerID: 1036,
    CustomerName: 'Nirav Joshi',
    CustomerEmail: 'nirav@gmail.com',
    CustomerImage:
      avatar2,
    ProjectName: 'Hosting Press HTML',
    Status: 'Active',
    StatusBg: '#8BE78B',
    Weeks: '40',
    Budget: '$2.4k',
    Location: 'India',
  },
  {
    CustomerID: 1037,

    CustomerName: 'Sunil Joshi',
    CustomerEmail: 'sunil@gmail.com',
    ProjectName: 'Elite Admin',
    Status: 'Active',
    CustomerImage:
      avatar3,

    StatusBg: '#8BE78B',
    Weeks: '11',
    Budget: '$3.9k',
    Location: 'India',
  },
  {
    CustomerID: 1038,

    CustomerName: 'Andrew McDownland',
    CustomerEmail: 'andrew@gmail.com',
    ProjectName: 'Real Homes WP Theme',
    Status: 'Pending',
    CustomerImage:
      avatar4,
    StatusBg: '#FEC90F',
    Weeks: '19',
    Budget: '$24.5k',
    Location: 'USA',
  },
  {
    CustomerID: 1039,
    CustomerName: 'Christopher Jamil',
    CustomerEmail: 'jamil@gmail.com',
    ProjectName: 'MedicalPro WP Theme',
    Status: 'Completed',
    CustomerImage:
      avatar,
    StatusBg: '#8BE78B',
    Weeks: '34',
    Budget: '$16.5k',
    Location: 'USA',
  },
  {
    CustomerID: 1040,
    CustomerName: 'Michael',
    CustomerEmail: 'michael@gmail.com',
    ProjectName: 'Weekly WP Theme',
    Status: 'Cancel',
    CustomerImage:
      avatar2,
    StatusBg: 'red',
    Weeks: '34',
    Budget: '$16.5k',
    Location: 'USA',
  },

];

export const employeesData = [
  {
    EmployeeID: 1,
    Name: 'Nancy Davolio',
    Title: 'Sales Representative',
    HireDate: '01/02/2021',
    Country: 'USA',
    ReportsTo: 'Carson',
    EmployeeImage:
    avatar3,
  },
  {
    EmployeeID: 2,
    Name: 'Nasimiyu Danai',
    Title: 'Marketing Head',
    HireDate: '01/02/2021',
    Country: 'USA',
    ReportsTo: 'Carson',
    EmployeeImage:
      avatar3,
  },
  {
    EmployeeID: 3,
    Name: 'Iulia Albu',
    Title: 'HR',
    HireDate: '01/02/2021',
    Country: 'USA',
    ReportsTo: 'Carson',
    EmployeeImage:
      avatar4,
  },
  {
    EmployeeID: 4,
    Name: 'Siegbert Gottfried',
    Title: 'Marketing Head',
    HireDate: '01/02/2021',
    Country: 'USA',
    ReportsTo: 'Carson',
    EmployeeImage:
      avatar2,
  },
  {
    EmployeeID: 5,
    Name: 'Omar Darobe',
    Title: 'HR',
    HireDate: '01/02/2021',
    Country: 'USA',
    ReportsTo: 'Carson',
    EmployeeImage:
      avatar,
  },
  {
    EmployeeID: 4,
    Name: 'Penjani Inyene',
    Title: 'Marketing Head',
    HireDate: '01/02/2021',
    Country: 'USA',
    ReportsTo: 'Carson',
    EmployeeImage:
      avatar,
  },
  {
    EmployeeID: 5,
    Name: 'Miron Vitold',
    Title: 'HR',
    HireDate: '01/02/2021',
    Country: 'USA',
    ReportsTo: 'Carson',
    EmployeeImage:
      avatar2,
  },
  {
    EmployeeID: 1,
    Name: 'Nancy Davolio',
    Title: 'Sales Representative',
    HireDate: '01/02/2021',
    Country: 'USA',
    ReportsTo: 'Carson',
    EmployeeImage:
    avatar2,

  },
  {
    EmployeeID: 2,
    Name: 'Nasimiyu Danai',
    Title: 'Marketing Head',
    HireDate: '01/02/2021',
    Country: 'USA',
    ReportsTo: 'Carson',
    EmployeeImage:
      avatar3,
  },
  {
    EmployeeID: 3,
    Name: 'Iulia Albu',
    Title: 'HR',
    HireDate: '01/02/2021',
    Country: 'USA',
    ReportsTo: 'Carson',
    EmployeeImage:
      avatar4,
  },
  {
    EmployeeID: 4,
    Name: 'Siegbert Gottfried',
    Title: 'Marketing Head',
    HireDate: '01/02/2021',
    Country: 'USA',
    ReportsTo: 'Carson',
    EmployeeImage:
      avatar2,
  },
  {
    EmployeeID: 5,
    Name: 'Omar Darobe',
    Title: 'HR',
    HireDate: '01/02/2021',
    Country: 'USA',
    ReportsTo: 'Carson',
    EmployeeImage:
      avatar,
  },
  {
    EmployeeID: 4,
    Name: 'Penjani Inyene',
    Title: 'Marketing Head',
    HireDate: '01/02/2021',
    Country: 'USA',
    ReportsTo: 'Carson',
    EmployeeImage:
      avatar,
  },
  {
    EmployeeID: 5,
    Name: 'Miron Vitold',
    Title: 'HR',
    HireDate: '01/02/2021',
    Country: 'USA',
    ReportsTo: 'Carson',
    EmployeeImage:
      avatar2,
  },
  {
    EmployeeID: 1,
    Name: 'Nancy Davolio',
    Title: 'Sales Representative',
    HireDate: '01/02/2021',
    Country: 'USA',
    ReportsTo: 'Carson',
    EmployeeImage:
    avatar,
  },
  {
    EmployeeID: 2,
    Name: 'Nasimiyu Danai',
    Title: 'Marketing Head',
    HireDate: '01/02/2021',
    Country: 'USA',
    ReportsTo: 'Carson',
    EmployeeImage:
      avatar3,
  },
  {
    EmployeeID: 3,
    Name: 'Iulia Albu',
    Title: 'HR',
    HireDate: '01/02/2021',
    Country: 'USA',
    ReportsTo: 'Carson',
    EmployeeImage:
      avatar4,
  },
  {
    EmployeeID: 4,
    Name: 'Siegbert Gottfried',
    Title: 'Marketing Head',
    HireDate: '01/02/2021',
    Country: 'USA',
    ReportsTo: 'Carson',
    EmployeeImage:
      avatar2,
  },
  {
    EmployeeID: 5,
    Name: 'Omar Darobe',
    Title: 'HR',
    HireDate: '01/02/2021',
    Country: 'USA',
    ReportsTo: 'Carson',
    EmployeeImage:
      avatar,
  },
  {
    EmployeeID: 4,
    Name: 'Penjani Inyene',
    Title: 'Marketing Head',
    HireDate: '01/02/2021',
    Country: 'USA',
    ReportsTo: 'Carson',
    EmployeeImage:
      avatar,
  },
  {
    EmployeeID: 5,
    Name: 'Miron Vitold',
    Title: 'HR',
    HireDate: '01/02/2021',
    Country: 'USA',
    ReportsTo: 'Carson',
    EmployeeImage:
      avatar2,
  },
  {
    EmployeeID: 1,
    Name: 'Nancy Davolio',
    Title: 'Sales Representative',
    HireDate: '01/02/2021',
    Country: 'USA',
    ReportsTo: 'Carson',
    EmployeeImage:
    avatar2,

  },
  {
    EmployeeID: 2,
    Name: 'Nasimiyu Danai',
    Title: 'Marketing Head',
    HireDate: '01/02/2021',
    Country: 'USA',
    ReportsTo: 'Carson',
    EmployeeImage:
      avatar3,
  },
  {
    EmployeeID: 3,
    Name: 'Iulia Albu',
    Title: 'HR',
    HireDate: '01/02/2021',
    Country: 'USA',
    ReportsTo: 'Carson',
    EmployeeImage:
      avatar4,
  },
  {
    EmployeeID: 4,
    Name: 'Siegbert Gottfried',
    Title: 'Marketing Head',
    HireDate: '01/02/2021',
    Country: 'USA',
    ReportsTo: 'Carson',
    EmployeeImage:
      avatar2,
  },
  {
    EmployeeID: 5,
    Name: 'Omar Darobe',
    Title: 'HR',
    HireDate: '01/02/2021',
    Country: 'USA',
    ReportsTo: 'Carson',
    EmployeeImage:
      avatar,
  },
  {
    EmployeeID: 4,
    Name: 'Penjani Inyene',
    Title: 'Marketing Head',
    HireDate: '01/02/2021',
    Country: 'USA',
    ReportsTo: 'Carson',
    EmployeeImage:
      avatar,
  },
  {
    EmployeeID: 5,
    Name: 'Miron Vitold',
    Title: 'HR',
    HireDate: '01/02/2021',
    Country: 'USA',
    ReportsTo: 'Carson',
    EmployeeImage:
      avatar2,
  },
  {
    EmployeeID: 1,
    Name: 'Nancy Davolio',
    Title: 'Sales Representative',
    HireDate: '01/02/2021',
    Country: 'USA',
    ReportsTo: 'Carson',
    EmployeeImage:
    avatar2,

  },
  {
    EmployeeID: 2,
    Name: 'Nasimiyu Danai',
    Title: 'Marketing Head',
    HireDate: '01/02/2021',
    Country: 'USA',
    ReportsTo: 'Carson',
    EmployeeImage:
      avatar3,
  },
  {
    EmployeeID: 3,
    Name: 'Iulia Albu',
    Title: 'HR',
    HireDate: '01/02/2021',
    Country: 'USA',
    ReportsTo: 'Carson',
    EmployeeImage:
      avatar4,
  },
  {
    EmployeeID: 4,
    Name: 'Siegbert Gottfried',
    Title: 'Marketing Head',
    HireDate: '01/02/2021',
    Country: 'USA',
    ReportsTo: 'Carson',
    EmployeeImage:
      avatar2,
  },
  {
    EmployeeID: 5,
    Name: 'Omar Darobe',
    Title: 'HR',
    HireDate: '01/02/2021',
    Country: 'USA',
    ReportsTo: 'Carson',
    EmployeeImage:
      avatar,
  },
  {
    EmployeeID: 4,
    Name: 'Penjani Inyene',
    Title: 'Marketing Head',
    HireDate: '01/02/2021',
    Country: 'USA',
    ReportsTo: 'Carson',
    EmployeeImage:
      avatar,
  },
  {
    EmployeeID: 5,
    Name: 'Miron Vitold',
    Title: 'HR',
    HireDate: '01/02/2021',
    Country: 'USA',
    ReportsTo: 'Carson',
    EmployeeImage:
      avatar2,
  },
  {
    EmployeeID: 1,
    Name: 'Nancy Davolio',
    Title: 'Sales Representative',
    HireDate: '01/02/2021',
    Country: 'USA',
    ReportsTo: 'Carson',
    EmployeeImage:
    avatar2,

  },
  {
    EmployeeID: 2,
    Name: 'Nasimiyu Danai',
    Title: 'Marketing Head',
    HireDate: '01/02/2021',
    Country: 'USA',
    ReportsTo: 'Carson',
    EmployeeImage:
      avatar3,
  },
  {
    EmployeeID: 3,
    Name: 'Iulia Albu',
    Title: 'HR',
    HireDate: '01/02/2021',
    Country: 'USA',
    ReportsTo: 'Carson',
    EmployeeImage:
      avatar4,
  },
  {
    EmployeeID: 4,
    Name: 'Siegbert Gottfried',
    Title: 'Marketing Head',
    HireDate: '01/02/2021',
    Country: 'USA',
    ReportsTo: 'Carson',
    EmployeeImage:
      avatar2,
  },
  {
    EmployeeID: 5,
    Name: 'Omar Darobe',
    Title: 'HR',
    HireDate: '01/02/2021',
    Country: 'USA',
    ReportsTo: 'Carson',
    EmployeeImage:
      avatar,
  },
  {
    EmployeeID: 4,
    Name: 'Penjani Inyene',
    Title: 'Marketing Head',
    HireDate: '01/02/2021',
    Country: 'USA',
    ReportsTo: 'Carson',
    EmployeeImage:
      avatar,
  },
  {
    EmployeeID: 5,
    Name: 'Miron Vitold',
    Title: 'HR',
    HireDate: '01/02/2021',
    Country: 'USA',
    ReportsTo: 'Carson',
    EmployeeImage:
      avatar2,
  },
  {
    EmployeeID: 1,
    Name: 'Nancy Davolio',
    Title: 'Sales Representative',
    HireDate: '01/02/2021',
    Country: 'USA',
    ReportsTo: 'Carson',
    EmployeeImage:
    avatar2,

  },
  {
    EmployeeID: 2,
    Name: 'Nasimiyu Danai',
    Title: 'Marketing Head',
    HireDate: '01/02/2021',
    Country: 'USA',
    ReportsTo: 'Carson',
    EmployeeImage:
      avatar3,
  },
  {
    EmployeeID: 3,
    Name: 'Iulia Albu',
    Title: 'HR',
    HireDate: '01/02/2021',
    Country: 'USA',
    ReportsTo: 'Carson',
    EmployeeImage:
      avatar4,
  },
  {
    EmployeeID: 4,
    Name: 'Siegbert Gottfried',
    Title: 'Marketing Head',
    HireDate: '01/02/2021',
    Country: 'USA',
    ReportsTo: 'Carson',
    EmployeeImage:
      avatar2,
  },
  {
    EmployeeID: 5,
    Name: 'Omar Darobe',
    Title: 'HR',
    HireDate: '01/02/2021',
    Country: 'USA',
    ReportsTo: 'Carson',
    EmployeeImage:
      avatar,
  },
  {
    EmployeeID: 4,
    Name: 'Penjani Inyene',
    Title: 'Marketing Head',
    HireDate: '01/02/2021',
    Country: 'USA',
    ReportsTo: 'Carson',
    EmployeeImage:
      avatar,
  },
  {
    EmployeeID: 5,
    Name: 'Miron Vitold',
    Title: 'HR',
    HireDate: '01/02/2021',
    Country: 'USA',
    ReportsTo: 'Carson',
    EmployeeImage:
      avatar2,
  },
  {
    EmployeeID: 1,
    Name: 'Nancy Davolio',
    Title: 'Sales Representative',
    HireDate: '01/02/2021',
    Country: 'USA',
    ReportsTo: 'Carson',
    EmployeeImage:
    avatar2,

  },
  {
    EmployeeID: 2,
    Name: 'Nasimiyu Danai',
    Title: 'Marketing Head',
    HireDate: '01/02/2021',
    Country: 'USA',
    ReportsTo: 'Carson',
    EmployeeImage:
      avatar3,
  },
  {
    EmployeeID: 3,
    Name: 'Iulia Albu',
    Title: 'HR',
    HireDate: '01/02/2021',
    Country: 'USA',
    ReportsTo: 'Carson',
    EmployeeImage:
      avatar4,
  },
  {
    EmployeeID: 4,
    Name: 'Siegbert Gottfried',
    Title: 'Marketing Head',
    HireDate: '01/02/2021',
    Country: 'USA',
    ReportsTo: 'Carson',
    EmployeeImage:
      avatar2,
  },
  {
    EmployeeID: 5,
    Name: 'Omar Darobe',
    Title: 'HR',
    HireDate: '01/02/2021',
    Country: 'USA',
    ReportsTo: 'Carson',
    EmployeeImage:
      avatar,
  },
  {
    EmployeeID: 4,
    Name: 'Penjani Inyene',
    Title: 'Marketing Head',
    HireDate: '01/02/2021',
    Country: 'USA',
    ReportsTo: 'Carson',
    EmployeeImage:
      avatar,
  },
  {
    EmployeeID: 5,
    Name: 'Miron Vitold',
    Title: 'HR',
    HireDate: '01/02/2021',
    Country: 'USA',
    ReportsTo: 'Carson',
    EmployeeImage:
      avatar2,
  },
  {
    EmployeeID: 1,
    Name: 'Nancy Davolio',
    Title: 'Sales Representative',
    HireDate: '01/02/2021',
    Country: 'USA',
    ReportsTo: 'Carson',
    EmployeeImage:
    avatar2,

  },
  {
    EmployeeID: 2,
    Name: 'Nasimiyu Danai',
    Title: 'Marketing Head',
    HireDate: '01/02/2021',
    Country: 'USA',
    ReportsTo: 'Carson',
    EmployeeImage:
      avatar3,
  },
  {
    EmployeeID: 3,
    Name: 'Iulia Albu',
    Title: 'HR',
    HireDate: '01/02/2021',
    Country: 'USA',
    ReportsTo: 'Carson',
    EmployeeImage:
      avatar4,
  },
  {
    EmployeeID: 4,
    Name: 'Siegbert Gottfried',
    Title: 'Marketing Head',
    HireDate: '01/02/2021',
    Country: 'USA',
    ReportsTo: 'Carson',
    EmployeeImage:
      avatar2,
  },
  {
    EmployeeID: 5,
    Name: 'Omar Darobe',
    Title: 'HR',
    HireDate: '01/02/2021',
    Country: 'USA',
    ReportsTo: 'Carson',
    EmployeeImage:
      avatar,
  },
  {
    EmployeeID: 4,
    Name: 'Penjani Inyene',
    Title: 'Marketing Head',
    HireDate: '01/02/2021',
    Country: 'USA',
    ReportsTo: 'Carson',
    EmployeeImage:
      avatar,
  },
  {
    EmployeeID: 5,
    Name: 'Miron Vitold',
    Title: 'HR',
    HireDate: '01/02/2021',
    Country: 'USA',
    ReportsTo: 'Carson',
    EmployeeImage:
      avatar2,
  },
];

export const ordersData = [
  {
    OrderID: 10248,
    CustomerName: 'Vinet',

    TotalAmount: 32.38,
    OrderItems: 'Fresh Tomato',
    Location: 'USA',
    Status: 'pending',
    StatusBg: '#FB9678',
    ProductImage:
      product6,
  },
  {
    OrderID: 345653,
    CustomerName: 'Carson Darrin',
    TotalAmount: 56.34,
    OrderItems: 'Butter Scotch',
    Location: 'Delhi',
    Status: 'complete',
    StatusBg: '#8BE78B',
   
  },
  {
    OrderID: 390457,
    CustomerName: 'Fran Perez',
    TotalAmount: 93.31,
    OrderItems: 'Candy Gucci',
    Location: 'New York',
    Status: 'active',
    StatusBg: '#03C9D7',
    ProductImage:
      product7,
  },
  {
    OrderID: 893486,
    CustomerName: 'Anika Viseer',
    TotalAmount: 93.31,
    OrderItems: 'Night Lamp',
    Location: 'Germany',
    Status: 'canceled',
    StatusBg: '#FF5C8E',
    ProductImage:
      product4,
  },
  {
    OrderID: 748975,
    CustomerName: 'Miron Vitold',
    TotalAmount: 23.99,
    OrderItems: 'Healthcare Erbology',
    Location: 'Spain',
    Status: 'rejected',
    StatusBg: 'red',
    ProductImage:
    product1,
  },
  {
    OrderID: 94757,
    CustomerName: 'Omar Darobe',
    TotalAmount: 95.99,
    OrderItems: 'Makeup Lancome Rouge',
    Location: 'USA',
    Status: 'canceled',
    StatusBg: '#FF5C8E',
    ProductImage:
      product2,
  },
  {
    OrderID: 944895,
    CustomerName: 'Lulia albu',
    TotalAmount: 17.99,
    OrderItems: 'Skincare',
    Location: 'USA',
    Status: 'active',
    StatusBg: '#03C9D7',
    ProductImage:
      product3,
  },
  {
    OrderID: 845954,
    CustomerName: 'Penjani',
    TotalAmount: 59.99,
    OrderItems: 'Headphone',
    Location: 'USA',
    Status: 'complete',
    StatusBg: '#8BE78B',
    ProductImage:
      product4,
  },
  {
    OrderID: 845954,
    CustomerName: 'Jie Yan',
    TotalAmount: 87.99,
    OrderItems: 'Shoes',
    Location: 'USA',
    Status: 'pending',
    StatusBg: '#FB9678',
    ProductImage:
      'https://cdn.shopclues.com/images1/thumbnails/104158/320/320/148648730-104158193-1592481791.jpg',
  },
  {
    OrderID: 874534,
    CustomerName: 'Danai',
    TotalAmount: 122.99,
    OrderItems: 'Watch',
    Location: 'USA',
    Status: 'canceled',
    StatusBg: '#FF5C8E',
    ProductImage:
      'https://hips.hearstapps.com/hmg-prod.s3.amazonaws.com/images/pop-womens-garmin-watches-1641919013.jpg?crop=0.502xw:1.00xh;0.250xw,0&resize=640:*',
  },
  {
    OrderID: 38489,
    CustomerName: 'Miron',
    TotalAmount: 87.99,
    OrderItems: 'Ice Cream',
    Location: 'USA',
    Status: 'active',
    StatusBg: '#03C9D7',
    ProductImage:
      'https://images.immediate.co.uk/production/volatile/sites/30/2020/08/dairy-free-ice-cream-eae372d.jpg',
  },
  {
    OrderID: 24546,
    CustomerName: 'Frank',
    TotalAmount: 84.99,
    OrderItems: 'Pan Cake',
    Location: 'Delhi',
    Status: 'complete',
    StatusBg: '#8BE78B',
    ProductImage:
      'https://images.unsplash.com/photo-1576618148400-f54bed99fcfd?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1480&q=80',
  },
  {
    OrderID: 874534,
    CustomerName: 'Danai',
    TotalAmount: 122.99,
    OrderItems: 'Watch',
    Location: 'USA',
    Status: 'canceled',
    StatusBg: '#FF5C8E',
    ProductImage:
      'https://hips.hearstapps.com/hmg-prod.s3.amazonaws.com/images/pop-womens-garmin-watches-1641919013.jpg?crop=0.502xw:1.00xh;0.250xw,0&resize=640:*',
  },
  {
    OrderID: 10248,
    CustomerName: 'Vinet',

    TotalAmount: 32.38,
    OrderItems: 'Fresh Tomato',
    Location: 'USA',
    Status: 'pending',
    StatusBg: '#FB9678',
    ProductImage:
      product6,
  },
  {
    OrderID: 345653,
    CustomerName: 'Carson Darrin',
    TotalAmount: 56.34,
    OrderItems: 'Butter Scotch',
    Location: 'Delhi',
    Status: 'complete',
    StatusBg: '#8BE78B',
  
  },
  {
    OrderID: 390457,
    CustomerName: 'Fran Perez',
    TotalAmount: 93.31,
    OrderItems: 'Candy Gucci',
    Location: 'New York',
    Status: 'active',
    StatusBg: '#03C9D7',
    ProductImage:
      product7,
  },
  {
    OrderID: 893486,
    CustomerName: 'Anika Viseer',
    TotalAmount: 93.31,
    OrderItems: 'Night Lamp',
    Location: 'Germany',
    Status: 'canceled',
    StatusBg: '#FF5C8E',
    ProductImage:
      product4,
  },
  {
    OrderID: 748975,
    CustomerName: 'Miron Vitold',
    TotalAmount: 23.99,
    OrderItems: 'Healthcare Erbology',
    Location: 'Spain',
    Status: 'rejected',
    StatusBg: 'red',
    ProductImage:
      product1,
  },
  {
    OrderID: 94757,
    CustomerName: 'Omar Darobe',
    TotalAmount: 95.99,
    OrderItems: 'Makeup Lancome Rouge',
    Location: 'USA',
    Status: 'canceled',
    StatusBg: '#FF5C8E',
    ProductImage:
      product2,
  },
  {
    OrderID: 944895,
    CustomerName: 'Lulia albu',
    TotalAmount: 17.99,
    OrderItems: 'Skincare',
    Location: 'USA',
    Status: 'active',
    StatusBg: '#03C9D7',
    ProductImage:
      product3,
  },
  {
    OrderID: 845954,
    CustomerName: 'Penjani',
    TotalAmount: 59.99,
    OrderItems: 'Headphone',
    Location: 'USA',
    Status: 'complete',
    StatusBg: '#8BE78B',
    ProductImage:
      product4,
  },
  {
    OrderID: 845954,
    CustomerName: 'Jie Yan',
    TotalAmount: 87.99,
    OrderItems: 'Shoes',
    Location: 'USA',
    Status: 'pending',
    StatusBg: '#FB9678',
    ProductImage:
      'https://cdn.shopclues.com/images1/thumbnails/104158/320/320/148648730-104158193-1592481791.jpg',
  },
  {
    OrderID: 874534,
    CustomerName: 'Danai',
    TotalAmount: 122.99,
    OrderItems: 'Watch',
    Location: 'USA',
    Status: 'canceled',
    StatusBg: '#FF5C8E',
    ProductImage:
      'https://hips.hearstapps.com/hmg-prod.s3.amazonaws.com/images/pop-womens-garmin-watches-1641919013.jpg?crop=0.502xw:1.00xh;0.250xw,0&resize=640:*',
  },
  {
    OrderID: 38489,
    CustomerName: 'Miron',
    TotalAmount: 87.99,
    OrderItems: 'Ice Cream',
    Location: 'USA',
    Status: 'active',
    StatusBg: '#03C9D7',
    ProductImage:
      'https://images.immediate.co.uk/production/volatile/sites/30/2020/08/dairy-free-ice-cream-eae372d.jpg',
  },
  {
    OrderID: 24546,
    CustomerName: 'Frank',
    TotalAmount: 84.99,
    OrderItems: 'Pan Cake',
    Location: 'Delhi',
    Status: 'complete',
    StatusBg: '#8BE78B',
    ProductImage:
      'https://images.unsplash.com/photo-1576618148400-f54bed99fcfd?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1480&q=80',
  },
  {
    OrderID: 874534,
    CustomerName: 'Danai',
    TotalAmount: 122.99,
    OrderItems: 'Watch',
    Location: 'USA',
    Status: 'canceled',
    StatusBg: '#FF5C8E',
    ProductImage:
      'https://hips.hearstapps.com/hmg-prod.s3.amazonaws.com/images/pop-womens-garmin-watches-1641919013.jpg?crop=0.502xw:1.00xh;0.250xw,0&resize=640:*',
  },
  {
    OrderID: 10248,
    CustomerName: 'Vinet',

    TotalAmount: 32.38,
    OrderItems: 'Fresh Tomato',
    Location: 'USA',
    Status: 'pending',
    StatusBg: '#FB9678',
    ProductImage:
      product6,
  },
  {
    OrderID: 345653,
    CustomerName: 'Carson Darrin',
    TotalAmount: 56.34,
    OrderItems: 'Butter Scotch',
    Location: 'Delhi',
    Status: 'complete',
    StatusBg: '#8BE78B',
    
  },
  {
    OrderID: 390457,
    CustomerName: 'Fran Perez',
    TotalAmount: 93.31,
    OrderItems: 'Candy Gucci',
    Location: 'New York',
    Status: 'active',
    StatusBg: '#03C9D7',
    ProductImage:
      product7,
  },
  {
    OrderID: 893486,
    CustomerName: 'Anika Viseer',
    TotalAmount: 93.31,
    OrderItems: 'Night Lamp',
    Location: 'Germany',
    Status: 'canceled',
    StatusBg: '#FF5C8E',
    ProductImage:
      product4,
  },
  {
    OrderID: 748975,
    CustomerName: 'Miron Vitold',
    TotalAmount: 23.99,
    OrderItems: 'Healthcare Erbology',
    Location: 'Spain',
    Status: 'rejected',
    StatusBg: 'red',
    ProductImage:
      product1,
  },
  {
    OrderID: 94757,
    CustomerName: 'Omar Darobe',
    TotalAmount: 95.99,
    OrderItems: 'Makeup Lancome Rouge',
    Location: 'USA',
    Status: 'canceled',
    StatusBg: '#FF5C8E',
    ProductImage:
      product2,
  },
  {
    OrderID: 944895,
    CustomerName: 'Lulia albu',
    TotalAmount: 17.99,
    OrderItems: 'Skincare',
    Location: 'USA',
    Status: 'active',
    StatusBg: '#03C9D7',
    ProductImage:
      product3,
  },
  {
    OrderID: 845954,
    CustomerName: 'Penjani',
    TotalAmount: 59.99,
    OrderItems: 'Headphone',
    Location: 'USA',
    Status: 'complete',
    StatusBg: '#8BE78B',
    ProductImage:
      product4,
  },
  {
    OrderID: 845954,
    CustomerName: 'Jie Yan',
    TotalAmount: 87.99,
    OrderItems: 'Shoes',
    Location: 'USA',
    Status: 'pending',
    StatusBg: '#FB9678',
    ProductImage:
      'https://cdn.shopclues.com/images1/thumbnails/104158/320/320/148648730-104158193-1592481791.jpg',
  },
  {
    OrderID: 874534,
    CustomerName: 'Danai',
    TotalAmount: 122.99,
    OrderItems: 'Watch',
    Location: 'USA',
    Status: 'canceled',
    StatusBg: '#FF5C8E',
    ProductImage:
      'https://hips.hearstapps.com/hmg-prod.s3.amazonaws.com/images/pop-womens-garmin-watches-1641919013.jpg?crop=0.502xw:1.00xh;0.250xw,0&resize=640:*',
  },
  {
    OrderID: 38489,
    CustomerName: 'Miron',
    TotalAmount: 87.99,
    OrderItems: 'Ice Cream',
    Location: 'USA',
    Status: 'active',
    StatusBg: '#03C9D7',
    ProductImage:
      'https://images.immediate.co.uk/production/volatile/sites/30/2020/08/dairy-free-ice-cream-eae372d.jpg',
  },
  {
    OrderID: 24546,
    CustomerName: 'Frank',
    TotalAmount: 84.99,
    OrderItems: 'Pan Cake',
    Location: 'Delhi',
    Status: 'complete',
    StatusBg: '#8BE78B',
    ProductImage:
      'https://images.unsplash.com/photo-1576618148400-f54bed99fcfd?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1480&q=80',
  },
  {
    OrderID: 874534,
    CustomerName: 'Danai',
    TotalAmount: 122.99,
    OrderItems: 'Watch',
    Location: 'USA',
    Status: 'canceled',
    StatusBg: '#FF5C8E',
    ProductImage:
      'https://hips.hearstapps.com/hmg-prod.s3.amazonaws.com/images/pop-womens-garmin-watches-1641919013.jpg?crop=0.502xw:1.00xh;0.250xw,0&resize=640:*',
  },
  {
    OrderID: 10248,
    CustomerName: 'Vinet',

    TotalAmount: 32.38,
    OrderItems: 'Fresh Tomato',
    Location: 'USA',
    Status: 'pending',
    StatusBg: '#FB9678',
    ProductImage:
      product6,
  },
  {
    OrderID: 345653,
    CustomerName: 'Carson Darrin',
    TotalAmount: 56.34,
    OrderItems: 'Butter Scotch',
    Location: 'Delhi',
    Status: 'complete',
    StatusBg: '#8BE78B',
   
  },
  {
    OrderID: 390457,
    CustomerName: 'Fran Perez',
    TotalAmount: 93.31,
    OrderItems: 'Candy Gucci',
    Location: 'New York',
    Status: 'active',
    StatusBg: '#03C9D7',
    ProductImage:
      product7,
  },
  {
    OrderID: 893486,
    CustomerName: 'Anika Viseer',
    TotalAmount: 93.31,
    OrderItems: 'Night Lamp',
    Location: 'Germany',
    Status: 'canceled',
    StatusBg: '#FF5C8E',
    ProductImage:
      product4,
  },
  {
    OrderID: 748975,
    CustomerName: 'Miron Vitold',
    TotalAmount: 23.99,
    OrderItems: 'Healthcare Erbology',
    Location: 'Spain',
    Status: 'rejected',
    StatusBg: 'red',
    ProductImage:
      product1,
  },
  {
    OrderID: 94757,
    CustomerName: 'Omar Darobe',
    TotalAmount: 95.99,
    OrderItems: 'Makeup Lancome Rouge',
    Location: 'USA',
    Status: 'canceled',
    StatusBg: '#FF5C8E',
    ProductImage:
      product2,
  },
  {
    OrderID: 944895,
    CustomerName: 'Lulia albu',
    TotalAmount: 17.99,
    OrderItems: 'Skincare',
    Location: 'USA',
    Status: 'active',
    StatusBg: '#03C9D7',
    ProductImage:
      product3,
  },
  {
    OrderID: 845954,
    CustomerName: 'Penjani',
    TotalAmount: 59.99,
    OrderItems: 'Headphone',
    Location: 'USA',
    Status: 'complete',
    StatusBg: '#8BE78B',
    ProductImage:
      product4,
  },
  {
    OrderID: 845954,
    CustomerName: 'Jie Yan',
    TotalAmount: 87.99,
    OrderItems: 'Shoes',
    Location: 'USA',
    Status: 'pending',
    StatusBg: '#FB9678',
    ProductImage:
      'https://cdn.shopclues.com/images1/thumbnails/104158/320/320/148648730-104158193-1592481791.jpg',
  },
  {
    OrderID: 874534,
    CustomerName: 'Danai',
    TotalAmount: 122.99,
    OrderItems: 'Watch',
    Location: 'USA',
    Status: 'canceled',
    StatusBg: '#FF5C8E',
    ProductImage:
      'https://hips.hearstapps.com/hmg-prod.s3.amazonaws.com/images/pop-womens-garmin-watches-1641919013.jpg?crop=0.502xw:1.00xh;0.250xw,0&resize=640:*',
  },
  {
    OrderID: 38489,
    CustomerName: 'Miron',
    TotalAmount: 87.99,
    OrderItems: 'Ice Cream',
    Location: 'USA',
    Status: 'active',
    StatusBg: '#03C9D7',
    ProductImage:
      'https://images.immediate.co.uk/production/volatile/sites/30/2020/08/dairy-free-ice-cream-eae372d.jpg',
  },
  {
    OrderID: 24546,
    CustomerName: 'Frank',
    TotalAmount: 84.99,
    OrderItems: 'Pan Cake',
    Location: 'Delhi',
    Status: 'complete',
    StatusBg: '#8BE78B',
    ProductImage:
      'https://images.unsplash.com/photo-1576618148400-f54bed99fcfd?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1480&q=80',
  },
  {
    OrderID: 874534,
    CustomerName: 'Danai',
    TotalAmount: 122.99,
    OrderItems: 'Watch',
    Location: 'USA',
    Status: 'canceled',
    StatusBg: '#FF5C8E',
    ProductImage:
      'https://hips.hearstapps.com/hmg-prod.s3.amazonaws.com/images/pop-womens-garmin-watches-1641919013.jpg?crop=0.502xw:1.00xh;0.250xw,0&resize=640:*',
  },
  {
    OrderID: 10248,
    CustomerName: 'Vinet',

    TotalAmount: 32.38,
    OrderItems: 'Fresh Tomato',
    Location: 'USA',
    Status: 'pending',
    StatusBg: '#FB9678',
    ProductImage:
      product6,
  },
  {
    OrderID: 345653,
    CustomerName: 'Carson Darrin',
    TotalAmount: 56.34,
    OrderItems: 'Butter Scotch',
    Location: 'Delhi',
    Status: 'complete',
    StatusBg: '#8BE78B',
   
  },
  {
    OrderID: 390457,
    CustomerName: 'Fran Perez',
    TotalAmount: 93.31,
    OrderItems: 'Candy Gucci',
    Location: 'New York',
    Status: 'active',
    StatusBg: '#03C9D7',
    ProductImage:
      product7,
  },
  {
    OrderID: 893486,
    CustomerName: 'Anika Viseer',
    TotalAmount: 93.31,
    OrderItems: 'Night Lamp',
    Location: 'Germany',
    Status: 'canceled',
    StatusBg: '#FF5C8E',
    ProductImage:
      product4,
  },
  {
    OrderID: 748975,
    CustomerName: 'Miron Vitold',
    TotalAmount: 23.99,
    OrderItems: 'Healthcare Erbology',
    Location: 'Spain',
    Status: 'rejected',
    StatusBg: 'red',
    ProductImage:
      product1,
  },
  {
    OrderID: 94757,
    CustomerName: 'Omar Darobe',
    TotalAmount: 95.99,
    OrderItems: 'Makeup Lancome Rouge',
    Location: 'USA',
    Status: 'canceled',
    StatusBg: '#FF5C8E',
    ProductImage:
      product2,
  },
  {
    OrderID: 944895,
    CustomerName: 'Lulia albu',
    TotalAmount: 17.99,
    OrderItems: 'Skincare',
    Location: 'USA',
    Status: 'active',
    StatusBg: '#03C9D7',
    ProductImage:
      product3,
  },
  {
    OrderID: 845954,
    CustomerName: 'Penjani',
    TotalAmount: 59.99,
    OrderItems: 'Headphone',
    Location: 'USA',
    Status: 'complete',
    StatusBg: '#8BE78B',
    ProductImage:
      product4,
  },
  {
    OrderID: 845954,
    CustomerName: 'Jie Yan',
    TotalAmount: 87.99,
    OrderItems: 'Shoes',
    Location: 'USA',
    Status: 'pending',
    StatusBg: '#FB9678',
    ProductImage:
      'https://cdn.shopclues.com/images1/thumbnails/104158/320/320/148648730-104158193-1592481791.jpg',
  },
  {
    OrderID: 874534,
    CustomerName: 'Danai',
    TotalAmount: 122.99,
    OrderItems: 'Watch',
    Location: 'USA',
    Status: 'canceled',
    StatusBg: '#FF5C8E',
    ProductImage:
      'https://hips.hearstapps.com/hmg-prod.s3.amazonaws.com/images/pop-womens-garmin-watches-1641919013.jpg?crop=0.502xw:1.00xh;0.250xw,0&resize=640:*',
  },
  {
    OrderID: 38489,
    CustomerName: 'Miron',
    TotalAmount: 87.99,
    OrderItems: 'Ice Cream',
    Location: 'USA',
    Status: 'active',
    StatusBg: '#03C9D7',
    ProductImage:
      'https://images.immediate.co.uk/production/volatile/sites/30/2020/08/dairy-free-ice-cream-eae372d.jpg',
  },
  {
    OrderID: 24546,
    CustomerName: 'Frank',
    TotalAmount: 84.99,
    OrderItems: 'Pan Cake',
    Location: 'Delhi',
    Status: 'complete',
    StatusBg: '#8BE78B',
    ProductImage:
      'https://images.unsplash.com/photo-1576618148400-f54bed99fcfd?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1480&q=80',
  },
  {
    OrderID: 874534,
    CustomerName: 'Danai',
    TotalAmount: 122.99,
    OrderItems: 'Watch',
    Location: 'USA',
    Status: 'canceled',
    StatusBg: '#FF5C8E',
    ProductImage:
      'https://hips.hearstapps.com/hmg-prod.s3.amazonaws.com/images/pop-womens-garmin-watches-1641919013.jpg?crop=0.502xw:1.00xh;0.250xw,0&resize=640:*',
  },
];

export const scheduleData = [
  {
    Id: 1,
    Subject: 'Explosion of Betelgeuse Star',
    Location: 'Space Center USA',
    StartTime: '2021-01-10T04:00:00.000Z',
    EndTime: '2021-01-10T05:30:00.000Z',
    CategoryColor: '#1aaa55',
  },
  {
    Id: 2,
    Subject: 'Thule Air Crash Report',
    Location: 'Newyork City',
    StartTime: '2021-01-11T06:30:00.000Z',
    EndTime: '2021-01-11T08:30:00.000Z',
    CategoryColor: '#357cd2',
  },
  {
    Id: 3,
    Subject: 'Blue Moon Eclipse',
    Location: 'Space Center USA',
    StartTime: '2021-01-12T04:00:00.000Z',
    EndTime: '2021-01-12T05:30:00.000Z',
    CategoryColor: '#7fa900',
  },
  {
    Id: 4,
    Subject: 'Meteor Showers in 2021',
    Location: 'Space Center USA',
    StartTime: '2021-01-13T07:30:00.000Z',
    EndTime: '2021-01-13T09:00:00.000Z',
    CategoryColor: '#ea7a57',
  },
  {
    Id: 5,
    Subject: 'Milky Way as Melting pot',
    Location: 'Space Center USA',
    StartTime: '2021-01-14T06:30:00.000Z',
    EndTime: '2021-01-14T08:30:00.000Z',
    CategoryColor: '#00bdae',
  },
  {
    Id: 6,
    Subject: 'Mysteries of Bermuda Triangle',
    Location: 'Bermuda',
    StartTime: '2021-01-14T04:00:00.000Z',
    EndTime: '2021-01-14T05:30:00.000Z',
    CategoryColor: '#f57f17',
  },
  {
    Id: 7,
    Subject: 'Glaciers and Snowflakes',
    Location: 'Himalayas',
    StartTime: '2021-01-15T05:30:00.000Z',
    EndTime: '2021-01-15T07:00:00.000Z',
    CategoryColor: '#1aaa55',
  },
  {
    Id: 8,
    Subject: 'Life on Mars',
    Location: 'Space Center USA',
    StartTime: '2021-01-16T03:30:00.000Z',
    EndTime: '2021-01-16T04:30:00.000Z',
    CategoryColor: '#357cd2',
  },
  {
    Id: 9,
    Subject: 'Alien Civilization',
    Location: 'Space Center USA',
    StartTime: '2021-01-18T05:30:00.000Z',
    EndTime: '2021-01-18T07:30:00.000Z',
    CategoryColor: '#7fa900',
  },
  {
    Id: 10,
    Subject: 'Wildlife Galleries',
    Location: 'Africa',
    StartTime: '2021-01-20T05:30:00.000Z',
    EndTime: '2021-01-20T07:30:00.000Z',
    CategoryColor: '#ea7a57',
  },
  {
    Id: 11,
    Subject: 'Best Photography 2021',
    Location: 'London',
    StartTime: '2021-01-21T04:00:00.000Z',
    EndTime: '2021-01-21T05:30:00.000Z',
    CategoryColor: '#00bdae',
  },
  {
    Id: 12,
    Subject: 'Smarter Puppies',
    Location: 'Sweden',
    StartTime: '2021-01-08T04:30:00.000Z',
    EndTime: '2021-01-08T06:00:00.000Z',
    CategoryColor: '#f57f17',
  },
  {
    Id: 13,
    Subject: 'Myths of Andromeda Galaxy',
    Location: 'Space Center USA',
    StartTime: '2021-01-06T05:00:00.000Z',
    EndTime: '2021-01-06T07:00:00.000Z',
    CategoryColor: '#1aaa55',
  },
  {
    Id: 14,
    Subject: 'Aliens vs Humans',
    Location: 'Research Center of USA',
    StartTime: '2021-01-05T04:30:00.000Z',
    EndTime: '2021-01-05T06:00:00.000Z',
    CategoryColor: '#357cd2',
  },
  {
    Id: 15,
    Subject: 'Facts of Humming Birds',
    Location: 'California',
    StartTime: '2021-01-19T04:00:00.000Z',
    EndTime: '2021-01-19T05:30:00.000Z',
    CategoryColor: '#7fa900',
  },
  {
    Id: 16,
    Subject: 'Sky Gazers',
    Location: 'Alaska',
    StartTime: '2021-01-22T05:30:00.000Z',
    EndTime: '2021-01-22T07:30:00.000Z',
    CategoryColor: '#ea7a57',
  },
  {
    Id: 17,
    Subject: 'The Cycle of Seasons',
    Location: 'Research Center of USA',
    StartTime: '2021-01-11T00:00:00.000Z',
    EndTime: '2021-01-11T02:00:00.000Z',
    CategoryColor: '#00bdae',
  },
  {
    Id: 18,
    Subject: 'Space Galaxies and Planets',
    Location: 'Space Center USA',
    StartTime: '2021-01-11T11:30:00.000Z',
    EndTime: '2021-01-11T13:00:00.000Z',
    CategoryColor: '#f57f17',
  },
  {
    Id: 19,
    Subject: 'Lifecycle of Bumblebee',
    Location: 'San Fransisco',
    StartTime: '2021-01-14T00:30:00.000Z',
    EndTime: '2021-01-14T02:00:00.000Z',
    CategoryColor: '#7fa900',
  },
  {
    Id: 20,
    Subject: 'Alien Civilization',
    Location: 'Space Center USA',
    StartTime: '2021-01-14T10:30:00.000Z',
    EndTime: '2021-01-14T12:30:00.000Z',
    CategoryColor: '#ea7a57',
  },
  {
    Id: 21,
    Subject: 'Alien Civilization',
    Location: 'Space Center USA',
    StartTime: '2021-01-10T08:30:00.000Z',
    EndTime: '2021-01-10T10:30:00.000Z',
    CategoryColor: '#ea7a57',
  },
  {
    Id: 22,
    Subject: 'The Cycle of Seasons',
    Location: 'Research Center of USA',
    StartTime: '2021-01-12T09:00:00.000Z',
    EndTime: '2021-01-12T10:30:00.000Z',
    CategoryColor: '#00bdae',
  },
  {
    Id: 23,
    Subject: 'Sky Gazers',
    Location: 'Greenland',
    StartTime: '2021-01-15T09:00:00.000Z',
    EndTime: '2021-01-15T10:30:00.000Z',
    CategoryColor: '#ea7a57',
  },
  {
    Id: 24,
    Subject: 'Facts of Humming Birds',
    Location: 'California',
    StartTime: '2021-01-16T07:00:00.000Z',
    EndTime: '2021-01-16T09:00:00.000Z',
    CategoryColor: '#7fa900',
  },
];

export const lineChartData = [
  [
    { x: new Date(2005, 0, 1), y: 21 },
    { x: new Date(2006, 0, 1), y: 24 },
    { x: new Date(2007, 0, 1), y: 36 },
    { x: new Date(2008, 0, 1), y: 38 },
    { x: new Date(2009, 0, 1), y: 54 },
    { x: new Date(2010, 0, 1), y: 57 },
    { x: new Date(2011, 0, 1), y: 70 },
  ],
  [
    { x: new Date(2005, 0, 1), y: 28 },
    { x: new Date(2006, 0, 1), y: 44 },
    { x: new Date(2007, 0, 1), y: 48 },
    { x: new Date(2008, 0, 1), y: 50 },
    { x: new Date(2009, 0, 1), y: 66 },
    { x: new Date(2010, 0, 1), y: 78 },
    { x: new Date(2011, 0, 1), y: 84 },
  ],

  [
    { x: new Date(2005, 0, 1), y: 10 },
    { x: new Date(2006, 0, 1), y: 20 },
    { x: new Date(2007, 0, 1), y: 30 },
    { x: new Date(2008, 0, 1), y: 39 },
    { x: new Date(2009, 0, 1), y: 50 },
    { x: new Date(2010, 0, 1), y: 70 },
    { x: new Date(2011, 0, 1), y: 100 },
  ],
];
export const dropdownData = [
  {
    Id: '1',
    Time: 'March 2021',
  },
  {
    Id: '2',
    Time: 'April 2021',
  }, {
    Id: '3',
    Time: 'May 2021',
  },
];
export const SparklineAreaData = [
  { x: 1, yval: 2 },
  { x: 2, yval: 6 },
  { x: 3, yval: 8 },
  { x: 4, yval: 5 },
  { x: 5, yval: 10 },

];

export const lineCustomSeries = [
  { dataSource: lineChartData[0],
    xName: 'x',
    yName: 'y',
    name: 'Germany',
    width: '2',
    marker: { visible: true, width: 10, height: 10 },
    type: 'Line' },

  { dataSource: lineChartData[1],
    xName: 'x',
    yName: 'y',
    name: 'England',
    width: '2',
    marker: { visible: true, width: 10, height: 10 },
    type: 'Line' },

  { dataSource: lineChartData[2],
    xName: 'x',
    yName: 'y',
    name: 'India',
    width: '2',
    marker: { visible: true, width: 10, height: 10 },
    type: 'Line' },

];

export const pieChartData = [
  { x: 'Labour', y: 18, text: '18%' },
  { x: 'Legal', y: 8, text: '8%' },
  { x: 'Production', y: 15, text: '15%' },
  { x: 'License', y: 11, text: '11%' },
  { x: 'Facilities', y: 18, text: '18%' },
  { x: 'Taxes', y: 14, text: '14%' },
  { x: 'Insurance', y: 16, text: '16%' },
];

export const contextMenuItems = [
  'AutoFit',
  'AutoFitAll',
  'SortAscending',
  'SortDescending',
  'Copy',
  'Edit',
  'Delete',
  'Save',
  'Cancel',
  'PdfExport',
  'ExcelExport',
  'CsvExport',
  'FirstPage',
  'PrevPage',
  'LastPage',
  'NextPage',
];

export const ecomPieChartData = [
  { x: '2018', y: 18, text: '35%' },
  { x: '2019', y: 18, text: '15%' },
  { x: '2020', y: 18, text: '25%' },
  { x: '2021', y: 18, text: '25%' },
];

export const stackedChartData = [
  [
    { x: 'Jan', y: 111.1 },
    { x: 'Feb', y: 127.3 },
    { x: 'Mar', y: 143.4 },
    { x: 'Apr', y: 159.9 },
    { x: 'May', y: 159.9 },
    { x: 'Jun', y: 159.9 },
    { x: 'July', y: 159.9 },
  ],
  [
    { x: 'Jan', y: 111.1 },
    { x: 'Feb', y: 127.3 },
    { x: 'Mar', y: 143.4 },
    { x: 'Apr', y: 159.9 },
    { x: 'May', y: 159.9 },
    { x: 'Jun', y: 159.9 },
    { x: 'July', y: 159.9 },
  ],
];

export const stackedCustomSeries = [

  { dataSource: stackedChartData[0],
    xName: 'x',
    yName: 'y',
    name: 'Budget',
    type: 'StackingColumn',
    background: 'blue',

  },

  { dataSource: stackedChartData[1],
    xName: 'x',
    yName: 'y',
    name: 'Expense',
    type: 'StackingColumn',
    background: 'red',

  },

];

export const stackedPrimaryXAxis = {
  majorGridLines: { width: 0 },
  minorGridLines: { width: 0 },
  majorTickLines: { width: 0 },
  minorTickLines: { width: 0 },
  interval: 1,
  lineStyle: { width: 0 },
  labelIntersectAction: 'Rotate45',
  valueType: 'Category',
};

export const stackedPrimaryYAxis = {
  lineStyle: { width: 0 },
  minimum: 100,
  maximum: 400,
  interval: 100,
  majorTickLines: { width: 0 },
  majorGridLines: { width: 1 },
  minorGridLines: { width: 1 },
  minorTickLines: { width: 0 },
  labelFormat: '{value}',
};

export const kanbanData = [
  {
    Id: 'Task 1',
    Title: 'Task - 29001',
    Status: 'Open',
    Summary: 'Analyze the new requirements gathered from the customer.',
    Type: 'Story',
    Priority: 'Low',
    Tags: 'Analyze,Customer',
    Estimate: 3.5,
    Assignee: 'Nancy Davloio',
    RankId: 1,
    Color: '#02897B',
    ClassName: 'e-story, e-low, e-nancy-davloio',
  },
  {
    Id: 'Task 2',
    Title: 'Task - 29002',
    Status: 'InProgress',
    Summary: 'Improve application performance',
    Type: 'Improvement',
    Priority: 'Normal',
    Tags: 'Improvement',
    Estimate: 6,
    Assignee: 'Andrew Fuller',
    RankId: 1,
    Color: '#673AB8',
    ClassName: 'e-improvement, e-normal, e-andrew-fuller',
  },
  {
    Id: 'Task 3',
    Title: 'Task - 29003',
    Status: 'Open',
    Summary: 'Arrange a web meeting with the customer to get new requirements.',
    Type: 'Others',
    Priority: 'Critical',
    Tags: 'Meeting',
    Estimate: 5.5,
    Assignee: 'Janet Leverling',
    RankId: 2,
    Color: '#1F88E5',
    ClassName: 'e-others, e-critical, e-janet-leverling',
  },
  {
    Id: 'Task 4',
    Title: 'Task - 29004',
    Status: 'InProgress',
    Summary: 'Fix the issues reported in the IE browser.',
    Type: 'Bug',
    Priority: 'Critical',
    Tags: 'IE',
    Estimate: 2.5,
    Assignee: 'Janet Leverling',
    RankId: 2,
    Color: '#E64A19',
    ClassName: 'e-bug, e-release, e-janet-leverling',
  },
  {
    Id: 'Task 5',
    Title: 'Task - 29005',
    Status: 'Review',
    Summary: 'Fix the issues reported by the customer.',
    Type: 'Bug',
    Priority: 'Low',
    Tags: 'Customer',
    Estimate: '3.5',
    Assignee: 'Steven walker',
    RankId: 1,
    Color: '#E64A19',
    ClassName: 'e-bug, e-low, e-steven-walker',
  },
  {
    Id: 'Task 6',
    Title: 'Task - 29007',
    Status: 'Validate',
    Summary: 'Validate new requirements',
    Type: 'Improvement',
    Priority: 'Low',
    Tags: 'Validation',
    Estimate: 1.5,
    Assignee: 'Robert King',
    RankId: 1,
    Color: '#673AB8',
    ClassName: 'e-improvement, e-low, e-robert-king',
  },
  {
    Id: 'Task 7',
    Title: 'Task - 29009',
    Status: 'Review',
    Summary: 'Fix the issues reported in Safari browser.',
    Type: 'Bug',
    Priority: 'Critical',
    Tags: 'Fix,Safari',
    Estimate: 1.5,
    Assignee: 'Nancy Davloio',
    RankId: 2,
    Color: '#E64A19',
    ClassName: 'e-bug, e-release, e-nancy-davloio',
  },
  {
    Id: 'Task 8',
    Title: 'Task - 29010',
    Status: 'Close',
    Summary: 'Test the application in the IE browser.',
    Type: 'Story',
    Priority: 'Low',
    Tags: 'Review,IE',
    Estimate: 5.5,
    Assignee: 'Margaret hamilt',
    RankId: 3,
    Color: '#02897B',
    ClassName: 'e-story, e-low, e-margaret-hamilt',
  },
  {
    Id: 'Task 9',
    Title: 'Task - 29011',
    Status: 'Validate',
    Summary: 'Validate the issues reported by the customer.',
    Type: 'Story',
    Priority: 'High',
    Tags: 'Validation,Fix',
    Estimate: 1,
    Assignee: 'Steven walker',
    RankId: 1,
    Color: '#02897B',
    ClassName: 'e-story, e-high, e-steven-walker',
  },
  {
    Id: 'Task 10',
    Title: 'Task - 29015',
    Status: 'Open',
    Summary: 'Show the retrieved data from the server in grid control.',
    Type: 'Story',
    Priority: 'High',
    Tags: 'Database,SQL',
    Estimate: 5.5,
    Assignee: 'Margaret hamilt',
    RankId: 4,
    Color: '#02897B',
    ClassName: 'e-story, e-high, e-margaret-hamilt',
  },
  {
    Id: 'Task 11',
    Title: 'Task - 29016',
    Status: 'InProgress',
    Summary: 'Fix cannot open user’s default database SQL error.',
    Priority: 'Critical',
    Type: 'Bug',
    Tags: 'Database,Sql2008',
    Estimate: 2.5,
    Assignee: 'Janet Leverling',
    RankId: 4,
    Color: '#E64A19',
    ClassName: 'e-bug, e-critical, e-janet-leverling',
  },
  {
    Id: 'Task 12',
    Title: 'Task - 29017',
    Status: 'Review',
    Summary: 'Fix the issues reported in data binding.',
    Type: 'Story',
    Priority: 'Normal',
    Tags: 'Databinding',
    Estimate: '3.5',
    Assignee: 'Janet Leverling',
    RankId: 4,
    Color: '#02897B',
    ClassName: 'e-story, e-normal, e-janet-leverling',
  },
  {
    Id: 'Task 13',
    Title: 'Task - 29018',
    Status: 'Close',
    Summary: 'Analyze SQL server 2008 connection.',
    Type: 'Story',
    Priority: 'Critical',
    Tags: 'Grid,Sql',
    Estimate: 2,
    Assignee: 'Andrew Fuller',
    RankId: 4,
    Color: '#02897B',
    ClassName: 'e-story, e-release, e-andrew-fuller',
  },
  {
    Id: 'Task 14',
    Title: 'Task - 29019',
    Status: 'Validate',
    Summary: 'Validate databinding issues.',
    Type: 'Story',
    Priority: 'Low',
    Tags: 'Validation',
    Estimate: 1.5,
    Assignee: 'Margaret hamilt',
    RankId: 1,
    Color: '#02897B',
    ClassName: 'e-story, e-low, e-margaret-hamilt',
  },
  {
    Id: 'Task 15',
    Title: 'Task - 29020',
    Status: 'Close',
    Summary: 'Analyze grid control.',
    Type: 'Story',
    Priority: 'High',
    Tags: 'Analyze',
    Estimate: 2.5,
    Assignee: 'Margaret hamilt',
    RankId: 5,
    Color: '#02897B',
    ClassName: 'e-story, e-high, e-margaret-hamilt',
  },
  {
    Id: 'Task 16',
    Title: 'Task - 29021',
    Status: 'Close',
    Summary: 'Stored procedure for initial data binding of the grid.',
    Type: 'Others',
    Priority: 'Critical',
    Tags: 'Databinding',
    Estimate: 1.5,
    Assignee: 'Steven walker',
    RankId: 6,
    Color: '#1F88E5',
    ClassName: 'e-others, e-release, e-steven-walker',
  },
  {
    Id: 'Task 17',
    Title: 'Task - 29022',
    Status: 'Close',
    Summary: 'Analyze stored procedures.',
    Type: 'Story',
    Priority: 'Critical',
    Tags: 'Procedures',
    Estimate: 5.5,
    Assignee: 'Janet Leverling',
    RankId: 7,
    Color: '#02897B',
    ClassName: 'e-story, e-release, e-janet-leverling',
  },
  {
    Id: 'Task 18',
    Title: 'Task - 29023',
    Status: 'Validate',
    Summary: 'Validate editing issues.',
    Type: 'Story',
    Priority: 'Critical',
    Tags: 'Editing',
    Estimate: 1,
    Assignee: 'Nancy Davloio',
    RankId: 1,
    Color: '#02897B',
    ClassName: 'e-story, e-critical, e-nancy-davloio',
  },
  {
    Id: 'Task 19',
    Title: 'Task - 29024',
    Status: 'Review',
    Summary: 'Test editing functionality.',
    Type: 'Story',
    Priority: 'Normal',
    Tags: 'Editing,Test',
    Estimate: 0.5,
    Assignee: 'Nancy Davloio',
    RankId: 5,
    Color: '#02897B',
    ClassName: 'e-story, e-normal, e-nancy-davloio',
  },
  {
    Id: 'Task 20',
    Title: 'Task - 29025',
    Status: 'Open',
    Summary: 'Enhance editing functionality.',
    Type: 'Improvement',
    Priority: 'Low',
    Tags: 'Editing',
    Estimate: 3.5,
    Assignee: 'Andrew Fuller',
    RankId: 5,
    Color: '#673AB8',
    ClassName: 'e-improvement, e-low, e-andrew-fuller',
  },
  {
    Id: 'Task 21',
    Title: 'Task - 29026',
    Status: 'InProgress',
    Summary: 'Improve the performance of the editing functionality.',
    Type: 'Epic',
    Priority: 'High',
    Tags: 'Performance',
    Estimate: 6,
    Assignee: 'Nancy Davloio',
    RankId: 5,
    Color: '#e91e64',
    ClassName: 'e-epic, e-high, e-nancy-davloio',
  },
  {
    Id: 'Task 22',
    Title: 'Task - 29027',
    Status: 'Open',
    Summary: 'Arrange web meeting with the customer to show editing demo.',
    Type: 'Others',
    Priority: 'High',
    Tags: 'Meeting,Editing',
    Estimate: 5.5,
    Assignee: 'Steven walker',
    RankId: 6,
    Color: '#1F88E5',
    ClassName: 'e-others, e-high, e-steven-walker',
  },
  {
    Id: 'Task 23',
    Title: 'Task - 29029',
    Status: 'Review',
    Summary: 'Fix the editing issues reported by the customer.',
    Type: 'Bug',
    Priority: 'Low',
    Tags: 'Editing,Fix',
    Estimate: '3.5',
    Assignee: 'Janet Leverling',
    RankId: 6,
    Color: '#E64A19',
    ClassName: 'e-bug, e-low, e-janet-leverling',
  },
  {
    Id: 'Task 24',
    Title: 'Task - 29030',
    Status: 'Testing',
    Summary: 'Fix the issues reported by the customer.',
    Type: 'Bug',
    Priority: 'Critical',
    Tags: 'Customer',
    Estimate: '3.5',
    Assignee: 'Steven walker',
    RankId: 1,
    Color: '#E64A19',
    ClassName: 'e-bug, e-critical, e-steven-walker',
  },
  {
    Id: 'Task 25',
    Title: 'Task - 29031',
    Status: 'Testing',
    Summary: 'Fix the issues reported in Safari browser.',
    Type: 'Bug',
    Priority: 'Critical',
    Tags: 'Fix,Safari',
    Estimate: 1.5,
    Assignee: 'Nancy Davloio',
    RankId: 2,
    Color: '#E64A19',
    ClassName: 'e-bug, e-release, e-nancy-davloio',
  },
];

export const financialChartData = [
  {
    x: new Date('2012-04-02'),
    open: 85.9757,
    high: 90.6657,
    low: 85.7685,
    close: 90.5257,
    volume: 660187068,
  },
  {
    x: new Date('2012-04-09'),
    open: 89.4471,
    high: 92,
    low: 86.2157,
    close: 86.4614,
    volume: 912634864,
  },
  {
    x: new Date('2012-04-16'),
    open: 87.1514,
    high: 88.6071,
    low: 81.4885,
    close: 81.8543,
    volume: 1221746066,
  },
  {
    x: new Date('2012-04-23'),
    open: 81.5157,
    high: 88.2857,
    low: 79.2857,
    close: 86.1428,
    volume: 965935749,
  },
  {
    x: new Date('2012-04-30'),
    open: 85.4,
    high: 85.4857,
    low: 80.7385,
    close: 80.75,
    volume: 615249365,
  },
  {
    x: new Date('2012-05-07'),
    open: 80.2143,
    high: 82.2685,
    low: 79.8185,
    close: 80.9585,
    volume: 541742692,
  },
  {
    x: new Date('2012-05-14'),
    open: 80.3671,
    high: 81.0728,
    low: 74.5971,
    close: 75.7685,
    volume: 708126233,
  },
  {
    x: new Date('2012-05-21'),
    open: 76.3571,
    high: 82.3571,
    low: 76.2928,
    close: 80.3271,
    volume: 682076215,
  },
  {
    x: new Date('2012-05-28'),
    open: 81.5571,
    high: 83.0714,
    low: 80.0743,
    close: 80.1414,
    volume: 480059584,
  },
  {
    x: new Date('2012-06-04'),
    open: 80.2143,
    high: 82.9405,
    low: 78.3571,
    close: 82.9028,
    volume: 517577005,
  },
  {
    x: new Date('2012-06-11'),
    open: 83.96,
    high: 84.0714,
    low: 80.9571,
    close: 82.0185,
    volume: 499693120,
  },
  {
    x: new Date('2012-06-18'),
    open: 81.5657,
    high: 84.2857,
    low: 81.4814,
    close: 83.1571,
    volume: 442172142,
  },
  {
    x: new Date('2012-06-25'),
    open: 82.4714,
    high: 83.4285,
    low: 80.8014,
    close: 83.4285,
    volume: 371529102,
  },
  {
    x: new Date('2012-07-02'),
    open: 83.5328,
    high: 87.7628,
    low: 83.3714,
    close: 86.5543,
    volume: 385906790,
  },
  {
    x: new Date('2012-07-09'),
    open: 86.4714,
    high: 88.5528,
    low: 84.6685,
    close: 86.4243,
    volume: 524235196,
  },
  {
    x: new Date('2012-07-16'),
    open: 86.4457,
    high: 87.9071,
    low: 86.1643,
    close: 86.3285,
    volume: 419537217,
  },
  {
    x: new Date('2012-07-23'),
    open: 84.9143,
    high: 87.0971,
    low: 81.4285,
    close: 83.5943,
    volume: 680773023,
  },
  {
    x: new Date('2012-07-30'),
    open: 84.4171,
    high: 88.2828,
    low: 83.9743,
    close: 87.9571,
    volume: 475109323,
  },
  {
    x: new Date('2012-08-06'),
    open: 88.1843,
    high: 89.2857,
    low: 87.8943,
    close: 88.8143,
    volume: 312826308,
  },
  {
    x: new Date('2012-08-13'),
    open: 89.0557,
    high: 92.5985,
    low: 89.0357,
    close: 92.5871,
    volume: 392867193,
  },
  {
    x: new Date('2012-08-20'),
    open: 92.8585,
    high: 96.4114,
    low: 92.5871,
    close: 94.746,
    volume: 708614692,
  },
  {
    x: new Date('2012-08-27'),
    open: 97.1414,
    high: 97.2671,
    low: 93.8928,
    close: 95.0343,
    volume: 383807217,
  },
  {
    x: new Date('2012-09-03'),
    open: 95.1085,
    high: 97.4971,
    low: 94.9285,
    close: 97.2057,
    volume: 355722047,
  },
  {
    x: new Date('2012-09-10'),
    open: 97.2071,
    high: 99.5685,
    low: 93.7143,
    close: 98.7543,
    volume: 724042207,
  },
  {
    x: new Date('2012-09-17'),
    open: 99.9071,
    high: 100.7243,
    low: 99.0885,
    close: 100.0135,
    volume: 500166040,
  },
  {
    x: new Date('2012-09-24'),
    open: 98.1228,
    high: 99.3028,
    low: 94.3357,
    close: 95.3007,
    volume: 714507994,
  },
  {
    x: new Date('2012-10-01'),
    open: 95.88,
    high: 96.6785,
    low: 92.95,
    close: 93.2271,
    volume: 638543622,
  },
  {
    x: new Date('2012-10-08'),
    open: 92.4114,
    high: 92.5085,
    low: 89.0785,
    close: 89.9591,
    volume: 747127724,
  },
  {
    x: new Date('2012-10-15'),
    open: 90.3357,
    high: 93.2557,
    low: 87.0885,
    close: 87.12,
    volume: 646996264,
  },
  {
    x: new Date('2012-10-22'),
    open: 87.4885,
    high: 90.7685,
    low: 84.4285,
    close: 86.2857,
    volume: 866040680,
  },
  {
    x: new Date('2012-10-29'),
    open: 84.9828,
    high: 86.1428,
    low: 82.1071,
    close: 82.4,
    volume: 367371310,
  },
  {
    x: new Date('2012-11-05'),
    open: 83.3593,
    high: 84.3914,
    low: 76.2457,
    close: 78.1514,
    volume: 919719846,
  },
  {
    x: new Date('2012-11-12'),
    open: 79.1643,
    high: 79.2143,
    low: 72.25,
    close: 75.3825,
    volume: 894382149,
  },
  {
    x: new Date('2012-11-19'),
    open: 77.2443,
    high: 81.7143,
    low: 77.1257,
    close: 81.6428,
    volume: 527416747,
  },
  {
    x: new Date('2012-11-26'),
    open: 82.2714,
    high: 84.8928,
    low: 81.7514,
    close: 83.6114,
    volume: 646467974,
  },
  {
    x: new Date('2012-12-03'),
    open: 84.8071,
    high: 84.9414,
    low: 74.09,
    close: 76.1785,
    volume: 980096264,
  },
  {
    x: new Date('2012-12-10'),
    open: 75,
    high: 78.5085,
    low: 72.2257,
    close: 72.8277,
    volume: 835016110,
  },
  {
    x: new Date('2012-12-17'),
    open: 72.7043,
    high: 76.4143,
    low: 71.6043,
    close: 74.19,
    volume: 726150329,
  },
  {
    x: new Date('2012-12-24'),
    open: 74.3357,
    high: 74.8928,
    low: 72.0943,
    close: 72.7984,
    volume: 321104733,
  },
  {
    x: new Date('2012-12-31'),
    open: 72.9328,
    high: 79.2857,
    low: 72.7143,
    close: 75.2857,
    volume: 540854882,
  },
  {
    x: new Date('2013-01-07'),
    open: 74.5714,
    high: 75.9843,
    low: 73.6,
    close: 74.3285,
    volume: 574594262,
  },
  {
    x: new Date('2013-01-14'),
    open: 71.8114,
    high: 72.9643,
    low: 69.0543,
    close: 71.4285,
    volume: 803105621,
  },
  {
    x: new Date('2013-01-21'),
    open: 72.08,
    high: 73.57,
    low: 62.1428,
    close: 62.84,
    volume: 971912560,
  },
  {
    x: new Date('2013-01-28'),
    open: 62.5464,
    high: 66.0857,
    low: 62.2657,
    close: 64.8028,
    volume: 656549587,
  },
  {
    x: new Date('2013-02-04'),
    open: 64.8443,
    high: 68.4014,
    low: 63.1428,
    close: 67.8543,
    volume: 743778993,
  },
  {
    x: new Date('2013-02-11'),
    open: 68.0714,
    high: 69.2771,
    low: 65.7028,
    close: 65.7371,
    volume: 585292366,
  },
  {
    x: new Date('2013-02-18'),
    open: 65.8714,
    high: 66.1043,
    low: 63.26,
    close: 64.4014,
    volume: 421766997,
  },
  {
    x: new Date('2013-02-25'),
    open: 64.8357,
    high: 65.0171,
    low: 61.4257,
    close: 61.4957,
    volume: 582741215,
  },
  {
    x: new Date('2013-03-04'),
    open: 61.1143,
    high: 62.2043,
    low: 59.8571,
    close: 61.6743,
    volume: 632856539,
  },
  {
    x: new Date('2013-03-11'),
    open: 61.3928,
    high: 63.4614,
    low: 60.7343,
    close: 63.38,
    volume: 572066981,
  },
  {
    x: new Date('2013-03-18'),
    open: 63.0643,
    high: 66.0143,
    low: 63.0286,
    close: 65.9871,
    volume: 552156035,
  },
  {
    x: new Date('2013-03-25'),
    open: 66.3843,
    high: 67.1357,
    low: 63.0886,
    close: 63.2371,
    volume: 390762517,
  },
  {
    x: new Date('2013-04-01'),
    open: 63.1286,
    high: 63.3854,
    low: 59.9543,
    close: 60.4571,
    volume: 505273732,
  },
  {
    x: new Date('2013-04-08'),
    open: 60.6928,
    high: 62.57,
    low: 60.3557,
    close: 61.4,
    volume: 387323550,
  },
  {
    x: new Date('2013-04-15'),
    open: 61,
    high: 61.1271,
    low: 55.0143,
    close: 55.79,
    volume: 709945604,
  },
  {
    x: new Date('2013-04-22'),
    open: 56.0914,
    high: 59.8241,
    low: 55.8964,
    close: 59.6007,
    volume: 787007506,
  },
  {
    x: new Date('2013-04-29'),
    open: 60.0643,
    high: 64.7471,
    low: 60,
    close: 64.2828,
    volume: 655020017,
  },
  {
    x: new Date('2013-05-06'),
    open: 65.1014,
    high: 66.5357,
    low: 64.3543,
    close: 64.71,
    volume: 545488533,
  },
  {
    x: new Date('2013-05-13'),
    open: 64.5014,
    high: 65.4143,
    low: 59.8428,
    close: 61.8943,
    volume: 633706550,
  },
  {
    x: new Date('2013-05-20'),
    open: 61.7014,
    high: 64.05,
    low: 61.4428,
    close: 63.5928,
    volume: 494379068,
  },
  {
    x: new Date('2013-05-27'),
    open: 64.2714,
    high: 65.3,
    low: 62.7714,
    close: 64.2478,
    volume: 362907830,
  },
  {
    x: new Date('2013-06-03'),
    open: 64.39,
    high: 64.9186,
    low: 61.8243,
    close: 63.1158,
    volume: 443249793,
  },
  {
    x: new Date('2013-06-10'),
    open: 63.5328,
    high: 64.1541,
    low: 61.2143,
    close: 61.4357,
    volume: 389680092,
  },
  {
    x: new Date('2013-06-17'),
    open: 61.6343,
    high: 62.2428,
    low: 58.3,
    close: 59.0714,
    volume: 400384818,
  },
  {
    x: new Date('2013-06-24'),
    open: 58.2,
    high: 58.38,
    low: 55.5528,
    close: 56.6471,
    volume: 519314826,
  },
  {
    x: new Date('2013-07-01'),
    open: 57.5271,
    high: 60.47,
    low: 57.3171,
    close: 59.6314,
    volume: 343878841,
  },
  {
    x: new Date('2013-07-08'),
    open: 60.0157,
    high: 61.3986,
    low: 58.6257,
    close: 60.93,
    volume: 384106977,
  },
  {
    x: new Date('2013-07-15'),
    open: 60.7157,
    high: 62.1243,
    low: 60.5957,
    close: 60.7071,
    volume: 286035513,
  },
  {
    x: new Date('2013-07-22'),
    open: 61.3514,
    high: 63.5128,
    low: 59.8157,
    close: 62.9986,
    volume: 395816827,
  },
  {
    x: new Date('2013-07-29'),
    open: 62.9714,
    high: 66.1214,
    low: 62.8857,
    close: 66.0771,
    volume: 339668858,
  },
  {
    x: new Date('2013-08-05'),
    open: 66.3843,
    high: 67.4128,
    low: 64.8071,
    close: 64.9214,
    volume: 368486781,
  },
  {
    x: new Date('2013-08-12'),
    open: 65.2657,
    high: 72.0357,
    low: 65.2328,
    close: 71.7614,
    volume: 711563584,
  },
  {
    x: new Date('2013-08-19'),
    open: 72.0485,
    high: 73.3914,
    low: 71.1714,
    close: 71.5743,
    volume: 417119660,
  },
  {
    x: new Date('2013-08-26'),
    open: 71.5357,
    high: 72.8857,
    low: 69.4286,
    close: 69.6023,
    volume: 392805888,
  },
  {
    x: new Date('2013-09-02'),
    open: 70.4428,
    high: 71.7485,
    low: 69.6214,
    close: 71.1743,
    volume: 317244380,
  },
  {
    x: new Date('2013-09-09'),
    open: 72.1428,
    high: 72.56,
    low: 66.3857,
    close: 66.4143,
    volume: 669376320,
  },
  {
    x: new Date('2013-09-16'),
    open: 65.8571,
    high: 68.3643,
    low: 63.8886,
    close: 66.7728,
    volume: 625142677,
  },
  {
    x: new Date('2013-09-23'),
    open: 70.8714,
    high: 70.9871,
    low: 68.6743,
    close: 68.9643,
    volume: 475274537,
  },
  {
    x: new Date('2013-09-30'),
    open: 68.1786,
    high: 70.3357,
    low: 67.773,
    close: 69.0043,
    volume: 368198906,
  },
  {
    x: new Date('2013-10-07'),
    open: 69.5086,
    high: 70.5486,
    low: 68.3257,
    close: 70.4017,
    volume: 361437661,
  },
  {
    x: new Date('2013-10-14'),
    open: 69.9757,
    high: 72.7514,
    low: 69.9071,
    close: 72.6985,
    volume: 342694379,
  },
  {
    x: new Date('2013-10-21'),
    open: 73.11,
    high: 76.1757,
    low: 72.5757,
    close: 75.1368,
    volume: 490458997,
  },
  {
    x: new Date('2013-10-28'),
    open: 75.5771,
    high: 77.0357,
    low: 73.5057,
    close: 74.29,
    volume: 508130174,
  },
  {
    x: new Date('2013-11-04'),
    open: 74.4428,
    high: 75.555,
    low: 73.1971,
    close: 74.3657,
    volume: 318132218,
  },
  {
    x: new Date('2013-11-11'),
    open: 74.2843,
    high: 75.6114,
    low: 73.4871,
    close: 74.9987,
    volume: 306711021,
  },
  {
    x: new Date('2013-11-18'),
    open: 74.9985,
    high: 75.3128,
    low: 73.3814,
    close: 74.2571,
    volume: 282778778,
  },
  {
    x: new Date('2013-11-25'),
    open: 74.4314,
    high: 79.7614,
    low: 74.4285,
    close: 79.4385,
    volume: 327405302,
  },
  {
    x: new Date('2013-12-02'),
    open: 79.7143,
    high: 82.1622,
    low: 78.6885,
    close: 80.0028,
    volume: 522055676,
  },
  {
    x: new Date('2013-12-09'),
    open: 80.1286,
    high: 81.5671,
    low: 79.0957,
    close: 79.2043,
    volume: 387271099,
  },
  {
    x: new Date('2013-12-16'),
    open: 79.2885,
    high: 80.377,
    low: 76.9714,
    close: 78.4314,
    volume: 457580848,
  },
  {
    x: new Date('2013-12-23'),
    open: 81.1428,
    high: 81.6971,
    low: 79.9285,
    close: 80.0128,
    volume: 274253503,
  },
  {
    x: new Date('2013-12-30'),
    open: 79.6371,
    high: 80.1828,
    low: 77.2043,
    close: 77.2828,
    volume: 275734934,
  },
  {
    x: new Date('2014-01-06'),
    open: 76.7785,
    high: 78.1228,
    low: 75.8728,
    close: 76.1343,
    volume: 393462075,
  },
  {
    x: new Date('2014-01-13'),
    open: 75.7014,
    high: 80.0285,
    low: 75.6971,
    close: 77.2385,
    volume: 439557459,
  },
  {
    x: new Date('2014-01-20'),
    open: 77.2843,
    high: 79.6128,
    low: 77.2028,
    close: 78.01,
    volume: 385585525,
  },
  {
    x: new Date('2014-01-27'),
    open: 78.5814,
    high: 79.2571,
    low: 70.5071,
    close: 71.5143,
    volume: 813702575,
  },
  {
    x: new Date('2014-02-03'),
    open: 71.8014,
    high: 74.7042,
    low: 71.3286,
    close: 74.24,
    volume: 434447570,
  },
  {
    x: new Date('2014-02-10'),
    open: 74.0943,
    high: 77.9971,
    low: 74,
    close: 77.7128,
    volume: 379011880,
  },
  {
    x: new Date('2014-02-17'),
    open: 78,
    high: 78.7414,
    low: 74.9428,
    close: 75.0357,
    volume: 289609443,
  },
  {
    x: new Date('2014-02-24'),
    open: 74.7357,
    high: 76.1071,
    low: 73.6571,
    close: 75.1771,
    volume: 367569649,
  },
  {
    x: new Date('2014-03-03'),
    open: 74.7743,
    high: 76.3928,
    low: 74.6871,
    close: 75.7771,
    volume: 275972640,
  },
  {
    x: new Date('2014-03-10'),
    open: 75.48,
    high: 77.0943,
    low: 74.7143,
    close: 74.9557,
    volume: 287729528,
  },
  {
    x: new Date('2014-03-17'),
    open: 75.3857,
    high: 76.6057,
    low: 75.0286,
    close: 76.1243,
    volume: 303531061,
  },
  {
    x: new Date('2014-03-24'),
    open: 76.9171,
    high: 78.4285,
    low: 76.3214,
    close: 76.6943,
    volume: 338387221,
  },
  {
    x: new Date('2014-03-31'),
    open: 77.0328,
    high: 77.64,
    low: 75.7971,
    close: 75.9743,
    volume: 245749459,
  },
  {
    x: new Date('2014-04-07'),
    open: 75.4314,
    high: 76.0343,
    low: 73.8771,
    close: 74.23,
    volume: 312008139,
  },
  {
    x: new Date('2014-04-14'),
    open: 74.5571,
    high: 75.3943,
    low: 73.0471,
    close: 74.9914,
    volume: 241209047,
  },
  {
    x: new Date('2014-04-21'),
    open: 75.0485,
    high: 81.7128,
    low: 74.8514,
    close: 81.7057,
    volume: 476651465,
  },
  {
    x: new Date('2014-04-28'),
    open: 81.8285,
    high: 85.6328,
    low: 81.7928,
    close: 84.6543,
    volume: 473712709,
  },
  {
    x: new Date('2014-05-05'),
    open: 84.3057,
    high: 86.3442,
    low: 82.9043,
    close: 83.6489,
    volume: 365535013,
  },
  {
    x: new Date('2014-05-12'),
    open: 83.9271,
    high: 85.3614,
    low: 83.6285,
    close: 85.3585,
    volume: 260931637,
  },
  {
    x: new Date('2014-05-19'),
    open: 85.4071,
    high: 87.8184,
    low: 85.3328,
    close: 87.7328,
    volume: 294298274,
  },
  {
    x: new Date('2014-05-26'),
    open: 87.9828,
    high: 92.0243,
    low: 87.9471,
    close: 90.4285,
    volume: 400232120,
  },
  {
    x: new Date('2014-06-02'),
    open: 90.5657,
    high: 93.0371,
    low: 88.9285,
    close: 92.2243,
    volume: 412158024,
  },
  {
    x: new Date('2014-06-09'),
    open: 92.7,
    high: 95.05,
    low: 90.88,
    close: 91.28,
    volume: 292422550,
  },
  {
    x: new Date('2014-06-16'),
    open: 91.51,
    high: 92.75,
    low: 90.9,
    close: 90.91,
    volume: 234325480,
  },
  {
    x: new Date('2014-06-23'),
    open: 91.32,
    high: 92,
    low: 89.65,
    close: 91.98,
    volume: 213712160,
  },
  {
    x: new Date('2014-06-30'),
    open: 92.1,
    high: 94.1,
    low: 92.09,
    close: 94.03,
    volume: 138936570,
  },
  {
    x: new Date('2014-07-07'),
    open: 94.14,
    high: 96.8,
    low: 93.52,
    close: 95.22,
    volume: 229782440,
  },
  {
    x: new Date('2014-07-14'),
    open: 95.86,
    high: 97.1,
    low: 92.57,
    close: 94.43,
    volume: 248104390,
  },
  {
    x: new Date('2014-07-21'),
    open: 94.99,
    high: 97.88,
    low: 93.72,
    close: 97.671,
    volume: 273021350,
  },
  {
    x: new Date('2014-07-28'),
    open: 97.82,
    high: 99.44,
    low: 94.81,
    close: 96.13,
    volume: 235868530,
  },
  {
    x: new Date('2014-08-04'),
    open: 96.37,
    high: 96.58,
    low: 93.28,
    close: 94.74,
    volume: 222522120,
  },
  {
    x: new Date('2014-08-11'),
    open: 95.27,
    high: 98.19,
    low: 94.8355,
    close: 97.98,
    volume: 178806580,
  },
  {
    x: new Date('2014-08-18'),
    open: 98.49,
    high: 101.47,
    low: 97.98,
    close: 101.32,
    volume: 246446280,
  },
  {
    x: new Date('2014-08-25'),
    open: 101.79,
    high: 102.9,
    low: 100.7,
    close: 102.5,
    volume: 232858250,
  },
  {
    x: new Date('2014-09-01'),
    open: 103.06,
    high: 103.74,
    low: 97.79,
    close: 98.97,
    volume: 322045910,
  },
  {
    x: new Date('2014-09-08'),
    open: 99.3,
    high: 103.08,
    low: 96.14,
    close: 101.66,
    volume: 460851840,
  },
  {
    x: new Date('2014-09-15'),
    open: 102.81,
    high: 103.05,
    low: 98.89,
    close: 100.96,
    volume: 296425730,
  },
  {
    x: new Date('2014-09-22'),
    open: 101.8,
    high: 102.94,
    low: 97.72,
    close: 100.75,
    volume: 337617850,
  },
  {
    x: new Date('2014-09-29'),
    open: 98.65,
    high: 101.54,
    low: 98.04,
    close: 99.62,
    volume: 246900230,
  },
  {
    x: new Date('2014-10-06'),
    open: 99.95,
    high: 102.38,
    low: 98.31,
    close: 100.73,
    volume: 279646740,
  },
  {
    x: new Date('2014-10-13'),
    open: 101.33,
    high: 101.78,
    low: 95.18,
    close: 97.67,
    volume: 356408760,
  },
  {
    x: new Date('2014-10-20'),
    open: 98.315,
    high: 105.49,
    low: 98.22,
    close: 105.22,
    volume: 355329760,
  },
  {
    x: new Date('2014-10-27'),
    open: 104.85,
    high: 108.04,
    low: 104.7,
    close: 108,
    volume: 219443560,
  },
  {
    x: new Date('2014-11-03'),
    open: 108.22,
    high: 110.3,
    low: 107.72,
    close: 109.01,
    volume: 199332700,
  },
  {
    x: new Date('2014-11-10'),
    open: 109.02,
    high: 114.19,
    low: 108.4,
    close: 114.18,
    volume: 203976340,
  },
  {
    x: new Date('2014-11-17'),
    open: 114.27,
    high: 117.57,
    low: 113.3,
    close: 116.47,
    volume: 232574480,
  },
  {
    x: new Date('2014-11-24'),
    open: 116.85,
    high: 119.75,
    low: 116.62,
    close: 118.93,
    volume: 181158620,
  },
  {
    x: new Date('2014-12-01'),
    open: 118.81,
    high: 119.25,
    low: 111.27,
    close: 115,
    volume: 266118290,
  },
  {
    x: new Date('2014-12-08'),
    open: 114.1,
    high: 114.85,
    low: 109.35,
    close: 109.73,
    volume: 259311140,
  },
  {
    x: new Date('2014-12-15'),
    open: 110.7,
    high: 113.24,
    low: 106.26,
    close: 111.78,
    volume: 326382400,
  },
  {
    x: new Date('2014-12-22'),
    open: 112.16,
    high: 114.52,
    low: 111.97,
    close: 113.99,
    volume: 119248900,
  },
  {
    x: new Date('2014-12-29'),
    open: 113.79,
    high: 114.77,
    low: 107.35,
    close: 109.33,
    volume: 151780640,
  },
  {
    x: new Date('2015-01-05'),
    open: 108.29,
    high: 113.25,
    low: 104.63,
    close: 112.01,
    volume: 282690970,
  },
  {
    x: new Date('2015-01-12'),
    open: 112.6,
    high: 112.8,
    low: 105.2,
    close: 105.99,
    volume: 303531140,
  },
  {
    x: new Date('2015-01-19'),
    open: 107.84,
    high: 113.75,
    low: 106.5,
    close: 112.98,
    volume: 198362640,
  },
  {
    x: new Date('2015-01-26'),
    open: 113.74,
    high: 120,
    low: 109.03,
    close: 117.16,
    volume: 461747290,
  },
  {
    x: new Date('2015-02-02'),
    open: 118.05,
    high: 120.51,
    low: 116.08,
    close: 118.93,
    volume: 270190470,
  },
  {
    x: new Date('2015-02-09'),
    open: 118.55,
    high: 127.48,
    low: 118.43,
    close: 127.08,
    volume: 301354470,
  },
  {
    x: new Date('2015-02-16'),
    open: 127.49,
    high: 129.5,
    low: 126.92,
    close: 129.495,
    volume: 193883960,
  },
  {
    x: new Date('2015-02-23'),
    open: 130.02,
    high: 133.6,
    low: 126.61,
    close: 128.46,
    volume: 369856960,
  },
  {
    x: new Date('2015-03-02'),
    open: 129.25,
    high: 130.28,
    low: 125.76,
    close: 126.6,
    volume: 246472020,
  },
  {
    x: new Date('2015-03-09'),
    open: 127.96,
    high: 129.57,
    low: 121.63,
    close: 123.59,
    volume: 325921230,
  },
  {
    x: new Date('2015-03-16'),
    open: 123.88,
    high: 129.2451,
    low: 122.87,
    close: 125.9,
    volume: 266016400,
  },
  {
    x: new Date('2015-03-23'),
    open: 127.12,
    high: 128.04,
    low: 122.6,
    close: 123.25,
    volume: 208731730,
  },
  {
    x: new Date('2015-03-30'),
    open: 124.05,
    high: 126.49,
    low: 123.1,
    close: 125.32,
    volume: 161628950,
  },
  {
    x: new Date('2015-04-06'),
    open: 124.47,
    high: 128.1218,
    low: 124.33,
    close: 127.1,
    volume: 181454510,
  },
  {
    x: new Date('2015-04-13'),
    open: 128.37,
    high: 128.57,
    low: 124.46,
    close: 124.75,
    volume: 170090870,
  },
  {
    x: new Date('2015-04-20'),
    open: 125.57,
    high: 130.63,
    low: 125.17,
    close: 130.28,
    volume: 206698310,
  },
  {
    x: new Date('2015-04-27'),
    open: 132.31,
    high: 134.54,
    low: 124.58,
    close: 128.95,
    volume: 417115180,
  },
  {
    x: new Date('2015-05-04'),
    open: 129.5,
    high: 130.57,
    low: 123.36,
    close: 127.62,
    volume: 270197900,
  },
  {
    x: new Date('2015-05-11'),
    open: 127.39,
    high: 129.49,
    low: 124.82,
    close: 128.77,
    volume: 207858180,
  },
  {
    x: new Date('2015-05-18'),
    open: 128.38,
    high: 132.97,
    low: 128.36,
    close: 132.54,
    volume: 216438970,
  },
  {
    x: new Date('2015-05-25'),
    open: 132.6,
    high: 132.91,
    low: 129.12,
    close: 130.28,
    volume: 197468800,
  },
  {
    x: new Date('2015-06-01'),
    open: 131.2,
    high: 131.39,
    low: 128.36,
    close: 128.65,
    volume: 170465550,
  },
  {
    x: new Date('2015-06-08'),
    open: 128.9,
    high: 130.18,
    low: 125.62,
    close: 127.17,
    volume: 219812710,
  },
  {
    x: new Date('2015-06-15'),
    open: 126.1,
    high: 128.31,
    low: 125.71,
    close: 126.6,
    volume: 197925030,
  },
  {
    x: new Date('2015-06-22'),
    open: 127.49,
    high: 129.8,
    low: 126.51,
    close: 126.75,
    volume: 195104520,
  },
  {
    x: new Date('2015-06-29'),
    open: 125.46,
    high: 126.94,
    low: 124.48,
    close: 126.44,
    volume: 150747530,
  },
  {
    x: new Date('2015-07-06'),
    open: 124.94,
    high: 126.23,
    low: 119.22,
    close: 123.28,
    volume: 274904180,
  },
  {
    x: new Date('2015-07-13'),
    open: 125.03,
    high: 129.62,
    low: 124.32,
    close: 129.62,
    volume: 188579340,
  },
  {
    x: new Date('2015-07-20'),
    open: 130.97,
    high: 132.97,
    low: 121.99,
    close: 124.5,
    volume: 336423370,
  },
  {
    x: new Date('2015-07-27'),
    open: 123.09,
    high: 123.91,
    low: 120.91,
    close: 121.3,
    volume: 191087840,
  },
  {
    x: new Date('2015-08-03'),
    open: 121.5,
    high: 122.57,
    low: 112.1,
    close: 115.52,
    volume: 383883210,
  },
  {
    x: new Date('2015-08-10'),
    open: 116.53,
    high: 119.99,
    low: 109.63,
    close: 115.96,
    volume: 344549090,
  },
  {
    x: new Date('2015-08-17'),
    open: 116.04,
    high: 117.65,
    low: 105.645,
    close: 105.76,
    volume: 318855760,
  },
  {
    x: new Date('2015-08-24'),
    open: 110.87,
    high: 113.31,
    low: 102.6,
    close: 113.29,
    volume: 498047270,
  },
  {
    x: new Date('2015-08-31'),
    open: 112.03,
    high: 114.53,
    low: 107.36,
    close: 109.27,
    volume: 297402060,
  },
  {
    x: new Date('2015-09-07'),
    open: 111.65,
    high: 114.21,
    low: 109.77,
    close: 114.21,
    volume: 251859600,
  },
  {
    x: new Date('2015-09-14'),
    open: 116.58,
    high: 116.89,
    low: 111.87,
    close: 113.45,
    volume: 276281980,
  },
  {
    x: new Date('2015-09-21'),
    open: 113.67,
    high: 116.69,
    low: 112.37,
    close: 114.71,
    volume: 238617740,
  },
  {
    x: new Date('2015-09-28'),
    open: 113.85,
    high: 114.57,
    low: 107.31,
    close: 110.38,
    volume: 313017610,
  },
  {
    x: new Date('2015-10-05'),
    open: 109.88,
    high: 112.28,
    low: 108.21,
    close: 112.12,
    volume: 261920950,
  },
  {
    x: new Date('2015-10-12'),
    open: 112.73,
    high: 112.75,
    low: 109.56,
    close: 111.04,
    volume: 184208970,
  },
  {
    x: new Date('2015-10-19'),
    open: 110.8,
    high: 119.228,
    low: 110.11,
    close: 119.08,
    volume: 221612230,
  },
  {
    x: new Date('2015-10-26'),
    open: 118.08,
    high: 121.22,
    low: 113.99,
    close: 119.5,
    volume: 319660750,
  },
  {
    x: new Date('2015-11-02'),
    open: 119.87,
    high: 123.82,
    low: 119.61,
    close: 121.06,
    volume: 194953700,
  },
  {
    x: new Date('2015-11-09'),
    open: 120.96,
    high: 121.81,
    low: 112.27,
    close: 112.34,
    volume: 216054740,
  },
  {
    x: new Date('2015-11-16'),
    open: 111.38,
    high: 119.92,
    low: 111,
    close: 119.3,
    volume: 189492500,
  },
  {
    x: new Date('2015-11-23'),
    open: 119.27,
    high: 119.73,
    low: 117.12,
    close: 117.81,
    volume: 109582040,
  },
  {
    x: new Date('2015-11-30'),
    open: 117.99,
    high: 119.41,
    low: 114.22,
    close: 119.03,
    volume: 205415620,
  },
  {
    x: new Date('2015-12-07'),
    open: 118.98,
    high: 119.86,
    low: 112.851,
    close: 113.18,
    volume: 188609110,
  },
  {
    x: new Date('2015-12-14'),
    open: 112.18,
    high: 112.8,
    low: 105.81,
    close: 106.03,
    volume: 314856190,
  },
  {
    x: new Date('2015-12-21'),
    open: 107.28,
    high: 109,
    low: 105.57,
    close: 108.03,
    volume: 126450510,
  },
  {
    x: new Date('2015-12-28'),
    open: 107.59,
    high: 109.43,
    low: 104.82,
    close: 105.26,
    volume: 123621760,
  },
  {
    x: new Date('2016-01-04'),
    open: 102.61,
    high: 105.85,
    low: 96.43,
    close: 96.96,
    volume: 343000960,
  },
  {
    x: new Date('2016-01-11'),
    open: 98.97,
    high: 101.19,
    low: 95.36,
    close: 97.13,
    volume: 303375940,
  },
  {
    x: new Date('2016-01-18'),
    open: 98.41,
    high: 101.46,
    low: 93.42,
    close: 101.42,
    volume: 242982970,
  },
  {
    x: new Date('2016-01-25'),
    open: 101.52,
    high: 101.53,
    low: 92.39,
    close: 97.34,
    volume: 376481100,
  },
  {
    x: new Date('2016-02-01'),
    open: 96.47,
    high: 97.33,
    low: 93.69,
    close: 94.02,
    volume: 216608840,
  },
  {
    x: new Date('2016-02-08'),
    open: 93.13,
    high: 96.35,
    low: 92.59,
    close: 93.99,
    volume: 230794620,
  },
  {
    x: new Date('2016-02-15'),
    open: 95.02,
    high: 98.89,
    low: 94.61,
    close: 96.04,
    volume: 167001070,
  },
  {
    x: new Date('2016-02-22'),
    open: 96.31,
    high: 98.0237,
    low: 93.32,
    close: 96.91,
    volume: 158759600,
  },
  {
    x: new Date('2016-02-29'),
    open: 96.86,
    high: 103.75,
    low: 96.65,
    close: 103.01,
    volume: 201482180,
  },
  {
    x: new Date('2016-03-07'),
    open: 102.39,
    high: 102.83,
    low: 100.15,
    close: 102.26,
    volume: 155437450,
  },
  {
    x: new Date('2016-03-14'),
    open: 101.91,
    high: 106.5,
    low: 101.78,
    close: 105.92,
    volume: 181323210,
  },
  {
    x: new Date('2016-03-21'),
    open: 105.93,
    high: 107.65,
    low: 104.89,
    close: 105.67,
    volume: 119054360,
  },
  {
    x: new Date('2016-03-28'),
    open: 106,
    high: 110.42,
    low: 104.88,
    close: 109.99,
    volume: 147641240,
  },
  {
    x: new Date('2016-04-04'),
    open: 110.42,
    high: 112.19,
    low: 108.121,
    close: 108.66,
    volume: 145351790,
  },
  {
    x: new Date('2016-04-11'),
    open: 108.97,
    high: 112.39,
    low: 108.66,
    close: 109.85,
    volume: 161518860,
  },
  {
    x: new Date('2016-04-18'),
    open: 108.89,
    high: 108.95,
    low: 104.62,
    close: 105.68,
    volume: 188775240,
  },
  {
    x: new Date('2016-04-25'),
    open: 105,
    high: 105.65,
    low: 92.51,
    close: 93.74,
    volume: 345910030,
  },
  {
    x: new Date('2016-05-02'),
    open: 93.965,
    high: 95.9,
    low: 91.85,
    close: 92.72,
    volume: 225114110,
  },
  {
    x: new Date('2016-05-09'),
    open: 93,
    high: 93.77,
    low: 89.47,
    close: 90.52,
    volume: 215596350,
  },
  {
    x: new Date('2016-05-16'),
    open: 92.39,
    high: 95.43,
    low: 91.65,
    close: 95.22,
    volume: 212312980,
  },
  {
    x: new Date('2016-05-23'),
    open: 95.87,
    high: 100.73,
    low: 95.67,
    close: 100.35,
    volume: 203902650,
  },
  {
    x: new Date('2016-05-30'),
    open: 99.6,
    high: 100.4,
    low: 96.63,
    close: 97.92,
    volume: 140064910,
  },
  {
    x: new Date('2016-06-06'),
    open: 97.99,
    high: 101.89,
    low: 97.55,
    close: 98.83,
    volume: 124731320,
  },
  {
    x: new Date('2016-06-13'),
    open: 98.69,
    high: 99.12,
    low: 95.3,
    close: 95.33,
    volume: 191017280,
  },
  {
    x: new Date('2016-06-20'),
    open: 96,
    high: 96.89,
    low: 92.65,
    close: 93.4,
    volume: 206149160,
  },
  {
    x: new Date('2016-06-27'),
    open: 93,
    high: 96.465,
    low: 91.5,
    close: 95.89,
    volume: 184254460,
  },
  {
    x: new Date('2016-07-04'),
    open: 95.39,
    high: 96.89,
    low: 94.37,
    close: 96.68,
    volume: 111769640,
  },
  {
    x: new Date('2016-07-11'),
    open: 96.75,
    high: 99.3,
    low: 96.73,
    close: 98.78,
    volume: 142244590,
  },
  {
    x: new Date('2016-07-18'),
    open: 98.7,
    high: 101,
    low: 98.31,
    close: 98.66,
    volume: 147358320,
  },
  {
    x: new Date('2016-07-25'),
    open: 98.25,
    high: 104.55,
    low: 96.42,
    close: 104.21,
    volume: 252358930,
  },
  {
    x: new Date('2016-08-01'),
    open: 104.41,
    high: 107.65,
    low: 104,
    close: 107.48,
    volume: 168265830,
  },
  {
    x: new Date('2016-08-08'),
    open: 107.52,
    high: 108.94,
    low: 107.16,
    close: 108.18,
    volume: 124255340,
  },
  {
    x: new Date('2016-08-15'),
    open: 108.14,
    high: 110.23,
    low: 108.08,
    close: 109.36,
    volume: 131814920,
  },
  {
    x: new Date('2016-08-22'),
    open: 108.86,
    high: 109.32,
    low: 106.31,
    close: 106.94,
    volume: 123373540,
  },
  {
    x: new Date('2016-08-29'),
    open: 106.62,
    high: 108,
    low: 105.5,
    close: 107.73,
    volume: 134426100,
  },
  {
    x: new Date('2016-09-05'),
    open: 107.9,
    high: 108.76,
    low: 103.13,
    close: 103.13,
    volume: 168312530,
  },
  {
    x: new Date('2016-09-12'),
    open: 102.65,
    high: 116.13,
    low: 102.53,
    close: 114.92,
    volume: 388543710,
  },
  {
    x: new Date('2016-09-19'),
    open: 115.19,
    high: 116.18,
    low: 111.55,
    close: 112.71,
    volume: 200842480,
  },
  {
    x: new Date('2016-09-26'),
    open: 111.64,
    high: 114.64,
    low: 111.55,
    close: 113.05,
    volume: 156186800,
  },
  {
    x: new Date('2016-10-03'),
    open: 112.71,
    high: 114.56,
    low: 112.28,
    close: 114.06,
    volume: 125587350,
  },
  {
    x: new Date('2016-10-10'),
    open: 115.02,
    high: 118.69,
    low: 114.72,
    close: 117.63,
    volume: 208231690,
  },
  {
    x: new Date('2016-10-17'),
    open: 117.33,
    high: 118.21,
    low: 113.8,
    close: 116.6,
    volume: 114497020,
  },
  {
    x: new Date('2016-10-24'),
    open: 117.1,
    high: 118.36,
    low: 113.31,
    close: 113.72,
    volume: 204530120,
  },
  {
    x: new Date('2016-10-31'),
    open: 113.65,
    high: 114.23,
    low: 108.11,
    close: 108.84,
    volume: 155287280,
  },
  {
    x: new Date('2016-11-07'),
    open: 110.08,
    high: 111.72,
    low: 105.83,
    close: 108.43,
    volume: 206825070,
  },
  {
    x: new Date('2016-11-14'),
    open: 107.71,
    high: 110.54,
    low: 104.08,
    close: 110.06,
    volume: 197790040,
  },
  {
    x: new Date('2016-11-21'),
    open: 110.12,
    high: 112.42,
    low: 110.01,
    close: 111.79,
    volume: 93992370,
  },
  {
    x: new Date('2016-11-28'),
    open: 111.43,
    high: 112.465,
    low: 108.85,
    close: 109.9,
    volume: 155229390,
  },
  {
    x: new Date('2016-12-05'),
    open: 110,
    high: 114.7,
    low: 108.25,
    close: 113.95,
    volume: 151624650,
  },
  {
    x: new Date('2016-12-12'),
    open: 113.29,
    high: 116.73,
    low: 112.49,
    close: 115.97,
    volume: 194003220,
  },
  {
    x: new Date('2016-12-19'),
    open: 115.8,
    high: 117.5,
    low: 115.59,
    close: 116.52,
    volume: 113106370,
  },
  {
    x: new Date('2016-12-26'),
    open: 116.52,
    high: 118.0166,
    low: 115.43,
    close: 115.82,
    volume: 84354060,
  },
  {
    x: new Date('2017-01-02'),
    open: 115.8,
    high: 118.16,
    low: 114.76,
    close: 117.91,
    volume: 103680760,
  },
  {
    x: new Date('2017-01-09'),
    open: 117.95,
    high: 119.93,
    low: 117.94,
    close: 119.04,
    volume: 138446660,
  },
  {
    x: new Date('2017-01-16'),
    open: 118.34,
    high: 120.5,
    low: 118.22,
    close: 120,
    volume: 113576380,
  },
  {
    x: new Date('2017-01-23'),
    open: 120,
    high: 122.44,
    low: 119.5,
    close: 121.95,
    volume: 124406640,
  },
  {
    x: new Date('2017-01-30'),
    open: 120.93,
    high: 130.49,
    low: 120.62,
    close: 129.08,
    volume: 248063580,
  },
  {
    x: new Date('2017-02-06'),
    open: 129.13,
    high: 132.94,
    low: 128.9,
    close: 132.12,
    volume: 136252280,
  },
  {
    x: new Date('2017-02-13'),
    open: 133.08,
    high: 136.27,
    low: 132.75,
    close: 135.72,
    volume: 136326260,
  },
  {
    x: new Date('2017-02-20'),
    open: 136.23,
    high: 137.48,
    low: 135.28,
    close: 136.66,
    volume: 87773190,
  },
  {
    x: new Date('2017-02-27'),
    open: 137.14,
    high: 140.2786,
    low: 136.28,
    close: 139.78,
    volume: 127757050,
  },
  {
    x: new Date('2017-03-06'),
    open: 139.365,
    high: 139.98,
    low: 137.05,
    close: 139.14,
    volume: 99061270,
  },
  {
    x: new Date('2017-03-13'),
    open: 138.85,
    high: 141.02,
    low: 138.82,
    close: 139.99,
    volume: 120881720,
  },
  {
    x: new Date('2017-03-20'),
    open: 140.4,
    high: 142.8,
    low: 139.73,
    close: 140.64,
    volume: 129178500,
  },
  {
    x: new Date('2017-03-27'),
    open: 139.39,
    high: 144.5,
    low: 138.62,
    close: 143.66,
    volume: 126819590,
  },
  {
    x: new Date('2017-04-03'),
    open: 143.71,
    high: 145.46,
    low: 143.05,
    close: 143.34,
    volume: 105274540,
  },
  {
    x: new Date('2017-04-10'),
    open: 143.6,
    high: 143.8792,
    low: 140.06,
    close: 141.05,
    volume: 87342130,
  },
  {
    x: new Date('2017-04-17'),
    open: 141.48,
    high: 142.92,
    low: 140.45,
    close: 142.27,
    volume: 89092650,
  },
  {
    x: new Date('2017-04-24'),
    open: 143.5,
    high: 144.9,
    low: 143.18,
    close: 143.65,
    volume: 90423600,
  },
  {
    x: new Date('2017-05-01'),
    open: 145.1,
    high: 148.98,
    low: 144.27,
    close: 148.96,
    volume: 173861760,
  },
  {
    x: new Date('2017-05-08'),
    open: 149.03,
    high: 156.42,
    low: 149.03,
    close: 156.1,
    volume: 173087500,
  },
  {
    x: new Date('2017-05-15'),
    open: 156.01,
    high: 156.65,
    low: 149.71,
    close: 153.06,
    volume: 156993820,
  },
  {
    x: new Date('2017-05-22'),
    open: 154,
    high: 154.9,
    low: 152.67,
    close: 153.61,
    volume: 103151450,
  },
  {
    x: new Date('2017-05-29'),
    open: 153.42,
    high: 155.45,
    low: 152.22,
    close: 155.45,
    volume: 88670120,
  },
  {
    x: new Date('2017-06-05'),
    open: 154.34,
    high: 155.98,
    low: 146.02,
    close: 148.98,
    volume: 158814040,
  },
  {
    x: new Date('2017-06-12'),
    open: 145.74,
    high: 147.5,
    low: 142.2,
    close: 142.27,
    volume: 219638930,
  },
  {
    x: new Date('2017-06-19'),
    open: 143.66,
    high: 147.16,
    low: 143.66,
    close: 146.28,
    volume: 132832660,
  },
  {
    x: new Date('2017-06-26'),
    open: 147.17,
    high: 148.28,
    low: 142.28,
    close: 144.02,
    volume: 126890110,
  },
  {
    x: new Date('2017-07-03'),
    open: 144.88,
    high: 145.3001,
    low: 142.41,
    close: 144.18,
    volume: 78465450,
  },
  {
    x: new Date('2017-07-10'),
    open: 144.11,
    high: 149.33,
    low: 143.37,
    close: 149.04,
    volume: 109759170,
  },
  {
    x: new Date('2017-07-17'),
    open: 148.82,
    high: 151.74,
    low: 148.57,
    close: 150.27,
    volume: 104744470,
  },
  {
    x: new Date('2017-07-24'),
    open: 150.58,
    high: 153.99,
    low: 147.3,
    close: 149.5,
    volume: 105536280,
  },
  {
    x: new Date('2017-07-31'),
    open: 149.9,
    high: 159.75,
    low: 148.13,
    close: 156.39,
    volume: 170204830,
  },
  {
    x: new Date('2017-08-07'),
    open: 157.06,
    high: 161.83,
    low: 154.63,
    close: 157.48,
    volume: 149860480,
  },
  {
    x: new Date('2017-08-14'),
    open: 159.32,
    high: 162.51,
    low: 156.72,
    close: 157.5,
    volume: 133829670,
  },
  {
    x: new Date('2017-08-21'),
    open: 157.5,
    high: 160.74,
    low: 155.1101,
    close: 159.86,
    volume: 112238670,
  },
  {
    x: new Date('2017-08-28'),
    open: 160.14,
    high: 164.94,
    low: 159.93,
    close: 164.05,
    volume: 125610990,
  },
  {
    x: new Date('2017-09-04'),
    open: 163.75,
    high: 164.25,
    low: 158.53,
    close: 158.63,
    volume: 101419110,
  },
  {
    x: new Date('2017-09-11'),
    open: 160.5,
    high: 163.96,
    low: 157.91,
    close: 159.88,
    volume: 220431100,
  },
  {
    x: new Date('2017-09-18'),
    open: 160.11,
    high: 160.5,
    low: 157.995,
    close: 158.67,
    volume: 27939544,
  },
];
export const PyramidData = [
  { x: 'Sweet Treats', y: 120, text: '120 cal' },
  { x: 'Milk, Youghnut, Cheese', y: 435, text: '435 cal' },
  { x: 'Vegetables', y: 470, text: '470 cal' },
  { x: 'Meat, Poultry, Fish', y: 475, text: '475 cal' },
  { x: 'Fruits', y: 520, text: '520 cal' },
  { x: 'Bread, Rice, Pasta', y: 930, text: '930 cal' },
];
