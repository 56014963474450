import { ColumnDirective, ColumnsDirective, GridComponent, Inject } from '@syncfusion/ej2-react-grids';
import { DetailRow } from '@syncfusion/ej2-react-grids';
import React, { useState } from 'react';
import { VolvoCEGrid, VolvoCEData, cluster1Data, cluster2Data, cluster3Data } from '../data/dummy';
import { Header } from '../components';
import image from '../data/image.png';
import BarChart from "../components/BarChart";
import { UserData } from "../data/Data";

const clusterContainerStyle = {
  width: '90%',
};

const mainGridContainerStyle = {
  width: '100%',
};

// Override styles for the expand icons to make them transparent
const expandIconStyle = {
  background: 'transparent',
  border: 'none',
  cursor: 'pointer',
};

function App() {
  const [userData, setUserData] = useState({
    labels: UserData.map((data) => data.month),
    datasets: [
            {
        label: "Users Gained",
        data: UserData.map((data) => data.userGain),
        backgroundColor: ["blue"],
        barPercentage: 0.6,
        categoryPercentage: 1,
      },
      {
        label: "Users Gained",
        data: UserData.map((data) => data.userGain1),
        backgroundColor: ["blue"],
        barPercentage: 0.6,
        categoryPercentage: 1,
      },
      {
        label: "Users Gained",
        data: UserData.map((data) => data.userGain2),
        backgroundColor: ["blue"],
        barPercentage: 0.6,
        categoryPercentage: 1,
      },
      {
        label: "Users Gained",
        data: UserData.map((data) => data.userGain3),
        backgroundColor: ["blue"],
        barPercentage: 0.6,
        categoryPercentage: 1,
      },
      {
        label: "Users Gained",
        data: UserData.map((data) => data.userGain4),
        backgroundColor: ["blue"],
        barPercentage: 0.6,
        categoryPercentage: 1,
      },
      // Add more datasets as needed for other userGain properties
    ],
  });
  const childGridColumns = [
    // ... existing childGridColumns ...
  ];
  const expandIconTemplate = (props) => {
    // If the row is expanded
    if (props.isExpand) {
      return (
        <img src={image.png} alt="Expanded Icon" style={{ width: '16px', height: '16px', cursor: 'pointer' }} />
      );
    } else {
      // If the row is not expanded
      return (
        <img src={image.png} alt="Collapsed Icon" style={{ width: '16px', height: '16px', cursor: 'pointer' }} />
      );
    }
  };

  const detailTemplate = (props) => {
    return (
      <div style={{ display: 'flex' }}>
        <div style={{ width: '100%' }}>
          <h4 style={{ fontWeight: 'bold', fontSize: '18px' }}>Cluster 1 Manila:</h4>
          <div style={clusterContainerStyle}>
            <GridComponent dataSource={cluster1Data} childGrid={childGridColumns}>
              <ColumnsDirective>
                {childGridColumns.map((column) => (
                  <ColumnDirective
                    key={column.field}
                    field={column.field}
                    headerText={column.headerName}
                    width={column.width}
                    format={column.format}
                  />
                ))}
              </ColumnsDirective>
            </GridComponent>
          </div>
          <h4 style={{ fontWeight: 'bold', fontSize: '18px' }}>Cluster 2 Cagayan De Oro:</h4>
          <div style={clusterContainerStyle}>
            <GridComponent dataSource={cluster2Data} childGrid={childGridColumns}>
              <ColumnsDirective>
                {childGridColumns.map((column) => (
                  <ColumnDirective
                    key={column.field}
                    field={column.field}
                    headerText={column.headerName}
                    width={column.width}
                    format={column.format}
                  />
                ))}
              </ColumnsDirective>
            </GridComponent>
          </div>
          <h4 style={{ fontWeight: 'bold', fontSize: '18px' }}>Cluster 3 Surigao:</h4>
          <div style={clusterContainerStyle}>
            <GridComponent dataSource={cluster3Data} childGrid={childGridColumns}>
              <ColumnsDirective>
                {childGridColumns.map((column) => (
                  <ColumnDirective
                    key={column.field}
                    field={column.field}
                    headerText={column.headerName}
                    width={column.width}
                    format={column.format}
                  />
                ))}
              </ColumnsDirective>
            </GridComponent>
          </div>
        </div>
        <div style={{ width: '50%', paddingLeft: '1px' }}>
          <img src={image} alt="Lubes Monitoring" />
        </div>
      </div>
    );
  };

  return (
    <div className="mt-6">
      <div className="flex flex-wrap lg:flex-nowrap justify-left">
      <div style={{ width: 700, height: 100,  }}>
          <BarChart chartData={userData} />
          
        </div>
      </div>
      <div className="m-1 md:m-1 p-1 md:p-2 bg-white rounded-3xl ">
        <Header category="" title="Lubes Monitoring" />
        <div style={mainGridContainerStyle}>
          <GridComponent dataSource={VolvoCEData} detailTemplate={detailTemplate} detailRowTemplate={expandIconStyle}>
            <ColumnsDirective>
              {VolvoCEGrid.map((column) => (
                <ColumnDirective
                  key={column.field}
                  field={column.field}
                  headerText={column.headerText}
                  textAlign={column.textAlign}
                  width={column.width}
                  format={column.format}
                />
              ))}
            </ColumnsDirective>
            <Inject services={[DetailRow]} detailRowTemplate={expandIconTemplate} />
          </GridComponent>
        </div>
      </div>
    </div>
  );
}

export default App;
